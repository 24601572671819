import React, { useEffect, useState } from "react";
// import {Post} from "../../../Components/Post/Post"
import { AdminPostApproved } from "../AdminPostApproved/repost"
import Axios from 'axios'
import './Home.css'
// import { PosttoolBar } from "../../Components/PostToolBar/PostToolBar";

// import { Notification } from "../../Components/Notifications/Notification"
export const AdminPostApprovedHome = () => {

    const [data, setdata] = useState()
    const [state, setstate] = useState(false)//ini
    const [load, setload] = useState(true)
    const [error, seterror] = useState('')
    const [output, setoutput] = useState('')

    function makeid(length) {
        length = 5
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }
    const [sp, setsp] = useState(false)
    useEffect(() => {
        loadme()
    }, [])
    const loadme = () => {
        setdata(false)
        setsp(!sp)
        console.log('loadme')
        Axios.get("/api/Admin/AdminPostViewApproved").then((response) => {
            console.log('success')
            console.log(response.data)
            const postSummaries = response.data.map((post) => ({ Report_count:post.Reported_Acc_id.length ,id: post._id, key: post.id, Post_Id: post.Post_Id, Content: post.Content, Author: post.Account_id, File: post.File, Posted_On: post.Posted_On, Commentcount: post.commentcount, merge: post.string, Likecount: post.likecount, Likestatus: post.likestatus }));
            
            console.log(response.data[5].Reported_Acc_id.length )
            setdata(postSummaries)
            setload(false)
            seterror('')

        }).catch(
            res => {
                setload(false)
                setdata(false)
                seterror('Something went wrong')
                console.log('Something went wrong')
            }
        )


    }

    const loaders = () => {
        if (load) {
            setoutput(<><br /><div className="text-center "> <div style={loader} className="spinner-border text-dark"></div></div></>)
        }
        else {
            if (data) {
                setoutput(data.map((number) =>
                    <AdminPostApproved key={number.id} loadme={loadme} ids={makeid()} dat={number}></AdminPostApproved>
                )
                )
            }
            else {
                setoutput(error)
            }
        }
    }

useEffect(() => {
 loaders()
}, [data])

    useEffect(() => {
        loaders()
    }, [error, load, sp])





    // Loadpost()
    var loader = {
        width: '5rem',
        height: '5rem'
    }
    return (

        <div className="container-fluid">
          

            <div className="row">
                {/* <div className="col-md-1"></div> */}
                <div className="postrt  tab  pt-5    " >
                    <div className=" dfrty  ">

                        {/* <Post dat={data}></Post> */}
                        {output}
                        {/* {load ? <div className="text-center cell"> <div style={loader} className="spinner-border text-dark"></div></div> :
                        data.map((number) =>
                            <Post key={number.id} dat={number}></Post>
                        )
                    }
                    {error ? error : ''} */}
                    </div>
                </div>

            </div>
        </div>

    )

}
