import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { notify } from '../../../Utility/notify';
import { Link, useSearchParams } from 'react-router-dom';

export const FMEMemberviewRM = () => {
  // const [data, setdata] = useState([]);

  const [params, useparams] = useSearchParams();

  var refferal = params.get('k');
  useEffect(() => {
    console.log(refferal);
  }, [refferal]);

  // useEffect(() => {
  //   axios
  //       .get('/api/regionalmanager/regionalgetvalue')
  //     .then((res) => {
  //       console.log('bnmbm');
  //       console.log(res.data);
  //       var newstate = res.data.map((elem, index) => {
  //         console.log(elem);
  //         console.log(index);
  //         return {
  //           ...elem,
  //           id: index + 1,
  //         };
  //       });
  //       console.log(newstate);
  //       setdata(newstate);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  const Deleteee = (id, index, Login_id) => {
    console.log(id);
    console.log(index);

    axios
      .post('/api/regionalmanager/regionaldelete', { id, index, Login_id })
      .then((res) => {
        console.log('bvasdsda');
        notify('Deleted Successfully', true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        notify('Something Went Wrong', false);
        console.log(err);
      });
  };

  const columns = [
    { field: 'id', headerName: 'Sl No', width: 90 },
    {
      field: 'Name',
      headerName: 'Name',
      width: 150,
    },
    {
      field: 'Role',
      headerName: 'Role',
      width: 150,
    },
    {
      field: 'country',
      headerName: 'Country',
      type: 'number',
      width: 110,
    },
    {
      field: 'state',
      headerName: 'State',

      width: 110,
      editable: true,
    },
    {
      field: 'city',
      headerName: 'City',

      width: 110,
    },
    // {
    //   field: 'Profile',
    //   headerName: 'Profile',
    //   width: 150,
    //   renderCell: (params) => {
    //     return (
    //       <Link to={`/sme_profile?k=${params.row._id}`}>
    //         <button className="btn pro-spaces-button3">Profile</button>
    //       </Link>
    //     );
    //   },
    // },

    {
      field: 'Delete',
      headerName: 'Delete',
      sortable: false,
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <button
          className="btn btn-danger"
          data-bs-toggle="modal"
          data-bs-target={'#deletemodal' + (params.row.id - 1)}
          onClick={(e) => {
            console.log(params.row.id);
          }}
        >
          Delete
        </button>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      Name: 'Snow',
      Role: 'Jon',
      Country: 35,
      State: 'kolkata',
      City: 'ff',
    },
  ];

  const [state, setState] = useState([]);

  useEffect(() => {
    axios
      .post('/api/fme/fmegetvalue', { refferal })
      .then((res) => {
        console.log(res.data);
        const newstate = res.data.map((elem, index) => {
          console.log(elem);
          return {
            ...elem,
            id: index + 1,
          };
        });
        console.log(newstate);
        setState(newstate);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    console.log(state);
  }, [state]);

  return (
    <>
      <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={state}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        />
      </Box>

      {state?.map((elem, index) => {
        return (
          <>
            <div
              class="modal fade"
              id={'deletemodal' + index}
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      id={'btnclose'}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body text-danger">
                    Are you sure you want to delete ?
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={(e) => {
                        Deleteee(elem._id, elem.id, elem.Login_id);
                        // setopperins(elem.ind);
                        // setIndex(elem.ind);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};
