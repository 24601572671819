import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validator } from 'validator';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Form } from 'react-bootstrap';

import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IoChevronUpSharp, IoInformationCircleOutline } from 'react-icons/io5';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import { Timer } from '../../Utility/timer';

import moment from 'moment';
import { type } from 'os';
import { Imageupload } from '../../ManageAdmin/Imageupload';
import { StateCity } from '../../ManageAdmin/StateCity';
import { print_city, print_state } from '../../../Utility/stateCity';
import { Viparheader } from '../../../Pages/HomePage/Viparheader';
import { Statecity2 } from '../../ManageAdmin/Statecity2';
import { IoIosCloseCircleOutline } from 'react-icons/io';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '300',
    },
  },
};

export const RegisterFME = (props) => {
  var btnchange = useRef();

  let navigate = useNavigate();
  const [Stateload, setStateload] = useState(true);
  useEffect(() => {
    if (Stateload) print_state('State');
    setStateload(false);
  }, []);
  const [value, setValue] = useState({
    AdminType: 'AffiliatedAccount',
    FixedSalary: true,
    Name: '',
    Email: '',
    Contact: '',
    Password: '',
    ConformPassword: '',
    showPassword: false,
    showConformPassword: false,
    Referral_Code: '',
    Active_status: true,
    Activev2_status: true,
    Activev3_status: true,
    Passive_status: true,
    Service_provide_category_All: true,
    Product_retailer_category_All: true,
    state: '',
    city: '',
    Commission_percentage: '',
    Bank_account_type: '',
    loginID: '',
    Work_Experience: '',
    Highest_Educational_Qualification: '',
    Ready_to_Join_on_anyday_after: '',
    ED_Pursuing_Education: '',
    ED_College: '',
    ED_University: '',

    ED_City: '',
    ED_Period_of_Internship_Start: '',
    ED_Period_of_Internship_End: '',

    Studentornot: 'NoStudent',
    FMName: '',
    FMRelationship: '',
    FMContact: '',
    FMAddress: '',
    FMPincode: '',
    BloodGroup: '',
  });

  const [btn, setbtn] = useState(false);
  const [valueee, setvalueee] = useState({
    Fmstate: '',
    Fmcity: '',
    Fmcountry: '',
  });
  const [Edit, setEdit] = useState('');
  useEffect(() => {
    axios
      .get('/api/AffiliatedAccount/getAffiliatedAccountDetails')
      .then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          var data = res.data[0];
          if (res.data.length > 0) {
            props.Auth1();
            setEdit(res.data);
            setValue({
              ...value,
              Name: data.Name,
              Email: data.Account[0]?.Email,
              Contact: data.Account[0]?.Contact,
              Referral_Code: data.Referral_Code,
              state: data.state,
              city: data.city,
              Account_holder_name: data.Account_holder_name,
              Account_number: data.Account_number,
              Ifsc_code: data.Ifsc_code,
              Bank_name: data.Bank_name,
              Branch_name: data.Branch_name,
              country: data.country,
              Bank_account_type: data?.Bank_account_type,
              loginID: data.Account[0]?._id,
              Address: data.Address,
              Pincode: data.Pincode,
              Age: data.Age,
              Gender: data.Gender,
              Nationality: data.Nationality,
              Work_Experience: data.Work_Experience,
              Highest_Educational_Qualification:
                data.Highest_Educational_Qualification,
              Ready_to_Join_on_anyday_after: data.Ready_to_Join_on_anyday_after,
              ED_Pursuing_Education: data.ED_Pursuing_Education,
              ED_College: data.ED_College,
              ED_City: data.ED_City,
              ED_Period_of_Internship_Start: data.ED_Period_of_Internship_Start,
              ED_Period_of_Internship_End: data.ED_Period_of_Internship_End,
              ED_University: data.ED_University,

              Studentornot: data.Studentornot,
              FMName: data.FMName,
              FMRelationship: data.FMRelationship,
              FMContact: data.FMContact,
              FMAddress: data.FMAddress,
              FMPincode: data.FMPincode,
              BloodGroup: data.BloodGroup,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    console.log(value);
  }, [value]);

  // var validator=validator

  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (sab == 'red') {
      toast.danger(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  function Cityshow(e) {
    var index = document.getElementById(e.target.id).selectedIndex;
    print_city('City', index);
    setValue({ ...value, state: e.target.value });
  }

  // const [Cat, setCat] = useState([])
  //       function reload()
  //       {
  //         axios
  //         .get("/api/api//getcategory")
  //         .then((res) => {
  //           if (res.status === 200) {
  //             console.log("workd");
  //             console.log(res.data)
  //             setCat(res.data);
  //           }
  //         })
  //         .catch((err) => {
  //           // console.log(err.response.status);
  //         });
  //       }
  // const [Cat1, setCat1] = useState([])

  //       function reload1()
  //       {
  //         axios
  //         .get("/api/api/getprocategory")
  //         .then((res) => {
  //           if (res.status === 200) {
  //             console.log("workd");
  //             console.log(res.data)
  //             setCat1(res.data);
  //           }
  //         })
  //         .catch((err) => {
  //           // console.log(err.response.status);
  //         });
  //       }

  //       useEffect(() => {
  //         reload()
  //         reload1()

  //       }, [])
  const [imgi, setimgi] = useState();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  function validator1(e) {
    e.preventDefault();
    setSubmit2(true);
    const errors = {};
    console.log('i am innnnnnnnnnnnnnnn');
    // if(!value.Name)return setError("Name is missing !");
    // if (!value.Email) {
    //   errors.email = 'Email required !'
    // return setError(errors)
    // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.Email)) {
    //   errors.email = 'Invalid email address !'
    // return setError(errors)
    // }

    // if (!value.Contact) {
    //     return setError('Enter phone number !')
    // }
    // if (value.Contact.toString().length != 10) {
    //     return setError('Enter a valid mobile number !')
    // }
    // if(!value.Commission_percentage)return setError("Discount percentage is missing !");
    if (value.Studentornot == 'NoStudent') {
      if (!value.Highest_Educational_Qualification) {
        return setError('PLEASE ENTER Highest Educational Qualification');
      }
      if (!value.Work_Experience) {
        return setError('PLEASE ENTER Work_Experience');
      }
      if (!value.Ready_to_Join_on_anyday_after) {
        return setError('PLEASE ENTER Ready to Join on anyday after');
      }
    }
    if (value.Studentornot == 'Student') {
      console.log('I am in');

      if (!value.Address) {
        return setError('PLEASE ENTER Address');
      }
      if (!value.Pincode) {
        return setError('PLEASE ENTER Pincode');
      }
      if (!value.ED_Pursuing_Education) {
        return setError('PLEASE ENTER Pursuing Education');
      }
      if (!value.ED_College) {
        return setError('PLEASE ENTER College');
      }
      if (!value.ED_City) {
        return setError('PLEASE ENTER City');
      }
      if (!value.ED_Period_of_Internship_Start) {
        return setError('PLEASE ENTER Period of Internship ');
      }
      if (!value.ED_Period_of_Internship_End) {
        return setError('PLEASE ENTER Period of Internship');
      }

      setError('');
    } else {
      console.log('working elspart q');
    }

    if (!Edit.length > 0) {
      if (!imgi) {
        return setError('PLEASE UPLOAD Image');
      }
      if (!value.Studentornot == 'Student') {
        if (!value.Work_Experience) return setError('3');
        if (!value.Highest_Educational_Qualification) return setError('4');
        if (!value.Ready_to_Join_on_anyday_after) return setError('5');
      }

      if (!value.Password) return setError('6');

      //   if (value.Password.toString().length <8) {
      //     return setError('Password must contain 8 characters !')
      // }
      setError('');
      if (value.Password.toString() !== value.ConformPassword.toString()) {
        return setError("'Password' and 'Confirm Password' must be matching !");
      }
    }

    setError('');
    // &&TermsAndCondition.Terms4
    if (Edit.length > 0) {
      console.log('<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>1');
      handleShow11();
    } else {
      if (value.Age <= 17) {
        return setError(
          'Sorry....you are too young ! Age must be 18 years and above.'
        );
      }
      setError('');

      go();
    }
  }

  const [params, setparams] = useSearchParams();
  var refferal = params.get('k');
  function go() {
    console.log('meeeeeeee');
    console.log(adar);
    var name = value.Name;
    if (ButtonDisabled1) {
      return setError('Enter referral code ');
    }

    setError('');
    setLoading(true);
    // console.log(value)
    // return

    var formdata = new FormData();
    var imag = new File([imgi], 'avatar.png', { type: 'image/png' });

    formdata.append('file', imag);
    adar?.map((elem) => {
      formdata.append('file', elem);
    });

    formdata.append(
      'data',
      JSON.stringify({ value, Affiliated, valueee, refferal })
    );

    var Affiliated = true;
    axios
      .post('/api/fme/fmeregistration', formdata)
      .then((res) => {
        if (res.status === 200) {
          notify('Successfully created', true);
          setValue({
            ...value,
            AdminType: '',

            Name: '',
            Email: '',
            Contact: '',
            Password: '',
            Pin: '',
            Active_status: true,
            Activev2_status: true,
            Activev3_status: true,
            Passive_status: true,
            Service_provide_category_All: true,
            Product_retailer_category_All: true,
            state: '',
            city: '',
            Commission_percentage: '',
            showConformPassword: false,
            Referral_Code: '',
            ConformPassword: '',
            Account_holder_name: '',
            Account_number: '',
            Ifsc_code: '',
            Bank_name: '',
            Branch_name: '',
            country: '',
            Bank_account_type: '',
            Gender: '',
            Age: '',
            Nationality: '',
            Address: '',
            Pincode: '',
            Work_Experience: '',
            Highest_Educational_Qualification: '',
            Ready_to_Join_on_anyday_after: '',
            ED_Pursuing_Education: '',
            ED_College: '',
            ED_City: '',
            ED_Period_of_Internship_Start: '',
            ED_Period_of_Internship_End: '',

            Studentornot: '',
            FMName: '',
            FMRelationship: '',
            FMContact: '',
            FMAddress: '',
            FMPincode: '',
            BloodGroup: '',
          });
          setService_provide_category([]);
          setProduct_retailer_category([]);
          setLoading(false);
          setError('');
          navigate(`/`);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          notify('Wrong security pin', false);
        } else if (err.response.status === 402 || err.response.status === 409) {
          notify('Email already exist', false);
        } else if (err.response.status === 403) {
          notify('Name already exist', false);
        } else if (err.response.status === 406) {
          notify('This Code already exists', false);
        } else if (err.response.status === 407) {
          notify('This Code already exists', false);
        } else if (err.response.status === 408) {
          notify('Enter referral code', false);
        } else if (err.response.status == 400) {
          notify('Email already exist', false);
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        } else {
          notify('Something went wrong', false);
        }
        setLoading(false);
      });
  }

  const [Service_provide_category, setService_provide_category] =
    React.useState([]);

  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setService_provide_category(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const [Product_retailer_category, setProduct_retailer_category] =
    React.useState([]);
  const handleChange2 = (event) => {
    const {
      target: { value },
    } = event;
    setProduct_retailer_category(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  useEffect(() => {
    if (value.Service_provide_category_All) {
      setService_provide_category([]);
    }
    if (value.Product_retailer_category_All) {
      setProduct_retailer_category([]);
    }
  }, [value.Service_provide_category_All, value.Product_retailer_category_All]);

  const [ButtonDisabled, setButtonDisabled] = useState(false);
  const [ButtonDisabled1, setButtonDisabled1] = useState(true);
  const checkReferral_Code = (e) => {
    if (!Edit?.length > 0) {
      setButtonDisabled(true);
    }
    var Referral_Code = e;
    axios
      .post('/api/auth/checkReferral_Code', { Referral_Code })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length == 0) {
            notify('This referral code is available for you', true);

            setButtonDisabled1(false);

            setError('');
          } else {
            notify('This Code already exists', false);
            setError('This Code already exists');
          }
        }
      })
      .catch((err) => {
        notify('Something went wrong', false);
        setError('');
      });
  };
  const [Submit2, setSubmit2] = useState(false);
  const [Error2, setError2] = useState('');
  function Update(e) {
    setError2('');
    var formdata = new FormData();
    formdata.append('data', JSON.stringify({ value, OTP }));
    if (imgi) {
      var imag = new File([imgi], 'avatar.png', { type: 'image/png' });

      formdata.append('file', imag);
    }
    axios
      .post('/api/AffiliatedAccount/Update_Account', formdata)
      .then((res) => {
        if (res.status == 200) {
          notify('Updated', true);
          console.log(res.data);
          handleClose11();
          setError2('');
          setOTP('');
          end();
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 410) {
          return setError2('Enter valid OTP ');
        }
        notify('Something went wrong !', false);
      });
  }
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const [TermsAndCondition, setTermsAndCondition] = useState({
    Terms1: false,
    Terms2: false,
    Terms3: false,
    Terms4: false,
    Terms5: false,
    Terms6: false,
    Terms7: false,
    Terms8: false,
    Terms9: false,
    Terms10: false,
    Terms16: false,
    Terms17: false,
    Terms18: false,
    Terms19: false,
    Terms20: false,
    Terms21: false,
    Terms22: false,
    Terms23: false,
    Terms24: false,
    Terms25: false,
    Terms26: false,
    Terms27: false,
    Terms28: false,
    Terms29: false,
    Terms30: false,
    Terms31: false,
    Terms32: false,
    Terms33: false,

    // Terms11: false,
    Terms12: false,
    // Terms13: false,
  });

  useEffect(() => {
    // console.log(TermsAndCondition)
    // console.log("ButtonDisabled :",ButtonDisabled)
    // console.log("ButtonDisabled1 :",ButtonDisabled1)
    console.log('<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>2');
    console.log(Edit.length);
    if (Edit.length > 0) {
      console.log(Edit.length);
    } else {
      console.log('<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>2');
      //
      if (
        TermsAndCondition.Terms1 &&
        TermsAndCondition.Terms2 &&
        TermsAndCondition.Terms3 &&
        TermsAndCondition.Terms4 &&
        TermsAndCondition.Terms5 &&
        TermsAndCondition.Terms6 &&
        TermsAndCondition.Terms7 &&
        TermsAndCondition.Terms8 &&
        TermsAndCondition.Terms9 &&
        TermsAndCondition.Terms10 &&
        TermsAndCondition.Terms16 &&
        TermsAndCondition.Terms17 &&
        TermsAndCondition.Terms18 &&
        TermsAndCondition.Terms19 &&
        TermsAndCondition.Terms20 &&
        TermsAndCondition.Terms21 &&
        TermsAndCondition.Terms22 &&
        TermsAndCondition.Terms23 &&
        TermsAndCondition.Terms24 &&
        TermsAndCondition.Terms25 &&
        TermsAndCondition.Terms26 &&
        TermsAndCondition.Terms27 &&
        TermsAndCondition.Terms28 &&
        TermsAndCondition.Terms29 &&
        TermsAndCondition.Terms30 &&
        TermsAndCondition.Terms31 &&
        TermsAndCondition.Terms32 &&
        // TermsAndCondition.Terms33 &&
        // TermsAndCondition.Terms11
        // &&
        TermsAndCondition.Terms12
        //  &&
        // TermsAndCondition.Terms13
      ) {
        if (!ButtonDisabled1) {
          setButtonDisabled(false);
          console.log('hai');
        }
      } else {
        return setError(' ');
      }
    }
    setError('');
  }, [Edit, TermsAndCondition, ButtonDisabled, ButtonDisabled1]);

  const [show11, setShow11] = useState(false);

  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => {
    setShow11(true);
    //
    GenerateOTP();
  };
  const GenerateOTP = () => {
    console.log('hai');
    axios
      .post('/api/AffiliatedAccount/GenerateOTPForAccountUpdate', {
        Email: value.Email,
      })
      .then((res) => {
        if (res.status == 200) {
          setpop(true);
          notify('OTP send successfully', true);
        }
      })
      .catch((err) => {
        setpop(false);
        notify('Something went wrong', false);
      });
  };

  const [OTP, setOTP] = useState('');
  const [pop, setpop] = useState(true);

  const end = () => setpop(false);

  const [NewPassword, setNewPassword] = useState({
    oldPassword: '',
    newPassword: '',
  });
  const [showpassword, setShowpassword] = useState(false);

  const handleClose111 = () => setShowpassword(false);
  const handleShow111 = () => setShowpassword(true);
  const [showPassword12, setShowPassword12] = useState(false);
  const [showPassword123, setShowPassword123] = useState(false);

  const [adar, setadar] = useState([]);

  const fun = (index) => {
    let temp = [...adar];
    temp.splice(index, 1);
    setadar([...temp]);
  };

  const UpdatePassword = () => {
    axios
      .post('/api/AffiliatedAccount/AccountPasswordUpdate', { NewPassword })
      .then((res) => {
        if (res.status == 200) {
          notify('Password updated successfully', true);
          setNewPassword({
            oldPassword: '',
            newPassword: '',
          });
          handleClose111();
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          notify('Old password is incorrect', false);
        } else {
          notify('Something went wrong', false);
        }
      });
  };

  const [showreferral, setShowreferral] = useState(false);

  const handleClosereferral = () => setShowreferral(false);
  const handleShowreferral = () => setShowreferral(true);

  const [termintern, setTermintern] = useState(true);

  // const StudentValidation = () => {
  //   console.log('StudentValidation');

  //   }
  // };

  /////////////////////////////////////////////////////////////
  return (
    <div className=" rounded bg-white px-3 px-md-5 py-5">
      <Viparheader />

      {!Edit?.length > 0 ? (
        <>
          <h3 className="text-center mt-4 ">
            DATA COLLECTION EXECUTIVE (Salary) REGISTRATION FORM
          </h3>
          {/* <div className="text-center">( Only for Salary-based Marketing )</div> */}
          <div className="pt-4 text-center" style={{ fontSize: '20px' }}>
            This is an Agreement between You and{' '}
            <span
              className="vipar"
              style={{
                color: 'green',
                fontSize: '30px',
                paddingRight: '5px',
              }}
            >
              Vipar
            </span>{' '}
            <sup>®</sup> only on Contract basis.
          </div>
        </>
      ) : (
        <h3 className=" mt-4 mb-5">Account Details</h3>
      )}
      {Edit?.length > 0 ? (
        <div className="d-flex justify-content-end">
          <button
            className="btn pro-spaces-button3"
            onClick={(e) => {
              handleShow111(e);
            }}
          >
            Change password
          </button>
        </div>
      ) : (
        ''
      )}
      <form
        onSubmit={(e) => validator1(e)}
        novalidate
        className={Submit2 ? 'was-validated' : ''}
      >
        <div class="form-check pt-3">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            defaultChecked={value.Studentornot === 'NoStudent' ? true : false}
            value={'NoStudent'}
            onChange={(e) =>
              setValue({
                ...value,
                Studentornot: e.target.value,
                ED_Pursuing_Education: '',
                ED_College: '',
                ED_University: '',
                ED_City: '',
                ED_Period_of_Internship_Start: '',
                ED_Period_of_Internship_End: '',
              })
            }
          />
          <label class="form-check-label" for="flexRadioDefault1">
            I am NOT a Student.
          </label>
        </div>
        <div class="form-check pt-3">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            defaultChecked={value.Studentornot == 'Student' ? true : false}
            value={'Student'}
            onChange={(e) =>
              setValue({
                ...value,
                Studentornot: e.target.value,
                Highest_Educational_Qualification: '',
                Work_Experience: '',
                Ready_to_Join_on_anyday_after: '',
              })
            }
          />
          <label class="form-check-label" for="flexRadioDefault2">
            I am a Student doing Internship.
          </label>
        </div>
        {!Edit?.length > 0 ? (
          <>
            <h5 className="mt-4 text-center">
              <u>TERMS and CONDITIONS</u>
            </h5>
            <h6 className="pt-4">CONSENT :</h6>

            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault1">
                <ul>
                  <li>
                    I am aware and fully understand that{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> is the Organisation (based out of Bengaluru,
                    Karnataka) which SHALL employ me as a Data Collection
                    Executive, ONLY IF and WHEN I pass the Skill Test Program,
                    and that <span className="prochure">PROchure</span>{' '}
                    {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                    is a digital web and mobile application developed by{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <span
                      className="verticals"
                      style={{ fontSize: '16px', color: 'black' }}
                    >
                      CONNECT
                    </span>
                    , which is a unit of{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup>.
                  </li>
                </ul>
              </label>
            </div>

            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault1">
                <ul>
                  <li>
                    I am ready to enrol myself as a Data Collection Executive at{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup> to meet potential customers and
                    explain to them about the digital platform called{' '}
                    <span className="prochure">PROchure</span>{' '}
                    {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                    (an app accessible to users across the globe) which is
                    primarily a digital directory of Services and Products.
                  </li>
                </ul>
              </label>
            </div>
            {value?.Studentornot == 'Student' ? (
              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault9">
                  <ul>
                    <li>
                      I admit and agree that I am joining{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup class="">®</sup> as a Marketing Intern with the
                      knowledge and consent of my family and the concerned
                      authority of the College / Institution where I am
                      currently pursuing my education, and that IF need arises,
                      I SHALL get the required consent letter from the College /
                      Institution.
                    </li>
                  </ul>
                </label>
              </div>
            ) : (
              <></>
            )}

            <h5 className="mt-4 ">
              TERMS and CONDITIONS for the 1-DAY SKILL TEST *
            </h5>
            <h6 className="pt-4">CONSENT :</h6>

            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault1">
                <ul>
                  <li>
                    I am aware and fully understand that the Skill Test is
                    solely to assess IF I am competent enough to do the job as a
                    Data Collection Executive in the required manner efficiently
                    and correctly.
                  </li>
                </ul>
              </label>
            </div>

            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault1">
                <ul>
                  <li>
                    I am aware and agree that the Skill Test is conducted for
                    assessing my efficiency, accuracy and speed with which I can
                    complete the task and target assigned to me for a single
                    day.
                  </li>
                </ul>
              </label>
            </div>

            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault1">
                <ul>
                  <li>
                    I am aware and agree that each Skill Test SHALL be completed
                    ONLY on a single day and the targets given to me is counted
                    ONLY for a single day and that it cannot be carried forward
                    to the following day or any other day.
                  </li>
                </ul>
              </label>
            </div>

            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault1">
                <ul>
                  <li>
                    I am aware and agree that I SHALL NOT be remunerated at all
                    for the Skill Test and that I am willing to attempt this
                    test in my vested interest to get the job as a Data
                    Collection Executive at{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup>.
                  </li>
                </ul>
              </label>
            </div>

            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault1">
                <ul>
                  <li>
                    I am aware and agree that I SHALL NOT be employed with{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> UNTIL that time I successfully complete and
                    pass the Skill Test.
                  </li>
                </ul>
              </label>
            </div>

            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault1">
                <ul>
                  <li>
                    I am aware and agree that passing the Skill Test, based on
                    the mutually agreed upon pre- requisite terms & conditions
                    and other criteria, is at the sole discretion of{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup>.
                  </li>
                </ul>
              </label>
            </div>

            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault1">
                <ul>
                  <li>
                    I am aware and agree that I am eligible to attempt the Skill
                    Test as many times I wish to UNTIL I pass the test
                    successfully and that I SHALL NOT be remunerated at all for
                    any of my attempts.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault1">
                <ul>
                  <li>
                    I am aware and agree that I SHALL make multiple attempts for
                    the Skill Test ONLY at my personal will to do so and that
                    the Organisation SHALL NOT compel me to do so.
                  </li>
                </ul>
              </label>
            </div>
            <h6 className="pt-4">DATA COLLECTION PROTOCOLS :</h6>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault15">
                <ul>
                  <li>
                    I am aware and agree that I SHALL upload business-related
                    information required for the App from prospective customers
                    and create Profiles of theirs ONLY from my DCE account of{' '}
                    <span className="prochure">PROchure</span>{' '}
                    {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                    via the web app www.vipar-connect.in and SHALL NOT take
                    details via any other online / offline method.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault15">
                <ul>
                  <li>
                    I am aware and agree that I SHALL upload photographs of the
                    shop ONLY by following the below mentioned Photo Protocols :
                    <br />
                    <div className="pt-2">
                      a) Photograpgh MUST be a very clear one and NOT blurred.
                      <br />
                      b) Photograph MUST be a Still picture and NOT taken while
                      in motion.
                      <br />
                      c) Photograpgh MUST NOT be taken in darkness.
                      <br />
                      d) Photograpgh MUST be taken either in broad daylight or
                      with ample lighing (if clicked after sunset) for a clearly
                      visible picture.
                      <br />
                      e) Photograph MUST be suitable for 16:9 resolution.
                      <br />
                      f) Photograph MUST NOT be taken from an angle.
                      <br />
                      g) Photograpgh MUST be taken from exactly the front of the
                      Office / Shop / Showroom.
                      <br />
                      h) Photograpgh MUST NOT be taken with the shutters of the
                      Office / Shop / Showroom in closed position or partially
                      closed position.
                      <br />
                      i) Photograpgh MUST be taken with the shutters of the
                      Office / Shop / Showroom in fully open position.
                      <br />
                      j) Photograph MUST contain the Name Board of the Office /
                      Shop / Showroom.
                      <br />
                      k) Photograph MUST have the Name Board captured such that
                      the board is very clearly visible and readable.
                      <br />
                      l) Photograpgh MUST focus on the entire front façade of
                      the Office / Shop / Showroom and not only on the name
                      board.
                      <br />
                      m) Photograpgh MUST NOT contain any other object in the
                      background or in and around the Office / Shop / Showroom.
                      <br />
                      n) Photograph MUST be taken without any obstruction of
                      Humans, Animals and/or Objects like vehicles, electric
                      poles, wires and cables, etc. covering the name board and
                      front façade of the Office / Shop / Showroom.
                      <br />
                      o) Photograpgh MUST be taken from a relatively appropriate
                      close distance of the Office / Shop / Showroom and NOT
                      from far distance.
                    </div>
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault3">
                <ul>
                  <li>
                    I am aware and agree that the data (of business profiles)
                    that I have collected SHALL be considered INVALID and SHALL
                    NOT be taken into account of my 1-day Skill Test IF I DO NOT
                    follow all or even any one of the above mentioned Photo
                    Protocols.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault3">
                <ul>
                  <li>
                    I am aware and agree that the data (of business profiles)
                    that I have collected SHALL be considered VALID and SHALL be
                    taken into account of my daily / monthly targets ONLY IF the
                    details of information / data (like the
                    Category/Sub-category, Profile Display Picture, Profile
                    Display Name, Country, State, City, Locality, Pin code and
                    Contact Number) collected matches correctly with the
                    respective business without a single error.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault2">
                <ul>
                  <li>
                    I am aware and agree that the onus of using a device of good
                    quality inorder to capture pictures/photographs as per the
                    Photo Protocols mentioned above is entirely on me and that
                    the inability of using a device of good quality SHALL NOT be
                    used as an excuse for my failure to pass the Skill Test.
                  </li>
                </ul>
              </label>
            </div>

            <h5 className="mt-4 ">
              TERMS and CONDITIONS AFTER PASSING the SKILL TEST *
            </h5>
            <h6 className="pt-4">CONSENT :</h6>

            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault1">
                <ul>
                  <li>
                    I am aware and agree that this Contractual Agreement is
                    valid only for a period of 1 (ONE) Year and SHALL be renewed
                    every year based on the mutual understanding between myself
                    and{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup>.
                  </li>
                </ul>
              </label>
            </div>

            <h6 className="pt-4">DATA COLLECTION PROTOCOLS :</h6>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault15">
                <ul>
                  <li>
                    I am aware and agree that I SHALL upload business-related
                    information required for the App from prospective customers
                    and create Profiles of theirs ONLY from my DCE account of{' '}
                    <span className="prochure">PROchure</span>{' '}
                    {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                    via the web app www.vipar-connect.in and SHALL NOT take
                    details via any other online / offline method.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault15">
                <ul>
                  <li>
                    I am aware and agree that I SHALL upload photographs of the
                    shop ONLY by following the below mentioned Photo Protocols :
                    <br />
                    <div className="pt-2">
                      a) Photograpgh MUST be a very clear one and NOT blurred.
                      <br />
                      b) Photograph MUST be a Still picture and NOT taken while
                      in motion.
                      <br />
                      c) Photograpgh MUST NOT be taken in darkness.
                      <br />
                      d) Photograpgh MUST be taken either in broad daylight or
                      with ample lighing (if clicked after sunset) for a clearly
                      visible picture.
                      <br />
                      e) Photograph MUST be suitable for 16:9 resolution.
                      <br />
                      f) Photograph MUST NOT be taken from an angle.
                      <br />
                      g) Photograpgh MUST be taken from exactly the front of the
                      Office / Shop / Showroom.
                      <br />
                      h) Photograpgh MUST NOT be taken with the shutters of the
                      Office / Shop / Showroom in closed position or partially
                      closed position.
                      <br />
                      i) Photograpgh MUST be taken with the shutters of the
                      Office / Shop / Showroom in fully open position.
                      <br />
                      j) Photograph MUST contain the Name Board of the Office /
                      Shop / Showroom.
                      <br />
                      k) Photograph MUST have the Name Board captured such that
                      the board is very clearly visible and readable.
                      <br />
                      l) Photograpgh MUST focus on the entire front façade of
                      the Office / Shop / Showroom and not only on the name
                      board.
                      <br />
                      m) Photograpgh MUST NOT contain any other object in the
                      background or in and around the Office / Shop / Showroom.
                      <br />
                      n) Photograph MUST be taken without any obstruction of
                      Humans, Animals and/or Objects like vehicles, electric
                      poles, wires and cables, etc. covering the name board and
                      front façade of the Office / Shop / Showroom.
                      <br />
                      o) Photograpgh MUST be taken from a relatively appropriate
                      close distance of the Office / Shop / Showroom and NOT
                      from far distance.
                    </div>
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault3">
                <ul>
                  <li>
                    I am aware and agree that the data (of business profiles)
                    that I have collected SHALL be considered INVALID and SHALL
                    NOT be taken into account of my daily / monthly targets IF I
                    DO NOT follow all or even any one of the above mentioned
                    Photo Protocols.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault3">
                <ul>
                  <li>
                    I am aware and agree that the data (of business profiles)
                    that I have collected SHALL be considered VALID and SHALL be
                    taken into account of my daily / monthly targets ONLY IF the
                    details of information / data (like the Category,
                    Sub-category, Profile Display Picture, Profile Display Name,
                    Country, State, City, Locality, Pin code and Contact Number)
                    collected matches correctly with the respective business
                    without a single error.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault3">
                <ul>
                  <li>
                    I am aware and agree that the onus of using a device of good
                    quality inorder to capture pictures/photographs as per the
                    Photo Protocols mentioned above is entirely on me and that
                    the inability of using a device of good quality SHALL NOT be
                    used as an excuse for my failure to achieve my daily /
                    monthly targets in the pre-requisite manner.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault2">
                <ul>
                  <li>
                    I am aware and agree that I SHALL clearly explain to the
                    owner / representative of an Office / Shop / Showroom (IF
                    asked about my actions) what this data (including the
                    photograpgh) is being collected for and also show them the
                    App available for their viewing on Google Play Store and on
                    the web app www.prochure.in.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault2">
                <ul>
                  <li>
                    I am aware and agree that I SHALL introduce myself to each
                    and every customer with my unique{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup> Employee ID card (issued to me by the
                    Organisation) and will co-operate with the respective
                    customer to scan the QR code on my ID card to Authenticate
                    my employment with{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup>.
                  </li>
                </ul>
              </label>
            </div>

            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault2">
                <ul>
                  <li>
                    I am aware and agree that I SHALL read, understand and
                    explain the features, benefits, fee structure and membership
                    subscription details of the app to each and every customer
                    with clarity.
                  </li>
                </ul>
              </label>
            </div>

            <h6 className="pt-4">PROFESSIONAL CONDUCT / ETHICS :</h6>

            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault3">
                <ul>
                  <li>
                    I am aware and agree that I SHALL NOT make any false claims
                    / false promises to the potential subscribers regarding the
                    features and benefits of using the platform or mislead them
                    with incorrect or wrong information regarding the app.
                  </li>
                </ul>
              </label>
            </div>

            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault15">
                <ul>
                  <li>
                    I am aware and agree that I SHALL NOT say anything to the
                    potential subscribers at my own discretion beyond what has
                    been instructed to me by{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup> and that I SHALL maintain absolute
                    professional decorum as a representative of{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup> during my course of interaction with
                    prospective customers.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault15">
                <ul>
                  <li>
                    I am aware and agree that I SHALL strictly abide by the
                    rules and regulations of the Organisation, and all the Terms
                    and Conditions mentioned here and that I SHALL NOT indulge
                    in any activity that will directly or indirectly spoil the
                    reputation of the digital Platform ({' '}
                    <span className="prochure">PROchure</span>{' '}
                    {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}) and the
                    Organisation ({' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup> ).
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault15">
                <ul>
                  <li>
                    I am aware and agree that I SHALL NOT lose my temper or get
                    into any kind of physical or verbal fights with a customer
                    even if it is a mistake on the part of my customer and SHALL
                    politely relieve myself from the customer’s place in any
                    such untoward scenario.
                  </li>
                </ul>
              </label>
            </div>
            <div class="pt-3">
              <label class="form-check-label" for="flexCheckDefault15">
                <ul>
                  <li>
                    I am aware and agree that I SHALL NOT post any content on
                    any social media platform or any other digital platform
                    against <span className="prochure">PROchure</span> or{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup> with the intention to tarnish the
                    reputation and goodwill of both{' '}
                    <span className="prochure">PROchure</span> and{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup>, purely out of personal grudge and
                    without substantiating evidence.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault15">
                <ul>
                  <li>
                    I am aware and agree that IF I flout any or all of the above
                    mentioned clauses in this section, then I SHALL be
                    Terminated with immediate effect.
                  </li>
                </ul>
              </label>
            </div>
            <h6 className="pt-4">FINANCIAL PROTOCOLS :</h6>

            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault2">
                <ul>
                  <li>
                    I am aware and agree that I SHALL NOT collect any amount of
                    money as Subscription Fee, from any individual who has
                    expressed his/her willingness to create a business listing /
                    profile on the platform, either in the form of cash / cheque
                    / DD / net transfer such as IMPS, NEFT, RTGS / QR code
                    scanning or UPI payment method to any external account
                    including my own account.
                  </li>
                </ul>
              </label>
            </div>
            <div class="pt-3">
              <label class="form-check-label" for="flexCheckDefault2">
                <ul>
                  <li>
                    I am aware and agree that IF I flout any or both of the
                    above mentioned clauses in this section, then I SHALL be
                    Terminated with immediate effect.
                  </li>
                </ul>
              </label>
            </div>
            <h6 className="pt-4">TARGETS and REMUNERATION :</h6>

            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault5">
                <ul>
                  <li>
                    I am aware and agree that the term ‘Remuneration’ in this
                    Agreement SHALL refer ONLY to the Fixed monthly Salary
                    (including Allowances) for achieving my daily / monthly
                    targets.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault5">
                <ul>
                  <li>
                    I am aware and agree that my average targets for each day /
                    month SHALL ONLY be as per what is mentioned in the slab
                    below.
                  </li>
                </ul>
              </label>
            </div>

            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault5">
                <ul>
                  <li>
                    I am aware and agree that my Fixed monthly Salary (including
                    Allowances) for achieving my daily / monthly target (as per
                    what is mentioned in the slab below) SHALL be Rs 25,000/-
                    (Rupees TWENTY FIVE THOUSAND only).
                  </li>
                </ul>
              </label>
              <div className="table-responsive pt-3">
                <table class="table table-sm align-middle table-bordered">
                  <thead>
                    <tr className="text-center">
                      <th
                        className=" justify-content-center align-items-start"
                        scope="col"
                      >
                        DAILY TARGET
                      </th>
                      <th
                        className="d-flex justify-content-center align-items-start"
                        scope="col"
                      >
                        MONTHLY TARGET
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td>50</td>
                      <td>1250</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault4">
                <ul>
                  <li>
                    I am aware and agree that I SHALL achieve both my daily and
                    monthly targets set by{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup>.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault8">
                <ul>
                  <li>
                    I am aware and agree that it is ONLY via my Unique Referral
                    Code (which I have created on this registration form) that{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup> analyses and tracks my daily / monthly
                    targets.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault8">
                <ul>
                  <li>
                    I am aware and agree that I SHALL NOT be eligibile to get
                    any reimbursement on the conveyance or phone call expenses
                    that I incur as a part of my efforts to collect data at{' '}
                    <span className="prochure">PROchure</span>.
                  </li>
                </ul>
              </label>
            </div>
            <div class="pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                <ul>
                  <li>
                    I am aware and agree that I SHALL NOT be eligible for any
                    additional fixed daily, weekly or monthly payments beyond
                    what is mutually agreed upon in this Agreement between
                    myself and{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup>.
                  </li>
                </ul>
              </label>
            </div>

            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                <ul>
                  <li>
                    I am aware and agree that my Remuneration SHALL be paid to
                    me ONLY on achieving the pre-requisite targets set by{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup> for that respective month.
                  </li>
                </ul>
              </label>
            </div>

            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                <ul>
                  <li>
                    I am aware and agree that my Remuneration for a month SHALL
                    be entirely based on the total number of Profile Data
                    collected in that respective month.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                <ul>
                  <li>
                    I am aware and agree that my Remuneration SHALL be subject
                    to proportionate deductions, as per the discretion of{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup> for not being able to achieve my
                    pre-requisite monthly targets.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                <ul>
                  <li>
                    I am aware and agree that I SHALL NOT be eligible for any
                    Remuneration in a month IF I do not achieve any of my
                    pre-requisite targets in that respective month which means
                    “NO Remuneration for NIL Targets”.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                <ul>
                  <li>
                    I am aware and agree that the Profile Data collected by me
                    SHALL be thoroughly verified by the Organisation and IF it
                    is found that there has been Duplication / Repetition of
                    Profiles; that there is Mismatch in Profile Information;
                    that there is Mistake / Error in the Contact Numbers
                    collected, then in such scenarios, the Organisation SHALL
                    deduct Rs 20/- (Rupees TWENTY only) per incorrect data from
                    my Remuneration.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                <ul>
                  <li>
                    I am aware and agree that my Remuneration SHALL be settled
                    in full only on the 10 th day of the following month.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                <ul>
                  <li>
                    I am aware and agree that I SHALL be notified in writing 1
                    (ONE) Month prior to any increase or decrease in my daily /
                    monthly targets and/or my Remuneration.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                <ul>
                  <li>
                    I am aware and agree that in case I would like to request
                    for any increase in my Remuneration for data collection,
                    then I SHALL submit a written request via mail to only the
                    official marketing email id of{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup> , which is
                    vipar.marketing.hr@gmail.com from my registered email id
                    with Subject Title as ‘
                    <span className="prochure">PROchure</span> DCE (Salary)
                    REMUNERATION’ and clearly mentioning my Unique Referral
                    Code, and that the Acceptance and Implementation of my
                    request is at the sole discretion of{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup>.
                  </li>
                </ul>
              </label>
            </div>

            <h6 className="pt-4">RESIGNATION :</h6>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                <ul>
                  <li>
                    I am aware and agree that while resigning from the job, I
                    SHALL send an email regarding my resignation to only the
                    official marketing email id of{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup>, which is vipar.marketing.hr@gmail.com
                    from my registered email id with Subject Title as ‘{' '}
                    <span className="prochure">PROchure</span> DCE (Salary)
                    RESIGNATION’ and clearly mentioning my Unique Referral Code.
                    Else my resignation SHALL NOT be acceptable.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault19">
                <ul>
                  <li>
                    I am aware and agree that I can resign from the Organisation
                    without any notice period However I SHALL be eligible for my
                    Remuneration Only for those many number of Targets I have
                    successfully achieved in that respective month.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault20">
                <ul>
                  <li>
                    I am aware and agree that both my Remuneration Certificate
                    and Experience Certificate SHALL be issued to me ONLY IF I
                    complete a minimum of 6 (SIX) Months of work duration with{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      &nbsp;Vipar
                    </span>{' '}
                    <sup class="">®</sup> as a DCE.
                  </li>
                </ul>
              </label>
            </div>
            <h6 className="pt-4">RELIEVING FROM JOB :</h6>
            <div class=" pt-3">
              <label class="form-check-label">
                <ul>
                  <li>
                    I am aware and agree that IF the Organisation finds me inept
                    for this Job or in a scenario where the Organisation decides
                    to put an end to the data collection for the app, then the
                    Organisation SHALL have the authority to relieve me from
                    this Job by giving me a Notice period of 1 (ONE) Month in
                    writing via mail to my registered email id.
                  </li>
                </ul>
              </label>
            </div>
            <h6 className="pt-4">DE-REGISTRATION :</h6>
            <div class=" pt-3">
              <label class="form-check-label">
                <ul>
                  <li>
                    I am aware and agree that 12 (TWELVE) Consecutive days of
                    ABSENCE from Work in a month without achieving my
                    pre-requisite monthly targets would immediately and
                    automatically lead to the de-registration of my DCE account
                    without any further intimation or notification unless I
                    submit a written valid substantiating reason for absence /
                    non-performance, within a maximum duration of 24 hours after
                    this 12-day period.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label">
                <ul>
                  <li>
                    I am aware and agree that I SHALL be Terminated with
                    immediate effect IF I do not respond to the Organisation
                    even within this granted time of 24 hours.
                  </li>
                </ul>
              </label>
            </div>
            <h6 className="pt-4">NON-DISCLOSURE :</h6>

            <div class=" pt-3">
              <label class="form-check-label">
                <ul>
                  <li>
                    I am aware and agree that for as long as I am employed with{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      &nbsp;Vipar
                    </span>{' '}
                    <sup class="">®</sup>, I SHALL NOT disclose any of the
                    marketing strategies or internal confidential matters of the
                    Organisation to any 3<sup>rd</sup> party, be it an
                    individual or company, and that IF I do so, I SHALL be
                    penalised for the same.
                  </li>
                </ul>
              </label>
            </div>
            <h6 className="pt-4">LEGAL BINDING :</h6>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault15">
                <ul>
                  <li>
                    I am aware and agree that I SHALL be Terminated without any
                    intimation or notification IF the Organisation detects any
                    suspicious activity or unethical practice on my part during
                    my course of data collection for the app.
                  </li>
                </ul>
              </label>
            </div>

            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                <ul>
                  <li>
                    I am aware and agree that it SHALL be me and only me who
                    will be fully and solely responsible for my bad and
                    irresponsible behaviour with a customer and only I SHALL be
                    held liable for the same legally and NOT any other employee
                    / representative of the Organisation or the Head of the
                    Organisation.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                <ul>
                  <li>
                    I am aware and agree that I SHALL be Terminated IF found to
                    be involved in any kind of fraudulent activity which
                    directly or indirectly is an act against the interests of
                    either a subscriber of{' '}
                    <span className="prochure">PROchure</span> or the
                    Organisation or both.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                <ul>
                  <li>
                    I am aware and agree that in case of Termination of my
                    employment contract with{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup> for any reason, then I SHALL NOT be
                    eligible for my pending Remuneration if any, and that I
                    SHALL be penalised and legally sued for monetary damages at
                    the discretion of{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup>.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                <ul>
                  <li>
                    I am aware and agree that any lapse(s) occuring from my end
                    regarding ALL or ANY of the above mentioned mutually agreed
                    upon Terms and Conditions SHALL solely be my responsibility
                    and for which{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup> SHALL NOT be held responsible or
                    liable, both legally and otherwise.
                  </li>
                </ul>
              </label>
            </div>
            <div class=" pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                <ul>
                  <li>
                    I am aware and agree that these Terms and the relationship
                    between myself and{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup> SHALL be governed by the laws of
                    Karnataka, India without regard to its conflict of law
                    provisions.{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup> and I agree that all disputes SHALL be
                    subject to arbitration at Benngaluru, Karnataka in
                    accordance with the Arbitration and Conciliation Act, 1996.
                    The arbitration proceedings SHALL be conducted in the
                    English language. That{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup> and I hereby expressly and irrevocably
                    attorn to the jurisdiction of the courts of Bengaluru with
                    respect to any matter or claim, suit, action or proceeding
                    arising under or related to this Agreement. I covenant NOT
                    to sue{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup> in any other forum.
                  </li>
                </ul>
              </label>
            </div>
            <div>
              <h6 className="mt-4">TO BE NOTED :</h6>

              <ul className="ps-5 mt-2">
                <li className="pt-2">
                  You can View the Accepted Terms and Conditions from your DCE
                  account.
                </li>
                <li className="pt-2">
                  You can Download, Print a copy of the Accepted Terms and
                  Conditions for future reference.
                </li>

                <li className="pt-2">
                  Your REMUNERATION CERTIFICATE and EXPERIENCE CERTIFICATE will
                  be issued to you upon resignation (and upon completion of
                  internship for students).
                </li>

                <li className="pt-2">
                  {' '}
                  For any grievances, send us an email regarding the issue to
                  vipar.marketing.hr@gmail.com from your registered email id
                  with Subject Title as ‘
                  <span className="prochure">PROchure</span> DCE (Salary)
                  GRIEVANCE’ and clearly mentioning your Unique Referral Code.
                </li>
              </ul>

              <h6 className="mt-4">REFERRAL CODE :</h6>
              <ul className="ps-5 mt-2">
                <li className="pt-2" style={{ lineSpacing: '0' }}>
                  During Registration, Create a Referral Code of your choice
                  which will be your DCE Identification at{' '}
                  <span
                    className="vipar"
                    style={{
                      fontSize: '22px',
                      color: '#99d050',
                    }}
                  >
                    {' '}
                    Vipar
                  </span>{' '}
                  <sup class="">®</sup>
                </li>

                <li className="pt-2">
                  It is Mandatory for tracking your data collection targets and
                  disbursing your Remuneration to you.
                </li>
                <li className="pt-2">
                  Once a Referral Code is created, it is permanent and cannot be
                  changed.
                </li>

                <li className="pt-2">
                  Can be a combination of Alphabets, Numbers and Symbols.
                </li>
                <li className="pt-2">
                  Minimum Character length : 5<br />
                  Maximum Character length : 10
                </li>
                <li className="pt-2">
                  In case you forget your Referral Code, it will be visible to
                  you on the ‘Account Details’ page of your profile.
                </li>
              </ul>
            </div>
          </>
        ) : (
          ''
        )}

        <hr />
        {/*  */}

        <div className=" pt-3">
          <div className="row">
            <div className="col-md-12 mt-2">
              <div className="d-flex justify-content-between">
                <h5 className="mt-2 d-flex align-items-center ">
                  UNIQUE REFERRAL CODE
                  *&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <IoInformationCircleOutline
                    size={18}
                    onClick={handleShowreferral}
                  />
                </h5>
              </div>
              <div className="labels">
                ( Click on the Information icon to clearly understand what
                Referral Code is. )
              </div>
              <input
                placeholder="Create Unique Referral Code of your Choice "
                disabled={Edit?.length > 0 ? true : false}
                className="form-control"
                required
                type={'text'}
                value={value.Referral_Code}
                onChange={(e) => {
                  setValue({ ...value, Referral_Code: e.target.value });
                }}
                minLength={5}
                maxLength={10}
                onBlur={(e) => {
                  if (e.target.value.length > 3)
                    checkReferral_Code(e.target.value);
                }}
              ></input>
              <div class="invalid-feedback">
                PLEASE ENTER Referral Code ( minimum of 5 characters )
              </div>
            </div>
            <h5 className="mt-4  ">PERSONAL DETAILS *</h5>

            <div className="col-12  col-md-3 mnmnmnm mmmmm ">
              <Imageupload onChange={(e) => setimgi(e)} id={value.id} />
              <span className="mt-3 " style={{ paddingLeft: '15px' }}>
                Upload your PASSPORT size Photo *
              </span>
            </div>

            <div className="col-12 col-md-9">
              <div className="row mt-3">
                <div className="col-md-12">
                  <label className="labels">Full Name *</label>
                  <input
                    placeholder="Enter Full Name"
                    className="form-control"
                    disabled={Edit?.length > 0 ? true : false}
                    required
                    type={'text'}
                    value={value.Name}
                    onChange={(e) => {
                      setValue({ ...value, Name: e.target.value });
                    }}
                  ></input>
                  <div class="invalid-feedback">PLEASE ENTER Full Name</div>
                </div>
                <div className="col-md-12">
                  <label className="labels">Gender *</label>
                  <select
                    class="form-control form-select"
                    required
                    disabled={Edit?.length > 0 ? true : false}
                    value={value.Gender}
                    onChange={(e) => {
                      setValue({ ...value, Gender: e.target.value });
                    }}
                  >
                    <option></option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Transgender">Transgender</option>
                  </select>
                  <div class="invalid-feedback">PLEASE SELECT Gender</div>
                </div>
                <div className="col-md-12 ">
                  <label className="labels">Age *</label>
                  <div>
                    <div className="age w-100">
                      <input
                        placeholder="Enter Age"
                        disabled={Edit?.length > 0 ? true : false}
                        className="form-control  "
                        type={'text'}
                        maxLength={2}
                        required
                        value={value.Age}
                        onChange={(e) => {
                          // if (
                          //   e.target.value.length <= 2 &&
                          //   /^\d*$/.test(e.target.value)
                          // )

                          if (/^\d*$/.test(e.target.value))
                            setValue({ ...value, Age: e.target.value });
                        }}
                      ></input>
                    </div>
                  </div>
                  <div class="invalid-feedback">PLEASE ENTER Age</div>
                </div>
                <div className="col-md-12">
                  <label className="labels">Nationality *</label>
                  <select
                    class="form-control form-select"
                    required
                    disabled={Edit?.length > 0 ? true : false}
                    value={value.Nationality}
                    onChange={(e) => {
                      setValue({ ...value, Nationality: e.target.value });
                    }}
                  >
                    <option></option>
                    <option value="Indian">Indian</option>
                    <option value="American">American</option>
                    <option value="British">British</option>
                    <option value="German">German</option>
                    <option value="Italian">Italian</option>
                  </select>
                  <div class="invalid-feedback">PLEASE SELECT Nationality</div>
                </div>
                <div className="col-md-12">
                  <label className="labels">Blood Group *</label>
                  <input
                    className="form-control"
                    disabled={Edit?.length > 0 ? true : false}
                    required
                    type={'text'}
                    value={value.BloodGroup}
                    onChange={(e) => {
                      setValue({ ...value, BloodGroup: e.target.value });
                    }}
                  ></input>
                  <div class="invalid-feedback">PLEASE ENTER Blood Group</div>
                </div>
                <StateCity setValue={setValue} value={value} Edit={Edit} />
                {/* [{country:value.country,state:value.state,city:value.city}] */}
                <div className="col-md-12 mt-2 ">
                  <label className="labels">Address *</label>

                  <textarea
                    placeholder="Enter  Full  Address"
                    className="form-control  "
                    required
                    value={value.Address}
                    onChange={(e) => {
                      setValue({ ...value, Address: e.target.value });
                    }}
                  ></textarea>

                  <div class="invalid-feedback">PLEASE ENTER Address</div>
                </div>
                <div className="col-md-12 mt-2">
                  <label className="labels">Pin Code *</label>

                  <input
                    placeholder="Enter Pin Code"
                    className="form-control  "
                    type={'text'}
                    maxLength={6}
                    required
                    value={value.Pincode}
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value))
                        setValue({ ...value, Pincode: e.target.value });
                    }}
                  ></input>

                  <div class="invalid-feedback">PLEASE ENTER Pin Code</div>
                </div>
                <div className="col-md-12">
                  <label className="labels">Email Id *</label>
                  <input
                    placeholder="Enter an existing Email Id  "
                    disabled={Edit?.length > 0 ? true : false}
                    className="form-control "
                    type={'email'}
                    required
                    value={value.Email}
                    onChange={(e) => {
                      setValue({ ...value, Email: e.target.value });
                    }}
                  ></input>
                  <div class="invalid-feedback">PLEASE ENTER Email Id</div>
                </div>

                <div className="col-md-12">
                  <label className="labels">Contact Number *</label>
                  <input
                    placeholder="Enter Anytime Available Contact Number  "
                    required
                    className="form-control "
                    maxLength={10}
                    type={'text'}
                    value={value.Contact}
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value))
                        setValue({ ...value, Contact: e.target.value });
                    }}
                  ></input>
                  <div class="invalid-feedback">PLEASE ENTER Contact</div>
                </div>
              </div>
              {/* <h5 className="mt-4">IDENTITY PROOF *</h5>
              <div className="row mt-2">
                <div className="col-md-12">
                  <label className="labels">Upload your Aadhaar Card *</label>
                  <input
                    className="form-control"
                    type="file"
                    id="formFile"
                    required
                  />
                  <div class="invalid-feedback">PLEASE UPLOAD Aadhaar Card</div>
                </div>
              </div> */}

              <h5 className="mt-4">IDENTITY PROOF *</h5>
              <div className="row mt-2">
                <label className="labels">
                  Upload both front and back sides of your Aadhaar Card
                </label>
                <div className="col-md-12">
                  <div></div>
                  <input
                    hidden
                    ref={btnchange}
                    className="form-control"
                    type="file"
                    id="formFile"
                    accept="image/*"
                    required
                    onChange={(e) => setadar([...adar, ...e.target.files])}
                  />
                  <button
                    id="btnchange"
                    className="btn pro-spaces-button mt-3 mx-2"
                    style={{ backgroundColor: 'rgb(1 32 96)' }}
                    accept="image/*"
                    onClick={(e) => btnchange.current.click()}
                  >
                    Upload your Aadhaar Card *
                  </button>

                  <div className="ps-3 pt-3">
                    <div className="row g-3">
                      {adar?.map((ele, index) => {
                        console.log(ele);
                        return (
                          <div className="col-12 col-md-6 col-lg-4">
                            <IoIosCloseCircleOutline
                              className="d-flex float-end"
                              onClick={(e) => fun(index)}
                            />
                            <img
                              src={URL.createObjectURL(ele)}
                              style={{
                                width: '100%',
                                height: '200px',
                                objectFit: 'cover',
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div class="invalid-feedback">PLEASE UPLOAD Aadhaar Card</div>
                </div>
              </div>
              {value?.Studentornot == 'Student' ? (
                <>
                  <h5 className="mt-4">EDUCATIONAL DETAILS *</h5>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <label className="labels">
                        Pursuing Educational Course *
                      </label>

                      <textarea
                        placeholder="Enter Course Details relevant to your Internship program"
                        className="form-control  "
                        required
                        value={value.ED_Pursuing_Education}
                        onChange={(e) => {
                          setValue({
                            ...value,
                            ED_Pursuing_Education: e.target.value,
                          });
                        }}
                      ></textarea>

                      <div class="invalid-feedback">
                        PLEASE ENTER Pursuing Educational Course
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="labels">College *</label>

                      <input
                        className="form-control  "
                        required
                        value={value.ED_College}
                        onChange={(e) => {
                          setValue({
                            ...value,
                            ED_College: e.target.value,
                          });
                        }}
                      ></input>

                      <div class="invalid-feedback">PLEASE ENTER COLLEGE</div>
                    </div>

                    <div className="col-md-12">
                      <label className="labels">University *</label>

                      <input
                        className="form-control  "
                        required
                        value={value.ED_University}
                        onChange={(e) => {
                          setValue({
                            ...value,
                            ED_University: e.target.value,
                          });
                        }}
                      ></input>

                      <div class="invalid-feedback">
                        PLEASE ENTER University
                      </div>
                    </div>

                    <div className="col-md-12">
                      <label className="labels">City *</label>

                      <input
                        placeholder=""
                        className="form-control  "
                        required
                        value={value.ED_City}
                        onChange={(e) => {
                          setValue({
                            ...value,
                            ED_City: e.target.value,
                          });
                        }}
                      />

                      <div class="invalid-feedback">PLEASE ENTER City</div>
                    </div>
                    <div className="col-md-12">
                      <label className="labels">Period of Internship *</label>
                      <div className="row">
                        <div className="col-5">
                          <input
                            placeholder=""
                            type="date"
                            className="form-control  "
                            required
                            value={value.ED_Period_of_Internship_Start}
                            onChange={(e) => {
                              setValue({
                                ...value,
                                ED_Period_of_Internship_Start: e.target.value,
                              });
                            }}
                          />
                          <div class="invalid-feedback">
                            PLEASE ENTER Period of Internship
                          </div>
                        </div>
                        <div className="col-2 d-flex justify-content-center align-items-end">
                          <span className="">to</span>
                        </div>
                        <div className="col-5">
                          <input
                            placeholder=""
                            type="date"
                            className="form-control  "
                            required
                            value={value.ED_Period_of_Internship_End}
                            onChange={(e) => {
                              setValue({
                                ...value,
                                ED_Period_of_Internship_End: e.target.value,
                              });
                            }}
                          />
                          <div class="invalid-feedback">
                            PLEASE ENTER Period of Internship
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {value?.Studentornot == 'NoStudent' ? (
                <>
                  <h5 className="mt-4">QUALIFICATION and WORK EXPERIENCE *</h5>
                  <div className="row mt-2">
                    <div className="col-md-12">
                      <label className="labels">
                        Highest Educational Qualification *
                      </label>

                      <textarea
                        className="form-control  "
                        required
                        value={value.Highest_Educational_Qualification}
                        onChange={(e) => {
                          setValue({
                            ...value,
                            Highest_Educational_Qualification: e.target.value,
                          });
                        }}
                      ></textarea>

                      <div class="invalid-feedback">
                        PLEASE ENTER Highest Educational Qualification
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="labels">Work Experience *</label>

                      <textarea
                        placeholder="Type NIL if Fresher"
                        className="form-control  "
                        required
                        value={value.Work_Experience}
                        onChange={(e) => {
                          setValue({
                            ...value,
                            Work_Experience: e.target.value,
                          });
                        }}
                      ></textarea>

                      <div class="invalid-feedback">
                        PLEASE ENTER Work Experience
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="labels">
                        Ready to Join on anyday after *
                      </label>

                      <input
                        type="date"
                        className="form-control"
                        required
                        // disabled={Edit?.length > 0 ? true : false}
                        value={value.Ready_to_Join_on_anyday_after?.slice(
                          0,
                          10
                        )}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setValue({
                            ...value,
                            Ready_to_Join_on_anyday_after: e.target.value,
                          });
                        }}
                      ></input>

                      <div class="invalid-feedback">
                        PLEASE ENTER Ready to Join on anyday after
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <h5 className="mt-4">BANK ACCOUNT DETAILS *</h5>

              <div className="row mt-2">
                <div className="col-md-12">
                  <label className="labels">Account Holder Name *</label>
                  <input
                    type="text"
                    placeholder="Enter Full Name as per bank account "
                    required
                    className="form-control"
                    maxLength="150"
                    value={value.Account_holder_name}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        Account_holder_name: e.target.value,
                      })
                    }
                  />
                  <div class="invalid-feedback">
                    PLEASE ENTER Bank account holder name
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="labels">Bank Name *</label>
                  <input
                    type="text"
                    placeholder="Enter Bank Name "
                    required
                    className="form-control"
                    maxLength="40"
                    value={value.Bank_name}
                    onChange={(e) =>
                      setValue({ ...value, Bank_name: e.target.value })
                    }
                  />
                  <div class="invalid-feedback">PLEASE ENTER Bank name</div>
                </div>
                <div className="col-md-12">
                  <label className="labels">Bank Account Type *</label>

                  <select
                    class="form-control form-select"
                    required
                    value={value.Bank_account_type}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        Bank_account_type: e.target.value,
                      })
                    }
                  >
                    <option></option>
                    <option value="SB">SB</option>
                    <option value="Current">Current</option>
                  </select>
                  <div class="invalid-feedback">
                    PLEASE SELECT Bank account type
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="labels">Account Number *</label>
                  <input
                    type="number"
                    placeholder="Enter Account Number "
                    required
                    className="form-control"
                    maxLength="100"
                    value={value.Account_number}
                    onChange={(e) =>
                      setValue({ ...value, Account_number: e.target.value })
                    }
                  />
                  <div class="invalid-feedback">
                    PLEASE ENTER Account number
                  </div>
                </div>

                <div className="col-md-12">
                  <label className="labels">IFSC code *</label>
                  <input
                    type="text"
                    required
                    placeholder="Enter IFSC code "
                    className="form-control"
                    maxLength="40"
                    value={value.Ifsc_code}
                    onChange={(e) =>
                      setValue({ ...value, Ifsc_code: e.target.value })
                    }
                  />
                  <div class="invalid-feedback">PLEASE ENTER IFSC code</div>
                </div>

                {!Edit?.length > 0 ? (
                  <>
                    <div>
                      <div class=" pt-3">
                        <label class="form-check-label" for="flexCheckDefault7">
                          <ul>
                            <li>
                              I am aware and agree that the bank account details
                              I have mentioned is correct and wish to have my
                              Remuneration credited to this account.
                            </li>
                          </ul>
                        </label>
                      </div>
                    </div>
                    <div>
                      <div class=" pt-3">
                        <label class="form-check-label" for="flexCheckDefault9">
                          <ul>
                            <li>
                              I am aware and agree that any mistake / error in
                              the bank account details entered by me SHALL NOT
                              be the responsibility of the Organisation.
                            </li>
                          </ul>
                        </label>
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
                <h5 className="mt-4">
                  CONTACT INFORMATION of a FAMILY MEMBER in case of an Emergency
                  *
                </h5>
                <div className="col-md-12 mt-2">
                  <label className="labels">Full Name of Family Member *</label>
                  <input
                    className="form-control"
                    required
                    placeholder="Enter Full Name"
                    type={'text'}
                    value={value.FMName}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        FMName: e.target.value,
                      })
                    }
                  ></input>
                  <div class="invalid-feedback">
                    PLEASE ENTER Full Name of Family Member
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="labels">
                    Relationship with Family Member *
                  </label>

                  <select
                    class="form-control form-select"
                    required
                    id="exampleFormControlSelect1"
                    value={value.FMRelationship}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        FMRelationship: e.target.value,
                      })
                    }
                  >
                    <option></option>
                    <option value="Father">Father</option>
                    <option value="Mother">Mother</option>
                    <option value="Brother">Brother</option>
                    <option value="Sister">Sister</option>
                    <option value="Husband">Husband</option>
                    <option value="Wife">Wife</option>
                    <option value="Son">Son</option>
                    <option value="Daughter">Daughter</option>
                  </select>
                  <div class="invalid-feedback">
                    PLEASE SELECT Relationship with Family Member
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="labels">
                    Contact Number of Family Member *
                  </label>
                  <input
                    placeholder="  "
                    maxLength={10}
                    required
                    className="form-control "
                    type={'text'}
                    value={value.FMContact}
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value))
                        setValue({ ...value, FMContact: e.target.value });
                    }}
                  ></input>
                  <div class="invalid-feedback">
                    PLEASE ENTER Contact Number of Family Member
                  </div>
                </div>
                <div className="col-md-12 mt-2 ">
                  <label className="labels">Address of Family Member *</label>

                  <textarea
                    placeholder="Enter Full Address"
                    className="form-control  "
                    required
                    value={value.FMAddress}
                    onChange={(e) => {
                      setValue({ ...value, FMAddress: e.target.value });
                    }}
                  ></textarea>

                  <div class="invalid-feedback">PLEASE ENTER Address</div>
                </div>

                <Statecity2 setValue={setvalueee} value={valueee} Edit={Edit} />
                <div className="col-md-12 mt-2">
                  <label className="labels">Pin Code *</label>

                  <input
                    placeholder="Enter Pin Code"
                    className="form-control  "
                    type={'text'}
                    maxLength={6}
                    required
                    value={value.FMPincode}
                    onChange={(e) => {
                      if (/^\d*$/.test(e.target.value))
                        setValue({ ...value, FMPincode: e.target.value });
                    }}
                  ></input>
                  <div class="invalid-feedback">PLEASE ENTER Pin Code</div>
                </div>
                <h5 className="mt-4">LOGIN CREDENTIALS *</h5>
                <div className="col-md-12 mt-2">
                  <label className="labels">
                    Login Id <br></br>( Your Email Id is your Default
                    Login Id. )
                  </label>
                  <input
                    disabled
                    className="form-control"
                    type={'text'}
                    value={value.Email}
                  ></input>
                </div>

                {!Edit?.length > 0 ? (
                  <>
                    <div className="col-md-12">
                      <label className="labels">Password *</label>
                      <div>
                        <input
                          class="form-control passInout12321"
                          placeholder="Enter Password"
                          aria-describedby="basic-addon2"
                          type={showPassword ? 'text' : 'password'}
                          value={value.Password}
                          onChange={(e) =>
                            setValue({ ...value, Password: e.target.value })
                          }
                          required
                        />
                        &nbsp;
                        <span class="showbutton123321">
                          {showPassword ? (
                            <RiEyeFill
                              onClick={(e) => {
                                setShowPassword(!showPassword);
                              }}
                            />
                          ) : (
                            <RiEyeOffFill
                              onClick={(e) => {
                                setShowPassword(!showPassword);
                              }}
                            />
                          )}
                        </span>
                        <div class="invalid-feedback ">
                          PLEASE ENTER Password
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <label className="labels">Confirm Password *</label>
                      <div>
                        <div>
                          <input
                            class="form-control passInout12321"
                            placeholder="Enter Password again"
                            type={showPassword1 ? 'text' : 'password'}
                            value={value.ConformPassword}
                            onChange={(e) =>
                              setValue({
                                ...value,
                                ConformPassword: e.target.value,
                              })
                            }
                            required
                          />

                          <span class="showbutton123321" type="button">
                            {' '}
                            {showPassword1 ? (
                              <RiEyeFill
                                onClick={(e) => {
                                  setShowPassword1(!showPassword1);
                                }}
                              />
                            ) : (
                              <RiEyeOffFill
                                onClick={(e) => {
                                  setShowPassword1(!showPassword1);
                                }}
                              />
                            )}
                          </span>
                        </div>
                        <div class="invalid-feedback ">
                          PLEASE ENTER Confirm Password
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}

                {!Edit?.length > 0 ? (
                  <>
                    <div class="form-check pt-3 px-5">
                      <input
                        class="form-check-input affcheckbox"
                        type="checkbox"
                        id="flexCheckDefault1"
                        defaultChecked={TermsAndCondition.Terms1}
                        onChange={(e) => setbtn(!btn)}
                        onClick={(e) => {
                          setTermsAndCondition({
                            ...TermsAndCondition,
                            Terms1: !TermsAndCondition.Terms1,
                          });
                        }}
                      />
                      <label class="form-check-label" for="flexCheckDefault9">
                        I have read and understood each and every clause
                        mentioned above and agree to comply with all the Terms
                        and Conditions upon registration.
                      </label>
                    </div>
                  </>
                ) : (
                  ''
                )}
                {error.length ? (
                  <div className="col-12 ">
                    <p className="py-1 m-0" style={{ color: 'red' }}>
                      {error ? error : ''}
                    </p>
                  </div>
                ) : (
                  ''
                )}

                <div className="col-12 px-1 py-3 d-flex justify-content-center ">
                  {ButtonDisabled ? (
                    <button className="btn pro-spaces-button3" disabled={!btn}>
                      {Edit?.length > 0 ? 'UPDATE' : 'REGISTER'}
                    </button>
                  ) : (
                    !loading && (
                      <button
                        disabled={!btn}
                        className="btn pro-spaces-button3"
                        onClick={(e) => validator1(e)}
                      >
                        {Edit?.length > 0 ? 'UPDATE' : 'REGISTER'}
                      </button>
                    )
                  )}
                  {/* {loading && (
                  <button className="btn pro-spaces-button3" 
                  disabled={!btn}
                  >
                    {Edit?.length > 0 ? "UPDATE" : "REGISTER"}
                    <span
                      hidden
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                )} */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
      </form>
      {/* referralcode modal */}

      <Modal show={showreferral} onHide={handleClosereferral}>
        <Modal.Header centered closeButton></Modal.Header>
        <Modal.Body>
          <div className="footlightfont">
            <div className="pt-3" style={{ lineSpacing: '0' }}>
              • During Registration, Create a Referral Code of your choice which
              will be your DCE Identification at{' '}
              <span
                className="vipar"
                style={{
                  fontSize: '22px',
                  color: '#99d050',
                }}
              >
                {' '}
                Vipar
              </span>{' '}
              <sup class="">®</sup>
            </div>

            <div className="pt-3">
              • It is Mandatory for tracking your data collection targets and
              disbursing your Remuneration to you.
            </div>
            <div className="pt-3">
              • Once a Referral Code is created, it is permanent and cannot be
              changed.
            </div>

            <div className="pt-3">
              • Can be a combination of Alphabets, Numbers and Symbols.
            </div>
            <div className="pt-3">
              • Minimum Character length &#8239;: 5<br />
              &nbsp;&nbsp;Maximum Character length : 10
            </div>
            <div className="pt-3">
              • In case you forget your Referral Code, it will be visible to you
              on the ‘Account Details’ page of your profile.
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* referralcode modal */}
    </div>
  );
};
