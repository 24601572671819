import axios from 'axios';
import React, { useState } from 'react';
import { notify } from '../../../Utility/notify';

export const Clerical2registration = () => {
  var [value, setvalue] = useState({
    Name: '',
    Email: '',
    Password: '',
    ConfirmPassword: '',
  });
  const [Submit2, setSubmit2] = useState(false);

  const submit = (e) => {
    e.preventDefault();
    axios
      .post('/api/clericaladmin2/clericaladmin2insert', value)
      .then((req) => {
        console.log('asds');
        notify('Registered Clerical Admin Successfully', true);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        notify('Something Went Wrong', false);
      });
  };
  return (
    <>
      <form
        onSubmit={(e) => submit(e)}
        className={Submit2 ? 'was-validated' : ''}
      >
        <div className="d-flex justify-content-center pt-5 ">
          <div
            className="card"
            style={{
              width: '350px',
              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            }}
          >
            <div class="px-2 pt-2">
              <label className="labels form-label">NAME * </label>
              <input
                required
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={(e) => setvalue({ ...value, Name: e.target.value })}
              />
              <div
                className="invalid-feedback"
                style={{ backgroundColor: 'white' }}
              >
                PLEASE ENTER YOUR NAME
              </div>
            </div>
            <div class="px-2 ">
              <label className="labels form-label">EMAIL * </label>
              <input
                type="email"
                required
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={(e) => setvalue({ ...value, Email: e.target.value })}
              />
              <div
                className="invalid-feedback"
                style={{ backgroundColor: 'white' }}
              >
                PLEASE ENTER YOUR EMAIL
              </div>
            </div>
            <div class="px-2 ">
              <label className="labels form-label">PASSWORD * </label>
              <input
                required
                type="password"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={(e) =>
                  setvalue({ ...value, Password: e.target.value })
                }
              />
              <div
                className="invalid-feedback"
                style={{ backgroundColor: 'white' }}
              >
                PLEASE ENTER YOUR PASSWORD
              </div>
            </div>
            <div class="px-2 ">
              <label className="labels form-label">CONFIRM PASSWORD * </label>
              <input
                required
                type="password"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                onChange={(e) =>
                  setvalue({ ...value, ConfirmPassword: e.target.value })
                }
              />
              <div
                className="invalid-feedback"
                style={{ backgroundColor: 'white' }}
              >
                PLEASE ENTER YOUR CONFIRM PASSWORD
              </div>
            </div>
            <div className="py-3 d-flex justify-content-center">
              <button
                className="btn pro-spaces-button3"
                onClick={(e) => {
                  setSubmit2(true);
                }}
              >
                Register
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
