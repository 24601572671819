import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const AdminselectionFSE = () => {
  const [data, setdata] = useState("")
  useEffect(() => {
    axios.post("/api/regionalmanager/regionalgetvalueforfse").then((req)=>{
      console.log("req")
      console.log(req.data)
      setdata(req.data)
    }).catch((err)=>{
      console.log(err)
    })
  

  }, [])
  
  useEffect(() => {
    
  console.log(data)
  }, [data])
  
  return (
    <div>
      <div>
        <div className="container ">
          <div className="row slhishieo3jfiehfjefbewf">
            <>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <Link to={`/fse_registration?k=${data.Referral_Code}`} className="wgfiuwrgiu">
                  <div className="card carrrrbus">
                    <div className="padunnor">
                      <center>
                        Registration     
                        <br></br> {/* {Counts.Active ? Counts.Active : '0'} */}
                      </center>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <Link to={`/fse_memberview?k=${data.Referral_Code}`} className="wgfiuwrgiu">
                  <div className="card carrrrbus">
                    <div className="padunnor">
                      <center>
                        Members View
                        <br></br>{' '}
                        {/* {Counts.Activev2 ? Counts.Activev2 : '0'} */}
                      </center>
                    </div>
                  </div>
                </Link>
              </div>
            </>
            {/* :""}
             */}
          </div>
        </div>
      </div>
    </div>
  );
};
