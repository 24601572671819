import React, { useState, useEffect, useReducer, createContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./CreateConseltent.css";
import axios from "axios";
import { useNavigate, useSearchParams } from 'react-router-dom'
import { CreateConseltentA } from "../CreateConseltent/forms/CreateConseltentA";

import { CreateConseltentC } from "../CreateConseltent/forms/CreateConseltentC";

import { CreateConseltentE } from "../CreateConseltent/forms/CreateConseltentE";
// import {CreateConseltentE as DirectlinkDoc} from "../FormForDoctor/forms/CreateConseltentE"
import Select from 'react-select';

export const CatsContext = createContext();
export const ConvertContext = createContext()
export const EditContext = createContext()


export function FormConsultent(props) {
    let [searchParams, setSearchParams] = useSearchParams();  /////getting Account id
    var Id = searchParams.get("k")
    useEffect(() => {
        axios.get('/api/api/getcategory').then(res => {
            if (res.status === 200) {
                setCat(res.data)
            }
        }).catch((err) => {
            if (err) {
                navigate('/')
            }
            console.log(err.response.status);
        })




    }, []);


    const reducer = (state, action) => {
        if (props.freeEdit === 'freeEdit') {
            switch (action.type) {
                case "1":
                    return <CreateConseltentA postTo={props.postTo} basicprofile={true} portfo={true} formType={1} />;
                case "2":
                    return <CreateConseltentA postTo={props.postTo} basicprofile={true} formType={2} />;
                case "3":
                    return <CreateConseltentC postTo={props.postTo} basicprofile={true} portfo={true} formType={3} />;
                case "4":
                    return <CreateConseltentC postTo={props.postTo} basicprofile={true} formType={4} />;
                case "5":
                    return <CreateConseltentE postTo={props.postTo} basicprofile={true} formType={5} />;
                // throw new Error();
            }
        }
        else {
            switch (action.type) {
                case "1":
                    return <CreateConseltentA postTo={props.postTo} basicprofile={true} portfo={true} formType={1} />;
                case "2":
                    return <CreateConseltentA postTo={props.postTo} basicprofile={true} formType={2} />;
                case "3":
                    return <CreateConseltentC postTo={props.postTo} basicprofile={true} portfo={true} formType={3} />;
                case "4":
                    return <CreateConseltentC postTo={props.postTo} basicprofile={true} formType={4} />;
                case "5":
                    return <CreateConseltentE postTo={props.postTo} basicprofile={true} formType={5} />;
                // throw new Error();
            }
        }

    };
    let navigate = useNavigate()

    const [value, setValue] = useState({
        category: "",
        subcategory: "",
        formType: ''

    });
    const [cat, setCat] = useState([])
    const [category, setCategory] = useState(false)
    const [subcategory, setSubcategory] = useState(false)
    const [brand, setBrand] = useState([])
    const [subcat, setsubcat] = useState([])

    const [state, dispatch] = useReducer(reducer, <></>);
    const sabari = (e) => {
        var anadhan
        setValue({ ...value, category: e })
        cat.forEach(element => {
            if (element._id === e) {
                setsubcat(element.sub)
                anadhan = element.sub.map((elements) => {
                    return <option formtype={elements.formType} value={elements._id}>{elements.sub}</option>
                })
            } else { }
        });
        setSubcategory(anadhan)
    }
    console.log()
    const [Acc, setAcc] = useState({
        currentCat: localStorage.getItem("Cat"),
        status: 'waiting',
    })
    useEffect(() => {
        axios
            .get("/api/ActiveUser/accountswitch")
            .then((res) => {
                console.log(res.data);
                if (res.status === 200) {
                    if (props.edit ? res.data.length > 1 : res.data.length >= 1) {
                        setAcc({ ...Acc, status: true })
                        setValue({ ...value, category: Acc.currentCat })

                    } else {
                        setAcc({ ...Acc, status: false })
                    }
                }
            })
            .catch((res) => {
                console.log(res)
            });
    }, []);
    var sab = []
    useEffect(() => {
        if (cat.length >= 1) {
            cat.forEach(element => {
                sab.push(<option value={element._id}>{element.category}</option>)
            });
            setCategory(sab)
            if (Acc.status) {
                let anadhan = []
                console.log(cat);
                cat.forEach(element => {
                    if (element._id === Acc.currentCat) {
                        console.log('object');
                        anadhan = element.sub.map((elements) => {
                            return <option formtype={elements.formType} value={elements._id}>{elements.sub}</option>
                        })
                    } else {
                        console.log(Acc.currentCat, element._id);
                    }
                });
                setSubcategory(anadhan)
            }
        } else {
        }
    }, [cat])
    const formLoader = () => {
        let e = document.getElementById('subcatananda83389')
        let ft = e.options[e.selectedIndex].getAttribute('formtype')
        dispatch({ type: ft })
        setValue({ ...value, subcategory: e.value, formType: ft })
        cat.find(elem => {
            if (elem._id === value.category) {
                elem.sub.find(element => {
                    if (element._id == e.value) {
                        setBrand(element.brand)
                    }
                })
            }
        })
    }
    const [data, setdata] = useState()
    let accountids = '/api/ActiveUser/ConProfile'
    if (props.freeEdit === 'freeEdit') {
        accountids = '/api/Free/ConProfileEdit' // for free editing while creating profile (in profile preview before payment <new feature>)
    } else {
        accountids = '/api/ActiveUser/ConProfile' // normal profile editing after createing profile
    }
    useEffect(() => {
        if (props.edit) {
            console.log(accountids)
            axios.post(accountids, { Id }).then((res) => {
                console.log(res.data)
                setValue({
                    ...value,
                    category: res.data[0].category,
                    subcategory: res.data[0].subcategory,
                    // formType: res.data[0].formType
                    Brand: res.data[0].Brand,
                    Id: Id
                })
                let e = document.getElementById('subcatananda83389')
                let anadhan
                let ft
                cat.forEach(element => {
                    if (element._id === res.data[0].category) {
                        anadhan = element.sub.map((elements) => {
                            if (elements._id === res.data[0].subcategory) {
                                ft = elements.formType
                            } else {
                                // console.log(elements._id,res.data[0].subcategory)
                            }
                            return <option formtype={elements.formType} value={elements._id}>{elements.sub}</option>
                        })
                    }
                });
                setSubcategory(anadhan)
                if (ft) {
                    ft = ft.toString()
                    dispatch({ type: ft })
                }
                setdata(res.data)
                cat.find(elem => {
                    if (elem._id === value.category) {
                        elem.sub.find(element => {
                            if (element._id == e.value) {
                                setBrand(element.brand)
                            }
                        })
                    }
                })
            }).catch((err) => {
                console.log(err)
            })
        }
    }, [category])


    // -------------input catagory box start------------------

    const [Options, setOptions] = useState([])
    useEffect(() => {
        axios
            .get('/api/api/getcategory')
            .then(res => {
                let op = res.data?.map((element, index) => {
                    return element.sub.map((elm, ind) => {
                        let value = elm.sub.toString()
                        if (elm.keyword?.length) {
                            var sabari = elm.keyword.map((keywordsmap) => keywordsmap.label)
                            value = value + '' + sabari.toString()
                        }
                        let brand
                        if (elm.brand?.length) {
                            brand = true
                        } else {
                            brand = false
                        }
                        return {
                            value,
                            label: `${elm.sub} [ ${element.category} ]`,
                            id: elm._id,
                            categoryName: element.category,
                            brand,
                            type: element.type,
                            catid: element._id,
                            ft: elm.formType
                        }
                    })
                })
                op = op.flat(1)
                setOptions(op)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const redmik20pro = id => {
        let catid = id.catid
        let subcatid = id.id
        let anadhan
        let ft = id.ft
        cat.forEach(element => {
            if (element._id === catid) {
                setsubcat(element.sub)
                anadhan = element.sub.map((elements) => {
                    if (elements._id === subcatid) {
                        ft = elements.formType
                    } else {
                        // console.log(elements._id,res.data[0].subcategory)
                    }
                    return (
                        <option formtype={elements.formType} value={elements._id}>
                            {elements.sub}
                        </option>
                    )
                })
            }
        });
        setSubcategory(anadhan)
        setValue((rev) => ({ ...rev, category: catid, subcategory: subcatid, formType: ft }))
        console.log(ft)
        dispatch({ type: ft.toString() })
    }

    const [selectedOption, setSelectedOption] = useState(null)
    const handleChange = selectedOption => {
        redmik20pro(selectedOption)
    }

    const handle = (e) => {
        if (e.target.value.length > 2) {
            let elem = document.querySelector(':root')
            elem.style.setProperty('--display', 'block  ');
        } else {
            let elem = document.querySelector(':root')
            elem.style.setProperty('--display', 'none  ');
        }
    }

    useEffect(() => {
        try {
            rt()

            let element = document.getElementsByClassName('inputselectcat')
            element[0].children[2].children[0].children[1].children[0].addEventListener('input', handle)
            element[0].children[2].children[1].classList.add('d-none')

        } catch (error) {
            console.log(error)
        }
        return () => {

            try {
                let element = document.getElementsByClassName('inputselectcat')
                element[0].children[2].children[0].children[1].children[0].removeEventListener('input', handle)
            } catch (err) {

            }
        }
    }, [])

    const rt = () => {
        let elem = document.querySelector(':root')
        elem.style.setProperty('--display', 'none ');
    }

    //  input catagory box end------------------

    return <div className="container rounded bg-white pt-5 pb-5">
        <center className='pt-4'><h5>Profile Creation Form</h5></center>
        <center><p>' * ' are all mandatory fields</p></center>
        <div className="row">
            {/* {!props.edit ? <>
                <div className="col-12">
                    <div className='mb-2 '>
                        <Select
                            value={selectedOption}
                            className="inputselectcat"
                            placeholder='Type and Search for your Category'
                            onChange={e => handleChange(e)}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused ? '#ced4da' : '#ced4da',
                                }),
                            }}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#ced4da',

                                    primary: '#ced4da',
                                },
                            })}
                            options={Options}
                        />
                    </div>
                </div>
                <center><h6>OR</h6></center>
            </> : <></>} */}
            <h6 className="mt-1">Select your appropriate Category and Sub Category</h6>
            <div className="col-md-12">
                <label className="labels" >Category *</label>
                <select
                  
                    className="form-select"
                    value={value.category}
                    onChange={(e) => sabari(e.target.value)}
                >
                    <option value="" disabled selected></option>
                    {category ? category : ""}
                </select>
            </div>
            <div className="col-md-12">
                <label className="labels">Sub Category *</label>

                <select
                    id={'subcatananda83389'}
                    className="form-select"
                    value={value.subcategory}
                    onChange={(e) =>
                        formLoader(e)
                    }
                >
                    <option></option> {subcategory ? subcategory : ""}
                </select>
                {/* {value.subcategory} */}

            </div>
        </div>
        {brand.length ? (
            <>
                <div className='col-md-12'>
                    <label className='labels'>Brand *</label>
                    <select value={value.Brand} className='form-select' onChange={e => setValue({ ...value, Brand: e.target.value })}
                    >
                        <option></option>
                        {brand.map(elem => {
                            return <option value={elem._id}>{elem.brand}</option>
                        })}
                    </select>
                </div>
            </>
        ) : (
            <></>
        )}
        <EditContext.Provider value={data}>
            <CatsContext.Provider value={{ value, brand: brand, subcat }} >
                <ConvertContext.Provider value={props.sab}>
                    {state}
                </ConvertContext.Provider>
            </CatsContext.Provider>
        </EditContext.Provider>

    </div>
}




