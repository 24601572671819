import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { StaticGoogleMap, Marker } from "react-static-google-map";
import { MdMyLocation } from "react-icons/md";

export const BussinessInformationVendorMedical_Store = ({ getval }) => {
  const {
    address_building,
    address_nearest,
    address_street,
    worktimimng,
    country,
    state,
    city,
    pincode,
    Location,
    Location_Coordinates,
    address_door,
    address,
    address_floor,
    licence,
    gst,
    website,
    working_hour_1,
    working_hour_2,
    working_hour_3,
    working_hour_4,
    workingdaysfullnew,
    workingdayshalfnew,
    workingdaysOffnew,
    addressBranch,
    hospitalname,
    localityh,
    break_time_1,
    break_time_2,
    brandName,
    who,
    TypeofStore,
    yearofb,
    EntityName,
    brandtagline,
  } = { ...getval };
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const linktovebsite = (website) => {
    if (website.indexOf("http") > -1) {
      window.open(website, "_blank");
    } else {
      window.open("http://" + website, "_blank");
    }
  };
  return (
    <Accordion
      expanded={expanded === "bussinessInformationvendor"}
      onChange={handleChange("bussinessInformationvendor")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel4bh-content"
        id="panel4bh-header"
      >
        <Typography sx={{ width: "100%", flexShrink: 0 }}>
          <b> Business Information</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {EntityName ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Entity Name</span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)" }}>
                  {EntityName ? EntityName : ""}
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}
          {brandName ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Brand Name</span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)" }}>
                  {brandName ? brandName : ""}
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}
          {brandtagline ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Entity / Brand Tag line</span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)" }}>
                  {brandtagline ? brandtagline : ""}
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}

          {address_floor ? (
            <div className="row py-2">
              <div className="col-md-12">
                <b>Address</b>
              </div>
            </div>
          ) : (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Address</span>
              </div>
              <div className="col-md-6">
                <label style={{ color: "var(--blue)" }}>{address}</label>
              </div>
            </div>
          )}
          {address_building ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Building / Mall / Property Name</span>
              </div>
              <div className="col-md-6">
                <label style={{ color: "var(--blue)" }}>
                  {address_building}
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}
          {address_door ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Door / Shop No.</span>
              </div>
              <div className="col-md-6">
                <label style={{ color: "var(--blue)" }}>{address_door}</label>
              </div>
            </div>
          ) : (
            <></>
          )}
          {address_floor ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Floor</span>
              </div>
              <div className="col-md-6">
                <label style={{ color: "var(--blue)" }}>{address_floor}</label>
              </div>
            </div>
          ) : (
            <></>
          )}
          {address_street ? (
            <>
              <div className="row py-2">
                <div className="col-md-6">
                  <span>Street / Lane / Road Name</span>
                </div>
                <div className="col-md-6">
                  <label style={{ color: "var(--blue)" }}>
                    {address_street}
                  </label>
                </div>
              </div>
              <div className="row py-2">
                <div className="col-md-6">
                  <span>Area / Locality Name</span>
                </div>
                <div className="col-md-6">
                  <label style={{ color: "var(--blue)" }}>{address}</label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {address_nearest ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Nearest Landmark</span>
              </div>
              <div className="col-md-6">
                <label style={{ color: "var(--blue)" }}>
                  {address_nearest}
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}
          {state ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>City, State / Province, Country</span>
              </div>
              <div className="col-md-6">
                <label style={{ color: "var(--blue)" }}>
                  {city}, {state}, {country}
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}
          {localityh ? (
            <>
              <div className="row py-2">
                <div className="col-md-6">
                  <span>Locality</span>
                </div>
                <div className="col-md-6 ">
                  <label style={{ color: "var(--blue)" }}>
                    {localityh ? localityh : ""}
                  </label>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {pincode ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Pin code</span>
              </div>
              <div className="col-md-6">
                <label style={{ color: "var(--blue)" }}>{pincode}</label>
              </div>
            </div>
          ) : (
            <></>
          )}
          {Location ? (
            <Typography>
              <p>GPS Location</p>
              <div className="container">
                <a
                  target="_blank"
                  href={`http://maps.google.com/maps?z=18&q=${Location_Coordinates.lat},${Location_Coordinates.lng}`}
                >
                  <StaticGoogleMap
                    zoom="15"
                    size="600x400"
                    className="img-fluid"
                    apiKey={process.env.REACT_APP_MAP_KEY}
                  >
                    <Marker
                      location={`${Location_Coordinates.lat},${Location_Coordinates.lng}`}
                      color="blue"
                      label="P"
                    />
                  </StaticGoogleMap>
                </a>
              </div>
            </Typography>
          ) : (
            <></>
          )}

          {who ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Type of Vendor / Business</span>
              </div>
              <div className="col-md-6 address">
                <label style={{ color: "var(--blue)" }}>
                  {" "}
                  {who
                    ? who == "Both"
                      ? "Wholesalers and Retailers"
                      : who
                    : ""}
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}
          {TypeofStore ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Type of Entity</span>
              </div>
              <div className="col-md-6 address">
                <label style={{ color: "var(--blue)" }}>
                  {" "}
                  {TypeofStore
                    ? TypeofStore == "Both"
                      ? "Physical Store and E-commerce"
                      : TypeofStore == "Physical"
                      ? "Physical Store"
                      : "E-commerce"
                    : ""}
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}
          {yearofb ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Year of Business Inception</span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)" }}>
                  {yearofb ? yearofb : ""}
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}
          {licence ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Have Trade Licence</span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)" }}>
                  {licence ? licence : ""}
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}
          {gst ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>GST registered</span>
              </div>
              <div className="col-md-6 ">
                <label style={{ color: "var(--blue)" }}>{gst ? gst : ""}</label>
              </div>
            </div>
          ) : (
            <></>
          )}

          {website ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Website</span>
              </div>
              <div className="col-md-6 ">
                <a href="#" onClick={(e) => linktovebsite(website)}>
                  {website ? website : ""}
                </a>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="row py-2">
            <div className="col-md-12">
              <b>Work Timings</b>
            </div>
          </div>
          {worktimimng ? (
            <div className="row py-2">
              <div className="col-md-12">
                <label style={{ color: "var(--blue)" }}>
                  We are open 24 hours
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}
          <>
            {workingdaysfullnew?.length ? (
              <div className="row py-2">
                <div className="col-md-6">
                  <span>Working Days (full)</span>
                </div>
                <div className="col-md-6 address">
                  <label style={{ color: "var(--blue)" }}>
                    {workingdaysfullnew
                      ? workingdaysfullnew.map((element, index) => {
                          if (index == workingdaysfullnew?.length - 1) {
                            return element;
                          } else {
                            return element + ", ";
                          }
                        })
                      : ""}
                  </label>
                </div>
              </div>
            ) : (
              <></>
            )}
            {working_hour_1?.length ? (
              <div className="row py-2">
                <div className="col-md-6">
                  <span>Working Hours</span>
                </div>
                <div className="col-md-6 address">
                  <label style={{ color: "var(--blue)" }}>
                    {working_hour_1 ? working_hour_1 + " to " : ""}{" "}
                    {working_hour_2 ? working_hour_2 : ""}{" "}
                  </label>
                </div>
              </div>
            ) : (
              <></>
            )}
            {break_time_1?.length ? (
              <div className="row py-2">
                <div className="col-md-6">
                  <span>Break Time</span>
                </div>
                <div className="col-md-6 address">
                  <label style={{ color: "var(--blue)" }}>
                    {break_time_1 ? break_time_1 + " to " : ""}{" "}
                    {break_time_2 ? break_time_2 : ""}{" "}
                  </label>
                </div>
              </div>
            ) : (
              <></>
            )}
            {workingdayshalfnew?.length ? (
              <>
                <div className="row py-2">
                  <div className="col-md-6">
                    <span>Working Days (half)</span>
                  </div>
                  <div className="col-md-6 address">
                    <label style={{ color: "var(--blue)" }}>
                      {workingdayshalfnew
                        ? workingdayshalfnew.map((element, index) => {
                            if (index == workingdayshalfnew?.length - 1) {
                              return element;
                            } else {
                              return element + ", ";
                            }
                          })
                        : ""}
                    </label>
                  </div>
                </div>
                {working_hour_3 != "Nil" ? (
                  <div className="row py-2">
                    <div className="col-md-6">
                      <span>Working Hours</span>
                    </div>
                    <div className="col-md-6 address">
                      <label style={{ color: "var(--blue)" }}>
                        {working_hour_3 ? working_hour_3 + " to " : ""}{" "}
                        {working_hour_4 ? working_hour_4 : ""}{" "}
                      </label>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </>
          {/* {worktimimng ? (
            <>
            {workingdaysfullnew ? (
              <div className="row py-2">
                <div className="col-md-6">
                  <span>Working Days (full)</span>
                </div>
                <div className="col-md-6 address">
                  <label style={{ color: "var(--blue)" }}>
                    {workingdaysfullnew
                      ? workingdaysfullnew.map((element, index) => {
                          if (index == workingdaysfullnew?.length - 1) {
                            return element;
                          } else {
                            return element + ", ";
                          }
                        })
                      : ""}
                  </label>
                </div>
              </div>
            ) : (
              <></>
            )}
            {working_hour_1 ? (
              <div className="row py-2">
                <div className="col-md-6">
                  <span>Working Hours</span>
                </div>
                <div className="col-md-6 address">
                  <label style={{ color: "var(--blue)" }}>
                    {working_hour_1 ? working_hour_1 + " to " : ""}{" "}
                    {working_hour_2 ? working_hour_2 : ""}{" "}
                  </label>
                </div>
              </div>
            ) : (
              <></>
            )}
            {break_time_1?.length ? (
              <div className="row py-2">
                <div className="col-md-6">
                  <span>Break Time</span>
                </div>
                <div className="col-md-6 address">
                  <label style={{ color: "var(--blue)" }}>
                    {break_time_1 ? break_time_1 + " to " : ""}{" "}
                    {break_time_2 ? break_time_2 : ""}{" "}
                  </label>
                </div>
              </div>
            ) : (
              <></>
            )}
            {workingdayshalfnew ? (
              <>
                <div className="row py-2">
                  <div className="col-md-6">
                    <span>Working Days (half)</span>
                  </div>
                  <div className="col-md-6 address">
                    <label style={{ color: "var(--blue)" }}>
                      {workingdayshalfnew
                        ? workingdayshalfnew.map((element, index) => {
                            if (index == workingdayshalfnew?.length - 1) {
                              return element;
                            } else {
                              return element + ", ";
                            }
                          })
                        : ""}
                    </label>
                  </div>
                </div>
                {working_hour_3 != "Nil" ? (
                  <div className="row py-2">
                    <div className="col-md-6">
                      <span>Working Hours</span>
                    </div>
                    <div className="col-md-6 address">
                      <label style={{ color: "var(--blue)" }}>
                        {working_hour_3 ? working_hour_3 + " to " : ""}{" "}
                        {working_hour_4 ? working_hour_4 : ""}{" "}
                      </label>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </>
          ) : (
            <>
              {workingdaysfullnew ? (
                <div className="row py-2">
                  <div className="col-md-6">
                    <span>Working Days (full)</span>
                  </div>
                  <div className="col-md-6 address">
                    <label style={{ color: "var(--blue)" }}>
                      {workingdaysfullnew
                        ? workingdaysfullnew.map((element, index) => {
                            if (index == workingdaysfullnew?.length - 1) {
                              return element;
                            } else {
                              return element + ", ";
                            }
                          })
                        : ""}
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {working_hour_1 ? (
                <div className="row py-2">
                  <div className="col-md-6">
                    <span>Working Hours</span>
                  </div>
                  <div className="col-md-6 address">
                    <label style={{ color: "var(--blue)" }}>
                      {working_hour_1 ? working_hour_1 + " to " : ""}{" "}
                      {working_hour_2 ? working_hour_2 : ""}{" "}
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {break_time_1?.length ? (
                <div className="row py-2">
                  <div className="col-md-6">
                    <span>Break Time</span>
                  </div>
                  <div className="col-md-6 address">
                    <label style={{ color: "var(--blue)" }}>
                      {break_time_1 ? break_time_1 + " to " : ""}{" "}
                      {break_time_2 ? break_time_2 : ""}{" "}
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {workingdayshalfnew ? (
                <>
                  <div className="row py-2">
                    <div className="col-md-6">
                      <span>Working Days (half)</span>
                    </div>
                    <div className="col-md-6 address">
                      <label style={{ color: "var(--blue)" }}>
                        {workingdayshalfnew
                          ? workingdayshalfnew.map((element, index) => {
                              if (index == workingdayshalfnew?.length - 1) {
                                return element;
                              } else {
                                return element + ", ";
                              }
                            })
                          : ""}
                      </label>
                    </div>
                  </div>
                  {working_hour_3 != "Nil" ? (
                    <div className="row py-2">
                      <div className="col-md-6">
                        <span>Working Hours</span>
                      </div>
                      <div className="col-md-6 address">
                        <label style={{ color: "var(--blue)" }}>
                          {working_hour_3 ? working_hour_3 + " to " : ""}{" "}
                          {working_hour_4 ? working_hour_4 : ""}{" "}
                        </label>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          )} */}
          {workingdaysOffnew ? (
            <div className="row py-2">
              <div className="col-md-6">
                <span>Weekly Off</span>
              </div>
              <div className="col-md-6 address">
                <label style={{ color: "var(--blue)" }}>
                  {workingdaysOffnew
                    ? workingdaysOffnew.map((element, index) => {
                        if (index == workingdaysOffnew?.length - 1) {
                          return element;
                        } else {
                          return element + ", ";
                        }
                      })
                    : ""}
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}
          {addressBranch?.length > 0 ? (
            <div className="row py-2">
              <hr />
              <div className="col-md-6">
                <b>Branches</b>
              </div>
              <div className="col-md-6">
                {/* <label style={{'color':'var(--blue)'}}>{getval.pincode}</label> */}
              </div>
            </div>
          ) : (
            <></>
          )}
          {addressBranch ? (
            addressBranch.map((element) => {
              return (
                <>
                  <div className="row py-2">
                    <div className="col-md-12">
                      <b>Business Information</b>
                    </div>
                  </div>
                  {element.address_floor ? (
                    <div className="row py-2">
                      <div className="col-md-12">
                        <span>Address</span>
                      </div>
                    </div>
                  ) : (
                    <div className="row py-2">
                      <div className="col-md-6">
                        <span>Address</span>
                      </div>
                      <div className="col-md-6">
                        <label style={{ color: "var(--blue)" }}>
                          {element.address1}
                        </label>
                      </div>
                    </div>
                  )}
                  {element.address_building ? (
                    <div className="row py-2">
                      <div className="col-md-6">
                        <span>Building / Mall / Property Name</span>
                      </div>
                      <div className="col-md-6">
                        <label style={{ color: "var(--blue)" }}>
                          {element.address_building}
                        </label>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {element.address_door ? (
                    <div className="row py-2">
                      <div className="col-md-6">
                        <span>Door / Shop No.</span>
                      </div>
                      <div className="col-md-6">
                        <label style={{ color: "var(--blue)" }}>
                          {element.address_door}
                        </label>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {element.address_floor ? (
                    <div className="row py-2">
                      <div className="col-md-6">
                        <span>Floor</span>
                      </div>
                      <div className="col-md-6">
                        <label style={{ color: "var(--blue)" }}>
                          {element.address_floor}
                        </label>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {element.address_street ? (
                    <>
                      <div className="row py-2">
                        <div className="col-md-6">
                          <span>Street / Lane / Road Name</span>
                        </div>
                        <div className="col-md-6">
                          <label style={{ color: "var(--blue)" }}>
                            {element.address_street}
                          </label>
                        </div>
                      </div>
                      <div className="row py-2">
                        <div className="col-md-6">
                          <span>Area / Locality Name</span>
                        </div>
                        <div className="col-md-6">
                          <label style={{ color: "var(--blue)" }}>
                            {element.address1}
                          </label>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {element.address_nearest ? (
                    <div className="row py-2">
                      <div className="col-md-6">
                        <span>Nearest Landmark</span>
                      </div>
                      <div className="col-md-6">
                        <label style={{ color: "var(--blue)" }}>
                          {element.address_nearest}
                        </label>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {element.state ? (
                    <div className="row py-2">
                      <div className="col-md-6">
                        <span></span>City, State / Province, Country
                      </div>
                      <div className="col-md-6 ">
                        <label style={{ color: "var(--blue)" }}>
                          {" "}
                          {element.city ? element.city : ""},{" "}
                          {element.state ? element.state : ""},{" "}
                          {element.country ? element.country : ""}
                        </label>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {element.pincode ? (
                    <div className="row py-2">
                      <div className="col-md-6">
                        <span>Pin code</span>
                      </div>
                      <div className="col-md-6 ">
                        <label style={{ color: "var(--blue)" }}>
                          {element.pincode ? element.pincode : ""}
                        </label>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {element.Location.lat ? (
                    <div className="row py-2">
                      <div className="col-12">
                        <a
                          target="_blank"
                          className=""
                          href={`http://maps.google.com/maps?z=18&q=${element.Location.lat},${element.Location.lng}`}
                        >
                          GPS Location <MdMyLocation color="" size={20} />
                        </a>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {element.licence ? (
                    <div className="row py-2">
                      <div className="col-md-6">
                        <span>Have Trade Licence</span>
                      </div>
                      <div className="col-md-6 address">
                        <label style={{ color: "var(--blue)" }}>
                          {element.licence ? element.licence : "Nil"}
                        </label>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {element.gst ? (
                    <div className="row py-2">
                      <div className="col-md-6">
                        <span>GST registered</span>
                      </div>
                      <div className="col-md-6 address">
                        <label style={{ color: "var(--blue)" }}>
                          {element.gst ? element.gst : "Nil"}
                        </label>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {element.worktimimng ? (
                    <div className="row py-2">
                      <div className="col-md-12">
                        <label style={{ color: "var(--blue)" }}>
                          We are open 24 hours
                        </label>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {element.worktimimng ? (
                    <></>
                  ) : (
                    <>
                      {element.workingdaysfullnew ? (
                        <div className="row py-2">
                          <div className="col-md-6">
                            <span>Working Days (full)</span>
                          </div>
                          <div className="col-md-6 address">
                            <label style={{ color: "var(--blue)" }}>
                              {element.workingdaysfullnew
                                ? element.workingdaysfullnew.map(
                                    (elemdfent, inas) => {
                                      if (
                                        inas ==
                                        element.workingdaysfullnew?.length - 1
                                      ) {
                                        return elemdfent;
                                      } else {
                                        return elemdfent + ", ";
                                      }
                                    }
                                  )
                                : ""}
                            </label>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {element.working_hour_1 ? (
                        <div className="row py-2">
                          <div className="col-md-6">
                            <span>Working Hours</span>
                          </div>
                          <div className="col-md-6 address">
                            <label style={{ color: "var(--blue)" }}>
                              {element.working_hour_1
                                ? element.working_hour_1 + " to "
                                : ""}{" "}
                              {element.working_hour_2
                                ? element.working_hour_2
                                : ""}{" "}
                            </label>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {element.break_time_1?.length ? (
                        <div className="row py-2">
                          <div className="col-md-6">
                            <span>Break Time</span>
                          </div>
                          <div className="col-md-6 address">
                            <label style={{ color: "var(--blue)" }}>
                              {element.break_time_1
                                ? element.break_time_1 + " to "
                                : ""}{" "}
                              {element.break_time_2 ? element.break_time_2 : ""}{" "}
                            </label>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {element.workingdayshalfnew ? (
                        <>
                          <div className="row py-2">
                            <div className="col-md-6">
                              <span>Working Days (half)</span>
                            </div>
                            <div className="col-md-6 address">
                              <label style={{ color: "var(--blue)" }}>
                                {element.workingdayshalfnew
                                  ? element.workingdayshalfnew.map(
                                      (elementmklk, indjj) => {
                                        if (
                                          indjj ==
                                          element.workingdayshalfnew?.length - 1
                                        ) {
                                          return elementmklk;
                                        } else {
                                          return elementmklk + ", ";
                                        }
                                      }
                                    )
                                  : ""}
                              </label>
                            </div>
                          </div>
                          {element.working_hour_3 != "Nil" ? (
                            <div className="row py-2">
                              <div className="col-md-6">
                                <span>Working Hours</span>
                              </div>
                              <div className="col-md-6 address">
                                <label style={{ color: "var(--blue)" }}>
                                  {element.working_hour_3
                                    ? element.working_hour_3 + " to "
                                    : ""}{" "}
                                  {element.working_hour_4
                                    ? element.working_hour_4
                                    : ""}{" "}
                                </label>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  {element.workingdaysOffnew ? (
                    <div className="row py-2">
                      <div className="col-md-6">
                        <span>Weekly Off</span>
                      </div>
                      <div className="col-md-6 address">
                        <label style={{ color: "var(--blue)" }}>
                          {element.workingdaysOffnew
                            ? element.workingdaysOffnew.map(
                                (elementlnk, indknex) => {
                                  if (
                                    indknex ==
                                    element.workingdaysOffnew?.length - 1
                                  ) {
                                    return elementlnk;
                                  } else {
                                    return elementlnk + ", ";
                                  }
                                }
                              )
                            : ""}
                        </label>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="row py-2">
                    <div className="col-md-12">
                      <b>Contact Information</b>
                    </div>
                  </div>
                  {element.countrycode ? (
                    <div className="row py-2">
                      <div className="col-md-6">
                        <span>Country Code</span>
                      </div>
                      <div className="col-md-6">
                        <label style={{ color: "var(--blue)" }}>
                          {element.countrycode}
                        </label>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {element.statecode ? (
                    <div className="row py-2">
                      <div className="col-md-6">
                        <span>State Code</span>
                      </div>
                      <div className="col-md-6">
                        <label style={{ color: "var(--blue)" }}>
                          {element.statecode}
                        </label>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {element.CON ? (
                    element.CON.map((elment, ind) => {
                      return (
                        <>
                          {element?.CON?.length > 1 ? <hr /> : <></>}
                          {elment.contact2 ? (
                            <>
                              <div className="row py-2">
                                <div className="col-md-6">
                                  <span>Mobile Number / Landline Number</span>
                                </div>
                                <div className="col-md-6">
                                  <label style={{ color: "var(--blue)" }}>
                                    {elment.contact2}
                                  </label>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {elment.contactpersonsname2 ? (
                            <>
                              <div className="row py-2">
                                <div className="col-md-6">
                                  <span>Contact Person</span>
                                </div>
                                <div className="col-md-6">
                                  <label style={{ color: "var(--blue)" }}>
                                    {elment.contactpersonsname2}
                                  </label>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {elment.contactpersonsdesignation2 ? (
                            <>
                              <div className="row py-2">
                                <div className="col-md-6">
                                  <span>Contact Person’s Designation</span>
                                </div>
                                <div className="col-md-6">
                                  <label style={{ color: "var(--blue)" }}>
                                    {elment.contactpersonsdesignation2}
                                  </label>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {elment.preferredlanguage2 ? (
                            <>
                              <div className="row py-2">
                                <div className="col-md-6">
                                  <span>
                                    Most Comfortable / Preferred Language for
                                    Communication
                                  </span>
                                </div>
                                <div className="col-md-6">
                                  <label style={{ color: "var(--blue)" }}>
                                    {elment.preferredlanguage2}
                                  </label>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })
                  ) : (
                    <></>
                  )}

                  {element.email ? (
                    <div className="row py-2">
                      <div className="col-md-6">
                        <span>Email Id</span>
                      </div>
                      <div className="col-md-6">
                        <label style={{ color: "var(--blue)" }}>
                          {element.email}
                        </label>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {addressBranch?.length > 1 ? <hr /> : <></>}
                </>
              );
            })
          ) : (
            <></>
          )}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
