import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { notify } from '../../Utility/notify';

export const Smetermsandcondition = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submit = () => {
    axios
      .post('/api/AffiliatedAccount/smetermsandcondition')
      .then((res) => {
        console.log('ad');
        notify('Updated', true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [State, setState] = useState(false);
  useEffect(() => {
    axios
      .post('/api/AffiliatedAccount/studentfind', {})
      .then((res) => {
        console.log(res.data);
        setState(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <div className="container pt-4">
        <div className="d-flex justify-content-end">
          <button className="btn pro-spaces-button3" onClick={handleShow}>
            Update
          </button>
        </div>
      </div>
      <div className="container ">
        <h5 className="mt-4 text-center">
          <u>TERMS and CONDITIONS</u>
        </h5>
        <h6 className="pt-4">CONSENT :</h6>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault1">
            <ul>
              <li>
                I am aware and fully understand that{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup>®</sup> is the Organisation (based out of Bengaluru,
                Karnataka) which SHALL employ me as a Data Collection Executive,
                ONLY IF and WHEN I pass the Skill Test Program, and that{' '}
                <span className="prochure">PROchure</span>{' '}
                {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                is a digital web and mobile application developed by{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <span
                  className="verticals"
                  style={{ fontSize: '16px', color: 'black' }}
                >
                  CONNECT
                </span>
                , which is a unit of{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup>®</sup>.
              </li>
            </ul>
          </label>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault1">
            <ul>
              <li>
                I am ready to enrol myself as a Data Collection Executive at{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> to meet potential customers and explain to
                them about the digital platform called{' '}
                <span className="prochure">PROchure</span>{' '}
                {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                (an app accessible to users across the globe) which is primarily
                a digital directory of Services and Products.
              </li>
            </ul>
          </label>
        </div>
        {State?.Studentornot == 'Student' ? (
          <div class=" pt-3">
            <label class="form-check-label" for="flexCheckDefault9">
              <ul>
                <li>
                  I admit and agree that I am joining{' '}
                  <span
                    className="vipar"
                    style={{
                      color: 'green',
                      fontSize: '20px',
                      paddingRight: '5px',
                    }}
                  >
                    Vipar
                  </span>{' '}
                  <sup class="">®</sup> as a Marketing Intern with the knowledge
                  and consent of my family and the concerned authority of the
                  College / Institution where I am currently pursuing my
                  education, and that IF need arises, I SHALL get the required
                  consent letter from the College / Institution.
                </li>
              </ul>
            </label>
          </div>
        ) : (
          <></>
        )}

        <h5 className="mt-4 ">
          TERMS and CONDITIONS for the 1-DAY SKILL TEST *
        </h5>
        <h6 className="pt-4">CONSENT :</h6>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault1">
            <ul>
              <li>
                I am aware and fully understand that the Skill Test is solely to
                assess IF I am competent enough to do the job as a Data
                Collection Executive in the required manner efficiently and
                correctly.
              </li>
            </ul>
          </label>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault1">
            <ul>
              <li>
                I am aware and agree that the Skill Test is conducted for
                assessing my efficiency, accuracy and speed with which I can
                complete the task and target assigned to me for a single day.
              </li>
            </ul>
          </label>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault1">
            <ul>
              <li>
                I am aware and agree that each Skill Test SHALL be completed
                ONLY on a single day and the targets given to me is counted ONLY
                for a single day and that it cannot be carried forward to the
                following day or any other day.
              </li>
            </ul>
          </label>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault1">
            <ul>
              <li>
                I am aware and agree that I SHALL NOT be remunerated at all for
                the Skill Test and that I am willing to attempt this test in my
                vested interest to get the job as a Data Collection Executive at{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup>®</sup>.
              </li>
            </ul>
          </label>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault1">
            <ul>
              <li>
                I am aware and agree that I SHALL NOT be employed with{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup>®</sup> UNTIL that time I successfully complete and pass
                the Skill Test.
              </li>
            </ul>
          </label>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault1">
            <ul>
              <li>
                I am aware and agree that passing the Skill Test, based on the
                mutually agreed upon pre- requisite terms & conditions and other
                criteria, is at the sole discretion of{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup>.
              </li>
            </ul>
          </label>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault1">
            <ul>
              <li>
                I am aware and agree that I am eligible to attempt the Skill
                Test as many times I wish to UNTIL I pass the test successfully
                and that I SHALL NOT be remunerated at all for any of my
                attempts.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault1">
            <ul>
              <li>
                I am aware and agree that I SHALL make multiple attempts for the
                Skill Test ONLY at my personal will to do so and that the
                Organisation SHALL NOT compel me to do so.
              </li>
            </ul>
          </label>
        </div>
        <h6 className="pt-4">DATA COLLECTION PROTOCOLS :</h6>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            <ul>
              <li>
                I am aware and agree that I SHALL upload business-related
                information required for the App from prospective customers and
                create Profiles of theirs ONLY from my DCE account of{' '}
                <span className="prochure">PROchure</span>{' '}
                {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                via the web app www.vipar-connect.in and SHALL NOT take details
                via any other online / offline method.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            <ul>
              <li>
                I am aware and agree that I SHALL upload photographs of the shop
                ONLY by following the below mentioned Photo Protocols :
                <br />
                <div className="pt-2">
                  a) Photograpgh MUST be a very clear one and NOT blurred.
                  <br />
                  b) Photograph MUST be a Still picture and NOT taken while in
                  motion.
                  <br />
                  c) Photograpgh MUST NOT be taken in darkness.
                  <br />
                  d) Photograpgh MUST be taken either in broad daylight or with
                  ample lighing (if clicked after sunset) for a clearly visible
                  picture.
                  <br />
                  e) Photograph MUST be suitable for 16:9 resolution.
                  <br />
                  f) Photograph MUST NOT be taken from an angle.
                  <br />
                  g) Photograpgh MUST be taken from exactly the front of the
                  Office / Shop / Showroom.
                  <br />
                  h) Photograpgh MUST NOT be taken with the shutters of the
                  Office / Shop / Showroom in closed position or partially
                  closed position.
                  <br />
                  i) Photograpgh MUST be taken with the shutters of the Office /
                  Shop / Showroom in fully open position.
                  <br />
                  j) Photograph MUST contain the Name Board of the Office / Shop
                  / Showroom.
                  <br />
                  k) Photograph MUST have the Name Board captured such that the
                  board is very clearly visible and readable.
                  <br />
                  l) Photograpgh MUST focus on the entire front façade of the
                  Office / Shop / Showroom and not only on the name board.
                  <br />
                  m) Photograpgh MUST NOT contain any other object in the
                  background or in and around the Office / Shop / Showroom.
                  <br />
                  n) Photograph MUST be taken without any obstruction of Humans,
                  Animals and/or Objects like vehicles, electric poles, wires
                  and cables, etc. covering the name board and front façade of
                  the Office / Shop / Showroom.
                  <br />
                  o) Photograpgh MUST be taken from a relatively appropriate
                  close distance of the Office / Shop / Showroom and NOT from
                  far distance.
                </div>
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault3">
            <ul>
              <li>
                I am aware and agree that the data (of business profiles) that I
                have collected SHALL be considered INVALID and SHALL NOT be
                taken into account of my 1-day Skill Test IF I DO NOT follow all
                or even any one of the above mentioned Photo Protocols.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault3">
            <ul>
              <li>
                I am aware and agree that the data (of business profiles) that I
                have collected SHALL be considered VALID and SHALL be taken into
                account of my daily / monthly targets ONLY IF the details of
                information / data (like the Category/Sub-category, Profile
                Display Picture, Profile Display Name, Country, State, City,
                Locality, Pin code and Contact Number) collected matches
                correctly with the respective business without a single error.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault2">
            <ul>
              <li>
                I am aware and agree that the onus of using a device of good
                quality inorder to capture pictures/photographs as per the Photo
                Protocols mentioned above is entirely on me and that the
                inability of using a device of good quality SHALL NOT be used as
                an excuse for my failure to pass the Skill Test.
              </li>
            </ul>
          </label>
        </div>

        <h5 className="mt-4 ">
          TERMS and CONDITIONS AFTER PASSING the SKILL TEST *
        </h5>
        <h6 className="pt-4">CONSENT :</h6>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault1">
            <ul>
              <li>
                I am aware and agree that this Contractual Agreement is valid
                only for a period of 1 (ONE) Year and SHALL be renewed every
                year based on the mutual understanding between myself and{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup>.
              </li>
            </ul>
          </label>
        </div>

        <h6 className="pt-4">DATA COLLECTION PROTOCOLS :</h6>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            <ul>
              <li>
                I am aware and agree that I SHALL upload business-related
                information required for the App from prospective customers and
                create Profiles of theirs ONLY from my DCE account of{' '}
                <span className="prochure">PROchure</span>{' '}
                {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                via the web app www.vipar-connect.in and SHALL NOT take details
                via any other online / offline method.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            <ul>
              <li>
                I am aware and agree that I SHALL upload photographs of the shop
                ONLY by following the below mentioned Photo Protocols :
                <br />
                <div className="pt-2">
                  a) Photograpgh MUST be a very clear one and NOT blurred.
                  <br />
                  b) Photograph MUST be a Still picture and NOT taken while in
                  motion.
                  <br />
                  c) Photograpgh MUST NOT be taken in darkness.
                  <br />
                  d) Photograpgh MUST be taken either in broad daylight or with
                  ample lighing (if clicked after sunset) for a clearly visible
                  picture.
                  <br />
                  e) Photograph MUST be suitable for 16:9 resolution.
                  <br />
                  f) Photograph MUST NOT be taken from an angle.
                  <br />
                  g) Photograpgh MUST be taken from exactly the front of the
                  Office / Shop / Showroom.
                  <br />
                  h) Photograpgh MUST NOT be taken with the shutters of the
                  Office / Shop / Showroom in closed position or partially
                  closed position.
                  <br />
                  i) Photograpgh MUST be taken with the shutters of the Office /
                  Shop / Showroom in fully open position.
                  <br />
                  j) Photograph MUST contain the Name Board of the Office / Shop
                  / Showroom.
                  <br />
                  k) Photograph MUST have the Name Board captured such that the
                  board is very clearly visible and readable.
                  <br />
                  l) Photograpgh MUST focus on the entire front façade of the
                  Office / Shop / Showroom and not only on the name board.
                  <br />
                  m) Photograpgh MUST NOT contain any other object in the
                  background or in and around the Office / Shop / Showroom.
                  <br />
                  n) Photograph MUST be taken without any obstruction of Humans,
                  Animals and/or Objects like vehicles, electric poles, wires
                  and cables, etc. covering the name board and front façade of
                  the Office / Shop / Showroom.
                  <br />
                  o) Photograpgh MUST be taken from a relatively appropriate
                  close distance of the Office / Shop / Showroom and NOT from
                  far distance.
                </div>
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault3">
            <ul>
              <li>
                I am aware and agree that the data (of business profiles) that I
                have collected SHALL be considered INVALID and SHALL NOT be
                taken into account of my daily / monthly targets IF I DO NOT
                follow all or even any one of the above mentioned Photo
                Protocols.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault3">
            <ul>
              <li>
                I am aware and agree that the data (of business profiles) that I
                have collected SHALL be considered VALID and SHALL be taken into
                account of my daily / monthly targets ONLY IF the details of
                information / data (like the Category, Sub-category, Profile
                Display Picture, Profile Display Name, Country, State, City,
                Locality, Pin code and Contact Number) collected matches
                correctly with the respective business without a single error.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault3">
            <ul>
              <li>
                I am aware and agree that the onus of using a device of good
                quality inorder to capture pictures/photographs as per the Photo
                Protocols mentioned above is entirely on me and that the
                inability of using a device of good quality SHALL NOT be used as
                an excuse for my failure to achieve my daily / monthly targets
                in the pre-requisite manner.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault2">
            <ul>
              <li>
                I am aware and agree that I SHALL clearly explain to the owner /
                representative of an Office / Shop / Showroom (IF asked about my
                actions) what this data (including the photograpgh) is being
                collected for and also show them the App available for their
                viewing on Google Play Store and on the web app www.prochure.in.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault2">
            <ul>
              <li>
                I am aware and agree that I SHALL introduce myself to each and
                every customer with my unique{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> Employee ID card (issued to me by the
                Organisation) and will co-operate with the respective customer
                to scan the QR code on my ID card to Authenticate my employment
                with{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup>.
              </li>
            </ul>
          </label>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault2">
            <ul>
              <li>
                I am aware and agree that I SHALL read, understand and explain
                the features, benefits, fee structure and membership
                subscription details of the app to each and every customer with
                clarity.
              </li>
            </ul>
          </label>
        </div>

        <h6 className="pt-4">PROFESSIONAL CONDUCT / ETHICS :</h6>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault3">
            <ul>
              <li>
                I am aware and agree that I SHALL NOT make any false claims /
                false promises to the potential subscribers regarding the
                features and benefits of using the platform or mislead them with
                incorrect or wrong information regarding the app.
              </li>
            </ul>
          </label>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            <ul>
              <li>
                I am aware and agree that I SHALL NOT say anything to the
                potential subscribers at my own discretion beyond what has been
                instructed to me by{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> and that I SHALL maintain absolute
                professional decorum as a representative of{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> during my course of interaction with
                prospective customers.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            <ul>
              <li>
                I am aware and agree that I SHALL strictly abide by the rules
                and regulations of the Organisation, and all the Terms and
                Conditions mentioned here and that I SHALL NOT indulge in any
                activity that will directly or indirectly spoil the reputation
                of the digital Platform ({' '}
                <span className="prochure">PROchure</span>{' '}
                {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}) and the
                Organisation ({' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> ).
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            <ul>
              <li>
                I am aware and agree that I SHALL NOT lose my temper or get into
                any kind of physical or verbal fights with a customer even if it
                is a mistake on the part of my customer and SHALL politely
                relieve myself from the customer’s place in any such untoward
                scenario.
              </li>
            </ul>
          </label>
        </div>
        <div class="pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            <ul>
              <li>
                I am aware and agree that I SHALL NOT post any content on any
                social media platform or any other digital platform against{' '}
                <span className="prochure">PROchure</span> or{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> with the intention to tarnish the
                reputation and goodwill of both{' '}
                <span className="prochure">PROchure</span> and{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup>, purely out of personal grudge and without
                substantiating evidence.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            <ul>
              <li>
                I am aware and agree that IF I flout any or all of the above
                mentioned clauses in this section, then I SHALL be Terminated
                with immediate effect.
              </li>
            </ul>
          </label>
        </div>
        <h6 className="pt-4">FINANCIAL PROTOCOLS :</h6>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault2">
            <ul>
              <li>
                I am aware and agree that I SHALL NOT collect any amount of
                money as Subscription Fee, from any individual who has expressed
                his/her willingness to create a business listing / profile on
                the platform, either in the form of cash / cheque / DD / net
                transfer such as IMPS, NEFT, RTGS / QR code scanning or UPI
                payment method to any external account including my own account.
              </li>
            </ul>
          </label>
        </div>
        <div class="pt-3">
          <label class="form-check-label" for="flexCheckDefault2">
            <ul>
              <li>
                I am aware and agree that IF I flout any or both of the above
                mentioned clauses in this section, then I SHALL be Terminated
                with immediate effect.
              </li>
            </ul>
          </label>
        </div>
        <h6 className="pt-4">TARGETS and REMUNERATION :</h6>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault5">
            <ul>
              <li>
                I am aware and agree that the term ‘Remuneration’ in this
                Agreement SHALL refer ONLY to the Fixed monthly Salary
                (including Allowances) for achieving my daily / monthly targets.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault5">
            <ul>
              <li>
                I am aware and agree that my average targets for each day /
                month SHALL ONLY be as per what is mentioned in the slab below.
              </li>
            </ul>
          </label>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault5">
            <ul>
              <li>
                I am aware and agree that my Fixed monthly Salary (including
                Allowances) for achieving my daily / monthly target (as per what
                is mentioned in the slab below) SHALL be Rs 25,000/- (Rupees
                TWENTY FIVE THOUSAND only).
              </li>
            </ul>
          </label>
          <div className="table-responsive pt-3">
            <table class="table table-sm align-middle table-bordered">
              <thead>
                <tr className="text-center">
                  <th
                    className=" justify-content-center align-items-start"
                    scope="col"
                  >
                    DAILY TARGET
                  </th>
                  <th
                    className="d-flex justify-content-center align-items-start"
                    scope="col"
                  >
                    MONTHLY TARGET
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center">
                  <td>50</td>
                  <td>1250</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault4">
            <ul>
              <li>
                I am aware and agree that I SHALL achieve both my daily and
                monthly targets set by{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup>.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault8">
            <ul>
              <li>
                I am aware and agree that it is ONLY via my Unique Referral Code
                (which I have created on this registration form) that{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> analyses and tracks my daily / monthly
                targets.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault8">
            <ul>
              <li>
                I am aware and agree that I SHALL NOT be eligibile to get any
                reimbursement on the conveyance or phone call expenses that I
                incur as a part of my efforts to collect data at{' '}
                <span className="prochure">PROchure</span>.
              </li>
            </ul>
          </label>
        </div>
        <div class="pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that I SHALL NOT be eligible for any
                additional fixed daily, weekly or monthly payments beyond what
                is mutually agreed upon in this Agreement between myself and{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup>.
              </li>
            </ul>
          </label>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that my Remuneration SHALL be paid to me
                ONLY on achieving the pre-requisite targets set by{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> for that respective month.
              </li>
            </ul>
          </label>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that my Remuneration for a month SHALL be
                entirely based on the total number of Profile Data collected in
                that respective month.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that my Remuneration SHALL be subject to
                proportionate deductions, as per the discretion of{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> for not being able to achieve my
                pre-requisite monthly targets.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that I SHALL NOT be eligible for any
                Remuneration in a month IF I do not achieve any of my
                pre-requisite targets in that respective month which means “NO
                Remuneration for NIL Targets”.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that the Profile Data collected by me SHALL
                be thoroughly verified by the Organisation and IF it is found
                that there has been Duplication / Repetition of Profiles; that
                there is Mismatch in Profile Information; that there is Mistake
                / Error in the Contact Numbers collected, then in such
                scenarios, the Organisation SHALL deduct Rs 20/- (Rupees TWENTY
                only) per incorrect data from my Remuneration.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that my Remuneration SHALL be settled in
                full only on the 10 th day of the following month.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that I SHALL be notified in writing 1 (ONE)
                Month prior to any increase or decrease in my daily / monthly
                targets and/or my Remuneration.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that in case I would like to request for
                any increase in my Remuneration for data collection, then I
                SHALL submit a written request via mail to only the official
                marketing email id of{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> , which is vipar.marketing.hr@gmail.com
                from my registered email id with Subject Title as ‘
                <span className="prochure">PROchure</span> DCE (Salary)
                REMUNERATION’ and clearly mentioning my Unique Referral Code,
                and that the Acceptance and Implementation of my request is at
                the sole discretion of{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup>.
              </li>
            </ul>
          </label>
        </div>

        <h6 className="pt-4">RESIGNATION :</h6>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that while resigning from the job, I SHALL
                send an email regarding my resignation to only the official
                marketing email id of{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup>, which is vipar.marketing.hr@gmail.com
                from my registered email id with Subject Title as ‘{' '}
                <span className="prochure">PROchure</span> DCE (Salary)
                RESIGNATION’ and clearly mentioning my Unique Referral Code.
                Else my resignation SHALL NOT be acceptable.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault19">
            <ul>
              <li>
                I am aware and agree that I can resign from the Organisation
                without any notice period However I SHALL be eligible for my
                Remuneration Only for those many number of Targets I have
                successfully achieved in that respective month.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault20">
            <ul>
              <li>
                I am aware and agree that both my Remuneration Certificate and
                Experience Certificate SHALL be issued to me ONLY IF I complete
                a minimum of 6 (SIX) Months of work duration with{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  &nbsp;Vipar
                </span>{' '}
                <sup class="">®</sup> as a DCE.
              </li>
            </ul>
          </label>
        </div>
        <h6 className="pt-4">RELIEVING FROM JOB :</h6>
        <div class=" pt-3">
          <label class="form-check-label">
            <ul>
              <li>
                I am aware and agree that IF the Organisation finds me inept for
                this Job or in a scenario where the Organisation decides to put
                an end to the data collection for the app, then the Organisation
                SHALL have the authority to relieve me from this Job by giving
                me a Notice period of 1 (ONE) Month in writing via mail to my
                registered email id.
              </li>
            </ul>
          </label>
        </div>
        <h6 className="pt-4">DE-REGISTRATION :</h6>
        <div class=" pt-3">
          <label class="form-check-label">
            <ul>
              <li>
                I am aware and agree that 12 (TWELVE) Consecutive days of
                ABSENCE from Work in a month without achieving my pre-requisite
                monthly targets would immediately and automatically lead to the
                de-registration of my DCE account without any further intimation
                or notification unless I submit a written valid substantiating
                reason for absence / non-performance, within a maximum duration
                of 24 hours after this 12-day period.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label">
            <ul>
              <li>
                I am aware and agree that I SHALL be Terminated with immediate
                effect IF I do not respond to the Organisation even within this
                granted time of 24 hours.
              </li>
            </ul>
          </label>
        </div>
        <h6 className="pt-4">NON-DISCLOSURE :</h6>

        <div class=" pt-3">
          <label class="form-check-label">
            <ul>
              <li>
                I am aware and agree that for as long as I am employed with{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  &nbsp;Vipar
                </span>{' '}
                <sup class="">®</sup>, I SHALL NOT disclose any of the marketing
                strategies or internal confidential matters of the Organisation
                to any 3<sup>rd</sup> party, be it an individual or company, and
                that IF I do so, I SHALL be penalised for the same.
              </li>
            </ul>
          </label>
        </div>
        <h6 className="pt-4">LEGAL BINDING :</h6>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            <ul>
              <li>
                I am aware and agree that I SHALL be Terminated without any
                intimation or notification IF the Organisation detects any
                suspicious activity or unethical practice on my part during my
                course of data collection for the app.
              </li>
            </ul>
          </label>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that it SHALL be me and only me who will be
                fully and solely responsible for my bad and irresponsible
                behaviour with a customer and only I SHALL be held liable for
                the same legally and NOT any other employee / representative of
                the Organisation or the Head of the Organisation.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that I SHALL be Terminated IF found to be
                involved in any kind of fraudulent activity which directly or
                indirectly is an act against the interests of either a
                subscriber of <span className="prochure">PROchure</span> or the
                Organisation or both.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that in case of Termination of my
                employment contract with{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> for any reason, then I SHALL NOT be
                eligible for my pending Remuneration if any, and that I SHALL be
                penalised and legally sued for monetary damages at the
                discretion of{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup>.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that any lapse(s) occuring from my end
                regarding ALL or ANY of the above mentioned mutually agreed upon
                Terms and Conditions SHALL solely be my responsibility and for
                which{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> SHALL NOT be held responsible or liable,
                both legally and otherwise.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that these Terms and the relationship
                between myself and{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> SHALL be governed by the laws of
                Karnataka, India without regard to its conflict of law
                provisions.{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> and I agree that all disputes SHALL be
                subject to arbitration at Benngaluru, Karnataka in accordance
                with the Arbitration and Conciliation Act, 1996. The arbitration
                proceedings SHALL be conducted in the English language. That{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> and I hereby expressly and irrevocably
                attorn to the jurisdiction of the courts of Bengaluru with
                respect to any matter or claim, suit, action or proceeding
                arising under or related to this Agreement. I covenant NOT to
                sue{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> in any other forum.
              </li>
            </ul>
          </label>
        </div>
        <div>
          <h6 className="mt-4">TO BE NOTED :</h6>

          <ul className="ps-5 mt-2">
            <li className="pt-2">
              You can View the Accepted Terms and Conditions from your DCE
              account.
            </li>
            <li className="pt-2">
              You can Download, Print a copy of the Accepted Terms and
              Conditions for future reference.
            </li>

            <li className="pt-2">
              Your REMUNERATION CERTIFICATE and EXPERIENCE CERTIFICATE will be
              issued to you upon resignation (and upon completion of internship
              for students).
            </li>

            <li className="pt-2">
              {' '}
              For any grievances, send us an email regarding the issue to
              vipar.marketing.hr@gmail.com from your registered email id with
              Subject Title as ‘<span className="prochure">PROchure</span> DCE
              (Salary) GRIEVANCE’ and clearly mentioning your Unique Referral
              Code.
            </li>
          </ul>

          <h6 className="mt-4">REFERRAL CODE :</h6>
          <ul className="ps-5 mt-2">
            <li className="pt-2" style={{ lineSpacing: '0' }}>
              During Registration, Create a Referral Code of your choice which
              will be your DCE Identification at{' '}
              <span
                className="vipar"
                style={{
                  fontSize: '22px',
                  color: '#99d050',
                }}
              >
                {' '}
                Vipar
              </span>{' '}
              <sup class="">®</sup>
            </li>

            <li className="pt-2">
              It is Mandatory for tracking your data collection targets and
              disbursing your Remuneration to you.
            </li>
            <li className="pt-2">
              Once a Referral Code is created, it is permanent and cannot be
              changed.
            </li>

            <li className="pt-2">
              Can be a combination of Alphabets, Numbers and Symbols.
            </li>
            <li className="pt-2">
              Minimum Character length : 5<br />
              Maximum Character length : 10
            </li>
            <li className="pt-2">
              In case you forget your Referral Code, it will be visible to you
              on the ‘Account Details’ page of your profile.
            </li>
          </ul>
        </div>
        <h6 className="mt-4">BANK ACCOUNT DETAILS :</h6>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault7">
            <ul>
              <li>
                I am aware and agree that the bank account details I have
                mentioned is correct and wish to have my Remuneration credited
                to this account.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault9">
            <ul>
              <li>
                I am aware and agree that any mistake / error in the bank
                account details entered by me SHALL NOT be the responsibility of
                the Organisation.
              </li>
            </ul>
          </label>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body closeButton>Are You Sure Want To Update ?</Modal.Body>
        <Modal.Footer>
          <button
            className="btn pro-spaces-button3"
            onClick={(e) => {
              submit();
              handleClose();
            }}
          >
            YES
          </button>
          <Button variant="secondary" onClick={handleClose}>
            NO
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
