
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';
export const Online_members = ({ type, Auth1 }) => {
  useEffect(() => {
    Auth1();
  }, []);
  const [Loading, setLoading] = useState(true);
  const [Counts, setCounts] = useState(false);
  useEffect(() => {
    console.log('Active Count test');

    Axios.get('/api/Admin/Count')
      .then((res) => {
        if (res.status === 200) {
          console.log('Active Count');
          console.log(res.data);
          setCounts(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);
  return (
    <div>
              {type === 'SUPERADMIN' && (
        <div>
          <div className="container ">
            <div className="row slhishieo3jfiehfjefbewf">

              <>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <Link to="/online_Prime_member" className="wgfiuwrgiu">
                    <div className="card carrrrbus">
                      <div className="padunnor">
                        <center>
                          Prime Members Online
                          <br>
                          </br>{' '}
                          {Counts.Active ? Counts.Active : '0'}
                        </center>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <Link to="/online_basic_member" className="wgfiuwrgiu">
                    <div className="card carrrrbus">
                      <div className="padunnor">
                        <center>
                          Elite Members Online
                          <br>
                          </br>{' '}
                          {Counts.Activev2 ? Counts.Activev2 : '0'}
                        </center>
                      </div>
                    </div>
                  </Link>
                </div>
   
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <Link to="/online_passive_member" className="wgfiuwrgiu">
                    <div className="card carrrrbus">
                      <div className="padunnor">
                        <center>
                          Passive Members Online<br></br>
                          {Counts ? Counts.passive : '0'}
                        </center>
                      </div>
                    </div>
                  </Link>
                </div>
              </>
              {/* :""}
               */}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
