import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const TempProfiles = () => {
    const [modalShow, setModalShow] = React.useState(false);
    const [first1, setfirst1] = useState([])
    const [Loading, setLoading] = useState(false)
    const show = () => setModalShow(true)
    const hide = () => setModalShow(false)
const [Current, setCurrent] = useState('')
    useEffect(() => {
        fetch()
    }, [])


    function fetch() {
        axios.get('/api/admin/tempProfiles').then((res) => {
            console.log(res.data)
            console.log('Coupon')
            setfirst1(res.data)
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })

    }

    const onShow = () => {
        // setLoading(true)
    }

    const onHide = () => {
        setLoading(false)
        hide()
        setError("")

    }
const approve = ()=>{
    setLoading(true)
    setError("")

    console.log(Current)
    let data = {
        user:Current._id,
        email:Current.email,
        pay:Current.payResult[0],
    }

axios.post('/api/Admin/ApproveSpecial',{data}).then((res)=>{
    console.log(res);
    setLoading(false)
    fetch()
    hide()
    setError("")

}).catch(err=>{
    console.log(err.response.data)
    setLoading(false)
    setError(err.response.data)
})
}
const [Error, setError] = useState('')
    return (
        <div className='p-2 border bg-light'>
            <div className='bg-secondary text-light text-center p-3' >
                <h1 className='text-uppercase '>
                INVITED SERVICE PROVIDERS
                </h1>
            </div>
            <div className='container p-0'>
                <div className="RollContainer p-0" >
                    <table className="table table-striped table-hover table-bordered ">
                        <thead className='pro-sticky-head'>
                            <tr className="tableHead">
                                <th scope="col">No</th>

                                <th scope="col">Name</th>

                                <th scope="col" colspan="2">Action</th>
                            </tr>
                        </thead>
                        <tbody className='tableBodyPro' style={{ borderTopColor: '#55374a' }}>
                            {first1 ? first1.map((element, index) => {
                                return <tr className='tableBody pro-overFlow-Y-scroll'>
                                    <th scope="row " className='text-center'>{index + 1}</th>
                                    <th scope="row">{element.pname}</th>
                                    <th>
                                        <button 
                                        className='btn btn-success' 
                                        onClick={() => {
                                        show()
                                        setCurrent(element)
                                    }
                                    } 
                                        >Action</button></th>
                                </tr>
                            }) : ''}

                        </tbody>
                    </table>
                </div>
            </div>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onShow={(e) => onShow()}
                onHide={(e) => onHide()}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Action
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <>Are you sure ? Approve {Current.pname}</>
                <br/>

                  <Button className='float-end' disabled={Loading} onClick={(e)=>approve()}>Accept</Button>
                <br/>
                  {Loading ?<><br/>Login id and password will be {Current.email.toString()} </>   :
                        <>
                            
                        </>
                    }
                    <p className='text-danger'>{Error}</p>
                </Modal.Body>
            </Modal>
        </div>


    )
}

export default TempProfiles