import React, { useState, useEffect, useContext, useRef } from 'react'
import { MyCarousel } from '../ImageUpload/MyCarousel';
import { ImageUploadComponent } from '../ImageUpload/ImageUploadComponent';
import { GenreList, bloodgrouplist, keyGen, MenuProps, OTTPlatformList } from "../../../../Utility/helper";
import { CatsContext } from '../InformationForms'
import { ConvertContext } from '../InformationForms'
import { EditContext } from '../InformationForms'
import axios from 'axios'
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

export const Form4 = () => {
    const Edit = useContext(EditContext);
    console.log(Edit)



    let x = [...Array(100).keys()]
    const cats = useContext(CatsContext);
    console.log(cats)
    const convert = useContext(ConvertContext);
    const [Submit, setSubmit] = useState(false)
    const [Submit2, setSubmit2] = useState(false);
    const [loading, setloading] = useState(false)
    const formData = new FormData()
    let navigate = useNavigate();

    const submit = async (e) => {
        e.preventDefault();
        console.log(Files);
        formData.delete('file')
        formData.delete('data')
        let loc = false
       
        console.log(dataSend)
        let metadata = {
            type: 'image/jpeg'
        };
        // if (newb) {
        //     let file = new File([newb], "avatar.jpg", metadata);
        //     console.log(file)
        //     formData.append("file", file);
        // }
        let filePrec = []
        for (let index = 0; index < Files.length; index++) {
            if (Files[index]?.file) {
                console.log(Files)
                let file = new File([Files[index].file], `ExtraImageIndex${index}${Date.now()}.jpg`, metadata);
                console.log(file)
                formData.append("file", file)
            } else {
                let temp = Files[index]
                delete temp.link
                filePrec = [...filePrec,temp ]
            }
        }
        console.log(filePrec);
        var dataSend = JSON.stringify({ value,filePrec })

        formData.append("data", dataSend);
        var status = false
        setSubmit(true)
        status = Validation()
        if (status == true) {
            setloading(true)
              
            if (!Edit) {

                axios.post('/api/Admin/InformationFormType4', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }).then((res) => {
                    if (res.status == 200) {
                        console.log("ok")
                        setloading(false)
                        notify("Information Add Successfully",true)
                        navigate(`/information`)
                    }
                }).catch((err) => {
                    console.log(err)
                    console.log("error")
                    notify("Something went wrong",false)
                    setloading(false)
                })
            }else{
                axios.post('/api/Admin/InformationFormType4Update', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }).then((res) => {
                    if (res.status == 200) {
                        console.log("ok")
                        setloading(false)
                        notify("Information Updated Successfully",true)
                        if (!cats?.value.SubSubCategory) {
                            navigate(`/Information_view_for_edit?k=${cats?.value.SubCategory}`)
                        }else{
                            navigate(`/Information_view_for_edit?k=${cats?.value.SubSubCategory}`)
                        }
                    }
                }).catch((err) => {
                    console.log(err)
                    console.log("error")
                    notify("Something went wrong",false)
                    setloading(false)
                })
            }



        }
    }

    const Validation = () => {


        if (value.category.length > 0) {
            setError("");
        } else {
            return setError("PLEASE ENTER Category");
        }
        if (value.subcategory.length > 0) {
            setError("");
        } else {
            return setError("PLEASE ENTER Sub Category");
        }
        setError("")
        if (cats?.value.SubSubCategory?.length > 0) {
            if (value.subsubcategory === undefined) {
                return setError('PLEASE SELECT A BRAND')
            }
            console.log(value)
            if (value.subsubcategory.length === 0) {
                return setError('PLEASE SELECT A BRAND')
            }
        }
        setError("")
        return true
    }

    const [value, setValue] = useState({
        category: cats?.value.Category,
        subcategory: cats?.value.SubCategory,
        subsubcategory: cats?.value.SubSubCategory,
        formType: cats?.value.FormType,
        title: "",
        genre: [],
        status: "",
        synopsis: "",
        actors_actresses: "",
        director: "",
        number_of_sessions: null,
        total_number_of_episodes: null,
        theater_release_date: "",
        ott_release_date: "",
        ott_platform: [],
        tv_channel: "",
        on_at: ""

    })
    useEffect(() => {
        console.log(cats?.subsubcategory?.length)
        setValue({ ...value, subcategory: cats?.value.SubCategory, category: cats?.value.Category, subsubcategory: cats?.value.SubSubCategory, Id: cats?.value.Id, formType: cats?.value.FormType })
        return () => {

        };
    }, [cats])
    const [Files, setFiles] = useState([])
    const FileDelete = (data, index) => {
        let temp = [...Files];
        temp.splice(index, 1)
        setFiles(temp);
    }

    const [error, setError] = useState('')

    useEffect(() => {

        if (Edit) {
            setFiles([...Edit.image])
            setValue({ ...value, 
                title:Edit.title ,
                genre:Edit.genre ,
                status:Edit.status ,
                synopsis: Edit.synopsis,
                actors_actresses:Edit.actors_actresses ,
                director:Edit.director ,
                number_of_sessions:Edit.number_of_sessions ,
                total_number_of_episodes:Edit.total_number_of_episodes ,
                theater_release_date:Edit.theater_release_date?Moment(Edit.theater_release_date ).format('YYYY-MM-DD'):null ,
                ott_release_date:Edit.ott_release_date? Moment(Edit.ott_release_date ).format('YYYY-MM-DD'):null ,
                ott_platform:Edit.ott_platform ,
                tv_channel:Edit.tv_channel ,
                on_at: Edit.on_at
            })
        }

    }, [Edit])

    return (
        <div className="container rounded bg-white  pb-5">
            <form onSubmit={(e) => submit(e)}
                novalidate
                className={Submit2 ? 'was-validated' : ''}
            >
                <MyCarousel data={Files} onChange={(e) => {

                }} titleEnabled={false} FileDelete={FileDelete} />
                <div className='d-flex'>
                    <ImageUploadComponent onChange={(e) => {
                        setFiles([...Files, { title: '', file: e }])
                    }} />
                </div>



                <div className="row mt-2">
                    <div className="col-12">
                        <label className="labels">Title </label>
                        <input
                            // required
                            type="text"
                            className="form-control"
                            value={value.title}
                            onChange={(e) =>
                                setValue({ ...value, title: e.target.value })
                            }
                        />
                        {/* <div class="invalid-feedback">
                                        PLEASE ENTER First Name
                                    </div> */}
                    </div>
                    <div className="col-md-12">
                        {/* working */}
                        <label className="labels">Genre </label>

                        <FormControl className="form-control">
                            <Select id="demo-multiple-checkbox" multiple
                                //  required 
                                // error={Submit2 ? value?.language?.length != 0 ? false : true : false}
                                className="form-select" sx={{ '& .MuiOutlinedInput-notchedOutline': { display: "none" }, '& .MuiSvgIcon-root': { display: 'none', }, '& .MuiSelect-select': { padding: '0' } }}
                                value={value.genre} onChange={(e) => setValue({ ...value, genre: e.target.value })} input={<OutlinedInput label="Tag" />} renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps}
                            >
                                {GenreList.map((Genre, index) => (
                                    <MenuItem key={index} value={Genre}>
                                        <Checkbox checked={value.genre.indexOf(Genre) > -1} />
                                        <ListItemText primary={Genre} />
                                    </MenuItem>
                                ))}
                            </Select>
                            {/* <FormHelperText className="mx-0" style={{ marginTop: '.25rem', fontSize: '.875rem', color: '#dc3545' }}>{Submit2 ? value?.language?.length != 0 ? false : 'PLEASE SELECT Languages Known' : false}</FormHelperText> */}
                        </FormControl>

                    </div>

                    <div className="col-12">
                        <label className="labels">Status </label>
                        <select
                            // required
                            className="form-select"
                            value={value.status}
                            onChange={(e) =>
                                setValue({ ...value, status: e.target.value })
                            }
                        >
                            <option value={""} selected></option>
                            <option value={"Upcoming"} >Upcoming</option>
                            <option value={"Released"} >Released</option>
                        </select>
                        {/* <div class="invalid-feedback">
                                        PLEASE ENTER First Name
                                    </div> */}
                    </div>

                    <div className="col-12">
                        <label className="labels">Synopsis </label>
                        <textarea
                            // required
                            // type="text"
                            className="form-control"
                            value={value.synopsis}
                            onChange={(e) =>
                                setValue({ ...value, synopsis: e.target.value })
                            }
                        />
                        {/* <div class="invalid-feedback">
                                        PLEASE ENTER First Name
                                    </div> */}
                    </div>

                    <div className="col-12">
                        <label className="labels">Actor / Actresses </label>
                        <input
                            // required
                            type="text"
                            className="form-control"
                            value={value.actors_actresses}
                            onChange={(e) =>
                                setValue({ ...value, actors_actresses: e.target.value })
                            }
                        />
                        {/* <div class="invalid-feedback">
                                        PLEASE ENTER First Name
                                    </div> */}
                    </div>
                    <div className="col-12">
                        <label className="labels">Director </label>
                        <input
                            // required
                            type="text"
                            className="form-control"
                            value={value.director}
                            onChange={(e) =>
                                setValue({ ...value, director: e.target.value })
                            }
                        />
                        {/* <div class="invalid-feedback">
                                        PLEASE ENTER First Name
                                    </div> */}
                    </div>
                    <div className="col-12">
                        <label className="labels">Number of sessions </label>
                        <input
                            // required
                            type="text"
                            className="form-control"
                            pattern="[0-9]*"
                            value={value.number_of_sessions}
                            onChange={(e) =>
                                setValue({ ...value, number_of_sessions: e.target.value })
                            }
                        />
                        {/* <div class="invalid-feedback">
                                        PLEASE ENTER First Name
                                    </div> */}
                    </div>

                    <div className="col-12">
                        <label className="labels">Total number of episodes </label>
                        <input
                            // required
                            type="text"
                            pattern="[0-9]*"
                            className="form-control"
                            value={value.total_number_of_episodes}
                            onChange={(e) =>
                                setValue({ ...value, total_number_of_episodes: e.target.value })
                            }
                        />
                        {/* <div class="invalid-feedback">
                                        PLEASE ENTER First Name
                                    </div> */}
                    </div>
                    <div className="col-12">
                        <label className="labels">Theater release date </label>
                        <input
                            // required
                            type="date"
                            className="form-control"
                            value={value.theater_release_date}
                            onChange={(e) =>
                                setValue({ ...value, theater_release_date: e.target.value })
                            }
                        />
                        {/* <div class="invalid-feedback">
                                        PLEASE ENTER First Name
                                    </div> */}
                    </div>
                    <div className="col-12">
                        <label className="labels">OTT release date </label>
                        <input
                            // required
                            type="date"
                            className="form-control"
                            value={value.ott_release_date}
                            onChange={(e) =>
                                setValue({ ...value, ott_release_date: e.target.value })
                            }
                        />
                        {/* <div class="invalid-feedback">
                                        PLEASE ENTER First Name
                                    </div> */}
                    </div>
                    <div className="col-md-12">
                        {/* working */}
                        <label className="labels">OTT platform </label>

                        <FormControl className="form-control">
                            <Select id="demo-multiple-checkbox" multiple
                                //  required 
                                // error={Submit2 ? value?.language?.length != 0 ? false : true : false}
                                className="form-select" sx={{ '& .MuiOutlinedInput-notchedOutline': { display: "none" }, '& .MuiSvgIcon-root': { display: 'none', }, '& .MuiSelect-select': { padding: '0' } }}
                                value={value.ott_platform} onChange={(e) => setValue({ ...value, ott_platform: e.target.value })} input={<OutlinedInput label="Tag" />} renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps}
                            >
                                {OTTPlatformList.map((platform, index) => (
                                    <MenuItem key={index} value={platform}>
                                        <Checkbox checked={value.ott_platform.indexOf(platform) > -1} />
                                        <ListItemText primary={platform} />
                                    </MenuItem>
                                ))}
                            </Select>
                            {/* <FormHelperText className="mx-0" style={{ marginTop: '.25rem', fontSize: '.875rem', color: '#dc3545' }}>{Submit2 ? value?.language?.length != 0 ? false : 'PLEASE SELECT Languages Known' : false}</FormHelperText> */}
                        </FormControl>

                    </div>

                    <div className="col-12">
                        <label className="labels">TV channel </label>
                        <input
                            // required
                            type="text"
                            className="form-control"
                            value={value.tv_channel}
                            onChange={(e) =>
                                setValue({ ...value, tv_channel: e.target.value })
                            }
                        />
                        {/* <div class="invalid-feedback">
                                        PLEASE ENTER First Name
                                    </div> */}
                    </div>

                </div>

                <h6 className='text-center text-danger' >{error ? error : ''}</h6>
                <div className="mt-2 text-center">
                    {Edit &&
                        <button type="button" className="btn mb-2 mt-4 me-2 btn-secondary" onClick={(e) => window.history.back()}>Discard</button>
                    }
                    {!loading &&
                        <button className="btn mb-2 mt-4" style={{ 'backgroundColor': '#55374a', 'color': 'white' }}
                            onClick={() => setSubmit2(true)
                                // just for testing something not required !!!!
                            }
                        >
                            {Edit ? 'Update' : 'Create '}
                        </button>
                    }
                    {loading &&
                        <button className="btn  mb-2 mt-4" disabled style={{ 'backgroundColor': '#55374a', 'color': 'white' }}>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

                        </button>
                    }
                </div>

            </form>

        </div>
    )
}
