import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiReset } from "react-icons/bi";

import { print_state, print_city, state_arr } from "../../../Utility/stateCity";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BiDotsVerticalRounded } from "react-icons/bi";

export function Childrens_Parks(props) {
  useEffect(() => {
    // props.Auth();
  }, []);

  const [show, setShow] = useState(false);
  const [Id, setId] = useState();
  const handleShow = () => setShow(true);

  const handleClose = () => {
    setShow(false);
    setId("");
  };

  const [load, setload] = useState(false);
  const [error, seterror] = useState("");
  const [output, setoutput] = useState([]);
  const [Keyer, setKeyer] = useState(1);
  const [Stateload, setStateload] = useState(true);

  const [value, setValue] = useState({
    state: "",
    city: "",
    pincode: "",
    // WE:false
  });

  function Cityshow(e) {
    var index = document.getElementById(e.target.id).selectedIndex;
    print_city("City", index);
    setValue({ ...value, state: e.target.value });
  }
  useEffect(() => {
    if (Stateload) print_state("State");
    setStateload(false);
  }, []);

  const vaid = () => {
    if (value.state.length > 0) {
      seterror("");
    } else {
      return seterror("Please select a state");
    }
    if (value.city.length > 0) {
      seterror("");
    } else {
      return seterror("Please select a city");
    }
    return true;
  };
  const Reset = (e) => {
    document.getElementById("searchForm").reset();
    setValue({ ...value, city: "", state: "", WE: !value.WE, pincode: "" });
    // Club();
    GetAmbuianceAnimal();
  };
  const GetAmbuianceAnimal = (last) => {
    setload(true);
    console.log("page");
    axios
      .get("/api/User/GetChildrens_Parks")
      .then((response) => {
        setload(false);
        console.log("heree");
        console.log(response);
        setoutput(response.data);
      })
      .catch((res) => {
        setload(false);
        console.log("Something went wrong");
      });
  };

  useEffect(() => {
    GetAmbuianceAnimal();
  }, []);

  const FindAmbuianceAnimal = (last) => {
    // console.log(page)
    setload(true);

    axios
      .post("/api/User/FindChildrens_Parks", { value })
      .then((response) => {
        setload(false);
        console.log("first");
        console.log(response.data);
        setoutput(response.data);
      })
      .catch((res) => {
        setload(false);
        console.log("Something went wrong");
      });
  };
  const [index, setindex] = useState();
  const Delete = (Id) => {
    console.log(Id);
    var id = Id;
    axios
      .post("/api/Admin/Childrens_Parks_Delete", { id })
      .then((response) => {
        // console.log('success')
        if (response.status === 200) {
          //   console.log("reported11")
          // setreported(true)
          // window.location.reload();
          let temp = [...output];
          temp.splice(index, 1);
          setindex(undefined);
          setId("");
          setoutput(temp);
          handleClose();
        }
      })
      .catch((res) => {
        console.log("Something went wrong");
      });
  };

  return (
    <div className="container-fluid bg-trasparent pro-sabari wieugfiqgefnqenc2rnewiuhgiuhq ">
      <div>
        {" "}
        <div className="d-flex justify-content-end">
          <Link to="/childrens_parks_create">
            <button className="btn btn-dosabutton ps-3 pe-3 mb-3 deghievgviehcieh">
              Add New
            </button>
          </Link>
        </div>
      </div>

      <div id="AdvancedSearch" className=" row bg-light  p-2 m-2 mb-4">
        <div>
          <p>Advanced Search Filters</p>
        </div>
        <form id="searchForm">
          <div className="col-12 ">
            <div className="row g-1">
              <div className="col-6 col-lg-3">
                <div className="form-floating">
                  <select
                    className="form-select   "
                    value={value.state}
                    id="State"
                    name="City"
                    onChange={(e) => Cityshow(e)}
                  ></select>
                  <label htmlFor="State">State</label>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="form-floating">
                  <select
                    className="form-select   "
                    name="City"
                    value={value.city}
                    id="City"
                    onChange={(e) =>
                      setValue({ ...value, city: e.target.value })
                    }
                  ></select>
                  <label htmlFor="City">City</label>
                </div>
              </div>

              <div className="col-6 col-lg-3">
                <div className="form-floating">
                  <input
                    type="number"
                    className="form-control"
                    name="pin"
                    value={value.pincode}
                    id="a"
                    onChange={(e) =>
                      setValue({ ...value, pincode: e.target.value })
                    }
                  ></input>
                  <label htmlFor="a">Pin&nbsp;code</label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 mb-1 mt-2">
            <div className=" d-flex justify-content-end">
              <div className="   me-2 ">
                <button
                  className="btn btnjui ps-4 pe-4 d-flex justify-content-center"
                  type="button"
                  onClick={(e) => Reset(e)}
                >
                  <BiReset size={24} />
                </button>
              </div>
              <button
                type="button"
                className="btn btnjui "
                onClick={(e) => FindAmbuianceAnimal()}
              >
                Go
              </button>
            </div>

            <span style={{ color: "red" }}>{error ? error : ""}</span>
          </div>
        </form>
      </div>

      <div className="row car">
        {" "}
        {load ? (
          <center>
            <Spinner animation="border" />
          </center>
        ) : output.length ? (
          output.map((elem, index) => {
            return (
              <>
                <div className=" col-12 col-md-6 col-lg-6 col-xl-4  mb-3">
                  <div className="text-center card-box p-0">
                    <div className="member-card pt-3 pb-0">
                      <div className="d-flex justify-content-end">
                        <a
                          type="button"
                          href="/"
                          className="dotoptionbtn   cell"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <BiDotsVerticalRounded
                            size={35}
                            onClick={(e) => {
                              setId(elem._id);
                              setindex(index);
                            }}
                            color={"black"}
                          ></BiDotsVerticalRounded>
                        </a>
                        <ul className="dropdown-menu ">
                          <li>
                            <Link
                              to={"/childrens_parks_update?pi=" + elem._id}
                              className="dropdown-item"
                            >
                              Edit post
                            </Link>
                          </li>
                          <li>
                            <a className="dropdown-item" onClick={handleShow}>
                              {" "}
                              Delete
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="">
                        <h6>{elem.Name.toUpperCase()}</h6>
                        <div>
                          <span>
                            <p className="ufghsuhie">
                              {elem.City} | {elem.State}
                            </p>

                            {elem.Pincode ? (
                              <>
                                <small className="ufghsuhie">Pin Code</small>
                                <br />
                                <p>{elem.Pincode}</p>
                              </>
                            ) : (
                              <></>
                            )}

                            {elem.Hours || elem.Description || elem.Address ? (
                              <>
                                <div className="ps-2 pe-2">
                                  <center>
                                    <hr
                                      style={{ width: "50%", marginLeft: "10" }}
                                    />
                                  </center>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}

                            {elem.Description ? (
                              <>
                                <small className="ufghsuhie">Description</small>
                                <br />
                                <p className="px-2">{elem.Description}</p>
                              </>
                            ) : (
                              <></>
                            )}
                            {elem.Address ? (
                              <>
                                <small className="ufghsuhie">Address</small>
                                <br />
                                <p className="px-2">{elem.Address}</p>
                              </>
                            ) : (
                              <></>
                            )}
                            {elem.Hours ? (
                              <>
                                <small className="ufghsuhie">Entry Hours</small>
                                <br />
                                <p>{elem.Hours}</p>
                              </>
                            ) : (
                              <></>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop={false}
                  id={index}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Deleting post </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>Are you sure?</Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn pro-spaces-button3"
                      onClick={(e) => Delete(elem._id)}
                    >
                      Delete
                    </button>
                  </Modal.Footer>
                </Modal>
              </>
            );
          })
        ) : (
          <>
            <center>
              <h4>No result Found !!</h4>
            </center>
          </>
        )}
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
