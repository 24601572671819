import { useEffect, useState, useRef, useContext } from "react"
import { keyGen } from "../../../Utility/helper";
import { print_state, print_city, state_arr } from '../../../Utility/stateCity'
import MapWithSwitch2 from "../../Maps/MapWithSwitch2";
import { EditContext } from '../FormProduct'
import { EditContext as EditContextv2 } from '../../CreateProductV2/FormProduct'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import MobileAddOn from "../../MobileAddOn/mobileAddOn";
import { MenuProps, languages, arrayofdays, arrayofdays2 } from '../../../Utility/languages'
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { TimeSelectBox } from "../../../Utility/timer";
import { FormHelperText } from "@mui/material";
import { ExistingData, InitializeCountry } from "../../../Utility/Country";


export default function Form1(props) {

    const [Data, setData] = useState({
        address_door: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].address_door ? props.addressBranch[props.index].address_door : '' : '' : '',
        address_floor: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].address_floor ? props.addressBranch[props.index].address_floor : '' : '' : '',
        address_building: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].address_building ? props.addressBranch[props.index].address_building : '' : '' : '',
        address_nearest: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].address_nearest ? props.addressBranch[props.index].address_nearest : '' : '' : '',
        address_street: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].address_street ? props.addressBranch[props.index].address_street : '' : '' : '',
        address1: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].address1 ? props.addressBranch[props.index].address1 : '' : '' : '',
        country: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].country ? props.addressBranch[props.index].country : '' : '' : '',
        state: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].state ? props.addressBranch[props.index].state : '' : '' : '',
        city: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].city ? props.addressBranch[props.index].city : '' : '' : '',
        pincode: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].pincode ? props.addressBranch[props.index].pincode : '' : '' : '',
        Location: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].Location ? props.addressBranch[props.index].Location : '' : '' : '',
        working_days_1: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].working_days_1 ? props.addressBranch[props.index].working_days_1 : '' : '' : '',
        working_days_2: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].working_days_2 ? props.addressBranch[props.index].working_days_2 : '' : '' : '',
        working_hour_1: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].working_hour_1 ? props.addressBranch[props.index].working_hour_1 : '' : '' : '',
        working_hour_2: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].working_hour_2 ? props.addressBranch[props.index].working_hour_2 : '' : '' : '',
        working_days_3: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].working_days_3 ? props.addressBranch[props.index].working_days_3 : '' : '' : '',
        working_days_4: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].working_days_4 ? props.addressBranch[props.index].working_days_4 : '' : '' : '',
        working_hour_3: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].working_hour_3 ? props.addressBranch[props.index].working_hour_3 : '' : '' : '',
        working_hour_4: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].working_hour_4 ? props.addressBranch[props.index].working_hour_4 : '' : '' : '',
        working_leave: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].working_leave ? props.addressBranch[props.index].working_leave : '' : '' : '',
        countrycode: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].countrycode ? props.addressBranch[props.index].countrycode : '' : '' : '',
        statecode: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].statecode ? props.addressBranch[props.index].statecode : '' : '' : '',
        CON: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].CON ? props.addressBranch[props.index].CON : [] : [] : [],
        workingdaysfullnew: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].workingdaysfullnew ? props.addressBranch[props.index].workingdaysfullnew : [] : [] : [],
        workingdayshalfnew: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].workingdayshalfnew ? props.addressBranch[props.index].workingdayshalfnew : [] : [] : [],
        workingdaysOffnew: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].workingdaysOffnew ? props.addressBranch[props.index].workingdaysOffnew : [] : [] : [],
        break_time_2: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].break_time_2 ? props.addressBranch[props.index].break_time_2 : '' : '' : '',
        break_time_1: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].break_time_1 ? props.addressBranch[props.index].break_time_1 : '' : '' : '',
        worktimimng: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].worktimimng ? props.addressBranch[props.index].worktimimng : '' : '' : '',

    })
    const Edit = useContext(props.basicprofile === true ? EditContextv2 : EditContext);

    const [CONCount, setConCount] = useState([keyGen()])
    const [CON, setCON] = useState([])
    const [Location, setLocation] = useState({ lat: '', lng: '' })
    let x = [...Array(100).keys()]


    const handleCON = (e, index) => {
        try {
            console.log({ e, index }, { CON });
            let CONcollection = CON
            if (e === 'remove') {
                CONcollection.splice(index, 1)
                let dsicount = CONCount.slice()
                let c = dsicount.splice(index, 1)
                setConCount(dsicount)
            } else {
                CONcollection[index] = e
            }
            setCON(CONcollection)
        } catch (error) {
            console.log(error)
        }
    }

    const [LocationOn, setLocationOn] = useState()
    let stat = useRef()

    const [Stateload, setStateload] = useState(true)
    useEffect(() => {
        if (Stateload)
            print_state("State" + props.index)
        setStateload(false)
    }, [])
    function Cityshow(e) {
        var index = document.getElementById(e.target.id).selectedIndex
        print_city('City' + props.index, index)
        setData({ ...Data, state: e.target.value })
    }

    useEffect(() => {
        props.handleaddressBranch(Data, props.index)
        if (props.submit) {
            props.validation()
        }
        console.log(Data)
    }, [Data])
    useEffect(() => {
        setData((prev) => ({ ...prev, Location }))
    }, [Location])
    useEffect(() => {
        setData((prev) => ({ ...prev, CON: CON }))
    }, [CON])
    useEffect(() => {

        if (Edit) {
            ExistingData(countryRef, stateRef, cityRef, props.addressBranch[props.index].country, props.addressBranch[props.index].state, props.addressBranch[props.index].city)

            if (props.addressBranch[props.index]) {
                var index = state_arr.indexOf(props.addressBranch[props.index].state)
                print_city('City' + props.index, index + 1)
                setLocation(props.addressBranch[props.index].Location)

                setCON(props.addressBranch[props.index].CON)
                // console.log(props.addressBranch[props.index])
                if (props.addressBranch[props.index]?.CON.length) {
                    setConCount(props.addressBranch[props.index].CON.map(element => {
                        console.log(element)
                        return keyGen()
                    }))
                    setData((prev) => ({ ...prev, CON: props.addressBranch ? props.addressBranch[props.index] ? props.addressBranch[props.index].CON ? props.addressBranch[props.index].CON : '' : '' : '', }))
                }
            }
        }
        return () => { }


    }, [])

    let countryRef = useRef()
    let stateRef = useRef()
    let cityRef = useRef()
    useEffect(() => {
        InitializeCountry(countryRef, stateRef, cityRef)

        return () => {

        }
    }, [])


    const isOptionDisabled = (name, value) => {
        // Add your condition to disable multiple options here
        // console.log(name)
        if (value.length > 0) {
            if (value.indexOf('Nil') >= 0) {
                return name === 'Monday' || name === 'Tuesday' || name === "Wednesday" || name === "Thursday" || name === "Friday" || name === "Saturday" || name === "Sunday";
            } else {
                return name === 'Nil'
            }
        }

    };

    return (
        <>
            <div className="p-0 pt-2">

                <h6 className="mt-4">Branch Business Information</h6>
                <div className='float-end pe-1' onClick={(e) => props.handleaddressBranch('remove', props.index)} style={{ "cursor": 'pointer' }} ><AiOutlineCloseCircle size={20} /></div>
                <label className="labels">Branch Address *</label>
                <div className="col-md-12">
                    <label className="labels">Building / Mall / Property Name *</label>
                    <input
                        required
                        type="text"
                        className="form-control"
                        value={Data.address_building}
                        onChange={(e) =>
                            setData({ ...Data, address_building: e.target.value })
                        }
                    />
                    <div class="invalid-feedback">
                        PLEASE ENTER Building / Mall / Property Name
                    </div>
                </div>
                <div className="col-md-12">
                    <label className="labels">Door / Shop No.</label>
                    <input
                        type="text"
                        className="form-control"
                        value={Data.address_door}
                        onChange={(e) =>
                            setData({ ...Data, address_door: e.target.value })
                        }
                    />
                </div>
                <div className="col-md-12">
                    <label className="labels">Floor *</label>
                    <select
                        required
                        className="form-select"
                        value={Data.address_floor}
                        onChange={(e) =>
                            setData({ ...Data, address_floor: e.target.value })
                        }
                    >
                        <option value="" disabled selected></option>
                        <option>Basement</option>
                        <option>Ground</option>
                        <option>Mezzanine</option>
                        {x.map((elem, index) => {
                            let temp = index + 1
                            if (index < 10) temp += 10
                            if (index + 1 <= 10 || index + 1 >= 20)
                                if (temp % 10 === 1) return <option>{index + 1}st</option>
                                else if (temp % 10 === 2) return <option>{index + 1}nd</option>
                                else if (temp % 10 === 3) return <option>{index + 1}rd</option>
                                else return <option>{index + 1}th</option>
                            else return <option>{index + 1}th</option>
                        })}
                    </select>
                    <div class="invalid-feedback">
                        PLEASE SELECT Floor
                    </div>
                </div>


                <div className="col-md-12">
                    <label className="labels">Street / Lane / Road Name *</label>
                    <input
                        required
                        type="text"
                        className="form-control"
                        value={Data.address_street}
                        onChange={(e) =>
                            setData({ ...Data, address_street: e.target.value })
                        }
                    />
                    <div class="invalid-feedback">
                        PLEASE ENTER Street / Lane / Road Name
                    </div>
                </div>
                <div className="col-md-12">
                    <label className="labels">Area / Locality Name *</label>
                    <input
                        required
                        type="text"
                        className="form-control"
                        value={Data.address1}
                        onChange={(e) =>
                            setData({ ...Data, address1: e.target.value })
                        }
                    />
                    <div class="invalid-feedback">
                        PLEASE ENTER Area / Locality Name
                    </div>
                </div>
                <div className="col-md-12">
                    <label className="labels">Nearest Landmark</label>
                    <input
                        type="text"
                        className="form-control"
                        value={Data.address_nearest}
                        onChange={(e) =>
                            setData({ ...Data, address_nearest: e.target.value })
                        }
                    />
                </div>
                {/* <button type="button" onClick={(e) => { console.log(Location) }} >clicl</button> */}
                <div className="col-md-12">
                    <label className="labels">Country *</label>
                    <select
                        required

                        ref={countryRef}
                        className="form-select"
                        value={Data.country}
                        onChange={(e) =>
                            setData({ ...Data, country: e.target.value })
                        }
                        name="City"
                    >
                    </select>
                    <div class="invalid-feedback">
                        PLEASE SELECT Country
                    </div>
                </div>
                <div className="col-md-12">
                    <label className="labels">State / Province *</label>
                    <select
                        required
                        ref={stateRef}
                        className="form-select"
                        value={Data.state}
                        // id={"State" + props.index}
                        name="City"
                        onChange={(e) =>
                            setData({ ...Data, state: e.target.value })
                        }
                    >
                    </select>
                    <div class="invalid-feedback">
                        PLEASE SELECT State / Province
                    </div>
                </div>
                <div className="col-md-12">
                    <label className="labels">City *</label>
                    <select
                        required
                        ref={cityRef}
                        className="form-select"
                        name={"City"}
                        value={Data.city}
                        // id={"City" + props.index}
                        onChange={(e) =>
                            setData({ ...Data, city: e.target.value })
                        }
                    >
                        <option></option>
                    </select>
                    <div class="invalid-feedback">
                        PLEASE SELECT City
                    </div>
                </div>


                <div className="col-md-12">
                    <label className="labels">Pin code / Zip code *</label>
                    <input
                        required
                        type="tel"

                        className="form-control"
                        value={Data.pincode}
                        onChange={(e) => {
                            setData({ ...Data, pincode: e.target.value });
                        }}
                    />
                    <div class="invalid-feedback">
                        PLEASE ENTER Pin code / Zip code
                    </div>
                </div>
                <div className="mt-2">
                    <MapWithSwitch2 Location={Location} setLocation={setLocation} LocationOn={LocationOn} setLocationOn={setLocationOn} key={keyGen()} />
                </div>
                <br />

                <label className="labels mt-3">Work Timings *</label>
                <div className="col-12 pt-1">
                    <div className="form-check">
                        <input
                            onChange={(e) =>
                                setData({
                                    ...Data,
                                    worktimimng: !Data.worktimimng,
                                })
                            }
                            className="form-check-input"
                            type="checkbox"
                            id="Enterprsdffise"
                            defaultChecked={Data.worktimimng}
                        />
                        <label className="labels" for="Enterprsdffise">
                            We are open 24 hours
                        </label>
                    </div>
                </div>
                <div className="col-md-12">
                    <label className="labels">Working Days (full) {Data.worktimimng ? '' : '*'}</label>
                    <FormControl className="form-control">
                        <Select id="demo-multiple-checkbox" multiple required={props.Submit2 ? Data?.worktimimng === true ? false : Data.workingdaysfullnew.length > 0 ? false : true : false} error={props.Submit2 ? Data?.worktimimng === true ? false : Data.workingdaysfullnew.length > 0 ? false : true : false} className="form-select" sx={{ '& .MuiOutlinedInput-notchedOutline': { display: "none" }, '& .MuiSvgIcon-root': { display: 'none', }, '& .MuiSelect-select': { padding: '0' } }}
                            value={Data.workingdaysfullnew} onChange={(e) => setData({ ...Data, workingdaysfullnew: e.target.value })} input={<OutlinedInput label="Tag" />} renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps}
                        >
                            {arrayofdays.map((arrayofdays) => (
                                <MenuItem key={arrayofdays} value={arrayofdays}
                                    disabled={isOptionDisabled(arrayofdays, Data.workingdaysfullnew)}

                                >
                                    <Checkbox checked={Data.workingdaysfullnew.indexOf(arrayofdays) > -1} />
                                    <ListItemText primary={arrayofdays} />
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText className="mx-0" style={{ marginTop: '.25rem', fontSize: '.875rem', color: '#dc3545' }}>{props.Submit2 ? Data?.worktimimng === true ? false : Data.workingdaysfullnew.length > 0 ? false : 'PLEASE SELECT Working Days (full)' : false}</FormHelperText>

                    </FormControl>
                </div>
                <div className="col-md-12">
                    <label className="labels">Working Hours {Data.worktimimng ? '' : '*'}</label>
                    <div className="row">
                        <div className="col-5">
                            <select
                                required={props.Submit2 ? Data?.worktimimng === true ? false : true : false}

                                className="form-select"
                                value={Data.working_hour_1}
                                onChange={(e) =>
                                    setData({ ...Data, working_hour_1: e.target.value })
                                }
                            >
                                <TimeSelectBox />
                            </select>
                            <div class="invalid-feedback">
                                PLEASE SELECT Working Hours
                            </div>
                        </div>
                        <div className="col-2 d-flex justify-content-center pt-2 p-0">to</div>
                        <div className="col-5">
                            <select
                                required={props.Submit2 ? Data?.worktimimng === true ? false : true : false}

                                className="form-select"
                                value={Data.working_hour_2}
                                onChange={(e) =>
                                    setData({ ...Data, working_hour_2: e.target.value })
                                }
                            >
                                <TimeSelectBox />
                            </select>
                            <div class="invalid-feedback">
                                PLEASE SELECT Working Hours
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <label className="labels">Break Time {Data.worktimimng ? '' : '*'}</label>
                    <div className="row">
                        <div className="col-5">
                            <select
                                required={props.Submit2 ? Data?.worktimimng === true ? false : true : false}

                                className="form-select"
                                value={Data.break_time_1}
                                onChange={(e) =>
                                    setData({ ...Data, break_time_1: e.target.value })
                                }
                            >
                                <TimeSelectBox type={'break'} />


                            </select>
                            <div class="invalid-feedback">
                                PLEASE SELECT Break Time
                            </div>
                        </div>
                        <div className="col-2 d-flex justify-content-center pt-2 p-0">to</div>
                        <div className="col-5">
                            <select
                                required={props.Submit2 ? Data?.worktimimng === true ? false : true : false}

                                className="form-select"
                                value={Data.break_time_2}
                                onChange={(e) =>
                                    setData({ ...Data, break_time_2: e.target.value })
                                }
                            >
                                <TimeSelectBox type={'break'} />

                            </select>
                            <div class="invalid-feedback">
                                PLEASE SELECT Break Time
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <label className="labels">Working Days (half) {Data.worktimimng ? '' : '*'}</label>
                    <FormControl className="form-control">
                        <Select id="demo-multiple-checkbox" multiple required={props.Submit2 ? Data?.worktimimng === true ? false : Data.workingdayshalfnew.length > 0 ? false : true : false} error={props.Submit2 ? Data?.worktimimng === true ? false : Data.workingdayshalfnew.length > 0 ? false : true : false} className="form-select" sx={{ '& .MuiOutlinedInput-notchedOutline': { display: "none" }, '& .MuiSvgIcon-root': { display: 'none', }, '& .MuiSelect-select': { padding: '0' } }}
                            value={Data.workingdayshalfnew} onChange={(e) => setData({ ...Data, workingdayshalfnew: e.target.value })} input={<OutlinedInput label="Tag" />} renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps}
                        >
                            {arrayofdays2.map((arrayofdays2) => (
                                <MenuItem key={arrayofdays2} value={arrayofdays2}
                                    disabled={isOptionDisabled(arrayofdays2, Data.workingdayshalfnew)}

                                >
                                    <Checkbox checked={Data.workingdayshalfnew.indexOf(arrayofdays2) > -1} />
                                    <ListItemText primary={arrayofdays2} />
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText className="mx-0" style={{ marginTop: '.25rem', fontSize: '.875rem', color: '#dc3545' }}>{props.Submit2 ? Data?.worktimimng === true ? false : Data.workingdayshalfnew.length > 0 ? false : 'PLEASE SELECT Working Days (half)' : false}</FormHelperText>

                    </FormControl>
                </div>
                <div className="col-md-12">
                    <label className="labels">Working Hours {Data.worktimimng ? '' : '*'}</label>
                    <div className="row">
                        <div className="col-5">
                            <select
                                required={props.Submit2 ? Data?.worktimimng === true ? false : true : false}
                                className="form-select"
                                value={Data.working_hour_3}
                                onChange={(e) =>
                                    setData({ ...Data, working_hour_3: e.target.value })
                                }
                            >
                                <TimeSelectBox type={'break'} />


                            </select>
                            <div class="invalid-feedback">
                                PLEASE SELECT Working Hours
                            </div>
                        </div>
                        <div className="col-2 d-flex justify-content-center pt-2 p-0">to</div>
                        <div className="col-5">
                            <select
                                required={props.Submit2 ? Data?.worktimimng === true ? false : true : false}
                                className="form-select"
                                value={Data.working_hour_4}
                                onChange={(e) =>
                                    setData({ ...Data, working_hour_4: e.target.value })
                                }
                            >
                                <TimeSelectBox />

                            </select>
                            <div class="invalid-feedback">
                                PLEASE SELECT Working Hours
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <label className="labels">Weekly Off *</label>
                    <FormControl className="form-control">
                        <Select id="demo-multiple-checkbox" multiple required error={props.Submit2 ? Data?.workingdaysOffnew?.length != 0 ? false : true : false} className="form-select" sx={{ '& .MuiOutlinedInput-notchedOutline': { display: "none" }, '& .MuiSvgIcon-root': { display: 'none', }, '& .MuiSelect-select': { padding: '0' } }}
                            value={Data.workingdaysOffnew} onChange={(e) => setData({ ...Data, workingdaysOffnew: e.target.value })} input={<OutlinedInput label="Tag" />} renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps}
                        >
                            {arrayofdays2.map((arrayofdays2) => (
                                <MenuItem key={arrayofdays2} value={arrayofdays2}
                                    disabled={isOptionDisabled(arrayofdays2, Data.workingdaysOffnew)}
                                >
                                    <Checkbox checked={Data.workingdaysOffnew.indexOf(arrayofdays2) > -1} />
                                    <ListItemText primary={arrayofdays2} />
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText className="mx-0" style={{ marginTop: '.25rem', fontSize: '.875rem', color: '#dc3545' }}>{props.Submit2 ? Data?.workingdaysOffnew?.length != 0 ? false : 'PLEASE SELECT Weekly Off' : false}</FormHelperText>
                    </FormControl>
                </div>
                <h6 className="mt-4">Contact Information</h6>
                <div className="col-md-12"><label className="labels">Country Code *</label>
                    <select
                        required
                        className="form-select"
                        value={Data.countrycode}
                        onChange={(e) =>
                            setData({ ...Data, countrycode: e.target.value })
                        }
                    >
                        <option value="" disabled selected></option>
                        <option value="+91">+91</option>

                    </select>
                    <div class="invalid-feedback">
                        PLEASE SELECT Country Code
                    </div>
                </div>
                {/* <div className="col-md-12"><label className="labels">State Code *</label><input type="number" required className="form-control" value={Data.statecode} onChange={(e) => setData({ ...Data, statecode: e.target.value })} />
                    <div class="invalid-feedback">
                        PLEASE ENTER State Code
                    </div>
                </div> */}
                <>
                    {CONCount.map((dsi, index) => {
                        return <MobileAddOn languages={languages} key={dsi} id={dsi} con={Data.CON} handleCON={handleCON} index={index} type="2"></MobileAddOn>
                    })}
                    <button style={{ fontSize: '12px' }} disabled={CONCount.length >= 5} className="btn pro-spaces-button" type="button" onClick={(e) => setConCount([...CONCount, keyGen()])} >ADD ANOTHER MOBILE / LANDLINE NUMBER </button>
                </>
            </div>

        </>
    )
}