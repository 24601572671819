import React, { useState, useEffect, useRef, useReducer, createContext, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./CreateConseltent.css";
import axios from "axios";
import { useNavigate } from 'react-router-dom'
// import { Navoutside } from '../Navoutside/Navoutside';
import { FormConsultent } from "./FormConsultent";
export const ConsContext = createContext();

export function CreateConseltent() {
  let navigate = useNavigate()
  const [converano, setConverano] = useState(false)


  const submit = async(formData) => {
return new Promise((resolve,reject)=>{
    axios.post('/api/accounts/register/consultantV3', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    }).then(res => {
      if (res.status == 200) {
        return navigate('/c_v_registration')
      }
      resolve(true)
    }).catch((err) => {
      if (err.response.status == 304) {
        // toast warning
        navigate('/c_v_registration')

      } else {
      }
      resolve(false)
    })
  })
  }


  return <>
    {/* <Navoutside /> */}
  <div className="container rounded bg-white pt-5 pb-5">
 
    <FormConsultent postTo={submit} sab={false}/>
 


  </div >
  </>
}




