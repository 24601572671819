import React, { useState, useEffect, useReducer, createContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import './CreateProduct.css'
import "cropperjs/dist/cropper.css";
import axios from 'axios'
import { CreateProductA } from "./forms/CreateProductA";
import Select from 'react-select';
import { useNavigate,useSearchParams } from 'react-router-dom'

export const CatsContext = createContext();
export const ConvertContext = createContext()
export const EditContext = createContext()


export function FormProduct(props) {
    let [searchParams, setSearchParams] = useSearchParams();  /////getting Account id
    var Id = searchParams.get("k")
    const reducer = (state, action) => {
        console.log(action)
        switch (action.type) {
            case "1":
                return <CreateProductA postTo={props.postTo} />;
              case "2":
                return <CreateProductA postTo={props.postTo} />;
              default:
                <></>;
              // throw new Error();
        } 
    };

    const [state, dispatch] = useReducer(reducer, <></>);
    const [brand, setBrand] = useState([])

    const [value, setValue] = useState({

        category: '',
        subcategory: "", 
 
    })
    const [cat, setCat] = useState([])
    const [category, setCategory] = useState(false)
    const [subcategory, setSubcategory] = useState(false);

      

    const sabari = (e) => {
        console.log(e); 
        var anadhan;
        setValue({ ...value, category: e });
        dispatch({ type: '.' })
        cat.forEach((element) => {
            console.log(element._id);
            if (element._id === e) {
              anadhan = element.sub.map((elements) => {
                if (elements?.Static_status) {
                  return (
                    <option
                      disabled
                      formtype={elements.formType}
                      value={elements._id}
                    >
                      {elements.sub}
                    </option>
                  );
                } else {
                  return (
                    <option formtype={elements.formType} value={elements._id}>
                      {elements.sub}
                    </option>
                  );
                }
              });
            }  else {
            }
        });
        setSubcategory(anadhan);
    };
 
    useEffect(() => {
        axios
            .get("/api/api/getprocategory")
            .then((res) => {
                if (res.status === 200) {
                    console.log("workd");
                    setCat(res.data);
                }
            })
            .catch((err) => {
                console.log(err.response.status);
            });
    }, []);
    const [data, setdata] = useState()

    var sab = [];
    useEffect(() => {
        if (cat.length >= 1) {
            cat.forEach((element) => {
                sab.push(<option value={element._id}>{element.category}</option>);
            });
            setCategory(sab);
        } else {
            console.log("nothing");
        }
    }, [cat]);

    const   formLoader = () => {
        let e = document.getElementById('subcatanandsfdfdsfda83389')
        console.log(e)
        let ft = e.options[e.selectedIndex].getAttribute('formtype')
        console.log(ft)
        dispatch({ type: ft })
        console.log(e.value)
        setValue({ ...value, subcategory: e.value })
        cat.find(elem => {
            if (elem._id === value.category) {
              elem.sub.find(element => {
                if (element._id == e.value) {
                  console.log(element)
                  setBrand(element.brand)
                }
              })
            }
          })
    }
    useEffect(() => {
        console.log("daaa")
        if (props.edit) {
            axios.post('/api/ActiveUser/ConProfile', {Id}).then((res) => {
                console.log(res)
                cat.find(elem => {
                    if (elem._id === res.data[0].category) {
                      elem.sub.find(element => {
                        if (element._id == res.data[0].subcategory) {
                          console.log(element)
                          setBrand(element.brand)
                        }
                      })
                    }
                  })
                setValue({
                    ...value,
                    category: res.data[0].category,
                    subcategory: res.data[0].subcategory,
                    Brand: res.data[0].Brand,
                    Id:Id
                })
                let e = document.getElementById('subcatanandsfdfdsfda83389')
                console.log(res.data[0].subcategory)
                let anadhan
                let ft
                cat.forEach(element => {
                    if (element._id === res.data[0].category) {
                        anadhan = element.sub.map((elements) => {
                            if (elements._id === res.data[0].subcategory) {
                                ft = elements.formType
                                console.log(elements)
                            } else {
                                // console.log(elements._id,res.data[0].subcategory)
                            }
                            return <option formtype={elements.formType} value={elements._id}>{elements.sub}</option>
                        })
                    }


                });
                console.log(ft)
                console.log(anadhan)
                setSubcategory(anadhan)
                if (ft)
                    {
                        ft = ft.toString()
                        console.log(ft)
                        dispatch({ type: ft })

                    }
                setdata(res.data)
                cat.find(elem => {
                    if (elem._id === value.category) {
                      elem.sub.find(element => {
                        if (element._id == e.value) {
                          console.log(element)
                          setBrand(element.brand)
                        }
                      })
                    }
                  })

            }).catch((err) => {
                console.log(err)
            })
        }
    }, [category])


      // -------------input catagory box start------------------

      const [Options, setOptions] = useState([])
      useEffect(() => {
          axios
          .get('/api/api/getprocategory')
          .then(res => {
              let op = res.data?.map((element, index) => {
              return element.sub.map((elm, ind) => {
                  let value = elm.sub.toString()
                  if (elm.keyword?.length) {
                  var sabari = elm.keyword.map((keywordsmap)=>keywordsmap.label)
                  value = value + '' + sabari.toString()
                  }
                  let brand
                  if (elm.brand?.length) {
                  brand = true
                  } else {
                  brand = false
                  }
                  return {
                  value,
                  label: `${elm.sub} [ ${element.category} ]`,
                  id: elm._id,
                  categoryName: element.category,
                  brand,
                  type: element.type,
                  catid:element._id,
                  ft :elm.formType
                  }
              })
              })
              op = op.flat(1)
              setOptions(op)
          })
          .catch(err => {
              console.log(err)
          })
      }, [])

const redmik20pro = id => {
  let catid = id.catid
  let subcatid = id.id
  let anadhan
  let ft = id.ft
  cat.forEach(element => {
      if (element._id === catid) {
          anadhan = element.sub.map((elements) => {
              if (elements._id === subcatid) {
                  ft = elements.formType
              } else {
                  // console.log(elements._id,res.data[0].subcategory)
              }
              return (
                  <option formtype={elements.formType} value={elements._id}>
                      {elements.sub}
                  </option>
              )
          })
      }
  });
  setSubcategory(anadhan)
  setValue((rev)=>({...rev,category:catid,subcategory:subcatid,formType:ft}))
  console.log(ft)
  dispatch({ type: ft.toString() })
}

const [selectedOption, setSelectedOption] = useState(null)
const handleChange = selectedOption => {
  redmik20pro(selectedOption)
}

const handle  = (e)=>{
  if(e.target.value.length>2){
    let elem =  document.querySelector(':root')
    elem.style.setProperty('--display', 'block  ');
   }else{
    let elem =  document.querySelector(':root')
    elem.style.setProperty('--display', 'none  ');
   }
}

useEffect(() => {
  try {
  rt()

  let element = document.getElementsByClassName('inputselectcat')
  element[0].children[2].children[0].children[1].children[0].addEventListener('input', handle    )
  element[0].children[2].children[1].classList.add('d-none')

  } catch (error) {
    console.log(error)
  }
  return () => {

   try{
    let element = document.getElementsByClassName('inputselectcat')
    element[0].children[2].children[0].children[1].children[0].removeEventListener('input',handle)
   }catch (err) {
     
   }
  }
}, [])

  const rt = ()=>{
    let elem =  document.querySelector(':root')
    elem.style.setProperty('--display', 'none ');
  }

  // -------------input catagory box end------------------



    return (
        <div className="container rounded bg-white pt-5 pb-5">
            <center><h5 className='pt-4'>Profile Creation Form</h5></center>
            <center><p>' * ' are all mandatory fields</p></center>
           <div className="row">
           {!props.edit?<>
            <div className="col-12">
            <div className='mb-2 '>
                <Select
                    value={selectedOption}
                    className="inputselectcat"
                    placeholder='Type and Search for your Category'
                    onChange={e => handleChange(e)}
                    styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? '#ced4da' : '#ced4da',
                    }),
                    }}
                    theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: '#ced4da',

                        primary: '#ced4da',
                    },
                    })}
                    options={Options}
                />
                </div>
            </div>
            <center><h6>OR</h6></center>
            </>:<></>}
            
                <h6>Select your appropriate Category and Sub Category</h6>
                <div className="col-md-12"><label className="labels">Category *</label>
                    <select className="form-select" value={value.category} onChange={(e) => sabari(e.target.value)}>
                        <option ></option>{category ? category : ''}</select></div>
                <div className="col-md-12"><label className="labels">Sub Category *</label>
                <select id='subcatanandsfdfdsfda83389' className="form-select" value={value.subcategory} onChange={(e) => formLoader(e)}>
                     <option></option>{subcategory ? subcategory : ""}</select></div>
            </div>
            {brand.length ? (
        <>
          <div className='col-md-12'>
            <label className='labels'>Brand *</label>
            <select
              value={value.Brand}
              className='form-select'
              onChange={e => setValue({ ...value, Brand: e.target.value })}
            >
              <option></option>
              {brand.map(elem => {
                return <option value={elem._id}>{elem.brand}</option>
              })}
            </select>
          </div>
        </>
      ) : (
        <></>
      )}
            <EditContext.Provider value={data}>
                <CatsContext.Provider value={{value,brand:brand}}>
                    <ConvertContext.Provider value={props.sab}>
                        {state}
                    </ConvertContext.Provider>
                </CatsContext.Provider>
            </EditContext.Provider>
        </div>
    )
}
