import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  useGetAllServiceCatagoryQuery,
  useGetAllProductCatagoryQuery,
  useGetAllSubCatagoryQuery,
  useGetAllAdminQuery,
  getfindUD,
  GET_FIND_UD,
  deteleUsers,
  useGetDceTAbleQuery,
  GET_DCE_TABLE_DATA,
  verifyAuth,
} from "..";
import { useImmer } from "use-immer";
import { notify } from "Utility/notify";
import { useDelete } from "shared";

export const useDceTable = ({ load = false }) => {
  const queryClient = useQueryClient();
  const { deleteInfo, onDelete, resetDeleteInfo } = useDelete();

  const initialFilterValue = {
    from: "",
    to: "",
    status: "",
    classification: "",
    city: "",
    membership: "",
    subcategory: "",
    category: "",
    executive: "",
    pname: "",
    verifySC: "",
    verifyCN: "",
    address1: "",
    state: "",
    country: "",
  };
  const [filtervalue, setfiltervalue] = useImmer(initialFilterValue);

  const [page, setPage] = useImmer(0);
  const [rowsPerPage, setRowsPerPage] = useImmer(25);

  const { query: getAllServiceCatagoryQuery } = useGetAllServiceCatagoryQuery({
    enabled: load && filtervalue.classification === "Consultant",
  });

  const { query: getAllProductCatagoryQuery } = useGetAllProductCatagoryQuery({
    enabled: load && filtervalue.classification === "Product",
  });

  const { query: getAllSubCatagoryQuery } = useGetAllSubCatagoryQuery({
    enabled: load && !!filtervalue.category,
    catId: filtervalue.category,
  });

  const {
    query: getDceTableDataQuery,
    filter,
    setFilter,
  } = useGetDceTAbleQuery({
    enabled: load,
  });

  const { data: dataDCETable, isLoading } = getDceTableDataQuery;
  const { data, count } = !!dataDCETable && dataDCETable;

  const { query: getAllAdminQuery } = useGetAllAdminQuery({
    enabled: load,
  });
  const findUDQuey = useQuery([GET_FIND_UD], getfindUD, {
    enabled: load,
  });

  const serviceCatagory = React.useMemo(() => {
    return getAllServiceCatagoryQuery
      ? getAllServiceCatagoryQuery?.data?.map((p) => {
          return { value: p._id, label: p.category };
        })
      : [];
  }, [getAllServiceCatagoryQuery]);

  const productCatagory = React.useMemo(() => {
    return getAllProductCatagoryQuery
      ? getAllProductCatagoryQuery?.data?.map((p) => {
          return { value: p._id, label: p.category };
        })
      : [];
  }, [getAllProductCatagoryQuery]);

  const subCatagory = React.useMemo(() => {
    return getAllSubCatagoryQuery
      ? getAllSubCatagoryQuery?.data?.map((p) => {
          return { value: p._id, label: p.sub };
        })
      : [];
  }, [getAllSubCatagoryQuery]);

  const adminList = React.useMemo(() => {
    return getAllAdminQuery
      ? getAllAdminQuery?.data?.map((p) => {
          return { value: p.value, label: p.label };
        })
      : [];
  }, [getAllAdminQuery]);

  const deleteUser = useMutation(deteleUsers, {
    onError: () => {
      notify("Can't delete !", false);
    },
    onSuccess: () => {
      notify("Data deleted !", true);
      queryClient.invalidateQueries([GET_DCE_TABLE_DATA, filter]);
    },
    onSettled: () => {
      resetDeleteInfo();
    },
  });

  const verifyUserAuth = useMutation(verifyAuth, {
    onError: () => {},
    onSuccess: () => {
      queryClient.invalidateQueries([GET_DCE_TABLE_DATA, filter]);
    },
  });

  const onFilterChange = (value, name) => {
    setfiltervalue((draft) => {
      draft[name] = value;
      return draft;
    });
  };

  const clearCatagory = () => {
    setfiltervalue((draft) => {
      draft.category = "";
      return draft;
      });
  };

  const clearSubcategory = () => {
    setfiltervalue((draft) => {
      draft.subcategory = "";
      return draft;
    });
  };

  const resetFilter = () => {
    setfiltervalue((draft) => {
      draft = initialFilterValue;
      return draft;
    });
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setFilter((draft) => {
        draft.filters = filtervalue;
        draft.skip = newPage * rowsPerPage;
        return draft;
      });
    } else {
      setFilter((draft) => {
        draft.filters = filtervalue;
        draft.skip = newPage * rowsPerPage;
        return draft;
      });
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let value = parseInt(event.target.value, 10);
    setRowsPerPage(value);
    setPage(0);
    if (value >= data.length) {
      setFilter((draft) => {
        draft.limit = value;
        return draft;
      });
    }
  };

  return {
    serviceCatagory,
    productCatagory,
    subCatagory,
    filtervalue,
    adminList,
    deleteUser,
    getDceTableDataQuery,
    filter,
    data,
    count,
    page,
    rowsPerPage,
    isLoading,
    deleteInfo,
    onDelete,
    resetDeleteInfo,
    onFilterChange,
    clearCatagory,
    clearSubcategory,
    resetFilter,
    setFilter,
    handleChangePage,
    handleChangeRowsPerPage,
    setPage,
    verifyUserAuth,
    findUDQuey,
  };
};
