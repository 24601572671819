import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { DataGrid } from '@mui/x-data-grid';
import { notify } from '../../Utility/notify';

export const Applicantsview = () => {
  const [opperins, setopperins] = useState({
    JobTitle: '',
    JobDescription: '',
    Qualfication: '',
    Experience: '',
    Vacancies: '',
    JoiningDate: '',
    verticals: '',
    Status: false,
  });

  const [opperins3, setopperins3] = useState();
  useEffect(() => {
    console.log(opperins3);
  }, [opperins3]);

  const [state, setState] = useState([]);
  const [index, setIndex] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submit = () => {};

  useEffect(() => {
    axios
      .post('/jobroute/jobapplicantview')
      .then((res) => {
        console.log(res.data);
        const newState = res.data
          .map((e, index) => {
            console.log(e);
            return {
              ...e,
              id: index + 1,
              ind: index,
            };
          })
          .filter((e) => e !== undefined);
        console.log(newState);
        setState(newState);
      })
      .catch((err) => {});
  }, []);

  const [arr, setarr] = useState([]);
  console.log(state);
  useEffect(() => {
    var array = state.map((e, index) => {
      return {
        ...e,
        id: e.id,
        ind: e.ind,
        JobTitle: e.job[0]?.JobTitle,
        JobDescription: e.job[0]?.JobDescription,
        Qualfication: e.job[0]?.Qualfication,
        Experience: e.job[0]?.Experience,
        // Vacancies: e.job[0]?.Vacancies,
        JobSection: e.job[0]?.JobSection,
        JobSubSection: e.job[0]?.JobSubSection,
        JoiningDate: e.job[0]?.JoiningDate,
        verticals: e.job[0]?.verticals,

        jobId: e.jobId,
        Image: e.Image[0],
        // applyStatus: e.applyStatus,
      };
    });
    setarr(array);
  }, [state]);
  useEffect(() => {
    console.log(arr);
  }, [arr]);

  useEffect(() => {
    console.log(state);
  }, [state]);

  useEffect(() => {
    console.log(opperins3);
  }, [opperins3]);

  const columns = [
    { field: 'id', headerName: 'Sl No', width: 70 },
    { field: 'JobSection', headerName: 'Job Category', width: 150 },
    { field: 'JobSubSection', headerName: 'Job Title', width: 150 },

    {
      field: 'Details',
      headerName: 'Details',
      sortable: false,
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <button
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#detailsModal"
          onClick={(e) => {
            console.log(params.row);
            setopperins3(params.row);
          }}
        >
          Details
        </button>
      ),
    },

    {
      field: 'Delete',
      headerName: 'Delete',
      sortable: false,
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <button
          className="btn btn-danger"
          data-bs-toggle="modal"
          data-bs-target={'#exampleModal' + params.row.ind}
          onClick={(e) => {
            console.log(params.row);
          }}
        >
          Delete
        </button>
      ),
    },
  ];
  const tableStyles = {
    backgroundColor: '#f0f0f0',
  };

  const Deleteee = (id, ind) => {
    console.log(id);
    console.log(ind);

    axios
      .post('/jobroute/jobapplicantdelete', { id, ind })
      .then((res) => {
        console.log(res.data);
        notify('Successfully Deleted', true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        notify('Something went Wrong', false);
      });
  };

  return (
    <>
      <div className="page-breadcrumb ">
        <div className="row bg-light align-items-center text-dark">
          <h4 className="page-title text-dark">Applicants View</h4>
        </div>
      </div>
      <div
        style={{
          height: 400,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <DataGrid
          rows={arr}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          style={tableStyles}
          className=" pt-3 sswewe"
        />
      </div>

      {/* details modal */}

      <div
        class="modal fade"
        id="detailsModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content ">
            <div class="modal-header">
              <h5 class="modal-title px-3" id="exampleModalLabel">
                Details
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div class="modal-body">
              <div className="px-3">
                {/* <div className="">Job id : {opperins?.jobId}</div> */}

                <div className="pt-3">
                  Job Category : {opperins3?.JobSection}
                </div>

                <div className="">Job Title :{opperins3?.JobSubSection}</div>

                <div className="">CurrentCTC : {opperins3?.CurrentCTS}</div>
                <div className="">
                  SalaryExpectation : {opperins3?.SalaryExpectation}
                </div>

                <center>
                  <button className="btn btn-sm btn-primary my-2">
                    <a
                      href={opperins3?.Image?.link}
                      style={{ textDecoration: 'none', color: 'white' }}
                      download
                    >
                      Download CV
                    </a>
                  </button>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* details modal */}
      {/* edit modal */}

      {/* edit modal */}

      {/* delet modal */}

      {state?.map((elem, index) => {
        console.log(elem);
        return (
          <>
            <div
              class="modal fade"
              id={'exampleModal' + index}
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      id={'btnclose'}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body text-danger">
                    Are you sure you want to delete ?
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={(e) => {
                        Deleteee(elem._id, elem.ind);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};
