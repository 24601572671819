import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

export const Users = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [value, setValue] = useState({
    account_type: 'All',
    membership_type: 'All',
    message: '',
  });
  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const [error, seterror] = useState('');
  const [Status, setStatus] = useState(false);
  const sendNotification = () => {
    // console.log(value.message.length)

    if (value.message.length <= 0) {
      return seterror('Enter message');
    }
    // return
    seterror('');
    setStatus(true);
    axios
      .post('/api/SuperAdmin/SendNotification', { value })
      .then((res) => {
        console.log(res);
        setStatus(false);
        notify('Notification send successfully', true);
        setValue({
          account_type: 'All',
          membership_type: 'All',
          message: '',
        });
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        notify('Something went wrong ', false);
        setStatus(false);
      });
  };

  return (
    <>
      {' '}
      <div style={{ height: '100%' }} className="bg-light container">
        <div class="d-flex justify-content-end pt-5">
          <button
            className="btn pro-spaces-button3 "
            onClick={(e) => handleShow(4)}
          >
            Send Notification
          </button>
        </div>
        <div className="row slhishieo3jfiehfjefbewf">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <Link to="/Consultants" className="wgfiuwrgiu">
              <div className="card carrrrbus">
                <div className="padunnor">
                  <center>
                    Service Providers <br></br>{' '}
                  </center>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <Link to="/ProductStore" className="wgfiuwrgiu">
              <div className="card carrrrbus">
                <div className="padunnor">
                  <center>
                    Product Retailers <br></br>
                  </center>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <Link to="/Passive" className="wgfiuwrgiu">
              <div className="card carrrrbus">
                <div className="padunnor">
                  <center>
                    Clients, Customers<br></br>
                  </center>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Send Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <label className="labels mt-3">Account type *</label>
            <div className="col-12 pt-1">
              <select
                className="form-select"
                value={value.account_type}
                onChange={(e) =>
                  setValue({ ...value, account_type: e.target.value })
                }
              >
                <option value="All" selected>
                  All
                </option>
                <option value="Consultant">Service Providers</option>
                <option value="Product">Product Retailers</option>
                <option value="Passive">Passive</option>
              </select>
            </div>
            <label className="labels mt-3">Subscription type </label>
            <div className="col-12 pt-1">
              <select
                className="form-select"
                value={value.membership_type}
                onChange={(e) =>
                  setValue({ ...value, membership_type: e.target.value })
                }
                disabled={value.account_type == 'Passive' ? true : false}
              >
                <option value="All" selected>
                  All
                </option>
                <option value="Active">Prime</option>
                <option value="Activev2">Elite</option>
                <option value="Activev3">Basic</option>
              </select>
            </div>
            <label className="labels mt-3">Enter the message *</label>
            <div className="col-12 pt-1">
              <textarea
                className="form-control"
                value={value.message}
                onChange={(e) =>
                  setValue({ ...value, message: e.target.value })
                }
              ></textarea>
            </div>
            <br></br>
            {error ? <p style={{ color: 'red' }}>{error}</p> : ''}
            {Status ? (
              <p style={{ color: 'green' }}>It will take sometime !</p>
            ) : (
              ''
            )}
          </>
        </Modal.Body>
        <Modal.Footer>
          {Status ? (
            <Button variant="primary" disabled>
              <Spinner animation="border" size="sm" />
              &nbsp;Send
            </Button>
          ) : (
            <Button variant="primary" onClick={(e) => sendNotification()}>
              Send
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
