import React, { useRef, useEffect } from 'react'
import { ExistingData, InitializeCountry } from "../../../Utility/Country";

export const StateCity = ({ setValue, value, Edit }) => {
    console.log(Edit)
console.log(value)
    let countryRef = useRef()
    let stateRef = useRef()
    let cityRef = useRef()

    useEffect(() => {

        InitializeCountry(countryRef, stateRef, cityRef)

        if (Edit) {
            console.log('Edit.country :',Edit.country)
            console.log('Edit.state :',Edit.state)
            console.log('Edit.city :',Edit.city)
            value.country=Edit.country
            ExistingData(countryRef, stateRef, cityRef, Edit.country, Edit.state, Edit.city)

        }

        return () => {

        }
    }, [])


    return (
        <>
            <div className="col-md-12">
                <label className="labels">Country * </label>
                <select
                    required
                    ref={countryRef}
                    className="form-select"
                    value={value.country}
                    // id="State" 
                    name="City"
                    onChange={(e) => setValue((prev) => ({ ...prev, country: e.target.value }))}
                >
                    {/* <select onChange={(e) =>}  className="form-select" >
                                          </select> */}
                </select>
                <div class="invalid-feedback">
                    PLEASE SELECT Country
                </div>
            </div>
            <div className="col-md-12">
                <label className="labels">State / Province *</label>
                <select
                    ref={stateRef}
                    required
                    className="form-select"
                    value={value.state}
                    // id="State"
                    onChange={(e) => setValue((prev) => ({ ...prev, state: e.target.value }))}

                    name="City"
                // onChange={(e) => Cityshow(e)}
                >
                    {/* <select onChange={(e) =>}  className="form-select" >
                                          </select> */}
                </select>
                <div class="invalid-feedback">
                    PLEASE SELECT State / Province
                </div>
            </div>
            <div className="col-md-12">
                <label className="labels">City *</label>
                <select
                    ref={cityRef}
                    required
                    className="form-select"
                    name="City"
                    value={value.city}
                    // id="City"
                    onChange={(e) => setValue((prev) => ({ ...prev, city: e.target.value }))}

                // onChange={(e) =>
                //     setValue({ ...value, city: e.target.value })
                // }
                >
                    <option></option>
                </select>
                <div class="invalid-feedback">
                    PLEASE SELECT City
                </div>
            </div>
        </>
    )
}
