
import React,{useState,useEffect} from 'react'
import axios from 'axios'
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
export default function SME_created_accounts(props){
  useEffect(() => {
      load()
  }, [])
  const [data, setData] = useState([])
  const load=()=>{
      axios.get('/api/SuperAdmin/Admin_created_accounts_by_SME',).then((res)=>{
          if(res.data){
              console.log(res.data)
              setData(res.data)
          }
      })   
  }
var    columns=[
      {
          field: "id",
          headerName: "No. ",
          width: 60,
        },
        {
          field: "pname",
          headerName: "Name",
          width: 200,
        },
        {
          field: "state",
          headerName: "State",
          width: 200,
        },
        {
          field: "city",
          headerName: "City",
          width: 200,
        },
        {
          field: "subcategorys",
          headerName: "Subcategory",
          width: 200,
        },
        {
          field: "categorys",
          headerName: "Category",
          width: 200,
        },
        {
          field: "created_date",
          headerName: "Created On",
          width: 200,
        },
        {
          field: "Account_holder_account_subscription_type",
          headerName: "Membership Type",
          width: 200,
        },
        {
          field: "subadmin",
          headerName: "SME",
          width: 200,
        },
  ]
return (
  <div className='container-fluid bg-light'>
  <Box sx={{ height: 600, width: '100%' }}>
    <DataGrid
      rows={data}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
      pageSizeOptions={[5]}
      // checkboxSelection
      // disableRowSelectionOnClick
    />
  </Box>

  <>
  </>

  </div>
)
}

