import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ActivistSocialReg() {
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState(false);


    const notify = (message, sab) => {
      if (sab) {
        toast.success(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.warning(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };



    const [value, setValue] = useState({

      name: "",
      phone: "",
        Description:"",
        Address:"",
        email:"", 
      });

      const dasapppan= async(e)=>{


        if(!value.name)return seterror("Name is missing !");


        if (!value.phone&&!value.email) {
          return seterror('Enter your phone number or email')
      }


      if(value.email)
      {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value.email)) {
          // setError('')
      }
      else {
          return seterror('Invalid Email')
      }
     
      }
        




        setloading(true)
        let sab = await  axios.post('/api/Admin/ActivistSocialReg',{
            value
      }).then((res)=>{
        if(res.status==200){
          notify("Successfully added", true);
         setValue({
          ...value,
          name: "",
          phone: "",
            Description:"",
            Address:"",
            email:"", 
         })
          
        }
      }).catch((err)=>{
        console.log(err)
        notify(" Something went wrong", false);
        setValue({
          ...value,
          name: "",
          phone: "",
            Description:"",
            Address:"",
            email:"", 
     
         })
      })
      setloading(false)
          
      }


     
     
  return (
    <div className="container pt-5 mt-3">
        <h1>Activist Social</h1>
        <div className='row'>
            <div className='col'><label className="labels">Name Of Activist / Activist Organization</label></div><div className='col'><input className="form-control" type="text"  value={value.name}
                    onChange={(e) =>
                      setValue({ ...value, name: e.target.value })
                    }></input></div>
        </div><br />

        <div className='row'>
            <div className='col'>  <label className="labels">Description</label></div><div className='col'>
              <textarea value={value.Description} className="form-control"
                    onChange={(e) =>
                      setValue({ ...value, Description: e.target.value })
                    }></textarea>
           </div>
        </div><br />
        <div className='row'>
            <div className='col'>  <label className="labels">Contact Number</label></div><div className='col'>
            <input type="number"  value={value.phone} className="form-control"
                    onChange={(e) =>
                     { setValue({ ...value, phone: e.target.value })}
                    }></input></div>
        </div><br />

        <div className='row'>
            <div className='col'>  <label className="labels"> Address</label></div><div className='col'>
              <textarea value={value.Address} className="form-control"
                    onChange={(e) =>
                      setValue({ ...value, Address: e.target.value })
                    }></textarea>
           </div>
        </div><br />

        <div className='row'>
            <div className='col'>  <label className="labels">Email Id</label></div><div className='col'>
            <input type="text"  value={value.email} className="form-control"
                    onChange={(e) =>
                     { setValue({ ...value, email: e.target.value })}
                    }></input></div>
        </div><br />
        <div className="row">
            <div className="col"><p style={{"color":'red'}}>{error?error:''}</p></div>
            <div className="col">
            {!loading &&   <button className="btn btn-success" onClick={(e)=>dasapppan(e)}>Add</button> }
            {loading &&   <button className="btn btn-success" disabled><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button> }

            </div>
        </div>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    </div>
  )
}
