import React,{useState,useEffect} from 'react'
import axios from 'axios'
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import { Footer } from '../../../Pages/HomePage/Footer';
import { FaRupeeSign } from 'react-icons/fa';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '300',
    },
  },
};
export const Salary_remittances_for_SME = (props) => {
    const [data, setdata] = useState([])
    useEffect(() => {
      props.Auth1()
    axios.get('/api/AffiliatedAccount/Salary_Remittances_For_SME_Get').then((res)=>{
      if (res.status==200) {
        setdata(res.data)
      }
        console.log(res.data)
    }).catch((err)=>{
        console.log(err)
    })
    }, [])
    var    columns=[
        {
            field: "id",
            headerName: "No. ",
            width: 60,
          },
          {
            field: "month",
            headerName: "Month",
            width: 200,
          },
          {
            field: "amounts",
            headerName: (
              <>
                Amount (<FaRupeeSign />)
              </>
            ),
            width: 150,
          },
          {
            field: "Status",
            headerName: "Status",
            width: 150,
          },
          {
            field: "created_on",
            headerName: "Credited On",
            width: 150,
          },
          {
            field: "PaymentId",
            headerName: "Transaction Id",
            width: 200,
          },          
    ]
    
  return (
    <div className='bg-light'>
        <div>
        <div className='pt-3 px-3'>
          <div className='pb-3'><center><h4>SALARY REMITTANCES</h4></center></div>
       <Box sx={{ height: 700, width: '100%' }}>
      <DataGrid
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        // checkboxSelection
        // disableRowSelectionOnClick
      />
    </Box>
       </div>
        </div>

        <div>
            <Footer/>
        </div>
    </div>

  )
}
