import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import Moment from 'moment';
import { useEffect, useState } from "react";
import Axios from 'axios'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { SpinnerBig } from "../../../Utility/spinner";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";




export function Expired_account_by_admin() {
  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [loader, setloader] = useState(<div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>)
  const [Loading, setLoading] = useState(true)
  const [reportedAbuse, setreportedAbuse] = useState(false)
  useEffect(() => {
    console.log('Expired_account_admin')
    Axios.get('/api/SuperAdmin/Expired_account_admin')
      .then(res => {
        if (res.status === 200) {
          console.log('Expired_account_admin')
          console.log(res.data)
          setreportedAbuse(res.data)
          setLoading(false)
        }
      }).catch((err) => {
        console.log(err)

      })
  }, [])

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [Mail, setMail] = useState({
    Acc_id:'',
    Login_id:'',
    pname:'',
    firstname:'',
    Login_mail:'',
    Acc_mail:'',
    description:""
  })
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
const [index, setindex] = useState()

  
    const handleClose = () =>{
      setShow(false);

      setMail({
        ...Mail,
        Acc_id:'',
        Login_id:'',
        pname:'',
        firstname:'',
        Login_mail:'',
        Acc_mail:'',
        description:""
  
      })
  
    } 

  function SetForMail(row,index) {
    console.log(row)
    setindex(index)
    setMail({
      ...Mail,
      Acc_id:row.login[0]._id,
      Login_id:row.login[0].Login_id,
      pname:row.login[0].pname,
      firstname:row.firstname,
      Login_mail:row.Email,
      Acc_mail:row.login[0].email,

    })
    handleShow()    
  }
const [loding, setloding] = useState(false)
  function SendMail() {
    console.log(Mail)
    setloding(true)
    if (Mail.description.length === 0) return notify(" Write some content", false);
    Axios.post('/api/SuperAdmin/Expired_account_normal_mail',{Mail})
      .then(res => {
        if (res.status === 200) {
          console.log('Expired_account_normal')
          console.log(res.data)
          notify(" Mail sended successfully", true)
          handleClose()
          setloding(false)
        }
      }).catch((err) => {
        console.log(err)
        notify("Something went wrong", false)
        setloding(false)


      })
    
  }


var a=0

  return (
    <div className="bg-light p-3">
      <div className="">
        <h2 className='p-1'>Expired Account Created By Executive</h2>          
              {
            Loading?
            <SpinnerBig/>
            :''
        }

{
       reportedAbuse?
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" responsive>
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>State</TableCell>
              <TableCell>City</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Sub category</TableCell>
              <TableCell>Sorted categorisation</TableCell>
              <TableCell>Current Membership Type</TableCell>
              <TableCell>Previous Year's Subscription Fees</TableCell>
              <TableCell>Subscription Expiry Date</TableCell>
              <TableCell></TableCell>



            </TableRow>
          </TableHead>
          <TableBody>
            {
           reportedAbuse.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,index) => (
              
                row.login.length>0?
                <>
                {/* {a++} */}
                          <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                 {a=+1}
                </TableCell>
                <TableCell component="th" scope="row" >
                 {row.login[0].pname  ?row.login[0].Type==="Consultant"||row.login[0].Type==="Consultantv2"?<Link style={{"textDecoration":"none","color":"black"}} to={`/consultant_profile?Ac_id=${row.login[0]._id}`}>{row.login[0].pname || row.login[0].firstname}</Link>:
                 <Link style={{"textDecoration":"none","color":"black"}} to={`/vendor_profile?Ac_id=${row.login[0]._id}`}>{row.login[0].pname ||row.login[0]?.firstname}</Link> : row.login[0].pname ||row.login[0]?.firstname}
                </TableCell>
                <TableCell >{row.login[0].state?row.login[0].state:"-"}</TableCell>
                <TableCell >{row.login[0].city?row.login[0].city:"-"}</TableCell>
                <TableCell >{row.login[0].category1[0]?row.login[0].category1[0].category:"-"}</TableCell>
                <TableCell >{row.login[0].subcategory1[0]?row.login[0].subcategory1[0].sub:"-"}</TableCell>
                <TableCell >{row.login[0].brand[0]?row.login[0].brand[0].brand:"-"}</TableCell>
                <TableCell >{row.login[0].Type=="Consultant"||row.login[0].Type=="Product"?"PRIME":row.login[0].Type=="Consultantv2"||row.login[0].Type=="Productv2"?"BASIC":"PASSIVE"}</TableCell>
                <TableCell >{row.login[0].Payments1[0]?row.login[0].Payments1[0].amount:"-"}</TableCell>
                <TableCell >{row.login[0].Validity?Moment(row.login[0].Validity).format('DD-MM-YYYY'):"-"}</TableCell>
                <TableCell><button className='btn btn-warning' onClick={(e)=>SetForMail(row,index)}>Send Mail</button> </TableCell>






              </TableRow>
                </>
              
      

                :
                <></>
              
              

            ))
            }
          </TableBody>
        </Table>
        <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={reportedAbuse.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </TableContainer>

        :
        <center>
        <h1>No Members to show</h1>
        </center>

    }



      </div>
      <Modal show={show} onHide={handleClose} backdrop={false} id={index} aria-labelledby="contained-modal-title-vcenter"centered >
        <Modal.Header closeButton>
          <Modal.Title>Send mail </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container'>
          <label className="labels">Content *</label>
            <textarea className='form-control' onChange={(e)=>setMail({...Mail,description:e.target.value})} ></textarea>

          </div>
        </Modal.Body>
        <Modal.Footer>
          {
        loding?
        <button className="btn pro-spaces-button3" disabled>
        Send      </button>
      :
      <button className="btn pro-spaces-button3" onClick={(e)=>SendMail()} >
      Send
    </button>

           
          }

        </Modal.Footer>
      </Modal>
      <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    </div>
  )
}

