import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';

export const Couponviewsme = () => {
  const [state, setState] = useState([]);

  useEffect(() => {
    axios
      .post('/couponsmeame/couponsmefindviewsme')
      .then((res) => {
        console.log(res.data);
        const newstate = res.data?.map((elem, index) => {
          console.log(elem);
          return {
            ...elem,
            id: index + 1,
          };
        });
        console.log(newstate);
        setState(newstate);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'Coupon_Name',
      headerName: 'Coupon Name',
      width: 150,
      editable: true,
    },
    {
      field: 'Discount_PandA',
      headerName: 'Discount Percentage/Amount',
      width: 180,
      editable: true,
    },
    {
      field: 'MomentDate',
      headerName: 'Assigned On',
      type: 'number',
      width: 110,
      editable: true,
    },
  ];
  return (
    <>
      <div
        style={{
          height: 550,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
        className="px-3"
      >
        <DataGrid
          rows={state}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          className="mt-5 pt-3 sswewe"
        />
      </div>
    </>
  );
};
