import React, { useState, useEffect, useRef } from "react";
import Axios from 'axios';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Form } from 'react-bootstrap';
import { DataGrid } from '@mui/x-data-grid';
import Categorys from "../categorys/Categorys";
import { Box } from "@mui/material";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '300',
    },
  },
};

export const ProForum = () => {

  useEffect(() => {
    reload();
    // reload1();
    GetAllTopics()
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => {setShow(false);
    setValue({
      Topic:""
    })
  }
  const handleShow = (id) => {
    setShow(true)
};
const [show1, setShow1] = useState(false);

const handleClose1 = () => {setShow1(false)
  setService_provide_category([])
  setValue({...value,
    Topic:"",
    UpdateID:"",
  })
};
const handleShow1 = (id,Topic,Category) => {
  console.log("Id :", id)
  setService_provide_category(Category)
  setValue({...value,
    Topic:Topic,
    UpdateID:id,
  })
  setShow1(true)  
};

const [show2, setShow2] = useState(false);
const [DeleteID, setDeleteID] = useState("")
const handleClose2 = () => {setShow2(false)
  setDeleteID("")
};
const handleShow2 = (id) => {
  setDeleteID(id)
  setShow2(true)
};

const [value, setValue] = useState({
  Topic:"",
  UpdateID:"",
});

const [Product_retailer_category, setProduct_retailer_category] =
  React.useState([]);
const [Service_provide_category, setService_provide_category] =
  React.useState([]);
const [Cat, setCat] = useState([]);
function reload() {
  Axios.get('/api/api/getcategoryALL')
    .then((res) => {
      if (res.status === 200) {
        console.log('workd');
        console.log(res.data);
        setCat(res.data);
      }
    })
    .catch((err) => {
      // console.log(err.response.status);
    });
}
const [Cat1, setCat1] = useState([]);

// function reload1() {
//   Axios.get('/api/api/getprocategory')
//     .then((res) => {
//       if (res.status === 200) {
//         console.log('workd');
//         console.log(res.data);
//         setCat1(res.data);
//       }
//     })
//     .catch((err) => {
//       // console.log(err.response.status);
//     });
// }

useEffect(() => {
  if (value.Service_provide_category_All) {
    setService_provide_category([]);
  }
  if (value.Product_retailer_category_All) {
    setProduct_retailer_category([]);
  }
}, [value.Service_provide_category_All, value.Product_retailer_category_All]);
const handleChange1 = (event) => {
  const {
    target: { value },
  } = event;
  setService_provide_category(
    // On autofill we get a stringified value.
    typeof value === 'string' ? value.split(',') : value
  );
};

const handleChange2 = (event) => {
  const {
    target: { value },
  } = event;
  setProduct_retailer_category(
    // On autofill we get a stringified value.
    typeof value === 'string' ? value.split(',') : value
  );
};

const ADD=()=>{
  if (Validation()!=true) {
    return
  }
  // in this Product_retailer_category this have both Product_retailer_category and Product_retailer_category
  Axios.post('/api/Admin/AddProForumTopic',{value,Service_provide_category})
  .then((res) => {
    if (res.status === 200) {
      console.log('workd');
      console.log(res.data);
      setProduct_retailer_category([])
      setValue({
        Topic:""
      })
      GetAllTopics()
      reload()
      handleClose()
    }
  })
  .catch((err) => {
    // console.log(err.response.status);
  });
}
///////////////////////////////Data Grid
const [Topic, setTopic] = useState([])
const columns = [
  { field: 'id', headerName: 'No.', width: 70 },
  { field: 'Topic', headerName: 'Topic', width: 130 },
  {
    field: 'Update',
    headerName: 'Update',
    width: 150,
    renderCell: (params) => {
      return (
          <button className="btn pro-spaces-button3" onClick={(e)=>handleShow1(params.row._id,params.row.Topic,params.row.Category)}  >Update</button>
      );
    },
  },
  {
    field: 'Delete',
    headerName: 'Delete',
    width: 150,
    renderCell: (params) => {
      return (
        <>
      {        params.row.PostCount==0?
        <button className="btn btn-danger" onClick={(e)=>handleShow2(params.row._id)}  >Delete</button>

        :""}
          </>
      );
    },
  },
];

const GetAllTopics=()=>{
  Axios.get('/api/Admin/GetAllTopics')
  .then((res) => {
    if (res.status === 200) {
      console.log(res.data)
      setTopic(res.data)
    }
  })
  .catch((err) => {
    console.log(err);
  });

}

const UpdateTopic=()=>{
  console.log(value)
  if (Validation()!=true) {
    return
  }
  
  Axios.post('/api/Admin/UpdateProForumTopic',{value,Service_provide_category})
  .then((res) => {
    if (res.status === 200) {
      GetAllTopics()
      reload()
      handleClose1()

    }
  })
  .catch((err) => {
    console.log(err);
  });
}

const Delete=()=>{
  Axios.post('/api/Admin/DeleteProForumTopic',{DeleteID})
  .then((res) => {
    if (res.status === 200) {
      GetAllTopics()
      reload()
      handleClose2()

    }
  })
  .catch((err) => {
    if (err.response.status==405) {
      GetAllTopics()
      reload()
      handleClose2()
      alert("Sorry there is a post exist in this topic ")
    }
    console.log(err);
  });
}
const [Error, setError] = useState("")
const Validation=()=>{
  if (!value.Topic) {
    return setError("Enter topic name")
  }
  setError("")
  if (Service_provide_category.length===0) {
    return setError("Select Category")
  }
  setError("")
  return true
}

  return (
    <>
    <div className='bg-light container'>
      <div className='pt-3 pb-3'>
     <h3><center>ProForum</center></h3>
     <div className='px-3'>
      <div className='d-flex justify-content-end' ><button className='btn pro-spaces-button3' onClick={(e)=>handleShow()}>Add Topic</button></div>
     </div>
     <div >
     <Box sx={{ height: 800, width: '100%' }}>
            <DataGrid
              rows={Topic}
              columns={columns}
              // Other DataGrid props...
            />
          </Box>
     </div>
     </div>
    </div>
    {/* Add */}
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add topic</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div>
          <div>
          <FormControl variant="outlined" className="form-control mt-2">
  <InputLabel htmlFor="outlined-adornment-password">
    {value.Topic ? 'Topic' : 'Topic *'}
  </InputLabel>
  <OutlinedInput
    type="text"
    value={value.Topic}
    onChange={(e) => {
      setValue({ ...value, Topic: e.target.value });
    }}
    label="Topic"
  />
</FormControl>

            </div>
           
          {/* <Form>
              <div className="row mt-1">
                <div className="col">
                  <Form.Check
                    type="checkbox"
                    label="All service provide category's"
                    id="prime-checkbox"
                    checked={value.Service_provide_category_All ? true : false}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        Service_provide_category_All:
                          !value.Service_provide_category_All,
                      })
                    }
                  />
                </div>
              </div>
            </Form> */}
            <div className="pt-3">
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  category *
                </InputLabel>
                <Select
                  // disabled={value.Service_provide_category_All ? true : false}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={Service_provide_category}
                  onChange={handleChange1}
                  input={<OutlinedInput label="category" />}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (categoryId) =>
                          Cat.find((cat) => cat._id === categoryId)?.category ||
                          ''
                      )
                      .join(', ')
                  }
                  MenuProps={MenuProps}
                >
                  {Cat.map((name) => {                   
                     var newName1=name.category ;
                     var newName2=name.type?"(Services Provider)":"(Product Retailer)"
                    var newName=newName1+newName2
                    //  console.log(newName)
               return     <MenuItem key={name._id} value={name._id}>
                      <Checkbox
                        checked={
                          Service_provide_category.indexOf(name._id) > -1
                        }
                      />
                      <ListItemText primary={newName} />
                    </MenuItem>})}
                </Select>
              </FormControl>
            </div>

            {/* <Form>
              <div className="row mt-1">
                <div className="col">
                  <Form.Check
                    type="checkbox"
                    label="All Product retailer category's"
                    id="prime-checkbox"
                    checked={value.Product_retailer_category_All ? true : false}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        Product_retailer_category_All:
                          !value.Product_retailer_category_All,
                      })
                    }
                  />
                </div>
              </div>
            </Form>
            <div className="mt-1">
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Product retailer category's
                </InputLabel>
                <Select
                  disabled={value.Product_retailer_category_All ? true : false}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={Product_retailer_category}
                  onChange={handleChange2}
                  input={<OutlinedInput label="Product retailer category's" />}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (categoryId) =>
                          Cat1.find((cat1) => cat1._id === categoryId)
                            ?.category || ''
                      )
                      .join(', ')
                  }
                  MenuProps={MenuProps}
                >
                  {Cat1.map((name) => (
                    <MenuItem key={name._id} value={name._id}>
                      <Checkbox
                        checked={
                          Product_retailer_category.indexOf(name._id) > -1
                        }
                      />
                      <ListItemText primary={name.category} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div> */}
            <div>
              <span><p style={{color:"red"}}>{Error?Error:""}</p></span>
            </div>
          </div>
          
        </Modal.Body>
        <Modal.Footer>
        <button className="btn pro-spaces-button3" onClick={(e)=>ADD()}  >Add</button>
        </Modal.Footer>
      </Modal>

{/* Update */}
      <Modal show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update topic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
          <div>
          <FormControl variant="outlined" className="form-control mt-2">
  <InputLabel htmlFor="outlined-adornment-password">
    {value.Topic ? 'Topic' : 'Topic *'}
  </InputLabel>
  <OutlinedInput
    type="text"
    value={value.Topic}
    onChange={(e) => {
      setValue({ ...value, Topic: e.target.value });
    }}
    label="Topic"
  />
</FormControl>

            </div>

            <div className="pt-3">
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  category *
                </InputLabel>
                <Select
                  // disabled={value.Service_provide_category_All ? true : false}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={Service_provide_category}
                  onChange={handleChange1}
                  input={<OutlinedInput label="category" />}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (categoryId) =>
                          Cat.find((cat) => cat._id === categoryId)?.category ||
                          ''
                      )
                      .join(', ')
                  }
                  MenuProps={MenuProps}
                >
                  {Cat.map((name) => {                   
                     var newName1=name.category ;
                     var newName2=name.type?"(Services Provider)":"(Product Retailer)"
                    var newName=newName1+newName2
                    //  console.log(newName)
               return     <MenuItem key={name._id} value={name._id}>
                      <Checkbox
                        checked={
                          Service_provide_category.indexOf(name._id) > -1
                        }
                      />
                      <ListItemText primary={newName} />
                    </MenuItem>})}
                </Select>
              </FormControl>
            </div>
            <div>
              <span><p style={{color:"red"}}>{Error?Error:""}</p></span>
            </div>

          </>
          
        </Modal.Body>
        <Modal.Footer>
        <button className="btn pro-spaces-button3" onClick={(e)=>UpdateTopic()}   >Update</button>
        </Modal.Footer>
      </Modal>

      {/* Delete */}
      <Modal show={show2} onHide={handleClose2} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete topic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
               <h4>Are you sure ?</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
        <button className="btn pro-spaces-button3"  onClick={(e)=>Delete()}  >Delete</button>
        </Modal.Footer>
      </Modal>
    </>
    
  )
}


