function keyGen() {
    var length = 5;
    var result = "";
    var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const OTTPlatformList = [
    'Amazon Prime Video',
    'Discovery+',
    'Disney+ Hotstar',
    'Eros Now',
    'MX Player',
    'ManoramaMAX',
    'SonyLIV',
    'Sun NXT',
    'Voot',
    'YuppTV',
    'Zee5',
'AAO NXT',
'Addatimes',
'Aha',
// 'Airtel Xstream Premium',
'ALTBalaji',
'Apple TV+',
'Arre',
'Asianet Mobile TV',
'BIGFlix',
'Crunchyroll',
'dittoTV',
'Hayu',
'Hoichoi',
'Hooq',
'Hungama Play',
'Klikk',
'Lionsgate Play',
'Mubi',
'Netflix',
'OHO Gujarati',
'Planet Marathi',
'ShemarooMe',
'Spuul',
'TVFPlay',
'kanccha lannka',
'Ullu App',
'Viki',
'Viu',
'Vuclip',
];

const bloodgrouplist=['A+','A-','B+','B-','O+','O-','AB+','AB-']

const GenreList=['Action','Animation','Comedy','Crime','Drama','Experimental','Fantasy','Historical','Horror',
             'Romance','Science Fiction','Thriller','Western','Musical','War','Biopics', ]
const AdminType=['AdminType1','AdminType2','AdminType3','AdminType4','AdminType5',]   
const DTHOperator=['Airtel Digital TV','Dish TV','Sun Direct','Tata Play','Videocon d2h'] 
const DTHPlans=['Assamese','Bengali','Bhojpuri','English','Gujarati','Hindi',
                'Kannada','Malayalam','Marathi','North East','Odia','Others',
                'Punjabi','Tamil','Telugu','Urdu']  
                
const currencyList = [
  { country: "India", code: "INR", symbol: "₹" },
  { country: "America", code: "USD", symbol: "$" },
  { country: "Canada", code: "CAD", symbol: "CA$" },
  { country: "Germany", code: "EUR", symbol: "€" },
  { country: "Australia", code: "AUD", symbol: "A$" },
  // Add more currencies as needed
];               
const unitOfMeasurementList = [
  "m", // Meter
  "cm", // Centimeter
  "mm", // Millimeter
  "in", // Inch
  "ft", // Foot
  "yd", // Yard
  "mi", // Mile
  "g", // Gram
  "kg", // Kilogram
  "oz", // Ounce
  "lb", // Pound
  "L", // Liter
  "ml", // Milliliter
  "cup", // Cup
  "tsp", // Teaspoon
  "tbsp", // Tablespoon
  "day", // Day
  "week", // Week
  "month", // Month
  "year", // Year
  // Add more units as needed
];







export { keyGen,MenuProps,OTTPlatformList,bloodgrouplist,GenreList,AdminType,DTHOperator,DTHPlans,currencyList,unitOfMeasurementList}