import React from 'react';
import { ControlBar } from './ControlBar';
import { NavBar } from './NavBar';
import { ContentSection } from './ContentSection';
import { Footer } from './Footer';

export const Homepage = () => {
  return (
    <>
      <div id="foooo" style={{ backgroundColor: 'white' }}>
        <ControlBar />
        <NavBar />
        <ContentSection />
      </div>

      <div className=" ">
        <Footer />
      </div>
    </>
  );
};
