import React, { useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import {
  useNavigate,
  useSearchParams,
  Link,
  useParams,
} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Form } from 'react-bootstrap';
import Admin_executive_earnings_in_profile_page from './Admin_executive_earnings_in_profile_page';
import FME_executive_earnings_in_profile_page from './FME_earnings_in_profile_page';
import { SpinnerBig } from '../../Utility/spinner';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { BiDownload } from 'react-icons/bi';
import Handlebars from 'handlebars';
import axios from 'axios';
const notify = (message, sab) => {
  if (sab) {
    toast.success(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.warning(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '300',
    },
  },
};
export const SMEProfile = () => {
  let [searchParams, setSearchParams] = useSearchParams(); /////getting id from the url the id of the jobs id
  var Id = searchParams.get('k');
  // finduserdata pass fail pause
  var id = searchParams.get('k');

  const [findUD, setFindUD] = useState();
  useEffect(() => {
    axios
      .post('/api/Admin/userdatafind', { id })
      .then((req) => {
        console.log('Assdaxz');
        console.log(req.data);
        setFindUD(req.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // finduserdata pass fail pause

  const [value, setValue] = useState({
    Id: '',
    Type: '',
    Name: '',
    Email: '',
    Contact: '',
    state: '',
    city: '',
    Account_holder_name: '',
    Account_number: '',
    Ifsc_code: '',
    Bank_name: '',
    Branch_name: '',
    Status: '',
    Bank_account_type: '',
    Gender: '',
    Age: '',
    Nationality: '',
    country: '',
    Address: '',
    Pincode: '',
    Work_Experience: '',
    FMName: '',
    FMRelationship: '',
    FMContact: '',
    FMAddress: '',
    FMPincode: '',
    FMcountry: '',
    FMstate: '',
    FMcity: '',
    BloodGroup: '',
  });
  // const [Counts, setCounts] = useState({
  //     total:0,
  //     thisWeek:0,
  //     thisMonth:0
  // })
  //     useEffect(() => {
  //     Axios.post('/api/SuperAdmin/CountOfAccountCreatedByFME',{ Id }).then((res)=>{
  //         if (res.data) {
  //             console.log(res.data)
  //         }
  //     })
  //     }, [])
  const [Loading, setLoading] = useState(true);

  React.useEffect(() => {
    setLoading(true);
    Axios.post('/api/Admin/GetOneSubAdmin', { Id })
      .then((res) => {
        if (res.data) {
          console.log(res.data);

          setValue({
            ...value,
            Role: res.data[0].Role,
            Login_id: res.data[0].Login_id,
            Id: res.data[0]._id,
            Type: res.data[0].con[0].Type,
            Name: res.data[0].Name,
            Email: res.data[0]?.con[0]?.Email,
            Contact: res.data[0]?.con[0]?.Contact,
            state: res.data[0]?.state,
            city: res.data[0]?.city,
            Adhar: res.data[0]?.Adhar,
            Account_holder_name:
              res.data[0].con[0].Type == 'AffiliatedAccount'
                ? res.data[0].Account_holder_name
                : res.data[0]?.con[0]?.acc[0]?.Account_holder_name,
            Account_number:
              res.data[0].con[0].Type == 'AffiliatedAccount'
                ? res.data[0].Account_number
                : res.data[0]?.con[0]?.acc[0]?.Account_number,
            Ifsc_code:
              res.data[0].con[0].Type == 'AffiliatedAccount'
                ? res.data[0].Ifsc_code
                : res.data[0]?.con[0]?.acc[0]?.Ifsc_code,
            Bank_name:
              res.data[0].con[0].Type == 'AffiliatedAccount'
                ? res.data[0].Bank_name
                : res.data[0]?.con[0]?.acc[0]?.Bank_name,
            Branch_name:
              res.data[0].con[0].Type == 'AffiliatedAccount'
                ? res.data[0].Branch_name
                : res.data[0]?.con[0]?.acc[0]?.Branch_name,
            Referral_Code: res.data[0]?.Referral_Code,
            Status: res.data[0].con[0].Status,
            Bank_account_type:
              res.data[0].con[0].Type == 'AffiliatedAccount'
                ? res.data[0].Bank_account_type
                : res.data[0]?.con[0]?.acc[0]?.Bank_account_type,
            Gender: res.data[0]?.Gender,
            Age: res.data[0]?.Age,
            Nationality: res.data[0]?.Nationality,
            unique_Code: res.data[0]?.unique_Code,

            country: res.data[0]?.country,
            Address: res.data[0]?.Address,
            Pincode: res.data[0]?.Pincode,
            Work_Experience: res.data[0]?.Work_Experience,
            Highest_Educational_Qualification:
              res.data[0]?.Highest_Educational_Qualification,
            Ready_to_Join_on_anyday_after:
              res.data[0]?.Ready_to_Join_on_anyday_after,
            ED_Pursuing_Education: res.data[0]?.ED_Pursuing_Education,
            ED_College: res.data[0]?.ED_College,
            ED_City: res.data[0]?.ED_City,
            ED_Period_of_Internship_Start:
              res.data[0]?.ED_Period_of_Internship_Start,
            ED_Period_of_Internship_End:
              res.data[0]?.ED_Period_of_Internship_End,
            ED_University: res.data[0]?.ED_University,

            Studentornot: res.data[0]?.Studentornot,
            FMName: res.data[0]?.Family_name,
            FMRelationship: res.data[0]?.Family_relationship,
            FMContact: res.data[0]?.Family_contact,
            FMAddress: res.data[0]?.Family_address,
            FMPincode: res.data[0]?.Family_pincode,
            FMcountry: res.data[0]?.Family_country,
            FMstate: res.data[0]?.Family_state,
            FMcity: res.data[0]?.Family_city,
            BloodGroup: res.data[0]?.BloodGroup,
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        notify('Something went wrong ', false);
      });
  }, []);
  // const modes = useRef()
  // const modalon = () => {
  //     modes.current(Id)
  // }
  const [Loginid, setLoginid] = useState('');
  const [suspendreason, setSuspendreason] = useState('');

  const updateStatus = () => {
    console.log(Loginid);
    var id = Loginid;
    var status = value.Status;
    var st1;
    if (value.Status == 'Active') {
      st1 = 'Suspended';
    } else {
      st1 = 'Active';
    }

    Axios.post('/api/SuperAdmin/UpdateSubAdminStatus', {
      id,
      status,
      suspendreason,
    })
      .then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          setValue({
            ...value,
            Status: st1,
          });
          setSuspendreason('');
          handleClose();
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          notify('Unbothered user', false);
        } else {
          notify('Something went wrong ', false);
        }
      });
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setSuspendreason('');
  };
  const handleShow = (id) => {
    setShow(true);
    setLoginid(id);
  };
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (id) => {
    setShow1(true);
    setLoginid(id);
  };

  var url;
  url = `https://s3.ap-south-1.amazonaws.com/prochure.in.profile/AffliateProfile/${value.Id}/avatar.png`;
  // url = `https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/AffliateProfile/${value.Id}/avatar.png`;

  return (
    <div className="bg-light px-2">
      {Loading && (
        <>
          <SpinnerBig />
        </>
      )}

      {!Loading && (
        <>
          <center>
            <h2>DCE Profile</h2>
          </center>
          <div className="container-fluid  py-3">
            <div className="mt-3">
              <div className="card" style={{ width: '130px', height: '130px' }}>
                <img
                  src={url}
                  onError={(e) => {
                    return (e.target.src = '/blank.png');
                  }}
                  style={{ width: '130px', height: '130px' }}
                />
              </div>
              <h4 className="py-2">{value.Name}</h4>
              <div className="d-flex flex-column  flex-md-row justify-content-lg-end">
                <div className="mx-1 py-1">
                  {value.Type != 'SUPERADMIN' ? (
                    <button
                      className="btn pro-spaces-button3"
                      onClick={(e) => handleShow(value.Login_id)}
                    >
                      {value.Status == 'Active'
                        ? 'Suspend this account'
                        : 'Activate this account'}
                    </button>
                  ) : (
                    ''
                  )}
                </div>
                {/* <div className="mx-1 py-1">
                  {value.Type != 'SUPERADMIN' ? (
                    <button
                      className="btn pro-spaces-button3"
                      onClick={(e) => handleShow1(value.Login_id)}
                    >
                      Send an Email
                    </button>
                  ) : (
                    ''
                  )}
                </div> */}
                {/* <div className="mx-1 py-1">
                  {value.Type != 'SUPERADMIN' ? <Appointmentletter /> : ''}
                </div> */}

                <div className="mx-1 py-1 ">
                  <Regionalreferralchange Id={value.Id} />
                </div>
                <div className="mx-1 py-1 ">
                  <SendRemarks />
                </div>
                <div className="mx-1 py-1 ">
                  <ViewRemarks />
                </div>
                {findUD.Pass === true ? (
                  <></>
                ) : (
                  <>
                    {findUD.Pause === true ? (
                      <>
                        <div className="mx-1 py-1 ">
                          <Fail />
                        </div>
                        <div className="mx-1 py-1 ">
                          <Pass />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div className="mx-1 py-1 ">
                      <Pause />
                    </div>
                  </>
                )}
                <div className="mx-1 py-1 ">
                  <ViewDMTargets value={value} />
                </div>
                <div className="mx-1 py-1 ">
                  <div class="dropdown">
                    <a
                      class="btn pro-spaces-button3 dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Appointment Letter
                    </a>

                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <li>
                        <Link
                          class="dropdown-item "
                          to="/create_appointment_letter"
                          style={{ paddingLeft: '12px' }}
                        >
                          Create Appointment Letter
                        </Link>
                      </li>
                      <li>
                        <Uploadappointmentletter id={Id} />
                      </li>
                      <li>
                        <Viewappointmentletter id={Id} />
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mx-1 py-1 ">
                  <div class="dropdown">
                    <Link
                      className="btn pro-spaces-button3 "
                      to={`/salessme?k=${value.Id}`}
                    >
                      Monthly Sales
                    </Link>
                  </div>
                </div>
                <div className="mx-1 py-1 ">
                  <div class="dropdown">
                    <Link
                      className="btn pro-spaces-button3 "
                      to={`/couponcreate_sme?k=${Id}`}
                    >
                      Add Coupon
                    </Link>
                  </div>
                </div>
                <div className="mx-1 py-1">
                  {value.Type != 'SUPERADMIN' ? (
                    <Category_Update Id={value.Id} />
                  ) : (
                    ''
                  )}
                </div>

                <div className="mx-1 py-1">
                  <Link
                    to={`/sme_salary_remittances?k=${value.Id}&&l=${value.Login_id}`}
                  >
                    <button className="btn pro-spaces-button3">
                      Salary remittances
                    </button>
                  </Link>
                </div>

                {/* <div className="mx-1 py-1">
                  <UpdateToAME Acc_id={value.Id} />
                </div> */}
                <div className="mx-1 py-1">
                  {value.Type != 'AffiliatedAccount' ? (
                    <>
                      <Link to={`/sub_admin_update?k=${value.Id}`}>
                        <button className="btn pro-spaces-button3">
                          Edit Profile
                        </button>
                      </Link>
                    </>
                  ) : (
                    ''
                  )}

                  {/* cal={modes} */}
                </div>
                <div className="mx-1 py-1 ">
                  {value.Type != 'AffiliatedAccount' ? (
                    <>
                      {/* <Link to={`/sub_admin_update?k=${value.Id}`}>
                        <button className="btn pro-spaces-button3">
                          Edit Profile
                        </button>
                      </Link> */}

                      <ChangePassword id={value.Login_id} />
                    </>
                  ) : (
                    ''
                  )}
                  {/* cal={modes} */}
                </div>
              </div>
            </div>
            <br />
            <h4 className="pt-2">Regional Manager Referral Code </h4>

            <div className="">Regional Referral Code</div>

            <div className="card ps-2 py-2">{value.unique_Code}</div>
            <br />
            <h4 className="pt-2">Personal Details </h4>

            <div className="pt-2">Email</div>
            <div className="card ps-2 py-2">
              <a
                href={`mailto:${value.Email}`}
                style={{ textDecoration: 'none', color: 'black' }}
              >
                {value.Email}
              </a>
            </div>

            <div className="pt-2">Contact</div>

            <div className="card ps-2 py-2">
              <a
                href={`tel:${value.Contact}`}
                style={{ textDecoration: 'none', color: 'black' }}
              >
                {value.Contact}
              </a>
            </div>

            <div className="pt-2">Age</div>

            <div className="card ps-2 py-2">{value.Age}</div>

            <div className="pt-2">Gender</div>

            <div className="card ps-2 py-2">{value.Gender}</div>

            <div className="pt-2">Nationality</div>

            <div className="card ps-2 py-2">{value.Nationality}</div>

            <div className="pt-2">Blood Group</div>
            <div className="card ps-2 py-2">{value.BloodGroup}</div>
            <div className="pt-2">Country</div>
            <div className="card ps-2 py-2">{value.country}</div>
            <div className="pt-2">State</div>
            <div className="card ps-2 py-2">{value.state}</div>
            <div className="pt-2">City</div>
            <div className="card ps-2 py-2">{value.city}</div>
            <div className="pt-2">Address</div>
            <div className="card ps-2 py-2">{value.Address}</div>
            <div className="pt-2">Pincode</div>
            <div className="card ps-2 py-2">{value.Pincode}</div>
            <div className="pt-2">Referral Code</div>
            <div className="card ps-2 py-2">{value.Referral_Code}</div>
            <br />
            <h4 className="pt-2">Identity Proof</h4>

            <div className="row">
              {value?.Adhar?.map((elem) => {
                console.log(elem);
                if (elem?.file != 'avatar.png') {
                  return (
                    <>
                      <div className="d-flex justify-content-center col-md-6 col-sm-12 g-2">
                        <img
                          src={elem.link}
                          style={{ width: '300px', height: '300px' }}
                        ></img>
                      </div>
                    </>
                  );
                }
              })}
            </div>
            {value.Studentornot == 'Student' ? (
              <>
                <br />
                <h4 className="pt-2">Education Details</h4>
                <div className="pt-2">Pursuing Educational Course</div>
                <div className="card ps-2 py-2">
                  {value.ED_Pursuing_Education}
                </div>
                <div className="pt-2">College</div>
                <div className="card ps-2 py-2">{value.ED_College}</div>
                <div className="pt-2">University</div>
                <div className="card ps-2 py-2">{value.ED_University}</div>
                <div className="pt-2">City</div>
                <div className="card ps-2 py-2">{value.ED_City}</div>
                <div className="pt-2">Period of Internship</div>
                <div className="row d-flex ps-3">
                  <div className="col-4 card ps-2 py-2">
                    {moment(value.ED_Period_of_Internship_Start).format(
                      'DD-MM-YYYY'
                    )}{' '}
                  </div>
                  <div className="col-2 d-flex justify-content-center align-items-center">
                    to
                  </div>
                  <div className="col-4 card ps-2 py-2">
                    {moment(value.ED_Period_of_Internship_End).format(
                      'DD-MM-YYYY'
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <br />

                <h4 className="pt-2">Qualification and Work Experience</h4>
                <div className="pt-2">Highest Educational Qualification</div>
                <div className="card ps-2 py-2">
                  {value.Highest_Educational_Qualification}
                </div>

                <div className="pt-2">Work Experience</div>
                <div className="card ps-2 py-2">{value.Work_Experience}</div>

                <div className="pt-2">Ready to Join on anyday after</div>
                <div className="card ps-2 py-2">
                  {moment(value.Ready_to_Join_on_anyday_after).format(
                    'DD-MM-YYYY'
                  )}
                </div>
              </>
            )}
            <br />

            <h4 className="pt-2">Bank Account Details</h4>

            <div className="pt-2">Account holder&nbsp;name</div>
            <div className="card ps-2 py-2">{value.Account_holder_name}</div>
            <div className="pt-2">Account number</div>
            <div className="card ps-2 py-2">{value.Account_number}</div>
            <div className="pt-2">Account IFSC&nbsp;code</div>
            <div className="card ps-2 py-2">{value.Ifsc_code}</div>
            <div className="pt-2">Bank name</div>
            <div className="card ps-2 py-2">{value.Bank_name}</div>
            <div className="pt-2">Bank account type</div>
            <div className="card ps-2 py-2">{value.Bank_account_type}</div>
            <br />

            <h4 className="pt-2">Contact Information of a Family Member</h4>

            <div className="pt-2">Full Name of Family Member</div>
            <div className="card ps-2 py-2">{value.FMName}</div>
            <div className="pt-2">Relationship with Family Member</div>
            <div className="card ps-2 py-2">{value.FMRelationship}</div>
            <div className="pt-2">Contact Number of Family Member</div>
            <div className="card ps-2 py-2">{value.FMContact}</div>
            <div className="pt-2">Residing Country of Family Member</div>
            <div className="card ps-2 py-2">{value.FMcountry}</div>
            <div className="pt-2">Residing State of Family Member</div>
            <div className="card ps-2 py-2">{value.FMstate}</div>
            <div className="pt-2">Residing City of Family Member</div>
            <div className="card ps-2 py-2">{value.FMcity}</div>
            <div className="pt-2">Pincode</div>
            <div className="card ps-2 py-2">{value.FMPincode}</div>

            {/* <div className="pt-2">Address of Family Member</div>
            <div className="card ps-2 py-2">{value.FMAddress}</div>

            <div className="pt-2">Residing Country of Family Member</div>
            <div className="card ps-2 py-2">{value.FMcountry}</div>
            <div className="pt-2">Residing State of Family Member</div>
            <div className="card ps-2 py-2">{value.FMstate}</div>
            <div className="pt-2">Residing City of Family Member</div>
            <div className="card ps-2 py-2">{value.FMcity}</div>
            <div className="pt-2">Pincode</div>
            <div className="card ps-2 py-2">{value.FMPincode}</div> */}
            <div className="mt-4">
              <h4 className="mt-3 ps-3">Profiles Created</h4>
              <FME_executive_earnings_in_profile_page id={Id} />
            </div>
          </div>
        </>
      )}

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Account Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            {value.Status == 'Active'
              ? ' Are you sure suspend this account ?'
              : 'Are you sure activate this account ?'}
          </h5>
          {value.Status == 'Active' ? (
            <div className="pt-3">
              <label class="form-label">Enter Reason</label>
              <textarea
                rows={5}
                class="form-control"
                onChange={(e) => setSuspendreason(e.target.value)}
              />
            </div>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn pro-spaces-button3"
            onClick={(e) => updateStatus()}
          >
            {value.Status == 'Active'
              ? 'Suspend this account'
              : 'Activate this account'}
          </button>
        </Modal.Footer>
      </Modal>
      {/* send an email */}
      <Modal show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <Modal.Title>Send an Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pt-3">
            <label className="labels">Date *</label>
            <input type="date" className="form-control" />
          </div>
          <div className="pt-3">
            <label className="labels">Monthly Salary *</label>
            <input type="text" className="form-control" />
          </div>
          <div className="pt-3">
            <label className="labels">Salary Break-up *</label>
            <input type="text" className="form-control" />
          </div>
          <div className="pt-3">
            <label className="labels">Target based Salary *</label>
            <input type="text" className="form-control" />
          </div>
          <div className="pt-3">
            <label className="labels">
              Allowances (towards conveyance and calls) *
            </label>
            <input type="text" className="form-control" />
          </div>
          <div className="pt-3">
            <label className="labels">
              Targets to achieve for the first Two Months *
            </label>
            <input type="text" className="form-control" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn pro-spaces-button3"
            onClick={(e) => updateStatus()}
          >
            Send
          </button>
        </Modal.Footer>
      </Modal>
      {/* send an email */}
    </div>
  );
};
function ChangePassword(props) {
  const [value, setValue] = useState({
    // id: props.id,
    Password: '',
    Pin: '',
    showPassword: false,
    showPin: false,
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  function ChangePassword1() {
    // console.log(props)
    // console.log(value)
    if (!value.Password) return setError('Password is missing !');

    if (value.Password.toString().length < 8) {
      return setError('Password must contain 8 characters !');
    }
    if (!value.Pin) return setError('security pin is missing !');

    setLoading(true);

    var values = {
      value,
      id: props.id,
    };
    // console.log(values)
    // return
    Axios.post('/api/SuperAdmin/ChangeSubAdminPassByAdmin', { values })
      .then((res) => {
        if (res.status === 200) {
          handleClose();
          setLoading(false);
          notify('Password updated', true);
          setValue({
            ...value,
            Password: '',
            Pin: '',
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          notify('Wrong security pin', false);
        } else {
          notify('Something went wrong ', false);
        }
        setLoading(false);
      });
  }
  const handleChange = (prop) => (event) => {
    setValue({ ...value, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValue({
      ...value,
      showPassword: !value.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword1 = () => {
    setValue({
      ...value,
      showPin: !value.showPin,
    });
  };

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <button
        onClick={() => handleShow()}
        type="button"
        className="btn pro-spaces-button3"
      >
        Change Password
      </button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControl variant="outlined" className="form-control mt-2">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={value.showPassword ? 'text' : 'password'}
              value={value.Password}
              // inputProps={{ maxLength: 10 }}
              onChange={handleChange('Password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {value.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control mt-2">
            {/* sx={{ m: 1, width: '25ch' }} */}
            <InputLabel htmlFor="outlined-adornment-password">Pin</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={value.showPin ? 'text' : 'password'}
              value={value.Pin}
              onChange={(e) => {
                if (e.target.value.length == 5) return false;
                setValue({ ...value, Pin: e.target.value });
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword1}
                    onMouseDown={handleMouseDownPassword1}
                    edge="end"
                  >
                    {value.showPin ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="pin"
            />
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          {/* <Button variant="primary" onClick={}>
                        Update Password
                    </Button> */}
          <div className="row mt-4">
            <div className="col">
              <p style={{ color: 'red' }}>{error ? error : ''}</p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col  d-flex justify-content-end">
              {!loading && (
                <button
                  className="btn pro-spaces-button3"
                  onClick={() => ChangePassword1()}
                >
                  Update Password
                </button>
              )}
              {loading && (
                <button className="btn btn-success" disabled>
                  Updating Password
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

const Category_Update = ({ Id }) => {
  useEffect(() => {
    reload();
    reload1();
  }, []);
  useEffect(() => {
    details();
  }, [Id]);

  const details = () => {
    Axios.post('/api/Admin/GetOneSubAdmin', { Id })
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          setValue({
            ...value,
            Service_provide_category_All:
              res.data[0]?.Service_provide_category_All,
            Product_retailer_category_All:
              res.data[0]?.Product_retailer_category_All,
          });
          setService_provide_category(
            res.data[0]?.Service_provide_category
              ? res.data[0]?.Service_provide_category
              : []
          );
          setProduct_retailer_category(
            res.data[0]?.Product_retailer_category
              ? res.data[0]?.Product_retailer_category
              : []
          );
        }
      })
      .catch((err) => {
        console.log(err);
        //  notify("Something went wrong 2", false);
      });
  };

  const [value, setValue] = useState({
    Service_provide_category_All: true,
    Product_retailer_category_All: true,
    Pin: '',
  });
  const [Product_retailer_category, setProduct_retailer_category] =
    React.useState([]);
  const [Service_provide_category, setService_provide_category] =
    React.useState([]);
  const [Cat, setCat] = useState([]);
  function reload() {
    Axios.get('/api/api/getcategory')
      .then((res) => {
        if (res.status === 200) {
          console.log('workd');
          console.log(res.data);
          setCat(res.data);
        }
      })
      .catch((err) => {
        // console.log(err.response.status);
      });
  }
  const [Cat1, setCat1] = useState([]);

  function reload1() {
    Axios.get('/api/api/getprocategory')
      .then((res) => {
        if (res.status === 200) {
          console.log('workd');
          console.log(res.data);
          setCat1(res.data);
        }
      })
      .catch((err) => {
        // console.log(err.response.status);
      });
  }
  const handleClickShowPassword1 = () => {
    setValue({
      ...value,
      showPin: !value.showPin,
    });
  };

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setService_provide_category(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleChange2 = (event) => {
    const {
      target: { value },
    } = event;
    setProduct_retailer_category(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const [Pins, setPins] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setPins('');
    setValue({
      Service_provide_category_All: true,
      Product_retailer_category_All: true,
      Pin: '',
    });
  };
  const handleShow = () => setShow(true);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (value.Service_provide_category_All) {
      setService_provide_category([]);
    }
    if (value.Product_retailer_category_All) {
      setProduct_retailer_category([]);
    }
  }, [value.Service_provide_category_All, value.Product_retailer_category_All]);

  const update = () => {
    if (
      !value.Product_retailer_category_All &&
      Product_retailer_category.length === 0
    ) {
      return setError('Select Product retailer category');
    }
    setError('');
    if (
      !value.Service_provide_category_All &&
      Service_provide_category.length === 0
    ) {
      return setError('Select Service provide category');
    }
    setError('');

    Axios.post('/api/SuperAdmin/UpdateCategoryForOneAME', {
      value,
      Service_provide_category,
      Product_retailer_category,
      Id,
      Pins,
    })
      .then((res) => {
        if (res.status == 200) {
          notify('Category updated', true);
          setService_provide_category([]);
          setProduct_retailer_category([]);
          setValue({
            ...value,
            Service_provide_category_All: true,
            Product_retailer_category_All: true,
            Pin: '',
          });
          reload();
          reload1();
          details();
          handleClose();
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          notify('Wrong security pin', false);
        } else {
          notify('Something went wrong ', false);
        }
      });
  };

  return (
    <>
      <button className="btn pro-spaces-button3" onClick={(e) => handleShow()}>
        Categories
      </button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update Categories</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Form>
              <div className="row mt-1">
                <div className="col">
                  <Form.Check
                    type="checkbox"
                    label="All service provide categories"
                    id="prime-checkbox"
                    checked={value.Service_provide_category_All ? true : false}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        Service_provide_category_All:
                          !value.Service_provide_category_All,
                      })
                    }
                  />
                </div>
              </div>
            </Form>
            <div className="mt-1">
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Service provide categories
                </InputLabel>
                <Select
                  disabled={value.Service_provide_category_All ? true : false}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={Service_provide_category}
                  onChange={handleChange1}
                  input={<OutlinedInput label="Service provide categories" />}
                  renderValue={(selected) =>
                    selected
                      ?.map(
                        (categoryId) =>
                          Cat.find((cat) => cat._id === categoryId)?.category ||
                          ''
                      )
                      .join(', ')
                  }
                  MenuProps={MenuProps}
                >
                  {Cat?.map((name) => (
                    <MenuItem key={name._id} value={name._id}>
                      <Checkbox
                        checked={
                          Service_provide_category.indexOf(name._id) > -1
                        }
                      />
                      <ListItemText primary={name.category} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <Form>
              <div className="row mt-1">
                <div className="col">
                  <Form.Check
                    type="checkbox"
                    label="All Product retailer categories"
                    id="prime-checkbox"
                    checked={value.Product_retailer_category_All ? true : false}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        Product_retailer_category_All:
                          !value.Product_retailer_category_All,
                      })
                    }
                  />
                </div>
              </div>
            </Form>
            <div className="mt-1">
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Product retailer categories
                </InputLabel>
                <Select
                  disabled={value.Product_retailer_category_All ? true : false}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={Product_retailer_category}
                  onChange={handleChange2}
                  input={<OutlinedInput label="Product retailer categories" />}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (categoryId) =>
                          Cat1.find((cat1) => cat1._id === categoryId)
                            ?.category || ''
                      )
                      .join(', ')
                  }
                  MenuProps={MenuProps}
                >
                  {Cat1?.map((name) => (
                    <MenuItem key={name._id} value={name._id}>
                      <Checkbox
                        checked={
                          Product_retailer_category.indexOf(name._id) > -1
                        }
                      />
                      <ListItemText primary={name.category} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl variant="outlined" className="form-control mt-2">
                {/* sx={{ m: 1, width: '25ch' }} */}
                <InputLabel htmlFor="outlined-adornment-password">
                  Pin
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={value.showPin ? 'text' : 'password'}
                  value={Pins}
                  onChange={(e) => {
                    setPins(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword1}
                        onMouseDown={handleMouseDownPassword1}
                        edge="end"
                      >
                        {value.showPin ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="pin"
                />
              </FormControl>
            </div>
            <div>
              <span>
                <p style={{ color: 'red' }}>{error ? error : ''}</p>
              </span>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          {/* <Button variant="primary" onClick={}>
                        Update Password
                    </Button> */}
          <div className="row mt-4"></div>
          <div className="row mt-3">
            <div className="col  d-flex justify-content-end">
              {!loading && (
                <button
                  className="btn pro-spaces-button3"
                  onClick={(e) => update()}
                >
                  Update{' '}
                </button>
              )}
              {loading && (
                <button className="btn btn-success" disabled>
                  Updating
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const UpdateToAME = ({ Acc_id }) => {
  // Acc_id===>account id of sme
  useEffect(() => {
    check();
  }, []);

  const [updateame, setUpdateame] = useState(false);

  const handleCloseame = () => setUpdateame(false);
  const handleShowame = () => setUpdateame(true);

  let navigate = useNavigate();

  const Update = () => {
    Axios.post('/api/SuperAdmin/updatetoame', { Acc_id })
      .then((res) => {
        if (res.status == 200) {
          notify('Account Updated', true);
          handleCloseame();
          navigate('/manage_sme');
        }
      })
      .catch((err) => {
        notify('Something Went Wrong', false);
      });
  };
  const [disablebtn, setDisablebtn] = useState(true);
  const [days, setDays] = useState(0);
  const check = () => {
    Axios.post('/api/SuperAdmin/checksmelastupdate', { Acc_id })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          var days = 0;
          var today = new Date();
          var lastUpdated = new Date(res.data.Last_Updated_To_SME_Date);
          var timeDiff = today.getTime() - lastUpdated.getTime();
          days = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
          console.log(days);
          if (res.data?.Last_Updated_To_SME_Date) {
            setDays(7 - days);
            if (days > 7) {
              setDisablebtn(false);
            }
          } else {
            setDisablebtn(false);
            setDays('');
          }
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      <div>
        <button className="btn pro-spaces-button3" onClick={handleShowame}>
          Update to AME
        </button>
      </div>
      <Modal show={updateame} onHide={handleCloseame} centered>
        <Modal.Header centered closeButton></Modal.Header>
        <Modal.Body>
          {days > 0
            ? `This account can only update after ${days} ${
                days == 1 ? 'day' : 'days'
              } `
            : 'Are you sure you want to update this account to AME account ?'}
        </Modal.Body>
        <Modal.Footer>
          {days > 0 ? (
            <button className="btn pro-spaces-button3" disabled>
              Update
            </button>
          ) : (
            <button
              className="btn pro-spaces-button3"
              onClick={(e) => Update()}
            >
              Update
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Uploadappointmentletter = ({ id }) => {
  console.log(id);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [img, setimg] = useState([]);
  const [err, setErr] = useState();

  const validate = () => {
    if (img.length === 0) {
      return setErr('PLEASE UPLOAD an Appointment Letter');
    }
    return true;
  };
  const submit = () => {
    console.log(img);
    var Formdata = new FormData();
    if (validate() === true) {
      img?.map((elem) => {
        console.log(elem);
        Formdata.append('file', elem);
      });
      Formdata.append('data', id);

      axios
        .post('/api/AffiliatedAccount/smeappointmentletter', Formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          notify('Appointment Letter Upload Successfully', true);
          handleClose();
          setErr('');
        })
        .catch((err) => {
          notify('Something Went Wrong', false);

          console.log(err);
        });
    }
  };

  return (
    <>
      <Button
        className="pro-spaces-button "
        style={{ border: 'none' }}
        onClick={handleShow}
      >
        Upload&nbsp;Appointment&nbsp;Letter
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>Upload Appointment Letter</Modal.Header>

        <Modal.Body>
          <div class="mb-3">
            <input
              class="form-control"
              type="file"
              id="formFile"
              onChange={(e) => setimg([...img, ...e.target.files])}
            />
          </div>
          <div className="text-danger">{err}</div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn pro-spaces-button3"
            onClick={(e) => {
              submit();
            }}
          >
            Upload
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Viewappointmentletter = ({ id }) => {
  console.log(id);
  const [show, setShow] = useState(false);
  const [imgg, setimgg] = useState([]);

  useEffect(() => {
    axios
      .get(`/api/AffiliatedAccount/smeappointmentletterview?id=${id}`)
      .then((res) => {
        console.log(res.data);
        setimgg(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(id);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        className="pro-spaces-button"
        style={{ border: 'none' }}
        onClick={handleShow}
      >
        View Appointment Letter
      </Button>

      <Modal show={show} onHide={handleClose} fullscreen>
        <Modal.Header closeButton>View Appointment Letter</Modal.Header>

        <Modal.Body>
          {imgg?.map((elem) => {
            console.log(elem);
            return (
              <>
                {elem.Image?.map((ele) => {
                  console.log(ele);
                  return (
                    <>
                      <img
                        src={ele.link}
                        style={{ width: '100%', height: '100%' }}
                      />
                    </>
                  );
                })}
              </>
            );
          })}
        </Modal.Body>
      </Modal>
    </>
  );
};

const Regionalreferralchange = ({ Id }) => {
  const [unique, setunique] = useState({
    unique_Code: '',
  });
  const update = (Id) => {
    axios
      .post('/api/Admin/regionaluniquechange', { unique, Id })
      .then((req) => {
        console.log('req');
        if (req.status === 200) {
          notify('Updated', true);
        }
      })
      .catch((err) => {
        console.log(err);
        notify('Something Went Wrong', false);
      });
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button
        className="pro-spaces-button3"
        style={{ border: 'none' }}
        onClick={handleShow}
      >
        Change Regional Manager
      </Button>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Change Regional Manager</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12">
            <label className="labels">Regional Manager Referral Code *</label>
            <input
              className="form-control"
              required
              type={'text'}
              onChange={(e) => {
                setunique({ ...unique, unique_Code: e.target.value });
              }}
            ></input>
            <div class="invalid-feedback">PLEASE ENTER Full Name</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn pro-spaces-button3"
            onClick={(e) => {
              update(Id);
              handleClose();
            }}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const SendRemarks = () => {
  const [params, setparams] = useSearchParams();

  var id = params.get('k');

  useEffect(() => {
    console.log(id);
  }, [id]);

  const [value, setvalue] = useState({
    Subject: '',
    Remark: '',
  });
  const submit = (e) => {
    console.log('assd');
    e.preventDefault();
    setSubmit2(true);

    axios
      .post('/api/Admin/sendremark', { value, id })
      .then((req) => {
        notify('Remark Sent Successfully', true);
        handleClose1();
      })
      .catch((err) => {
        notify('Something Went Wrong', false);

        console.log(err);
      });
  };

  const [Submit2, setSubmit2] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  return (
    <>
      <Button
        className="pro-spaces-button3"
        style={{ border: 'none' }}
        onClick={handleShow1}
      >
        Send Remark
      </Button>
      <Modal show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <Modal.Title>Send Remark</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            novalidate
            onSubmit={(e) => submit()}
            className={Submit2 ? 'was-validated' : ''}
          >
            <div className="col-md-12">
              <label className="labels">Subject *</label>
              <input
                className="form-control"
                required
                type={'text'}
                onChange={(e) =>
                  setvalue({ ...value, Subject: e.target.value })
                }
              ></input>
              <div class="invalid-feedback">PLEASE ENTER Subject</div>
            </div>
            <div className="col-md-12">
              <label className="labels">Remark *</label>
              <textarea
                className="form-control"
                required
                onChange={(e) => setvalue({ ...value, Remark: e.target.value })}
              ></textarea>
              <div class="invalid-feedback">PLEASE ENTER Remark</div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn pro-spaces-button3" onClick={(e) => submit(e)}>
            Send
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const ViewRemarks = () => {
  const [first, setFirst] = useState([]);
  const [params, setparams] = useSearchParams();
  var id = params.get('k');
  useEffect(() => {
    console.log(id);
  }, [id]);

  const formatDate = (dateString) => {
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    const formattedDate = moment(dateString); // No need to specify the format for ISO 8601

    if (formattedDate.isSame(today, 'day')) {
      return 'Today';
    } else if (formattedDate.isSame(yesterday, 'day')) {
      return 'Yesterday';
    } else {
      return formattedDate.format('DD-MM-YYYY');
    }
  };

  useEffect(() => {
    axios
      .post('/api/Admin/viewremarks', { id })
      .then((req) => {
        console.log(req.data);
        setFirst(req.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [Submit2, setSubmit2] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  return (
    <>
      <Button
        className="pro-spaces-button3"
        style={{ border: 'none' }}
        onClick={handleShow1}
      >
        View Remarks
      </Button>
      <Modal show={show1} onHide={handleClose1} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>View Remarks</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {first?.length ? (
            <>
              {first
                ?.slice()
                .reverse()
                .map((elem, index) => {
                  const formattedDate = formatDate(elem.Created_on);

                  console.log(elem);
                  return (
                    <>
                      <div
                        className="card"
                        style={{ width: '100%' }}
                        key={elem.id}
                      >
                        <div className="card-body">
                          <div className="fw-bold">{elem.Subject}</div>
                          <div className="pt-1">{elem.Remark}</div>
                          <div className="d-flex justify-content-end text-secondary">
                            {formattedDate}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </>
          ) : (
            <>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: '100vh', fontWeight: 'bold' }}
              >
                Nothing to Show
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

const Fail = () => {
  const [params, setparams] = useSearchParams();
  var id = params.get('k');

  const [value, setvalue] = useState({
    Subject: '',
    Remark: '',
    Pass: false,
    Fail: true,
  });

  const submit = (e) => {
    console.log('assd');
    e.preventDefault();
    setSubmit2(true);

    axios
      .post('/api/Admin/sendremark', { value, id })
      .then((req) => {
        notify('Remark Sent Successfully', true);
        handleClose1();
      })
      .catch((err) => {
        notify('Something Went Wrong', false);

        console.log(err);
      });
  };

  const [Submit2, setSubmit2] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  return (
    <>
      <Button
        className="btn btn-danger"
        style={{ border: 'none' }}
        onClick={handleShow1}
      >
        Fail
      </Button>
      <Modal show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <Modal.Title>Fail Remark</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            novalidate
            onSubmit={(e) => submit()}
            className={Submit2 ? 'was-validated' : ''}
          >
            <div className="col-md-12">
              <label className="labels">Subject *</label>
              <input
                className="form-control"
                required
                type={'text'}
                onChange={(e) =>
                  setvalue({ ...value, Subject: e.target.value })
                }
              ></input>
              <div class="invalid-feedback">PLEASE ENTER Subject</div>
            </div>
            <div className="col-md-12">
              <label className="labels">Remark *</label>
              <textarea
                className="form-control"
                required
                onChange={(e) => setvalue({ ...value, Remark: e.target.value })}
              ></textarea>
              <div class="invalid-feedback">PLEASE ENTER Remark</div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn pro-spaces-button3" onClick={(e) => submit(e)}>
            Send
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Pass = () => {
  const [params, setparams] = useSearchParams();
  var id = params.get('k');

  const [value, setvalue] = useState({
    Subject: '',
    Remark: '',
    Pass: true,
    Fail: false,
  });

  const submit = (e) => {
    console.log('assd');
    e.preventDefault();
    setSubmit2(true);

    axios
      .post('/api/Admin/sendremark', { value, id })
      .then((req) => {
        notify('Remark Sent Successfully', true);
        handleClose1();
      })
      .catch((err) => {
        notify('Something Went Wrong', false);

        console.log(err);
      });
  };
  // const update = (Id) => {
  //   axios
  //     .post('/api/Admin/regionaluniquechange', { unique, Id })
  //     .then((req) => {
  //       console.log('req');
  //       if (req.status === 200) {
  //         notify('Updated', true);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       notify('Something Went Wrong', false);
  //     });
  // };
  const [Submit2, setSubmit2] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  return (
    <>
      <Button
        className="btn btn-success"
        style={{ border: 'none' }}
        onClick={handleShow1}
      >
        Pass
      </Button>
      <Modal show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <Modal.Title>Pass Remark</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form novalidate className={Submit2 ? 'was-validated' : ''}>
            <div className="col-md-12">
              <label className="labels">Subject *</label>
              <input
                className="form-control"
                required
                type={'text'}
                onChange={(e) =>
                  setvalue({ ...value, Subject: e.target.value })
                }
              ></input>
              <div class="invalid-feedback">PLEASE ENTER Subject</div>
            </div>
            <div className="col-md-12">
              <label className="labels">Remark *</label>
              <textarea
                className="form-control"
                required
                onChange={(e) => setvalue({ ...value, Remark: e.target.value })}
              ></textarea>
              <div class="invalid-feedback">PLEASE ENTER Remark</div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn pro-spaces-button3" onClick={(e) => submit(e)}>
            Send
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Pause = () => {
  const [first, setfirst] = useState();

  const [params, setparams] = useSearchParams();
  const id = params.get('k');
  useEffect(() => {
    console.log(id);
  }, [id]);
  const [value, setvalue] = useState(true);
  const submit = () => {
    console.log('asdsa');
    if (first.Pause === true) {
      axios
        .post('/api/Admin/pausefalse', { value, id })
        .then((req) => {
          console.log('Assdaxz');
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post('/api/Admin/pausetrue', { value, id })
        .then((req) => {
          console.log('Assdaxz');
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    axios
      .post('/api/Admin/pauseget', { value, id })
      .then((req) => {
        console.log('Assdaxz');
        console.log(req.data);
        setfirst(req.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    console.log(first);
  }, [first]);

  return (
    <>
      <Button
        className="btn btn-warning"
        style={{ border: 'none', color: 'white' }}
        onClick={(e) => {
          submit();
        }}
      >
        Pause
      </Button>
    </>
  );
};

const ViewDMTargets = (value) => {
  console.log(value);
  const [params, setparams] = useSearchParams();
  var id = params.get('k');
  console.log(id);

  const [Count, setCount] = useState();
  useEffect(() => {
    axios
      .post('/api/User/dcecountadmin', { id, value })
      .then((res) => {
        console.log(res.data);
        setCount(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [Submit2, setSubmit2] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  return (
    <>
      <Button
        className="btn pro-spaces-button3"
        style={{ border: 'none' }}
        onClick={handleShow1}
      >
        View Targets
      </Button>
      <Modal show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <Modal.Title>View Daily & Monthly Targets</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container   d-flex justify-content-center">
            <div
              className="card pb-3"
              style={{
                width: '45rem',
                padding: '10px',
                boxShadow: '5px 10px 18px grey',
                borderRadius: '20px',
              }}
            >
              <div className="card-body">
                <div className="row d-flex justify-content-center">
                  <div className="col-6">
                    <div className="mb-3">Daily Targets :</div>
                  </div>
                  <div className="col-6">
                    <div>
                      {Count?.todayCount ? (
                        <span>{Count?.todayCount}</span>
                      ) : (
                        <span>0</span>
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="mb-3">Monthly Targets :</div>
                  </div>
                  <div className="col-6">
                    <div>
                      {Count?.monthlyCount ? (
                        <span>{Count?.monthlyCount}</span>
                      ) : (
                        <span>0</span>
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="mb-3">Total Profiles :</div>
                  </div>
                  <div className="col-6">
                    <div>
                      {Count?.totalCount ? (
                        <span>{Count?.totalCount}</span>
                      ) : (
                        <span>0</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
// export function Appointmentletter({ id }) {
//   const [data, setData] = useState({
//     DiscountAmount: '',
//     GST: '',
//     AmountToPay: '',
//     PayAmount: '',
//     order_id: '',
//     GSTIN: '',
//     pname: '',
//     AmountToPayInWords: '',
//     date: '',
//   });
//   const exportRef = useRef();
//   const exportAsImage = async (el, imageFileName) => {
//     const canvas = await html2canvas(el);
//     const image = canvas.toDataURL('image/png', 1.0);
//     console.log(image);
//     downloadImage(image, imageFileName);
//   };
//   const downloadImage = (blob, fileName) => {
//     const fakeLink = window.document.createElement('a');
//     // console.log(fileName)
//     fakeLink.download = fileName;
//     fakeLink.href = blob;
//     document.body.appendChild(fakeLink);
//     fakeLink.click();
//     document.body.removeChild(fakeLink);
//     fakeLink.remove();
//   };
//   const [show, setShow] = useState(false);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
//   const hbr = `
//   <!DOCTYPE html>
//   <html>
//   <head>
//   <title></title>
//   <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
//   <meta name="viewport" content="width=device-width, initial-scale=1">
//   <meta http-equiv="X-UA-Compatible" content="IE=edge" />
//   <style type="text/css">

//   body, table, td, a { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; }
//   table, td { mso-table-lspace: 0pt; mso-table-rspace: 0pt; }

//   table { border-collapse: collapse !important; }
//   body { height: 100% !important; margin: 0 !important; padding: 0 !important; width: 100% !important; }

//   a[x-apple-data-detectors] {
//       color: inherit !important;
//       text-decoration: none !important;
//       font-size: inherit !important;
//       font-family: inherit !important;
//       font-weight: inherit !important;
//       line-height: inherit !important;
//   }

//   @media screen and (max-width: 480px) {
//       .mobile-hide {
//           display: none !important;
//       }
//       .mobile-center {
//           text-align: center !important;
//       }
//   }
//   div[style*="margin: 16px 0;"] { margin: 0 !important; }
//   </style>
//   <body style="margin: 0 !important; padding: 0 !important; background-color: #eeeeee;" bgcolor="#eeeeee">

//   <table border="0" cellpadding="0" cellspacing="0" width="100%">
//       <tr>
//           <td align="center" style="background-color: #eeeeee;" bgcolor="#eeeeee">

//           <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:600px;">

//               <tr>
//                   <td align="center" style="  background-color: #012060;" bgcolor="#1b9ba3">
//                   <div align="" border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:600px;">

//                       <div style="display:flex;align-items:center;justify-content:space-between;"  >

//                           <div  align="left" style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 20px 0px 15px 20px; ">
//                         <div style="display:flex; justify-content:center">

//                           <img style="width:75px; height:98px;" src="https://s3.ap-south-1.amazonaws.com/prochure.in.profile/Admin/viparm_log.png"><img>

//                           <span style="color: #99d050; font-family: 'monotype Corsiva'; font-size: 80px; margin:41px; ">Vipar&nbsp;<sup style="font-size:48px; color:#fff" >®</sup></span>

//                         </div>
//                         </div>
//                           <div  style="width:30%;color:white;font-size:10px;">
//                           <p></p>
//                           <div style="text-align:left;" >
//                           Number 203, Abhiman Hills<br>
//   Light House Hill road,<br>
//   Hampankatta, Mangalore-575001
//   </div>
//   <p></p>
//   <div style="text-align:left;" >GSTIN : 29AERPV7005H2ZV</div>
//   <div style="text-align:left;" >Email : vipar.official@gmail.com</div>
//   <div style="text-align:left;" >Web : www.vipar-connect.in</div>
//   <p></p>

//   </div>

//                       </div>

//                   </div>
//                   </td>
//               </tr>

//               <tr>

//                   <td align="center" style="padding-left: 35px; padding-right: 35px; padding-top: 10px; padding-bottom: 20px; background-color: #ffffff;">
//                   <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:600px; background-image: url('https://s3.ap-south-1.amazonaws.com/prochure.in.profile/Admin/vipar_background.png');background-size:contain;background-repeat:no-repeat;background-position:center">

//                       <tr >
//                           <td align="left" style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px;">
//                           <br/>
//                                                     <br/>

//                           <br/>

//                             <p style="font-size:1.1em">Dear {{name}}, </p>
//                              <p>We are happy to inform you that you have officially been appointed as Marketing Executive at Vipar (R) with effect from {date in DD/MM/YYYY format} on a gross monthly salary of {Rs /- (in words)}.</p>

//                           </td>

//                       </tr>

//                    <tr align="left" style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px;">
//                    <td>
//                    Salary Break-up :
//                    </td>
//                    </tr>
//                     <tr align="left" style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px;">
//                    <td>
//                    Target based Salary : Rs  /-
//                    </td>
//                    </tr>
//                     <tr align="left" style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px;">
//                    <td>
//                    Allowances (towards conveyance and calls) : Rs  /-
//                    </td>
//                    </tr>
//                     <tr align="left" style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px;">
//                    <td>
//                    Targets to achieve for the first Two Months :
//                    </td>
//                    </tr>
//                     <tr align="left" style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px;">
//                    <td>
//                    Best Wishes,
//                    </td>
//                    </tr>
//                     <tr align="left" style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px;">
//                    <td>
//                    Regards
//                    </td>
//                    </tr>
//                     <tr align="left" style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px;">
//                    <td>
//                  (Proprietor, Vipar)
//                    </td>
//                    </tr>
//                   </table>

//                   </td>
//               </tr>

//           </table>
//           </td>
//       </tr>
//   </table>

//   </body>
//   </html>

// `;
//   const doit = () => {
//     console.log(id);
//     axios
//       .post('/api/User/invoice', { id })
//       .then((res) => {
//         console.log(res);
//         let dat = res.data;
//         console.log(dat.created_at);
//         let date = moment.unix(dat.created_at).format('DD-MM-YYYY');
//         console.log(date);
//         setData({
//           ...dat.Notes,
//           ...dat,
//           date,
//         });
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
//   const template = Handlebars.compile(hbr);
//   return (
//     <div className="">
//       <Link
//         className="list-group-item list-group-item-action py-2 ripple"
//         onClick={(e) => {
//           handleShow();
//           doit();
//         }}
//       >
//         APPOINTMENT LETTER{' '}
//       </Link>
//       <Modal
//         show={show}
//         onHide={handleClose}
//         size=""
//         aria-labelledby="contained-modal-title-vcenter"
//         fullscreen
//       >
//         <Modal.Header closeButton></Modal.Header>
//         <Modal.Body className="py-3">
//           <div
//             style={{ backgroundColor: '#eeeeee' }}
//             className="mt-2"
//             ref={exportRef}
//             dangerouslySetInnerHTML={{ __html: template(data) }}
//           />
//         </Modal.Body>
//       </Modal>
//     </div>
//   );
// }

export default SMEProfile;
