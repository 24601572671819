import Confetti from 'react-confetti';
import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import './Congratulations.css';
import axios from 'axios';

export const Congratulations = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  var name = searchParams.get('name');
  var type = searchParams.get('type');
  console.log(type);

  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const capitalized = name.charAt(0).toUpperCase() + name.slice(1);

  return (
    <>
      <div className="App">
        <div
          style={{ height, width }}
          className="d-flex confettie-wrap justify-content-center align-items-center"
        >
          <div
            className="card shadowbox py-4 px-5"
            style={{ backgroundColor: '#5d334e' }}
          >
            <div className="text-center  text-white footlightfont ">
              CONGRATULATIONS {capitalized} !
            </div>
            <div className="pt-3 px-2 text-center text-white footlightfont ">
              You are now a Registered
              <br />
              {type == 'FSE' ? (
                <> Affiliate Marketing Executive (AME)</>
              ) : (
                <>Salaried Marketing Exwcutive (SME)</>
              )}
              <br />
              at
              <br />
              <span className="vipar" style={{ fontSize: '24px' }}>
                Vipar&nbsp;
              </span>
              &#8239;<sup class="">&reg;</sup>
            </div>
            <div className="mt-4">
              <Link to={'/sign_in'}>
                <center>
                  <button className="btn newprochureoutlinrbutton">
                    SIGN IN / LOG IN
                  </button>
                </center>
              </Link>
            </div>
          </div>
          <Confetti numberOfPieces={150} width={width} height={height} />
        </div>
      </div>
    </>
  );
};
