import React, { useState, useEffect, Children, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";

import TagFacesIcon from '@mui/icons-material/TagFaces';
import { SpinnerBig } from "../../../Utility/spinner";
const notify = (message, sab) => {
  if (sab) {
    toast.success(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.warning(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const Add_information_Cat_and_Subcat = () => {
  const [Loading, setLoading] = useState(true)
  useEffect(() => {
    getInformation_Cat()
      }, [])
  const [Category, setCategory] = useState([])
  
  const getInformation_Cat=()=>{
axios.get('/api/api/Get_Information_category').then((res)=>{
  if (res.status==200) {
    setCategory(res.data)
    setLoading(false)
  }
  console.log(res.data)
}).catch((err)=>{
  console.log(err)
})
  }

  
  return (
    <div className="bg-light" style={{height:"100vh"}}>

    <div className="container " >
      <div className="pt-3">
       <AddCategory getInformation_Cat={getInformation_Cat} Category={Category} />
    </div>

    {
     Loading? 
     <SpinnerBig/>
     :
     <div className="py-1">
     {
        Category?.map((e,index)=>{
         return(
           <>
           <ShowCategory e={e} index={index} getInformation_Cat={getInformation_Cat} Category={Category} />
           </>
         )
        })
     }
   </div>

    }



    </div>
    </div>
  )
}
const ShowCategory =({e,index,getInformation_Cat,Category })=>{


  return(
    <>
    <div className="py-2">
    <Accordion >
    <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography><b>{e.category+"  ( "+e.Information_subcategory?.length +" ) "}</b></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="">

                <EditCategory getInformation_Cat={getInformation_Cat} Category={Category} Form_type={e.formType}  Category_name={e.category} Category_id={e._id} SubCategory_length={e.Information_subcategory?.length?e.Information_subcategory?.length:0}  />

             </div>
             <div className="py-2">
             <AddSubCategory Category_id={e._id} getInformation_Cat={getInformation_Cat}  />
             </div>
             <div className="py-2">
             <ShowSubCategory getInformation_Cat={getInformation_Cat} Category_id={e._id} Sub_Category={e.Information_subcategory} />
             </div>
  </Typography>
          </AccordionDetails>
  </Accordion>
    </div>


    </>
  )
  }

const ShowSubCategory=({getInformation_Cat,Category_id,Sub_Category})=>{
  return(
    <>
        <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>No.</TableCell>
              <TableCell align="left">Sub category</TableCell>
              <TableCell align="left">Button </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              Sub_Category?.map((row,index)=>{
               return(
                <>
                <Row key={index} row={row} id={Category_id} index={index}  getInformation_Cat={getInformation_Cat} ></Row>
                </>
               ) 
              })
            }
          </TableBody>
          </Table>
      </TableContainer>
    
    </>
  )
}
const Row=(props)=> {
  const { row,id,index,index1,getInformation_Cat } = props;
  const [open, setOpen] = React.useState(false);
  // console.log(row.brand1.length," ",row.brand1.brand)
const  ActivateBrand=(id)=>{
  axios.post('/api/Admin/ActivateSub_Sub_Category',{id}).then((res)=>{
if (res.status==200) {
  getInformation_Cat()
}
  }).catch((err)=>{
console.log(err)
  })
}
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{index+1}</TableCell>
        <TableCell align="left">{row.sub}</TableCell>
        <TableCell align="left"><EditSubCategory getInformation_Cat={getInformation_Cat} SubCategory_name={row.sub} SubCategory_id={row._id} Category_id={id}  /></TableCell>
        <TableCell align="left"><Sub_Category_Delete getInformation_Cat={getInformation_Cat} SubCategory_name={row.sub} SubCategory_id={row._id} Category_id={id}  /></TableCell>

        
      </TableRow>
     
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
             {
              row.sub_sub_cat==true?
              row?.brand?.length>0?
              <>
          
             <Typography variant="h6" gutterBottom component="div">
             Sub Sub Category

              </Typography>
              <>
              <div className="d-flex justify-content-end ">
             < AddSub_SubCategory SubCategory_id={row._id} getInformation_Cat={getInformation_Cat} />
                        </div>
                <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell>Sub Sub Category</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.brand.map((brand,ind) => (<>
                    <TableRow key={ind}>
                      <TableCell component="th" scope="row">
                        {ind+1}
                      </TableCell>
                      <TableCell>{brand.sub_sub_category}</TableCell>
                      <TableCell><EditSub_SubCategory getInformation_Cat={getInformation_Cat} SubCategory_id={row._id} Sub_Sub_Category_id={brand._id} Sub_Sub_Category_name={brand.sub_sub_category} /></TableCell>
                      <TableCell><Sub_Sub_Category_Delete getInformation_Cat={getInformation_Cat} SubCategory_id={row._id} Sub_Sub_Category_id={brand._id} Sub_Sub_Category_name={brand.sub_sub_category} /></TableCell>
                      
                    </TableRow>
                  </>
                  ))}
                </TableBody>
              </Table>
              </>

              </>
              :
              <div className="d-flex justify-content-center">
                < AddSub_SubCategory SubCategory_id={row._id} getInformation_Cat={getInformation_Cat} />
              </div>
              :
              row.Information_count?.length!=0 ?
              <center><button className="btn btn-primary" disabled >Activate Brand</button></center>
              :
               <center><button className="btn btn-primary" onClick={(e)=>ActivateBrand(row._id)}>Activate Brand</button></center>
             }

            </Box>

          </Collapse>
        </TableCell>
      </TableRow>

    </React.Fragment>
  );
}
const AddCategory= ({getInformation_Cat,Category})=>{
  const [show, setShow] = useState(false);
  const handleClose = () =>{ setShow(false)
    setValue({
      ...Value,
      name:null,
      position:null,
      first:false,
      position_after_index:null,
      form_type:null,
    })
  };
  const handleShow = () => setShow(true);
  const [Value, setValue] = useState({
    name:"",
    position:null,
    first:false,
    position_after_index:null,
    form_type:null
  })
  const [Error, setError] = useState("")
  const AddCategory=async ()=>{

    if (!Value.name) {
      return setError("Enter category")
    }
    setError("")
    if (!Value.form_type) {
      return setError("Select form type")
    }
    setError("")
    if (!Value.position_after_index&&!Value.first) {
      return setError("Select position")
    }
    setError("")

    let position
    if (!Value.first) {
      position=await Category_position(Value,Category)
    }
    else{
      position=0.0

    }
    axios.post('/api/Admin/AddInformationCategory',{Value,position}).then((res)=>{
      if (res.status==200) {
        getInformation_Cat()
        handleClose()
        setValue({
          ...Value,
          name:null,
          position:null,
          first:false,
          position_after_index:null,
          form_type:null,
        })
        notify(" Category Add Successfully",true)

      }
     console.log(res.data)
    }).catch((err)=>{
      console.log(err)
      notify(" Something went wrong",false)

    })
  }
  return(
    <>
        <div>
      <div className="d-flex justify-content-end"><button className="btn pro-spaces-button3" onClick={(e)=>handleShow()}>Add Category</button></div>
    </div>
    <Modal show={show} onHide={handleClose}  centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
           <form>
            <div class="form-group py-1">
              <label for="NewCategory">New Category *</label>
              <input type="text" class="form-control" id="NewCategory" placeholder="New Category" onChange={(e)=>{setValue({...Value,name:e.target.value})}} />
            </div>
            <div class="form-group py-1">
                <label for="exampleFormControlSelect1">Form type *</label>
                <select class="form-control" id="exampleFormControlSelect1" value={Value.form_type} onChange={(e)=>{setValue({...Value,form_type:e.target.value})}}>
                <option value={""} > </option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                </select>
              </div>
            <div class="form-group form-check py-1">
              <input type="checkbox" class="form-check-input" id="exampleCheck1" defaultValue={Value.first} onChange={(e)=>{setValue({...Value,first:!Value.first})}} />
              <label class="form-check-label" for="exampleCheck1">First position</label>
            </div>
              <div class="form-group py-1">
                <label for="exampleFormControlSelect1">Category after </label>
                <select class="form-control" id="exampleFormControlSelect1" onChange={(e)=>{setValue({...Value,position_after_index:e.target.value})}}>
                <option value={""} ></option>
                {
                  Category?
                  Category.map((e,index)=>{
                    return<option value={index}>{e.category}</option>
                  })
                  :""
                }

                </select>
              </div>
            </form>
            <span>
              <p style={{color:"red"}}>{Error?Error:""}</p>
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>

          <button className="btn pro-spaces-button3" onClick={(e)=>{AddCategory()}}>
            Add
          </button>
        </Modal.Footer>
      </Modal>

    </>
  )
}

const EditCategory=({getInformation_Cat,Category,Category_name,Category_id,Form_type,SubCategory_length})=>{
  const [show, setShow] = useState(false);
  const [Value, setValue] = useState({
    name:Category_name,
    position:null,
    first:false,
    position_after_index:null,
    form_type:Form_type,
    Category_id:Category_id,

  })
  const handleClose = () => {setShow(false)
    setValue({
      ...Value,
      name:null,
      position:null,
      first:false,
      position_after_index:null,
      form_type:null,
      Category_id:null,
    })
  };
  const handleShow = () => setShow(true);
  const [Error, setError] = useState("")

  const EditCategory=async()=>{


    if (!Value.name) {
      return setError("Enter category")
    }
    setError("")
    if (!Value.form_type) {
      return setError("Select form type")
    }
    setError("")
    let position
    if (!Value.first) {
      position=await Category_position(Value,Category)
    }
    // console.log(position)
    // return
    axios.post('/api/Admin/EditInformationCategory',{Value,position}).then((res)=>{
      if (res.status==200) {
        getInformation_Cat()
        handleClose()
        setValue({
          ...Value,
          name:null,
          position:null,
          first:false,
          position_after_index:null,
          form_type:null,
          Category_id:null,
        })
        notify(" Category Edited Successfully",true)

      }
console.log(res.data)
    }).catch((err)=>{
      console.log(err)
      notify("Something went wrong",false)

    })
  }
  const getValues =()=>{
    setValue({
      ...Value,
      name:Category_name,
      position:null,
      first:false,
      position_after_index:null,
      form_type:Form_type,
      Category_id:Category_id,
    })
  }

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const Delete=()=>{
      axios.post('/api/Admin/DeleteInformationCategory',{Category_id}).then((res)=>{
        // console.log(res)
        if (res.status==200) {
          // console.log(res)
          handleClose1()
          getInformation_Cat()
          notify("Category Deletion Successfully",true)
        }
      }).catch((err)=>{
        notify("Something went wrong",false)
      })
  }

  
  return(
    <>
        <div>
           <div className="pb-3 ">
            {Category_name}
            <div className="d-flex justify-content-end">
            <button className="btn pro-spaces-button3  " onClick={(e)=>handleShow()}>Edit</button>
            {/* {
            SubCategory_length==0? */}
            <button className="btn btn-danger float-end ms-1 " onClick={(e)=>handleShow1()}>Delete</button>
           {/* :<></>
            } */}
            </div>

           </div>
    </div>
    <Modal show={show} onHide={handleClose} onShow={(e)=>getValues()}  centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
           <form>
            <div class="form-group py-1">
              <label for="NewCategory"> Category *</label>
              <input type="text" class="form-control" id="NewCategory" placeholder=" Category" value={Value.name}  onChange={(e)=>{setValue({...Value,name:e.target.value})}} />
            </div>
            <div class="form-group py-1">
                <label for="exampleFormControlSelect1">Form type *</label>
                <select class="form-control" id="exampleFormControlSelect1" value={Value.form_type} onChange={(e)=>{setValue({...Value,form_type:e.target.value})}}>
                <option value={""} > </option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                </select>
              </div>
            <div class="form-group form-check py-1">
              <input type="checkbox" class="form-check-input" id="exampleCheck1" defaultValue={Value.first} onChange={(e)=>{setValue({...Value,first:!Value.first})}} />
              <label class="form-check-label" for="exampleCheck1">First position</label>
            </div>
              <div class="form-group py-1">
                <label for="exampleFormControlSelect1">Category after </label>
                <select class="form-select" id="exampleFormControlSelect1" disabled={Value.first} onChange={(e)=>{setValue({...Value,position_after_index:e.target.value})}}>
                <option value={""} ></option>
                {
                  Category?
                  Category.map((e,index)=>{
                    return<option value={index}>{e.category}</option>
                  })
                  :""
                }
                </select>
              </div>
            </form>
            <span>
              <p style={{color:"red"}}>{Error?Error:""}</p>
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>

          <button className="btn pro-spaces-button3" onClick={(e)=>{EditCategory()}}>
            Update
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Deleting category</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure ?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={(e)=>Delete()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  )
}

const AddSubCategory=({getInformation_Cat,Category_id})=>{
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Value, setValue] = useState({
    name:"",
    Category_id:Category_id
  })
  const [Error, setError] = useState("")
  const AddSubCategory=()=>{
    if (!Value.name) {
      return setError("Enter subcategory name")
    } 
    setError("")
    axios.post('/api/Admin/AddInformationSubCategory',{Value}).then((res)=>{
      if (res.status==200) {
        getInformation_Cat()
        handleClose()
        notify(" Sub Category Add Successfully",true)

      }
console.log(res.data)
    }).catch((err)=>{
      console.log(err)
      notify("Something went wrong",false)

    })
  }
  return(
    <>
        <div>
      <div className="d-flex justify-content-end"><button className="btn pro-spaces-button3" onClick={(e)=>handleShow()}>Add Sub Category</button></div>
    </div>
    <Modal show={show} onHide={handleClose}  centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Sub Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
           <form>
            <div class="form-group py-1">
              <label for="NewCategory">New Sub Category *</label>
              <input type="text" class="form-control" id="NewCategory" placeholder="New  Sub Category" onChange={(e)=>{setValue({...Value,name:e.target.value})}} />
            </div>
            </form>
            <span>
              <p style={{color:"red"}}>{Error?Error:""}</p>
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>

          <button className="btn pro-spaces-button3" onClick={(e)=>{AddSubCategory()}}>
            Add
          </button>
        </Modal.Footer>
      </Modal>

    </>
  )
}
const EditSubCategory=({getInformation_Cat,Category_id,SubCategory_id,SubCategory_name})=>{
  const [show, setShow] = useState(false);
  const handleClose = () => {setShow(false)
    setValue({
      ...Value,
      name:"",
      Category_id:"",
      SubCategory_id:""
    })
  };
  const handleShow = () => setShow(true);
  const [Value, setValue] = useState({
    name:"",
    Category_id:Category_id,
    SubCategory_id:SubCategory_id
  })
  const [Error, setError] = useState("")
  const EditSubCategory=()=>{
        if (!Value.name) {
          return setError("Enter subcategory name")
        } 
        setError("")

    axios.post('/api/Admin/EditInformationSubCategory',{Value}).then((res)=>{
      if (res.status==200) {
        getInformation_Cat()
        handleClose()
        notify(" Sub Category Edited Successfully",true)

      }
console.log(res.data)
    }).catch((err)=>{
      console.log(err)
      notify("Something went wrong",false)

    })
  }

  const getValues =()=>{
    setValue({
      ...Value,
      name:SubCategory_name,
      Category_id:Category_id,
      SubCategory_id:SubCategory_id
    })
  }
  

  return(
    <>
        <div>
      <div className="">
        <button className="btn pro-spaces-button3" onClick={(e)=>handleShow()}>Update</button>
        
        </div>
    </div>
    <Modal show={show} onHide={handleClose}  onShow={(e)=>getValues()}  centered>
        <Modal.Header closeButton>
          <Modal.Title>Update Sub Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
           <form>
            <div class="form-group py-1">
              <label for="NewCategory">Sub Category *</label>
              <input type="text" class="form-control" id="NewCategory" placeholder="Sub Category" value={Value.name}  onChange={(e)=>{setValue({...Value,name:e.target.value})}} />
            </div>
            </form>
            <span>
              <p style={{color:"red"}}>{Error?Error:""}</p>
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>

          <button className="btn pro-spaces-button3" onClick={(e)=>{EditSubCategory()}}>
            Update
          </button>
        </Modal.Footer>
      </Modal>



    </>
  )
}
const AddSub_SubCategory=({getInformation_Cat,SubCategory_id})=>{

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Value, setValue] = useState({
    name:"",
    SubCategory_id:SubCategory_id
  })
  const AddSub_SubCategory=()=>{
    axios.post('/api/Admin/AddInformationSub_SubCategory',{Value}).then((res)=>{
      if (res.status==200) {
        getInformation_Cat()
        handleClose()
        notify("Sub Sub Category Add Successfully",true)

      }
console.log(res.data)
    }).catch((err)=>{
      console.log(err)
      notify("Something went wrong",false)

    })
  }
  return(
    <>
        <div>
      <div className=""><button className="btn pro-spaces-button3" onClick={(e)=>handleShow()}>Add Sub Sub Category</button></div>
    </div>
    <Modal show={show} onHide={handleClose}  centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Sub Sub Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
           <form>
            <div class="form-group py-1">
              <label for="NewCategory">New Sub Sub Category *</label>
              <input type="text" class="form-control" id="NewCategory" placeholder="New Sub SubCategory" onChange={(e)=>{setValue({...Value,name:e.target.value})}} />
            </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>

          <button className="btn pro-spaces-button3" onClick={(e)=>{AddSub_SubCategory()}}>
            Add
          </button>
        </Modal.Footer>
      </Modal>

    </>
  )
}
const EditSub_SubCategory=({getInformation_Cat,SubCategory_id,Sub_Sub_Category_id,Sub_Sub_Category_name})=>{

  const [show, setShow] = useState(false);
  const handleClose = () => {setShow(false)
    setValue({
      ...Value,
      name:"",
      SubCategory_id:null,
      Sub_Sub_Category_id:null
    })
  };
  const handleShow = () => setShow(true);
  const [Value, setValue] = useState({
    name:"",
    SubCategory_id:SubCategory_id,
    Sub_Sub_Category_id:Sub_Sub_Category_id
  })
  const EditSub_SubCategory=()=>{
    axios.post('/api/Admin/EditInformationSub_SubCategory',{Value}).then((res)=>{
      if (res.status==200) {
        getInformation_Cat()
        handleClose()
        setValue({
          ...Value,
          name:"",
          SubCategory_id:null,
          Sub_Sub_Category_id:null
        })
        notify("Sub Sub Category Edited Successfully",true)

      }
console.log(res.data)
    }).catch((err)=>{
      console.log(err)
      notify("Something went wrong",false)

    })
  }

  const getValues =()=>{
    setValue({
      ...Value,
      name:Sub_Sub_Category_name,
      Sub_Sub_Category_id:Sub_Sub_Category_id,
      SubCategory_id:SubCategory_id
    })
  }

  return(
    <>
        <div>
      <div className=""><button className="btn pro-spaces-button3" onClick={(e)=>handleShow()}>Update</button></div>
    </div>
    <Modal show={show} onHide={handleClose} onShow={(e)=>getValues()}  centered>
        <Modal.Header closeButton>
          <Modal.Title>Update Sub Sub Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
           <form>
            <div class="form-group py-1">
              <label for="NewCategory">Sub Sub Category *</label>
              <input type="text" class="form-control" id="NewCategory" placeholder="New Sub SubCategory" value={Value.name} onChange={(e)=>{setValue({...Value,name:e.target.value})}} />
            </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>

          <button className="btn pro-spaces-button3" onClick={(e)=>{EditSub_SubCategory()}}>
            Update
          </button>
        </Modal.Footer>
      </Modal>

    </>
  )
}

const Category_position=(Value,Category)=>{
var length=Category.length-1
length=length
Value.position_after_index=parseFloat(Value.position_after_index)
var newIndex=Value.position_after_index+1
if (length=== Value.position_after_index) {
  var num1=Category[Category.length-1]?.num
  var num2=num1+1
  console.log("num2 :",num2)
  return num2
}else{
  var num1=Category[Value.position_after_index]?.num
  var  num2=Category[Value.position_after_index+1]?.num
  var num3=((num1+num2)/2)
  console.log("num3 :",num3)
    return num3
}
}

const Sub_Category_Delete=({getInformation_Cat,Category_id,SubCategory_id,SubCategory_name})=>{
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const Delete=()=>{
      axios.post('/api/Admin/DeleteInformationSubCategory',{SubCategory_id}).then((res)=>{
        // console.log(res)
        if (res.status==200) {
          // console.log(res)
          handleClose1()
          getInformation_Cat()
          notify("Sub Category Deletion Successfully",true)
        }
      }).catch((err)=>{
        notify("Something went wrong",false)
      })
  }
  return(
    <>
     <div>
     <button className="btn btn-danger float-end ms-1 " onClick={(e)=>handleShow1()}>Delete</button>
     </div>
    
<Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Deleting category</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure ?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={(e)=>Delete()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  )

}

const Sub_Sub_Category_Delete=({getInformation_Cat,SubCategory_id,Sub_Sub_Category_id,Sub_Sub_Category_name})=>{
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const Delete=()=>{
      axios.post('/api/Admin/DeleteInformationSubSubCategory',{Sub_Sub_Category_id}).then((res)=>{
        // console.log(res)
        if (res.status==200) {
          // console.log(res)
          handleClose1()
          getInformation_Cat()
          notify("Sub Sub Category Deletion Successfully",true)
        }
      }).catch((err)=>{
        notify("Something went wrong",false)
      })
  }
  return(
    <>
     <div>
     <button className="btn btn-danger float-end ms-1 " onClick={(e)=>handleShow1()}>Delete</button>
     </div>
    
<Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Deleting category</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure ?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={(e)=>Delete()}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  )

}