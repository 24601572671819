import React from 'react'
import { Link, useLocation } from 'react-router-dom';

export const Navbar2 = () => {
  return (
    <>
      <div
          className=" px-4  w-100"
          style={{
            display: 'block',
            height: '122px',
            backgroundColor: 'rgb(1,32,96,1)',
          }}
        >
          <div className="text-light">
            <div className="d-flex justify-content-between">
              <div style={{ marginTop: '20px' }}>
                <Link
                  class="navbar-brand text-success logo align-self-center"
                  to="/"
                >
                  <img
                    className=""
                    src="/connect.png"
                    alt=""
                    height="80px"
                    width="80px"
                  />
                </Link>
              </div>

              <div className="xn">
                {/* <span className="invisible">
                  Innovative&nbsp;Utilitarian&nbsp;Products
                </span> */}
                <div className="pt-2">
                  <div className="mt-2  ">
                    <span
                      className="vipar transform "
                      style={{
                        fontSize: '22px',
                        color: '#99d050',
                        paddingLeft: '5px',
                      }}
                    >
                      Vipar
                    </span>
                    <span
                      className="ps-1 transform maindrafont"
                      style={{
                        fontSize: '20px',
                        color: '#CFD6E1',
                      }}
                    >
                      &nbsp;CONNECT
                    </span>
                  </div>
                  <div className=" " style={{ height: '13px' }}></div>
                  <div className="mt-3">
                    {' '}
                    <span
                      className="    btnsss maindrafont "
                      style={{
                        fontSize: '16px',
                        textTransform: 'none',
                        color: '#CFD6E1',
                      }}
                    >
                      Informative.&nbsp;Productive.
                    </span>
                  </div>
                </div>
              </div>
              <div className="invisible">
                <div></div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}
