
const languages = [
    "English",
    "Hindi",
    "Assamese",
    "Bengali",
    "Bodo",
    "Dogri",
    "Gujarati",
    "Kannada",
    "Kashmiri",
    "Konkani",
    "Maithili",
    "Malayalam",
    "Manipuri / Meitei",
    "Marathi",
    "Nepalese",
    "Oriya",
    "Punjabi",
    "Sanskrit",
    "Santali",
    "Sindhi",
    "Tamil",
    "Telugu",
    "Tulu",
    "Urdu",
    "Arabic",
    "Chinese",
    "French",
    "German",
    "Greek",
    "Indonesian",
    "Italian",
    "Japanese",
    "Korean",
    "Malay",
    "Mandarin",
    "Polish",
    "Portuguese",
    "Romanian",
    "Russian",
    "Spanish",
    "Thai",
    "Turkish",
    
];
const preferdmodeofcommunication = [
    'Email',
    'Mobile / Landline number'
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const arrayofdays  = ['Nil','Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday','Nil']
const arrayofdays2 = ['Nil','Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday','Nil']
const arrayofdays3 = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']



export { MenuProps, ITEM_HEIGHT, languages, ITEM_PADDING_TOP,arrayofdays,arrayofdays2,preferdmodeofcommunication ,arrayofdays3}