import { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Cropper } from "react-cropper";
import { AiFillCamera } from "react-icons/ai";

export const ImageUploadComponent = ({ onChange }) => {
    const fileInputRef = useRef(null);
  
    const [show, setshow] = useState(false);
    const handleClose = () => {
      setshow(false);
    };
    const handleOpen = () => {
      setshow(true);
    };
    const [ImageToCrop, setImageToCrop] = useState();
    const handleButtonClick = () => {
      fileInputRef.current.click();
    };
  
    const handleImageUpload = (event) => {
      if (event.target.files[0].type.indexOf('image') != -1) {
        let file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          setImageToCrop(reader.result);
          handleOpen();
        };
        reader.readAsDataURL(file);
        // onChange(event.target.files)
      } else if (event.target.files[0].type.indexOf('video') != -1) {
        onChange(event.target.files[0]);
      }
      event.target.value = null;
    };
    const [cropper, setCropper] = useState();
    const crop = () => {
      if (typeof cropper !== 'undefined') {
        // setCropData(cropper.getCroppedCanvas().toDataURL());
        cropper.getCroppedCanvas().toBlob(
          (blob) => {
            handleClose();
            onChange(blob);
            setCropper();
            setImageToCrop();
          },
          'image/jpeg',
          1
        );
      }
    };
    return (
      <>
        <div className="form-group">
          <div className="input-group">
            <input
              ref={fileInputRef}
              type="file"
              className="d-none"
              accept="image/*"
              onChange={handleImageUpload}
            />
            <div className="input-group-append">
              <button
                className="btn pro-spaces-button3"
                type="button"
                onClick={handleButtonClick}
              >
                Upload Image
                <AiFillCamera className='ps-2' size={24} />
              </button>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose} backdrop={'static'} centered>
          <Modal.Header>
            <Modal.Title>Crop Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {ImageToCrop && (
              <>
                <Cropper
                  style={{ height: 400, width: '100%' }}
                  zoomTo={false}
                  aspectRatio={16 / 9}
                  preview=".img-preview"
                  src={ImageToCrop}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={0}
                  checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                />
                <div>
                  {' '}
                  <button
                    type="button"
                    className="btn pro-spaces-button3 float-end"
                    onClick={crop}
                  >
                    ok
                  </button>
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  };