import React from 'react';
import { Button } from 'react-bootstrap';

export const ContentSection = () => {
  return (
    <>
      <section className="bgvipar ">
        <div className="container w-100">
          <div className=" py-2 py-md-3 px-2">
            <p
              className="text-light my-4 maindrafont"
              style={{
                fontFamily: 'maiandra',

                textAlign: 'left',
                fontSize: '18px',
              }}
            >
              Professional-Networking, Informative, Educative and Awareness
              Creation online platforms which are Accessible to users Globally.
            </p>
          </div>
        </div>
      </section>
      <div
        style={{ overflow: 'hidden', backgroundColor: 'white' }}
        className="py-2"
      >
        <div className="container footlightfont pt-3">
          <span
            className="vipar "
            style={{
              fontSize: '22px',
              color: '#99d050',
            }}
          >
            {' '}
            Vipar&nbsp;
          </span>{' '}
          <span className="maindrafont">CONNECT</span>, the IT (Information
          Technology) wing of{' '}
          <span
            className="vipar "
            style={{
              fontSize: '22px',
              color: '#99d050',
            }}
          >
            Vipar&nbsp;
          </span>{' '}
          <sup>®</sup>, develops subscription-based web and mobile apps
          available on Windows, Android and iOS.
        </div>
        <div className="container pt-4" style={{ minHeight: '18vh' }}>
          <div className="footlightfont">
            The Organization :{' '}
            <span
              className="vipar "
              style={{
                fontSize: '22px',
                color: '#99d050',
              }}
            >
              Vipar&nbsp;
            </span>{' '}
            <sup>®</sup>
          </div>
          <div className="pt-1 footlightfont">Founded in : 2020</div>
          <div className="pt-1 footlightfont">
            Organization's Official Web domain :{' '}
            <a href="https://www.vipar.in">www.vipar.in</a>
          </div>
          <div className="pt-1 footlightfont">
            Organization's Official E-mail ID : vipar.official@gmail.com
          </div>
        </div>
      </div>
      {/* <div className="pt-2 ">
        <marquee className="bgvipar text-white footlightfont py-2 mt-3">
          Our Organization's Primary Web Domain www.vipar.in is currently under
          renovation.{' '}
        </marquee>
      </div> */}
    </>
  );
};
