import React, { useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import {
  useNavigate,
  useSearchParams,
  Link,
  useParams,
} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { BiDownload } from 'react-icons/bi';
import Handlebars from 'handlebars';
import axios from 'axios';
import { SpinnerBig } from '../../../Utility/spinner';
// import FME_executive_earnings_in_profile_page from '../../ManageAdmin/FME_earnings_in_profile_page';
const notify = (message, sab) => {
  if (sab) {
    toast.success(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.warning(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '300',
    },
  },
};

export const RMprofile = () => {
  var [searchparams, setsearchparams] = useSearchParams();
  var Id = searchparams.get('k');

  useEffect(() => {
    console.log(Id);
  }, [Id]);

  const [value, setValue] = useState({
    Id: '',
    Type: '',
    Name: '',
    Email: '',
    Contact: '',
    state: '',
    city: '',
    Account_holder_name: '',
    Account_number: '',
    Ifsc_code: '',
    Bank_name: '',
    Branch_name: '',
    Status: '',
    Bank_account_type: '',
    Gender: '',
    Age: '',
    Nationality: '',
    country: '',
    Address: '',
    Pincode: '',
    Work_Experience: '',
    BloodGroup: '',
    FMContact: '',
    FMAddress: '',
    FMName: '',
    FMRelationship: '',
    FMPincode: '',
  });
  // const [Counts, setCounts] = useState({
  //     total:0,
  //     thisWeek:0,
  //     thisMonth:0
  // })
  //     useEffect(() => {
  //     Axios.post('/api/SuperAdmin/CountOfAccountCreatedByFME',{ Id }).then((res)=>{
  //         if (res.data) {
  //             console.log(res.data)
  //         }
  //     })
  //     }, [])
  const [Loading, setLoading] = useState(true);
  React.useEffect(() => {
    setLoading(true);
    Axios.post('/api/regionalmanager/profileshow', { Id })
      .then((res) => {
        console.log(res.data);
        if (res.data) {
          console.log(res.data);
          var newstate = res.data.map((elem) => {
            console.log(elem);
            return {
              Name: elem.Name,
              Account_holder_name: elem.Account_holder_name,
              Account_number: elem.Account_number,
              Address: elem.Address,
              Age: elem.Age,
              Adhar: elem.Adhar,
              Bank_account_type: elem.Bank_account_type,
              Bank_name: elem.Bank_name,
              Gender: elem.Gender,
              Ifsc_code: elem.Ifsc_code,
              Nationality: elem.Nationality,
              Pincode: elem.Pincode,
              city: elem.city,
              country: elem.country,
              state: elem.state,
              Contact: elem.Account[0].Contact,
              Email: elem.Account[0].Email,
              Referral_Code: elem.Referral_Code,
              FMAddress: elem.Family_address,
              FMName: elem.Family_name,
              FMRelationship: elem.Family_relationship,

              FMContact: elem.Family_contact,
              FMPincode: elem.Family_pincode,
              BloodGroup: elem.BloodGroup,
              FMstate: elem.Family_state,
              FMcountry: elem.Family_country,
              FMcity: elem.Family_city,
            };
          });
          console.log(newstate);
          setValue(newstate);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        notify('Something went wrong ', false);
      });
  }, []);
  // const modes = useRef()
  // const modalon = () => {
  //     modes.current(Id)
  // }
  const [Loginid, setLoginid] = useState('');
  const [suspendreason, setSuspendreason] = useState('');

  const updateStatus = () => {
    console.log(Loginid);
    var id = Loginid;
    var status = value.Status;
    var st1;
    if (value.Status == 'Active') {
      st1 = 'Suspended';
    } else {
      st1 = 'Active';
    }

    Axios.post('/api/SuperAdmin/UpdateSubAdminStatus', {
      id,
      status,
      suspendreason,
    })
      .then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          setValue({
            ...value,
            Status: st1,
          });
          setSuspendreason('');
          handleClose();
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          notify('Unbothered user', false);
        } else {
          notify('Something went wrong ', false);
        }
      });
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setSuspendreason('');
  };
  const handleShow = (id) => {
    setShow(true);
    setLoginid(id);
  };
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = (id) => {
    setShow1(true);
    setLoginid(id);
  };

  var url;
  url = `https://s3.ap-south-1.amazonaws.com/prochure.in.profile/AffliateProfile/${value.Id}/avatar.png`;
  // url = `https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/AffliateProfile/${value.Id}/avatar.png`;
  return (
    <div className="bg-light px-5">
      {Loading && (
        <>
          <SpinnerBig />
        </>
      )}

      {!Loading && (
        <>
          <center>
            <h2>Regional&nbsp;Manager&nbsp;Profile</h2>
          </center>
          <div className="container-fluid border py-3">
            <div className="mt-3">
              <div className="card" style={{ width: '130px', height: '130px' }}>
                <img
                  src={url}
                  onError={(e) => {
                    return (e.target.src = '/blank.png');
                  }}
                  style={{ width: '130px', height: '130px' }}
                />
              </div>
              {/* <h3>{value.Name}</h3> */}
              <div className="d-flex flex-column  flex-md-row justify-content-lg-end">
                {/* <div className="mx-1 py-1">
                  {value.Type != 'SUPERADMIN' ? (
                    <button
                      className="btn pro-spaces-button3"
                      onClick={(e) => handleShow(value.Login_id)}
                    >
                      {value.Status == 'Active'
                        ? 'Suspend this account'
                        : 'Activate this account'}
                    </button>
                  ) : (
                    ''
                  )}
                </div> */}

                {/* <div className="mx-1 py-1">
                <UpdateToAME Acc_id={value.Id} />
              </div> */}

                <div className="mx-1 py-1 ">
                  {/* <Link to={`/regionalmanager_editprofile?k=${Id}`}>
                    <button className="btn pro-spaces-button3">
                      Edit Profile
                    </button>
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="mt-3 col-6">
              <div className="mt-3 row">
                <div className="col-5">Name</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].Name}</div>
              </div>

              <div className="mt-3 row">
                <div className="col-5">Email</div>
                <div className="col-2">:</div>
                <div className="col-5">
                  <a
                    href={`mailto:${value[0].Email}`}
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    {value[0].Email}
                  </a>
                </div>
              </div>
              <div className="mt-3 row">
                <div className="col-5">Contact</div>
                <div className="col-2">:</div>
                <div className="col-5">
                  <a
                    href={`tel:${value[0].Contact}`}
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    {value[0].Contact}
                  </a>
                </div>
              </div>

              <div className="mt-3 row">
                <div className="col-5">Age</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].Age}</div>
              </div>
              <div className="mt-3 row">
                <div className="col-5">Gender</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].Gender}</div>
              </div>
              <div className="mt-3 row">
                <div className="col-5">Nationality</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].Nationality}</div>
              </div>
              <div className="mt-3 row">
                <div className="col-5">Blood Group</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].BloodGroup}</div>
              </div>
              <div className="mt-3 row">
                <div className="col-5">Country</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].country}</div>
              </div>

              <div className="mt-3 row">
                <div className="col-5">City</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].city}</div>
              </div>
              <div className="mt-3 row">
                <div className="col-5">State</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].state}</div>
              </div>

              <div className="mt-3 row">
                <div className="col-5">Address</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].Address}</div>
              </div>
              <div className="mt-3 row">
                <div className="col-5">Pincode</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].Pincode}</div>
              </div>

              <div className="mt-3 row">
                <div className="col-5">Referral Code</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].Referral_Code}</div>
              </div>
              <br></br>

              <h4 className="pt-2">Identity Proof</h4>

              <div className="row">
                {value[0]?.Adhar?.map((elem) => {
                  console.log(elem);
                  if (elem?.file != 'avatar.png') {
                    return (
                      <>
                        <div className="d-flex justify-content-center col-md-6 col-sm-12 g-2">
                          <img
                            src={elem.link}
                            style={{
                              width: '300px',
                              height: '300px',
                              objectFit: 'cover',
                            }}
                          ></img>
                        </div>
                      </>
                    );
                  }
                })}
              </div>
              <br />

              <h4>Bank Account Details</h4>

              <div className="mt-3 row">
                <div className="col-5">Account holder&nbsp;name</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].Account_holder_name}</div>
              </div>
              <div className="mt-3 row">
                <div className="col-5">Account number</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].Account_number}</div>
              </div>
              <div className="mt-3 row">
                <div className="col-5">Account IFSC&nbsp;code</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].Ifsc_code}</div>
              </div>
              <div className="mt-3 row">
                <div className="col-5">Bank name</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].Bank_name}</div>
              </div>
              <div className="my-3 row">
                <div className="col-5">Bank account type</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].Bank_account_type}</div>
              </div>
              <h4>CONTACT INFORMATION of a FAMILY MEMBER</h4>

              <div className="mt-3 row">
                <div className="col-5">Full Name of Family Member</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].FMName}</div>
              </div>
              <div className="mt-3 row">
                <div className="col-5">Relationship with Family Member</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].FMRelationship}</div>
              </div>
              <div className="mt-3 row">
                <div className="col-5">Contact Number of Family Member</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].FMContact}</div>
              </div>
              <div className="mt-3 row">
                <div className="col-5">Address of Family Member</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].FMAddress}</div>
              </div>

              <div className="mt-3 row">
                <div className="col-5">Residing Country of Family Member</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].FMcountry}</div>
              </div>
              <div className="mt-3 row">
                <div className="col-5">Residing State of Family Member</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].FMstate}</div>
              </div>
              <div className="mt-3 row">
                <div className="col-5">Residing City of Family Member</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].FMcity}</div>
              </div>
              <div className="mt-3 row">
                <div className="col-5">Pincode</div>
                <div className="col-2">:</div>
                <div className="col-5">{value[0].FMPincode}</div>
              </div>
            </div>
            {/* <div className="mt-4">
              <h4 className="mt-3">Accounts created</h4>
              <FME_executive_earnings_in_profile_page id={Id} />
            </div> */}
          </div>
        </>
      )}

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Account Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            {value.Status == 'Active'
              ? ' Are you sure suspend this account ?'
              : 'Are you sure activate this account ?'}
          </h5>
          {value.Status == 'Active' ? (
            <div className="pt-3">
              <label class="form-label">Enter Reason</label>
              <textarea
                rows={5}
                class="form-control"
                onChange={(e) => setSuspendreason(e.target.value)}
              />
            </div>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn pro-spaces-button3"
            onClick={(e) => updateStatus()}
          >
            {value.Status == 'Active'
              ? 'Suspend this account'
              : 'Activate this account'}
          </button>
        </Modal.Footer>
      </Modal>
      {/* send an email */}
      <Modal show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <Modal.Title>Send an Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pt-3">
            <label className="labels">Date *</label>
            <input type="date" className="form-control" />
          </div>
          <div className="pt-3">
            <label className="labels">Monthly Salary *</label>
            <input type="text" className="form-control" />
          </div>
          <div className="pt-3">
            <label className="labels">Salary Break-up *</label>
            <input type="text" className="form-control" />
          </div>
          <div className="pt-3">
            <label className="labels">Target based Salary *</label>
            <input type="text" className="form-control" />
          </div>
          <div className="pt-3">
            <label className="labels">
              Allowances (towards conveyance and calls) *
            </label>
            <input type="text" className="form-control" />
          </div>
          <div className="pt-3">
            <label className="labels">
              Targets to achieve for the first Two Months *
            </label>
            <input type="text" className="form-control" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn pro-spaces-button3"
            onClick={(e) => updateStatus()}
          >
            Send
          </button>
        </Modal.Footer>
      </Modal>
      {/* send an email */}
    </div>
  );
};
