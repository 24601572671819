import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiReset,BiDotsVerticalRounded } from "react-icons/bi";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import {  GiRotaryPhone } from "react-icons/gi";
import Modal from "react-bootstrap/Modal";

export default function ActivistsAnimalView(props) {
  useEffect(() => {
    // props.Auth();
  }, []);

  const [show, setShow] = useState(false);
  const [Id, setId] = useState();
  const handleShow = () => setShow(true);

  const handleClose = () => {
    setShow(false);
    setId("");
  };

  const [first, setfirst] = useState(false);
  const [data, setdata] = useState(false);
  const [cat, setCat] = useState(false);

  const [load, setload] = useState(false);
  const [error, seterror] = useState("");
  const [output, setoutput] = useState([]);
  const [Keyer, setKeyer] = useState(1);
  const [Stateload, setStateload] = useState(true);

  const GetActivistsAnimal = (last) => {
    setload(true);
    console.log("page");
    axios
      .get("/api/User/getActivistsAnimal")
      .then((response) => {
        setload(false);
        console.log("heree");
        console.log(response);
        setoutput(response.data);
      })
      .catch((res) => {
        setload(false);
        console.log("Something went wrong");
      });
  };

  useEffect(() => {
    GetActivistsAnimal();
  }, []);

  const [index, setindex] = useState();

  const Delete = () => {
    console.log(Id);
    console.log(Id);
    var id = Id;
    axios
      .post("/api/Admin/ActivistsAnimalDelete", { id })
      .then((response) => {
        // console.log('success')
        if (response.status === 200) {
          //   console.log("reported11")
          // setreported(true)
          // window.location.reload();
          let temp = [...output];
          temp.splice(index, 1);
          setindex(undefined);
          setId("");
          setoutput(temp);
          handleClose();
        }
      })
      .catch((res) => {
        console.log("Something went wrong");
      });
  };

  return (
    <div className="container-fluid bg-trasparent pro-sabari wieugfiqgefnqenc2rnewiuhgiuhq ">
      <div>
        {" "}
        <div className="d-flex justify-content-end">
          <Link to="/activist_animal_reg">
            <button className="btn btn-dosabutton ps-3 pe-3 mb-3 deghievgviehcieh">
              Add New
            </button>
          </Link>
          &nbsp;
        </div>
      </div>

      <div className="row car">
        {" "}
        {load ? (
          <center>
            <Spinner animation="border" />
          </center>
        ) : output.length ? (
          output.map((elem) => {
            return (
              <>
                <div className=" col-12 col-md-6 col-lg-6 col-xl-4  mb-3">
                  <div className="text-center card-box p-0">
                    <div className="member-card pt-3 pb-0">
                      <div className="d-flex justify-content-end">
                        <a
                          type="button"
                          href="/"
                          className="dotoptionbtn   cell"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <BiDotsVerticalRounded
                            size={35}
                            onClick={(e) => {
                              setId(elem._id);
                              setindex(index);
                            }}
                            color={"black"}
                          ></BiDotsVerticalRounded>
                        </a>
                        <ul className="dropdown-menu ">
                          <li>
                            <Link
                              to={"/activist_animal_edit?pi=" + elem._id}
                              className="dropdown-item"
                            >
                              Edit post
                            </Link>
                          </li>
                          <li>
                            <a className="dropdown-item" onClick={handleShow}>
                              {" "}
                              Delete
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="py-2">
                        <h6>{elem.name.toUpperCase()}</h6>
                        <div>
                          <span>
                            {elem.Description ? (
                              <div>
                                <b>Description</b>
                                <p className="px-2">{elem.Description}</p>
                              </div>
                            ) : (
                              ""
                            )}

                            {elem.Address ? (
                              <div>
                                <b>Address</b>
                                <p className="px">{elem.Address}</p>
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="row w-100 me-0 m-0 p-0">
                              {elem.phone ? (
                                <div className="entityJobs col">
                                  <small className="ufghsuhie">
                                    <GiRotaryPhone size={25} />
                                  </small>
                                  <br />
                                  <>
                                    <a
                                      href={`tel:${elem.phone}`}
                                      style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                      }}
                                    >
                                      {elem.phone}
                                    </a>
                                  </>
                                </div>
                              ) : (
                                <></>
                              )}
                              {elem.email ? (
                                <div className="entityJobs col">
                                  <small className="ufghsuhie">Email Id</small>
                                  <br />
                                  <>
                                    <a
                                      href={`mailto:${elem.email}`}
                                      style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                      }}
                                    >
                                      {elem.email}
                                    </a>
                                  </>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Modal show={show} onHide={handleClose} backdrop={false}>
                  <Modal.Header closeButton>
                    <Modal.Title>Deleting post </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>Are you sure?</Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn pro-spaces-button3"
                      onClick={(e) => Delete()}
                    >
                      Delete
                    </button>
                  </Modal.Footer>
                </Modal>
              </>
            );
          })
        ) : (
          <>
            <center>
              <h4>No result Found !!</h4>
            </center>
          </>
        )}
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
