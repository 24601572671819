import React, { useState, useEffect, useRef, useReducer, createContext, useContext } from "react";
import { Form1 } from './Form/Form1'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "cropperjs/dist/cropper.css";
import { useNavigate, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import Select from 'react-select';
import { Form2 } from "./Form/Form2";
import { Form3 } from "./Form/Form3";
import { Form4 } from "./Form/Form4";
import { SpinnerBig } from "../../../Utility/spinner";
export const CatsContext = createContext();
export const ConvertContext = createContext()
export const EditContext = createContext()
export const InformationForms = (props) => {

  useEffect(() => {
    GetAllInformationCategory() 
  }, [])
  const [Loading, setLoading] = useState({ categoryData: true, data: true })
  let [searchParams, setSearchParams] = useSearchParams();  /////getting Information id
  var id = searchParams.get("k")
  const reducer = (state, action) => {
    console.log(action)
    switch (action.type) {
      case "1":
        return <Form1 postTo={props.postTo} />;
      case "2":
        return <Form2 postTo={props.postTo} />;
      case "3":
        return <Form3 postTo={props.postTo} />;
      case "4":
        return <Form4 postTo={props.postTo} />;
      default:
        <></>;
      // throw new Error();
    }
  };
  const [state, dispatch] = useReducer(reducer, <></>);

  const [Category, setCategory] = useState([])
  const [SubCategory, setSubCategory] = useState([])
  const [SubSubCategory, setSubSubCategory] = useState([])
  const [ShowSubSubCategory, setShowSubSubCategory] = useState(false)
  const [value, setvalue] = useState({
    Category: "",
    SubCategory: "",
    SubSubCategory: "",
    FormType: ""
  })
  useEffect(() => {
    GetAllInformationCategory() 
    GetEdit()
      
  }, [Category.length])
  const GetAllInformationCategory = () => {
    axios.get('/api/api/GetAllInformationCategory').then((res) => {
      if (res.status == 200) {
        console.log(res.data)
        setCategory(res.data)

      }
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      ListCategory()
      setLoading(prev => ({ ...prev, categoryData: false }));


    })
  }
const [ListsCategory, setListsCategory] = useState("")
  const ListCategory=()=>{
      
    var me
    me=Category.map((e) => {
      return <option value={e._id}>{e.category}</option>
    })
    setListsCategory(me)
  }
  const ListSubCategory2 = (Cat_id) => {
    console.log(Cat_id)
    // setvalue({ ...value, Category: Cat_id });
    dispatch({ type: '.' })
    var me
    me = Category.map((e) => {
      if (e._id === Cat_id) {
        var formType = e.formType.toString()
        // setvalue({ ...value, FormType: formType, Category: Cat_id })
        dispatch({ type: formType })
        return e.Subcategory.map((e1) => {
          return <option value={e1._id}>{e1.sub}</option>
        })
      }
    })
    console.log(value)
    me = me.flat()
    me = me.filter((elem) => elem != undefined)
    setSubCategory(me)
  }
  const ListSubCategory = (Cat_id) => {
    console.log(Cat_id)
    setvalue({ ...value, Category: Cat_id });
    dispatch({ type: '.' })
    var me
    me = Category.map((e) => {
      if (e._id === Cat_id) {
        var formType = e.formType.toString()
        setvalue({ ...value, FormType: formType, Category: Cat_id })
        dispatch({ type: formType })
        return e.Subcategory.map((e1) => {
          return <option value={e1._id}>{e1.sub}</option>
        })
      }
    })
    console.log(value)
    me = me.flat()
    me = me.filter((elem) => elem != undefined)
    setSubCategory(me)
  }
  const ListBrand = (Sub) => {
    console.log(Sub)
    console.log(value.Category)
    setvalue({ ...value, SubCategory: Sub })
    var me
    me = Category.map((e) => {
      if (e._id === value.Category) {
        return e.Subcategory.map((e1) => {
          if (e1._id === Sub) {
            if (e1.SubSubCategory.length > 0) {
              setShowSubSubCategory(true)
              return e1.SubSubCategory.map((e2) => {
                return <option value={e2._id}>{e2.sub_sub_category}</option>
              })
            }

          }

        })
      }
    })
    console.log(me)
    me = me.flat()
    me = me.filter((elem) => elem != undefined)
    setSubSubCategory(me)
  }
  const ListBrand2 = (Cat,Sub) => {
    console.log(Sub)
    console.log(value.Category)
    var me
    me = Category.map((e) => {
      if (e._id === Cat) {
        return e.Subcategory.map((e1) => {
          if (e1._id === Sub) {
            if (e1.SubSubCategory.length > 0) {
              setShowSubSubCategory(true)
              return e1.SubSubCategory.map((e2) => {
                return <option value={e2._id}>{e2.sub_sub_category}</option>
              })
            }

          }

        })
      }
    })
    console.log(me)
    me = me.flat()
    me = me.filter((elem) => elem != undefined)
    setSubSubCategory(me)
  }
  const [data, setdata] = useState("")
  const GetEdit = () => {
    if (props.edit) {
      axios.post('/api/Admin/GetInformationFormForEdit', { id }).then((res) => {
        if (res.status == 200) {
          console.log(res.data)
          // ListCategory()
          setdata(res.data)
          setvalue({
            Category: res.data.category,
            SubCategory: res.data.subcategory,
            SubSubCategory: res.data.subsubcategory,
            FormType: res.data.formType,
            Id: id
          })
          if (res.data.subsubcategory) {
            setShowSubSubCategory(true)
          }
          ListSubCategory2(res.data.category)
          ListBrand2(res.data.category,res.data.subcategory)
          dispatch({ type: res.data.formType })
        }
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        setLoading(prev => ({ ...prev, data: false }));

      })
    }

  }

//   useEffect(() => {
// console.log(value)
//   }, [value])
  



  if (Loading.categoryData && Loading.data) {
    return <div><SpinnerBig/>
    </div>
  } else {
    return (


      <>
        <div className="container rounded bg-white">
          <center><h5 className='pt-4'>Information Creation Form</h5></center>

          <h6>Select your appropriate Category and Sub Category</h6>
          <div className="col-md-12"><label className="labels">Category *</label>
            <select className="form-select" value={value.Category} onChange={(e) => ListSubCategory(e.target.value)} disabled={props.edit?true:false}>
              <option ></option>
              {
               ListsCategory?ListsCategory:""
              }
            </select>
          </div>
          <div className="col-md-12"><label className="labels">Sub Category *</label>
            <select id='subcatanandsfdfdsfda83389' className="form-select" value={value.SubCategory} onChange={(e) => ListBrand(e.target.value)}>
              <option></option>{SubCategory ? SubCategory : ""}</select></div>
          {
            ShowSubSubCategory ?
              <>
                <div className="col-md-12"><label className="labels">Sub Sub Category *</label>
                  <select className="form-select" value={value.SubSubCategory} onChange={(e) => setvalue({ ...value, SubSubCategory: e.target.value })} >
                    <option></option>{SubSubCategory ? SubSubCategory : ""}</select></div>
              </>
              : ""
          }

        </div>
        {
          value.Category && value.SubCategory ?
            <>
              <EditContext.Provider value={data}>
                <CatsContext.Provider value={{ value }}>
                  <ConvertContext.Provider value={props.sab}>
                    {state}
                  </ConvertContext.Provider>
                </CatsContext.Provider>
              </EditContext.Provider>
            </>
            : ""
        }
      </>
    )
  }
}
