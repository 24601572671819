import React, { useState, useEffect } from "react";
import { AdminType } from "../../Utility/helper";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { validator } from "validator";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Form } from "react-bootstrap";

import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { print_state, print_city, state_arr } from "../../Utility/stateCity";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StateCity } from "./StateCity";
import { IoChevronUpSharp, IoInformationCircleOutline } from "react-icons/io5";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Timer } from "../../Utility/timer";
import { Footer } from "../../Pages/HomePage/Footer";
import { ControlBar } from "../../Pages/HomePage/ControlBar";
import { Navbar2 } from "../../Pages/HomePage/Navbar2";
import { Imageupload } from "./Imageupload";
import moment from "moment";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "300",
    },
  },
};

export const MarketingAccount = (props) => {
  let navigate = useNavigate();
  const [Stateload, setStateload] = useState(true);
  useEffect(() => {
    if (Stateload) print_state("State");
    setStateload(false);
  }, []);
  const [value, setValue] = useState({
    AdminType: "AffiliatedAccount",
    FixedSalary: true,
    Name: "",
    Email: "",
    Contact: "",
    Password: "",
    ConformPassword: "",
    showPassword: false,
    showConformPassword: false,
    Referral_Code: "",
    Active_status: true,
    Activev2_status: true,
    Activev3_status: true,
    Passive_status: true,
    Service_provide_category_All: true,
    Product_retailer_category_All: true,
    state: "",
    city: "",
    City: "",
    Commission_percentage: "",
    Bank_account_type: "",
    loginID: "",
    Work_Experience: "",
    Highest_Educational_Qualification: "",
    Ready_to_Join_on_anyday_after: "",
  });

  const [Edit, setEdit] = useState("");
  useEffect(() => {
    axios
      .get("/api/AffiliatedAccount/getAffiliatedAccountDetails")
      .then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          var data = res.data[0];
          if (res.data.length > 0) {
            props.Auth1();
            setEdit(res.data);
            setValue({
              ...value,
              Name: data.Name,
              Email: data.Account[0]?.Email,
              Contact: data.Account[0]?.Contact,
              Referral_Code: data.Referral_Code,
              state: data.state,
              city: data.city,
              Account_holder_name: data.Account_holder_name,
              Account_number: data.Account_number,
              Ifsc_code: data.Ifsc_code,
              Bank_name: data.Bank_name,
              Branch_name: data.Branch_name,
              country: data.country,
              Bank_account_type: data?.Bank_account_type,
              loginID: data.Account[0]?._id,
              Address: data.Address,
              Pincode: data.Pincode,
              Age: data.Age,
              Gender: data.Gender,
              Nationality: data.Nationality,
              Work_Experience: data.Work_Experience,
              Highest_Educational_Qualification:
                data.Highest_Educational_Qualification,
              Ready_to_Join_on_anyday_after: data.Ready_to_Join_on_anyday_after,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    console.log(value);
  }, [value]);

  // var validator=validator

  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (sab == "red") {
      toast.danger(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  function Cityshow(e) {
    var index = document.getElementById(e.target.id).selectedIndex;
    print_city("City", index);
    setValue({ ...value, state: e.target.value });
  }

  // const [Cat, setCat] = useState([])
  //       function reload()
  //       {
  //         axios
  //         .get("/api/api//getcategory")
  //         .then((res) => {
  //           if (res.status === 200) {
  //             console.log("workd");
  //             console.log(res.data)
  //             setCat(res.data);
  //           }
  //         })
  //         .catch((err) => {
  //           // console.log(err.response.status);
  //         });
  //       }
  // const [Cat1, setCat1] = useState([])

  //       function reload1()
  //       {
  //         axios
  //         .get("/api/api/getprocategory")
  //         .then((res) => {
  //           if (res.status === 200) {
  //             console.log("workd");
  //             console.log(res.data)
  //             setCat1(res.data);
  //           }
  //         })
  //         .catch((err) => {
  //           // console.log(err.response.status);
  //         });
  //       }

  //       useEffect(() => {
  //         reload()
  //         reload1()

  //       }, [])

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  function validator1(e) {
    e.preventDefault();
    setSubmit2(true);
    const errors = {};
    // if(!value.Name)return setError("Name is missing !");
    // if (!value.Email) {
    //   errors.email = 'Email required !'
    // return setError(errors)
    // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.Email)) {
    //   errors.email = 'Invalid email address !'
    // return setError(errors)
    // }

    // if (!value.Contact) {
    //     return setError('Enter phone number !')
    // }
    // if (value.Contact.toString().length != 10) {
    //     return setError('Enter a valid mobile number !')
    // }
    // if(!value.Commission_percentage)return setError("Discount percentage is missing !");

    if (!value.Address) {
      return setError("");
    }
    if (!value.Pincode) {
      return setError("");
    }

    if (!Edit.length > 0) {
      if (!img) {
        return setError("PLEASE UPLOAD Image");
      }
      if (!value.Work_Experience) return setError("");
      if (!value.Highest_Educational_Qualification) return setError("");
      if (!value.Ready_to_Join_on_anyday_after) return setError("");

      if (!value.Password) return setError("");

      //   if (value.Password.toString().length <8) {
      //     return setError('Password must contain 8 characters !')
      // }
      setError("");
      if (value.Password.toString() !== value.ConformPassword.toString()) {
        return setError("'Password' and 'Confirm Password' must be matching !");
      }
    }

    setError("");
    // &&TermsAndCondition.Terms4
    if (Edit.length > 0) {
      console.log("<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>1");
      handleShow11();
    } else {
      if (value.Age <= 17) {
        return setError(
          "Sorry....you are too young ! Age must be 18 years and above."
        );
      }
      setError("");
      if (
        TermsAndCondition.Terms1 &&
        TermsAndCondition.Terms2 &&
        TermsAndCondition.Terms3 &&
        TermsAndCondition.Terms4 &&
        TermsAndCondition.Terms5 &&
        TermsAndCondition.Terms6 &&
        TermsAndCondition.Terms7 &&
        TermsAndCondition.Terms8 &&
        TermsAndCondition.Terms9 &&
        TermsAndCondition.Terms10 &&
        TermsAndCondition.Terms16 &&
        TermsAndCondition.Terms17 &&
        TermsAndCondition.Terms18 &&
        TermsAndCondition.Terms19 &&
        TermsAndCondition.Terms20 &&
        TermsAndCondition.Terms21 &&
        TermsAndCondition.Terms22 &&
        TermsAndCondition.Terms23 &&
        TermsAndCondition.Terms24 &&
        TermsAndCondition.Terms25 &&
        TermsAndCondition.Terms26 &&
        TermsAndCondition.Terms27 &&
        TermsAndCondition.Terms28 &&
        // TermsAndCondition.Terms11
        //  &&
        TermsAndCondition.Terms12
        // &&
        // TermsAndCondition.Terms13
      ) {
        if (!ButtonDisabled1) {
          setButtonDisabled(false);
          console.log("hai");
        }
      } else {
        return setError("Accept all terms and condition ");
      }
      go();
    }
  }

  const [img, setimg] = useState();

  function go() {
    console.log("meeeeeeee");
    var name = value.Name;
    if (ButtonDisabled1) {
      return setError("Enter referral code ");
    }
    setError("");
    setLoading(true);
    // console.log(value)
    // return

    var formdata = new FormData();

    formdata.append("data", JSON.stringify({ value, Affiliated }));
    var imag = new File([img], "avatar.png", { type: "image/png" });

    formdata.append("file", imag);

    var Affiliated = true;
    axios
      .post("/api/auth/CreateAffiliatedAccount", formdata)
      .then((res) => {
        if (res.status === 200) {
          notify("Successfully created", true);
          setValue({
            ...value,
            AdminType: "",
            Name: "",
            Email: "",
            Contact: "",
            Password: "",
            Pin: "",
            Active_status: true,
            Activev2_status: true,
            Activev3_status: true,
            Passive_status: true,
            Service_provide_category_All: true,
            Product_retailer_category_All: true,
            state: "",
            city: "",
            Commission_percentage: "",
            showConformPassword: false,
            Referral_Code: "",
            ConformPassword: "",
            Account_holder_name: "",
            Account_number: "",
            Ifsc_code: "",
            Bank_name: "",
            Branch_name: "",
            country: "",
            Bank_account_type: "",
            Gender: "",
            Age: "",
            Nationality: "",
            Address: "",
            Pincode: "",
            Work_Experience: "",
            Highest_Educational_Qualification: "",
            Ready_to_Join_on_anyday_after: "",
          });
          setService_provide_category([]);
          setProduct_retailer_category([]);
          setLoading(false);
          setError("");
          navigate(`/congratulations?name=${name}`);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          notify("Wrong security pin", false);
        } else if (err.response.status === 402 || err.response.status === 409) {
          notify("Email already exist", false);
        } else if (err.response.status === 403) {
          notify("Name already exist", false);
        } else if (err.response.status === 406) {
          notify("This Code already exists", false);
        } else if (err.response.status === 407) {
          notify("This Code already exists", false);
        } else if (err.response.status === 408) {
          notify("Enter referral code", false);
        } else {
          notify("Something went wrong", false);
        }
        setLoading(false);
      });
  }

  const [Service_provide_category, setService_provide_category] =
    React.useState([]);

  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setService_provide_category(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [Product_retailer_category, setProduct_retailer_category] =
    React.useState([]);
  const handleChange2 = (event) => {
    const {
      target: { value },
    } = event;
    setProduct_retailer_category(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    if (value.Service_provide_category_All) {
      setService_provide_category([]);
    }
    if (value.Product_retailer_category_All) {
      setProduct_retailer_category([]);
    }
  }, [value.Service_provide_category_All, value.Product_retailer_category_All]);

  const [ButtonDisabled, setButtonDisabled] = useState(false);
  const [ButtonDisabled1, setButtonDisabled1] = useState(true);
  const checkReferral_Code = (e) => {
    if (!Edit?.length > 0) {
      setButtonDisabled(true);
    }
    var Referral_Code = e;
    axios
      .post("/api/auth/checkReferral_Code", { Referral_Code })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length == 0) {
            notify("This referral code is available for you", true);

            setButtonDisabled1(false);

            setError("");
          } else {
            notify("This Code already exists", false);
            setError("This Code already exists");
          }
        }
      })
      .catch((err) => {
        notify("Something went wrong", false);
        setError("");
      });
  };
  const [Submit2, setSubmit2] = useState(false);
  const [Error2, setError2] = useState("");
  function Update(e) {
    setError2("");
    var formdata = new FormData();
    formdata.append("data", JSON.stringify({ value, OTP }));
    if (img) {
      var imag = new File([img], "avatar.png", { type: "image/png" });

      formdata.append("file", imag);
    }
    axios
      .post("/api/AffiliatedAccount/Update_Account", formdata)
      .then((res) => {
        if (res.status == 200) {
          notify("Updated", true);
          console.log(res.data);
          handleClose11();
          setError2("");
          setOTP("");
          end();
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 410) {
          return setError2("Enter valid OTP ");
        }
        notify("Something went wrong !", false);
      });
  }
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const [TermsAndCondition, setTermsAndCondition] = useState({
    Terms1: false,
    Terms2: false,
    Terms3: false,
    Terms4: false,
    Terms5: false,
    Terms6: false,
    Terms7: false,
    Terms8: false,
    Terms9: false,
    Terms10: false,
    Terms16: false,
    Terms17: false,
    Terms18: false,
    Terms19: false,
    Terms20: false,
    Terms21: false,
    Terms22: false,
    Terms23: false,
    Terms24: false,
    Terms25: false,
    Terms26: false,
    Terms27: false,
    Terms28: false,

    // Terms11: false,
    Terms12: false,
    // Terms13: false,
  });

  useEffect(() => {
    // console.log(TermsAndCondition)
    // console.log("ButtonDisabled :",ButtonDisabled)
    // console.log("ButtonDisabled1 :",ButtonDisabled1)
    console.log("<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>2");
    console.log(Edit.length);
    if (Edit.length > 0) {
      console.log(Edit.length);
    } else {
      console.log("<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>2");
      //
      if (
        TermsAndCondition.Terms1 &&
        TermsAndCondition.Terms2 &&
        TermsAndCondition.Terms3 &&
        TermsAndCondition.Terms4 &&
        TermsAndCondition.Terms5 &&
        TermsAndCondition.Terms6 &&
        TermsAndCondition.Terms7 &&
        TermsAndCondition.Terms8 &&
        TermsAndCondition.Terms9 &&
        TermsAndCondition.Terms10 &&
        TermsAndCondition.Terms16 &&
        TermsAndCondition.Terms17 &&
        TermsAndCondition.Terms18 &&
        TermsAndCondition.Terms19 &&
        TermsAndCondition.Terms20 &&
        TermsAndCondition.Terms21 &&
        TermsAndCondition.Terms22 &&
        TermsAndCondition.Terms23 &&
        TermsAndCondition.Terms24 &&
        TermsAndCondition.Terms25 &&
        TermsAndCondition.Terms28 &&
        TermsAndCondition.Terms26 &&
        TermsAndCondition.Terms27 &&
        // TermsAndCondition.Terms11
        // &&
        TermsAndCondition.Terms12
        //  &&
        // TermsAndCondition.Terms13
      ) {
        if (!ButtonDisabled1) {
          setButtonDisabled(false);
          console.log("hai");
        }
      } else {
        return setError(" ");
      }
    }
    setError("");
  }, [Edit, TermsAndCondition, ButtonDisabled, ButtonDisabled1]);

  const [show11, setShow11] = useState(false);

  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => {
    setShow11(true);
    //
    GenerateOTP();
  };
  const GenerateOTP = () => {
    console.log("hai");
    axios
      .post("/api/AffiliatedAccount/GenerateOTPForAccountUpdate", {
        Email: value.Email,
      })
      .then((res) => {
        if (res.status == 200) {
          setpop(true);
          notify("OTP send successfully", true);
        }
      })
      .catch((err) => {
        setpop(false);
        notify("Something went wrong", false);
      });
  };

  const [OTP, setOTP] = useState("");
  const [pop, setpop] = useState(true);

  const end = () => setpop(false);

  const [NewPassword, setNewPassword] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [showpassword, setShowpassword] = useState(false);

  const handleClose111 = () => setShowpassword(false);
  const handleShow111 = () => setShowpassword(true);
  const [showPassword12, setShowPassword12] = useState(false);
  const [showPassword123, setShowPassword123] = useState(false);
  const UpdatePassword = () => {
    axios
      .post("/api/AffiliatedAccount/AccountPasswordUpdate", { NewPassword })
      .then((res) => {
        if (res.status == 200) {
          notify("Password updated successfully", true);
          setNewPassword({
            oldPassword: "",
            newPassword: "",
          });
          handleClose111();
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          notify("Old password is incorrect", false);
        } else {
          notify("Something went wrong", false);
        }
      });
  };

  const [showreferral, setShowreferral] = useState(false);

  const handleClosereferral = () => setShowreferral(false);
  const handleShowreferral = () => setShowreferral(true);

  /////////////////////////////////////////////////////////////
  return (
    <>
      {!Edit?.length > 0 ? (
        <>
          <ControlBar />
          <Navbar2 />
        </>
      ) : (
        ""
      )}

      <div className=" rounded bg-white px-3 px-md-5 py-5">
        {!Edit?.length > 0 ? (
            <>
          <h3 className="text-center mt-4 ">
            MARKETING INTERNSHIP REGISTRATION FORM
          </h3>
           <div className="text-center">
           This is a Legal Agreement between You and{' '}
           <span
             className="vipar"
             style={{
               color: 'green',
               fontSize: '20px',
               paddingRight: '5px',
             }}
           >
             Vipar
           </span>{' '}
           <sup>®</sup>
         </div>
         </>
        ) : (
          <h3 className=" mt-4 mb-5">Account Details</h3>
        )}
        {Edit?.length > 0 ? (
          <div className="d-flex justify-content-end">
            <button
              className="btn pro-spaces-button3"
              onClick={(e) => {
                handleShow111(e);
              }}
            >
              Change password
            </button>
          </div>
        ) : (
          ""
        )}
        <form
          onSubmit={(e) => validator1(e)}
          novalidate
          className={Submit2 ? "was-validated" : ""}
        >
          {!Edit?.length > 0 ? (
            <>
              <h5 className="mt-4 ">TERMS and CONDITIONS *</h5>
              <div className="pt-4">CONSENT :</div>

              <div class="form-check pt-3">
                <input
                  class="form-check-input affcheckbox"
                  type="checkbox"
                  id="flexCheckDefault1"
                  defaultChecked={TermsAndCondition.Terms1}
                  onClick={(e) => {
                    setTermsAndCondition({
                      ...TermsAndCondition,
                      Terms1: !TermsAndCondition.Terms1,
                    });
                  }}
                />
                <label class="form-check-label" for="flexCheckDefault1">
                  I am aware and agree that I fully understand that{" "}
                  <span
                    className="vipar"
                    style={{
                      color: "green",
                      fontSize: "20px",
                      paddingRight: "5px",
                    }}
                  >
                    Vipar
                  </span>{" "}
                  <sup>®</sup> is the Organisation (based out of Mangalore,
                  Karnataka) which has employed me as a Marketing Intern and
                  that <span className="prochure">PROchure</span> is only an
                  application developed by{" "}
                  <span
                    className="vipar"
                    style={{
                      color: "green",
                      fontSize: "20px",
                      paddingRight: "5px",
                    }}
                  >
                    Vipar
                  </span>{" "}
                  <span
                    className="verticals"
                    style={{ fontSize: "16px", color: "black" }}
                  >
                    CONNECT
                  </span>
                  , which is a unit of{" "}
                  <span
                    className="vipar"
                    style={{
                      color: "green",
                      fontSize: "20px",
                      paddingRight: "5px",
                    }}
                  >
                    Vipar
                  </span>{" "}
                  <sup>®</sup>.
                </label>
              </div>

              <div class="form-check pt-3">
                <input
                  class="form-check-input affcheckbox"
                  type="checkbox"
                  id="flexCheckDefault1"
                  defaultChecked={TermsAndCondition.Terms26}
                  onClick={(e) => {
                    setTermsAndCondition({
                      ...TermsAndCondition,
                      Terms26: !TermsAndCondition.Terms26,
                    });
                  }}
                />
                <label class="form-check-label" for="flexCheckDefault1">
                  I admit and agree that I am joining{" "}
                  <span
                    className="vipar"
                    style={{
                      color: "green",
                      fontSize: "20px",
                      paddingRight: "5px",
                    }}
                  >
                    Vipar
                  </span>{" "}
                  <sup class="">®</sup> as a Marketing Intern with the knowledge
                  and consent of the concerned authority of the College /
                  Institution where I am currently pursuing my education, and
                  that IF need arises, I shall get the required consent form
                  from the College / Institution.
                </label>
              </div>

              <div class="form-check pt-3">
                <input
                  class="form-check-input affcheckbox"
                  type="checkbox"
                  id="flexCheckDefault1"
                  defaultChecked={TermsAndCondition.Terms28}
                  onClick={(e) => {
                    setTermsAndCondition({
                      ...TermsAndCondition,
                      Terms28: !TermsAndCondition.Terms28,
                    });
                  }}
                />
                <label class="form-check-label" for="flexCheckDefault1">
                  I am aware that as a Field Sales Marketing Intern at{" "}
                  <span
                    className="vipar"
                    style={{
                      color: "green",
                      fontSize: "20px",
                      paddingRight: "5px",
                    }}
                  >
                    Vipar
                  </span>{" "}
                  <sup class="">®</sup> my job is to get potential interested
                  customers to subscribe on the platform called{" "}
                  <span className="prochure">PROchure</span> (an app accessible
                  to users across the globe) which is primarily a digital
                  directory of Services and Products.
                </label>
              </div>

              <div className="pt-4">MARKETING :</div>

              <div class="form-check pt-3">
                <input
                  class="form-check-input affcheckbox"
                  type="checkbox"
                  id="flexCheckDefault2"
                  defaultChecked={TermsAndCondition.Terms21}
                  onClick={(e) => {
                    setTermsAndCondition({
                      ...TermsAndCondition,
                      Terms21: !TermsAndCondition.Terms21,
                    });
                  }}
                />
                <label class="form-check-label" for="flexCheckDefault2">
                  I am aware and agree that I SHALL read, understand and explain
                  the features, benefits, fee structure and membership
                  subscription details of the app to each and every customer
                  with clarity.
                </label>
              </div>
              <div class="form-check pt-3">
                <input
                  class="form-check-input affcheckbox"
                  type="checkbox"
                  id="flexCheckDefault2"
                  defaultChecked={TermsAndCondition.Terms22}
                  onClick={(e) => {
                    setTermsAndCondition({
                      ...TermsAndCondition,
                      Terms22: !TermsAndCondition.Terms22,
                    });
                  }}
                />
                <label class="form-check-label" for="flexCheckDefault2">
                  I am aware and agree that I SHALL strictly follow the
                  instructions regarding how a profile should be created for a
                  customer and shall leave no scope for errors.{" "}
                </label>
              </div>

              <div className="pt-4">PROFESSIONAL CONDUCT / ETHICS :</div>
              <div class="form-check pt-3">
                <input
                  class="form-check-input affcheckbox"
                  type="checkbox"
                  id="flexCheckDefault15"
                  defaultChecked={TermsAndCondition.Terms17}
                  onClick={(e) => {
                    setTermsAndCondition({
                      ...TermsAndCondition,
                      Terms17: !TermsAndCondition.Terms17,
                    });
                  }}
                />
                <label class="form-check-label" for="flexCheckDefault15">
                  I am aware and agree that I SHALL enroll subscribers on to the{" "}
                  <span className="prochure">PROchure</span>{" "}
                  {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                  platform ONLY via the www.vipar-connect.in domain / web
                  application and SHALL NOT do the same via any other online /
                  offline method.
                </label>
              </div>
              <div class="form-check pt-3">
                <input
                  class="form-check-input affcheckbox"
                  type="checkbox"
                  id="flexCheckDefault3"
                  defaultChecked={TermsAndCondition.Terms3}
                  onClick={(e) => {
                    setTermsAndCondition({
                      ...TermsAndCondition,
                      Terms3: !TermsAndCondition.Terms3,
                    });
                  }}
                />
                <label class="form-check-label" for="flexCheckDefault3">
                  I am aware and agree that I SHALL NOT make any false claims /
                  false promises to the potential subscribers regarding the
                  features and benefits of using the platform or mislead them
                  with incorrect or wrong information regarding the app only for
                  the sake of getting their subscription.
                </label>
              </div>

              <div class="form-check pt-3">
                <input
                  class="form-check-input affcheckbox"
                  type="checkbox"
                  id="flexCheckDefault15"
                  defaultChecked={TermsAndCondition.Terms16}
                  onClick={(e) => {
                    setTermsAndCondition({
                      ...TermsAndCondition,
                      Terms16: !TermsAndCondition.Terms16,
                    });
                  }}
                />
                <label class="form-check-label" for="flexCheckDefault15">
                  I am aware and agree that I SHALL NOT say anything to the
                  potential subscribers at my own discretion beyond what has
                  been instructed to me by{" "}
                  <span
                    className="vipar"
                    style={{
                      color: "green",
                      fontSize: "20px",
                      paddingRight: "5px",
                    }}
                  >
                    Vipar
                  </span>{" "}
                  <sup class="">®</sup> and that I SHALL maintain absolute
                  professional decorum as a representative of{" "}
                  <span
                    className="vipar"
                    style={{
                      color: "green",
                      fontSize: "20px",
                      paddingRight: "5px",
                    }}
                  >
                    Vipar
                  </span>{" "}
                  <sup class="">®</sup> during my course of interaction with
                  prospective customers.
                </label>
              </div>
              <div class="form-check pt-3">
                <input
                  class="form-check-input affcheckbox"
                  type="checkbox"
                  id="flexCheckDefault15"
                  defaultChecked={TermsAndCondition.Terms23}
                  onClick={(e) => {
                    setTermsAndCondition({
                      ...TermsAndCondition,
                      Terms23: !TermsAndCondition.Terms23,
                    });
                  }}
                />
                <label class="form-check-label" for="flexCheckDefault15">
                  I am aware and agree that I SHALL strictly abide by the rules
                  and regulations of the organisation, and all the Terms and
                  Conditions mentioned here and that I SHALL NOT indulge in any
                  activity that will directly or indirectly spoil the reputation
                  of the platform ( <span className="prochure">PROchure</span>{" "}
                  {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}) and the
                  organisation ({" "}
                  <span
                    className="vipar"
                    style={{
                      color: "green",
                      fontSize: "20px",
                      paddingRight: "5px",
                    }}
                  >
                    Vipar
                  </span>{" "}
                  <sup class="">®</sup> ).
                </label>
              </div>
              <div className="pt-4">TARGETS and REMUNERATION :</div>
              <div class="form-check pt-3">
                <input
                  class="form-check-input affcheckbox"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault5"
                  defaultChecked={TermsAndCondition.Terms5}
                  onClick={(e) => {
                    setTermsAndCondition({
                      ...TermsAndCondition,
                      Terms5: !TermsAndCondition.Terms5,
                    });
                  }}
                />
                <label class="form-check-label" for="flexCheckDefault5">
                  I am aware and agree that my sales targets for each month is
                  subject to change every month and that I shall be notified
                  regarding the following month’s targets on the 1 st working
                  day of the current month.{" "}
                </label>
              </div>

              <div class="form-check pt-3">
                <input
                  class="form-check-input affcheckbox"
                  type="checkbox"
                  id="flexCheckDefault8"
                  defaultChecked={TermsAndCondition.Terms24}
                  onClick={(e) => {
                    setTermsAndCondition({
                      ...TermsAndCondition,
                      Terms24: !TermsAndCondition.Terms24,
                    });
                  }}
                />
                <label class="form-check-label" for="flexCheckDefault8">
                  {/* I am aware and agree that I have to achieve a fixed minimum
                  target of 125 to 250 * subscriptions of PRIME league every
                  month. */}
                  I am aware and agree that I SHALL achieve both daily and
                  monthly sales targets set by{" "}
                  <span
                    className="vipar"
                    style={{
                      color: "green",
                      fontSize: "20px",
                      paddingRight: "5px",
                    }}
                  >
                    Vipar
                  </span>{" "}
                  <sup class="">®</sup> .
                </label>
              </div>
              <div class="form-check pt-3">
                <input
                  class="form-check-input affcheckbox"
                  type="checkbox"
                  id="flexCheckDefault8"
                  defaultChecked={TermsAndCondition.Terms25}
                  onClick={(e) => {
                    setTermsAndCondition({
                      ...TermsAndCondition,
                      Terms25: !TermsAndCondition.Terms25,
                    });
                  }}
                />
                <label class="form-check-label" for="flexCheckDefault8">
                  {/* I am aware and agree that I have to achieve a fixed minimum
                  target of 125 to 250 * subscriptions of PRIME league every
                  month. */}
                  I am aware and agree that the categories / sub categories and
                  daily / monthly sales targets assigned to me is at the sole
                  discretion of{" "}
                  <span
                    className="vipar"
                    style={{
                      color: "green",
                      fontSize: "20px",
                      paddingRight: "5px",
                    }}
                  >
                    Vipar
                  </span>{" "}
                  <sup class="">®</sup> based on my marketing capabilities /
                  performance and my chosen location for marketing.
                </label>
              </div>
              <div class="form-check pt-3">
                <input
                  class="form-check-input affcheckbox"
                  type="checkbox"
                  id="flexCheckDefault18"
                  defaultChecked={TermsAndCondition.Terms18}
                  onClick={(e) => {
                    setTermsAndCondition({
                      ...TermsAndCondition,
                      Terms18: !TermsAndCondition.Terms18,
                    });
                  }}
                />
                <label class="form-check-label" for="flexCheckDefault6">
                  I am aware and agree that it is ONLY via my Unique Referral
                  Code (which I have created on this registration form) that{" "}
                  <span
                    className="vipar"
                    style={{
                      color: "green",
                      fontSize: "20px",
                      paddingRight: "5px",
                    }}
                  >
                    Vipar
                  </span>{" "}
                  <sup class="">®</sup> I am aware and agree that the categories
                  / sub categories and daily / monthly sales targets assigned to
                  me is at the sole discretion of
                </label>
              </div>
              <div class="form-check pt-3">
                <input
                  class="form-check-input affcheckbox"
                  type="checkbox"
                  id="flexCheckDefault6"
                  defaultChecked={TermsAndCondition.Terms6}
                  onClick={(e) => {
                    setTermsAndCondition({
                      ...TermsAndCondition,
                      Terms6: !TermsAndCondition.Terms6,
                    });
                  }}
                />
                <label class="form-check-label" for="flexCheckDefault6">
                  I am aware and agree that the categories / sub categories and
                  daily / monthly sales targets assigned to me is at the sole
                  discretion of{" "}
                  <span
                    className="vipar"
                    style={{
                      color: "green",
                      fontSize: "20px",
                      paddingRight: "5px",
                    }}
                  >
                    Vipar
                  </span>{" "}
                  <sup class="">®</sup> and that I will be paid only based on my
                  sales performance every month, and that my remuneration is
                  neither guaranteed nor a fixed amount.
                </label>
              </div>
              <div className="pt-4">FINANCIAL PROTOCOLS :</div>
              <div class="form-check pt-3">
                <input
                  class="form-check-input affcheckbox"
                  type="checkbox"
                  id="flexCheckDefault2"
                  defaultChecked={TermsAndCondition.Terms2}
                  onClick={(e) => {
                    setTermsAndCondition({
                      ...TermsAndCondition,
                      Terms2: !TermsAndCondition.Terms2,
                    });
                  }}
                />
                <label class="form-check-label" for="flexCheckDefault2">
                  I am aware and agree that I SHALL NOT collect money for
                  subscriptions from any individual, who wishes to create a
                  business listing / profile of theirs on the platform, either
                  in the form of cash / cheque / DD / net transfer such as IMPS,
                  NEFT, RTGS / QR code scanning or UPI payment method to any
                  external account including my own.
                </label>
              </div>

              <div class="form-check pt-3">
                <input
                  class="form-check-input affcheckbox"
                  type="checkbox"
                  id="flexCheckDefault8"
                  defaultChecked={TermsAndCondition.Terms8}
                  onClick={(e) => {
                    setTermsAndCondition({
                      ...TermsAndCondition,
                      Terms8: !TermsAndCondition.Terms8,
                    });
                  }}
                />
                <label class="form-check-label" for="flexCheckDefault8">
                  I am aware and agree that the subscription fees SHALL be
                  collected ONLY via the UPI payment method on the{" "}
                  <span className="prochure">PROchure</span> platform to the{" "}
                  <span
                    className="vipar"
                    style={{
                      color: "green",
                      fontSize: "20px",
                      paddingRight: "5px",
                    }}
                  >
                    Vipar
                  </span>{" "}
                  <sup class="">®</sup> account and NOT to any of my personal
                  accounts or to any other account of person(s) / comapny known
                  to me.
                </label>
              </div>
              <div className="pt-4">DE-REGISTRATION :</div>
              <div class="form-check pt-3">
                <input
                  class="form-check-input affcheckbox"
                  type="checkbox"
                  id="flexCheckDefault6"
                  defaultChecked={TermsAndCondition.Terms10}
                  onClick={(e) => {
                    setTermsAndCondition({
                      ...TermsAndCondition,
                      Terms10: !TermsAndCondition.Terms10,
                    });
                  }}
                />
                <label class="form-check-label" for="flexCheckDefault6">
                  I am aware and agree that my account will be de-registered
                  without any intimation or notification IF the organisation
                  detects any suspicious activity or unethical practice during
                  my course of marketing of the App.{" "}
                </label>
              </div>
              <div className="pt-4">LEGAL BINDING :</div>
              <div class="form-check pt-3">
                <input
                  class="form-check-input affcheckbox"
                  type="checkbox"
                  id="flexCheckDefault15"
                  defaultChecked={TermsAndCondition.Terms27}
                  onClick={(e) => {
                    setTermsAndCondition({
                      ...TermsAndCondition,
                      Terms27: !TermsAndCondition.Terms27,
                    });
                  }}
                />
                <label class="form-check-label" for="flexCheckDefault15">
                  I am aware and agree that any lapse(s) occuring from my end
                  regarding ALL or ANY of the above mentioned mutually agreed
                  upon Terms and Conditions SHALL solely be my responsibility
                  and for which{" "}
                  <span
                    className="vipar"
                    style={{
                      color: "green",
                      fontSize: "20px",
                      paddingRight: "5px",
                    }}
                  >
                    Vipar
                  </span>{" "}
                  <sup class="">®</sup> SHALL NOT be held responsible or liable,
                  both legally and otherwise.
                </label>
              </div>

              <div class="form-check pt-3">
                <input
                  class="form-check-input affcheckbox"
                  type="checkbox"
                  id="flexCheckDefault6"
                  defaultChecked={TermsAndCondition.Terms12}
                  onClick={(e) => {
                    setTermsAndCondition({
                      ...TermsAndCondition,
                      Terms12: !TermsAndCondition.Terms12,
                    });
                  }}
                />
                <label class="form-check-label" for="flexCheckDefault6">
                  I am aware and agree that these Terms and the relationship
                  between myself and{" "}
                  <span
                    className="vipar"
                    style={{
                      color: "green",
                      fontSize: "20px",
                      paddingRight: "5px",
                    }}
                  >
                    Vipar
                  </span>{" "}
                  <sup class="">®</sup> shall be governed by the laws of
                  Karnataka, India without regard to its conflict of law
                  provisions.{" "}
                  <span
                    className="vipar"
                    style={{
                      color: "green",
                      fontSize: "20px",
                      paddingRight: "5px",
                    }}
                  >
                    Vipar
                  </span>{" "}
                  <sup class="">®</sup> and I agree that all disputes shall be
                  subject to arbitration at Mangaluru, Karnataka in accordance
                  with the Arbitration and Conciliation Act, 1996. The
                  arbitration proceedings shall be conducted in the English
                  language. That{" "}
                  <span
                    className="vipar"
                    style={{
                      color: "green",
                      fontSize: "20px",
                      paddingRight: "5px",
                    }}
                  >
                    Vipar
                  </span>{" "}
                  <sup class="">®</sup> and I hereby expressly and irrevocably
                  attorn to the jurisdiction of the courts of Mangaluru with
                  respect to any matter or claim, suit, action or proceeding
                  arising under or related to this Agreement. I covenant not to
                  sue{" "}
                  <span
                    className="vipar"
                    style={{
                      color: "green",
                      fontSize: "20px",
                      paddingRight: "5px",
                    }}
                  >
                    Vipar
                  </span>{" "}
                  <sup class="">®</sup> in any other forum.
                </label>
              </div>
            </>
          ) : (
            ""
          )}
          <hr />
          {/*  */}

          <div className=" pt-3">
            <div className="row">
              <h5 className="mt-4 d-block d-md-none">PERSONAL DETAILS *</h5>

              <div className="col-12  col-md-3 mnmnmnm mmmmm ">
                <Imageupload onChange={(e) => setimg(e)} id={value.id} />
                <span className="mt-3 " style={{ paddingLeft: "15px" }}>
                  Upload your Photo *
                </span>
              </div>

              <div className="col-12 col-md-9">
                <h5 className="mt-4 d-none d-md-block ">PERSONAL DETAILS *</h5>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <label className="labels">Full Name *</label>
                    <input
                      placeholder="Enter Full Name"
                      className="form-control"
                      disabled={Edit?.length > 0 ? true : false}
                      required
                      type={"text"}
                      value={value.Name}
                      onChange={(e) => {
                        setValue({ ...value, Name: e.target.value });
                      }}
                    ></input>
                    <div class="invalid-feedback">PLEASE ENTER Full Name</div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Gender *</label>
                    <select
                      class="form-control form-select"
                      id="exampleFormControlSelect1"
                      required
                      disabled={Edit?.length > 0 ? true : false}
                      value={value.Gender}
                      onChange={(e) => {
                        setValue({ ...value, Gender: e.target.value });
                      }}
                    >
                      <option></option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Transgender">Transgender</option>
                    </select>
                    <div class="invalid-feedback">PLEASE SELECT Gender</div>
                  </div>
                  <div className="col-md-12 ">
                    <label className="labels">Age *</label>
                    <div>
                      <div className="age w-100">
                        <input
                          placeholder="Enter Age"
                          disabled={Edit?.length > 0 ? true : false}
                          className="form-control  "
                          type={"number"}
                          max={2}
                          required
                          value={value.Age}
                          onChange={(e) => {
                            if (
                              e.target.value.length <= 2 &&
                              /^\d*$/.test(e.target.value)
                            )
                              setValue({ ...value, Age: e.target.value });
                          }}
                        ></input>
                      </div>
                    </div>
                    <div class="invalid-feedback">PLEASE ENTER Age</div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Nationality *</label>
                    <select
                      class="form-control form-select"
                      required
                      id="exampleFormControlSelect1"
                      disabled={Edit?.length > 0 ? true : false}
                      value={value.Nationality}
                      onChange={(e) => {
                        setValue({ ...value, Nationality: e.target.value });
                      }}
                    >
                      <option></option>
                      <option value="Indian">Indian</option>
                      <option value="American">American</option>
                      <option value="British">British</option>
                      <option value="German">German</option>
                      <option value="Italian">Italian</option>
                    </select>
                    <div class="invalid-feedback">
                      PLEASE SELECT Nationality
                    </div>
                  </div>

                  <StateCity setValue={setValue} value={value} Edit={Edit} />
                  {/* [{country:value.country,state:value.state,city:value.city}] */}
                  <div className="col-md-12 mt-2 ">
                    <label className="labels">Address *</label>

                    <textarea
                      placeholder="Enter  Full  Address"
                      className="form-control  "
                      required
                      value={value.Address}
                      onChange={(e) => {
                        setValue({ ...value, Address: e.target.value });
                      }}
                    ></textarea>

                    <div class="invalid-feedback">PLEASE ENTER Address</div>
                  </div>
                  <div className="col-md-12 mt-2">
                    <label className="labels">Pin Code *</label>

                    <input
                      placeholder="Enter Pin Code"
                      className="form-control  "
                      type={"text"}
                      maxLength={6}
                      required
                      value={value.Pincode}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value))
                          setValue({ ...value, Pincode: e.target.value });
                      }}
                    ></input>

                    <div class="invalid-feedback">PLEASE ENTER Pin Code</div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Email Id *</label>
                    <input
                      placeholder="Enter an existing Email Id  "
                      disabled={Edit?.length > 0 ? true : false}
                      className="form-control "
                      type={"email"}
                      required
                      value={value.Email}
                      onChange={(e) => {
                        setValue({ ...value, Email: e.target.value });
                      }}
                    ></input>
                    <div class="invalid-feedback">PLEASE ENTER Email Id</div>
                  </div>

                  <div className="col-md-12">
                    <label className="labels">Contact Number *</label>
                    <input
                      placeholder="Enter Anytime Available Contact Number  "
                      required
                      className="form-control "
                      maxLength={10}
                      type={"text"}
                      value={value.Contact}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value))
                          setValue({ ...value, Contact: e.target.value });
                      }}
                    ></input>
                    <div class="invalid-feedback">PLEASE ENTER Contact</div>
                  </div>
                  <div className="col-md-12 mt-2">
                    <div className="d-flex justify-content-between">
                      <div className="labels d-flex align-items-center ">
                        Unique Referral Code
                        *&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <IoInformationCircleOutline
                          size={15}
                          onClick={handleShowreferral}
                        />
                      </div>
                    </div>
                    <div className="labels">
                      ( Click on the Information icon to clearly understand what
                      Referral Code is. )
                    </div>
                    <input
                      placeholder="Create Unique Referral Code of your wish "
                      disabled={Edit?.length > 0 ? true : false}
                      className="form-control"
                      required
                      type={"text"}
                      value={value.Referral_Code}
                      onChange={(e) => {
                        setValue({ ...value, Referral_Code: e.target.value });
                      }}
                      minLength={5}
                      maxLength={10}
                      onBlur={(e) => {
                        if (e.target.value.length > 3)
                          checkReferral_Code(e.target.value);
                      }}
                    ></input>
                    <div class="invalid-feedback">
                      PLEASE ENTER Referral Code ( minimum of 5 characters )
                    </div>
                  </div>
                </div>
                <h5 className="mt-4">EDUCATIONAL DETAILS *</h5>
                <div className="row mt-2">
                  <div className="col-md-12">
                    <label className="labels">Pursuing Education *</label>

                    <textarea
                      className="form-control  "
                      required
                      value={value.Highest_Educational_Qualification}
                      onChange={(e) => {
                        setValue({
                          ...value,
                          Highest_Educational_Qualification: e.target.value,
                        });
                      }}
                    ></textarea>

                    <div class="invalid-feedback">
                      PLEASE ENTER Educational Qualification
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">College *</label>

                    <textarea
                      placeholder="Type NIL if Fresher"
                      className="form-control  "
                      required
                      value={value.Work_Experience}
                      onChange={(e) => {
                        setValue({ ...value, Work_Experience: e.target.value });
                      }}
                    ></textarea>

                    <div class="invalid-feedback">PLEASE ENTER COLLEGE</div>
                  </div>

                  <div className="col-md-12">
                    <label className="labels">University *</label>

                    <textarea
                      placeholder="Type NIL if Fresher"
                      className="form-control  "
                      required
                      value={value.Work_Experience}
                      onChange={(e) => {
                        setValue({ ...value, Work_Experience: e.target.value });
                      }}
                    ></textarea>

                    <div class="invalid-feedback">PLEASE ENTER University</div>
                  </div>

                  <div className="col-md-12">
                    <label className="labels">city *</label>

                    <input
                      placeholder="Type NIL if Fresher"
                      className="form-control  "
                      required
                      value={value.Work_Experience}
                      onChange={(e) => {
                        setValue({ ...value, City: e.target.value });
                      }}
                    />

                    <div class="invalid-feedback">PLEASE ENTER University</div>
                  </div>
                </div>

                <h5 className="mt-4">BANK ACCOUNT DETAILS *</h5>

                <div className="row mt-2">
                  <div className="col-md-12">
                    <label className="labels">Account Holder Name *</label>
                    <input
                      type="text"
                      placeholder="Enter Full Name as per bank account "
                      required
                      className="form-control"
                      maxLength="150"
                      value={value.Account_holder_name}
                      onChange={(e) =>
                        setValue({
                          ...value,
                          Account_holder_name: e.target.value,
                        })
                      }
                    />
                    <div class="invalid-feedback">
                      PLEASE ENTER Bank account holder name
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Bank Name *</label>
                    <input
                      type="text"
                      placeholder="Enter Bank Name "
                      required
                      className="form-control"
                      maxLength="40"
                      value={value.Bank_name}
                      onChange={(e) =>
                        setValue({ ...value, Bank_name: e.target.value })
                      }
                    />
                    <div class="invalid-feedback">PLEASE ENTER Bank name</div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Bank Account Type *</label>

                    <select
                      class="form-control form-select"
                      required
                      id="exampleFormControlSelect1"
                      value={value.Bank_account_type}
                      onChange={(e) =>
                        setValue({
                          ...value,
                          Bank_account_type: e.target.value,
                        })
                      }
                    >
                      <option></option>
                      <option value="SB">SB</option>
                      <option value="Current">Current</option>
                    </select>
                    <div class="invalid-feedback">
                      PLEASE SELECT Bank account type
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Account Number *</label>
                    <input
                      type="number"
                      placeholder="Enter Account Number "
                      required
                      className="form-control"
                      maxLength="100"
                      value={value.Account_number}
                      onChange={(e) =>
                        setValue({ ...value, Account_number: e.target.value })
                      }
                    />
                    <div class="invalid-feedback">
                      PLEASE ENTER Account number
                    </div>
                  </div>

                  <div className="col-md-12">
                    <label className="labels">IFSC code *</label>
                    <input
                      type="text"
                      required
                      placeholder="Enter IFSC code "
                      className="form-control"
                      maxLength="40"
                      value={value.Ifsc_code}
                      onChange={(e) =>
                        setValue({ ...value, Ifsc_code: e.target.value })
                      }
                    />
                    <div class="invalid-feedback">PLEASE ENTER IFSC code</div>
                  </div>

                  {!Edit?.length > 0 ? (
                    <>
                      <div>
                        <div class="form-check pt-3">
                          <input
                            class="form-check-input affcheckbox"
                            type="checkbox"
                            id="flexCheckDefault7"
                            defaultChecked={TermsAndCondition.Terms7}
                            onClick={(e) => {
                              setTermsAndCondition({
                                ...TermsAndCondition,
                                Terms7: !TermsAndCondition.Terms7,
                              });
                            }}
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault7"
                          >
                            I am aware and agree that the bank account details I
                            have mentioned is correct and wish to have my
                            stipend / remuneration credited to this account.
                          </label>
                        </div>
                      </div>
                      <div>
                        <div class="form-check pt-3">
                          <input
                            class="form-check-input affcheckbox"
                            type="checkbox"
                            id="flexCheckDefault9"
                            defaultChecked={TermsAndCondition.Terms9}
                            onClick={(e) => {
                              setTermsAndCondition({
                                ...TermsAndCondition,
                                Terms9: !TermsAndCondition.Terms9,
                              });
                            }}
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault9"
                          >
                            I am and agree that any mistake / error in the bank
                            account details entered is not the responsibility of
                            the platform.
                          </label>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <h5 className="mt-4">LOGIN CREDENTIALS *</h5>
                  <div className="col-md-12 mt-2">
                    <label className="labels">
                      Login Id <br></br>( Your Email Id is your Default
                      Login Id. )
                    </label>
                    <input
                      disabled
                      className="form-control"
                      type={"text"}
                      value={value.Email}
                    ></input>
                  </div>

                  {!Edit?.length > 0 ? (
                    <>
                      <div className="col-md-12">
                        <label className="labels">Password *</label>
                        <div>
                          <input
                            class="form-control passInout12321"
                            placeholder="Enter Password"
                            aria-describedby="basic-addon2"
                            type={showPassword ? "text" : "password"}
                            value={value.Password}
                            onChange={(e) =>
                              setValue({ ...value, Password: e.target.value })
                            }
                            required
                          />
                          &nbsp;
                          <span class="showbutton123321">
                            {showPassword ? (
                              <RiEyeFill
                                onClick={(e) => {
                                  setShowPassword(!showPassword);
                                }}
                              />
                            ) : (
                              <RiEyeOffFill
                                onClick={(e) => {
                                  setShowPassword(!showPassword);
                                }}
                              />
                            )}
                          </span>
                          <div class="invalid-feedback ">
                            PLEASE ENTER Password
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <label className="labels">Confirm Password *</label>
                        <div>
                          <div>
                            <input
                              class="form-control passInout12321"
                              placeholder="Enter Password again"
                              type={showPassword1 ? "text" : "password"}
                              value={value.ConformPassword}
                              onChange={(e) =>
                                setValue({
                                  ...value,
                                  ConformPassword: e.target.value,
                                })
                              }
                              required
                            />

                            <span class="showbutton123321" type="button">
                              {" "}
                              {showPassword1 ? (
                                <RiEyeFill
                                  onClick={(e) => {
                                    setShowPassword1(!showPassword1);
                                  }}
                                />
                              ) : (
                                <RiEyeOffFill
                                  onClick={(e) => {
                                    setShowPassword1(!showPassword1);
                                  }}
                                />
                              )}
                            </span>
                          </div>
                          <div class="invalid-feedback ">
                            PLEASE ENTER Confirm Password
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {!Edit?.length > 0 ? (
                    <>
                      <div>
                        <h5 className="mt-4">TO BE NOTED :</h5>

                        <div className="mt-4">
                          <span className="boldfont"></span>
                        </div>
                        <ul className="ps-5 mt-2">
                          <li className="pt-2">
                            {" "}
                            Once you are registered as a Marketing Intern, your
                            APPOINTMENT LETTER will be officially sent to you
                            only from vipar.official@gmail.com within 48 hours
                            after your registration, to your email id mentioned
                            on the form.
                          </li>
                          <li className="pt-2">
                            {" "}
                            You can also View your Appointment Letter and the
                            Accepted Terms and Conditions from your account.
                          </li>
                          <li className="pt-2">
                            {" "}
                            Your INTERNSHIP COMPLETION CERTIFICATE will be
                            issued to you upon completion of your Internship
                            program.
                          </li>

                          <li className="pt-2">
                            {" "}
                            For any grievances, send us an email regarding the
                            issue to vipar.official@gmail.com from your
                            registered email id with Subject Title as ‘PROchure
                            Marketing Intern GRIEVANCE’ and clearly mentioning
                            your Unique Referral Code.
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {error.length ? (
                    <div className="col-12 ">
                      <p className="py-1 m-0" style={{ color: "red" }}>
                        {error ? error : ""}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="col-12 px-1 py-3 d-flex justify-content-center ">
                    {ButtonDisabled ? (
                      <button className="btn pro-spaces-button3" disabled>
                        {Edit?.length > 0 ? "UPDATE" : "REGISTER"}
                      </button>
                    ) : (
                      !loading && (
                        <button
                          className="btn pro-spaces-button3"
                          onClick={(e) => validator1(e)}
                        >
                          {Edit?.length > 0 ? "UPDATE" : "REGISTER"}
                        </button>
                      )
                    )}
                    {loading && (
                      <button className="btn pro-spaces-button3" disabled>
                        {Edit?.length > 0 ? "UPDATE" : "REGISTER"}
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    )}
                  </div>

                  <div className="pt-4">
                    <span className="boldfont"></span>
                  </div>
                  <ul className="ps-5 mt-2">
                    <li className="pt-2">
                      {" "}
                      Create a Referral Code of your choice which will be your
                      Employee Identification at{" "}
                      <span
                        className="vipar"
                        style={{
                          fontSize: "22px",
                          color: "#99d050",
                        }}
                      >
                        {" "}
                        Vipar
                      </span>{" "}&nbsp;
                      <sup class="">®</sup>.
                    </li>
                    <li className="pt-2">
                      {" "}
                      Your Unique Referral Code is the one with which{" "}
                      <span
                        className="vipar"
                        style={{
                          fontSize: "22px",
                          color: "#99d050",
                        }}
                      >
                        {" "}
                        Vipar
                      </span>{" "}&nbsp;
                      <sup class="">®</sup> can identify that it is via your
                      account that a Subscription is sold.
                    </li>
                    <li className="pt-2">
                      {" "}
                      It is Mandatory for tracking your sales targets and
                      disbursing your stipend / remuneration (if any) to you.
                    </li>

                    <li className="pt-2">
                      {" "}
                      Once a Referral Code is created, it is permanent and
                      cannot be changed.
                    </li>
                    <li className="pt-2">
                      {" "}
                      Can be a combination of Alphabets, Numbers and Symbols.
                    </li>
                    <li className="pt-2">
                      {" "}
                      Minimum Character length : 5 <br/>Maximum Character length :
                      10
                    </li>
                    <li className="pt-2">
                      {" "}
                      In case you forget your Referral Code, it will be visible to you on the ‘Account Details’ page of your profile.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          /> */}
        </form>
      </div>
      <div>
        <Footer />
      </div>

      <Modal show={show11} onHide={handleClose11} centered>
        <Modal.Header closeButton>
          <Modal.Title>Enter OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row mt-2">
              <div className="col-12">
                <label className="labels">
                  OTP (sent to registered email address)
                </label>
                <input
                  type="text"
                  placeholder="Enter OTP "
                  required
                  className="form-control"
                  maxLength="5"
                  value={OTP}
                  onChange={(e) => setOTP(e.target.value)}
                />
              </div>
            </div>

            <div className="pt-4 ">
              {pop ? (
                <div className="mb-3 text-center">
                  <Timer minutes={2} seconds={59} callback={end} />
                </div>
              ) : (
                ""
              )}

              {pop ? (
                <>
                  <button className="btn pro-spaces-outline my-1 " disabled>
                    Resend OTP ?
                  </button>
                  <br />
                </>
              ) : (
                <>
                  <a
                    id="resendOTP"
                    onClick={(e) => GenerateOTP(e)}
                    className="btn pro-spaces-outline my-1 "
                  >
                    Resend OTP ?{" "}
                    {loading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </a>
                  <br />
                </>
              )}
            </div>
            <div>
              <p style={{ color: "red" }}>{Error2 ? Error2 : ""}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn pro-spaces-button3"
            onClick={(e) => {
              Update();
            }}
          >
            Verify
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showpassword} onHide={handleClose111} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <label className="labels">Old password *</label>
                <div>
                  <input
                    class="form-control passInout12321"
                    placeholder="Enter old Password"
                    aria-describedby="basic-addon2"
                    type={showPassword12 ? "text" : "password"}
                    value={NewPassword.oldPassword}
                    onChange={(e) =>
                      setNewPassword({
                        ...NewPassword,
                        oldPassword: e.target.value,
                      })
                    }
                  />
                  &nbsp;
                  <span class="showbutton123321">
                    {showPassword12 ? (
                      <RiEyeFill
                        onClick={(e) => {
                          setShowPassword12(!showPassword12);
                        }}
                      />
                    ) : (
                      <RiEyeOffFill
                        onClick={(e) => {
                          setShowPassword12(!showPassword12);
                        }}
                      />
                    )}
                  </span>
                </div>
              </div>

              <div className="col-md-12">
                <label className="labels">New password *</label>
                <div>
                  <div>
                    <input
                      class="form-control passInout12321"
                      placeholder="Enter new Password"
                      type={showPassword123 ? "text" : "password"}
                      value={NewPassword.newPassword}
                      onChange={(e) =>
                        setNewPassword({
                          ...NewPassword,
                          newPassword: e.target.value,
                        })
                      }
                    />

                    <span class="showbutton123321" type="button">
                      {" "}
                      {showPassword123 ? (
                        <RiEyeFill
                          onClick={(e) => {
                            setShowPassword123(!showPassword123);
                          }}
                        />
                      ) : (
                        <RiEyeOffFill
                          onClick={(e) => {
                            setShowPassword123(!showPassword123);
                          }}
                        />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p style={{ color: "red" }}>{}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn pro-spaces-button3"
            onClick={(e) => {
              UpdatePassword();
            }}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>

      {/* referralcode modal */}

      <Modal show={showreferral} onHide={handleClosereferral}>
        <Modal.Header centered closeButton></Modal.Header>
        <Modal.Body>
          <div className="footlightfont">
            <div className="pt-3" style={{ lineSpacing: "0" }}>
              • Create a Referral Code of your choice which will be your
              Employee Identification at{" "}
              <span
                className="vipar"
                style={{
                  fontSize: "22px",
                  color: "#99d050",
                }}
              >
                {" "}
                Vipar
              </span>{" "}
              <sup class="">®</sup>.
            </div>
            <div className="pt-3" style={{ lineSpacing: "0" }}>
              • Your Unique Referral Code is the one with which
              <span
                className="vipar"
                style={{
                  fontSize: "22px",
                  color: "#99d050",
                }}
              >
                {" "}
                Vipar
              </span>{" "}
              <sup class="">®</sup> can identify that it is via your SME account
              that a Subscription is sold.
            </div>
            <div className="pt-3">
              • It is Mandatory for tracking your sales targets and disbursing
              your salary to you.
            </div>
            <div className="pt-3">
              • Once a Referral Code is created, it is permanent and cannot be
              changed.
            </div>

            <div className="pt-3">
              • Can be a combination of Alphabets, Numbers and Symbols.
            </div>
            <div className="pt-3">
              • Minimum Character length &#8239;: 5<br />
              &nbsp;&nbsp;Maximum Character length : 10
            </div>
            <div className="pt-3">
              • In case you forget your Referral Code, it will be visible to you
              on the ‘Account Details’ page of your profile.
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* referralcode modal */}
    </>
  );
};
