import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { MdUpload } from "react-icons/md";
import { GrPowerReset } from "react-icons/gr";
import { bucketName } from '../../config'
import axios from 'axios';
const LogoFileUpload = ({ onChange, Edit, id }) => {
  console.log(Edit)
  const fileInputRef = useRef();
  const cropperRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [previewSrc, setPreviewSrc] = useState(null);
  const [cropper, setCropper] = useState();

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setImageSrc(objectUrl);
      // setPreviewSrc(objectUrl);
      setShowModal(true);
    }
  };

  const handleCrop = () => {
    try {
      console.log(cropper.getCroppedCanvas())
      const croppedImage = cropper.getCroppedCanvas().toDataURL();
      cropper.getCroppedCanvas().toBlob((blob) => {
        if (typeof (onChange) == 'function') {
          onChange(blob)
        }
      }, 'image/jpeg', 0.5)
      setPreviewSrc(croppedImage);
      setShowModal(false);

      // Handle the cropped image as needed, for example, display it in a preview
    } catch (error) {
      console.log(error)
    }

  };

  const handleReset = () => {
    setImageSrc(null);
    setPreviewSrc(null);
    setShowModal(false);
    if (typeof (onChange) == 'function') {
      onChange(null)
    }
  };

  const openFileInput = () => {
    fileInputRef.current.click();
  };
  useEffect(() => {
    if (Edit) {
      setPreviewSrc(bucketName + `/${id}/company_logo.png`)
    } else {

    }
    return () => {

    }
  }, [Edit])
  const deleteThis = () => {
    axios.delete('/api/User/deleteLogo').then((res) => {
      console.log(res)
    }).catch((err) => {
      console.log(err)
    })
  }

  return (
    <div>
      <label>Upload Logo</label>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        ref={fileInputRef}
      />
      <div style={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
      }} >


        {/* <button onClick={deleteThis} type="button" >delkete</button> */}
        {previewSrc ? (
          <img src={previewSrc} onError={({ currentTarget }) => {
            setPreviewSrc(null)
          }} alt="Preview" style={{ width: '100%', height: '100px', maxWidth: '100px', marginTop: '10px' }} />
        ) : (
          <div
            style={{
              width: '100%',
              maxWidth: '100px',
              height: '100px', // Automatically adjust the height to maintain aspect ratio
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #ddd', // Add styling for the rectangle
              borderRadius: '5px', // Optional: Add border-radius for a rounded rectangle
            }}
          >
            <span style={{ fontSize: '14px', color: '#555' }}>Logo</span>
          </div>
        )}

        <div style={{
          display: "flex",
          "justify-content": "space-around"
        }}>
          <button variant="primary" type='button' className='pro-spaces-button btn px-2 mx-2 ' style={{ 'fontSize': 'x-small' }} onClick={openFileInput}>
            {previewSrc ? 'Change' : 'upload'}
          </button>
          <button variant="danger" type='button' className='pro-spaces-button3 btn px-2' style={{ 'fontSize': 'x-small' }} onClick={handleReset}>
            Remove
          </button>
        </div>
      </div>

      {imageSrc && (
        <Modal backdrop={true} show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Crop Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Cropper
              ref={cropperRef}
              src={imageSrc}
              style={{ height: 400, width: '100%' }}
              aspectRatio={1 / 1}
              background={false}
              guides={true}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleCrop}>
              Crop
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default LogoFileUpload;
