import React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

export const MemberviewZM = () => {
  const columns = [
    { field: 'id', headerName: 'Sl No', width: 90 },
    {
      field: 'Name',
      headerName: 'Name',
      width: 150,
    },
    {
      field: 'Role',
      headerName: 'Role',
      width: 150,
    },
    {
      field: 'Country',
      headerName: 'Country',
      type: 'number',
      width: 110,
    },
    {
      field: 'State',
      headerName: 'State',

      width: 110,
      editable: true,
    },
    {
      field: 'City',
      headerName: 'City',

      width: 110,
    },
  ];

  const rows = [
    {
      id: 1,
      Name: 'Snow',
      Role: 'Jon',
      Country: 35,
      State: 'kolkata',
      City: 'ff',
    },
  ];
  return (
    <div>
      <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        />
      </Box>
    </div>
  );
};
