import React, { useState, useEffect } from 'react'

const MobileAddOn = (props) => {
    
    console.log(props);
    const [value, setValue] = useState({
        contact2: props.con ? props.con[props.index] ? props.con[props.index].contact2 ? props.con[props.index].contact2 : '' : '' : '',
        contactpersonsname2: props.con ? props.con[props.index] ? props.con[props.index].contactpersonsname2 ? props.con[props.index].contactpersonsname2 : '' : '' : '',
        contactpersonsdesignation2: props.con ? props.con[props.index] ? props.con[props.index].contactpersonsdesignation2 ? props.con[props.index].contactpersonsdesignation2 : '' : '' : '',
        preferredlanguage2: props.con ? props.con[props.index] ? props.con[props.index].preferredlanguage2 ? props.con[props.index].preferredlanguage2 : '' : '' : '',
    })
    useEffect(() => {
        props.handleCON(value, props.index)
        console.log(value);
        return () => {

        }
    }, [value])

    return (
        <fieldset>
            <div >
                {props.index != 0 && <button className='btn btn-close float-end pe-1' onClick={(e) => props.handleCON('remove', props.index)} style={{ "cursor": 'pointer' }} ></button>
                }
                <div className="col-md-12">
                    <label className="labels">Mobile Number / Landline Number </label>
                    <input type="number" className="form-control" value={value.contact2} onChange={(e) => { if (e.target.value.length == 13) return false; setValue({ ...value, contact2: e.target.value }) }} />
                </div>
                <div className="col-md-12">
                    <label className="labels">Contact Person’s Name </label>
                    <input type="text" className="form-control" value={value.contactpersonsname2} onChange={(e) => setValue({ ...value, contactpersonsname2: e.target.value })} />
                </div>
                <div className="col-md-12">
                    <label className="labels">Contact Person’s Designation </label>
                    <select
                        className="form-select"
                        value={value.contactpersonsdesignation2}
                        onChange={(e) =>
                            setValue({ ...value, contactpersonsdesignation2: e.target.value })
                        }
                    >
                        <option value="" disabled selected></option>
                        <option value="Consultant">Consultant</option>
                        <option value="Propreitor / Owner">Propreitor / Owner</option>
                        <option value="Personal Secretary / Manager">Personal Secretary / Manager</option>
                        <option value="Marketing Head">Marketing Head</option>
                        <option value="Receptionist">Receptionist</option>
                    </select>
                </div>
                <div className="col-md-12">
                    <label className="labels">Most Comfortable / Preferred Language for Communication </label>
                    <select
                        className="form-select"
                        value={value.preferredlanguage2} onChange={(e) => setValue({ ...value, preferredlanguage2: e.target.value })}
                    >
                        <option value="" disabled selected></option>
                        {props.languages.map((languages) => (
                            <option key={languages} value={languages}>{languages}</option>
                        ))}
                    </select>

                </div>
            </div>
            <hr />
        </fieldset>
    )
}

export default MobileAddOn