import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import moment from 'moment';
import { Link, useSearchParams } from 'react-router-dom';
import { notify } from '../../Utility/notify';
import { ControlBar } from './ControlBar';
import { Footer } from './Footer';
import { NavBar } from './NavBar';

export const Viewvaccanies = () => {
  const [uservalue, setUservalue] = useState({
    CurrentCTS: '',
    SalaryExpectation: '',
  });
  const [Submit2, setSubmit2] = useState(false);

  const [check, setcheck] = useState(true);
  const [check2, setcheck2] = useState(true);

  const anees = useRef();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [shows, setShows] = useState(false);

  const [index, setindex] = useState();

  const handleCloses = () => setShows(false);
  const handleShows = () => setShows(true);
  const [drop, setdrop] = useState(true);
  const [opper, setopper] = useState();

  useEffect(() => {
    axios
      .post('/vipar/reg')
      .then((res) => {
        console.log(res.data);
        setopper(res.data);
      })
      .catch((err) => {});
  }, []);
  useEffect(() => {
    console.log(opper);
  }, [opper]);

  const [verticals, setVerticals] = useState([]);
  const [novacancy, setNovacancy] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  var id = searchParams.get('id');
  useEffect(() => {
    axios
      .get(`/jobroute/jobconnectviewnew?id=${id}`)
      .then((res) => {
        console.log(res.data);
        setVerticals(res.data);
        setLoading(false);
      })
      .catch((err) => {});
  }, []);

  const [img, setimg] = useState([]);

  const [first, setfirst] = useState({});

  const submit = (e) => {
    console.log('ASdsasd');
    console.log(e);
    e.preventDefault();
    handleClose();

    var formData = new FormData();
    console.log(img);
    {
      img?.map((elem) => {
        formData.append('file', elem);
      });
    }

    var dataSend = JSON.stringify({ uservalue, first });
    formData.append('data', dataSend);

    axios
      .post('/jobroute/jobconnectuser', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        notify('Vacancy Applied Succesffuly', true);
        handleClose2();
        setimg([]);
        setSubmit2(false);
      })
      .catch((err) => {
        notify('Something went wrong', false);
        setSubmit2(false);
        console.log(err);
      });
  };
  const [jobview, setJobview] = useState({});

  useEffect(() => {
    console.log(verticals);
  }, [verticals]);

  const [Noval, setNoval] = useState('');
  // const sortedOpper = opper.sort((a, b) => a.opper.localeCompare(b.opper));

  const [loading, setLoading] = useState(true);

  const nosalpha = (index) => {
    let temp = index + 1;
    if (index < 10) temp += 10;
    if (index + 1 <= 10 || index + 1 >= 20)
      if (temp % 10 === 1) return `${index + 1}st`;
      else if (temp % 10 === 2) return `${index + 1}nd`;
      else if (temp % 10 === 3) return `${index + 1}rd`;
      else return `${index + 1}th`;
    else return `${index + 1}th`;
  };

  return (
    <>
      {loading ? (
        <div
          class="d-flex justify-content-center align-items-center "
          style={{ height: '100vh' }}
        >
          <div class="spinner-border" role="status"></div>
        </div>
      ) : (
        <>
          <ControlBar />
          <NavBar />
          <div id="fot">
            <div
              className="   w-100 mb-4"
              style={{
                display: 'block',
                backgroundColor: 'rgb(1,32,96,1)',
              }}
            >
              <div className="px-2 ">
                <div
                  className=" py-3  text-light footlightfont "
                  style={{ textTransform: 'uppercase' }}
                >
                  {verticals?.JobSection}
                </div>
                <div className="pb-3 ">
                  <span
                    className="boldfive text-light footlightfont"
                    style={{ textTransform: 'uppercase' }}
                  >
                    {verticals?.JobSubSection}
                  </span>
                </div>
              </div>
            </div>
            <div className="">
              {/* content */}
              <div className="px-2 footlightfont bg-white ">
                {verticals?.JobTitle?.length ? (
                  <>
                    <div className="py-2">
                      <div htmlhtmlFor="inputname">
                        <span className="boldfive">Job Highlights</span>
                        <div
                          className="pt-2"
                          dangerouslySetInnerHTML={{
                            __html: verticals?.JobTitle,
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {verticals?.Skills?.length ? (
                  <>
                    <div className="py-2">
                      <div htmlhtmlFor="inputname">
                        <span className="boldfive">Key Skills (Technical)</span>
                        <div
                          className="pt-2"
                          dangerouslySetInnerHTML={{
                            __html: verticals?.Skills,
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {verticals?.Vacancies ? (
                  verticals?.Vacancies[0]?.EmploymentType ||
                  verticals?.Vacancies[0]?.WorkExperience ||
                  verticals?.Vacancies[0]?.JobRole ||
                  verticals?.Vacancies[0]?.SalaryOffered ||
                  verticals?.Vacancies[0]?.WorkMode ||
                  verticals?.Vacancies[0]?.Gender ||
                  verticals?.Vacancies[0]?.JoiningDateEnd ||
                  verticals?.Vacancies[0]?.JoiningDateStart ||
                  verticals?.Vacancies[0]?.Location ||
                  verticals?.Vacancies[0]?.Number ? (
                    <>
                      <div className="py-2">
                        <label htmlhtmlFor="inputname ">
                          <span className="boldfive">Vacancies</span>
                        </label>

                        {verticals?.Vacancies?.map((el, index) => {
                          console.log(el);
                          return (
                            <>
                              {/* <div className="pt-2">
                                <span>{nosalpha(index)}&nbsp;Vacancy</span>
                              </div> */}
                              {el?.WorkExperience?.length ? (
                                <>
                                  <div className="pt-3">
                                    <div htmlhtmlFor="inputname">
                                      <span>Work Experience</span> :{' '}
                                      {el?.WorkExperience}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              {el?.EmploymentType?.length ? (
                                <>
                                  <div className="pt-3">
                                    <div htmlhtmlFor="inputname">
                                      <span>Employment Type</span> :{' '}
                                      {el?.EmploymentType}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              {el?.WorkMode?.length ? (
                                <>
                                  <div className="pt-3">
                                    <div htmlhtmlFor="inputname">
                                      <span>Work Mode</span> : {el?.WorkMode}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              {el?.Gender?.length ? (
                                <>
                                  <div className="pt-3">
                                    <div htmlhtmlFor="inputname">
                                      <span>Gender</span> : {el?.Gender}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              {el?.Number?.length ? (
                                <>
                                  <div className="pt-3">
                                    <div htmlhtmlFor="inputname">
                                      <span>Number</span> : {el?.Number}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              {el?.JobRole?.length ? (
                                <>
                                  <div className="pt-3">
                                    <div htmlhtmlFor="inputname">
                                      <span>Job Role</span> : {el?.JobRole}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              {el?.SalaryOffered?.length ? (
                                <>
                                  <div className="pt-3">
                                    <div htmlhtmlFor="inputname">
                                      <span>Salary Offered</span> :{' '}
                                      {el?.SalaryOffered}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              {el?.Location?.length ? (
                                <>
                                  <div className="pt-3">
                                    <div htmlhtmlFor="inputname">
                                      <span>Job Location</span> : {el?.Location}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              {el?.JoiningDateStart?.length ? (
                                <>
                                  <div className="pt-3">
                                    <div htmlhtmlFor="inputname">
                                      <span>When to Join ?</span> :{' '}
                                      {el?.JoiningDateStart}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              <hr />
                            </>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <></>
                  )
                ) : (
                  ''
                )}

                {verticals?.JobDescription?.length ? (
                  <>
                    <div className="py-2">
                      <div htmlhtmlFor="inputname">
                        <span className="boldfive">
                          Detailed Job Profile / Description
                        </span>
                        <div
                          className="pt-2"
                          dangerouslySetInnerHTML={{
                            __html: verticals?.JobDescription,
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {verticals?.Skills_NT?.length ? (
                  <>
                    <div className="py-2">
                      <div htmlhtmlFor="inputname">
                        <span className="boldfive">Skills (Non-Technical)</span>
                        <div
                          className="pt-2"
                          dangerouslySetInnerHTML={{
                            __html: verticals?.Skills_NT,
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {verticals?.OtherAttributes?.length ? (
                  <>
                    <div className="py-2">
                      <div htmlhtmlFor="inputname">
                        <span className="boldfive">Other Attributes</span> :{' '}
                        {verticals?.OtherAttributes}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {verticals?.Qualfication?.length ? (
                  <>
                    <div className="py-2">
                      <label htmlhtmlFor="inputname">
                        <span className="boldfive">
                          Least Educational Qualification
                        </span>{' '}
                        : {verticals?.Qualfication}
                      </label>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {verticals?.Message?.length ? (
                  <>
                    <div className="py-4">
                      <div
                        className="card"
                        style={{
                          boxShadow:
                            ' rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px',
                        }}
                      >
                        <div
                          htmlhtmlFor="inputname"
                          className="py-3 px-3"
                          style={{ whiteSpace: 'pre-line' }}
                        >
                          {/* <span className="boldfive">Message</span> :{' '} */}
                          {verticals?.Message}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {/* <div className="pb-4">
       <div>
         <button
           className="btn btn-primary mt-3 mx-2"
           style={{
             backgroundColor: 'rgb(1 32 96)',
           }}
           variant="primary"
           onClick={(e) => {
             // setSubmit2(true);
             handleShow2();
             // setindex(ind);
             handleClose();
           }}
         >
           APPLY
         </button>
       </div>
     </div> */}
              </div>
            </div>
          </div>
          <div className="">
            <Footer />
          </div>
        </>
      )}

      <Modal show={show2} onHide={handleClose2} centered>
        <Modal.Header closeButton></Modal.Header>
        <form
          id="myForm"
          onSubmit={(e) => submit(e)}
          novalidate
          className={Submit2 ? 'was-validated' : ''}
        >
          <Modal.Body>
            {verticals?.Experience != 'Fresher' ||
            verticals?.Experience != 'Intern' ? (
              <>
                <div className="footlightfont">
                  <label className="">What is your Current CTC ?</label>
                  <input
                    className="form-control pt-2"
                    type="number"
                    onChange={(e) => {
                      setUservalue({
                        ...uservalue,
                        CurrentCTS: e.target.value,
                      });
                    }}
                  />
                  <div class="invalid-feedback ">
                    Please enter your Current CTC
                  </div>
                </div>
                <div className="py-4 footlightfont">
                  <label>What is your Salary Expectation ?</label>
                  <input
                    className="form-control pt-2"
                    type="number"
                    onChange={(e) => {
                      setUservalue({
                        ...uservalue,
                        SalaryExpectation: e.target.value,
                      });
                    }}
                  />
                  <div class="invalid-feedback">
                    Please enter your Salary Expectation
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="footlightfont">
              <label>Are you ready to Relocate ?</label>

              <div className="pt-2">
                <input
                  type="checkbox"
                  defaultChecked
                  className="form-check-input "
                  id="exampleCheck1"
                  value={check2}
                  onChange={(e) => setcheck2(false)}
                />
                <label class="form-check-label ps-2" for="exampleCheck1">
                  Yes I am ready to Relocate.
                </label>
              </div>
            </div>
            <div className="pt-4 footlightfont">
              <div>
                <div class="form-check form-check-inline">
                  <input
                    required
                    class="form-check-input"
                    type="checkbox"
                    id="inlineCheckbox1"
                    value={check}
                    onChange={(e) => setcheck(false)}
                  />
                  <label class="form-check-label" for="inlineCheckbox1">
                    I have read, understood and agree with the Concept,
                    Remuneration and Employment Policies of
                    <span
                      className="vipar"
                      style={{
                        fontSize: '23px',
                        color: '#99d050',
                      }}
                    >
                      {' '}
                      Vipar
                    </span>
                    .
                  </label>
                </div>
              </div>
            </div>
            <div className="pt-3 footlightfont">
              <div>
                <input
                  required
                  hidden
                  ref={anees}
                  type="file"
                  className="btn btn-primary mt-3 mx-2"
                  style={{ backgroundColor: 'rgb(1 32 96)' }}
                  onChange={(e) => setimg([...img, ...e.target.files])}
                />
                <button
                  type="button"
                  id="anees"
                  className="btn btn-outline-primary mt-3 mx-2"
                  style={{
                    backgroundColor: 'white',
                    color: 'rgb(1 32 96)',
                    borderColor: 'rgb(1 32 96)',
                  }}
                  onClick={(e) => anees.current.click()}
                >
                  UPLOAD CV
                </button>
                <div class="invalid-feedback">PLEASE UPLOAD CV</div>
              </div>
            </div>

            <div className="py-4">
              <div>
                <button
                  className="btn btn-primary mt-3 mx-2 footlightfont"
                  style={{ backgroundColor: 'rgb(1 32 96)' }}
                  variant="primary"
                  onClick={(e) => {
                    setSubmit2(true);
                    setfirst(verticals);
                  }}
                >
                  SEND
                </button>
              </div>
            </div>
          </Modal.Body>
        </form>
      </Modal>
    </>
  );
};

{
  /* <div className="py-4  footlightfont">
                      Interested Candidates may kindly reply with the following
                      information to vipar.official@gmail.com :
                    </div>
                    <div className="pb-2 footlightfont">
                      1. Your Resumé / CV with detailed Work Experience and
                      Skills.
                      <br />
                      2. Current CTC
                      <br />
                      3. Expected Salary
                    </div> */
}
