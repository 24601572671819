import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { ControlBar } from './ControlBar';
import { NavBar } from './NavBar';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { Footer } from './Footer';
import { Vaccanies } from './Vacancies';

export const Jobs = () => {
  const [plus, setplus] = useState({
    col1: false,
    col2: false,
    col3: false,
  });
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [openmb1, setOpenmb1] = useState(false);
  const [openmb2, setOpenmb2] = useState(false);
  const [openmb3, setOpenmb3] = useState(false);

  return (
    <div style={{ backgroundColor: '#e9eef5' }}>
      <ControlBar />
      <NavBar />
      <div id="fo">
        <hr className="m-0 zxc"></hr>
        <div id="coll">
          <section
            className="pb-5 d-md-block  d-none footlightfont"
            style={{ backgroundColor: '#e9eef5', fontSize: '15px' }}
          >
            <div className="container-fluid">
              <div
                className="card ps-1 "
                style={{ backgroundColor: '#e9eef5', border: 'none' }}
              >
                <div className="card-body pb-0">
                  <div className="row mt-3">
                    <div className="col p-0">
                      <span style={{ fontSize: '18px' }} className=" text-dark">
                        <div style={{ fontSize: '18px' }} className=" ">
                          WORKING HOURS and DAYS
                        </div>
                        {/* <div className=" bnmm pt-2">“Equal Collar” Jobs</div>{' '} */}
                      </span>
                    </div>
                    <div className="col-2 p-0">
                      <h5 className="text-center">
                        <Button
                          onClick={() => setOpen(!open)}
                          aria-controls="example-collapse-text"
                          aria-expanded={open}
                          className="btn btn-outline-dark btn-light btn-md zxxxx"
                          // style={{backgroundColor:"#e9eef5"}}
                        >
                          Read&nbsp;more.....
                        </Button>
                      </h5>
                    </div>
                  </div>
                  <Collapse in={open}>
                    <div>
                      <div
                        className="card ps-0 card-body"
                        style={{ backgroundColor: '#e9eef5', border: 'none' }}
                      >
                        <div className="row">
                          <div className="col ps-3">
                            <div className="bnm " style={{ fontSize: '18px' }}>
                              WORKING HOURS{' '}
                            </div>
                            <div
                              className="bnm pt-3"
                              style={{ fontSize: '18px' }}
                            >
                              09:00 to 18:00 (9 AM to 6 PM) with a 45-minute
                              break for lunch, short nap and a 15-minute break
                              for tea.
                            </div>
                            <div
                              className="bnm pt-3"
                              style={{ fontSize: '18px' }}
                            >
                              Lunch Break : Anytime between 12:00 to 14:00 (12
                              PM to 2 PM)
                              <br />
                              Tea Break : Anytime between 16:00 to 17:00 (4 PM
                              to 5 PM)
                            </div>
                            <div
                              className="bnm pt-3"
                              style={{ fontSize: '18px' }}
                            >
                              WORKING DAYS
                            </div>
                            <div
                              className="bnm pt-3"
                              style={{ fontSize: '18px' }}
                            >
                              Monday to Friday
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>

            <div className="container-fluid  ">
              <div
                className="card ps-1 pt-1 "
                style={{ backgroundColor: '#e9eef5', border: 'none' }}
              >
                <div className="card-body pb-0">
                  <div className="row mt-3">
                    <div className="col p-0">
                      <span style={{ fontSize: '18px' }} className=" text-dark">
                        <div className="nmb" style={{ fontSize: '18px' }}>
                          EMPLOYMENT POLICIES
                        </div>
                        {/* <div className="bnmm pt-2">
                          Only on the basis of Working Hours
                        </div>{' '} */}
                      </span>
                    </div>
                    <div className="col-2 p-0">
                      <h5 className="text-center">
                        <Button
                          onClick={() => setOpen1(!open1)}
                          aria-controls="example-collapse-text"
                          aria-expanded={open}
                          className="btn btn-outline-dark btn-light btn-md zxxxx"
                          // style={{backgroundColor:"#e9eef5"}}
                        >
                          Read&nbsp;more.....
                        </Button>
                      </h5>
                    </div>
                  </div>
                  <Collapse in={open1}>
                    <div
                      className="collapse"
                      data-bs-parent="#coll"
                      id="collapse12"
                    >
                      <div
                        className="card ps-0 card-body"
                        style={{ backgroundColor: '#e9eef5', border: 'none' }}
                      >
                        <div className="row">
                          <div className="col ps-3">
                            <span
                              className=" text-dark"
                              style={{ fontSize: '18px' }}
                            >
                              <div>For Freshers (Inexperienced) :</div>

                              <div className="pt-2">
                                <ul className="pt-4">
                                  <li>
                                    The first week will be an ‘Unpaid Training
                                    Period’ by the end of which
                                    <span
                                      className="vipar"
                                      style={{
                                        fontSize: '22px',
                                        color: '#99d050',
                                      }}
                                    >
                                      {' '}
                                      Vipar&nbsp;
                                    </span>{' '}
                                    <sup class="">®</sup> has the discretion to
                                    decide whether or not to employ the person.
                                  </li>
                                  <li className="pt-3">
                                    Post the first week, the employee will be on
                                    a probation period of one year.
                                  </li>
                                  <li className="pt-3">
                                    Must sign a work contract for a minimum
                                    period of one year.
                                  </li>
                                  <li className="pt-3">
                                    The first month’s salary will be paid only
                                    on completion of one year along with the 12
                                    <sup>th</sup> month’s salary.
                                  </li>
                                  <li className="pt-3">
                                    Must mandatorily give a Notice period of a
                                    minimum of 3 months if they wish to leave
                                    the organization. If this is not followed,
                                    then neither will the first month’s salary
                                    be paid nor will a work experience
                                    certificate be issued.
                                  </li>
                                  <li className="pt-3">
                                    <span
                                      className="vipar"
                                      style={{
                                        fontSize: '22px',
                                        color: '#99d050',
                                      }}
                                    >
                                      {' '}
                                      Vipar&nbsp;
                                    </span>{' '}
                                    <sup class="">®</sup> will give a minimum of
                                    2 months of Notice period to the employee to
                                    relieve him / her from the organization.
                                  </li>
                                  <li className="pt-3">
                                    Working Hours per Day for All will be
                                    Excluding lunch and tea Breaks.
                                  </li>
                                  <li className="pt-3">
                                    Paid Leave is applicable Only in cases of{' '}
                                    <u>Critical Medical Emergency</u> *
                                    <sup>1</sup> to Self,{' '}
                                    <u>Critical Medical Emergency</u> *
                                    <sup>1</sup> <u>to Immediate Family</u> *
                                    <sup>2</sup>, Death in{' '}
                                    <u>Immediate Family</u> *<sup>2</sup> ,{' '}
                                    <u>Menstrual Leave</u> *<sup>3</sup> (for
                                    women), <u>Maternity Leave</u> *<sup>4</sup>{' '}
                                    (for women), <u>Paternity Leave</u> *
                                    <sup>5</sup> (for men).
                                  </li>
                                  <li className="pt-3">
                                    Every Paid Leave Application (except in
                                    cases of monthly Menstrual Leaves) will be
                                    entertained and sanctioned Only with Valid
                                    Substantiating Documentation.
                                  </li>
                                  <li className="pt-3">
                                    Leaves in a month cannot be carried forward
                                    to another month.
                                  </li>
                                  <li className="pt-3">
                                    Work-from-Home is a not concept we believe
                                    in except in case of a pandemic or state /
                                    city shutdown.
                                  </li>
                                </ul>
                              </div>

                              <div className="pt-2">
                                <span className="bld">
                                  What it ( * ) means ?
                                </span>
                                <br />
                              </div>
                              <div className="pt-4">
                                <span className="">
                                  *<sup>1</sup> Critical Medical Emergency :
                                </span>
                                <div className="pt-2">
                                  <ul>
                                    <li className="pt-3">
                                      Hospitalization / Treatment for a
                                      Life-threatening Illness
                                    </li>
                                    <li className="pt-3">
                                      Critically required Surgeries
                                    </li>
                                    <li className="pt-3">
                                      Accident related conditions / treatment /
                                      hospitalization
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div className="pt-4">
                                <span className="">
                                  *<sup>2</sup> Immediate Family :
                                </span>
                                <div className="pt-2">
                                  <ul>
                                    <li className="pt-3">Parent</li>
                                    <li className="pt-3">Spouse</li>
                                    <li className="pt-3">Child</li>
                                    <li className="pt-3">Blood Sibling</li>
                                    <li className="pt-3">Spouse’s Parent</li>
                                  </ul>
                                </div>
                              </div>

                              <div className="pt-4">
                                <span className="">
                                  *<sup>3</sup> Menstrual Leave : A maximum of 4
                                  days every month.
                                </span>
                              </div>
                              <div className="pt-4">
                                <span className="">
                                  *<sup>4</sup> Maternity Leave : A maximum of
                                  one year (3 months before delivery and 9
                                  months post delivery).
                                </span>
                              </div>
                              <div className="pt-4">
                                <span className="">
                                  *<sup>5</sup> Paternity Leave : A maximum of 3
                                  months.
                                </span>
                              </div>

                              <div className="pt-5">
                                For Permanent Employees :
                              </div>

                              <div className="pt-2">
                                <ul className="pt-4">
                                  <li>
                                    Must sign a work contract for a minimum
                                    period of two years.
                                  </li>
                                  <li className="pt-3">
                                    Must mandatorily give a Notice period of a
                                    minimum of 3 months if they wish to leave
                                    the organization. If this is not followed,
                                    then neither will the immediate previous
                                    month’s salary be paid nor will a work
                                    experience certificate be issued.
                                  </li>
                                  <li className="pt-3">
                                    <span
                                      className="vipar"
                                      style={{
                                        fontSize: '22px',
                                        color: '#99d050',
                                      }}
                                    >
                                      {' '}
                                      Vipar&nbsp;
                                    </span>{' '}
                                    <sup class="">®</sup> will give a minimum of
                                    2 months of Notice period to the employee to
                                    relieve him / her from the organization.
                                  </li>
                                  <li className="pt-3">
                                    Working Hours per Day for All will be
                                    Excluding lunch and tea Breaks.
                                  </li>
                                  <li className="pt-3">
                                    Paid Leave is applicable Only in cases of{' '}
                                    <u>Critical Medical Emergency</u> *
                                    <sup>1</sup> to Self,{' '}
                                    <u>Critical Medical Emergency</u> *
                                    <sup>1</sup> <u>to Immediate Family</u> *
                                    <sup>2</sup>, Death in{' '}
                                    <u>Immediate Family</u> *<sup>2</sup> ,{' '}
                                    <u>Menstrual Leave</u> *<sup>3</sup> (for
                                    women), <u>Maternity Leave</u> *<sup>4</sup>{' '}
                                    (for women), <u>Paternity Leave</u> *
                                    <sup>5</sup> (for men).
                                  </li>
                                  <li className="pt-3">
                                    Every Paid Leave Application (except in
                                    cases of monthly Menstrual Leaves) will be
                                    entertained and sanctioned Only with Valid
                                    Substantiating Documentation.
                                  </li>
                                  <li className="pt-3">
                                    Work-from-Home is a not concept we believe
                                    in except in case of a pandemic or state /
                                    city shutdown.
                                  </li>
                                </ul>
                              </div>

                              <div className="pt-2">
                                <span className="bld">
                                  What it ( * ) means ?
                                </span>
                                <br />
                              </div>
                              <div className="pt-4">
                                <span className="">
                                  *<sup>1</sup> Critical Medical Emergency :
                                </span>
                                <div className="pt-2">
                                  <ul>
                                    <li className="pt-3">
                                      Hospitalization / Treatment for a
                                      Life-threatening Illness
                                    </li>
                                    <li className="pt-3">
                                      Critically required Surgeries
                                    </li>
                                    <li className="pt-3">
                                      Accident related conditions / treatment /
                                      hospitalization
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div className="pt-4">
                                <span className="">
                                  *<sup>2</sup> Immediate Family :
                                </span>
                                <div className="pt-2">
                                  <ul>
                                    <li className="pt-3">Parent</li>
                                    <li className="pt-3">Spouse</li>
                                    <li className="pt-3">Child</li>
                                    <li className="pt-3">Blood Sibling</li>
                                    <li className="pt-3">Spouse’s Parent</li>
                                  </ul>
                                </div>
                              </div>

                              <div className="pt-4">
                                <span className="">
                                  *<sup>3</sup> Menstrual Leave : A maximum of 4
                                  days every month.
                                </span>
                              </div>
                              <div className="pt-4">
                                <span className="">
                                  *<sup>4</sup> Maternity Leave : A maximum of
                                  one year (3 months before delivery and 9
                                  months post delivery).
                                </span>
                              </div>
                              <div className="pt-4">
                                <span className="">
                                  *<sup>5</sup> Paternity Leave : A maximum of 3
                                  months.
                                </span>
                              </div>
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                  <div
                    className="collapse"
                    data-bs-parent="#coll"
                    id="collapse12"
                  >
                    <div
                      className="card ps-0 card-body"
                      style={{ backgroundColor: '#e9eef5', border: 'none' }}
                    >
                      <div className="row">
                        <div className="col ps-3">
                          <span
                            className=" text-dark"
                            style={{ fontSize: '14px' }}
                          >
                            <div>For Freshers (Inexperienced) :</div>

                            <div className="pt-2">
                              <ul className="pt-4">
                                <li>
                                  The first week will be an ‘Unpaid Training
                                  Period’ by the end of which
                                  <span
                                    className="vipar"
                                    style={{
                                      fontSize: '22px',
                                      color: '#99d050',
                                    }}
                                  >
                                    {' '}
                                    Vipar &nbsp;
                                  </span>{' '}
                                  <sup class="">®</sup> has the discretion to
                                  decide whether or not to employ the person.
                                </li>
                                <li className="pt-3">
                                  Post the first week, the employee will be on a
                                  probation period of one year.
                                </li>
                                <li className="pt-3">
                                  Must sign a work contract for a minimum period
                                  of one year.
                                </li>
                                <li className="pt-3">
                                  The first month’s salary will be paid only on
                                  completion of one year along with the 1212
                                  <sup>th</sup>
                                  month’s salary.
                                </li>
                                <li className="pt-3">
                                  Must mandatorily give a Notice period of a
                                  minimum of 3 months if they wish to leave the
                                  organization. If this is not followed, then
                                  neither will the first month’s salary be paid
                                  nor will a work experience certificate be
                                  issued.
                                </li>
                                <li className="pt-3">
                                  <span
                                    className="vipar"
                                    style={{
                                      fontSize: '22px',
                                      color: '#99d050',
                                    }}
                                  >
                                    {' '}
                                    Vipar&nbsp;<sup class="">®</sup>{' '}
                                  </span>{' '}
                                  will give a minimum of 2 months of Notice
                                  period to the employee to relieve him / her
                                  from the organization.
                                </li>
                                <li className="pt-3">
                                  Working Hours per Day for All will be
                                  Excluding lunch and tea Breaks.
                                </li>
                                <li className="pt-3">
                                  Paid Leave is applicable Only in cases of{' '}
                                  <u>Critical Medical Emergency</u> *
                                  <sup>1</sup> to Self,{' '}
                                  <u>Critical Medical Emergency</u> *
                                  <sup>1</sup> <u>to Immediate Family</u> *
                                  <sup>2</sup> , Death in{' '}
                                  <u>Immediate Family</u> *<sup>2</sup> ,{' '}
                                  <u>Menstrual Leave</u> *<sup>3</sup> (for
                                  women), <u>Maternity Leave</u> *<sup>4</sup>{' '}
                                  (for women), <u>Paternity Leave</u> *
                                  <sup>5</sup> (for men).
                                </li>
                                <li className="pt-3">
                                  Every Paid Leave Application (except in cases
                                  of monthly Menstrual Leaves) will be
                                  entertained and sanctioned Only with Valid
                                  Substantiating Documentation.
                                </li>
                                <li className="pt-3">
                                  Leaves in a month cannot be carried forward to
                                  another month.
                                </li>
                                <li className="pt-3">
                                  Work-from-Home is a not concept we believe in
                                  except in case of a pandemic or state / city
                                  shutdown.
                                </li>
                              </ul>
                            </div>

                            <div className="pt-2">
                              <span className="bld">What it ( * ) means ?</span>
                              <br />
                            </div>
                            <div className="pt-4">
                              <span className="">
                                *<sup>1</sup> Critical Medical Emergency :
                              </span>
                              <div className="pt-2">
                                <ul>
                                  <li className="pt-3">
                                    Hospitalization / Treatment for a
                                    Life-threatening Illness
                                  </li>
                                  <li className="pt-3">
                                    Critically required Surgeries
                                  </li>
                                  <li className="pt-3">
                                    Accident related conditions / treatment /
                                    hospitalization
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div className="pt-4">
                              <span className="">
                                *<sup>2</sup> Immediate Family :
                              </span>
                              <div className="pt-2">
                                <ul>
                                  <li className="pt-3">Parent</li>
                                  <li className="pt-3">Spouse</li>
                                  <li className="pt-3">Child</li>
                                  <li className="pt-3">Blood Sibling</li>
                                  <li className="pt-3">Spouse’s Parent</li>
                                </ul>
                              </div>
                            </div>

                            <div className="pt-4">
                              <span className="">
                                *<sup>3</sup> Menstrual Leave : A maximum of 4
                                days every month.
                              </span>
                            </div>
                            <div className="pt-4">
                              <span className="">
                                *<sup>4</sup> Maternity Leave : A maximum of one
                                year (3 months before delivery and 9 months post
                                delivery).
                              </span>
                            </div>
                            <div className="pt-4">
                              <span className="">
                                *<sup>5</sup> Paternity Leave : A maximum of 3
                                months.
                              </span>
                            </div>

                            <div className="pt-5">
                              For Permanent Employees :
                            </div>

                            <div className="pt-2">
                              <ul className="pt-4">
                                <li>
                                  Must sign a work contract for a minimum period
                                  of two years.
                                </li>
                                <li className="pt-3">
                                  Must mandatorily give a Notice period of a
                                  minimum of 3 months if they wish to leave the
                                  organization. If this is not followed, then
                                  neither will the immediate previous month’s
                                  salary be paid nor will a work experience
                                  certificate be issued.
                                </li>
                                <li className="pt-3">
                                  <span
                                    className="vipar"
                                    style={{
                                      fontSize: '22px',
                                      color: '#99d050',
                                    }}
                                  >
                                    {' '}
                                    Vipar&nbsp;
                                  </span>{' '}
                                  <sup class="">®</sup> will give a minimum of 2
                                  months of Notice period to the employee to
                                  relieve him / her from the organization.
                                </li>
                                <li className="pt-3">
                                  Working Hours per Day for All will be
                                  Excluding lunch and tea Breaks.
                                </li>
                                <li className="pt-3">
                                  Paid Leave is applicable Only in cases of{' '}
                                  <u>Critical Medical Emergency</u> *
                                  <sup>1</sup> to Self,{' '}
                                  <u>Critical Medical Emergency</u> *
                                  <sup>1</sup> <u>to Immediate Family</u> *
                                  <sup>2</sup> , Death in{' '}
                                  <u>Immediate Family</u> *<sup>2</sup> ,{' '}
                                  <u>Menstrual Leave</u> *<sup>3</sup> (for
                                  women), <u>Maternity Leave</u> *<sup>4</sup>{' '}
                                  (for women), <u>Paternity Leave</u> *
                                  <sup>5</sup> (for men).
                                </li>
                                <li className="pt-3">
                                  Every Paid Leave Application (except in cases
                                  of monthly Menstrual Leaves) will be
                                  entertained and sanctioned Only with Valid
                                  Substantiating Documentation.
                                </li>
                                <li className="pt-3">
                                  Work-from-Home is a not concept we believe in
                                  except in case of a pandemic or state / city
                                  shutdown.
                                </li>
                              </ul>
                            </div>

                            <div className="pt-2">
                              <span className="bld">What it ( * ) means ?</span>
                              <br />
                            </div>
                            <div className="pt-4">
                              <span className="">
                                *<sup>1</sup> Critical Medical Emergency :
                              </span>
                              <div className="pt-2">
                                <ul>
                                  <li className="pt-3">
                                    Hospitalization / Treatment for a
                                    Life-threatening Illness
                                  </li>
                                  <li className="pt-3">
                                    Critically required Surgeries
                                  </li>
                                  <li className="pt-3">
                                    Accident related conditions / treatment /
                                    hospitalization
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div className="pt-4">
                              <span className="">
                                *<sup>2</sup> Immediate Family :
                              </span>
                              <div className="pt-2">
                                <ul>
                                  <li className="pt-3">Parent</li>
                                  <li className="pt-3">Spouse</li>
                                  <li className="pt-3">Child</li>
                                  <li className="pt-3">Blood Sibling</li>
                                  <li className="pt-3">Spouse’s Parent</li>
                                </ul>
                              </div>
                            </div>

                            <div className="pt-4">
                              <span className="">
                                *<sup>3</sup> Menstrual Leave : A maximum of 4
                                days every month.
                              </span>
                            </div>
                            <div className="pt-4">
                              <span className="">
                                *<sup>4</sup> Maternity Leave : A maximum of one
                                year (3 months before delivery and 9 months post
                                delivery).
                              </span>
                            </div>
                            <div className="pt-4">
                              <span className="">
                                *<sup>5</sup> Paternity Leave : A maximum of 3
                                months.
                              </span>
                            </div>
                            <br />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="bdr">
              <div className="container-fluid">
                <div
                  className="card ps-1 pt-2"
                  style={{ backgroundColor: '#e9eef5', border: 'none' }}
                >
                  <div className="card-body">
                    <div className="row mt-3">
                      <div className="col p-0">
                        <span
                          style={{ fontSize: '18px' }}
                          className=" text-dark"
                        >
                          <div style={{ fontSize: '18px' }} className="nmb">
                            JOB OPPORTUNITIES
                          </div>
                          {/* <div className=" bnmm pt-2">
                          Kindly Read and Understand the Concept, Remuneration
                          and Employment Policies clearly before you apply for a
                          job with us.
                        </div>{' '} */}
                        </span>
                      </div>
                      <div className="col-2 p-0">
                        <h5 className="text-center">
                          <Button
                            onClick={() => setOpen2(!open2)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                            className="btn btn-outline-dark btn-light btn-md zxxxx"
                            // style={{backgroundColor:"#e9eef5"}}
                          >
                            Read&nbsp;more.....
                          </Button>
                        </h5>
                      </div>
                    </div>
                    <Collapse in={open2}>
                      <div>
                        {/* <div className="footlightfont text-center py-4">
                          <span className="boldfont">WORK-from-OFFICE</span>
                          &nbsp;&nbsp;&nbsp;Jobs
                        </div> */}
                        <Vaccanies />
                      </div>
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className=" d-md-none d-block footlightfont"
            style={{
              backgroundColor: '#e9eef5',
              paddingTop: '36px',
              paddingBottom: '36px',
            }}
          >
            <div id="bdr">
              <div className="container">
                <div
                  className="card ps-1"
                  style={{ backgroundColor: '#e9eef5', border: 'none' }}
                >
                  <div className="card-body pb-0">
                    <div className="row mt-3">
                      <div className="col p-0">
                        <span
                          style={{ fontSize: '18px' }}
                          className=" text-dark"
                        >
                          <div className="nmb" style={{ fontSize: '18px' }}>
                            WORKING HOURS and DAYS&nbsp;
                          </div>
                          {/* <div className=" bnmm pt-2">“Equal Collar” Jobs</div>{' '} */}
                        </span>
                      </div>
                      <div className="col-2 ">
                        <h5 className="text-center">
                          {!plus.col1 ? (
                            <Button
                              aria-controls="example-collapse-text"
                              aria-expanded={open}
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              variant="black"
                              onClick={(e) => {
                                setOpenmb1(!openmb1);
                                setplus({
                                  ...plus,
                                  col1: true,
                                });
                              }}
                            >
                              <AiOutlinePlusCircle
                                name="add-circle-outline"
                                size="25px"
                                style={{ color: 'black' }}
                              ></AiOutlinePlusCircle>
                            </Button>
                          ) : (
                            <Button
                              aria-controls="example-collapse-text"
                              aria-expanded={openmb1}
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              variant="black"
                              onClick={(e) => {
                                setOpenmb1(!openmb1);
                                setplus({
                                  ...plus,
                                  col1: false,
                                });
                              }}
                            >
                              <AiOutlineMinusCircle
                                name="add-circle-outline"
                                size="25px"
                                style={{ color: 'black' }}
                              ></AiOutlineMinusCircle>
                            </Button>
                          )}
                        </h5>
                      </div>
                    </div>
                    <Collapse in={openmb1}>
                      <div
                        className="collapse"
                        data-bs-parent="#coll"
                        id="collapse11"
                      >
                        <div
                          className="card  card-body"
                          style={{ backgroundColor: '#e9eef5', border: 'none' }}
                        >
                          <div className="row">
                            <div className="col ps-0">
                              <span
                                className=" text-dark"
                                style={{ fontSize: '18px' }}
                              >
                                <div className="bnm ">WORKING HOURS</div>
                                <div className="bnm pt-3">
                                  09:00 to 18:00 (9 AM to 6 PM) with a 45-minute
                                  break for lunch, short nap and a 15-minute
                                  break for tea.
                                </div>
                                <div className="bnm pt-3">
                                  Lunch Break : Anytime between 12:00 to 14:00
                                  (12 PM to 2 PM)
                                  <br />
                                  Tea Break : Anytime between 16:00 to 17:00 (4
                                  PM to 5 PM)
                                </div>
                                <div className="bnm pt-3">WORKING DAYS</div>
                                <div className="bnm pt-3">Monday to Friday</div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
                <hr className="px-3 mb-0"></hr>
              </div>
            </div>

            <div id="bdr">
              <div className="container">
                <div
                  className="card ps-1"
                  style={{ backgroundColor: '#e9eef5', border: 'none' }}
                >
                  <div className="card-body py-0">
                    <div className="row mt-3">
                      <div className="col p-0">
                        <span
                          className=" text-dark"
                          style={{ fontSize: '18px' }}
                        >
                          <div className="nmb" style={{ fontSize: '18px' }}>
                            EMPLOYMENT POLICIES
                          </div>
                          {/* <div className="bnmm pt-2">
                          Only on the basis of Working Hours
                        </div>{' '} */}
                        </span>
                      </div>
                      <div className="col-2 ">
                        <h5 className="text-center">
                          {!plus.col2 ? (
                            <Button
                              aria-controls="example-collapse-text"
                              aria-expanded={open}
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              variant="black"
                              onClick={(e) => {
                                setOpenmb2(!openmb2);
                                setplus({
                                  ...plus,
                                  col2: true,
                                });
                              }}
                            >
                              <AiOutlinePlusCircle
                                name="add-circle-outline"
                                size="25px"
                                style={{ color: 'black' }}
                              ></AiOutlinePlusCircle>
                            </Button>
                          ) : (
                            <Button
                              aria-controls="example-collapse-text"
                              aria-expanded={openmb1}
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              variant="black"
                              onClick={(e) => {
                                setOpenmb2(!openmb2);
                                setplus({
                                  ...plus,
                                  col2: false,
                                });
                              }}
                            >
                              <AiOutlineMinusCircle
                                name="add-circle-outline"
                                size="25px"
                                style={{ color: 'black' }}
                              ></AiOutlineMinusCircle>
                            </Button>
                          )}
                        </h5>
                      </div>
                    </div>
                    <Collapse in={openmb2}>
                      <div
                        className="collapse"
                        data-bs-parent="#coll"
                        id="collapse12"
                      >
                        <div
                          className="card  card-body"
                          style={{
                            backgroundColor: '#e9eef5',
                            border: 'none',
                            paddingLeft: '14px',
                          }}
                        >
                          <div className="row">
                            <div className="col ps-3">
                              <span
                                className=" text-dark"
                                style={{ fontSize: '18px' }}
                              >
                                <div>For Freshers (Inexperienced) :</div>

                                <div className="pt-2">
                                  <ul className="pt-4">
                                    <li>
                                      The first week will be an ‘Unpaid Training
                                      Period’ by the end of which
                                      <span
                                        className="vipar"
                                        style={{
                                          fontSize: '22px',
                                          color: '#99d050',
                                        }}
                                      >
                                        {' '}
                                        Vipar&nbsp;
                                      </span>{' '}
                                      <sup class="">®</sup> has the discretion
                                      to decide whether or not to employ the
                                      person.
                                    </li>
                                    <li className="pt-3">
                                      Post the first week, the employee will be
                                      on a probation period of one year.
                                    </li>
                                    <li className="pt-3">
                                      Must sign a work contract for a minimum
                                      period of one year.
                                    </li>
                                    <li className="pt-3">
                                      The first month’s salary will be paid only
                                      on completion of one year along with the
                                      12<sup>th</sup> month’s salary.
                                    </li>
                                    <li className="pt-3">
                                      Must mandatorily give a Notice period of a
                                      minimum of 3 months if they wish to leave
                                      the organization. If this is not followed,
                                      then neither will the first month’s salary
                                      be paid nor will a work experience
                                      certificate be issued.
                                    </li>
                                    <li className="pt-3">
                                      <span
                                        className="vipar"
                                        style={{
                                          fontSize: '22px',
                                          color: '#99d050',
                                        }}
                                      >
                                        {' '}
                                        Vipar&nbsp;
                                      </span>{' '}
                                      <sup class="">®</sup> will give a minimum
                                      of 2 months of Notice period to the
                                      employee to relieve him / her from the
                                      organization.
                                    </li>
                                    <li className="pt-3">
                                      Working Hours per Day for All will be
                                      Excluding lunch and tea Breaks.
                                    </li>
                                    <li className="pt-3">
                                      Paid Leave is applicable Only in cases of{' '}
                                      <u>Critical Medical Emergency</u> *
                                      <sup>1</sup> to Self,{' '}
                                      <u>Critical Medical Emergency</u> *
                                      <sup>1</sup> <u>to Immediate Family</u> *
                                      <sup>2</sup> , Death in{' '}
                                      <u> Immediate Family</u> *<sup>2</sup> ,{' '}
                                      <u>Menstrual Leave</u> *<sup>3</sup> (for
                                      women), <u>Maternity Leave</u> *
                                      <sup>4</sup> (for women),{' '}
                                      <u>Paternity Leave</u> *<sup>5</sup> (for
                                      men).
                                    </li>
                                    <li className="pt-3">
                                      Every Paid Leave Application (except in
                                      cases of monthly Menstrual Leaves) will be
                                      entertained and sanctioned Only with Valid
                                      Substantiating Documentation.
                                    </li>
                                    <li className="pt-3">
                                      Leaves in a month cannot be carried
                                      forward to another month.
                                    </li>
                                    <li className="pt-3">
                                      Work-from-Home is a not concept we believe
                                      in except in case of a pandemic or state /
                                      city shutdown.
                                    </li>
                                  </ul>
                                </div>

                                <div className="pt-2">
                                  <span className="bld">
                                    What it ( * ) means ?
                                  </span>
                                  <br />
                                </div>
                                <div className="pt-4">
                                  <span className="">
                                    *<sup>1</sup> Critical Medical Emergency :
                                  </span>
                                  <div className="pt-2">
                                    <ul>
                                      <li className="pt-3">
                                        Hospitalization / Treatment for a
                                        Life-threatening Illness
                                      </li>
                                      <li className="pt-3">
                                        Critically required Surgeries
                                      </li>
                                      <li className="pt-3">
                                        Accident related conditions / treatment
                                        / hospitalization
                                      </li>
                                    </ul>
                                  </div>
                                </div>

                                <div className="pt-4">
                                  <span className="">
                                    *<sup>2</sup> Immediate Family :
                                  </span>
                                  <div className="pt-2">
                                    <ul>
                                      <li className="pt-3">Parent</li>
                                      <li className="pt-3">Spouse</li>
                                      <li className="pt-3">Child</li>
                                      <li className="pt-3">Blood Sibling</li>
                                      <li className="pt-3">Spouse’s Parent</li>
                                    </ul>
                                  </div>
                                </div>

                                <div className="pt-4">
                                  <span className="">
                                    *<sup>3</sup> Menstrual Leave : A maximum of
                                    4 days every month.
                                  </span>
                                </div>
                                <div className="pt-4">
                                  <span className="">
                                    *<sup>4</sup> Maternity Leave : A maximum of
                                    one year (3 months before delivery and 9
                                    months post delivery).
                                  </span>
                                </div>
                                <div className="pt-4">
                                  <span className="">
                                    *<sup>5</sup> Paternity Leave : A maximum of
                                    3 months.
                                  </span>
                                </div>
                                <div className="pt-5">
                                  For Permanent Employees :
                                </div>

                                <div className="pt-2">
                                  <ul className="pt-4">
                                    <li>
                                      Must sign a work contract for a minimum
                                      period of two years.
                                    </li>
                                    <li className="pt-3">
                                      Must mandatorily give a Notice period of a
                                      minimum of 3 months if they wish to leave
                                      the organization. If this is not followed,
                                      then neither will the immediate previous
                                      month’s salary be paid nor will a work
                                      experience certificate be issued.
                                    </li>
                                    <li className="pt-3">
                                      <span
                                        className="vipar"
                                        style={{
                                          fontSize: '22px',
                                          color: '#99d050',
                                        }}
                                      >
                                        {' '}
                                        Vipar&nbsp;
                                      </span>{' '}
                                      <sup class="">®</sup> will give a minimum
                                      of 2 months of Notice period to the
                                      employee to relieve him / her from the
                                      organization.
                                    </li>
                                    <li className="pt-3">
                                      Working Hours per Day for All will be
                                      Excluding lunch and tea Breaks.
                                    </li>
                                    <li className="pt-3">
                                      Paid Leave is applicable Only in cases of{' '}
                                      <u>Critical Medical Emergency</u> *
                                      <sup>1</sup> to Self,{' '}
                                      <u>Critical Medical Emergency</u> *
                                      <sup>1</sup> <u>to Immediate Family</u> *
                                      <sup>2</sup> , Death in{' '}
                                      <u> Immediate Family</u> *<sup>2</sup> ,{' '}
                                      <u>Menstrual Leave</u> *<sup>3</sup> (for
                                      women), <u>Maternity Leave</u> *
                                      <sup>4</sup> (for women),{' '}
                                      <u>Paternity Leave</u> *<sup>5</sup> (for
                                      men).
                                    </li>
                                    <li className="pt-3">
                                      Every Paid Leave Application (except in
                                      cases of monthly Menstrual Leaves) will be
                                      entertained and sanctioned Only with Valid
                                      Substantiating Documentation.
                                    </li>
                                    <li className="pt-3">
                                      Work-from-Home is a not concept we believe
                                      in except in case of a pandemic or state /
                                      city shutdown.
                                    </li>
                                  </ul>
                                </div>

                                <div className="pt-2">
                                  <span className="bld">
                                    What it ( * ) means ?
                                  </span>
                                  <br />
                                </div>
                                <div className="pt-4">
                                  <span className="">
                                    *<sup>1</sup> Critical Medical Emergency :
                                  </span>
                                  <div className="pt-2">
                                    <ul>
                                      <li className="pt-3">
                                        Hospitalization / Treatment for a
                                        Life-threatening Illness
                                      </li>
                                      <li className="pt-3">
                                        Critically required Surgeries
                                      </li>
                                      <li className="pt-3">
                                        Accident related conditions / treatment
                                        / hospitalization
                                      </li>
                                    </ul>
                                  </div>
                                </div>

                                <div className="pt-4">
                                  <span className="">
                                    *<sup>2</sup> Immediate Family :
                                  </span>
                                  <div className="pt-2">
                                    <ul>
                                      <li className="pt-3">Parent</li>
                                      <li className="pt-3">Spouse</li>
                                      <li className="pt-3">Child</li>
                                      <li className="pt-3">Blood Sibling</li>
                                      <li className="pt-3">Spouse’s Parent</li>
                                    </ul>
                                  </div>
                                </div>

                                <div className="pt-4">
                                  <span className="">
                                    *<sup>3</sup> Menstrual Leave : A maximum of
                                    4 days every month.
                                  </span>
                                </div>
                                <div className="pt-4">
                                  <span className="">
                                    *<sup>4</sup> Maternity Leave : A maximum of
                                    one year (3 months before delivery and 9
                                    months post delivery).
                                  </span>
                                </div>
                                <div className="pt-4">
                                  <span className="">
                                    *<sup>5</sup> Paternity Leave : A maximum of
                                    3 months.
                                  </span>
                                </div>
                                <br />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
                <hr className="px-3 mb-0"></hr>
              </div>
            </div>

            <div id="bdr">
              <div className="container">
                <div
                  className="card ps-1"
                  style={{ backgroundColor: '#e9eef5', border: 'none' }}
                >
                  <div className="card-body py-0">
                    <div className="row mt-3">
                      <div className="col p-0">
                        <span
                          style={{ fontSize: '18px' }}
                          className=" text-dark"
                        >
                          <div className="nmb" style={{ fontSize: '18px' }}>
                            JOB OPPORTUNITIES
                          </div>
                          {/* <div className=" bnmm pt-2">
                          Kindly Read and Understand the Concept, Remuneration
                          and Employment Policies clearly before you apply for a
                          job with us.
                        </div>{' '} */}
                        </span>
                      </div>
                      <div className="col-2 ">
                        <h5 className="text-center">
                          {!plus.col3 ? (
                            <Button
                              aria-controls="example-collapse-text"
                              aria-expanded={open}
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              variant="black"
                              onClick={(e) => {
                                setOpenmb3(!openmb3);
                                setplus({
                                  ...plus,
                                  col3: true,
                                });
                              }}
                            >
                              <AiOutlinePlusCircle
                                name="add-circle-outline"
                                size="25px"
                                style={{ color: 'black' }}
                              ></AiOutlinePlusCircle>
                            </Button>
                          ) : (
                            <Button
                              aria-controls="example-collapse-text"
                              aria-expanded={openmb1}
                              style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                              }}
                              variant="black"
                              onClick={(e) => {
                                setOpenmb3(!openmb3);
                                setplus({
                                  ...plus,
                                  col3: false,
                                });
                              }}
                            >
                              <AiOutlineMinusCircle
                                name="add-circle-outline"
                                size="25px"
                                style={{ color: 'black' }}
                              ></AiOutlineMinusCircle>
                            </Button>
                          )}
                        </h5>
                      </div>
                    </div>
                    <Collapse in={openmb3}>
                      <div>
                        {/* <div className="footlightfont text-center py-4">
                          <span className="boldfont">WORK-from-OFFICE</span>
                          &nbsp;&nbsp;&nbsp;Jobs
                        </div> */}
                        <Vaccanies />
                      </div>
                    </Collapse>
                  </div>
                </div>
                <hr className="px-3 mb-0"></hr>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="pt-5" style={{ backgroundColor: '#e9eef5' }}>
        <Footer />
      </div>
    </div>
  );
};
