import React,{useState,useEffect} from 'react'
import axios from 'axios'
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from 'moment';
import { useNavigate, useSearchParams, Link } from 'react-router-dom'
const notify = (message, sab) => {
    if (sab) {
        toast.success(message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } else {
        toast.warning(message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '300',
    },
  },
};
export const SME_salary_remittances = () => {
    let [searchParams, setSearchParams] = useSearchParams();  
    var Acc_id = searchParams.get("k")// sme Account id
    var log_id = searchParams.get("l")// sme login id
    const [data, setdata] = useState([])
    useEffect(() => {
      get_data()
    }, [])
    // modal 1
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // modal 2
    const [show1, setShow1] = useState(false);
    const [Ids, setIds] = useState("")
    const handleClose1 = () => setShow1(false);
    const handleShow1 = (id) => {setShow1(true);
      console.log(id)
      setIds(id)

    }
  
    const Delete=()=>{
      axios.post('/api/SuperAdmin/SME_salary_remittances_delete',{Ids}).then((res)=>{
        if (res.status==200) {
          get_data()
          notify(' Deleted successfully',true)
          handleClose1()
        }
          console.log(res.data)
      }).catch((err)=>{
         console.log(err)
         notify('Something went wrong',false)
      })
    }
 
    

    const get_data=()=>{
      axios.post('/api/SuperAdmin/SME_salary_remittances_get',{Acc_id,log_id,}).then((res)=>{
        if (res.status==200) {
          setdata(res.data)
        }
          console.log(res.data)
      }).catch((err)=>{
         console.log(err)
      })
    }

    var    columns=[
        {
            field: "id",
            headerName: "No. ",
            width: 60,
          },
          {
            field: "month",
            headerName: "Month",
            width: 200,
          },
          {
            field: "Amount",
            headerName: "Amount",
            width: 200,
          },
          {
            field: "Status",
            headerName: "Status",
            width: 200,
          },
          {
            field: "created_on",
            headerName: "Credited On",
            width: 200,
          },
          {
            field: "PaymentId",
            headerName: "Transaction Id",
            width: 200,
          },          
        //   {
        //     field: 'Update',
        //     headerName: 'Update',
        //     width: 150,
        //     renderCell: (params) => {
        //       return (
        //         <button className='btn pro-spaces-button3' onClick={(e)=>openFirstModal(params.rowIndex,params.row._id,params.row.Sub_Admin_login_id,params.row.Account_holder_login_id,params)}>
        //           Edit
        //         </button>
        //       );
        //     },
        //   },
          {
            field: 'Delete',
            headerName: 'Delete',
            width: 150,
            renderCell: (params) => {
              return (
                <button className='btn pro-spaces-button3' onClick={(e)=>handleShow1(params.row._id)}>
                  Delete
                </button>
              );
            },
          },
    ]

    const [values, setvalues] = useState({
        month:"",
        paymentId:"",
        paymentDate:"",
        // showPassword:false,
        amount:"",
        // pin:""
    })
const [error, seterror] = useState("")
    const submit=()=>{
      if (!values.amount) {
       return seterror("Enter amount")
      }

      if (!values.month) {
        return seterror("Select month")
      }
      seterror("")

      if (!values.paymentId) {
        return seterror("Enter payment transaction id")
      }
      seterror("")

      if (!values.paymentDate) {
        return  seterror("Enter payment date")
      }
      seterror("")

      console.log(values)
        axios.post('/api/SuperAdmin/SME_salary_remittances_submit',{Acc_id,log_id,values}).then((res)=>{
            if (res.data) {
                console.log(res)

                setvalues({ 
              ...values, 
                month:"",
                paymentId:"",
                paymentDate:"",
                // showPassword:false,
                amount:"",
                // pin:""
            })
            notify(' Added successfully',true)
            get_data()
            handleClose()
            }
        }).catch((err)=>{
            console.log(err)
            notify(' Something went wrong',true)
        })
    }

  return (
    <>
    <div className='bg-light' >

    <div className='d-flex justify-content-center pt-3'><h3>Salary remittances</h3> </div>
    <div className='mt-3 d-flex justify-content-end me-2'><button className='btn pro-spaces-button3' onClick={(e)=>handleShow()}>Salary remittances</button></div>
       <div className='mt-3 px-3'>
       <Box sx={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        // checkboxSelection
        // disableRowSelectionOnClick
      />
    </Box>
       </div>
    </div>
{/* Modal */}
    <>
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Salary remittances</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <>

            <div className='row'>
            <div className='col-12 mt-2'>
        <TextField
        label="Amount"
        type="text"
        value={values.amount}
        onChange={(e)=>setvalues({...values,amount:e.target.value})}
        fullWidth
      />
        </div>

            <div className='col-12 mt-2'>
        <TextField
        label="Month"
        type="month"
        value={values.month}
        onChange={(e)=>setvalues({...values,month:e.target.value})}
        fullWidth
        InputLabelProps={{
            shrink: true,
          }}
      />
        </div>
        <div className='col-12 mt-2'>
        <TextField
        label="Payment Transaction ID"
        type='text'
        value={values.paymentId}
        onChange={(e)=>setvalues({...values,paymentId:e.target.value})}
        fullWidth
      />
        </div>
        <div className='col-12 mt-2'>
        <TextField
        label="Payment Date"
        type="date"
        value={values.paymentDate}
        onChange={(e)=>setvalues({...values,paymentDate:e.target.value})}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth

      />
       </div>
{/* <div className='col-12'>
<div className='mt-2'>
    <TextField
        label="Pin"
        type={values.showPassword ? 'text' : 'password'}
        value={values.pin}
        onChange={(e)=>setvalues({...values,pin:e.target.value})}
        fullWidth

        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={(e)=>setvalues({...values,showPassword:!values.showPassword})}>
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

    </div>
</div> */}
<div className='pt-2'>
      <p style={{color:"red"}}>{error} </p>
    </div>

      </div>
            
            </>
        </Modal.Body>
        <Modal.Footer>
        <button className='btn pro-spaces-button3' onClick={(e)=>submit()}>Submit</button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <Modal.Title>Deleting</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want delete</Modal.Body>
        <Modal.Footer>
            <button className='btn btn-danger' onClick={(e)=>{Delete()}}>Delete</button>
        </Modal.Footer>
      </Modal>


    </>
    </>

  )
}
