import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {AiOutlineCheck,AiOutlineClose} from "react-icons/ai"
import { FaPhotoVideo, FaVideo } from "react-icons/fa";
import {BsFillPlusCircleFill} from "react-icons/bs"
export default function CategorysAddEdit() {
const [cat, setCat] = useState([]);
    const [category, setCategory] = useState(false);
    const [subcategory, setSubcategory] = useState(false);
    const [Stateload, setStateload] = useState(true);
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState(false);
    const [error1, seterror1] = useState(false);

//     const formData = new FormData()
   
//     const [Image, setImage] = useState(false)
//     const [Video, setVideo] = useState(false)
//     const [Schedule, setSchedule] = useState(false)
//     const [first, setfirst] = useState(false);
//     const [rerender, setRerender] = useState(false);
//     const [showMedia, setshowMedia] = useState([])
//     const [content, setContent] = useState('');
//     const [tittle, setTittle] = useState('');

//     const Clickhandler = (cont) => {
//       console.log(cont)
//       if (cont == 'image') {
//           document.getElementById("image").click();
//       } else {
//           document.getElementById("video").click();
//       }
//   }

//   var arr = []
//     const [fil, setfil] = useState([])
//     const mediaHandler = (file, type) => {
//         for(var i = 0 ; i<file.length; i++) {
//             var sab = fil
//             sab.push(file[i])
            
//         };
//         setfil(sab)
//         console.log(file)
        
       
//         console.log('media handler');
//         console.log(showMedia);
//         if(showMedia.length>0){
//             arr = showMedia
//         }
//         var temp = arr
//         if (type === 'img') {
//             for (var i = 0; i < file.length; i++) {
//                 if (temp.length > 0) {
//                     temp.push
//                         (
//                             <div className="carousel-item ">
//                                 <img className='carItem' src={URL.createObjectURL(file[i])}></img>
//                             </div>
//                         )
//                 }
//                 else {
//                     temp.push
//                         (
//                             <div className="carousel-item active  ">
//                                 <img className='carItem' src={URL.createObjectURL(file[i])}></img>
//                             </div>
//                         )
//                 }
//             }
//         }
//         if (type === 'vid') {

//             for (var i = 0; i < file.length; i++) {
//                 if (temp.length > 0) {
//                     temp.push(
//                         <div className="carousel-item  ">
//                             <video className="carItem" controls>
//                                 <source src={URL.createObjectURL(file[i])} />
//                             </video>
//                         </div>
//                     )
//                 }
//                 else {
//                     temp.push(
//                         <div className="carousel-item   active ">
//                             <video className="carItem" controls>
//                                 <source src={URL.createObjectURL(file[i])} />
//                             </video>
//                         </div>
//                     )
//                 }
//             }
//         }
//         arr = temp
//         if(rerender){
//             setshowMedia(arr)
//             setRerender(!rerender)
//         }else{
//             setshowMedia(temp)
//             setRerender(!rerender)
//         }      
//     }
//     function dorender(media) {
//       console.log('works fine');
//       setfirst(media)
//   }
//   useEffect(() => {
//       console.log('first');
//       if (showMedia.length > 0) {
//           dorender(showMedia)
//       }
//   }, [showMedia]);

//   const dommerdup = () => {
  


    
// }

    const notify = (message, sab) => {
      if (sab) {
        toast.success(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.warning(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };


    const [value1, setValue1] = useState({
       
        category: "",
        type:true
      });
      const [value, setValue] = useState({
       
        category: "",
        categoryname:''

      });

    
      const sabari = (e) => {
        console.log(e.target.value);
        var anadhan;
        setValue({ ...value, category: e.target.value });
        selectCtategory( e.target.value)
        // setValue({...value,})
        cat.forEach((element) => {
          console.log(element._id);
          if (element._id === e.target.value) {
            anadhan = element.sub.map((elements) => {
              return <option value={elements._id}>{elements.sub}</option>;
            });
          } else {
          }
        });
        setSubcategory(anadhan);
      };

        function reload()
        {
          axios
          .get("/api/api/getcategory")
          .then((res) => {
            if (res.status === 200) {
              console.log("workd");
              setCat(res.data);
            }
          })
          .catch((err) => {
            console.log(err.response.status);
          });
        }

    
      useEffect(() => {
        reload()
 
      }, []);
    
      var sab = [];
      useEffect(() => {
        if (cat.length >= 1) {
          cat.forEach((element) => {
            sab.push(<option value={element._id}>{element.category}</option>);
          });
          setCategory(sab);
        } else {
          console.log("nothing");
        }
      }, [cat]);
      // useEffect(() => {
      //   if (Stateload) print_state("State");
      //   setStateload(false);
      // }, []);


      const AddCategorys= async(e)=>{
        // setloading(true)
        if(!value1.category)
        {
         return seterror("Enter category name")
        }
        seterror("")

         console.log(value1)
        let check = await axios.post('/api/Admin/CheckCategorys',{value1}).then(
          (res)=>{
            console.log(res.data)
        if(res.status==200)
        {
            if(!res.data)
            {

              let sab =  axios.post('/api/Admin/AddCategorys',{value1}).then((res)=>{
                if(res.status==200){
                  notify("Successfully added", true);
    
                  reload()
                      
                  seterror("")
              setloading(false)

                }
              }).catch((err)=>{
                console.log(err)
                notify(" Something went wrong", false);
    
              })
              setloading(false)
            }
            else{
              seterror("Category already exists")
            }
       
        }
      }
        ).catch((err)=>{
          console.log(err)
          notify(" Something went wrong", false);
          setloading(false)

        })
          
      }

    const [catname, setcatname] = useState(
      {
        category:''
      }
    )
      const selectCtategory=async(category)=>{
        console.log(category)
        // return
        var select= await axios.post('/api/Admin/selectCtategory',{category}).then((res)=>{
          if(res.status==200){
            // notify("Successfully added", true);
            console.log(res.data)
            // setcatname(res.data)
            setValue({categoryname:res.data.category,
                      category:res.data._id
            })
          }

        }).catch((err)=>{
          console.log(err)
          notify(" Something went wrong", false);
          setloading(false)

        })

      }

      const EditCategorys= async(e)=>{
         console.log('value')
         if(value.category.length>0)
         {
          seterror1("")
          
         }
         else{
         return seterror1("Select category")
         }
         if(!value.categoryname)
         {
          return seterror1("Enter category name")
         }
         seterror1("")
         let update=await axios.post('/api/Admin/UpdateCtategory',{value}).then((res)=>{
          if(res.status==200)
          {

            notify("Successfully updated", true);
            
            setValue({
              categoryname:'',
              category:''
        

            })
            reload()

          }
         }).catch((err)=>{
          console.log(err)
          notify(" Something went wrong", false);
          setloading(false)

        })

      }


     
     
  return (
    <div className="container pt-5  pageleft"><br />
        <h1>Add Consultants Category</h1><br />
        <div className='row'>
            <div className='col'>Add Categorys</div><div className='col'><input className="form-control" type="text"  value={value1.category}
                    onChange={(e) =>
                      setValue1({ ...value1, category: e.target.value })
                    }></input></div><div className="col">  <div className="col">
                    {!loading &&   <button className="btn btn-success" onClick={(e)=>AddCategorys(e)}>Add</button> }
                    {loading &&   <button className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button> }
                    </div></div>
        </div><br />
        <div className="row">
          <div className="col">
            <div className="col"><p style={{"color":'red'}}>{error?error:''}</p></div>
                  
          </div>

        </div>
 

        <br></br>

<hr/>
<h1>Edit Consultants Category</h1><br />
        
        <div className='row'>
            <div className='col'> <label className="labels">Category</label></div><div className='col'>
            <select
                  className="form-select"
                  value={value.category}
                  onChange={(e) => sabari(e)}
                >
                  <option value="" disabled selected>
                    Select your category
                  </option>
                  {category ? category : ""}
                </select>
            </div>
            <div className="col">

            </div>
        </div><br />
        {/* <div className='row'>
            <div className='col'> <label className="labels">sub Category</label></div><div className='col'>
            <select
                  className="form-select"
                  value={value.subcategory}
                  onChange={(e) =>
                    setValue({ ...value, subcategory: e.target.value })
                  }
                >
                  <option value="" disabled selected>
                    Select your sub category
                  </option>
                  {subcategory ? subcategory : ""}
                </select>
            </div>
        </div> */}

        <div className="row">
          <div className="col">
           New name
          </div>
          <div className="col">
          <input className="form-control" type="text"  value={value.categoryname}
                    onChange={(e) =>
                      setValue({ ...value, categoryname: e.target.value })
                    }></input>
               
          </div>
          <div className="col">
                <div className="col">
            {!loading &&   <button className="btn btn-success" onClick={(e)=>EditCategorys(e)}>Edit</button> }
            {loading &&   <button className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button> }
            </div>
          
          </div>
        </div>



        <br />
       
        
        <div className="row">
            <div className="col"><p style={{"color":'red'}}>{error1?error1:''}</p></div>
        </div>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
       
    </div>
  )
}
