import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./Pview.css";
import axios from "axios";
import { FaPhotoVideo, FaVideo } from "react-icons/fa";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
// import "./Edit";
// import { TimeLineExternal } from "../../../Components/Timeline/TimeLineExternal"
import { useNavigate, useSearchParams } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GetportExternal1 } from './GetportExternal1'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosSend } from 'react-icons/io'
import { GetOfferExternal } from "./GetOfferExternal";

import {
  StaticGoogleMap,
  Marker,
  Path,
} from 'react-static-google-map';

function Pview(props) {
  useEffect(() => {
    // props.Auth()
  }, [])

  const [expanded, setExpanded] = useState(false);
  const [loading, setloading] = useState(false)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let [searchParams, setSearchParams] = useSearchParams();
  const [Follower, setFollower] = useState(false)
  let Navigate = useNavigate()
  var Ac = searchParams.get("Ac_id")
  var idd = searchParams.get("Ac_id")
  var Acc = searchParams.get("Ac_id")

  const astyle = {
    'color':'#0a58ca',
    'textDecoration':'underline'
  }

  const enablechat = () => {
    document.getElementById('closebuttonforreportkgwfiuwsgfbvsdjbfuisea').click()
    Navigate('/Account')
}

  const [EmailRef, setEmailRef] = useState({
    email: [],
    subject: "",
    massage: "",

    id: Acc
  })
  const makeEmail = (x, Lorum) => {
    console.log(Lorum)
    console.log(x)
    x = document.getElementById(x)
    x.style.color = 'black'
    var val = x.value
    if (val.charAt(val.length - 1) === ' ') {
      var email = val.trim()
      email = email.replace(/ +/g, "");

      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        x.value = ''
        // return console.log(skill.length+fina)
        x.style.color = 'black'
        addEmail(email)
      }
      else {
        x.style.color = 'red'
      }
    }
    if (Lorum) {
      var email = val.trim()
      email = email.replace(/ +/g, "");

      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        x.value = ''
        // return console.log(skill.length+fina)
        x.style.color = 'black'
        addEmail(email)
      }
      else {
        x.style.color = 'red'
      }
    }
  }

  const notify = (message, stat) => {
    // console.log(message);
    // console.log(stat)

    if (stat == true) {
      // console.log("toasfted");
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (stat == false) {
      toast.error(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [Email, setEmail] = useState([])
  const [Arr, setArr] = useState([])
  var listItems = ''
  const [AllEmail, setAllEmail] = useState([])

  const addEmail = (vall) => {
    vall = vall.trim()
    var dep = AllEmail
    dep.push(vall)
    setAllEmail(dep)
    var x = React.createElement('div',
      { className: 'p-0 boXe px-2 alert  alert-dismissible fade show m-0 mb-1 me-1', role: 'alert' },
      [React.createElement('strong', {}, vall, <>&nbsp;&nbsp;&nbsp;</>,
        React.createElement('button', { onClick: (e) => removeEmail(vall), type: "button", className: "btn-close ms-5 Gbt p-0 pt-2 ", 'data-bs-dismiss': "alert", 'aria-label': "Close" }, ''),
      ),
      ]
    )
    Arr.push(x)
    listItems = Arr.map((myList) => {
      return <>{myList}</>;
    });
    setEmail(listItems)
  }

  useEffect(() => {
    setEmailRef({ ...EmailRef, email: AllEmail })
  }, [AllEmail])

  const removeEmail = (e) => {
    var index = AllEmail.indexOf(e)
    AllEmail.splice(index, 1);
    setEmailRef({ ...EmailRef, email: AllEmail })
  }



  const [submittrue, setsubmittrue] = useState(false)
  const SendRef = (e) => {
    setsubmittrue((prev) => !prev)
    console.log("haii")
    console.log(EmailRef)
    var status = false
    status = validation()
    if (status) {
      setloading(true)
      axios.post('/api/User/ReferVendor', {
        EmailRef
      }).then((res) => {
        console.log(res.data)
        //  alert("Refereed successfully !!!!!")
        if (res.status === 200) {
          document.getElementById('poweclose').click()
          notify("Referred successfully", true);
          setloading(false)
          setEmailRef({
            email: [],
            subject: "",
            massage: "",
            id: Acc
          })
          setAllEmail([])
          setEmail([])
        }

      }).catch((err) => {
        console.log(err)
        setloading(false)
        document.getElementById('poweclose').click()
        notify("Something went wrong !", false);

      })
    }
  }


  const [rateingfor, setrateingfor] = useState([])
  useEffect(() => {
    axios.post('/api/User/getportfolio', {
      Acc
    }).then((res) => {
      setrateingfor(res.data)
    }).catch((err) => {
      console.log(err)
    })
    FollowOrNot(idd);
  }, [])

  const FollowOrNot = (followme) => {
    try {
      axios
        .post("/api/User/followornot", { followme })
        .then((response) => {
          console.log("success");
          if (response.status === 200) {
            console.log("first");
            setFollower(true);
          }
        })
        .catch((res) => {
          console.log("Something went wrong");
        });
    } catch (er) { }
  };

  const follow = (e, id) => {
    var followme = id;
    axios
      .post("/api/User/follow", { followme })
      .then((response) => {
        console.log("success");
        if (response.status === 200) {
          setFollower(true);
        }
      })
      .catch((res) => {
        console.log("Something went wrong");
      });
  };

  const unfollow = (e, id) => {
    var followme = id;
    axios
      .post("/api/User/unfollow", { followme })
      .then((response) => {
        console.log("success");
        if (response.status === 200) {

          setFollower(false);
        }
      })
      .catch((res) => {
        console.log("Something went wrong");
      });
  };



  const addreating = (e, status) => {

    // console.log(id)
    if (e.target.checked) {
      var status = status
      axios.post('/api/User/rateingforcon', {
        status, idd
      }).then((res) => {

      }).catch((err) => {
        console.log(err)
      })
    } else {
      axios.delete('/api/User/rateingdelcon', {
        data: {
          status, idd
        }
      }).then((res) => {

      }).catch((err) => {
        console.log(err)
      })
    }
  }

  const communicate = (e) => {
    var Ac = searchParams.get("Ac_id")

    let id = Ac
    let dataSend = {
      id
    }
    console.log(id)
    axios.post('/api/User/communicate', { dataSend }).then((res) => {
      console.log(res)
      if (res.status === 200) {
        Navigate('/cubicles?consultantTrue=' + res.data)
        console.log(res)
      } else { Navigate('/cubicles?consultantTrue=' + res.data) }
    }).catch((res) => {
      console.log(res.response)
      if (res.response.status === 400) {
        // Navigate('/cubicles')
      } else if (res.response.status === 401) {
        // TOAST SOMETHING WRONG SAME PERSON
      }
    })
  }

  useEffect(() => {
    let id = Ac
    axios
      .post("/api/User/selselectpro", {
        id,
      })
      .then((res) => {
        if (res.status === 200) {
          document.getElementById(Ac).checked = true;
          // setFollower(true);
          setmappas(true)
          console.log(res.status)
        }
      })
      .catch((err) => {
        console.log(err);
      });


  }, []);


  const checkaeijfpi = () => {
    var id = Ac
    if (document.getElementById(id).checked) {
      axios
        .post("/api/User/selectpro", {
          id,
        })
        .then((res) => {
          setmappas(true)
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post("/api/User/disselectpro", {
          id,
        })
        .then((res) => {
          setmappas(false)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const [mappas, setmappas] = useState()

  const [Image, setImage] = useState(false);
  const [Video, setVideo] = useState([]);
  const [Media, setMedia] = useState([]);
  const [getval, setGetval] = useState(false);
  const [dopper, setDooper] = useState(false);
  const [file, setFile] = useState([]);
  const [filedel, setFiledel] = useState([]);
  const [forume, setforume] = useState();

  const [first, setfirst] = useState(false)
  useEffect(() => {
    var accid = Acc
    axios.post('/api/User/rateingforconCheck', {
      accid
    }).then((res) => {
      console.log(res.data)
      setfirst(res.data)
    }).catch((err) => {
      console.log(err)
    })
  }, [])

  const Clickhandler = (cont) => {
    console.log(cont);
    if (cont == "image") {
      document.getElementById("image").click();
    } else {
      document.getElementById("video").click();
    }
  };

  function keyGen() {
    var length = 5;
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }




  useEffect(() => {
    axios
      .post("/api/User/ProProfileexternal", {
        Ac
      })
      .then((res) => {
        setGetval(res.data[0]);
        setEmailRef((prev) => ({ ...prev, sub: res.data[0].string2[0].sub }))
        console.log(res.data[0]);

        // console.log(res.data[0])
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);






  function cater(e) {
    // console.log(e.target.value)
    setforume(e.target.value);
  }
  useEffect(() => {
    console.log(forume);
  }, [forume]);

  function jvur(e, fr) {
    document.getElementById(fr).click();
  }

  function fastandf(e, content, ee, elem, id) {
    console.log(elem);
    var pit = document.getElementById(content).value;
    console.log(content)
    // var acer = document.getElementById(eyfye).files;
    console.log(pit);
    // console.log(acer);
    var data = {
      content: pit,
      deleted: filedel,
      elem,
      id
    };
    var m = JSON.stringify(data);

  }


  useEffect(() => {

    try {
      if (first) {
        if (first.good)
          document.getElementById('GoodVendor').checked = true

        if (first.high) {
          document.getElementById('HRvendor').checked = true

        }

      }
    } catch (error) {
      console.log(error)
    }
  }, [first])

  const [referCount, setreferCount] = useState(false)
  const [yeser, setyeser] = useState(false)

  useEffect(() => {
    var ids = Acc
    axios.post('/api/User/ReferCount', {
      ids
    }).then((res) => {
      console.log(res.data)
      if (res.data === '0' || res.data === 0) {
        return
      } else if (res.data === '1' || res.data === 1) {

        setreferCount(res.data)
        setyeser('time')
      }
      else {

        setreferCount(res.data)
        setyeser('times')
      }
    }).catch((err) => {
      console.log(err)
    })

    return () => {

    }
  }, [])


  const [error, seterror] = useState(false)
  const validation = () => {
    if (EmailRef.email.length > 0) {
      seterror("")
    } else {
      return seterror('Please add a Email Id')
    }
    if (EmailRef.massage.length > 0) {
      seterror("")
    } else {
      return seterror('PLEASE ENTER Message')
    }
    return true
  }






  const [Content, setContent] = useState(
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );

  useEffect(() => {
    if (submittrue) {
      validation()
    }
  })




  const [Final, setFinal] = useState();
  const [FinalVideo, setFinalVideo] = useState();

  function PortfolioHandler() {
    if (Video.length > 0) {
      var V_initId = Video[0].lastModified;
      var V_value = Object.values(Video);

      var V_r = V_value.length
        ? V_value.map((V_pos) => {
          if (V_pos.lastModified == V_initId && Media.length < 1) {
            return (
              <div
                key={V_pos.lastModified}
                id={V_pos.lastModified}
                className="itemx carousel-item active"
              >
                <video className="img-thumbnaili" controls>
                  <source
                    src={URL.createObjectURL(V_pos)}
                    height="100%"
                    width="100%"
                  />
                </video>
              </div>
            );
          } else {
            return (
              <div
                key={V_pos.lastModified}
                id={V_pos.lastModified}
                className="itemx carousel-item "
              >
                <video className="img-thumbnaili" controls>
                  <source
                    src={URL.createObjectURL(V_pos)}
                    height="100%"
                    width="100%"
                  />
                </video>
              </div>
            );
          }
        })
        : null;

      setFinalVideo(V_r);
    }
    if (Media.length > 0) {
      var initId = Media[0].lastModified;
      var value = Object.values(Media);

      var r = value.length
        ? value.map((pos) => {
          if (pos.lastModified == initId && Video.length < 1) {
            return (
              <div
                key={pos.lastModified}
                id={pos.lastModified}
                className="itemx carousel-item active"
              >
                <img
                  src={URL.createObjectURL(pos)}
                  className="img-thumbnaili"
                  alt="..."
                />
              </div>
            );
          } else {
            return (
              <div
                key={pos.lastModified}
                id={pos.lastModified}
                className="itemx carousel-item "
              >
                <img
                  src={URL.createObjectURL(pos)}
                  className="img-thumbnaili"
                  alt="..."
                />
              </div>
            );
          }
        })
        : null;

      setFinal(r);
    }
  }

  useEffect(() => {
    if (Media.length > 0) {
      PortfolioHandler();
    }
    if (Video.length > 0) {
      PortfolioHandler();
    }
  }, [Media, Video]);

  function changeDp(file) {
    var DP = document.getElementById("DP");
    DP.src = URL.createObjectURL(file);
    console.log(DP.src);
    console.log("DP.src");


  }

  const [type, settype] = useState()
  useEffect(() => {
    if (getval.formType === 1) {
      settype(true)
    } else if (getval.formType === 2) {
      settype(false)
    }
  }, [getval])


  const [first8, setfirst8] = useState(false)

  const [sabari, setsabari] = useState('')
  const [sabarierr, setsabarierr] = useState('')

  const reprtsabari = () => {
    if (sabari.length === 0) {
      return setsabarierr('Please enter a reason')
    }
    else {
      axios.post('api/User/reportabuse', {
        sabari, Acc
      }).then((res) => {
        document.getElementById('closebuttonforreport').click()
        notify("Reported", true);
      }).catch((err) => {
        console.log(err)
        document.getElementById('closebuttonforreport').click()
        notify("Something went wrong !", false);
      })
    }

  }




  const [CommunicationStatus, setCommunicationStatus] = useState('Loading')

  useEffect(() => {

    axios.post('/api/User/CommunicationStatus', { id: Acc }).then((res) => {
      console.log(res)
      setCommunicationStatus(res.data.Chat)
    }).catch((err) => {
      console.log(err)
    })

    console.log(CommunicationStatus)
    return () => {

    }
  }, [])

  return (
    <>
      {/* <NavBar /> */}
      <div>
        <div className="container emp-profile border ">

          <div className="row">
            <div className="d-none d-md-block mb-3 mt-2">
              <div className=" row justify-content-center  ">
                {getval.WomanOwned &&
                  <div className="col-12 col-md-auto"><img width="30" height={30} src="/wpower.png" alt='w' />&nbsp;WOMAN ENTREPRENEUR</div>}
                {getval.verified &&
                  <div className="col-12 col-md-auto"> <span className="puskaran2 notranslate">PRO-</span>&nbsp;<span className="notranslate">AUTHENTIC VENDOR</span></div>}

              </div>
            </div>
            <div className="d-block d-md-none mb-3 mt-3">
              <div className="row  ">
                {getval.WomanOwned &&
                  <div className="col-12 "><center><img width="30" height={30} src="/wpower.png" alt='w' />&nbsp;WOMAN ENTREPRENEUR</center></div>}
                {getval.verified &&
                  <div className="col-12 "> <center><span className="puskaran2 notranslate">PRO-</span>&nbsp;<span className="notranslate">AUTHENTIC VENDOR</span></center></div>}

              </div>
            </div>
            <div className="col-md-4">
              <div className="profilee-img">
                <img
                  id="DP"
                  className="img-fluid"
                  width="100px"
                  height="100px"
                  src={getval.link}
                  alt=""
                />
                {/* <div className="file btn btn-lg btn-primary">
                  Change Photo
                  <input onChange={(e)=>changeDp(e.target.files[0])} type="file" name="ProfilePic" accept="image/*"/>
                </div> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="profile-head mt-3">
                <div className="d-none d-md-block"> <h5>{getval.pname ? getval.pname.toUpperCase() : ''}</h5></div>
                <div className="d-block d-md-none"> <center><h5>{getval.pname ? getval.pname.toUpperCase() : ''}</h5></center></div>
                <div className="row">
                  <div className="col-md-9">
                    <div className="d-none d-md-block">
                      <h6>
                        {getval.string ? getval.string[0].category : ""} | {getval.string2 ? getval.string2[0].sub : ''}
                        <br />
                      </h6>
                    </div>
                    <div className="d-block d-md-none">
                      <center> <h6>
                        {getval.string ? getval.string[0].category : ""} | {getval.string2 ? getval.string2[0].sub : ''}
                        <br />
                      </h6></center>
                    </div>
                  </div>
                  <div className="col-md-3">
                    {/* <Link to="/My_profile_productstore/Edit_profile_p">
                      <input
                        type="submit"
                        className="profile-edit-btn"
                        name="btnAddMore"
                        value="Edit Profile"
                      />
                    </Link> */}
                  </div>
                </div> 
                <div className="row pe-0">
                  <div className="col pe-0">
                    {CommunicationStatus === true ? localStorage.getItem("Chat") === 'false' ?
                      <>

                        <div className="d-none d-md-block">
                          <input
                            type="submit"
                            className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                            style={{ 'borderRadius': '25px' }}
                            name="btnAddMore"
                            value="Communicate"
                            data-bs-toggle="modal"
                            data-bs-target={`${localStorage.getItem("Chat") === 'false' ? '#turnonchat23423' : '#communi'}`}
                          />
                        </div>
                        <div className="d-block d-md-none">
                          <center>
                            <input
                              type="submit"
                              className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                              style={{ 'borderRadius': '25px' }}
                              name="btnAddMore"
                              value="Communicate"
                              data-bs-toggle="modal"
                              data-bs-target={`${localStorage.getItem("Chat") === 'false' ? '#turnonchat23423' : '#communi'}`}
                            />
                          </center>
                        </div>
                      </>
                      :
                      <>
                        <div className="d-none d-md-block">
                          <input
                            type="button" onClick={(e) => { communicate() }}
                            className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                            style={{ 'borderRadius': '25px' }}
                            name="btnAddMore"
                            value="Communicate"
                          />
                        </div>
                        <div className="d-block d-md-none">
                          <center>
                            <input
                              type="button" onClick={(e) => { communicate() }}
                              className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                              style={{ 'borderRadius': '25px' }}
                              name="btnAddMore"
                              value="Communicate"
                            />
                          </center>
                        </div>
                        </>
                   :<></> }
                  </div>
                </div>
                <div className="row">
                  <div className="form-check col-7 col-md-8 col-lg-6 d-flex justify-content-start mb-3 ms-3 pe-0">
                    <input
                      className="form-check-input float-end checkboxpro me-3 mt-3"
                      type="checkbox"
                      value=""
                      id={Ac}
                      onClick={(e) => checkaeijfpi(e)}
                    />
                    <span className="iorughoisrhgiohs">{!mappas ? 'Short-list this Vendor' : 'Short-listed'}</span>
                  </div>
                  <div className="col-3 col-md-3 col-lg-5 d-none">
                    {!Follower ? (<>
                      <button
                        type="button"
                        className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                        style={{ 'borderRadius': '25px' }}
                        onClick={(e) => follow(e, idd)}
                      >
                        Follow
                      </button></>
                    ) : (<>
                      <button
                        type="button"
                        className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                        style={{ 'borderRadius': '25px' }}
                        onClick={(e) => unfollow(e, idd)}
                      >
                        UnFollow
                      </button></>
                    )}
                  </div>
                </div>
                {/* <p className="proile-rating">
                  About: {" "}
                  <span>
                    I'm Yuki. Full Stack Designer I enjoy creating user-centric,
                    delightful and human experiences.I'm Yuki.
                  </span>
                </p> */}
                {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="home-tab"
                      data-toggle="tab"
                      
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                      onClick={() => activate("home-tab")}
                    >
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link "
                      id="profile-tab"
                      data-toggle="tab"
                     
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      onClick={() => activate("profile-tab")}
                    >
                      Timeline
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link "
                      id="Portfolio-tab"
                      data-toggle="tab"
                     
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      onClick={() => activate("Portfolio-tab")}
                    >
                      Catalogue
                    </a>
                  </li>
                </ul> */}

              </div>
            </div>

            <div className="modal fade" id="reportthismodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Report this Vendor </h5>
                    <button type="button" id="closebuttonforreport" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    {/* <h5>Are you sure you want to report this consultant&nbsp;?</h5><br /> */}
                    <p>Please BE INFORMED that you must Report Abuse about a Vendor ONLY for a genuine valid reason with evidence.</p><p>Fake / False reporting about a member as an act of prank will result in deactivation of your membership without refund.</p>
                    <textarea className="form-control" onChange={(e) => setsabari(e.target.value)}></textarea>
                    <p className="text-danger">{sabarierr ? sabarierr : ''}</p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn pro-spaces-button3" onClick={reprtsabari}>Report</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="turnonchat23423" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">You need to Activate Chat to communicate !</h5>
                    <button type="button" id="closebuttonforreportkgwfiuwsgfbvsdjbfuisea" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    {/* <h5>Are you sure you want to report this consultant&nbsp;?</h5><br /> */}
                    <p>Your One-to-One Chatting feature is currently Disabled.</p>
                    <p style={astyle} onClick={enablechat} to='/Account' >Enable Chatting now</p>

                  </div>
                  <div className="modal-footer">
                    {/* <button type="button" className="btn pro-spaces-button3" onClick={reprtsabari}>Report</button> */}
                  </div>
                </div>
              </div>
            </div>
            <center className='pt-4'>
              <div className="" style={{ 'maxWidth': '800px', 'textAlign': 'left' }}>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                      <b>{getval ? type ? 'Catalogue of Products' : 'Catalogue of Projects' : 'Catalogue'}</b>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <>
                        <div className="row">

                          <div className="col-6 d-none">
                            <a className="card-title sgknriog" data-bs-toggle="modal" data-bs-target="#exampleModal">Rate this Vendor</a>
                          </div>

                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="card-text">Good</p>
                          </div>
                          <div className="col-6">
                            <p className="card-text">{getval.Status1 ? getval.Status1.length : ''}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="card-text">Highly Recommended (HR)</p>
                          </div>
                          <div className="col-6">
                            <p className="card-text">{getval.Status2 ? getval.Status2.length : ''}</p>
                          </div>
                        </div>
                        <div className="row mt-2">

                          <div className="col-6 d-none">
                            <a className="card-title sgknriog" data-bs-toggle="modal" data-bs-target="#examplewkligheModal">Refer this Vendor</a>
                          </div>

                        </div>
                        {yeser ?
                          <div className="row">
                            <div className="col-6">
                              <p className="card-text"> Has been Referred</p>
                            </div>
                            <div className="col-6">
                              <p className="card-text">{referCount ? referCount : ''} {yeser ? yeser : ''}</p>
                            </div>
                          </div> : <></>}
                        <br />
                        <div className="row d-none">
                          <a href="" data-bs-toggle="modal" data-bs-target="#reportthismodal">Report this Vendor</a>
                        </div>
                        <hr />
                        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Rate this Vendor </h5>
                                <button type="button" id="closebuttonforreport" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div className="modal-body">


                                <div>
                                  <div className="form-check  ms-5 m-3">
                                    <input className="form-check-input" type="checkbox" value="" id="GoodVendor" onChange={(e) => addreating(e, 1)} defaultChecked={first ? first.good ? true : false : false} />
                                    <label className="form-check-label" htmlFor="GoodVendor" >
                                      Good
                                    </label>
                                  </div>
                                  <div className="form-check ms-5 m-3">
                                    <input className="form-check-input" type="checkbox" value="" id="HRvendor" onChange={(e) => addreating(e, 2)} defaultChecked={first ? first.high ? true : false : false} />
                                    <label className="form-check-label" htmlFor="HRvendor">
                                      Highly Recommended (HR)
                                    </label>
                                  </div>
                                </div>
                              </div>









                            </div>
                          </div>
                        </div>
                        <div className="modal fade" id="examplewkligheModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Refer this Vendor </h5>
                                <button type="button" id="closebuttonforreport" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div className="modal-body">

                                <div>

                                  {/* <label className="form-label" htmlFor="flexCheckDefault1222">
                  Refer
                </label> */}
                                  <div className="col-md-12 p-1 mt-2">
                                    <label className="form-label">Email Id *</label>
                                    <p>Enter the email ids of the persons to whom you would like to refer this vendor</p>
                                    {/* <p style={{'fontSize':'10px'}} className="text-start my-2">L</p> */}

                                    <div className="row ps-4">

                                      {Email ? Email : ''}

                                    </div>
                                  </div>
                                  <div className="col-md-12 mt-2">
                                    <div className="input-group w-100">
                                      <input maxLength='' className='form-control' id="skills" onChange={(e) => makeEmail(e.target.id)} type="text" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                      <button className="btn pro-spaces-button" onClick={(e) => makeEmail('skills', true)} type="button" id="button-addon2">Add</button>
                                    </div>
                                  </div>

                                  <div className="">
                                    <label>Subject</label>
                                    <input type="text" className="form-control" value="PROchure Vendor Referral" onChange={(e) => setEmailRef({ ...EmailRef, subject: e.target.value })} disabled></input>
                                  </div>
                                  <br></br>
                                  <div className="">
                                    <label>Message *</label>
                                    <textarea className="form-control" value={EmailRef.massage} onChange={(e) => setEmailRef({ ...EmailRef, massage: e.target.value })}></textarea>
                                    {/* <input type="text" className="form-control" onChange={(e)=>setEmailRefer({...EmailRefer,massage:e.target.value})} ></input> */}
                                  </div>
                                  <br />

                                  <p className="text-danger">{error ? error : ''}</p>
                                  <div className="button-section d-flex justify-content-end">
                                    {/* <button className="button add" type="button" onClick={() => addFormFields()}>Add</button> */}
                                    {!loading &&
                                      <button className="btn pro-spaces-button3" type="submit" onClick={(e) => SendRef()}><IoIosSend /></button>
                                    }
                                    {loading &&
                                      <button className="btn pro-spaces-button3" type="submit" onClick={(e) => SendRef()} disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

                                      </button>
                                    }
                                  </div>


                                </div>
                              </div>



                            </div></div></div>
                      </>
                    </Typography>

                    <Typography>

                      <GetportExternal1 Acc={Acc} sab={type} />

                    </Typography>



                  </AccordionDetails>
                </Accordion>
                {getval ? getval.Type === 'Product' ?

                  <Accordion expanded={expanded === 'panel332'} onChange={handleChange('panel332')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3bh-content"
                      id="panel3bh-header"
                    >
                      <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        <b>Exclusive Offers</b>
                      </Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <GetOfferExternal Acc={Acc} sab={type} />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  : <></> : <></>
                }
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                      <b>Business Information</b>
                    </Typography>

                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {getval.brandName ?
                        <div className="row" >
                          <div className="col-md-6">
                            <span>Brand Name</span>
                          </div>
                          <div className="col-md-6 ">
                            <label style={{ 'color': 'var(--blue)' }}>{getval.brandName ? getval.brandName : ''}</label>
                          </div>
                        </div>
                        : <></>}
                      {getval.who ?
                        <div className="row" >
                          <div className="col-md-6">
                            <span>Type of Vendor / Business</span>
                          </div>
                          <div className="col-md-6 address">
                            <label style={{ 'color': 'var(--blue)' }}> {getval.who
                              ? getval.who == "Both"
                                ? "Wholesalers and Retailers"
                                : getval.who
                              : ""}</label>
                          </div>
                        </div>
                        : <></>}
                      {getval.TypeofStore ?
                        <div className="row" >
                          <div className="col-md-6">
                            <span>Type of Entity</span>
                          </div>
                          <div className="col-md-6 address">
                            <label style={{ 'color': 'var(--blue)' }}> {getval.TypeofStore
                              ? getval.TypeofStore == "Both"
                                ? "Physical and E-commerce"
                                : getval.TypeofStore
                              : ""}</label>
                          </div>
                        </div>
                        : <></>}
                      {getval.yearofb ?
                        <div className="row" >
                          <div className="col-md-6">
                            <span>Year of Business Inception</span>
                          </div>
                          <div className="col-md-6 ">
                            <label style={{ 'color': 'var(--blue)' }}>{getval.yearofb ? getval.yearofb : ''}</label>
                          </div>
                        </div>
                        : <></>}
                      {getval.licence ?
                        <div className="row" >
                          <div className="col-md-6">
                            <span>Have Trade Licence</span>
                          </div>
                          <div className="col-md-6 ">
                            <label style={{ 'color': 'var(--blue)' }}>{getval.licence ? getval.licence : ''}</label>
                          </div>
                        </div>
                        : <></>}
                      {getval.gst ?
                        <div className="row" >
                          <div className="col-md-6">
                            <span>GST registered</span>
                          </div>
                          <div className="col-md-6 ">
                            <label style={{ 'color': 'var(--blue)' }}>{getval.gst ? getval.gst : ''}</label>
                          </div>
                        </div>
                        : <></>}
                    
                      {getval.website ?
                        <div className="row" >
                          <div className="col-md-6">
                            <span>Website</span>
                          </div>
                          <div className="col-md-6 ">
                            <label style={{ 'color': 'var(--blue)' }}>{getval.website ? getval.website : ''}</label>
                          </div>
                        </div>
                        : <></>}
                         {getval.working_days_1?
          <div className="row">
          <div className="col-md-6">
            <span>Working Days</span>
          </div>
          <div className="col-md-6 address">
            <label style={{'color':'var(--blue)'}}>{getval.working_days_1 ? getval.working_days_1+" to " : ""} {getval.working_days_2 ? getval.working_days_2 : ""} </label>
          </div>
          </div>:<></>}
          {getval.working_hour_1?
          <div className="row">
          <div className="col-md-6">
            <span>Working Hours</span>
          </div>
          <div className="col-md-6 address">
          <label style={{'color':'var(--blue)'}}>{getval.working_hour_1 ? getval.working_hour_1+" to " : ""} {getval.working_hour_2 ? getval.working_hour_2 : ""} </label>

          </div>
          </div>:<></>}
          {getval.working_leave?
          <div className="row">
          <div className="col-md-6">
            <span>Weekly Off</span>
          </div>
          <div className="col-md-6 address">
          <label style={{'color':'var(--blue)'}}>{getval.working_leave ? getval.working_leave: ""} </label>
          </div>
          </div>:<></>}
          {getval.address ?
                        <div className="row" >
                          <div className="col-md-6">
                            <span>Address</span>
                          </div>
                          <div className="col-md-6 ">
                            <label style={{ 'color': 'var(--blue)' }}>{getval.address ? getval.address : ''}</label>
                          </div>
                        </div>
                        : <></>}
                      {getval.brandName ?
                        <div className="row" >
                          <div className="col-md-6">
                            <span></span>State, City
                          </div>
                          <div className="col-md-6 ">
                            <label style={{ 'color': 'var(--blue)' }}> {getval.state}, {getval.city}</label>
                          </div>
                        </div>
                        : <></>}
                      {getval.pincode ?
                        <div className="row" >
                          <div className="col-md-6">
                            <span>Pin code</span>
                          </div>
                          <div className="col-md-6 ">
                            <label style={{ 'color': 'var(--blue)' }}>{getval.pincode ? getval.pincode : ''}</label>
                          </div>
                        </div>
                        : <></>}
                        
                    </Typography>
                    {getval.Location?
          <Typography>
             <p>Location Map</p>
            <div className="container">
            <a target="_blank" href={`http://maps.google.com/maps?z=18&q=${getval.Location_Coordinates.lat},${getval.Location_Coordinates.lng}`}>
            <StaticGoogleMap zoom="15" size="600x400" className="img-fluid" apiKey={process.env.REACT_APP_MAP_KEY}>
                <Marker location={`${getval.Location_Coordinates.lat},${getval.Location_Coordinates.lng}`} color="blue" label="P" />
              </StaticGoogleMap>
              </a>
            </div>
          </Typography>
          :<></> }
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                      <span><b> Personal / Entity Information</b><br />(of the Proprietor / Business Owner)</span>
                    </Typography>

                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="row" >
                        <div className="col-md-6">
                          <span>Vendor’s Name</span>
                        </div>
                        <div className="col-md-6 ">
                          <label style={{ 'color': 'var(--blue)' }}>{getval.firstname ? getval.firstname.toUpperCase() : ''} {getval.lastname ? getval.lastname.toUpperCase() : ''}</label>

                        </div>
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}><b>Contact Information</b></Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {getval.contact ?
                        <div className="row" >
                          <div className="col-md-6">
                            <span>Contact Number 1</span>
                          </div>
                          <div className="col-md-6 ">
                            <label style={{ 'color': 'var(--blue)' }}>{getval.contact ? getval.contact : ''}</label>
                          </div>
                        </div>
                        : <></>}
                      {getval.contact2 ?
                        <div className="row" >
                          <div className="col-md-6">
                            <span>Contact Number 2</span>
                          </div>
                          <div className="col-md-6 ">
                            <label style={{ 'color': 'var(--blue)' }}>{getval.contact2 ? getval.contact2 : ''}</label>
                          </div>
                        </div>
                        : <></>}
                      {getval.email ?
                        <div className="row" >
                          <div className="col-md-6">
                            <span>Email Id</span>
                          </div>
                          <div className="col-md-6 ">
                            <label style={{ 'color': 'var(--blue)' }}>{getval.email ? getval.email : ''}</label>
                          </div>
                        </div>
                        : <></>}
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                {/* <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography sx={{ width: '100%', flexShrink: 0 }}><b>Timeline {first8?first8>1?'('+first8+ ' posts)':'('+first8+ ' post)':''}</b></Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                <TimeLineExternal acid={Acc} setfirst8={setfirst8} />
                </Typography>
              </AccordionDetails>
            </Accordion>
            {getval.followers?getval.followers.length==0?<></>
: <Accordion expanded={expanded === 'panel7'} onChange={handleChange('pane20')}>
<AccordionSummary
 
  aria-controls="panel4bh-content"
  id="panel4bh-header"
>
  <Typography sx={{ width: '100%', flexShrink: 0 }}><b>Followers {getval.followers?getval.followers.length:''}</b></Typography>
</AccordionSummary>

</Accordion>:''} */}

              </div></center>
          </div>
          <div className="row">
            <div className="col-md-4">

            </div>
            <div className="col-md-8">

            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default Pview;
