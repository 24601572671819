import React from 'react'

export  function SpinnerBig() {
    var loader = {
        width: '5rem',
        height: '5rem',
        color:'var(--blue)'
    }

  return (
    <div className="d-flex justify-content-center align-items-center " style={{'height':'calc(100vh - 70px)'}} > <div style={loader} className="spinner-border " ></div></div>
  )
}

export  function SpinnerSmall() {
    var loader = {
       
        color:'var(--blue)'
    }

  return (
    <div className="d-flex justify-content-center align-items-center " style={{'height':'calc(100vh - 70px)'}} > <div style={loader} className="spinner-border " ></div></div>
  )
}

export function SpinnerSmallTransparent() {
  var loader = {
    color: "var(--blue)",
  };
  return (
    <div
      className="d-flex justify-content-center align-items-center "
      style={{ 
        "z-index": "1491",
      "overflow": "hidden",
      "height": "100%",
      "position": "absolute",
      "right": "0",
      "top": "0",
      
      "background": "rgb(33 37 41 / 41%)",
      "width": "100%",
    }}
    >
      {" "}
      <div style={loader} className="spinner-border "></div>
    </div>
  );
}
