import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { TestEditor } from '../TestEditor';
import { Tableverticals } from './Tableverticals';
import { notify } from '../../Utility/notify';
import axios from 'axios';
import { keyGen } from '../../Utility/helper';

export const Jobsectionselectbox = [
  {
    category: 'Marketing',
    subcategory: ['Marketing Executive', 'Marketing Head'],
  },
  {
    category: 'Software Engineer',
    subcategory: [
      'Project Manager',
      'System Engineer',
      'Software Developer',
      'Software Tester',
    ],
  },
];
export const Jobopportunities = () => {
  const [opperins, setopperins] = useState({
    JobSection: '',
    JobSubSection: '',
    JobTitle: '',
    JobDescription: '',
    Qualfication: '',
    Skills: '',
    Skills_NT: '',
    OtherAttributes: '',
    Message: '',

    Experience: '',
    Salary: '',
    // WorkJoblocation: '',
    Vacancies: [],
  });

  const submit = (e) => {
    console.log(opperins);
    e.preventDefault();

    handleClose();

    axios
      .post('/jobroute/jobconnectinsert', opperins)
      .then((res) => {
        console.log();
        notify('Vacancy Added Successfully', true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        notify('Something went wrong', false);

        console.log(err);
      });
  };

  const [Submit2, setSubmit2] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [addmore, setAddmore] = useState(['add']);

  const addmorecontroller = (index, value, state) => {
    if (state) {
      let temp = [...opperins.Vacancies];
      temp[index] = value;
      setopperins({ ...opperins, Vacancies: temp });
    } else {
      let temp = [...opperins.Vacancies];
      let tempkey = [...addmore];
      temp.splice(index, 1);
      tempkey.splice(index, 1);
      setopperins({ ...opperins, Vacancies: temp });
      setAddmore(tempkey);
    }
  };

  return (
    <>
      <div className="bg-light">
        <div className="page-breadcrumb ">
          <div className="d-flex justify-content-between align-items-center text-dark py-3 px-2">
            <h4 className="page-title text-dark">Opportunites</h4>
            <button className=" btn pro-spaces-button" onClick={handleShow}>
              Add Vacancy
            </button>
          </div>
        </div>

        <Tableverticals />
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <form
          onSubmit={(e) => submit(e)}
          novalidate
          className={Submit2 ? 'was-validated' : ''}
        >
          <Modal.Body>
            {' '}
            <div className="">
              <label htmlhtmlFor="inputsubject">Job Category</label>
              {/* <select
                required
                class="form-select"
                aria-label="Default select example"
                onChange={(e) => {
                  setopperins({ ...opperins, JobSection: e.target.value });
                }}
              >
                <option></option>
                {Jobsectionselectbox.map((element) => {
                  return <option>{element.category}</option>;
                })}s
                
              </select> */}
              <input
                type="text"
                className="form-control mt-1 text-uppercase"
                style={{ textTransform: 'uppercase' }}
                onChange={(e) => {
                  setopperins({
                    ...opperins,
                    JobSection: e.target.value.toUpperCase(),
                  });
                }}
              />
              <div class="invalid-feedback">PLEASE SELECT Job Category</div>
            </div>
            <div className="pt-4">
              <label htmlhtmlFor="inputsubject">Job Title</label>
              {/* <select
                required
                class="form-select"
                aria-label="Default select example"
                onChange={(e) => {
                  setopperins({ ...opperins, JobSubSection: e.target.value });
                }}
              >
                <option></option>
                {Jobsectionselectbox.map((element) => {
                  console.log(element);
                  if (element.category === opperins?.JobSection) {
                    return element.subcategory.map((elem) => {
                      return <option>{elem}</option>;
                    });
                  }
                })}
              </select> */}
              <input
                type="text"
                className="form-control mt-1 text-uppercase"
                style={{ textTransform: 'uppercase' }}
                onChange={(e) => {
                  setopperins({
                    ...opperins,
                    JobSubSection: e.target.value.toUpperCase(),
                  });
                }}
              />
              <div class="invalid-feedback">PLEASE SELECT Job Title</div>
            </div>
            <div className="pt-4">
              <label htmlhtmlFor="inputname">Job Highlights </label>
              <TestEditor
                value={opperins.JobTitle}
                onChange={(e) => {
                  setopperins({ ...opperins, JobTitle: e });
                }}
              />

              {/* <div class="invalid-feedback">PLEASE ENTER Job Role</div> */}
            </div>{' '}
            <div className="pt-4">
              <label htmlhtmlFor="inputname">Key Skills (Technical)</label>

              {addmore.map((elem, index) => {
                return (
                  <Vaccanciesaddmore
                    key={elem}
                    onChange={addmorecontroller}
                    value={opperins}
                    index={index}
                  />
                );
              })}
              <div className="pt-2">
                <button
                  className="btn btn-outline-primary "
                  type="button"
                  onClick={(e) => setAddmore([...addmore, keyGen()])}
                >
                  ADD MORE
                </button>
              </div>
            </div>
            <div className="pt-4">
              <label className="pb-2" htmlhtmlFor="inputname">
                Detailed Job Profile / Description *
              </label>
              <TestEditor
                value={opperins.JobDescription}
                onChange={(e) => {
                  setopperins({ ...opperins, JobDescription: e });
                }}
              />

              <div class="invalid-feedback">PLEASE ENTER Job Profile</div>
            </div>
            <div className="pt-4">
              <label htmlhtmlFor="inputname">Skills (Non-Technical)</label>

              <TestEditor
                value={opperins.Skills_NT}
                onChange={(e) => {
                  setopperins({ ...opperins, Skills_NT: e });
                }}
              />
              <div class="invalid-feedback">PLEASE ENTER Skills</div>
            </div>
            <div className="pt-4">
              <label htmlhtmlFor="inputname">Other Attributes </label>
              <input
                // required
                type="text"
                className="form-control mt-1"
                id="name"
                name="name"
                onChange={(e) => {
                  setopperins({ ...opperins, OtherAttributes: e.target.value });
                }}
              />
              {/* <div class="invalid-feedback">PLEASE ENTER Job Role</div> */}
            </div>
            {/* <div className="pt-4">
              <label htmlhtmlFor="inputname">Work Experience </label>
              <select
                class="form-select"
                aria-label="Default select example"
                onChange={(e) => {
                  setopperins({ ...opperins, Experience: e.target.value });
                }}
              >
                <option></option>
                <option value="Any">Any</option>

                <option value="Intern">Intern</option>
                <option value="Fresher">Fresher</option>
                <option value="0-1 year">0-1 year</option>
                <option value="1-2 years">1-2 years</option>
                <option value="2-3 years">2-3 years</option>
                <option value="3-4 years">3-4 years</option>
                <option value="4-5 years">4-5 years</option>
                <option value="5+ years">5+ years</option>
              </select>
              <div class="invalid-feedback">PLEASE SELECT Experience</div>
            </div> */}
            <div className="pt-4">
              <label htmlhtmlFor="inputname">
                Least Educational Qualification
              </label>
              <input
                type="text"
                className="form-control mt-1"
                id="name"
                name="name"
                onChange={(e) => {
                  setopperins({ ...opperins, Qualfication: e.target.value });
                }}
              />
              <div class="invalid-feedback">
                PLEASE ENTER Least Educational Qualification
              </div>
            </div>
            <div className="pt-4">
              <label htmlhtmlFor="inputname">Message</label>
              <textarea
                type="text"
                className="form-control mt-1"
                id="name"
                name="name"
                rows={5}
                onChange={(e) => {
                  setopperins({ ...opperins, Message: e.target.value });
                }}
              />
              <div class="invalid-feedback">PLEASE ENTER Message</div>
            </div>
            {/* <div className="pt-4">
              <label htmlhtmlFor="inputname">Job Location</label>
              <input
                type="text"
                className="form-control mt-1"
                id="name"
                name="name"
                onChange={(e) => {
                  setopperins({ ...opperins, WorkJoblocation: e.target.value });
                }}
              />
              <div class="invalid-feedback">PLEASE ENTER Job Location</div>
            </div> */}
            {/* <div className="pt-4">
              <label htmlhtmlFor="inputname">Joining Date </label>
              <input
                type="date"
                className="form-control  mt-1"
                id="name"
                name="name"
                onChange={(e) => {
                  setopperins({ ...opperins, JoiningDate: e.target.value });
                }}
              />
              <div class="invalid-feedback">PLEASE SELECT Joining Date</div>
            </div> */}
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="primary"
              onClick={(e) => {
                setSubmit2(true);
              }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export const Vaccanciesaddmore = ({ onChange, value, index }) => {
  const [first, setfirst] = useState({
    WorkExperience: value?.Vacancies[index]?.WorkExperience
      ? value?.Vacancies[index]?.WorkExperience
      : '',
    EmploymentType: value?.Vacancies[index]?.EmploymentType
      ? value?.Vacancies[index]?.EmploymentType
      : '',
    WorkMode: value?.Vacancies[index]?.WorkMode
      ? value?.Vacancies[index]?.WorkMode
      : '',
    Number: value?.Vacancies[index]?.Number
      ? value.Vacancies[index]?.Number
      : '',
    JobRole: value?.Vacancies[index]?.JobRole
      ? value?.Vacancies[index]?.JobRole
      : '',
    SalaryOffered: value?.Vacancies[index]?.SalaryOffered
      ? value?.Vacancies[index]?.SalaryOffered
      : '',
    Gender: value?.Vacancies[index]?.Gender
      ? value.Vacancies[index]?.Gender
      : '',
    Location: value?.Vacancies[index]?.Location
      ? value?.Vacancies[index]?.Location
      : '',
    JoiningDateStart: value?.Vacancies[index]?.JoiningDateStart
      ? value?.Vacancies[index]?.JoiningDateStart
      : '',
    JoiningDateEnd: value?.Vacancies[index]?.JoiningDateEnd
      ? value?.Vacancies[index]?.JoiningDateEnd
      : '',
  });
  useEffect(() => {
    onChange(index, first, true);
  }, [first]);

  return (
    <>
      {index == 0 ? (
        <></>
      ) : (
        <>
          <button
            type=" button"
            className="float-end pb-3"
            style={{
              border: 'none',
              fontSize: '20px',
              backgroundColor: 'white',
            }}
            onClick={() => onChange(index, first, false)}
          >
            x
          </button>
        </>
      )}
      <div className="pt-4">
        <label htmlhtmlFor="inputname">Work Experience</label>
        <input
          type="text"
          className="form-control mt-1"
          id="name"
          name="name"
          value={first.WorkExperience}
          onChange={(e) => {
            setfirst({ ...first, WorkExperience: e.target.value });
          }}
        />
        <div class="invalid-feedback">PLEASE ENTER Work Experience</div>
      </div>
      <div className="pt-4">
        <label htmlhtmlFor="inputname">Employment Type </label>
        <select
          class="form-select"
          aria-label="Default select example"
          value={first.EmploymentType}
          onChange={(e) => {
            setfirst({ ...first, EmploymentType: e.target.value });
          }}
        >
          <option></option>

          <option value="Full Time">Full Time</option>
          <option value="Part Time">Part Time</option>
          <option value="Contract">Contract</option>
          <option value="Temporary">Temporary</option>
          <option value="Volunteer">Volunteer</option>
          <option value="Internship">Internship</option>
        </select>
        <div class="invalid-feedback">PLEASE SELECT Employment Type </div>
      </div>
      <div className="pt-4">
        <label htmlhtmlFor="inputname">Work Mode </label>
        <select
          class="form-select"
          aria-label="Default select example"
          value={first.WorkMode}
          onChange={(e) => {
            setfirst({ ...first, WorkMode: e.target.value });
          }}
        >
          <option></option>

          <option value="On-site">On-site</option>
          <option value="Remote">Remote</option>
          <option value="Hybrid">Hybrid</option>
        </select>
        <div class="invalid-feedback">PLEASE SELECT Work Mode </div>
      </div>
      <div className="pt-4">
        <label htmlhtmlFor="inputname">Gender </label>
        <select
          class="form-select"
          aria-label="Default select example"
          value={first.Gender}
          onChange={(e) => {
            setfirst({ ...first, Gender: e.target.value });
          }}
        >
          <option></option>
          <option value="Any">Any</option>

          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Transgender">Transgender</option>
        </select>
        <div class="invalid-feedback">PLEASE SELECT Gender </div>
      </div>
      <div className="pt-4">
        <label htmlhtmlFor="inputname">Number</label>
        <input
          type="number"
          className="form-control mt-1"
          id="name"
          name="number"
          value={first.Number}
          onChange={(e) => {
            setfirst({ ...first, Number: e.target.value });
          }}
        />
        <div class="invalid-feedback">PLEASE ENTER Number</div>
      </div>
      <div className="pt-4">
        <label htmlhtmlFor="inputname">Job Role</label>
        <input
          type="text"
          className="form-control mt-1"
          id="name"
          name="name"
          value={first.JobRole}
          onChange={(e) => {
            setfirst({ ...first, JobRole: e.target.value });
          }}
        />
        <div class="invalid-feedback">PLEASE ENTER Job Role</div>
      </div>
      <div className="pt-4">
        <label htmlhtmlFor="inputname">Salary Offered</label>
        <input
          type="text"
          className="form-control mt-1"
          id="name"
          name="name"
          value={first.SalaryOffered}
          onChange={(e) => {
            setfirst({ ...first, SalaryOffered: e.target.value });
          }}
        />
        <div class="invalid-feedback">PLEASE ENTER Salary Offered</div>
      </div>

      <div className="pt-4">
        <label htmlhtmlFor="inputname">Job Location</label>
        <input
          type="text"
          className="form-control mt-1"
          id="name"
          name="name"
          value={first.Location}
          onChange={(e) => {
            setfirst({ ...first, Location: e.target.value });
          }}
        />
        <div class="invalid-feedback">PLEASE ENTER Location</div>
      </div>

      <div className="py-4">
        <label htmlhtmlFor="inputname">When to Join ? </label>
        <div className="d-flex">
          <input
            type="text"
            className="form-control   mt-1"
            id="name"
            name="name"
            value={first.JoiningDateStart}
            onChange={(e) => {
              setfirst({ ...first, JoiningDateStart: e.target.value });
            }}
          />
          {/* <div class="invalid-feedback">PLEASE SELECT Joining Date</div> */}
          {/* <div className="d-flex align-items-center px-3"> to </div> */}
          {/* <input
            type="text"
            className="form-control w-50 mt-1"
            id="name"
            name="name"
            value={first.JoiningDateEnd}
            onChange={(e) => {
              setfirst({ ...first, JoiningDateEnd: e.target.value });
            }}
          /> */}
        </div>
        {/* <div class="invalid-feedback">PLEASE SELECT Joining Date</div> */}
      </div>
    </>
  );
};
