import React from 'react'
import { Route, Routes } from "react-router";
import { CreateProfile } from "../CreateProfile/CreateProfile"
import { CreateConseltent } from "../CreateConseltent/CreateConseltent"
import { CreateProduct } from "../CreateProduct/CreateProduct"

export function ProfileCreation() {
  return (
    <div >
        <Routes>
          <Route element={<CreateProfile />} exact path="/"></Route>
          <Route element={<CreateConseltent />} path="CreateConseltent"></Route>
          <Route element={<CreateProduct />} path="CreateProduct"></Route>
        </Routes>
    </div >
  )
}
   