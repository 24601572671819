import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { notify } from '../../../Utility/notify';

export const Csmtermsandcondition = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submit = () => {
    axios
      .post('/api/AffiliatedAccount/ametermsandcondition')
      .then((res) => {
        console.log('zxc');
        notify('Updated', true);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="container pt-4">
        <div className="d-flex justify-content-end">
          <button className="btn pro-spaces-button3" onClick={handleShow}>
            Update
          </button>
        </div>
      </div>

      <div className="container ">
        <h5 className="mt-4 ">TERMS and CONDITIONS *</h5>

        <div className="pt-4">CONSENT :</div>

        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault1">
            I am aware and agree that I fully understand that{' '}
            <span
              className="vipar"
              style={{
                color: 'green',
                fontSize: '20px',
                paddingRight: '5px',
              }}
            >
              Vipar
            </span>{' '}
            <sup>®</sup> is the Organisation (based out of Bengaluru,
            Karnataka)with which I am associated as a Cluster Sales Manager and
            that <span className="prochure">PROchure</span>{' '}
            {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
            is only an application developed by{' '}
            <span
              className="vipar"
              style={{
                color: 'green',
                fontSize: '20px',
                paddingRight: '5px',
              }}
            >
              Vipar
            </span>{' '}
            <span
              className="prochure"
              style={{ fontSize: '16px', color: 'black' }}
            >
              CONNECT
            </span>
            , which is a unit of{' '}
            <span
              className="vipar"
              style={{
                color: 'green',
                fontSize: '20px',
                paddingRight: '5px',
              }}
            >
              Vipar
            </span>{' '}
            <sup>®</sup>.
          </label>
        </div>

        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault2">
            I am ready to enrol myself as a Cluster Sales Manager at{' '}
            <span
              className="vipar"
              style={{
                color: 'green',
                fontSize: '20px',
                paddingRight: '5px',
              }}
            >
              Vipar
            </span>{' '}
            <sup class="">®</sup> to recruit and supervise Field Sales
            Executives inorder to get potential interested customers to
            subscribe on the platform called{' '}
            <span className="prochure">PROchure</span>{' '}
            {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
            (an app accessible to users across the globe) which is primarily a
            digital directory of Services and Products.
          </label>
        </div>

        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault2">
            I am aware and agree that at any given time, I SHALL recruit and
            maintain a minimum of 5 (FIVE) Field Sales Executives and SHALL
            recruit Only a maximum of 50 (FIFTY) Field Sales Executives.
          </label>
        </div>

        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault2">
            I am aware and agree that in a sceanrio where I have lesser than 5
            FSEs in my FSE Cluster team, then my CSM account and the accounts of
            all the existing FSEs in my team will be Locked, and also that the
            Unique Referral Code of the existing FSEs in my team will be
            invalid.
          </label>
        </div>
        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault2">
            I am aware and agree that I SHALL mandatorily get each and every FSE
            working under me in my Cluster team to register as a FSE via the
            Organisation’s web app www.vipar-connect.in before the FSE starts
            his/her sales pitch.
          </label>
        </div>
        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault2">
            I am aware and agree that any sales achieved by any of my FSEs SHALL
            NOT be considered valid unless and until his/her registration as a
            FSE is not done with{' '}
            <span
              className="vipar"
              style={{
                color: 'green',
                fontSize: '20px',
                paddingRight: '5px',
              }}
            >
              Vipar
            </span>{' '}
            <sup class="">®</sup>.
          </label>
        </div>
        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault2">
            I am aware and agree that I SHALL NOT recruit anyone to join as a
            FSE by misguiding / misleading / misinforming them regarding their
            remuneration and professional protocols with the Organisation.
          </label>
        </div>
        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault2">
            I am aware and agree that this Contractual Agreement is valid only
            for a period of 1 (ONE) Year and SHALL be renewed every year based
            on the mutual understanding between myself and{' '}
            <span
              className="vipar"
              style={{
                color: 'green',
                fontSize: '20px',
                paddingRight: '5px',
              }}
            >
              Vipar
            </span>{' '}
            <sup class="">®</sup>.
          </label>
        </div>
        <div className="pt-4">SALES :</div>

        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault2">
            I am aware and agree that I SHALL give appropriate training and
            guidance to each and every Field Sales Executive who is working
            under me in my team about the features, benefits, fee structure and
            membership subscription details of the app so that each one of my
            FSEs can explain the same to each and every customer with clarity.
          </label>
        </div>

        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault8">
            I am aware and agree that I SHALL clearly explain to each one of my
            FSEs regarding how a profile should be created for a customer and
            shall leave no scope for errors.
          </label>
        </div>

        <div className="pt-4">PROFESSIONAL CONDUCT / ETHICS :</div>

        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault4">
            I am aware and agree that I SHALL NOT encourage my FSEs to engage or
            indulge in any fraudulent activities that will spoil the reputation
            of the either the App or the Organisation, only for the sake of
            getting subscriptions at <span className="prochure">PROchure</span>{' '}
            via my FSEs.
          </label>
        </div>

        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault5">
            I am aware and agree that I SHALL NOT encourage or allow my FSEs to
            make any false claims / false promises to the potential subscribers
            regarding the features and benefits of using the platform or mislead
            them with incorrect or wrong information regarding the app only for
            the sake of getting their subscription.
          </label>
        </div>

        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            I am aware and agree that I SHALL NOT encourage or allow my FSEs to
            say anything to the potential subscribers at my / their own
            discretion beyond what has been instructed to me / them by{' '}
            <span
              className="vipar"
              style={{
                color: 'green',
                fontSize: '20px',
                paddingRight: '5px',
              }}
            >
              Vipar
            </span>{' '}
            <sup class="">®</sup> and that I SHALL ensure that my FSEs maintain
            absolute professional decorum as a representative of{' '}
            <span
              className="vipar"
              style={{
                color: 'green',
                fontSize: '20px',
                paddingRight: '5px',
              }}
            >
              Vipar
            </span>{' '}
            <sup class="">®</sup> during my course of interaction with
            prospective customers.
          </label>
        </div>
        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault13">
            I am aware and agree that I SHALL strictly abide by the rules and
            regulations of the Organisation, and all the Terms and Conditions
            mentioned here and that I SHALL NOT indulge in any activity that
            will directly or indirectly spoil the reputation of the platform ({' '}
            <span className="prochure">PROchure</span>{' '}
            {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}) and the
            Organisation ({' '}
            <span
              className="vipar"
              style={{
                color: 'green',
                fontSize: '20px',
                paddingRight: '5px',
              }}
            >
              Vipar
            </span>{' '}
            <sup class="">®</sup> ).
          </label>
        </div>
        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            I am aware and agree that I SHALL ensure that my FSEs neither lose
            their temper nor get into any kind of physical or verbal fights with
            a customer even if it is a mistake on the part of the customer and
            SHALL advice them to politely relieve themselves from the customer’s
            place in any such untoward scenario.
          </label>
        </div>
        <div className="pt-4">REMUNERATION :</div>
        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault14">
            I am aware and agree that I SHALL be paid the sales commission only
            on percentage basis on each Subscription (amount) achieved via sales
            by each of my FSEs via their Unique Referral Code and that this
            Subscription amount is subject to change at the discretion of{' '}
            <span
              className="vipar"
              style={{
                color: 'green',
                fontSize: '20px',
                paddingRight: '5px',
              }}
            >
              Vipar
            </span>{' '}
            <sup class="">®</sup> .
          </label>
        </div>
        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            I am aware and agree that I SHALL be paid commission Only on the
            Final (in case of using a Discount Coupon) Subscription (amount) of
            each profile that each of my FSEs get listed at{' '}
            <span className="prochure">PROchure</span> via their Unique Referral
            Code.
          </label>
        </div>
        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            I am aware and agree that I SHALL be paid commission (which is
            mentioned in my Appointment Letter) on each Subscription (amount) of
            each profile that each one of my FSEs get listed at{' '}
            <span className="prochure">PROchure</span> via their Unique Referral
            Code and each of my FSEs SHALL be paid 35% commission on each of the
            Subscriptions that they achieve via sales.
          </label>
        </div>
        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            I am aware and agree that the percentage commissions on sales SHALL
            be settled in full only on the last working day of that respective
            month.
          </label>
        </div>
        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            I am aware and agree that I SHALL NOT be eligible for any additional
            fixed daily, weekly or monthly remuneration beyond the sales
            percentage commission.
          </label>
        </div>
        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            I am aware and agree that I SHALL NOT be eligibile to get any
            reimbursement on the conveyance or phone call expenses that I incur
            as a part of my efforts to either recruit or to get subscriptions at{' '}
            <span className="prochure">PROchure</span> via my FSEs.
          </label>
        </div>

        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            I am aware and agree that I SHALL be eligible for my sales
            commissions from subscriptions of my FSEs Only if the customer
            mandatorily mentions the Unique Referral Code of the respective FSE
            on the payment page while creating his/her/their profile at{' '}
            <span className="prochure">PROchure</span>.
          </label>
        </div>

        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            I am aware and agree that I SHALL be notified (in writing) 1 (ONE)
            Month prior to any increase or decrease in my commission percentage
            and/or the sales commission percentage of my FSEs.
          </label>
        </div>

        <div className="pt-4">FINANCIAL PROTOCOLS :</div>
        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            I am aware and agree that I SHALL NOT encourage or allow to collect
            money for subscriptions from any individual, who wishes to create a
            business listing / profile of theirs on the platform, either in the
            form of cash / cheque / DD / net transfer such as IMPS, NEFT, RTGS /
            QR code scanning or UPI payment method to any external account
            including my own or my FSEs.
          </label>
        </div>
        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            I am aware and agree that the subscription fees SHALL be collected
            ONLY via the UPI payment method on the web and mobile apps of the{' '}
            <span className="prochure">PROchure</span> platform to the{' '}
            <span
              className="vipar"
              style={{
                color: 'green',
                fontSize: '20px',
                paddingRight: '5px',
              }}
            >
              Vipar
            </span>{' '}
            <sup class="">®</sup> account and NOT to any of my personal accounts
            or my FSEs’ personal accounts or to any other account of person(s) /
            company known to me.
          </label>
        </div>

        <div className="pt-4">RESIGNATION :</div>
        <div class="form-check pt-3">
          <label class="form-check-label">
            I am aware and agree that while resigning from the job, I SHALL send
            an email regarding my resignation to only the official marketing
            email id of{' '}
            <span
              className="vipar"
              style={{
                color: 'green',
                fontSize: '20px',
                paddingRight: '5px',
              }}
            >
              Vipar
            </span>{' '}
            <sup class="">®</sup>, which is vipar.marketing.hr@gmail.com from my
            registered email id with Subject Title as ‘{' '}
            <span className="prochure">PROchure</span> CSM RESIGNATION’ and
            clearly mentioning my Unique Referral Code. Else my resignation
            SHALL NOT be acceptable.
          </label>
        </div>
        <div class="form-check pt-3">
          <label class="form-check-label">
            I am aware and agree that I can resign from the Organisation without
            any notice period.
          </label>
        </div>
        <div class="form-check pt-3">
          <label class="form-check-label">
            I am aware and agree that both my Salary Certificate and Experience
            Certificate SHALL be issued to me ONLY IF I complete a minimum of 6
            (SIX) Months of work duration with{' '}
            <span
              className="vipar"
              style={{
                color: 'green',
                fontSize: '20px',
                paddingRight: '5px',
              }}
            >
              Vipar
            </span>{' '}
            <sup class="">®</sup> as a CSM.
          </label>
        </div>
        <div class="form-check pt-3">
          <label class="form-check-label">
            I am aware and agree that upon my resignation, my entire FSE Cluster
            team would be immediately and automatically dissolved However I
            SHALL NOT stop the Organisation from hiring or assigning my FSEs to
            other Cluster Sales Managers or Regional Marketing Managers IF the
            FSEs in my Cluster team wish to continue to work for and be a part
            of the Organisation.
          </label>
        </div>
        <div className="pt-4">RELIEVING FROM JOB :</div>
        <div class="form-check pt-3">
          <label class="form-check-label">
            I am aware and agree that IF the Organisation finds me inept for
            this Job or in a scenario where the Organisation decides to put an
            end to the marketing and sales of the app, then the Organisation
            SHALL have the authority to relieve me from this Job by giving me a
            Notice period of 1 (ONE) Month in writing via mail to my registered
            email id.
          </label>
        </div>
        <div className="pt-4">DE-REGISTRATION / TERMINATION :</div>
        <div class="form-check pt-3">
          <label class="form-check-label">
            I am aware and agree that my CSM account SHALL be de-registered /
            terminated without any intimation or notification IF the
            Organisation detects any suspicious activity or unethical practice
            during my course of sales of the app.
          </label>
        </div>
        <div class="form-check pt-3">
          <label class="form-check-label">
            I am aware and agree that I SHALL NOT be eligible for any pending
            remuneration / commission for sales in case of de-registration /
            termination.
          </label>
        </div>
        <div className="pt-4">LEGAL BINDING :</div>
        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            I am aware and agree that it SHALL be only my FSE who will be fully
            and solely responsible for his/her bad and irresponsible behaviour
            with a customer and only he/she SHALL be held liable for the same
            legally and NOT any other employee / representative of the
            Organisation or the Head of the Organisation since my FSEs are
            directly recruited by me and not by the Organisation.
          </label>
        </div>
        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            I am aware and agree that I SHALL be legally sued for monetary
            damages at the discretion of{' '}
            <span
              className="vipar"
              style={{
                color: 'green',
                fontSize: '20px',
                paddingRight: '5px',
              }}
            >
              Vipar
            </span>{' '}
            <sup class="">®</sup> IF found to be involved in any kind of
            fraudulent activity which directly or indirectly is an act against
            the interests of either a customer or the Organisation or both. And
            that I SHALL NOT be eligible for any pending remuneration /
            commission for sales in such a scenario.
          </label>
        </div>
        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            I am aware and agree that any lapse(s) occuring from my end
            regarding ALL or ANY of the above mentioned mutually agreed upon
            Terms and Conditions SHALL solely be my responsibility and for which{' '}
            <span
              className="vipar"
              style={{
                color: 'green',
                fontSize: '20px',
                paddingRight: '5px',
              }}
            >
              Vipar
            </span>{' '}
            <sup class="">®</sup> SHALL NOT be held responsible or liable, both
            legally and otherwise.
          </label>
        </div>
        <div class="form-check pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            I am aware and agree that these Terms and the relationship between
            myself and{' '}
            <span
              className="vipar"
              style={{
                color: 'green',
                fontSize: '20px',
                paddingRight: '5px',
              }}
            >
              Vipar
            </span>{' '}
            <sup class="">®</sup> shall be governed by the laws of Karnataka,
            India without regard to its conflict of law provisions.{' '}
            <span
              className="vipar"
              style={{
                color: 'green',
                fontSize: '20px',
                paddingRight: '5px',
              }}
            >
              Vipar
            </span>{' '}
            <sup class="">®</sup> and I agree that all disputes shall be subject
            to arbitration at Bengaluru, Karnataka in accordance with the
            Arbitration and Conciliation Act, 1996. The arbitration proceedings
            shall be conducted in the English language. That{' '}
            <span
              className="vipar"
              style={{
                color: 'green',
                fontSize: '20px',
                paddingRight: '5px',
              }}
            >
              Vipar
            </span>{' '}
            <sup class="">®</sup> and I hereby expressly and irrevocably attorn
            to the jurisdiction of the courts of Bengaluru with respect to any
            matter or claim, suit, action or proceeding arising under or related
            to this Agreement. I covenant not to sue{' '}
            <span
              className="vipar"
              style={{
                color: 'green',
                fontSize: '20px',
                paddingRight: '5px',
              }}
            >
              Vipar
            </span>{' '}
            <sup class="">®</sup> in any other forum.
          </label>
        </div>

        <h5 className="mt-4">BANK ACCOUNT DETAILS *</h5>

        <div class="form-check pt-3">
          <label class="">
            I am aware and agree that the bank account details I have mentioned
            is correct and wish to have my commissions credited to this account.
          </label>
        </div>
        <div class="form-check pt-3">
          <label class="">
            I am aware and agree that any mistake / error in the bank account
            details entered is not the responsibility of the platform.
          </label>
        </div>
        <div>
          <h5 className="mt-4">TO BE NOTED :</h5>

          <ul className="ps-5 mt-2">
            <li className="pt-2">
              You can View the Accepted Terms and Conditions from your CSM
              account.
            </li>

            <li className="pt-2">
              {' '}
              For any grievances, send us an email regarding the issue to
              vipar.marketing.hr@gmail.com from your registered email id with
              Subject Title as ‘<span className="prochure">PROchure</span> CSM
              GRIEVANCE’ and clearly mentioning your Unique Referral Code.
            </li>
          </ul>
          <h5 className="mt-4">REFERRAL CODE :</h5>
          <ul className="ps-5 mt-2">
            <li className="pt-2" style={{ lineSpacing: '0' }}>
              During Registration, Create a Referral Code of your choice which
              will be your CSM Identification at{' '}
              <span
                className="vipar"
                style={{
                  fontSize: '22px',
                  color: '#99d050',
                }}
              >
                {' '}
                Vipar
              </span>{' '}
              <sup class="">®</sup>
            </li>

            <li className="pt-2">
              It is Mandatory for disbursing on your commission to you.
            </li>
            <li className="pt-2">
              Once a Referral Code is created, it is permanent and cannot be
              changed.
            </li>

            <li className="pt-2">
              Can be a combination of Alphabets, Numbers and Symbols.
            </li>
            <li className="pt-2">
              Minimum Character length &#8239;: 5<br />
              &nbsp;&nbsp;Maximum Character length : 10
            </li>
            <li className="pt-2">
              In case you forget your Referral Code, it will be visible to you
              on the ‘Account Details’ page of your profile.
            </li>
          </ul>
        </div>
      </div>

      <center>
        <Modal className="" show={show} onHide={handleClose} centered>
          <Modal.Body closeButton>Are You Sure Want To Update ?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn pro-spaces-button3"
              onClick={(e) => {
                submit();
                handleClose();
              }}
            >
              Yes
            </button>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </center>
    </>
  );
};
