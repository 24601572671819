
import React, { useState } from 'react';
import { SiGooglemaps } from "react-icons/si";
import { Link } from 'react-router-dom';
export const Information_Medical_Store_card = ({data,Edit,Get}) => {
  
  return (
  <div className="col-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 mb-3 "><>
  <div className="text-center card-box p-1">
    <div className="member-card pb-1">
    {
          data?.link?
      <div className="thumb-lg member-thumb mx-auto">
        {
          data?.link?
          <img
          src={data?.link}
          className="rounded-circle img-thumbnail"
          alt="profile-image"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src =
                "/blank.png";
        }}
        />
        :""
      }
      </div>
      :""
    }
      <div className="">
        <h4>{ data.pname? data.pname.toUpperCase() : ''}</h4>
        <span className="ufghsuhie rigehioerhog">
          <span className="ufghsuhie">
            {data.subcategory.sub} | {data.category.category}
          </span>

          <br />
          <span>
            <span className="ufghsuhie">{data.city} | {data.state} {data.Location === true ?
              <a target="_blank" href={`http://maps.google.com/maps?z=18&q=${data.Location_Coordinates.lat},${data.Location_Coordinates.lng}`}>
                <SiGooglemaps className="gmapicon" />
              </a>
              : <></>}</span>
          </span>
        </span>
      </div>
      <div className="row mt-2">
        <div className="col-12">
          <Link to={`/Information_medical_store_profile?k=${data._id}`}>
            <button
              type="button"
              className="btn pro-spaces-button3 btn-rounded ps-3 pe-3 "
            >
              View
            </button>
          </Link>
        </div>

      </div>
    </div>
  </div>
      </>
</div>
  )
}
