import React, { useEffect, useState } from 'react';
import { ControlBar } from './ControlBar';
import { NavBar } from './NavBar';
import { Button, Modal } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { Footer } from './Footer';
import { print_city, print_state } from '../../Utility/stateCity';
import { StateCity } from '../../Components/CreateConseltent/StateCity';
import axios from 'axios';
import { toast } from 'react-toastify';

export const Apps = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const [show5, setShow5] = useState(false);

  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const [show6, setShow6] = useState(false);

  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [show7, setShow7] = useState(false);

  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [show8, setShow8] = useState(false);

  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show9, setShow9] = useState(false);

  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => setShow9(true);
  const [Stateload, setStateload] = useState(true);
  const [Stateload1, setStateload1] = useState(true);

  useEffect(() => {
    if (Stateload) print_state('State');
    setStateload(false);
  }, []);

  useEffect(() => {
    if (Stateload1) print_state('State');
    setStateload1(false);
  }, []);

  function Cityshow(e) {
    var index = document.getElementById(e.target.id).selectedIndex;
    print_city('City', index);
    setdata({ ...data, state: e.target.value });
  }

  function Cityshow(e) {
    var index = document.getElementById(e.target.id).selectedIndex;
    print_city('City', index);
    setdata1({ ...data1, salesstate: e.target.value });
  }

  const [data, setdata] = useState({
    Name: '',
    Age: '',
    Contact: '',
    Email: '',
    state: '',
    city: '',
    country: '',
  });

  const [data1, setdata1] = useState({
    state: '',
    city: '',
    country: '',
  });
  const [err, seterr] = useState('');
  const validation = () => {
    if (data.Name.length <= 0) {
      return seterr('Enter Your Name');
    }
    if (data.Email.length <= 0) {
      return seterr('Enter Your Email');
    }
    if (data.Age.length >= 17) {
      return seterr(' You Are To young');
    }
    if (data.Age.length <= 0) {
      return seterr('Enter Your Age');
    }
    if (data.Contact.length <= 0) {
      return seterr('Enter Your Contact');
    }
    if (data.state.length <= 0) {
      return seterr('Enter Your state');
    }
    if (data.city.length <= 0) {
      return seterr('Enter Your city');
    }
    if (data.country.length <= 0) {
      return seterr('Enter Your country');
    }
    if (data1.state.length <= 0) {
      return seterr('Enter Your state');
    }
    if (data1.country.length <= 0) {
      return seterr('Enter Your country');
    }
    if (data1.city.length <= 0) {
      return seterr('Enter Your city');
    }
    return true;
  };
  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (sab == 'red') {
      toast.danger(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const [csmshow, setcsmshow] = useState({});
  const [fmeshow, setfmeshow] = useState({});
  const [fseshow, setfseshow] = useState({});

  const submit = () => {
    if (validation() === true) {
      axios
        .post('/api/csm/csminsert', { data, data1 })
        .then((req) => {
          console.log('req');
          console.log(req.data);
          setcsmshow(req.data);
          setdata({
            ...data,
            Name: '',
            Age: '',
            Contact: '',
            Email: '',
            state: '',
            city: '',
            country: '',
          });
          setdata1({ ...data1, state: '', city: '', country: '' });
          seterr('');
          notify('Successfully created', true);
          handleShow7(true);
          setTimeout(() => {
            handleClose7(true);
          }, 5000);
        })
        .catch((err) => {
          notify('Something wrong', false);
        });
    }
  };

  const submit1 = () => {
    if (validation() === true) {
      axios
        .post('/api/fse/fseinsert', { data, data1 })
        .then((req) => {
          console.log('req');
          setfseshow(req.data);
          setdata({
            ...data,
            Name: '',
            Age: '',
            Contact: '',
            Email: '',
            state: '',
            city: '',
            country: '',
          });
          setdata1({ ...data1, state: '', city: '', country: '' });
          seterr('');
          notify('Successfully created', true);
          handleShow5(true);
          setTimeout(() => {
            handleClose5(true);
          }, 5000);
        })
        .catch((err) => {
          notify('Something wrong', false);
        });
    }
  };

  const submit2 = () => {
    if (validation() === true) {
      axios
        .post('/api/fme/fmeinsert', { data, data1 })
        .then((req) => {
          console.log('req');
          setfmeshow(req.data);
          setdata({
            ...data,
            Name: '',
            Age: '',
            Contact: '',
            Email: '',
            state: '',
            city: '',
            country: '',
          });
          setdata1({ ...data1, state: '', city: '', country: '' });

          notify('Successfully created', true);
          handleShow9(true);
          seterr('');
          setTimeout(() => {
            handleClose9(true);
          }, 5000);
        })
        .catch((err) => {
          console.log(err);

          notify('Something wrong', false);
        });
    }
  };

  return (
    <>
      <div id="fooooo" style={{ backgroundColor: 'white' }}>
        <ControlBar />
        <NavBar />

        <hr className="m-0 zxc"></hr>

        <div
          style={{ overflow: 'hidden', backgroundColor: 'white' }}
          className="py-2"
        >
          <div className="container pt-4">
            <div className=" pe-0 ">
              <div className="d-flex justify-content-center">
                <div
                  id="containerft2"
                  className="  m-0  "
                  style={{ display: 'inline-block' }}
                  // style={{ maxWidth: "400px", border: "none" }}
                >
                  <a
                    // to={anees?.Status ? 'https://www.prochure.in/' : ''}
                    // href="https://www.prochure.in/pre_launch_prochure"
                    style={{ textDecoration: 'none' }}
                    target="_blank"
                  >
                    <div className="d-flex justify-content-start mt-4 ps-1  pt-2">
                      <span className=" d-flex align-items-center ps-2 blinkkk invisible">
                        <img src="/finger.png" width="30px" height="30px" />
                      </span>
                      &nbsp;&nbsp;
                      <img
                        className="img-fluid szz "
                        src="/prochureLogo.png"
                        alt=""
                      />
                      {/* <span className="d-flex align-items-center ps-2 blinkkk ">
                              <img
                                src="/img/finger2.png"
                                width="30px"
                                height="30px"
                              />
                            </span> */}
                      {/* <span
                              className="nn  transform m-0 pt-3 blinkkk "
                              style={{
                                fontSize: '15px',
                                // fontFamily: 'calibri',
                                fontWeight: '800',
                                color: 'black',
                                transform: 'rotateY(180deg) rotate(90deg)',
                              }}
                            >
                              <BsHandIndexThumb color="#5d334e" size={23} />
                            </span> */}
                    </div>
                    <div className="">
                      <div className=" pe-0  ">
                        <div
                          className="mmm text-center text-dark pt-1 calibrifont "
                          style={{ fontSize: '15px' }}
                        >
                          &nbsp;&nbsp;an E-Brochure of&nbsp;
                          <br />
                          Professional Services and Products
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <hr
              className="my-3 "
              style={{
                color: 'darkgray',
                borderTop: '2px solid',
              }}
            />
            <div className="footlightfont text-center">
              <span className="prochure">PROchure</span>
              {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
              &nbsp;
              <span className="boldfont">MARKETING</span>
            </div>
            <div className="pt-3 footlightfont">
              We are Recruiting Candidates across India who are interested in
              the Sales and Marketing of our App called{' '}
              <span className="prochure">PROchure</span>.
            </div>
            {/* <div className="pt-3  footlightfont">
              Would you like to Apply as a{' '}
              <span className="boldfont">SALARIED</span> MARKETING EXECUTIVE ?
              <span className="float-end">
                <button className="btn btnsemi" onClick={handleShow2}>
                  YES
                </button>
              </span>
            </div> */}
            {/* <div className="pt-3  footlightfont">Or</div> */}

            <div className="row d-flex justify-content-center">
              <div className="col-10 pt-3 footlightfont">
                Would you like to Apply for a Job as a Data Collection Executive
                (Salary) ?
              </div>
              <div className="col-2 pt-3 footlightfont">
                <button className="btn btnsemi" onClick={handleShow8}>
                  YES
                </button>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-10 pt-3 footlightfont">
                Would you like to Apply for a Job as a Field Sales Executive
                (Commission) ?
              </div>
              <div className="col-2 pt-3 footlightfont">
                <button className="btn btnsemi" onClick={handleShow4}>
                  YES
                </button>
              </div>
            </div>

            {/* <div className="row d-flex justify-content-center">
              <div className="col-10 pt-4  footlightfont">
                Would you like to Apply for a Job as a Cluster Sales Manager
                (CSM) ?
                <div className="pt-3">
                  ( A Cluster Sales Manager must recruit and supervise a group
                  of Field Sales Executives in a City / Town )
                </div>
              </div>
              <div className="col-2  pt-4  footlightfont ">
                <button className="btn btnsemi" onClick={handleShow6}>
                  YES
                </button>
              </div>
            </div> */}

            <div
              className="card mt-3"
              style={{
                boxShadow:
                  'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px',
              }}
            >
              <div className="footlightfont px-3">
                <div className="boldfont pt-3 ">
                  DATA COLLECTION EXECUTIVE (Salary) TARGETS and REMUNERATION
                </div>
                <div className="pt-2">
                  Fixed monthly Salary (including Allowances) of Rs 25,000/-
                  (Rupees TWENTY FIVE THOUSAND only) for achieving the below
                  mentioned Data Collection daily / monthly targets.
                </div>
                <div className="table-responsive pt-3 ">
                  <table class="table table-sm align-middle table-bordered">
                    <thead>
                      <tr className="text-center">
                        <th
                          className=" justify-content-center align-items-start"
                          scope="col"
                        >
                          DAILY TARGET
                        </th>
                        <th
                          className="d-flex justify-content-center align-items-start"
                          scope="col"
                        >
                          MONTHLY TARGET
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-center">
                        <td>50</td>
                        <td>1250</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="boldfont pt-3 ">
                  FIELD SALES EXECUTIVE (Commission) REMUNERATION
                </div>
                <div className="table-responsive pt-3 ">
                  <table class="table table-sm align-middle table-bordered">
                    <thead>
                      <tr className="text-center">
                        <th
                          className="d-flex justify-content-center align-items-center"
                          scope="col"
                        >
                          NUMBER OF SALES
                        </th>
                        <th scope="col">
                          COMMISSION PERCENTAGE
                          <br />
                          <span style={{ fontWeight: '400' }}>
                            per subscription sale
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-center">
                        <td>1 to 1000</td>
                        <td>35%</td>
                      </tr>
                      <tr className="text-center">
                        <td>1001 to 5000</td>
                        <td>40%</td>
                      </tr>
                      <tr className="text-center">
                        <td>5001 and above</td>
                        <td>50%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="pt-2">
                  ADDITIONAL INCENTIVE of Rs 25,000/- (Rupees TWENTY FIVE
                  THOUSAND only) for achieving every 1000 (ONE THOUSAND)
                  Subscription sales upto a total of 5000 (FIVE THOUSAND)
                  subscriptions, WITHOUT using a Discount coupon.
                </div>
                <div className="pt-2 pb-3">
                  ADDITIONAL INCENTIVE of Rs 50,000/- (Rupees FIFTY THOUSAND
                  only) for achieving every 1000 (ONE THOUSAND) Subscription
                  sales beyond 5000 (FIVE THOUSAND) subscriptions, WITHOUT using
                  a Discount coupon.
                </div>
              </div>
            </div>

            <hr
              className="my-3 "
              style={{
                color: 'darkgray',
                borderTop: '2px solid',
              }}
            />
            <div className="pt-3 footlightfont">
              Business Listings must be created under Two CLASSIFICATIONS namely
              :
            </div>
            <div className="pt-3 footlightfont">
              1. SERVICES
              <br /> 2. PRODUCTS
            </div>
            {/* <div className=" pt-3 footlightfont">
            Mentioned below are the CATEGORIES 
          // and SUB CATEGORIES 
            of the two classifications.
          </div> */}
            <div className=" pt-3 footlightfont">
              After Registering as a DCE / FSE, you can View the CATEGORIES and
              SUB CATEGORIES under Services and Products in which Profiles must
              be created.
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5" style={{ backgroundColor: 'white' }}>
        <Footer />
      </div>

      {/* Affiliate Marketing Executive modal */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton centered></Modal.Header>
        <Modal.Body>
          <div className="pt-3 footlightfont text-center ">
            <span className="prochure">PROchure</span>
            &nbsp;
            <span className="boldfont">AFFILIATE MARKETING</span>
          </div>
          <div className="pt-3 footlightfont">
            Affiliate Marketing is FREE-LANCE Marketing. In simple terms, you
            will be paid on per-subscription basis.
          </div>
          <div className="pt-3 footlightfont">
            <span className="boldfont">ADVANTAGES</span> of AFFILIATE
            MARKETING&nbsp;:
            <div className="pt-3 footlightfont">
              1. You can DO MULTIPLE JOBS. <br />
              2. You have NO TARGETS every day / month.
              <br />
              3. You can WORK AT YOUR CONVENIENCE.
              <br />
              4. You can EARN WELL.
              <br />
              5. You can add EXTRA INCOME to your existing ones.
              <br />
              6. You can WORK FOR 30 DAYS OR JUST ONE DAY IN A MONTH depending
              on how much money you would like to earn.
            </div>
          </div>
          <div className="pt-3 footlightfont">
            You can now earn money via Marketing and Sales of{' '}
            <span className="prochure">PROchure</span>
            &nbsp;&nbsp;by getting Service Providers and Product Retailers to
            Subscribe on our platform.
          </div>
          <div className="pt-3 footlightfont">
            <span className="boldfont">All you have to do is</span> :
          </div>
          <div className="pt-3 footlightfont">
            1. Click on ‘ ENROLL NOW ’ below.
            <br /> 2. Register as an AME (Affiliate Marketing Executive).
            <br /> 3. Get Businesses to Subscribe at{' '}
            <span className="prochure">PROchure</span>
            .<br />
            4. You can select any location / multiple locations of your choice
            for marketing.
          </div>
          <div className="pt-3 footlightfont">
            And you will begin to earn money with ease !
          </div>
          <div className="pt-3 footlightfont">
            There are currently two types of{' '}
            <span className="boldfont">Membership Subscription</span>.
            <div className="pt-3 footlightfont">
              1. <span className="boldfont">BASIC listing</span>
              <br />₹ 500/- per year including GST
            </div>
            <div className="pt-3 footlightfont">
              2. <span className="boldfont">PRIME league</span>
              <br />₹ 5000/- per year including GST
            </div>
          </div>
          <div className="pt-3 footlightfont">
            Please Note that in certain Categories / Sub Categories of Qualified
            Professionals, Profiles cannot be created under BASIC listing.
          </div>
          <div className="pt-3 footlightfont">
            Profiles of these Qualified Professionals (like Architects,
            Structural Engineers, Chartered Accountants, Doctors, Lawyers, etc)
            can be listed Only under PRIME league because of mandatory
            Professional information required from them.
          </div>
          <div className="pt-3 footlightfont">
            <span className="boldfont">Remuneration</span> :
          </div>
          <div className="pt-3 footlightfont">
            You will be paid <span className="boldfont">32% Commission</span> on
            the Final (in case of using a Discount Coupon) Subscription Amount
            on each profile that you get listed via your Unique Referral Code.
          </div>
          <div className="pt-3 footlightfont">
            For instance, if you create a Profile under PRIME league for ₹
            5000/-,&nbsp;&nbsp;you will get ₹ 1600/- as commission.{' '}
          </div>

          <div className="pt-3 footlightfont">
            <span className="boldfont">Here's how it works</span> :
          </div>
          <div className="pt-3 footlightfont">
            1. ENROLL to Create an AME account with us.
            <br />
            2. Via your AME account, you can :<br />
            a) See details of the profiles created by yourself.
            <br />
            b) Track your commission remittances.
          </div>
          <div className="pt-3 footlightfont ">
            <span className="boldfont">After Registeration</span> :
          </div>
          <div className="pt-3 footlightfont">
            • You can see the CATEGORIES and SUB CATEGORIES under Services and
            Products in which Profiles must be created.
            <br />• You can Sign In / Log In to www.vipar-connect.in anytime you
            wish to do marketing.
          </div>
          <div className="pt-3 footlightfont">
            <span className="boldfont">Referral Code</span> :
          </div>

          <div className="pt-3 footlightfont" style={{ lineSpacing: '0' }}>
            • Create a Referral Code of your choice which will be your Affiliate
            Identification at{' '}
            <span
              className="vipar"
              style={{
                fontSize: '22px',
                color: '#99d050',
              }}
            >
              {' '}
              Vipar
            </span>{' '}
            <sup class="">®</sup>.
          </div>
          <div className="pt-3 footlightfont" style={{ lineSpacing: '0' }}>
            • Your Unique Referral Code is the one with which
            <span
              className="vipar"
              style={{
                fontSize: '22px',
                color: '#99d050',
              }}
            >
              {' '}
              Vipar
            </span>{' '}
            <sup class="">®</sup> can identify that it is via your AME account
            that a Subscription is sold.
          </div>
          <div className="pt-3 footlightfont">
            • It is Mandatory for disbursing on your commission to you.
          </div>
          <div className="pt-3 footlightfont">
            • Once a Referral Code is created, it is permanent and cannot be
            changed.
          </div>

          <div className="pt-3 footlightfont">
            • Can be a combination of Alphabets, Numbers and Symbols.
          </div>
          <div className="pt-3 footlightfont">
            • Minimum Character length &#8239;: 5<br />
            &nbsp;&nbsp;Maximum Character length : 10
          </div>
          <div className="pt-3 footlightfont">
            • In case you forget your Referral Code, it will be visible to you
            on the ‘Account Details’ page of your profile.
          </div>

          <div className="py-3 d-flex justify-content-center">
            <Link
              style={{ textDecoration: 'none' }}
              to="/ame_registration_form"
            >
              <Button className="btnmainvipar footlightfont ">
                ENROLL NOW
              </Button>
            </Link>
          </div>
        </Modal.Body>
      </Modal>

      {/* Affiliate Marketing Executive modal */}

      {/* FIXED SALARY MARKETING EXECUTIVE modal */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton centered></Modal.Header>
        <Modal.Body>
          <div className="pt-3 footlightfont text-center ">
            <span className="prochure">PROchure</span>
            {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
            &nbsp;<span className="boldfont">SALARIED MARKETING </span>
          </div>
          <div className="pt-3 footlightfont">
            1. Click on ‘ APPLY NOW ’ below.
            <br /> 2. Register as a SME (Salaried Marketing Executive).
            <br /> 3. Get Businesses (Service Providers and Product Retailers)
            to Subscribe at <span className="prochure">PROchure</span>
            {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
            .<br />
            4. You can select any location / multiple locations of your choice
            for marketing.
          </div>
          <div className="pt-3 footlightfont">
            There are currently two types of{' '}
            <span className="boldfont">Membership Subscription</span>.
            <div className="pt-3 footlightfont">
              1. <span className="boldfont">BASIC listing</span>
              {/* <br />₹ 2000/- for the 1<sup>st</sup>&nbsp;year
              <br />₹ 500/- from the 2<sup>nd</sup>&nbsp;year onwards. */}
              <br />₹ 500/- per year including GST
            </div>
            <div className="pt-3 footlightfont">
              2. <span className="boldfont">PRIME league</span>
              {/* <br />₹ 5000/- for the 1<sup>st</sup>&nbsp;year
              <br />₹ 1000/- from the 2<sup>nd</sup>&nbsp;year onwards. */}
              <br />₹ 5000/- per year including GST
            </div>
          </div>
          <div className="pt-3 footlightfont">
            Please Note that in certain Categories / Sub Categories of Qualified
            Professionals, Profiles cannot be created under BASIC listing.
          </div>
          <div className="pt-3 footlightfont">
            Profiles of these Qualified Professionals (like Architects,
            Structural Engineers, Chartered Accountants, Doctors, Lawyers, etc)
            can be listed Only under PRIME league because of mandatory
            Professional information required from them.
          </div>
          <div className="pt-3 footlightfont">
            <span className="boldfont">Remuneration</span> :
          </div>
          <div className="pt-3 footlightfont">
            <div className="row ">
              <div className="col-5">Number of WORKING DAYS in a month </div>
              <div className="col-2 d-flex justify-content-center align-items-center">
                :
              </div>
              <div className="col-5 d-flex align-items-center ">25</div>
              {/* <div className="col-5 pt-3">AVERAGE DAILY TARGET </div>
              <div className="col-2 pt-3 d-flex justify-content-center align-items-center">
                :
              </div>

              <div className="col-5 pt-3 d-flex align-items-center">
                10 subscriptions of PRIME league
              </div>
              <div className="col-5 pt-3">FIXED MINIMUM MONTHLY TARGET </div>
              <div className="col-2 d-flex justify-content-center align-items-center pt-3">
                :
              </div>

              <div className="col-5 pt-3 d-flex align-items-center">
                125 to 250 * subscriptions of PRIME league
              </div> */}
              <div className="col-5 pt-3 d-flex align-items-center">
                <span className="boldfont">MONTHLY SALARY</span>{' '}
              </div>
              <div className="col-2 d-flex justify-content-center align-items-center pt-3">
                :
              </div>

              <div className="col-5 pt-3">
                <span className="boldfont">
                  Rs 1,00,000/- (Rs One Lakh only)
                </span>
                .
              </div>
              <div className="col-5 pt-3">
                <span className="boldfont">MONTHLY ALLOWANCE AMOUNT</span>{' '}
                (towards conveyance and calls)
              </div>
              <div className="col-2 d-flex justify-content-center align-items-center pt-3">
                :
              </div>

              <div className="col-5 pt-3 d-flex align-items-center">
                <span className="boldfont">
                  Rs 12,500/- (Rs Twelve Thousand Five Hundred only)
                </span>
                .
              </div>
            </div>
          </div>
          {/* <div className="pt-3 footlightfont">
            IF you FAIL to achieve the fixed minimum target of 500 subscriptions
            every month, your monthly remuneration will only be as per what is
            mentioned below :
          </div>

          <div className="pt-4 footlightfont">
            <div className="row pxfont15">
              <div className="col-4"></div>
              <div className="col-4 d-flex justify-content-center">
                Salary&nbsp;
              </div>
              <div className="col-4">Allowance Amount</div>
              <div className="col-4 pt-3">
                1. 50 – 99 subscriptions per month
              </div>{' '}
              <div className="col-4 d-flex justify-content-center align-items-center pt-3">
                Rs 5,000/-
              </div>{' '}
              <div className="col-4 d-flex  align-items-center pt-3">
                Rs 1,000/-
              </div>{' '}
              <div className="col-4 pt-3">
                2. 100 – 199 subscriptions per month
              </div>{' '}
              <div className="col-4 d-flex justify-content-center align-items-center pt-3">
                Rs 10,000/-
              </div>{' '}
              <div className="col-4 d-flex align-items-center pt-3">
                Rs 2,000/-
              </div>{' '}
              <div className="col-4 pt-3">
                3. 200 – 299 subscriptions per month
              </div>{' '}
              <div className="col-4 d-flex justify-content-center align-items-center pt-3">
                Rs 20,000/-
              </div>{' '}
              <div className="col-4 d-flex align-items-center pt-3">
                Rs 3,000/-
              </div>{' '}
              <div className="col-4 pt-3">
                4. 300 – 399 subscriptions per month
              </div>{' '}
              <div className="col-4 d-flex justify-content-center align-items-center pt-3">
                Rs 30,000/-
              </div>{' '}
              <div className="col-4 d-flex align-items-center pt-3">
                Rs 4,000/-
              </div>{' '}
              <div className="col-4 pt-3">
                5. 400 – 499 subscriptions per month
              </div>{' '}
              <div className="col-4 d-flex justify-content-center align-items-center pt-3">
                Rs 40,000/-
              </div>{' '}
              <div className="col-4 d-flex align-items-center pt-3">
                Rs 5,000/-
              </div>
            </div>
          </div> */}

          <div className="pt-3 footlightfont">
            <span className="boldfont">Here's how it works</span> :
          </div>
          <div className="pt-3 footlightfont">
            1. APPLY to Create a SME account with us.
            <br />
            2. Via your SME account, you can :<br />
            a) See details of the profiles created by yourself.
            <br />
            b) Track your salary remittances.
          </div>
          <div className="pt-3 footlightfont ">
            <span className="boldfont">After Registeration</span> :
          </div>
          <div className="pt-3 footlightfont">
            • You can see the CATEGORIES and SUB CATEGORIES under Services and
            Products in which Profiles must be created.
            <br />• You can Sign In / Log In to www.vipar-connect.in and start
            marketing.
          </div>
          <div className="pt-3 footlightfont">
            <span className="boldfont">Referral Code</span> :
          </div>

          <div className="pt-3 footlightfont" style={{ lineSpacing: '0' }}>
            • Create a Referral Code of your choice which will be your Employee
            Identification at{' '}
            <span
              className="vipar"
              style={{
                fontSize: '22px',
                color: '#99d050',
              }}
            >
              {' '}
              Vipar
            </span>{' '}
            <sup class="">®</sup>.
          </div>
          <div className="pt-3 footlightfont" style={{ lineSpacing: '0' }}>
            • Your Unique Referral Code is the one with which
            <span
              className="vipar"
              style={{
                fontSize: '22px',
                color: '#99d050',
              }}
            >
              {' '}
              Vipar
            </span>{' '}
            <sup class="">®</sup> can identify that it is via your SME account
            that a Subscription is sold.
          </div>
          <div className="pt-3 footlightfont">
            • It is Mandatory for tracking your sales targets and disbursing
            your salary to you.
          </div>
          <div className="pt-3 footlightfont">
            • Once a Referral Code is created, it is permanent and cannot be
            changed.
          </div>

          <div className="pt-3 footlightfont">
            • Can be a combination of Alphabets, Numbers and Symbols.
          </div>
          <div className="pt-3 footlightfont">
            • Minimum Character length &#8239;: 5<br />
            &nbsp;&nbsp;Maximum Character length : 10
          </div>
          <div className="pt-3 footlightfont">
            • In case you forget your Referral Code, it will be visible to you
            on the ‘Account Details’ page of your profile.
          </div>

          <div className="pt-3 footlightfont text-center">
            <span className="boldfont ">PLEASE NOTE</span>
          </div>
          <div className="pt-3 footlightfont">
            • Kindly remember that as a SME you must be fully assured of
            achieving your daily / monthly sales targets without fail and any
            excuses.
          </div>
          <div className="pt-3 footlightfont">
            • Hence, we sincerely and strongly recommend that you begin your
            journey with us as an AME for a minimum of 2 days and then switch to
            a SME, once you gain confidence in selling our product.
          </div>

          <div className="py-3 d-flex justify-content-center">
            <Link
              style={{ textDecoration: 'none' }}
              to="/sme_registration_form"
            >
              <Button className="btnmainvipar footlightfont ">APPLY NOW</Button>
            </Link>
          </div>
        </Modal.Body>
      </Modal>
      {/* FIXED SALARY MARKETING EXECUTIVE modal */}

      {/* Intership modal */}
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton centered></Modal.Header>
        <Modal.Body>
          <div className="pt-3 footlightfont text-center ">
            <span className="prochure">PROchure</span>
            {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
            &nbsp;<span className="boldfont">SALARIED MARKETING </span>
          </div>
          <div className="pt-3 footlightfont">
            1. Click on ‘ APPLY NOW ’ below.
            <br /> 2. Register as a SME (Salaried Marketing Executive).
            <br /> 3. Get Businesses (Service Providers and Product Retailers)
            to Subscribe at <span className="prochure">PROchure</span>
            {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
            .<br />
            4. You can select any location / multiple locations of your choice
            for marketing.
          </div>
          <div className="pt-3 footlightfont">
            There are currently two types of{' '}
            <span className="boldfont">Membership Subscription</span>.
            <div className="pt-3 footlightfont">
              1. <span className="boldfont">BASIC listing</span>
              {/* <br />₹ 2000/- for the 1<sup>st</sup>&nbsp;year
              <br />₹ 500/- from the 2<sup>nd</sup>&nbsp;year onwards. */}
              <br />₹ 500/- per year including GST
            </div>
            <div className="pt-3 footlightfont">
              2. <span className="boldfont">PRIME league</span>
              {/* <br />₹ 5000/- for the 1<sup>st</sup>&nbsp;year
              <br />₹ 1000/- from the 2<sup>nd</sup>&nbsp;year onwards. */}
              <br />₹ 5000/- per year including GST
            </div>
          </div>
          <div className="pt-3 footlightfont">
            Please Note that in certain Categories / Sub Categories of Qualified
            Professionals, Profiles cannot be created under BASIC listing.
          </div>
          <div className="pt-3 footlightfont">
            Profiles of these Qualified Professionals (like Architects,
            Structural Engineers, Chartered Accountants, Doctors, Lawyers, etc)
            can be listed Only under PRIME league because of mandatory
            Professional information required from them.
          </div>
          <div className="pt-3 footlightfont">
            <span className="boldfont">Remuneration</span> :
          </div>
          <div className="pt-3 footlightfont">
            <div className="row ">
              <div className="col-5">Number of WORKING DAYS in a month </div>
              <div className="col-2 d-flex justify-content-center align-items-center">
                :
              </div>
              <div className="col-5 d-flex align-items-center ">25</div>

              <div className="col-5 pt-3 d-flex align-items-center">
                <span className="boldfont">MONTHLY SALARY</span>{' '}
              </div>
              <div className="col-2 d-flex justify-content-center align-items-center pt-3">
                :
              </div>

              <div className="col-5 pt-3">
                <span className="boldfont">
                  Rs 1,00,000/- (Rs One Lakh only)
                </span>
                .
              </div>
              <div className="col-5 pt-3">
                <span className="boldfont">MONTHLY ALLOWANCE AMOUNT</span>{' '}
                (towards conveyance and calls)
              </div>
              <div className="col-2 d-flex justify-content-center align-items-center pt-3">
                :
              </div>

              <div className="col-5 pt-3 d-flex align-items-center">
                <span className="boldfont">
                  Rs 12,500/- (Rs Twelve Thousand Five Hundred only)
                </span>
                .
              </div>
            </div>
          </div>

          <div className="pt-3 footlightfont">
            <span className="boldfont">Here's how it works</span> :
          </div>
          <div className="pt-3 footlightfont">
            1. APPLY to Create a SME account with us.
            <br />
            2. Via your SME account, you can :<br />
            a) See details of the profiles created by yourself.
            <br />
            b) Track your salary remittances.
          </div>
          <div className="pt-3 footlightfont ">
            <span className="boldfont">After Registeration</span> :
          </div>
          <div className="pt-3 footlightfont">
            • You can see the CATEGORIES and SUB CATEGORIES under Services and
            Products in which Profiles must be created.
            <br />• You can Sign In / Log In to www.vipar-connect.in and start
            marketing.
          </div>
          <div className="pt-3 footlightfont">
            <span className="boldfont">Referral Code</span> :
          </div>

          <div className="pt-3 footlightfont" style={{ lineSpacing: '0' }}>
            • Create a Referral Code of your choice which will be your Employee
            Identification at{' '}
            <span
              className="vipar"
              style={{
                fontSize: '22px',
                color: '#99d050',
              }}
            >
              {' '}
              Vipar
            </span>{' '}
            <sup class="">®</sup>.
          </div>
          <div className="pt-3 footlightfont" style={{ lineSpacing: '0' }}>
            • Your Unique Referral Code is the one with which
            <span
              className="vipar"
              style={{
                fontSize: '22px',
                color: '#99d050',
              }}
            >
              {' '}
              Vipar
            </span>{' '}
            <sup class="">®</sup> can identify that it is via your SME account
            that a Subscription is sold.
          </div>
          <div className="pt-3 footlightfont">
            • It is Mandatory for tracking your sales targets and disbursing
            your salary to you.
          </div>
          <div className="pt-3 footlightfont">
            • Once a Referral Code is created, it is permanent and cannot be
            changed.
          </div>

          <div className="pt-3 footlightfont">
            • Can be a combination of Alphabets, Numbers and Symbols.
          </div>
          <div className="pt-3 footlightfont">
            • Minimum Character length &#8239;: 5<br />
            &nbsp;&nbsp;Maximum Character length : 10
          </div>
          <div className="pt-3 footlightfont">
            • In case you forget your Referral Code, it will be visible to you
            on the ‘Account Details’ page of your profile.
          </div>

          <div className="pt-3 footlightfont text-center">
            <span className="boldfont ">PLEASE NOTE</span>
          </div>
          <div className="pt-3 footlightfont">
            • Kindly remember that as a SME you must be fully assured of
            achieving your daily / monthly sales targets without fail and any
            excuses.
          </div>
          <div className="pt-3 footlightfont">
            • Hence, we sincerely and strongly recommend that you begin your
            journey with us as an AME for a minimum of 2 days and then switch to
            a SME, once you gain confidence in selling our product.
          </div>

          <div className="py-3 d-flex justify-content-center">
            <Link
              style={{ textDecoration: 'none' }}
              to="/sme_registration_form"
            >
              <Button className="btnmainvipar footlightfont ">APPLY NOW</Button>
            </Link>
          </div>
        </Modal.Body>
      </Modal>
      {/* Intership modal */}

      {/* FSE */}
      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header className="footlightfont boldfont" closeButton centered>
          FIELD SALES Executive (Commission) Application Form
        </Modal.Header>
        <Modal.Body>
          <div className=" footlightfont">
            <div class="mb-3">
              <label
                for="exampleFormControlInput1"
                className="form-label labels"
              >
                Name *
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder=""
                onChange={(e) => {
                  setdata({ ...data, Name: e.target.value });
                }}
              />
            </div>
            <div class="mb-3">
              <label
                for="exampleFormControlInput1"
                className="form-label labels"
              >
                Age *
              </label>
              <input
                type={'number'}
                min="18"
                pattern="[0-9]{2}"
                class="form-control"
                value={data.Age}
                onChange={(e) => {
                  if (e.target.value.length > 2) return;
                  setdata({ ...data, Age: e.target.value });
                }}
              />
            </div>
            <div class="mb-3">
              <label
                for="exampleFormControlInput1"
                className="form-label labels"
              >
                Contact Number *
              </label>
              <input
                type={'number'}
                class="form-control"
                value={data.Contact}
                onChange={(e) => {
                  if (e.target.value.length > 10) return;
                  setdata({ ...data, Contact: e.target.value });
                }}
              />
            </div>
            <div class="mb-3">
              <label
                for="exampleFormControlInput1"
                className="form-label labels"
              >
                Email ID *
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder=""
                onChange={(e) => {
                  setdata({ ...data, Email: e.target.value });
                }}
              />
            </div>
            <div class="mb-3">
              <label
                for="exampleFormControlInput1"
                className="form-label labels"
              >
                Presently Residing Location *
              </label>
              <StateCity setValue={setdata} value={data} />
            </div>

            <div class="mb-3">
              <label
                for="exampleFormControlInput1"
                className="form-label labels"
              >
                Preferred Location for Sales Job *
              </label>
              <StateCity setValue={setdata1} value={data1} />
            </div>
            <p className="text-danger">{err}</p>
            <div className="mb-3">
              <button
                className="btn btn-center pro-spaces-button3"
                onClick={(e) => {
                  submit1();
                  handleClose4();
                }}
              >
                APPLY NOW
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* FSE */}

      {/* FSE THANKYOU */}
      <Modal centered show={show5} onHide={handleClose5}>
        <Modal.Body style={{ background: '#5d334e' }}>
          <div className="footlightfont text-light">
            <div className="pt-1">
              THANK YOU {fseshow?.Name} for Showing Interest !
            </div>
            <div className="pt-1">
              Our Regional Marketing Manager shall get in touch with you
              shortly.
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* FSE THANKYOU */}

      {/* CSM */}
      <Modal show={show6} onHide={handleClose6}>
        <Modal.Header closeButton centered>
          CLUSTER SALES Manager Application Form
        </Modal.Header>
        <Modal.Body>
          <div className=" footlightfont">
            <div class="mb-3">
              <label
                for="exampleFormControlInput1"
                className="form-label labels"
              >
                Name *
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder=""
                onChange={(e) => {
                  setdata({ ...data, Name: e.target.value });
                }}
              />
            </div>
            <div class="mb-3">
              <label
                for="exampleFormControlInput1"
                className="form-label labels"
              >
                Age *
              </label>
              <input
                type={'number'}
                min="18"
                pattern="[0-9]{2}"
                class="form-control"
                value={data.Age}
                onChange={(e) => {
                  if (e.target.value.length > 2) return;
                  setdata({ ...data, Age: e.target.value });
                }}
              />
            </div>
            <div class="mb-3">
              <label
                for="exampleFormControlInput1"
                className="form-label labels"
              >
                Contact Number *
              </label>
              <input
                type={'number'}
                class="form-control"
                value={data.Contact}
                onChange={(e) => {
                  if (e.target.value.length > 10) return;
                  setdata({ ...data, Contact: e.target.value });
                }}
              />
            </div>
            <div class="mb-3">
              <label
                for="exampleFormControlInput1"
                className="form-label labels"
              >
                Email ID *
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder=""
                onChange={(e) => {
                  setdata({ ...data, Email: e.target.value });
                }}
              />
            </div>
            <div class="mb-3">
              <label
                for="exampleFormControlInput1"
                className="form-label labels"
              >
                Residing Location *
              </label>
              <StateCity setValue={setdata} value={data} />
            </div>

            <div class="mb-3">
              <label
                for="exampleFormControlInput1"
                className="form-label labels"
              >
                Preferred Location for Cluster Job *
              </label>
              <StateCity setValue={setdata1} value={data1} />
            </div>
            <p className="text-danger">{err}</p>

            <div className="mb-3">
              <button
                className="btn btn-center pro-spaces-button3"
                onClick={(e) => {
                  submit();
                  handleClose6();
                }}
              >
                APPLY NOW
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* CSM */}

      {/* CSM THANKYOU */}
      <Modal centered show={show7} onHide={handleClose7}>
        <Modal.Body style={{ background: '#5d334e' }}>
          <div className="footlightfont text-light">
            <div className="pt-1">
              THANK YOU {csmshow?.Name} for Showing Interest !
            </div>
            <div className="pt-1">
              Our Regional Marketing Manager shall get in touch with you
              shortly.
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* CSM THANKYOU */}

      {/* FME */}
      <Modal show={show8} onHide={handleClose8}>
        <Modal.Header className="footlightfont boldfont" closeButton centered>
          DATA COLLECTION Executive (Salary) Application Form
        </Modal.Header>
        <Modal.Body>
          <div className=" footlightfont">
            <div class="mb-3">
              <label
                for="exampleFormControlInput1"
                className="form-label labels"
              >
                Name *
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder=""
                onChange={(e) => {
                  setdata({ ...data, Name: e.target.value });
                }}
              />
            </div>
            <div class="mb-3">
              <label
                for="exampleFormControlInput1"
                className="form-label labels"
              >
                Age *
              </label>
              <input
                type={'number'}
                class="form-control"
                id="exampleFormControlInput1"
                placeholder=""
                value={data.Age}
                onChange={(e) => {
                  if (e.target.value.length > 2) return;
                  setdata({ ...data, Age: e.target.value });
                }}
              />
            </div>
            <div class="mb-3">
              <label
                for="exampleFormControlInput1"
                className="form-label labels"
              >
                Contact Number *
              </label>
              <input
                type={'number'}
                class="form-control"
                value={data.Contact}
                onChange={(e) => {
                  if (e.target.value.length > 10) return;
                  setdata({ ...data, Contact: e.target.value });
                }}
              />
            </div>
            <div class="mb-3">
              <label
                for="exampleFormControlInput1"
                className="form-label labels"
              >
                Email ID *
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder=""
                onChange={(e) => {
                  setdata({ ...data, Email: e.target.value });
                }}
              />
            </div>
            <div class="mb-3">
              <label
                for="exampleFormControlInput1"
                className="form-label labels"
              >
                Presently Residing Location *
              </label>
              <StateCity setValue={setdata} value={data} />
            </div>

            <div class="mb-3">
              <label
                for="exampleFormControlInput1"
                className="form-label labels"
              >
                Preferred Location for Data Collection Job *
              </label>
              <StateCity setValue={setdata1} value={data1} />
            </div>
            <p className="text-danger">{err}</p>
            <div className="mb-3">
              <button
                className="btn btn-center pro-spaces-button3"
                onClick={(e) => {
                  submit2();
                  handleClose8();
                }}
              >
                APPLY NOW
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* FME */}

      {/* FME THANKYOU */}
      <Modal centered show={show9} onHide={handleClose9}>
        <Modal.Body style={{ background: '#5d334e' }}>
          <div className="footlightfont text-light">
            <div className="pt-1">
              THANK YOU {fmeshow?.Name} for Showing Interest !
            </div>
            <div className="pt-1">
              Our Regional Marketing Manager shall get in touch with you
              shortly.
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* FME THANKYOU */}
    </>
  );
};
