export const InitializeCountry = (country, state, city) => {
  if (country.current && state.current && city.current) {
    // console.log(country,state,city);
    let stateInput = state.current;
    let cityInput = city.current;

    let countryInput = country.current;
    // console.log(countryInput?.value);
    // console.log(countryInput?.target);
    // console.log(countryInput?.target?.value);
    if (countryInput?.target?.value) {
      // let selectedCountry = countryInput.target.value
      // stateInput.length = 0;
      // stateInput.options[0] = new Option('', '');
      // stateInput.selectedIndex = 0;
      // let stateData = countryData.find((e) => e.country == selectedCountry).states
      // for (var i = 0; i < stateData.length; i++) {
      //     stateInput.options[stateInput.length] = new Option(stateData[i].state, stateData[i].state);
      // }
    } else {
      countryInput.length = 0;
      countryInput.options[0] = new Option('', '');
      countryInput.selectedIndex = 0;

      for (var i = 0; i < countryData.length; i++) {
        countryInput.options[countryInput.length] = new Option(
          countryData[i].country,
          countryData[i].country
        );
      }
    }
    countryInput.addEventListener('change', (e) => {
      // console.log(e.target.value);
      if (e.target.value.length == 0) {
        return;
      }
      let selectedCountry = e.target.value;
      stateInput.length = 0;
      stateInput.options[0] = new Option('', '');
      stateInput.selectedIndex = 0;
      cityInput.length = 0;
      cityInput.options[0] = new Option('', '');
      cityInput.selectedIndex = 0;
      let stateData = countryData.find(
        (e) => e.country == selectedCountry
      ).states;
      for (var i = 0; i < stateData.length; i++) {
        stateInput.options[stateInput.length] = new Option(
          stateData[i].state,
          stateData[i].state
        );
      }
    });

    stateInput.addEventListener('change', (ev) => {
      let selectedCountry = countryInput?.value;
      let selectedState = ev.target.value;
      let stateData = countryData.find(
        (e) => e.country == selectedCountry
      ).states;
      // console.log(selectedState, stateData);
      let citiesData = stateData.find((e) => e.state == selectedState).cities;
      // console.log(citiesData);
      cityInput.length = 0;
      cityInput.options[0] = new Option('', '');
      cityInput.selectedIndex = 0;
      for (var i = 0; i < citiesData.length; i++) {
        cityInput.options[cityInput.length] = new Option(
          citiesData[i],
          citiesData[i]
        );
      }
    });
  } else {
    // console.log(country,state,city);
    // console.log('empty');
  }
};

export const ExistingData = (
  country,
  state,
  city,
  selectedCountry,
  selectedState
) => {
  let stateInput = state.current;
  let cityInput = city.current;
  let countryInput = country.current;
  // console.log(selectedCountry, selectedState);
  if (selectedCountry) {
    let stateData = countryData.find(
      (e) => e.country == selectedCountry
    )?.states;
    // console.log(selectedState, stateData);
    stateInput.length = 0;
    stateInput.options[0] = new Option('', '');
    stateInput.selectedIndex = 0;
    for (var i = 0; i < stateData?.length; i++) {
      stateInput.options[stateInput.length] = new Option(
        stateData[i].state,
        stateData[i].state
      );
    }
  }
  if (selectedState) {
    let stateData = countryData.find(
      (e) => e.country == selectedCountry
    )?.states;
    // console.log(selectedState, stateData);
    let citiesData = stateData?.find((e) => e.state == selectedState)?.cities;
    // console.log(citiesData);
    cityInput.length = 0;
    cityInput.options[0] = new Option('', '');
    cityInput.selectedIndex = 0;

    for (var i = 0; i < citiesData?.length; i++) {
      cityInput.options[cityInput.length] = new Option(
        citiesData[i],
        citiesData[i]
      );
    }
  }
};
const countryData = [
  {
    country: 'Worldwide',
    states: [
      {
        state: 'Worldwide',
        cities: ['All Cities'],
      },
    ],
  },
  {
    country: 'Select Countries',
    states: [
      {
        state: 'Select  Countries',
        cities: ['Select Cities'],
      },
    ],
  },
  {
    country: 'India',
    states: [
      {
        state: 'pan-India',
        cities: ['All Cities'],
      },
      {
        state: 'Select States',
        cities: ['Select Cities'],
      },
      {
        state: 'Andaman & Nicobar',
        cities: [
          'Alipur',
          'Andaman Island',
          'Anderson Island',
          'Arainj-Laka-Punga',
          'Austinabad',
          'Bamboo Flat',
          'Barren Island',
          'Beadonabad',
          'Betapur',
          'Bindraban',
          'Bonington',
          'Brookesabad',
          'Cadell Point',
          'Calicut',
          'Chetamale',
          'Cinque Islands',
          'Defence Island',
          'Digilpur',
          'Dolyganj',
          'Flat Island',
          'Geinyale',
          'Great Coco Island',
          'Haddo',
          'Havelock Island',
          'Henry Lawrence Island',
          'Herbertabad',
          'Hobdaypur',
          'Ilichar',
          'Ingoie',
          'Inteview Island',
          'Jangli Ghat',
          'Jhon Lawrence Island',
          'Karen',
          'Kartara',
          'KYD Islannd',
          'Landfall Island',
          'Little Andmand',
          'Little Coco Island',
          'Long Island',
          'Maimyo',
          'Malappuram',
          'Manglutan',
          'Manpur',
          'Mitha Khari',
          'Neill Island',
          'Nicobar Island',
          'North Brother Island',
          'North Passage Island',
          'North Sentinel Island',
          'Nothen Reef Island',
          'Outram Island',
          'Pahlagaon',
          'Palalankwe',
          'Passage Island',
          'Phaiapong',
          'Phoenix Island',
          'Port Blair',
          'Preparis Island',
          'Protheroepur',
          'Rangachang',
          'Rongat',
          'Rutland Island',
          'Sabari',
          'Saddle Peak',
          'Shadipur',
          'Smith Island',
          'Sound Island',
          'South Sentinel Island',
          'Spike Island',
          'Tarmugli Island',
          'Taylerabad',
          'Titaije',
          'Toibalawe',
          'Tusonabad',
          'West Island',
          'Wimberleyganj',
          'Yadita',
        ],
      },
      {
        state: 'Andhra Pradesh',
        cities: [
          'Achampet',
          'Adilabad',
          'Adoni',
          'Alampur',
          'Allagadda',
          'Alur',
          'Amalapuram',
          'Amangallu',
          'Anakapalle',
          'Anantapur',
          'Andole',
          'Araku',
          'Armoor',
          'Asifabad',
          'Aswaraopet',
          'Atmakur',
          'B. Kothakota',
          'Badvel',
          'Banaganapalle',
          'Bandar',
          'Bangarupalem',
          'Banswada',
          'Bapatla',
          'Bellampalli',
          'Bhadrachalam',
          'Bhainsa',
          'Bheemunipatnam',
          'Bhimadole',
          'Bhimavaram',
          'Bhongir',
          'Bhooragamphad',
          'Boath',
          'Bobbili',
          'Bodhan',
          'Chandoor',
          'Chavitidibbalu',
          'Chejerla',
          'Chepurupalli',
          'Cherial',
          'Chevella',
          'Chinnor',
          'Chintalapudi',
          'Chintapalle',
          'Chirala',
          'Chittoor',
          'Chodavaram',
          'Cuddapah',
          'Cumbum',
          'Darsi',
          'Devarakonda',
          'Dharmavaram',
          'Dichpalli',
          'Divi',
          'Donakonda',
          'Dronachalam',
          'East Godavari',
          'Eluru',
          'Eturnagaram',
          'Gadwal',
          'Gajapathinagaram',
          'Gajwel',
          'Garladinne',
          'Giddalur',
          'Godavari',
          'Gooty',
          'Gudivada',
          'Gudur',
          'Guntur',
          'Hindupur',
          'Hunsabad',
          'Huzurabad',
          'Huzurnagar',
          'Hyderabad',
          'Ibrahimpatnam',
          'Jaggayyapet',
          'Jagtial',
          'Jammalamadugu',
          'Jangaon',
          'Jangareddygudem',
          'Jannaram',
          'Kadiri',
          'Kaikaluru',
          'Kakinada',
          'Kalwakurthy',
          'Kalyandurg',
          'Kamalapuram',
          'Kamareddy',
          'Kambadur',
          'Kanaganapalle',
          'Kandukuru',
          'Kanigiri',
          'Karimnagar',
          'Kavali',
          'Khammam',
          'Khanapur (AP)',
          'Kodangal',
          'Koduru',
          'Koilkuntla',
          'Kollapur',
          'Kothagudem',
          'Kovvur',
          'Krishna',
          'Krosuru',
          'Kuppam',
          'Kurnool',
          'Lakkireddipalli',
          'Madakasira',
          'Madanapalli',
          'Madhira',
          'Madnur',
          'Mahabubabad',
          'Mahabubnagar',
          'Mahadevapur',
          'Makthal',
          'Mancherial',
          'Mandapeta',
          'Mangalagiri',
          'Manthani',
          'Markapur',
          'Marturu',
          'Medachal',
          'Medak',
          'Medarmetla',
          'Metpalli',
          'Mriyalguda',
          'Mulug',
          'Mylavaram',
          'Nagarkurnool',
          'Nalgonda',
          'Nallacheruvu',
          'Nampalle',
          'Nandigama',
          'Nandikotkur',
          'Nandyal',
          'Narasampet',
          'Narasaraopet',
          'Narayanakhed',
          'Narayanpet',
          'Narsapur',
          'Narsipatnam',
          'Nazvidu',
          'Nelloe',
          'Nellore',
          'Nidamanur',
          'Nirmal',
          'Nizamabad',
          'Nuguru',
          'Ongole',
          'Outsarangapalle',
          'Paderu',
          'Pakala',
          'Palakonda',
          'Paland',
          'Palmaneru',
          'Pamuru',
          'Pargi',
          'Parkal',
          'Parvathipuram',
          'Pathapatnam',
          'Pattikonda',
          'Peapalle',
          'Peddapalli',
          'Peddapuram',
          'Penukonda',
          'Piduguralla',
          'Piler',
          'Pithapuram',
          'Podili',
          'Polavaram',
          'Prakasam',
          'Proddatur',
          'Pulivendla',
          'Punganur',
          'Putturu',
          'Rajahmundri',
          'Rajampeta',
          'Ramachandrapuram',
          'Ramannapet',
          'Rampachodavaram',
          'Rangareddy',
          'Rapur',
          'Rayachoti',
          'Rayadurg',
          'Razole',
          'Repalle',
          'Saluru',
          'Sangareddy',
          'Sathupalli',
          'Sattenapalle',
          'Satyavedu',
          'Shadnagar',
          'Siddavattam',
          'Siddipet',
          'Sileru',
          'Sircilla',
          'Sirpur Kagaznagar',
          'Sodam',
          'Sompeta',
          'Srikakulam',
          'Srikalahasthi',
          'Srisailam',
          'Srungavarapukota',
          'Sudhimalla',
          'Sullarpet',
          'Tadepalligudem',
          'Tadipatri',
          'Tanduru',
          'Tanuku',
          'Tekkali',
          'Tenali',
          'Thungaturthy',
          'Tirivuru',
          'Tirupathi',
          'Tuni',
          'Udaygiri',
          'Ulvapadu',
          'Uravakonda',
          'Utnor',
          'V.R. Puram',
          'Vaimpalli',
          'Vayalpad',
          'Venkatgiri',
          'Venkatgirikota',
          'Vijayawada',
          'Vikrabad',
          'Vinjamuru',
          'Vinukonda',
          'Visakhapatnam',
          'Vizayanagaram',
          'Vizianagaram',
          'Vuyyuru',
          'Wanaparthy',
          'Warangal',
          'Wardhannapet',
          'Yelamanchili',
          'Yelavaram',
          'Yeleswaram',
          'Yellandu',
          'Yellanuru',
          'Yellareddy',
          'Yerragondapalem',
          'Zahirabad',
        ],
      },
      {
        state: 'Arunachal Pradesh',
        cities: [
          'Along',
          'Anini',
          'Anjaw',
          'Bameng',
          'Basar',
          'Changlang',
          'Chowkhem',
          'Daporizo',
          'Dibang Valley',
          'Dirang',
          'Hayuliang',
          'Huri',
          'Itanagar',
          'Jairampur',
          'Kalaktung',
          'Kameng',
          'Khonsa',
          'Kolaring',
          'Kurung Kumey',
          'Lohit',
          'Lower Dibang Valley',
          'Lower Subansiri',
          'Mariyang',
          'Mechuka',
          'Miao',
          'Nefra',
          'Pakkekesang',
          'Pangin',
          'Papum Pare',
          'Passighat',
          'Roing',
          'Sagalee',
          'Seppa',
          'Siang',
          'Tali',
          'Taliha',
          'Tawang',
          'Tezu',
          'Tirap',
          'Tuting',
          'Upper Siang',
          'Upper Subansiri',
          'Yiang Kiag',
        ],
      },
      {
        state: 'Assam',
        cities: [
          'Abhayapuri',
          'Baithalangshu',
          'Barama',
          'Barpeta Road',
          'Bihupuria',
          'Bijni',
          'Bilasipara',
          'Bokajan',
          'Bokakhat',
          'Boko',
          'Bongaigaon',
          'Cachar',
          'Cachar Hills',
          'Darrang',
          'Dhakuakhana',
          'Dhemaji',
          'Dhubri',
          'Dibrugarh',
          'Digboi',
          'Diphu',
          'Goalpara',
          'Gohpur',
          'Golaghat',
          'Guwahati',
          'Hailakandi',
          'Hajo',
          'Halflong',
          'Hojai',
          'Howraghat',
          'Jorhat',
          'Kamrup',
          'Karbi Anglong',
          'Karimganj',
          'Kokarajhar',
          'Kokrajhar',
          'Lakhimpur',
          'Maibong',
          'Majuli',
          'Mangaldoi',
          'Mariani',
          'Marigaon',
          'Moranhat',
          'Morigaon',
          'Nagaon',
          'Nalbari',
          'Rangapara',
          'Sadiya',
          'Sibsagar',
          'Silchar',
          'Sivasagar',
          'Sonitpur',
          'Tarabarihat',
          'Tezpur',
          'Tinsukia',
          'Udalgiri',
          'Udalguri',
          'UdarbondhBarpeta',
        ],
      },
      {
        state: 'Bihar',
        cities: [
          'Adhaura',
          'Amarpur',
          'Araria',
          'Areraj',
          'Arrah',
          'Arwal',
          'Aurangabad',
          'Bagaha',
          'Banka',
          'Banmankhi',
          'Barachakia',
          'Barauni',
          'Barh',
          'Barosi',
          'Begusarai',
          'Benipatti',
          'Benipur',
          'Bettiah',
          'Bhabhua',
          'Bhagalpur',
          'Bhojpur',
          'Bidupur',
          'Biharsharif',
          'Bikram',
          'Bikramganj',
          'Birpur',
          'Buxar',
          'Chakai',
          'Champaran',
          'Chapara',
          'Dalsinghsarai',
          'Danapur',
          'Darbhanga',
          'Daudnagar',
          'Dhaka',
          'Dhamdaha',
          'Dumraon',
          'Ekma',
          'Forbesganj',
          'Gaya',
          'Gogri',
          'Gopalganj',
          'H.Kharagpur',
          'Hajipur',
          'Hathua',
          'Hilsa',
          'Imamganj',
          'Jahanabad',
          'Jainagar',
          'Jamshedpur',
          'Jamui',
          'Jehanabad',
          'Jhajha',
          'Jhanjharpur',
          'Kahalgaon',
          'Kaimur (Bhabua)',
          'Katihar',
          'Katoria',
          'Khagaria',
          'Kishanganj',
          'Korha',
          'Lakhisarai',
          'Madhepura',
          'Madhubani',
          'Maharajganj',
          'Mahua',
          'Mairwa',
          'Mallehpur',
          'Masrakh',
          'Mohania',
          'Monghyr',
          'Motihari',
          'Motipur',
          'Munger',
          'Muzaffarpur',
          'Nabinagar',
          'Nalanda',
          'Narkatiaganj',
          'Naugachia',
          'Nawada',
          'Pakribarwan',
          'Pakridayal',
          'Patna',
          'Phulparas',
          'Piro',
          'Pupri',
          'Purena',
          'Purnia',
          'Rafiganj',
          'Rajauli',
          'Ramnagar',
          'Raniganj',
          'Raxaul',
          'Rohtas',
          'Rosera',
          'S.Bakhtiarpur',
          'Saharsa',
          'Samastipur',
          'Saran',
          'Sasaram',
          'Seikhpura',
          'Sheikhpura',
          'Sheohar',
          'Sherghati',
          'Sidhawalia',
          'Singhwara',
          'Sitamarhi',
          'Siwan',
          'Sonepur',
          'Supaul',
          'Thakurganj',
          'Triveniganj',
          'Udakishanganj',
          'Vaishali',
          'Wazirganj',
        ],
      },
      {
        state: 'Chandigarh',
        cities: ['Chandigarh', 'Mani Marja'],
      },
      {
        state: 'Chhattisgarh',
        cities: [
          'Ambikapur',
          'Antagarh',
          'Arang',
          'Bacheli',
          'Bagbahera',
          'Bagicha',
          'Baikunthpur',
          'Balod',
          'Balodabazar',
          'Balrampur',
          'Barpalli',
          'Basana',
          'Bastanar',
          'Bastar',
          'Bderajpur',
          'Bemetara',
          'Berla',
          'Bhairongarh',
          'Bhanupratappur',
          'Bharathpur',
          'Bhatapara',
          'Bhilai',
          'Bhilaigarh',
          'Bhopalpatnam',
          'Bijapur',
          'Bilaspur',
          'Bodla',
          'Bokaband',
          'Chandipara',
          'Chhinagarh',
          'Chhuriakala',
          'Chingmut',
          'Chuikhadan',
          'Dabhara',
          'Dallirajhara',
          'Dantewada',
          'Deobhog',
          'Dhamda',
          'Dhamtari',
          'Dharamjaigarh',
          'Dongargarh',
          'Durg',
          'Durgakondal',
          'Fingeshwar',
          'Gariaband',
          'Garpa',
          'Gharghoda',
          'Gogunda',
          'Ilamidi',
          'Jagdalpur',
          'Janjgir',
          'Janjgir-Champa',
          'Jarwa',
          'Jashpur',
          'Jashpurnagar',
          'Kabirdham-Kawardha',
          'Kanker',
          'Kasdol',
          'Kathdol',
          'Kathghora',
          'Kawardha',
          'Keskal',
          'Khairgarh',
          'Kondagaon',
          'Konta',
          'Korba',
          'Korea',
          'Kota',
          'Koyelibeda',
          'Kuakunda',
          'Kunkuri',
          'Kurud',
          'Lohadigundah',
          'Lormi',
          'Luckwada',
          'Mahasamund',
          'Makodi',
          'Manendragarh',
          'Manpur',
          'Marwahi',
          'Mohla',
          'Mungeli',
          'Nagri',
          'Narainpur',
          'Narayanpur',
          'Neora',
          'Netanar',
          'Odgi',
          'Padamkot',
          'Pakhanjur',
          'Pali',
          'Pandaria',
          'Pandishankar',
          'Parasgaon',
          'Pasan',
          'Patan',
          'Pathalgaon',
          'Pendra',
          'Pratappur',
          'Premnagar',
          'Raigarh',
          'Raipur',
          'Rajnandgaon',
          'Rajpur',
          'Ramchandrapur',
          'Saraipali',
          'Saranggarh',
          'Sarona',
          'Semaria',
          'Shakti',
          'Sitapur',
          'Sukma',
          'Surajpur',
          'Surguja',
          'Tapkara',
          'Toynar',
          'Udaipur',
          'Uproda',
          'Wadrainagar',
        ],
      },
      {
        state: 'Dadra & Nagar Haveli',
        cities: [
          'Amal',
          'Amli',
          'Bedpa',
          'Chikhli',
          'Dadra & Nagar Haveli',
          'Dahikhed',
          'Dolara',
          'Galonda',
          'Kanadi',
          'Karchond',
          'Khadoli',
          'Kharadpada',
          'Kherabari',
          'Kherdi',
          'Kothar',
          'Luari',
          'Mashat',
          'Rakholi',
          'Rudana',
          'Saili',
          'Sili',
          'Silvassa',
          'Sindavni',
          'Udva',
          'Umbarkoi',
          'Vansda',
          'Vasona',
          'Velugam',
        ],
      },
      {
        state: 'Daman & Diu',
        cities: [
          'Brancavare',
          'Dagasi',
          'Daman',
          'Diu',
          'Magarvara',
          'Nagwa',
          'Pariali',
          'Passo Covo',
        ],
      },
      {
        state: 'Delhi',
        cities: [
          'Central Delhi',
          'East Delhi',
          'New Delhi',
          'North Delhi',
          'North East Delhi',
          'North West Delhi',
          'South Delhi',
          'South West Delhi',
          'West Delhi',
        ],
      },
      {
        state: 'Goa',
        cities: [
          'Canacona',
          'Candolim',
          'Chinchinim',
          'Cortalim',
          'Goa',
          'Jua',
          'Madgaon',
          'Mahem',
          'Mapuca',
          'Marmagao',
          'Panji',
          'Ponda',
          'Sanvordem',
          'Terekhol',
        ],
      },
      {
        state: 'Gujarat',
        cities: [
          'Ahmedabad',
          'Ahwa',
          'Amod',
          'Amreli',
          'Anand',
          'Anjar',
          'Ankaleshwar',
          'Babra',
          'Balasinor',
          'Banaskantha',
          'Bansada',
          'Bardoli',
          'Bareja',
          'Baroda',
          'Barwala',
          'Bayad',
          'Bhachav',
          'Bhanvad',
          'Bharuch',
          'Bhavnagar',
          'Bhiloda',
          'Bhuj',
          'Billimora',
          'Borsad',
          'Botad',
          'Chanasma',
          'Chhota Udaipur',
          'Chotila',
          'Dabhoi',
          'Dahod',
          'Damnagar',
          'Dang',
          'Danta',
          'Dasada',
          'Dediapada',
          'Deesa',
          'Dehgam',
          'Deodar',
          'Devgadhbaria',
          'Dhandhuka',
          'Dhanera',
          'Dharampur',
          'Dhari',
          'Dholka',
          'Dhoraji',
          'Dhrangadhra',
          'Dhrol',
          'Dwarka',
          'Fortsongadh',
          'Gadhada',
          'Gandhi Nagar',
          'Gariadhar',
          'Godhra',
          'Gogodar',
          'Gondal',
          'Halol',
          'Halvad',
          'Harij',
          'Himatnagar',
          'Idar',
          'Jambusar',
          'Jamjodhpur',
          'Jamkalyanpur',
          'Jamnagar',
          'Jasdan',
          'Jetpur',
          'Jhagadia',
          'Jhalod',
          'Jodia',
          'Junagadh',
          'Junagarh',
          'Kalawad',
          'Kalol',
          'Kapad Wanj',
          'Keshod',
          'Khambat',
          'Khambhalia',
          'Khavda',
          'Kheda',
          'Khedbrahma',
          'Kheralu',
          'Kodinar',
          'Kotdasanghani',
          'Kunkawav',
          'Kutch',
          'Kutchmandvi',
          'Kutiyana',
          'Lakhpat',
          'Lakhtar',
          'Lalpur',
          'Limbdi',
          'Limkheda',
          'Lunavada',
          'M.M.Mangrol',
          'Mahuva',
          'Malia-Hatina',
          'Maliya',
          'Malpur',
          'Manavadar',
          'Mandvi',
          'Mangrol',
          'Mehmedabad',
          'Mehsana',
          'Miyagam',
          'Modasa',
          'Morvi',
          'Muli',
          'Mundra',
          'Nadiad',
          'Nakhatrana',
          'Nalia',
          'Narmada',
          'Naswadi',
          'Navasari',
          'Nizar',
          'Okha',
          'Paddhari',
          'Padra',
          'Palanpur',
          'Palitana',
          'Panchmahals',
          'Patan',
          'Pavijetpur',
          'Porbandar',
          'Prantij',
          'Radhanpur',
          'Rahpar',
          'Rajaula',
          'Rajkot',
          'Rajpipla',
          'Ranavav',
          'Sabarkantha',
          'Sanand',
          'Sankheda',
          'Santalpur',
          'Santrampur',
          'Savarkundla',
          'Savli',
          'Sayan',
          'Sayla',
          'Shehra',
          'Sidhpur',
          'Sihor',
          'Sojitra',
          'Sumrasar',
          'Surat',
          'Surendranagar',
          'Talaja',
          'Thara',
          'Tharad',
          'Thasra',
          'Una-Diu',
          'Upleta',
          'Vadgam',
          'Vadodara',
          'Valia',
          'Vallabhipur',
          'Valod',
          'Valsad',
          'Vanthali',
          'Vapi',
          'Vav',
          'Veraval',
          'Vijapur',
          'Viramgam',
          'Visavadar',
          'Visnagar',
          'Vyara',
          'Waghodia',
          'Wankaner',
        ],
      },
      {
        state: 'Haryana',
        cities: [
          'Adampur Mandi',
          'Ambala',
          'Assandh',
          'Bahadurgarh',
          'Barara',
          'Barwala',
          'Bawal',
          'Bawanikhera',
          'Bhiwani',
          'Charkhidadri',
          'Cheeka',
          'Chhachrauli',
          'Dabwali',
          'Ellenabad',
          'Faridabad',
          'Fatehabad',
          'Ferojpur Jhirka',
          'Gharaunda',
          'Gohana',
          'Gurgaon',
          'Hansi',
          'Hisar',
          'Jagadhari',
          'Jatusana',
          'Jhajjar',
          'Jind',
          'Julana',
          'Kaithal',
          'Kalanaur',
          'Kalanwali',
          'Kalka',
          'Karnal',
          'Kosli',
          'Kurukshetra',
          'Loharu',
          'Mahendragarh',
          'Meham',
          'Mewat',
          'Mohindergarh',
          'Naraingarh',
          'Narnaul',
          'Narwana',
          'Nilokheri',
          'Nuh',
          'Palwal',
          'Panchkula',
          'Panipat',
          'Pehowa',
          'Ratia',
          'Rewari',
          'Rohtak',
          'Safidon',
          'Sirsa',
          'Siwani',
          'Sonipat',
          'Tohana',
          'Tohsam',
          'Yamunanagar',
        ],
      },
      {
        state: 'Himachal Pradesh',
        cities: [
          'Amb',
          'Arki',
          'Banjar',
          'Bharmour',
          'Bilaspur',
          'Chamba',
          'Churah',
          'Dalhousie',
          'Dehra Gopipur',
          'Hamirpur',
          'Jogindernagar',
          'Kalpa',
          'Kangra',
          'Kinnaur',
          'Kullu',
          'Lahaul',
          'Mandi',
          'Nahan',
          'Nalagarh',
          'Nirmand',
          'Nurpur',
          'Palampur',
          'Pangi',
          'Paonta',
          'Pooh',
          'Rajgarh',
          'Rampur Bushahar',
          'Rohru',
          'Shimla',
          'Sirmaur',
          'Solan',
          'Spiti',
          'Sundernagar',
          'Theog',
          'Udaipur',
          'Una',
        ],
      },
      {
        state: 'Jammu & Kashmir',
        cities: [
          'Akhnoor',
          'Anantnag',
          'Badgam',
          'Bandipur',
          'Baramulla',
          'Basholi',
          'Bedarwah',
          'Budgam',
          'Doda',
          'Gulmarg',
          'Jammu',
          'Kalakot',
          'Kargil',
          'Karnah',
          'Kathua',
          'Kishtwar',
          'Kulgam',
          'Kupwara',
          'Leh',
          'Mahore',
          'Nagrota',
          'Nobra',
          'Nowshera',
          'Nyoma',
          'Padam',
          'Pahalgam',
          'Patnitop',
          'Poonch',
          'Pulwama',
          'Rajouri',
          'Ramban',
          'Ramnagar',
          'Reasi',
          'Samba',
          'Srinagar',
          'Udhampur',
          'Vaishno Devi',
        ],
      },
      {
        state: 'Jharkhand',
        cities: [
          'Bagodar',
          'Baharagora',
          'Balumath',
          'Barhi',
          'Barkagaon',
          'Barwadih',
          'Basia',
          'Bermo',
          'Bhandaria',
          'Bhawanathpur',
          'Bishrampur',
          'Bokaro',
          'Bolwa',
          'Bundu',
          'Chaibasa',
          'Chainpur',
          'Chakardharpur',
          'Chandil',
          'Chatra',
          'Chavparan',
          'Daltonganj',
          'Deoghar',
          'Dhanbad',
          'Dumka',
          'Dumri',
          'Garhwa',
          'Garu',
          'Ghaghra',
          'Ghatsila',
          'Giridih',
          'Godda',
          'Gomia',
          'Govindpur',
          'Gumla',
          'Hazaribagh',
          'Hunterganj',
          'Ichak',
          'Itki',
          'Jagarnathpur',
          'Jamshedpur',
          'Jamtara',
          'Japla',
          'Jharmundi',
          'Jhinkpani',
          'Jhumaritalaiya',
          'Kathikund',
          'Kharsawa',
          'Khunti',
          'Koderma',
          'Kolebira',
          'Latehar',
          'Lohardaga',
          'Madhupur',
          'Mahagama',
          'Maheshpur Raj',
          'Mandar',
          'Mandu',
          'Manoharpur',
          'Muri',
          'Nagarutatri',
          'Nala',
          'Noamundi',
          'Pakur',
          'Palamu',
          'Palkot',
          'Patan',
          'Rajdhanwar',
          'Rajmahal',
          'Ramgarh',
          'Ranchi',
          'Sahibganj',
          'Saraikela',
          'Simaria',
          'Simdega',
          'Singhbhum',
          'Tisri',
          'Torpa',
        ],
      },
      {
        state: 'Karnataka',
        cities: [
          'Afzalpur',
          'Ainapur',
          'Aland',
          'Alur',
          'Anekal',
          'Ankola',
          'Arsikere',
          'Athani',
          'Aurad',
          'Bableshwar',
          'Badami',
          'Bagalkot',
          'Bagepalli',
          'Bailhongal',
          'Bangalore',
          'Bangalore Rural',
          'Bangarpet',
          'Bantwal',
          'Basavakalyan',
          'Basavanabagewadi',
          'Basavapatna',
          'Belgaum',
          'Bellary',
          'Belthangady',
          'Belur',
          'Bhadravati',
          'Bhalki',
          'Bhatkal',
          'Bidar',
          'Bijapur',
          'Biligi',
          'Chadchan',
          'Challakere',
          'Chamrajnagar',
          'Channagiri',
          'Channapatna',
          'Channarayapatna',
          'Chickmagalur',
          'Chikballapur',
          'Chikkaballapur',
          'Chikkanayakanahalli',
          'Chikkodi',
          'Chikmagalur',
          'Chincholi',
          'Chintamani',
          'Chitradurga',
          'Chittapur',
          'Cowdahalli',
          'Davanagere',
          'Deodurga',
          'Devangere',
          'Devarahippargi',
          'Dharwad',
          'Doddaballapur',
          'Gadag',
          'Gangavathi',
          'Gokak',
          'Gowribdanpur',
          'Gubbi',
          'Gulbarga',
          'Gundlupet',
          'H.B.Halli',
          'H.D. Kote',
          'Haliyal',
          'Hampi',
          'Hangal',
          'Harapanahalli',
          'Hassan',
          'Haveri',
          'Hebri',
          'Hirekerur',
          'Hiriyur',
          'Holalkere',
          'Holenarsipur',
          'Honnali',
          'Honnavar',
          'Hosadurga',
          'Hosakote',
          'Hosanagara',
          'Hospet',
          'Hubli',
          'Hukkeri',
          'Humnabad',
          'Hungund',
          'Hunsagi',
          'Hunsur',
          'Huvinahadagali',
          'Indi',
          'Jagalur',
          'Jamkhandi',
          'Jewargi',
          'Joida',
          'K.R. Nagar',
          'Kadur',
          'Kalghatagi',
          'Kamalapur',
          'Kanakapura',
          'Kannada',
          'Kargal',
          'Karkala',
          'Karwar',
          'Khanapur',
          'Kodagu',
          'Kolar',
          'Kollegal',
          'Koppa',
          'Koppal',
          'Koratageri',
          'Krishnarajapet',
          'Kudligi',
          'Kumta',
          'Kundapur',
          'Kundgol',
          'Kunigal',
          'Kurugodu',
          'Kustagi',
          'Lingsugur',
          'Madikeri',
          'Madugiri',
          'Malavalli',
          'Malur',
          'Mandya',
          'Mangalore',
          'Manipal',
          'Manvi',
          'Mashal',
          'Molkalmuru',
          'Mudalgi',
          'Muddebihal',
          'Mudhol',
          'Mudigere',
          'Mulbagal',
          'Mundagod',
          'Mundargi',
          'Murugod',
          'Mysore',
          'Nagamangala',
          'Nanjangud',
          'Nargund',
          'Narsimrajapur',
          'Navalgund',
          'Nelamangala',
          'Nimburga',
          'Pandavapura',
          'Pavagada',
          'Puttur',
          'Raibag',
          'Raichur',
          'Ramdurg',
          'Ranebennur',
          'Ron',
          'Sagar',
          'Sakleshpur',
          'Salkani',
          'Sandur',
          'Saundatti',
          'Savanur',
          'Sedam',
          'Shahapur',
          'Shankarnarayana',
          'Shikaripura',
          'Shimoga',
          'Shirahatti',
          'Shorapur',
          'Siddapur',
          'Sidlaghatta',
          'Sindagi',
          'Sindhanur',
          'Sira',
          'Sirsi',
          'Siruguppa',
          'Somwarpet',
          'Sorab',
          'Sringeri',
          'Sriniwaspur',
          'Srirangapatna',
          'Sullia',
          'T. Narsipur',
          'Tallak',
          'Tarikere',
          'Telgi',
          'Thirthahalli',
          'Tiptur',
          'Tumkur',
          'Turuvekere',
          'Udupi',
          'Virajpet',
          'Wadi',
          'Yadgiri',
          'Yelburga',
          'Yellapur',
        ],
      },
      {
        state: 'Kerala',
        cities: [
          'Adimaly',
          'Adoor',
          'Agathy',
          'Alappuzha',
          'Alathur',
          'Alleppey',
          'Alwaye',
          'Amini',
          'Androth',
          'Attingal',
          'Badagara',
          'Bitra',
          'Calicut',
          'Cannanore',
          'Chetlet',
          'Ernakulam',
          'Idukki',
          'Irinjalakuda',
          'Kadamath',
          'Kalpeni',
          'Kalpetta',
          'Kanhangad',
          'Kanjirapally',
          'Kannur',
          'Karungapally',
          'Kasargode',
          'Kavarathy',
          'Kiltan',
          'Kochi',
          'Koduvayur',
          'Kollam',
          'Kottayam',
          'Kovalam',
          'Kozhikode',
          'Kunnamkulam',
          'Malappuram',
          'Mananthodi',
          'Manjeri',
          'Mannarghat',
          'Mavelikkara',
          'Minicoy',
          'Munnar',
          'Muvattupuzha',
          'Nedumandad',
          'Nedumgandam',
          'Nilambur',
          'Palai',
          'Palakkad',
          'Palghat',
          'Pathaanamthitta',
          'Pathanamthitta',
          'Payyanur',
          'Peermedu',
          'Perinthalmanna',
          'Perumbavoor',
          'Punalur',
          'Quilon',
          'Ranni',
          'Shertallai',
          'Shoranur',
          'Taliparamba',
          'Tellicherry',
          'Thiruvananthapuram',
          'Thodupuzha',
          'Thrissur',
          'Tirur',
          'Tiruvalla',
          'Trichur',
          'Trivandrum',
          'Uppala',
          'Vadakkanchery',
          'Vikom',
          'Wayanad',
        ],
      },
      {
        state: 'Lakshadweep',
        cities: [
          'Agatti Island',
          'Bingaram Island',
          'Bitra Island',
          'Chetlat Island',
          'Kadmat Island',
          'Kalpeni Island',
          'Kavaratti Island',
          'Kiltan Island',
          'Lakshadweep Sea',
          'Minicoy Island',
          'North Island',
          'South Island',
        ],
      },
      {
        state: 'Ladakh',
        cities: ['Kargil', 'Leh'],
      },
      {
        state: 'Madhya Pradesh',
        cities: [
          'Agar',
          'Ajaigarh',
          'Alirajpur',
          'Amarpatan',
          'Amarwada',
          'Ambah',
          'Anuppur',
          'Arone',
          'Ashoknagar',
          'Ashta',
          'Atner',
          'Babaichichli',
          'Badamalhera',
          'Badarwsas',
          'Badnagar',
          'Badnawar',
          'Badwani',
          'Bagli',
          'Baihar',
          'Balaghat',
          'Baldeogarh',
          'Baldi',
          'Bamori',
          'Banda',
          'Bandhavgarh',
          'Bareli',
          'Baroda',
          'Barwaha',
          'Barwani',
          'Batkakhapa',
          'Begamganj',
          'Beohari',
          'Berasia',
          'Berchha',
          'Betul',
          'Bhainsdehi',
          'Bhander',
          'Bhanpura',
          'Bhikangaon',
          'Bhimpur',
          'Bhind',
          'Bhitarwar',
          'Bhopal',
          'Biaora',
          'Bijadandi',
          'Bijawar',
          'Bijaypur',
          'Bina',
          'Birsa',
          'Birsinghpur',
          'Budhni',
          'Burhanpur',
          'Buxwaha',
          'Chachaura',
          'Chanderi',
          'Chaurai',
          'Chhapara',
          'Chhatarpur',
          'Chhindwara',
          'Chicholi',
          'Chitrangi',
          'Churhat',
          'Dabra',
          'Damoh',
          'Datia',
          'Deori',
          'Deosar',
          'Depalpur',
          'Dewas',
          'Dhar',
          'Dharampuri',
          'Dindori',
          'Gadarwara',
          'Gairatganj',
          'Ganjbasoda',
          'Garoth',
          'Ghansour',
          'Ghatia',
          'Ghatigaon',
          'Ghorandogri',
          'Ghughari',
          'Gogaon',
          'Gohad',
          'Goharganj',
          'Gopalganj',
          'Gotegaon',
          'Gourihar',
          'Guna',
          'Gunnore',
          'Gwalior',
          'Gyraspur',
          'Hanumana',
          'Harda',
          'Harrai',
          'Harsud',
          'Hatta',
          'Hoshangabad',
          'Ichhawar',
          'Indore',
          'Isagarh',
          'Itarsi',
          'Jabalpur',
          'Jabera',
          'Jagdalpur',
          'Jaisinghnagar',
          'Jaithari',
          'Jaitpur',
          'Jaitwara',
          'Jamai',
          'Jaora',
          'Jatara',
          'Jawad',
          'Jhabua',
          'Jobat',
          'Jora',
          'Kakaiya',
          'Kannod',
          'Kannodi',
          'Karanjia',
          'Kareli',
          'Karera',
          'Karhal',
          'Karpa',
          'Kasrawad',
          'Katangi',
          'Katni',
          'Keolari',
          'Khachrod',
          'Khajuraho',
          'Khakner',
          'Khalwa',
          'Khandwa',
          'Khaniadhana',
          'Khargone',
          'Khategaon',
          'Khetia',
          'Khilchipur',
          'Khirkiya',
          'Khurai',
          'Kolaras',
          'Kotma',
          'Kukshi',
          'Kundam',
          'Kurwai',
          'Kusmi',
          'Laher',
          'Lakhnadon',
          'Lamta',
          'Lanji',
          'Lateri',
          'Laundi',
          'Maheshwar',
          'Mahidpurcity',
          'Maihar',
          'Majhagwan',
          'Majholi',
          'Malhargarh',
          'Manasa',
          'Manawar',
          'Mandla',
          'Mandsaur',
          'Manpur',
          'Mauganj',
          'Mawai',
          'Mehgaon',
          'Mhow',
          'Morena',
          'Multai',
          'Mungaoli',
          'Nagod',
          'Nainpur',
          'Narsingarh',
          'Narsinghpur',
          'Narwar',
          'Nasrullaganj',
          'Nateran',
          'Neemuch',
          'Niwari',
          'Niwas',
          'Nowgaon',
          'Pachmarhi',
          'Pandhana',
          'Pandhurna',
          'Panna',
          'Parasia',
          'Patan',
          'Patera',
          'Patharia',
          'Pawai',
          'Petlawad',
          'Pichhore',
          'Piparia',
          'Pohari',
          'Prabhapattan',
          'Punasa',
          'Pushprajgarh',
          'Raghogarh',
          'Raghunathpur',
          'Rahatgarh',
          'Raisen',
          'Rajgarh',
          'Rajpur',
          'Ratlam',
          'Rehli',
          'Rewa',
          'Sabalgarh',
          'Sagar',
          'Sailana',
          'Sanwer',
          'Sarangpur',
          'Sardarpur',
          'Satna',
          'Saunsar',
          'Sehore',
          'Sendhwa',
          'Seondha',
          'Seoni',
          'Seonimalwa',
          'Shahdol',
          'Shahnagar',
          'Shahpur',
          'Shajapur',
          'Sheopur',
          'Sheopurkalan',
          'Shivpuri',
          'Shujalpur',
          'Sidhi',
          'Sihora',
          'Silwani',
          'Singrauli',
          'Sirmour',
          'Sironj',
          'Sitamau',
          'Sohagpur',
          'Sondhwa',
          'Sonkatch',
          'Susner',
          'Tamia',
          'Tarana',
          'Tendukheda',
          'Teonthar',
          'Thandla',
          'Tikamgarh',
          'Timarani',
          'Udaipura',
          'Ujjain',
          'Umaria',
          'Umariapan',
          'Vidisha',
          'Vijayraghogarh',
          'Waraseoni',
          'Zhirnia',
        ],
      },
      {
        state: 'Maharashtra',
        cities: [
          'Achalpur',
          'Aheri',
          'Ahmednagar',
          'Ahmedpur',
          'Ajara',
          'Akkalkot',
          'Akola',
          'Akole',
          'Akot',
          'Alibagh',
          'Amagaon',
          'Amalner',
          'Ambad',
          'Ambejogai',
          'Amravati',
          'Arjuni Merogaon',
          'Arvi',
          'Ashti',
          'Atpadi',
          'Aurangabad',
          'Ausa',
          'Babhulgaon',
          'Balapur',
          'Baramati',
          'Barshi Takli',
          'Barsi',
          'Basmatnagar',
          'Bassein',
          'Beed',
          'Bhadrawati',
          'Bhamregadh',
          'Bhandara',
          'Bhir',
          'Bhiwandi',
          'Bhiwapur',
          'Bhokar',
          'Bhokardan',
          'Bhoom',
          'Bhor',
          'Bhudargad',
          'Bhusawal',
          'Billoli',
          'Brahmapuri',
          'Buldhana',
          'Butibori',
          'Chalisgaon',
          'Chamorshi',
          'Chandgad',
          'Chandrapur',
          'Chandur',
          'Chanwad',
          'Chhikaldara',
          'Chikhali',
          'Chinchwad',
          'Chiplun',
          'Chopda',
          'Chumur',
          'Dahanu',
          'Dapoli',
          'Darwaha',
          'Daryapur',
          'Daund',
          'Degloor',
          'Delhi Tanda',
          'Deogad',
          'Deolgaonraja',
          'Deori',
          'Desaiganj',
          'Dhadgaon',
          'Dhanora',
          'Dharani',
          'Dhiwadi',
          'Dhule',
          'Dhulia',
          'Digras',
          'Dindori',
          'Edalabad',
          'Erandul',
          'Etapalli',
          'Gadhchiroli',
          'Gadhinglaj',
          'Gaganbavada',
          'Gangakhed',
          'Gangapur',
          'Gevrai',
          'Ghatanji',
          'Golegaon',
          'Gondia',
          'Gondpipri',
          'Goregaon',
          'Guhagar',
          'Hadgaon',
          'Hatkangale',
          'Hinganghat',
          'Hingoli',
          'Hingua',
          'Igatpuri',
          'Indapur',
          'Islampur',
          'Jalgaon',
          'Jalna',
          'Jamkhed',
          'Jamner',
          'Jath',
          'Jawahar',
          'Jintdor',
          'Junnar',
          'Kagal',
          'Kaij',
          'Kalamb',
          'Kalamnuri',
          'Kallam',
          'Kalmeshwar',
          'Kalwan',
          'Kalyan',
          'Kamptee',
          'Kandhar',
          'Kankavali',
          'Kannad',
          'Karad',
          'Karjat',
          'Karmala',
          'Katol',
          'Kavathemankal',
          'Kedgaon',
          'Khadakwasala',
          'Khamgaon',
          'Khed',
          'Khopoli',
          'Khultabad',
          'Kinwat',
          'Kolhapur',
          'Kopargaon',
          'Koregaon',
          'Kudal',
          'Kuhi',
          'Kurkheda',
          'Kusumba',
          'Lakhandur',
          'Langa',
          'Latur',
          'Lonar',
          'Lonavala',
          'Madangad',
          'Madha',
          'Mahabaleshwar',
          'Mahad',
          'Mahagaon',
          'Mahasala',
          'Mahaswad',
          'Malegaon',
          'Malgaon',
          'Malgund',
          'Malkapur',
          'Malsuras',
          'Malwan',
          'Mancher',
          'Mangalwedha',
          'Mangaon',
          'Mangrulpur',
          'Manjalegaon',
          'Manmad',
          'Maregaon',
          'Mehda',
          'Mekhar',
          'Mohadi',
          'Mohol',
          'Mokhada',
          'Morshi',
          'Mouda',
          'Mukhed',
          'Mul',
          'Mumbai',
          'Murbad',
          'Murtizapur',
          'Murud',
          'Nagbhir',
          'Nagpur',
          'Nahavara',
          'Nanded',
          'Nandgaon',
          'Nandnva',
          'Nandurbar',
          'Narkhed',
          'Nashik',
          'Navapur',
          'Ner',
          'Newasa',
          'Nilanga',
          'Niphad',
          'Omerga',
          'Osmanabad',
          'Pachora',
          'Paithan',
          'Palghar',
          'Pali',
          'Pandharkawada',
          'Pandharpur',
          'Panhala',
          'Paranda',
          'Parbhani',
          'Parner',
          'Parola',
          'Parseoni',
          'Partur',
          'Patan',
          'Pathardi',
          'Pathari',
          'Patoda',
          'Pauni',
          'Peint',
          'Pen',
          'Phaltan',
          'Pimpalner',
          'Pirangut',
          'Poladpur',
          'Pune',
          'Pusad',
          'Pusegaon',
          'Radhanagar',
          'Rahuri',
          'Raigad',
          'Rajapur',
          'Rajgurunagar',
          'Rajura',
          'Ralegaon',
          'Ramtek',
          'Ratnagiri',
          'Raver',
          'Risod',
          'Roha',
          'Sakarwadi',
          'Sakoli',
          'Sakri',
          'Salekasa',
          'Samudrapur',
          'Sangamner',
          'Sanganeshwar',
          'Sangli',
          'Sangola',
          'Sanguem',
          'Saoner',
          'Saswad',
          'Satana',
          'Satara',
          'Sawantwadi',
          'Seloo',
          'Shahada',
          'Shahapur',
          'Shahuwadi',
          'Shevgaon',
          'Shirala',
          'Shirol',
          'Shirpur',
          'Shirur',
          'Shirwal',
          'Sholapur',
          'Shri Rampur',
          'Shrigonda',
          'Shrivardhan',
          'Sillod',
          'Sinderwahi',
          'Sindhudurg',
          'Sindkheda',
          'Sindkhedaraja',
          'Sinnar',
          'Sironcha',
          'Soyegaon',
          'Surgena',
          'Talasari',
          'Talegaon S.Ji Pant',
          'Taloda',
          'Tasgaon',
          'Thane',
          'Tirora',
          'Tiwasa',
          'Trimbak',
          'Tuljapur',
          'Tumsar',
          'Udgir',
          'Umarkhed',
          'Umrane',
          'Umrer',
          'Urlikanchan',
          'Vaduj',
          'Velhe',
          'Vengurla',
          'Vijapur',
          'Vita',
          'Wada',
          'Wai',
          'Walchandnagar',
          'Wani',
          'Wardha',
          'Warlydwarud',
          'Warora',
          'Washim',
          'Wathar',
          'Yavatmal',
          'Yawal',
          'Yeola',
          'Yeotmal',
        ],
      },
      {
        state: 'Manipur',
        cities: [
          'Bishnupur',
          'Chakpikarong',
          'Chandel',
          'Chattrik',
          'Churachandpur',
          'Imphal',
          'Jiribam',
          'Kakching',
          'Kalapahar',
          'Mao',
          'Mulam',
          'Parbung',
          'Sadarhills',
          'Saibom',
          'Sempang',
          'Senapati',
          'Sochumer',
          'Taloulong',
          'Tamenglong',
          'Thinghat',
          'Thoubal',
          'Ukhrul',
        ],
      },
      {
        state: 'Meghalaya',
        cities: [
          'Amlaren',
          'Baghmara',
          'Cherrapunjee',
          'Dadengiri',
          'Garo Hills',
          'Jaintia Hills',
          'Jowai',
          'Khasi Hills',
          'Khliehriat',
          'Mariang',
          'Mawkyrwat',
          'Nongpoh',
          'Nongstoin',
          'Resubelpara',
          'Ri Bhoi',
          'Shillong',
          'Tura',
          'Williamnagar',
        ],
      },
      {
        state: 'Mizoram',
        cities: [
          'Aizawl',
          'Champhai',
          'Demagiri',
          'Kolasib',
          'Lawngtlai',
          'Lunglei',
          'Mamit',
          'Saiha',
          'Serchhip',
        ],
      },
      {
        state: 'Nagaland',
        cities: [
          'Dimapur',
          'Jalukie',
          'Kiphire',
          'Kohima',
          'Mokokchung',
          'Mon',
          'Phek',
          'Tuensang',
          'Wokha',
          'Zunheboto',
        ],
      },
      {
        state: 'Odisha',
        cities: [
          'Anandapur',
          'Angul',
          'Anugul',
          'Aska',
          'Athgarh',
          'Athmallik',
          'Attabira',
          'Bagdihi',
          'Balangir',
          'Balasore',
          'Baleswar',
          'Baliguda',
          'Balugaon',
          'Banaigarh',
          'Bangiriposi',
          'Barbil',
          'Bargarh',
          'Baripada',
          'Barkot',
          'Basta',
          'Berhampur',
          'Betanati',
          'Bhadrak',
          'Bhanjanagar',
          'Bhawanipatna',
          'Bhubaneswar',
          'Birmaharajpur',
          'Bisam Cuttack',
          'Boriguma',
          'Boudh',
          'Buguda',
          'Chandbali',
          'Chhatrapur',
          'Chhendipada',
          'Cuttack',
          'Daringbadi',
          'Daspalla',
          'Deodgarh',
          'Deogarh',
          'Dhanmandal',
          'Dharamgarh',
          'Dhenkanal',
          'Digapahandi',
          'Dunguripali',
          'G. Udayagiri',
          'Gajapati',
          'Ganjam',
          'Ghatgaon',
          'Gudari',
          'Gunupur',
          'Hemgiri',
          'Hindol',
          'Jagatsinghapur',
          'Jajpur',
          'Jamankira',
          'Jashipur',
          'Jayapatna',
          'Jeypur',
          'Jharigan',
          'Jharsuguda',
          'Jujumura',
          'Kalahandi',
          'Kalimela',
          'Kamakhyanagar',
          'Kandhamal',
          'Kantabhanji',
          'Kantamal',
          'Karanjia',
          'Kashipur',
          'Kendrapara',
          'Kendujhar',
          'Keonjhar',
          'Khalikote',
          'Khordha',
          'Khurda',
          'Komana',
          'Koraput',
          'Kotagarh',
          'Kuchinda',
          'Lahunipara',
          'Laxmipur',
          'M. Rampur',
          'Malkangiri',
          'Mathili',
          'Mayurbhanj',
          'Mohana',
          'Motu',
          'Nabarangapur',
          'Naktideul',
          'Nandapur',
          'Narlaroad',
          'Narsinghpur',
          'Nayagarh',
          'Nimapara',
          'Nowparatan',
          'Nowrangapur',
          'Nuapada',
          'Padampur',
          'Paikamal',
          'Palla Hara',
          'Papadhandi',
          'Parajang',
          'Pardip',
          'Parlakhemundi',
          'Patnagarh',
          'Pattamundai',
          'Phiringia',
          'Phulbani',
          'Puri',
          'Puruna Katak',
          'R. Udayigiri',
          'Rairakhol',
          'Rairangpur',
          'Rajgangpur',
          'Rajkhariar',
          'Rayagada',
          'Rourkela',
          'Sambalpur',
          'Sohela',
          'Sonapur',
          'Soro',
          'Subarnapur',
          'Sunabeda',
          'Sundergarh',
          'Surada',
          'T. Rampur',
          'Talcher',
          'Telkoi',
          'Titlagarh',
          'Tumudibandha',
          'Udala',
          'Umerkote',
        ],
      },
      {
        state: 'Pondicherry',
        cities: [
          'Bahur',
          'Karaikal',
          'Mahe',
          'Pondicherry',
          'Purnankuppam',
          'Valudavur',
          'Villianur',
          'Yanam',
        ],
      },
      {
        state: 'Punjab',
        cities: [
          'Abohar',
          'Ajnala',
          'Amritsar',
          'Balachaur',
          'Barnala',
          'Batala',
          'Bathinda',
          'Chandigarh',
          'Dasua',
          'Dinanagar',
          'Faridkot',
          'Fatehgarh Sahib',
          'Fazilka',
          'Ferozepur',
          'Garhashanker',
          'Goindwal',
          'Gurdaspur',
          'Guruharsahai',
          'Hoshiarpur',
          'Jagraon',
          'Jalandhar',
          'Jugial',
          'Kapurthala',
          'Kharar',
          'Kotkapura',
          'Ludhiana',
          'Malaut',
          'Malerkotla',
          'Mansa',
          'Moga',
          'Muktasar',
          'Nabha',
          'Nakodar',
          'Nangal',
          'Nawanshahar',
          'Nawanshahr',
          'Pathankot',
          'Patiala',
          'Patti',
          'Phagwara',
          'Phillaur',
          'Phulmandi',
          'Quadian',
          'Rajpura',
          'Raman',
          'Rayya',
          'Ropar',
          'Rupnagar',
          'Samana',
          'Samrala',
          'Sangrur',
          'Sardulgarh',
          'Sarhind',
          'SAS Nagar',
          'Sultanpur Lodhi',
          'Sunam',
          'Tanda Urmar',
          'Tarn Taran',
          'Zira',
        ],
      },
      {
        state: 'Rajasthan',
        cities: [
          'Abu',
          'Ahore',
          'Ajmer',
          'Aklera',
          'Alwar',
          'Amber',
          'Amet',
          'Anupgarh',
          'Asind',
          'Aspur',
          'Atru',
          'Bagidora',
          'Bali',
          'Bamanwas',
          'Banera',
          'Bansur',
          'Banswara',
          'Baran',
          'Bari',
          'Barisadri',
          'Barmer',
          'Baseri',
          'Bassi',
          'Baswa',
          'Bayana',
          'Beawar',
          'Begun',
          'Behror',
          'Bhadra',
          'Bharatpur',
          'Bhilwara',
          'Bhim',
          'Bhinmal',
          'Bikaner',
          'Bilara',
          'Bundi',
          'Chhabra',
          'Chhipaborad',
          'Chirawa',
          'Chittorgarh',
          'Chohtan',
          'Churu',
          'Dantaramgarh',
          'Dausa',
          'Deedwana',
          'Deeg',
          'Degana',
          'Deogarh',
          'Deoli',
          'Desuri',
          'Dhariawad',
          'Dholpur',
          'Digod',
          'Dudu',
          'Dungarpur',
          'Dungla',
          'Fatehpur',
          'Gangapur',
          'Gangdhar',
          'Gerhi',
          'Ghatol',
          'Girwa',
          'Gogunda',
          'Hanumangarh',
          'Hindaun',
          'Hindoli',
          'Hurda',
          'Jahazpur',
          'Jaipur',
          'Jaisalmer',
          'Jalore',
          'Jhalawar',
          'Jhunjhunu',
          'Jodhpur',
          'Kaman',
          'Kapasan',
          'Karauli',
          'Kekri',
          'Keshoraipatan',
          'Khandar',
          'Kherwara',
          'Khetri',
          'Kishanganj',
          'Kishangarh',
          'Kishangarhbas',
          'Kolayat',
          'Kota',
          'Kotputli',
          'Kotra',
          'Kotri',
          'Kumbalgarh',
          'Kushalgarh',
          'Ladnun',
          'Ladpura',
          'Lalsot',
          'Laxmangarh',
          'Lunkaransar',
          'Mahuwa',
          'Malpura',
          'Malvi',
          'Mandal',
          'Mandalgarh',
          'Mandawar',
          'Mangrol',
          'Marwar-Jn',
          'Merta',
          'Nadbai',
          'Nagaur',
          'Nainwa',
          'Nasirabad',
          'Nathdwara',
          'Nawa',
          'Neem Ka Thana',
          'Newai',
          'Nimbahera',
          'Nohar',
          'Nokha',
          'Onli',
          'Osian',
          'Pachpadara',
          'Pachpahar',
          'Padampur',
          'Pali',
          'Parbatsar',
          'Phagi',
          'Phalodi',
          'Pilani',
          'Pindwara',
          'Pipalda',
          'Pirawa',
          'Pokaran',
          'Pratapgarh',
          'Raipur',
          'Raisinghnagar',
          'Rajgarh',
          'Rajsamand',
          'Ramganj Mandi',
          'Ramgarh',
          'Rashmi',
          'Ratangarh',
          'Reodar',
          'Rupbas',
          'Sadulshahar',
          'Sagwara',
          'Sahabad',
          'Salumber',
          'Sanchore',
          'Sangaria',
          'Sangod',
          'Sapotra',
          'Sarada',
          'Sardarshahar',
          'Sarwar',
          'Sawai Madhopur',
          'Shahapura',
          'Sheo',
          'Sheoganj',
          'Shergarh',
          'Sikar',
          'Sirohi',
          'Siwana',
          'Sojat',
          'Sri Dungargarh',
          'Sri Ganganagar',
          'Sri Karanpur',
          'Sri Madhopur',
          'Sujangarh',
          'Taranagar',
          'Thanaghazi',
          'Tibbi',
          'Tijara',
          'Todaraisingh',
          'Tonk',
          'Udaipur',
          'Udaipurwati',
          'Uniayara',
          'Vallabhnagar',
          'Viratnagar ',
        ],
      },
      {
        state: 'Sikkim',
        cities: [
          'Barmiak',
          'Be',
          'Bhurtuk',
          'Chhubakha',
          'Chidam',
          'Chubha',
          'Chumikteng',
          'Dentam',
          'Dikchu',
          'Dzongri',
          'Gangtok',
          'Gauzing',
          'Gyalshing',
          'Hema',
          'Kerung',
          'Lachen',
          'Lachung',
          'Lema',
          'Lingtam',
          'Lungthu',
          'Mangan',
          'Namchi',
          'Namthang',
          'Nanga',
          'Nantang',
          'Naya Bazar',
          'Padamachen',
          'Pakhyong',
          'Pemayangtse',
          'Phensang',
          'Rangli',
          'Rinchingpong',
          'Sakyong',
          'Samdong',
          'Singtam',
          'Siniolchu',
          'Sombari',
          'Soreng',
          'Sosing',
          'Tekhug',
          'Temi',
          'Tsetang',
          'Tsomgo',
          'Tumlong',
          'Yangang',
          'Yumtang',
        ],
      },
      {
        state: 'Tamil Nadu',
        cities: [
          'Ambasamudram',
          'Anamali',
          'Arakandanallur',
          'Arantangi',
          'Aravakurichi',
          'Ariyalur',
          'Arkonam',
          'Arni',
          'Aruppukottai',
          'Attur',
          'Avanashi',
          'Batlagundu',
          'Bhavani',
          'Chengalpattu',
          'Chengam',
          'Chennai',
          'Chidambaram',
          'Chingleput',
          'Coimbatore',
          'Courtallam',
          'Cuddalore',
          'Cumbum',
          'Denkanikoitah',
          'Devakottai',
          'Dharampuram',
          'Dharmapuri',
          'Dindigul',
          'Erode',
          'Gingee',
          'Gobichettipalayam',
          'Gudalur',
          'Gudiyatham',
          'Harur',
          'Hosur',
          'Jayamkondan',
          'Kallkurichi',
          'Kanchipuram',
          'Kangayam',
          'Kanyakumari',
          'Karaikal',
          'Karaikudi',
          'Karur',
          'Keeranur',
          'Kodaikanal',
          'Kodumudi',
          'Kotagiri',
          'Kovilpatti',
          'Krishnagiri',
          'Kulithalai',
          'Kumbakonam',
          'Kuzhithurai',
          'Madurai',
          'Madurantgam',
          'Manamadurai',
          'Manaparai',
          'Mannargudi',
          'Mayiladuthurai',
          'Mayiladutjurai',
          'Mettupalayam',
          'Metturdam',
          'Mudukulathur',
          'Mulanur',
          'Musiri',
          'Nagapattinam',
          'Nagarcoil',
          'Namakkal',
          'Nanguneri',
          'Natham',
          'Neyveli',
          'Nilgiris',
          'Oddanchatram',
          'Omalpur',
          'Ootacamund',
          'Ooty',
          'Orathanad',
          'Palacode',
          'Palani',
          'Palladum',
          'Papanasam',
          'Paramakudi',
          'Pattukottai',
          'Perambalur',
          'Perundurai',
          'Pollachi',
          'Polur',
          'Pondicherry',
          'Ponnamaravathi',
          'Ponneri',
          'Pudukkottai',
          'Rajapalayam',
          'Ramanathapuram',
          'Rameshwaram',
          'Ranipet',
          'Rasipuram',
          'Salem',
          'Sankagiri',
          'Sankaran',
          'Sathiyamangalam',
          'Sivaganga',
          'Sivakasi',
          'Sriperumpudur',
          'Srivaikundam',
          'Tenkasi',
          'Thanjavur',
          'Theni',
          'Thirumanglam',
          'Thiruraipoondi',
          'Thoothukudi',
          'Thuraiyure',
          'Tindivanam',
          'Tiruchendur',
          'Tiruchengode',
          'Tiruchirappalli',
          'Tirunelvelli',
          'Tirupathur',
          'Tirupur',
          'Tiruttani',
          'Tiruvallur',
          'Tiruvannamalai',
          'Tiruvarur',
          'Tiruvellore',
          'Tiruvettipuram',
          'Trichy',
          'Tuticorin',
          'Udumalpet',
          'Ulundurpet',
          'Usiliampatti',
          'Uthangarai',
          'Valapady',
          'Valliyoor',
          'Vaniyambadi',
          'Vedasandur',
          'Vellore',
          'Velur',
          'Vilathikulam',
          'Villupuram',
          'Virudhachalam',
          'Virudhunagar',
          'Wandiwash',
          'Yercaud',
        ],
      },
      {
        state: 'Telangana',
        cities: [
          'Achampet',
          'Adilabad',
          'Adoni',
          'Alampur',
          'Allagadda',
          'Alur',
          'Amalapuram',
          'Amangallu',
          'Anakapalle',
          'Anantapur',
          'Andole',
          'Araku',
          'Armoor',
          'Asifabad',
          'Aswaraopet',
          'Atmakur',
          'B. Kothakota',
          'Badvel',
          'Banaganapalle',
          'Bandar',
          'Bangarupalem',
          'Banswada',
          'Bapatla',
          'Bellampalli',
          'Bhadrachalam',
          'Bhainsa',
          'Bheemunipatnam',
          'Bhimadole',
          'Bhimavaram',
          'Bhongir',
          'Bhooragamphad',
          'Boath',
          'Bobbili',
          'Bodhan',
          'Chandoor',
          'Chavitidibbalu',
          'Chejerla',
          'Chepurupalli',
          'Cherial',
          'Chevella',
          'Chinnor',
          'Chintalapudi',
          'Chintapalle',
          'Chirala',
          'Chittoor',
          'Chodavaram',
          'Cuddapah',
          'Cumbum',
          'Darsi',
          'Devarakonda',
          'Dharmavaram',
          'Dichpalli',
          'Divi',
          'Donakonda',
          'Dronachalam',
          'East Godavari',
          'Eluru',
          'Eturnagaram',
          'Gadwal',
          'Gajapathinagaram',
          'Gajwel',
          'Garladinne',
          'Giddalur',
          'Godavari',
          'Gooty',
          'Gudivada',
          'Gudur',
          'Guntur',
          'Hindupur',
          'Hunsabad',
          'Huzurabad',
          'Huzurnagar',
          'Hyderabad',
          'Ibrahimpatnam',
          'Jaggayyapet',
          'Jagtial',
          'Jammalamadugu',
          'Jangaon',
          'Jangareddygudem',
          'Jannaram',
          'Kadiri',
          'Kaikaluru',
          'Kakinada',
          'Kalwakurthy',
          'Kalyandurg',
          'Kamalapuram',
          'Kamareddy',
          'Kambadur',
          'Kanaganapalle',
          'Kandukuru',
          'Kanigiri',
          'Karimnagar',
          'Kavali',
          'Khammam',
          'Khanapur (AP)',
          'Kodangal',
          'Koduru',
          'Koilkuntla',
          'Kollapur',
          'Kothagudem',
          'Kovvur',
          'Krishna',
          'Krosuru',
          'Kuppam',
          'Kurnool',
          'Lakkireddipalli',
          'Madakasira',
          'Madanapalli',
          'Madhira',
          'Madnur',
          'Mahabubabad',
          'Mahabubnagar',
          'Mahadevapur',
          'Makthal',
          'Mancherial',
          'Mandapeta',
          'Mangalagiri',
          'Manthani',
          'Markapur',
          'Marturu',
          'Medachal',
          'Medak',
          'Medarmetla',
          'Metpalli',
          'Mriyalguda',
          'Mulug',
          'Mylavaram',
          'Nagarkurnool',
          'Nalgonda',
          'Nallacheruvu',
          'Nampalle',
          'Nandigama',
          'Nandikotkur',
          'Nandyal',
          'Narasampet',
          'Narasaraopet',
          'Narayanakhed',
          'Narayanpet',
          'Narsapur',
          'Narsipatnam',
          'Nazvidu',
          'Nelloe',
          'Nellore',
          'Nidamanur',
          'Nirmal',
          'Nizamabad',
          'Nuguru',
          'Ongole',
          'Outsarangapalle',
          'Paderu',
          'Pakala',
          'Palakonda',
          'Paland',
          'Palmaneru',
          'Pamuru',
          'Pargi',
          'Parkal',
          'Parvathipuram',
          'Pathapatnam',
          'Pattikonda',
          'Peapalle',
          'Peddapalli',
          'Peddapuram',
          'Penukonda',
          'Piduguralla',
          'Piler',
          'Pithapuram',
          'Podili',
          'Polavaram',
          'Prakasam',
          'Proddatur',
          'Pulivendla',
          'Punganur',
          'Putturu',
          'Rajahmundri',
          'Rajampeta',
          'Ramachandrapuram',
          'Ramannapet',
          'Rampachodavaram',
          'Rangareddy',
          'Rapur',
          'Rayachoti',
          'Rayadurg',
          'Razole',
          'Repalle',
          'Saluru',
          'Sangareddy',
          'Sathupalli',
          'Sattenapalle',
          'Satyavedu',
          'Shadnagar',
          'Siddavattam',
          'Siddipet',
          'Sileru',
          'Sircilla',
          'Sirpur Kagaznagar',
          'Sodam',
          'Sompeta',
          'Srikakulam',
          'Srikalahasthi',
          'Srisailam',
          'Srungavarapukota',
          'Sudhimalla',
          'Sullarpet',
          'Tadepalligudem',
          'Tadipatri',
          'Tanduru',
          'Tanuku',
          'Tekkali',
          'Tenali',
          'Thungaturthy',
          'Tirivuru',
          'Tirupathi',
          'Tuni',
          'Udaygiri',
          'Ulvapadu',
          'Uravakonda',
          'Utnor',
          'V.R. Puram',
          'Vaimpalli',
          'Vayalpad',
          'Venkatgiri',
          'Venkatgirikota',
          'Vijayawada',
          'Vikrabad',
          'Vinjamuru',
          'Vinukonda',
          'Visakhapatnam',
          'Vizayanagaram',
          'Vizianagaram',
          'Vuyyuru',
          'Wanaparthy',
          'Warangal',
          'Wardhannapet',
          'Yelamanchili',
          'Yelavaram',
          'Yeleswaram',
          'Yellandu',
          'Yellanuru',
          'Yellareddy',
          'Yerragondapalem',
          'Zahirabad',
        ],
      },
      {
        state: 'Tripura',
        cities: [
          'Agartala',
          'Ambasa',
          'Bampurbari',
          'Belonia',
          'Dhalai',
          'Dharam Nagar',
          'Kailashahar',
          'Kamal Krishnabari',
          'Khopaiyapara',
          'Khowai',
          'Phuldungsei',
          'Radha Kishore Pur',
          'Tripura',
        ],
      },
      {
        state: 'Uttar Pradesh',
        cities: [
          'Achhnera',
          'Agra',
          'Akbarpur',
          'Aliganj',
          'Aligarh',
          'Allahabad',
          'Ambedkar Nagar',
          'Amethi',
          'Amiliya',
          'Amroha',
          'Anola',
          'Atrauli',
          'Auraiya',
          'Azamgarh',
          'Baberu',
          'Badaun',
          'Baghpat',
          'Bagpat',
          'Baheri',
          'Bahraich',
          'Ballia',
          'Balrampur',
          'Banda',
          'Bansdeeh',
          'Bansgaon',
          'Bansi',
          'Barabanki',
          'Bareilly',
          'Basti',
          'Bhadohi',
          'Bharthana',
          'Bharwari',
          'Bhogaon',
          'Bhognipur',
          'Bidhuna',
          'Bijnore',
          'Bikapur',
          'Bilari',
          'Bilgram',
          'Bilhaur',
          'Bindki',
          'Bisalpur',
          'Bisauli',
          'Biswan',
          'Budaun',
          'Budhana',
          'Bulandshahar',
          'Bulandshahr',
          'Capianganj',
          'Chakia',
          'Chandauli',
          'Charkhari',
          'Chhata',
          'Chhibramau',
          'Chirgaon',
          'Chitrakoot',
          'Chunur',
          'Dadri',
          'Dalmau',
          'Dataganj',
          'Debai',
          'Deoband',
          'Deoria',
          'Derapur',
          'Dhampur',
          'Domariyaganj',
          'Dudhi',
          'Etah',
          'Etawah',
          'Faizabad',
          'Farrukhabad',
          'Fatehpur',
          'Firozabad',
          'Garauth',
          'Garhmukteshwar',
          'Gautam Buddha Nagar',
          'Ghatampur',
          'Ghaziabad',
          'Ghazipur',
          'Ghosi',
          'Gonda',
          'Gorakhpur',
          'Gunnaur',
          'Haidergarh',
          'Hamirpur',
          'Hapur',
          'Hardoi',
          'Harraiya',
          'Hasanganj',
          'Hasanpur',
          'Hathras',
          'Jalalabad',
          'Jalaun',
          'Jalesar',
          'Jansath',
          'Jarar',
          'Jasrana',
          'Jaunpur',
          'Jhansi',
          'Jyotiba Phule Nagar',
          'Kadipur',
          'Kaimganj',
          'Kairana',
          'Kaisarganj',
          'Kalpi',
          'Kannauj',
          'Kanpur',
          'Karchhana',
          'Karhal',
          'Karvi',
          'Kasganj',
          'Kaushambi',
          'Kerakat',
          'Khaga',
          'Khair',
          'Khalilabad',
          'Kheri',
          'Konch',
          'Kumaon',
          'Kunda',
          'Kushinagar',
          'Lalganj',
          'Lalitpur',
          'Lucknow',
          'Machlishahar',
          'Maharajganj',
          'Mahoba',
          'Mainpuri',
          'Malihabad',
          'Mariyahu',
          'Math',
          'Mathura',
          'Mau',
          'Maudaha',
          'Maunathbhanjan',
          'Mauranipur',
          'Mawana',
          'Meerut',
          'Mehraun',
          'Meja',
          'Mirzapur',
          'Misrikh',
          'Modinagar',
          'Mohamdabad',
          'Mohamdi',
          'Moradabad',
          'Musafirkhana',
          'Muzaffarnagar',
          'Nagina',
          'Najibabad',
          'Nakur',
          'Nanpara',
          'Naraini',
          'Naugarh',
          'Nawabganj',
          'Nighasan',
          'Noida',
          'Orai',
          'Padrauna',
          'Pahasu',
          'Patti',
          'Pharenda',
          'Phoolpur',
          'Phulpur',
          'Pilibhit',
          'Pitamberpur',
          'Powayan',
          'Pratapgarh',
          'Puranpur',
          'Purwa',
          'Raibareli',
          'Rampur',
          'Ramsanehi Ghat',
          'Rasara',
          'Rath',
          'Robertsganj',
          'Sadabad',
          'Safipur',
          'Sagri',
          'Saharanpur',
          'Sahaswan',
          'Sahjahanpur',
          'Saidpur',
          'Salempur',
          'Salon',
          'Sambhal',
          'Sandila',
          'Sant Kabir Nagar',
          'Sant Ravidas Nagar',
          'Sardhana',
          'Shahabad',
          'Shahganj',
          'Shahjahanpur',
          'Shikohabad',
          'Shravasti',
          'Siddharthnagar',
          'Sidhauli',
          'Sikandra Rao',
          'Sikandrabad',
          'Sitapur',
          'Siyana',
          'Sonbhadra',
          'Soraon',
          'Sultanpur',
          'Tanda',
          'Tarabganj',
          'Tilhar',
          'Unnao',
          'Utraula',
          'Varanasi',
          'Zamania',
        ],
      },
      {
        state: 'Uttarakhand',
        cities: [
          'Almora',
          'Bageshwar',
          'Bhatwari',
          'Chakrata',
          'Chamoli',
          'Champawat',
          'Dehradun',
          'Deoprayag',
          'Dharchula',
          'Dunda',
          'Haldwani',
          'Haridwar',
          'Joshimath',
          'Karan Prayag',
          'Kashipur',
          'Khatima',
          'Kichha',
          'Lansdown',
          'Munsiari',
          'Mussoorie',
          'Nainital',
          'Pantnagar',
          'Partapnagar',
          'Pauri Garhwal',
          'Pithoragarh',
          'Purola',
          'Rajgarh',
          'Ranikhet',
          'Roorkee',
          'Rudraprayag',
          'Tehri Garhwal',
          'Udham Singh Nagar',
          'Ukhimath',
          'Uttarkashi',
        ],
      },
      {
        state: 'West Bengal',
        cities: [
          'Adra',
          'Alipurduar',
          'Amlagora',
          'Arambagh',
          'Asansol',
          'Balurghat',
          'Bankura',
          'Bardhaman',
          'Basirhat',
          'Berhampur',
          'Bethuadahari',
          'Birbhum',
          'Birpara',
          'Bishanpur',
          'Bolpur',
          'Bongoan',
          'Bulbulchandi',
          'Burdwan',
          'Calcutta',
          'Canning',
          'Champadanga',
          'Contai',
          'Cooch Behar',
          'Daimond Harbour',
          'Dalkhola',
          'Dantan',
          'Darjeeling',
          'Dhaniakhali',
          'Dhuliyan',
          'Dinajpur',
          'Dinhata',
          'Durgapur',
          'Gangajalghati',
          'Gangarampur',
          'Ghatal',
          'Guskara',
          'Habra',
          'Haldia',
          'Harirampur',
          'Harishchandrapur',
          'Hooghly',
          'Howrah',
          'Islampur',
          'Jagatballavpur',
          'Jalpaiguri',
          'Jhalda',
          'Jhargram',
          'Kakdwip',
          'Kalchini',
          'Kalimpong',
          'Kalna',
          'Kandi',
          'Karimpur',
          'Katwa',
          'Kharagpur',
          'Khatra',
          'Krishnanagar',
          'Mal Bazar',
          'Malda',
          'Manbazar',
          'Mathabhanga',
          'Medinipur',
          'Mekhliganj',
          'Mirzapur',
          'Murshidabad',
          'Nadia',
          'Nagarakata',
          'Nalhati',
          'Nayagarh',
          'Parganas',
          'Purulia',
          'Raiganj',
          'Rampur Hat',
          'Ranaghat',
          'Seharabazar',
          'Siliguri',
          'Suri',
          'Takipur',
          'Tamluk',
        ],
      },
    ],
  },
  {
    country: 'United States of America',
    states: [
      {
        state: 'pan-America',
        cities: ['All Cities'],
      },
      {
        state: 'Alabama',
        cities: [
          'Alexander City',
          'Andalusia',
          'Anniston',
          'Athens',
          'Atmore',
          'Auburn',
          'Bessemer',
          'Birmingham',
          'Chickasaw',
          'Clanton',
          'Cullman',
          'Decatur',
          'Demopolis',
          'Dothan',
          'Enterprise',
          'Eufaula',
          'Florence',
          'Fort Payne',
          'Gadsden',
          'Greenville',
          'Guntersville',
          'Huntsville',
          'Jasper',
          'Marion',
          'Mobile',
          'Montgomery',
          'Opelika',
          'Ozark',
          'Phenix City',
          'Prichard',
          'Scottsboro',
          'Selma',
          'Sheffield',
          'Sylacauga',
          'Talladega',
          'Troy',
          'Tuscaloosa',
          'Tuscumbia',
          'Tuskegee',
        ],
      },
      {
        state: 'Alaska',
        cities: [
          'Anchorage',
          'Cordova',
          'Fairbanks',
          'Haines',
          'Homer',
          'Juneau',
          'Ketchikan',
          'Kodiak',
          'Kotzebue',
          'Nome',
          'Palmer',
          'Seward',
          'Sitka',
          'Skagway',
          'Valdez',
        ],
      },
      {
        state: 'Arizona',
        cities: [
          'Ajo',
          'Avondale',
          'Bisbee',
          'Casa Grande',
          'Chandler',
          'Clifton',
          'Douglas',
          'Flagstaff',
          'Florence',
          'Gila Bend',
          'Glendale',
          'Globe',
          'Kingman',
          'Lake Havasu City',
          'Mesa',
          'Nogales',
          'Oraibi',
          'Phoenix',
          'Prescott',
          'Scottsdale',
          'Sierra Vista',
          'Tempe',
          'Tombstone',
          'Tucson',
          'Walpi',
          'Window Rock',
          'Winslow',
          'Yuma',
        ],
      },
      {
        state: 'Arkansas',
        cities: [
          'Arkadelphia',
          'Arkansas Post',
          'Batesville',
          'Benton',
          'Blytheville',
          'Camden',
          'Conway',
          'Crossett',
          'El Dorado',
          'Fayetteville',
          'Forrest City',
          'Fort Smith',
          'Harrison',
          'Helena',
          'Hope',
          'Hot Springs',
          'Jacksonville',
          'Jonesboro',
          'Little Rock',
          'Magnolia',
          'Morrilton',
          'Newport',
          'North Little Rock',
          'Osceola',
          'Pine Bluff',
          'Rogers',
          'Searcy',
          'Stuttgart',
          'Van Buren',
          'West Memphis',
        ],
      },
      {
        state: 'California',
        cities: [
          'Alameda',
          'Alhambra',
          'Anaheim',
          'Antioch',
          'Arcadia',
          'Bakersfield',
          'Barstow',
          'Belmont',
          'Berkeley',
          'Beverly Hills',
          'Brea',
          'Buena Park',
          'Burbank',
          'Calexico',
          'Calistoga',
          'Carlsbad',
          'Carmel',
          'Chico',
          'Chula Vista',
          'Claremont',
          'Compton',
          'Concord',
          'Corona',
          'Coronado',
          'Costa Mesa',
          'Culver City',
          'Daly City',
          'Davis',
          'Downey',
          'El Centro',
          'El Cerrito',
          'El Monte',
          'Escondido',
          'Eureka',
          'Fairfield',
          'Fontana',
          'Fremont',
          'Fresno',
          'Fullerton',
          'Garden Grove',
          'Glendale',
          'Hayward',
          'Hollywood',
          'Huntington Beach',
          'Indio',
          'Inglewood',
          'Irvine',
          'La Habra',
          'Laguna Beach',
          'Lancaster',
          'Livermore',
          'Lodi',
          'Lompoc',
          'Long Beach',
          'Los Angeles',
          'Malibu',
          'Martinez',
          'Marysville',
          'Menlo Park',
          'Merced',
          'Modesto',
          'Monterey',
          'Mountain View',
          'Napa',
          'Needles',
          'Newport Beach',
          'Norwalk',
          'Novato',
          'Oakland',
          'Oceanside',
          'Ojai',
          'Ontario',
          'Orange',
          'Oroville',
          'Oxnard',
          'Pacific Grove',
          'Palm Springs',
          'Palmdale',
          'Palo Alto',
          'Pasadena',
          'Petaluma',
          'Pomona',
          'Port Hueneme',
          'Rancho Cucamonga',
          'Red Bluff',
          'Redding',
          'Redlands',
          'Redondo Beach',
          'Redwood City',
          'Richmond',
          'Riverside',
          'Roseville',
          'Sacramento',
          'Salinas',
          'San Bernardino',
          'San Clemente',
          'San Diego',
          'San Fernando',
          'San Francisco',
          'San Gabriel',
          'San Jose',
          'San Juan Capistrano',
          'San Leandro',
          'San Luis Obispo',
          'San Marino',
          'San Mateo',
          'San Pedro',
          'San Rafael',
          'San Simeon',
          'Santa Ana',
          'Santa Barbara',
          'Santa Clara',
          'Santa Clarita',
          'Santa Cruz',
          'Santa Monica',
          'Santa Rosa',
          'Sausalito',
          'Simi Valley',
          'Sonoma',
          'South San Francisco',
          'Stockton',
          'Sunnyvale',
          'Susanville',
          'Thousand Oaks',
          'Torrance',
          'Turlock',
          'Ukiah',
          'Vallejo',
          'Ventura',
          'Victorville',
          'Visalia',
          'Walnut Creek',
          'Watts',
          'West Covina',
          'Whittier',
          'Woodland',
          'Yorba Linda',
          'Yuba City',
        ],
      },
      {
        state: 'Colorado',
        cities: [
          'Alamosa',
          'Aspen',
          'Aurora',
          'Boulder',
          'Breckenridge',
          'Brighton',
          'Canon City',
          'Central City',
          'Climax',
          'Colorado Springs',
          'Cortez',
          'Cripple Creek',
          'Denver',
          'Durango',
          'Englewood',
          'Estes Park',
          'Fort Collins',
          'Fort Morgan',
          'Georgetown',
          'Glenwood Springs',
          'Golden',
          'Grand Junction',
          'Greeley',
          'Gunnison',
          'La Junta',
          'Leadville',
          'Littleton',
          'Longmont',
          'Loveland',
          'Montrose',
          'Ouray',
          'Pagosa Springs',
          'Pueblo',
          'Silverton',
          'Steamboat Springs',
          'Sterling',
          'Telluride',
          'Trinidad',
          'Vail',
          'Walsenburg',
          'Westminster',
        ],
      },
      {
        state: 'Connecticut',
        cities: [
          'Ansonia',
          'Berlin',
          'Bloomfield',
          'Branford',
          'Bridgeport',
          'Bristol',
          'Coventry',
          'Danbury',
          'Darien',
          'Derby',
          'East Hartford',
          'East Haven',
          'Enfield',
          'Fairfield',
          'Farmington',
          'Greenwich',
          'Groton',
          'Guilford',
          'Hamden',
          'Hartford',
          'Lebanon',
          'Litchfield',
          'Manchester',
          'Mansfield',
          'Meriden',
          'Middletown',
          'Milford',
          'Mystic',
          'Naugatuck',
          'New Britain',
          'New Haven',
          'New London',
          'North Haven',
          'Norwalk',
          'Norwich',
          'Old Saybrook',
          'Orange',
          'Seymour',
          'Shelton',
          'Simsbury',
          'Southington',
          'Stamford',
          'Stonington',
          'Stratford',
          'Torrington',
          'Wallingford',
          'Waterbury',
          'Waterford',
          'Watertown',
          'West Hartford',
          'West Haven',
          'Westport',
          'Wethersfield',
          'Willimantic',
          'Windham',
          'Windsor',
          'Windsor Locks',
          'Winsted',
        ],
      },
      {
        state: 'Delaware',
        cities: [
          'Dover',
          'Lewes',
          'Milford',
          'New Castle',
          'Newark',
          'Smyrna',
          'Wilmington',
        ],
      },
      {
        state: 'Florida',
        cities: [
          'Apalachicola',
          'Bartow',
          'Belle Glade',
          'Boca Raton',
          'Bradenton',
          'Cape Coral',
          'Clearwater',
          'Cocoa Beach',
          'Cocoa-Rockledge',
          'Coral Gables',
          'Daytona Beach',
          'De Land',
          'Deerfield Beach',
          'Delray Beach',
          'Fernandina Beach',
          'Fort Lauderdale',
          'Fort Myers',
          'Fort Pierce',
          'Fort Walton Beach',
          'Gainesville',
          'Hallandale Beach',
          'Hialeah',
          'Hollywood',
          'Homestead',
          'Jacksonville',
          'Key West',
          'Lake City',
          'Lake Wales',
          'Lakeland',
          'Largo',
          'Melbourne',
          'Miami',
          'Miami Beach',
          'Naples',
          'New Smyrna Beach',
          'Ocala',
          'Orlando',
          'Ormond Beach',
          'Palatka',
          'Palm Bay',
          'Palm Beach',
          'Panama City',
          'Pensacola',
          'Pompano Beach',
          'Saint Augustine',
          'Saint Petersburg',
          'Sanford',
          'Sarasota',
          'Sebring',
          'Tallahassee',
          'Tampa',
          'Tarpon Springs',
          'Titusville',
          'Venice',
          'West Palm Beach',
          'White Springs',
          'Winter Haven',
          'Winter Park',
        ],
      },
      {
        state: 'Georgia',
        cities: [
          'Albany',
          'Americus',
          'Andersonville',
          'Athens',
          'Atlanta',
          'Augusta',
          'Bainbridge',
          'Blairsville',
          'Brunswick',
          'Calhoun',
          'Carrollton',
          'Columbus',
          'Dahlonega',
          'Dalton',
          'Darien',
          'Decatur',
          'Douglas',
          'East Point',
          'Fitzgerald',
          'Fort Valley',
          'Gainesville',
          'La Grange',
          'Macon',
          'Marietta',
          'Milledgeville',
          'Plains',
          'Rome',
          'Savannah',
          'Toccoa',
          'Valdosta',
          'Warm Springs',
          'Warner Robins',
          'Washington',
          'Waycross',
        ],
      },
      {
        state: 'Hawaii',
        cities: [
          'Hanalei',
          'Hilo',
          'Honaunau',
          'Honolulu',
          'Kahului',
          'Kaneohe',
          'Kapaa',
          'Kawaihae',
          'Lahaina',
          'Laie',
          'Wahiawa',
          'Wailuku',
          'Waimea',
        ],
      },
      {
        state: 'Idaho',
        cities: [
          'Blackfoot',
          'Boise',
          'Bonners Ferry',
          'Caldwell',
          'Coeur d’Alene',
          'Idaho City',
          'Idaho Falls',
          'Kellogg',
          'Lewiston',
          'Moscow',
          'Nampa',
          'Pocatello',
          'Priest River',
          'Rexburg',
          'Sun Valley',
          'Twin Falls',
        ],
      },
      {
        state: 'Illinois',
        cities: [
          'Alton',
          'Arlington Heights',
          'Arthur',
          'Aurora',
          'Belleville',
          'Belvidere',
          'Bloomington',
          'Brookfield',
          'Cahokia',
          'Cairo',
          'Calumet City',
          'Canton',
          'Carbondale',
          'Carlinville',
          'Carthage',
          'Centralia',
          'Champaign',
          'Charleston',
          'Chester',
          'Chicago',
          'Chicago Heights',
          'Cicero',
          'Collinsville',
          'Danville',
          'Decatur',
          'DeKalb',
          'Des Plaines',
          'Dixon',
          'East Moline',
          'East Saint Louis',
          'Effingham',
          'Elgin',
          'Elmhurst',
          'Evanston',
          'Freeport',
          'Galena',
          'Galesburg',
          'Glen Ellyn',
          'Glenview',
          'Granite City',
          'Harrisburg',
          'Herrin',
          'Highland Park',
          'Jacksonville',
          'Joliet',
          'Kankakee',
          'Kaskaskia',
          'Kewanee',
          'La Salle',
          'Lake Forest',
          'Libertyville',
          'Lincoln',
          'Lisle',
          'Lombard',
          'Macomb',
          'Mattoon',
          'Moline',
          'Monmouth',
          'Mount Vernon',
          'Mundelein',
          'Naperville',
          'Nauvoo',
          'Normal',
          'North Chicago',
          'Oak Park',
          'Oregon',
          'Ottawa',
          'Palatine',
          'Park Forest',
          'Park Ridge',
          'Pekin',
          'Peoria',
          'Petersburg',
          'Pontiac',
          'Quincy',
          'Rantoul',
          'River Forest',
          'Rock Island',
          'Rockford',
          'Salem',
          'Shawneetown',
          'Skokie',
          'South Holland',
          'Springfield',
          'Streator',
          'Summit',
          'Urbana',
          'Vandalia',
          'Virden',
          'Waukegan',
          'Wheaton',
          'Wilmette',
          'Winnetka',
          'Wood River',
          'Zion',
        ],
      },
      {
        state: 'Indiana',
        cities: [
          'Anderson',
          'Bedford',
          'Bloomington',
          'Columbus',
          'Connersville',
          'Corydon',
          'Crawfordsville',
          'East Chicago',
          'Elkhart',
          'Elwood',
          'Evansville',
          'Fort Wayne',
          'French Lick',
          'Gary',
          'Geneva',
          'Goshen',
          'Greenfield',
          'Hammond',
          'Hobart',
          'Huntington',
          'Indianapolis',
          'Jeffersonville',
          'Kokomo',
          'Lafayette',
          'Madison',
          'Marion',
          'Michigan City',
          'Mishawaka',
          'Muncie',
          'Nappanee',
          'Nashville',
          'New Albany',
          'New Castle',
          'New Harmony',
          'Peru',
          'Plymouth',
          'Richmond',
          'Santa Claus',
          'Shelbyville',
          'South Bend',
          'Terre Haute',
          'Valparaiso',
          'Vincennes',
          'Wabash',
          'West Lafayette',
        ],
      },
      {
        state: 'Iowa',
        cities: [
          'Amana Colonies',
          'Ames',
          'Boone',
          'Burlington',
          'Cedar Falls',
          'Cedar Rapids',
          'Charles City',
          'Cherokee',
          'Clinton',
          'Council Bluffs',
          'Davenport',
          'Des Moines',
          'Dubuque',
          'Estherville',
          'Fairfield',
          'Fort Dodge',
          'Grinnell',
          'Indianola',
          'Iowa City',
          'Keokuk',
          'Mason City',
          'Mount Pleasant',
          'Muscatine',
          'Newton',
          'Oskaloosa',
          'Ottumwa',
          'Sioux City',
          'Waterloo',
          'Webster City',
          'West Des Moines',
        ],
      },
      {
        state: 'Kansas',
        cities: [
          'Abilene',
          'Arkansas City',
          'Atchison',
          'Chanute',
          'Coffeyville',
          'Council Grove',
          'Dodge City',
          'Emporia',
          'Fort Scott',
          'Garden City',
          'Great Bend',
          'Hays',
          'Hutchinson',
          'Independence',
          'Junction City',
          'Kansas City',
          'Lawrence',
          'Leavenworth',
          'Liberal',
          'Manhattan',
          'McPherson',
          'Medicine Lodge',
          'Newton',
          'Olathe',
          'Osawatomie',
          'Ottawa',
          'Overland Park',
          'Pittsburg',
          'Salina',
          'Shawnee',
          'Smith Center',
          'Topeka',
          'Wichita',
        ],
      },
      {
        state: 'Kentucky',
        cities: [
          'Ashland',
          'Barbourville',
          'Bardstown',
          'Berea',
          'Boonesborough',
          'Bowling Green',
          'Campbellsville',
          'Covington',
          'Danville',
          'Elizabethtown',
          'Frankfort',
          'Harlan',
          'Harrodsburg',
          'Hazard',
          'Henderson',
          'Hodgenville',
          'Hopkinsville',
          'Lexington',
          'Louisville',
          'Mayfield',
          'Maysville',
          'Middlesboro',
          'Newport',
          'Owensboro',
          'Paducah',
          'Paris',
          'Richmond',
        ],
      },
      {
        state: 'Louisiana',
        cities: [
          'Abbeville',
          'Alexandria',
          'Bastrop',
          'Baton Rouge',
          'Bogalusa',
          'Bossier City',
          'Gretna',
          'Houma',
          'Lafayette',
          'Lake Charles',
          'Monroe',
          'Morgan City',
          'Natchitoches',
          'New Iberia',
          'New Orleans',
          'Opelousas',
          'Ruston',
          'Saint Martinville',
          'Shreveport',
          'Thibodaux',
        ],
      },
      {
        state: 'Maine',
        cities: [
          'Auburn',
          'Augusta',
          'Bangor',
          'Bar Harbor',
          'Bath',
          'Belfast',
          'Biddeford',
          'Boothbay Harbor',
          'Brunswick',
          'Calais',
          'Caribou',
          'Castine',
          'Eastport',
          'Ellsworth',
          'Farmington',
          'Fort Kent',
          'Gardiner',
          'Houlton',
          'Kennebunkport',
          'Kittery',
          'Lewiston',
          'Lubec',
          'Machias',
          'Orono',
          'Portland',
          'Presque Isle',
          'Rockland',
          'Rumford',
          'Saco',
          'Scarborough',
          'Waterville',
          'York',
        ],
      },
      {
        state: 'Maryland',
        cities: [
          'Aberdeen',
          'Annapolis',
          'Baltimore',
          'Bethesda-Chevy Chase',
          'Bowie',
          'Cambridge',
          'Catonsville',
          'College Park',
          'Columbia',
          'Cumberland',
          'Easton',
          'Elkton',
          'Emmitsburg',
          'Frederick',
          'Greenbelt',
          'Hagerstown',
          'Hyattsville',
          'Laurel',
          'Oakland',
          'Ocean City',
          'Rockville',
          'Saint Marys City',
          'Salisbury',
          'Silver Spring',
          'Takoma Park',
          'Towson',
          'Westminster',
        ],
      },
      {
        state: 'Massachusetts',
        cities: [
          'Abington',
          'Adams',
          'Amesbury',
          'Amherst',
          'Andover',
          'Arlington',
          'Athol',
          'Attleboro',
          'Barnstable',
          'Bedford',
          'Beverly',
          'Boston',
          'Bourne',
          'Braintree',
          'Brockton',
          'Brookline',
          'Cambridge',
          'Canton',
          'Charlestown',
          'Chelmsford',
          'Chelsea',
          'Chicopee',
          'Clinton',
          'Cohasset',
          'Concord',
          'Danvers',
          'Dartmouth',
          'Dedham',
          'Dennis',
          'Duxbury',
          'Eastham',
          'Edgartown',
          'Everett',
          'Fairhaven',
          'Fall River',
          'Falmouth',
          'Fitchburg',
          'Framingham',
          'Gloucester',
          'Great Barrington',
          'Greenfield',
          'Groton',
          'Harwich',
          'Haverhill',
          'Hingham',
          'Holyoke',
          'Hyannis',
          'Ipswich',
          'Lawrence',
          'Lenox',
          'Leominster',
          'Lexington',
          'Lowell',
          'Ludlow',
          'Lynn',
          'Malden',
          'Marblehead',
          'Marlborough',
          'Medford',
          'Milton',
          'Nahant',
          'Natick',
          'New Bedford',
          'Newburyport',
          'Newton',
          'North Adams',
          'Northampton',
          'Norton',
          'Norwood',
          'Peabody',
          'Pittsfield',
          'Plymouth',
          'Provincetown',
          'Quincy',
          'Randolph',
          'Revere',
          'Salem',
          'Sandwich',
          'Saugus',
          'Somerville',
          'South Hadley',
          'Springfield',
          'Stockbridge',
          'Stoughton',
          'Sturbridge',
          'Sudbury',
          'Taunton',
          'Tewksbury',
          'Truro',
          'Watertown',
          'Webster',
          'Wellesley',
          'Wellfleet',
          'West Bridgewater',
          'West Springfield',
          'Westfield',
          'Weymouth',
          'Whitman',
          'Williamstown',
          'Woburn',
          'Woods Hole',
          'Worcester',
        ],
      },
      {
        state: 'Michigan',
        cities: [
          'Adrian',
          'Alma',
          'Ann Arbor',
          'Battle Creek',
          'Bay City',
          'Benton Harbor',
          'Bloomfield Hills',
          'Cadillac',
          'Charlevoix',
          'Cheboygan',
          'Dearborn',
          'Detroit',
          'East Lansing',
          'Eastpointe',
          'Ecorse',
          'Escanaba',
          'Flint',
          'Grand Haven',
          'Grand Rapids',
          'Grayling',
          'Grosse Pointe',
          'Hancock',
          'Highland Park',
          'Holland',
          'Houghton',
          'Interlochen',
          'Iron Mountain',
          'Ironwood',
          'Ishpeming',
          'Jackson',
          'Kalamazoo',
          'Lansing',
          'Livonia',
          'Ludington',
          'Mackinaw City',
          'Manistee',
          'Marquette',
          'Menominee',
          'Midland',
          'Monroe',
          'Mount Clemens',
          'Mount Pleasant',
          'Muskegon',
          'Niles',
          'Petoskey',
          'Pontiac',
          'Port Huron',
          'Royal Oak',
          'Saginaw',
          'Saint Ignace',
          'Saint Joseph',
          'Sault Sainte Marie',
          'Traverse City',
          'Trenton',
          'Warren',
          'Wyandotte',
          'Ypsilanti',
        ],
      },
      {
        state: 'Minnesota',
        cities: [
          'Albert Lea',
          'Alexandria',
          'Austin',
          'Bemidji',
          'Bloomington',
          'Brainerd',
          'Crookston',
          'Duluth',
          'Ely',
          'Eveleth',
          'Faribault',
          'Fergus Falls',
          'Hastings',
          'Hibbing',
          'International Falls',
          'Little Falls',
          'Mankato',
          'Minneapolis',
          'Moorhead',
          'New Ulm',
          'Northfield',
          'Owatonna',
          'Pipestone',
          'Red Wing',
          'Rochester',
          'Saint Cloud',
          'Saint Paul',
          'Sauk Centre',
          'South Saint Paul',
          'Stillwater',
          'Virginia',
          'Willmar',
          'Winona',
        ],
      },
      {
        state: 'Mississippi',
        cities: [
          'Bay Saint Louis',
          'Biloxi',
          'Canton',
          'Clarksdale',
          'Columbia',
          'Columbus',
          'Corinth',
          'Greenville',
          'Greenwood',
          'Grenada',
          'Gulfport',
          'Hattiesburg',
          'Holly Springs',
          'Jackson',
          'Laurel',
          'Meridian',
          'Natchez',
          'Ocean Springs',
          'Oxford',
          'Pascagoula',
          'Pass Christian',
          'Philadelphia',
          'Port Gibson',
          'Starkville',
          'Tupelo',
          'Vicksburg',
          'West Point',
          'Yazoo City',
        ],
      },
      {
        state: 'Missouri',
        cities: [
          'Boonville',
          'Branson',
          'Cape Girardeau',
          'Carthage',
          'Chillicothe',
          'Clayton',
          'Columbia',
          'Excelsior Springs',
          'Ferguson',
          'Florissant',
          'Fulton',
          'Hannibal',
          'Independence',
          'Jefferson City',
          'Joplin',
          'Kansas City',
          'Kirksville',
          'Lamar',
          'Lebanon',
          'Lexington',
          'Maryville',
          'Mexico',
          'Monett',
          'Neosho',
          'New Madrid',
          'Rolla',
          'Saint Charles',
          'Saint Joseph',
          'Saint Louis',
          'Sainte Genevieve',
          'Salem',
          'Sedalia',
          'Springfield',
          'Warrensburg',
          'West Plains',
        ],
      },
      {
        state: 'Montana',
        cities: [
          'Anaconda',
          'Billings',
          'Bozeman',
          'Butte',
          'Dillon',
          'Fort Benton',
          'Glendive',
          'Great Falls',
          'Havre',
          'Helena',
          'Kalispell',
          'Lewistown',
          'Livingston',
          'Miles City',
          'Missoula',
          'Virginia City',
        ],
      },
      {
        state: 'Nebraska',
        cities: [
          'Beatrice',
          'Bellevue',
          'Boys Town',
          'Chadron',
          'Columbus',
          'Fremont',
          'Grand Island',
          'Hastings',
          'Kearney',
          'Lincoln',
          'McCook',
          'Minden',
          'Nebraska City',
          'Norfolk',
          'North Platte',
          'Omaha',
          'Plattsmouth',
          'Red Cloud',
          'Sidney',
        ],
      },
      {
        state: 'Nevada',
        cities: [
          'Boulder City',
          'Carson City',
          'Elko',
          'Ely',
          'Fallon',
          'Genoa',
          'Goldfield',
          'Henderson',
          'Las Vegas',
          'North Las Vegas',
          'Reno',
          'Sparks',
          'Virginia City',
          'Winnemucca',
        ],
      },
      {
        state: 'New Hampshire',
        cities: [
          'Berlin',
          'Claremont',
          'Concord',
          'Derry',
          'Dover',
          'Durham',
          'Exeter',
          'Franklin',
          'Hanover',
          'Hillsborough',
          'Keene',
          'Laconia',
          'Lebanon',
          'Manchester',
          'Nashua',
          'Peterborough',
          'Plymouth',
          'Portsmouth',
          'Rochester',
          'Salem',
          'Somersworth',
        ],
      },
      {
        state: 'New Jersey',
        cities: [
          'Asbury Park',
          'Atlantic City',
          'Bayonne',
          'Bloomfield',
          'Bordentown',
          'Bound Brook',
          'Bridgeton',
          'Burlington',
          'Caldwell',
          'Camden',
          'Cape May',
          'Clifton',
          'Cranford',
          'East Orange',
          'Edison',
          'Elizabeth',
          'Englewood',
          'Fort Lee',
          'Glassboro',
          'Hackensack',
          'Haddonfield',
          'Hoboken',
          'Irvington',
          'Jersey City',
          'Lakehurst',
          'Lakewood',
          'Long Beach',
          'Long Branch',
          'Madison',
          'Menlo Park',
          'Millburn',
          'Millville',
          'Montclair',
          'Morristown',
          'Mount Holly',
          'New Brunswick',
          'New Milford',
          'Newark',
          'Ocean City',
          'Orange',
          'Parsippany–Troy Hills',
          'Passaic',
          'Paterson',
          'Perth Amboy',
          'Plainfield',
          'Princeton',
          'Ridgewood',
          'Roselle',
          'Rutherford',
          'Salem',
          'Somerville',
          'South Orange Village',
          'Totowa',
          'Trenton',
          'Union',
          'Union City',
          'Vineland',
          'Wayne',
          'Weehawken',
          'West New York',
          'West Orange',
          'Willingboro',
          'Woodbridge',
        ],
      },
      {
        state: 'New Mexico',
        cities: [
          'Acoma',
          'Alamogordo',
          'Albuquerque',
          'Artesia',
          'Belen',
          'Carlsbad',
          'Clovis',
          'Deming',
          'Farmington',
          'Gallup',
          'Grants',
          'Hobbs',
          'Las Cruces',
          'Las Vegas',
          'Los Alamos',
          'Lovington',
          'Portales',
          'Raton',
          'Roswell',
          'Santa Fe',
          'Shiprock',
          'Silver City',
          'Socorro',
          'Taos',
          'Truth or Consequences',
          'Tucumcari',
        ],
      },
      {
        state: 'New York',
        cities: [
          'Albany',
          'Amsterdam',
          'Auburn',
          'Babylon',
          'Batavia',
          'Beacon',
          'Bedford',
          'Binghamton',
          'Bronx',
          'Brooklyn',
          'Buffalo',
          'Chautauqua',
          'Cheektowaga',
          'Clinton',
          'Cohoes',
          'Coney Island',
          'Cooperstown',
          'Corning',
          'Cortland',
          'Crown Point',
          'Dunkirk',
          'East Aurora',
          'East Hampton',
          'Eastchester',
          'Elmira',
          'Flushing',
          'Forest Hills',
          'Fredonia',
          'Garden City',
          'Geneva',
          'Glens Falls',
          'Gloversville',
          'Great Neck',
          'Hammondsport',
          'Harlem',
          'Hempstead',
          'Herkimer',
          'Hudson',
          'Huntington',
          'Hyde Park',
          'Ilion',
          'Ithaca',
          'Jamestown',
          'Johnstown',
          'Kingston',
          'Lackawanna',
          'Lake Placid',
          'Levittown',
          'Lockport',
          'Mamaroneck',
          'Manhattan',
          'Massena',
          'Middletown',
          'Mineola',
          'Mount Vernon',
          'New Paltz',
          'New Rochelle',
          'New Windsor',
          'New York City',
          'Newburgh',
          'Niagara Falls',
          'North Hempstead',
          'Nyack',
          'Ogdensburg',
          'Olean',
          'Oneida',
          'Oneonta',
          'Ossining',
          'Oswego',
          'Oyster Bay',
          'Palmyra',
          'Peekskill',
          'Plattsburgh',
          'Port Washington',
          'Potsdam',
          'Poughkeepsie',
          'Queens',
          'Rensselaer',
          'Rochester',
          'Rome',
          'Rotterdam',
          'Rye',
          'Sag Harbor',
          'Saranac Lake',
          'Saratoga Springs',
          'Scarsdale',
          'Schenectady',
          'Seneca Falls',
          'Southampton',
          'Staten Island',
          'Stony Brook',
          'Stony Point',
          'Syracuse',
          'Tarrytown',
          'Ticonderoga',
          'Tonawanda',
          'Troy',
          'Utica',
          'Watertown',
          'Watervliet',
          'Watkins Glen',
          'West Seneca',
          'White Plains',
          'Woodstock',
          'Yonkers',
        ],
      },
      {
        state: 'North Carolina',
        cities: [
          'Asheboro',
          'Asheville',
          'Bath',
          'Beaufort',
          'Boone',
          'Burlington',
          'Chapel Hill',
          'Charlotte',
          'Concord',
          'Durham',
          'Edenton',
          'Elizabeth City',
          'Fayetteville',
          'Gastonia',
          'Goldsboro',
          'Greensboro',
          'Greenville',
          'Halifax',
          'Henderson',
          'Hickory',
          'High Point',
          'Hillsborough',
          'Jacksonville',
          'Kinston',
          'Kitty Hawk',
          'Lumberton',
          'Morehead City',
          'Morganton',
          'Nags Head',
          'New Bern',
          'Pinehurst',
          'Raleigh',
          'Rocky Mount',
          'Salisbury',
          'Shelby',
          'Washington',
          'Wilmington',
          'Wilson',
          'Winston-Salem',
        ],
      },
      {
        state: 'North Dakota',
        cities: [
          'Bismarck',
          'Devils Lake',
          'Dickinson',
          'Fargo',
          'Grand Forks',
          'Jamestown',
          'Mandan',
          'Minot',
          'Rugby',
          'Valley City',
          'Wahpeton',
          'Williston',
        ],
      },
      {
        state: 'Ohio',
        cities: [
          'Akron',
          'Alliance',
          'Ashtabula',
          'Athens',
          'Barberton',
          'Bedford',
          'Bellefontaine',
          'Bowling Green',
          'Canton',
          'Chillicothe',
          'Cincinnati',
          'Cleveland',
          'Cleveland Heights',
          'Columbus',
          'Conneaut',
          'Cuyahoga Falls',
          'Dayton',
          'Defiance',
          'Delaware',
          'East Cleveland',
          'East Liverpool',
          'Elyria',
          'Euclid',
          'Findlay',
          'Gallipolis',
          'Greenville',
          'Hamilton',
          'Kent',
          'Kettering',
          'Lakewood',
          'Lancaster',
          'Lima',
          'Lorain',
          'Mansfield',
          'Marietta',
          'Marion',
          'Martins Ferry',
          'Massillon',
          'Mentor',
          'Middletown',
          'Milan',
          'Mount Vernon',
          'New Philadelphia',
          'Newark',
          'Niles',
          'North College Hill',
          'Norwalk',
          'Oberlin',
          'Painesville',
          'Parma',
          'Piqua',
          'Portsmouth',
          'Put-in-Bay',
          'Salem',
          'Sandusky',
          'Shaker Heights',
          'Springfield',
          'Steubenville',
          'Tiffin',
          'Toledo',
          'Urbana',
          'Warren',
          'Wooster',
          'Worthington',
          'Xenia',
          'Yellow Springs',
          'Youngstown',
          'Zanesville',
        ],
      },
      {
        state: 'Oklahoma',
        cities: [
          'Ada',
          'Altus',
          'Alva',
          'Anadarko',
          'Ardmore',
          'Bartlesville',
          'Bethany',
          'Chickasha',
          'Claremore',
          'Clinton',
          'Cushing',
          'Duncan',
          'Durant',
          'Edmond',
          'El Reno',
          'Elk City',
          'Enid',
          'Eufaula',
          'Frederick',
          'Guthrie',
          'Guymon',
          'Hobart',
          'Holdenville',
          'Hugo',
          'Lawton',
          'McAlester',
          'Miami',
          'Midwest City',
          'Moore',
          'Muskogee',
          'Norman',
          'Oklahoma City',
          'Okmulgee',
          'Pauls Valley',
          'Pawhuska',
          'Perry',
          'Ponca City',
          'Pryor',
          'Sallisaw',
          'Sand Springs',
          'Sapulpa',
          'Seminole',
          'Shawnee',
          'Stillwater',
          'Tahlequah',
          'The Village',
          'Tulsa',
          'Vinita',
          'Wewoka',
          'Woodward',
        ],
      },
      {
        state: 'Oregon',
        cities: [
          'Albany',
          'Ashland',
          'Astoria',
          'Baker City',
          'Beaverton',
          'Bend',
          'Brookings',
          'Burns',
          'Coos Bay',
          'Corvallis',
          'Eugene',
          'Grants Pass',
          'Hillsboro',
          'Hood River',
          'Jacksonville',
          'John Day',
          'Klamath Falls',
          'La Grande',
          'Lake Oswego',
          'Lakeview',
          'McMinnville',
          'Medford',
          'Newberg',
          'Newport',
          'Ontario',
          'Oregon City',
          'Pendleton',
          'Port Orford',
          'Portland',
          'Prineville',
          'Redmond',
          'Reedsport',
          'Roseburg',
          'Salem',
          'Seaside',
          'Springfield',
          'The Dalles',
          'Tillamook',
        ],
      },
      {
        state: 'Pennsylvania',
        cities: [
          'Abington',
          'Aliquippa',
          'Allentown',
          'Altoona',
          'Ambridge',
          'Bedford',
          'Bethlehem',
          'Bloomsburg',
          'Bradford',
          'Bristol',
          'Carbondale',
          'Carlisle',
          'Chambersburg',
          'Chester',
          'Columbia',
          'Easton',
          'Erie',
          'Franklin',
          'Germantown',
          'Gettysburg',
          'Greensburg',
          'Hanover',
          'Harmony',
          'Harrisburg',
          'Hazleton',
          'Hershey',
          'Homestead',
          'Honesdale',
          'Indiana',
          'Jeannette',
          'Jim Thorpe',
          'Johnstown',
          'Lancaster',
          'Lebanon',
          'Levittown',
          'Lewistown',
          'Lock Haven',
          'Lower Southampton',
          'McKeesport',
          'Meadville',
          'Middletown',
          'Monroeville',
          'Nanticoke',
          'New Castle',
          'New Hope',
          'New Kensington',
          'Norristown',
          'Oil City',
          'Philadelphia',
          'Phoenixville',
          'Pittsburgh',
          'Pottstown',
          'Pottsville',
          'Reading',
          'Scranton',
          'Shamokin',
          'Sharon',
          'State College',
          'Stroudsburg',
          'Sunbury',
          'Swarthmore',
          'Tamaqua',
          'Titusville',
          'Uniontown',
          'Warren',
          'Washington',
          'West Chester',
          'Wilkes-Barre',
          'Williamsport',
          'York',
        ],
      },
      {
        state: 'Rhode Island',
        cities: [
          'Barrington',
          'Bristol',
          'Central Falls',
          'Cranston',
          'East Greenwich',
          'East Providence',
          'Kingston',
          'Middletown',
          'Narragansett',
          'Newport',
          'North Kingstown',
          'Pawtucket',
          'Portsmouth',
          'Providence',
          'South Kingstown',
          'Tiverton',
          'Warren',
          'Warwick',
          'Westerly',
          'Wickford',
          'Woonsocket',
        ],
      },
      {
        state: 'South Carolina',
        cities: [
          'Abbeville',
          'Aiken',
          'Anderson',
          'Beaufort',
          'Camden',
          'Charleston',
          'Columbia',
          'Darlington',
          'Florence',
          'Gaffney',
          'Georgetown',
          'Greenville',
          'Greenwood',
          'Hartsville',
          'Lancaster',
          'Mount Pleasant',
          'Myrtle Beach',
          'Orangeburg',
          'Rock Hill',
          'Spartanburg',
          'Sumter',
          'Union',
        ],
      },
      {
        state: 'South Dakota',
        cities: [
          'Aberdeen',
          'Belle Fourche',
          'Brookings',
          'Canton',
          'Custer',
          'De Smet',
          'Deadwood',
          'Hot Springs',
          'Huron',
          'Lead',
          'Madison',
          'Milbank',
          'Mitchell',
          'Mobridge',
          'Pierre',
          'Rapid City',
          'Sioux Falls',
          'Spearfish',
          'Sturgis',
          'Vermillion',
          'Watertown',
          'Yankton',
        ],
      },
      {
        state: 'Tennessee',
        cities: [
          'Alcoa',
          'Athens',
          'Chattanooga',
          'Clarksville',
          'Cleveland',
          'Columbia',
          'Cookeville',
          'Dayton',
          'Elizabethton',
          'Franklin',
          'Gallatin',
          'Gatlinburg',
          'Greeneville',
          'Jackson',
          'Johnson City',
          'Jonesborough',
          'Kingsport',
          'Knoxville',
          'Lebanon',
          'Maryville',
          'Memphis',
          'Morristown',
          'Murfreesboro',
          'Nashville',
          'Norris',
          'Oak Ridge',
          'Shelbyville',
          'Tullahoma',
        ],
      },
      {
        state: 'Texas',
        cities: [
          'Abilene',
          'Alpine',
          'Amarillo',
          'Arlington',
          'Austin',
          'Baytown',
          'Beaumont',
          'Big Spring',
          'Borger',
          'Brownsville',
          'Bryan',
          'Canyon',
          'Cleburne',
          'College Station',
          'Corpus Christi',
          'Crystal City',
          'Dallas',
          'Del Rio',
          'Denison',
          'Denton',
          'Eagle Pass',
          'Edinburg',
          'El Paso',
          'Fort Worth',
          'Freeport',
          'Galveston',
          'Garland',
          'Goliad',
          'Greenville',
          'Harlingen',
          'Houston',
          'Huntsville',
          'Irving',
          'Johnson City',
          'Kilgore',
          'Killeen',
          'Kingsville',
          'Laredo',
          'Longview',
          'Lubbock',
          'Lufkin',
          'Marshall',
          'McAllen',
          'McKinney',
          'Mesquite',
          'Midland',
          'Mission',
          'Nacogdoches',
          'New Braunfels',
          'Odessa',
          'Orange',
          'Pampa',
          'Paris',
          'Pasadena',
          'Pecos',
          'Pharr',
          'Plainview',
          'Plano',
          'Port Arthur',
          'Port Lavaca',
          'Richardson',
          'San Angelo',
          'San Antonio',
          'San Felipe',
          'San Marcos',
          'Sherman',
          'Sweetwater',
          'Temple',
          'Texarkana',
          'Texas City',
          'Tyler',
          'Uvalde',
          'Victoria',
          'Waco',
          'Weatherford',
          'Wichita Falls',
          'Ysleta',
        ],
      },
      {
        state: 'Utah',
        cities: [
          'Alta',
          'American Fork',
          'Bountiful',
          'Brigham City',
          'Cedar City',
          'Clearfield',
          'Delta',
          'Fillmore',
          'Green River',
          'Heber City',
          'Kanab',
          'Layton',
          'Lehi',
          'Logan',
          'Manti',
          'Moab',
          'Monticello',
          'Murray',
          'Nephi',
          'Ogden',
          'Orderville',
          'Orem',
          'Panguitch',
          'Park City',
          'Payson',
          'Price',
          'Provo',
          'Saint George',
          'Salt Lake City',
          'Spanish Fork',
          'Springville',
          'Tooele',
          'Vernal',
        ],
      },
      {
        state: 'Vermont',
        cities: [
          'Barre',
          'Bellows Falls',
          'Bennington',
          'Brattleboro',
          'Burlington',
          'Essex',
          'Manchester',
          'Middlebury',
          'Montpelier',
          'Newport',
          'Plymouth',
          'Rutland',
          'Saint Albans',
          'Saint Johnsbury',
          'Sharon',
          'Winooski',
        ],
      },
      {
        state: 'Virginia',
        cities: [
          'Abingdon',
          'Alexandria',
          'Bristol',
          'Charlottesville',
          'Chesapeake',
          'Danville',
          'Fairfax',
          'Falls Church',
          'Fredericksburg',
          'Hampton',
          'Hanover',
          'Hopewell',
          'Lexington',
          'Lynchburg',
          'Manassas',
          'Martinsville',
          'New Market',
          'Newport News',
          'Norfolk',
          'Petersburg',
          'Portsmouth',
          'Reston',
          'Richmond',
          'Roanoke',
          'Staunton',
          'Suffolk',
          'Virginia Beach',
          'Waynesboro',
          'Williamsburg',
          'Winchester',
        ],
      },
      {
        state: 'Washington',
        cities: [
          'Aberdeen',
          'Anacortes',
          'Auburn',
          'Bellevue',
          'Bellingham',
          'Bremerton',
          'Centralia',
          'Coulee Dam',
          'Coupeville',
          'Ellensburg',
          'Ephrata',
          'Everett',
          'Hoquiam',
          'Kelso',
          'Kennewick',
          'Longview',
          'Moses Lake',
          'Oak Harbor',
          'Olympia',
          'Pasco',
          'Point Roberts',
          'Port Angeles',
          'Pullman',
          'Puyallup',
          'Redmond',
          'Renton',
          'Richland',
          'Seattle',
          'Spokane',
          'Tacoma',
          'Vancouver',
          'Walla Walla',
          'Wenatchee',
          'Yakima',
        ],
      },
      {
        state: 'West Virginia',
        cities: [
          'Bath',
          'Beckley',
          'Bluefield',
          'Buckhannon',
          'Charles Town',
          'Charleston',
          'Clarksburg',
          'Elkins',
          'Fairmont',
          'Grafton',
          'Harpers Ferry',
          'Hillsboro',
          'Hinton',
          'Huntington',
          'Keyser',
          'Lewisburg',
          'Logan',
          'Martinsburg',
          'Morgantown',
          'Moundsville',
          'New Martinsville',
          'Parkersburg',
          'Philippi',
          'Point Pleasant',
          'Princeton',
          'Romney',
          'Shepherdstown',
          'South Charleston',
          'Summersville',
          'Weirton',
          'Welch',
          'Wellsburg',
          'Weston',
          'Wheeling',
          'White Sulphur Springs',
          'Williamson',
        ],
      },
      {
        state: 'Wisconsin',
        cities: [
          'Appleton',
          'Ashland',
          'Baraboo',
          'Belmont',
          'Beloit',
          'Eau Claire',
          'Fond du Lac',
          'Green Bay',
          'Hayward',
          'Janesville',
          'Kenosha',
          'La Crosse',
          'Lake Geneva',
          'Madison',
          'Manitowoc',
          'Marinette',
          'Menasha',
          'Milwaukee',
          'Neenah',
          'New Glarus',
          'Oconto',
          'Oshkosh',
          'Peshtigo',
          'Portage',
          'Prairie du Chien',
          'Racine',
          'Rhinelander',
          'Ripon',
          'Sheboygan',
          'Spring Green',
          'Stevens Point',
          'Sturgeon Bay',
          'Superior',
          'Waukesha',
          'Wausau',
          'Wauwatosa',
          'West Allis',
          'West Bend',
          'Wisconsin Dells',
        ],
      },
      {
        state: 'Wyoming',
        cities: [
          'Buffalo',
          'Casper',
          'Cheyenne',
          'Cody',
          'Douglas',
          'Evanston',
          'Gillette',
          'Green River',
          'Jackson',
          'Lander',
          'Laramie',
          'Newcastle',
          'Powell',
          'Rawlins',
          'Riverton',
          'Rock Springs',
          'Sheridan',
          'Ten Sleep',
          'Thermopolis',
          'Torrington',
          'Worland',
        ],
      },
    ],
  },
  {
    country: 'Canada',
    states: [
      {
        state: 'pan-Canada',
        cities: ['All Cities'],
      },
      {
        state: 'Alberta',
        cities: [
          'Banff',
          'Brooks',
          'Calgary',
          'Edmonton',
          'Fort McMurray',
          'Grande Prairie',
          'Jasper',
          'Lake Louise',
          'Lethbridge',
          'Medicine Hat',
          'Red Deer',
          'Saint Albert',
        ],
      },
      {
        state: 'British Columbia',
        cities: [
          'Barkerville',
          'Burnaby',
          'Campbell River',
          'Chilliwack',
          'Courtenay',
          'Cranbrook',
          'Dawson Creek',
          'Delta',
          'Esquimalt',
          'Fort Saint James',
          'Fort Saint John',
          'Hope',
          'Kamloops',
          'Kelowna',
          'Kimberley',
          'Kitimat',
          'Langley',
          'Nanaimo',
          'Nelson',
          'New Westminster',
          'North Vancouver',
          'Oak Bay',
          'Penticton',
          'Powell River',
          'Prince George',
          'Prince Rupert',
          'Quesnel',
          'Revelstoke',
          'Rossland',
          'Trail',
          'Vancouver',
          'Vernon',
          'Victoria',
          'West Vancouver',
          'White Rock',
        ],
      },
      {
        state: 'Manitoba',
        cities: [
          'Brandon',
          'Churchill',
          'Dauphin',
          'Flin Flon',
          'Kildonan',
          'Saint Boniface',
          'Swan River',
          'Thompson',
          'Winnipeg',
          'York Factory',
        ],
      },
      {
        state: 'New Brunswick',
        cities: [
          'Bathurst',
          'Caraquet',
          'Dalhousie',
          'Fredericton',
          'Miramichi',
          'Moncton',
          'Saint John',
        ],
      },
      {
        state: 'Newfoundland and Labrador',
        cities: [
          'Argentia',
          'Bonavista',
          'Channel-Port aux Basques',
          'Corner Brook',
          'Ferryland',
          'Gander',
          'Grand Falls–Windsor',
          'Happy Valley–Goose Bay',
          'Harbour Grace',
          'Labrador City',
          'Placentia',
          'Saint Anthony',
          'St. John’s',
          'Wabana',
        ],
      },
      {
        state: 'Northwest Territories',
        cities: [
          'Fort Smith',
          'Hay River',
          'Inuvik',
          'Tuktoyaktuk',
          'Yellowknife',
        ],
      },
      {
        state: 'Nova Scotia',
        cities: [
          'Baddeck',
          'Digby',
          'Glace Bay',
          'Halifax',
          'Liverpool',
          'Louisbourg',
          'Lunenburg',
          'Pictou',
          'Port Hawkesbury',
          'Springhill',
          'Sydney',
          'Yarmouth',
        ],
      },
      {
        state: 'Nunavut',
        cities: ['Iqaluit'],
      },
      {
        state: 'Ontario',
        cities: [
          'Bancroft',
          'Barrie',
          'Belleville',
          'Brampton',
          'Brantford',
          'Brockville',
          'Burlington',
          'Cambridge',
          'Chatham',
          'Chatham-Kent',
          'Cornwall',
          'Elliot Lake',
          'Etobicoke',
          'Fort Erie',
          'Fort Frances',
          'Gananoque',
          'Guelph',
          'Hamilton',
          'Iroquois Falls',
          'Kapuskasing',
          'Kawartha Lakes',
          'Kenora',
          'Kingston',
          'Kirkland Lake',
          'Kitchener',
          'Laurentian Hills',
          'London',
          'Midland',
          'Mississauga',
          'Moose Factory',
          'Moosonee',
          'Niagara Falls',
          'Niagara-on-the-Lake',
          'North Bay',
          'North York',
          'Oakville',
          'Orillia',
          'Oshawa',
          'Ottawa',
          'Parry Sound',
          'Perth',
          'Peterborough',
          'Picton',
          'Port Colborne',
          'Saint Catharines',
          'Saint Thomas',
          'Sarnia-Clearwater',
          'Sault Sainte Marie',
          'Scarborough',
          'Simcoe',
          'Stratford',
          'Sudbury',
          'Temiskaming Shores',
          'Thorold',
          'Thunder Bay',
          'Timmins',
          'Toronto',
          'Trenton',
          'Waterloo',
          'Welland',
          'West Nipissing',
          'Windsor',
          'Woodstock',
          'York',
        ],
      },
      {
        state: 'Prince Edward Island',
        cities: [
          'Borden',
          'Cavendish',
          'Charlottetown',
          'Souris',
          'Summerside',
        ],
      },
      {
        state: 'Quebec',
        cities: [
          'Asbestos',
          'Baie-Comeau',
          'Beloeil',
          'Cap-de-la-Madeleine',
          'Chambly',
          'Charlesbourg',
          'Châteauguay',
          'Chibougamau',
          'Côte-Saint-Luc',
          'Dorval',
          'Gaspé',
          'Gatineau',
          'Granby',
          'Havre-Saint-Pierre',
          'Hull',
          'Jonquière',
          'Kuujjuaq',
          'La Salle',
          'La Tuque',
          'Lachine',
          'Laval',
          'Lévis',
          'Longueuil',
          'Magog',
          'Matane',
          'Montreal',
          'Montréal-Nord',
          'Percé',
          'Port-Cartier',
          'Quebec',
          'Rimouski',
          'Rouyn-Noranda',
          'Saguenay',
          'Saint-Eustache',
          'Saint-Hubert',
          'Sainte-Anne-de-Beaupré',
          'Sainte-Foy',
          'Sainte-Thérèse',
          'Sept-Îles',
          'Sherbrooke',
          'Sorel-Tracy',
          'Trois-Rivières',
          'Val-d’Or',
          'Waskaganish',
        ],
      },
      {
        state: 'Saskatchewan',
        cities: [
          'Batoche',
          'Cumberland House',
          'Estevan',
          'Flin Flon',
          'Moose Jaw',
          'Prince Albert',
          'Regina',
          'Saskatoon',
          'Uranium City',
        ],
      },
      {
        state: 'Yukon',
        cities: ['Dawson', 'Watson Lake', 'Whitehorse'],
      },
    ],
  },
  {
    country: 'Germany',
    states: [
      {
        state: 'pan-Germany',
        cities: ['All Cities'],
      },
      {
        state: 'Alberta',
        cities: [
          'Banff',
          'Brooks',
          'Calgary',
          'Edmonton',
          'Fort McMurray',
          'Grande Prairie',
          'Jasper',
          'Lake Louise',
          'Lethbridge',
          'Medicine Hat',
          'Red Deer',
          'Saint Albert',
        ],
      },
      {
        state: 'British Columbia',
        cities: [
          'Barkerville',
          'Burnaby',
          'Campbell River',
          'Chilliwack',
          'Courtenay',
          'Cranbrook',
          'Dawson Creek',
          'Delta',
          'Esquimalt',
          'Fort Saint James',
          'Fort Saint John',
          'Hope',
          'Kamloops',
          'Kelowna',
          'Kimberley',
          'Kitimat',
          'Langley',
          'Nanaimo',
          'Nelson',
          'New Westminster',
          'North Vancouver',
          'Oak Bay',
          'Penticton',
          'Powell River',
          'Prince George',
          'Prince Rupert',
          'Quesnel',
          'Revelstoke',
          'Rossland',
          'Trail',
          'Vancouver',
          'Vernon',
          'Victoria',
          'West Vancouver',
          'White Rock',
        ],
      },
      {
        state: 'Manitoba',
        cities: [
          'Brandon',
          'Churchill',
          'Dauphin',
          'Flin Flon',
          'Kildonan',
          'Saint Boniface',
          'Swan River',
          'Thompson',
          'Winnipeg',
          'York Factory',
        ],
      },
      {
        state: 'New Brunswick',
        cities: [
          'Bathurst',
          'Caraquet',
          'Dalhousie',
          'Fredericton',
          'Miramichi',
          'Moncton',
          'Saint John',
        ],
      },
      {
        state: 'Newfoundland and Labrador',
        cities: [
          'Argentia',
          'Bonavista',
          'Channel-Port aux Basques',
          'Corner Brook',
          'Ferryland',
          'Gander',
          'Grand Falls–Windsor',
          'Happy Valley–Goose Bay',
          'Harbour Grace',
          'Labrador City',
          'Placentia',
          'Saint Anthony',
          'St. John’s',
          'Wabana',
        ],
      },
      {
        state: 'Northwest Territories',
        cities: [
          'Fort Smith',
          'Hay River',
          'Inuvik',
          'Tuktoyaktuk',
          'Yellowknife',
        ],
      },
      {
        state: 'Nova Scotia',
        cities: [
          'Baddeck',
          'Digby',
          'Glace Bay',
          'Halifax',
          'Liverpool',
          'Louisbourg',
          'Lunenburg',
          'Pictou',
          'Port Hawkesbury',
          'Springhill',
          'Sydney',
          'Yarmouth',
        ],
      },
      {
        state: 'Nunavut',
        cities: ['Iqaluit'],
      },
      {
        state: 'Ontario',
        cities: [
          'Bancroft',
          'Barrie',
          'Belleville',
          'Brampton',
          'Brantford',
          'Brockville',
          'Burlington',
          'Cambridge',
          'Chatham',
          'Chatham-Kent',
          'Cornwall',
          'Elliot Lake',
          'Etobicoke',
          'Fort Erie',
          'Fort Frances',
          'Gananoque',
          'Guelph',
          'Hamilton',
          'Iroquois Falls',
          'Kapuskasing',
          'Kawartha Lakes',
          'Kenora',
          'Kingston',
          'Kirkland Lake',
          'Kitchener',
          'Laurentian Hills',
          'London',
          'Midland',
          'Mississauga',
          'Moose Factory',
          'Moosonee',
          'Niagara Falls',
          'Niagara-on-the-Lake',
          'North Bay',
          'North York',
          'Oakville',
          'Orillia',
          'Oshawa',
          'Ottawa',
          'Parry Sound',
          'Perth',
          'Peterborough',
          'Picton',
          'Port Colborne',
          'Saint Catharines',
          'Saint Thomas',
          'Sarnia-Clearwater',
          'Sault Sainte Marie',
          'Scarborough',
          'Simcoe',
          'Stratford',
          'Sudbury',
          'Temiskaming Shores',
          'Thorold',
          'Thunder Bay',
          'Timmins',
          'Toronto',
          'Trenton',
          'Waterloo',
          'Welland',
          'West Nipissing',
          'Windsor',
          'Woodstock',
          'York',
        ],
      },
      {
        state: 'Prince Edward Island',
        cities: [
          'Borden',
          'Cavendish',
          'Charlottetown',
          'Souris',
          'Summerside',
        ],
      },
      {
        state: 'Quebec',
        cities: [
          'Asbestos',
          'Baie-Comeau',
          'Beloeil',
          'Cap-de-la-Madeleine',
          'Chambly',
          'Charlesbourg',
          'Châteauguay',
          'Chibougamau',
          'Côte-Saint-Luc',
          'Dorval',
          'Gaspé',
          'Gatineau',
          'Granby',
          'Havre-Saint-Pierre',
          'Hull',
          'Jonquière',
          'Kuujjuaq',
          'La Salle',
          'La Tuque',
          'Lachine',
          'Laval',
          'Lévis',
          'Longueuil',
          'Magog',
          'Matane',
          'Montreal',
          'Montréal-Nord',
          'Percé',
          'Port-Cartier',
          'Quebec',
          'Rimouski',
          'Rouyn-Noranda',
          'Saguenay',
          'Saint-Eustache',
          'Saint-Hubert',
          'Sainte-Anne-de-Beaupré',
          'Sainte-Foy',
          'Sainte-Thérèse',
          'Sept-Îles',
          'Sherbrooke',
          'Sorel-Tracy',
          'Trois-Rivières',
          'Val-d’Or',
          'Waskaganish',
        ],
      },
      {
        state: 'Saskatchewan',
        cities: [
          'Batoche',
          'Cumberland House',
          'Estevan',
          'Flin Flon',
          'Moose Jaw',
          'Prince Albert',
          'Regina',
          'Saskatoon',
          'Uranium City',
        ],
      },
      {
        state: 'Yukon',
        cities: ['Dawson', 'Watson Lake', 'Whitehorse'],
      },
      {
        state: '',
        cities: [
          'Lake Athabasca',
          'lake',
          'Canada',
          'Northwest Territories',
          'deltas',
          'Slave River',
          'Arctic Ocean',
          'Great Slave Lake',
          'Mackenzie River',
          '',
          'Samuel Hearne',
          'North West Company',
          'Uranium City',
          'gold',
          'uranium',
          'commercial fishing',
          'Wood Buffalo National Park',
          'national park',
        ],
      },
    ],
  },
  {
    country: 'Australia',

    states: [
      {
        state: 'pan-Australia',
        cities: ['All Cities'],
      },
      {
        state: 'Australian Capital Territory',
        cities: ['Canberra'],
      },
      {
        state: 'New South Wales',
        cities: [
          'Albury-Wodonga',
          'Armidale',
          'Ballina',
          'Balranald',
          'Batemans Bay',
          'Bathurst',
          'Bega',
          'Bourke',
          'Bowral',
          'Broken Hill',
          'Byron Bay',
          'Camden',
          'Campbelltown',
          'Cobar',
          'Coffs Harbour',
          'Cooma',
          'Coonabarabran',
          'Coonamble',
          'Cootamundra',
          'Corowa',
          'Cowra',
          'Deniliquin',
          'Dubbo',
          'Forbes',
          'Forster',
          'Glen Innes',
          'Gosford',
          'Goulburn',
          'Grafton',
          'Griffith',
          'Gundagai',
          'Gunnedah',
          'Hay',
          'Inverell',
          'Junee',
          'Katoomba',
          'Kempsey',
          'Kiama',
          'Kurri Kurri',
          'Lake Cargelligo',
          'Lismore',
          'Lithgow',
          'Maitland',
          'Moree',
          'Moruya',
          'Murwillumbah',
          'Muswellbrook',
          'Nambucca Heads',
          'Narrabri',
          'Narrandera',
          'Newcastle',
          'Nowra-Bomaderry',
          'Orange',
          'Parkes',
          'Parramatta',
          'Penrith',
          'Port Macquarie',
          'Queanbeyan',
          'Raymond Terrace',
          'Richmond',
          'Scone',
          'Singleton',
          'Sydney',
          'Tamworth',
          'Taree',
          'Temora',
          'Tenterfield',
          'Tumut',
          'Ulladulla',
          'Wagga Wagga',
          'Wauchope',
          'Wellington',
          'West Wyalong',
          'Windsor',
          'Wollongong',
          'Wyong',
          'Yass',
          'Young',
        ],
      },
      {
        state: 'Northern Territory',
        cities: [
          'Alice Springs',
          'Anthony Lagoon',
          'Darwin',
          'Katherine',
          'Tennant Creek',
        ],
      },
      {
        state: 'Queensland',
        cities: [
          'Ayr',
          'Beaudesert',
          'Blackwater',
          'Bowen',
          'Brisbane',
          'Buderim',
          'Bundaberg',
          'Caboolture',
          'Cairns',
          'Charleville',
          'Charters Towers',
          'Cooktown',
          'Dalby',
          'Deception Bay',
          'Emerald',
          'Gatton',
          'Gladstone',
          'Gold Coast',
          'Goondiwindi',
          'Gympie',
          'Hervey Bay',
          'Ingham',
          'Innisfail',
          'Kingaroy',
          'Mackay',
          'Mareeba',
          'Maroochydore',
          'Maryborough',
          'Moonie',
          'Moranbah',
          'Mount Isa',
          'Mount Morgan',
          'Moura',
          'Redcliffe',
          'Rockhampton',
          'Roma',
          'Stanthorpe',
          'Toowoomba',
          'Townsville',
          'Warwick',
          'Weipa',
          'Winton',
          'Yeppoon',
        ],
      },
      {
        state: 'South Australia',
        cities: [
          'Adelaide',
          'Ceduna',
          'Clare',
          'Coober Pedy',
          'Gawler',
          'Goolwa',
          'Iron Knob',
          'Leigh Creek',
          'Loxton',
          'Millicent',
          'Mount Gambier',
          'Murray Bridge',
          'Naracoorte',
          'Oodnadatta',
          'Port Adelaide Enfield',
          'Port Augusta',
          'Port Lincoln',
          'Port Pirie',
          'Renmark',
          'Victor Harbor',
          'Whyalla',
        ],
      },
      {
        state: 'Tasmania',
        cities: [
          'Beaconsfield',
          'Bell Bay',
          'Burnie',
          'Devonport',
          'Hobart',
          'Kingston',
          'Launceston',
          'New Norfolk',
          'Queenstown',
          'Richmond',
          'Rosebery',
          'Smithton',
          'Stanley',
          'Ulverstone',
          'Wynyard',
        ],
      },
      {
        state: 'Victoria',
        cities: [
          'Albury-Wodonga',
          'Ararat',
          'Bacchus Marsh',
          'Bairnsdale',
          'Ballarat',
          'Beechworth',
          'Benalla',
          'Bendigo',
          'Castlemaine',
          'Colac',
          'Echuca',
          'Geelong',
          'Hamilton',
          'Healesville',
          'Horsham',
          'Kerang',
          'Kyabram',
          'Kyneton',
          'Lakes Entrance',
          'Maryborough',
          'Melbourne',
          'Mildura',
          'Moe',
          'Morwell',
          'Port Fairy',
          'Portland',
          'Sale',
          'Sea Lake',
          'Seymour',
          'Shepparton',
          'Sunbury',
          'Swan Hill',
          'Traralgon',
          'Yarrawonga',
          'Wangaratta',
          'Warragul',
          'Werribee',
          'Wonthaggi',
        ],
      },
      {
        state: 'Western Australia',
        cities: [
          'Broome',
          'Bunbury',
          'Busselton',
          'Coolgardie',
          'Dampier',
          'Derby',
          'Fremantle',
          'Geraldton',
          'Kalgoorlie',
          'Kambalda',
          'Katanning',
          'Kwinana',
          'Mandurah',
          'Meekatharra',
          'Mount Barker',
          'Narrogin',
          'Newman',
          'Northam',
          'Perth',
          'Port Hedland',
          'Tom Price',
          'Wyndham',
        ],
      },
      {
        state: 'Lismore, New South Wales, Australia',
        cities: [
          'Lismore',
          'New South Wales',
          'Australia',
          'Richmond River',
          'Pacific Ocean',
          'Ballina',
        ],
      },
    ],
  },
  {
    country: 'UAE',
    states: [
      {
        state: 'pan-UAE',
        cities: ['All Cities'],
      },
      {
        state: 'Abu Dhabi',
        cities: ['Abu Dhabi'],
      },
      {
        state: 'Dubai',
        cities: ['Dubai'],
      },
      {
        state: 'Sharjah',
        cities: ['Sharjah'],
      },
      {
        state: 'Ras Al Khaimah',
        cities: ['Ras Al Khaimah'],
      },
      {
        state: 'Fujairah',
        cities: ['Fujairah'],
      },
      {
        state: 'Umm Al-Quwain',
        cities: ['Umm Al-Quwain'],
      },
      {
        state: 'Ajman',
        cities: ['Ajman'],
      },
    ],
  },
];
