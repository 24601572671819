import React,{useState,useEffect} from 'react'
import axios from 'axios'
 
function Account_details_executive() {
    const [value, setValue] = useState({
        Account_holder_name:"",
        Account_number:"",
        Ifsc_code:"",
        Bank_name:"",
        Branch_name:"",
        terms_and_condition:false
    })
    const [Edit, setEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [Submit2, setSubmit2] = useState(false);
    useEffect(() => {
        load()
    }, [])
    const load=()=>{
        console.log("loding")
                axios.get('/api/Admin/my_bank_details').then((res)=>{
            if (res.data.Account_holder_name) {
                setEdit(true)
                setValue({
                    id:res.data._id,
                    Account_holder_name:res.data.Account_holder_name,
                    Account_number:res.data.Account_number,
                    Ifsc_code:res.data.Ifsc_code,
                    Bank_name:res.data.Bank_name,
                    Branch_name:res.data.Branch_name,
                    terms_and_condition:res.data.terms_and_condition?res.data.terms_and_condition:false
                })
            }
          })
    }

    const submit=(e)=>{
        e.preventDefault();
        console.log(value)
        setLoading(true)
        if (value.terms_and_condition) {
            if (!Edit) {
                axios.post('/api/Admin/my_bank_details_add',{value}).then((res)=>{
                    if (res.data) {
                        setLoading(false)
                        load()
                        alert("Account details updated")
                    }
                }).catch((err)=>{
                    alert("Something went wrong")
                    setLoading(false)
                })  
            }else{
                axios.post('/api/Admin/my_bank_details_Update',{value}).then((res)=>{
                    if (res.data) {
                        setLoading(false)
                        setValue({
                            id:"",
                            Account_holder_name:'',
                            Account_number:'',
                            Ifsc_code:'',
                            Bank_name:'',
                            Branch_name:'',
                            terms_and_condition:false
                        })
                        load()
                        alert("Account details updated")
                    }
                }).catch((err)=>{
                    alert("Something went wrong")
                    setLoading(false)
                })
            }
            
        }else{
            alert("Agree terms and condition")
            setLoading(false)
        }


    }
    


  return (
    <div className="container rounded bg-white pt-5 pb-5">
        <form onSubmit={(e) => submit(e)}
                novalidate
                className={Submit2 ? 'was-validated' : ''}
            >
        <h4>Bank details</h4>
               <div className="row mt-2">
                                <div className="col-md-12"><label className="labels">Bank account holder name *</label><input type="text" required className="form-control" maxLength='150' value={value.Account_holder_name} onChange={(e) => setValue({ ...value, Account_holder_name: e.target.value })} />
                                    <div class="invalid-feedback">
                                        PLEASE ENTER Bank account holder name
                                    </div>
                                </div>
                                <div className="col-md-12"><label className="labels">Account number *</label><input type="number" required className="form-control" maxLength='100' value={value.Account_number} onChange={(e) => setValue({ ...value, Account_number: e.target.value })} />
                                    <div class="invalid-feedback">
                                        PLEASE ENTER Account number
                                    </div>
                                </div>
                                
                                <div className="col-md-12"><label className="labels">IFSC code *</label><input type="text" required className="form-control" maxLength='40' value={value.Ifsc_code} onChange={(e) => setValue({ ...value, Ifsc_code: e.target.value })} />
                                    <div class="invalid-feedback">
                                        PLEASE ENTER IFSC code
                                    </div>
                                </div>
                                <div className="col-md-12"><label className="labels">Bank name *</label><input type="text" required className="form-control" maxLength='40' value={value.Bank_name} onChange={(e) => setValue({ ...value, Bank_name: e.target.value })} />
                                    <div class="invalid-feedback">
                                        PLEASE ENTER Bank name
                                    </div>
                                </div>
                                <div className="col-md-12"><label className="labels">Branch name *</label><input type="text" required className="form-control" maxLength='40' value={value.Branch_name} onChange={(e) => setValue({ ...value, Branch_name: e.target.value })} />
                                    <div class="invalid-feedback">
                                        PLEASE ENTER Branch name
                                    </div>
                                </div>

                                {
                                    !Edit?
                                    <div className="col-12">
                                    <div className="form-check">
                                        <input 
                                        onChange={(e) =>
                                            setValue({ ...value, terms_and_condition: !value.terms_and_condition })
                                        } 
                                        defaultChecked={value.terms_and_condition?true:false}
                                        className="form-check-input" type="checkbox" id="Enterprise" />
                                        <label className="labels" for="Enterprise">
                                        I agree that the bank account details I have mentioned is correct and wish to have my commission credited to this account. Any mistake / error in this bank account details is not the responsibility of the platform.
                                        </label>
                                    </div>
                                </div>
                                    :<label>I agree that the bank account details I have mentioned is correct and wish to have my commission credited to this account. Any mistake / error in this bank account details is not the responsibility of the platform.</label>
                                }

                            </div>

                            <div className="mt-2 text-center">

                    {!loading &&
                        <button className="btn mb-2 mt-4" style={{ 'backgroundColor': '#55374a', 'color': 'white' }}
                            onClick={() => setSubmit2(true)}
                            disabled={value.terms_and_condition?false:true}
                        >
                            {Edit ? 'Update' : 'Add bank details'}
                        </button>
                    }
                    {loading &&
                        <button className="btn  mb-2 mt-4" disabled style={{ 'backgroundColor': '#55374a', 'color': 'white' }}>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

                        </button>
                    }
                </div>

                </form>
    </div>
  )
}

export default Account_details_executive