import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './Coupon.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

export function Coupon() {
  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [Error, setError] = useState('');
  const [first, setfirst] = useState({
    name: '',
    amount: '',
    validity: '',
    utype: 'ALL',
    ctype: 'GENERAL',
    extendsvalidity: 1,
    pin: '',
    showPin: false,
  });
  const [Loading, setLoading] = useState(false);
  const submit = () => {
    if (first.name.length < 6) {
      return setError('Name must be 6 letters !');
    } else {
      setError('');
    }
    if (first.validity.length == 0) {
      return setError('Select validity !');
    }
    setError('');
    setLoading(true);
    axios
      .post('/api/admin/coupon', {
        first,
      })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          notify('Coupon created successfully', true);
          setfirst({
            name: '',
            amount: '',
            validity: '',
            utype: 'ALL',
            ctype: 'GENERAL',
            extendsvalidity: 1,
            pin: '',
            showPin: false,
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status == 401) {
          notify('Wrong pin', false);
        } else {
          notify('Something went wrong', false);
        }

        setLoading(false);
      });
  };
  const [Calc, setCalc] = useState({
    total: 0,
    newval: 0,
  });
  const [Result, setResult] = useState();

  useEffect(() => {
    console.log('first');

    if (Calc.total && Calc.newval) {
      let result = ((Calc.total - Calc.newval) / Calc.total) * 100;
      setResult(result);
    }
    return () => {};
  }, [Calc]);

  const [Visi, setVisi] = useState(false);

  useEffect(() => {
    console.log(Visi);

    return () => {};
  }, [Visi]);

  const handleClickShowPassword1 = () => {
    setfirst({
      ...first,
      showPin: !first.showPin,
    });
  };

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  return (
    <div className="p-2 border bg-light">
      <div className="bg-secondary text-light text-center p-3">
        {' '}
        <h1 className="text-uppercase ">Create Coupon</h1>
      </div>
      <div className="container">
        <div className="row gy-3 p-4">
          <div className="col-2"></div>
          <div className="col-3">Coupon Name</div>
          <div className="col-6">
            <input
              type="text"
              className="form-control"
              value={first.name}
              onChange={(e) =>
                setfirst({ ...first, name: e.target.value.toUpperCase() })
              }
            ></input>
          </div>
          <div className="col-1"></div>
          <div className="col-2"></div>
          <div className="col-3">Discount</div>
          <div className="col-6">
            <input
              type="number"
              placeholder="In percentage (max 2 digit) !"
              className="form-control"
              value={first.amount}
              onChange={(e) => setfirst({ ...first, amount: e.target.value })}
            ></input>
          </div>
          <div className="col-1"></div>
          <div className="col-2"></div>
          <div className="col-3">Coupon Validity</div>
          <div className="col-6">
            <input
              type="date"
              className="form-control"
              value={first.validity}
              onChange={(e) => setfirst({ ...first, validity: e.target.value })}
            ></input>
          </div>
          <div className="col-1"></div>
          <div className="col-2"></div>
          <div className="col-3">Coupon Type</div>
          <div className="col-6">
            <select
              className="form-control"
              id="usertype"
              value={first.ctype}
              onChange={(e) => setfirst({ ...first, ctype: e.target.value })}
            >
              <option selected value="GENERAL">
                GENERAL
              </option>
              <option value="ADVERTISEMENT">ADVERTISEMENT</option>
            </select>
          </div>
          <div className="col-1"></div>
          <div className="col-2"></div>
          <div className="col-3">User Type</div>
          <div className="col-6">
            <select
              className="form-control"
              id="usertype"
              value={first.utype}
              onChange={(e) => setfirst({ ...first, utype: e.target.value })}
            >
              <option value="ALL">ALL</option>
              <option value="ACTIVE">PRIME</option>
              <option value="ACTIVEV2">ELITE</option>
              <option value="ACTIVEV3">BASIC</option>
              <option value="PASSIVE">PASSIVE</option>
            </select>
          </div>
          {/* <div className='col-1'></div>
                    <div className='col-2'></div>
                    <div className='col-3'>
                        Number of extended year
                    </div>
                    <div className='col-6'>
                        <input type="Number" className='form-control' value={first.extendsvalidity} onChange={(e) => setfirst({ ...first, extendsvalidity: e.target.value })}></input>
                    </div> */}
          <div className="col-1"></div>
          <div className="col-2"></div>
          <div className="col-3"></div>
          <div className="col-6">
            <FormControl variant="outlined" className="form-control mt-2">
              {/* sx={{ m: 1, width: '25ch' }} */}
              <InputLabel htmlFor="outlined-adornment-password">Pin</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={first.showPin ? 'text' : 'password'}
                value={first.pin}
                onChange={(e) => {
                  setfirst({ ...first, pin: e.target.value });
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword1}
                      onMouseDown={handleMouseDownPassword1}
                      edge="end"
                    >
                      {first.showPin ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="pin"
              />
            </FormControl>
          </div>
          <div className="col-1"></div>
          <div className="col-2"></div>
          <div className="col-3"></div>
          <div className="col-6">
            <p className="text-danger">{Error}</p>
            {Loading ? (
              <button className="btn btn-success" type="button" disabled>
                Create
              </button>
            ) : (
              <button
                className="btn btn-success"
                type="button"
                onClick={(e) => submit(e)}
              >
                Create
              </button>
            )}
          </div>
          <div className="col-1"></div>
        </div>
      </div>

      <div onClick={(e) => setVisi(!Visi)} className="">
        &nbsp;
        <div className={`${!Visi ? 'd-none' : ''}`} style={{ width: '200px' }}>
          <input
            placeholder="Total Amount"
            value={Calc.total}
            onChange={(e) =>
              setCalc((prev) => ({ ...prev, total: e.target.value }))
            }
            className="form-control"
          ></input>
          <input
            placeholder="New Amount"
            value={Calc.newval}
            onChange={(e) =>
              setCalc((prev) => ({ ...prev, newval: e.target.value }))
            }
            className="form-control"
          ></input>
          <span>{Result}%</span>
        </div>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
