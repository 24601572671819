import React, { useState, useEffect, useRef, useReducer, createContext, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import './CreateProduct.css'
import "cropperjs/dist/cropper.css";
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { FormProduct } from './FormProduct';
// import { Navoutside } from '../Navoutside/Navoutside';


export function CreateProduct() {
    let navigate = useNavigate()
    const submit = async (formData) => {
        return new Promise((resolve,reject)=>{
        axios.post('/api/accounts/register/registerproduct', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then(res => {
            if (res.status == 200) {
                return navigate('/c_v_registration')
            }
            resolve(true)
        }).catch((err) => {
             resolve(false)

        })

    })
    }

    return (
        <>
        {/* <Navoutside /> */}
        <div className="container rounded bg-white pt-5 pb-5">
                <FormProduct postTo={submit} sab={false}/>
        </div>
        </>


    )
}
