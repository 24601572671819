
import React,{useState,useEffect} from 'react'
import axios from 'axios'
import { Footer } from '../../../Pages/HomePage/Footer'
export default function Sub_admin_my_earnings(props) {
    const [Data, setData] = useState("")
    const [FullAmount, setFullAmount] = useState(0)
    const load=()=>{
    axios.get('/api/Admin/Sub_admin_my_earnings').then((res)=>{
        if (res.data) {
          console.log(res.data) 
           var full=res.data.paid&&res.data.pending?res.data.paid+res.data.pending:0
           console.log()
           full=full.toFixed(2)
           setFullAmount(full)
          setData(res.data)
        }
    })
    }
    useEffect(() => {
        load()
        props.Auth1()
    }, [])
    
  return (
    <div className=''>
    <div className="container mt-5  d-flex justify-content-center">
        <div className="card mt-5" style={{width:"45rem",padding:"10px",boxShadow:"5px 10px 18px grey",borderRadius:"20px"}}>
          <div className="card-body" >
            <h5 className="card-title text-center">My Earnings</h5>
    <div className="row d-flex justify-content-center">
    <div className="col-6">
            <div className="mb-3">
              <b htmlFor="Paid Amount" className="form-label">TOTAL EARNED :</b>
            </div>
            </div>
      <div className="col-6">

              <div><b> {FullAmount?FullAmount:"0"} /- </b></div>
              </div>
      <div className="col-6">
            <div className="mb-3">
              <b style={{color:"green"}} htmlFor="Paid Amount" className="form-label">SETTLED :</b>
            </div>
            </div>
      <div className="col-6">

              <div><b style={{color:"green"}}> {Data.paid?Data.paid:"0"} /-</b> </div>
              </div>
      <div className="col-6">

            <div className="mb-3">
              <b style={{color:"red"}} htmlFor="Pending Amount" className="form-label">PENDING :</b>
              </div>
              </div>
      <div className="col-6">

              <div><b style={{color:"red"}}>{Data.pending?Data.pending:"0"} /-</b></div>
            </div>
          </div>
        </div>
      </div>
  
  </div>

  <div className='' style={{position:"absolute",paddingTop:"150px",width:"100%" }} >
    <Footer/>
  </div>
  </div>
  )
}
