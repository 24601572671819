import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from 'axios'
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const notify = (message, sab) => {
  if (sab) {
    toast.success(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.warning(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const Information_table_edit = ({data,Get}) => {

  const [show, setShow] = useState(false);
const [id, setId] = useState("")
const handleClose = () => setShow(false);
const handleShow = (id) => {setShow(true)
  setId(id)
};
  var columns = [
    {
      field: 'id',
      headerName: 'No. ',
      width: 60,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'country',
      headerName: 'Country',
      width: 150,
    },
    {
      field: 'state',
      headerName: 'Country',
      width: 150,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 150,
    },
    {
      field: 'price',
      headerName: 'Price',
      width: 100,
    },
    {
      field: 'unit',
      headerName: 'Unit',
      width: 50,
    },
    {
      field: 'duration',
      headerName: 'Duration',
      width: 250,
    },

    {
      field: 'Edit',
      headerName: 'Edit',
      width: 150,
      renderCell: (params) => {
        return (
          <Link to={`/information_forms_edit?k=${params.row._id}`}>
            <button className="btn pro-spaces-button3">Edit</button>
          </Link>
        );
      },
    },

    {
      field: 'Delete',
      headerName: 'Delete',
      width: 150,
      renderCell: (params) => {
        return (
            <button className="btn btn-danger" onClick={(e)=>handleShow(params.row._id)} >Delete</button>
        );
      },
    },
  ];
const Delete=()=>{
  Axios.post('/api/User/Information_delete',{id}).then((res)=>{
    if (res.status==200) {
      notify("Deleted successfully",true)
      Get()
    }
  }).catch((err)=>{
    notify("Something went wrong",false)

  })  
  
}


  return (
    <div>   
      {data && (
      <Box sx={{ height: 800, width: '100%' }}>
        <DataGrid
          rows={data}
          columns={columns}
          // Other DataGrid props...
        />
      </Box>
    )}

<Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Are you sure ?</h3>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-danger' onClick={(e)=>Delete()}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    
    
    </div>
  )
}
