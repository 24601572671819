import axios from 'axios';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const Viewnotificationsmeame = ({ log_id, Acc_id }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  var Loginid = searchParams.get('k');
  var Accountid = searchParams.get('j');

  console.log(log_id);

  const [first, setFirst] = useState([]);
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  useEffect(() => {
    axios
      .post('/api/Admin/viewsendmail_ame_sme', { Loginid, Accountid })
      .then((res) => {
        console.log(res.data);
        setFirst(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  if (first.length) {
    return (
      <div className="card">
        {first.map((elem, index) => {
          console.log(elem);
          return (
            <>
              <>
                <div className=" container ">
                  <div className="pt-3">
                    <div className="" style={{ fontSize: '20px' }}>
                      Date : {moment(elem.created_on).format('DD-MM-YYYY')}
                    </div>
                    <div className="" style={{ fontSize: '20px' }}>
                      Mail subject : {elem.Subject}
                    </div>
                    <div className="" style={{ fontSize: '20px' }}>
                      Message : {elem.Message}
                    </div>
                    <hr />
                  </div>
                </div>
              </>
            </>
          );
        })}
      </div>
    );
  } else {
    return (
      <div
        className="d-flex justify-content-center align-items-center pt-5"
        style={{ fontSize: '30px' }}
      >
        Nothing to Show{' '}
      </div>
    );
  }
};
