import React from 'react';
import { Link } from 'react-router-dom';

export const AdminselectionZM = () => {
  return (
    <div>
      <div>
        <div className="container ">
          <div className="row slhishieo3jfiehfjefbewf">
            <>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <Link to="/zonalmanager_registration" className="wgfiuwrgiu">
                  <div className="card carrrrbus">
                    <div className="padunnor">
                      <center>
                        Registration
                        <br></br> {/* {Counts.Active ? Counts.Active : '0'} */}
                      </center>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <Link to="/zonalmanager_memberview" className="wgfiuwrgiu">
                  <div className="card carrrrbus">
                    <div className="padunnor">
                      <center>
                        Members View
                        <br></br>{' '}
                        {/* {Counts.Activev2 ? Counts.Activev2 : '0'} */}
                      </center>
                    </div>
                  </div>
                </Link>
              </div>
            </>
            {/* :""}
             */}
          </div>
        </div>
      </div>
    </div>
  );
};
