import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Axios from "axios";
import Moment from 'moment';
import { width } from '@mui/system';
import { Link } from 'react-router-dom'

export const Offline_profile_Creation_list = ()=> {
    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'Name', headerName: 'Profile Name', width:300 },
        { field: 'MainCat', headerName: 'Category', width:150 },
        { field: 'subCat', headerName: ' Sub Category', width:150 },
        { field: 'State', headerName: 'State' , width:150},
        { field: 'City', headerName: 'City' , width:150},
        { field: 'Contact', headerName: 'Contact' , width:150},
        { field: 'Email', headerName: 'Email' , width:300},    
        { field: 'Date', headerName:'Validity' },
        { field: 'Invoice',
         renderCell:(values)=>{
            return( 
                <Link to={`/offline_account_print_invoice?pi=${values.row.login[0]?._id}`}>
                 <button className="btn pro-spaces-button3"  >
                 View
                 </button>
                </Link>
            )
         }
    },

    ]
    
    const [rows, setDatas] = useState([])
    useEffect(() => {
        Axios.get('/api/SuperAdmin/Offline_Created_profile').then(res => {
            if (res.data) {
                console.log(res.data)
                setDatas(res.data.map((elem, index) => { return { id: index + 1,...elem,
                    Name:elem.login[0]?.pname?elem.login[0]?.pname:elem.login[0]?.firstname?elem.login[0]?.firstname:"-",
                    State:elem.login[0]?.state,
                    City:elem.login[0]?.city,
                    Contact:elem.Contact?elem.Contact : elem.login[0]?.contact?elem.login[0]?.contact:"-",
                    Email:elem.Email?elem.Email : elem.login[0]?.email?elem.login[0]?.email:"-",
                    subCat:elem.login[0]?.subcategory1[0]?.sub,MainCat:elem.login[0]?.category1[0]?.category,
                    Date:Moment(elem.login[0]?.Validity).format('DD-MM-YYYY'), } }))
            }
        }).catch((err) => {
            console.log(err)
        })
    }, [])
  return (
    <div>
    <div className='bg-light container' style={{ height: 900, width: '100%' }}>
        <DataGrid
            rows={rows}
            columns={columns}
            pageSize={15}


        />
    </div>
</div>
  )
}
