import { createContext, useEffect, useContext, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Carousel, CarouselItem } from "react-bootstrap"
import { useSearchParams } from "react-router-dom";
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { keyGen } from '../../../../Utility/helper'
import { AiOutlineSetting } from "react-icons/ai";
import { Link } from "react-router-dom"
import '../movie.css'
import Moment from 'moment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export let moviesContext = createContext()
export const MovieGridView = () => {
    let [searchParams, setSearchParams] = useSearchParams()
    const [Error, setError] = useState()
    const [Movies, setMovies] = useState([])
    const [Loading, setLoading] = useState(true)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    var language = searchParams.get("language");
    console.log(language)
    const notify = (message, sab) => {
        if (sab) {
            toast.success(message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            toast.warning(message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const FetchMovies = async () => {
        try {

            if (language.length === 0) {
                return setError('Invalid Page')
            }
            let movies = await axios.post('/api/User/getMoviesByLanguage', { language: language })
            setLoading(false)
            if (movies.status != 200) {
                return console.log('something went wrong')
            }
            setMovies(movies.data)
            console.log(movies)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }
    useEffect(() => {
        FetchMovies()
        return () => {

        }
    }, [])
    const [currentIndex, setcurrentIndex] = useState(undefined)
    useEffect(() => {
        console.log(currentIndex)
        console.log(Movies[currentIndex])
        return () => {

        }
    }, [currentIndex])

    const Delete = (e, id, ind) => {
        var id = id
        axios.post("/api/User/MoviesDelete", { id }).then((response) => {
            // console.log('success')
            if (response.status === 200) {
                //   console.log("reported11")
                // setreported(true)
                // window.location.reload();
                let temp = [...Movies]
                temp.splice(ind, 1)
                setcurrentIndex(undefined)
                setMovies(temp)
                setShow(false)
                notify("Post deleted successfully",true);

            }
        }).catch(
            res => {
                console.log('Something went wrong')
                notify("Post deleted unsuccessfully",false);

            }
        )
    }
    const [DeleteStatus, setDeleteStatus] = useState(false)
    useEffect(() => {
        if (!show) {
            setTimeout(() => {
                setDeleteStatus(false)


            }, 150);

        }

    }, [show])



 

    return (
        <div className='TopTuck' style={{'marginTop':'80px'}}>
            <moviesContext.Provider value={{ setcurrentIndex, handleShow }}>
                <h1>{Error}</h1>
                <div className='container-fluid'>
                    <div className=' row  '>
                        {Movies?.length > 0 ? Movies.map((elem, index) => {
                            return <SingleTile key={keyGen()} value={elem} index={index} style={{ display: 'inline-block' }} />

                        }) : <></>}
                    </div>
                </div>
            </moviesContext.Provider>

            {/* <Button variant="primary" onClick={handleShow}>
                Launch demo modal
            </Button> */}

            <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" style={{'zIndex':'1000000'}}
                centered scrollable>
                <Modal.Header closeButton>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    {
                        !DeleteStatus ?
                            <>
                                {currentIndex >= 0 &&
                                    <SliderMovieLite Files={Movies[currentIndex].Files} />
                                }
                                <div>
                                    <h3><center>{Movies[currentIndex]?.HeadLine}</center></h3>
                                    {/* <h2>{Movies[currentIndex]?._id}</h2> */}
                                    {/* {localStorage.getItem("Type")==="sponsored" ? */}

                                      
                                    <div className='d-flex justify-content-end' >
                                            <a type="button" href="/" className="dotoptionbtn   cell" data-bs-toggle="dropdown" aria-expanded="false">
                                                <AiOutlineSetting size={35}></AiOutlineSetting>
                                            </a>
                                            <ul className="dropdown-menu ">
                                                <li>
                                                    <Link to={"/update_movie?pi=" + Movies[currentIndex]?._id} className="dropdown-item">Edit post</Link>
                                                </li>
                                                <li >
                                                    <a className="dropdown-item" onClick={(e) => { setDeleteStatus(true) }}> Delete</a>
                                                </li>
                                            </ul>

                                        </div>
                                    
                                    {/* :""}  */}
                                </div>
                                <br></br>
                                {  
                                           
                                           Movies[currentIndex]?.Genre?
                                           Movies[currentIndex].Genre.length!=0?
                                           <div className='row'>
                                            <div className='col-6'>
                                            Genre
                                             </div> 
                                             <div className='col-1'>
                                               :
                                             </div>
                                             <div className='col-5'>
                                             {Movies[currentIndex].Genre.map((e,index)=>{
                                                  if(Movies[currentIndex].Genre.length!==index+1)
                                                  {

                                                         return<div>{e+","+" "}</div>
                                                          
                                                  }
                                                  else{
                                                   return e
                                                  }
                                             })  }
                                             </div>
         
                                         </div>

                                         :""
                                         :""
                                         }
                                <br></br>
                                {  
                             Movies[currentIndex]
                             ?.Status? <div className='row'>
                                   <div className='col-6'>
                                   Status
                                    </div> 
                                    <div className='col-1'>
                                      :
                                    </div>
                                    <div className='col-5'>
                                    {Movies[currentIndex]?.Status}
                                    </div>

                                </div>
                                :""
                                }
                                <br></br>

                                <div>
                                    <p>{Movies[currentIndex]?.Description}</p>
                                </div>
                                
                             {  
                             Movies[currentIndex]
                             ?.Actors? <div className='row'>
                                   <div className='col-6'>
                                   Actors&nbsp;/&nbsp;Actresses
                                    </div> 
                                    <div className='col-1'>
                                      :
                                    </div>
                                    <div className='col-5'>
                                    {Movies[currentIndex]?.Actors}
                                    </div>

                                </div>
                                :""
                                }
                                   {  
                             Movies[currentIndex]?.Director? <div className='row'>
                                   <div className='col-6'>
                                   Director
                                    </div> 
                                    <div className='col-1'>
                                      :
                                    </div>
                                    <div className='col-5'>
                                    {Movies[currentIndex]?.Director}
                                    </div>

                                </div>
                                :""
                                }

                              {  
                             Movies[currentIndex]?.NumberOfSeries? <div className='row'>
                                   <div className='col-6'>
                                   Number of Sessions
                                    </div> 
                                    <div className='col-1'>
                                      :
                                    </div>
                                    <div className='col-5'>
                                    {Movies[currentIndex]?.NumberOfSeries}
                                    </div>

                                </div>
                                :""
                                }
                                      {  
                             Movies[currentIndex]?.TotalNumberOfEpisodes? <div className='row'>
                                   <div className='col-6'>
                                   Total Number Of Episodes
                                    </div> 
                                    <div className='col-1'>
                                      :
                                    </div>
                                    <div className='col-5'>
                                    {Movies[currentIndex]?.TotalNumberOfEpisodes}
                                    </div>

                                </div>
                                :""
                                }
                                          {  
                                            
                                            Movies[currentIndex]?.TheaterRelease? <div className='row'>
                                             <div className='col-6'>
                                             Theater Release Date 
                                              </div> 
                                              <div className='col-1'>
                                                :
                                              </div>
                                              <div className='col-5'>
                                              { Moment(Movies[currentIndex]?.TheaterRelease).format('DD-MM-YYYY') }
                                              </div>
          
                                          </div>
                                          :""
                                          }
                                           {  
                                            
                                  Movies[currentIndex]?.OTTRelease? 
                              
                                  <div className='row'>
                                   <div className='col-6'>
                                   OTT Release Date 
                                    </div> 
                                    <div className='col-1'>
                                      :
                                    </div>
                                    <div className='col-5'>
                                    { Moment(Movies[currentIndex]?.OTTRelease).format('DD-MM-YYYY') }
                                    </div>

                                </div>
                                :""
                           
                                }

                                         {  
                                           
                                            Movies[currentIndex]?.OTTPlatform? 
                                            Movies[currentIndex].OTTPlatform.length!=0?
                                            <div className='row'>
                                             <div className='col-6'>
                                             OTT Platform's
                                              </div> 
                                              <div className='col-1'>
                                                :
                                              </div>
                                              <div className='col-5'>
                                              {Movies[currentIndex].OTTPlatform.map((e,index)=>{
                                                   if(Movies[currentIndex].OTTPlatform.length!==index+1)
                                                   {

                                                          return<div>{e+","+" "}</div>
                                                           
                                                   }
                                                   else{
                                                    return e
                                                   }
                                              })  }
                                              </div>
          
                                          </div>
                                          :""
                                          :""
                                          }
                            </>


                            :
                            <>
                                <h3>Are you sure?</h3>
                                <div className='d-flex justify-content-end'>
                                    <button className='btn pro-spaces-button' onClick={(e) => { setDeleteStatus(false) }}>No</button>
                                    &nbsp;&nbsp;&nbsp;
                                    <button className='btn pro-spaces-button3' onClick={(e) => { Delete(e, Movies[currentIndex]._id, currentIndex) }} >Yes</button>&nbsp;

                                </div>
                            </>

                    }

                    {/* <SingleTile key={keyGen()} value={Movies[currentIndex]} index={currentIndex} style={{ display: 'inline-block' }} /> */}



                </Modal.Body>

            </Modal>

            
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        </div >
    )
}

export const SingleTile = (props) => {

    let MovieContext = useContext(moviesContext)
    const Open = () => {
        MovieContext.setcurrentIndex(props.index)
        MovieContext.handleShow()
    }
    console.log(props)

    return (<div className='col-12 col-md-4 col-lg-3 m-0'>
        <Card  className='' onClick={(e) => Open()} sx={{width:'100%',  display: 'inline-block', margin: '2px' }}>
            <CardActionArea>
                <SliderMovieLite Files={props.value.Files} />
                <CardContent>
                    <Typography className='moviesinglelineheadline' gutterBottom variant="h5" component="div">
                        <center>{props.value.HeadLine}</center>
                    </Typography>
                    <Typography className='moviereadmore' variant="body2" color="text.secondary">
                        {props.value.Description}
                        <b className='invisible'>Lorem ipsum dolor sit amet consectetur </b>
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
        </div>
    );
}

export const SliderMovieLite = (props) => {
    console.log(props)
    let imgs = {
        position: 'relative',
        float: 'left',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: 'inherit'
    }

    if (props.Files.length > 0) {


        return <>
            <Carousel interval={null} autop slid className="specscarosel23876487236">
                {props.Files.length > 0 && props.Files.map((elem, index) => {
                    console.log(elem)
                    return <CarouselItem key={keyGen()} >
                        <div className="bg-dark text-light" style={{ height: '450px' }}>
                            <img style={imgs} src={elem.link}></img>
                        </div>
                    </CarouselItem>
                })
                }


            </Carousel></>
    } else {
        return <></>
    }
}