import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Axios from 'axios';
import { SpinnerBig } from '../../../Utility/spinner';
import Moment from 'moment';
import { Link } from 'react-router-dom';

export default function Online_basic() {
  const [List, setList] = useState();
  const [SpinnerStatus, setSpinnerStatus] = useState(true);
  useEffect(() => {
    Axios.get('/api/SuperAdmin/Online_Basic_member').then((res) => {
      if (res.data) {
        console.log(res.data);
        setList(res.data);
        setSpinnerStatus(false);
      } else {
        setList(null);
        setSpinnerStatus(false);
      }
    });
  }, []);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className=" p-2 border">
      <center>
        <h3>Elite&nbsp;Members</h3>
      </center>
      {SpinnerStatus ? <SpinnerBig /> : ''}

      {List ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* <TableCell>Account Type</TableCell> */}
                <TableCell>Profile Name</TableCell>
                <TableCell>State</TableCell>
                <TableCell>City</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Created On</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {List.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {/* <TableCell component="th" scope="row">
                  {row.Type==="Active"?"Prime":row.Type==="Activev2"?"Basic":"Passive"}
                </TableCell> */}
                  <TableCell>
                    {row.Type ? (
                      row.Type === 'Consultant' ||
                      row.Type === 'Consultantv2' ? (
                        <Link
                          style={{ textDecoration: 'none', color: 'black' }}
                          to={`/consultant_profile?Ac_id=${row._id}`}
                        >
                          {row.pname ? row.pname : row.firstname}
                        </Link>
                      ) : (
                        <Link
                          style={{ textDecoration: 'none', color: 'black' }}
                          to={`/vendor_profile?Ac_id=${row._id}`}
                        >
                          {row.pname ? row.pname : row.firstname}
                        </Link>
                      )
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell>{row?.state ? row.state : '-'}</TableCell>
                  <TableCell>{row?.city ? row.city : '-'}</TableCell>
                  <TableCell>{row.email ? row.email : '-'}</TableCell>
                  <TableCell>{row.contact ? row.contact : '-'}</TableCell>
                  <TableCell>
                    {Moment(row.Created_on).format('DD-MM-YYYY')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={List.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      ) : (
        <center>
          <h1>No Members to show</h1>
        </center>
      )}
    </div>
  );
}
