import React, { useState, useEffect, useRef } from "react";
import './post.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import axios from "axios";

const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

    return [htmlElRef, setFocus]
}

export function AdminPostApproved(props) {


    function keyGen() {
        var length = 5
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    const [loader, setloader] = useState(<div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
    </div>)
    const [Loading, setLoading] = useState(true)

    // useEffect(() => {
    //     axios.post('/Admin/AdminPostView')
    //         .then(res => {
    //             console.log("test!")
    //             if (res.status === 200) {
    //                 console.log('Admin View')
    //                 console.log(res.data)
    //                 // console.log(Id)
    //                 setLoading(false)
    //             }
    //         }).catch((err) => {
    //             console.log(err.response)
    //             alert("sonthing went wrong");

    //         })
    // }, [])

    function approve(id) {
        console.log(props)
        // console.log("fuck+" + id)
        axios.post('/api/Admin/AdminPostApproval', { id }).then((res) => {
            console.log(res)
            if (res.status == 200) {
                console.log("Post Approved")
                props.loadme()

            }
        }).catch((err) => {
            console.log(err.response.data)
        })
        // console.log('first')
    }
    function Reject() {
        console.log(props)
     
    
        var data = {

            id,
            reason,
            Accid
        }
        console.log(data)
        // return
        axios.post('/api/Admin/AdminPostReject', { data }).then((res) => {
            console.log(res)
            if (res.status == 200) {
                console.log("Post Rejected")
                document.getElementById('fkit').click()
                props.loadme()

            }
        }).catch((err) => {
            console.log(err.response.data)
        })
        // console.log('first')
    }
    const [id, setid] = useState({
        id:''
    })


    function getID(id,Accid){
        setid(id)
        setAccid(Accid)
        // console.log("acc+"+Accid)

    }


    const [Accid,setAccid]=useState({
        Accid:''
    })






    const [reason, setreson] = useState({
         reson: ''
    })
    

    return (

        // #733380
        <div className="container-fluid p-0">

            {
               props.dat.Report_count>4?
              
                <div className=" post mb-2 ">
                <div className="postHeader p-1">
                    <div className="headerinfo tab mx-0 ">

                        <div className=" text-start pThumbcontainer  cell px-0 cot ">

                            {/* post author profile picture starts */}
                            <img alt="" className="thumbnailpost " src={"/profile/" + props.dat.merge[0]._id + "/avatar.png"} />
                            {/* post author profile picture ends */}

                        </div>
                        <div id="postinfo" className="postinfo px-0 ms-1 cell">

                            {/* post author name  starts*/}
                            <h6 id="authorName" className=" pt-1 m-0">{props.dat.merge[0].firstname} {props.dat.merge[0].lastname}</h6>
                            {/* post author name  ends*/}

                            <p>{props.dat.Posted_On.slice(0, 10)}<i className="fa fa-globe" aria-hidden="true"></i></p>
                        </div>

                        {/* post 3dot option starts*/}
                        {/* <div className="cell dropstart float-end pt-2 mt-1">
                            <a type="button" href="/" className="dotoptionbtn   cell" data-bs-toggle="dropdown" aria-expanded="false">
                                <img alt="" className="threedot " src="4312166.png" />
                            </a>


                            </div> */}
                            {/* <ul className="dropdown-menu "> */}
                            {/* {Follower ?
                                    <li onClick={(e) => unfollow(e, props.dat.Author)}><a className="dropdown-item" ><MdPersonAddAlt1 /> unfollow</a></li>

                                    :
                                    <li onClick={(e) => follow(e, props.dat.Author)}><a className="dropdown-item" ><MdPersonAddAlt1 /> Follow</a></li>

                                } */}
                            {/* <li><a className="dropdown-item" href="/"><BsFillSave2Fill /> Save</a></li>
                                <li><a className="dropdown-item" href="/"><MdLink /> Copy link</a></li>
                                <li><a className="dropdown-item" href="/"><MdFlag /> Report</a></li>
                            </ul > */}


                        {/* post 3dot option ends*/}

                    </div>

                    {/* post body starts */}
                    {props.dat.Content != 'undefined' ? <div>    {props.dat.Content}


                        {/* {props.dat.id} */}

                    </div> : ''}
                    {/* post body ends */}

                    {/* post image / video starts */}
                    <div className="postContent container-fluid m-0">
                        {/* {props.File.map((e)=>{
                           
                            return<p>{e}</p>
                        })} */}
                        <div id={'poda' + props.dat.id} className="carousel slide" data-bs-ride="carousel">

                            <div className="carousel-inner">
                                {props.dat.File.map((element, index) => {

                                    if (index === 0) {
                                        if ('image' === element.filetype.slice(0, 5)) {
                                            return <div key={keyGen()} className="carousel-item active brokendrtff">
                                                <img alt="" className="snblsnlbns" src={element.filename} />

                                                {/* <img src="..." className="d-block w-100" alt="..." /> */}
                                            </div>
                                        } else if ('video' === element.filetype.slice(0, 5)) {
                                            return <div key={keyGen()} className="carousel-item active">
                                                <video className="carItem" controls> <source src={element.filename} />  </video>
                                            </div>
                                        }
                                    } else {
                                        if ('image' === element.filetype.slice(0, 5)) {
                                            return <div key={keyGen()} className="carousel-item brokendrtff">
                                                <img alt="" className="snblsnlbns " src={element.filename} />
                                                {/* <img src="..." className="d-block w-100" alt="..." /> */}
                                            </div>
                                        } else if ('video' === element.filetype.slice(0, 5)) {
                                            //    
                                            return <div key={keyGen()} className="carousel-item ">
                                                <video className="carItem" controls> <source src={element.filename} />  </video>
                                            </div>
                                        }
                                    }
                                })}



                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target={'#poda' + props.dat.id} data-bs-slide="prev" style={{ 'heigth': '50px' }}>
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target={'#poda' + props.dat.id} data-bs-slide="next" style={{ 'heigth': '50px' }}>
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>


                        </div>

                    </div>

                    {/* post image / video ends */}

                </div>
                {/* <button onClick={(e) => approve(props.dat.id)}>Approve</button>  */}

                <button onClick={(e) =>getID(props.dat.id,props.dat.Author)} data-bs-toggle="modal" data-bs-target={"#modeqql"+props.dat.id} className="btn btn-warning" >Send warning</button>

                <div className="modal fade" id={"modeqql"+props.dat.id} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog ">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Reason for Send warning</h5>
        <button type="button" id='fkit' className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <textarea onChange={(e)=>setreson(e.target.value)} style={{
                            height: '200px'
                        }} className="form-control "></textarea>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary" onClick={Reject}>Confirm </button>
      </div>
    </div>
  </div>
</div>
            </div>


                :""
                
                // "No post Available"
            }
           
        </div>
    )

}