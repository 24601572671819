import axios from 'axios';

export const getAllServiceCatagory = async () => {
  const res = await axios.get('/api/api/get_service_catagory');
  return res.data;
};
export const getAllProductCatagory = async () => {
  const res = await axios.get('/api/api/get_product_catagory');
  return res.data;
};
export const getAllSubCatagory = async (catId) => {
  const res = await axios.post('/api/api/get_sub_catagory_by_id', { catId });
  return res.data;
};
export const getAllAdmin = async () => {
  const res = await axios.get('/api/User/adminExecutivelist');
  return res.data;
};
export const getfindUD = async () => {
  const res = await axios.post('/api/Admin/userdatadataadminroute');
  return res.data;
};
export const deteleUsers = async (Login_id) => {
  const res = await axios.delete('/api/User/deteleUsers', {
    data: {
      Login_id,
    },
  });
  return res.data;
};
export const getDceTable = async (filter) => {
  const res = await axios.post('/api/User/dataentryTable', filter);
  return res.data;
};
export const verifyAuth = async (verify) => {
  const res = await axios.post('/api/User/verifyDCEdata', verify);
  return res.data;
};
