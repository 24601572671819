import React,{useState,useEffect} from 'react'
import { Link } from "react-router-dom";
import Axios from 'axios'
import { SpinnerBig } from '../../Utility/spinner';

export const Information_Category_list = () => {
  const [Category, setCategory] = useState([])
  const [Loading, setLoading] = useState(true)
  useEffect(() => {
    
    Axios.get('/api/api/Information_category_only').then((res)=>{
      if (res.status==200) {
        setCategory(res.data)
        setLoading(false)
      }
    }).catch((err)=>{
      setLoading(false)

    })
  }, [])
  
  return (
    <div>

      <div className='container'>
        {
          Loading?
          <SpinnerBig/>
          :
          <>
          <div className='d-flex justify-content-end'>
            <Link to={"/information_forms"}>
            <button className='btn pro-spaces-button3'>Add Information</button>
            </Link>
            
          </div>

 <div className="row ">
           {
             Category?
             Category.map((e)=>{
              return <>
          <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">
            <Link to={`/information_subcategory?k=${e._id}`} className="wgfiuwrgiu">
              <div className="card carrrrbus">
                <div className="padunnor"><center>{e.category}<br>
                </br> </center></div>
              </div>
            </Link>
          </div>
              </>
            })
            :<center><h3>Nothing to show !</h3></center>
          }
        </div>
          </>
         }  
      </div>
    </div>
    
  )
}
