import { Spinner } from "reactstrap";

export const Loading = ({ size = null }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
      size={size}
    >
      <Spinner>Loading...</Spinner>
    </div>
  );
};
