import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/signin.css';
import Axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { CgSpinner } from 'react-icons/cg';
import axios from 'axios';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Timer } from '../../Utility/timer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';
import { Footer } from '../../Pages/HomePage/Footer';
import { NavBar } from '../../Pages/HomePage/NavBar';
import { Navbar2 } from '../../Pages/HomePage/Navbar2';
import { ControlBar } from '../../Pages/HomePage/ControlBar';
const notify = (message, sab) => {
  if (sab) {
    toast.success(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else if (sab == 'red') {
    toast.danger(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.warning(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};
// import {Priceing} from "../Priceing/Priceing"

export const Signin = ({ checkforrupdateamesme }) => {
  let navigate = useNavigate();
  const [Authenticated, setAuthenticated] = useState(false);
  const Auth = () => {
    axios
      .get('/api/verify/authenticateduser')
      .then((res) => {
        if (res.status === 200) {
          console.log('workd');
          setAuthenticated(true);
          navigate('/lobby');
        }
      })
      .catch((err) => {
        console.log(err.response.status);
      });
  };

  useEffect(() => {
    Auth();
  }, []);

  const [Email, setEmail] = useState('');
  const [Password, setPassword] = useState('');
  const [Submit, setSubmit] = useState(false);
  const [Error, setError] = useState('');
  const [ServerError, setServerError] = useState(false);

  const [loading, setloading] = useState(false);

  // let [searchParams, setSearchParams] = useSearchParams();
  // searchParams.get("k")

  /* eslint-disable */
  function Validate() {
    if (Password.length == 0) {
      setError('Enter Password');
      return false;
    } else if (Password.length < 8) {
      // setError('Enter Valid Password')
      // return false
    } else {
      setError('');
    }

    return true;
  }
  const [Locked, setLocked] = useState(false);
  const formhandler = (e) => {
    e.preventDefault();
    var status = false;
    setSubmit(true);
    status = Validate();
    if (status == false) return;

    setloading(true);
    console.log(status);

    var dataSend = {
      Contact: Email,
      Password,
    };

    Axios.post('/api/auth/login', { dataSend })
      .then((res) => {
        // const persons = res.data;
        setloading(false);
        console.log(res);
        console.log('send success');
        if (res.status == '200') {
          console.log('re');
          navigate('/');
          checkforrupdateamesme();
          window.location.reload();
        } else {
          console.log('le');
          return;
        }
      })
      .catch((err) => {
        setError('');
        console.log(err.response.status);
        if (err.response.status == '400' || err.response.status == '401') {
          setError(err.response.data);
          setloading(false);
        } else if (err.response.status == '409') {
          // return navigate('/payment')
          // console.log('payment')
          setError(err.response.data);
          setLocked(true);
          setloading(false);
        } else if (err.response.status == '500') {
          setSError('Something went wrong !');
          setloading(false);
        }
        console.log(err.response.status);
      });
  };
  useEffect(() => {
    if (Submit) {
      // Validate()
    }
  });

  const [first, setfirst] = useState(true);

  //////////////////////////////forgot password///////////////

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [forgotPassword, setforgotPassword] = useState({
    Email: '',
    OTP: '',
    newPassword: '',
    shownNewPassword: false,
    EmailStatus: false,
  });
  const [ForgotError, setForgotError] = useState('');

    const GenerateOTP = () => {
      console.log('hai');
      axios
        .post('/api/Recovery/forgotPasswordSentOtp', {
          Email: forgotPassword.Email,
        })
        .then((res) => {
          if (res.status == 200) {
            setpop(true);
            notify('OTP send successfully', true);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log('close');
          setpop(false);
          notify('Something went wrong', false);
        });
    };

  const checkAccount = () => {
    setForgotError('');
    axios
      .post('/api/Recovery/checkAccount', { Email: forgotPassword.Email })
      .then((res) => {
        if (res.status === 200) {
          GenerateOTP();
          setForgotError('');
          setforgotPassword({ ...forgotPassword, EmailStatus: true });
        } else if (res.status === 201) {
          return setForgotError('User id / Account not existed');
        }
      })
      .catch((err) => {
        console.log(err);
        notify('Something went wrong', false);
      });
  };
  const UpdatePassword = () => {
    axios
      .post('/api/Recovery/UpdateForgotPassword', {
        Email: forgotPassword.Email,
        OTP: forgotPassword.OTP,
        newPassword: forgotPassword.newPassword,
      })
      .then((res) => {
        if (res.status === 200) {
          setForgotError('');
          setforgotPassword({
            Email: '',
            OTP: '',
            newPassword: '',
            shownNewPassword: false,
            EmailStatus: false,
          });
          setforgotPassword({ ...forgotPassword, EmailStatus: false });
          handleClose();
          notify('Password updated successfully', true);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status == 410) {
          notify('Enter valid OTP', false);
        } else {
          notify('Something went wrong', false);
        }
      });
  };

  const [pop, setpop] = useState(true);

  const end = () => setpop(false);

  return (
    <>
      {/* <Priceing /> */}

      <div className="">
        <div id="foter">
          <ControlBar />
          <Navbar2 />
          <div className="d-flex  justify-content-center mt-5 mb-5 pt-3">
            <form
              onSubmit={(e) => formhandler(e)}
              className="card signin px-4 pt-5 pb-2 mt-3 "
              style={{ maxWidth: '400px' }}
            >
              <h2 className="mb-2">SIGN IN</h2>
              {/* <p className="mb-2">Stay updated</p> */}
              <div className="w-100">
                <div className="mt-2 mb-4">
                  <label> User id / Login id </label>
                  <input
                    type="text"
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    id="floatingInput"
                    placeholder="Enter Email Id"
                  />
                </div>
                <div className="mt-2 mb-3">
                  <label>Password </label>
                  <input
                    type={first ? 'password' : 'text'}
                    value={Password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control sbdciuwg"
                    id="floatingPassword"
                    placeholder="Enter Password"
                  />
                  <div className=" sjdhfkshdkjcghu me-2">
                    {first ? (
                      <AiFillEyeInvisible onClick={(e) => setfirst(!first)} />
                    ) : (
                      <AiFillEye onClick={(e) => setfirst(!first)} />
                    )}
                  </div>
                </div>
                {Locked ? (
                  <>
                    <div className="pt-3 pb-3">
                      <>
                        Your AME account is currently LOCKED 🔒.<br></br>
                        <br></br>
                        Contact Admin at vipar.official@gmail.com from your
                        registered email id to UNLOCK 🔓 your AME account to
                        start marketing.<br></br>
                        <br></br>
                        Mention your Referral Code without fail in the email.
                      </>
                    </div>
                  </>
                ) : (
                  ''
                )}
                <div className="text-danger mx-0 mt-1">{Error} </div>
                <div className="text-danger mx-0 mt-1">{ServerError} </div>
                {/* <Link to="/create_affiliated_account" className="aldfhahfoiheaf mt-2"  >Create new account</Link> */}
                <span 
                  className="aldfhahfoiheaf mt-2 pb-2 "
                  onClick={(e) => {
                    handleShow(e);
                  }}
                >
                  forgot password?
                </span>
              </div>
              {!loading && (
                <center>
                  <input
                    id="signinbtn"
                    className="btn mt-2"
                    type="submit"
                    value="Sign in"
                  />
                </center>
              )}
              {loading && (
                <center>
                  <button
                    id="signinbtn"
                    class="btn  mt-2"
                    type="button"
                    disabled
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading...
                  </button>
                </center>
              )}
              <div className="m-3"></div>
            </form>
          </div>
        </div>
        <div className="">
          <Footer />
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Forgot password ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <label>Enter User id / Login id </label>
                <input
                  className="form-control"
                  value={forgotPassword.Email}
                  onChange={(e) => {
                    setforgotPassword({
                      ...forgotPassword,
                      Email: e.target.value,
                    });
                  }}
                ></input>
              </div>
            </div>
            <div>
              {forgotPassword.EmailStatus ? (
                <>
                  <div className="pt-2 ">
                    {pop ? (
                      <div className="mb-2 text-center">
                        <Timer minutes={2} seconds={59} callback={end} />
                      </div>
                    ) : (
                      ''
                    )}

                    {pop ? (
                      <>
                        <button
                          className="btn pro-spaces-outline my-1 "
                          disabled
                        >
                          Resend OTP ?
                        </button>
                        <br />
                      </>
                    ) : (
                      <>
                        <a
                          id="resendOTP"
                          onClick={(e) => GenerateOTP(e)}
                          className="btn pro-spaces-outline my-1 "
                        >
                          Resend OTP ?{' '}
                          {loading && (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                        </a>
                        <br />
                      </>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12 pt-1">
                      <label className="labels"> OTP </label>
                      <input
                        className="form-control"
                        placeholder="Enter OTP"
                        value={forgotPassword.OTP}
                        maxLength={5}
                        onChange={(e) =>
                          setforgotPassword({
                            ...forgotPassword,
                            OTP: e.target.value,
                          })
                        }
                      ></input>
                    </div>
                    <div className="col-md-12">
                      <label className="labels">New Password </label>
                      <div>
                        <input
                          class="form-control passInout12321"
                          placeholder="Enter new Password"
                          aria-describedby="basic-addon2"
                          type={
                            forgotPassword.shownNewPassword
                              ? 'text'
                              : 'password'
                          }
                          value={forgotPassword.newPassword}
                          onChange={(e) =>
                            setforgotPassword({
                              ...forgotPassword,
                              newPassword: e.target.value,
                            })
                          }
                        />

                        <span class="showbutton123321">
                          {forgotPassword.shownNewPassword ? (
                            <RiEyeFill
                              onClick={(e) =>
                                setforgotPassword({
                                  ...forgotPassword,
                                  shownNewPassword:
                                    !forgotPassword.shownNewPassword,
                                })
                              }
                            />
                          ) : (
                            <RiEyeOffFill
                              onClick={(e) =>
                                setforgotPassword({
                                  ...forgotPassword,
                                  shownNewPassword:
                                    !forgotPassword.shownNewPassword,
                                })
                              }
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
            <div></div>

            <div>
              <p style={{ color: 'red' }}>{ForgotError ? ForgotError : ''}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {forgotPassword.EmailStatus ? (
            <button
              className="btn pro-spaces-button3"
              onClick={(e) => {
                UpdatePassword();
              }}
            >
              UPDATE
            </button>
          ) : (
            <button
              className="btn pro-spaces-button3"
              onClick={(e) => checkAccount()}
            >
              FIND ACCOUNT
            </button>
          )}
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
