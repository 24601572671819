import React, { useEffect, useState } from 'react';
import { Imageupload } from '../../ManageAdmin/Imageupload';
import axios from 'axios';
import { StateCity } from '../../ManageAdmin/StateCity';
import { notify } from '../../../Utility/notify';
import { useSearchParams } from 'react-router-dom';
import { AiFillCamera } from 'react-icons/ai';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { SpinnerBig, SpinnerSmall } from 'Utility/spinner';
import Select from 'react-select';

export const ViparFriendinsert = () => {
  const [loading, setLoading] = useState(false);
  const [newb, setnewb] = useState();
  const [newb2, setnewb2] = useState();
  const [checkbtn, setcheckbtn] = useState(false);

  const productOptions = [
    { value: 'ANTIQUE ARTICLES', label: 'ANTIQUE ARTICLES' },
    { value: 'APARTMENTS and VILLAS', label: 'APARTMENTS and VILLAS' },
    { value: 'AQUARIUMS', label: 'AQUARIUMS' },
    { value: 'ARTIFICIAL GRASS', label: 'ARTIFICIAL GRASS' },
    { value: 'BAKERIES', label: 'BAKERIES' },
    { value: 'BOOK and TOY STORES', label: 'BOOK and TOY STORES' },
    {
      value: 'BUILDING CONSTRUCTION MATERIALS',
      label: 'BUILDING CONSTRUCTION MATERIALS',
    },
    { value: 'CAR and BIKE ACCESSORIES', label: 'CAR and BIKE ACCESSORIES' },
    { value: 'CARPETS and RUGS', label: 'CARPETS and RUGS' },
    {
      value: "CHILDRENS' BICYCLES and TRICYCLES",
      label: "CHILDRENS' BICYCLES and TRICYCLES",
    },
    { value: 'COFFINS', label: 'COFFINS' },
    { value: 'COMPUTER HARDWARE', label: 'COMPUTER HARDWARE' },
    {
      value: 'CONFECTIONERIES and SAVOURIES',
      label: 'CONFECTIONERIES and SAVOURIES',
    },
    {
      value: 'CORPORATE GIFTS with CUSTOMISED BRANDING',
      label: 'CORPORATE GIFTS with CUSTOMISED BRANDING',
    },
    {
      value: 'COSMETICS, SKIN and HAIR CARE PRODUCTS',
      label: 'COSMETICS, SKIN and HAIR CARE PRODUCTS',
    },
    {
      value: 'DECORATIVE (indoor and outdoor) PRODUCTS',
      label: 'DECORATIVE (indoor and outdoor) PRODUCTS',
    },
    { value: 'DRAPES and BLINDS', label: 'DRAPES and BLINDS' },
    {
      value: 'DRY FRUITS and NUTS, SPICES and CONDIMENTS',
      label: 'DRY FRUITS and NUTS, SPICES and CONDIMENTS',
    },
    {
      value: 'ELECTRICAL and ELECTRONIC APPLIANCES and ACCESSORIES',
      label: 'ELECTRICAL and ELECTRONIC APPLIANCES and ACCESSORIES',
    },
    { value: 'ELEVATORS and ESCALATORS', label: 'ELEVATORS and ESCALATORS' },
    {
      value: 'FABRICS, GARMENTS,  CLOTHES, FOOTWEAR, WATCHES, etc.',
      label: 'FABRICS, GARMENTS,  CLOTHES, FOOTWEAR, WATCHES, etc.',
    },
    { value: 'FANCY DRESS COSTUMES', label: 'FANCY DRESS COSTUMES' },
    {
      value: 'FARMING and GARDEN EQUIPMENTS and TOOLS',
      label: 'FARMING and GARDEN EQUIPMENTS and TOOLS',
    },
    { value: 'FOUNTAINS', label: 'FOUNTAINS' },
    { value: 'FURNITURE', label: 'FURNITURE' },
    { value: 'GENERAL', label: 'GENERAL' },
    { value: 'GOODS PACKING MATERIALS', label: 'GOODS PACKING MATERIALS' },
    { value: 'GRANDFATHER CLOCKS', label: 'GRANDFATHER CLOCKS' },
    {
      value: 'GREETING CARDS, FANCY / GIFT / DECORATIVE ARTICLES',
      label: 'GREETING CARDS, FANCY / GIFT / DECORATIVE ARTICLES',
    },
    { value: 'GROCERY STORES', label: 'GROCERY STORES' },
    { value: 'GYM EQUIPMENTS', label: 'GYM EQUIPMENTS' },
    { value: 'HARDWARE', label: 'HARDWARE' },

    {
      value: 'HEALTHCARE and WELLNESS PRODUCTS',
      label: 'HEALTHCARE and WELLNESS PRODUCTS',
    },
    { value: 'HEAVY DUTY MACHINES', label: 'HEAVY DUTY MACHINES' },
    {
      value: 'HOUSEHOLD UTILITY PRODUCTS',
      label: 'HOUSEHOLD UTILITY PRODUCTS',
    },
    { value: 'JEWELLERY', label: 'JEWELLERY' },
    {
      value: 'KITCHENWARE, GLASSWARE, BARWARE, CROCKERY, UTENSILS',
      label: 'KITCHENWARE, GLASSWARE, BARWARE, CROCKERY, UTENSILS',
    },
    { value: 'LIGHT DUTY MACHINES', label: 'LIGHT DUTY MACHINES' },
    { value: 'LIGHT FIXTURES', label: 'LIGHT FIXTURES' },
    { value: 'MEAT STORES / SHOPS', label: 'MEAT STORES / SHOPS' },
    {
      value: 'MEDICAL, SURGICAL, HEALTHCARE DEVICES and EQUIPMENTS',
      label: 'MEDICAL, SURGICAL, HEALTHCARE DEVICES and EQUIPMENTS',
    },
    {
      value: 'MOBILITY AID EQUIPMENTS and DEVICES for DISABLED, AGED PERSONS',
      label: 'MOBILITY AID EQUIPMENTS and DEVICES for DISABLED, AGED PERSONS',
    },
    { value: 'MODULAR KITCHENS', label: 'MODULAR KITCHENS' },
    { value: 'MUSICAL INSTRUMENTS', label: 'MUSICAL INSTRUMENTS' },
    {
      value: "NEW BORNS, TODDLERS, CHILDRENS' WEAR and ACCESSORIES",
      label: "NEW BORNS, TODDLERS, CHILDRENS' WEAR and ACCESSORIES",
    },
    { value: 'NEW VEHICLE SHOWROOMS', label: 'NEW VEHICLE SHOWROOMS' },
    { value: 'OPTICAL STORES', label: 'OPTICAL STORES' },
    {
      value: 'PAINTINGS, MURALS, HANDICRAFTS',
      label: 'PAINTINGS, MURALS, HANDICRAFTS',
    },
    { value: 'PET FOOD and PRODUCTS', label: 'PET FOOD and PRODUCTS' },
    {
      value: 'PHARMACIES / MEDICAL SHOPS',
      label: 'PHARMACIES / MEDICAL SHOPS',
    },
    { value: 'PLANT NURSERIES', label: 'PLANT NURSERIES' },
    { value: 'POWER TOOLS and MACHINERY', label: 'POWER TOOLS and MACHINERY' },
    {
      value: 'RAINCOATS, UMBRELLAS, SWEATERS, JACKETS, WOOLEN WEAR',
      label: 'RAINCOATS, UMBRELLAS, SWEATERS, JACKETS, WOOLEN WEAR',
    },
    {
      value: 'SCHOOL BAGS, WATER BOTTLES, TIFFIN BOXES, RAIN COATS, FOOTWEAR',
      label: 'SCHOOL BAGS, WATER BOTTLES, TIFFIN BOXES, RAIN COATS, FOOTWEAR',
    },
    { value: 'SCRAP DEALERS', label: 'SCRAP DEALERS' },
    {
      value: 'SECOND HAND VEHICLE AUTHORISED SHOWROOMS',
      label: 'SECOND HAND VEHICLE AUTHORISED SHOWROOMS',
    },
    {
      value: 'SECURITY SYSTEMS and SAFETY PRODUCTS',
      label: 'SECURITY SYSTEMS and SAFETY PRODUCTS',
    },
    {
      value: 'SHOWER CUBICLES, BATH TUBS, JACUZZIS',
      label: 'SHOWER CUBICLES, BATH TUBS, JACUZZIS',
    },
    {
      value: 'SPORTS BICYCLES (for adults)',
      label: 'SPORTS BICYCLES (for adults)',
    },
    { value: 'SPORTS WEAR and GOODS', label: 'SPORTS WEAR and GOODS' },
    {
      value: 'STATIONERY, ACADEMIC BOOKS and SUPPLIES',
      label: 'STATIONERY, ACADEMIC BOOKS and SUPPLIES',
    },
    {
      value:
        'STONE PILLARS, PERGOLAS, SCULPTURES, SLABS and LANDSCAPING PRODUCTS',
      label:
        'STONE PILLARS, PERGOLAS, SCULPTURES, SLABS and LANDSCAPING PRODUCTS',
    },
    {
      value: 'SUITCASES, BRIEFCASES, TRAVEL BAGS',
      label: 'SUITCASES, BRIEFCASES, TRAVEL BAGS',
    },
    { value: 'SUPERMARKETS', label: 'SUPERMARKETS' },
    { value: 'SWIMMING POOLS', label: 'SWIMMING POOLS' },
    { value: 'VACANT PLOTS / LAND', label: 'VACANT PLOTS / LAND' },
    { value: 'VEHICLE SPARE PARTS', label: 'VEHICLE SPARE PARTS' },
    { value: 'VEHICLE TYRES', label: 'VEHICLE TYRES' },
    { value: 'WALL PAPERS', label: 'WALL PAPERS' },
    {
      value: 'WINE SHOPS / LIQUOR STORES',
      label: 'WINE SHOPS / LIQUOR STORES',
    },
    {
      value: 'WORKSTATIONS and WORK CUBICLES',
      label: 'WORKSTATIONS and WORK CUBICLES',
    },
  ];

  const utilitesOptions = [
    { value: 'ACTIVISTS', label: 'ACTIVISTS' },
    { value: 'ANIMAL RESCUERS', label: 'ANIMAL RESCUERS' },
    { value: 'BLOOD DONORS', label: 'BLOOD DONORS' },
    {
      value: 'COMPANY OWNED / AUTHORISED VEHICLE SERVICE CENTRES',
      label: 'COMPANY OWNED / AUTHORISED VEHICLE SERVICE CENTRES',
    },
    { value: 'FUEL STATIONS', label: 'FUEL STATIONS' },
    { value: 'GAS AGENCIES', label: 'GAS AGENCIES' },
    { value: 'MILK VENDORS', label: 'MILK VENDORS' },
    { value: 'NEWSPAPER AGENCIES', label: 'NEWSPAPER AGENCIES' },
    { value: 'NGOs', label: 'NGOs' },
    { value: 'ORPHANAGES', label: 'ORPHANAGES' },
    {
      value: 'VEHICLE TOWING SERVICES / ROADSIDE ASSISTANCE',
      label: 'VEHICLE TOWING SERVICES / ROADSIDE ASSISTANCE',
    },
    { value: 'WATER SUPPLIERS', label: 'WATER SUPPLIERS' },
    {
      value: 'WI-FI / BROADBAND / DTH PROVIDERS',
      label: 'WI-FI / BROADBAND / DTH PROVIDERS',
    },
  ];
  const serviceOptions = [
    {
      value: '3D RENDERING / VISUALIZATION',
      label: '3D RENDERING / VISUALIZATION',
    },
    {
      value: 'AC INSTALLATION, RE-INSTALLATION',
      label: 'AC INSTALLATION, RE-INSTALLATION',
    },
    { value: 'ACOUSTIC ENGINEERS', label: 'ACOUSTIC ENGINEERS' },
    { value: 'ACUPUNCTURE CENTRES', label: 'ACUPUNCTURE CENTRES' },
    { value: 'AEROBIC STUDIOS', label: 'AEROBIC STUDIOS' },
    {
      value: 'AGRICULTURE EXPERTS / CONSULTANTS',
      label: 'AGRICULTURE EXPERTS / CONSULTANTS',
    },
    {
      value: 'ANIMATORS / ANIMATION STUDIOS',
      label: 'ANIMATORS / ANIMATION STUDIOS',
    },
    {
      value: 'APP DEVELOPERS / COMPANIES',
      label: 'APP DEVELOPERS / COMPANIES',
    },
    { value: 'ARBITRATORS', label: 'ARBITRATORS' },
    { value: 'ARCHITECTS', label: 'ARCHITECTS' },
    { value: 'ART COACHING', label: 'ART COACHING' },
    { value: 'ASTROLOGERS', label: 'ASTROLOGERS' },
    { value: 'AUDITORIUMS / HALLS', label: 'AUDITORIUMS / HALLS' },
    { value: 'AUTOMATION CONSULTANTS', label: 'AUTOMATION CONSULTANTS' },
    { value: 'AUTO-RICKSHAWS', label: 'AUTO-RICKSHAWS' },
    { value: 'AYURVEDA RETREATS', label: 'AYURVEDA RETREATS' },
    { value: 'BABY SITTERS / NANNIES', label: 'BABY SITTERS / NANNIES' },
    { value: 'BAR-attached RESTAURANTS', label: 'BAR-attached RESTAURANTS' },
    { value: 'BEAUTY PARLOURS', label: 'BEAUTY PARLOURS' },
    { value: 'BIOGRAPHERS', label: 'BIOGRAPHERS' },
    {
      value: 'BRIDAL MAKE-UP / MAKE-OVER',
      label: 'BRIDAL MAKE-UP / MAKE-OVER',
    },
    {
      value: 'BRAND BUILDING, PERSONALITY DEVELOPMENT',
      label: 'BRAND BUILDING, PERSONALITY DEVELOPMENT',
    },
    {
      value: 'BUILDING LICENCE SANCTION AGENTS',
      label: 'BUILDING LICENCE SANCTION AGENTS',
    },
    { value: 'BUILDING MODEL MAKERS', label: 'BUILDING MODEL MAKERS' },
    { value: 'BUTLERS', label: 'BUTLERS' },
    { value: 'CABS / TAXIS', label: 'CABS / TAXIS' },
    { value: 'CAFES', label: 'CAFES' },
    { value: 'CARETAKERS', label: 'CARETAKERS' },
    { value: 'CARPENTERS', label: 'CARPENTERS' },
    { value: 'CATERING SERVICES', label: 'CATERING SERVICES' },
    { value: 'CHARTERED ACCOUNTANTS', label: 'CHARTERED ACCOUNTANTS' },
    { value: 'CHAUFFEURS', label: 'CHAUFFEURS' },
    { value: 'CLEANING', label: 'CLEANING' },
    {
      value: 'CLIMATOLOGY EXPERTS (in buildings)',
      label: 'CLIMATOLOGY EXPERTS (in buildings)',
    },
    {
      value: 'COACHING and PLACEMENT INSTITUTES',
      label: 'COACHING and PLACEMENT INSTITUTES',
    },
    { value: 'COBBLERS / SHOE REPAIR', label: 'COBBLERS / SHOE REPAIR' },
    { value: 'COCONUT OIL MILLS', label: 'COCONUT OIL MILLS' },
    { value: 'COCONUT TREE CLIMBERS', label: 'COCONUT TREE CLIMBERS' },
    { value: 'COMPANY SECRETARIES', label: 'COMPANY SECRETARIES' },
    { value: 'CONTENT WRITERS', label: 'CONTENT WRITERS' },
    { value: 'CONTRACTORS', label: 'CONTRACTORS' },
    {
      value: 'COPYRIGHT and TRADEMARK CONSULTANTS',
      label: 'COPYRIGHT and TRADEMARK CONSULTANTS',
    },
    { value: 'CORE CUTTING', label: 'CORE CUTTING' },
    { value: 'COUNSELLORS', label: 'COUNSELLORS' },
    {
      value: 'COURIER and PARCEL SERVICE',
      label: 'COURIER and PARCEL SERVICE',
    },
    { value: 'CREMATION SERVICES', label: 'CREMATION SERVICES' },
    {
      value: 'CUTLERS / KNIFE SHARPENING',
      label: 'CUTLERS / KNIFE SHARPENING',
    },
    {
      value: 'CUTTING (LASER, CNC) and CARVING',
      label: 'CUTTING (LASER, CNC) and CARVING',
    },
    { value: 'DANCE COACHING', label: 'DANCE COACHING' },
    { value: 'DAY CARE CENTRES', label: 'DAY CARE CENTRES' },
    {
      value: 'DEALERS / BROKERS in USED VEHICLES',
      label: 'DEALERS / BROKERS in USED VEHICLES',
    },
    { value: 'DHABAS', label: 'DHABAS' },
    {
      value: 'DIGITAL and OFFSET PRINTING',
      label: 'DIGITAL and OFFSET PRINTING',
    },
    { value: 'DOCTORS', label: 'DOCTORS' },
    { value: 'DRIVERS', label: 'DRIVERS' },
    {
      value: 'DRIVING LICENCE CONSULTANTS',
      label: 'DRIVING LICENCE CONSULTANTS',
    },
    { value: 'DRIVING SCHOOLS', label: 'DRIVING SCHOOLS' },
    {
      value: 'EDUCATIONAL INSTITUTIONS (PRIVATE)',
      label: 'EDUCATIONAL INSTITUTIONS (PRIVATE)',
    },
    { value: 'ELECTRICIANS', label: 'ELECTRICIANS' },
    { value: 'EMCEES', label: 'EMCEES' },
    { value: 'EMPLOYMENT AGENCIES', label: 'EMPLOYMENT AGENCIES' },
    { value: 'EVENT PLANNERS', label: 'EVENT PLANNERS' },
    {
      value: 'FABRIC VACUUM and SHAMPOO WASH',
      label: 'FABRIC VACUUM and SHAMPOO WASH',
    },
    {
      value: 'FASHION / COSTUME and JEWELLERY DESIGNERS',
      label: 'FASHION / COSTUME and JEWELLERY DESIGNERS',
    },
    { value: 'FAST FOOD', label: 'FAST FOOD' },
    { value: 'FILM DIRECTORS / STUDIOS', label: 'FILM DIRECTORS / STUDIOS' },
    {
      value: 'FILM and MODELLING AGENCIES',
      label: 'FILM and MODELLING AGENCIES',
    },
    { value: 'FILM PRODUCTION HOUSES', label: 'FILM PRODUCTION HOUSES' },
    { value: 'FINANCIERS', label: 'FINANCIERS' },
    { value: 'FITNESS TRAINERS', label: 'FITNESS TRAINERS' },
    { value: 'FLORISTS', label: 'FLORISTS' },
    { value: 'FLOUR MILLS', label: 'FLOUR MILLS' },
    { value: 'FOREX SERVICES', label: 'FOREX SERVICES' },
    { value: 'FUNERAL SERVICES', label: 'FUNERAL SERVICES' },
    { value: 'FURNITURE REUPHOLSTERY', label: 'FURNITURE REUPHOLSTERY' },
    { value: 'GARDENERS', label: 'GARDENERS' },
    { value: 'GENERAL', label: 'GENERAL' },

    { value: 'GOLDSMITHS', label: 'GOLDSMITHS' },
    { value: 'GYMS', label: 'GYMS' },
    { value: 'HAIR SALONS', label: 'HAIR SALONS' },
    {
      value: 'HAIR FIXING / TRANSPLANTATION / IMPLANTATION',
      label: 'HAIR FIXING / TRANSPLANTATION / IMPLANTATION',
    },
    { value: 'HEALING PRACTIONERS', label: 'HEALING PRACTIONERS' },
    { value: 'HEALTHCARE PROFESSIONALS', label: 'HEALTHCARE PROFESSIONALS' },
    { value: 'HOLIDAY HOMES', label: 'HOLIDAY HOMES' },
    { value: 'HOSPITALS', label: 'HOSPITALS' },
    { value: 'HOSTELS', label: 'HOSTELS' },
    { value: 'HOTELS', label: 'HOTELS' },
    { value: 'HOUSE BOATS', label: 'HOUSE BOATS' },
    {
      value: 'HVAC CONSULTANTS (heating, ventilation, air-conditioning)',
      label: 'HVAC CONSULTANTS (heating, ventilation, air-conditioning)',
    },
    { value: 'ICE CREAM PARLOURS', label: 'ICE CREAM PARLOURS' },
    { value: 'INSECT MESH', label: 'INSECT MESH' },
    {
      value: 'INSURANCE and INVESTMENT AGENTS / COMPANIES',
      label: 'INSURANCE and INVESTMENT AGENTS / COMPANIES',
    },
    {
      value: 'INTERIOR DESIGNERS (non-architects)',
      label: 'INTERIOR DESIGNERS (non-architects)',
    },
    { value: 'INTERPRETERS', label: 'INTERPRETERS' },
    { value: 'IRONING SHOPS', label: 'IRONING SHOPS' },
    { value: 'JUICE SHOPS', label: 'JUICE SHOPS' },
    { value: 'LAND SURVEYORS', label: 'LAND SURVEYORS' },
    {
      value: 'LANDSCAPE SPECIALISTS (non-architects)',
      label: 'LANDSCAPE SPECIALISTS (non-architects)',
    },
    { value: 'LAUNDRY and DRY CLEANING', label: 'LAUNDRY and DRY CLEANING' },
    { value: 'LAWYERS / ADVOCATES', label: 'LAWYERS / ADVOCATES' },
    {
      value: 'LOAN PROCESSING CONSULTANTS',
      label: 'LOAN PROCESSING CONSULTANTS',
    },
    {
      value: 'LOCKSMITHS / DUPLICATE KEY MAKERS',
      label: 'LOCKSMITHS / DUPLICATE KEY MAKERS',
    },
    { value: 'LODGES', label: 'LODGES' },
    { value: 'LOGISTICS', label: 'LOGISTICS' },
    { value: 'MARTIAL ARTS COACHING', label: 'MARTIAL ARTS COACHING' },
    { value: 'MASONRY / CIVIL WORKS', label: 'MASONRY / CIVIL WORKS' },
    {
      value: 'MASSAGE PARLOURS / MASSEURS',
      label: 'MASSAGE PARLOURS / MASSEURS',
    },
    {
      value: 'MEDIA and MARKETING AGENCIES, INFLUENCERS',
      label: 'MEDIA and MARKETING AGENCIES, INFLUENCERS',
    },
    { value: 'MEDICAL CLINICS', label: 'MEDICAL CLINICS' },
    { value: 'MEDICAL LABORATORIES', label: 'MEDICAL LABORATORIES' },
    { value: 'MEDITATION CENTRES', label: 'MEDITATION CENTRES' },
    {
      value: 'MEP CONSULTANTS (mechanical, electrical, plumbing)',
      label: 'MEP CONSULTANTS (mechanical, electrical, plumbing)',
    },
    { value: 'MUSIC COACHING', label: 'MUSIC COACHING' },
    { value: 'MUSIC DIRECTORS / STUDIOS', label: 'MUSIC DIRECTORS / STUDIOS' },
    {
      value: 'MUSICAL INSTRUMENTS COACHING',
      label: 'MUSICAL INSTRUMENTS COACHING',
    },
    { value: 'NOTARIES', label: 'NOTARIES' },
    { value: 'NURSES', label: 'NURSES' },
    { value: 'PACKERS and MOVERS', label: 'PACKERS and MOVERS' },
    { value: 'PAINTERS', label: 'PAINTERS' },
    { value: 'PALMISTS', label: 'PALMISTS' },
    {
      value: 'PAYING GUEST FACILITIES / HOMESTAYS',
      label: 'PAYING GUEST FACILITIES / HOMESTAYS',
    },
    { value: 'PEST CONTROL / FUMIGATION', label: 'PEST CONTROL / FUMIGATION' },
    { value: 'PET CARE SERVICES', label: 'PET CARE SERVICES' },
    { value: 'PHOTOGRAPHERS', label: 'PHOTOGRAPHERS' },
    { value: 'PHOTO STUDIOS', label: 'PHOTO STUDIOS' },
    { value: 'PICTURE, PHOTO FRAMING', label: 'PICTURE, PHOTO FRAMING' },
    { value: 'PLUMBERS', label: 'PLUMBERS' },
    {
      value: 'PREFAB STRUCTURE CONSULTANTS',
      label: 'PREFAB STRUCTURE CONSULTANTS',
    },
    { value: 'PRIESTS, CLERGIES', label: 'PRIESTS, CLERGIES' },
    {
      value: 'PRINT-OUTS, SCANNING, BINDING, LAMINATION',
      label: 'PRINT-OUTS, SCANNING, BINDING, LAMINATION',
    },
    { value: 'PRODUCT DESIGNING', label: 'PRODUCT DESIGNING' },
    { value: 'PRODUCT MANUFACTURING', label: 'PRODUCT MANUFACTURING' },
    {
      value: 'PROJECT MANAGEMENT CONSULTANTS',
      label: 'PROJECT MANAGEMENT CONSULTANTS',
    },
    {
      value: 'PROPERTY DOCUMENTATION and VERIFICATION',
      label: 'PROPERTY DOCUMENTATION and VERIFICATION',
    },
    { value: 'PUBLISHERS', label: 'PUBLISHERS' },
    { value: 'PUBS', label: 'PUBS' },
    {
      value: 'RAINWATER HARVESTING EXPERTS',
      label: 'RAINWATER HARVESTING EXPERTS',
    },
    {
      value: 'REAL ESTATE AGENCIES / CONSULTANTS',
      label: 'REAL ESTATE AGENCIES / CONSULTANTS',
    },
    { value: 'RECYCLING', label: 'RECYCLING' },
    { value: 'RENTING', label: 'RENTING' },
    { value: 'REPAIRING, SERVICING', label: 'REPAIRING, SERVICING' },
    { value: 'RESORTS', label: 'RESORTS' },
    { value: 'RESTAURANTS', label: 'RESTAURANTS' },
    { value: 'SECURITY GUARDS', label: 'SECURITY GUARDS' },
    { value: 'SECURITY SERVICES', label: 'SECURITY SERVICES' },
    { value: 'SERVICE APARTMENTS', label: 'SERVICE APARTMENTS' },
    {
      value: 'SEX EDUCATION CONSULTANTS, INFLUENCERS',
      label: 'SEX EDUCATION CONSULTANTS, INFLUENCERS',
    },
    {
      value: 'SCHOOL PICK UP and DROP SERVICES',
      label: 'SCHOOL PICK UP and DROP SERVICES',
    },
    { value: 'SOFT SKILL TRAINING', label: 'SOFT SKILL TRAINING' },
    { value: 'SPAS', label: 'SPAS' },
    {
      value: 'SPORTS & GAMES COACHING and TRAINING',
      label: 'SPORTS & GAMES COACHING and TRAINING',
    },
    {
      value: 'STOCK BROKERS and SHARE MARKET CONSULTANTS',
      label: 'STOCK BROKERS and SHARE MARKET CONSULTANTS',
    },
    {
      value: 'STP CONSULTANTS (sewage treatment plant)',
      label: 'STP CONSULTANTS (sewage treatment plant)',
    },
    { value: 'STRUCTURAL ENGINEERS', label: 'STRUCTURAL ENGINEERS' },
    { value: 'TAILORS', label: 'TAILORS' },
    { value: 'TECH EXPERTS', label: 'TECH EXPERTS' },
    {
      value: 'TELEVISION PROGRAM DIRECTORS / STUDIOS',
      label: 'TELEVISION PROGRAM DIRECTORS / STUDIOS',
    },
    { value: 'TESTING FIRMS / CENTRES', label: 'TESTING FIRMS / CENTRES' },
    { value: 'TEXTILE CONSULTANTS', label: 'TEXTILE CONSULTANTS' },
    { value: 'TIFFIN MEALS', label: 'TIFFIN MEALS' },
    { value: 'TRANSLATORS', label: 'TRANSLATORS' },
    { value: 'TRANSPORTATION', label: 'TRANSPORTATION' },
    { value: 'TRAVEL AGENCIES', label: 'TRAVEL AGENCIES' },
    { value: 'TRAVEL CONSULTANTS', label: 'TRAVEL CONSULTANTS' },
    { value: 'TUITION TEACHERS / TUTORS', label: 'TUITION TEACHERS / TUTORS' },
    {
      value: 'TYPISTS / DOCUMENT DRAFTING',
      label: 'TYPISTS / DOCUMENT DRAFTING',
    },
    { value: 'UI / UX DESIGNERS', label: 'UI / UX DESIGNERS' },
    { value: 'VAASTU SPECIALISTS', label: 'VAASTU SPECIALISTS' },
    {
      value: 'VEHICLE AESTHETIC MODIFICATION, CUSTOMIZATION',
      label: 'VEHICLE AESTHETIC MODIFICATION, CUSTOMIZATION',
    },
    {
      value: 'VEHICLE POLISHING and PAINTING',
      label: 'VEHICLE POLISHING and PAINTING',
    },
    { value: 'VEHICLE WASH', label: 'VEHICLE WASH' },
    { value: 'VFX ARTISTS / STUDIOS', label: 'VFX ARTISTS / STUDIOS' },
    { value: 'VIDEOGRAPHERS', label: 'VIDEOGRAPHERS' },
    { value: 'WELLNESS CENTERS', label: 'WELLNESS CENTERS' },
    {
      value:
        'WHEEL ALIGNMENT, 3D WHEEL BALANCING, TYRE REPLACEMENT, TYRE AIR FILLING',
      label:
        'WHEEL ALIGNMENT, 3D WHEEL BALANCING, TYRE REPLACEMENT, TYRE AIR FILLING',
    },
    {
      value: 'WORKSHOPS / VEHICLE MECHANICS',
      label: 'WORKSHOPS / VEHICLE MECHANICS',
    },
    { value: 'WORKSPACES', label: 'WORKSPACES' },
    { value: 'XEROX / PHOTOCOPY', label: 'XEROX / PHOTOCOPY' },
    { value: 'YOGA STUDIOS, INSTRUCTORS', label: 'YOGA STUDIOS, INSTRUCTORS' },
    { value: 'ZUMBA STUDIOS', label: 'ZUMBA STUDIOS' },
  ];

  var bucketName =
    'https://s3.ap-south-1.amazonaws.com/prochure.in.profile/profile';

  const [image, setImage] = useState();
  const [cropData, setCropData] = useState(false);
  const [cropper, setCropper] = useState();
  const [image2, setImage2] = useState();
  const [cropData2, setCropData2] = useState(false);
  const [cropper2, setCropper2] = useState();
  const onChange = async (e) => {
    var myModal = new bootstrap.Modal(
      document.getElementById('profileUpdation')
    );
    myModal.show();
    var myModalEl = document.getElementById('profileUpdation');
    myModalEl.addEventListener('shown.bs.modal', function (event) {
      cropData ? setCropData(false) : <></>;
      e.preventDefault();
      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      if (files[0]) {
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
      }
    });

    var x = document.getElementById('profileUpdation').offsetWidth;
    // var s=document.getElementById('profileUpdation')
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      // setCropData(cropper.getCroppedCanvas().toDataURL());
      setCropData();
      cropper.getCroppedCanvas().toBlob(
        (blob) => {
          setCropData(URL.createObjectURL(blob));
          setnewb(blob);
        },
        'image/jpeg',
        0.5
      );
    }
  };
  useEffect(() => {
    setValue({ ...value, image: cropData });
  }, [cropData]);
  const uploadme = () => {
    var DP = document.getElementById('DP');
    DP.src = cropData;
  };
  const photo = () => document.getElementById('image').click();

  const onChange2 = async (e) => {
    var myModal = new bootstrap.Modal(
      document.getElementById('profileUpdation2')
    );
    myModal.show();
    var myModalEl = document.getElementById('profileUpdation2');
    myModalEl.addEventListener('shown.bs.modal', function (event) {
      cropData2 ? setCropData2(false) : <></>;
      e.preventDefault();
      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      if (files[0]) {
        const reader = new FileReader();
        reader.onload = () => {
          setImage2(reader.result);
        };
        reader.readAsDataURL(files[0]);
      }
    });

    var x = document.getElementById('profileUpdation2').offsetWidth;
    // var s=document.getElementById('profileUpdation')
  };

  const getCropData2 = () => {
    if (typeof cropper2 !== 'undefined') {
      // setCropData(cropper.getCroppedCanvas().toDataURL());
      setCropData2();
      cropper2.getCroppedCanvas().toBlob(
        (blob2) => {
          setCropData2(URL.createObjectURL(blob2));
          setnewb2(blob2);
        },
        'image/jpeg',
        0.5
      );
    }
  };
  useEffect(() => {
    setValue({ ...value, image2: cropData2 });
  }, [cropData2]);
  const uploadme2 = () => {
    var DP = document.getElementById('DP2');
    DP.src = cropData2;
  };
  const photo2 = () => document.getElementById('image2').click();

  const [value, setValue] = useState({
    Category: '',

    ShopName: '',
    SArea: '',
    country: '',
    city: '',
    state: '',
    Pincode: '',
    contact: '',
    Email: '',
    Createdby: '',
    CatandSubcat: '',
    PCreatedby: '',
    Created_on: '',
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setValue({ ...value, CatandSubcat: selectedOption.value });
  };

  let blank = '/test1.jpg';
  let blank2 = '/test1.jpg';

  const [error, setError] = useState('');

  const [Edit, setEdit] = useState('');

  const [Submit2, setSubmit2] = useState(false);

  const [imgi, setimgi] = useState();
  const [imgii, setimgii] = useState();

  const [params, setparams] = useSearchParams();
  var refferal = params.get('k');

  const submit = (e) => {
    setLoading(true);
    e.preventDefault();
 

    var formdata = new FormData();
    var imag = new File([image], 'avatar.png', { type: 'image/png' });
    var imagi = new File([image2], 'avatar2.png', { type: 'image/png' });
    console.log(imag)
    console.log(imagi)


    formdata.append('file', imag);
    formdata.append('file', imagi);

    formdata.append('data', JSON.stringify(value));

    axios
      .post('/api/freelisting/freelistinginsert', formdata)
      .then((req) => {
        window.parent.postMessage('closeModal', '*');
        setLoading(false);

        //   setValue({...value,
        //     category: '',
        // subcategory: '',

        // Brand: null,
        // ProfileName : "",
        // Area:"",
        // country:"",
        // city:"",
        // state:"",
        // Pincode:"",
        // contact:"",
        //   })

        setTimeout(() => {
          window.location.reload();
        }, 3000);
        notify('FREE / STATIC Listing Created Successfully', true);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        notify('Something Went Wrong', false);
      });
  };

  return (
    <form
      onSubmit={(e) => submit(e)}
      novalidate
      className={Submit2 ? 'was-validated' : ''}
    >
      <div
        className="modal fade"
        id="profileUpdation"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="exampleModalLabel">
                IMAGE{' '}
              </h6>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div id="bod" className="modal-body ">
              <div>
                <div className="d-flex justify-content-center">
                  {cropData ? (
                    <div
                      className="box"
                      style={{ width: '50%', float: 'right' }}
                    >
                      <img
                        style={{ width: '100%' }}
                        src={cropData}
                        alt="cropped"
                      />
                      {/* <button className="btn btn-success mt-2" style={{ float: "right" }}  >Save</button> */}
                    </div>
                  ) : (
                    <div style={{ width: '100%' }}>
                      <Cropper
                        style={{ height: 400, width: '100%' }}
                        zoomTo={false}
                        aspectRatio={45 / 45}
                        preview=".img-preview"
                        src={image}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={0}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        onInitialized={(instance) => {
                          setCropper(instance);
                        }}
                        guides={true}
                      />
                      {/* <button className="btn btn-info text-light mt-2" style={{ float: "right" }} onClick={getCropData}>Ok</button> */}
                    </div>
                  )}
                </div>
                {/* <button className="btn btn-info text-light" style={{ float: "right" }}  onClick={getCropData}>Ok</button> */}

                {/* <button onClick={() => uploadme()}>Start upload</button> */}
              </div>
            </div>
            <div className="modal-footer">
              {/* <button onClick={() => setcp(true)}>doit</button> */}
              {!cropData ? (
                <button
                  type="button"
                  className="btn pro-spaces-button3"
                  onClick={getCropData}
                >
                  Ok
                </button>
              ) : (
                <button
                  type="button"
                  className="btn pro-spaces-button3"
                  onClick={() => uploadme()}
                  data-bs-dismiss="modal"
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="profileUpdation2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="exampleModalLabel">
                IMAGE{' '}
              </h6>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div id="bod" className="modal-body ">
              <div>
                <div className="d-flex justify-content-center">
                  {cropData2 ? (
                    <div
                      className="box"
                      style={{ width: '50%', float: 'right' }}
                    >
                      <img
                        style={{ width: '100%' }}
                        src={cropData2}
                        alt="cropped"
                      />
                      {/* <button className="btn btn-success mt-2" style={{ float: "right" }}  >Save</button> */}
                    </div>
                  ) : (
                    <div style={{ width: '100%' }}>
                      <Cropper
                        style={{ height: 400, width: '100%' }}
                        zoomTo={false}
                        aspectRatio={45 / 45}
                        preview=".img-preview"
                        src={image2}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={0}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        onInitialized={(instance) => {
                          setCropper2(instance);
                        }}
                        guides={true}
                      />
                      {/* <button className="btn btn-info text-light mt-2" style={{ float: "right" }} onClick={getCropData}>Ok</button> */}
                    </div>
                  )}
                </div>
                {/* <button className="btn btn-info text-light" style={{ float: "right" }}  onClick={getCropData}>Ok</button> */}

                {/* <button onClick={() => uploadme()}>Start upload</button> */}
              </div>
            </div>
            <div className="modal-footer">
              {/* <button onClick={() => setcp(true)}>doit</button> */}
              {!cropData2 ? (
                <button
                  type="button"
                  className="btn pro-spaces-button3"
                  onClick={getCropData2}
                >
                  Ok
                </button>
              ) : (
                <button
                  type="button"
                  className="btn pro-spaces-button3"
                  onClick={() => uploadme2()}
                  data-bs-dismiss="modal"
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <h5 className=" pt-4 pb-2 text-center">
        <u>FREE BUSINESS LISTING FORM</u>
      </h5>

      <h6 className="pt-4 mx-3">Please NOTE :</h6>
      <ul className="px-5 mt-2">
        <li className="pt-2">
          FREE Business Listing is also referred to as STATIC listing on this
          platform.
        </li>
        <li className="pt-2">
          Free Listing is ONLY for Professional Consultants and Commercial
          Businesses, that is for All Service Providers and Product Retailers.
        </li>
        <li className="pt-2">
          Your Free Business Listing will be verified and authenticated by{' '}
          <span
            className="vipar"
            style={{
              color: 'green',
              fontSize: '25px',
              paddingRight: '5px',
            }}
          >
            Vipar
          </span>{' '}
          Admin before it is visible on the PROchure App. This might take 24-48
          hours after you send us this form.
        </li>
        <li className="pt-2">
          Verification is mandatory and inevitable inorder to maintain the
          credibility of the platform and the profile creator / owner is
          requested to co-operate.
        </li>
        <li className="pt-2">
          Verification is to authenticate whether it is a genuine business with
          an active contact number and operating address.{' '}
          <span
            className="vipar"
            style={{
              color: 'green',
              fontSize: '25px',
              paddingRight: '5px',
            }}
          >
            Vipar
          </span>{' '}
          Admin may get in touch with you for verification. Kindly co-operate.
        </li>
        <li className="pt-2">
          Free Listing will be approved ONLY after assessing and verifying the
          authenticity of the respective business / individual.
        </li>
        <li className="pt-2">
          Duplication of the Same Profiles will be Discarded.
        </li>
        <li className="pt-2">
          You can UPGRADE your Free Listing to a Paid Membership at any time.
        </li>
        <li className="pt-2">
          If you are into more than one type of Business / Service, Please fill
          separate forms for each business with a Different Mobile Number /
          Email ID.
        </li>
        <li className="pt-2">
          Once your Business Listing is Approved and Created on the App for
          Free, you can Log-in to the App and Make Payment to Upgrade to a Paid
          Membership.
        </li>
        <li className="pt-2">
          We shall share your User / Login Id and Password to your Email Id so
          that you can Log-in to your Account.
        </li>
        <li className="pt-2">
          The Approval and Duration of FREE / STATIC Listing of a respective
          business profile is at the sole discretion of the platform.
        </li>
      </ul>
      <h6 className="py-2 mx-3 ">
        Please FILL in the below mentioned fields appropriately
      </h6>

      <section>
        <div className="  mx-3  mt-2">
          <div className="py-2">
            <h6 className="">CLASSIFICATION *</h6>

            <div class="form-check pt-1">
              <input
                required
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                value={'Services'}
                onChange={(e) =>
                  setValue({
                    ...value,
                    Classification: e.target.value,
                  })
                }
              />
              <label class="form-check-label " for="flexRadioDefault">
                Services
              </label>
            </div>
            <div class="form-check py-1 ">
              <input
                required
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                value={'Products'}
                onChange={(e) =>
                  setValue({
                    ...value,
                    Classification: e.target.value,
                  })
                }
              />
              <label class="form-check-label " for="flexRadioDefault">
                Products
              </label>
            </div>
            <div class="form-check pt-1">
              <input
                required
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                value={'Public Utilities and Social Service'}
                onChange={(e) =>
                  setValue({
                    ...value,
                    Classification: e.target.value,
                  })
                }
              />
              <label class="form-check-label " for="flexRadioDefault">
                Public Utilities and Social Service
              </label>
            </div>

            <div className="invalid-feedback">PLEASE SELECT Classification</div>
          </div>

          <div className="py-2">
            {value?.Classification == 'Services' && (
              <>
                <div class=" py-1 ">
                  <h6 className="">
                    Select your appropriate Category / Industry / Nature of
                    Business *
                  </h6>

                  <Select
                    value={selectedOption}
                    options={serviceOptions}
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback">
                    PLEASE SELECT your appropriate Category / Industry / Nature
                    of Business
                  </div>
                </div>
              </>
            )}
            {value?.Classification == 'Products' && (
              <>
                <div class="py-1 ">
                  <h6 className="">
                    Select your appropriate Category / Industry / Nature of
                    Business *
                  </h6>

                  <Select
                    value={selectedOption}
                    options={productOptions}
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback">
                    PLEASE SELECT your appropriate Category / Industry / Nature
                    of Business
                  </div>
                </div>
              </>
            )}
            {value?.Classification == 'Public Utilities and Social Service' && (
              <>
                <div class=" py-1 ">
                  <h6 className="">
                    Select your appropriate Category / Industry / Nature of
                    Business *
                  </h6>

                  <Select
                    value={selectedOption}
                    options={utilitesOptions}
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback">
                    PLEASE SELECT your appropriate Category / Industry / Nature
                    of Business
                  </div>
                </div>
              </>
            )}
            {/* {value?.Classification == 'a Professional Consultant' && (
              <>
                <div class=" py-1 ">
                  <h6 className="">Category *</h6>

                  <Select
                    value={selectedOption}
                    options={pcOptions}
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback">PLEASE SELECT Category</div>
                </div>
              </>
            )} */}
          </div>
          <div className="py-2">
            <h6 className="">
              Give a brief or detailed description of the types of Services /
              Products
            </h6>
            <input
              // required
              type="text"
              className="form-control"
              onChange={(e) => setValue({ ...value, Category: e.target.value })}
            />
            <div className="invalid-feedback">
              PLEASE ENTER a brief or detailed description of the types of
              Services / Products
            </div>
          </div>
          <div className="pt-4  ">
            <div className="d-flex flex-column align-items-center text-center ps-0 pe-0 ">
              <img
                id="DP"
                className=" mt-5"
                width="150px"
                src={`${bucketName}/${value.id}/avatar.png`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = '/blank.png';
                }}
              />
              <div id="idtestingprofilepicvalidation" className="upload">
                <input
                  required
                  type="file"
                  accept="image/*"
                  onChange={onChange}
                  id="image"
                  className="form-control"
                />
                <label for="image">
                  <AiFillCamera size={'40px'} />
                </label>
                <div
                  class="invalid-feedback"
                  style={{ backgroundColor: 'white' }}
                >
                  PLEASE UPLOAD IMAGE
                </div>
              </div>
              <h6 className="text-50 mt-3 up" onClick={() => photo()}>
                IMAGE *
              </h6>
              <span> </span>
            </div>
            {/* <div className="pb-2 ">
              <label>IMAGE </label>

              <Imageupload   onChange={(e)=>setimgii(e)} ratio={4/4}/>
            </div> */}
          </div>

          <div className="pt-4  ">
            <div className="d-flex flex-column align-items-center text-center  ps-0 pe-0 ">
              <img
                id="DP2"
                className=" mt-5"
                width="150px"
                src={`${bucketName}/${value.id}/avatar.png`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = '/blank.png';
                }}
              />
              <div id="idtestingprofilepicvalidation" className="upload">
                <input
                  //   required
                  type="file"
                  accept="image/*"
                  onChange={onChange2}
                  id="image2"
                  className="form-control"
                />
                <label for="image2">
                  <AiFillCamera size={'40px'} />
                </label>
                {/* <div
                  class="invalid-feedback"
                  style={{ backgroundColor: 'white' }}
                >
                  PLEASE upload PROFILE DISPLAY PICTURE
                </div> */}
              </div>
              <h6 className="text-50 mt-3 up" onClick={() => photo2()}>
                IMAGE{' '}
              </h6>
              <span> </span>
            </div>
            <div className="d-flex justify-content-center">
              <label className="pt-3 labels text-center">
                Upload Image(s) of Shop or Visiting card or both
              </label>
            </div>
          </div>

          <div className=" ">
            <div className="pt-4 pb-2  pt-5">
              <div className="col-md-12">
                <h6 className="">
                  NAME of CONSULTANT / SERVICE PROVIDER / BUSINESS / SHOP *
                </h6>
                <input
                  required
                  placeholder="Individual / Entity / Company Name"
                  type="text"
                  className="form-control"
                  onChange={(e) =>
                    setValue({ ...value, ShopName: e.target.value })
                  }
                />
                <div className="invalid-feedback">
                  PLEASE ENTER NAME of CONSULTANT / SERVICE PROVIDER / BUSINESS
                  / SHOP
                </div>
              </div>

              <div className="col-md-12">
                <h6 className="pt-5 pb-2">ADDRESS *</h6>
                <label className="labels">Secondary, Primary Locality *</label>
                <br />
                <label className="labels">
                  ( Enter " Only E-commerce " if no physical store )
                </label>
                <input
                  type="text"
                  required
                  placeholder="e.g. HAL 2nd Stage, Indiranagar"
                  className="form-control"
                  onChange={(e) =>
                    setValue({ ...value, SArea: e.target.value })
                  }
                />
                <div className="invalid-feedback">
                  PLEASE ENTER Secondary, Primary Locality
                </div>
              </div>

              <StateCity setValue={setValue} value={value} Edit={Edit} />
              <div className="col-md-12">
                <label className="labels">Pin code / Zip code </label>
                <input
                  type="number"
                  className="form-control"
                  value={value.Pincode}
                  onChange={(e) => {
                    if (e.target.value.length > 6) return false;

                    setValue({ ...value, Pincode: e.target.value });
                  }}
                />
               
              </div>

              <div className="col-md-12">
                <h6 className="pt-5 pb-2">CONTACT DETAILS *</h6>

                <label className="labels">
                  Mobile Number / Landline Number / Toll Free Number / Customer
                  Care Number *
                </label>
                <br />
                <label className="labels">
                  ( Enter Names of Websites / Web Apps if only online store )
                </label>
                <input
                  type="text"
                  required
                  value={value.contact}
                  className="form-control"
                  placeholder="Enter State Code before Landline Number"
                  onChange={(e) => {
                    // if (e.target.value.length == 13) return false;
                    setValue({ ...value, contact: e.target.value });
                  }}
                />
                <div className="invalid-feedback">
                  PLEASE ENTER Mobile Number / Landline Number / Toll Free
                  Number / Customer Care Number
                </div>
              </div>

              <div className="col-md-12 ">
                <label className="labels">Email id</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) =>
                    setValue({ ...value, Email: e.target.value })
                  }
                />
              </div>

              <div className="col-md-12 pt-4">
                <label className="labels">Profile Created by *</label>
                <div class="form-check pt-1">
                  <input
                    required
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault1"
                    value={'Business / Profile Owner'}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        PCreatedby: e.target.value,
                      })
                    }
                  />
                  <label class="form-check-label " for="flexRadioDefault1">
                    Business / Profile Owner
                  </label>
                </div>
                <div class="form-check py-1 ">
                  <input
                    required
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault1"
                    value={'Friend / Acquaintance'}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        PCreatedby: e.target.value,
                      })
                    }
                  />
                  <label class="form-check-label " for="flexRadioDefault1">
                    Friend / Acquaintance (of the Profile Owner)
                  </label>
                </div>
                <div class="form-check py-1 ">
                  <input
                    required
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault1"
                    value={'Relative'}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        PCreatedby: e.target.value,
                      })
                    }
                  />
                  <label class="form-check-label " for="flexRadioDefault1">
                    Relative (of the Profile Owner)
                  </label>
                </div>

                <div class="form-check py-1 ">
                  <input
                    required
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault1"
                    value={'Client / Customer'}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        PCreatedby: e.target.value,
                      })
                    }
                  />
                  <label class="form-check-label " for="flexRadioDefault1">
                    Client / Customer (of the Profile Owner)
                  </label>
                </div>
                <div class="form-check py-1 ">
                  <input
                    required
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault1"
                    value={'Vipar Friend'}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        PCreatedby: e.target.value,
                      })
                    }
                  />
                  <label class="form-check-label " for="flexRadioDefault1">
                    Vipar Friend
                  </label>
                </div>
              </div>
              <div className="col-md-12 pt-4">
                <label className="labels">Name of the Profile Creator *</label>
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Enter Your Name"
                  onChange={(e) =>
                    setValue({ ...value, Createdby: e.target.value })
                  }
                />
                <div className="invalid-feedback">
                  PLEASE ENTER Name of the Profile Creator
                </div>
              </div>
              <div className="col-md-12 pt-4">
                <label className="labels">Profile Created on *</label>
                <input
                  required
                  type="date"
                  className="form-control"
                  onChange={(e) =>
                    setValue({ ...value, Created_on: e.target.value })
                  }
                />
                <div className="invalid-feedback">
                  PLEASE SELECT Profile Created on
                </div>
              </div>

              <div class="form-check pt-5 ">
                <input
                  class="form-check-input affcheckbox"
                  type="checkbox"
                  id="flexCheckDefault1"
                  onChange={(e) => setcheckbtn(e.target.checked)}
                />
                <label class="form-check-label">
                  I hereby confirm that this profile is created for the business
                  owned by me / owned by a person known to me and that it is an
                  existing / currently active genuine business. I declare that
                  the information provided here is true to the best of my
                  knowledge and that I give my consent for verification by{' '}
                  <span
                    className="vipar"
                    style={{
                      color: 'green',
                      fontSize: '25px',
                      paddingRight: '5px',
                    }}
                  >
                    Vipar
                  </span>{' '}
                  Admin.
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="my-2 text-center">
          {loading ? (
            <button
              class="btn mb-2 mt-4"
              type="button"
              disabled
              style={{ backgroundColor: '#55374a', color: 'white' }}
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Loading...</span>
            </button>
          ) : (
            <button
              disabled={checkbtn === false}
              className="btn mb-2 mt-4"
              style={{ backgroundColor: '#55374a', color: 'white' }}
              onClick={(e) => {
                setSubmit2(true);
              }}
            >
              Save
            </button>
          )}
        </div>
      </section>
    </form>
  );
};
