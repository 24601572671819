import React, { useState, useEffect } from 'react'

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import './PassiveProfileCreation.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
// import { Navoutside } from '../Navoutside/Navoutside';
import { print_state, print_city, state_arr } from '../../Utility/stateCity'
export function PassiveProfileCreation(props) {
    
    const [Authenticated, setAuthenticated] = useState(false);
    const [value, setValue] = useState({pname:'', firstname: '', dob: '', gender: '',state:'',city:'' })
    const [error, setError] = useState('')
    const [Submit, setSubmit] = useState(false)

    const [Stateload, setStateload] = useState(true)
    useEffect(() => {
        if (Stateload)
            print_state("State")
        setStateload(false)
    }, [])

    // useEffect(() => {
    //     axios.get('/api/verify/meatsignup').then(res => {
    //       if (res.status === 200) {
    //         console.log('workd');
    //         setAuthenticated(true)
    //       }
    //     }).catch((err) => {
    //       if (err) {
    //         navigate('/')
    //       }
    //       console.log(err.response.status);
    //     })
    //   }, []);

      function Cityshow(e) {
        var index = document.getElementById(e.target.id).selectedIndex
        print_city('City', index)
        setValue({ ...value, state: e.target.value })
    }


    let navigate = useNavigate()

    function validation() {

        if (value.firstname.length > 0) {
            setError('')
        }
        else {
            return setError('PLEASE ENTER Name')
        }

        if (value.dob.length > 0) {
            console.log(value.dob);
            var age = Math.floor(
              parseInt(new Date() - new Date(value.dob)) / 1000 / 60 / 60 / 24 / 365
            );
            if (age > 11) {
              setError("");
            }else{
              return setError("Sorry ! You should be atleast 12 years of age");
            }
           
          } else {
            return setError("PLEASE ENTER Date of Birth ");
          }

        if (value.gender.length > 0) {
            setError('')
        }
        else {
            return setError('PLEASE ENTER Gender')
        }
        if (value.state.length > 0) {
            setError('')
        }
        else {
            return setError('PLEASE ENTER State')
        }
        if (value.city.length > 0) {
            setError('')
        }
        else {
            return setError('PLEASE ENTER City')
        }
        return true

    }
const [loading, setloading] = useState(false)

    const submit = (e) => {
        e.preventDefault()
        
        var status = false
        setSubmit(true)
       
        status = validation()
        if (status == true) {
            setloading(true)
            // console.log(value)
            // return
            axios.post('/api/accounts/passiveregister', { value })
                .then(res => {
                    console.log(res)
                    setloading(false)
                    if (res.status == 200) {
                        return navigate('/c_v_registration')
                    }
                }).catch((err) => {
                    if (err.response.status == 304) {
                        console.log(err.response.data)
                        return navigate('/c_v_registration')
                        // toast warning
                        setloading(false)
                        
                    }else{
                        setloading(false)
                    }
                    console.log(err.response)
                })
        }

    }

    useEffect(() => {

        if (Submit) {
            validation()
        }
    }, [value])


    return (
<>
{/* <Navoutside /> */}
        <div className="registration-form pt-3">
            <form onSubmit={(e) => submit(e)}>
                <center> <h3 className='headding'>Create a Profile</h3></center>
                <div className='row'>
                <div className="col-md-12">
                                    <label className="labels">Name *</label>
                                    <input type="text" className="form-control " id="username"  value={value.firstname} onChange={(e) => setValue({ ...value, firstname: e.target.value , pname:e.target.value})} />
                </div>
                <div className="col-md-12">
                                    <label className="labels">Date of Birth *</label>
                                    <input type="date" className="form-control " id="birth-date" value={value.dob} onChange={(e) => setValue({ ...value, dob: e.target.value })} />
                </div>
                <div className="col-md-12">
                                    <label className="labels">Gender *</label>
                                    <select type="text" className="form-select " id="birth-date" value={value.gender} onChange={(e) => setValue({ ...value, gender: e.target.value })}><option value="" disabled ></option><option value='male'>Male</option><option value='female'>Female</option><option value='transgender'>Transgender</option></select>
                </div>
                <div className="col-md-12">
                                    <label className="labels">State *</label>
                                    <select
                                        className="form-select"
                                        value={value.state}
                                        id="State"
                                        name="City"
                                        onChange={(e) => Cityshow(e)}
                                    >
                                        {/* <select onChange={(e) =>}  className="form-select" >
                                          </select> */}
                                    </select>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label className="labels">City *</label>
                                    <select
                                        className="form-select"
                                        name="City"
                                        value={value.city}
                                        id="City"
                                        onChange={(e) =>
                                            setValue({ ...value, city: e.target.value })
                                        }
                                    >
                                        <option></option>
                                    </select>
                                </div>



                </div>


                <h6 style={{'color':'red'}}>{error ? error : ''}</h6>

                <div className="form-group">


                {!loading && 
                    <button className="btn pro-spaces-button3 ">Create Account</button>
                }

                {loading && 
                    <button className="btn pro-spaces-button3  " disabled> <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              Loading...</button>
                }
               
                </div>
            </form>

        </div>

        </>

    )
}
