import React, { useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

let backdropRef = null;

function BackdropComponent() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  backdropRef = {
    open: () => handleOpen(),
    close: () => handleClose(),
  };

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export function openBackdrop() {
  backdropRef && backdropRef.open();
}

export function closeBackdrop() {
  backdropRef && backdropRef.close();
}

export default BackdropComponent;
