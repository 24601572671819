import React,{useState,useEffect} from 'react'
import { Link,useSearchParams } from "react-router-dom";
import Axios from 'axios'
import { SpinnerBig } from '../../Utility/spinner';
import { Information_card1 } from './Listing_Card/Information_card1';
import { Information_card2 } from './Listing_Card/Information_card2';
import { Information_card3 } from './Listing_Card/Information_card3';
import { Information_table } from './Information_table';
import { Information_table_edit } from './Information_table_edit';

export const Information_listing_edit = () => {
  let [searchParams, setSearchParams] = useSearchParams(); // this id may sub category id or sub sub category id
  var id = searchParams.get("k")
  const [data, setData] = useState([])
  const [Loading, setLoading] = useState(true)
  var account_type=localStorage.getItem("Account_type")
  useEffect(() => {
    Get()
  }, [])

  const Get=()=>{
    if (account_type!=="SUPERADMIN") {
        Axios.post('/api/User/Information_view_for_edit',{id}).then((res)=>{
            if (res.status==200) {
              console.log(res.data)
              setData(res.data)
              Toggle(res.data[0].formType,res.data)
              setLoading(false)
            }
          }).catch((err)=>{
            setLoading(false)
      
          })  
    }else{
        Axios.post('/api/User/Information_listing',{id}).then((res)=>{
            if (res.status==200) {
              console.log(res.data)
              setData(res.data)
              Toggle(res.data[0].formType,res.data)
              setLoading(false)
            }
          }).catch((err)=>{
            setLoading(false)
      
          })
    }

  }

const [OutPut, setOutPut] = useState([])
  const Toggle=(formType,data)=>{
    console.log(formType)
    // if (formType==="1") {
    //   setOutPut(<Information_card1 card={data} ></Information_card1>)
    // }
  }

  return (
    <div className='container'>
      <div className=' row '>
        {

          Loading?
          <SpinnerBig/>
          :
          <>
          {
            !data.length==0?
            <>
              {
               data.map((e)=>{
                return(
                  e.formType==="1"||e.formType==="2"||e.formType==="4"?
                  e.formType==="1"?<Information_card1 data={e} Edit={true} Get={Get} ></Information_card1>:e.formType==="2"? <Information_card2 data={e} Edit={true} Get={Get}/>:e.formType==="4"?<Information_card3 data={e} Edit={true} Get={Get}/>
                  :""
                  :""
                )
               }) 
              }
              {
                data[0].formType==="3"?
                <Information_table_edit data={data} Get={Get}/>
                :""
              }
            </>
            :<>
            <div style={{"display":"flex","justifyContent":"center","alignItems":"center","height":"100vh"}}>
                <h3>Nothing to show !</h3>
            </div>
            </>
          }

          </>
} 
      </div>
    </div>
  )
}
