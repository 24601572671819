import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Axios from 'axios';
import { SpinnerBig } from '../../Utility/spinner';
import { Information_card1 } from './Listing_Card/Information_card1';
import { Information_card2 } from './Listing_Card/Information_card2';
import { Information_card3 } from './Listing_Card/Information_card3';
import { Information_table } from './Information_table';
import { Information_Search1 } from './Search/Information_Search1';
import { Information_Search2 } from './Search/Information_Search2';
import { Information_Search3 } from './Search/Information_Search3';
import { Information_Search4 } from './Search/Information_Search4';
import { Information_Search5 } from './Search/Information_Search5';

export const Information_listing = () => {
  let [searchParams, setSearchParams] = useSearchParams(); // this id may sub category id or sub sub category id
  var id = searchParams.get('k');
  console.log(id);
  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [FormType, setFormType] = useState('');
  useEffect(() => {
    Get();
  }, []);

  const Get = () => {
    Axios.post('/api/User/Information_listing', { id })
      .then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          setData(res.data);
          Toggle(res.data[0].formType, res.data);
          setFormType(res.data[0].formType);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const [OutPut, setOutPut] = useState([]);
  const Toggle = (formType, data) => {
    console.log(formType);
    // if (formType==="1") {
    //   setOutPut(<Information_card1 card={data} ></Information_card1>)
    // }
  };
  const [value, setValue] = useState({
    id: id,
    country: 'India',
    state: '',
    city: '',
    pincode: '',
    pname: '',
    title: '',
    blood_group: '',
    genre: '',
  });
  const Reset = () => {
    setValue({
      ...value,
      country: 'India',
      state: '',
      city: '',
      pincode: '',
      pname: '',
      title: '',
      blood_group: '',
      genre: '',
    });
    Get();
  };

  const dva = () => {
    Axios.post('/api/User/Information_listing_search', { value, FormType })
      .then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          setData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const daswe2 = () => {
  //   axios
  //     .post("/api/User/vensearching", {
  //       id,
  //       quickLocation: userData.quickLocation,
  //     })
  //     .then((res) => {
  //       setCat(res.data);
  //       setResults(res.data.map((sr) => sr._id));
  //       const postSummaries = res.data.map((post) => ({
  //         id: post._id,
  //         key: post.id,
  //         data: post,
  //       }));
  //       setdata(postSummaries);
  //       setload(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  return (
    <div className="container">
      <div className=" row ">
        {Loading ? (
          <SpinnerBig />
        ) : (
          <>
            <div className="d-flex justify-content-end pb-3">
              <Link to={`/Information_view_for_edit?k=${id}`}>
                <button className="btn pro-spaces-button3">
                  View your Post
                </button>
              </Link>
            </div>
            {FormType ? (
              FormType == '1' ? (
                id == '64b4edae0b1209dabd8deea4' ? ( // this is the is the id of blood donar of information subcategory
                  <Information_Search2
                    setValue={setValue}
                    value={value}
                    dva={dva}
                    Reset={Reset}
                  />
                ) : (
                  <Information_Search1
                    setValue={setValue}
                    value={value}
                    dva={dva}
                    Reset={Reset}
                  />
                )
              ) : FormType == '2' ? (
                <Information_Search3
                  setValue={setValue}
                  value={value}
                  dva={dva}
                  Reset={Reset}
                />
              ) : FormType == '3' ? (
                <Information_Search4
                  setValue={setValue}
                  value={value}
                  dva={dva}
                  Reset={Reset}
                />
              ) : FormType == '4' ? (
                <Information_Search5
                  setValue={setValue}
                  value={value}
                  dva={dva}
                  Reset={Reset}
                />
              ) : (
                ''
              )
            ) : (
              ''
            )}

            <div></div>

            {!data.length == 0 ? (
              <>
                {data.map((e) => {
                  return e.formType === '1' ||
                    e.formType === '2' ||
                    e.formType === '4' ? (
                    e.formType === '1' ? (
                      <Information_card1 data={e}></Information_card1>
                    ) : e.formType === '2' ? (
                      <Information_card2 data={e} />
                    ) : e.formType === '4' ? (
                      <Information_card3 data={e} />
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  );
                })}
                {data[0].formType === '3' ? (
                  <Information_table data={data} />
                ) : (
                  ''
                )}
              </>
            ) : (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '50vh',
                  }}
                >
                  <h3>Nothing to show !</h3>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
