
import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Modal, Carousel, Dropdown } from 'react-bootstrap';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { SiGooglemaps } from "react-icons/si";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from 'axios'
import { useNavigate } from "react-router-dom";

const notify = (message, sab) => {
  if (sab) {
    toast.success(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.warning(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const Information_card1 = ({data,Edit,Get}) => {
  let navigate = useNavigate();
  console.log(data)
var account_type=localStorage.getItem("Account_type")
var account_id=localStorage.getItem("AccountID")
var id=data._id
const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
const Delete=()=>{
  Axios.post('/api/User/Information_delete',{id}).then((res)=>{
    if (res.status==200) {
      notify("Deleted successfully",true)
      Get()
    }
  }).catch((err)=>{
    notify("Something went wrong",false)

  })  
  
}
  return (
  <div className="col-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 mb-3 "><>
  <div className="text-center card-box p-1">
    {
      Edit?
      account_type==="SUPERADMIN"||account_id===data?.Created_by?
      <div className='d-flex justify-content-end'>
      <Dropdown>
        <Dropdown.Toggle id="dropdown-basic"  style={{ background: 'none', border: 'none' }}>
          <BsThreeDotsVertical color='black' size={15} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={(e)=> navigate(`/information_forms_edit?k=${data._id}`) }>     
            Edit
            </Dropdown.Item>
          <Dropdown.Item onClick={(e)=>handleShow()}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      </div>
      :
      <></>
      :<></>
    }

    <div className="member-card pb-1">
    {
          data?.profile_image?.name?
      <div className="thumb-lg member-thumb mx-auto">
        {
          data?.profile_image?.name?
          <img
          src={data?.link}
          className="rounded-circle img-thumbnail"
          alt="profile-image"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src =
                "/blank.png";
        }}
        />
        :""
      }
      </div>
      :""
    }
      <div className="">
        <h4>{ data.pname? data.pname.toUpperCase() : ''}</h4>
        <span className="ufghsuhie rigehioerhog">
          <span className="ufghsuhie">
            {data.subcategory.sub} | {data.category.category}
          </span>

          <br />
          <span>
            <span className="ufghsuhie">{data.city} | {data.state} {data.Location === true ?
              <a target="_blank" href={`http://maps.google.com/maps?z=18&q=${data.Location_Coordinates.lat},${data.Location_Coordinates.lng}`}>
                <SiGooglemaps className="gmapicon" />
              </a>
              : <></>}</span>
          </span>
        </span>
      </div>
      <div className="row mt-2">
        <div className="col-12">
          <Link to={`/Information_profile?k=${data._id}`}>
            <button
              type="button"
              className="btn pro-spaces-button3 btn-rounded ps-3 pe-3 "
            >
              View
            </button>
          </Link>
        </div>

      </div>
    </div>
  </div>
      </>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Are you sure ?</h3>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-danger' onClick={(e)=>Delete()}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
</div>
  )
}
