import { createContext, useEffect, useContext, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Carousel, CarouselItem } from "react-bootstrap"
import { useSearchParams } from "react-router-dom";
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import { keyGen } from '../../../../Utility/helper'
import { AiOutlineSetting } from "react-icons/ai";
import { Link } from "react-router-dom"
import '../movie.css'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export let moviesContext = createContext()
export const SportsGridView = () => {
    let [searchParams, setSearchParams] = useSearchParams()
    const [Error, setError] = useState()
    const [Movies, setMovies] = useState([])
    const [Loading, setLoading] = useState(true)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    var language = searchParams.get("language");
    console.log(language)
    const notify = (message, sab) => {
        if (sab) {
            toast.success(message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            toast.warning(message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const FetchMovies = async () => {
        try {

            // if (language.length === 0) {
            //     return setError('Invalid Page')
            // }
            let movies = await axios.get('/api/User/getSports')
            setLoading(false)
            if (movies.status != 200) {
                return console.log('something went wrong')
            }
            setMovies(movies.data)
            console.log(movies)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }
    useEffect(() => {
        FetchMovies()
        return () => {

        }
    }, [])
    const [currentIndex, setcurrentIndex] = useState(undefined)
    useEffect(() => {
        console.log(currentIndex)
        console.log(Movies[currentIndex])
        return () => {

        }
    }, [currentIndex])

    const Delete = (e, id, ind) => {
        var id = id
        axios.post("/api/User/SportsDelete", { id }).then((response) => {
            // console.log('success')
            if (response.status === 200) {
                //   console.log("reported11")
                // setreported(true)
                // window.location.reload();
                let temp = [...Movies]
                temp.splice(ind, 1)
                setcurrentIndex(undefined)
                setMovies(temp)
                setShow(false)
                notify("Post deleted successfully",true);

            }
        }).catch(
            res => {
                console.log('Something went wrong')
                notify("Post deleted unsuccessfully",false);

            }
        )
    }
    const [DeleteStatus, setDeleteStatus] = useState(false)
    useEffect(() => {
        if (!show) {
            setTimeout(() => {
                setDeleteStatus(false)


            }, 150);

        }

    }, [show])



 

    return (
        <div className='TopTuck' style={{'marginTop':'80px'}}>
                 <div> <div className="d-flex justify-content-end">
        <Link to="/sports_create">
          <button className="btn btn-dosabutton ps-3 pe-3 mb-3 deghievgviehcieh" >Add New</button>
        </Link>
       &nbsp;
       </div>
       </div>
            <moviesContext.Provider value={{ setcurrentIndex, handleShow }}>
                <h1>{Error}</h1>
                <div className='container-fluid'>
                    <div className=' row  '>
                        {Movies?.length > 0 ? Movies.map((elem, index) => {
                            return <SingleTile key={keyGen()} value={elem} index={index} style={{ display: 'inline-block' }} />

                        }) : <></>}
                    </div>
                </div>
            </moviesContext.Provider>

            {/* <Button variant="primary" onClick={handleShow}>
                Launch demo modal
            </Button> */}

            <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" style={{'zIndex':'1000000'}}
                centered scrollable>
                <Modal.Header closeButton>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    {
                        !DeleteStatus ?
                            <>
                                {currentIndex >= 0 &&
                                    <SliderMovieLite Files={Movies[currentIndex].Files} />
                                }
                                <div>
                                    <h3><center>{Movies[currentIndex]?.HeadLine}</center></h3>
                                    {/* <h2>{Movies[currentIndex]?._id}</h2> */}
                                    {/* {localStorage.getItem("Type")==="sponsored" ? */}

                                      
                                    <div className='d-flex justify-content-end' >
                                            <a type="button" href="/" className="dotoptionbtn   cell" data-bs-toggle="dropdown" aria-expanded="false">
                                                <AiOutlineSetting size={35}></AiOutlineSetting>
                                            </a>
                                            <ul className="dropdown-menu ">
                                                <li>
                                                    <Link to={"/sports_update?pi=" + Movies[currentIndex]?._id} className="dropdown-item">Edit post</Link>
                                                </li>
                                                <li >
                                                    <a className="dropdown-item" onClick={(e) => { setDeleteStatus(true) }}> Delete</a>
                                                </li>
                                            </ul>

                                        </div>
                                    
                                    {/* :""}  */}
                                  

                                </div>
                                <div>
                                    <p>{Movies[currentIndex]?.Description}</p>
                                </div>
                                
                             {  
                             Movies[currentIndex]
                             ?.TvChannel? <div className='row'>
                                   <div className='col-6'>
                                   Tv Channel
                                    </div> 
                                    <div className='col-1'>
                                      :
                                    </div>
                                    <div className='col-5'>
                                    {Movies[currentIndex]?.TvChannel}
                                    </div>

                                </div>
                                :""
                                }
                                   {  
                             Movies[currentIndex]?.TvChannelOnAt? <div className='row'>
                                   <div className='col-6'>
                                   ON / AT
                                    </div> 
                                    <div className='col-1'>
                                      :
                                    </div>
                                    <div className='col-5'>
                                    {Movies[currentIndex]?.TvChannelOnAt}
                                    </div>

                                </div>
                                :""
                                }

{  
                                           
                                           Movies[currentIndex]?.OTTPlatform? <div className='row'>
                                            <div className='col-6'>
                                            OTT Platform's
                                             </div> 
                                             <div className='col-1'>
                                               :
                                             </div>
                                             <div className='col-5'>
                                             {Movies[currentIndex].OTTPlatform.map((e,index)=>{
                                                  if(Movies[currentIndex].OTTPlatform.length!==index+1)
                                                  {

                                                         return<div>{e+","+" "}</div>
                                                          
                                                  }
                                                  else{
                                                   return e
                                                  }
                                             })  }
                                             </div>
         
                                         </div>
                                         :""
                                         }

                              {  
                             Movies[currentIndex]?.OTTOnAt
                             
                             ? <div className='row'>
                                   <div className='col-6'>
                                   ON / AT
                                    </div> 
                                    <div className='col-1'>
                                      :
                                    </div>
                                    <div className='col-5'>
                                    {Movies[currentIndex]?.OTTOnAt}
                                    </div>

                                </div>
                                :""
                                }
                      

                                    
                            </>


                            :
                            <>
                                <h3>Are you sure?</h3>
                                <div className='d-flex justify-content-end'>
                                    <button className='btn pro-spaces-button' onClick={(e) => { setDeleteStatus(false) }}>No</button>
                                    &nbsp;&nbsp;&nbsp;
                                    <button className='btn pro-spaces-button3' onClick={(e) => { Delete(e, Movies[currentIndex]._id, currentIndex) }} >Yes</button>&nbsp;

                                </div>
                            </>

                    }

                    {/* <SingleTile key={keyGen()} value={Movies[currentIndex]} index={currentIndex} style={{ display: 'inline-block' }} /> */}



                </Modal.Body>

            </Modal>

            
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        </div >
    )
}

export const SingleTile = (props) => {

    let MovieContext = useContext(moviesContext)
    const Open = () => {
        MovieContext.setcurrentIndex(props.index)
        MovieContext.handleShow()
    }
    console.log(props)

    return (<div className='col-12 col-md-4 col-lg-3 m-0'>
        <Card  className='' onClick={(e) => Open()} sx={{width:'100%',  display: 'inline-block', margin: '2px' }}>
            <CardActionArea>
                <SliderMovieLite Files={props.value.Files} />
                <CardContent>
                    <Typography className='moviesinglelineheadline' gutterBottom variant="h5" component="div">
                        <center>{props.value.HeadLine}</center>
                    </Typography>
                    <Typography className='moviereadmore' variant="body2" color="text.secondary">
                        {props.value.Description}
                        <b className='invisible'>Lorem ipsum dolor sit amet consectetur </b>
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
        </div>
    );
}

export const SliderMovieLite = (props) => {
    console.log(props)
    let imgs = {
        position: 'relative',
        float: 'left',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: 'inherit'
    }

    if (props.Files.length > 0) {


        return <>
            <Carousel interval={null} autop slid className="specscarosel23876487236">
                {props.Files.length > 0 && props.Files.map((elem, index) => {
                    console.log(elem)
                    return <CarouselItem key={keyGen()} >
                        <div className="bg-dark text-light" style={{ height: '450px' }}>
                            <img style={imgs} src={elem.link}></img>
                        </div>
                    </CarouselItem>
                })
                }


            </Carousel></>
    } else {
        return <></>
    }
}