import React, { useState, useEffect, useContext, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { AiFillCamera, AiOutlinePlusCircle } from 'react-icons/ai'
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import axios from 'axios'
import { print_state, print_city, state_arr } from '../../../../Utility/stateCity'
import { CatsContext } from '../InformationForms'
import { ConvertContext } from '../InformationForms'
import { EditContext } from '../InformationForms'
import { bloodgrouplist, keyGen } from "../../../../Utility/helper";
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { MenuProps, languages, arrayofdays, arrayofdays2 } from '../../../../Utility/languages'
import MapWithSwitch from "../../../ProMapsv3/MapWithSwitch";
import MobileAddOn from "../mobileAddOn";
import { TimeSelectBox } from "../../../../Utility/timer";
import Modal from 'react-bootstrap/Modal';
import { StateCity } from "../StateCity";
import { FormHelperText } from "@mui/material";
import { MyCarousel } from "../ImageUpload/MyCarousel";
import { ImageUploadComponent } from "../ImageUpload/ImageUploadComponent";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

// in this code there validation in some filed check that before writing validation in future this is the copy of static form till now only some changes
export const Form1 = (props) => {
    const Edit = useContext(EditContext);
    console.log(Edit)
    let x = [...Array(100).keys()]
    const cats = useContext(CatsContext);
    console.log(cats)
    const convert = useContext(ConvertContext);
    const [CONCount, setConCount] = useState([keyGen()])
    const formData = new FormData()
    const [show1, setShow1] = useState(false);
    const [Submit2, setSubmit2] = useState(false);
    const [error2, setError2] = useState("PLEASE ENTERT Age");

    const handleClose = () => setShow1(false);
    const handleShow = () => setShow1(true);
    const [value, setValue] = useState({
        id: '',

        firstname: "",
        lastname: "",
        age: "",
        language: [],
        Description: "",
        EntityName: "",
        gender: "",

        contact: "",
        contact2: "",
        contact3: "",
        contact4: "",
        email: '',
        website: '',
        worktimimng: false,
        category: cats?.value.Category,
        subcategory: cats?.value.SubCategory,
        subsubcategory: cats?.value.SubSubCategory,
        address_door: "",
        address_floor: "",
        address_building: "",
        address_nearest: "",
        address_street: "",
        address: "",
        state: '',
        city: '',
        pincode: '',
        image: '',
        preferredlanguage: "",
        pname: '',
        WomanOwned: false,
        formType: 1,
        countrycode: "",
        statecode: "",
        working_leave: "",
        working_hour_2: "",
        working_hour_1: "",
        working_hour_3: "",
        working_hour_4: "",
        working_days_2: "",
        working_days_1: "",
        working_days_3: "",
        working_days_4: "",
        contactpersonsname: "",
        contactpersonsdesignation: "",
        contactpersonsname2: "",
        contactpersonsdesignation2: "",
        preferredlanguage2: "",
        contactpersonsname3: "",
        contactpersonsdesignation3: "",
        preferredlanguage3: "",
        contactpersonsname4: "",
        contactpersonsdesignation4: "",
        preferredlanguage4: "",
        licence: "",
        gst: "",
        workingdaysfullnew: [],
        workingdayshalfnew: [],
        workingdaysOffnew: [],
        break_time_2: '',
        break_time_1: '',
        services_Provided:"",
        service_Charges:"",
        blood_Group:"",
        formType:cats?.value.FormType

    })

    useEffect(() => {
        console.log(cats?.subsubcategory?.length)
        setValue({ ...value, subcategory: cats?.value.SubCategory, category: cats?.value.Category, subsubcategory: cats?.value.SubSubCategory, Id: cats?.value.Id,formType:cats?.value.FormType })
        return () => {

        };
    }, [cats])
    const [error, setError] = useState('')
    const [Submit, setSubmit] = useState(false)
    const [image, setImage] = useState();
    const [cropData, setCropData] = useState(false);
    const [cropper, setCropper] = useState();
    const [Stateload, setStateload] = useState(true)
    const [cat, setCat] = useState([])
    const [category, setCategory] = useState(false)
    const [subcategory, setSubcategory] = useState(false);
    function Cityshow(e) {
        var index = document.getElementById(e.target.id).selectedIndex
        print_city('City', index)
        setValue({ ...value, state: e.target.value })
    }
    const onChange = async (e) => {
        var myModal = new bootstrap.Modal(document.getElementById('profileUpdation'))
        myModal.show()
        var myModalEl = document.getElementById('profileUpdation')
        myModalEl.addEventListener('shown.bs.modal', function (event) {
            cropData ? setCropData(false) : <></>
            e.preventDefault();
            let files;
            if (e.dataTransfer) {
                files = e.dataTransfer.files;
            } else if (e.target) {
                files = e.target.files;
            }
            if (files[0]) {
                const reader = new FileReader();
                reader.onload = () => {
                    setImage(reader.result);
                };
                reader.readAsDataURL(files[0]);
            }
        })

        var x = document.getElementById('profileUpdation').offsetWidth
        console.log(x)
        // var s=document.getElementById('profileUpdation')
    };

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            // setCropData(cropper.getCroppedCanvas().toDataURL());
            setCropData()
            cropper.getCroppedCanvas().toBlob((blob) => {
                console.log(blob)
                console.log(URL.createObjectURL(blob))
                setCropData(URL.createObjectURL(blob))
                setnewb(blob)
            }, 'image/jpeg', 0.5)
        }
    };
    useEffect(() => {
        setValue({ ...value, image: cropData })
    }, [cropData])
    const uploadme = () => {
        var DP = document.getElementById('DP')
        DP.src = cropData
    }
    const photo = () => (
        document.getElementById('image').click()
    )
    function validation() {

        if (cats?.value.SubSubCategory?.length > 0) {
            if (value.subsubcategory === undefined) {
                return setError('PLEASE SELECT A BRAND')
            }
            console.log(value)
            if (value.subsubcategory.length === 0) {
                return setError('PLEASE SELECT A BRAND')
            }
        }

        if (value.category.length > 0) {
            setError("");
        } else {
            return setError("PLEASE ENTER Category");
        }
        if (value.subcategory.length > 0) {
            setError("");
        } else {
            return setError("PLEASE ENTER Sub Category");
        }

        if (!Edit) {

            if (cropData) {
                var imgsize = cropData.length - 22;
                var imgsizeinkb =
                    (4 * Math.ceil(imgsize / 3) * 0.5624896334383812) / 1000;
                console.log(imgsize);
                console.log(imgsizeinkb);
                if (cropData.length > 0) {
                    setError();
                } else {
                    return setError("Photo size is greater than 2MB");
                }
            } 
            // else {
            //      return setError("PLEASE upload PROFILE DISPLAY PICTURE");
            //  }

        }

        if (value.pname.length === 0) {
            return setError("PLEASE ENTER PROFILE DISPLAY NAME")
        }
        else {
            setError("");
        }

        // if (value.firstname.length > 0) {
        //     setError("");
        // } else {
        //     return setError("PLEASE ENTER First Name");
        // }
        // console.log(value.lastname.length)
        // if (value.lastname.length > 0) {
        //     setError("");
        // } else {
        //     return setError("PLEASE ENTER Last Name");

        // }
        // if (value.gender.length > 0) {
        //     setError("");
        // } else {
        //     return setError("PLEASE ENTER gender");
        // }

        // if (value.age.length > 0) {
        //     if (value.age > 17) {
        //         setError("");
        //     } else {
        //         return setError("Sorry ! You should be atleast 18 years of age");
        //     }
        // } else {
        //     return setError("PLEASE ENTER Your Age");
        // }





        // if (value.language.length > 0) {
        //     setError("");
        // } else {
        //     return setError("PLEASE ENTER Languages Known");
        // }




        if (!value.state) {
            return setError('PLEASE ENTER state')
        }
        if (!value.city) {
            return setError('PLEASE ENTER city')
        }
        if (value.pincode.length > 0) {
            setError("");
        } else {
            return setError("PLEASE ENTER Pin code / Zip code ");
        }
        // if (!value.worktimimng) {
        //     if (value.workingdaysfullnew.length > 0) {
        //         setError("");
        //     } else {
        //         return setError("PLEASE ENTER Working Days (full)");
        //     }

        //     if (value.working_hour_1.length > 0) {
        //         setError("");
        //     } else {
        //         return setError("PLEASE ENTER Working Hours");
        //     }

        //     if (value.working_hour_2.length > 0) {
        //         setError("");
        //     } else {
        //         return setError("PLEASE ENTER Working Hours");
        //     }
        //     if (value.break_time_1.length > 0) {
        //         setError("");
        //     } else {
        //         return setError("PLEASE ENTER Break Time");
        //     }
        //     if (value.workingdayshalfnew.length > 0) {
        //         setError("");
        //     } else {
        //         return setError("PLEASE ENTER Working Days (half)");
        //     }

        //     if (value.working_hour_3.length > 0) {
        //         setError("");
        //     } else {
        //         return setError("PLEASE ENTER Working Hours");
        //     }

        //     if (value.working_hour_4.length > 0) {
        //         setError("");
        //     } else {
        //         return setError("PLEASE ENTER Working Hours");
        //     }
        // }

        // if (value.workingdaysOffnew.length > 0) {
        //     setError("");
        // } else {
        //     return setError("PLEASE ENTER Weekly Off");
        // }

        // if (value.countrycode.length > 0) {
        //     setError("");
        // } else {
        //     return setError("PLEASE SELECT Country Code");
        // }
        // if (value.statecode.length > 0) {
        //     setError("");
        // } else {
        //     return setError("PLEASE SELECT State Code");
        // }

        // for (const element of CON) {
        //     if (CON.indexOf(element) === 0) {
        //         if (element.contact2.length < 6 || element.contact2.length > 12) {
        //             return setError('PLEASE ENTER VALID A Mobile Number / Landline Number')
        //         }
        //         if (element.contactpersonsname2.length == 0) {
        //             return setError(`PLEASE ENTER Contact Person’s Name`)
        //         }
        //         if (element.contactpersonsdesignation2.length == 0) {
        //             return setError(`PLEASE ENTER Contact Person’s Designation`)
        //         }
        //         if (element.preferredlanguage2.length == 0) {
        //             return setError(`PLEASE SELECT A Most Comfortable / Preferred Language for Communication for ${element.contact2}`)
        //         }
        //     }
        // }
        // if (value.preferredlanguage.length > 0) {
        //     setError("");
        // } else {
        //     return setError("PLEASE SELECT Most Comfortable / Preferred Language for Communication");
        // }

        return true
    }

    useEffect(() => {
        if (Stateload)
            print_state("State")
        setStateload(false)
    }, [])
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const [newb, setnewb] = useState()
    let navigate = useNavigate();
    const submit = async (e) => {
        e.preventDefault();
        formData.delete('file')
        formData.delete('data')
        let loc = false
        if (Location.lng) {
            loc = true
        }
        let metadata = {
            type: 'image/jpeg'
        };
        if (newb) {
            let file = new File([newb], "avatar.jpg", metadata);
            console.log(file)
            formData.append("file", file);
        }
        let filePrec = []
        for (let index = 0; index < Files.length; index++) {
            if (Files[index]?.file) {
                console.log(Files)
                let file = new File([Files[index].file], `ExtraImageIndex${index}${Date.now()}.jpg`, metadata);
                console.log(file)
                formData.append("file", file)
            } else {
                let temp = Files[index]
                delete temp.link
                filePrec = [...filePrec,temp ]
            }
        }
        var dataSend = JSON.stringify({ value, Location, LocationOn: loc, CON,filePrec })

        formData.append("data", dataSend);
        var status = false
        setSubmit(true)
        status = validation()
        if (status == true) {
            setloading(true)
            // let a = await props.postTo(formData)
            // if (!a) {
            //     setloading(false)
            // }

            if (!Edit) {

                axios.post('/api/Admin/InformationFormType1',formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }).then((res)=>{
                    if (res.status==200) {
                        console.log("ok")
                        setloading(false)
                        notify("Information Add Successfully",true)
                        navigate(`/information`)
                        // 
                    }
                }).catch((err)=>{
                    console.log(err)
                    console.log("error")
                    notify("Something went wrong",false)

                    setloading(false)
                })
            }else{
                axios.post('/api/Admin/InformationFormType1Update',formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }).then((res)=>{
                    if (res.status==200) {
                        console.log("ok")
                        setloading(false)
                        notify("Information Updated Successfully",true)

                        if (!cats?.value.SubSubCategory) {
                            navigate(`/Information_view_for_edit?k=${cats?.value.SubCategory}`)
                        }else{
                            navigate(`/Information_view_for_edit?k=${cats?.value.SubSubCategory}`)
                        }
                    }
                }).catch((err)=>{
                    console.log(err)
                    console.log("error")
                    setloading(false)
                    notify("Something went wrong",false)

                })
            }



        
        }
    }
    useEffect(() => {
        if (Submit) {
            validation()
        }
    }, [value])

    const [CON, setCON] = useState([])

    const handleCON = (e, index) => {
        try {
            console.log({ e, index }, { CON });
            let CONcollection = CON
            if (e === 'remove') {
                CONcollection.splice(index, 1)
                let dsicount = CONCount.slice()
                let c = dsicount.splice(index, 1)
                setConCount(dsicount)
            } else {
                CONcollection[index] = e
            }
            setCON(CONcollection)
        } catch (error) {
            console.log(error)
        }
    }



    useEffect(() => {
        axios
            .get("/api/api/getprocategory")
            .then((res) => {
                if (res.status === 200) {
                    // console.log("workd");
                    setCat(res.data);
                }
            })
            .catch((err) => {
                console.log(err.response.status);
            });
    }, []);

    var sab = [];
    useEffect(() => {
        if (cat.length >= 1) {
            cat.forEach((element) => {
                sab.push(<option value={element._id}>{element.category}</option>);
            });
            setCategory(sab);
        } else {
            console.log("nothing");
        }
    }, [cat]);



    const [Location, setLocation] = useState({ lat: '', lng: '' })
    const [LocationOn, setLocationOn] = useState()

    useEffect(() => {
        if (Edit) {

            let arr1 = []
            let arr2 = []
            let arr3 = []

            if (Edit.workingdaysfullnew) {
                if (typeof (Edit.workingdaysfullnew) === 'string') {
                    arr1 = [...arr1, Edit.workingdaysfullnew]
                } else {
                    arr1 = [...Edit.workingdaysfullnew]
                }
            }

            if (Edit.workingdayshalfnew) {
                if (typeof (Edit.workingdayshalfnew) === 'string') {
                    arr2 = [...arr2, Edit.workingdayshalfnew]
                } else {
                    arr2 = [...Edit.workingdayshalfnew]
                }
            }

            if (Edit.workingdaysOffnew) {
                if (typeof (Edit.workingdaysOffnew) === 'string') {
                    arr3 = [...arr3, Edit.workingdaysOffnew]
                } else {
                    arr3 = [...Edit.workingdaysOffnew]
                }
            }

            setCON(Edit.CON ? Edit.CON : [])

            setValue({
                ...value,
                id: Edit._id,
                pname: Edit.pname ? Edit.pname : '',
                worktimimng: Edit.worktimimng ? Edit.worktimimng : false,
                firstname: Edit.firstname ? Edit.firstname : "",
                lastname: Edit.lastname ? Edit.lastname : "",
                age: Edit.age ? Edit.age : "",
                language: Edit.language ? Edit.language : "",
                Description: Edit.Description ? Edit.Description : "",
                EntityName: Edit.EntityName ? Edit.EntityName : "",
                gender: Edit.gender ? Edit.gender : "",

                email: Edit.email,
                address_door: Edit.address_door ? Edit.address_door : '',
                address_floor: Edit.address_floor ? Edit.address_floor : '',
                address_building: Edit.address_building ? Edit.address_building : '',
                address_nearest: Edit.address_nearest ? Edit.address_nearest : '',
                address_street: Edit.address_street ? Edit.address_street : '',
                address: Edit.address ? Edit.address : '',
                state: Edit.state,
                city: Edit.city,
                pincode: Edit.pincode ? Edit.pincode : '',
                image: Edit.image,
                pname: Edit.pname,
                break_time_2: Edit.break_time_2 ? Edit.break_time_2 : '',
                break_time_1: Edit.break_time_1 ? Edit.break_time_1 : '',
                working_leave: Edit.working_leave ? Edit.working_leave : '',
                working_hour_2: Edit.working_hour_2 ? Edit.working_hour_2 : '',
                working_hour_1: Edit.working_hour_1 ? Edit.working_hour_1 : '',
                working_days_2: Edit.working_days_2 ? Edit.working_days_2 : '',
                working_days_1: Edit.working_days_1 ? Edit.working_days_1 : '',
                working_hour_3: Edit.working_hour_3 ? Edit.working_hour_3 : '',
                working_hour_4: Edit.working_hour_4 ? Edit.working_hour_4 : '',
                working_days_3: Edit.working_days_3 ? Edit.working_days_3 : '',
                working_days_4: Edit.working_days_4 ? Edit.working_days_4 : '',
                website: Edit.website,
                licence: Edit.licence ? Edit.licence : '',
                gst: Edit.gst ? Edit.gst : '',
                CON: Edit.CON ? Edit.CON : [],
                countrycode: Edit.countrycode ? Edit.countrycode : '',
                statecode: Edit.statecode ? Edit.statecode : '',
                workingdaysfullnew: arr1,
                workingdayshalfnew: arr2,
                workingdaysOffnew: arr3,
                contact: Edit.contact ? Edit.contact : '',
                contactpersonsname: Edit.contactpersonsname ? Edit.contactpersonsname : '',
                contactpersonsdesignation: Edit.contactpersonsdesignation ? Edit.contactpersonsdesignation : '',
                preferredlanguage: Edit.preferredlanguage ? Edit.preferredlanguage : '',

                contact2: Edit.contact2 ? Edit.contact2 : '',
                contactpersonsname2: Edit.contactpersonsname2 ? Edit.contactpersonsname2 : '',
                contactpersonsdesignation2: Edit.contactpersonsdesignation2 ? Edit.contactpersonsdesignation2 : '',
                preferredlanguage2: Edit.preferredlanguage2 ? Edit.preferredlanguage2 : '',

                contact3: Edit.contact3 ? Edit.contact3 : '',
                contactpersonsname3: Edit.contactpersonsname3 ? Edit.contactpersonsname3 : '',
                contactpersonsdesignation3: Edit.contactpersonsdesignation3 ? Edit.contactpersonsdesignation3 : '',
                preferredlanguage3: Edit.preferredlanguage3 ? Edit.preferredlanguage3 : '',

                contact4: Edit.contact4 ? Edit.contact4 : '',
                contactpersonsname4: Edit.contactpersonsname4 ? Edit.contactpersonsname4 : '',
                contactpersonsdesignation4: Edit.contactpersonsdesignation4 ? Edit.contactpersonsdesignation4 : '',
                preferredlanguage4: Edit.preferredlanguage4 ? Edit.preferredlanguage4 : '',

                
                services_Provided:Edit.services_Provided ? Edit.services_Provided : '',
                service_Charges:Edit.service_Charges ? Edit.service_Charges : '',
                blood_Group:Edit.blood_Group ? Edit.blood_Group : '',



            })
            if (Edit.state.length >= 1) {


                var index = state_arr.indexOf(Edit.state)
                // var index = 2
                print_city('City', index + 1)
                // console.log(index)


            }
            let com = []
            if (Edit.CON) {
                Edit.CON.forEach(element => {
                    com = [...com, keyGen()]
                });
                setConCount(com)
            }

        }

        return () => {

        }
    }, [])

    useEffect(() => {
        console.log(Location)

        return () => {

        }
    }, [Location])

    const [loading, setloading] = useState(false)



    var bucketName='https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/Information'
    // var bucketName = 'https://s3.ap-south-1.amazonaws.com/prochure.in.profile/Information'

    const isOptionDisabled = (name, value) => {
        // Add your condition to disable multiple options here
        // console.log(name)
        if (value.length > 0) {
            if (value.indexOf('Nil') >= 0) {
                return name === 'Monday' || name === 'Tuesday' || name === "Wednesday" || name === "Thursday" || name === "Friday" || name === "Saturday" || name === "Sunday";
            } else {
                return name === 'Nil'
            }
        }

    };
    const [Files, setFiles] = useState([])
    const FileDelete = (data, index) => {
        let temp = [...Files];
        temp.splice(index, 1)
        setFiles(temp);
      }
    useEffect(() => {
         if (Edit) {
            setFiles([...Edit.image]) 
         }
    }, [Edit])
    return (
        <div className="container rounded bg-white  pb-5">
            <form onSubmit={(e) => submit(e)}
                novalidate
                className={Submit2 ? 'was-validated' : ''}
            >
                <div className="modal fade" id="profileUpdation" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title" id="exampleModalLabel">PROFILE DISPLAY PICTURE </h6>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div id="bod" className="modal-body ">
                                <div>
                                    <div className="d-flex justify-content-center">
                                        {cropData ? <div className="box" style={{ width: "50%", float: "right" }}>
                                            <img style={{ width: "100%" }} src={cropData} alt="cropped" />
                                            {/* <button className="btn btn-success mt-2" style={{ float: "right" }}  >Save</button> */}
                                        </div>
                                            : <div style={{ width: "100%" }}>
                                                <Cropper
                                                    style={{ height: 400, width: "100%" }}
                                                    zoomTo={false}
                                                    aspectRatio={45 / 45}
                                                    preview=".img-preview"
                                                    src={image}
                                                    viewMode={1}
                                                    minCropBoxHeight={10}
                                                    minCropBoxWidth={10}
                                                    background={false}
                                                    responsive={true}
                                                    autoCropArea={0}
                                                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                                    onInitialized={(instance) => {
                                                        setCropper(instance);
                                                    }}
                                                    guides={true}
                                                />
                                                {/* <button className="btn btn-info text-light mt-2" style={{ float: "right" }} onClick={getCropData}>Ok</button> */}
                                            </div>

                                        }
                                    </div>
                                    {/* <button className="btn btn-info text-light" style={{ float: "right" }}  onClick={getCropData}>Ok</button> */}


                                    {/* <button onClick={() => uploadme()}>Start upload</button> */}

                                </div>
                            </div>
                            <div className="modal-footer">

                                {/* <button onClick={() => setcp(true)}>doit</button> */}
                                {!cropData ?
                                    <button type="button" className="btn pro-spaces-button3" onClick={getCropData}>Ok</button>
                                    :
                                    <button type="button" className="btn pro-spaces-button3" onClick={() => uploadme()} data-bs-dismiss="modal">Save</button>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3 border-right ">
                        <div className="d-flex flex-column align-items-center text-center pb-5 ps-0 pe-0 pt-5 ">
                            <img id="DP" className=" mt-5" width="150px" src={`${bucketName}/${value.id}/avatar.jpg`} onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =
                                    "/blank.png";
                            }} />
                            <div id="idtestingprofilepicvalidation" className="upload">
                                <input
                                    // required={!Edit}
                                    type="file"
                                    accept="image/*"
                                    onChange={onChange}
                                    id="image"
                                    className="form-control"
                                />
                                <label for="image"><AiFillCamera size={"40px"} /></label>
                                <div class="invalid-feedback" style={{ 'backgroundColor': 'white' }}>
                                    PLEASE upload PROFILE DISPLAY PICTURE

                                </div>
                            </div>
                            <span className="text-50 mt-3 up" onClick={() => photo()}>PROFILE DISPLAY PICTURE</span><span> </span>
                        </div>
                    </div>

                    <div className="col-md-9 border-right">
                        <div className="pb-5 ps-0 pe-0 pt-5">
                            
                            
                        <MyCarousel  data={Files} onChange={(e) => {
                              
                            }} titleEnabled={false} FileDelete={FileDelete} />
                            <ImageUploadComponent onChange={(e) => {
                                setFiles([...Files,  { title: '', file:e}])
                            }} />


                            <h6 className="pt-3" >PROFILE DISPLAY NAME *</h6>

                            <div className="row mt-2">
                                <div className="col-12">
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        placeholder="Individual / Entity / Company Name"
                                        value={value.pname}
                                        onChange={(e) =>
                                            setValue({ ...value, pname: e.target.value })
                                        }
                                    />
                                    <div class="invalid-feedback">
                                        PLEASE ENTER PROFILE DISPLAY NAME
                                    </div>
                                </div>
                            </div>


                            <h6 className="mt-4">Personal Information <p style={{ 'fontWeight': '400', 'fontSize': '15px' }}>(of the Product Retailer / Proprietor)</p></h6>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <label className="labels">First Name </label>
                                    <input
                                        // required
                                        type="text"
                                        className="form-control"
                                        value={value.firstname}
                                        onChange={(e) =>
                                            setValue({ ...value, firstname: e.target.value })
                                        }
                                    />
                                    {/* <div class="invalid-feedback">
                                        PLEASE ENTER First Name
                                    </div> */}
                                </div>
                                <div className="col-12">
                                    <label className="labels">Last Name </label>
                                    <input
                                        // required
                                        type="text"
                                        className="form-control"
                                        value={value.lastname}
                                        onChange={(e) =>
                                            setValue({ ...value, lastname: e.target.value })
                                        }
                                    />
                                    {/* <div class="invalid-feedback">
                                        PLEASE ENTER Last Name
                                    </div> */}
                                </div>

                                <div className="col-12">
                                    <label className="labels">Gender </label>
                                    <select
                                        // required
                                        className="form-select"
                                        value={value.gender}
                                        onChange={(e) =>
                                            setValue({ ...value, gender: e.target.value })
                                        }
                                    >
                                        <option value="" disabled selected></option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Transgender">Transgender</option>
                                    </select>
                                    {/* <div class="invalid-feedback">
                                        PLEASE SELECT Gender
                                    </div> */}
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">
                                        Age 
                                    </label>
                                    <input
                                        // required
                                        type="tel"
                                        maxLength="3"
                                        className="form-control"
                                        value={value.age}
                                        onChange={(e) => {
                                            const age = parseInt(e.target.value);
                                            if (age < 18) {
                                                // If age is not a number or less than 18, set an error message
                                                e.target.setCustomValidity("Age must be at least 18 years");
                                                setError2("Age must be at least 18 years")
                                            } else {
                                                // If age is valid, clear the error message
                                                e.target.setCustomValidity("");
                                            }
                                            setValue({ ...value, age: e.target.value });
                                        }}
                                    />
                                    {/* {error2 && <div className="invalid-feedback">{error2}</div>} */}
                                </div>

                                <div className="col-12">
                                    <label className="labels">Blood Group</label>
                                    <select
                                        // required
                                        className="form-select"
                                        value={value.blood_Group}
                                        onChange={(e) =>
                                            setValue({ ...value, blood_Group: e.target.value })
                                        }
                                    >
                                        <option value="" disabled selected></option>
                                        {
                                            bloodgrouplist.map((e)=>{
                                                return <option value={e}>{e}</option>
                                            })
                                        }
                                    </select>
                                    {/* <div class="invalid-feedback">
                                        PLEASE SELECT Gender
                                    </div> */}
                                </div>
                                <div className="col-md-12">
                                    {/* working */}
                                    <label className="labels">Languages Known </label>

                                    <FormControl className="form-control">
                                        <Select id="demo-multiple-checkbox" multiple
                                        //  required 
                                        // error={Submit2 ? value?.language?.length != 0 ? false : true : false}
                                         className="form-select" sx={{ '& .MuiOutlinedInput-notchedOutline': { display: "none" }, '& .MuiSvgIcon-root': { display: 'none', }, '& .MuiSelect-select': { padding: '0' } }}
                                            value={value.language} onChange={(e) => setValue({ ...value, language: e.target.value })} input={<OutlinedInput label="Tag" />} renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps}
                                        >
                                            {languages.map((languages) => (
                                                <MenuItem key={languages} value={languages}>
                                                    <Checkbox checked={value.language.indexOf(languages) > -1} />
                                                    <ListItemText primary={languages} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {/* <FormHelperText className="mx-0" style={{ marginTop: '.25rem', fontSize: '.875rem', color: '#dc3545' }}>{Submit2 ? value?.language?.length != 0 ? false : 'PLEASE SELECT Languages Known' : false}</FormHelperText> */}
                                    </FormControl>

                                </div>
        
                            </div>

                            <h6 className="mt-4"> Information / Description</h6>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <label className="labels">Services Provided</label>
                                    <textarea
                                        className="form-control"
                                        value={value.services_Provided}
                                        onChange={(e) =>
                                            setValue({ ...value, services_Provided: e.target.value })
                                        }
                                    ></textarea>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-12">
                                    <label className="labels">Service Charges</label>
                                    <textarea
                                        className="form-control"
                                        value={value.service_Charges}
                                        onChange={(e) =>
                                            setValue({ ...value, service_Charges: e.target.value })
                                        }
                                    ></textarea>
                                </div>
                            </div>


                            <>
                                <h3 className="labels mt-3">Address </h3>

                                <div className="col-md-12">
                                    <label className="labels">Building / Mall / Property Name </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={value.address_building}
                                        onChange={(e) =>
                                            setValue({ ...value, address_building: e.target.value })
                                        }
                                    />
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Door / Shop No.</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={value.address_door}
                                        onChange={(e) =>
                                            setValue({ ...value, address_door: e.target.value })
                                        }
                                    />
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Floor</label>
                                    <select
                                        className="form-select"
                                        value={value.address_floor}
                                        onChange={(e) =>
                                            setValue({ ...value, address_floor: e.target.value })
                                        }
                                    >
                                        <option value="" disabled selected></option>
                                        <option>Basement</option>
                                        <option>Ground</option>
                                        <option>Mezzanine</option>
                                        {x.map((elem, index) => {
                                            let temp = index + 1
                                            if (index < 10) temp += 10
                                            if (index + 1 <= 10 || index + 1 >= 20)
                                                if (temp % 10 === 1) return <option>{index + 1}st</option>
                                                else if (temp % 10 === 2) return <option>{index + 1}nd</option>
                                                else if (temp % 10 === 3) return <option>{index + 1}rd</option>
                                                else return <option>{index + 1}th</option>
                                            else return <option>{index + 1}th</option>
                                        })}
                                    </select>
                                </div>


                                <div className="col-md-12">
                                    <label className="labels">Street / Lane / Road Name </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={value.address_street}
                                        onChange={(e) =>
                                            setValue({ ...value, address_street: e.target.value })
                                        }
                                    />
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Area / Locality Name </label>
                                    <input type="text" className="form-control" value={value.address} onChange={(e) => setValue({ ...value, address: e.target.value })} />
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Nearest Landmark</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={value.address_nearest}
                                        onChange={(e) =>
                                            setValue({ ...value, address_nearest: e.target.value })
                                        }
                                    />
                                </div>
                                <StateCity setValue={setValue} value={value} Edit={Edit} />

                                <div className="col-md-12">
                                    <label className="labels">Pin code / Zip code *</label>
                                    <input
                                        // required
                                        type="tel"

                                        className="form-control"
                                        value={value.pincode}
                                        onChange={(e) => {
                                            setValue({ ...value, pincode: e.target.value });
                                        }}
                                    />
                                    <div class="invalid-feedback">
                                        PLEASE ENTER Pin code / Zip code
                                    </div>
                                </div>

                                <div className="col-md-12 py-3">
                                    <MapWithSwitch Location={Location} setLocation={setLocation} LocationOn={LocationOn} setLocationOn={setLocationOn} key={"aouhduahdiuqbduiqderhetjyvq65564"} />
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Do you have a Trade Licence ? </label>
                                    <select
                                        // required
                                        className="form-select"
                                        value={value.licence}
                                        onChange={(e) =>
                                            setValue({ ...value, licence: e.target.value })
                                        }
                                    >
                                        <option value="" disabled selected></option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                        <option value="N/A">N/A</option>
                                    </select>
                                    {/* <div class="invalid-feedback">
                                        PLEASE ENTER Do you have a Trade Licence ?
                                    </div> */}
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Is your entity GST registered ? </label>
                                    <select
                                        // required
                                        className="form-select"
                                        value={value.gst}
                                        onChange={(e) => setValue({ ...value, gst: e.target.value })}
                                    >
                                        <option value="" disabled selected></option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                    {/* <div class="invalid-feedback">
                                        PLEASE ENTER Is your entity GST registered ?
                                    </div> */}
                                </div>
                                <label className="labels mt-3">Work Timings </label>
                                <div className="col-12 pt-1">
                                    <div className="form-check">
                                        <input
                                            onChange={(e) =>
                                                setValue({
                                                    ...value,
                                                    worktimimng: !value.worktimimng,
                                                })
                                            }
                                            className="form-check-input"
                                            type="checkbox"
                                            id="Enterprsdffise"
                                            checked={value.worktimimng}
                                        />
                                        <label className="labels" for="Enterprsdffise">
                                            We are open 24 hours
                                        </label>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <label className="labels">Working Days (full) 
                                    {/* {value.worktimimng ? '' : '*'} */}
                                    </label>
                                    <FormControl className="form-control">
                                        <Select id="demo-multiple-checkbox" multiple 
                                        // required={Submit2 ? value?.worktimimng === true ? false : value.workingdaysfullnew.length > 0 ? false : true : false} error={Submit2 ? value?.worktimimng === true ? false : value.workingdaysfullnew.length > 0 ? false : true : false} 
                                        className="form-select" sx={{ '& .MuiOutlinedInput-notchedOutline': { display: "none" }, '& .MuiSvgIcon-root': { display: 'none', }, '& .MuiSelect-select': { padding: '0' } }}
                                            value={value.workingdaysfullnew} onChange={(e) => setValue({ ...value, workingdaysfullnew: e.target.value })} input={<OutlinedInput label="Tag" />} renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps}
                                        >
                                            {arrayofdays.map((arrayofdays) => (
                                                <MenuItem key={arrayofdays} value={arrayofdays}
                                                    disabled={isOptionDisabled(arrayofdays, value.workingdaysfullnew)}
                                                >
                                                    <Checkbox checked={value.workingdaysfullnew.indexOf(arrayofdays) > -1} />
                                                    <ListItemText primary={arrayofdays} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {/* <FormHelperText className="mx-0" style={{ marginTop: '.25rem', fontSize: '.875rem', color: '#dc3545' }}>{Submit2 ? value?.worktimimng === true ? false : value.workingdaysfullnew.length > 0 ? false : 'PLEASE SELECT Working Days (full)' : false}</FormHelperText> */}
                                    </FormControl>
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Working Hours 
                                    {/* {value.worktimimng ? '' : '*'} */}
                                    </label>
                                    <div className="row">
                                        <div className="col-5">
                                            <select
                                                // required={Submit2 ? value?.worktimimng === true ? false : true : false}
                                                className="form-select"
                                                value={value.working_hour_1}
                                                onChange={(e) =>
                                                    setValue({ ...value, working_hour_1: e.target.value })
                                                }
                                            >
                                                <TimeSelectBox />


                                            </select>
                                            <div class="invalid-feedback">
                                                PLEASE SELECT Working Hours
                                            </div>
                                        </div>
                                        <div className="col-2 d-flex justify-content-center pt-2 p-0">to</div>
                                        <div className="col-5">
                                            <select
                                                // required={Submit2 ? value?.worktimimng === true ? false : true : false}
                                                className="form-select"
                                                value={value.working_hour_2}
                                                onChange={(e) =>
                                                    setValue({ ...value, working_hour_2: e.target.value })
                                                }
                                            >
                                                <TimeSelectBox />

                                            </select>
                                            {/* <div class="invalid-feedback">
                                                PLEASE SELECT Working Hours
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Break Time 
                                    {/* {value.worktimimng ? '' : '*'} */}
                                    </label>
                                    <div className="row">
                                        <div className="col-5">
                                            <select
                                                // required={Submit2 ? value?.worktimimng === true ? false : true : false}
                                                className="form-select"
                                                value={value.break_time_1}
                                                onChange={(e) =>
                                                    setValue({ ...value, break_time_1: e.target.value })
                                                }
                                            >
                                                <TimeSelectBox type={'break'} />


                                            </select>
                                            {/* <div class="invalid-feedback">
                                                PLEASE SELECT Break Time
                                            </div> */}
                                        </div>
                                        <div className="col-2 d-flex justify-content-center pt-2 p-0">to</div>
                                        <div className="col-5">
                                            <select
                                                // required={Submit2 ? value?.worktimimng === true ? false : true : false}
                                                className="form-select"
                                                value={value.break_time_2}
                                                onChange={(e) =>
                                                    setValue({ ...value, break_time_2: e.target.value })
                                                }
                                            >
                                                <TimeSelectBox type={'break'} />


                                            </select>
                                            {/* <div class="invalid-feedback">
                                                PLEASE SELECT Break Time
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Working Days (half) 
                                    {/* {value.worktimimng ? '' : '*'} */}
                                    </label>
                                    <FormControl className="form-control">
                                        <Select id="demo-multiple-checkbox" multiple
                                        //  required={Submit2 ? value?.worktimimng === true ? false : value.workingdayshalfnew.length > 0 ? false : true : false} error={Submit2 ? value?.worktimimng === true ? false : value.workingdayshalfnew.length > 0 ? false : true : false} 
                                        className="form-select" sx={{ '& .MuiOutlinedInput-notchedOutline': { display: "none" }, '& .MuiSvgIcon-root': { display: 'none', }, '& .MuiSelect-select': { padding: '0' } }}
                                            value={value.workingdayshalfnew} onChange={(e) => setValue({ ...value, workingdayshalfnew: e.target.value })} input={<OutlinedInput label="Tag" />} renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps}
                                        >
                                            {arrayofdays2.map((arrayofdays2) => (
                                                <MenuItem key={arrayofdays2} value={arrayofdays2}
                                                    disabled={isOptionDisabled(arrayofdays2, value.workingdayshalfnew)}
                                                >
                                                    <Checkbox checked={value.workingdayshalfnew.indexOf(arrayofdays2) > -1} />
                                                    <ListItemText primary={arrayofdays2} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {/* <FormHelperText className="mx-0" style={{ marginTop: '.25rem', fontSize: '.875rem', color: '#dc3545' }}>{Submit2 ? value?.worktimimng === true ? false : value.workingdayshalfnew.length > 0 ? false : 'PLEASE SELECT Working Days (half)' : false}</FormHelperText> */}
                                    </FormControl>
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Working Hours
                                     {/* {value.worktimimng ? '' : '*'} */}
                                     </label>
                                    <div className="row">
                                        <div className="col-5">
                                            <select
                                                // required={Submit2 ? value?.worktimimng === true ? false : true : false}
                                                className="form-select"
                                                value={value.working_hour_3}
                                                onChange={(e) =>
                                                    setValue({ ...value, working_hour_3: e.target.value })
                                                }
                                            >
                                                <TimeSelectBox />


                                            </select>
                                            {/* <div class="invalid-feedback">
                                                PLEASE SELECT Working Hours
                                            </div> */}
                                        </div>
                                        <div className="col-2 d-flex justify-content-center pt-2 p-0">to</div>
                                        <div className="col-5">
                                            <select
                                                // required={Submit2 ? value?.worktimimng === true ? false : true : false}
                                                className="form-select"
                                                value={value.working_hour_4}
                                                onChange={(e) =>
                                                    setValue({ ...value, working_hour_4: e.target.value })
                                                }
                                            >
                                                <TimeSelectBox />

                                            </select>
                                            {/* <div class="invalid-feedback">
                                                PLEASE SELECT Working Hours
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label className="labels">Weekly Off </label>
                                    <FormControl className="form-control">
                                        <Select id="demo-multiple-checkbox" multiple 
                                        // required error={Submit2 ? value?.workingdaysOffnew?.length != 0 ? false : true : false} 
                                        className="form-select" sx={{ '& .MuiOutlinedInput-notchedOutline': { display: "none" }, '& .MuiSvgIcon-root': { display: 'none', }, '& .MuiSelect-select': { padding: '0' } }}
                                            value={value.workingdaysOffnew} onChange={(e) => setValue({ ...value, workingdaysOffnew: e.target.value })} input={<OutlinedInput label="Tag" />} renderValue={(selected) => selected.join(', ')} MenuProps={MenuProps}
                                        >
                                            {arrayofdays2.map((arrayofdays2) => (
                                                <MenuItem key={arrayofdays2} value={arrayofdays2}
                                                    disabled={isOptionDisabled(arrayofdays2, value.workingdaysOffnew)}
                                                >
                                                    <Checkbox checked={value.workingdaysOffnew.indexOf(arrayofdays2) > -1} />
                                                    <ListItemText primary={arrayofdays2} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {/* <FormHelperText className="mx-0" style={{ marginTop: '.25rem', fontSize: '.875rem', color: '#dc3545' }}>{Submit2 ? value?.workingdaysOffnew?.length != 0 ? false : 'PLEASE SELECT Weekly Off' : false}</FormHelperText> */}
                                    </FormControl>
                                </div>
                            </>
                            <h6 className="mt-4">Contact Information</h6>
                            <div className="mt-3">
                                <div className="col-md-12">
                                    <label className="labels">Email Id</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        value={value.email} onChange={(e) =>
                                            setValue({ ...value, email: e.target.value })
                                        }
                                    />

                                </div>
                                <div className="col-md-12"><label className="labels">Country Code </label>
                                    <select
                                        // required
                                        className="form-select"
                                        value={value.countrycode}
                                        onChange={(e) =>
                                            setValue({ ...value, countrycode: e.target.value })
                                        }
                                    >
                                        <option value="" disabled selected></option>
                                        <option value="+91">+91</option>

                                    </select>
                                    {/* <div class="invalid-feedback">
                                        PLEASE SELECT Country Code
                                    </div> */}
                                </div>
                                <div className="col-md-12"><label className="labels">State Code </label><input type="number"
                                //  required
                                 className="form-control" value={value.statecode} onChange={(e) => setValue({ ...value, statecode: e.target.value })} />
                                    {/* <div class="invalid-feedback">
                                        PLEASE ENTER State Code
                                    </div> */}
                                </div>
                                <>
                                    {CONCount.map((dsi, index) => {
                                        return <MobileAddOn languages={languages} key={dsi} id={dsi} con={value.CON} handleCON={handleCON} index={index} type="2"></MobileAddOn>
                                    })}
                                    <button style={{ fontSize: '12px' }} disabled={CONCount.length >= 5} className="btn pro-spaces-button my-3" type="button" onClick={(e) => setConCount([...CONCount, keyGen()])} >ADD ANOTHER MOBILE / LANDLINE NUMBER </button>
                                </>
                                <Modal show={show1} onHide={handleClose} centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title></Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        To Add Additional Branches of your Service / Business Entity, you must UPGRADE to a Basic or Prime membership
                                    </Modal.Body>
                                </Modal>

                            </div>
                        </div>
                    </div>
                </div>
                <h6 className='text-center text-danger' >{error ? error : ''}</h6>
                <div className="mt-2 text-center">
                    {Edit &&
                        <button type="button" className="btn mb-2 mt-4 me-2 btn-secondary" onClick={(e) => window.history.back()}>Discard</button>
                    }
                    {!loading &&
                        <button className="btn mb-2 mt-4" style={{ 'backgroundColor': '#55374a', 'color': 'white' }}
                            onClick={() => setSubmit2(true)
                                // just for testing something not required !!!!
                            }
                        >
                            {Edit ? 'Update' : 'Create'}
                        </button>
                    }
                    {loading &&
                        <button className="btn  mb-2 mt-4" disabled style={{ 'backgroundColor': '#55374a', 'color': 'white' }}>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

                        </button>
                    }
                </div>
            </form>
        </div>
    )
}
