import React from 'react'
import { Link } from "react-router-dom";
export default function Non_registered_members() {
  return (
    <div>
        <div className=' p-2 border '>

        <div className="container ">
      <div className="row ">
        <div className="col-lg-6 col-md-6 col-sm-12">

        <Link to="/registered_members"  className="wgfiuwrgiu">

          <div className="card carrrrbus">
            
            
              <div className="padunnor"><center>Registered&nbsp;Member<br>

              </br> </center></div>

          </div>
          </Link>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">

        <Link to="/profile_created_members"  className="wgfiuwrgiu">

          <div className="card carrrrbus">
          
           
              <div className="padunnor"><center>Profile&nbsp;Created&nbsp;Members<br>
              
              </br></center></div>
            
          </div>
          </Link>
        </div>

      </div>
   
  </div>

        </div>
    </div>
  )
}
