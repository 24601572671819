import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { FaRupeeSign } from 'react-icons/fa';
import { Footer } from '../../../Pages/HomePage/Footer';
export default function Sub_admin_executive_details_page(props) {
  useEffect(() => {
    load();
    props.Auth1();
  }, []);
  const [data, setData] = useState([]);
  const load = () => {
    axios.get('/api/Admin/myself_created_accounts').then((res) => {
      if (res.data) {
        console.log(res.data);
        const d = res.data.filter((item) => item !== null);
        setData(d);
      }
    });
  };
  var columns = [
    {
      field: 'id',
      headerName: 'No. ',
      width: 60,
    },
    {
      field: 'pname',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 200,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 200,
    },
    {
      field: 'created_date',
      headerName: 'Created on',
      width: 200,
    },
    {
      field: 'Account_holder_account_subscription_type',
      headerName: 'Membership Type',
      width: 200,
    },
    {
      field: 'Subscription_fee_1',
      headerName: (
        <>
          Subscription Fee (<FaRupeeSign />)
        </>
      ),
      width: 200,
    },
    {
      field: 'CouponName_1',
      headerName: 'Coupon Applied',
      width: 200,
    },
    {
      field: 'DiscountAmount_1',
      headerName: (
        <>
          Discount Amount (<FaRupeeSign />)
        </>
      ),
      width: 200,
    },
    {
      field: 'Commission_percentage',
      headerName: 'Commission Percentage (%)',
      width: 250,
    },
    {
      field: 'Commission_amount_1',
      headerName: (
        <>
          Commission Amount (<FaRupeeSign />)
        </>
      ),
      width: 200,
    },
    {
      field: 'Admin_payment_settled_Status',
      headerName: 'Payment Status',
      width: 200,
      cellStyle: (params) => {
        console.log(params.value);
        return params.value === 'Settled'
          ? {
              color: 'green',
            }
          : {
              color: 'yellow',
            };
      },
    },
    {
      field: 'Payment_transaction_id',
      headerName: 'Payment Transaction Id',
      width: 200,
    },
    {
      field: 'Payment_Dates',
      headerName: 'Payment Date',
      width: 200,
    },
  ];

  return (
    <>
      <div className=" bg-light ">
        <div className="mt-2">
          <Box sx={{ height: '85vh', width: '100%' }}>
            <DataGrid
              rows={data}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              // checkboxSelection
              // disableRowSelectionOnClick
            />
          </Box>
        </div>
      </div>
      <div style={{ position: 'absolute', width: '100%' }}>
        <Footer />
      </div>
    </>
  );
}
