import React, {
    useState,
    useEffect,
    useContext
  } from 'react'
  import Map from './Maps'
  import { keyGen } from '../../Utility/helper'
  import Modal from 'react-bootstrap/Modal'
  import { EditContext } from '../CreateProductV3/FormProduct'
  let count = 0
  
  let done = false
  export default function MapWithSwitch (props) {
    const Edit = useContext(EditContext)
    const [AddingLocation, setAddingLocation] = useState(false)
    const getLocation = bool => {
      console.log(AddingLocation)
      // if (!navigator.geolocation) {
      //     setStatus('Geolocation is not supported by your browser');
      //     alert('Geolocation is not supported by your browser')
      // } else {
      //     setStatus('Locating...');
      //     navigator.geolocation.getCurrentPosition((position) => {
      //         // alert('Geolocati')
      //         setkey(keyGen())
      //         setStatus(null);
  
      //         setlocation({ lat: position.coords.latitude, lng: position.coords.longitude, status: true })
      //         console.log(position, position.coords.latitude)
      //         handleShow()
      //     }, () => {
      //         setStatus('Unable to retrieve your location');
      //         alert('Unable to retrieve your location')
  
      //     });
      // }
      handleShow()
    }
    const [location, setlocation] = useState({
      lat: false,
      lng: false,
      status: false
    })
    // useEffect(() => {
    //     if (location.lat) {
    //         setAddingLocation(!AddingLocation)
    //     }
    //     return () => {
    //     }
    // }, [location])
    let infos = ''
  
    const [status, setStatus] = useState(false)
    const [mapPosition, setmapPosition] = useState({
      lng: '',
      lat: ''
    })
    const handleClose = () => {
      setAddingLocation(false)
      setTimeout(() => {
        setlocation(infos)
        props.setLocation(infos)
      }, 400)
    }
    const handleCloseOnly = () => {
      console.log('closeonly')
      setAddingLocation(false)
    }
    const handleShow = () => setAddingLocation(true)
    useEffect(() => {
      console.log(infos)
      return () => {}
    }, [AddingLocation])
  
    const [key, setkey] = useState(keyGen())
    // useEffect(() => {
    //     // props.setLocationOn(status)
    //     console.log(count)
    //     if (Edit) {
    //         if (count <= 1) {
    //             count += 1
    //             return
    //         } else {
    //             if (status) {
    //                 console.log(count)
    //                 handleShow()
    //             }
    //         }
    //     } else {
  
    //         if (status) {
    //             handleShow()
    //         }
    //     }
  
    // }, [status])
  
    useEffect(() => {
      console.log(props)
      // console.log(Edit)
  
      if (!done) {
        if (Edit) {
          console.log(props)
          if (Edit[0].Location_Coordinates) {
            setlocation(Edit[0].Location_Coordinates)
            setStatus(Edit[0].Location)
            done = true
          }
        }
      }
      return () => {}
    }, [props])
  
    const setInfo = e => {
      console.log(e)
      infos = e
    }
  
    useEffect(() => {
      return () => {
        done = false
      }
    }, [])
  
    const doit = () => {
      handleShow()
    }
    const handleRemove = () => {
      setAddingLocation(false)
        setTimeout(() => {
            infos = ''
            setlocation({
                lat: false,
                lng: false,
                status: false
              })
            props.setLocation({
                lat: false,
                lng: false,
                status: false
              })
  
        }, 400);
    }
    useEffect(() => {
      console.log(AddingLocation)
  
      return () => {}
    }, [AddingLocation])
  
    return (
      <div>
       <button
                  type='button'
                  className='btn pro-spaces-button mt-2'
                  onClick={e => {
                    doit()
                  }}
                >
                  GPS LOCATION
                </button>
        <Modal
          show={AddingLocation}
          onHide={handleCloseOnly}
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Choose a Location</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ height: '400px' }} className='mt-3'>
              <Map
                key={key}
                center={location}
                height='300px'
                zoom={10}
                setmapPosition={setInfo}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className='btn pro-spaces-button'
              onClick={() => handleRemove()}
            >
              Remove
            </button>
            <button
              className='btn pro-spaces-button'
              onClick={() => handleClose()}
            >
              Continue
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
  