import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Box from '@mui/material/Box';
import { useSearchParams } from 'react-router-dom';

import { DataGrid } from '@mui/x-data-grid';
import { notify } from '../../Utility/notify';

export const Couponadminame = () => {
  const [coupon, setCoupon] = useState({
    Coupon_Name: '',
    Discount_PandA: '',
    Assigned_On: '',
  });
  const [Submit2, setSubmit2] = useState(false);

  const [show, setShow] = useState(false);
  const [Ids, setIds] = useState({
    delid: '',
    delindex: '',
  });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'Coupon_Name',
      headerName: 'Coupon Name',
      width: 150,
      editable: true,
    },
    {
      field: 'Discount_PandA',
      headerName: 'Discount Percentage/Amount',
      width: 150,
      editable: true,
    },
    {
      field: 'MomentDate',
      headerName: 'Assigned On',
      type: 'number',
      width: 110,
      editable: true,
    },
    {
      field: 'Edit',
      headerName: 'Edit',
      sortable: false,
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <button
          className="btn btn-success"
          onClick={(e) => {
            console.log(params.row);
            setCoupon(params.row);
            // setIndex(ind);
            handleShow1();
          }}
        >
          Edit
        </button>
      ),
    },
    {
      field: 'Delete',
      headerName: 'Delete',
      sortable: false,
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <button
          className="btn btn-danger"
          data-bs-toggle="modal"
          data-bs-target={'#exampleModal'}
          onClick={(e) => {
            setIds({ ...Ids, delid: params.row._id, delindex: params.row.id });
          }}
        >
          Delete
        </button>
      ),
    },
  ];

  useEffect(() => {
    console.log(Ids);
  }, [Ids]);

  const tableStyles = {
    backgroundColor: '#f0f0f0',
  };
  let [searchParams, setSearchParams] = useSearchParams(); /////getting id from the url the id of the jobs id
  var Id = searchParams.get('k');
  const submit = (e) => {
    e.preventDefault();
    axios
      .post('/couponsmeame/couponameinsert', { coupon, Id })
      .then((res) => {
        handleClose();
        notify('Coupon Added Successfully', true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        notify('Something Went Wrong', false);
      });
  };
  const [state, setState] = useState([]);

  useEffect(() => {
    axios
      .post('/couponsmeame/couponamefind', { Id })
      .then((res) => {
        console.log(res.data);
        const newstate = res.data?.map((elem, index) => {
          console.log(elem);
          return {
            ...elem,
            id: index + 1,
          };
        });
        console.log(newstate);
        setState(newstate);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [Submit3, setSubmit3] = useState(false);

  const update = (e) => {
    e.preventDefault();

    axios
      .post('/couponsmeame/couponameupdate', { coupon, Id })
      .then((res) => {
        handleClose1();
        notify('Updated Successfully', true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        notify('Something Went Wrong', false);
      });
  };

  const Delete = (ind) => {
    console.log(ind);
    var deleid = Ids.delid;
    var deleindex = Ids.delindex;

    axios
      .post('/couponsmeame/couponamedelete', { deleid, deleindex, Id })
      .then((res) => {
        console.log(res.data);
        notify('Successfully Deleted', true);
        document.getElementById('btnclose').click();

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        notify('Something went Wrong', false);
      });
  };

  useEffect(() => {
    console.log(state);
  }, [state]);

  return (
    <>
      <div className="">
        <div className="page-breadcrumb ">
          <div className="d-flex justify-content-between align-items-center text-dark py-3 px-2">
            <h4 className="page-title text-dark">COUPON CREATION</h4>
            <button
              type="button"
              className=" btn pro-spaces-button"
              onClick={handleShow}
            >
              Add COUPON
            </button>
          </div>
        </div>

        <div
          style={{
            height: 400,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <DataGrid
            rows={state}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            style={tableStyles}
            className="mt-5 pt-3 sswewe"
          />
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <form
          onSubmit={(e) => submit(e)}
          novalidate
          className={Submit2 ? 'was-validated' : ''}
        >
          <Modal.Body>
            {' '}
            <div className="">
              <label htmlhtmlFor="inputsubject">Coupon Name</label>

              <input
                required
                type="text"
                className="form-control mt-1 text-uppercase"
                style={{ textTransform: 'uppercase' }}
                onChange={(e) => {
                  setCoupon({
                    ...coupon,
                    Coupon_Name: e.target.value.toUpperCase(),
                  });
                }}
              />
              <div class="invalid-feedback">PLEASE ENTER Coupon</div>
            </div>
            <div className="pt-4">
              <label htmlhtmlFor="inputsubject">
                Discount Percentage/Amount
              </label>

              <input
                required
                type="text"
                className="form-control mt-1 text-uppercase"
                style={{ textTransform: 'uppercase' }}
                onChange={(e) => {
                  setCoupon({
                    ...coupon,
                    Discount_PandA: e.target.value,
                  });
                }}
              />
              <div class="invalid-feedback">
                PLEASE ENTER Discount Percentage/Amount
              </div>
            </div>
            <div className="pt-4">
              <label htmlhtmlFor="inputname">Assigned On</label>
              <input
                required
                type="date"
                className="form-control mt-1"
                id="name"
                name="name"
                onChange={(e) => {
                  setCoupon({ ...coupon, Assigned_On: e.target.value });
                }}
              />
              <div class="invalid-feedback">PLEASE SELECT Assigned Date</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="submit"
              className="btn  pro-spaces-button3"
              onClick={(e) => {
                setSubmit2(true);
              }}
            >
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* editmodal */}

      <Modal show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <form
          onSubmit={(e) => update(e)}
          novalidate
          className={Submit3 ? 'was-validated' : ''}
        >
          <Modal.Body>
            {' '}
            <div className="">
              <label htmlhtmlFor="inputsubject">Coupon Name</label>

              <input
                required
                type="text"
                className="form-control mt-1 text-uppercase"
                style={{ textTransform: 'uppercase' }}
                value={coupon?.Coupon_Name}
                onChange={(e) => {
                  setCoupon({
                    ...coupon,
                    Coupon_Name: e.target.value.toUpperCase(),
                  });
                }}
              />
              <div class="invalid-feedback">PLEASE ENTER Coupon</div>
            </div>
            <div className="pt-4">
              <label htmlhtmlFor="inputsubject">
                Discount Percentage/Amount
              </label>

              <input
                type="text"
                value={coupon?.Discount_PandA}
                required
                className="form-control mt-1 text-uppercase"
                style={{ textTransform: 'uppercase' }}
                onChange={(e) => {
                  setCoupon({
                    ...coupon,
                    Discount_PandA: e.target.value,
                  });
                }}
              />
              <div class="invalid-feedback">
                PLEASE ENTER Discount Percentage/Amount
              </div>
            </div>
            <div className="pt-4">
              <label htmlhtmlFor="inputname">Assigned On</label>
              <input
                type="date"
                required
                value={
                  coupon?.Assigned_On ? coupon.Assigned_On.substring(0, 10) : ''
                }
                className="form-control mt-1"
                id="name"
                name="name"
                onChange={(e) => {
                  setCoupon({ ...coupon, Assigned_On: e.target.value });
                }}
              />

              <div class="invalid-feedback">PLEASE SELECT Assigned Date</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="submit"
              className="btn  pro-spaces-button3"
              onClick={(e) => {
                setSubmit3(true);
              }}
            >
              Update
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* editmodal */}

      {/* deletemodal */}

      <div
        class="modal fade"
        id={'exampleModal'}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                id={'btnclose'}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body text-danger">
              Are you sure you want to delete ?
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={(e) => {
                  Delete(state.id);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* deletemodal */}
    </>
  );
};
