import React from 'react';
import { Link } from 'react-router-dom';

export const Updatetermsandcondition = () => {
  return (
    <>
      <div style={{ height: '100%' }} className="bg-light container">
        <div className="row slhishieo3jfiehfjefbewf">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <Link to="/fsetermsandcondition" className="wgfiuwrgiu">
              <div className="card carrrrbus">
                <div className="padunnor">
                  <center>
                    FSE (Commission) Terms And Conditions <br></br>{' '}
                  </center>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <Link to="/smetermsandcondition" className="wgfiuwrgiu">
              <div className="card carrrrbus">
                <div className="padunnor">
                  <center>
                    DCE (Salary) Terms And Conditions <br></br>
                  </center>
                </div>
              </div>
            </Link>
          </div>
          {/* <div className="col-lg-6 col-md-6 col-sm-12">
            <Link to="/csmtermsandcondition" className="wgfiuwrgiu">
              <div className="card carrrrbus">
                <div className="padunnor">
                  <center>
                    CSM Terms And Conditions <br></br>
                  </center>
                </div>
              </div>
            </Link>
          </div> */}
          {/* <div className="col-lg-6 col-md-6 col-sm-12">
                <Link to="/Passive" className="wgfiuwrgiu">
                    <div className="card carrrrbus">
                        <div className="padunnor"><center>Passive<br>
                        </br></center></div>
                    </div>
                </Link>
            </div> */}
        </div>
      </div>
    </>
  );
};
