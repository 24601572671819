import React, { useState, useEffect,createContext
 } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { print_state, print_city, } from '../../../../../Utility/stateCity'
import validator from 'validator'
import MapWithSwitch from "../../../../AdminMap/MapWithSwitch";
export let EditContext = createContext()


export default function Fuel_create() {
    const [Stateload, setStateload] = useState(true);
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState(false);
    const notify = (message, sab) => {
      if (sab) {
        toast.success(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.warning(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };



    const [value, setValue] = useState({
          state: "",
          city: "",
          Petrol:null,
          Diesel:null,
          CNG:null,
          Cooking_Gas:null,
          Kerosene:null,

          
      });
      const [Location, setLocation] = useState({lat:'',lng:''})
      const [LocationOn, setLocationOn] = useState(false)

    
      function Cityshow(e) {
        var index = document.getElementById(e.target.id).selectedIndex;
        print_city("City", index);
        setValue({ ...value, state: e.target.value });
      }
    
  
      useEffect(() => {
        if (Stateload) print_state("State");
        setStateload(false);
      }, []);


      const dasapppan= async(e)=>{

        if (value.state.length === 0) return seterror("State is missing !");
        if (value.city.length === 0) return seterror("City is missing !");
  

    

     
   
        setloading(true)
        var data={
          value,
          Location,
          // LocationOn:Location_status
        }
        let sab = await  axios.post('/api/Admin/Fuel_Reg',{
          data
      }).then((res)=>{
        if(res.status==200){
          notify("Successfully added", true);
         setValue({
          state: "",
          city: "",
          Petrol:"",
          Diesel:"",
          CNG:"",
          Cooking_Gas:"",
          Kerosene:"",
         })
         setLocation({lat:'',lng:''})
         setLocationOn()
         seterror('')

          
        }
      }).catch((err)=>{
        console.log(err)
        notify(" Something went wrong", false);
         seterror('')
         setLocationOn()

      })
      setloading(false)
          
      }

  return (
  

    <div className="container pt-5 mt-3">
    <h1>Fuel</h1>     
    <div className='row'>
        <div className='col'> <label className="labels">State *</label></div><div className='col'> <select
    className="form-select"
    value={value.state}
    id="State"
    name="City"
    onChange={(e) => Cityshow(e)}
  >
    {/* <select onChange={(e) =>}  className="form-select" >
                        </select> */}
  </select></div>
    </div><br />
    <div className='row'>
        <div className='col'> <label className="labels">City *</label></div><div className='col'>
              <select
                className="form-select"
                name="City"
                value={value.city}
                id="City"
                onChange={(e) =>
                  setValue({ ...value, city: e.target.value })
                }
              >
                <option>Select City</option>
              </select></div>
    </div><br />
    <div className='row'>
        <div className='col'><label className="labels">Petrol (Rs/L) </label></div><div className='col'><input type="number" className="form-control"   value={value.Petrol}
                onChange={(e) =>
                  setValue({ ...value, Petrol: e.target.value })
                }></input></div>
    </div><br />
    <div className='row'>
        <div className='col'>  <label className="labels">Diesel (Rs/L) </label></div><div className='col'>
        <input type="number"  value={value.Diesel} className="form-control"
                onChange={(e) =>
                 {if(e.target.value.length==6) return false; setValue({ ...value, Diesel: e.target.value })}
                }></input></div>
    </div><br />
    <div className='row'>
        <div className='col'>  <label className="labels">CNG (Rs/L)</label></div><div className='col'>
        <input type="number"  value={value.CNG} className="form-control"
                onChange={(e) =>
                 {if(e.target.value.length==6) return false; setValue({ ...value, CNG: e.target.value })}
                }></input></div>
    </div><br />
    <div className='row'>
        <div className='col'>  <label className="labels">COOKING GAS (Rs/L)</label></div><div className='col'>
        <input type="number"  value={value.Cooking_Gas} className="form-control"
                onChange={(e) =>
                 {if(e.target.value.length==6) return false; setValue({ ...value, Cooking_Gas: e.target.value })}
                }></input></div>
    </div><br />
    <div className='row'>
        <div className='col'>  <label className="labels">Kerosene  (Rs/L)</label></div><div className='col'>
        <input type="number"  value={value.Kerosene} className="form-control"
                onChange={(e) =>
                 {if(e.target.value.length==6) return false; setValue({ ...value, Kerosene: e.target.value })}
                }></input></div>
    </div><br />
    <div className="row">
        <div className="col"><p style={{"color":'red'}}>{error?error:''}</p></div>
        <div className="col">
        {!loading &&   <button className="btn pro-spaces-button3" onClick={(e)=>dasapppan(e)}>Add</button> }
        {loading &&   <button className="btn btn-success" disabled><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button> }

        </div>
    </div>
    <ToastContainer
      position="bottom-left"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
</div>


  )
}
