import React from "react";
import { useQuery } from "react-query";
import { getDceTable, GET_DCE_TABLE_DATA } from "..";
import { useImmer } from "use-immer";

export const useGetDceTAbleQuery = ({ enabled = false,skip=0,limit=25,filters=null }) => {
  const [filter,setFilter] = useImmer({
    skip,
    limit,
    filters
  })
  return {
    query: useQuery([GET_DCE_TABLE_DATA,filter], ()=>getDceTable(filter), {
      enabled,
    }),
    filter,
    setFilter,
  };
};
