import React from 'react'
import { Link } from "react-router-dom";


export default function Service() {
  return (
    <div>
    <div className="container ">
      <div className="row slhishieo3jfiehfjefbewf">

        <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

        <Link to="/emergency_services"  className="wgfiuwrgiu">

          <div className="card carrrrbus">
            
            
              <div className="padunnor"><center>Emergency Services<br>

              </br> </center></div>
            
            
          </div>
          </Link>
        </div>


          <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/entertainment" className="wgfiuwrgiu">

  <div className="card carrrrbus">


    <div className="padunnor"><center>Entertainment<br>

    </br></center></div>


  </div>
</Link>
</div>



          <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

            <Link to="/innovation" className="wgfiuwrgiu">

              <div className="card carrrrbus">


                <div className="padunnor"><center>Innovation<br>

                </br></center></div>


              </div>
            </Link>
          </div>

          <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/activists" className="wgfiuwrgiu">

  <div className="card carrrrbus">


    <div className="padunnor"><center>Activists<br>

    </br></center></div>


  </div>
</Link>
</div>
<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/leisure" className="wgfiuwrgiu">

  <div className="card carrrrbus">


    <div className="padunnor"><center>Leisure<br>

    </br></center></div>


  </div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/utilities" className="wgfiuwrgiu">

  <div className="card carrrrbus">


    <div className="padunnor"><center>Utilities<br>

    </br></center></div>


  </div>
</Link>
</div>

 <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">
<Link to="/prices" className="wgfiuwrgiu">
  <div className="card carrrrbus">
    <div className="padunnor"><center>Prices<br>
    </br></center></div>
  </div>
</Link>
</div>  
<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">
<Link to="/road_blocks" className="wgfiuwrgiu">
  <div className="card carrrrbus">
    <div className="padunnor"><center>Road Blocks<br>
    </br></center></div>
  </div>
</Link>
</div>      


        
        

      </div>
   
  </div>
  </div>
  )
}
