import React,{useState,useEffect} from 'react'
import { AiOutlineCloseCircle } from "react-icons/ai";
import axios from "axios";


export  function GetOfferExternal(props) {

    const [dopper, setDooper] = useState(false);
    const [filedel, setFiledel] = useState([]);
    var Acc=props.Acc


    useEffect(() => {
        getport()
    
      return () => {
        
      }
    }, [])
    

    const getport = async () => {
        var data = await axios
          .post("/api/User/getoffer", {
            Acc,
          })
          .then((res) => {
            setDooper(res.data);
            console.log(res.data);
           
    
            if (res) {
              return (
                <div
                  className="tab-pane about fade show active"
                  id="Portfolio"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                
    
                 
                  {/* portfolio modal ends */}
                </div>
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
        return data;
      };

      
    
      // useEffect(() => {
      //   getport();
      // }, []);
    
      function buds(e, iuy) {
        console.log(iuy);
        var arr = filedel;
        arr.push(iuy);
        setFiledel(arr);
    
        // setValue(...value, File:e.target.value)
      }


  return (
    <div className="row text-dark">
                    {dopper.length > 0 ? (
                      dopper.map((element, ind) => {
                        return (
                          <>
                            {" "}
                            <div className="col-12  col-xl-6 mb-3">
                              <a
                                className="portfolioLink"
                                data-bs-toggle="modal"
                                data-bs-target={"#portfolio" + element._id}
                              >
                                <div className="card">
                                  <img
                                    src={
                                      element.File[0]
                                        ? element.File[0].filename
                                        : '/port.jpeg'
                                    }
                                    className="img-fluid wrighirhgipjpihdfpjhpidfpi"
                                    alt="..."
                                  />
                                  <div className="card-body text-dark">
                                    <center><h5 className="card-title">{element.catalouge_title?element.catalouge_title:''}</h5></center>
                                  </div>
                                </div>
                              </a>
                            </div>
                            <div
                              className="modal fade  seiufgigfiuwegiuowebh"
                              id={"portfolio" + element._id}
                              tabIndex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id="exampleModalLabel"
                                    >{element.catalouge_title?element.catalouge_title:''}</h5>
                                    <button
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    <div className="w-100">
                                      {/* <button
                                          className="btn-danger"
                                          type="button"exampleModa
                                          onClick={(e) => lossupennu(e, ind)}
                                        >
                                          EDIT
                                        </button> */}
                                      {/* <button className="btn-danger">
                                          DELETE
                                        </button> */}
                                      {/* <button
                                          className="btn-danger d-none float-end"
                                          id={"trkorrr" + ind}
                                          onClick={(e) => pyari(e, ind)}
                                        >
                                          CANCEL
                                        </button> */}
                                      {/* <AiFillFileAdd className="uhoihfoisbvoibs d-none float-end"  id={"uhf"+ind} onClick={(e)=>jvur(e,"wnvisnv"+ind)}/>
                    <input className="d-none" id={"wnvisnv"+ind} type="file" onChange={(e)=>setFile(e.target.value)} accept="image/,video/"/> */}
                                    </div>
                                    <div className=" container-fluid p-0">
                                     
                                    
                                      {element.File.length > 0 ? (
                                        <label className="ms-1">
                                         {element.type==='project'?
                                         '':''
                                         }
                                        </label>
                                      ) : (
                                        <></>
                                      )}
                                      <div
                                        id={"carouselExampleControlsprp" + ind}
                                        className="carousel slide"
                                        data-bs-ride="carousel"
                                      >
                                        <div className="carousel-inner">
                                          {element.File
                                            ? element.File.map(
                                                (element1, index) => {
                                                  console.log(index);
                                                  if (index === 0) {
                                                    if (
                                                      "image" ===
                                                      element1.filetype.slice(0, 5)
                                                    ) {
                                                      return (
                                                        <div className="carousel-item active brokendrtff">
                                                          <AiOutlineCloseCircle
                                                            id={
                                                              "trkor" + index + ind
                                                            }
                                                            className="dksjvbsoubvinvinadoivnaibviadvb d-none"
                                                            onClick={(e) =>
                                                              buds(
                                                                e,
                                                                element1.filename
                                                              )
                                                            }
                                                          />
                                                          <img
                                                            alt=""
                                                            className="snblsnlbns"
                                                            src={element1.filename}
                                                          />
                                                          <p>
                                                            {element.titles[
                                                              index
                                                            ] &&
                                                              element.titles[index]
                                                                .title}
                                                          </p>
                                                          <p>
                                                            {element.titleB[index] &&
                                                              element.titleB[index]
                                                                .title}
                                                          </p>
                                                          <p>
                                                            {element.titleC[
                                                              index
                                                            ] &&
                                                              element.titleC[index]
                                                                .title}
                                                          </p>
                                                          
                                                          <p>
                                                            {element.discriptions[
                                                              index
                                                            ] &&
                                                              element.discriptions[
                                                                index
                                                              ].Disc}
                                                          </p>
                                                        </div>
                                                      );
                                                    } else if (
                                                      "video" ===
                                                      element1.filetype.slice(0, 5)
                                                    ) {
                                                      return (
                                                        <div className="carousel-item active">
                                                          <AiOutlineCloseCircle
                                                            id={
                                                              "trkor" + index + ind
                                                            }
                                                            className="dksjvbsoubvinvinadoivnaibviadvb d-none"
                                                            onClick={(e) =>
                                                              buds(
                                                                e,
                                                                element1.filename
                                                              )
                                                            }
                                                          />
                                                          <video
                                                            className="carItem"
                                                            controls
                                                          >
                                                            {" "}
                                                            <source
                                                              src={
                                                                element1.filename
                                                              }
                                                            />{" "}
                                                          </video>
                                                          <p>
                                                            {element.titles[
                                                              index
                                                            ] &&
                                                              element.titles[index]
                                                                .title}
                                                          </p>
                                                          <p>
                                                            {element.titleB[index] &&
                                                              element.titleB[index]
                                                                .title}
                                                          </p>
                                                          <p>
                                                            {element.titleC[
                                                              index
                                                            ] &&
                                                              element.titleC[index]
                                                                .title}
                                                          </p>
                                                          
                                                          <p>
                                                            {element.discriptions[
                                                              index
                                                            ] &&
                                                              element.discriptions[
                                                                index
                                                              ].Disc}
                                                          </p>
                                                        </div>
                                                      );
                                                    }
                                                    else if (
                                                      "audio" ===
                                                      element1.filetype.slice(0, 5)
                                                    ) {
                                                      return (
                                                        <div className="carousel-item active">
                                                          <AiOutlineCloseCircle
                                                            id={
                                                              "trkor" + index + ind
                                                            }
                                                            className="dksjvbsoubvinvinadoivnaibviadvb d-none"
                                                            onClick={(e) =>
                                                              buds(
                                                                e,
                                                                element1.filename
                                                              )
                                                            }
                                                          />
                                                          <audio controls
                                                            className="carItem"
                                                          
                                                          >
                                                            {" "}
                                                            <source
                                                              src={
                                                                element1.filename
                                                              } type='audio/ogg'
                                                            />{" "}
                                                          </audio>
                                                          <p>
                                                            {element.titles[
                                                              index
                                                            ] &&
                                                              element.titles[index]
                                                                .title}
                                                          </p>
                                                          <p>
                                                            {element.titleB[index] &&
                                                              element.titleB[index]
                                                                .title}
                                                          </p>
                                                          <p>
                                                            {element.titleC[
                                                              index
                                                            ] &&
                                                              element.titleC[index]
                                                                .title}
                                                          </p>
                                                          
                                                          <p>
                                                            {element.discriptions[
                                                              index
                                                            ] &&
                                                              element.discriptions[
                                                                index
                                                              ].Disc}
                                                          </p>
                                                        </div>
                                                      );
                                                    }
                                                  } else {
                                                    if (
                                                      "image" ===
                                                      element1.filetype.slice(0, 5)
                                                    ) {
                                                      return (
                                                        <div className="carousel-item brokendrtff">
                                                          <AiOutlineCloseCircle
                                                            id={
                                                              "trkor" + index + ind
                                                            }
                                                            className="dksjvbsoubvinvinadoivnaibviadvb d-none"
                                                            onClick={(e) =>
                                                              buds(
                                                                e,
                                                                element1.filename
                                                              )
                                                            }
                                                          />
                                                          <img
                                                            alt=""
                                                            className="snblsnlbns "
                                                            src={element1.filename}
                                                          />
                                                             <p>
                                                            {element.titles[
                                                              index
                                                            ] &&
                                                              element.titles[index]
                                                                .title}
                                                          </p>
                                                          <p>
                                                            {element.titleB[index] &&
                                                              element.titleB[index]
                                                                .title}
                                                          </p>
                                                          <p>
                                                            {element.titleC[
                                                              index
                                                            ] &&
                                                              element.titleC[index]
                                                                .title}
                                                          </p>
                                                          
                                                          <p>
                                                            {element.discriptions[
                                                              index
                                                            ] &&
                                                              element.discriptions[
                                                                index
                                                              ].Disc}
                                                          </p>
                                                        </div>
                                                      );
                                                    } else if (
                                                      "video" ===
                                                      element1.filetype.slice(0, 5)
                                                    ) {
                                                      return (
                                                        <div className="carousel-item ">
                                                          <AiOutlineCloseCircle
                                                            id={
                                                              "trkor" + index + ind
                                                            }
                                                            className="dksjvbsoubvinvinadoivnaibviadvb d-none"
                                                            onClick={(e) =>
                                                              buds(
                                                                e,
                                                                element1.filename
                                                              )
                                                            }
                                                          />
                                                          <video
                                                            className="carItem"
                                                            controls
                                                          >
                                                            {" "}
                                                            <source
                                                              src={
                                                                element1.filename
                                                              }
                                                            />{" "}
                                                          </video>
                                                          <p>
                                                            {element.titles[
                                                              index
                                                            ] &&
                                                              element.titles[index]
                                                                .title}
                                                          </p>
                                                          <p>
                                                            {element.titleB[index] &&
                                                              element.titleB[index]
                                                                .title}
                                                          </p>
                                                          <p>
                                                            {element.titleC[
                                                              index
                                                            ] &&
                                                              element.titleC[index]
                                                                .title}
                                                          </p>
                                                          
                                                          <p>
                                                            {element.discriptions[
                                                              index
                                                            ] &&
                                                              element.discriptions[
                                                                index
                                                              ].Disc}
                                                          </p>
                                                        </div>
                                                      );
                                                    }
                                                    else if (
                                                      "audio" ===
                                                      element1.filetype.slice(0, 5)
                                                    ) {
                                                      return (
                                                        <div className="carousel-item ">
                                                          <AiOutlineCloseCircle
                                                            id={
                                                              "trkor" + index + ind
                                                            }
                                                            className="dksjvbsoubvinvinadoivnaibviadvb d-none"
                                                            onClick={(e) =>
                                                              buds(
                                                                e,
                                                                element1.filename
                                                              )
                                                            }
                                                          />
                                                          <audio controls
                                                            className="carItem"
                                                          
                                                          >
                                                            {" "}
                                                            <source
                                                              src={
                                                                element1.filename
                                                              } type='audio/ogg'
                                                            />{" "}
                                                          </audio>
                                                          <p>
                                                            {element.titles[
                                                              index
                                                            ] &&
                                                              element.titles[index]
                                                                .title}
                                                          </p>
                                                          <p>
                                                            {element.titleB[index] &&
                                                              element.titleB[index]
                                                                .title}
                                                          </p>
                                                          <p>
                                                            {element.titleC[
                                                              index
                                                            ] &&
                                                              element.titleC[index]
                                                                .title}
                                                          </p>
                                                          
                                                          <p>
                                                            {element.discriptions[
                                                              index
                                                            ] &&
                                                              element.discriptions[
                                                                index
                                                              ].Disc}
                                                          </p>
                                                        </div>
                                                      );
                                                    }
                                                  }
                                                }
                                              )
                                            : ""}
                                        </div>
                                        {element.File ? (
                                          element.File.length > 1 ? (
                                            <>
                                              <button
                                                id="Bbtn"
                                                className="carousel-control-prev ekgdfukweguobwljbvuwgcuonew"
                                                type="button"
                                                data-bs-target={
                                                  "#carouselExampleControlsprp" + ind
                                                }
                                                data-bs-slide="prev"
                                              >
                                                <span
                                                  className="carousel-control-prev-icon dscguwgouvgwouegcljasbcljBG"
                                                  aria-hidden="true"
                                                ></span>
                                                <span className="visually-hidden">
                                                  Previous
                                                </span>
                                              </button>
                                              <button
                                                id="Fbtn"
                                                className="carousel-control-next ekgdfukweguobwljbvuwgcuonew"
                                                type="button"
                                                data-bs-target={
                                                  "#carouselExampleControlsprp" + ind
                                                }
                                                data-bs-slide="next"
                                              >
                                                <span
                                                  className="carousel-control-next-icon dscguwgouvgwouegcljasbcljBG"
                                                  aria-hidden="true"
                                                ></span>
                                                <span className="visually-hidden">
                                                  Next
                                                </span>
                                              </button>
                                            </>
                                          ) : (
                                            <></>
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      <div className='container'>
                                      {element.type==='product'?
                                      <>
                                      {element.product_name?
                                        <div className="row" >
                                        <div className="col-md-6">
                                        <span>Product Name</span>
                                        </div>
                                        <div className="col-md-6 ">
                                        <label>{element.product_name?element.product_name:''}</label>
                                        </div>
                                    </div>
                                        :<></>}
                                         {element.brand?
                                        <div className="row" >
                                        <div className="col-md-6">
                                        <span>Product Brand</span>
                                        </div>
                                        <div className="col-md-6 ">
                                        <label>{element.brand?element.brand:''}</label>
                                        </div>
                                    </div>
                                        :<></>}


                                         {element.product_discription?
                                        <div className="row" >
                                        <div className="col-md-6">
                                        <span>Product Description</span>
                                        </div>
                                        <div className="col-md-6 ">
                                        <label>{element.product_discription?element.product_discription:''}</label>
                                        </div>
                                    </div>
                                        :<></>}


                                      {element.Product_Discriptions.map((elem)=>{
                                         return(<> {elem.product_varint?
                                            <div className="row mt-3" >
                                            <div className="col-md-6">
                                            <span>Product Variant</span>
                                            </div>
                                            <div className="col-md-6 ">
                                            <label>{element.product_varint?element.product_varint:''}</label>
                                            </div>
                                          </div>
                                            :<></>}

                                            <div className="row" >
                                            <div className="col-md-6">
                                            <span>Product Specifications  :</span>
                                            </div>
                                            </div>

                                            {elem.color?
                                            <div className="row" >
                                            <div className="col-md-6">
                                            <span>1.  Colours available </span>
                                            </div>
                                            <div className="col-md-6 ">
                                            <label>{elem.color?elem.color:''}</label>
                                            </div>
                                          </div>
                                            :<></>}

                                            {elem.dimention?
                                            <div className="row" >
                                            <div className="col-md-6">
                                            <span>2. Dimensions / Sizes</span>
                                            </div>
                                            <div className="col-md-6 ">
                                            <label>{elem.dimention?elem.dimention:''}</label>
                                            </div>
                                          </div>
                                            :<></>}
                                            {elem.width?
                                            <div className="row" >
                                            <div className="col-md-6">
                                            <span>Width</span>
                                            </div>
                                            <div className="col-md-6 ">
                                            <label>{elem.width?elem.width:''}</label>
                                            </div>
                                          </div>
                                            :<></>}
                                            {elem.length?
                                            <div className="row" >
                                            <div className="col-md-6">
                                            <span>Length</span>
                                            </div>
                                            <div className="col-md-6 ">
                                            <label>{elem.length?elem.length:''}</label>
                                            </div>
                                          </div>
                                            :<></>}
                                            {elem.depth?
                                            <div className="row" >
                                            <div className="col-md-6">
                                            <span>Depth / Thickness</span>
                                            </div>
                                            <div className="col-md-6 ">
                                            <label>{elem.depth?elem.depth:''}</label>
                                            </div>
                                          </div>
                                            :<></>}
                                            <br />
                                            {elem.product_usp?
                                            <div className="row" >
                                            <div className="col-md-6">
                                            <span>Product USP</span>
                                            </div>
                                            <div className="col-md-6 ">
                                            <label>{elem.product_usp?elem.product_usp:''}</label>
                                            </div>
                                          </div>
                                            :<></>}
                                            {element.mrp?
                                            <div className="row" >
                                            <div className="col-md-6">
                                            <span>MRP</span>
                                            </div>
                                            <div className="col-md-6 ">
                                            <label>{elem.mrp?elem.mrp:''}</label>
                                            </div>
                                          </div>
                                            :<></>}
                                              {elem.selling_price?
                                            <div className="row" >
                                            <div className="col-md-6">
                                            <span>Selling Price</span>
                                            </div>
                                            <div className="col-md-6 ">
                                            <label>{elem.selling_price?elem.selling_price:''}</label>
                                            </div>
                                          </div>
                                            :<></>}
                                            </>)
                                      })}
                                    


                                         
  
                                      </>
                                      :<>
                                    

                                          {element.project_name?
                                        <div className="row" >
                                        <div className="col-md-6">
                                        <span>Project Name </span>
                                        </div>
                                        <div className="col-md-6 ">
                                        <label>{element.project_name?element.project_name:''}</label>
                                        </div>
                                    </div>
                                        :<></>}

                                          {element.state?
                                        <div className="row" >
                                        <div className="col-md-6">
                                        <span>Project Location (State)</span>
                                        </div>
                                        <div className="col-md-6 ">
                                        <label>{element.state?element.state:''}</label>
                                        </div>
                                    </div>
                                        :<></>}

                                          {element.city?
                                        <div className="row" >
                                        <div className="col-md-6">
                                        <span>Project Location (City) </span>
                                        </div>
                                        <div className="col-md-6 ">
                                        <label>{element.city?element.city:''}</label>
                                        </div>
                                    </div>
                                        :<></>}

                                          {element.project_locality?
                                        <div className="row" >
                                        <div className="col-md-6">
                                        <span>Project Location (Locality) </span>
                                        </div>
                                        <div className="col-md-6 ">
                                        <label>{element.project_locality?element.project_locality:''}</label>
                                        </div>
                                    </div>
                                        :<></>}


                                          {element.project_type?
                                        <div className="row" >
                                        <div className="col-md-6">
                                        <span>Project Type</span>
                                        </div>
                                        <div className="col-md-6 ">
                                        <label>{element.project_type?element.project_type:''}</label>
                                        </div>
                                    </div>
                                        :<></>}

                                          {element.number_of_units?
                                        <div className="row" >
                                        <div className="col-md-6">
                                        <span>Total Number of Units </span>
                                        </div>
                                        <div className="col-md-6 ">
                                        <label>{element.number_of_units?element.number_of_units:''}</label>
                                        </div>
                                    </div>
                                        :<></>}
                                          {element.number_of_floor?
                                        <div className="row" >
                                        <div className="col-md-6">
                                        <span>Total Number of Floors</span>
                                        </div>
                                        <div className="col-md-6 ">
                                        <label>{element.number_of_floor?element.number_of_floor:''}</label>
                                        </div>
                                    </div>
                                        :<></>}

                                          {element.Project_Discriptions.map((elm)=>{
                                            return (<>
                                             <hr className='mt-2'/>
                                             {elm.number_of_bedroom?
                                            
                                              <div className="row" >
                                              <div className="col-md-6">
                                              <span>Number of Bedrooms</span>
                                              </div>
                                              <div className="col-md-6 ">
                                              <label>{elm.number_of_bedroom?elm.number_of_bedroom:''}</label>
                                              </div>
                                          </div>
                                              :<></>}
                                                {elm.area?
                                              <div className="row" >
                                              <div className="col-md-6">
                                              <span>Unit Built-up Area </span>
                                              </div>
                                              <div className="col-md-6 ">
                                              <label>{elm.area?elm.area:''}</label>
                                              </div>
                                          </div>
                                              :<></>}
                                              {elm.carpet_area?
                                              <div className="row" >
                                              <div className="col-md-6">
                                              <span>Unit Carpet Area </span>
                                              </div>
                                              <div className="col-md-6 ">
                                              <label>{elm.carpet_area?elm.carpet_area:''}</label>
                                              </div>
                                          </div>
                                              :<></>}
                                            </>)
                                          })}
                                          <hr className='mb-2'/>

                                         



                                        {element.project_discription?
                                        <div className="row" >
                                        <div className="col-md-6">
                                        <span>Project Description </span>
                                        </div>
                                        <div className="col-md-6 ">
                                        <label>{element.project_discription?element.project_discription:''}</label>
                                        </div>
                                    </div>
                                        :<></>}
                                        {element.project_highlights?
                                        <div className="row" >
                                        <div className="col-md-6">
                                        <span>Project Highlights / USP </span>
                                        </div>
                                        <div className="col-md-6 ">
                                        <label>{element.project_highlights?element.project_highlights:''}</label>
                                        </div>
                                    </div>
                                        :<></>}
                                         {element.project_status?
                                        <div className="row" >
                                        <div className="col-md-6">
                                        <span>Project Status </span>
                                        </div>
                                        <div className="col-md-6 ">
                                        <label>{element.project_status?element.project_status:''}</label>
                                        </div>
                                    </div>
                                        :<></>}
                                         {element.rera?
                                        <div className="row" >
                                        <div className="col-md-6">
                                        <span>RERA Compliant</span>
                                        </div>
                                        <div className="col-md-6 ">
                                        <label>{element.rera?element.rera:''}</label>
                                        </div>
                                    </div>
                                        :<></>}
                                         {element.vasstu?
                                        <div className="row" >
                                        <div className="col-md-6">
                                        <span>Vaastu Compliant </span>
                                        </div>
                                        <div className="col-md-6 ">
                                        <label>{element.vasstu?element.vasstu:''}</label>
                                        </div>
                                    </div>
                                        :<></>}
                                         {element.occupancy_avalibility?
                                        <div className="row" >
                                        <div className="col-md-6">
                                        <span>Occupancy Availability </span>
                                        </div>
                                        <div className="col-md-6 ">
                                        <label>{element.occupancy_avalibility?element.occupancy_avalibility:''}</label>
                                        </div>
                                    </div>
                                        :<></>}
                                         {element.occupancy_certificate?
                                        <div className="row" >
                                        <div className="col-md-6">
                                        <span>Occupancy Certificate </span>
                                        </div>
                                        <div className="col-md-6 ">
                                        <label>{element.occupancy_certificate?element.occupancy_certificate:''}</label>
                                        </div>
                                    </div>
                                        :<></>}
                                         {element.association?
                                        <div className="row" >
                                        <div className="col-md-6">
                                        <span>Association Formation </span>
                                        </div>
                                        <div className="col-md-6 ">
                                        <label>{element.association?element.association:''}</label>
                                        </div>
                                    </div>
                                        :<></>}
                                         {element.rate?
                                        <div className="row" >
                                        <div className="col-md-6">
                                        <span>Rate per SQFT / SQCM on Built-up Area </span>
                                        </div>
                                        <div className="col-md-6 ">
                                        <label>{element.rate?element.rate:''}</label>
                                        </div>
                                    </div>
                                        :<></>}
                                         {element.offers?
                                        <div className="row" >
                                        <div className="col-md-6">
                                        <span>Offers</span>
                                        </div>
                                        <div className="col-md-6 ">
                                        <label>{element.offers?element.offers:''}</label>
                                        </div>
                                    </div>
                                        :<></>}
                                         
                                      </>}
                                      </div>
                                    </div>
    
                                    <span>
                                     
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <p>&nbsp;&nbsp;No {props.sab?'Products':'Projects'} added for Offer</p>
                    )}
                    {/* portfolio modal starts */}
                  </div>
  )
}
