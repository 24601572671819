import React, { useEffect, useRef, useState } from 'react';
import { Imageupload } from '../../ManageAdmin/Imageupload';
import { StateCity } from '../../CreateConseltent/StateCity';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';
import { print_state, print_city, state_arr } from '../../../Utility/stateCity';
import { Modal } from 'react-bootstrap';
import axios, { Axios } from 'axios';
import { notify } from '../../../Utility/notify';
import { useNavigate } from 'react-router';
import { IoIosCloseCircleOutline } from 'react-icons/io';

export const Registartionregional = () => {
  let navigate = useNavigate();
  var btnchange = useRef();

  const [img, setimg] = useState('');
  const [showpassword, setshowpassword] = useState(false);
  const [showreferral, setShowreferral] = useState(false);
  const [error, setError] = useState('');
  const [Submit2, setSubmit2] = useState(false);

  const handleClosereferral = () => setShowreferral(false);
  const handleShowreferral = () => setShowreferral(true);
  const [adar, setadar] = useState([]);
  var btnchange = useRef();

  const buttonshow = useRef();

  function Cityshow(e) {
    var index = document.getElementById(e.target.id).selectedIndex;
    print_city('City', index);
    setvaluefield({ ...valuefield, state: e.target.value });
  }

  const [btn, setbtn] = useState(false);
  const [valuefield, setvaluefield] = useState({
    Name: '',
    Email: '',
    Gender: '',
    Age: '',
    Nationality: '',
    Contact: '',
    Password: '',
    ConfirmPassword: '',
    Role: 'REGIONAL MANAGER',
    showPassword: false,
    showConformPassword: false,
    Referral_Code: '',
    Active_status: true,
    Activev2_status: true,
    Activev3_status: true,
    Passive_status: true,
    Service_provide_category_All: true,
    Product_retailer_category_All: true,
    country: '',
    state: '',
    city: '',
    Commission_percentage: '',
    Bank_account_type: '',
    Account_holder_name: '',
    Bank_name: '',
    Account_number: '',
    Ifc_code: '',
    loginID: '',
    Address: '',
    Pincode: '',
    id: '',
    BloodGroup: '',
    FMName: '',
    FMRelationship: '',
    FMContact: '',
    FMAddress: '',
    FMPincode: '',
  });
  const [Stateload, setStateload] = useState(true);
  function validator1(e) {
    e.preventDefault();

    setSubmit2(true);
    const errors = {};
    // if(!value.Name)return setError("Name is missing !");
    // if (!value.Email) {
    //   errors.email = 'Email required !'
    // return setError(errors)
    // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.Email)) {
    //   errors.email = 'Invalid email address !'
    // return setError(errors)
    // }

    // if (!value.Contact) {
    //     return setError('Enter phone number !')
    // }
    // if (valuefield.Contact.toString().length != 10) {
    //     return setError('Enter a valid mobile number !')
    // }
    // if(!value.Commission_percentage)return setError("Discount percentage is missing !");
    if (!valuefield.Address) {
      return setError('');
    }
    if (!valuefield.Pincode) {
      return setError('');
    }

    if (!img) {
      return setError('PLEASE UPLOAD Image');
    }

    if (!valuefield.Password) return setError('');

    //   if (valuefield.Password.toString().length <8) {
    //     return setError('Password must contain 8 characters !')
    // }
    setError('');
    if (
      valuefield.Password.toString() !== valuefield.ConfirmPassword.toString()
    ) {
      return setError("'Password' and 'Confirm Password' must be matching !");
    }

    setError('');
    // &&TermsAndCondition.Terms4

    if (valuefield.Age <= 17) {
      return setError(
        'Sorry....you are too young ! Age must be 18 years and above.'
      );
    }
    go();
  }

  useEffect(() => {
    if (Stateload) print_state('State');
    setStateload(false);
  }, []);

  function go() {
    var formdata = new FormData();
    formdata.append('data', JSON.stringify({ valuefield, valueee }));
    var imag = new File([img], 'avatar.png', { type: 'image/png' });
    {
      adar?.map((elem) => {
        formdata.append('file', elem);
      });
    }
    formdata.append('file', imag);

    axios
      .post('/api/regionalmanager/regionalregistration', formdata)
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          notify('Successfully created', true);
          navigate(`/regionalmanager`);
        }
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }

  const [valueee, setvalueee] = useState({
    Fmstate: '',
    Fmcity: '',
    Fmcountry: '',
  });

  function Cityshow(e) {
    var index = document.getElementById(e.target.id).selectedIndex;
    print_city('City', index);
    setvalueee({ ...valueee, Fmstate: e.target.value });
  }

  const fun = (index) => {
    let temp = [...adar];
    temp.splice(index, 1);
    setadar([...temp]);
  };
  const checkReferral_Code = (e) => {
    console.log(e);
    var Referral_Code = e;
    axios
      .post('/api/regionalmanager/checkReferral_Code', { Referral_Code })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length == 0) {
            notify('This referral code is available for you', true);
          } else {
            notify('This Code already exists', false);
          }
        }
      })
      .catch((err) => {
        notify('Something went wrong', false);
      });
  };
  return (
    <>
      <div className=" rounded bg-white px-3 px-md-5 py-5">
        <h3 className="text-center mt-4 ">
          REGIONAL MANAGER REGISTRATION FORM
        </h3>

        <form
          onSubmit={(e) => validator1(e)}
          novalidate
          className={Submit2 ? 'was-validated' : ''}
        >
          <div className=" pt-3">
            <div className="row">
              <h5 className="mt-4 d-block d-md-none">PERSONAL DETAILS *</h5>
              <div className="col-12  col-md-3 mnmnmnm mmmmm">
                <Imageupload required onChange={(e) => setimg(e)} />
                <span className="mt-3 " style={{ paddingLeft: '15px' }}>
                  Upload your PASSPORT size Photo *
                </span>
                <p className="text-danger">{error} </p>
                <div class="invalid-feedback">
                  PLEASE Upload your PASSPORT size Photo
                </div>
              </div>

              <div className="col-12 col-md-9">
                <div className="row mt-3">
                  <h5 className="mt-4  d-none d-md-block">
                    PERSONAL DETAILS *
                  </h5>
                  <div className="col-md-12">
                    <label className="labels">Full Name *</label>
                    <input
                      placeholder="Enter Full Name"
                      className="form-control"
                      type={'text'}
                      onChange={(e) =>
                        setvaluefield({ ...valuefield, Name: e.target.value })
                      }
                      required
                    ></input>
                    <div class="invalid-feedback">PLEASE ENTER Full Name</div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Gender *</label>
                    <select
                      class="form-control form-select"
                      onChange={(e) =>
                        setvaluefield({ ...valuefield, Gender: e.target.value })
                      }
                      required
                    >
                      <option></option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Transgender">Transgender</option>
                    </select>
                    <div class="invalid-feedback">PLEASE SELECT Gender</div>
                  </div>
                  <div className="col-md-12 ">
                    <label className="labels">Age *</label>
                    <div>
                      <div className="age w-100">
                        <input
                          placeholder="Enter Age"
                          className="form-control  "
                          type={'number'}
                          min="18"
                          pattern="[0-9]{2}"
                          required
                          onChange={(e) => {
                            setvaluefield({
                              ...valuefield,
                              Age: e.target.value,
                            });
                          }}
                          // onBlur={(e) => {
                          //   if (e.target.value <= 17) {
                          //     setvaluefield({ ...valuefield, Age: ' ' });

                          //   }
                          //   else{
                          //     setErr
                          //   }
                          // }}
                        ></input>
                      </div>
                    </div>
                    <div class="invalid-feedback">PLEASE ENTER Age</div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Nationality *</label>
                    <select
                      class="form-control form-select"
                      onChange={(e) => {
                        setvaluefield({
                          ...valuefield,
                          Nationality: e.target.value,
                        });
                      }}
                      required
                    >
                      <option></option>
                      <option value="Indian">Indian</option>
                      <option value="American">American</option>
                      <option value="British">British</option>
                      <option value="German">German</option>
                      <option value="Italian">Italian</option>
                    </select>
                    <div class="invalid-feedback">
                      PLEASE SELECT Nationality
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Blood Group *</label>
                    <input
                      className="form-control"
                      required
                      type={'text'}
                      onChange={(e) => {
                        setvaluefield({
                          ...valuefield,
                          BloodGroup: e.target.value,
                        });
                      }}
                    ></input>
                    <div class="invalid-feedback">PLEASE ENTER Blood Group</div>
                  </div>

                  <StateCity setValue={setvaluefield} value={valuefield} />
                  {/* [{country:value.country,state:value.state,city:value.city}] */}

                  <div className="col-md-12 mt-2 ">
                    <label className="labels">Address *</label>

                    <textarea
                      placeholder="Enter Full Address"
                      className="form-control  "
                      required
                      onChange={(e) => {
                        setvaluefield({
                          ...valuefield,
                          Address: e.target.value,
                        });
                      }}
                    ></textarea>

                    <div class="invalid-feedback">PLEASE ENTER Address</div>
                  </div>
                  <div className="col-md-12 mt-2">
                    <label className="labels">Pin Code *</label>

                    <input
                      placeholder="Enter Pin Code"
                      className="form-control  "
                      type={'text'}
                      maxLength={6}
                      required
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value))
                          setvaluefield({
                            ...valuefield,
                            Pincode: e.target.value,
                          });
                      }}
                    ></input>

                    <div class="invalid-feedback">PLEASE ENTER Pin Code</div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Email Id *</label>
                    <input
                      placeholder="Enter an existing Email Id  "
                      className="form-control "
                      type={'email'}
                      onChange={(e) => {
                        setvaluefield({ ...valuefield, Email: e.target.value });
                      }}
                      required
                    ></input>
                    <div class="invalid-feedback">PLEASE ENTER Email Id</div>
                  </div>

                  <div className="col-md-12">
                    <label className="labels">Contact Number *</label>
                    <input
                      placeholder="Enter Anytime Available Contact Number  "
                      maxLength={10}
                      required
                      className="form-control "
                      type={'text'}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value))
                          setvaluefield({
                            ...valuefield,
                            Contact: e.target.value,
                          });
                      }}
                    ></input>
                    <div class="invalid-feedback">PLEASE ENTER Contact</div>
                  </div>

                  <div className="col-md-12 mt-2">
                    <div className="d-flex justify-content-between">
                      <div className="labels d-flex align-items-center ">
                        Unique Referral Code
                        *&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <IoInformationCircleOutline
                          size={18}
                          onClick={handleShowreferral}
                        />
                      </div>
                    </div>
                    <div className="labels">
                      ( Click on the Information icon to clearly understand what
                      Referral Code is. ) ( Click on the Information icon to
                      clearly understand what Referral Code is. )
                    </div>
                    <input
                      placeholder="Create Unique Referral Code of your wish "
                      className="form-control"
                      type={'text'}
                      onChange={(e) =>
                        setvaluefield({
                          ...valuefield,
                          Referral_Code: e.target.value,
                        })
                      }
                      minLength={5}
                      maxLength={10}
                      onBlur={(e) => {
                        if (e.target.value.length > 3)
                          checkReferral_Code(e.target.value);
                      }}
                      required
                    ></input>
                    <div class="invalid-feedback">
                      PLEASE ENTER Referral Code ( minimum of 5 characters )
                    </div>
                  </div>
                </div>
                <h5 className="mt-4">IDENTITY PROOF *</h5>
                <div className="row mt-2">
                  <label className="labels">
                    Upload both front and back sides of your Aadhaar Card
                  </label>
                  <div className="col-md-12">
                    <div></div>
                    <input
                      hidden
                      ref={btnchange}
                      className="form-control"
                      type="file"
                      id="formFile"
                      accept="image/*"
                      required
                      onChange={(e) => setadar([...adar, ...e.target.files])}
                    />
                    <button
                      id="btnchange"
                      className="btn pro-spaces-button mt-3 mx-2"
                      style={{ backgroundColor: 'rgb(1 32 96)' }}
                      accept="image/*"
                      onClick={(e) => btnchange.current.click()}
                    >
                      Upload your Aadhaar Card *
                    </button>

                    <div className="ps-3 pt-3">
                      <div className="row g-3">
                        {adar.map((ele, index) => {
                          console.log(ele);
                          return (
                            <div className="col-12 col-md-6 col-lg-4">
                              <IoIosCloseCircleOutline
                                className="d-flex float-end"
                                onClick={(e) => fun(index)}
                              />
                              <img
                                src={URL.createObjectURL(ele)}
                                style={{
                                  width: '100%',
                                  height: '200px',
                                  objectFit: 'cover',
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div class="invalid-feedback">
                      PLEASE UPLOAD Aadhaar Card
                    </div>
                  </div>
                </div>
                <h5 className="mt-4">BANK ACCOUNT DETAILS *</h5>

                <div className="row mt-2">
                  <div className="col-md-12">
                    <label className="labels">Account Holder Name *</label>
                    <input
                      type="text"
                      placeholder="Enter Full Name as per bank account "
                      className="form-control"
                      maxLength="150"
                      onChange={(e) => {
                        setvaluefield({
                          ...valuefield,
                          Account_holder_name: e.target.value,
                        });
                      }}
                      required
                    />
                    <div class="invalid-feedback">
                      PLEASE ENTER Bank account holder name
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Bank Name *</label>
                    <input
                      type="text"
                      placeholder="Enter Bank Name "
                      className="form-control"
                      maxLength="40"
                      onChange={(e) => {
                        setvaluefield({
                          ...valuefield,
                          Bank_name: e.target.value,
                        });
                      }}
                      required
                    />
                    <div class="invalid-feedback">PLEASE ENTER Bank name</div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Bank Account Type *</label>

                    <select
                      class="form-control form-select"
                      onChange={(e) => {
                        setvaluefield({
                          ...valuefield,
                          Bank_account_type: e.target.value,
                        });
                      }}
                      required
                    >
                      <option></option>
                      <option value="SB">SB</option>
                      <option value="Current">Current</option>
                    </select>
                    <div class="invalid-feedback">
                      PLEASE SELECT Bank account type
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Account Number *</label>
                    <input
                      type="number"
                      placeholder="Enter Account Number "
                      className="form-control"
                      maxLength="100"
                      onChange={(e) => {
                        setvaluefield({
                          ...valuefield,
                          Account_number: e.target.value,
                        });
                      }}
                      required
                    />
                    <div class="invalid-feedback">
                      PLEASE ENTER Account number
                    </div>
                  </div>

                  <div className="col-md-12">
                    <label className="labels">IFSC code *</label>
                    <input
                      type="text"
                      placeholder="Enter IFSC code "
                      className="form-control"
                      maxLength="40"
                      onChange={(e) => {
                        setvaluefield({
                          ...valuefield,
                          Ifc_code: e.target.value,
                        });
                      }}
                      required
                    />
                    <div class="invalid-feedback">PLEASE ENTER IFSC code</div>
                  </div>
                  {/* 
                  <>
                    <div>
                      <div class="form-check pt-3">
                        <label class="form-check-label" for="flexCheckDefault7">
                          I am aware and agree that the bank account details I
                          have mentioned is correct and wish to have my
                          commission credited to this account.
                        </label>
                      </div>
                    </div>
                    <div>
                      <div class="form-check pt-3">
                        <label class="form-check-label" for="flexCheckDefault9">
                          I am aware and agree that any mistake / error in the
                          bank account details entered is not the responsibility
                          of the platform.
                        </label>
                      </div>
                    </div>
                  </> */}
                  <h5 className="mt-4">
                    CONTACT INFORMATION of a FAMILY MEMBER in case of an
                    Emergency *
                  </h5>
                  <div className="col-md-12 mt-2">
                    <label className="labels">
                      Full Name of Family Member *
                    </label>
                    <input
                      className="form-control"
                      required
                      placeholder="Enter Full Name"
                      type={'text'}
                      onChange={(e) =>
                        setvaluefield({
                          ...valuefield,
                          FMName: e.target.value,
                        })
                      }
                    ></input>
                    <div class="invalid-feedback">
                      PLEASE ENTER Full Name of Family Member
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">
                      Relationship with Family Member *
                    </label>

                    <select
                      class="form-control form-select"
                      required
                      id="exampleFormControlSelect1"
                      onChange={(e) =>
                        setvaluefield({
                          ...valuefield,
                          FMRelationship: e.target.value,
                        })
                      }
                    >
                      <option></option>
                      <option value="Father">Father</option>
                      <option value="Mother">Mother</option>
                      <option value="Brother">Brother</option>
                      <option value="Sister">Sister</option>
                      <option value="Husband">Husband</option>
                      <option value="Wife">Wife</option>
                      <option value="Son">Son</option>
                      <option value="Daughter">Daughter</option>
                    </select>
                    <div class="invalid-feedback">
                      PLEASE SELECT Relationship with Family Member
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">
                      Contact Number of Family Member *
                    </label>
                    <input
                      placeholder="  "
                      maxLength={10}
                      required
                      className="form-control "
                      type={'text'}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value))
                          setvaluefield({
                            ...valuefield,
                            FMContact: e.target.value,
                          });
                      }}
                    ></input>
                    <div class="invalid-feedback">
                      PLEASE ENTER Contact Number of Family Member
                    </div>
                  </div>
                  <div className="col-md-12 mt-2 ">
                    <label className="labels">Address of Family Member *</label>

                    <textarea
                      placeholder="Enter Full Address"
                      className="form-control  "
                      required
                      onChange={(e) => {
                        setvaluefield({
                          ...valuefield,
                          FMAddress: e.target.value,
                        });
                      }}
                    ></textarea>

                    <div class="invalid-feedback">PLEASE ENTER Address</div>
                  </div>
                  {/* country
                  state
                  city */}
                  <div className="col-md-12 mt-2">
                    <StateCity setValue={setvalueee} value={valueee} />
                  </div>
                  <div className="col-md-12 mt-2">
                    <label className="labels">Pin Code *</label>

                    <input
                      placeholder="Enter Pin Code"
                      className="form-control  "
                      type={'text'}
                      maxLength={6}
                      required
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value))
                          setvaluefield({
                            ...valuefield,
                            FMPincode: e.target.value,
                          });
                      }}
                    ></input>
                    <div class="invalid-feedback">PLEASE ENTER Pin Code</div>
                  </div>

                  <h5 className="mt-4">LOGIN CREDENTIALS *</h5>
                  <div className="col-md-12 mt-2">
                    <label className="labels">
                      Login Id <br></br>( Your Email Id is your Default
                      Login Id. )
                    </label>
                    <input
                      className="form-control"
                      type={'text'}
                      value={valuefield.Email}
                    ></input>
                  </div>

                  <>
                    <div className="col-md-12">
                      <label className="labels">Password *</label>
                      <div>
                        <input
                          class="form-control passInout12321"
                          placeholder="Enter Password"
                          aria-describedby="basic-addon2"
                          type={showpassword ? 'text' : 'password'}
                          onChange={(e) => {
                            setvaluefield({
                              ...valuefield,
                              Password: e.target.value,
                            });
                          }}
                          required
                        />
                        &nbsp;
                        <span class="showbutton123321">
                          {showpassword ? (
                            <RiEyeFill
                              onClick={(e) => {
                                setshowpassword(!showpassword);
                              }}
                            />
                          ) : (
                            <RiEyeOffFill
                              onClick={(e) => {
                                setshowpassword(!showpassword);
                              }}
                            />
                          )}
                        </span>
                        <div class="invalid-feedback ">
                          PLEASE ENTER Password
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <label className="labels">Confirm Password *</label>
                      <div>
                        <div>
                          <input
                            class="form-control passInout12321"
                            placeholder="Enter Password again"
                            type={showpassword ? 'text' : 'password'}
                            required
                            onChange={(e) =>
                              setvaluefield({
                                ...valuefield,
                                ConfirmPassword: e.target.value,
                              })
                            }
                          />

                          <span class="showbutton123321" type="button">
                            {' '}
                            {showpassword ? (
                              <RiEyeFill
                                onClick={(e) => {
                                  setshowpassword(!showpassword);
                                }}
                              />
                            ) : (
                              <RiEyeOffFill
                                onClick={(e) => {
                                  setshowpassword(!showpassword);
                                }}
                              />
                            )}
                          </span>
                        </div>
                        <div class="invalid-feedback ">
                          PLEASE ENTER Confirm Password
                        </div>
                      </div>
                    </div>
                  </>

                  {/* <>
                    <div>
                      <h5 className="mt-4">TO BE NOTED :</h5>

                      <ul className="ps-5 mt-2">
                        <li className="pt-2">
                          You can View the Accepted Terms and Conditions from
                          your AME account.
                        </li>

                        <li className="pt-2">
                          {' '}
                          For switching from an AME to a SME, you must contact
                          Admin by sending a mail to vipar.official.hr@gmail.com
                          from your registered email id with Subject Title as
                          ‘PROchure AME to SME TRANSFER’ and clearly mentioning
                          your Unique Referral Code.
                        </li>
                        <li className="pt-2">
                          {' '}
                          For any grievances, send us an email regarding the
                          issue to vipar.official.hr@gmail.com from your
                          registered email id with Subject Title as ‘PROchure
                          AME GRIEVANCE’ and clearly mentioning your Unique
                          Referral Code.
                        </li>
                      </ul>
                    </div>
                    <div class="form-check pt-3 ps-5">
                      <input
                        class="form-check-input affcheckbox"
                        type="checkbox"
                        id="flexCheckDefault1"
                        onChange={(e) => setbtn(!btn)}
                      />
                      <label class="form-check-label">
                        I have read, understood and agree to comply with all the
                        Terms and Conditions.
                      </label>
                    </div>
                  </> */}

                  <div className="col-12 px-1  d-flex justify-content-center pt-3  ">
                    <button
                      className="btn pro-spaces-button3 "
                      // ref={buttonshow}
                      // disabled={!btn}
                      onClick={(e) => validator1(e)}
                    >
                      REGISTER
                    </button>

                    {/* {loading && (
                      <button
                        className="btn pro-spaces-button3"
                        disabled={!btn}
                      >
                        REGISTER &nbsp;
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          /> */}
        </form>

        {/* referralcode modal */}

        <Modal show={showreferral} onHide={handleClosereferral}>
          <Modal.Header centered closeButton></Modal.Header>
          <Modal.Body>
            <div className="footlightfont">
              <div className="pt-3" style={{ lineSpacing: '0' }}>
                • Create a Referral Code of your choice which will be your
                Affiliate Identification at{' '}
                <span
                  className="vipar"
                  style={{
                    fontSize: '22px',
                    color: '#99d050',
                  }}
                >
                  {' '}
                  Vipar
                </span>{' '}
                <sup class="">®</sup>
              </div>
              <div className="pt-3" style={{ lineSpacing: '0' }}>
                • Your Unique Referral Code is the one with which
                <span
                  className="vipar"
                  style={{
                    fontSize: '22px',
                    color: '#99d050',
                  }}
                >
                  {' '}
                  Vipar
                </span>{' '}
                <sup class="">®</sup> can identify that it is via your AME
                account that a Subscription is sold.
              </div>
              <div className="pt-3">
                • It is Mandatory for disbursing on your commission to you.
              </div>
              <div className="pt-3">
                • Once a Referral Code is created, it is permanent and cannot be
                changed.
              </div>

              <div className="pt-3">
                • Can be a combination of Alphabets, Numbers and Symbols.
              </div>
              <div className="pt-3">
                • Minimum Character length &#8239;: 5<br />
                &nbsp;&nbsp;Maximum Character length : 10
              </div>
              <div className="pt-3">
                • In case you forget your Referral Code, it will be visible to
                you on the ‘Account Details’ page of your profile.
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* referralcode modal */}
      </div>
    </>
  );
};
