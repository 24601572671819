import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./Pview.css";
// import { TimeLineExternal } from "../../../Components/Timeline/TimeLineExternal";
import Moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaPhotoVideo, FaVideo } from "react-icons/fa";
import axios from "axios";
import { Link } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";
// import "./Edit";
import {IoIosSend} from 'react-icons/io'
import { useNavigate, useSearchParams } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { GetportExternal } from "./GetportExternal";
import { GetOfferExternal } from "./GetOfferExternal";

import {
  StaticGoogleMap,
  Marker,
  Path,
} from 'react-static-google-map';



function ExternalPview(props) {
  useEffect(() => {
    // props.Auth();
  }, []);
  let [searchParams, setSearchParams] = useSearchParams();
  const [Follower, setFollower] = useState(false);
  let Navigate = useNavigate();
  const [loading, setloading] = useState(false)
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const astyle = {
    'color':'#0a58ca',
    'textDecoration':'underline'
  }

  var Ac = searchParams.get("Ac_id");
  var idd = searchParams.get("Ac_id");
  var Acc = searchParams.get("Ac_id");

  const [rateingfor, setrateingfor] = useState([]);
  useEffect(() => {
    axios
      .post("/api/User/getportfolio", {
        Acc,
      })
      .then((res) => {
        setrateingfor(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  

  const enablechat = () => {
      document.getElementById('closebuttonforreportusgfugssug').click()
      Navigate('/Account')
  }

  const communicate = (e, st) => {
    let id = Ac;
    console.log(id);
    document.getElementById("slr").click();
    let dataSend = {
      id,
      st,
    };
    console.log(id);
    axios
      .post("/api/User/communicate", { dataSend })
      .then((res) => {
        if (res.status === 200) {
          Navigate("/cubicles?consultantTrue=" + res.data);
          console.log(res);
        } else {
          Navigate("/cubicles?consultantTrue=" + res.data);
        }
      })
      .catch((res) => {
        console.log(res.response);
        if (res.response.status === 400) {
          // Navigate('/cubicles?consultantTrue='+id)
        } else if (res.response.status === 401) {
          // TOAST SOMETHING WRONG SAME PERSON
        }
      });
  };

  useEffect(() => {
    let id = Ac;
    axios
      .post("/api/User/selselectcon", {
        id,
      })
      .then((res) => {
        if (res.status === 200) {
          document.getElementById(Ac).checked = true;
          // setFollower(true);
          setmappas(true);
          console.log(res.status);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    FollowOrNot(idd);
  }, []);

  const FollowOrNot = (followme) => {
    try {
      axios
        .post("/api/User/followornot", { followme })
        .then((response) => {
          console.log("success");
          if (response.status === 200) {
            console.log("first");
            setFollower(true);
          }
        })
        .catch((res) => {
          console.log("Something went wrong");
        });
    } catch (er) {}
  };

  const follow = (e, id) => {
    var followme = id;
    axios
      .post("/api/User/follow", { followme })
      .then((response) => {
        console.log("success");
        if (response.status === 200) {
          setFollower(true);
        }
      })
      .catch((res) => {
        console.log("Something went wrong");
      });
  };

  const unfollow = (e, id) => {
    var followme = id;
    axios
      .post("/api/User/unfollow", { followme })
      .then((response) => {
        console.log("success");
        if (response.status === 200) {
          setFollower(false);
        }
      })
      .catch((res) => {
        console.log("Something went wrong");
      });
  };

  const checkaeijfpi = () => {
    var id = Ac;
    if (document.getElementById(id).checked) {
      axios
        .post("/api/User/selectcon", {
          id,
        })
        .then((res) => {
          setmappas(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post("/api/User/disselectcon", {
          id,
        })
        .then((res) => {
          setmappas(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const [mappas, setmappas] = useState();

  const [Image, setImage] = useState(false);
  const [Video, setVideo] = useState([]);
  const [Media, setMedia] = useState([]);
  const [getval, setGetval] = useState(false);
  const [dopper, setDooper] = useState(false);
  const [file, setFile] = useState([]);
  const [filedel, setFiledel] = useState([]);
  const [forume, setforume] = useState();

  const [first, setfirst] = useState(false);
  useEffect(() => {
    var accid = Acc;
    axios
      .post("/api/User/rateingforconCheck", {
        accid,
      })
      .then((res) => {
        console.log(res.data);
        setfirst(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const Clickhandler = (cont) => {
    console.log(cont);
    if (cont == "image") {
      document.getElementById("image").click();
    } else {
      document.getElementById("video").click();
    }
  };

  const addreating = (e, status) => {
    // console.log(id)
    if (e.target.checked) {
      var status = status;
      axios
        .post("/api/User/rateingforcon", {
          status,
          idd,
        })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .delete("/api/User/rateingdelcon", {
          data: {
            status,
            idd,
          },
        })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function keyGen() {
    var length = 5;
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }



  function lossupennu(e, ind) {
    dopper[ind].File.map((element1, index) => {
      document.getElementById("trkor" + index + ind).classList.remove("d-none");
    });

    document.getElementById("trkorrr" + ind).classList.remove("d-none");
    // document.getElementById("uhf"+ind).classList.remove('d-none')
    document.getElementById("gana" + ind).removeAttribute("disabled");
  }

  function pyari(e, ind) {
    dopper[ind].File.map((element1, index) => {
      document.getElementById("trkor" + index + ind).classList.add("d-none");
    });
    document.getElementById("trkorrr" + ind).classList.add("d-none");
    // document.getElementById("uhf"+ind).classList.add('d-none')
    document.getElementById("gana" + ind).setAttribute("disabled", true);
  }

  function cater(e) {
    // console.log(e.target.value)
    setforume(e.target.value);
  }
  useEffect(() => {
    console.log(forume);
  }, [forume]);

  var sab;

  if (getval.experience > 1) {
    sab = "years";
  } else {
    sab = "year";
  }

  var date = Moment(getval.dob).format("DD-MM-YYYY");

  function jvur(e, fr) {
    document.getElementById(fr).click();
  }

  // update port
  // console.log(idd)

  // init
  useEffect(() => {
    axios
      .post("/api/User/ConProfileexterbal", {
        idd,
      })
      .then((res) => {
        setGetval(res.data[0]);
        setEmailRef((prev)=>({...prev, sub:res.data[0].string2[0].sub}))
        console.log(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const [myarr, setMyarr] = useState(false);
  const [age, setage] = useState();


  const notify = (message, stat) => {
    // console.log(message);
    // console.log(stat)

    if (stat == true) {
      // console.log("toasfted");
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (stat == false) {
      toast.error(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [Content, setContent] = useState();

  var init = "";

  function Preview(data) {
    for (var i = 0; i < data.length; i++) {}
  }

    ////////////////////Anantha krishnan S/////////////////
    const [EmailRef, setEmailRef] = useState({
      email:[],
      subject:"",
      massage:"",
      sub:'',
      id:Acc
    })
    const makeEmail = (x, Lorum) => {
      console.log(Lorum)
      console.log(x)
      x = document.getElementById(x)
      x.style.color = 'black'
      var val = x.value
      if (val.charAt(val.length - 1) === ' ') {
          var email = val.trim()
          email = email.replace(/ +/g, "");
  
          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
              x.value = ''
              // return console.log(skill.length+fina)
              x.style.color = 'black'
              addEmail(email)
          }
          else {
              x.style.color = 'red'
          }
      }
      if (Lorum) {
          var email = val.trim()
          email = email.replace(/ +/g, "");
  
          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
              x.value = ''
              // return console.log(skill.length+fina)
              x.style.color = 'black'
              addEmail(email)
          }
          else {
              x.style.color = 'red'
          }
      }
  }
  
  const [Email, setEmail] = useState([])
  const [Arr, setArr] = useState([])
  var listItems = ''
  const [AllEmail, setAllEmail] = useState([])
  
  const addEmail = (vall) => {
      vall = vall.trim()
      var dep = AllEmail
      dep.push(vall)
      setAllEmail(dep)
      var x = React.createElement('div',
          { className: 'p-0 boXe px-2 alert  alert-dismissible fade show m-0 mb-1 me-1', role: 'alert' },
          [React.createElement('strong', {}, vall, <>&nbsp;&nbsp;&nbsp;</>,
              React.createElement('button', { onClick: (e) => removeEmail(vall), type: "button", className: "btn-close ms-5 Gbt p-0 pt-2 ", 'data-bs-dismiss': "alert", 'aria-label': "Close" }, ''),
          ),
          ]
      )
      Arr.push(x)
      listItems = Arr.map((myList) => {
          return <>{myList}</>;
      });
      setEmail(listItems)
  }
  
  useEffect(() => {
      setEmailRef({ ...EmailRef, email: AllEmail })
  }, [AllEmail])
  
  const removeEmail = (e) => {
      var index = AllEmail.indexOf(e)
      AllEmail.splice(index, 1);
      setEmailRef({ ...EmailRef, email: AllEmail })
  }

const [error, seterror] = useState(false)

  const validation=()=>{
    if(EmailRef.email.length>0){
      seterror("")
    }else{
      return seterror('PLEASE ADD a Email Id')
    }
    if(EmailRef.massage.length>0){
      seterror("")
    }else{
      return seterror('PLEASE ENTER Message')
    }
    
    return true
  }


  const [submittrue, setsubmittrue] = useState(false)
  const SendRef=(e)=>{
    setsubmittrue((prev)=>!prev)
    console.log("haii")
    console.log(EmailRef)
    var status = false
    status = validation()
    if(status){
      setloading(true)
    axios.post('/api/User/Refer', {
      EmailRef
    }).then((res) => {
      console.log(res.data)
        //  alert("Refereed successfully !!!!!")
        if(res.status===200){
          document.getElementById('poweclose').click()
          notify("Referred successfully", true);
          setloading(false)
        setEmailRef({
          email:[],
          subject:"",
          massage:"",
          sub:getval.string2[0].sub?getval.string2[0].sub:'',
          id:Acc
        })
        setAllEmail([])
        setEmail([])
        }
         
    }).catch((err) => {
      console.log(err)
      setloading(false)
      document.getElementById('poweclose').click()
      notify("Something went wrong !", false);
  
    })
  }
  }

  useEffect(()=>{
   if(submittrue){
    validation()
   }
  })

  useEffect(() => {
   try {
    if (first) {
      if (first.good) {
        document.getElementById("flexCheckDefault12").checked = true;
      }
      if (first.high) {
        document.getElementById("flexCheckDefault122").checked = true;
      }
     
    }
   } catch (error) {
    console.log(error)
   }
  }, [first]);


  const [referCount, setreferCount] = useState(false)
  const [yeser, setyeser] = useState(false)

  useEffect(() => {
    var ids=Acc
    axios.post('/api/User/ReferCount',{
      ids
    }).then((res)=>{
      if(res.data==='0'|| res.data===0){
        return
       }else if(res.data==='1' || res.data===1){
  
         setreferCount(res.data)
         setyeser('time')
       }
     else{
      setreferCount(res.data)
      setyeser('times')
     }
    }).catch((err)=>{
      console.log(err)
    })
  
    return () => {
      
    }
  }, [])
  console.log(getval.followers)
  
const [first8, setfirst8] = useState(false)

const [sabari, setsabari] = useState('')
const [sabarierr, setsabarierr] = useState('')

const reprtsabari = () => {
 if(sabari.length===0){
  return setsabarierr('Please enter a reason')
 }
 else{
  axios.post('api/User/reportabuse',{
    sabari , Acc
  }).then((res)=>{
    document.getElementById('closebuttonforreport').click()
    notify("Reported", true);
  }).catch((err)=>{
    console.log(err)
    document.getElementById('closebuttonforreport').click()
    notify("Something went wrong !", false);
  })
 }
 
}

const [CommunicationStatus, setCommunicationStatus] = useState('Loading')

useEffect(() => {
  
  axios.post('/api/User/CommunicationStatus',{id:Acc}).then((res)=>{
    console.log(res)
    setCommunicationStatus(res.data.Chat)
  }).catch((err)=>{
    console.log(err)
  })

  console.log(CommunicationStatus)
  return () => {
    
  }
}, [])


  return (
    <>
      {/* <NavBar /> */}
      <div>
        <div className="container emp-profile border ">

          {/* whgiowhroighsoihlznvlsnd */}
          <div className="row">
         <div className="d-none d-md-block mb-3 mt-2">
         <div className=" row justify-content-center  ">
         {getval.WomanOwned && 
              <div className="col-12 col-md-auto"><img width="30" height={30} src="/wpower.png" alt='w' />&nbsp;WOMAN ENTREPRENEUR</div>}
                 {getval.verified && 
              <div className="col-12 col-md-auto"> <span className="puskaran2 notranslate">PRO-</span>&nbsp;<span className="notranslate">AUTHENTIC CONSULTANT</span></div>}

            </div>
         </div>
          <div className="d-block d-md-none mb-3 mt-2">
          <div className="row  ">
          {getval.WomanOwned && 
              <div className="col-12 "><center><img width="30" height={30} src="/wpower.png" alt='w' />&nbsp;WOMAN ENTREPRENEUR</center></div> }
               {getval.verified && 
              <div className="col-12 "> <center><span className="puskaran2 notranslate">PRO-</span>&nbsp;<span className="notranslate">AUTHENTIC CONSULTANT</span></center></div>}

            </div>
          </div>
            <div className="col-md-4">
              <div className="profilee-img ">
                <img
                  id="DP"
                  className="img-fluid"
                  width="100px"
                  height="100px"
                  src={getval.link}
                  alt=""
                />
                {/* <div className="file btn btn-lg btn-primary">
                  Change Photo
                  <input onChange={(e)=>changeDp(e.target.files[0])} type="file" name="ProfilePic" accept="image/*"/>
                </div> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="profile-head mt-3">
              <div className="d-none d-md-block">
                <h5>
                {getval.pname?getval.pname.toUpperCase():''} 
                </h5>
                </div>
                <div className="d-block d-md-none">
               <center>
               <h5>
                {getval.pname?getval.pname.toUpperCase():''} 
                </h5>
               </center>
                </div>
                <div className="row">
                  <div className="col-md-9">
                   <div className="d-none d-md-block">
                   <h6>
                      {getval.string
                        ? getval.string[0].category +
                          " | " +
                          getval.string2[0].sub
                        : ""}
                      <br />
                    </h6>
                   </div>
                    <div className="d-block d-md-none">
                      <center>
                      <h6>
                      {getval.string
                        ? getval.string[0].category +
                          " | " +
                          getval.string2[0].sub
                        : ""}
                      <br />
                    </h6>
                      </center>
                    </div>
                  </div>
                  <div className="row pe-0">
                  <div className="col pe-0">
{CommunicationStatus===true&&<>

                   <div className="d-none d-md-block">
                     <input
                        type="submit"
                        className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                        style={{'borderRadius':'25px'}}
                        name="btnAddMore"
                        value="Communicate"
                        data-bs-toggle="modal"
                        data-bs-target={`${localStorage.getItem("Chat")==='false'?'#turnonchat23423':'#communi'}`}
                      />
                    </div>
                    <div className="d-block d-md-none">
                    <center>
                    <input
                        type="submit"
                        className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                        style={{'borderRadius':'25px'}}
                        name="btnAddMore"
                        value="Communicate"
                        data-bs-toggle="modal"
                        data-bs-target={`${localStorage.getItem("Chat")==='false'?'#turnonchat23423':'#communi'}`}
                      />
                    </center>
                   </div>
                   </>
}
                    </div>
                   
                    

                   
                  </div>
                </div>
                <div className="row">
                  <div className="form-check col-7 col-md-8 col-lg-6 d-flex justify-content-start mb-3 ms-3 pe-0">
                    <input
                      className="form-check-input float-end checkboxpro me-3 mt-3"
                      type="checkbox"
                      value=""
                      id={Ac}
                      onClick={(e) => checkaeijfpi(e)}
                    />
                    <span className="iorughoisrhgiohs">
                      {!mappas ? "Short-list this Consultant" : "Short-listed"}
                    </span>
                  </div>
                  <div className="col-3 col-md-3 col-lg-5 d-none">
                    {!Follower ? (
                      <>
                        <button
                          type="button"
                          className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                        style={{'borderRadius':'25px'}}
                          onClick={(e) => follow(e, idd)}
                        >
                          Follow
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn pro-spaces-button ms-1 ps-3 pe-3 mt-2 mb-2"
                          style={{'borderRadius':'25px'}}
                          onClick={(e) => unfollow(e, idd)}
                        >
                          UnFollow
                        </button>
                      </>
                    )}
                  </div>
                 
                </div>




                <div className="modal fade" id="reportthismodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Report this {getval.formType===1 || getval.formType===2 ? 'Consultant':'Service Provider'} </h5>
                        <button type="button" id="closebuttonforreport" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                        {/* <h5>Are you sure you want to report this consultant&nbsp;?</h5><br /> */}
                        <p>Please BE INFORMED that you must Report Abuse about a {getval.formType===1 || getval.formType===2 ? 'Consultant':'Service Provider'} ONLY for a genuine valid reason with evidence.</p><p>Fake / False reporting about a member as an act of prank will result in deactivation of your membership without refund.</p>
                        <textarea className="form-control" onChange={(e)=>setsabari(e.target.value)}></textarea>
                        <p className="text-danger">{sabarierr?sabarierr:''}</p>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn pro-spaces-button3" onClick={reprtsabari}>Report</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal fade" id="turnonchat23423" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">You need to Activate Chat to communicate !</h5>
                        <button type="button" id="closebuttonforreportusgfugssug" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                        {/* <h5>Are you sure you want to report this consultant&nbsp;?</h5><br /> */}
                        <p>Your One-to-One Chatting feature is currently Disabled.</p>
                       <p style={astyle} onClick={enablechat} to='/Account' >Enable Chatting now</p>
                      </div>
                      <div className="modal-footer">
                        {/* <button type="button" className="btn pro-spaces-button3" onClick={reprtsabari}>Report</button> */}
                      </div>
                    </div>
                  </div>
                </div>


                
                {
                  
                }
             
                {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="home-tab"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                      onClick={() => activate("home-tab")}
                    >
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link "
                      id="profile-tab"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      onClick={() => activate("profile-tab")}
                    >
                      Timeline
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link "
                      id="Portfolio-tab"
                      data-toggle="tab"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      onClick={() => activate("Portfolio-tab")}
                    >
                      Portfolio
                    </a>
                  </li>
                </ul> */}
              </div>
             
            </div>
            <center className='pt-4'>
            
            <div className="" style={{'maxWidth':'800px','textAlign':'left'}}>
      


      {getval.formType?
           getval.formType===1?
<>
<Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}><b>Portfolio of Works / Services</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
         <>
         <div className="row">
          
          <div className="col-6 d-none">
            <a className="card-title sgknriog" data-bs-toggle="modal" data-bs-target="#exampleModal">Rate this Consultant</a>
          </div>

        </div> 
        <div className="row">
          <div className="col-6">
            <p className="card-text">Good</p>
          </div>
          <div className="col-6">
            <p className="card-text">{getval.Status1 ? getval.Status1.length : ''}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <p className="card-text">Highly Recommended (HR)</p>
          </div>
          <div className="col-6">
            <p className="card-text">{getval.Status2 ? getval.Status2.length : ''}</p>
          </div>
        </div>
        <div className="row mt-2">
          
          <div className="col-6 d-none">
            <a className="card-title sgknriog" data-bs-toggle="modal" data-bs-target="#examplewkligheModal">Refer this Consultant</a>
          </div>

        </div> 
        {yeser?
        <div className="row">
          <div className="col-6">
            <p className="card-text"> Has been Referred</p>
          </div>
          <div className="col-6">
            <p className="card-text">{referCount ? referCount : ''} {yeser?yeser:''}</p>
          </div>
        </div>:<></>}
        <br />
        <div className="row d-none">
           <a href="" data-bs-toggle="modal" data-bs-target="#reportthismodal">Report this Consultant</a>
        </div>
        <hr />
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Rate this Consultant </h5>
                        <button type="button" id="closebuttonforreport" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
              <div className="modal-body">
              
                <div>
                <div className="form-check  ms-5 m-3">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault12" onChange={(e) => addreating(e, 1)} defaultChecked={first ? first.good ? true : false : false} />
                <label className="form-check-label" htmlFor="flexCheckDefault12" >
                  Good
                </label>
              </div>
              <div className="form-check ms-5 m-3">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault122" onChange={(e) => addreating(e, 2)} defaultChecked={first ? first.high ? true : false : false} />
                <label className="form-check-label" htmlFor="flexCheckDefault122">
                  Highly Recommended (HR)
                </label>
              </div>
                </div>
              </div>
              
             
                  

                




            </div>
          </div>
        </div>
        <div className="modal fade" id="examplewkligheModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Refer this Consultant </h5>
                        <button type="button" id="closebuttonforreport" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
              <div className="modal-body">
              
                <div>
                
                       {/* <label className="form-label" htmlFor="flexCheckDefault1222">
                  Refer
                </label> */}
                <div className="col-md-12 p-1 mt-2">
                  <label className="form-label">Email Id *</label>
                  <p>Enter the email ids of the persons to whom you would like to refer this consultant</p>

                  {/* <p style={{'fontSize':'10px'}} className="text-start my-2">L</p> */}

                  <div className="row ps-4">

                      {Email ? Email : ''}

                  </div>
              </div>
              <div className="col-md-12 mt-2">
                  <div className="input-group w-100">
                      <input maxLength='' className='form-control' id="skills" onChange={(e) => makeEmail(e.target.id)} type="text" aria-label="Recipient's username" aria-describedby="button-addon2" />
                      <button className="btn pro-spaces-button" onClick={(e) => makeEmail('skills', true)} type="button" id="button-addon2">Add</button>
                  </div>
              </div>
         
                        <div className="">
                        <label>Subject</label>
                          <input type="text" className="form-control" value="PROchure Consultant Referral" onChange={(e)=>setEmailRef({...EmailRef,subject:e.target.value})} disabled></input>
                        </div>
                        <br></br>
                        <div className="">
                        <label>Message</label>
                        <textarea className="form-control" value={EmailRef.massage} onChange={(e)=>setEmailRef({...EmailRef,massage:e.target.value})}></textarea>
                          {/* <input type="text" className="form-control" onChange={(e)=>setEmailRefer({...EmailRefer,massage:e.target.value})} ></input> */}
                        </div>
                        <br />

                      <p className="text-danger">{error?error:''}</p>
                        <div className="button-section d-flex justify-content-end">
                          {/* <button className="button add" type="button" onClick={() => addFormFields()}>Add</button> */}
                          {!loading &&
                          <button className="btn pro-spaces-button3" type="submit" onClick={(e)=>SendRef()}><IoIosSend /></button>
                          }
                           {loading &&
                          <button className="btn pro-spaces-button3" type="submit" onClick={(e)=>SendRef()} disabled>
                           <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                               
                          </button>
                          }
                        </div>
                      
                     
                </div>
              </div>
              


              </div></div></div>
         </>
          </Typography>
          <Typography>
           
              <GetportExternal Acc={Acc}/>
             
          </Typography>
        </AccordionDetails>
      </Accordion>

      {getval?getval.Type==='Consultant'?
            
            <Accordion expanded={expanded === 'panel332'} onChange={handleChange('panel332')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                <b>Exclusive Offers</b>
                </Typography>
              
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                <GetOfferExternal Acc={Acc} />
                </Typography>
              </AccordionDetails>
            </Accordion>
            :<></>:<></>
            }

      {getval.DSI?getval.DSI.length>0?
      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
          <b>Detailed Service Information</b>
          </Typography>
          
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {getval.DSI?getval.DSI.length>0?getval.DSI.map((elem)=>{
              console.log(getval.DSI)
              return (<>
              <div className="row">
              <div className="col-md-6">
                <span>Service Title</span>
              </div>
              <div className="col-md-6 ">
                <label style={{'color':'var(--blue)'}}>{elem.serviceTitle?elem.serviceTitle:''}</label>
              </div>
              </div>
               <div className="row">
               <div className="col-md-6">
                 <span>Service Description</span>
               </div>
               <div className="col-md-6 ">
                 <label style={{'color':'var(--blue)'}}>{elem.serviceDescription?elem.serviceDescription:''}</label>
               </div>
               </div>
                <div className="row">
                <div className="col-md-6">
                  <span>Service Fees / Charges</span>
                </div>
                <div className="col-md-6 ">
                  <label style={{'color':'var(--blue)'}}>{elem.serviceCharge?elem.serviceCharge:''}</label>
                </div>
                </div>
                 <div className="row">
                 <div className="col-md-6">
                   <span>Payment Terms</span>
                 </div>
                 <div className="col-md-6 ">
                   <label style={{'color':'var(--blue)'}}>{elem.paymentTerms?elem.paymentTerms:''}</label>
                 </div>
                 </div>
                 <hr />
                 </>)
            }):<></>:<></>}
          </Typography>
        </AccordionDetails>
      </Accordion>:<></>:<></>}

      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
          <b>Work / Service-related Information</b>
          </Typography>
          
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          {getval.TypeofConsultation?

          <div className="row">
            <div className="col-md-6">
              <span>Type of Consultation</span>
            </div>
            <div className="col-md-6 ">
              <label style={{'color':'var(--blue)'}}> {getval.TypeofConsultation
                        ? getval.TypeofConsultation == "both"
                          ? "Online and Offline"
                          : getval.TypeofConsultation
                        : ""}</label>
            </div>
          </div>:<></>}
          {getval.serviceprovided?

<div className="row">
<div className="col-md-6">
  <span>Services Provided</span>
</div>
<div className="col-md-6 ">
  <label style={{'color':'var(--blue)'}}>{getval.serviceprovided?getval.serviceprovided:'Nil'}</label>
</div>
</div>:<></>}

{getval.specialization?

<div className="row">
<div className="col-md-6">
  <span>Specialization</span>
</div>
<div className="col-md-6 ">
  <label style={{'color':'var(--blue)'}}>{getval.specialization?getval.specialization:'Nil'}</label>
</div>
</div>:<></>}
{getval.experience?

<div className="row">
<div className="col-md-6">
  <span>Total Work Experience (of the Chief Consultant / Proprietor)</span>
</div>
<div className="col-md-6 ">
  <label style={{'color':'var(--blue)'}}>{getval.experience?getval.experience:'Nil'} {sab}</label>
</div>
</div>:<></>}

          {getval.yearofb?

          <div className="row">
          <div className="col-md-6">
            <span>Practicing Independently since (Chief Consultant / Proprietor)</span>
          </div>
          <div className="col-md-6 ">
            <label style={{'color':'var(--blue)'}}>{getval.yearofb?getval.yearofb:''}</label>
          </div>
          </div>:<></>}

          {getval.previousorganization?

          <div className="row">
          <div className="col-md-6">
            <span>Prior Work Experience (of the Chief Consultant / Proprietor)</span>
          </div>
          <div className="col-md-6 ">
            <label style={{'color':'var(--blue)'}}>{getval.previousorganization?getval.previousorganization:'Nil'} </label>
          </div>
          </div>:<></>}

          

         
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}><span><b>Qualifications and Enrolment Information</b><br />(of the Chief Consultant / Proprietor)</span></Typography>
          
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          {getval.qualification?

<div className="row">
<div className="col-md-6">
  <span>Highest Qualification</span>
</div>
<div className="col-md-6 ">
  <label style={{'color':'var(--blue)'}}>{getval.qualification?getval.qualification:''}</label>
</div>
</div>:<></>}
{getval.yearofpassing ?
<div className="row">
<div className="col-md-6">
  <span>Year of Obtaining highest qualification</span>
</div>
<div className="col-md-6 ">
  <label style={{'color':'var(--blue)'}}>{getval.yearofpassing ? getval.yearofpassing : ""}</label>
</div>
</div>:<></>}
{getval.univesity?
<div className="row">
<div className="col-md-6">
  <span>University / Institution</span>
</div>
<div className="col-md-6 ">
  <label style={{'color':'var(--blue)'}}> {getval.univesity?getval.univesity:''}</label>
</div>
</div>:<></>}


{getval.skills ?
<div className="row">
<div className="col-md-6">
  <span>Technical Skills</span>
</div>
<div className="col-md-6 ">
  <label style={{'color':'var(--blue)'}}>{getval.skills ? getval.skills : "Nil"}</label>
</div>
</div>:<></>}

{getval.chif ?
<div className="row">
<div className="col-md-6">
  <span>Statutory body Registration details</span>
</div>
<div className="col-md-6 ">
  <label style={{'color':'var(--blue)'}}>{getval.chif ? getval.chif : "Nil"}</label>
</div>
</div>:<></>}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
         <span><b> Personal / Entity Information</b><br />(of the Chief Consultant / Proprietor)</span>
          </Typography>
          
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className="row" >
        <div className="col-md-6">
          <span>Consultant’s Name</span>
        </div>
        <div className="col-md-6 ">
          <label style={{'color':'var(--blue)'}}>{getval.firstname?getval.firstname.toUpperCase():''} {getval.lastname?getval.lastname.toUpperCase():''}</label>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <span>Gender</span>
        </div>
        <div className="col-md-6 ">
          <label style={{'color':'var(--blue)'}}>{getval.gender?getval.gender:''}</label>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <span> Age</span>
        </div>
        <div className="col-md-6 ">
          <label style={{'color':'var(--blue)'}}>
            {Math.floor(
              parseInt(new Date() - new Date(getval.dob)) /
                1000 /
                60 /
                60 /
                24 /
                365
            )}
          </label>
        </div>
      </div>

      {getval.language?

      <div className="row">
        <div className="col-md-6">
          <span>Languages Known</span>
        </div>
        <div className="col-md-6 ">
        <label style={{'color':'var(--blue)'}}>{getval.language?getval.language.map((element,index) => {
            if(index==getval.language.length-1){
              return element
            }else{
              return element +', '
            }
          }) :''}</label>
        </div>
      </div>:<></>}
          </Typography>
        </AccordionDetails>
      </Accordion>
   
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}><b>Business Information</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
         

            <div className="row">
            <div className="col-md-6">
              <span>Have Trade Licence</span>
            </div>
            <div className="col-md-6 address">
              <label style={{'color':'var(--blue)'}}>{getval.licence ? getval.licence : "Nil"}</label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <span>GST registered</span>
            </div>
            <div className="col-md-6 address">
              <label style={{'color':'var(--blue)'}}>{getval.gst ? getval.gst : "Nil"}</label>
            </div>
          </div>
          {getval.website?
          <div className="row">
          <div className="col-md-6">
            <span>Website</span>
          </div>
          <div className="col-md-6 address">
            <label style={{'color':'var(--blue)'}}>{getval.website ? getval.website : "Nil"}</label>
          </div>
          </div>:<></>}
          {getval.working_days_1?
          <div className="row">
          <div className="col-md-6">
            <span>Working Days</span>
          </div>
          <div className="col-md-6 address">
            <label style={{'color':'var(--blue)'}}>{getval.working_days_1 ? getval.working_days_1+" to " : ""} {getval.working_days_2 ? getval.working_days_2 : ""} </label>
          </div>
          </div>:<></>}
          {getval.working_hour_1?
          <div className="row">
          <div className="col-md-6">
            <span>Working Hours</span>
          </div>
          <div className="col-md-6 address">
          <label style={{'color':'var(--blue)'}}>{getval.working_hour_1 ? getval.working_hour_1+" to " : ""} {getval.working_hour_2 ? getval.working_hour_2 : ""} </label>

          </div>
          </div>:<></>}
          {getval.working_leave?
          <div className="row">
          <div className="col-md-6">
            <span>Weekly Off</span>
          </div>
          <div className="col-md-6 address">
          <label style={{'color':'var(--blue)'}}>{getval.working_leave ? getval.working_leave: ""} </label>
          </div>
          </div>:<></>}
          {getval.address1?

<div className="row">
<div className="col-md-6">
  <span>Address</span>
</div>
<div className="col-md-6">
  <label style={{'color':'var(--blue)'}}>{getval.address1}</label>
</div>
</div>:<></>}


{getval.state?
<div className="row">
<div className="col-md-6">
  <span>State, City</span>
</div>
<div className="col-md-6">
  <label style={{'color':'var(--blue)'}}>
    {getval.state}, {getval.city}
  </label>
</div>
</div>
:<></>}
{getval.pincode?

<div className="row">
  <div className="col-md-6">
    <span>Pin code</span>
  </div>
  <div className="col-md-6">
    <label style={{'color':'var(--blue)'}}>{getval.pincode}</label>
  </div>
</div>:<></>}
          </Typography>
          {getval.Location?
          <Typography>
             <p>Location Map</p>
            <div className="container">
            <a target="_blank" href={`http://maps.google.com/maps?z=18&q=${getval.Location_Coordinates.lat},${getval.Location_Coordinates.lng}`}>
            <StaticGoogleMap zoom="15" size="600x400" className="img-fluid" apiKey={process.env.REACT_APP_MAP_KEY}>
                <Marker location={`${getval.Location_Coordinates.lat},${getval.Location_Coordinates.lng}`} color="blue" label="P" />
              </StaticGoogleMap>
                </a>
            </div>
          </Typography>
          :<></> }
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}><b>Contact Information</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          {getval.contact?

          <div className="row">
          <div className="col-md-6">
            <span>Contact Number 1</span>
          </div>
          <div className="col-md-6">
            <label style={{'color':'var(--blue)'}}>{getval.contact}</label>
          </div>
          </div>:<></>}


          {getval.contact2?
          <div className="row">
          <div className="col-md-6">
            <span>Contact Number 2</span>
          </div>
          <div className="col-md-6">
            <label style={{'color':'var(--blue)'}}>
              {getval.contact2}
            </label>
          </div>
          </div>
          :<></>}

          {getval.email?
          <div className="row">
          <div className="col-md-6">
            <span>Email Id</span>
          </div>
          <div className="col-md-6">
            <label style={{'color':'var(--blue)'}}>
              {getval.email}
            </label>
          </div>
          </div>
          :<></>}
          </Typography>
        </AccordionDetails>
      </Accordion>  
</>
:getval.formType===2?
<>
{getval.DSI?getval.DSI.length>0?
      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
          <b>Detailed Service Information</b>
          </Typography>
          
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {getval.DSI?getval.DSI.length>0?getval.DSI.map((elem)=>{
              console.log(getval.DSI)
              return (<>
              <div className="row">
              <div className="col-md-6">
                <span>Service Title</span>
              </div>
              <div className="col-md-6 ">
                <label style={{'color':'var(--blue)'}}>{elem.serviceTitle?elem.serviceTitle:''}</label>
              </div>
              </div>
               <div className="row">
               <div className="col-md-6">
                 <span>Service Description</span>
               </div>
               <div className="col-md-6 ">
                 <label style={{'color':'var(--blue)'}}>{elem.serviceDescription?elem.serviceDescription:''}</label>
               </div>
               </div>
                <div className="row">
                <div className="col-md-6">
                  <span>Service Fees / Charges</span>
                </div>
                <div className="col-md-6 ">
                  <label style={{'color':'var(--blue)'}}>{elem.serviceCharge?elem.serviceCharge:''}</label>
                </div>
                </div>
                 <div className="row">
                 <div className="col-md-6">
                   <span>Payment Terms</span>
                 </div>
                 <div className="col-md-6 ">
                   <label style={{'color':'var(--blue)'}}>{elem.paymentTerms?elem.paymentTerms:''}</label>
                 </div>
                 </div>
                 <hr />
                 </>)
            }):<></>:<></>}
          </Typography>
        </AccordionDetails>
      </Accordion>:<></>:<></>}
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
          <b>Work / Service-related Information</b>
          </Typography>
          
        </AccordionSummary>
        
        <AccordionDetails>
        <Typography>
         <>
         <div className="row">
          
          <div className="col-6 d-none">
            <a className="card-title sgknriog" data-bs-toggle="modal" data-bs-target="#exampleModal">Rate this Consultant</a>
          </div>

        </div> 
        <div className="row">
          <div className="col-6">
            <p className="card-text">Good</p>
          </div>
          <div className="col-6">
            <p className="card-text">{getval.Status1 ? getval.Status1.length : ''}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <p className="card-text">Highly Recommended (HR)</p>
          </div>
          <div className="col-6">
            <p className="card-text">{getval.Status2 ? getval.Status2.length : ''}</p>
          </div>
        </div>
        <div className="row mt-2">
          
          <div className="col-6 d-none">
            <a className="card-title sgknriog" data-bs-toggle="modal" data-bs-target="#examplewkligheModal">Refer this Consultant</a>
          </div>

        </div> 
        {yeser?
        <div className="row">
          <div className="col-6">
            <p className="card-text"> Has been Referred</p>
          </div>
          <div className="col-6">
            <p className="card-text">{referCount ? referCount : ''} {yeser?yeser:''}</p>
          </div>
        </div>:<></>}
        <br />
        <div className="row d-none">
           <a href="" data-bs-toggle="modal" data-bs-target="#reportthismodal">Report this Consultant</a>
        </div>
        <hr />
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Rate this Consultant </h5>
                        <button type="button" id="closebuttonforreport" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
              <div className="modal-body">
                <div>
                <div className="d-flex justify-content-end"><button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
                </div>
             
                <div>
                <div className="form-check  ms-5 m-3">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault12" onChange={(e) => addreating(e, 1)} defaultChecked={first ? first.good ? true : false : false} />
                <label className="form-check-label" htmlFor="flexCheckDefault12" >
                  Good
                </label>
              </div>
              <div className="form-check ms-5 m-3">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault122" onChange={(e) => addreating(e, 2)} defaultChecked={first ? first.high ? true : false : false} />
                <label className="form-check-label" htmlFor="flexCheckDefault122">
                  Highly Recommended (HR)
                </label>
              </div>
                </div>
              </div>
              
             
                  

                




            </div>
          </div>
        </div>
        <div className="modal fade" id="examplewkligheModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Refer this Consultant </h5>
                        <button type="button" id="closebuttonforreport" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
              <div className="modal-body">
                
                <div>
                
                       {/* <label className="form-label" htmlFor="flexCheckDefault1222">
                  Refer
                </label> */}
                <div className="col-md-12 p-1 mt-2">
                  <label className="form-label">Email Id *</label>
                  <p>Enter the email ids of the persons to whom you would like to refer this consultant</p>

                  {/* <p style={{'fontSize':'10px'}} className="text-start my-2">L</p> */}

                  <div className="row ps-4">

                      {Email ? Email : ''}

                  </div>
              </div>
              <div className="col-md-12 mt-2">
                  <div className="input-group w-100">
                      <input maxLength='' className='form-control' id="skills" onChange={(e) => makeEmail(e.target.id)} type="text" aria-label="Recipient's username" aria-describedby="button-addon2" />
                      <button className="btn pro-spaces-button" onClick={(e) => makeEmail('skills', true)} type="button" id="button-addon2">Add</button>
                  </div>
              </div>
         
              <div className="">
                        <label>Subject</label>
                          <input type="text" className="form-control" value="PROchure Consultant Referral" onChange={(e)=>setEmailRef({...EmailRef,subject:e.target.value})} disabled></input>
                        </div>
                        <br></br>
                        <div className="">
                        <label>Message</label>
                        <textarea className="form-control" value={EmailRef.massage} onChange={(e)=>setEmailRef({...EmailRef,massage:e.target.value})}></textarea>
                          {/* <input type="text" className="form-control" onChange={(e)=>setEmailRefer({...EmailRefer,massage:e.target.value})} ></input> */}
                        </div>
                        <br />

                      <p className="text-danger">{error?error:''}</p>
                        <div className="button-section d-flex justify-content-end">
                          {/* <button className="button add" type="button" onClick={() => addFormFields()}>Add</button> */}
                          {!loading &&
                          <button className="btn pro-spaces-button3" type="submit" onClick={(e)=>SendRef()}><IoIosSend /></button>
                          }
                           {loading &&
                          <button className="btn pro-spaces-button3" type="submit" onClick={(e)=>SendRef()} disabled>
                           <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                               
                          </button>
                          }
                        </div>
                      
                     
                </div>
              </div>
              


        </div></div></div>
         </>
          </Typography>
          <Typography>
          {getval.TypeofConsultation?

          <div className="row">
            <div className="col-md-6">
              <span>Type of Consultation</span>
            </div>
            <div className="col-md-6 ">
              <label style={{'color':'var(--blue)'}}> {getval.TypeofConsultation
                        ? getval.TypeofConsultation == "both"
                          ? "Online and Offline"
                          : getval.TypeofConsultation
                        : ""}</label>
            </div>
          </div>:<></>}

          {getval.serviceprovided?

<div className="row">
<div className="col-md-6">
  <span>Services Provided</span>
</div>
<div className="col-md-6 ">
  <label style={{'color':'var(--blue)'}}>{getval.serviceprovided?getval.serviceprovided:'Nil'}</label>
</div>
</div>:<></>}

{getval.specialization?

<div className="row">
<div className="col-md-6">
  <span>Specialization</span>
</div>
<div className="col-md-6 ">
  <label style={{'color':'var(--blue)'}}>{getval.specialization?getval.specialization:'Nil'}</label>
</div>
</div>:<></>}

{getval.experience?

<div className="row">
<div className="col-md-6">
  <span>Total Work Experience (of the Chief Consultant / Proprietor)</span>
</div>
<div className="col-md-6 ">
  <label style={{'color':'var(--blue)'}}>{getval.experience?getval.experience:'Nil'} {sab}</label>
</div>
</div>:<></>}

          {getval.yearofb?

          <div className="row">
            <div className="col-md-6">
              <span>Practicing Independently since (Chief Consultant / Proprietor)</span>
            </div>
            <div className="col-md-6 ">
              <label style={{'color':'var(--blue)'}}>{getval.yearofb?getval.yearofb:''}</label>
            </div>
          </div>:<></>}



          {getval.previousorganization?

          <div className="row">
          <div className="col-md-6">
            <span>Prior Work Experience (of the Chief Consultant / Proprietor)</span>
          </div>
          <div className="col-md-6 ">
            <label style={{'color':'var(--blue)'}}>{getval.previousorganization?getval.previousorganization:'Nil'} </label>
          </div>
          </div>:<></>}

        

         
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}><span><b>Qualifications and Enrolment Information<br /></b>(of the Chief Consultant / Proprietor)</span></Typography>
          
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          {getval.qualification?

<div className="row">
<div className="col-md-6">
  <span>Highest Qualification</span>
</div>
<div className="col-md-6 ">
  <label style={{'color':'var(--blue)'}}>{getval.qualification?getval.qualification:''}</label>
</div>
</div>:<></>}
{getval.yearofpassing ?
<div className="row">
<div className="col-md-6">
  <span>Year of Obtaining highest qualification</span>
</div>
<div className="col-md-6 ">
  <label style={{'color':'var(--blue)'}}>{getval.yearofpassing ? getval.yearofpassing : ""}</label>
</div>
</div>:<></>}
{getval.univesity?
<div className="row">
<div className="col-md-6">
  <span>University / Institution</span>
</div>
<div className="col-md-6 ">
  <label style={{'color':'var(--blue)'}}> {getval.univesity?getval.univesity:''}</label>
</div>
</div>:<></>}


{getval.skills ?
<div className="row">
<div className="col-md-6">
  <span>Technical Skills</span>
</div>
<div className="col-md-6 ">
  <label style={{'color':'var(--blue)'}}>{getval.skills ? getval.skills : "Nil"}</label>
</div>
</div>:<></>}

{getval.chif ?
<div className="row">
<div className="col-md-6">
  <span>Statutory body Registration details</span>
</div>
<div className="col-md-6 ">
  <label style={{'color':'var(--blue)'}}>{getval.chif ? getval.chif : "Nil"}</label>
</div>
</div>:<></>}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}><b>Portfolio of Works / Services</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
       
          <Typography>
           
              <GetportExternal Acc={Acc}/>
             
          </Typography>
        </AccordionDetails>
      </Accordion>
      {getval?getval.Type==='Consultant'?
            
            <Accordion expanded={expanded === 'panel332'} onChange={handleChange('panel332')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                <b>Exclusive Offers</b>
                </Typography>
              
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                <GetOfferExternal Acc={Acc} />
                </Typography>
              </AccordionDetails>
            </Accordion>
            :<></>:<></>
            }
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
         <b> Personal / Entity Information</b>
          </Typography>
          
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className="row" >
        <div className="col-md-6">
          <span>Consultant’s Name</span>
        </div>
        <div className="col-md-6 ">
          <label style={{'color':'var(--blue)'}}>{getval.firstname?getval.firstname.toUpperCase():''} {getval.lastname?getval.lastname.toUpperCase():''}</label>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <span>Gender</span>
        </div>
        <div className="col-md-6 ">
          <label style={{'color':'var(--blue)'}}>{getval.gender?getval.gender:''}</label>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <span> Age</span>
        </div>
        <div className="col-md-6 ">
          <label style={{'color':'var(--blue)'}}>
            {Math.floor(
              parseInt(new Date() - new Date(getval.dob)) /
                1000 /
                60 /
                60 /
                24 /
                365
            )}
          </label>
        </div>
      </div>

      {getval.language?

      <div className="row">
        <div className="col-md-6">
          <span>Languages Known</span>
        </div>
        <div className="col-md-6 ">
        <label style={{'color':'var(--blue)'}}>{getval.language?getval.language.map((element,index) => {
            if(index==getval.language.length-1){
              return element
            }else{
              return element +', '
            }
          }) :''}</label>
        </div>
      </div>:<></>}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}><b>Business Information</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
         

            <div className="row">
            <div className="col-md-6">
              <span>Have Trade Licence</span>
            </div>
            <div className="col-md-6 address">
              <label style={{'color':'var(--blue)'}}>{getval.licence ? getval.licence : "Nil"}</label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <span>GST registered</span>
            </div>
            <div className="col-md-6 address">
              <label style={{'color':'var(--blue)'}}>{getval.gst ? getval.gst : "Nil"}</label>
            </div>
          </div>
          {getval.website?
          <div className="row">
          <div className="col-md-6">
            <span>Website</span>
          </div>
          <div className="col-md-6 address">
            <label style={{'color':'var(--blue)'}}>{getval.website ? getval.website : "Nil"}</label>
          </div>
          </div>:<></>}
          {getval.working_days_1?
          <div className="row">
          <div className="col-md-6">
            <span>Working Days</span>
          </div>
          <div className="col-md-6 address">
            <label style={{'color':'var(--blue)'}}>{getval.working_days_1 ? getval.working_days_1+" to " : ""} {getval.working_days_2 ? getval.working_days_2 : ""} </label>
          </div>
          </div>:<></>}
          {getval.working_hour_1?
          <div className="row">
          <div className="col-md-6">
            <span>Working Hours</span>
          </div>
          <div className="col-md-6 address">
          <label style={{'color':'var(--blue)'}}>{getval.working_hour_1 ? getval.working_hour_1+" to " : ""} {getval.working_hour_2 ? getval.working_hour_2 : ""} </label>

          </div>
          </div>:<></>}
          {getval.working_leave?
          <div className="row">
          <div className="col-md-6">
            <span>Weekly Off</span>
          </div>
          <div className="col-md-6 address">
          <label style={{'color':'var(--blue)'}}>{getval.working_leave ? getval.working_leave: ""} </label>
          </div>
          </div>:<></>}
          {getval.address1?

<div className="row">
<div className="col-md-6">
  <span>Address</span>
</div>
<div className="col-md-6">
  <label style={{'color':'var(--blue)'}}>{getval.address1}</label>
</div>
</div>:<></>}


{getval.state?
<div className="row">
<div className="col-md-6">
  <span>State, City</span>
</div>
<div className="col-md-6">
  <label style={{'color':'var(--blue)'}}>
    {getval.state}, {getval.city}
  </label>
</div>
</div>
:<></>}
{getval.pincode?

<div className="row">
  <div className="col-md-6">
    <span>Pin code</span>
  </div>
  <div className="col-md-6">
    <label style={{'color':'var(--blue)'}}>{getval.pincode}</label>
  </div>
</div>:<></>}
          </Typography>
          {getval.Location?
          <Typography>
             <p>Location Map</p>
            <div className="container">
            <a target="_blank" href={`http://maps.google.com/maps?z=18&q=${getval.Location_Coordinates.lat},${getval.Location_Coordinates.lng}`}>
            <StaticGoogleMap zoom="15" size="600x400" className="img-fluid" apiKey={process.env.REACT_APP_MAP_KEY}>
                <Marker location={`${getval.Location_Coordinates.lat},${getval.Location_Coordinates.lng}`} color="blue" label="P" />
              </StaticGoogleMap>
                </a>
            </div>
          </Typography>
          :<></> }
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}><b>Contact Information</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          {getval.contact?

          <div className="row">
          <div className="col-md-6">
            <span>Contact Number 1</span>
          </div>
          <div className="col-md-6">
            <label style={{'color':'var(--blue)'}}>{getval.contact}</label>
          </div>
          </div>:<></>}


          {getval.contact2?
          <div className="row">
          <div className="col-md-6">
            <span>Contact Number 2</span>
          </div>
          <div className="col-md-6">
            <label style={{'color':'var(--blue)'}}>
              {getval.contact2}
            </label>
          </div>
          </div>
          :<></>}

          {getval.email?
          <div className="row">
          <div className="col-md-6">
            <span>Email Id</span>
          </div>
          <div className="col-md-6">
            <label style={{'color':'var(--blue)'}}>
              {getval.email}
            </label>
          </div>
          </div>
          :<></>}
          </Typography>
        </AccordionDetails>
      </Accordion>  
</>
:getval.formType===3?
<>
{getval.DSI?getval.DSI.length>0?
      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
          <b>Detailed Service Information</b>
          </Typography>
          
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {getval.DSI?getval.DSI.length>0?getval.DSI.map((elem)=>{
              console.log(getval.DSI)
              return (<>
              <div className="row">
              <div className="col-md-6">
                <span>Service Title</span>
              </div>
              <div className="col-md-6 ">
                <label style={{'color':'var(--blue)'}}>{elem.serviceTitle?elem.serviceTitle:''}</label>
              </div>
              </div>
               <div className="row">
               <div className="col-md-6">
                 <span>Service Description</span>
               </div>
               <div className="col-md-6 ">
                 <label style={{'color':'var(--blue)'}}>{elem.serviceDescription?elem.serviceDescription:''}</label>
               </div>
               </div>
                <div className="row">
                <div className="col-md-6">
                  <span>Service Fees / Charges</span>
                </div>
                <div className="col-md-6 ">
                  <label style={{'color':'var(--blue)'}}>{elem.serviceCharge?elem.serviceCharge:''}</label>
                </div>
                </div>
                 <div className="row">
                 <div className="col-md-6">
                   <span>Payment Terms</span>
                 </div>
                 <div className="col-md-6 ">
                   <label style={{'color':'var(--blue)'}}>{elem.paymentTerms?elem.paymentTerms:''}</label>
                 </div>
                 </div>
                 <hr />
                 </>)
            }):<></>:<></>}
          </Typography>
        </AccordionDetails>
      </Accordion>:<></>:<></>}
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
          <b>Work / Service-related Information</b>
          </Typography>
          
        </AccordionSummary>
        <AccordionDetails>
        <Typography>
         <>
         <div className="row">
          
          <div className="col-6 d-none">
            <a className="card-title sgknriog" data-bs-toggle="modal" data-bs-target="#exampleModal">Rate this Service Provider</a>
          </div>

        </div> 
        <div className="row">
          <div className="col-6">
            <p className="card-text">Good</p>
          </div>
          <div className="col-6">
            <p className="card-text">{getval.Status1 ? getval.Status1.length : ''}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <p className="card-text">Highly Recommended (HR)</p>
          </div>
          <div className="col-6">
            <p className="card-text">{getval.Status2 ? getval.Status2.length : ''}</p>
          </div>
        </div>
        <div className="row mt-2">
          
          <div className="col-6 d-none">
            <a className="card-title sgknriog" data-bs-toggle="modal" data-bs-target="#examplewkligheModal">Refer this Service Provider</a>
          </div>

        </div> 
        {yeser?
        <div className="row">
          <div className="col-6">
            <p className="card-text"> Has been Referred</p>
          </div>
          <div className="col-6">
            <p className="card-text">{referCount ? referCount : ''} {yeser?yeser:''}</p>
          </div>
        </div>:<></>}
        <br />
        <div className="row d-none">
           <a href="" data-bs-toggle="modal" data-bs-target="#reportthismodal">Report this Service Provider</a>
        </div>
        <hr />
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Rate this Service Provider </h5>
                        <button type="button" id="closebuttonforreport" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
              <div className="modal-body">
               
                <div>
                <div className="form-check  ms-5 m-3">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault12" onChange={(e) => addreating(e, 1)} defaultChecked={first ? first.good ? true : false : false} />
                <label className="form-check-label" htmlFor="flexCheckDefault12" >
                  Good
                </label>
              </div>
              <div className="form-check ms-5 m-3">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault122" onChange={(e) => addreating(e, 2)} defaultChecked={first ? first.high ? true : false : false} />
                <label className="form-check-label" htmlFor="flexCheckDefault122">
                  Highly Recommended (HR)
                </label>
              </div>
                </div>
              </div>
              
             
                  

                




            </div>
          </div>
        </div>
        <div className="modal fade" id="examplewkligheModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Refer this Service Provider </h5>
                        <button type="button" id="closebuttonforreport" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
              <div className="modal-body">
               
                <div>
                
                       {/* <label className="form-label" htmlFor="flexCheckDefault1222">
                  Refer
                </label> */}
                <div className="col-md-12 p-1 mt-2">
                  <label className="form-label">Email Id *</label>
                  <p>Enter the email ids of the persons to whom you would like to refer this service provider</p>

                  {/* <p style={{'fontSize':'10px'}} className="text-start my-2">L</p> */}

                  <div className="row ps-4">

                      {Email ? Email : ''}

                  </div>
              </div>
              <div className="col-md-12 mt-2">
                  <div className="input-group w-100">
                      <input maxLength='' className='form-control' id="skills" onChange={(e) => makeEmail(e.target.id)} type="text" aria-label="Recipient's username" aria-describedby="button-addon2" />
                      <button className="btn pro-spaces-button" onClick={(e) => makeEmail('skills', true)} type="button" id="button-addon2">Add</button>
                  </div>
              </div>
         
              <div className="">
                        <label>Subject</label>
                          <input type="text" className="form-control" value="PROchure Consultant Referral" onChange={(e)=>setEmailRef({...EmailRef,subject:e.target.value})} disabled></input>
                        </div>
                        <br></br>
                        <div className="">
                        <label>Message</label>
                        <textarea className="form-control" value={EmailRef.massage} onChange={(e)=>setEmailRef({...EmailRef,massage:e.target.value})}></textarea>
                          {/* <input type="text" className="form-control" onChange={(e)=>setEmailRefer({...EmailRefer,massage:e.target.value})} ></input> */}
                        </div>
                        <br />



                      <p className="text-danger">{error?error:''}</p>
                        <div className="button-section d-flex justify-content-end">
                          {/* <button className="button add" type="button" onClick={() => addFormFields()}>Add</button> */}
                          {!loading &&
                          <button className="btn pro-spaces-button3" type="submit" onClick={(e)=>SendRef()}><IoIosSend /></button>
                          }
                           {loading &&
                          <button className="btn pro-spaces-button3" type="submit" onClick={(e)=>SendRef()} disabled>
                           <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                               
                          </button>
                          }
                        </div>
                      
                     
                </div>
              </div>
              


              </div></div></div>
         </>
          </Typography>
          <Typography>
         
          {getval.serviceprovided?

<div className="row">
<div className="col-md-6">
  <span>Services Provided</span>
</div>
<div className="col-md-6 ">
  <label style={{'color':'var(--blue)'}}>{getval.serviceprovided?getval.serviceprovided:'Nil'}</label>
</div>
</div>:<></>}

{getval.specialization?

<div className="row">
<div className="col-md-6">
  <span>Specialization</span>
</div>
<div className="col-md-6 ">
  <label style={{'color':'var(--blue)'}}>{getval.specialization?getval.specialization:'Nil'}</label>
</div>
</div>:<></>}
          {getval.experience?

          <div className="row">
          <div className="col-md-6">
            <span>Total Work Experience (of the Service Provider / Proprietor)</span>
          </div>
          <div className="col-md-6 ">
            <label style={{'color':'var(--blue)'}}>{getval.experience?getval.experience:'Nil'} {sab}</label>
          </div>
          </div>:<></>}

          
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}><b>Portfolio of Services</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
        
          <Typography>
           
              <GetportExternal Acc={Acc}/>
             
          </Typography>
        </AccordionDetails>
      </Accordion>
      {getval?getval.Type==='Consultant'?
            <Accordion expanded={expanded === 'panel332'} onChange={handleChange('panel332')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                <b>Exclusive Offers</b>
                </Typography>
              
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                <GetOfferExternal Acc={Acc} />
                </Typography>
              </AccordionDetails>
            </Accordion>
            :<></>:<></>
            }
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}>
         <span><b> Personal / Entity Information</b><br />(of the Service Provider / Proprietor)</span>
          </Typography>
          
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <div className="row" >
        <div className="col-md-6">
          <span>Service Provider’s Name</span>
        </div>
        <div className="col-md-6 ">
          <label style={{'color':'var(--blue)'}}>{getval.firstname?getval.firstname.toUpperCase():''} {getval.lastname?getval.lastname.toUpperCase():''}</label>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <span>Gender</span>
        </div>
        <div className="col-md-6 ">
          <label style={{'color':'var(--blue)'}}>{getval.gender?getval.gender:''}</label>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <span> Age</span>
        </div>
        <div className="col-md-6 ">
          <label style={{'color':'var(--blue)'}}>
           {getval.age?getval.age:''}
          </label>
        </div>
      </div>

      {getval.language?

      <div className="row">
        <div className="col-md-6">
          <span>Languages Known</span>
        </div>
        <div className="col-md-6 ">
        <label style={{'color':'var(--blue)'}}>{getval.language?getval.language.map((element,index) => {
            if(index==getval.language.length-1){
              return element
            }else{
              return element +', '
            }
          }) :''}</label>
        </div>
      </div>:<></>}
          </Typography>
        </AccordionDetails>
      </Accordion>
   
     
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}><b>Business Information</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
         
          {getval.working_days_1?
          <div className="row">
          <div className="col-md-6">
            <span>Working Days</span>
          </div>
          <div className="col-md-6 address">
            <label style={{'color':'var(--blue)'}}>{getval.working_days_1 ? getval.working_days_1+" to " : ""} {getval.working_days_2 ? getval.working_days_2 : ""} </label>
          </div>
          </div>:<></>}
          {getval.working_hour_1?
          <div className="row">
          <div className="col-md-6">
            <span>Working Hours</span>
          </div>
          <div className="col-md-6 address">
          <label style={{'color':'var(--blue)'}}>{getval.working_hour_1 ? getval.working_hour_1+" to " : ""} {getval.working_hour_2 ? getval.working_hour_2 : ""} </label>

          </div>
          </div>:<></>}
          {getval.working_leave?
          <div className="row">
          <div className="col-md-6">
            <span>Weekly Off</span>
          </div>
          <div className="col-md-6 address">
          <label style={{'color':'var(--blue)'}}>{getval.working_leave ? getval.working_leave: ""} </label>
          </div>
          </div>:<></>}
          {getval.address1?

<div className="row">
<div className="col-md-6">
  <span>Address</span>
</div>
<div className="col-md-6">
  <label style={{'color':'var(--blue)'}}>{getval.address1}</label>
</div>
</div>:<></>}


{getval.state?
<div className="row">
<div className="col-md-6">
  <span>State, City</span>
</div>
<div className="col-md-6">
  <label style={{'color':'var(--blue)'}}>
    {getval.state}, {getval.city}
  </label>
</div>
</div>
:<></>}
{getval.pincode?

<div className="row">
  <div className="col-md-6">
    <span>Pin code</span>
  </div>
  <div className="col-md-6">
    <label style={{'color':'var(--blue)'}}>{getval.pincode}</label>
  </div>
</div>:<></>}

           
          </Typography>
          {getval.Location?
          <Typography>
             <p>Location Map</p>
            <div className="container">
            <a target="_blank" href={`http://maps.google.com/maps?z=18&q=${getval.Location_Coordinates.lat},${getval.Location_Coordinates.lng}`}>
            <StaticGoogleMap zoom="15" size="600x400" className="img-fluid" apiKey={process.env.REACT_APP_MAP_KEY}>
                <Marker location={`${getval.Location_Coordinates.lat},${getval.Location_Coordinates.lng}`} color="blue" label="P" />
              </StaticGoogleMap>
                </a>
            </div>
          </Typography>
          :<></> }
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}><b>Contact Information</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          {getval.contact?

          <div className="row">
          <div className="col-md-6">
            <span>Contact Number 1</span>
          </div>
          <div className="col-md-6">
            <label style={{'color':'var(--blue)'}}>{getval.contact}</label>
          </div>
          </div>:<></>}


          {getval.contact2?
          <div className="row">
          <div className="col-md-6">
            <span>Contact Number 2</span>
          </div>
          <div className="col-md-6">
            <label style={{'color':'var(--blue)'}}>
              {getval.contact2}
            </label>
          </div>
          </div>
          :<></>}

        
          </Typography>
        </AccordionDetails>
      </Accordion>  
</>
:<>
<Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}><b>Portfolio of Services / Facilities</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
        <AccordionDetails>
          <Typography>
         <>
         <div className="row">
          
          <div className="col-6 d-none">
            <a className="card-title sgknriog" data-bs-toggle="modal" data-bs-target="#exampleModal">Rate this Service Provider</a>
          </div>

        </div> 
        <div className="row">
          <div className="col-6">
            <p className="card-text">Good</p>
          </div>
          <div className="col-6">
            <p className="card-text">{getval.Status1 ? getval.Status1.length : ''}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <p className="card-text">Highly Recommended (HR)</p>
          </div>
          <div className="col-6">
            <p className="card-text">{getval.Status2 ? getval.Status2.length : ''}</p>
          </div>
        </div>
        <div className="row mt-2">
          
          <div className="col-6 d-none">
            <a className="card-title sgknriog" data-bs-toggle="modal" data-bs-target="#examplewkligheModal">Refer this Service Provider</a>
          </div>

        </div> 
        {yeser?
        <div className="row">
          <div className="col-6">
            <p className="card-text"> Has been Referred</p>
          </div>
          <div className="col-6">
            <p className="card-text">{referCount ? referCount : ''} {yeser?yeser:''}</p>
          </div>
        </div>:<></>}
        <br />
        <div className="row d-none">
           <a href="" data-bs-toggle="modal" data-bs-target="#reportthismodal">Report this Service Provider</a>
        </div>
        <hr />
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Rate this Service Provider </h5>
                        <button type="button" id="closebuttonforreport" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
              <div className="modal-body">
                
             
                <div>
                <div className="form-check  ms-5 m-3">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault12" onChange={(e) => addreating(e, 1)} defaultChecked={first ? first.good ? true : false : false} />
                <label className="form-check-label" htmlFor="flexCheckDefault12" >
                  Good
                </label>
              </div>
              <div className="form-check ms-5 m-3">
                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault122" onChange={(e) => addreating(e, 2)} defaultChecked={first ? first.high ? true : false : false} />
                <label className="form-check-label" htmlFor="flexCheckDefault122">
                  Highly Recommended (HR)
                </label>
              </div>
                </div>
              </div>
              
             
                  

                




            </div>
          </div>
        </div>
        <div className="modal fade" id="examplewkligheModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Refer this Service Provider </h5>
                        <button type="button" id="closebuttonforreport" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
              <div className="modal-body">
                
                <div>
                
                       {/* <label className="form-label" htmlFor="flexCheckDefault1222">
                  Refer
                </label> */}
                <div className="col-md-12 p-1 mt-2">
                  <label className="form-label">Email Id *</label>
                  <p>Enter the email ids of the persons to whom you would like to refer this service provider</p>

                  {/* <p style={{'fontSize':'10px'}} className="text-start my-2">L</p> */}

                  <div className="row ps-4">

                      {Email ? Email : ''}

                  </div>
              </div>
              <div className="col-md-12 mt-2">
                  <div className="input-group w-100">
                      <input maxLength='' className='form-control' id="skills" onChange={(e) => makeEmail(e.target.id)} type="text" aria-label="Recipient's username" aria-describedby="button-addon2" />
                      <button className="btn pro-spaces-button" onClick={(e) => makeEmail('skills', true)} type="button" id="button-addon2">Add</button>
                  </div>
              </div>
         
              <div className="">
                        <label>Subject</label>
                          <input type="text" className="form-control" value="PROchure Consultant Referral" onChange={(e)=>setEmailRef({...EmailRef,subject:e.target.value})} disabled></input>
                        </div>
                        <br></br>
                        <div className="">
                        <label>Message</label>
                        <textarea className="form-control" value={EmailRef.massage} onChange={(e)=>setEmailRef({...EmailRef,massage:e.target.value})}></textarea>
                          {/* <input type="text" className="form-control" onChange={(e)=>setEmailRefer({...EmailRefer,massage:e.target.value})} ></input> */}
                        </div>
                        <br />



                      <p className="text-danger">{error?error:''}</p>
                        <div className="button-section d-flex justify-content-end">
                          {/* <button className="button add" type="button" onClick={() => addFormFields()}>Add</button> */}
                          {!loading &&
                          <button className="btn pro-spaces-button3" type="submit" onClick={(e)=>SendRef()}><IoIosSend /></button>
                          }
                           {loading &&
                          <button className="btn pro-spaces-button3" type="submit" onClick={(e)=>SendRef()} disabled>
                           <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                               
                          </button>
                          }
                        </div>
                      
                     
                </div>
              </div>
              


              </div></div></div>
         </>
          </Typography>
          <Typography>
           
              <GetportExternal Acc={Acc}/>
             
          </Typography>
        </AccordionDetails>
         
        </AccordionDetails>
      </Accordion>
      {getval?getval.Type==='Consultant'?
            
            <Accordion expanded={expanded === 'panel332'} onChange={handleChange('panel332')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography sx={{ width: '100%', flexShrink: 0 }}>
                <b>Exclusive Offers</b>
                </Typography>
              
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                <GetOfferExternal Acc={Acc} />
                </Typography>
              </AccordionDetails>
            </Accordion>
            :<></>:<></>
            }
      {getval.DSI?getval.DSI.length>0?
<Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel3bh-content"
    id="panel3bh-header"
  >
    <Typography sx={{ width: '100%', flexShrink: 0 }}>
    <b>Detailed Service Information</b>
    </Typography>
    
  </AccordionSummary>
  <AccordionDetails>
    <Typography>
      {getval.DSI?getval.DSI.length>0?getval.DSI.map((elem)=>{
        console.log(getval.DSI)
        return (<>
        <div className="row">
        <div className="col-md-6">
          <span>Facility / Service Title</span>
        </div>
        <div className="col-md-6 ">
          <label style={{'color':'var(--blue)'}}>{elem.serviceTitle?elem.serviceTitle:''}</label>
        </div>
        </div>
         <div className="row">
         <div className="col-md-6">
           <span>Facility / Service Description</span>
         </div>
         <div className="col-md-6 ">
           <label style={{'color':'var(--blue)'}}>{elem.serviceDescription?elem.serviceDescription:''}</label>
         </div>
         </div>
          <div className="row">
          <div className="col-md-6">
            <span>Service Fees / Charges</span>
          </div>
          <div className="col-md-6 ">
            <label style={{'color':'var(--blue)'}}>{elem.serviceCharge?elem.serviceCharge:''}</label>
          </div>
          </div>
           <div className="row">
           <div className="col-md-6">
             <span>Payment Terms</span>
           </div>
           <div className="col-md-6 ">
             <label style={{'color':'var(--blue)'}}>{elem.paymentTerms?elem.paymentTerms:''}</label>
           </div>
           </div>
           <hr />
           </>)
      }):<></>:<></>}
    </Typography>
  </AccordionDetails>
</Accordion>:<></>:<></>}
<Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel3bh-content"
    id="panel3bh-header"
  >
    <Typography sx={{ width: '100%', flexShrink: 0 }}>
    <b>Facility / Service-related Information</b>
    </Typography>
    
  </AccordionSummary>
  <AccordionDetails>
    <Typography>
   

    {getval.typeofservice?

    <div className="row">
    <div className="col-md-6">
      <span>Type of Service</span>
    </div>
    <div className="col-md-6 ">
      <label style={{'color':'var(--blue)'}}>{getval.typeofservice?getval.typeofservice:'Nil'} {sab}</label>
    </div>
    </div>:<></>}

    {getval.serviceprovided?

<div className="row">
<div className="col-md-6">
  <span>Facilities / Services Provided</span>
</div>
<div className="col-md-6 ">
  <label style={{'color':'var(--blue)'}}>{getval.serviceprovided?getval.serviceprovided:'Nil'}</label>
</div>
</div>:<></>}

{getval.specialization?

<div className="row">
<div className="col-md-6">
  <span>Specialization</span>
</div>
<div className="col-md-6 ">
  <label style={{'color':'var(--blue)'}}>{getval.specialization?getval.specialization:'Nil'}</label>
</div>
</div>:<></>}

    {getval.yearofb?

<div className="row">
<div className="col-md-6">
  <span>Year of Business Inception</span>
</div>
<div className="col-md-6 ">
  <label style={{'color':'var(--blue)'}}>{getval.yearofb?getval.yearofb:'Nil'}</label>
</div>
</div>:<></>}

    
    </Typography>
  </AccordionDetails>
</Accordion>

<Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel4bh-content"
    id="panel4bh-header"
  >
    <Typography sx={{ width: '100%', flexShrink: 0 }}><b>Business Information</b></Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Typography>
   
    <div className="row">
            <div className="col-md-6">
              <span>Have Trade Licence</span>
            </div>
            <div className="col-md-6 address">
              <label style={{'color':'var(--blue)'}}>{getval.licence ? getval.licence : "Nil"}</label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <span>GST registered</span>
            </div>
            <div className="col-md-6 address">
              <label style={{'color':'var(--blue)'}}>{getval.gst ? getval.gst : "Nil"}</label>
            </div>
          </div>
          {getval.website?
          <div className="row">
          <div className="col-md-6">
            <span>Website</span>
          </div>
          <div className="col-md-6 address">
            <label style={{'color':'var(--blue)'}}>{getval.website ? getval.website : "Nil"}</label>
          </div>
          </div>:<></>}
          {getval.working_days_1?
          <div className="row">
          <div className="col-md-6">
            <span>Working Days</span>
          </div>
          <div className="col-md-6 address">
            <label style={{'color':'var(--blue)'}}>{getval.working_days_1 ? getval.working_days_1+" to " : ""} {getval.working_days_2 ? getval.working_days_2 : ""} </label>
          </div>
          </div>:<></>}
          {getval.working_hour_1?
          <div className="row">
          <div className="col-md-6">
            <span>Working Hours</span>
          </div>
          <div className="col-md-6 address">
          <label style={{'color':'var(--blue)'}}>{getval.working_hour_1 ? getval.working_hour_1+" to " : ""} {getval.working_hour_2 ? getval.working_hour_2 : ""} </label>

          </div>
          </div>:<></>}
          {getval.working_leave?
          <div className="row">
          <div className="col-md-6">
            <span>Weekly Off</span>
          </div>
          <div className="col-md-6 address">
          <label style={{'color':'var(--blue)'}}>{getval.working_leave ? getval.working_leave: ""} </label>
          </div>
          </div>:<></>}
          {getval.address1?

<div className="row">
<div className="col-md-6">
  <span>Address</span>
</div>
<div className="col-md-6">
  <label style={{'color':'var(--blue)'}}>{getval.address1}</label>
</div>
</div>:<></>}


{getval.state?
<div className="row">
<div className="col-md-6">
  <span>State, City</span>
</div>
<div className="col-md-6">
  <label style={{'color':'var(--blue)'}}>
    {getval.state}, {getval.city}
  </label>
</div>
</div>
:<></>}
{getval.pincode?

<div className="row">
  <div className="col-md-6">
    <span>Pin code</span>
  </div>
  <div className="col-md-6">
    <label style={{'color':'var(--blue)'}}>{getval.pincode}</label>
  </div>
</div>:<></>}

     
    </Typography>
    {getval.Location?
          <Typography>
             <p>Location Map</p>
            <div className="container">
            <a target="_blank" href={`http://maps.google.com/maps?z=18&q=${getval.Location_Coordinates.lat},${getval.Location_Coordinates.lng}`}>
            <StaticGoogleMap zoom="15" size="600x400" className="img-fluid" apiKey={process.env.REACT_APP_MAP_KEY}>
                <Marker location={`${getval.Location_Coordinates.lat},${getval.Location_Coordinates.lng}`} color="blue" label="P" />
              </StaticGoogleMap>
                </a>
            </div>
          </Typography>
          :<></> }
  </AccordionDetails>
</Accordion>
<Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel4bh-content"
    id="panel4bh-header"
  >
    <Typography sx={{ width: '100%', flexShrink: 0 }}><b>Contact Information</b></Typography>
  </AccordionSummary>
  <AccordionDetails>
    <Typography>
    {getval.contact?

    <div className="row">
    <div className="col-md-6">
      <span>Contact Number 1</span>
    </div>
    <div className="col-md-6">
      <label style={{'color':'var(--blue)'}}>{getval.contact}</label>
    </div>
    </div>:<></>}


    {getval.contact2?
    <div className="row">
    <div className="col-md-6">
      <span>Contact Number 2</span>
    </div>
    <div className="col-md-6">
      <label style={{'color':'var(--blue)'}}>
        {getval.contact2}
      </label>
    </div>
    </div>
    :<></>}

{getval.email?
          <div className="row">
          <div className="col-md-6">
            <span>Email Id</span>
          </div>
          <div className="col-md-6">
            <label style={{'color':'var(--blue)'}}>
              {getval.email}
            </label>
          </div>
          </div>
          :<></>}

  
    </Typography>
  </AccordionDetails>
</Accordion>  
</>
:''}



      {/* <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '100%', flexShrink: 0 }}><b>Timeline {first8?first8>1?'('+first8+ ' posts)':'('+first8+ ' post)':''}</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <TimeLineExternal acid={Acc} setfirst8={setfirst8}/>
          </Typography>
        </AccordionDetails>
      </Accordion>
      {getval.followers?getval.followers.length==0?<></>
: <Accordion expanded={expanded === 'panel7'} onChange={handleChange('pane20')}>
<AccordionSummary
 
  aria-controls="panel4bh-content"
  id="panel4bh-header"
>
  <Typography sx={{ width: '100%', flexShrink: 0 }}><b>Followers {getval.followers?getval.followers.length:''}</b></Typography>
</AccordionSummary>

</Accordion>:''} */}
     
          </div>
            </center>
          </div>
         
        </div>
        <div
                    className="modal fade"
                    id="communi"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="staticBackdropLabel">
                            YOU ARE A.....
                          </h5>
                          <button
                            id="slr"
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>

                        <div className="modal-body">
                          <div className="form-check  ms-5 mt-2 mb-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              onChange={(e) => communicate(e, true)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              CLIENT
                            </label>
                          </div>
                          <div className="form-check ms-5 mb-3">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              onChange={(e) => communicate(e, false)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              CONSULTANT
                            </label>
                          </div>
                          <div className="form-check ms-5">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              onChange={(e) => communicate(e, false)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              VENDOR
                            </label>
                          </div>
                          {/* <input type={'check'} onClick={(e) => communicate(e, true)}>Client</input> */}
                        </div>
                      </div>
                    </div>
                  </div>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default ExternalPview;
// new
