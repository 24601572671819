import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Moment from 'moment';
export default function Admin_executive_earnings(props) {
  useEffect(() => {
    load();
  }, []);
  const [data, setData] = useState([]);
  const load = () => {
    axios
      .get('/api/SuperAdmin/Admin_created_accounts_by_sub_Admin')
      .then((res) => {
        if (res.data) {
          const d = res.data.filter((item) => item !== null);
          setData(d);
        }
      });
  };
  var columns = [
    {
      field: 'id',
      headerName: 'No. ',
      width: 60,
    },
    {
      field: 'pname',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 200,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 200,
    },
    {
      field: 'subcategorys',
      headerName: 'Subcategory',
      width: 200,
    },
    {
      field: 'categorys',
      headerName: 'Category',
      width: 200,
    },
    {
      field: 'created_date',
      headerName: 'Created On',
      width: 200,
    },
    {
      field: 'Account_holder_account_subscription_type',
      headerName: 'Membership Type',
      width: 200,
    },

    {
      field: 'Commission_percentage',
      headerName: 'Commission Percentage (%)',
      width: 250,
    },
    {
      field: 'Commission_amount',
      headerName: 'Commission Amount',
      width: 200,
    },
    {
      field: 'subadmin',
      headerName: 'AME',
      width: 200,
    },

    {
      field: 'Admin_payment_settled_Status',
      headerName: 'Payment Status',
      width: 200,
      cellStyle: (status) => {
        console.log(status);
        return status === 'Settled'
          ? {
              color: 'green',
              cursor: 'pointer',
              '&::before': {
                content: '✓',
                color: 'white',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: '16px',
              },
            }
          : { color: 'yellow' };
      },
    },
    {
      field: 'Payment_transaction_id',
      headerName: 'Payment Transaction Id',
      width: 200,
    },
    {
      field: 'Payment_Dates',
      headerName: 'Payment Date',
      width: 200,
    },
    {
      field: 'Update',
      headerName: 'Update',
      width: 150,
      renderCell: (params) => {
        return params.row?.FixedSalary != true ? (
          <button
            className="btn pro-spaces-button3"
            onClick={(e) =>
              openFirstModal(
                params.rowIndex,
                params.row._id,
                params.row.Sub_Admin_login_id,
                params.row.Account_holder_login_id,
                params
              )
            }
          >
            Edit
          </button>
        ) : (
          ''
        );
      },
    },
  ];
  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [paymentId, setPaymentId] = useState('');
  const [paymentDate, setPaymentDate] = useState('');
  const [password, setPassword] = useState('');
  const [UpdateDetails, setUpdateDetails] = useState({
    _id: '',
    index: '',
    Account_holder_login_id: '',
    Sub_Admin_login_id: '',
  });

  const [SendpaymentId, setSendpaymentId] = useState();
  const [SendpaymentDate, setSendpaymentDate] = useState();
  const openFirstModal = (index, id, login_id, account_id, params) => {
    console.log('Button clicked for row:', params);
    const rowId = params.row.id;
    const rowIndex = data.findIndex((row) => row.id === rowId);
    if (rowIndex !== -1) {
      // Perform the desired action using the rowIndex
      console.log('Button clicked for row:', rowIndex);
    }

    setUpdateDetails({
      _id: id,
      index: rowIndex,
      Account_holder_login_id: account_id,
      Sub_Admin_login_id: login_id,
    });
    setIsFirstModalOpen(true);
  };

  const closeFirstModal = () => {
    setIsFirstModalOpen(false);
    setPaymentId('');
    setPassword('');
    setPaymentDate('');
    setUpdateDetails({
      _id: '',
      index: '',
      Account_holder_login_id: '',
      Sub_Admin_login_id: '',
    });
  };

  const [error, setError] = useState('');

  const handlePaymentUpdate = (id, date, password) => {
    console.log(id, date, password);
    // setPaymentId(id);
    // setPaymentDate(date);
    // setPassword(password);
    var paymentId = id;
    var paymentDate = date;
    var password = password;
    // Perform the update logic and send request to the backend
    // using the updated paymentId, paymentDate, and password
    console.log('Payment ID:', paymentId);
    console.log('Payment Date:', paymentDate);
    console.log('Password:', password);
    console.log('UpdateDetails :', UpdateDetails);
    if (!paymentId.length > 0) {
      return setError('Enter payment id');
    }
    setError('');
    if (!paymentDate.length > 0) {
      return setError('Enter payment date');
    }
    setError('');

    axios
      .post('/api/SuperAdmin/Update_payment_id_by_supper_admin', {
        paymentId,
        paymentDate,
        password,
        UpdateDetails,
      })
      .then((res) => {
        if (res.data.acknowledged && res.data.modifiedCount === 1) {
          // console.log(res.data)
          date = Moment(date).format('DD-MM-YYYY');
          handleUpdatePaymentId(UpdateDetails.index, id, date);
          setPaymentId('');
          setPassword('');
          setPaymentDate('');
          load();
          setUpdateDetails({
            _id: '',
            index: '',
            Account_holder_login_id: '',
            Sub_Admin_login_id: '',
          });
          closeFirstModal();
          notify('Updated', true);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          notify('Wrong security pin', false);
        } else {
          notify('Something went wrong', false);
        }
      });
  };
  const handleUpdatePaymentId = (index, newPaymentId, paymentDate) => {
    const updatedData = [...data];
    updatedData[index].Payment_transaction_id = newPaymentId;
    updatedData[index].Payment_Dates = paymentDate;
    updatedData[index].Admin_payment_settled_Status = 'Settled';
    setData(updatedData);
  };

  return (
    <div className="container-fluid bg-light">
      <Box sx={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={data}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          // checkboxSelection
          // disableRowSelectionOnClick
        />
      </Box>

      <>
        <center>
          <FirstModal
            isOpen={isFirstModalOpen}
            closeModal={closeFirstModal}
            handlePaymentUpdate={handlePaymentUpdate}
            error={error}
          />

          {/* <SecondModal
      isOpen={isSecondModalOpen}
      closeModal={closeSecondModal}
      handlePasswordUpdate={handlePasswordUpdate}
    /> */}
        </center>
      </>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
const FirstModal = ({ isOpen, closeModal, handlePaymentUpdate, error }) => {
  const [paymentId, setPaymentId] = useState('');
  const [paymentDate, setPaymentDate] = useState('');

  const handlePaymentIdChange = (e) => {
    setPaymentId(e.target.value);
  };

  const handlePaymentDateChange = (e) => {
    setPaymentDate(e.target.value);
  };

  const handleUpdatePayment = () => {
    // Perform the update logic and call the handlePaymentUpdate function
    handlePaymentUpdate(paymentId, paymentDate, password);
  };

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    if (!isOpen) {
      setShowPassword('');
      setPaymentDate('');
      setPaymentId('');
    }
  }, [isOpen]);

  return (
    <div>
      <Modal show={isOpen} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update payment details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <TextField
                label="Payment Transaction ID"
                value={paymentId}
                onChange={handlePaymentIdChange}
                fullWidth
              />
            </div>
            <div className="col-12 mt-2">
              <TextField
                label="Payment Date"
                type="date"
                value={paymentDate}
                onChange={handlePaymentDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </div>
            <div className="col-12">
              <div className="mt-2">
                <TextField
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={handlePasswordChange}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePasswordVisibility}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="pt-2">
              <p style={{ color: 'red' }}>{error} </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleUpdatePayment}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
