import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { notify } from '../../../Utility/notify';

export const Accountdetails = () => {
  const [value, setvalue] = useState({
    Email: '',
  });

  const [pass, setpass] = useState({
    OldPassword: '',
    NewPassword: '',
  });

  useEffect(() => {
    axios
      .get('/api/Admin/usernamchange')
      .then((req) => {
        console.log(req.data);
        setvalue(req.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const submit = () => {
    axios
      .post('/api/Admin/usernamchangevalue', value)
      .then((req) => {
        console.log('req');
        notify('UserName changed', true);

      })
      .catch((err) => {
        console.log(err);
        notify('something went wrong', false);

      });
  };

  const update = () => {
    axios
      .post('/api/Admin/changepassword', pass)
      .then((req) => {
        console.log('req');
        handleClose111();

        notify('Password changed', true);
      })
      .catch((err) => {
        console.log(err);
        notify('something went wrong', false);
      });
  };

  const [showpassword, setShowpassword] = useState(false);

  const handleClose111 = () => setShowpassword(false);
  const handleShow111 = () => setShowpassword(true);
  const [showPassword12, setShowPassword12] = useState(false);
  const [showPassword123, setShowPassword123] = useState(false);
  return (
    <div>
      <h3 className="text-center mt-4 mb-5">Account Settings</h3>

      <div className=" mx-3 d-flex justify-content-end">
        <button
          className="btn pro-spaces-button3"
          onClick={(e) => {
            handleShow111(e);
          }}
        >
          Change password
        </button>
      </div>

      <div className="mx-3 pt-3">
        <label className="labels">Username</label>
        <input
          className="form-control"
          type="text"
          value={value?.Email}
          onChange={(e) => {
            setvalue({ ...value, Email: e.target.value });
          }}
        />
        <div className="mt-3 d-flex justify-content-center">
          <button className=" btn pro-spaces-button3" onClick={submit}>
            Update
          </button>
        </div>
      </div>

      <Modal show={showpassword} onHide={handleClose111} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <label className="labels">Old password *</label>
                <div>
                  <input
                    class="form-control passInout12321"
                    placeholder="Enter old Password"
                    aria-describedby="basic-addon2"
                    // type={showPassword12 ? 'text' : 'password'}
                    // value={NewPassword.oldPassword}
                    // onChange={(e) =>
                    //   setNewPassword({
                    //     ...NewPassword,
                    //     oldPassword: e.target.value,
                    //   })
                    // }
                    type="password"
                    onChange={(e) => {
                      setpass({ ...pass, OldPassword: e.target.value });
                    }}
                  />
                  &nbsp;
                  <span class="showbutton123321">
                    {/* {showPassword12 ? (
                      <RiEyeFill
                        onClick={(e) => {
                          setShowPassword12(!showPassword12);
                        }}
                      />
                    ) : (
                      <RiEyeOffFill
                        onClick={(e) => {
                          setShowPassword12(!showPassword12);
                        }}
                      />
                    )} */}
                  </span>
                </div>
              </div>

              <div className="col-md-12">
                <label className="labels">New password *</label>
                <div>
                  <div>
                    <input
                      class="form-control passInout12321"
                      placeholder="Enter new Password"
                      //   type={showPassword123 ? 'text' : 'password'}
                      //   value={NewPassword.newPassword}
                      //   onChange={(e) =>
                      //     setNewPassword({
                      //       ...NewPassword,
                      //       newPassword: e.target.value,
                      //     })
                      //   }
                      type="password"
                      onChange={(e) =>
                        setpass({ ...pass, NewPassword: e.target.value })
                      }
                    />

                    <span class="showbutton123321" type="button">
                      {' '}
                      {/* {showPassword123 ? (
                        <RiEyeFill
                          onClick={(e) => {
                            setShowPassword123(!showPassword123);
                          }}
                        />
                      ) : (
                        <RiEyeOffFill
                          onClick={(e) => {
                            setShowPassword123(!showPassword123);
                          }}
                        />
                      )} */}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p style={{ color: 'red' }}>{}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn pro-spaces-button3" onClick={update}>
            Update
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
