import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Mask } from "../../Utility/mask";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
function ConsultantsList(props) {
  console.log(props.data.product.Type);
  let navigate = useNavigate();
  var ids = props.data.product._id;
  const [loading, setloading] = useState(false);

  const continuereg = () => {
    setloading((prev) => !prev);
    if (props.data.product.Status === "CP1") {
      axios
        .post("/api/Admin/continuereg", {
          ids,
        })
        .then((res) => {
          if (props.data.product.Type === "Active") {
            navigate("/next/");
          } else if (props.data.product.Type === "Activev2") {
            navigate("/next_/");
          } else if (props.data.product.Type === "Activev3") {
            navigate("/next__/");
          } else if (props.data.product.Type === "Passive") {
            navigate("/passive_profile_creation");
          }

          setloading((prev) => !prev);
        })
        .catch((err) => {
          setloading((prev) => !prev);
        });
    } else if (props.data.product.Status === "CP2") {
      if (props.type == "AffiliatedAccount") {
        navigate(`/payment_by_affliate?id=${ids}`);
      } else {
        navigate(`/payment?id=${ids}`);
      }
    }
  };

  return (
    <tr scope="row">
      <th scope="row">{props.data.index + 1}</th>
      <td>
        {props.data.product.EmailLogin
          ? props.data.product.Email
          : props.data.product.Contact}
      </td>
      <td>{props.data.product.Contact ? props.data.product.Contact : "-"}</td>
      <td>{props.data.product.Email ? props.data.product.Email : "-"}</td>
      <td>
        {props.data.product.Status === "CP1"
          ? "Pending profile creation"
          : "Pending payment"}
      </td>
      <td>
        {props.data.product.Status === "CP1" ? (
          loading ? (
            <button className="btn pro-spaces-button3" type="button" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Loading...</span>
            </button>
          ) : (
            <button className="btn pro-spaces-button3" onClick={continuereg}>
              Continue
            </button>
          )
        ) : loading ? (
          <button className="btn pro-spaces-button3" type="button" disabled>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Loading...</span>
          </button>
        ) : (
          <button className="btn pro-spaces-button3" onClick={continuereg}>
            Continue
          </button>
        )}
      </td>
      <td>
        {props.data.product.Status === "CP2" &&
        props.data.product.login[0]?.Type != "Passive" ? (
          <PreviewModalComponent
            account_id={props.data.product.login[0]?._id}
            Type={props.data.product.login[0]?.Type}
          />
        ) : (
          ""
        )}
      </td>
    </tr>
  );
}
export default ConsultantsList;

function PreviewModalComponent({ account_id, Type }) {
  console.log(account_id);
  const [show, setShow] = useState(false);
  var Url;
  // Url="http://localhost:3001/"

  Url = "https://www.prochure.in/";
  var accountsLink;

  if (
    Type == "Consultant" ||
    Type == "Consultantv2" ||
    Type == "Consultantv3"
  ) {
    accountsLink = "services_profile_demo";
  } else {
    accountsLink = "products_profile_demo";
  }

  console.log(Url + accountsLink + "?Ac_id=" + Mask.encode(account_id));

  const openModal = () => {
    setShow(true);
  };

  return (
    <>
      <button
        variant="primary"
        onClick={() => openModal(true)}
        className="btn pro-spaces-button3"
      >
        Preview
      </button>
      <div style={{ zIndex: "20000" }}>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          aria-labelledby="example-custom-modal-styling-title"
          dialogClassName="modal-xxl-down"
          size="xxl-down"
          centered
          style={{ zIndex: "20000" }}
          fullscreen
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Preview
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              src={`${Url}${accountsLink}?Ac_id=${Mask.encode(account_id)}`}
              width="100%"
              height="100%"
              title="Modal Content"
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
