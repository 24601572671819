import React from 'react';

export const Viparheader = () => {
  return (
    <div>
      <div className="text-center">
        <span
          className="vipar"
          style={{
            color: 'green',
            fontSize: '60px',
            paddingRight: '5px',
          }}
        >
          Vipar{' '}
        </span>{' '}
        <sup
          style={{
            fontSize: '30px',
          }}
        >
          ®
        </sup>
        <div style={{ color: 'blue', fontSize: '20px' }}>
          # 103, 2<sup>nd</sup> floor, 2023/A, 14<sup>th</sup> Main,
          Jeevanbheema Nagar, HAL 2<sup>nd</sup> Stage, Indiranagar, Bengaluru -
          38
          <br /> Landline : 080 – 417 00022
        </div>
      </div>
    </div>
  );
};
