import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './payment.css';
import { Footer } from '../HomePage/Footer';
import { SpinnerBig } from '../../Utility/spinner';
// import { Navoutside } from "../../Components/Navoutside/Navoutside";

function displayRazorpay(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export function Payment2(props) {
  let [searchParams, setSearchParams] = useSearchParams();
  var ids = searchParams.get('id');
  useEffect(() => {
    Checks();
    props.Auth1();
  }, []);
  useEffect(() => {
    Checks();
  }, []);

  const [Active, setActive] = useState('');
  const [Activev2, setActivev2] = useState('');
  const [Activev3, setActivev3] = useState('');
  const [PAssive, setPAssive] = useState('');
  const [RefCode, setRefCode] = useState('');

  const [UserLoading, setUserLoading] = useState(false);

  const Checks = () => {
    axios
      .post('/api/Admin/checkforpayment', {
        ids,
      })
      .then((res) => {
        console.log(res);
        setfirst(res.data);
        setUserLoading(true);
      })
      .catch((err) => {
        console.log('error');
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get('/api/free/getsubscriptionfees')
      .then((res) => {
        console.log(res.data);
        setActive(res.data.Active);
        setActivev2(res.data.Activev2);
        setActivev3(res.data.Activev3);
        setPAssive(res.data.Passive);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    console.log(Activev2);

    return () => {};
  }, [Activev2]);

  const [loading, setloading] = useState(false);
  const [first, setfirst] = useState(null);
  const [Authenticated, setAuthenticated] = useState(false);
  let navigate = useNavigate();
  async function RazorPay() {
    setloading(true);
    const res = await displayRazorpay(
      'https://checkout.razorpay.com/v1/checkout.js'
    );
    if (!res) {
      alert('failed sdk to load');
      setloading(false);
      return;
    }
    var tx = {
      Coupon,
    };
    axios
      .post('/api/payment/CreatePaymentByAffiliated', {
        method: 'POST',
        body: Coupon,
        first,
      })
      .then((t) => {
        setloading(false);
        var data = t.data;
        console.log(data);
        var options = {
          key: process.env.KEY_ID, // Enter the Key ID generated from the Dashboard S6PuOik0Pm6t7RcAyX8Ws5i2
          amount: data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: 'INR',
          name: 'PROchure',
          order_id: data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          handler: function (response) {
            success(response);
          },

          theme: {
            color: '#55374a',
          },
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response) {
          setloading(false);
          // alert(response.error.code);
          // alert(response.error.description);
          // alert(response.error.source);
          // alert(response.error.step);
          alert(response.error.reason);
          // alert(response.error.metadata.order_id);
          // alert(response.error.metadata.payment_id);
        });
        // document.getElementById('rzp-button1').onclick = function (e) {
        rzp1.open();
        // e.preventDefault();
      });

    function success(res) {
      // alert('Congratulations....You are now a PRO- member !!')
      console.log(res);
      axios
        .post('/api/payment/successs', { res, GSTIN, RefCode })
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data);
            return navigate('/c_v_registration');
            // if (res.data === "Consultant") {
            //   return navigate("/welcome?welcome=success");
            // } else if (res.data === "Consultantv2") {
            //   return navigate("/");
            // } else if (res.data === "Consultantv3") {
            //   return navigate("/");
            // } else if (res.data === "Productv2") {
            //   return navigate("/");
            // } else if (res.data === "Productv3") {
            //   return navigate("/");
            // } else if (res.data === "Product") {
            //   return navigate("/welcome");
            // } else if (res.data === "Passive") {
            //   return navigate("/");
            // }
          }
        })
        .catch((err) => {});
    }
  }

  const [couponError, setcouponError] = useState('');
  const [couponsuccess, setcouponsuccess] = useState('');
  const [Coupon, setCoupon] = useState();

  const checkCoupe = async () => {
    setCoupon('');
    setcouponError('');
    setcouponsuccess('');
    let coupon = document.getElementById('coupe').value;
    let dataSend = {
      coupon,
    };
    try {
      if (coupon.length > 5) {
        let result = await axios.post('/api/coupon/check', { dataSend });
        if (result) {
          console.log(result);
          setCoupon(coupon);
          setcouponError('');
          setcouponsuccess(
            'Coupon applied ! Now click on Make Payment to proceed further'
          );
        }
      } else {
        setcouponError('Invalid coupon / coupon withdrawn');
        console.log('first');
      }
    } catch (error) {
      console.log(error.response.data);
      setcouponError(error.response.data);
    }
  };

  const StartMyFreeTrail = async () => {
    try {
      let send = await axios.post('/api/coupon/StartFreeTrail');
      if (send.status === 200) {
        navigate('/');
      }
    } catch (err) {}
  };

  const [GSTIN, setGSTIN] = useState();

  return (
    <>
      {/* <Navoutside /> */}
      <div style={{}}>
        {!UserLoading ? (
          <SpinnerBig />
        ) : (
          <div className=" container d-flex justify-content-center pt-5">
            <div
              style={{ maxWidth: '500px', minHeight: '300px' }}
              className="card  carrrrbuss"
            >
              <div className="container p-1">
                {first ? (
                  first === 'Product' ||
                  first === 'Consultant' ||
                  first === 'Productv2' ||
                  first === 'Consultantv2' ||
                  first === 'Productv3' ||
                  first === 'Consultantv3' ||
                  first === 'Passive' ? (
                    <>
                      <div className="row ">
                        <center>
                          {' '}
                          <h3 className="eufgiueg mb-1">
                            {' '}
                            {first
                              ? first === 'Product' || first === 'Consultant'
                                ? 'PRIME league'
                                : first === 'Productv2' ||
                                  first === 'Consultantv2'
                                ? 'ELITE club'
                                : first === 'Productv3' ||
                                  first === 'Consultantv3'
                                ? 'BASIC listing'
                                : first === 'Passive'
                                ? 'Clients, Customers'
                                : ''
                              : ''}
                          </h3>
                          <p>
                            ( ₹{' '}
                            {first
                              ? first === 'Product' || first === 'Consultant'
                                ? Active
                                : first === 'Productv2' ||
                                  first === 'Consultantv2'
                                ? Activev2
                                : first === 'Productv3' ||
                                  first === 'Consultantv3'
                                ? Activev3
                                : first === 'Passive'
                                ? PAssive
                                : ''
                              : ''}
                            /- inclusive of 18% GST )
                          </p>
                        </center>
                      </div>

                      <div>
                        {first === 'Product' || first === 'Consultant' ? (
                          <>
                            <p className="mt-4">
                              Use the below mentioned COUPON CODE for a 50%
                              Discount
                              <br></br>
                              <br></br>
                              PRIME2500 for only ₹ 2500/-
                            </p>
                          </>
                        ) : first === 'Productv3' ||
                          first === 'Consultantv3' ? (
                          <>
                            <p className="mt-4">
                              Use the below mentioned COUPON CODE for a 50%
                              Discount
                              <br></br>
                              <br></br>
                              BASIC250 for only ₹ 250/-<br></br>
                            </p>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="row">
                        {first ? (
                          first === 'Product' ||
                          first === 'Consultant' ||
                          first === 'Productv2' ||
                          first === 'Consultantv2' ||
                          first === 'Productv3' ||
                          first === 'Consultantv3' ? (
                            // <p className="text-center">
                            //   ONLY for
                            //   Service&nbsp;Providers&nbsp;and&nbsp;Product&nbsp;Retailers
                            // </p>
                            <></>
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}

                        <p className="invisible mb-1">
                          wwwwwwwwwwwwwwwwwwwwwwww
                        </p>
                      </div>
                      {/* <div className='row d-flex justify-content-center'>
                               <input type="text" id='coupe' className='textboxdrete bg-light'></input>
                               <div className='col d-flex justify-content-end py-3'>
                                   <button onClick={(e) => checkCoupe(e)} className='btn wygdiwgdigwidg'>Apply</button>
                               </div>
                           </div> */}

                      {/* <h6 className="eufgiueg mt-2">Referral Code / Name</h6>
                 <div className="container ">
                   <div className="row">
                     <div className="col-12 m-0 p-0">
                       {" "}
                       <input
                         type="text"
                         placeholder="Enter Referral Code / Name "
                         className="form-control bg-light"
                         value={RefCode}
                         onChange={(e) => setRefCode(e.target.value)}
                       />
                     </div>
                   </div>
                 </div> */}

                      <h6 className="eufgiueg mt-2">Discount Coupon</h6>
                      <div className="container ">
                        <div className="row">
                          <div className="col-md-9 col-8 m-0 p-0">
                            {' '}
                            <input
                              type="text"
                              id="coupe"
                              placeholder="Enter Coupon Code "
                              className="form-control bg-light"
                            />
                          </div>
                          <div className="col-md-3 col-4 m-0 p-0 ps-1">
                            {' '}
                            <button
                              onClick={(e) => checkCoupe(e)}
                              className="btn px-3 w-100 pro-spaces-button3"
                            >
                              APPLY
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="mt-2">
                        <p>
                          If you need a GST invoice for your subscription, then
                          please mention your GST number below
                        </p>
                        <input
                          onChange={(e) => setGSTIN(e.target.value)}
                          placeholder="Enter GST registration number"
                          className="form-control"
                          type={'text'}
                        />
                      </div>

                      <div className="row mt-2">
                        <p>
                          A copy of your Invoice will be sent to your email id.
                          You can also View and Download your Invoice from
                          'MEMBERSHIP SUBSCRIPTION DETAILS' in 'Subscription
                          Details and Account Settings' on your Profile tab.
                        </p>
                      </div>
                      <div className="row ">
                        <div className="col"></div>
                        <p className="text-success">
                          {couponsuccess ? couponsuccess : ''}
                        </p>
                        <p className="text-danger">
                          {couponError ? couponError : ''}
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="row ">
                        <center>
                          {/* <h3 className='eufgiueg mb-1'> BASIC MEMBERSHIP</h3> */}
                          {/* <p>A Service Fee of ₹ {PAssive} /- is applicable</p> */}
                        </center>
                      </div>
                      <div className="mt-2 mb-4">
                        {/* <p>If you need a GST invoice for your subscription, then please mention your GST number below</p>
                                   <input onChange={(e) => setGSTIN(e.target.value)} className='form-control' type={'text'} /> */}
                      </div>

                      {/* <h6 className="eufgiueg mt-2">Referral Code / Name</h6>
                 <div className="container ">
                   <div className="row">
                     <div className="col-12 m-0 p-0">
                       {" "}
                       <input
                         type="text"
                         placeholder="Enter Referral Code / Name "
                         className="form-control bg-light"
                         value={RefCode}
                         onChange={(e) => setRefCode(e.target.value)}
                       />
                     </div>
                   </div>
                 </div> */}

                      {/* <h6 className="eufgiueg mt-2">Discount Coupon</h6>
                 <div className="container ">
                   <div className="row">
                     <div className="col-md-9 col-8 m-0 p-0">
                       {" "}
                       <input
                         type="text"
                         id="coupe"
                         placeholder="Enter Coupon Code "
                         className="form-control bg-light"
                       />
                     </div>
                     <div className="col-md-3 col-4 m-0 p-0 ps-1">
                       {" "}
                       <button
                         onClick={(e) => checkCoupe(e)}
                         className="btn px-3 w-100 wygdiwgdigwidg"
                       >
                         APPLY
                       </button>
                     </div>
                   </div>
                 </div>

                 <div className="mt-2">
                   <p>
                     If you need a GST invoice for your subscription, then
                     please mention your GST number below
                   </p>
                   <input
                     onChange={(e) => setGSTIN(e.target.value)}
                     placeholder="Enter GST registration number"
                     className="form-control"
                     type={"text"}
                   />
                 </div>
       



                 <div className="row mt-2">
                   <p>
                     A copy of your Invoice will be sent to your email id. You
                     can also View and Download your Invoice from 'MEMBERSHIP
                     SUBSCRIPTION DETAILS' in 'Subscription Details and Account
                     Settings' on your Profile tab.
                   </p>
                 </div>
                 <div className="row ">
                   <div className="col"></div>
                   <p className="text-success">
                     {couponsuccess ? couponsuccess : ""}
                   </p>
                   <p className="text-danger">
                     {couponError ? couponError : ""}
                   </p>
                 </div> */}
                    </>
                  )
                ) : (
                  <></>
                )}

                <div className="row mt-2">
                  {!loading && (
                    <button
                      className="btn btn pro-spaces-button3"
                      style={{ borderRadius: '50px' }}
                      onClick={RazorPay}
                    >
                      {first === 'Product' ||
                      first === 'Productv2' ||
                      first === 'Productv3' ||
                      first === 'Consultant' ||
                      first === 'Consultantv2' ||
                      first === 'Consultantv3' ||
                      first === 'Passive'
                        ? 'PAY'
                        : 'Please Wait'}
                    </button>
                  )}
                  {loading && (
                    <button
                      className="btn pro-spaces-button3"
                      style={{ borderRadius: '10px' }}
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  )}
                </div>
                <div className="row mt-3 in d-none">
                  <button
                    className="btn btn-udaguadgouao"
                    onClick={(e) => StartMyFreeTrail()}
                  >
                    Start One Day Free Trial
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </>
  );
}
