import axios from 'axios';
import React, { useState } from 'react';
import { notify } from '../../Utility/notify';
import { useEffect } from 'react';

export const Ameupdatetermsandcondition = () => {
  const [first, setfirst] = useState(false);

  const submit = () => {
    axios
      .post('/api/AffiliatedAccount/ameupdatetermsandcondition')
      .then((res) => {
        console.log('as');
        notify('Accepted', true);
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        notify('Something Went Wrong', false);
      });
  };

  // useEffect(() => {
  //  document.getElementById("popup").click();
  // }, [])

  const [State, setState] = useState(false);
  useEffect(() => {
    axios
      .post('/api/AffiliatedAccount/studentfind', {})
      .then((res) => {
        console.log(res.data);
        setState(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="container bg-light">
        <h5 className="mt-4 ">TERMS and CONDITIONS *</h5>
        <h6 className="pt-4">CONSENT :</h6>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault1">
            <ul>
              <li>
                I am aware and agree that I fully understand that{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup>®</sup> is the Organisation (based out of Bengaluru,
                Karnataka) with which I am associated as a Field Sales Executive
                and that <span className="prochure">PROchure</span>{' '}
                {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                is a digital web and mobile application developed by{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <span
                  className="prochure"
                  style={{ fontSize: '16px', color: 'black' }}
                >
                  CONNECT
                </span>
                , which is a unit of{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup>®</sup>.
              </li>
            </ul>
          </label>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault2">
            <ul>
              <li>
                I am ready to enrol myself as a Field Sales Executive at{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> to get potential interested customers to
                subscribe on the digital platform called{' '}
                <span className="prochure">PROchure</span>{' '}
                {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                (an app accessible to users across the globe) which is primarily
                a digital directory of Services and Products.
              </li>
            </ul>
          </label>
        </div>
        {State?.Studentornot == 'Student' ? (
          <div class=" pt-3">
            <label class="form-check-label" for="flexCheckDefault9">
              <ul>
                <li>
                  I admit and agree that I am joining{' '}
                  <span
                    className="vipar"
                    style={{
                      color: 'green',
                      fontSize: '20px',
                      paddingRight: '5px',
                    }}
                  >
                    Vipar
                  </span>{' '}
                  <sup class="">®</sup> as a Sales Intern with the knowledge and
                  consent of my family and the concerned authority of the
                  College / Institution where I am currently pursuing my
                  education, and that IF need arises, I SHALL get the required
                  consent letter from the College / Institution.
                </li>
              </ul>
            </label>
          </div>
        ) : (
          <></>
        )}
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault2">
            <ul>
              <li>
                I am aware and agree that this Registration Form itself is my
                Appointment Letter and SHALL be considered as the mutually
                agreed upon Terms and Conditions between myself and{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup>.
              </li>
            </ul>
          </label>
        </div>
        <h6 className="pt-4">SALES PROTOCOLS :</h6>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault2">
            <ul>
              <li>
                I am aware and agree that I SHALL introduce myself to each and
                every customer with my unique{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> Employee ID card (issued to me by the
                Organisation) and will co-operate with the respective customer
                to scan the QR code on my ID card to Authenticate my employment
                with{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup>.
              </li>
            </ul>
          </label>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault8">
            <ul>
              <li>
                I am aware and agree that I SHALL read, understand and explain
                the features, benefits, fee structure and membership
                subscription details of the app to each and every customer with
                clarity.
              </li>
            </ul>
          </label>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault3">
            <ul>
              <li>
                I am aware and agree that I SHALL strictly follow the
                instructions regarding how a profile should be created for a
                customer and shall leave no scope for errors.
              </li>
            </ul>
          </label>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault4">
            <ul>
              <li>
                I am aware and agree that I SHALL enrol subscribers on to the{' '}
                <span className="prochure">PROchure</span> platform ONLY via the
                web app www.prochure.in or the mobile apps of{' '}
                <span className="prochure">PROchure</span> available on Google
                Play Store and App Store in the name of{' '}
                <span className="prochure">PROchure</span> and SHALL NOT take
                subscriptions via any other online / offline method.
              </li>
            </ul>
          </label>
        </div>

        <h6 className="pt-4">PROFESSIONAL CONDUCT / ETHICS :</h6>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault5">
            <ul>
              <li>
                I am aware and agree that I SHALL NOT make any false claims /
                false promises to the potential subscribers regarding the
                features and benefits of using the platform or mislead them with
                incorrect or wrong information regarding the app only for the
                sake of getting their subscription.
              </li>
            </ul>
          </label>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that I SHALL NOT say anything to the
                potential subscribers at my own discretion beyond what has been
                instructed to me by{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> and that I SHALL maintain absolute
                professional decorum as a representative of{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> during my course of interaction with
                prospective customers.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault13">
            <ul>
              <li>
                I am aware and agree that I SHALL strictly abide by the rules
                and regulations of the organisation, and all the Terms and
                Conditions mentioned here and that I SHALL NOT indulge in any
                activity that will directly or indirectly spoil the reputation
                of the digital platform ({' '}
                <span className="prochure">PROchure</span>{' '}
                {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}) and the
                organisation ({' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> ).
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            <ul>
              <li>
                I am aware and agree that I SHALL neither lose my temper nor get
                into any kind of physical or verbal fights with a customer even
                if it is a mistake on the part of my customer and SHALL politely
                relieve myself from the customer’s place in any such untoward
                scenario.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault13">
            <ul>
              <li>
                I am aware and agree that I SHALL NOT post any content on any
                social media platform or any other digital platform against{' '}
                <span className="prochure">PROchure</span> or
                {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> with the intention to tarnish the
                reputation and goodwill of both{' '}
                <span className="prochure">PROchure</span> and
                {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup>, purely out of personal grudge and without
                substantiating evidence.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            <ul>
              <li>
                I am aware and agree that IF I flout any or all of the above
                mentioned clauses in this section, then I SHALL be Terminated
                with immediate effect.
              </li>
            </ul>
          </label>
        </div>

        <h6 className="pt-4">FINANCIAL PROTOCOLS :</h6>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that I SHALL collect the Subscription Fee,
                from any individual who wishes to create a business listing /
                profile of his/her on the platform, ONLY via the UPI payment
                method on the web and mobile apps of{' '}
                <span className="prochure">PROchure</span> to the{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> account and NOT to any of my personal
                accounts or to any other account of person(s) / company known to
                me.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that I SHALL NOT collect this Subscription
                Fee, from any individual who wishes to create a business listing
                / profile of his/her on the platform, either in the form of cash
                / cheque / DD / net transfer such as IMPS, NEFT, RTGS / QR code
                scanning or UPI payment method to any external account including
                my own account.
              </li>
            </ul>
          </label>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            <ul>
              <li>
                I am aware and agree that IF I flout any or both of the above
                mentioned clauses in this section, then I SHALL be Terminated
                with immediate effect.
              </li>
            </ul>
          </label>
        </div>
        <h6 className="pt-4">REMUNERATION :</h6>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            <ul>
              <li>
                I am aware and agree that the term ‘Remuneration’ in this
                Agreement SHALL refer ONLY to the Commissions on Percentage
                basis from each Subscription sale.
              </li>
            </ul>
          </label>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            <ul>
              <li>
                I am aware and agree that my Remuneration for Subscription sales
                SHALL be as per the slabs mentioned in the table below :
              </li>
            </ul>
          </label>
          <div className="table-responsive pt-3">
            <table class="table table-sm align-middle table-bordered">
              <thead>
                <tr className="text-center">
                  <th
                    className="d-flex justify-content-center align-items-center"
                    scope="col"
                  >
                    NUMBER OF SALES
                  </th>
                  <th scope="col">
                    COMMISSION PERCENTAGE
                    <br />
                    <span style={{ fontWeight: '400' }}>
                      per subscription sale
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center">
                  <td>1 to 1000</td>
                  <td>35%</td>
                </tr>
                <tr className="text-center">
                  <td>1001 to 5000</td>
                  <td>40%</td>
                </tr>
                <tr className="text-center">
                  <td>5001 and above</td>
                  <td>50%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that my Remuneration SHALL be settled in
                full on every Sunday.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            <ul>
              <li>
                I am aware and agree that for every 1000 (ONE THOUSAND)
                Subscription sales I achieve upto a total of 5000 (FIVE
                THOUSAND) subscriptions, WITHOUT using a Discount coupon, I
                SHALL be paid an INCENTIVE amount (beyond my Remuneration) of Rs
                25,000/- (Rupees TWENTY FIVE THOUSAND only).
              </li>
            </ul>
          </label>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            <ul>
              <li>
                I am aware and agree that for every 1000 (ONE THOUSAND)
                Subscription sales I achieve above 5000 (FIVE THOUSAND)
                subscriptions, WITHOUT using a Discount coupon, I SHALL be paid
                an INCENTIVE amount (beyond my Remuneration) of Rs 50,000/-
                (Rupees FIFTY THOUSAND only).
              </li>
            </ul>
          </label>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            <ul>
              <li>
                I am aware and agree that the Incentive amount SHALL be paid in
                full within 24 hours after achieving 1000 (ONE THOUSAND)
                Subscription sales.
              </li>
            </ul>
          </label>
        </div>

        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that I SHALL NOT be eligibile to get any
                reimbursement on the conveyance or phone call expenses that I
                incur as a part of my efforts to make subscription sales at{' '}
                <span className="prochure">PROchure</span>
                {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault14">
            <ul>
              <li>
                I am aware and agree that I SHALL NOT be eligible for any
                additional fixed daily, weekly or monthly payments beyond what
                is mutually agreed upon in this Agreement between myself and{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> .
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that I SHALL be eligible for my
                Remuneration from subscription sales ONLY IF my customer
                mandatorily mentions my Unique Referral Code on the payment page
                while creating his/her/their profile (customers) at{' '}
                <span className="prochure">PROchure</span>.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that my Remuneration SHALL ONLY be for
                Subscription Sales and SHALL NOT be for any failed efforts /
                attempts to make subscription sales irrespective of how much
                loss I have had to incur with respect to time, money and effort.
              </li>
            </ul>
          </label>
        </div>

        <h6 className="pt-4">RESIGNATION :</h6>
        <div class=" pt-3">
          <label class="form-check-label">
            <ul>
              <li>
                I am aware and agree that while resigning from the job, I SHALL
                send an email regarding my resignation to only the official
                marketing email id of{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup>, which is vipar.marketing.hr@gmail.com
                from my registered email id with Subject Title as ‘
                <span className="prochure">PROchure</span> FSE (Commission)
                RESIGNATION’ and clearly mentioning my Unique Referral Code.
                Else my resignation SHALL NOT be acceptable.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label">
            <ul>
              <li>
                I am aware and agree that I can resign from the Organisation
                without any notice period.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label">
            <ul>
              <li>
                I am aware and agree that both my Remuneration Certificate and
                Experience Certificate SHALL be issued to me ONLY IF I complete
                a minimum of 6 (SIX) Months of work duration with{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> as a FSE.
              </li>
            </ul>
          </label>
        </div>
        <h6 className="pt-4">RELIEVING FROM JOB :</h6>
        <div class=" pt-3">
          <label class="form-check-label">
            <ul>
              <li>
                I am aware and agree that IF the Organisation finds me inept for
                this Job or in a scenario where the Organisation decides to put
                an end to the marketing and sales of the app, then the
                Organisation SHALL have the authority to relieve me from this
                Job by giving me a Notice period of 1 (ONE) Month in writing via
                mail to my registered email id.
              </li>
            </ul>
          </label>
        </div>
        <h6 className="pt-4">NON-DISCLOSURE :</h6>
        <div class=" pt-3">
          <label class="form-check-label">
            <ul>
              <li>
                I am aware and agree that for as long as I am employed with{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup>, I SHALL NOT disclose any of the marketing
                strategies or internal confidential matters of the Organisation
                to any 3<sup>rd</sup> party, be it an individual or company, and
                that IF I do so, I SHALL be penalised for the same.
              </li>
            </ul>
          </label>
        </div>

        <h6 className="pt-4">LEGAL BINDING :</h6>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            <ul>
              <li>
                I am aware and agree that I SHALL be Terminated without any
                intimation or notification IF the Organisation detects any
                suspicious activity or unethical practice on my part during my
                course of sales of the app.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            <ul>
              <li>
                I am aware and agree that it SHALL be me and only me who will be
                fully and solely responsible for my bad and irresponsible
                behaviour with a customer and only I SHALL be held liable for
                the same legally and NOT any other employee / representative of
                the Organisation or the Head of the Organisation.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault15">
            <ul>
              <li>
                I am aware and agree that I SHALL be Terminated IF found to be
                involved in any kind of fraudulent activity which directly or
                indirectly is an act against the interests of either a
                subscriber of <span className="prochure">PROchure</span> or the
                Organisation or both.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that in case of Termination of my
                employment contract with{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> for any reason, then I SHALL NOT be
                eligible for my pending Remuneration if any, and that I SHALL be
                penalised and legally sued for monetary damages at the
                discretion of{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> .
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that any lapse(s) occuring from my end
                regarding ALL or ANY of the above mentioned mutually agreed upon
                Terms and Conditions SHALL solely be my responsibility and for
                which{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> SHALL NOT be held responsible or liable,
                both legally and otherwise.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault6">
            <ul>
              <li>
                I am aware and agree that these Terms and the relationship
                between myself and{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> SHALL be governed by the laws of
                Karnataka, India without regard to its conflict of law
                provisions.{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> and I agree that all disputes SHALL be
                subject to arbitration at Bengaluru, Karnataka in accordance
                with the Arbitration and Conciliation Act, 1996. The arbitration
                proceedings SHALL be conducted in the English language. That{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> and I hereby expressly and irrevocably
                attorn to the jurisdiction of the courts of Bengaluru with
                respect to any matter or claim, suit, action or proceeding
                arising under or related to this Agreement. I covenant NOT to
                sue{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> in any other forum.
              </li>
            </ul>
          </label>
        </div>
        <div>
          <h6 className="mt-4">TO BE NOTED :</h6>

          <ul className="ps-5 mt-2">
            <li className="pt-2">
              You can View the Accepted Terms and Conditions from your FSE
              account.
            </li>
            <li className="pt-2">
              You can Download, Print a copy of the Accepted Terms and
              Conditions for future reference.
            </li>

            <li className="pt-2">
              {' '}
              For any grievances, send us an email regarding the issue to
              vipar.marketing.hr@gmail.com from your registered email id with
              Subject Title as ‘<span className="prochure">PROchure</span> FSE
              (Commission) GRIEVANCE’ and clearly mentioning your Unique
              Referral Code.
            </li>
          </ul>

          <h6 className="mt-4">REFERRAL CODE :</h6>
          <ul className="ps-5 mt-2">
            <li className="pt-2" style={{ lineSpacing: '0' }}>
              During Registration, Create a Referral Code of your choice which
              will be your FSE Identification Code at{' '}
              <span
                className="vipar"
                style={{
                  fontSize: '22px',
                  color: '#99d050',
                }}
              >
                {' '}
                Vipar
              </span>{' '}
              <sup class="">®</sup>
            </li>
            <li className="pt-2" style={{ lineSpacing: '0' }}>
              Your Unique Referral Code is the one with which
              <span
                className="vipar"
                style={{
                  fontSize: '22px',
                  color: '#99d050',
                }}
              >
                {' '}
                Vipar
              </span>{' '}
              <sup class="">®</sup> can identify that it is via your FSE account
              that a Subscription is sold.
            </li>
            <li className="pt-2">
              It is Mandatory for tracking your sales targets and disbursing
              your Remuneration to you.
            </li>
            <li className="pt-2">
              Once a Referral Code is created, it is permanent and cannot be
              changed.
            </li>

            <li className="pt-2">
              Can be a combination of Alphabets, Numbers and Symbols.
            </li>
            <li className="pt-2">
              Minimum Character length &#8239;: 5<br />
              Maximum Character length : 10
            </li>
            <li className="pt-2">
              In case you forget your Referral Code, it will be visible to you
              on the ‘Account Details’ page of your profile.
            </li>
          </ul>
        </div>
        <h6 className="mt-4">BANK ACCOUNT DETAILS :</h6>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault7">
            <ul>
              <li>
                I am aware and agree that the bank account details I have
                mentioned is correct and wish to have my Remuneration credited
                to this account.
              </li>
            </ul>
          </label>
        </div>
        <div class=" pt-3">
          <label class="form-check-label" for="flexCheckDefault9">
            <ul>
              <li>
                I am aware and agree that any mistake / error in the bank
                account details entered by me SHALL NOT be the responsibility of
                the Organisation.
              </li>
            </ul>
          </label>
        </div>
        <div className="form-check px-5 pt-4">
          <input
            class="form-check-input affcheckbox"
            type="checkbox"
            id="flexCheckDefault1"
            onChange={(e) => setfirst(!first)}
          />

          <label>
            {' '}
            I have read and understood each and every clause mentioned above and
            agree to comply with all the Terms and Conditions upon registration.
          </label>
        </div>

        <div className="d-flex justify-content-center pb-5 pt-4">
          <button
            className="btn pro-spaces-button3"
            disabled={!first}
            onClick={submit}
          >
            Accept
          </button>
        </div>

        <button
          hidden
          type="button"
          class=""
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          id="popup"
        ></button>

        <div
          class="modal fade "
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Terms And Conditions{' '}
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div class="modal-body ">
                We have Updated our Terms and Conditions. Kindly read and agree
                to the new T & C to move forward.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
