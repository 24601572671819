import React, { useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Form } from 'react-bootstrap';
import Admin_executive_earnings_in_profile_page from './Admin_executive_earnings_in_profile_page';
import { SpinnerBig } from '../../Utility/spinner';
import UpdateCommissionPercentageSingleAME from './Components/update_Commission_percentage_Single_AME';
import axios from 'axios';
import moment from 'moment';
const notify = (message, sab) => {
  if (sab) {
    toast.success(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.warning(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '300',
    },
  },
};
export const SubAdminProfile = () => {
  let [searchParams, setSearchParams] = useSearchParams(); /////getting id from the url the id of the jobs id
  var Id = searchParams.get('k');
  const [value, setValue] = useState({
    Id: '',
    Type: '',
    Name: '',
    Email: '',
    Contact: '',
    state: '',
    city: '',
    Account_holder_name: '',
    Account_number: '',
    Ifsc_code: '',
    Bank_name: '',
    Branch_name: '',
    Status: '',
    Bank_account_type: '',
    Gender: '',
    Age: '',
    Nationality: '',
    Address: '',
    Pincode: '',
    FMName: '',
    FMRelationship: '',
    FMContact: '',
    FMAddress: '',
    FMPincode: '',
    FMcountry: '',
    FMcstate: '',
    FMcity: '',
    BloodGroup: '',
    unique_Code: '',
  });

  const [Loading, setLoading] = useState(true);

  React.useEffect(() => {
    setLoading(true);
    Axios.post('/api/Admin/GetOneSubAdmin', { Id })
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          setValue({
            ...value,
            Login_id: res.data[0].Login_id,
            Id: res.data[0]._id,
            Type: res.data[0].con[0].Type,
            Name: res.data[0].Name,
            Email: res.data[0]?.con[0]?.Email,
            Contact: res.data[0]?.con[0]?.Contact,
            state: res.data[0]?.state,
            city: res.data[0]?.city,
            Account_holder_name:
              res.data[0].con[0].Type == 'AffiliatedAccount'
                ? res.data[0].Account_holder_name
                : res.data[0]?.con[0]?.acc[0]?.Account_holder_name,
            Account_number:
              res.data[0].con[0].Type == 'AffiliatedAccount'
                ? res.data[0].Account_number
                : res.data[0]?.con[0]?.acc[0]?.Account_number,
            Ifsc_code:
              res.data[0].con[0].Type == 'AffiliatedAccount'
                ? res.data[0].Ifsc_code
                : res.data[0]?.con[0]?.acc[0]?.Ifsc_code,
            Bank_name:
              res.data[0].con[0].Type == 'AffiliatedAccount'
                ? res.data[0].Bank_name
                : res.data[0]?.con[0]?.acc[0]?.Bank_name,
            Branch_name:
              res.data[0].con[0].Type == 'AffiliatedAccount'
                ? res.data[0].Branch_name
                : res.data[0]?.con[0]?.acc[0]?.Branch_name,
            Referral_Code: res.data[0]?.Referral_Code,
            Status: res.data[0].con[0].Status,
            Bank_account_type:
              res.data[0].con[0].Type == 'AffiliatedAccount'
                ? res.data[0].Bank_account_type
                : res.data[0]?.con[0]?.acc[0]?.Bank_account_type,
            Gender: res.data[0]?.Gender,
            Age: res.data[0]?.Age,
            Nationality: res.data[0]?.Nationality,
            country: res.data[0]?.country,
            Address: res.data[0]?.Address,
            Pincode: res.data[0]?.Pincode,
            Work_Experience: res.data[0]?.Work_Experience,
            Highest_Educational_Qualification:
              res.data[0]?.Highest_Educational_Qualification,
            Ready_to_Join_on_anyday_after:
              res.data[0]?.Ready_to_Join_on_anyday_after,
            ED_Pursuing_Education: res.data[0]?.ED_Pursuing_Education,
            ED_College: res.data[0]?.ED_College,
            ED_City: res.data[0]?.ED_City,
            ED_Period_of_Internship_Start:
              res.data[0]?.ED_Period_of_Internship_Start,
            ED_Period_of_Internship_End:
              res.data[0]?.ED_Period_of_Internship_End,
            ED_University: res.data[0]?.ED_University,
            Adhar: res.data[0]?.Adhar,
            Studentornot: res.data[0]?.Studentornot,
            FMName: res.data[0]?.Family_name,
            FMRelationship: res.data[0]?.Family_relationship,
            FMContact: res.data[0]?.Family_contact,
            FMAddress: res.data[0]?.Family_address,
            FMPincode: res.data[0]?.Family_pincode,
            FMcountry: res.data[0]?.Family_country,
            FMstate: res.data[0]?.Family_state,
            FMcity: res.data[0]?.Family_city,
            BloodGroup: res.data[0]?.BloodGroup,
            unique_Code: res.data[0]?.unique_Code,
          });

          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        notify('Something went wrong ', false);
      });
  }, []);
  // const modes = useRef()
  // const modalon = () => {
  //     modes.current(Id)
  // }
  const [Loginid, setLoginid] = useState('');
  const [suspendreason, setSuspendreason] = useState('');
  const updateStatus = () => {
    console.log(Loginid);
    var id = Loginid;
    var status = value.Status;
    var st1;
    if (value.Status == 'Active') {
      st1 = 'Suspended';
    } else {
      st1 = 'Active';
    }

    Axios.post('/api/SuperAdmin/UpdateSubAdminStatus', {
      id,
      status,
      suspendreason,
    })
      .then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          setValue({
            ...value,
            Status: st1,
          });
          setSuspendreason('');
          handleClose();
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          notify('Unbothered user', false);
        } else {
          notify('Something went wrong ', false);
        }
      });
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setSuspendreason('');
  };
  const handleShow = (id) => {
    setShow(true);
    setLoginid(id);
  };
  var url;
  url = `https://s3.ap-south-1.amazonaws.com/prochure.in.profile/AffliateProfile/${value.Id}/avatar.png`;
  //   url = `https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/AffliateProfile/${value.Id}/avatar.png`;

  return (
    <div className="bg-light px-2">
      {Loading && (
        <>
          <SpinnerBig />
        </>
      )}

      {!Loading && (
        <>
          <center>
            <h2>FSE Profile</h2>
          </center>
          <div className="container-fluid  py-3">
            <div className="mt-3">
              <div className="card" style={{ width: '130px', height: '130px' }}>
                <img
                  src={url}
                  onError={(e) => {
                    return (e.target.src = '/blank.png');
                  }}
                  style={{ width: '130px', height: '130px' }}
                />
              </div>
              <h4 className="py-2">{value.Name}</h4>
              <div className="d-flex flex-column  flex-md-row justify-content-lg-end">
                <div className="mx-1 py-1 ">
                  {value.Type != 'SUPERADMIN' ? (
                    <button
                      className="btn pro-spaces-button3"
                      onClick={(e) => handleShow(value.Login_id)}
                    >
                      {value.Status == 'Active'
                        ? 'Suspend this account'
                        : 'Activate this account'}
                    </button>
                  ) : (
                    ''
                  )}
                </div>
                <div className="mx-1 py-1 ">
                  <Regionalreferralchange Id={value.Id} />
                </div>
                <div className="mx-1 py-1 ">
                  <div class="dropdown">
                    <Link
                      className="btn pro-spaces-button3 "
                      to={`/couponcreate_ame?k=${Id}`}
                    >
                      Add Coupon
                    </Link>
                  </div>
                </div>
                {/* <div className="mx-1 py-1 ">
                  <div class="dropdown">
                    <a
                      class="btn pro-spaces-button3 dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Appointment Letter
                    </a>

                    <ul
                      class="dropdown-menu "
                      aria-labelledby="dropdownMenuLink"
                    >
                      <li>
                        <Link
                          class="dropdown-item"
                          to="/create_appointment_letter"
                        >
                          Create Appointment Letter
                        </Link>
                      </li>
                      <li>
                        <Uploadappointmentletter />
                      </li>
                      <li>
                        <Viewappointmentletter />
                      </li>
                    </ul>
                  </div>
                </div> */}
                <div className="mx-1 py-1 ">
                  {value.Type != 'SUPERADMIN' ? (
                    <Category_Update Id={value.Id} />
                  ) : (
                    ''
                  )}
                </div>
                <div className="mx-1 py-1 ">
                  <UpdateCommissionPercentageSingleAME id={value.Id} />
                </div>
                {/* <div className="mx-1 py-1">
                  <UpdateToSME Acc_id={value.Id} />
                </div> */}
                <div className="mx-1 py-1 ">
                  {value.Type != 'AffiliatedAccount' ? (
                    <>
                      <Link to={`/sub_admin_update?k=${value.Id}`}>
                        <button className="btn pro-spaces-button3">
                          Edit Profile
                        </button>
                      </Link>
                    </>
                  ) : (
                    ''
                  )}
                  {/* cal={modes} */}
                </div>
                <div className="mx-1 py-1 ">
                  {value.Type != 'AffiliatedAccount' ? (
                    <>
                      {/* <Link to={`/sub_admin_update?k=${value.Id}`}>
                        <button className="btn pro-spaces-button3">
                          Edit Profile
                        </button>
                      </Link> */}

                      <ChangePassword id={value.Login_id} />
                    </>
                  ) : (
                    ''
                  )}
                  {/* cal={modes} */}
                </div>
              </div>{' '}
            </div>
            <br />
            <h4 className="pt-2">Regional Manager Referral Code </h4>

            <div className="">Regional Referral Code</div>

            <div className="card ps-2 py-2">{value.unique_Code}</div>
            <br />
            <h4 className="pt-2">Personal Details </h4>

            <div className="pt-2">Email</div>
            <div className="card ps-2 py-2">
              <a
                href={`mailto:${value.Email}`}
                style={{ textDecoration: 'none', color: 'black' }}
              >
                {value.Email}
              </a>
            </div>

            <div className="pt-2">Contact</div>

            <div className="card ps-2 py-2">
              <a
                href={`tel:${value.Contact}`}
                style={{ textDecoration: 'none', color: 'black' }}
              >
                {value.Contact}
              </a>
            </div>

            <div className="pt-2">Age</div>

            <div className="card ps-2 py-2">{value.Age}</div>

            <div className="pt-2">Gender</div>

            <div className="card ps-2 py-2">{value.Gender}</div>

            <div className="pt-2">Nationality</div>

            <div className="card ps-2 py-2">{value.Nationality}</div>

            <div className="pt-2">Blood Group</div>
            <div className="card ps-2 py-2">{value.BloodGroup}</div>
            <div className="pt-2">Country</div>
            <div className="card ps-2 py-2">{value.country}</div>
            <div className="pt-2">State</div>
            <div className="card ps-2 py-2">{value.state}</div>
            <div className="pt-2">City</div>
            <div className="card ps-2 py-2">{value.city}</div>
            <div className="pt-2">Address</div>
            <div className="card ps-2 py-2">{value.Address}</div>
            <div className="pt-2">Pincode</div>
            <div className="card ps-2 py-2">{value.Pincode}</div>
            <div className="pt-2">Referral Code</div>
            <div className="card ps-2 py-2">{value?.Referral_Code}</div>
            <br />

            <h4 className="pt-2">Identity Proof</h4>

            <div className="row">
              {value?.Adhar?.map((elem) => {
                console.log(elem);
                if (elem?.file != 'avatar.png') {
                  return (
                    <>
                      <div className="d-flex justify-content-center col-md-6 col-sm-12 g-2">
                        <img
                          src={elem.link}
                          style={{ width: '300px', height: '300px' }}
                        ></img>
                      </div>
                    </>
                  );
                }
              })}
            </div>

            {value.Studentornot == 'Student' ? (
              <>
                <br />
                <h4 className="pt-2">Education Details</h4>
                <div className="pt-2">Pursuing Educational Course</div>
                <div className="card ps-2 py-2">
                  {value.ED_Pursuing_Education}
                </div>
                <div className="pt-2">College</div>
                <div className="card ps-2 py-2">{value.ED_College}</div>
                <div className="pt-2">University</div>
                <div className="card ps-2 py-2">{value.ED_University}</div>
                <div className="pt-2">City</div>
                <div className="card ps-2 py-2">{value.ED_City}</div>
                <div className="pt-2">Period of Internship</div>
                <div className="row d-flex ps-3">
                  <div className="col-4 card ps-2 py-2">
                    {moment(value.ED_Period_of_Internship_Start).format(
                      'DD-MM-YYYY'
                    )}{' '}
                  </div>
                  <div className="col-2 d-flex justify-content-center align-items-center">
                    to
                  </div>
                  <div className="col-4 card ps-2 py-2">
                    {moment(value.ED_Period_of_Internship_End).format(
                      'DD-MM-YYYY'
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <br />

                <h4 className="pt-2">Qualification and Work Experience</h4>
                <div className="pt-2">Highest Educational Qualification</div>
                <div className="card ps-2 py-2">
                  {value.Highest_Educational_Qualification}
                </div>

                <div className="pt-2">Work Experience</div>
                <div className="card ps-2 py-2">{value.Work_Experience}</div>

                <div className="pt-2">Ready to Join on anyday after</div>
                <div className="card ps-2 py-2">
                  {moment(value.Ready_to_Join_on_anyday_after).format(
                    'DD-MM-YYYY'
                  )}
                </div>
              </>
            )}
            <br />

            <div className="pt-3"></div>

            <h4 className="pt-2">Bank Account Details</h4>

            <div className="pt-2">Account holder&nbsp;name</div>
            <div className="card ps-2 py-2">{value.Account_holder_name}</div>
            <div className="pt-2">Account number</div>
            <div className="card ps-2 py-2">{value.Account_number}</div>
            <div className="pt-2">Account IFSC&nbsp;code</div>
            <div className="card ps-2 py-2">{value.Ifsc_code}</div>
            <div className="pt-2">Bank name</div>
            <div className="card ps-2 py-2">{value.Bank_name}</div>
            <div className="pt-2">Bank account type</div>
            <div className="card ps-2 py-2">{value.Bank_account_type}</div>
            <br />

            <h4 className="pt-2">Contact Information of a Family Member</h4>

            <div className="pt-2">Full Name of Family Member</div>
            <div className="card ps-2 py-2">{value.FMName}</div>
            <div className="pt-2">Relationship with Family Member</div>
            <div className="card ps-2 py-2">{value.FMRelationship}</div>
            <div className="pt-2">Contact Number of Family Member</div>
            <div className="card ps-2 py-2">{value.FMContact}</div>
            <div className="pt-2">Address of Family Member</div>
            <div className="card ps-2 py-2">{value.FMAddress}</div>

            <div className="pt-2">Residing Country of Family Member</div>
            <div className="card ps-2 py-2">{value.FMcountry}</div>
            <div className="pt-2">Residing State of Family Member</div>
            <div className="card ps-2 py-2">{value.FMstate}</div>
            <div className="pt-2">Residing City of Family Member</div>
            <div className="card ps-2 py-2">{value.FMcity}</div>
            <div className="pt-2">Pincode</div>
            <div className="card ps-2 py-2">{value.FMPincode}</div>

            <div className="mt-4">
              <h4 className="mt-3 ps-3">Profiles Created</h4>
              <Admin_executive_earnings_in_profile_page id={Id} />
            </div>
          </div>
        </>
      )}

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Account Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            {value.Status == 'Active'
              ? ' Are you sure suspend this account ?'
              : 'Are you sure activate this account ?'}
          </h5>
          {value.Status == 'Active' ? (
            <div className="pt-3">
              <label class="form-label">Enter Reason</label>
              <textarea
                rows={5}
                class="form-control"
                onChange={(e) => setSuspendreason(e.target.value)}
              />
            </div>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn pro-spaces-button3"
            onClick={(e) => updateStatus()}
          >
            {value.Status == 'Active'
              ? 'Suspend this account'
              : 'Activate this account'}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
function ChangePassword(props) {
  const [value, setValue] = useState({
    // id: props.id,
    Password: '',
    Pin: '',
    showPassword: false,
    showPin: false,
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  function ChangePassword1() {
    // console.log(props)
    // console.log(value)
    if (!value.Password) return setError('Password is missing !');

    if (value.Password.toString().length < 8) {
      return setError('Password must contain 8 characters !');
    }
    if (!value.Pin) return setError('security pin is missing !');

    setLoading(true);

    var values = {
      value,
      id: props.id,
    };
    // console.log(values)
    // return
    Axios.post('/api/SuperAdmin/ChangeSubAdminPassByAdmin', { values })
      .then((res) => {
        if (res.status === 200) {
          handleClose();
          setLoading(false);
          notify('Password updated', true);
          setValue({
            ...value,
            Password: '',
            Pin: null,
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          notify('Wrong security pin', false);
        } else {
          notify('Something went wrong ', false);
        }
        setLoading(false);
      });
  }
  const handleChange = (prop) => (event) => {
    setValue({ ...value, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValue({
      ...value,
      showPassword: !value.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword1 = () => {
    setValue({
      ...value,
      showPin: !value.showPin,
    });
  };

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <button
        onClick={() => handleShow()}
        type="button"
        className="btn pro-spaces-button3"
      >
        Change Password
      </button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControl variant="outlined" className="form-control mt-2">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={value.showPassword ? 'text' : 'password'}
              value={value.Password}
              // inputProps={{ maxLength: 10 }}
              onChange={handleChange('Password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {value.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control mt-2">
            {/* sx={{ m: 1, width: '25ch' }} */}
            <InputLabel htmlFor="outlined-adornment-password">Pin</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={value.showPin ? 'text' : 'password'}
              value={value.Pin}
              onChange={(e) => {
                if (e.target.value.length == 5) return false;
                setValue({ ...value, Pin: e.target.value });
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword1}
                    onMouseDown={handleMouseDownPassword1}
                    edge="end"
                  >
                    {value.showPin ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="pin"
            />
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          {/* <Button variant="primary" onClick={}>
                        Update Password
                    </Button> */}
          <div className="row mt-4">
            <div className="col">
              <p style={{ color: 'red' }}>{error ? error : ''}</p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col  d-flex justify-content-end">
              {!loading && (
                <button
                  className="btn pro-spaces-button3"
                  onClick={() => ChangePassword1()}
                >
                  Update Password
                </button>
              )}
              {loading && (
                <button className="btn btn-success" disabled>
                  Updating Password
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

const Category_Update = ({ Id }) => {
  useEffect(() => {
    reload();
    reload1();
  }, []);
  useEffect(() => {
    details();
  }, [Id]);

  const details = () => {
    Axios.post('/api/Admin/GetOneSubAdmin', { Id })
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          setValue({
            ...value,
            Service_provide_category_All:
              res.data[0]?.Service_provide_category_All,
            Product_retailer_category_All:
              res.data[0]?.Product_retailer_category_All,
          });
          setService_provide_category(
            res.data[0]?.Service_provide_category
              ? res.data[0]?.Service_provide_category
              : []
          );
          setProduct_retailer_category(
            res.data[0]?.Product_retailer_category
              ? res.data[0]?.Product_retailer_category
              : []
          );
        }
      })
      .catch((err) => {
        console.log(err);
        //  notify("Something went wrong 2", false);
      });
  };

  const [value, setValue] = useState({
    Service_provide_category_All: true,
    Product_retailer_category_All: true,
  });
  const [Product_retailer_category, setProduct_retailer_category] =
    React.useState([]);
  const [Service_provide_category, setService_provide_category] =
    React.useState([]);
  const [Cat, setCat] = useState([]);
  function reload() {
    Axios.get('/api/api/getcategory')
      .then((res) => {
        if (res.status === 200) {
          console.log('workd');
          console.log(res.data);
          setCat(res.data);
        }
      })
      .catch((err) => {
        // console.log(err.response.status);
      });
  }
  const [Cat1, setCat1] = useState([]);

  function reload1() {
    Axios.get('/api/api/getprocategory')
      .then((res) => {
        if (res.status === 200) {
          console.log('workd');
          console.log(res.data);
          setCat1(res.data);
        }
      })
      .catch((err) => {
        // console.log(err.response.status);
      });
  }
  const handleClickShowPassword1 = () => {
    setValue({
      ...value,
      showPin: !value.showPin,
    });
  };

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setService_provide_category(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleChange2 = (event) => {
    const {
      target: { value },
    } = event;
    setProduct_retailer_category(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const [show, setShow] = useState(false);
  const [Pins, setPins] = useState('');
  const handleClose = () => {
    setShow(false);
    setPins('');
    setValue({
      ...value,
      Service_provide_category_All: true,
      Product_retailer_category_All: true,
    });
    console.log(value);
  };
  const handleShow = () => setShow(true);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (value.Service_provide_category_All) {
      setService_provide_category([]);
    }
    if (value.Product_retailer_category_All) {
      setProduct_retailer_category([]);
    }
  }, [value.Service_provide_category_All, value.Product_retailer_category_All]);

  const update = () => {
    if (
      !value.Product_retailer_category_All &&
      Product_retailer_category.length === 0
    ) {
      return setError('Select Product retailer category');
    }
    setError('');
    if (
      !value.Service_provide_category_All &&
      Service_provide_category.length === 0
    ) {
      return setError('Select Service provide category');
    }
    setError('');

    Axios.post('/api/SuperAdmin/UpdateCategoryForOneAME', {
      value,
      Service_provide_category,
      Product_retailer_category,
      Id,
      Pins,
    })
      .then((res) => {
        if (res.status == 200) {
          setValue({
            ...value,
            Service_provide_category_All: true,
            Product_retailer_category_All: true,
          });
          notify('Category updated', true);
          setService_provide_category([]);
          setProduct_retailer_category([]);
          reload();
          reload1();
          details();
          setPins('');
          handleClose();

          // window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          notify('Wrong security pin', false);
        } else {
          notify('Something went wrong ', false);
        }
      });
  };

  return (
    <>
      <button className="btn pro-spaces-button3" onClick={(e) => handleShow()}>
        Categories
      </button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update Categories</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Form>
              <div className="row mt-1">
                <div className="col">
                  <Form.Check
                    type="checkbox"
                    label="All service provide categories"
                    id="prime-checkbox"
                    checked={value.Service_provide_category_All ? true : false}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        Service_provide_category_All:
                          !value.Service_provide_category_All,
                      })
                    }
                  />
                </div>
              </div>
            </Form>
            <div className="mt-1">
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Service provide categories
                </InputLabel>
                <Select
                  disabled={value.Service_provide_category_All ? true : false}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={Service_provide_category}
                  onChange={handleChange1}
                  input={<OutlinedInput label="Service provide categories" />}
                  renderValue={(selected) =>
                    selected
                      ?.map(
                        (categoryId) =>
                          Cat.find((cat) => cat._id === categoryId)?.category ||
                          ''
                      )
                      .join(', ')
                  }
                  MenuProps={MenuProps}
                >
                  {Cat?.map((name) => (
                    <MenuItem key={name._id} value={name._id}>
                      <Checkbox
                        checked={
                          Service_provide_category.indexOf(name._id) > -1
                        }
                      />
                      <ListItemText primary={name.category} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <Form>
              <div className="row mt-1">
                <div className="col">
                  <Form.Check
                    type="checkbox"
                    label="All Product retailer categories"
                    id="prime-checkbox"
                    checked={value.Product_retailer_category_All ? true : false}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        Product_retailer_category_All:
                          !value.Product_retailer_category_All,
                      })
                    }
                  />
                </div>
              </div>
            </Form>
            <div className="mt-1">
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Product retailer categories
                </InputLabel>
                <Select
                  disabled={value.Product_retailer_category_All ? true : false}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={Product_retailer_category}
                  onChange={handleChange2}
                  input={<OutlinedInput label="Product retailer categories" />}
                  renderValue={(selected) =>
                    selected
                      ?.map(
                        (categoryId) =>
                          Cat1.find((cat1) => cat1._id === categoryId)
                            ?.category || ''
                      )
                      .join(', ')
                  }
                  MenuProps={MenuProps}
                >
                  {Cat1?.map((name) => (
                    <MenuItem key={name._id} value={name._id}>
                      <Checkbox
                        checked={
                          Product_retailer_category.indexOf(name._id) > -1
                        }
                      />
                      <ListItemText primary={name.category} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div>
              <FormControl variant="outlined" className="form-control mt-2">
                {/* sx={{ m: 1, width: '25ch' }} */}
                <InputLabel htmlFor="outlined-adornment-password">
                  Pin
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={value.showPin ? 'text' : 'password'}
                  value={Pins}
                  onChange={(e) => {
                    setPins(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword1}
                        onMouseDown={handleMouseDownPassword1}
                        edge="end"
                      >
                        {value.showPin ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="pin"
                />
              </FormControl>
            </div>
            <div>
              <span>
                <p style={{ color: 'red' }}>{error ? error : ''}</p>
              </span>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          {/* <Button variant="primary" onClick={}>
                        Update Password
                    </Button> */}
          <div className="row mt-4">
            <div className="col"></div>
          </div>
          <div className="row mt-3">
            <div className="col  d-flex justify-content-end">
              {!loading && (
                <button
                  className="btn pro-spaces-button3"
                  onClick={(e) => update()}
                >
                  Update{' '}
                </button>
              )}
              {loading && (
                <button className="btn btn-success" disabled>
                  Updating
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const UpdateToSME = ({ Acc_id }) => {
  // Acc_id===>account id of ame
  const [updatesme, setUpdatesme] = useState(false);

  const handleClosesme = () => setUpdatesme(false);
  const handleShowsme = () => setUpdatesme(true);

  let navigate = useNavigate();

  const Update = () => {
    Axios.post('/api/SuperAdmin/updatetosme', { Acc_id })
      .then((res) => {
        if (res.status == 200) {
          notify('Account Updated', true);
          handleClosesme();
          navigate('/sub_admin_list');
        }
      })
      .catch((err) => {
        notify('Something Went Wrong', false);
      });
  };

  return (
    <>
      <div>
        <button className="btn pro-spaces-button3" onClick={handleShowsme}>
          Update to SME
        </button>
      </div>
      <Modal show={updatesme} onHide={handleClosesme} centered>
        <Modal.Header centered closeButton></Modal.Header>
        <Modal.Body>
          Are you sure you want to update this account to SME account ?
        </Modal.Body>
        <Modal.Footer>
          <button className="btn pro-spaces-button3" onClick={(e) => Update()}>
            Update
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Uploadappointmentletter = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [img, setimg] = useState([]);
  const [err, setErr] = useState();

  const validate = () => {
    if (img.length === 0) {
      return setErr('PLEASE UPLOAD an Appointment Letter');
    }
    return true;
  };

  const submit = () => {
    console.log(img);
    var Formdata = new FormData();
    if (validate() === true) {
      img?.map((elem) => {
        console.log(elem);
        Formdata.append('file', elem);
      });

      axios
        .post('/api/AffiliatedAccount/smeappointmentletter', Formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          notify('Appointment Letter Upload Successfully', true);
          handleClose();

          setErr('');
        })
        .catch((err) => {
          notify('Something Went Wrong', false);

          console.log(err);
        });
    }
  };

  return (
    <>
      <Button
        className="pro-spaces-button"
        style={{ border: 'none' }}
        onClick={handleShow}
      >
        Upload Appointment Letter
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>Upload Appointment Letter</Modal.Header>

        <Modal.Body>
          <div class="mb-3">
            <input
              class="form-control"
              type="file"
              id="formFile"
              onChange={(e) => setimg([...img, ...e.target.files])}
            />
          </div>
          <div className="text-danger">{err}</div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn pro-spaces-button3"
            onClick={(e) => {
              submit();
            }}
          >
            Upload
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const Viewappointmentletter = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        className="pro-spaces-button"
        style={{ border: 'none' }}
        onClick={handleShow}
      >
        View Appointment Letter
      </Button>

      <Modal show={show} onHide={handleClose} fullscreen>
        <Modal.Header closeButton>View Appointment Letter</Modal.Header>

        <Modal.Body>
          <div class="mb-3"></div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const Regionalreferralchange = ({ Id }) => {
  const [unique, setunique] = useState({
    unique_Code: '',
  });
  const update = (Id) => {
    axios
      .post('/api/Admin/regionaluniquechange', { unique, Id })
      .then((req) => {
        if (req.status === 200) {
          notify('Updated', true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button
        className="pro-spaces-button3"
        style={{ border: 'none' }}
        onClick={handleShow}
      >
        Change Regional Manager
      </Button>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Change Regional Manager</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-md-12">
            <label className="labels">Regional Manager Referral Code *</label>
            <input
              className="form-control"
              required
              type={'text'}
              onChange={(e) => {
                setunique({ ...unique, unique_Code: e.target.value });
              }}
            ></input>
            <div class="invalid-feedback">PLEASE ENTER Full Name</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn pro-spaces-button3"
            onClick={(e) => {
              update(Id);
              handleClose();
            }}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
