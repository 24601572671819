import React, { useContext, useState, useEffect } from 'react'
import { AiFillDelete } from 'react-icons/ai'
import { RiArrowGoBackLine } from 'react-icons/ri'
import { Modal } from 'react-bootstrap'
import { AdContext } from './Advertisementt'
const Archive = ({ choose, onChange, onClose,Account_Id }) => {
    const adContext = useContext(AdContext)
    const deleteAd = adContext.deleteAd
    let data = adContext.Archieved
    const [show, setshow] = useState(false)
    const [heigh, setheigh] = useState(null)
    const handleClose = () => {
        setshow(false)
    }
    const handleShow = () => {
        setshow(true)
    }
    const [call, setcall] = useState(null) // delete image id
    const [dshow, dsetShow] = useState(false) // delete_warning modal state
    const dhandleClose = () => dsetShow(false); //delete_warning modal handle
    const dhandleShow = (ev) => {
        setcall(ev)
        dsetShow(true)
    };  //delete_warning modal handle
    useEffect(() => {
        if (!show) {
            setheigh(null)
        }
        return () => {
        }
    }, [show])
    useEffect(() => {
        if (choose) {
            handleShow()
        } else {
            handleClose()
        }
        return () => {
        }
    }, [choose])
    return (
        <div>
            <button
                type='button'
                className='btn pro-spaces-button mb-3'
                onClick={handleShow}
            >
                AD ARCHIVES
            </button>
            <Modal
                show={show}
                onHide={() => {
                    onClose()
                    handleClose();
                }}
                backdrop={'static'}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=''>
                        {heigh === null &&
                            <div className='row '>
                                {data?.File?.length ? data.File.map((ele) => {
                                    return <div className='col-6 p-1'>
                                        <img
                                            onClick={() => {
                                                setheigh(ele)
                                            }}
                                            className='img-fluid'
                                            src={ele.link}
                                        ></img>
                                    </div>
                                }) : <p className='text-center py-3'>No images found !</p>}
                            </div>
                        }
                        {heigh && <div  >
                            <div className='adContainer020'>
                                <div className='w-100'>

                                    <div className='d-flex justify-content-end mb-3'><button className='btn pro-spaces-button px-2 me-2'
                                        onClick={() => {
                                            setheigh(null)
                                        }} >GO Back</button>
                                        <button type='button'
                                            className='btn pro-spaces-button px-2 '
                                            onClick={(e) => {
                                                dhandleShow()
                                            }}
                                        >delete this image</button></div>
                                </div>
                                <div className='adImage236326'>
                                    <img height={160} src={heigh.link}></img>
                                </div>
                                <center>
                                    <button
                                        type='button'
                                        className='btn pro-spaces-button px-2 mt-3'
                                        onClick={(e) => {
                                            onChange(heigh)
                                        }}
                                    >Choose this image
                                    </button>

                                </center>
                            </div>
                        </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={dshow}
                onHide={() => {
                    setcall(null)
                    dhandleClose();
                }}
                backdrop={'static'}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   <p className='text-danger'>
                   Are you sure you want to delete this image ? This action cannot be undone.
                    
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn pro-spaces-button3"
                        onClick={() => {
                            deleteAd(heigh, dhandleClose, handleClose())
                        }}
                    >ok</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Archive