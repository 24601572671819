import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Axios from 'axios';
import { SpinnerBig } from '../../Utility/spinner';
import { Link, useSearchParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataGrid } from '@mui/x-data-grid';
import UpdateCommissionPercentage from './Components/update_Commission_percentage';
import axios from 'axios';
import { RiWhatsappFill } from 'react-icons/ri';

const notify = (message, sab) => {
  if (sab) {
    toast.success(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.warning(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export default function SMEList({ type }) {
  const [List, setList] = useState();
  const [SpinnerStatus, setSpinnerStatus] = useState(true);
  useEffect(() => {
    fetch();
  }, []);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetch = () => {
    setSpinnerStatus(true);
    Axios.get('/api/SuperAdmin/GetSubSMEList')
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          setList(res.data);
          setSpinnerStatus(false);
        } else {
          setList(null);
          setSpinnerStatus(false);
        }
      })
      .catch((error) => {
        // Handle error if API request fails
        console.error(error);
        setList(null);
        setSpinnerStatus(false);
      });
  };

  var columns = [
    {
      field: 'id',
      headerName: 'No. ',
      width: 60,
    },
    {
      field: 'Name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'Referral_Code',
      headerName: 'Referral Code',
      width: 200,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 200,
    },
    {
      field: 'Email',
      headerName: 'Email',
      width: 250,
    },
    {
      field: 'Contact',
      headerName: 'Contact',
      width: 200,
    },
    {
      field: 'created_on',
      headerName: 'created On',
      width: 200,
    },
    // {
    //   field: "createdBy",
    //   headerName: "Account created",
    //   width: 200,
    // },
    {
      field: 'Status',
      headerName: 'Account status',
      width: 150,
    },

    {
      field: 'Profile',
      headerName: 'Profile',
      width: 150,
      renderCell: (params) => {
        return (
          <Link to={`/sme_profile?k=${params.row.Account_id}`}>
            <button className="btn pro-spaces-button3">Profile</button>
          </Link>
        );
      },
    },
    {
      field: 'SendMail',
      headerName: 'Send Notification',
      width: 170,
      renderCell: (params) => {
        return (
          <Sendanemail
            log_id={params.row.Login_id}
            Acc_id={params.row.Account_id}
          />
        );
      },
    },
    {
      field: 'viewMail',
      headerName: 'View Notification',
      width: 170,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={`/viewnotification?k=${params.row.Login_id}&&j=${params.row.Account_id}`}
            >
              <button className="btn pro-spaces-button3">
                View Notification
              </button>
            </Link>
          </>
        );
      },
    },
    {
      field: 'WhatsApp',
      headerName: 'WhatsApp',
      width: 150,
      renderCell: (params) => {
        return (
          <>
            {params.row.Contact ? (
              <>
                <a
                  target="_blank"
                  href={`https://wa.me/${
                    params.row.Contact.length == 10
                      ? '+91' + params.row.Contact
                      : params.row.Contact
                  } `}
                  className="btn "
                  style={{ border: 'none' }}
                >
                  <RiWhatsappFill size={30} color="green" />
                </a>
              </>
            ) : (
              <></>
            )}
          </>
        );
      },
    },

    {
      field: 'Delete',
      headerName: 'Delete',
      width: 150,
      renderCell: (params) => {
        console.log(params.row.Status);
        console.log(params.row.Type);
        // ||params.row.Status!="Deleted"
        return params.row.Type != 'SUPERADMIN' ? (
          <DeleteSubAdmin
            key={params.row.Account_id}
            id={params.row.Login_id}
            fetch={fetch}
          />
        ) : (
          ''
        );
      },
    },
  ];

  return (
    <div className=" p-2 border bg-light ">
      <center>
        <h3>Manage Account of DCE</h3>
      </center>
      {/* <div class="d-flex justify-content-end"><UpdateCommissionPercentage/></div> */}

      {SpinnerStatus ? <SpinnerBig /> : ''}

      <div className="bg-light mt-4">
        {List && (
          <Box sx={{ height: 800, width: '100%' }}>
            <DataGrid
              rows={List}
              columns={columns}
              // Other DataGrid props...
            />
          </Box>
        )}
      </div>
    </div>
  );
}

function DeleteSubAdmin(props) {
  const [value, setValue] = useState({
    Pin: '',
    showPin: false,
  });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const handleChange = (prop) => (event) => {
    setValue({ ...value, [prop]: event.target.value });
  };
  const handleClickShowPassword1 = () => {
    setValue({
      ...value,
      showPin: !value.showPin,
    });
  };

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };
  function Delete() {
    if (!value.Pin) return setError('security pin is missing !');
    var values = {
      value,
      id: props.id,
    };
    setLoading(true);

    Axios.post('/api/SuperAdmin/DeleteSubAdmin', { values })
      .then((res) => {
        if (res.status === 200) {
          handleClose();
          notify('Sub admin delete successfully', true);
          setLoading(false);
          setValue({
            ...value,
            Pin: '',
          });
          props.fetch();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          notify('Wrong security pin', false);
          setLoading(false);
        } else {
          notify('Something went wrong', false);
          setLoading(false);
        }
      });
  }

  return (
    <div>
      <button className="btn btn-danger" onClick={() => handleShow()}>
        Delete
      </button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControl variant="outlined" className="form-control mt-2">
            {/* sx={{ m: 1, width: '25ch' }} */}
            <InputLabel htmlFor="outlined-adornment-password">Pin</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={value.showPin ? 'text' : 'password'}
              value={value.Pin}
              onChange={(e) => {
                if (e.target.value.length == 5) return false;
                setValue({ ...value, Pin: e.target.value });
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword1}
                    onMouseDown={handleMouseDownPassword1}
                    edge="end"
                  >
                    {value.showPin ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="pin"
            />
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          {/* <Button variant="primary" onClick={}>
                        Update Password
                    </Button> */}
          <div className="row mt-4">
            <div className="col">
              <p style={{ color: 'red' }}>{error ? error : ''}</p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col  d-flex justify-content-end">
              {!loading && (
                <button
                  className="btn pro-spaces-button3"
                  onClick={() => Delete()}
                >
                  Delete
                </button>
              )}
              {loading && (
                <button className="btn btn-success" disabled>
                  Deleting
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

const Sendanemail = ({ log_id, Acc_id }) => {
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [state, setState] = useState({
    Subject: '',
    Message: '',
    Acc_id: Acc_id,
    log_id: log_id,
  });

  const send = () => {
    if (validate(state) === true) {
      axios
        .post('/api/Admin/Sendmail_ame_sme', { state })
        .then((res) => {
          handleClose1();
          notify('Notification Sent Successfully', true);

          console.log();
        })
        .catch((err) => {
          console.log(err);
          notify('Something Went Wrong', false);
        });
    }
  };

  const [err, setErr] = useState(false);

  const validate = (state) => {
    if (state?.Subject?.length === 0) {
      return setErr('PLEASE ENTER Mail Subject');
    }
    if (state?.Message?.length === 0) {
      return setErr('PLEASE ENTER Message');
    }
    if (state?.Subject?.length < 4) {
      return setErr('Mail Subject is too short');
    }
    if (state?.Message?.length < 4) {
      return setErr('Message is too short');
    }
    return true;
  };
  return (
    <>
      <button className="btn pro-spaces-button3" onClick={handleShow1}>
        Send Notification
      </button>

      {/* send an email */}
      <Modal show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            <label className="">Mail Subject *</label>
            <input
              className="form-control"
              type="text"
              onChange={(e) => {
                setState({ ...state, Subject: e.target.value });
              }}
              required
            />
          </div>
          <div className="pt-2">
            <label className="">Message *</label>
            <textarea
              className="form-control"
              rows={3}
              onChange={(e) => {
                setState({ ...state, Message: e.target.value });
              }}
              required
            />
          </div>
          <div className="py-1 text-danger">{err}</div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn pro-spaces-button3" onClick={(e) => send()}>
            Send
          </button>
        </Modal.Footer>
      </Modal>
      {/* send an email */}
    </>
  );
};
