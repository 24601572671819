import React from 'react'
import { Link } from "react-router-dom";


export default function EmergencyServices() {
  return (
    <div> <div className="container ">
    <div className="row slhishieo3jfiehfjefbewf">

      <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

      <Link to="/ambulance_services_humans"  className="wgfiuwrgiu">

        <div className="card carrrrbus">
          
          
            <div className="padunnor"><center>AMBULANCE SERVICES (HUMANS)<br>

            </br> </center></div>
          
          
        </div>
        </Link>
      </div>
      {/* {localStorage.getItem("Type")==="Consultant" || "Product"? */}
      <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

      <Link to="/ambulance_services_animals"  className="wgfiuwrgiu">

        <div className="card carrrrbus">
        
         
            <div className="padunnor"><center>AMBULANCE SERVICES (ANIMALS)<br>
            
            </br></center></div>
          

        </div>
        </Link>
      </div>
        <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

          <Link to="/blood_donors" className="wgfiuwrgiu">

            <div className="card carrrrbus">


              <div className="padunnor"><center>BLOOD DONORS<br>

              </br></center></div>


            </div>
          </Link>
        </div>
        <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

          <Link to="/deceased_animal_burial_grounds" className="wgfiuwrgiu">

            <div className="card carrrrbus">


              <div className="padunnor"><center>DECEASED ANIMAL BURIAL GROUNDS<br>

              </br></center></div>


            </div>
          </Link>
        </div>

        <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

          <Link to="/blood_banks" className="wgfiuwrgiu">

            <div className="card carrrrbus">


              <div className="padunnor"><center>Blood Banks<br>

              </br></center></div>


            </div>
          </Link>
        </div>

        <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

          <Link to="/police_stations" className="wgfiuwrgiu">

            <div className="card carrrrbus">


              <div className="padunnor"><center>Police Stations<br>

              </br></center></div>


            </div>
          </Link>
        </div>
        <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

          <Link to="/police_control_rooms" className="wgfiuwrgiu">

            <div className="card carrrrbus">


              <div className="padunnor"><center>Police Control Rooms<br>

              </br></center></div>


            </div>
          </Link>
        </div>
        <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

          <Link to="/fire_stations" className="wgfiuwrgiu">

            <div className="card carrrrbus">


              <div className="padunnor"><center>Fire Stations<br>

              </br></center></div>


            </div>
          </Link>
        </div>
        <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

          <Link to="/pharmacies" className="wgfiuwrgiu">

            <div className="card carrrrbus">


              <div className="padunnor"><center>Pharmacies<br>

              </br></center></div>


            </div>
          </Link>
        </div>
      
      
      {/* :""}
       */}
    </div>
 
</div></div>
  )
}
