import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { notify } from '../../../Utility/notify';
import moment from 'moment';
import { Button } from 'react-bootstrap';

export const Clerical2memberview = () => {
  const [first, setfirst] = useState([]);

  const columns = [
    { field: 'id', headerName: 'Sl No', width: 50 },
    {
      field: 'Name',
      headerName: 'Name',
      width: 200,
    },

    {
      field: 'Email',
      headerName: 'User Name',
      type: 'number',
      width: 200,
    },
    {
      field: 'Change Password',
      headerName: 'Change Password',
      sortable: false,
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <button
          className="btn pro-spaces-button3"
          data-bs-toggle="modal"
          data-bs-target={'#changepasswordmodal' + (params.row.id - 1)}
          onClick={(e) => {
            console.log(params.row.id);
          }}
        >
          Change Password
        </button>
      ),
    },

    {
      field: 'date',
      headerName: 'Created on',

      width: 110,
      editable: true,
    },

    {
      field: 'Delete',
      headerName: 'Actions',
      sortable: false,
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <button
          className="btn btn-danger"
          data-bs-toggle="modal"
          data-bs-target={'#deletemodal' + (params.row.id - 1)}
          onClick={(e) => {
            console.log(params.row.id);
          }}
        >
          Delete
        </button>
      ),
    },
  ];

  useEffect(() => {
    axios
      .get('/api/clericaladmin2/clearadmin2get')
      .then((res) => {
        const newstate = res.data?.map((elem, index) => {
          return {
            ...elem,
            id: index + 1,
            date: moment(elem.Created_on).format('DD-MM-YYYY'),
          };
        });
        console.log(newstate);
        console.log(newstate.date);
        setfirst(newstate);
      })

      .catch((err) => {
        console.log(err);
      });
  }, []);

  const Deleteee = (id, ind) => {
    console.log(id);
    console.log(ind);
    axios
      .post('/api/clericaladmin/accountdelete', { id, ind })
      .then((req) => {
        console.log('req');
        notify('deleted', true);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        notify('Something Went Wrong', false);
      });
  };

  const [value, setValue] = useState({
    currentpassword: '',
    changepassword: '',
    confirmpassword: '',
  });

  const passwordchange = (id, ind) => {
    console.log(id);
    console.log(ind);
    axios
      .post('/api/clericaladmin/passwordchange', { value, id })
      .then((req) => {
        console.log('Asdsa');
        if (req.status == 200) {
          notify('Password Changed', true);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          return notify('Current Password Wrong ', false);
        }
      });
  };

  return (
    <>
      <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={first}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        />
      </Box>
      {/* delete */}

      {first?.map((elem, index) => {
        console.log(elem);
        return (
          <>
            <div
              class="modal fade"
              id={'deletemodal' + index}
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      id={'btnclose'}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body text-danger">
                    Are you sure you want to delete ?
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={(e) => {
                        Deleteee(elem._id, elem.id);
                        // setopperins(elem.ind);
                        // setIndex(elem.ind);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}

      {/* delete */}
      {/* change password */}
      {first?.map((elem, index) => {
        console.log(elem);
        return (
          <>
            <div
              class="modal fade"
              id={'changepasswordmodal' + index}
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      id={'btnclose'}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body ">
                    <div className="">
                      <label className="">Current Password</label>
                      <input
                        className="form-control"
                        type="password"
                        onChange={(e) =>
                          setValue({
                            ...value,
                            currentpassword: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div>
                      <label className="">New Password</label>
                      <input
                        className="form-control"
                        type="password"
                        onChange={(e) => {
                          setValue({
                            ...value,
                            changepassword: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div>
                      <label className="">Confirm New Password</label>
                      <input
                        className="form-control"
                        type="password"
                        onChange={(e) => {
                          setValue({
                            ...value,
                            confirmpassword: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={(e) => {
                        passwordchange(elem._id, elem.id);
                        // setopperins(elem.ind);
                        // setIndex(elem.ind);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}

      {/* change password */}
    </>
  );
};
