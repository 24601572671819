import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { useNavigate } from "react-router-dom";
// import "./Edit.css";
import "cropperjs/dist/cropper.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormProduct } from "./FormProduct";

function EditProductV2(props) {
  const formData = new FormData();
  useEffect(() => {
    // props.Auth();
  }, []);

  const [value, setValue] = useState({
    firstname: "",
    contact: "",
    contact2: "",
    email: "",
    website: "",
    category: "",
    subcategory: "",
    Brand:"",
    address: "",
    state: "",
    city: "",
    pincode: "",
    licence: "",
    image: "",
    yearofb: "",
    gst: "",
    who: "",
    pname: '',
    TypeofStore: "",
    WomanOwned: false,
    brandName: "",
    profileType: "",
    lastname: "",
    gender: "",
  });
  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
      // .post("/api/ActiveUser/ProProfile", {})
  let navigate = useNavigate();
  const submit = async (formData) => {
    return new Promise((resolve,reject)=>{
    // e.preventDefault();

    // formData.delete("file");
    // formData.delete("data");
    // console.log(value);
    // var dataSend = JSON.stringify(value);
    // console.log(newb);

    var status = false;
    let metadata = {
      type: "image/jpeg",
    };
    // if (newb) {
    //   let file = new File([newb], "test.jpg", metadata);
    //   console.log(file);

    //   formData.append("file", file);
    // }
    // formData.append("data", dataSend);

    // setSubmit(true);
    // status = validation();
    // if (status == true) {
      axios
        .put("/api/ActiveUser/ProProfile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          notify("Profile Updated", true);
          navigate("/ProductStore");
          resolve(true)
          return;
        })
        .catch((err) => {
          notify("Something went wrong ", false);
          console.log(err);
          resolve(false)
        });

      // console.log('get')
    // } else {
    //   console.log("validation error");
    //   e.preventDefault();
    // }

  })
  };
  return (
    <div className="container rounded bg-white mt-5 mb-5">
      <FormProduct postTo={submit} edit={true} sab={true} />
      
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover

      />
    </div>
  );
}

export default EditProductV2;
