import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import React, { useEffect, useState } from "react";
import './jobPostForm.css'
import Axios from "axios";
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { print_state, print_city, s_a, print_city_inputstate } from '../../../Utility/stateCity'
import Moment from 'moment';


// import { keyGen } from '../../Utility/helper';




export const Insider = (props) => {
    const [cat, setCat] = useState([])
    const [category, setCategory] = useState(false)
    const [subcategory, setSubcategory] = useState(false)
    const [Stateload, setStateload] = useState(true)
    useEffect(() => {
        if (Stateload)
            print_state("State")
        setStateload(false)
    }, [])
    let [searchParams, setSearchParams] = useSearchParams();  /////getting id from the url 
    var id = searchParams.get("pi")
    var cats = searchParams.get("cat")

    // id = "6364ea1edea8937ba98907d1"
    // let cats = "Service"
    const [ProductCat, setProductCat] = useState([])
    const [ServiceCat, setServiceCat] = useState([])
    // var ProductCat=[]
    // var ServiceCat=[]
    let f1
    let f2

    useEffect(async () => {
        console.log(cats)
        try {
            f1= await Axios.get('/api/api/getcategory')
            console.log(f1)
            setServiceCat(f1.data)
          f2=await     Axios.get('/api/api/getprocategory')
            console.log(f2)
            setProductCat(f2.data)
            if (id) {
    
              let f3=await Axios.post('/api/Admin/Employe_Marketing_rew_Update_view', { id },)
                    // .then(res => {
                    //     if (res.status == 200) {
                    //         console.log(res.data)
                    //         print_city_inputstate('City', res.data[0].State)
                    //         console.log(cat)
                    //         var date=res.data[0].Date.slice(0,10)
                    //          console.log(f1)
                    //          console.log(f2)

                                
                    //             sabari123("Service Provider")
                   
                    //         console.log(date)
              
                    //         // Chikku(res.data[0].mainCat, cats)
        
                    //     }
                    // })
                    console.log(f3)
                    if (f3.data.length===1) {
                            print_city_inputstate('City', f3.data[0].State)
                          
                            var date=f3.data[0].Date.slice(0,10)
                             
                                      setQ1({
                                ...Q1,
                                id:f3.data[0]._id,
                                CreatedBy: f3.data[0].CreatedBy,
                                Coupon: f3.data[0].Coupon,
                                Fees: f3.data[0].Fees,
                                Type: f3.data[0].Type,
                                Remarks:f3.data[0].Remarks,
                                Response: f3.data[0].Response,
                                subCat: f3.data[0].subCat,
                                mainCat: f3.data[0].mainCat,
                                Classification: f3.data[0].Classification,
                                Name: f3.data[0].Name,
                                Side: f3.data[0].Side,
                                Street_road: f3.data[0].Street_road,
                                Area: f3.data[0].Area,
                                City: f3.data[0].City,
                                State: f3.data[0].State,
                                Date:date ,
                            })
                     
                            console.log("Cat123")
                            console.log(f3.data[0].Classification)
                            console.log(f1.data)
                            console.log(f2.data)


                            let temp=[]
                            if (f3.data[0].Classification==="Service Provider") {
                                temp=[...f1.data]
                               }else if (f3.data[0].Classification==="Product Retailer") {
                                temp=[...f2.data]
                               }
                            // console.log(temp);
                            // console.log(cat)
                            console.log(temp)
                            var anadhan;
                            let sab=[]
                            if (temp.length) {
                                temp.forEach((element) => {
                                    sab.push(<option value={element._id}>{element.category}</option>);
                                  });
                                  setCategory(sab);
                               }
                            setQ1({ ...Q1, mainCat: f3.data[0].mainCat});
                             temp.forEach((element) => {
                                console.log(element._id);
                                if (element._id === f3.data[0].mainCat) {
                                    anadhan = element.sub.map((elements) => {
                                        return <option value={elements._id}>{elements.sub}</option>;
                                    });
                                } else {
                    
                                }
                            });
                            setSubcategory(anadhan);
                    
                        
                    }


        
            }
        
            
        } catch (error) {
            console.log(error.response)
            
        }



    }, []);


    const [Q1, setQ1] = useState({
        id:"",
        CreatedBy: '',
        Coupon: '',
        Fees: "",
        Type: '',
        Remarks: '',
        Response: '',
        subCat: '',
        mainCat: '',
        Classification: '',
        Name: '',
        Side: '',
        Street_road: '',
        Area: '',
        City: '',
        State: '',
        Date: '',
    })

 
  const sabari123 = (e) => {


    var temp=[]
 
       let sab=[]
       if (e==="Service Provider") {
        temp=[...ServiceCat]
       }else if (e==="Product Retailer") {
        temp=[...ProductCat]
       }
       if (temp.length) {
        temp.forEach((element) => {
            sab.push(<option value={element._id}>{element.category}</option>);
          });
          setCategory(sab);
       }

    };
    const sabari = (e,casllfi) => {
        console.log("Cat123")
        console.log(e)
        console.log(casllfi)
        console.log(ServiceCat)
        console.log(ProductCat)
        setQ1({...Q1,mainCat:"",subCat:""})
        let temp=[]
        if (casllfi==="Service Provider") {
            temp=[...ServiceCat]
           }else if (casllfi==="Product Retailer") {
            temp=[...ProductCat]
           }
        // console.log(temp);
        // console.log(cat)
        console.log(temp)
        var anadhan;
        setQ1({ ...Q1, mainCat: e });
         temp.forEach((element) => {
            console.log(element._id);
            if (element._id === e) {
                anadhan = element.sub.map((elements) => {
                    return <option value={elements._id}>{elements.sub}</option>;
                });
            } else {

            }
        });
        setSubcategory(anadhan);
    };
    const notify = (message, stat) => {
        if (stat == true) {
            // console.log("toasfted");
            toast.success(message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (stat == false) {
            toast.error(message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };
    const [Today, setToday] = useState(false)
    useEffect(() => {
        var dtToday = new Date();

        var month = dtToday.getMonth() + 1;
        var day = dtToday.getDate();
        var year = dtToday.getFullYear();
        if (month < 10)
            month = '0' + month.toString();
        if (day < 10)
            day = '0' + day.toString();

        setToday(year + '-' + month + '-' + day)
    }, [])



    let navigate = useNavigate()





    const [File, setFile] = useState([])
    const formData = new FormData()
    useEffect(() => {
        console.log(File)

    }, [File])



    function Cityshow(e) {
        Statefix()
        var index = document.getElementById(e.target.id).selectedIndex
        print_city('City', index)
    }


    const Statefix = () => {
        const City = document.getElementById('City').value
        const Statee = document.getElementById('State').value
        setQ1({ ...Q1, State: Statee, City: City })
    }




    // Validation Starts here
    const [Error, setError] = useState(false)


    const v1 = () => {

        if (!Q1.Date.length > 0) {
            return setError('Enter Date')
        }
        if (!Q1.State.length > 0) {
            return setError('Select  state')
        }
        if (!Q1.City.length > 0) {
            return setError('Select  city')
        }
        if (!Q1.Area.length > 0) {
            return setError('Enter the area')
        }
        if (!Q1.Street_road.length > 0) {
            return setError('Enter Street/road')
        }
        if (!Q1.Side.length > 0) {
            return setError('Enter side')
        }
        if (!Q1.Name.length > 0) {
            return setError('Enter profile name')
        }
        if (!Q1.Classification.length > 0) {
            return setError('Select Classification')
        }
        if (!Q1.mainCat.length > 0) {
            return setError('Select Category')
        }
        if (!Q1.subCat.length > 0) {
            return setError('Select Sub category')
        }
        if (!Q1.Response.length > 0) {
            return setError('Select Response')
        }
        if (Q1.Response==="Negative") {
            if (!Q1.Remarks.length > 0) {
                return setError('Select Remarks')
            }
            
        }
        if (!Q1.Type.length > 0) {
            return setError('Select membership type')
        }
        if (!Q1.Coupon.length > 0) {
            return setError('Select Coupon')
        }
        if (!Q1.CreatedBy.length > 0) {
            return setError('Select Created By')
        }

        setError('')
        upload1()
    }

    //     if (!Q8.recentCTC) {
    //         return setError8('Enter your last CTC')
    //     }
    //     setError8('')
    //     Qchanger('Q9')
    // }
    // const v9 = () => {
    //     if (!Q9.expectedCTC) {
    //         return setError9('Enter your expected salary')
    //     }
    //     setError9('')
    //     Qchanger('Q8')
    // }
    // const v10 = () => {
    //     if (!Q10) {
    //         // return setError10('Upload Your work')
    //     }
    //     setError10('')
    //     Qchanger('Q9')
    // }
    // const v11 = () => {
    //     if (!Q11.email) {
    //         return setError11('Enter your email')
    //     }
    //     if (!Q11.phone) {
    //         return setError11('Enter your phone number')
    //     }
    //     setError11('')
    //     Qchanger('Q10')
    // }
    // const v12 = async () => {
    //     if (!Q12.about) {
    //         return setError12('Write about yourself')
    //     }
    //     if (!Q12.hobbies) {
    //         return setError12('Enter your hobbies')
    //     }
    //     if (Q12.skills.length == 0) {
    //         return setError12('Enter skills')
    //     }

    //     setError12('')
    //     console.log('done')

    //     upload1();
    // }
    // Validation Ends here


    const [loading, setloading] = useState(false)

    function upload1() {

        // setloading(true)



        var final = {
            Q1,
        }
        console.log(final)
        // return
        const data = JSON.stringify(final)

        // return
        if (data != null || data != '') {

            if (id) {
                Axios.post('/api/Admin/Employe_Marketing_rew_update', { data },)
                .then(res => {
                    if (res.status == 200) {
                        console.log('Employe_Marketing_rew_update')
                        console.log(res.data)
                        notify("Updated successfully", true);

                        setTimeout(() => {

                            setloading(false)

                            return navigate(`/marketing_feedback_view`)
                        }, 1000);

                    }
                }).catch((err) => {
                    console.log(err.response.data)
                    setloading(false)
                    notify('Something went wrong', false)


                })

                
            }
            else
            {
                Axios.post('/api/Admin/Employe_Marketing_rew', { data },)
                .then(res => {
                    if (res.status == 200) {
                        console.log('Employe_Marketing_rew ')
                        console.log(res.data)
                        notify("Posted successfully", true);

                        setTimeout(() => {

                            setloading(false)

                            return navigate(`/marketing_feedback_view`)
                        }, 1000);

                    }
                }).catch((err) => {
                    console.log(err.response.data)
                    setloading(false)
                    notify('Something went wrong', false)


                })


            }


        }


        console.log('first')
    }

    const [Coupons, setCoupons] = useState([])
    useEffect(() => {
        var data = Q1.Type
        console.log(Q1.Type)
        Axios.post('/api/Admin/getPrices', { data }).then(res => {
            if (res.status === 200) {
                console.log(res.data);
                var a = 0
                var b = 0
                a = (res.data.data.amount * 18) / 100
                b = Math.round(res.data.data.amount + a)
                console.log(b)
                setQ1({ ...Q1, Fees: b })
                setCoupons(res.data.data1)
            }
        }).catch((err) => {

            console.log(err.response.status);
        })

    }, [Q1.Type])






 const meCat=(e)=>{
    var ab= e
    console.log(ab)
    setQ1({ ...Q1, Classification: e})
    sabari123(e)
 }

//  useEffect(() => {
//  console.log(Q1.subCat)
//  }, [Q1])
useEffect(() => {
 
}, [])


    return (

        <div className="container bg-light mt-4  p-3 p-md-5 pt-5">
            <br></br>
            <div id="Q1" className=" d-non container  p-3 p-md-5 ">
                <div className="row mt-3">
                    <h5 className="text-center "> Marketing Feedback </h5>

                    <div className="col-md-12"><label className="fontSizerforlabel labels ">Date / Month / Year *</label>
                        <input onChange={(e) => setQ1({ ...Q1, Date: e.target.value })} max={Today} value={Q1.Date} type="date" className='form-control' />
                    </div>
                    <div className="col-md-12"><label className="fontSizerforlabel labels ">State *</label>
                        <select className="form-select" value={Q1.State} onChange={(e) => Cityshow(e)} id="State" name="City" />
                    </div>
                    <div className="col-md-12 mt-2"><label className="fontSizerforlabel labels ">City *</label>
                        <select name="City" value={Q1.City} onChange={(e) => Statefix(e)} className="form-select" id="City">
                            {/* <option>Select City</option> */}
                        </select>
                    </div>
                    <div className="col-md-12">
                        <label className="fontSizerforlabel labels " >Area *</label>
                        <input onChange={(e) => setQ1({ ...Q1, Area: e.target.value })} value={Q1.Area} type="text" className="form-control" accept="application/pdf" />
                    </div>
                    <div className="col-md-12">
                        <label className="fontSizerforlabel labels ">Street / Road *</label>
                        <input onChange={(e) => setQ1({ ...Q1, Street_road: e.target.value })} value={Q1.Street_road} type="text" className="form-control" accept="application/pdf" />
                    </div>
                    <div className="col-md-12"><label className="fontSizerforlabel labels ">Side *</label>
                        <input onChange={(e) => setQ1({ ...Q1, Side: e.target.value })} value={Q1.Side} type="text" className="form-control" />
                    </div>
                    <div className="col-md-12"><label className="fontSizerforlabel labels ">Profile Name *</label>
                        <input onChange={(e) => setQ1({ ...Q1, Name: e.target.value })} value={Q1.Name} type="text" className="form-control" />
                    </div>

                    <div className="col-md-12"><label className="fontSizerforlabel labels ">Classification *</label><br></br>
                        <select value={Q1.Classification} onChange={(e) => meCat(e.target.value)} type="text" className="form-select" >
                            <option value=""  selected>
                            </option>
                            <option value="Service Provider">Service Provider</option>
                            <option value="Product Retailer">Product Retailer</option>
                        </select>
                    </div>

                    {Q1.Classification ?
                        <>
                            <div className="mb-1 mb-md-3">
                                <label htmlFor="" className="fontSizerforlabel labels "> Category *</label>
                                <select value={Q1.mainCat} onChange={(e) => sabari(e.target.value,Q1.Classification)} type="text" className="form-select" >
                                    <option value=""  selected>
                                    </option>
                                    {category ? category : ''}
                                </select>

                            </div>
                            <div className="mb-1 mb-md-3">
                                <label htmlFor="" className="fontSizerforlabel labels "> Sub Category *</label>
                                <select value={Q1.subCat} onChange={(e) => setQ1({ ...Q1, subCat: e.target.value })} type="text" className="form-select" >
                                    <option value=""  selected>

                                    </option>
                                    {subcategory ? subcategory : ''}
                                </select>

                            </div>
                        </>
                        :
                        ""

                    }


                    <div className="col-md-12"><label className="fontSizerforlabel labels ">Response *</label><br></br>
                        <select value={Q1.Response} onChange={(e) => setQ1({ ...Q1, Response: e.target.value })} type="text" className="form-select" >
                            <option value="" disabled selected>
                            </option>
                            <option value="Positive">Positive</option>
                            <option value="Negative">Negative</option>
                        </select>
                    </div>

                    {
                        Q1.Response == "Negative" ?

                            <div className="col-md-12"><label className="fontSizerforlabel labels "> Remarks *</label><br></br>
                                <select value={Q1.Remarks} onChange={(e) => setQ1({ ...Q1, Remarks: e.target.value })} type="text" className="form-select" >
                                    <option value="" disabled selected>
                                    </option>
                                    <option value="Member Will Do It Later">Member Will Do It Later</option>
                                    <option value="Asked Us To Come Later"> Asked Us To Come Later</option>
                                    <option value="Not Interested"> Not Interested</option>

                                </select>
                            </div>

                            : ''
                    }

                    <div className="col-md-12"><label className="fontSizerforlabel labels "> Membership Type *</label><br></br>
                        <select value={Q1.Type} onChange={(e) => setQ1({ ...Q1, Type: e.target.value })} type="text" className="form-select" >
                            <option value="" disabled selected>
                            </option>
                            <option value="Basic">Basic</option>
                            <option value="Prime">Prime</option>
                        </select>
                    </div>

                    <div className="col-md-12">
                        <label className="fontSizerforlabel labels ">Fees</label>
                        <input value={Q1.Fees} onChange={(e) => setQ1({ ...Q1, Fees: e.target.value })} type="email" className="form-control" accept="application/pdf" disabled />
                    </div>


                    <div className="col-md-12"><label className="fontSizerforlabel labels "> Coupon Code *</label><br></br>
                        <select value={Q1.Coupon} onChange={(e) => setQ1({ ...Q1, Coupon: e.target.value })} type="text" className="form-select" >
                            <option value="" disabled selected>
                            </option>
                            {
                                Coupons.map((e) => {
                                    return <option value={e.name}>{e.name}</option>
                                })
                            }

                        </select>
                    </div>


                    <div className="col-md-12"><label className="fontSizerforlabel labels ">   Created By *</label><br></br>
                        <select value={Q1.CreatedBy} onChange={(e) => setQ1({ ...Q1, CreatedBy: e.target.value })} type="text" className="form-select" >
                            <option value="" disabled selected>
                            </option>
                            <option value="Member" >
                                Member
                            </option>
                            <option value="Executive" >
                                Executive
                            </option>

                        </select>
                    </div>

                    <p className='text-danger m-0'>{Error ? Error : ''}</p>
                    <div className="col-md-12 mt-3">
                        {/* <input type="button" onClick={(e) => v12()} className="btn btn-primary w-100 mt-3 rnd" value="Post" /> */}
                        <div className='row'>

                            <div className='col-6'>
                                <input className="btn btnpost w-100 mt-2 pro-spaces-button" data-bs-toggle="modal" data-bs-target="#SureYouWanna" type="submit" value="Reset" />

                            </div>

                            {
                                id?
                                
                            <div className='col-6'>
                            {!loading && <center>
                                <input onClick={(e) => v1()} className="btn w-100 pro-spaces-button3 mt-2" type="submit" value="Updating" />
                            </center>}
                            {loading && <center>
                                <button className="btn btnpost w-100  mt-2" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    &nbsp;Updating...
                                </button>
                            </center>}
                        </div>

                                :
                                
                            <div className='col-6'>
                            {!loading && <center>
                                <input onClick={(e) => v1()} className="btn w-100 pro-spaces-button3 mt-2" type="submit" value="Post" />
                            </center>}
                            {loading && <center>
                                <button className="btn btnpost w-100  mt-2" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    &nbsp;Posting...
                                </button>
                            </center>}
                        </div>
                            }

                            {/* <div className='col-6'>
                                {!loading && <center>
                                    <input onClick={(e) => v1()} className="btn w-100 pro-spaces-button3 mt-2" type="submit" value="Post" />
                                </center>}
                                {loading && <center>
                                    <button className="btn btnpost w-100  mt-2" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        &nbsp;Posting...
                                    </button>
                                </center>}
                            </div> */}

                        </div>
                    </div>
                </div>
                <div className="modal fade" id="SureYouWanna" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                Are you sure? !!! This will erase all the fields on all the pages.
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn pro-spaces-button" data-bs-dismiss="modal">Cancel</button>
                                <button type="button" onClick={(e) => {
                                    props.setReset(!props.Reset)
                                }} data-bs-dismiss="modal" className="btn pro-spaces-button3">Reset</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <br />
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )




}