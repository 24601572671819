import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios';
import Moment from 'moment';
import ConsultantsList from './ConsultantsList';
import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';

export function Consultants(props) {
  const [consultants, setconsultants] = useState([]);

  const [Product, setProduct] = useState([]);
  const [load, setload] = useState(true);
  const [error, seterror] = useState('');
  const [output, setoutput] = useState('');
  const [data, setdata] = useState(false);
  const [productid, setproductid] = useState([]);
  useEffect(() => {
    // var Id='6209f5e094805605d016655d';
    console.log('Admin feedback view test');

    Axios.get('/api/Admin/findadmincreatedaccounts')
      .then((res) => {
        if (res.status === 200) {
          // console.log('passive View')
          console.log(res.data);
          setconsultants(res.data);
          setload(false);

          const datas1 = res.data.map((product, index) => ({
            product: product,
            index: index,
          }));
          setdata(datas1);
          // Counts=res.data
          // res.data
          // console.log(Id)

          // setLoading(false)
          //    settotal(res.data.JobRecruit_screenQustionNumber)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  function makeid(length) {
    length = 5;
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  useEffect(() => {
    if (load) {
      setoutput(
        <>
          <br />
          <div className="text-center ">
            {' '}
            <div className="spinner-border text-dark"></div>
          </div>
        </>
      );
    } else {
      if (data) {
        setoutput(
          data.map((number) => {
            // if(number.con>0){
            return (
              <ConsultantsList
                key={number.id}
                ids={makeid()}
                data={number}
                type={props.type}
              ></ConsultantsList>
            );
            // }
          })
        );
      } else {
        setoutput(error);
      }
    }
  }, [data]);

  if (data) {
    if (data.length) {
      return (
        <div className="bg-light py-2">
          <center>
            <h4 style={{ textTransform: 'uppercase' }}>
              Complete pending account creation
            </h4>
          </center>

          <div className="table1 ">
            <div style={{ overflow: 'scroll' }} className="container ">
              <br></br>
              {/* <Form.Select aria-label="Default sel">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </Form.Select> */}
              <table className="table table-striped table-hover table-bordered">
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">Login Id</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Email</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                    <th scope="col">Preview</th>
                  </tr>
                </thead>
                <tbody>{output}</tbody>
              </table>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  } else {
    return <div></div>;
  }
}
