import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import Moment from 'moment';
import './Feedback.css'
import { useEffect, useState } from "react";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import  Axios  from "axios";
import { SpinnerBig } from "../../../Utility/spinner";
import { Prochure_Ask_Us_List } from './Prochure_Ask_Us_List';
import { BsDot } from 'react-icons/bs';



export  function Feedback(props) {

console.log(props)
 
  const [loader, setloader] = useState(<div class="spinner-border text-primary" role="status">
  <span class="visually-hidden">Loading...</span>
</div>)
const [Loading, setLoading] = useState(true)
const [Feedback, setFeedback] = useState(false)
const [Suggestion, setSuggestion] = useState(false)

const [LogOp, setLogOp] = useState(true)
// var Counts
  useEffect(() => {
      // var Id='6209f5e094805605d016655d';
      console.log('Admin feedback view test')
      setLoading(true)
        
      Axios.get('/api/Admin/FeedbackView')
      .then(res => {
          if (res.status === 200) {
              console.log('Feedback View')
              console.log(res.data)
              setFeedback(res.data)
              // Counts=res.data
                // res.data
              // console.log(Id)
           
setLoading(false)
          //    settotal(res.data.JobRecruit_screenQustionNumber)
      }
  }).catch((err) => {
          console.log(err)
      
         })
         Axios.get('/api/Admin/CategorySuggestion')
         .then(res => {
             if (res.status === 200) {
                 console.log('Category Suggestion')
                 console.log(res.data)
                 setSuggestion(res.data)
                 // Counts=res.data
                   // res.data
                 // console.log(Id)
              
   setLoading(false)
             //    settotal(res.data.JobRecruit_screenQustionNumber)
         }
     }).catch((err) => {
             console.log(err)
         
            })
    }, [])


    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

 const [Switch1, setSwitch1] = useState(true)

 const FeedbackTable = <>
 {
        Feedback?
        <>
                <h2>Ask Us {props?.dataCount>0?<><BsDot size={35} color='red' ></BsDot></>:<></>}  </h2>

                {/* <TableContainer component={Paper} className="mt-3">
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>No</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Feedback</TableCell>
                        <TableCell>Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        Feedback.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (

                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell >{index + 1}</TableCell>
                            <TableCell >{row.string[0] ? row.string[0].pname : "-"}</TableCell>
                            <TableCell >{row.message ? row.message : "-"}</TableCell>
                            <TableCell >{Moment(row.Date).format('DD-MM-YYYY')}</TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={Feedback.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer> */}
                <Prochure_Ask_Us_List callAskCount={props.callAskCount}/>
        </>

            :"NO members to show"
      }
 </>

const CategoryTable = <>
{
       Suggestion?
       <>
               <h2>Category Suggestion</h2>

               <TableContainer component={Paper} className="mt-3">
                 <Table sx={{ minWidth: 650 }} aria-label="simple table">
                   <TableHead>
                     <TableRow>
                       <TableCell>No</TableCell>
                       <TableCell>Member name</TableCell>
                       <TableCell>Type</TableCell>
                       <TableCell>Category</TableCell>
                       <TableCell>Sub Category</TableCell>
                       <TableCell>Date</TableCell>
                     </TableRow>
                   </TableHead>
                   <TableBody>
                     {
                       Suggestion.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (

                         <TableRow
                           key={index}
                           sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                         >
                           <TableCell >{index + 1}</TableCell>
                           <TableCell >{row.User_Account_id ? row.User_Account_id.pname : "-"}</TableCell>
                           <TableCell >{row.Type ? row.Type : "-"}</TableCell>
                           <TableCell >{row.Category ? row.Category : "-"}</TableCell>
                           <TableCell >{row.SubCategory ? row.SubCategory : "-"}</TableCell>
                           <TableCell >{Moment(row.Created).format('DD-MM-YYYY')}</TableCell>
                         </TableRow>
                       ))
                     }
                   </TableBody>
                 </Table>
                 <TablePagination
                   rowsPerPageOptions={[10, 25, 100]}
                   component="div"
                   count={Feedback.length}
                   rowsPerPage={rowsPerPage}
                   page={page}
                   onPageChange={handleChangePage}
                   onRowsPerPageChange={handleChangeRowsPerPage}
                 />
               </TableContainer>
       </>

           :"NO members to show"
     }
</>

    return (
      <div className="bg-light p-3" >
    <div className=" ms-1" style={{maxHeight:"90vh"}}>
      <center>
        <div  className="row p-0 m-0 mb-3  ">
            <div className="col-6 p-0">
                <input type="button" style={{ 'borderRadius': '0px' }} className={`btn pro-spaces-outline w-100 ${LogOp ? 'RActive' : ''}`} onClick={(e) => setLogOp(true)} value='Ask Us' name="btnradio" id='Emd2' autocomplete="off" />
            </div>
            <div className="col-6 p-0">
                <input type="button" style={{ 'borderRadius': '0px' }} className={`btn pro-spaces-outline w-100 px-0 ${!LogOp ? 'RActive' : ''}`} onClick={(e) => setLogOp(false)} value='Category Suggestion' name="btnradio" id='Mbn2' autocomplete="off"  />
            </div>
        </div>
      </center>

       {Loading?<SpinnerBig/>:''}
       {LogOp ? FeedbackTable : CategoryTable}

      





    </div>
  </div>
    )
}

