import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {AiOutlineCheck,AiOutlineClose} from "react-icons/ai"
import { FaPhotoVideo, FaVideo } from "react-icons/fa";
import {BsFillPlusCircleFill} from "react-icons/bs"

export default function SubEdit2() {
    const [cat, setCat] = useState([]);
    const [category, setCategory] = useState(false);
    const [subcategory, setSubcategory] = useState(false);
    const [Stateload, setStateload] = useState(true);
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState(false);

    useEffect(() => {
      reload()

    }, []);

    const notify = (message, sab) => {
      if (sab) {
        toast.success(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.warning(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };


    const [value1, setValue1] = useState({
       
        category: "",
        type:true
      });
      const [value, setValue] = useState({
       
        category: "",
        subcategoryname:'',
        subcategory:'',
        first:false,
        formType:1,

      });


    
      const sabari = (e) => {
        console.log("haii")
        console.log(e.target.value);
        var anadhan;
        setValue({ ...value, category: e.target.value });
        selectCtategory( e.target.value)
        // setValue({...value,})
        cat.forEach((element) => {
          console.log(element._id);
          if (element._id === e.target.value) {
            anadhan = element.sub.map((elements) => {
              return <option value={elements._id}>{elements.sub}</option>;
            });
          } else {
          }
        });
        setSubcategory(anadhan);
      };

        function reload()
        {
          axios
          .get("/api/api/getcategory")
          .then((res) => {
            if (res.status === 200) {
              console.log("workd");
              console.log(res.data)
              setCat(res.data);
            }
          })
          .catch((err) => {
            console.log(err.response.status);
          });
        }

    
    
    
      var sab = [];
      useEffect(() => {
        if (cat.length >= 1) {
          cat.forEach((element) => {
            sab.push(<option value={element._id}>{element.category}</option>);
          });
          setCategory(sab);
        } else {
          console.log("nothing");
        }
      }, [cat]);
      // useEffect(() => {
      //   if (Stateload) print_state("State");
      //   setStateload(false);
      // }, []);


      const AddSubCategorys= async(e)=>{
        // setloading(true)
        console.log(value)

        if(!value.category)
        {
           return seterror("Select category")
        }
        seterror("")

        if(!value.subcategoryname)
        {
            return seterror("Enter sub category name")
        }
        seterror("")
        if(!value.first&&!value.subcategory)
        {
            return seterror("Select position")
        }


         console.log(value)
        let check = await axios.post('/api/Admin/CheckSubCategorys',{value}).then(
          (res)=>{
            console.log(res.data)
        if(res.status==200)
        {
            if(!res.data)
            {

              let sab =  axios.post('/api/Admin/AddSubCategorys',{value}).then((res)=>{
                if(res.status==200){
                  notify("Successfully added", true);
    
          
                  seterror("")
            //   setloading(false)
              setValue({
       
                category: "",
                subcategoryname:'',
                subcategory:'',
                first:false,
                formType:1
              });

              document.getElementById('formType1').checked=true
              document.getElementById('firstPoss').checked=false


              reload()

                }
                // window.location.reload();
              }).catch((err)=>{
                console.log(err)
                notify(" Something went wrong", false);
    
              })
              setloading(false)
            }
            else{
              seterror("Sub Category already exists")
            }
       
        }
      }
        ).catch((err)=>{
          console.log(err)
          notify(" Something went wrong", false);
          setloading(false)

        })
          
      }


      const selectCtategory=async(category)=>{
        console.log(category)
        // return
        var select= await axios.post('/api/Admin/selectCtategory',{category}).then((res)=>{
          if(res.status==200){
            // notify("Successfully added", true);
            console.log(res.data)
            // setcatname(res.data)
            setValue({categoryname:res.data.category,
                      category:res.data._id,
                      formType:1
            })
          }

        }).catch((err)=>{
          console.log(err)
          notify(" Something went wrong", false);
          setloading(false)

        })

      }

    //   const EditSubCategorys= async(e)=>{
    //      console.log(value)
    //      let update=await axios.post('/api/Admin/UpdateSubCtategory',{value}).then((res)=>{
    //       if(res.status==200)
    //       {

    //         notify("Successfully updated", true);
            
    //         setValue({
    //           categoryname:'',
    //           category:'',
    //           subcategory:'',
    //           first:false

    //         })
    //         reload()

    //       }
    //      }).catch((err)=>{
    //       console.log(err)
    //       notify(" Something went wrong", false);
    //       setloading(false)

    //     })

    //   }
  return (
    <div>
        <br></br>
        <h1>Add Consultants Sub-Category</h1><br />
        <div className='row'>
            <div className='col-3'> <label className="labels">Category</label></div><div className='col-3'>
            <select
                  className="form-select"
                  value={value.category}
                  onChange={(e) => sabari(e)}
                >
                  <option value="" disabled selected>
                    Select your category
                  </option>
                  {category ? category : ""}
                </select>
            </div>
            <div className="col">

            </div>
        </div><br />
        <div className='row'>
            <div className='col-3'>Add Sub-Categorys</div><div className='col-3'><input className="form-control" type="text"  value={value.subcategoryname}
                    onChange={(e) =>
                      setValue({ ...value, subcategoryname: e.target.value })
                    }></input></div>
                    {/* <div className="col">  <div className="col">
                    {!loading &&   <button className="btn btn-success" onClick={(e)=>AddCategorys(e)}>Add</button> }
                    {loading &&   <button className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button> }
                    </div></div> */}
        </div><br />
                <div className='row'>
            <div className='col-3'> <label className="labels">After</label></div>
            <div className='col-3'>
            <select
                  className="form-select"
                  value={value.subcategory}
                  onChange={(e) =>
                    setValue({ ...value, subcategory: e.target.value })
                  }
                >
                  <option value="" disabled selected>
                    Select your sub category
                  </option>
                  {subcategory ? subcategory : ""}
                </select>
            </div>
            <div className="col-4">
            <label className="labels">First subcategory</label>
            &nbsp;&nbsp;&nbsp;

               <input type="checkbox" value={!value.first} className="form-check-input " id="firstPoss"  onChange={(e) =>
                    setValue({ ...value, first: e.target.value })
                  } ></input>
            </div>
         
        </div>
        <br/>

        <div className="row">
          <div className="col-3">
            Form Type
          </div>
          <div className="col">

                  1 <input onChange={(e) => setValue ({ ...value, formType: e.target.value })} type="radio" className="form-check-input" defaultChecked name="formType" id="formType1" value={1} />
                    &nbsp; &nbsp; 2<input onChange={(e) => setValue({ ...value, formType: e.target.value })} type="radio" className="form-check-input" name="formType" id="formType2" value={2} />
                    &nbsp; &nbsp; 3 <input onChange={(e) => setValue({ ...value, formType: e.target.value })} type="radio" className="form-check-input" name="formType"  id="formType3" value={3} />
                    &nbsp; &nbsp; 4 <input onChange={(e) => setValue({ ...value, formType: e.target.value })} type="radio" className="form-check-input" name="formType"  id="formType4"value={4} />
                    
          </div>

        </div>
        <div className="row">
        <div className="col-6"> </div> <div className="col">
                    {!loading &&   <button className="btn btn-success" onClick={(e)=>AddSubCategorys(e)}>Add</button> }
                    {loading &&   <button className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button> }
                    </div>
        </div>


        <div className="row">
          <div className="col">
            <div className="col"><p style={{"color":'red'}}>{error?error:''}</p></div>
                  
          </div>

        </div>
 

        <br></br>

<ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    </div>
  )
}
