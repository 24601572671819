import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function ActivistsEnvironmentalEdit() {
    let navigate = useNavigate()

    useEffect(() => {
        // props.Auth()
    }, [])
    const notify = (message, sab) => {
        if (sab) {
            toast.success(message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            toast.warning(message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };




    let [searchParams, setSearchParams] = useSearchParams();  /////getting id from the url the id of the jobs id
    var id = searchParams.get("k")
    // var id = "62b5b8c5b44ac0fe19a83e77"

    // setValue({...value,id:id})
    useEffect(() => {
        console.log(id)
  
    }, [])
    

    const [value, setValue] = useState({
        name: "",
        phone: "",
          Description:"",
          Address:"",
          email:"", 
    });





   

    // Validation Starts here
    const [error, seterror] = useState(false)





    const [loading, setloading] = useState(false)

    function up1(e) {

        setloading(true)

        console.log(value)

        
        if(!value.name)return seterror("Name is missing !");


        if (!value.phone&&!value.email) {
          return seterror('Enter your phone number or email')
      }


      
      if(value.email)
      {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value.email)) {
          // setError('')
      }
      else {
          return seterror('Invalid Email')
      }
     
      }


        Axios.post('/api/Admin/ActivistEnvironmentalEdit',{value})
            .then(res => {
                if (res.status == 200) {

                    console.log(res.data)

                    notify("Post updateed successfully",true);
                    setloading(false)

                    setTimeout(() => {

                        setloading(false)

                        return navigate(`/activist_environmental`)
                    }, 1000);
                }

            }).catch((err) => {
                console.log(err.response.data)
                notify("Somthing went wrong",false);


            })
        console.log('first')



    }







  


    const [loader, setLoader] = useState(<div id='iooioooo1231312' className='container  d-flex mt-5 pt-5  justify-content-center p-3 p-md-5'><div class="spinner-border text-dark" role="status">
        <span class="visually-hidden">Loading...</span>
    </div></div>)
    const [Loading, setLoading] = useState(true)
    // var id = "625541f375d16debd14ced72"
    useEffect(() => {
        console.log("haiiiii")
        Axios.post('/api/Admin/ActivistEnvironmentalEditView', { id })
            .then(res => {
                if (res.status === 200) {
                    console.log('jobView Edit')
                    console.log(res.data)
                    // console.log(Id)
                    // setDetails(res.data);
                    setValue({
                        ...value,
                        id: res.data._id,
                        name:res.data.name,
                        phone:res.data.phone,
                        email:res.data.email,
                        Description:res.data.Description,
                        Address:res.data.Address,
                    })

                    setLoading(false)
                }
            }).catch((err) => {
                console.log(err)
                alert("something went wrong");
                setLoading(false)

            })
    }, [])









    return (
        <div className="container pt-5 mt-3">
            <h1> Activists Environmental Update</h1>
            <div className='row'>
            <div className='col'><label className="labels">Name Of Activist / Activist Organization</label></div><div className='col'><input className="form-control" type="text"  value={value.name}
                    onChange={(e) =>
                      setValue({ ...value, name: e.target.value })
                    }></input></div>
        </div><br />

        <div className='row'>
            <div className='col'>  <label className="labels">Description</label></div><div className='col'>
              <textarea value={value.Description} className="form-control"
                    onChange={(e) =>
                      setValue({ ...value, Description: e.target.value })
                    }></textarea>
           </div>
        </div><br />
        <div className='row'>
            <div className='col'>  <label className="labels">Contact Number</label></div><div className='col'>
            <input type="number"  value={value.phone} className="form-control"
                    onChange={(e) =>
                     { setValue({ ...value, phone: e.target.value })}
                    }></input></div>
        </div><br />

        <div className='row'>
            <div className='col'>  <label className="labels"> Address</label></div><div className='col'>
              <textarea value={value.Address} className="form-control"
                    onChange={(e) =>
                      setValue({ ...value, Address: e.target.value })
                    }></textarea>
           </div>
        </div><br />

        <div className='row'>
            <div className='col'>  <label className="labels">Email Id</label></div><div className='col'>
            <input type="text"  value={value.email} className="form-control"
                    onChange={(e) =>
                     { setValue({ ...value, email: e.target.value })}
                    }></input></div>
        </div><br />
            <div className="row">
                <div className="col"><p style={{ "color": 'red' }}>{error ? error : ''}</p></div>
                <div className="col">
                    {!loading && <button className="btn btn-success" onClick={(e) => up1(e)}>Edit</button>}
                    {loading && <button className="btn btn-success" disabled><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>}

                </div>
            </div>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )
}
