import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {AiOutlineCheck,AiOutlineClose} from "react-icons/ai"
import { FaPhotoVideo, FaVideo } from "react-icons/fa";
import {BsFillPlusCircleFill} from "react-icons/bs"
import VendorSubEdit from "./VendorSubEdit";
import VendorSubEdit2 from "./VendorSubEdit2";
export default function VendorSubCategorysAddEdit() {
const [cat, setCat] = useState([]);
    const [category, setCategory] = useState(false);
    const [subcategory, setSubcategory] = useState(false);
    const [Stateload, setStateload] = useState(true);
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState(false);

    useEffect(() => {
      reload()

    }, []);

    const notify = (message, sab) => {
      if (sab) {
        toast.success(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.warning(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };


    const [value1, setValue1] = useState({
       
        category: "",
        type:true
      });
      const [value, setValue] = useState({
       
        category: "",
        subcategoryname:'',
        subcategory:'',
        first:false

      });


    
      const sabari = (e) => {
        console.log("haii")
        console.log(e.target.value);
        var anadhan;
        setValue({ ...value, category: e.target.value });
        selectCtategory( e.target.value)
        // setValue({...value,})
        cat.forEach((element) => {
          console.log(element._id);
          if (element._id === e.target.value) {
            anadhan = element.sub.map((elements) => {
              return <option value={elements._id}>{elements.sub}</option>;
            });
          } else {
          }
        });
        setSubcategory(anadhan);
      };

        function reload()
        {
          axios
          .get("/api/api/getcategory")
          .then((res) => {
            if (res.status === 200) {
              console.log("workd");
              setCat(res.data);
            }
          })
          .catch((err) => {
            console.log(err.response.status);
          });
        }

    
    
    
      var sab = [];
      useEffect(() => {
        if (cat.length >= 1) {
          cat.forEach((element) => {
            sab.push(<option value={element._id}>{element.category}</option>);
          });
          setCategory(sab);
        } else {
          console.log("nothing");
        }
      }, [cat]);
      // useEffect(() => {
      //   if (Stateload) print_state("State");
      //   setStateload(false);
      // }, []);


      const AddSubCategorys= async(e)=>{
        setloading(true)

         console.log(value)
        let check = await axios.post('/api/Admin/CheckSubCategorys',{value}).then(
          (res)=>{
            console.log(res.data)
        if(res.status==200)
        {
            if(!res.data)
            {

              let sab =  axios.post('/api/Admin/AddSubCategorys',{value}).then((res)=>{
                if(res.status==200){
                  notify("Successfully added", true);
    
          
                  seterror("")
              setloading(false)

                }
              }).catch((err)=>{
                console.log(err)
                notify(" Something went wrong", false);
    
              })
              setloading(false)
            }
            else{
              seterror("Category already exists")
            }
       
        }
      }
        ).catch((err)=>{
          console.log(err)
          notify(" Something went wrong", false);
          setloading(false)

        })
          
      }


      const selectCtategory=async(category)=>{
        console.log(category)
        // return
        var select= await axios.post('/api/Admin/selectCtategory',{category}).then((res)=>{
          if(res.status==200){
            // notify("Successfully added", true);
            console.log(res.data)
            // setcatname(res.data)
            setValue({categoryname:res.data.category,
                      category:res.data._id
            })
          }

        }).catch((err)=>{
          console.log(err)
          notify(" Something went wrong", false);
          setloading(false)

        })

      }

      const EditSubCategorys= async(e)=>{
         console.log(value)
         let update=await axios.post('/api/Admin/UpdateSubCtategory',{value}).then((res)=>{
          if(res.status==200)
          {

            notify("Successfully updated", true);
            
            setValue({
              categoryname:'',
              category:'',
              subcategory:'',
              first:false

            })
            reload()

          }
         }).catch((err)=>{
          console.log(err)
          notify(" Something went wrong", false);
          setloading(false)

        })

      }


     
     
  return (
    <div className="container pt-5  pageleft"><br />
     <VendorSubEdit2></VendorSubEdit2>

<hr/>

    <VendorSubEdit></VendorSubEdit>   
        
     
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
       
    </div>
  )
}
