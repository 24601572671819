import React from 'react'
import { Link } from "react-router-dom";

export default function Leisure() {
  return (
<div>
<div className="container ">
  <div className="row slhishieo3jfiehfjefbewf">

    <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

    <Link to="/shopping_malls"  className="wgfiuwrgiu">

      <div className="card carrrrbus">
        
        
          <div className="padunnor"><center>Shopping Malls<br>

          </br> </center></div>
        
        
      </div>
      </Link>
    </div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/theatres" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Theatres<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/childrens_parks" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Childrens' Parks<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/jogging_parks" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Jogging Parks<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/beaches" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Beaches<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/amusement_water_theme_parks" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Amusement, Water-theme Parks<br>

</br></center></div>


</div>
</Link>
</div>
<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/museums" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Museums<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/libraries" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Libraries<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/social_clubs" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Social Clubs<br>

</br></center></div>


</div>
</Link>
</div>


  </div>

</div>
</div>





  )
}
