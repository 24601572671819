import React,{ useState, useEffect, useContext, useRef }  from 'react'
import { bloodgrouplist, currencyList, keyGen, unitOfMeasurementList } from "../../../../Utility/helper";
import { CatsContext } from '../InformationForms'
import { ConvertContext } from '../InformationForms'
import { EditContext } from '../InformationForms'
import axios from 'axios'
import { StateCity } from "../StateCity";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
export const Form3 = () => {
    const Edit = useContext(EditContext);
    let x = [...Array(100).keys()]
    const cats = useContext(CatsContext);
    console.log(cats)
    const convert = useContext(ConvertContext);
    const [Submit, setSubmit] = useState(false)
    const [Submit2, setSubmit2] = useState(false);
    const [loading, setloading] = useState(false)
    const formData = new FormData()
    let navigate = useNavigate();

    const submit = async (e) => {
        e.preventDefault();
        // formData.delete('file')
        // formData.delete('data')
        let loc = false
        // var dataSend = JSON.stringify({ value })
        // console.log(dataSend)
        // let metadata = {
        //     type: 'image/jpeg'
        // };
        // if (newb) {
        //     let file = new File([newb], "avatar.jpg", metadata);
        //     console.log(file)
        //     formData.append("file", file);
        // }
        // for (let index = 0; index < Files.length; index++) {
        //     console.log(Files)
        //     let file = new File([Files[index].file], `ExtraImageIndex${index}${Date.now()}.jpg`, metadata);
        //     console.log(file)
        //     formData.append("file", file)
        // }
        
        // formData.append("data", dataSend);
        var status = false
        setSubmit(true)
        status = Validation()
        if (status == true) {
            setloading(true)

            if (!Edit) {
                axios.post('/api/Admin/InformationFormType3',{value}).then((res)=>{
                    if (res.status==200) {
                        console.log("ok")
                        setloading(false)
                        notify("Information Add Successfully",true)
                        navigate(`/information`)
                    }
                }).catch((err)=>{
                    console.log(err)
                    console.log("error")
                    notify("Something went wrong",false)
                    setloading(false)
                })  
            }else{
                axios.post('/api/Admin/InformationFormType3Update',{value}).then((res)=>{
                    if (res.status==200) {
                        console.log("ok")
                        notify("Information Updated Successfully",true)
                        setloading(false)
                        if (!cats?.value.SubSubCategory) {
                            navigate(`/Information_view_for_edit?k=${cats?.value.SubCategory}`)
                        }else{
                            navigate(`/Information_view_for_edit?k=${cats?.value.SubSubCategory}`)
                        }
                    }
                }).catch((err)=>{
                    console.log(err)
                    console.log("error")
                    notify("Something went wrong",false)
                    setloading(false)
                })
            }



        
        }
    }
    
    const Validation=()=>{


        if (value.category.length > 0) {
            setError("");
        } else {
            return setError("PLEASE ENTER Category");
        }
        if (value.subcategory.length > 0) {
            setError("");
        } else {
            return setError("PLEASE ENTER Sub Category");
        }
        setError("")
        if (cats?.value.SubSubCategory?.length > 0) {
            if (value.subsubcategory === undefined) {
                return setError('PLEASE SELECT A BRAND')
            }
            console.log(value)
            if (value.subsubcategory.length === 0) {
                return setError('PLEASE SELECT A BRAND')
            }
        }
        setError("")

        return true
    }

    const [value, setValue] = useState({
     category: cats?.value.Category,
     subcategory: cats?.value.SubCategory,
     subsubcategory: cats?.value.SubSubCategory,
     formType:cats?.value.FormType,
     country:"",
     state: '',
     city: '',
     name:"",
     currency:"",
     price:"",
     unit:"",
     duration:""

    })
    useEffect(() => {
        console.log(cats?.subsubcategory?.length)
        setValue({ ...value, subcategory: cats?.value.SubCategory, category: cats?.value.Category, subsubcategory: cats?.value.SubSubCategory, Id: cats?.value.Id,formType:cats?.value.FormType })
        return () => {

        };
    }, [cats])

    useEffect(() => {
if (Edit) {
    setValue({
        ...value,
        country:Edit.country,
        state: Edit.state,
        city: Edit.city,
        name:Edit.name,
        currency:Edit.currency,
        price:Edit.price,
        unit:Edit.unit,
        duration:Edit.duration
    })
}
    }, [Edit])
    
    const [Files, setFiles] = useState([])
    const FileDelete = (data, index) => {
        let temp = [...Files];
        temp.splice(index, 1)
        setFiles(temp);
      }

    const [error, setError] = useState('')

  return (
    <div className="container rounded bg-white ">
    <form onSubmit={(e) => submit(e)}
        novalidate
        className={Submit2 ? 'was-validated' : ''}
    >
                          
                          <div className="row mt-2">

                          <div className="col-12">
                                    <label className="labels">Name </label>
                                    <input
                                        required
                                        type="text"
                                        className="form-control"
                                        value={value.name}
                                        onChange={(e) =>
                                            setValue({ ...value, name: e.target.value })
                                        }
                                    />
                                    <div class="invalid-feedback">
                                        PLEASE ENTER Name
                                    </div>
                                </div>

                          <StateCity setValue={setValue} value={value} Edit={Edit} />
                                <div className="col-12">
                                    <label className="labels">Currency </label>
                                    <select
                                        // required
                                        className="form-select"
                                        value={value.currency}
                                        onChange={(e) =>
                                            setValue({ ...value, currency: e.target.value })
                                        }
                                    >
                                      <option value={""} selected></option>
                                      {
                                        currencyList.map((e)=>{
                                          return <option value={e.code}>{e.code}</option>
                                        })
                                      }
                                      </select>
                                    {/* <div class="invalid-feedback">
                                        PLEASE ENTER First Name
                                    </div> */}
                                </div>

                                <div className="col-12">
                                    <label className="labels">Price / Charges </label>
                                    <input
                                        // required
                                        type="text"
                                        className="form-control"
                                        value={value.price}
                                        onChange={(e) =>
                                            setValue({ ...value, price: e.target.value })
                                        }
                                    />
                                    {/* <div class="invalid-feedback">
                                        PLEASE ENTER First Name
                                    </div> */}
                                </div>

                                <div className="col-12">
                                    <label className="labels">Unit </label>
                                    <select
                                        // required
                                        className="form-select"
                                        value={value.unit}
                                        onChange={(e) =>
                                            setValue({ ...value, unit: e.target.value })
                                        }
                                    >
                                      <option value={""} selected></option>
                                      {
                                        unitOfMeasurementList.map((e)=>{
                                          return <option value={e}>{e}</option>
                                        })
                                      }
                                      </select>
                                    {/* <div class="invalid-feedback">
                                        PLEASE ENTER First Name
                                    </div> */}
                                </div>
                                <div className="col-12">
                                    <label className="labels">Duration </label>
                                    <input
                                        // required
                                        type="text"
                                        className="form-control"
                                        value={value.duration}
                                        onChange={(e) =>
                                            setValue({ ...value, duration: e.target.value })
                                        }
                                    />
                                    {/* <div class="invalid-feedback">
                                        PLEASE ENTER First Name
                                    </div> */}
                                </div>
                     </div>     

                                     <h6 className='text-center text-danger' >{error ? error : ''}</h6>
                <div className="mt-2 text-center">
                    {Edit &&
                        <button type="button" className="btn mb-2 mt-4 me-2 btn-secondary" onClick={(e) => window.history.back()}>Discard</button>
                    }
                    {!loading &&
                        <button className="btn mb-2 mt-4" style={{ 'backgroundColor': '#55374a', 'color': 'white' }}
                            onClick={() => setSubmit2(true)
                                // just for testing something not required !!!!
                            }
                        >
                            {Edit ? 'Update' : 'Create '}
                        </button>
                    }
                    {loading &&
                        <button className="btn  mb-2 mt-4" disabled style={{ 'backgroundColor': '#55374a', 'color': 'white' }}>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

                        </button>
                    }
                </div>             

        </form>

    </div>
  )
}
