import React from "react";
import { useQuery } from "react-query";
import { getAllServiceCatagory, GET_ALL_SERVICE_CATAGORY } from "..";

export const useGetAllServiceCatagoryQuery = ({ enabled = false }) => {
  return {
    query: useQuery([GET_ALL_SERVICE_CATAGORY], getAllServiceCatagory, {
      enabled,
    }),
  };
};
