import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {AiOutlineCheck,AiOutlineClose} from "react-icons/ai"
import { FaPhotoVideo, FaVideo } from "react-icons/fa";
import {BsFillPlusCircleFill} from "react-icons/bs"
import { GrRefresh } from "react-icons/gr";


import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function BrandconslEdit() {

    const [cat, setCat] = useState([]);
    const [category, setCategory] = useState(false);
    const [subcategory, setSubcategory] = useState(false);
    const [subcategory1, setSubcategory1] = useState(false);

    const [Stateload, setStateload] = useState(true);
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState(false);
    const [Stateload1, setStateload1] = useState(true);
    const [loading1, setloading1] = useState(false)
    const [loader, setLoader] = useState(<div id='iooioooo1231312' className='container  d-flex mt-5 pt-5  justify-content-center p-3 p-md-5'><div class="spinner-border text-dark" role="status">
        <span class="visually-hidden">Loading...</span>
    </div></div>)

    useEffect(() => {
      reload()

    }, []);

    const notify = (message, sab) => {
      if (sab) {
        toast.success(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.warning(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };


    const [value1, setValue1] = useState({
       
        category: "",
        type:true
      });
      const [value, setValue] = useState({
       
        category: "",
        subcategory:'',
        Brandname:"",
        Position:"",

      });


    
      const sabari = (e) => {
        console.log("haii")
        console.log(e.target.value);
        var anadhan;
        setValue({ ...value, category: e.target.value });
        // selectCtategory( e.target.value)
        // setValue({...value,})
        cat.forEach((element) => {
          console.log(element._id);
          if (element._id === e.target.value) {
            anadhan = element.sub.map((elements) => {
              return <option value={elements._id}>{elements.sub}</option>;
            });
          } else {
          }
        });
        setSubcategory(anadhan);
      };
      const sabari1 = (e) => {
        console.log("haii")
        console.log(e.target.value);
        var anadhan;
        // setValue({ ...value, category: e.target.value });
        // selectCtategory( e.target.value)
        // setValue({...value,})
        cat.forEach((element) => {
          console.log(element._id);
          if (element._id === e.target.value) {
            anadhan = element.sub.map((elements) => {
              return <option value={elements._id}>{elements.sub}</option>;
            });
          } else {
          }
        });
        setSubcategory1(anadhan);
      };

        function reload()
        {
            setloading1(true)
          axios
          .get("/api/api/getcategory")
          .then((res) => {
            if (res.status === 200) {
              console.log("workd");
              setCat(res.data);
            setloading1(false)
            setValue({
              category:"",
              subcategoryname:"",
                    subcategory:"",
                     Position:''
                  //   subcategory1:''
                    
          })

            }
          })
          .catch((err) => {
            console.log(err.response.status);
          });
        }

    
    
    
      var sab = [];
      useEffect(() => {
        if (cat.length >= 1) {
          cat.forEach((element) => {
            sab.push(<option value={element._id}>{element.category}</option>);
          });
          setCategory(sab);
        } else {
          console.log("nothing");
        }
      }, [cat]);
      // useEffect(() => {
      //   if (Stateload) print_state("State");
      //   setStateload(false);
      // }, []);





      // const selectSubCtategory=async(category)=>{
      //   console.log(category)
      //   // return
      //   var select= await axios.post('/api/Admin/selectSubCtategory',{category}).then((res)=>{
      //     if(res.status==200){
      //       // notify("Successfully added", true);
      //       console.log(res.data)
      //       // setcatname(res.data)
      //       setValue({
      //           category:res.data.catid,
      //           Brandname:"",
      //           position:"",
      //                 subcategory:res.data._id,
      //                 first:false,
      //                 subcategoryAfter:null,
      //                 formType:res.data.formType

      //       })

      //       if(res.data.formType==1)
      //       {

      //         document.getElementById('1formType1').checked=true

      //       }
      //       else if(res.data.formType==2)
      //       {
      //         document.getElementById('1formType2').checked=true

      //       }
      //       else if(res.data.formType==3)
      //       {
      //         document.getElementById('1formType3').checked=true

      //       }
      //       else{
      //         document.getElementById('1formType4').checked=true

      //       }
      //     }

      //   }).catch((err)=>{
      //     console.log(err)
      //     notify(" Something went wrong", false);
      //     setloading(false)

      //   })

      // }
      const [personName, setPersonName] = useState([]);
      
      const [idup, setidup] = useState({
        ids:[],
        formType:null
      });
      
      var a=[]
      const EditSubCategorys= async(e)=>{
         console.log(value)
        //  return
         if(!value.category)
         {
            return seterror("Select category")
         }
         seterror("")
         if(!value.subcategory)
         {
             return seterror("Select sub category ")
         }
         seterror("")
 
         if(!value.Brandname)
         {
             return seterror("Enter brand category name")
         }
         seterror("")
        //  if(!value.first||!value.subcategory)
        //  {
        //      return seterror("Select position")
        //  }
         let update=await axios.post('/api/Admin/ConsBrandAdd',{value}).then((res)=>{
          if(res.status==200)
          {
            

            notify("Successfully Added", true);
            
              setValue({
                category:"",
                Brandname:"",
                position:"", 
                subcategory:""                  
            })
            // window.location.reload();
            reload()
            console.log(value)

          }
         }).catch((err)=>{
          console.log(err)
          notify(" Something went wrong", false);
          setloading(false)

        })

      }
      function timer()
      {
        setTimeout(() => {
           setloading1(false)
          }, 500);

      }

      function getStyles(name, personName, theme) {
        return {
          fontWeight:
            personName.indexOf(name) === -1
              ? theme.typography.fontWeightRegular
              : theme.typography.fontWeightMedium,
        };
      }
      const theme = useTheme();
    
      const handleChange = (event) => {
        const {
          target: { value },
        } = event;
        setPersonName(
          // On autofill we get a stringified value.
          // typeof value === 'string' ? value.split(',') : 
          value
        );
      };
      const selectSubCtategory=async(category)=>{
        console.log(category)
        // return
        var select= await axios.post('/api/Admin/selectSubCtategory',{category}).then((res)=>{
          if(res.status==200){
            // notify("Successfully added", true);
            console.log(res.data)
            // setcatname(res.data)
            setValue({
                category:res.data.catid,
                subcategoryname:res.data.sub,
                      subcategory:res.data._id,
                      Position:''
            })
          }


        
        }).catch((err)=>{
          console.log(err)
          notify(" Something went wrong", false);
          setloading(false)

        })

      }
      

  return (
    <>
     <h1>Consultants Brand</h1><br />
        
     <div className='row'>
            <div className='col-3'> <label className="labels">Category</label></div><div className='col-3'>
            <select
                  className="form-select"
                  value={value.category}
                  onChange={(e) => {
                    sabari(e);sabari1(e)
                  }}
                //   onClick={(e)=>sabari1(e)}
                >
                  <option value="" disabled selected>
                    Select your category
                  </option>
                  {category ? category : ""}
                </select>
            </div>
            {/* <div className="col-1">
            {!loading1 &&   <button className="btn btn-success" onClick={(e)=>{reload();timer()}}>Refresh</button> }
            {loading1 &&   <button className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button> }
            </div> */}
        </div><br />
        <div className="row">
        <div className='col-3'> <label className="labels">Subcategory</label></div>
            <div className='col-3'>
            <select
                  className="form-select"
                  value={value.subcategory}
                  onChange={(e) =>
                    setValue({ ...value, subcategory: e.target.value })
                  }
                  onClick={(e)=>selectSubCtategory(e.target.value)}
                >
                  <option value="" disabled selected>
                    Select your sub category
                  </option>
                  {subcategory ? subcategory : ""}
                </select>
            </div>
           
      
        </div>
        <br></br>
        <div className='row'>
            <div className='col-3'> Brand</div><div className='col-3'><input className="form-control" type="text"  value={value.Brandname}
                    onChange={(e) =>
                      setValue({ ...value, Brandname: e.target.value })
                    }></input></div>
                    {/* <div className="col">  <div className="col">
                    {!loading &&   <button className="btn btn-success" onClick={(e)=>AddCategorys(e)}>Add</button> }
                    {loading &&   <button className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button> }
                    </div></div> */}
        </div><br />
        <div className='row'>
            <div className='col-3'>Position</div><div className='col-3'><input className="form-control" type="text"  value={value.Position}
                    onChange={(e) =>
                      setValue({ ...value, Position: e.target.value })
                    }></input></div>
                    {/* <div className="col">  <div className="col">
                    {!loading &&   <button className="btn btn-success" onClick={(e)=>AddCategorys(e)}>Add</button> }
                    {loading &&   <button className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button> }
                    </div></div> */}
        </div><br />
                {/* <div className='row'>
            <div className='col-3'> <label className="labels">After</label></div>
            <div className='col-3'>
            <select
                  className="form-select"
                  value={value.subcategoryAfter}
                  onChange={(e) =>
                    setValue({ ...value, subcategoryAfter: e.target.value })
                  }
                >
                  <option value="" disabled selected>
                    Select your sub category
                  </option>
                  {subcategory1 ? subcategory1 : ""}
                </select>
            </div>
            <div className="col-4">
            <label className="labels">First subcategory</label>
            &nbsp;&nbsp;&nbsp;
            {console.log(value.first)}
            
                      {
                        value.first? <input type="checkbox" value={false} className="form-check-input " defaultChecked  onChange={(e) =>
                            setValue({ ...value, first: (!value.first) })
                          } ></input>:
                          <input type="checkbox" value={false} className="form-check-input "  onChange={(e) =>
                            setValue({ ...value, first: (!value.first) })
                          } ></input>

                      }
              
            </div>
         
        </div> */}
        {/* <div className="row">
          <div className="col-3">
            Form Type
          </div>
          <div className="col">

                  1 <input onChange={(e) => setValue ({ ...value, formType: e.target.value })} type="radio" className="form-check-input"  name="formType" id="1formType1" value={1} />
                    &nbsp; &nbsp; 2<input onChange={(e) => setValue({ ...value, formType: e.target.value })} type="radio" className="form-check-input" name="formType" id="1formType2" value={2} />
                    &nbsp; &nbsp; 3 <input onChange={(e) => setValue({ ...value, formType: e.target.value })} type="radio" className="form-check-input" name="formType"  id="1formType3" value={3} />
                    &nbsp; &nbsp; 4 <input onChange={(e) => setValue({ ...value, formType: e.target.value })} type="radio" className="form-check-input" name="formType"  id="1formType4"value={4} />
                    
          </div>

        </div> */}
        {/* <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-chip-label">Select subcatagory</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {subcategory?subcategory.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          )):''}
        </Select>
      </FormControl>
    </div>
     */}
        <div className="row">
          <div className="col">
 
          </div>
          <div className="col">
         
               
          </div>
          <div className="col">
                <div className="col">
            {!loading &&   <button className="btn btn-success" onClick={(e)=>EditSubCategorys(e)}>Add</button> }
            {loading &&   <button className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button> }
            </div>
    
          </div>
        </div>
    
    
    
        <br />
       
        
        <div className="row">
            <div className="col"><p style={{"color":'red'}}>{error?error:''}</p></div>
            {/* <div className="col">
            {!loading &&   <button className="btn btn-success" onClick={(e)=>EditCategorys(e)}>Add</button> }
            {loading &&   <button className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button> }
            </div> */}
        </div>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    
    
    </>
     )
}
