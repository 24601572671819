import React, { useState, useEffect,createContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { print_state, print_city, state_arr, s_a } from '../../../../../Utility/stateCity'
import Map from "../../../../Maps/Maps";

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { keyGen } from "../../../../../Utility/helper";
import MapWithSwitch from "../../../../AdminMap/MapWithSwitch";
export let EditContext = createContext()


export default function PoliceStationsReg(props) {
  const [cat, setCat] = useState([]);
  const [category, setCategory] = useState(false);
  const [subcategory, setSubcategory] = useState(false);
  const [Stateload, setStateload] = useState(true);
  const [loading, setloading] = useState(false)
  const [error, seterror] = useState(false);
  const [location, setlocation] = useState({ lat: 28.633008623330056, lng: 77.22197631835937 })

  const [lat, setLat] = useState(28.633008623330056);
  const [lng, setLng] = useState(77.22197631835937);
  const [status, setStatus] = useState(null);
  const [AddingLocation, setAddingLocation] = useState(false)
  // if (AddingLocation) {
  //   getLocation()
  // }
  const [key, setkey] = useState(keyGen())
  const getLocation = () => {
    setAddingLocation(!AddingLocation)
    if (AddingLocation) {
      return
    }
    if (!navigator.geolocation) {
      setStatus('Geolocation is not supported by your browser');
      alert('Geolocation is not supported by your browser')

    } else {
      setStatus('Locating...');
      navigator.geolocation.getCurrentPosition((position) => {
        setStatus(null);
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
        setlocation({ lat: position.coords.latitude, lng: position.coords.longitude })
        console.log(position, position.coords.latitude)
      }, () => {
        setStatus('Unable to retrieve your location');
        alert('Unable to retrieve your location')

      });
    }

  }



  useEffect(() => {
    console.log(location)
    setkey(keyGen())
    return () => {

    }
  }, [location])


  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };



  const [value, setValue] = useState({
    name: "",
    phone: "",
    phone2: "",
    state: "",
    city: "",
    ServiceCharges: "",
    bloodgroup: "",
    pincode: "",
    Address: "",
    Availability: ""
  });
  const [mapPosition, setmapPosition] = useState({
    lng: '',
    lat: ''
  })


  function Cityshow(e) {
    var index = document.getElementById(e.target.id).selectedIndex;
    print_city("City", index);
    setValue({ ...value, state: e.target.value });
  }

  const sabari = (e) => {
    console.log(e.target.value);
    var anadhan;
    setValue({ ...value, category: e.target.value });
    cat.forEach((element) => {
      console.log(element._id);
      if (element._id === e.target.value) {
        anadhan = element.sub.map((elements) => {
          return <option value={elements._id}>{elements.sub}</option>;
        });
      } else {
      }
    });
    setSubcategory(anadhan);
  };

  useEffect(() => {
    axios
      .get("/api/api/getcategory")
      .then((res) => {
        if (res.status === 200) {
          console.log("workd");
          setCat(res.data);
        }
      })
      .catch((err) => {
        console.log(err.response.status);
      });
  }, []);

  var sab = [];
  useEffect(() => {
    if (cat.length >= 1) {
      cat.forEach((element) => {
        sab.push(<option value={element._id}>{element.category}</option>);
      });
      setCategory(sab);
    } else {
      console.log("nothing");
    }
  }, [cat]);
  useEffect(() => {
    if (Stateload) print_state("State");
    setStateload(false);
  }, []);
  const [Location, setLocation] = useState({lat:'',lng:''})
  const [LocationOn, setLocationOn] = useState(false)

  const dasapppan = async (e) => {



    if (!value.name) return seterror("Name is missing !");
    if (value.state.length === 0) return seterror("State is missing !");
    if (value.city.length === 0) return seterror("City is missing !");
    if (value.pincode) {
      if (value.pincode.toString().length != 6) return seterror(" Enter valid Pin code is missing !");

    }

    if (!value.phone) {
      return seterror('Enter your phone number')
    }
    // if (value.phone.toString().length != 10) {
    //   return seterror('Enter a valid mobile number')
    // }

    // if(value.bloodgroup.length===0) return seterror("Blood group is missing !")
     var me=false
        if(Location.lat&&Location.lng)
        {
            me=true
        }

    let value1 = {
      mapPosition:Location,
      AddingLocation: me

    }
    console.log(value1)
    //  return
    setloading(true)
    let sab = await axios.post('/api/Admin/PoliceStationsReg', {
      value, value1
    }).then((res) => {
      if (res.status == 200) {
        notify("Successfully added", true);
        setValue({
          name: "",
          phone: "",
          phone2: "",
          state: "",
          city: "",
          pincode: "",
          location: {
            address: '',
            lat: '',
            log: ''
          }
        })

      }
    }).catch((err) => {
      console.log(err)
      notify(" Something went wrong", false);
      // setValue({
      //   name: "",
      //   phone: "",
      //   state: "",
      //   city: "",
      //   ServiceCharges: "",  
      //   bloodgroup:"",
      //   pincode :""   

      //  })
    })
    setloading(false)

  }





  return (
    <EditContext.Provider value={{ Edit: false, Location, LocationOn }}  >

    <div className="container pt-5 mt-3">
      <h1>Police Stations</h1>
      <div className='row'>
        <div className='col'><label className="labels">Name *</label></div><div className='col'><input className="form-control" type="text" value={value.name}
          onChange={(e) =>
            setValue({ ...value, name: e.target.value })
          }></input></div>
      </div><br />

      <div className='row'>
        <div className='col'> <label className="labels">State *</label></div><div className='col'> <select
          className="form-select"
          value={value.state}
          id="State"
          name="City"
          onChange={(e) => Cityshow(e)}
        >
          {/* <select onChange={(e) =>}  className="form-select" >
                            </select> */}
        </select></div>
      </div><br />
      <div className='row'>
        <div className='col'> <label className="labels">City *</label></div><div className='col'>
          <select
            className="form-select"
            name="City"
            value={value.city}
            id="City"
            onChange={(e) =>
              setValue({ ...value, city: e.target.value })
            }
          >
            <option>Select City</option>
          </select></div>
      </div><br />
      <div className='row'>
        <div className='col'><label className="labels">PIN Code</label></div><div className='col'><input type="number" className="form-control" value={value.pincode}
          onChange={(e) =>
            setValue({ ...value, pincode: e.target.value })
          }></input></div>
      </div><br />
      <div className='row'>
        <div className='col'>  <label className="labels">Contact Number *</label></div><div className='col'>
          <input type="number" value={value.phone} className="form-control"
            onChange={(e) => {  setValue({ ...value, phone: e.target.value }) }
            }></input></div>
      </div><br />
      <div className='row'>
        <div className='col'>  <label className="labels">Contact Number 2</label></div><div className='col'>
          <input type="number" value={value.phone2} className="form-control"
            onChange={(e) => {  setValue({ ...value, phone2: e.target.value }) }
            }></input></div>
      </div><br />
      <MapWithSwitch Location={Location} Edit={true} EditContext={EditContext} setLocation={setLocation} LocationOn={LocationOn} setLocationOn={setLocationOn} key={"aouhduahdiuqbduiqderhetjyvq65564"} />

      {/* <h5 className="mb-3 mt-5 ">Hide profile temporarily</h5> */}
      {/* <p>These settings help you keep your account secure.</p> */}
      {/* <div className="list-group-item shadow ">
        <div className="row align-items-center">
          <div className="col">
            <strong className="mb-2">Add location</strong>
          </div>
          <div className="col-auto">

            <FormGroup>
              <FormControlLabel control={<Switch onChange={(e) => getLocation()} />} />
            </FormGroup>
          </div>
        </div>
      </div> */}
      <br></br>
      {
        AddingLocation ?
          <div style={{ height: '400px' }}>
            <Map
              key={key}
              center={location}
              height='300px'
              zoom={10}
              setmapPosition={setmapPosition}
            /></div>
          :
          ""
      }

      <br></br>
      <br></br>

      <br></br>
      <br></br>

      <br></br>


      <div className="row">
        <div className="col"><p style={{ "color": 'red' }}>{error ? error : ''}</p></div>
        <div className="col">
          {!loading && <button className="btn pro-spaces-button3" onClick={(e) => dasapppan(e)}>Add</button>}
          {loading && <button className="btn btn-success" disabled><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>}

        </div>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
    </EditContext.Provider>
  )
}
