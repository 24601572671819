import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';

export const Abstractdailymonthlytargets = () => {
  const [Count, setCount] = useState();
  useEffect(() => {
    axios
      .post('/api/User/dcecount')
      .then((res) => {
        console.log(res.data);
        setCount(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="container mt-5  d-flex justify-content-center">
        <div
          className="card mt-5"
          style={{
            width: '45rem',
            padding: '10px',
            boxShadow: '5px 10px 18px grey',
            borderRadius: '20px',
          }}
        >
          <div className="card-body">
            <div className="row d-flex justify-content-center">
              <div className="col-6">
                <div className="mb-3">
                  Daily Targets :{' '}
                  {Count?.todayCount ? (
                    <span>{Count?.todayCount}</span>
                  ) : (
                    <span>0</span>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div>
                  <b> </b>
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  Monthly Targets :{' '}
                  {Count?.monthlyCount ? (
                    <span>{Count?.monthlyCount}</span>
                  ) : (
                    <span>0</span>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div>
                  <b></b>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
