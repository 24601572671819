import React from 'react';
import { useQuery } from 'react-query';
import { GET_ALL_ADMIN, getAllAdmin } from '..';

export const useGetAllAdminQuery = ({ enabled = false }) => {
  return {
    query: useQuery([GET_ALL_ADMIN], getAllAdmin, {
      enabled,
    }),
  };
};
