import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './CouponView.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Moment from 'moment';
const notify = (message, sab) => {
  if (sab) {
    toast.success(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.warning(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export function CouponView() {
  const [first1, setfirst1] = useState(false);
  const [Error, setError] = useState('');
  const [Loading, setLoading] = useState(false);
  var arr = [];
  const [first, setfirst] = useState({
    name: '',
    amount: '',
    validity: '',
    utype: 'ALL',
    ctype: 'GENERAL',
    extendsvalidity: 1,
    pin: '',
  });
  useEffect(() => {
    fetch();
  }, []);

  const Utype = (value) => {
    if (value === 'ACTIVE') {
      return 'PRIME';
    } else if (value === 'ACTIVEV2') {
      return 'ELITE';
    } else if (value === 'ACTIVEV3') {
      return 'BASIC';
    } else if (value === 'PASSIVE') {
      return 'PASSIVE';
    } else if (value === 'ALL' || value === 'GENERAL') {
      return 'ALL';
    } else {
      console.log(value);
    }
  };
  function fetch() {
    axios
      .get('/api/admin/getcoupon')
      .then((res) => {
        // console.log(res.data)
        console.log('Coupon');
        arr = res.data;
        setfirst1(arr);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setfirst({
      id: '',
      name: '',
      amount: '',
      validity: '',
      utype: 'ALL',
      ctype: 'GENERAL',
      extendsvalidity: 1,
      pin: '',
      showPin: false,
    });
  };
  const handleShow = () => setShow(true);
  const OpenModal = (data) => {
    var date = Moment(data.validity).format('YYYY-MM-DD');
    setfirst({
      id: data._id,
      name: data.name,
      amount: data.amount,
      validity: date,
      utype: data.utype,
      ctype: data.ctype,
      extendsvalidity: data.extendsvalidity,
    });
    handleShow();
  };
  const Update = () => {
    if (first.name.length < 6) {
      return setError('Name must be 6 letters !');
    } else {
      setError('');
    }
    setLoading(true);
    axios
      .post('/api/admin/UpdateCoupon', { first })
      .then((res) => {
        console.log(res);
        notify('Coupon updated successfully', true);

        if (res.status == 200) {
          setfirst({
            id: '',
            name: '',
            amount: '',
            validity: '',
            utype: 'ALL',
            ctype: 'GENERAL',
            extendsvalidity: 1,
            pin: '',
            showPin: false,
          });
          setLoading(false);
          handleClose();
          fetch();
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status == 401) {
          notify('Wrong pin', false);
        } else {
          notify('Something went wrong', false);
        }
        setLoading(false);
      });
  };

  const handleClickShowPassword1 = () => {
    setfirst({
      ...first,
      showPin: !first.showPin,
    });
  };

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  return (
    <div
      style={{ height: '100%', overflow: 'hidden' }}
      className=" p-2 border bg-light"
    >
      <div>
        <div className="bg-secondary text-light text-center p-3">
          {' '}
          <h1 className="text-capital ">COUPONS</h1>
        </div>
        <div className="p-2 ps-0">
          {' '}
          <Link
            to="/Coupon"
            aria-current="true"
            className="btn btn-success px-5"
          >
            <span className="mx-2">+</span>New Coupon
          </Link>
        </div>
      </div>
      <div className="RollContainer p-0">
        <table className="table table-striped table-hover table-bordered ">
          <thead className="pro-sticky-head">
            <tr className="tableHead">
              <th scope="col">No</th>

              <th scope="col">Name</th>
              <th scope="col">Discount</th>
              <th scope="col">Validity</th>
              <th scope="col">User Type</th>
              <th scope="col">Profile validity</th>
              <th scope="col" colspan="2">
                Action
              </th>
            </tr>
          </thead>
          <tbody className="tableBodyPro" style={{ borderTopColor: '#55374a' }}>
            {first1
              ? first1.map((element, index) => {
                  return (
                    <tr className="tableBody pro-overFlow-Y-scroll">
                      <th scope="row">{index + 1}</th>
                      <th scope="row">{element.name}</th>
                      <td>{element.amount}&nbsp;%</td>
                      <td>{Moment(element.validity).format('DD-MM-YYYY')}</td>
                      <td>{Utype(element.utype)}</td>
                      <td>
                        {element.extendsvalidity == null ||
                        element.extendsvalidity == '' ||
                        element.extendsvalidity == 1
                          ? '1 year'
                          : element.extendsvalidity + ' years'}
                      </td>
                      <td>
                        <button
                          className="btn btn-outline-primary"
                          onClick={(e) => OpenModal(element)}
                        >
                          Edit
                        </button>
                      </td>
                      <td>
                        <DeleteSubAdmin id={element._id} fetch={fetch} />{' '}
                      </td>
                    </tr>
                  );
                })
              : ''}
          </tbody>
        </table>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row gy-1">
              <div className="col-5">Coupon Name</div>
              <div className="col-7">
                <input
                  type="text"
                  className="form-control"
                  value={first.name}
                  onChange={(e) =>
                    setfirst({ ...first, name: e.target.value.toUpperCase() })
                  }
                ></input>
              </div>

              <div className="col-5">Discount</div>
              <div className="col-7">
                <input
                  type="number"
                  placeholder="In percentage (max 2 digit) !"
                  className="form-control"
                  value={first.amount}
                  onChange={(e) =>
                    setfirst({ ...first, amount: e.target.value })
                  }
                ></input>
              </div>

              <div className="col-5">Coupon Validity</div>
              <div className="col-7">
                <input
                  type="date"
                  className="form-control"
                  value={first.validity}
                  onChange={(e) =>
                    setfirst({ ...first, validity: e.target.value })
                  }
                ></input>
              </div>
              <div className="col-5">Coupon Type</div>
              <div className="col-7">
                <select
                  className="form-control"
                  id="usertype"
                  value={first.ctype}
                  onChange={(e) =>
                    setfirst({ ...first, ctype: e.target.value })
                  }
                >
                  <option selected value="GENERAL">
                    GENERAL
                  </option>
                  <option value="ADVERTISEMENT">ADVERTISEMENT</option>
                </select>
              </div>
              <div className="col-5">User Type</div>
              <div className="col-7">
                <select
                  className="form-control"
                  id="usertype"
                  value={first.utype}
                  onChange={(e) =>
                    setfirst({ ...first, utype: e.target.value })
                  }
                >
                  <option value="ALL">ALL</option>
                  <option value="ACTIVE">PRIME</option>
                  <option value="ACTIVEV2">ELITE</option>
                  <option value="ACTIVEV3">BASIC</option>
                  <option value="PASSIVE">PASSIVE</option>
                </select>
              </div>

              {/* <div className='col-5'>
        Number of extended year
    </div>
    <div className='col-7'>
        <input type="Number" className='form-control' value={first.extendsvalidity} onChange={(e) => setfirst({ ...first, extendsvalidity: e.target.value })}></input>
    </div> */}

              <div className="col-5"></div>
              <div className="col-7">
                <FormControl variant="outlined" className="form-control mt-2">
                  {/* sx={{ m: 1, width: '25ch' }} */}
                  <InputLabel htmlFor="outlined-adornment-password">
                    Pin
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={first.showPin ? 'text' : 'password'}
                    value={first.pin}
                    onChange={(e) => {
                      setfirst({ ...first, pin: e.target.value });
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword1}
                          onMouseDown={handleMouseDownPassword1}
                          edge="end"
                        >
                          {first.showPin ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="pin"
                  />
                </FormControl>
              </div>

              <div className="col-6">
                <p className="text-danger">{Error}</p>
                {/* <button className='btn btn-success' type='button' onClick={(e) => submit(e)}>Create</button> */}
              </div>
              <div className="col-1"></div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {Loading ? (
            <button className="btn pro-spaces-button3 " disabled>
              {' '}
              Update
            </button>
          ) : (
            <button
              className="btn pro-spaces-button3 "
              onClick={(e) => Update()}
            >
              {' '}
              Update
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

function DeleteSubAdmin(props) {
  const [value, setValue] = useState({
    Pin: '',
    showPin: false,
  });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const handleChange = (prop) => (event) => {
    setValue({ ...value, [prop]: event.target.value });
  };
  const handleClickShowPassword1 = () => {
    setValue({
      ...value,
      showPin: !value.showPin,
    });
  };

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };
  function Delete() {
    if (!value.Pin) return setError('security pin is missing !');
    var values = {
      value,
      id: props.id,
    };
    // console.log(values)
    setLoading(true);
    // return

    axios
      .post('/api/SuperAdmin/DeleteCoupon', { values })
      .then((res) => {
        if (res.status === 200) {
          handleClose();
          notify('Coupon delete successfully', true);
          setLoading(false);
          setValue({
            ...value,
            Pin: '',
          });
          props.fetch();
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          notify('Wrong security pin', false);
          setLoading(false);
        } else {
          notify('Something went wrong', false);
          setLoading(false);
        }
      });
  }

  return (
    <div>
      <button className="btn btn-outline-danger" onClick={() => handleShow()}>
        Delete
      </button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControl variant="outlined" className="form-control mt-2">
            {/* sx={{ m: 1, width: '25ch' }} */}
            <InputLabel htmlFor="outlined-adornment-password">Pin</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={value.showPin ? 'text' : 'password'}
              value={value.Pin}
              onChange={(e) => {
                if (e.target.value.length == 5) return false;
                setValue({ ...value, Pin: e.target.value });
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword1}
                    onMouseDown={handleMouseDownPassword1}
                    edge="end"
                  >
                    {value.showPin ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="pin"
            />
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          {/* <Button variant="primary" onClick={}>
                        Update Password
                    </Button> */}
          <div className="row mt-4">
            <div className="col">
              <p style={{ color: 'red' }}>{error ? error : ''}</p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col  d-flex justify-content-end">
              {!loading && (
                <button
                  className="btn pro-spaces-button3"
                  onClick={() => Delete()}
                >
                  Delete
                </button>
              )}
              {loading && (
                <button className="btn pro-spaces-button3" disabled>
                  Deleting
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
