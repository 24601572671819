import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './payment.css';
import { useSearchParams } from 'react-router-dom';

function displayRazorpay(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export function Payment() {
  let [searchParams, setSearchParams] = useSearchParams();

  const [first, setfirst] = useState(null);
  const [Authenticated, setAuthenticated] = useState(false);
  let navigate = useNavigate();
  var ids = searchParams.get('id');

  useEffect(() => {
    axios
      .post('/api/Admin/checkforpayment', {
        ids,
      })
      .then((res) => {
        setfirst(res.data);
      })
      .catch((err) => {});
  }, []);

  async function RazorPay() {
    const res = await displayRazorpay(
      'https://checkout.razorpay.com/v1/checkout.js'
    );
    if (!res) {
      alert('failed sdk to load');
      return;
    }
    var tx = {
      Coupon,
    };
    axios
      .post('/api/payment', {
        method: 'POST',
        body: Coupon,
        first,
        Amount,
        payment_method,
        Validity,
        Invoice_method,
      })
      .then((t) => {
        var data = t.data;
        console.log(data);
        var options = {
          key: payment_method ? process.env.KEY_ID : 'rzp_test_hR7Z3hE19Q0n9L',
          //   key: process.env.KEY_ID, // Enter the Key ID generated from the Dashboard S6PuOik0Pm6t7RcAyX8Ws5i2
          amount: data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: 'INR',
          name: 'PROchure',
          order_id: data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          handler: function (response) {
            success(response);
          },

          theme: {
            color: '#55374a',
          },
        };
        console.log(options);

        var rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response) {
          console.log(response);
          alert(response.error.code);
          alert(response.error.description);
          alert(response.error.source);
          alert(response.error.step);
          alert(response.error.reason);
          alert(response.error.metadata.order_id);
          alert(response.error.metadata.payment_id);
        });
        // document.getElementById('rzp-button1').onclick = function (e) {
        rzp1.open();
        // e.preventDefault();
      })
      .catch((err) => {
        console.log(err.response);
        alert(err.response.data);
      });

    function success(res) {
      // alert('Congratulations....You are now a PRO- member !!')
      console.log(res);
      axios
        .post('/api/payment/successs', { res, GSTIN })
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data);
            // if (res.data === 'Consultant') {
            //     return navigate('/welcome?welcome=success')
            // } else if (res.data === 'Consultantv2') {
            //     return navigate('/')
            // } else if (res.data === 'Productv2') {
            //     return navigate('/')
            // } else if (res.data === 'Product') {
            //     return navigate('/welcome')
            // }
            // else if (res.data === 'Passive') {
            //     return navigate('/')
            // }
            navigate('/c_v_registration');
          }
        })
        .catch((err) => {});
    }
  }

  const [couponError, setcouponError] = useState('');
  const [Validity, setValidity] = useState('');
  const [couponsuccess, setcouponsuccess] = useState('');
  const [Coupon, setCoupon] = useState();
  const [Error, setError] = useState('');
  const checkCoupe = async () => {
    setCoupon('');
    setcouponError('');
    setcouponsuccess('');
    let coupon = document.getElementById('coupe').value;
    let dataSend = {
      coupon,
    };
    try {
      if (coupon.length > 5) {
        let result = await axios.post('/api/coupon/check', { dataSend });
        if (result) {
          console.log(result);
          setCoupon(coupon);
          setcouponError('');
          setcouponsuccess(
            'Coupon applied ! Now click on Make Payment to proceed further'
          );
        }
      } else {
        setcouponError('Invalid coupon / coupon withdrawn');
        console.log('first');
      }
    } catch (error) {
      console.log(error.response.data);
      setcouponError(error.response.data);
    }
  };

  const StartMyFreeTrail = async () => {
    try {
      let send = await axios.post('/api/coupon/StartFreeTrail');
      if (send.status === 200) {
        navigate('/');
      }
    } catch (err) {}
  };
  const [Amount, setAmount] = useState(0);
  const [GSTIN, setGSTIN] = useState();
  const [payment_method, setPayment_Method] = useState(false);
  const [Invoice_method, setInvoice_Method] = useState(true);

  const [Active, setActive] = useState('');
  const [Activev2, setActivev2] = useState('');
  const [Activev3, setActivev3] = useState('');
  const [PAssive, setPAssive] = useState('');
  useEffect(() => {
    axios
      .get('/api/free/getsubscriptionfees')
      .then((res) => {
        console.log(res.data);
        setActive(res.data.Active);
        setActivev2(res.data.Activev2);
        setActivev3(res.data.Activev3);
        setPAssive(res.data.Passive);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {/* <Navoutside /> */}
      <div className=" container d-flex justify-content-center pt-5">
        <div style={{ maxWidth: '500px' }} className="card  carrrrbuss">
          <div className="container p-1">
            {first ? (
              first === 'Product' ||
              first === 'Consultant' ||
              first === 'Productv2' ||
              first === 'Consultantv2' ||
              first === 'Productv3' ||
              first === 'Consultantv3' ||
              first === 'Passive' ? (
                <>
                  <div className="row ">
                    <center>
                      {' '}
                      <h3 className="eufgiueg mb-1">
                        {' '}
                        {first
                          ? first === 'Product' || first === 'Consultant'
                            ? 'PRIME league'
                            : first === 'Productv2' || first === 'Consultantv2'
                            ? 'ELITE club'
                            : first === 'Productv3' || first === 'Consultantv3'
                            ? 'BASIC listing'
                            : 'PASSIVE listing '
                          : ''}
                      </h3>
                      <p>
                        ( ₹{' '}
                        {first
                          ? first === 'Product' || first === 'Consultant'
                            ? Active
                            : first === 'Productv2' || first === 'Consultantv2'
                            ? Activev2
                            : Activev3
                          : ''}
                        /- inclusive of 18% GST )
                      </p>
                    </center>
                  </div>
                  <div className="row">
                    {first != 'Passive' ? (
                      <p className="text-center">
                        ONLY for
                        Service&nbsp;Providers&nbsp;/&nbsp;Product&nbsp;Retailers
                      </p>
                    ) : (
                      <p>
                        A&nbsp;Service&nbsp;Fee&nbsp;of&nbsp;₹&nbsp;{PAssive}
                        &nbsp;/-&nbsp;is&nbsp;applicable
                      </p>
                    )}
                    <p className="invisible mb-1">wwwwwwwwwwwwwwwwwwwwwwww</p>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div class="form-check">
                        <input
                          class="form-check-input blink_check"
                          type="radio"
                          name="setPayment_Method"
                          id="setPayment_Method_Offline"
                          defaultChecked
                          onChange={(e) => setPayment_Method(!payment_method)}
                        />
                        <label
                          class="form-check-label"
                          for="flexRadioDefault1Individual"
                        >
                          Offline
                        </label>
                      </div>
                    </div>
                    <div className="col-6">
                      <div class="form-check">
                        <input
                          class="form-check-input blink_check"
                          type="radio"
                          name="setPayment_Method"
                          id="setPayment_Method_Online"
                          onChange={(e) => setPayment_Method(!payment_method)}
                        />
                        <label
                          class="form-check-label"
                          for="flexRadioDefault1Company"
                        >
                          Online
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <div class="form-check">
                        <input
                          class="form-check-input blink_check"
                          type="radio"
                          name="setInvoice_Method"
                          id="setInvoice_Method_Offline"
                          defaultChecked
                          onChange={(e) => setInvoice_Method(!Invoice_method)}
                        />
                        <label
                          class="form-check-label"
                          for="flexRadioDefault1Individual"
                        >
                          With invoice
                        </label>
                      </div>
                    </div>
                    <div className="col-6">
                      <div class="form-check">
                        <input
                          class="form-check-input blink_check"
                          type="radio"
                          name="setInvoice_Method"
                          id="setInvoice_Method_Online"
                          onChange={(e) => setInvoice_Method(!Invoice_method)}
                        />
                        <label
                          class="form-check-label"
                          for="flexRadioDefault1Company"
                        >
                          Without invoice
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* <div className='row d-flex justify-content-center'>
                                <input type="text" id='coupe' className='textboxdrete bg-light'></input>
                                <div className='col d-flex justify-content-end py-3'>
                                    <button onClick={(e) => checkCoupe(e)} className='btn wygdiwgdigwidg'>Apply</button>
                                </div>
                            </div> */}

                  <div className="mt-2 d-none">
                    <p>
                      If you need a GST invoice for your subscription, then
                      please mention your GST number below
                    </p>
                    <input
                      onChange={(e) => setGSTIN(e.target.value)}
                      placeholder="Enter GST registration number"
                      className="form-control"
                      type={'text'}
                    />
                  </div>
                  <h6 className="eufgiueg mt-4">Validity</h6>
                  <div className="container ">
                    <div className="row">
                      <div className="col-12">
                        <input
                          type="date"
                          className="form-control"
                          value={Validity}
                          onChange={(e) => setValidity(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <h6 className="eufgiueg mt-4">Discount Amount</h6>
                  <div className="container ">
                    <div className="row">
                      <div className="col-12">
                        <input
                          type="number"
                          id="coupe"
                          value={Amount}
                          onChange={(e) => setAmount(e.target.value)}
                          placeholder=""
                          className="form-control bg-light"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col"></div>
                    <p className="text-success">
                      {couponsuccess ? couponsuccess : ''}
                    </p>
                    <p className="text-danger">
                      {couponError ? couponError : ''}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="row ">
                    <center>
                      {/* <h3 className='eufgiueg mb-1'> BASIC MEMBERSHIP</h3> */}
                      <p>A Service Fee of ₹ {PAssive} /- is applicable</p>
                    </center>
                  </div>

                  <div className="mt-2 mb-4">
                    {/* <p>If you need a GST invoice for your subscription, then please mention your GST number below</p>
                                    <input onChange={(e) => setGSTIN(e.target.value)} className='form-control' type={'text'} /> */}
                  </div>
                </>
              )
            ) : (
              <></>
            )}

            <p className="text-danger">{Error}</p>
            <div className="row mt-2">
              <button
                className="btn btn-udaguadgouao paymentbuttonsabari"
                onClick={RazorPay}
              >
                {first
                  ? first === 'Product' || first === 'Consultant'
                    ? 'PAY'
                    : 'PAY'
                  : 'Please Wait'}
              </button>
            </div>
            <div className="row mt-3 in d-none">
              <button
                className="btn btn-udaguadgouao"
                onClick={(e) => StartMyFreeTrail()}
              >
                Start One Day Free Trial
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
