import React from 'react'
import { Link } from "react-router-dom";


export default function Entertainment() {
  return (
    <div> <div className="container ">
    <div className="row slhishieo3jfiehfjefbewf">



    <div className="col-lg-6 col-md-6 col-sm-12">

<Link to="/movies" className="wgfiuwrgiu">

  <div className="card carrrrbus">


    <div className="padunnor"><center>Movies<br>

    </br></center></div>


  </div>
</Link>
</div>

<div className="col-lg-6 col-md-6 col-sm-12">

<Link to="/web_series" className="wgfiuwrgiu">

  <div className="card carrrrbus">


    <div className="padunnor"><center>Web Series<br>

    </br></center></div>


  </div>
</Link>
</div>

<div className="col-lg-6 col-md-6 col-sm-12">
<Link to="/sports" className="wgfiuwrgiu">

  <div className="card carrrrbus">


    <div className="padunnor"><center>Sports<br>

    </br></center></div>


  </div>
</Link>
</div>

<div className="col-lg-6 col-md-6 col-sm-12">

<Link to="/other" className="wgfiuwrgiu">

  <div className="card carrrrbus">


    <div className="padunnor"><center>Other<br>

    </br></center></div>


  </div>
</Link>
</div>






   

 


      
      
      {/* :""}
       */}
    </div>
 
</div></div>
  )
}
