import React from 'react';

import {
  MdMessage,
  MdOnlinePrediction,
  MdPersonOutline,
  MdPerson,
} from 'react-icons/md';

import './AdminDashBord.css';

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Axios from 'axios';
import { Footer } from '../../../Pages/HomePage/Footer';

export function AdminDashBord({ type, Auth1 }) {
  useEffect(() => {
    Auth1();
  }, []);

  const [loader, setloader] = useState(
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  );
  const [Loading, setLoading] = useState(true);
  const [Counts, setCounts] = useState(false);

  // var Counts

  return (
    <>
      <div
        style={{
          backgroundColor: '#5e334e',
          width: '100%',
          minHeight: '85vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div className="d-flex justify-content-center align-items-center">
          <img src="/prochure_aff_home.jpg" style={{ maxWidth: '340px' }} />
        </div>
        <div className="mt-5 pt-2 prochure1" style={{ textAlign: 'center' }}>
          <a
            target="_blank"
            className="prochure1"
            style={{
              textDecoration: 'none',
              fontSize: '30px',
            }}
            href="https://www.prochure.in/pre_launch_prochure"
          >
            www.prochure.in
          </a>
        </div>
      </div>

      {type === 'AffiliatedAccount' && (
        <div className="">
          <Footer />
        </div>
      )}
    </>
  );
}
