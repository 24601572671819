import React from 'react'
import { Link } from "react-router-dom";

export default function Prices() {
  return (
<div>
<div className="container ">
  <div className="row slhishieo3jfiehfjefbewf">

    <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

    <Link to="/fuel"  className="wgfiuwrgiu">

      <div className="card carrrrbus">
        
        
          <div className="padunnor"><center>Fuel<br>

          </br> </center></div>
        
        
      </div>
      </Link>
    </div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/jewellery" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Jewellery<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/ott_platforms" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>OTT&nbsp;Platforms<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/dth_services" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>DTH&nbsp;Services<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/courier_services" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Courier&nbsp;Services<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/indian_postal_services" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Indian&nbsp;Postal&nbsp;Services<br>

</br></center></div>


</div>
</Link>
</div>
<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/vegetables" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Vegetables<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/sea_food" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center> Sea&nbsp;Food <br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/chicken" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Chicken<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/mutton" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Mutton<br>

</br></center></div>


</div>
</Link>
</div>

{/* <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/railway_stations" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Railway Stations<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/airports" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Airports<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/post_offices" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Post&nbsp;Offices<br>

</br></center></div>


</div>
</Link>
</div>
<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/regional_transport_offices" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Regional&nbsp;Transport&nbsp;Offices<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/banks" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Banks<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/telecom" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Telecom<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/media" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Media<br>

</br></center></div>


</div>
</Link>
</div> */}



  </div>

</div>
</div>





  )
}
