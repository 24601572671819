import React, { useEffect, useRef, useState } from 'react';

import { ControlBar } from './ControlBar';
import { NavBar } from './NavBar';
import { Footer } from './Footer';
import moment from 'moment';

export const About = () => {
  const [age, setAge] = useState(0);
  const birthDate = '1978-07-22'; // your date of birth in YYYY-MM-DD format

  useEffect(() => {
    const calculateAge = () => {
      const currentDate = moment(); // Get the current date
      const birthMoment = moment(birthDate, 'YYYY-MM-DD');
      const ag = currentDate.diff(birthMoment, 'years'); // Calculate the age in years
      setAge(ag);
    };

    calculateAge(); // Calculate the age initially when the component mounts

    // To automatically update the age, you can use a setInterval
    const interval = setInterval(calculateAge, 1000 * 60); // Update every minute, change the interval as needed

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  return (
    <>
      <ControlBar />
      <NavBar />
      <section className="bgvipar text-white py-3">
        <div className="container w-100">
          <div className="d-flex justify-content-between align-items-center pt-3 ps-1">
            <span className="footlightfont">THE NAME</span>
            <span className="footlightfont">THE TAGLINE</span>
            <span className="footlightfont">THE LOGO</span>
          </div>
          <div className="d-flex justify-content-between align-items-center  ">
            <span
              className="vipar"
              style={{
                fontSize: '22px',
                color: '#99d050',
                paddingLeft: '12px',
              }}
            >
              {' '}
              Vipar
            </span>
            <div className=" ">
              <span style={{ fontSize: '15px' }} className=" ml">
                <span
                  className="vipar "
                  style={{ fontSize: '15px', color: '#99D050' }}
                >
                  &nbsp;&nbsp;&nbsp;Vi
                </span>
                <span className="vipar  fcgrey ml" style={{ fontSize: '15px' }}>
                  sionary
                </span>{' '}
                &nbsp;
                <span
                  className="vipar"
                  style={{ fontSize: '15px', color: '#99D050' }}
                >
                  &nbsp;par&nbsp;
                </span>
                <span className="vipar  fcgrey ml" style={{ fontSize: '15px' }}>
                  &nbsp;extremity
                </span>
              </span>
            </div>
            <span class="text-success logo" style={{ paddingRight: '10px' }}>
              <img
                className=""
                src="/viparman.png"
                alt=""
                height="50px"
                width="50px"
              />
            </span>
          </div>
        </div>
      </section>
      <section className="" style={{ backgroundColor: '#e9eef5' }}>
        <div className="container ps-3">
          <div className=" pt-5">
            <span
              className="footlightfont boldfive"
              style={{ fontWeight: '500' }}
            >
              {' '}
              THE NAME
            </span>
            <br />
          </div>

          <div className="pt-4">
            <span className="footlightfont">
              Our Brand Name "
              <span
                className="vipar"
                style={{ fontSize: '22px', color: '#99d050' }}
              >
                Vipar&nbsp;
              </span>
              " &nbsp;is a combination of words in the tagline.
            </span>
            <br />
          </div>
          <div className="pt-3 footlightfont">
            <span className="">The Name is pronounced as "y-per".</span>
            <br />
          </div>

          <div className="pt-5">
            <span
              className="footlightfont boldfive"
              style={{ fontWeight: '500' }}
            >
              THE LOGO
            </span>
            <br />
          </div>
          <div className="pt-4">
            <span className="footlightfont">
              A Logo is the Identity of a Brand and our Mascot "
              <span
                className="vipar"
                style={{ fontSize: '22px', color: '#99d050' }}
              >
                Vipar&nbsp;
              </span>
              -man" is our Logo.
            </span>
            <br />
          </div>
          <div
            className="pt-5 footlightfont boldfive"
            style={{ fontWeight: '500' }}
          >
            FOUNDER
          </div>
        </div>
      </section>
      <section className=" pb-3 " style={{ backgroundColor: '#e9eef5' }}>
        <div className="container w-100">
          <div className=" d-flex justify-content-between  align-items-center py-2 py-md-3 px-2">
            <div
              className=" "
              style={{
                fontSize: '16x',
                paddingTop: '15px',
                paddingBottom: '10px',
              }}
            >
              <span className="footlightfont">Ar. Vinod Venugopal</span>
              <br></br>
              <span className="footlightfont">{age} years</span>
              <br />

              <span className="footlightfont">
                Council of Architecture Reg.&nbsp;No.&nbsp;: CA/2011/51213
              </span>
            </div>
          </div>
          <div className="ps-2">
            <div className="footlightfont  ">
              Vinod Venugopal, a '96 batch graduate in B.Arch from M S Ramaiah
              Institute of Technology (Bangalore), is a very passionate
              Architect by profession.
            </div>
            <div className="">
              {/* <span className="footlightfont boldfive">OFFICE ADDRESS</span> */}
              {/* <br /> */}
            </div>
            <div className="pt-2 footlightfont invisible">
              <span className="">
                Vipar
                <br />
                # 103, 2nd Floor
                <br />
                2023/A, Jeevanbheema Nagar
                <br />
                14th Main, HAL 2nd Stage
                <br />
                Indiranagar, Bangalore
                <br />
                Pin code : 560038
              </span>
              {/* <br />
              <br />
              <div>{/* Contact Number : 080-41700022 */}
            </div>
          </div>
        </div>
      </section>
      <div className="pt-5" style={{ backgroundColor: '#e9eef5' }}>
        <Footer />
      </div>
    </>
  );
};
