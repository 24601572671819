import React, { useEffect, useState } from 'react';
import { Imageupload } from '../../ManageAdmin/Imageupload';
import axios from 'axios';
import { StateCity } from '../../ManageAdmin/StateCity';
import { notify } from '../../../Utility/notify';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';

export const VFprofile = () => {
  const [value, setValue] = useState({});

  const [error, setError] = useState('');

  const [Edit, setEdit] = useState('');

  const [Submit2, setSubmit2] = useState(false);

  const [imgi, setimgi] = useState();

  const [params, setparams] = useSearchParams();
  var id = params.get('k');

  useEffect(() => {
    console.log(id);
  }, [id]);

  // const submit = (e) => {
  //   e.preventDefault();
  //   console.log(imgi);
  //   var formdata = new FormData();
  //   var imag = new File([imgi], 'avatar.png', { type: 'image/png' });
  //   formdata.append('file', imag);
  //   formdata.append('data', JSON.stringify({ value, refferal }));

  //   axios
  //     .post('/api/testform/testforminsert', formdata)
  //     .then((req) => {
  //       console.log('sdaasdsad');
  //       //   setValue({...value,
  //       //     category: '',
  //       // subcategory: '',

  //       // Brand: null,
  //       // ProfileName : "",
  //       // Area:"",
  //       // country:"",
  //       // city:"",
  //       // state:"",
  //       // Pincode:"",
  //       // contact:"",
  //       //   })

  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 3000);
  //       notify('Test Profile Created', true);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       notify('Something Went Wrong', false);
  //     });
  // };

  useEffect(() => {
    axios
      .post('/api/freelisting/freelistingprofileview', { id })
      .then((req) => {
        console.log(req.data);
        setValue(req.data);
        // setEdit(req.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  var url;
  url = `https://s3.ap-south-1.amazonaws.com/prochure.in.profile/AffliateProfile/${value._id}/avatar.png`;
  // url = `https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/AffliateProfile/${value._id}/avatar.png`;
  return (
    <form>
      <h5 className=" pt-4 pb-2 text-center">
        {' '}
        <u>FREE BUSINESS LISTING FORM</u>
      </h5>

      <section>
        <div className="  mx-3  mt-2">
          <div className="py-2">
            <h6 className="">CLASSIFICATION </h6>
            {/* <div class="form-check pt-1">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                checked={value.Classification === 'a Professional Consultant'}
                value={'a Professional Consultant'}
                onChange={(e) =>
                  setValue({
                    ...value,
                    Classification: e.target.value,
                  })
                }
              />
              <label class="form-check-label " for="flexRadioDefault1">
                a Professional Consultant ?
              </label>
            </div> */}
            <div class="form-check pt-1">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                checked={value.Classification === 'Services'}
                value={'Providing a Service'}
                onChange={(e) =>
                  setValue({
                    ...value,
                    Classification: e.target.value,
                  })
                }
              />
              <label class="form-check-label " for="flexRadioDefault1">
                Services
              </label>
            </div>
            <div class="form-check pt-1">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                checked={value.Classification === 'Products'}
                value={'Selling Products'}
                onChange={(e) =>
                  setValue({
                    ...value,
                    Classification: e.target.value,
                  })
                }
              />
              <label class="form-check-label " for="flexRadioDefault1">
                Products
              </label>
            </div>
            <div class="form-check pt-1">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                checked={
                  value.Classification === 'Public Utilities and Social Service'
                }
                value={'Public Utilities and Social Service'}
                onChange={(e) =>
                  setValue({
                    ...value,
                    Classification: e.target.value,
                  })
                }
              />
              <label class="form-check-label " for="flexRadioDefault1">
                Public Utilities and Social Service
              </label>
            </div>

            {/* <div className="invalid-feedback">PLEASE ENTER Category</div> */}
          </div>
          <div className="py-2">
            <h6 className="">
              Select your appropriate Category / Industry / Nature of Business{' '}
            </h6>
            <input
              type="text"
              value={value.CatandSubcat}
              className="form-control"
            />
          </div>
          <div className="py-2">
            <h6 className="">
              Give a brief or detailed description of the types of Services /
              Products{' '}
            </h6>
            <input
              type="text"
              value={value.Category}
              className="form-control"
            />
          </div>
          <div className="pt-4  ">
            <div className="pb-2 ">
              <h6>IMAGE </h6>
              <div className="card" style={{ width: '130px', height: '130px' }}>
                <img
                  src={url}
                  onError={(e) => {
                    return (e.target.src = '/blank.png');
                  }}
                  style={{ width: '130px', height: '130px' }}
                />
              </div>
            </div>
            <div className="pb-2 ">
              <h6>IMAGE </h6>

              <div className="card" style={{ width: '130px', height: '130px' }}>
                <img
                  src={url}
                  onError={(e) => {
                    return (e.target.src = '/blank.png');
                  }}
                  style={{ width: '130px', height: '130px' }}
                />
              </div>
            </div>
          </div>

          <div className=" ">
            <div className="pt-4 pb-2  pt-2">
              <div className="col-md-12">
                <h6 className="">
                  NAME of CONSULTANT / SERVICE PROVIDER / BUSINESS / SHOP
                </h6>
                <input
                  value={value.Shop_name}
                  placeholder="Individual / Entity / Company Name"
                  type="text"
                  className="form-control"
                />
              </div>

              <div className="col-md-12">
                <h6 className="pt-3 pb-2">ADDRESS </h6>

                <label className="labels">Secondary, Primary Locality </label>
                <input
                  type="text"
                  value={value.Area}
                  placeholder="e.g. HAL 2nd Stage, Indiranagar"
                  className="form-control"
                />
                {/* <div className="invalid-feedback">
                      PLEASE ENTER Secondary, Primary Locality
                    </div> */}
              </div>

              {/* <StateCity setValue={setValue} value={value} Edit={Edit} /> */}

              <div className="col-md-12">
                <label className="labels">Country </label>
                <div className="card ps-2 py-2">{value.country}</div>
              </div>
              <div className="col-md-12">
                <label className="labels">State </label>
                <div className="card ps-2 py-2">{value.state}</div>
              </div>
              <div className="col-md-12">
                <label className="labels">City </label>
                <div className="card ps-2 py-2">{value.city}</div>
              </div>
              <div className="col-md-12">
                <label className="labels">Pin code </label>
                <input
                  type="number"
                  value={value.Pincode}
                  className="form-control"
                />
              </div>

              <div className="col-md-12">
                <h6 className="pt-3 pb-2">CONTACT </h6>

                <label className="labels">
                  Mobile Number / Landline Number / Toll Free Number / Customer
                  Care Number
                </label>
                <input
                  type="text"
                  value={value.Contact}
                  className="form-control"
                  placeholder="Enter State Code before Contact Number"
                />
              </div>
              <div className="col-md-12 ">
                <label className="labels">Email id</label>
                <input
                  value={value.Email}
                  type="text"
                  className="form-control"
                  onChange={(e) =>
                    setValue({ ...value, Email: e.target.value })
                  }
                />
              </div>
              <div className="col-md-12 pt-4">
                <label className="labels">Profile Created by </label>
                <div class="form-check pt-1">
                  <input
                    required
                    checked={value.PCreatedby == 'Business / Profile Owner'}
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault1"
                    value={'Business / Profile Owner'}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        PCreatedby: e.target.value,
                      })
                    }
                  />
                  <label class="form-check-label " for="flexRadioDefault1">
                    Business / Profile Owner
                  </label>
                </div>
                <div class="form-check py-1 ">
                  <input
                    required
                    checked={value.PCreatedby == 'Friend / Acquaintance'}
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault1"
                    value={'Friend / Acquaintance'}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        PCreatedby: e.target.value,
                      })
                    }
                  />
                  <label class="form-check-label " for="flexRadioDefault1">
                    Friend / Acquaintance (of the Profile Owner)
                  </label>
                </div>
                <div class="form-check py-1 ">
                  <input
                    checked={value.PCreatedby == 'Relative'}
                    required
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault1"
                    value={'Relative'}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        PCreatedby: e.target.value,
                      })
                    }
                  />
                  <label class="form-check-label " for="flexRadioDefault1">
                    Relative (of the Profile Owner)
                  </label>
                </div>

                <div class="form-check py-1 ">
                  <input
                    checked={value.PCreatedby == 'Client / Customer'}
                    required
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault1"
                    value={'Client / Customer'}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        PCreatedby: e.target.value,
                      })
                    }
                  />
                  <label class="form-check-label " for="flexRadioDefault1">
                    Client / Customer (of the Profile Owner)
                  </label>
                </div>

                <div class="form-check py-1 ">
                  <input
                    checked={value.PCreatedby == 'Vipar Friend'}
                    required
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault1"
                    value={'Vipar Friend'}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        PCreatedby: e.target.value,
                      })
                    }
                  />
                  <label class="form-check-label " for="flexRadioDefault1">
                    Vipar Friend / DREE
                  </label>
                </div>
              </div>
              <div className="col-md-12 py-4">
                <label className="labels">Name of the Profile Creator </label>
                <input
                  value={value.Created_by}
                  type="text"
                  className="form-control"
                />
                <div className="invalid-feedback">
                  PLEASE ENTER Name of the Profile Creator
                </div>
              </div>
              <div className="col-md-12 pt-4">
                <label className="labels">Profile Created on </label>
                <input
                  required
                  type="text"
                  value={moment(value.Created_on).format('DD-MM-YYYY')}
                  className="form-control"
                />
                <div className="invalid-feedback">
                  PLEASE SELECT Profile Created on
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  );
};
