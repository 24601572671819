import { Link } from "react-router-dom"

export const UsersAd = () => {

    return <div style={{height:'100%'}} className="bg-light container">
        <div className="row slhishieo3jfiehfjefbewf">
            <div className="col-lg-6 col-md-6 col-sm-12">
                <Link to="/Consultants_add" className="wgfiuwrgiu">
                    <div className="card carrrrbus">
                        <div className="padunnor"><center>Services Provider  <br>
                        </br> </center></div>
                    </div>
                </Link>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <Link to="/Product_store_add" className="wgfiuwrgiu">
                    <div className="card carrrrbus">
                        <div className="padunnor"><center>Product Retailer   <br>
                        </br></center></div>
                    </div>
                </Link>
            </div>
            {/* <div className="col-lg-6 col-md-6 col-sm-12">
                <Link to="/Passive" className="wgfiuwrgiu">
                    <div className="card carrrrbus">
                        <div className="padunnor"><center>Passive<br>
                        </br></center></div>
                    </div>
                </Link>
            </div> */}
        </div>
    </div>
}