import React, { useEffect, useState } from 'react'

import { Route, Routes } from "react-router";
import { CreateProfile } from "../CreateProfileV3/CreateProfile"
import { CreateConseltent } from "../CreateConseltentV3/CreateConseltent"
import { CreateProduct } from "../CreateProductV3/CreateProduct"
import Axios from 'axios'
import { useNavigate } from 'react-router-dom'
export function ProfileCreation3() {
  
  return (
    <div >
        <Routes>
          <Route element={<CreateProfile />} exact path="/"></Route>
          <Route element={<CreateConseltent />} path="CreateConseltent"></Route>
          <Route element={<CreateProduct />} path="CreateProduct"></Route>
        </Routes>
    </div >
  )
}
