import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { keyGen } from "../../../../../Utility/helper";
import { print_state, print_city, state_arr,s_a, } from '../../../../../Utility/stateCity'
import { keyGen } from '../../../../../Utility/helper'


export default function DeceasedAnimalBurialGroundsReg() {
    const [cat, setCat] = useState([]);
    const [category, setCategory] = useState(false);
    const [subcategory, setSubcategory] = useState(false);
    const [Stateload, setStateload] = useState(true);
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState(false);


    
    const notify = (message, sab) => {
      if (sab) {
        toast.success(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.warning(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };



    const [value, setValue] = useState({
          name: "",
          phone: "",
          phone2: "",
          state: "",
          city: "",
          ServiceCharges: "",  
          ServicesProvided:"",
          pincode :"" ,
          locations:[] ,
      });



      function printlocation(city_id) {
        var option_str = document.getElementById(city_id);
        option_str.length = 0;
        option_str.options[0] = new Option('Select City', '');
        option_str.selectedIndex = 0;
        var city_arr = []
        var tot = ''
        for (var ip = 0; ip <= 35; ip++) {
            var str = s_a[ip]
            tot = tot.concat(str)
        }
        city_arr = tot.split("|");
        var x = city_arr.sort()

        for (var i = 0; i < x.length; i++) {
            option_str.options[option_str.length] = new Option(x[i], x[i]);
        }
    }
      const Statefix = () => {
          const City = document.getElementById('City').value
          const Statee = document.getElementById('State').value
          setValue({ ...value, state: Statee, city: City })
      }
  
  
      function Cityshow(e) {
          var index = document.getElementById(e.target.id).selectedIndex
          console.log(index)
          let state  = e.target.value
          Statefix()
          console.log(state)
          print_city('City', index)
      }
  
  
      useEffect(() => {
          if (Stateload)
              print_state("State")
          printlocation('jobLocation')
          setStateload(false)
      }, [])
  
  
  
  
      const [loc, setloc] = useState([])
      var locked = ''
      const [viewLoc, setviewLoc] = useState([])
      const locationSelection = (e) => {
          var st = loc.find(ef => ef === e)
          if (st) {
              return
          }
          loc.push(e)
          var len = 0
          locked = loc.map((myList) => {
              return <div className='disposeBox m-1' id={'dB' + (len += 1)} key={keyGen()} >
                  {myList}
                  <button className='btn-close pt-1' aria-label='Close' id={'B' + (len)} onClick={(e) => removeLocation(e.target.id)} >
                  </button>
              </div>;
          });
          setviewLoc(locked)
          setValue({ ...value, locations: loc })
      }
      const removeLocation = (e) => {
          var red = e.substring(1)
          red = red - 1
          loc.splice(red, 1);
          var len = 0
          locked = loc.map((myList) => {
              return <div className='disposeBox m-1' id={'dB' + (len += 1)} key={keyGen()} >
                  {myList}
                  <button className='btn-close pt-1' aria-label='Close' id={'B' + (len)} onClick={(e) => removeLocation(e.target.id)} >
                  </button>
              </div>;
          });
          setviewLoc(locked)
          setValue({ ...value, locations: loc })
  
      }

      const sabari = (e) => {
        console.log(e.target.value);
        var anadhan;
        setValue({ ...value, category: e.target.value });
        cat.forEach((element) => {
          console.log(element._id);
          if (element._id === e.target.value) {
            anadhan = element.sub.map((elements) => {
              return <option value={elements._id}>{elements.sub}</option>;
            });
          } else {
          }
        });
        setSubcategory(anadhan);
      };





      const dasapppan= async(e)=>{

     
          console.log(value.state)
          console.log(value.city)
          if(!value.name)return seterror("Name is missing !");
          if (value.state.length === 0) return seterror("State is missing !");
          if (value.city.length === 0) return seterror("City is missing !");
          if(value.pincode)
          {
          if(value.pincode.toString().length!=6)return seterror(" Enter valid Pin code is missing !");
              
          }
  
          if (!value.phone) {
              return seterror('Enter your phone number')
          }




        setloading(true)
        let sab = await  axios.post('/api/Admin/DeceasedAnimalBurialGroundsReg',{
            value
      }).then((res)=>{
        if(res.status==200){
          notify("Successfully added", true);
         setValue({
            name: "",
            phone: "",
            phone2: "",
            state: "",
            city: "",
            ServiceCharges: "",  
            ServicesProvided:"",
            pincode :"" ,
            locations:[] ,

         })
         setviewLoc([])

          
        }
      }).catch((err)=>{
        console.log(err)
        notify(" Something went wrong", false);
        setValue({
          name: "",
          phone: "",
          state: "",
          city: "",
          ServiceCharges: "",  
          ServicesProvided:"",
          pincode :""  ,
          locations:[] ,
     
         })
         setviewLoc([])
      })
      setloading(false)
          
      }

// function keyGen() {
//     var length = 5
//     var result = '';
//     var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//     var charactersLength = characters.length;
//     for (var i = 0; i < length; i++) {
//         result += characters.charAt(Math.floor(Math.random() *
//             charactersLength));
//     }
//     return result;
// }


     
     
  return (
    <div className="container pt-5 mt-3">
        <h1>Deceased Animal Burial Grounds</h1>
        <div className='row'>
            <div className='col'><label className="labels">Name *</label></div><div className='col'><input className="form-control" type="text"  value={value.name}
                    onChange={(e) =>
                      setValue({ ...value, name: e.target.value })
                    }></input></div>
        </div><br />
           
        <div className='row'>
            <div className='col'> <label className="labels">State *</label></div><div className='col'> 
            <select
        className="form-select"
        value={value.state}
        id="State"
       
        onChange={(e) => Cityshow(e)}
      >
 
      </select>
     </div>
        </div><br />
        <div className='row'>
            <div className='col'> <label className="labels">City *</label></div><div className='col'>
                  <select
                    className="form-select"
                    name="City"
                    value={value.city}
                    id="City"
                    onChange={(e) =>
                      setValue({ ...value, city: e.target.value })
                    }
                  >
                    <option>Select City</option>
                  </select></div>
        </div><br />
        <div className='row'>
            <div className='col'><label className="labels">PIN Code</label></div><div className='col'><input type="number" className="form-control"   value={value.pincode}
                    onChange={(e) =>
                      setValue({ ...value, pincode: e.target.value })
                    }></input></div>
        </div><br />
        <div className='row'>
            <div className='col'>  <label className="labels">Contact Number *</label></div><div className='col'>
            <input type="number"  value={value.phone} className="form-control"
                    onChange={(e) =>
                     {setValue({ ...value, phone: e.target.value })}
                    }></input></div>
        </div><br />
        <div className='row'>
            <div className='col'>  <label className="labels">Contact Number 2</label></div><div className='col'>
            <input type="number"  value={value.phone2} className="form-control"
                    onChange={(e) =>
                     { setValue({ ...value, phone2: e.target.value })}
                    }></input></div>
        </div><br />
        <div className="row">
          <div className="col">
          <label className="labels ">Locations </label>
                        <p style={{ 'fontSize': '10px' }} className="text-start my-2">You can select up to 4 preferred locations</p>

          </div>
                       <div className="col">
                       <div id='loc'>
                            {viewLoc ? viewLoc : ''}
                        </div>

                        <select onChange={(e) => locationSelection(e.target.value)} type="text" id='jobLocation' className="form-select" >
                        </select>

                       </div>


                      
                    </div>
                    <br></br>
        <div className='row'>
            <div className='col'>  <label className="labels">Services Provided</label></div><div className='col'>
              <textarea value={value.ServicesProvided} className="form-control"
                    onChange={(e) =>
                      setValue({ ...value, ServicesProvided: e.target.value })
                    }></textarea>
           </div>
        </div><br />
        <div className='row'>
            <div className='col'><label className="labels">Service Charges in Indian Rupee</label></div><div className='col'><input type="number" className="form-control"   value={value.ServiceCharges}
                    onChange={(e) =>
                      setValue({ ...value, ServiceCharges: e.target.value })
                    }></input></div>
        </div><br />
     
      
        <div className="row">
            <div className="col"><p style={{"color":'red'}}>{error?error:''}</p></div>
            <div className="col">
            {!loading &&   <button className="btn pro-spaces-button3" onClick={(e)=>dasapppan(e)}>Add</button> }
            {loading &&   <button className="btn btn-success" disabled><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button> }

            </div>
        </div>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    </div>
  )
}
