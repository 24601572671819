import React from 'react'
import { Link } from "react-router-dom";


export default function Activists() {
  return (
    <div>
        <div> <div className="container ">
    <div className="row slhishieo3jfiehfjefbewf">
    {/* <div className="d-flex justify-content-end">
  <button className="btn btn-dosabutton ps-3 pe-3 mb-3 deghievgviehcieh" data-bs-toggle="modal" data-bs-target="#jotundeejvheirstand">UNDERSTAND THIS FEATURE</button>
  </div>
  <hr /> */}
      <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

      <Link to="/activists_animal"  className="wgfiuwrgiu">

        <div className="card carrrrbus">
          
          
            <div className="padunnor"><center>ANIMAL<br>

            </br> </center></div>
          
          
        </div>
        </Link>
      </div>
      {/* {localStorage.getItem("Type")==="Consultant" || "Product"? */}
      <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

      <Link to="/activist_social"  className="wgfiuwrgiu">

        <div className="card carrrrbus">
        
         
            <div className="padunnor"><center>SOCIAL<br>
            
            </br></center></div>
          

        </div>
        </Link>
      </div>
      <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/activist_environmental"  className="wgfiuwrgiu">

  <div className="card carrrrbus">
  
   
      <div className="padunnor"><center>ENVIRONMENTAL<br>
      
      </br></center></div>
    

  </div>
  </Link>
</div>
{/* <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/DeceasedAnimalBurialGrounds"  className="wgfiuwrgiu">

  <div className="card carrrrbus">
  
   
      <div className="padunnor"><center>DECEASED ANIMAL BURIAL GROUNDS<br>
      
      </br></center></div>
    

  </div>
  </Link>
</div> */}
      
      

    </div>
 
</div></div>
    </div>
  )
}
