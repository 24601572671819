import React, { useEffect } from 'react';
import './App.css';
import { Admin } from './Pages/Admin';
import { Notifycomponent } from './Utility/notify';
import BackdropComponent from './Components/backdrop/backdrop';

function App() {
  // console.log = function () {};
  // console.warn = function () {};
  // console.error = function () {};
  useEffect(() => {
    const preventIncreaseGlobally = (event) => {
      const target = event.target;

      // Check if the event target is a number input
      if (target.tagName === 'INPUT' && target.type === 'number') {
        // Prevent the default behavior (increase or decrease) for all keys and wheel events
        if (
          (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'PageDown' || event.key === 'PageUp') || (event.deltaY >= 0 ||event.deltaY <= 0 && event.deltaMode === 0)
        ) {
          // Prevent the default behavior (increase or decrease)
          event.preventDefault();
        }
      }
    };

    document.addEventListener('keydown', preventIncreaseGlobally);

    // Explicitly set the wheel event listener to be non-passive
    document.addEventListener('wheel', preventIncreaseGlobally, { passive: false });

    // Cleanup the event listeners on component unmount
    return () => {
      document.removeEventListener('keydown', preventIncreaseGlobally);
      document.removeEventListener('wheel', preventIncreaseGlobally);
    };
  }, []);
  return (
    <>
      <Admin />
      <Notifycomponent />
      <BackdropComponent />
      <>
        {/* t & c */}
        <div
          class="modal fade "
          id="ACCEPTED_TERMS_CONDITIONS"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ zIndex: '10000' }}
        >
          <div class="modal-dialog modal-fullscreen" role="document">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h5 class="modal-title" id="exampleModalToggleLabel">Modal 1</h5> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body container">
                <center>
                  <h3>ACCEPTED TERMS & CONDITIONS </h3>
                </center>
                <div className="pt-4">CONSENT :</div>

                <div class="form-check pt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault1"
                    defaultChecked={true}
                    disabled
                  />
                  <label class="" for="flexCheckDefault">
                    I am aware and agree that I fully understand that{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> is the Organisation (based out of Mangalore,
                    Karnataka)with which I am associated as a Free-lance
                    (Affiliate) Marketing Executive and that{' '}
                    <span className="prochure">PROchure</span>{' '}
                    {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                    is only an application developed by{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <span
                      className="prochure"
                      style={{ fontSize: '16px', color: 'black' }}
                    >
                      CONNECT
                    </span>
                    , which is a unit of{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup>
                  </label>
                </div>

                <div class="form-check pt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault2"
                    defaultChecked={true}
                    disabled
                  />
                  <label class="" for="flexCheckDefault">
                    I am ready to enrol myself as an Affiliate Marketing
                    Executive at{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> to get potential interested customers to
                    subscribe on the platform called{' '}
                    <span className="prochure">PROchure</span>{' '}
                    {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                    (an app accessible to users across the globe) which is
                    primarily a digital directory of Services and Products.
                  </label>
                </div>
                <div className="pt-4">MARKETING :</div>

                <div class="form-check pt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault2"
                    defaultChecked={true}
                    disabled
                  />
                  <label class="" for="flexCheckDefault">
                    I am aware and agree that I SHALL read, understand and
                    explain the features, benefits, fee structure and membership
                    subscription details of the app to each and every customer
                    with clarity.
                  </label>
                </div>

                <div class="form-check pt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault3"
                    defaultChecked={true}
                    disabled
                  />
                  <label class="" for="flexCheckDefault">
                    I am aware and agree that I SHALL strictly follow the
                    instructions regarding how a profile should be created for a
                    customer and shall leave no scope for errors.
                  </label>
                </div>
                <div className="pt-4">PROFESSIONAL CONDUCT / ETHICS :</div>

                <div class="form-check pt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault4"
                    defaultChecked={true}
                    disabled
                  />
                  <label class="" for="flexCheckDefault">
                    I am aware and agree that I SHALL enroll subscribers on to
                    the <span className="prochure">PROchure</span>{' '}
                    {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                    platform ONLY via the www.vipar-connect.in domain / web
                    application and SHALL NOT do the same via any other online /
                    offline method.
                  </label>
                </div>

                <div class="form-check pt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault5"
                    defaultChecked={true}
                    disabled
                  />
                  <label class="" for="flexCheckDefault">
                    I am aware and agree that I SHALL NOT make any false claims
                    / false promises to the potential subscribers regarding the
                    features and benefits of using the platform or mislead them
                    with incorrect or wrong information regarding the app only
                    for the sake of getting their subscription.
                  </label>
                </div>

                <div class="form-check pt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault6"
                    defaultChecked={true}
                    disabled
                  />
                  <label class="" for="flexCheckDefault">
                    I am aware and agree that I SHALL NOT say anything to the
                    potential subscribers at my own discretion beyond what has
                    been instructed to me by{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> sand that I SHALL maintain absolute
                    professional decorum as a representative of{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> during my course of interaction with
                    prospective customers.
                  </label>
                </div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault6"
                    defaultChecked={true}
                    disabled
                  />
                  <label class="" for="flexCheckDefault">
                    I am aware and agree that I SHALL strictly abide by the
                    rules and regulations of the organisation, and all the Terms
                    and Conditions mentioned here and that I SHALL NOT indulge
                    in any activity that will directly or indirectly spoil the
                    reputation of the platform ({' '}
                    <span className="prochure">PROchure</span>{' '}
                    {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}) and the
                    organisation ({' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> ).
                  </label>
                </div>
                <div className="pt-4">REMUNERATION :</div>

                <div class="form-check pt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault6"
                    defaultChecked={true}
                    disabled
                  />
                  <label class="" for="flexCheckDefault">
                    I am aware and agree that I shall be paid the marketing
                    commission only on percentage basis (percentage that exists
                    at that time of referral / membership subscription) on each
                    and every subscription which is subject to change at the
                    discretion of{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> .
                  </label>
                </div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault6"
                    defaultChecked={true}
                    disabled
                  />
                  <label class="" for="flexCheckDefault">
                    I am aware and agree that I shall be paid commission Only on
                    the Final (in case of using a Discount Coupon) 1
                    <sup>st</sup> year Subscription Amount on each profile that
                    I get listed at <span className="prochure">PROchure</span>{' '}
                    {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                    via my Unique Referral Code.
                  </label>
                </div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault6"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault15">
                    I am aware and agree that the commission shall be settled on
                    a daily basis by 6 PM on the same day for all the
                    subscriptions done until 4 PM on that particular day and by
                    11 AM on the following day for all the subscriptions done
                    after 4 PM on that particular day.
                  </label>
                </div>

                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault6"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault6">
                    I am aware and agree that there is NO fixed daily, weekly or
                    monthly commitment of remuneration beyond the referral
                    percentage commission by{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> to me.
                  </label>
                </div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault7"
                    defaultChecked={true}
                    disabled
                  />
                  <label class="" for="flexCheckDefault">
                    I am aware and agree that I am NOT eligibile to get any
                    reimbursement on the conveyance or phone call expenses that
                    I incur as a part of my efforts to get subscriptions at{' '}
                    <span className="prochure">PROchure</span>
                    {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}.
                  </label>
                </div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault7"
                    defaultChecked={true}
                    disabled
                  />
                  <label class="" for="flexCheckDefault">
                    I am aware and agree that I SHALL be eligible for my
                    ‘Affiliate Marketing Commission’ from a subscription Only if
                    he/she/they mandatorily mention my unique ‘Referral Code’ on
                    the payment page while creating his/her/their profile at{' '}
                    <span className="prochure">PROchure</span>
                    {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}.
                  </label>
                </div>
                <div className="pt-4">FINANCIAL PROTOCOLS :</div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault7"
                    defaultChecked={true}
                    disabled
                  />
                  <label class="" for="flexCheckDefault">
                    I am aware and agree that I SHALL NOT collect money for
                    subscriptions from any individual, who wishes to create a
                    business listing / profile of theirs on the platform, either
                    in the form of cash / cheque / DD / net transfer such as
                    IMPS, NEFT, RTGS / QR code scanning or UPI payment method to
                    any external account including my own.
                  </label>
                </div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault7"
                    defaultChecked={true}
                    disabled
                  />
                  <label class="" for="flexCheckDefault">
                    I am aware and agree that the subscription fees SHALL be
                    collected ONLY via the UPI payment method on the{' '}
                    <span className="prochure">PROchure</span>{' '}
                    {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                    platform to the{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> account and NOT to any of my personal accounts
                    or to any other account of person(s) / company known to me.
                  </label>
                </div>
                <div className="pt-4">DE-REGISTRATION :</div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault7"
                    defaultChecked={true}
                    disabled
                  />
                  <label class="" for="flexCheckDefault">
                    I am aware and agree that my AME account will be
                    de-registered without any intimation or notification IF the
                    organisation detects any suspicious activity or unethical
                    practice during my course of marketing of the App.
                  </label>
                </div>
                <div className="pt-4">LEGAL BINDING :</div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault7"
                    defaultChecked={true}
                    disabled
                  />
                  <label class="" for="flexCheckDefault">
                    I am aware and agree that any lapse(s) occuring from my end
                    regarding ALL or ANY of the above mentioned mutually agreed
                    upon Terms and Conditions SHALL solely be my responsibility
                    and for which{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> SHALL NOT be held responsible or liable, both
                    legally and otherwise.
                  </label>
                </div>

                <div class="form-check pt-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault7"
                    defaultChecked={true}
                    disabled
                  />
                  <label class="" for="flexCheckDefault">
                    I am aware and agree that these Terms and the relationship
                    between myself and{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> shall be governed by the laws of Karnataka,
                    India without regard to its conflict of law provisions.{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> and I agree that all disputes shall be subject
                    to arbitration at Mangaluru, Karnataka in accordance with
                    the Arbitration and Conciliation Act, 1996. The arbitration
                    proceedings shall be conducted in the English language. That{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> and I hereby expressly and irrevocably attorn
                    to the jurisdiction of the courts of Mangaluru with respect
                    to any matter or claim, suit, action or proceeding arising
                    under or related to this Agreement. I covenant not to sue{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> in any other forum.
                  </label>
                </div>
                <div>
                  <h5 className="mt-4">BANK ACCOUNT DETAILS *</h5>

                  <div class="form-check pt-3">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault7"
                      defaultChecked={true}
                      disabled
                    />
                    <label class="" for="flexCheckDefault">
                      I am aware and agree that the bank account details I have
                      mentioned is correct and wish to have my commission
                      credited to this account.
                    </label>
                  </div>
                  <div class="form-check pt-3">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault8"
                      defaultChecked={true}
                      disabled
                    />
                    <label class="" for="flexCheckDefault8">
                      I am aware and agree that any mistake / error in the bank
                      account details entered is not the responsibility of the
                      platform.
                    </label>
                  </div>
                  <div>
                    <h5 className="mt-4">TO BE NOTED :</h5>

                    <ul className="ps-5">
                      <li className="pt-2">
                        You can View the Accepted Terms and Conditions from your
                        AME account.
                      </li>
                      <li className="pt-2">
                        {' '}
                        You can switch from an AME to a SME at any time.
                      </li>
                      <li className="pt-2">
                        {' '}
                        For switching from an AME to a SME, you must contact
                        Admin by sending a mail to vipar.official@gmail.com from
                        your registered email id with Subject Title as ‘PROchure
                        AME to SME TRANSFER’ and clearly mentioning your Unique
                        Referral Code.
                      </li>
                      <li className="pt-2">
                        {' '}
                        For any grievances, send us an email regarding the issue
                        to vipar.official@gmail.com from your registered email
                        id with Subject Title as ‘PROchure AME GRIEVANCE’ and
                        clearly mentioning your Unique Referral Code.
                      </li>
                    </ul>
                  </div>
                  <div class="form-check pt-3">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault8"
                      defaultChecked={true}
                      disabled
                    />
                    <label class="" for="flexCheckDefault8">
                      I have read, understood and agree to comply with all the
                      Terms and Conditions.
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        {/* t & c 2  */}
        <div
          class="modal fade "
          id="ACCEPTED_TERMS_CONDITIONS_SME"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ zIndex: '10000' }}
        >
          <div class="modal-dialog modal-fullscreen" role="document">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h5 class="modal-title" id="exampleModalToggleLabel">Modal 1</h5> */}
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body container">
                <center>
                  <h3>ACCEPTED TERMS & CONDITIONS </h3>
                </center>
                <div className="pt-4">CONSENT :</div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault1"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault1">
                    I am aware and agree that I fully understand that{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> is the Organisation (based out of Mangalore,
                    Karnataka) which has employed me as a Marketing Executive
                    and that <span className="prochure">PROchure</span>{' '}
                    {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                    is only an application developed by{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <span
                      className="verticals"
                      style={{ fontSize: '16px', color: 'black' }}
                    >
                      CONNECT
                    </span>{' '}
                    , which is a unit of{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup>.
                  </label>
                </div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault1"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault1">
                    I am ready to take up the fixed monthly salary job as a
                    Field Sales Marketing Executive at{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> to get potential interested customers to
                    subscribe on the platform called{' '}
                    <span className="prochure">PROchure</span>{' '}
                    {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                    (an app accessible to users across the globe) which is
                    primarily a digital directory of Services and Products.
                  </label>
                </div>
                <div className="pt-4">MARKETING :</div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault2"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault2">
                    I am aware and agree that I SHALL read, understand and
                    explain the features, benefits, fee structure and membership
                    subscription details of the app to each and every customer
                    with clarity.
                  </label>
                </div>

                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault8"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault8">
                    I am aware and agree that I SHALL strictly follow the
                    instructions regarding how a profile should be created for a
                    customer and shall leave no scope for errors.{' '}
                  </label>
                </div>
                <div className="pt-4">PROFESSIONAL CONDUCT / ETHICS :</div>

                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault3"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault3">
                    I am aware and agree that I SHALL enroll subscribers on to
                    the <span className="prochure">PROchure</span>{' '}
                    {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                    platform ONLY via the www.vipar-connect.in domain / web
                    application and SHALL NOT do the same via any other online /
                    offline method.
                  </label>
                </div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault4"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault15">
                    I am aware and agree that I SHALL NOT make any false claims
                    / false promises to the potential subscribers regarding the
                    features and benefits of using the platform or mislead them
                    with incorrect or wrong information regarding the app only
                    for the sake of getting their subscription.
                  </label>
                </div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    value=""
                    disabled
                    defaultChecked
                  />
                  <label class="">
                    I am aware and agree that I SHALL NOT say anything to the
                    potential subscribers at my own discretion beyond what has
                    been instructed to me by{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> and that I SHALL maintain absolute professional
                    decorum as a representative of{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> during my course of interaction with
                    prospective customers.
                  </label>
                </div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault4"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault4">
                    I am aware and agree that I SHALL strictly abide by the
                    rules and regulations of the organisation, and all the Terms
                    and Conditions mentioned here and that I SHALL NOT indulge
                    in any activity that will directly or indirectly spoil the
                    reputation of the platform ({' '}
                    <span className="prochure">PROchure</span>{' '}
                    {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}) and the
                    organisation ({' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> ).
                  </label>
                </div>
                <div className="pt-4">TARGETS and REMUNERATION :</div>

                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault5"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault5">
                    I am aware and agree that the Target based Salary +
                    Allowances shall be paid to me ONLY on achieving the
                    pre-requisite targets set by{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> for that current month.
                  </label>
                </div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault6"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault6">
                    I am aware and agree that my sales targets for each month is
                    subject to change every month and that I shall be notified
                    regarding the following month’s targets on the 1
                    <sup>st</sup> working day of the current month.
                  </label>
                </div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault6"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault6">
                    I am aware and agree that I SHALL achieve both daily and
                    monthly sales targets set by{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> .
                  </label>
                </div>

                {/* <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault6"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault6">
                    I am aware and agree that if I FAIL to achieve the overall
                    fixed minimum target of 500 subscriptions every month, my
                    monthly remuneration will only be as per what is mentioned
                    below :
                  </label>
                  <div className="pt-3 ">
                    <div className="row">
                      <div className="col-4"></div>
                      <div className="col-4 d-flex justify-content-center ">
                        Salary&nbsp;
                      </div>
                      <div className="col-4">Allowance Amount</div>
                      <div className="col-4 pt-3">
                        1. 50 – 99 subscriptions per month
                      </div>{' '}
                      <div className="col-4 d-flex justify-content-center align-items-center pt-3">
                        Rs 5,000/-
                      </div>{' '}
                      <div className="col-4 d-flex  align-items-center pt-3">
                        Rs 1,000/-
                      </div>{' '}
                      <div className="col-4 pt-3">
                        2. 100 – 199 subscriptions per month
                      </div>{' '}
                      <div className="col-4 d-flex justify-content-center align-items-center pt-3">
                        Rs 10,000/-
                      </div>{' '}
                      <div className="col-4 d-flex align-items-center pt-3">
                        Rs 2,000/-
                      </div>{' '}
                      <div className="col-4 pt-3">
                        3. 200 – 299 subscriptions per month
                      </div>{' '}
                      <div className="col-4 d-flex justify-content-center align-items-center pt-3">
                        Rs 20,000/-
                      </div>{' '}
                      <div className="col-4 d-flex align-items-center pt-3">
                        Rs 3,000/-
                      </div>{' '}
                      <div className="col-4 pt-3">
                        4. 300 – 399 subscriptions per month
                      </div>{' '}
                      <div className="col-4 d-flex justify-content-center align-items-center pt-3">
                        Rs 30,000/-
                      </div>{' '}
                      <div className="col-4 d-flex align-items-center pt-3">
                        Rs 4,000/-
                      </div>{' '}
                      <div className="col-4 pt-3">
                        5. 400 – 499 subscriptions per month
                      </div>{' '}
                      <div className="col-4 d-flex justify-content-center align-items-center pt-3">
                        Rs 40,000/-
                      </div>{' '}
                      <div className="col-4 d-flex align-items-center pt-3">
                        Rs 5,000/-
                      </div>
                    </div>
                  </div>
                </div> */}

                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault6"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault6">
                    I am aware and agree that the categories / sub categories
                    and daily / monthly sales targets assigned to me is at the
                    sole discretion of{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> based on my marketing capabilities /
                    performance and my chosen location for marketing.
                  </label>
                </div>

                {/* <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault6"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault6">
                    I am aware and agree that Registering as a Salaried
                    Marketing Executive in itself is the Final Appointment
                    Letter given to me by
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>
                    .{' '}
                  </label>
                </div> */}

                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault6"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault19">
                    I am aware and agree that it is ONLY via my Unique Referral
                    Code (which I have created on this registration form) that{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> analyses and tracks my daily / monthly sales
                    targets and that ONLY profiles created via my SME account
                    will be considered towards my sales targets.
                  </label>
                </div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault5"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault5">
                    I am aware and agree that the Target based Salary +
                    Allowances SHALL be paid to me ONLY on achieving the
                    pre-requisite sales targets set by{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup> for that current month.
                  </label>
                </div>

                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault5"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault5">
                    I am aware and agree that my fixed monthly salary is subject
                    to proportionate deductions, as per the discretion of{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup>, for not being able to achieve my
                    pre-requisite monthly sales targets.
                  </label>
                </div>

                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault5"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault5">
                    I am aware and agree that I SHALL NOT be eligible for any
                    remuneration / salary in a month IF I do not achieve any
                    sales in that month.
                  </label>
                </div>

                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault5"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault5">
                    I am aware and agree that the Target based Salary +
                    Allowances shall be paid to me ONLY on achieving the
                    pre-requisite targets set by{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> for that current month.
                  </label>
                </div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault6"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault19">
                    I am aware and agree that my Total Net Salary including
                    Allowances (for the current month) shall be credited (via
                    NEFT payment mode) to the account that I have provided
                    correctly to{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> only (at anytime before 6 pm) on the 1
                    <sup>st</sup> day of the next immediate month.
                  </label>
                </div>
                <div className="pt-4">FINANCIAL PROTOCOLS :</div>

                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault6"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault6 ">
                    I am aware and agree that I SHALL NOT collect money for
                    subscriptions from any individual, who wishes to create a
                    business listing / profile of theirs on the platform, either
                    in the form of cash / cheque / DD / net transfer such as
                    IMPS, NEFT, RTGS / QR code scanning or UPI payment method to
                    any external account including my own.
                  </label>
                </div>
                <div>
                  <div class="form-check pt-3">
                    <input
                      class="form-check-input affcheckbox"
                      type="checkbox"
                      id="flexCheckDefault7"
                      disabled
                      defaultChecked
                    />
                    <label class="" for="flexCheckDefault7">
                      I am aware and agree that the subscription fees SHALL be
                      collected ONLY via the UPI payment method on the{' '}
                      <span className="prochure">PROchure</span>{' '}
                      {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                      platform to the{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup>®</sup> account and NOT to any of my personal
                      accounts or to any other account of person(s) / company
                      known to me.
                    </label>
                  </div>
                </div>
                <div className="pt-4">RESIGNATION :</div>

                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault9"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault9">
                    I am aware and agree that there is a mutual notice period of
                    a minimum of Two Weeks to a maximum of One Month between
                    myself and{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> inorder to relieve myself from this job.
                  </label>
                </div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault9"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault9">
                    I am aware and agree that while resigning from the job, I
                    SHALL send an email regarding my resignation to only the
                    official email id of{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      &nbsp;Vipar
                    </span>{' '}
                    <sup>®</sup> , which is vipar.official@gmail.com from my
                    registered email id with Subject Title as ‘PROchure SME
                    RESIGNATION’ and clearly mentioning my Unique Referral Code.
                    Else my resignation SHALL NOT be acceptable.
                  </label>
                </div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault9"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault9">
                    I am aware and agree that both my Salary Certificate and
                    Experience Certificate SHALL be issued to me ONLY if I
                    complete a minimum of SIX Months of work duration with{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      &nbsp;Vipar
                    </span>{' '}
                    <sup>®</sup> as a SME.
                  </label>
                </div>

                <div className="pt-4">DE-REGISTRATION :</div>

                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault9"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault9">
                    I am aware and agree that 12 (Twelve) Consecutive working
                    days of Non-Performance (Read : “NO SALES”) in a month
                    without achieving my pre-requisite monthly sales targets
                    would immediately and automatically lead to the
                    de-registration of my SME account without any further
                    intimation or notification Unless I submit a written valid
                    reason for non-performance within a maximum duration of 24
                    hours after this 12-day period.
                  </label>
                </div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault9"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault9">
                    I am aware and agree that my SME account will be
                    de-registered without any intimation or notification IF the
                    organisation detects any suspicious activity or unethical
                    practice during my course of marketing of the App.
                  </label>
                </div>
                <div className="pt-4">LEGAL BINDING :</div>

                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault9"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault9">
                    I am aware and agree that any lapse(s) occuring from my end
                    regarding ALL or ANY of the above mentioned mutually agreed
                    upon Terms and Conditions SHALL solely be my responsibility
                    and for which{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> SHALL NOT be held responsible or liable, both
                    legally and otherwise.
                  </label>
                </div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault9"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault9">
                    I am aware and agree that these Terms and the relationship
                    between myself and{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> shall be governed by the laws of Karnataka,
                    India without regard to its conflict of law provisions.{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> and I agree that all disputes shall be subject
                    to arbitration at Mangaluru, Karnataka in accordance with
                    the Arbitration and Conciliation Act, 1996. The arbitration
                    proceedings shall be conducted in the English language. That{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> and I hereby expressly and irrevocably attorn
                    to the jurisdiction of the courts of Mangaluru with respect
                    to any matter or claim, suit, action or proceeding arising
                    under or related to this Agreement. I covenant not to sue{' '}
                    <span
                      className="vipar"
                      style={{
                        color: 'green',
                        fontSize: '20px',
                        paddingRight: '5px',
                      }}
                    >
                      Vipar
                    </span>{' '}
                    <sup>®</sup> in any other forum.
                  </label>
                </div>
                <h5 className="mt-4">BANK ACCOUNT DETAILS</h5>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault9"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault9">
                    I am aware and agree that the bank account details I have
                    mentioned is correct and wish to have my salary + allowance
                    amount credited to this account.
                  </label>
                </div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault9"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault9">
                    I am aware and agree that any mistake / error in the bank
                    account details entered is not the responsibility of the
                    platform.
                  </label>
                </div>
                <div>
                  <h5 className="mt-4">TO BE NOTED :</h5>
                  <ul className="ps-5 mt-2">
                    <li className="pt-2">
                      {' '}
                      Once you are registered as a SME, your APPOINTMENT LETTER
                      will be officially sent to you only from
                      vipar.official@gmail.com within 48 hours after your
                      registration, to your email id mentioned on the form.
                    </li>
                    <li className="pt-2">
                      {' '}
                      You can also View your Appointment Letter the Accepted
                      Terms and Conditions from your SME account.
                    </li>
                    <li className="pt-2">
                      {' '}
                      Your SALARY CERTIFICATE and EXPERIENCE CERTIFICATE will be
                      issued to you upon resignation.
                    </li>

                    <li className="pt-2">
                      {' '}
                      You can switch from a SME to an AME on completion of
                      either One Week or Multiples of One Week. And you will be
                      paid your salary for that period as a SME on pro-rata
                      basis you have achieved your average daily targets.
                    </li>
                    <li className="pt-2">
                      For switching from a SME to an AME, you must contact Admin
                      by sending a mail to vipar.official@gmail.com from your
                      registered email id with Subject Title as ‘PROchure SME to
                      AME TRANSFER’ and clearly mentioning your Unique Referral
                      Code.
                    </li>
                    <li className="pt-2">
                      For any grievances, send us an email regarding the issue
                      to vipar.official@gmail.com from your registered email id
                      with Subject Title as ‘PROchure SME GRIEVANCE’ and clearly
                      mentioning your Unique Referral Code.
                    </li>
                  </ul>
                </div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input affcheckbox"
                    type="checkbox"
                    id="flexCheckDefault9"
                    disabled
                    defaultChecked
                  />
                  <label class="" for="flexCheckDefault9">
                    I have read, understood and agree to comply with all the
                    Terms and Conditions.
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
      </>
    </>
  );
}

export default App;
