import React, { useEffect, useRef, useState } from 'react';
import { InitializeCountry } from '../../Utility/Country';
import axios from 'axios';
import { ShareThis } from './Share';
import { keyGen } from '../../Utility/helper';
import { Modal } from 'react-bootstrap';
import { notify } from '../../Utility/notify';

const domain = 'https://www.prochure.in';
// const domain = 'localhost:3000'

export const LinkForm2 = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [data, setdata] = useState({
    Cat: '',
    SubCat: '',
    ProfileDisplayName: '',
    Country: '',
    City: '',
    State: '',
    mType: '',
    uType: '',
    category: '',
    subcategory: '',
    discountCoupon: '',
    discountCouponPercentage: '',
    inviteeName: '',
    inviteFrom: '',
    profileCreationTime: '',
    freeGift: '',
    listingType: '',
    classificationName: '',
    validity: '01-01-2025',
  });

  const [cat, setCat] = useState([]);
  const [consultantCategory, setconsultantCategory] = useState([]);
  const [productCategory, setproductCategory] = useState([]);

  useEffect(() => {
    axios
      .get('/api/api/getcategory')
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          console.log();
          setconsultantCategory(
            res.data
              .map((element) => {
                if (element.Hide_status !== true) {
                  return element;
                }
              })
              .filter((element) => element != undefined)
          );
        }
      })
      .catch((err) => {
        console.log(err.response.status);
      });
  }, []);

  useEffect(() => {
    axios
      .get('/api/api/getprocategory ')
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setproductCategory(
            res.data
              .map((element) => {
                if (element.Hide_status !== true) {
                  return element;
                }
              })
              .filter((element) => element != undefined)
          );
        }
      })
      .catch((err) => {
        console.log(err.response.status);
      });
  }, []);

  const [subcategory, setSubcategory] = useState([]);
  const [category, setCategory] = useState([]);
  var sab = [];

  useEffect(() => {
    if (cat.length >= 1) {
      cat.forEach((element) => {
        sab.push(<option value={element._id}>{element.category}</option>);
      });
      setCategory(sab);
    }
  }, [cat]);

  useEffect(() => {
    if (data.uType == 'Consultant') {
      console.log(consultantCategory);
      setCat(consultantCategory);
    }
    if (data.uType == 'Product') {
      console.log(productCategory);

      setCat(productCategory);
    }
    setSubcategory([]);
    return () => {};
  }, [data.uType]);

  useEffect(() => {
    if (data.Cat) {
      if (cat.length >= 1) {
        cat.forEach((element) => {
          if (element._id == data.Cat) {
            setSubcategory(
              element.sub.map((elements) => (
                <option value={elements._id}>{elements.sub}</option>
              ))
            );
          }
        });
      }
    }
    return () => {};
  }, [data.Cat]);

  let countryref = useRef();
  let stateref = useRef();
  let cityref = useRef();

  useEffect(() => {
    InitializeCountry(countryref, stateref, cityref);
  }, []);

  const [err, seterr] = useState();
  const validation = () => {
    if (data.mType.length == 0) {
      return seterr('Please select a MEMBERSHIP TYPE');
    }
    if (data.uType.length == 0) {
      return seterr('Please select a CLASSIFICATION');
    }
    if (data.inviteeName.length == 0) {
      return seterr('Please enter a INVITEE NAME');
    }
    if (data.inviteFrom.length == 0) {
      return seterr('Please enter a INVITE FROM');
    }
    if (data.listingType.length == 0) {
      return seterr('Please select a LISTING TYPE');
    }
    if (data.classificationName.length == 0) {
      return seterr('Please select a CLASSIFICATION NAME');
    }
    seterr('');
    return true;
  };

  const [data1, setdata1] = useState('');
  const [Submit, setSubmit] = useState(false);
  const submit = () => {
    setSubmit(true);
    console.log(data);
    var pr = '';
    let tail = '';

    if (data.uType == 'Consultant') {
      if (data.mType == 'PRO') {
        pr = '/services_prime';
        tail = `MEMBERSHIP TYPE : PRIME
SUBSCRIPTION FEE (per year including GST) : ₹ 5000/-`;
      } else if (data.mType == 'BASIC') {
        pr = '/services_elite';
        tail = `MEMBERSHIP TYPE : Elite
SUBSCRIPTION FEE (per year including GST) : ₹ 50000/-`;
      } else if (data.mType == 'STATIC') {
        pr = '/services_static';
        tail = `MEMBERSHIP TYPE : BASIC
SUBSCRIPTION FEE (per year including GST) : ₹ 500/-`;
      }
    }
    if (data.uType == 'Product') {
      if (data.mType == 'PRO') {
        pr = '/products_prime';
        tail = `MEMBERSHIP TYPE : PRIME
SUBSCRIPTION FEE (per year including GST) : ₹ 5000/-`;
      } else if (data.mType == 'BASIC') {
        pr = '/products_elite';
        tail = `MEMBERSHIP TYPE : Elite
SUBSCRIPTION FEE (per year including GST) : ₹ 50000/-`;
      } else if (data.mType == 'STATIC') {
        pr = '/products_static';
        tail = `MEMBERSHIP TYPE : BASIC
SUBSCRIPTION FEE (per year including GST) : ₹ 500/-`;
      }
    }

    if (validation() === true) {
      if (
        data.Cat.length ||
        data.SubCat.length ||
        data.ProfileDisplayName.length ||
        data.Country.length ||
        data.State.length ||
        data.City.length
      ) {
        var link = encodeURI(
          `${domain}${pr}?mType=${data.mType}&&uType=${data.uType}&&category=${data.Cat}&&subcategory=${data.SubCat}&&profileName=${data.ProfileDisplayName}&&country=${data.Country}&&state=${data.State}&&city=${data.City}&&superlink=1`
        );
      } else {
        var link = encodeURI(`${domain}${pr}`);
      }
      setKey(keyGen());
      var test = `
PROchure ™ : an online brochure of Professional Services and Products.\n
${data.inviteeName.length ? `Hi ${data.inviteeName.toUpperCase()},` : 'Hi,'}
This is an INVITE REFERRAL from ${data.inviteFrom.toUpperCase()} to Create your ${
        data.listingType
      } of ${data.classificationName} at PROchure ™.\n
${tail}
${
  data.discountCouponPercentage.length
    ? `
REFERRAL DISCOUNT : ${data.discountCouponPercentage}%
`
    : ``
}${
        data.discountCoupon.length
          ? `DISCOUNT COUPON CODE : ${data.discountCoupon.toUpperCase()}
`
          : ``
      }SUBSCRIPTION VALIDITY : until ${data.validity}\n
${
  localStorage.getItem('ReferalCode')?.length
    ? `Please mention the REFERRAL CODE : ${localStorage.getItem(
        'ReferalCode'
      )}\n`
    : ''
}
VISIT ${link}\n
Create your Profile and Avail a GIFT from Vipar ®.
`;
      // create your business profile at :\n${link}\n${tail}\n${data.discountCoupon.length ? `\nUse Coupon Code ${data.discountCoupon} for a ${data.discountCouponPercentage}% discount` : ''}\n${data.inviteFrom.length ? `\nThis is send to you by ${data.inviteFrom}` : ''}\n${data.freeGift.length ? `\nCreate your profile within ${data.profileCreationTime} and get the ${data.freeGift} for FREE !` : ''}

      //////////////////////////////////////////////////

      // let test = `\nCreate your Profile at ${link}\n\nCategory : ${data.category}\nSub category : ${data.subcategory}\nPlease mention my Referral Code : ${localStorage.getItem('ReferalCode')}\n${tail}`
      console.log(test);
      setLink(test);
      seturi(link);
      handleShow();
    }
    // console.log(link);
    // copy(link)
    /////////////////////////////////////////
  };
  const [uri, seturi] = useState('');
  const [Link, setLink] = useState('');
  const copy = async (link) => {
    try {
      await navigator.clipboard.writeText(link);
      notify('Copied to clipboard !', true);
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };
  const [Key, setKey] = useState(keyGen());

  useEffect(() => {
    if (Submit) {
      validation();
    }
  }, [data]);

  return (
    <>
      <div
        className="container overflow-scroll mt-3"
        style={{ backgroundColor: 'white' }}
      >
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-danger mt-3 me-1"
            onClick={() => window.location.reload()}
          >
            Reset Form
          </button>
        </div>
        <div>
          <div>
            <label className="mt-3">MEMBERSHIP TYPE *</label>
            <select
              className="form-select mt-2"
              onChange={(e) => setdata({ ...data, mType: e.target.value })}
            >
              <option></option>
              <option value={'STATIC'}>BASIC</option>
              <option value={'PRO'}>PRIME</option>
              {/* <option>ELITE</option> */}
            </select>
          </div>
          <div>
            <label className="mt-3">CLASSIFICATION * </label>
            <select
              className="form-select mt-2"
              onChange={(e) =>
                setdata({ ...data, uType: e.target.value, Cat: '', SubCat: '' })
              }
            >
              <option></option>
              <option value={'Consultant'}>SERVICES</option>
              <option value={'Product'}>PRODUCTS</option>
            </select>
          </div>
          <div>
            <label className="mt-3">INVITEE NAME *</label>
            <input
              className="form-control "
              value={data.inviteeName.toUpperCase()}
              onChange={(e) => {
                setdata({ ...data, inviteeName: e.target.value });
              }}
            />
          </div>
          <div>
            <label className="mt-3">INVITE FROM *</label>
            <input
              className="form-control "
              value={data.inviteFrom.toUpperCase()}
              onChange={(e) => {
                setdata({ ...data, inviteFrom: e.target.value });
              }}
            />
          </div>
          <div>
            <label className="mt-3">LISTING TYPE *</label>
            <select
              className="form-select "
              onChange={(e) => {
                setdata({ ...data, listingType: e.target.value });
              }}
            >
              <option></option>
              <option>CONSULTANT PROFILE</option>
              <option>BUSINESS PROFILE</option>
            </select>
          </div>
          <div>
            <label className="mt-3">CLASSIFICATION NAME *</label>
            <select
              className="form-select "
              onChange={(e) => {
                setdata({ ...data, classificationName: e.target.value });
              }}
            >
              <option></option>
              <option>SERVICES</option>
              <option>PRODUCTS</option>
            </select>
          </div>
          <div>
            <label className="mt-3">DISCOUNT COUPON CODE</label>
            <input
              className="form-control"
              value={data.discountCoupon.toUpperCase()}
              onChange={(e) => {
                setdata({ ...data, discountCoupon: e.target.value });
              }}
            />
          </div>
          <div>
            <label className="mt-3">DISCOUNT PERCENTAGE</label>
            <input
              className="form-control "
              type="number"
              value={data.discountCouponPercentage}
              maxLength={2}
              onChange={(e) => {
                if (
                  e.target.value.toString().length > 2 ||
                  e.target.value.toString() == '0'
                ) {
                  e.preventDefault();
                  return;
                }
                if (
                  (e.target.valueAsNumber >= 0 &&
                    e.target.valueAsNumber <= 99) ||
                  e.target.value == ''
                ) {
                  setdata({
                    ...data,
                    discountCouponPercentage: e.target.value,
                  });
                } else {
                  e.preventDefault();
                }
              }}
            />
          </div>
          <div>
            <label className="mt-3">SUBSCRIPTION VALIDITY *</label>
            <input className="form-control" value="01-01-2025" disabled />
          </div>

          <div className="d-none">
            <label className="mt-3">CATEGORY </label>
            <select
              className="form-select mt-2"
              ref={countryref}
              aria-label="Default select example"
              onChange={(e) => {
                setdata({
                  ...data,
                  Cat: e.target.value,
                  SubCat: '',
                  category:
                    e.nativeEvent.target[e.nativeEvent.target.selectedIndex]
                      .text,
                  subcategory: '',
                });
              }}
            >
              <option></option>
              {category}
            </select>
            <label className="mt-3">SUB CATEGORY </label>
            <select
              className="form-select mt-2"
              ref={countryref}
              aria-label="Default select example"
              onChange={(e) => {
                setdata({
                  ...data,
                  SubCat: e.target.value,
                  subcategory:
                    e.nativeEvent.target[e.nativeEvent.target.selectedIndex]
                      .text,
                });
              }}
            >
              <option></option>
              {subcategory}
            </select>
            <label className="mt-3"> PROFILE DISPLAY NAME</label>
            <input
              type="email"
              className="form-control mt-2"
              id="exampleFormControlInput1"
              onChange={(e) => {
                setdata({ ...data, ProfileDisplayName: e.target.value });
              }}
            />
            <label className="mt-3">Country </label>
            <select
              className="form-select mt-2"
              ref={countryref}
              aria-label="Default select example"
              onChange={(e) => {
                setdata({
                  ...data,
                  Country: e.target.value,
                  State: '',
                  City: '',
                });
              }}
            ></select>
            <label className="mt-3">State </label>
            <select
              className="form-select mt-2"
              ref={stateref}
              aria-label="Default select example"
              onChange={(e) => {
                setdata({ ...data, State: e.target.value, City: '' });
              }}
            ></select>
            <label className="mt-3">City </label>
            <select
              className="form-select mt-2"
              ref={cityref}
              aria-label="Default select example"
              onChange={(e) => {
                setdata({ ...data, City: e.target.value });
              }}
            ></select>

            <div className="d-none">
              <div>
                <label className="mt-3">PROFILE CREATION TIME</label>
                <input
                  className="form-control "
                  onChange={(e) => {
                    setdata({ ...data, profileCreationTime: e.target.value });
                  }}
                />
              </div>
              <div>
                <label className="mt-3">FREE GIFT</label>
                <input
                  className="form-control "
                  onChange={(e) => {
                    setdata({ ...data, freeGift: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>

          <p className="text-danger">{err}</p>
          <div className="d-flex justify-content-center align-items-center">
            <button
              className="btn pro-spaces-button3 my-3 "
              onClick={(e) => {
                submit();
              }}
            >
              GENERATE LINK
            </button>
          </div>
          <br />
        </div>
      </div>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="" style={{ overflow: 'hidden' }}>
            <ul className="hpop">
              {/* <li>
                                <a target="_blank" className=" " style={{ border: 'none', textAlign: 'start',textDecoration:"none" ,color:"black"}} href={`https://${uri}`}  >Open the link</a>

                            </li> */}
              <li>
                <button
                  className=""
                  type="button"
                  style={{
                    border: 'none',
                    textAlign: 'start',
                    background: 'inherit',
                  }}
                  onClick={() => copy(uri)}
                >
                  COPY LINK to clipboard to send via sms / email / messenger
                </button>
              </li>
              <li style={{ justifyContent: '' }}>
                Share link on whatsapp{' '}
                {Link?.length ? <ShareThis url={Link} key={Key} /> : <></>}
              </li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
