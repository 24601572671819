import React from 'react';

export const Pausepage = () => {
  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '75vh' }}
      >
        <div className="px-3 ">
          <div>Your DCE account has been temporarily closed. </div>{' '}
          <div>
            Please Contact Admin at vipar.marketing.hr@gmail.com with your
            Unique Referal Code.
          </div>
        </div>
      </div>
      ;
    </>
  );
};
