import React from 'react'
import { Link } from "react-router-dom";

export default function Utilities() {
  return (
<div>
<div className="container ">
  <div className="row slhishieo3jfiehfjefbewf">

    <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

    <Link to="/gas_agencies_govt"  className="wgfiuwrgiu">

      <div className="card carrrrbus">
        
        
          <div className="padunnor"><center>Gas Agencies (Govt)<br>

          </br> </center></div>
        
        
      </div>
      </Link>
    </div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/gas_agencies_private" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Gas Agencies (Private)<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/newspaper_agencies" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Newspaper Agencies<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/milk_vendors" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Milk Vendors<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/vegetable_markets" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Vegetable Markets<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/meat_markets" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Meat&nbsp;Markets<br>

</br></center></div>


</div>
</Link>
</div>
<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/waste_disposal" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Waste&nbsp;Disposal<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/fuel_stations" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center> Fuel&nbsp;Stations&nbsp;<span style={{"fontSize":"12px"}}>(&nbsp;Open&nbsp;24&nbsp;X&nbsp;7&nbsp;)</span> <br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/bus_stand" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Bus Stand<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/metro_stations" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Metro Stations<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/railway_stations" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Railway Stations<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/airports" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Airports<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/post_offices" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Post&nbsp;Offices<br>

</br></center></div>


</div>
</Link>
</div>
<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/regional_transport_offices" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Regional&nbsp;Transport&nbsp;Offices<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/banks" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Banks<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/telecom" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Telecom<br>

</br></center></div>


</div>
</Link>
</div>

<div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">

<Link to="/media" className="wgfiuwrgiu">

<div className="card carrrrbus">


<div className="padunnor"><center>Media<br>

</br></center></div>


</div>
</Link>
</div>



  </div>

</div>
</div>





  )
}
