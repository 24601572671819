import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment';

const formatDate = (dateString) => {
  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'days').startOf('day');
  const formattedDate = moment(dateString); // No need to specify the format for ISO 8601

  if (formattedDate.isSame(today, 'day')) {
    return 'Today';
  } else if (formattedDate.isSame(yesterday, 'day')) {
    return 'Yesterday';
  } else {
    return formattedDate.format('MMMM D, YYYY');
  }
};

export const Admin_notification = () => {
  const [data, setdata] = useState();
  const [regdata, setregdata] = useState();

  const [status, setstatus] = useState();
  var count = 0;
  useEffect(() => {
    axios
      .post('/api/AffiliatedAccount/notification')
      .then((res) => {
        console.log(res.data);
        {
          res.data?.map((elem) => {
            console.log(elem);
            if (elem.Status === false) {
              count++;
            }
          });
        }
        console.log(count);
        setstatus(count);
        console.log(res.data);
        setdata(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .post('/api/AffiliatedAccount/registernotification')
      .then((res) => {
        console.log('ASD');
        console.log(res.data);
        {
          res.data.map((elem) => {
            if (elem.Status === false) {
              count++;
            }
            console.log(elem);
          });
        }
        setstatus(count);
        setregdata(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="d-flex justify-content-center pb-2">
        <div className="card ">
          <div className="card-body">Notifications : {status}</div>
        </div>
      </div>

      <div className="dr">
        {data?.map((elem) => {
          const formattedDate = formatDate(elem.Date);

          return (
            <div className="card" style={{ width: '100%' }} key={elem.id}>
              <div className="card-body">
                <div className="fw-bold">
                  {elem.AmeandSme === 'false'
                    ? 'New AME Account Created'
                    : 'New SME Account Created'}
                </div>
                <div className="">Name: {elem.Name}</div>
                <div className="">Referral Code: {elem.Refferal_Code}</div>
                <div className="d-flex justify-content-end text-secondary">
                  {formattedDate}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div>
        {regdata?.map((elem) => {
          const formattedDate = formatDate(elem.Date);

          console.log(formattedDate);
          console.log(elem);

          if (elem.SubscriptionType === 'STATIC') {
            return (
              <>
                <div class="card" style={{ width: '100%' }}>
                  <div class="card-body">
                    <div className="fw-bold">
                      New Profile has been Created by {elem.AdName}(
                      {elem.Referral_Code})
                    </div>
                    <div className="">Name : {elem.Name}</div>
                    <div className="">
                      Subscription Type : {elem.SubscriptionType}
                    </div>
                    <div className="">Account Type : {elem.Accounttype}</div>
                    <div className="d-flex justify-content-end text-secondary">
                      {formattedDate}
                    </div>
                  </div>
                </div>
              </>
            );
          } else if (elem.SubscriptionType === 'ELIT') {
            return (
              <>
                <div class="card" style={{ width: '100%' }}>
                  <div class="card-body">
                    <div className="fw-bold">
                      New Profile has been Created by {elem.AdName}(
                      {elem.Referral_Code})
                    </div>
                    <div className="">Name : {elem.Name}</div>
                    <div className="">
                      Subscription Type : {elem.SubscriptionType}
                    </div>
                    <div className="">Account Type : {elem.Accounttype}</div>
                    <div className="d-flex justify-content-end text-secondary">
                      {formattedDate}
                    </div>
                  </div>
                </div>
              </>
            );
          } else if (elem.SubscriptionType === 'PRIME') {
            return (
              <>
                <div class="card" style={{ width: '100%' }}>
                  <div class="card-body">
                    <div className="fw-bold">
                      New Profile has been Created by {elem.AdName}(
                      {elem.Referral_Code})
                    </div>
                    <div className="">Name : {elem.Name}</div>
                    <div className="">
                      Subscription Type : {elem.SubscriptionType}
                    </div>
                    <div className="">Account Type : {elem.Accounttype}</div>
                    <div className="d-flex justify-content-end text-secondary">
                      {formattedDate}
                    </div>
                  </div>
                </div>
              </>
            );
          } else if (elem.SubscriptionType === 'passive') {
            return (
              <>
                <div class="card" style={{ width: '100%' }}>
                  <div class="card-body">
                    <div className="fw-bold">
                      New Profile has been Created by {elem.AdName}(
                      {elem.Referral_Code})
                    </div>
                    <div className="">Name : {elem.Name}</div>
                    <div className="">
                      Subscription Type : {elem.SubscriptionType}
                    </div>
                    <div className="">Account Type : {elem.Accounttype}</div>
                    <div className="d-flex justify-content-end text-secondary">
                      {formattedDate}
                    </div>
                  </div>
                </div>
              </>
            );
          }
        })}
      </div>
    </>
  );
};
