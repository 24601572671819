import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const TempProfilesV3 = () => {
  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [modalShow, setModalShow] = React.useState(false);
  const [first1, setfirst1] = useState([]);
  const [Loading, setLoading] = useState(false);
  const show = () => setModalShow(true);
  const hide = () => setModalShow(false);
  const [Current, setCurrent] = useState('');
  useEffect(() => {
    fetch();
  }, []);
  let navigate = useNavigate();

  function fetch() {
    axios
      .get('/api/admin/tempProfilesv3')
      .then((res) => {
        console.log(res.data);
        console.log('Coupon');
        setfirst1(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  const onShow = () => {
    // setLoading(true)
  };

  const onHide = () => {
    setLoading(false);
    hide();
    setError('');
  };
  const approve = () => {
    setLoading(true);
    setError('');

    console.log(Current);
    let data = {
      user: Current._id,
      email: Current.email,
      pay: Current.payResult[0],
      Type: Current.Type,
    };

    axios
      .post('/api/Admin/SpacialApprovev3', { data })
      .then((res) => {
        console.log(res);
        setLoading(false);
        hide();
        setError('');
        fetch();
      })
      .catch((err) => {
        console.log(err.response.data);
        setLoading(false);
        setError(err.response.data);
      });
  };
  const [Error, setError] = useState('');
  const [modalShow1, setModalShow1] = React.useState(false);
  const show1 = () => {
    console.log(typeof Current._id);
    console.log(Current.Type);
    setpreCat({
      ...preCat,
      category: Current.category,
      subcategory: Current.subcategory,
      id: Current._id,
    });
    setValue({ ...value, id: Current._id });
    if (Current.Type === 'Consultant_Unique_v3') {
      conselcat();
    } else {
      productcat();
    }
    setModalShow1(true);
  };
  const conselcat = () => {
    setCat([]);
    axios
      .get('/api/api/getcategory')
      .then((res) => {
        if (res.status === 200) {
          setCat(res.data);
          console.log(res.data);
        }
      })
      .catch((err) => {
        if (err) {
          navigate('/');
        }
      });
  };
  const productcat = () => {
    setCat([]);
    axios
      .get('/api/api/getprocategory')
      .then((res) => {
        if (res.status === 200) {
          setCat(res.data);
          console.log(res.data);
        }
      })
      .catch((err) => {
        if (err) {
          navigate('/');
        }
      });
  };

  const hide1 = () => {
    setModalShow1(false);
    setValue({
      category: '',
      subcategory: '',
      formType: '',
      Brand: '',
      id: '',
    });
  };
  const [preCat, setpreCat] = React.useState({
    category: '',
    subcategory: '',
    id: '',
  });
  const [value, setValue] = useState({
    category: '',
    subcategory: '',
    formType: '',
    Brand: '',
    id: preCat.id,
  });
  const [cat, setCat] = useState([]);
  const [category, setCategory] = useState(false);
  const [subcategory, setSubcategory] = useState(false);
  const [brand, setBrand] = useState([]);

  const sabari = (e) => {
    var anadhan;
    setValue({ ...value, category: e });
    cat.forEach((element) => {
      if (element._id === e) {
        anadhan = element.sub.map((elements) => {
          if (elements?.Static_status) {
            return (
              <option
                disabled
                formtype={elements.formType}
                value={elements._id}
              >
                {elements.sub}
              </option>
            );
          } else {
            return (
              <option formtype={elements.formType} value={elements._id}>
                {elements.sub}
              </option>
            );
          }
        });
      } else {
      }
    });
    setSubcategory(anadhan);
  };
  useEffect(() => {
    cat.find((elem) => {
      if (elem._id === value.category) {
        elem?.sub.find((element) => {
          setValue({ ...value, formType: element.formType });
          if (element._id == value.subcategory) {
            setBrand(element.brand);
          }
        });
      }
    });
  }, [value.subcategory]);
  useEffect(() => {
    console.log(value);
  }, [value]);
  var sab = [];
  useEffect(() => {
    if (cat.length >= 1) {
      cat.forEach((element) => {
        sab?.push(<option value={element._id}>{element.category}</option>);
      });
      setCategory(sab);
    } else {
    }
  }, [cat]);

  ///////////////update category///////
  const updateCat = () => {
    axios
      .post('/api/Admin/UpdateCatInV3', { value })
      .then((res) => {
        if (res.status == 200) {
          notify('Category Updated', true);
          hide1();
          fetch();
        }
      })
      .catch((err) => {
        notify('Something went wrong', false);
      });
  };

  return (
    <div className="p-2 border bg-light">
      <div className="bg-secondary text-light text-center p-3">
        <h1 className="text-uppercase ">INVITED PRODUCT RETAILERS</h1>
      </div>
      <div className="container p-0">
        <div className="RollContainer p-0">
          <table className="table table-striped table-hover table-bordered ">
            <thead className="pro-sticky-head">
              <tr className="tableHead">
                <th scope="col">No</th>

                <th scope="col">Name</th>
                <th scope="col">Profile Calcification</th>
                <th scope="col">Membership Type</th>
                <th scope="col" colspan="2">
                  Action
                </th>
              </tr>
            </thead>
            <tbody
              className="tableBodyPro"
              style={{ borderTopColor: '#55374a' }}
            >
              {first1
                ? first1.map((element, index) => {
                    return (
                      <tr className="tableBody pro-overFlow-Y-scroll">
                        <th scope="row " className="text-center">
                          {index + 1}
                        </th>
                        <th scope="row">{element.pname}</th>
                        <th scope="row">
                          {element.Type == 'Consultant_Unique_v3'
                            ? 'Service provider'
                            : element.Type == 'Product_Unique_v3'
                            ? 'Product retailer'
                            : 'Utility'}
                        </th>
                        <th scope="row">
                          {element?.payResult[0]?.Notes.AccountType == 'Active'
                            ? 'PRIME'
                            : element?.payResult[0]?.Notes.AccountType ==
                              'Activev2'
                            ? 'ELITE'
                            : 'BASIC'}
                        </th>
                        <th>
                          {element.AdminApproveAccount ? (
                            <button
                              className="btn btn-info"
                              onClick={(e) => {
                                show1();
                                setCurrent(element);
                              }}
                              disabled
                            >
                              Update category
                            </button>
                          ) : (
                            <button
                              className="btn btn-info"
                              onClick={(e) => {
                                show1();
                                setCurrent(element);
                              }}
                            >
                              Update category
                            </button>
                          )}
                        </th>
                        <th>
                          {element.AdminApproveAccount ? (
                            <button
                              className="btn btn-success"
                              onClick={() => {
                                show();
                                setCurrent(element);
                              }}
                            >
                              Action
                            </button>
                          ) : (
                            <button
                              className="btn btn-success"
                              onClick={() => {
                                show();
                                setCurrent(element);
                              }}
                              disabled
                            >
                              Action
                            </button>
                          )}
                        </th>
                      </tr>
                    );
                  })
                : ''}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onShow={(e) => onShow()}
        onHide={(e) => onHide()}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>Are you sure ? Approve {Current.pname}</>
          <br />
          <Button
            className="float-end"
            disabled={Loading}
            onClick={(e) => approve()}
          >
            Accept
          </Button>
          <br />
          {Loading ? (
            <>
              <br />
              Login id and password will be {Current.email.toString()}{' '}
            </>
          ) : (
            <></>
          )}
          <p className="text-danger">{Error}</p>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="primary">Save changes</Button>
        </Modal.Footer> */}
      </Modal>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow1}
        onShow={(e) => show1()}
        onHide={(e) => hide1()}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <h6 className="mt-1">
              Select your appropriate Category and Sub Category
            </h6>
            <div className="col-md-12">
              <label className="labels">Category *</label>
              <div className="col-md-12">
                <label className="labels mt-1 mb-1">
                  <b>Category:- {preCat.category}</b>
                </label>
              </div>
              <select
                className="form-select"
                value={value.category}
                onChange={(e) => sabari(e.target.value)}
              >
                <option value="" disabled selected></option>
                {category ? category : ''}
              </select>
            </div>
            <div className="col-md-12">
              <label className="labels">Sub Category *</label>
              <div className="col-md-12">
                <label className="labels mt-1 mb-1">
                  <b>Sub Category:- {preCat.subcategory}</b>
                </label>
              </div>
              <select
                id={'subcatananda83389'}
                className="form-select"
                value={value.subcategory}
                onChange={(e) =>
                  setValue({ ...value, subcategory: e.target.value })
                }
              >
                <option></option> {subcategory ? subcategory : ''}
              </select>
              {/* {value.subcategory} */}
            </div>
            {brand.length ? (
              <>
                <div className="col-md-12">
                  <label className="labels">Brand *</label>
                  <select
                    value={value.Brand}
                    className="form-select"
                    onChange={(e) =>
                      setValue({ ...value, Brand: e.target.value })
                    }
                  >
                    <option></option>
                    {brand?.map((elem) => {
                      return <option value={elem._id}>{elem.brand}</option>;
                    })}
                  </select>
                </div>
              </>
            ) : (
              <></>
            )}
          </>

          <p className="text-danger">{Error}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={(e) => updateCat()}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default TempProfilesV3;
