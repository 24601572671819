import React, { useEffect, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { AiOutlineApi } from 'react-icons/ai';
import { BiMessageAltCheck } from 'react-icons/bi';
import { HiViewGridAdd } from 'react-icons/hi';
import { MdHome } from 'react-icons/md';
import { VscThreeBars } from 'react-icons/vsc';
import { Link } from 'react-router-dom';

export const ChHomepage = () => {
  const [collapse, setcollapse] = useState(false);
  const [type, setType] = useState();
  var [value,valid,valuefield] = ["value","valid","valuefield"]
  useEffect(() => {
  console.log(valid)
  }, [valid])
  
  return <>COUNTRY HEAD</>;
};
