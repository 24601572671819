import { Link } from "react-router-dom";

export default function ManageAdmin() {


    return <div style={{ height: '100%', overflow: 'hidden' }} className=' p-2 border bg-light'>
        <div>
            <div className='bg-secondary text-light text-center p-3' > <h1 className='text-capital '>MANAGE AFFILIATE USERS</h1></div>
        </div>
        <div className="border row gx-2 p-2 my-2">
            <div className="col-12 col-md-6"><Link to='/create_admin' ><button className="btn btn-success py-3 w-100 text-light">Create</button></Link></div>
            <div className="col-12 col-md-6"><Link to='/sub_admin_list' ><button className="btn btn-secondary py-3 w-100 text-light " >Manage</button></Link></div>
        </div>

        <div>
        </div>
    </div>
}   