import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { DataGrid } from '@mui/x-data-grid';
import { Jobsectionselectbox, Vaccanciesaddmore } from './Jobopportunities';
import { TestEditor } from '../TestEditor';
import { notify } from '../../Utility/notify';
import { keyGen } from '../../Utility/helper';
export const Tableverticals = () => {
  const [opperins, setopperins] = useState({
    JobTitle: '',
    JobDescription: '',
    Qualfication: '',
    Skills: '',
    Skills_NT: '',
    OtherAttributes: '',
    Message: '',
    Experience: '',
    // WorkJoblocation: '',
    Vacancies: [],
    // JoiningDate: '',
    JobSection: '',
    Salary: '',
    JobSubSection: '',
    verticals: '',
    Status: false,
  });

  const [opperinsto, setopperinsto] = useState(false);

  const [err, seterr] = useState();

  const submit = (e) => {
    e.preventDefault();
    handleClose();
    axios
      .post('/jobroute/jobconnectupdate', opperins)
      .then((res) => {
        notify('Updated Successfully', true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        console.log(res.data);
      })
      .catch((err) => {
        notify('Something went wrong', false);

        console.log(err);
      });
  };

  useEffect(() => {
    console.log(opperins);
  }, [opperins]);

  const Deleteee = (id, ind) => {
    console.log(id);
    console.log(ind);
    axios
      .post('/jobroute/jobconnectdelete', { id, ind })
      .then((res) => {
        console.log(res.data);
        let temp = [...state];
        console.log(temp);
        temp.splice(ind, 1);
        setState([...temp]);
        // document.getElementById('btnclose').click();
        notify('Successfully Deleted', true);

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        notify('Something Went Wrong', false);
      });
  };
  const updatee = (params) => {
    console.log('hiii');
    console.log(params);
    axios
      .post('/jobroute/jobswitchupdate', params)
      .then((res) => {
        console.log('zccx');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [state, setState] = useState([]);

  useEffect(() => {
    axios
      .post('/jobroute/jobconnectview')
      .then((res) => {
        console.log(res.data);
        const newstate = res.data.map((elem, index) => {
          console.log(elem);
          return {
            ...elem,
            id: index + 1,
          };
        });
        console.log(newstate);
        setState(newstate);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [index, setIndex] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [Submit2, setSubmit2] = useState(false);

  const [addmore, setAddmore] = useState(['add']);

  const addmorecontroller = (index, value, state) => {
    if (state) {
      let temp = [...opperins.Vacancies];
      temp[index] = value;
      setopperins({ ...opperins, Vacancies: temp });
    } else {
      let temp = [...opperins.Vacancies];
      let tempkey = [...addmore];
      temp.splice(index, 1);
      tempkey.splice(index, 1);
      setopperins({ ...opperins, Vacancies: temp });
      setAddmore(tempkey);
    }
  };

  const columns = [
    // { field: 'id', headerName: 'Sl No', width: 70 },
    { field: 'id', headerName: 'Sl No', width: 70 },

    { field: 'JobSection', headerName: 'Job Category', width: 180 },
    { field: 'JobSubSection', headerName: 'Job Title', width: 180 },

    {
      field: 'Details',
      headerName: 'Details',
      sortable: false,
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <button
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#detailsModal"
          onClick={(e) => {
            console.log(params.row);
            setopperins(params.row);
          }}
        >
          Details
        </button>
      ),
    },
    {
      field: 'switch',
      headerName: 'Hide/Show',
      sortable: false,
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <span style={{ border: 'none' }} class="form-check form-switch">
          <input
            defaultChecked={params.row.Status}
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            value={params.row.Status}
            onChange={(e) => {
              // console.log(params.row);
              // let temp = { ...params.row };
              // temp.Status = !temp.Status;
              // console.log(temp.Status);
              params.row.Status = !params.row.Status;
              console.log(params.row);
              updatee(params.row);
            }}
          />
        </span>
      ),
    },
    {
      field: 'Edit',
      headerName: 'Edit',
      sortable: false,
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <button
          className="btn btn-success"
          onClick={(e) => {
            console.log(params.row);
            setopperins(params.row);
            // setIndex(ind);
            handleShow();
          }}
        >
          Edit
        </button>
      ),
    },
    {
      field: 'Delete',
      headerName: 'Delete',
      sortable: false,
      width: 90,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <button
          className="btn btn-danger"
          data-bs-toggle="modal"
          data-bs-target={'#exampleModal' + (params.row.id - 1)}
          onClick={(e) => {
            console.log(typeof params.row.id);
          }}
        >
          Delete
        </button>
      ),
    },
  ];
  const tableStyles = {
    backgroundColor: '#f0f0f0',
  };
  return (
    <>
      <div
        style={{
          height: 400,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <DataGrid
          rows={state}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          style={tableStyles}
          className="mt-5 pt-3 sswewe"
        />
      </div>

      {/* details modal */}
      <div
        class="modal fade"
        id="detailsModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content ">
            <div class="modal-header">
              <h5 class="modal-title px-3" id="exampleModalLabel">
                Details
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              {opperins?.JobSection?.length ? (
                <div className="pt-3">
                  Job Category :{' '}
                  <span className="text-uppercase">{opperins?.JobSection}</span>
                </div>
              ) : (
                <></>
              )}
              {opperins?.JobSubSection?.trim()?.length ? (
                <div className="pt-3">
                  Job Title :
                  <span className="text-uppercase">
                    {opperins?.JobSubSection}
                  </span>
                </div>
              ) : (
                <></>
              )}
              {opperins?.JobTitle?.length ? (
                <div className="pt-3">
                  Job Highlights :
                  <div
                    className="pt-2"
                    dangerouslySetInnerHTML={{
                      __html: opperins?.JobTitle,
                    }}
                  />
                </div>
              ) : (
                <></>
              )}

              {opperins?.Skills?.length ? (
                <div className="pt-3">
                  Key Skills (Technical) :
                  <div
                    className="pt-2"
                    dangerouslySetInnerHTML={{
                      __html: opperins?.Skills,
                    }}
                  />
                </div>
              ) : (
                <></>
              )}

              <div className="">
                {opperins?.Vacancies[0]?.EmploymentType ||
                opperins?.Vacancies[0]?.WorkExperience ||
                opperins?.Vacancies[0]?.JobRole ||
                opperins?.Vacancies[0]?.SalaryOffered ||
                opperins?.Vacancies[0]?.WorkMode ||
                opperins?.Vacancies[0]?.Gender ||
                opperins?.Vacancies[0]?.JoiningDateEnd ||
                opperins?.Vacancies[0]?.JoiningDateStart ||
                opperins?.Vacancies[0]?.Location ||
                opperins?.Vacancies[0]?.Number ? (
                  <div className="pt-3">
                    <span className="boldfont">Vacancies</span>
                    {opperins?.Vacancies.map((elem, index) => {
                      return (
                        <>
                          {elem?.WorkExperience?.length ? (
                            <>
                              <div className="pt-3">
                                Work Experience: {elem?.WorkExperience}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {elem?.EmploymentType?.length ? (
                            <>
                              <div className="pt-3">
                                Employment Type : {elem?.EmploymentType}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {elem?.WorkMode?.length ? (
                            <>
                              <div className="pt-3">
                                Work Mode : {elem?.WorkMode}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {elem?.Gender?.length ? (
                            <>
                              <div className="pt-3">
                                Gender : {elem?.Gender}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {elem?.Number?.length ? (
                            <>
                              <div className="pt-3">
                                Number : {elem?.Number}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {elem?.JobRole?.length ? (
                            <>
                              <div className="pt-3">
                                Job Role : {elem?.JobRole}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {elem?.SalaryOffered?.length ? (
                            <>
                              <div className="pt-3">
                                Salary Offered : {elem?.SalaryOffered}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          {elem?.Location?.length ? (
                            <>
                              <div className="pt-3">
                                Job Location : {elem?.Location}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          {elem?.JoiningDateStart?.length ? (
                            <>
                              <div className="pt-3">
                                When to Join ? : {elem?.JoiningDateStart}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          {/* {elem?.JoiningDateEnd?.length ? (
                            <>
                              <div className="pt-3">
                                Joining Date End : {elem?.JoiningDateEnd}
                              </div>
                            </>
                          ) : (
                            <></>
                          )} */}

                          <hr />
                        </>
                      );
                    })}
                  </div>
                ) : (
                  <></>
                )}

                {opperins?.JobDescription?.length ? (
                  <div className="pt-3">
                    Detailed Job Profile / Description :
                    <div
                      className="pt-2"
                      dangerouslySetInnerHTML={{
                        __html: opperins?.JobDescription,
                      }}
                    ></div>
                  </div>
                ) : (
                  <></>
                )}

                {opperins?.Skills_NT?.trim()?.length ? (
                  <div className="pt-3">
                    Skills (Non-Technical) :
                    <div
                      className="pt-2"
                      dangerouslySetInnerHTML={{
                        __html: opperins?.Skills_NT,
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}

                {opperins?.OtherAttributes?.length ? (
                  <div className="pt-3">
                    Other Attributes : {opperins?.OtherAttributes}
                  </div>
                ) : (
                  <></>
                )}
                {opperins?.Experience?.length ? (
                  <div className="pt-3">
                    Work Experience : {opperins?.Experience}
                  </div>
                ) : (
                  <></>
                )}
                {opperins?.Qualfication?.length ? (
                  <div className="pt-3">
                    Least Educational Qualification : {opperins?.Qualfication}
                  </div>
                ) : (
                  <></>
                )}

                {opperins?.Message?.length ? (
                  <div className="pt-3" style={{ whiteSpace: 'pre-line' }}>
                    Message : {opperins?.Message}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* details modal */}
      {/* edit modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <form
          onSubmit={(e) => submit(e)}
          novalidate
          className={Submit2 ? 'was-validated' : ''}
        >
          <Modal.Body>
            <form>
              {' '}
              <div className="py-2">
                <label htmlhtmlFor="inputsubject">Job Category </label>

                <input
                  type="text"
                  className="form-control mt-1 text-uppercase"
                  value={opperins?.JobSection}
                  onChange={(e) => {
                    setopperins({
                      ...opperins,
                      JobSection: e.target.value.toUpperCase(),
                    });
                  }}
                />
                <div class="invalid-feedback">PLEASE SELECT Job Category</div>
              </div>
              <div className="py-2">
                <label htmlhtmlFor="inputsubject">Job Title </label>

                <input
                  type="text"
                  className="form-control mt-1 text-uppercase"
                  value={opperins?.JobSubSection}
                  onChange={(e) => {
                    setopperins({
                      ...opperins,
                      JobSubSection: e.target.value.toUpperCase(),
                    });
                  }}
                />
                <div class="invalid-feedback">PLEASE SELECT Job Title</div>
              </div>
              <div className="py-2">
                <label htmlhtmlFor="inputname">Job Highlights </label>
                <TestEditor
                  value={opperins?.JobTitle}
                  onChange={(e) => {
                    setopperins({ ...opperins, JobTitle: e });
                  }}
                />

                {/* <div class="invalid-feedback">PLEASE ENTER Job Role</div> */}
              </div>{' '}
              <div className="py-2">
                <label htmlhtmlFor="inputname">Key Skills (Technical)</label>

                {/* <textarea
                  rows={3}
                  className="form-control mt-1"
                  id="name"
                  name="name"
                  value={opperins?.Skills}
                  required
                  onChange={(e) => {
                    setopperins({ ...opperins, Skills: e.target.value });
                  }}
                /> */}
                <TestEditor
                  value={opperins?.Skills}
                  onChange={(e) => {
                    setopperins({ ...opperins, Skills: e });
                  }}
                />
                <div class="invalid-feedback">PLEASE ENTER Skills</div>
              </div>
              <div className="py-2">
                <label htmlhtmlFor="inputname boldfont">
                  <b>Vacancies</b>{' '}
                </label>

                {addmore.map((elem, index) => {
                  return (
                    <>
                      <Vaccanciesaddmore
                        key={elem}
                        onChange={addmorecontroller}
                        value={opperins}
                        index={index}
                      />
                      <hr />
                    </>
                  );
                })}
                <div className="py-2">
                  <button
                    className="btn btn-outline-primary "
                    type="button"
                    onClick={(e) => setAddmore([...addmore, keyGen()])}
                  >
                    ADD MORE
                  </button>
                </div>
                <div class="invalid-feedback">PLEASE ENTER Vacancies</div>
              </div>
              <div className="py-2">
                <label htmlhtmlFor="inputname">
                  Detailed Job Profile / Description{' '}
                </label>
                <TestEditor
                  value={opperins?.JobDescription}
                  onChange={(e) => {
                    setopperins({ ...opperins, JobDescription: e });
                  }}
                />

                <div class="invalid-feedback">
                  PLEASE ENTER Detailed Job Profile / Description
                </div>
              </div>
              <div className="py-2">
                <label htmlhtmlFor="inputname">Skills (Non-Technical)</label>

                {/* <textarea
                  rows={3}
                  className="form-control mt-1"
                  id="name"
                  name="name"
                  value={opperins?.Skills}
                  required
                  onChange={(e) => {
                    setopperins({ ...opperins, Skills: e.target.value });
                  }}
                /> */}
                <TestEditor
                  value={opperins?.Skills_NT}
                  onChange={(e) => {
                    setopperins({ ...opperins, Skills_NT: e });
                  }}
                />
                <div class="invalid-feedback">PLEASE ENTER Skills</div>
              </div>
              <div className="py-2">
                <label htmlhtmlFor="inputname">Other Attributes</label>

                <input
                  type="text"
                  className="form-control mt-1"
                  id="name"
                  name="name"
                  value={opperins?.OtherAttributes}
                  onChange={(e) => {
                    setopperins({
                      ...opperins,
                      OtherAttributes: e.target.value,
                    });
                  }}
                />
                <div class="invalid-feedback">
                  PLEASE ENTER Other Attributes
                </div>
              </div>
              <div className="py-2">
                <label htmlhtmlFor="inputname">
                  Least Educational Qualification
                </label>

                <input
                  type="text"
                  className="form-control mt-1"
                  id="name"
                  name="name"
                  value={opperins?.Qualfication}
                  onChange={(e) => {
                    setopperins({ ...opperins, Qualfication: e.target.value });
                  }}
                />
                <div class="invalid-feedback">
                  PLEASE ENTER Least Educational Qualification
                </div>
              </div>
              <div className="py-2">
                <label htmlhtmlFor="inputname">Message</label>

                <textarea
                  type="text"
                  className="form-control mt-1"
                  id="name"
                  name="name"
                  rows={5}
                  value={opperins?.Message}
                  onChange={(e) => {
                    setopperins({ ...opperins, Message: e.target.value });
                  }}
                />
                <div class="invalid-feedback">PLEASE ENTER Message</div>
              </div>
              {/* <div className="pb-4">
                <label htmlhtmlFor="inputname">Job Location </label>

                <input
                  type="text"
                  className="form-control mt-1"
                  id="name"
                  name="name"
                  value={opperins?.WorkJoblocation}
                  required
                  onChange={(e) => {
                    setopperins({
                      ...opperins,
                      WorkJoblocation: e.target.value,
                    });
                  }}
                />
                <div class="invalid-feedback">PLEASE ENTER Job Location</div>
              </div> */}
              {/* <div className="pb-4">
                <label htmlhtmlFor="inputname">Joining Date *</label>

                <input
                  type="date"
                  className="form-control mt-1"
                  id="name"
                  required
                  name="name"
                  value={
                    opperins?.JoiningDate
                      ? opperins.JoiningDate.substring(0, 10)
                      : ''
                  }
                  onChange={(e) => {
                    setopperins({ ...opperins, JoiningDate: e.target.value });
                  }}
                />
                <div class="invalid-feedback">PLEASE SELECT Joining Date</div>
              </div> */}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="primary"
              onClick={(e) => {
                setSubmit2(true);
              }}
            >
              Update
            </Button>{' '}
          </Modal.Footer>
        </form>
      </Modal>
      {/* edit modal */}

      {state?.map((elem, index) => {
        return (
          <>
            <div
              class="modal fade"
              id={'exampleModal' + index}
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      id={'btnclose'}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body text-danger">
                    Are you sure you want to delete ?
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={(e) => {
                        Deleteee(elem._id, elem.id);
                        // setopperins(elem.ind);
                        // setIndex(elem.ind);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};
