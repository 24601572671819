import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { notify } from '../../../Utility/notify';
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import moment from "moment";
import reportWebVitals from "reportWebVitals";

export const ViparFriendprofileview = () => {
  const [state, setState] = useState([]);
  const [value, setValue] = useState(false);
  const [data, setdata] = useState([]);
  const [check, setcheck] = useState();

  useEffect(() => {
    console.log(check);
  }, [check]);

  const handleChange = (e, valuee) => {
    setValue(!value); // Update the state with the new value
    submit(valuee); // Call the submit function with the new value
  };

  const submit = (e) => {
    console.log(e);
    var id = e._id;

    console.log(id);
    console.log(value);
    axios
      .post("/api/freelisting/checkstatus", { id, value })
      .then((req) => {
        console.log(req.data);
        setcheck(req.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get("/api/freelisting/getstatus")
      .then((req) => {
        console.log(req.data);
        setdata(req.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const Deleteee = (Id) => {
    console.log(Id);
    var Id = Id;
    axios
      .delete(`/api/freelisting/freelistingdelete/${Id}`)
      .then((req) => {
        console.log(req);
        notify("Deleted", true);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

const [remarks, setremark] = useState({
  Remark:""
})

  const remark = (id) => {
    console.log(id);
    var id = id;
    axios.post("/api/freelisting/remarks", { id,remarks }).then((req)=>{
      console.log(req.data)
      if(req.status == 200){
        notify("Remarked successfully",true)
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }

    }).catch((Err)=>{
      console.log(Err)
    });
  };


   const [remarkupdates, setremarkupdate] = useState({
    Remark:"",
   })

  const remarkgets = (id)=>{
    var id = id
    axios.post("/api/freelisting/remarkget",{id}).then((req) =>{
      console.log(req.data)
      setremarkupdate(req.data)
    
    }).catch((err)=>{
      console.log(err)
      
    })
  }
  

  const closeee = () =>{
    window.location.reload();

  }



  const remarkupdate = (id) =>{
    var id = id
    console.log(id)
    axios.post("/api/freelisting/remarkupdate",{id,remarkupdates}).then((req)=>{
      console.log(req.data)
      if(req.status == 200){
        notify("Remark updated",true)
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }).catch((Err)=>{
      console.log(Err)
      notify("Something Went Wrong",false)
    })
  }




  const remarkdelete=(id)=>{
    var id = id
    console.log(id)
    axios.post("api/freelisting/remarkdelete",{id}).then((req)=>{
      console.log("req")
      if(req.status==200){
        notify("Successfully Deleted",true)

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }).catch((err)=>{
      console.log(err)
      notify("something went wrong",false)
    })
  }

  const columns = [
    { field: "id", headerName: "Sl No", width: 90 },
    {
      field: "checkStatus",
      headerName: "V / Status",
      width: 90,
      renderCell: (params) => {
        console.log(params);
        return (
          <input
            class="form-check-input"
            type="checkbox"
            checked={
              params.row?._id == check?._id && check?.checkstatus == true
            }
            onChange={(e) => {
              handleChange(e, params.row);
            }}
          />
        );
      },
    },
    {
      field: "PCreatedby",
      headerName: "Profile Created by",
      width: 150,
    },
    {
      field: "Created_by",
      headerName: "Name of the Profile Creator",
      width: 150,
    },
    {
      field: "date",
      headerName: "Created on",
      width: 120,
    },
    {
      field: "Classification",
      headerName: "Classification",
      width: 120,
    },
    {
      field: "CatandSubcat",
      headerName: "Category",
      width: 220,
    },
    {
      field: "Category",
      headerName: "Types of Services / Products",
      width: 200,
    },
    {
      field: "Shop_name",
      headerName: "CONSULTANT / SERVICE PROVIDER / BUSINESS / SHOP",
      width: 350,
    },
    {
      field: "Area",
      headerName: "Secondary Locality / Area Name",
      width: 150,
    },

    {
      field: "country",
      headerName: "Country",
      width: 100,
    },
    {
      field: "state",
      headerName: "State",
      width: 110,
    },
    {
      field: "city",
      headerName: "City",
      width: 110,
    },
    {
      field: "Pincode",
      headerName: "Pincode",
      width: 100,
    },
    {
      field: "Contact",
      headerName: "Ph no",
      width: 150,
    },
    {
      field: "Profile",
      headerName: "Profile",
      width: 100,
      renderCell: (params) => {
        return (
          <Link to={`/prochure_free_listing_profile?k=${params.row._id}`}>
            <button className="btn pro-spaces-button3">Profile</button>
          </Link>
        );
      },
    },
    {
      field: "Remark",
      headerName: "Remark",
      width: 300,
      renderCell: (params) => {
        return (
          <>
          <button
            className="btn pro-spaces-button3"
            data-bs-toggle="modal"
            data-bs-target={"#remark" + (params.row.id - 1)}
            onClick={(e) => {
              remarkgets(params.row._id);
            }}
          >
            Remarks
          </button>
          &nbsp;
          <button
            className="btn btn-danger"
            data-bs-toggle="modal"
            data-bs-target={"#remarkdelete" + (params.row.id - 1)}
           
          >
            Remarks Delete
          </button>
          </>
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      sortable: false,
      width: 100,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <>
          <SendRemark />
          &nbsp;
          <button
            className="btn btn-danger"
            data-bs-toggle="modal"
            data-bs-target={"#deletemodal" + (params.row.id - 1)}
            onClick={(e) => {
              console.log(params.row.id);
            }}
          >
            Delete
          </button>
        </>
      ),
    },
  ];

  useEffect(() => {
    axios
      .get("/api/freelisting/freelistingview")
      .then((res) => {
        console.log(res.data);
        const newstate = res.data.map((elem, index) => {
          console.log(elem);
          return {
            ...elem,
            id: index + 1,
            date: moment(elem.Created_on).format("DD-MM-YYYY"),
          };
        });
        console.log(newstate);
        setState(newstate);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    console.log(state);
  }, [state]);

  return (
    <>
      <h4 className="my-3 text-center">
        Profiles Created by PROchure Free Listing{" "}
      </h4>
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={state}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        />
      </Box>
      {/* delete */}
      {state?.map((elem, index) => {
        console.log(elem);
        return (
          <>
            <div
              class="modal fade"
              id={"deletemodal" + index}
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <button
                      type="button"
                      class="btn-close"
                      id={"btnclose"}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body text-danger">
                    Are you sure you want to delete ?
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                      onClick={(e) => {
                        Deleteee(elem._id);
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}

      {/* remar */}

      {state?.map((elem, index) => {
        console.log(elem)
      
          return (
            remarkupdates?.length==0 ? (  <>
              <div
                class="modal fade"
                id={"remark" + index}
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header"></div>
                    <div class="modal-body  ">
                      <div className="pt-2">
                        <label className="">Remark </label>
                        
                        <textarea className="form-control"  rows={3} onChange={(e)=>setremark({...remarks,Remark:e.target.value})}/>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        // data-bs-dismiss="modal"
                        onClick={closeee}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-bs-dismiss="modal"
                        onClick={(e) => {
                          remark(elem._id);
                        }}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>):(
              <>
                <div
                class="modal fade"
                id={"remark" + index}
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header"></div>
                    <div class="modal-body  ">
                      <div className="pt-2">
                        <label className="">Remark </label>
                        
                        <textarea className="form-control" onChange={(e)=>setremarkupdate({...remarkupdates,Remark:e.target.value})} value={remarkupdates?.Remark}  rows={3} />
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                        onClick={closeee}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-bs-dismiss="modal"
                        onClick={(e) => {
                          remarkupdate(elem._id);
                        }}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              </>
            )
          
          );
     
       
      })}


      {/* remarkdelete */}
{state.map((elem,index)=>{
  return(
    <>
     <div
        class="modal fade"
        id={"remarkdelete" + index}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                id={"btnclose"}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body text-danger">
              Are you sure you want to delete ?
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={(e) => {
                  remarkdelete(elem._id)
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
})}
      
{/* delete modal */}
      <div
        class="modal fade"
        id={"deletemodal"}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                id={"btnclose"}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body text-danger">
              Are you sure you want to delete ?
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={(e) => {
                  // Deleteee(elem._id, elem.id, elem.Login_id);
                  // setopperins(elem.ind);
                  // setIndex(elem.ind);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* delete */}
      {/* img view */}
      <div
        class="modal fade"
        id={"imagemodal"}
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                id={"btnclose"}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body text-danger">
              <div className="py-3">
                <label className="labels">Image 1</label>
                img 1
              </div>
              <div className="py-3">
                <label className="labels">Image 2</label>
                img 2
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* img view */}
    </>
  );
};

//send REMARK
const SendRemark = ({}) => {
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [state, setState] = useState({
    // pname: remarkValue?.pname || '',
    // classification: remarkValue?.classification || '',
    // category: remarkValue?.category || '',
    // subcategory: remarkValue?.subcategory || '',
    // mType: remarkValue?.mType || '',
    // verifyAdmin: remarkValue?.verifyAdmin || false,
    // Login_id: log_id,
    // id: remarkValue?._id,
  });

  // const send = () => {
  //   axios
  //     .post('/api/Admin/remarkinsert', state)
  //     .then((res) => {
  //       handleClose1();
  //       notify('Remark Sent Successfully', true);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       notify('Something Went Wrong', false);
  //     });
  // };

  // const update = () => {
  //   axios
  //     .put(`/api/Admin/remarkupdate`, state)
  //     .then((res) => {
  //       handleClose1();
  //       notify('Remark Sent Successfully', true);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       notify('Something Went Wrong', false);
  //     });
  // };

  return (
    <>
      {/* {remarkValue?.verifyAdmin === true ? (
        <button className="btn btn-success" onClick={handleShow1}>
          DONE
        </button>
      ) : (
        <button
          className={
            remarkValue?.pname?.length > 0 ||
            remarkValue?.classification?.length > 0 ||
            remarkValue?.category?.length > 0 ||
            remarkValue?.subcategory?.length > 0 ||
            remarkValue?.mType?.length > 0
              ? 'btn btn-danger'
              : 'btn btn-outline-secondary'
          }
          onClick={handleShow1}
        >
          {remarkValue?.pname?.length > 0 ||
          remarkValue?.classification?.length > 0 ||
          remarkValue?.category?.length > 0 ||
          remarkValue?.subcategory?.length > 0 ||
          remarkValue?.mType?.length > 0 ? (
            'DOUBT'
          ) : (
            <span className="invisible">DOUBT</span>
          )}
        </button>
      )} */}
      {/* <button onClick={handleShow1}>Remark</button> */}

      <Modal show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton>Remarks</Modal.Header>
        <Modal.Body>
          <div className="pt-2">
            <label className="labels">Remark</label>
            <input
              value={state.pname}
              className="form-control"
              // onChange={(e) => formValue(e, 'pname')}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn pro-spaces-button3"
            //  onClick={send}
          >
            SUBMIT
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
//send REMARK
