import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import React, { useEffect, useState } from "react";
import './jobPostForm.css'
import { BsArrowLeft } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import Axios from "axios";
import { useNavigate } from 'react-router-dom'

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Insider} from "./insider"

export const Marketing_feedback = (props) => {

    useEffect(() => {
        // props.Auth()
        }, [])


    const [Reset, setReset] = useState(true)
    const [Keyval, setKeyval] = useState(23)
    // const [Display, setDisplay] = useState(<Insider Reset={Reset} setReset={setReset} />)
    useEffect(() => {
        console.log(Reset)
        setKeyval(Keyval + 1)
    }, [Reset])


    return (
    <Insider key={Keyval} Reset={Reset} setReset={setReset} />
    )

    
}
