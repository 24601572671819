import React, { useState, useEffect, useRef } from 'react';
import { AdminType } from '../../../Utility/helper';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validator } from 'validator';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Form } from 'react-bootstrap';

import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { print_state, print_city, state_arr } from '../../../Utility/stateCity';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IoChevronUpSharp, IoInformationCircleOutline } from 'react-icons/io5';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Timer } from '../../../Utility/timer';

import { StateCity } from '../../ManageAdmin/StateCity';
import { Imageupload } from '../../ManageAdmin/Imageupload';
import { Viparheader } from '../../../Pages/HomePage/Viparheader';
import { IoIosCloseCircleOutline } from 'react-icons/io';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '300',
    },
  },
};

export const RegisterFSE = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let navigate = useNavigate();
  const [Stateload, setStateload] = useState(true);
  useEffect(() => {
    if (Stateload) print_state('State');
    setStateload(false);
  }, []);
  const [value, setValue] = useState({
    AdminType: 'AffiliatedAccount',
    Name: '',
    Email: '',
    Gender: '',
    Age: '',
    Nationality: '',
    Contact: '',
    Password: '',
    ConformPassword: '',
    showPassword: false,
    showConformPassword: false,
    Referral_Code: '',
    Active_status: true,
    Activev2_status: true,
    Activev3_status: true,
    Passive_status: true,
    Service_provide_category_All: true,
    Product_retailer_category_All: true,
    country: '',
    state: '',
    city: '',
    Commission_percentage: '',
    Bank_account_type: '',
    loginID: '',
    Gender: '',
    Age: '',
    Nationality: '',
    Address: '',
    Pincode: '',
    id: '',
    Work_Experience: '',
    Highest_Educational_Qualification: '',
    Ready_to_Join_on_anyday_after: '',
    ED_Pursuing_Education: '',
    ED_College: '',
    ED_University: '',

    ED_City: '',
    ED_Period_of_Internship_Start: '',
    ED_Period_of_Internship_End: '',

    Studentornot: 'NoStudent',
    FMName: '',
    FMRelationship: '',
    FMContact: '',
    FMAddress: '',
    FMPincode: '',
    BloodGroup: '',
  });

  const [btn, setbtn] = useState(false);

  const [imgi, setimgi] = useState('');
  const [adar, setadar] = useState([]);

  var btnchange = useRef();

  const [Edit, setEdit] = useState('');
  useEffect(() => {
    axios
      .get('/api/AffiliatedAccount/getAffiliatedAccountDetails')
      .then((res) => {
        console.log('XCdasasd');
        if (res.status == 200) {
          console.log(res.data);
          var data = res.data[0];
          if (res.data.length > 0) {
            props.Auth1();
            setEdit(res.data);
            setValue({
              ...value,
              Name: data.Name,
              Email: data.Account[0]?.Email,
              Gender: data.Gender,
              Age: data.Age,
              Nationality: data.Nationality,
              Contact: data.Account[0]?.Contact,
              Referral_Code: data.Referral_Code,

              state: data.state,
              city: data.city,
              country: data.country,
              Account_holder_name: data.Account_holder_name,
              Account_number: data.Account_number,
              Ifsc_code: data.Ifsc_code,
              Bank_name: data.Bank_name,
              Branch_name: data.Branch_name,
              country: data.country,
              Bank_account_type: data?.Bank_account_type,
              loginID: data.Account[0]?._id,
              Gender: data.Gender,
              Age: data.Age,
              Nationality: data.Nationality,
              Address: data.Address,
              Pincode: data.Pincode,
              id: data._id,
              Work_Experience: data.Work_Experience,
              Highest_Educational_Qualification:
                data.Highest_Educational_Qualification,
              Ready_to_Join_on_anyday_after: data.Ready_to_Join_on_anyday_after,
              ED_Pursuing_Education: data.ED_Pursuing_Education,
              ED_College: data.ED_College,
              ED_City: data.ED_City,
              ED_Period_of_Internship_Start: data.ED_Period_of_Internship_Start,
              ED_Period_of_Internship_End: data.ED_Period_of_Internship_End,
              ED_University: data.ED_University,

              Studentornot: data.Studentornot,
              FMName: data.FMName,
              FMRelationship: data.FMRelationship,
              FMContact: data.FMContact,
              FMAddress: data.FMAddress,
              FMPincode: data.FMPincode,
              BloodGroup: data.BloodGroup,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // var validator=validator

  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (sab == 'red') {
      toast.danger(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const [valueee, setvalueee] = useState({
    Fmstate: '',
    Fmcity: '',
    Fmcountry: '',
  });
  function Cityshow(e) {
    var index = document.getElementById(e.target.id).selectedIndex;
    print_city('City', index);
    setValue({ ...value, state: e.target.value });
  }

  function Cityshow(e) {
    var index = document.getElementById(e.target.id).selectedIndex;
    print_city('City', index);
    setvalueee({ ...valueee, Fmstate: e.target.value });
  }

  // const [Cat, setCat] = useState([])
  //       function reload()
  //       {
  //         axios
  //         .get("/api/api//getcategory")
  //         .then((res) => {
  //           if (res.status === 200) {
  //             console.log("workd");
  //             console.log(res.data)
  //             setCat(res.data);
  //           }
  //         })
  //         .catch((err) => {
  //           // console.log(err.response.status);
  //         });
  //       }
  // const [Cat1, setCat1] = useState([])

  //       function reload1()
  //       {
  //         axios
  //         .get("/api/api/getprocategory")
  //         .then((res) => {
  //           if (res.status === 200) {
  //             console.log("workd");
  //             console.log(res.data)
  //             setCat1(res.data);
  //           }
  //         })
  //         .catch((err) => {
  //           // console.log(err.response.status);
  //         });
  //       }

  //       useEffect(() => {
  //         reload()
  //         reload1()

  //       }, [])

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  console.log('dadasdsaasdsdasddssd');
  function validator1(e) {
    console.log("czxcxzsadsadsd")
    e.preventDefault();

    setSubmit2(true);
    const errors = {};
    // if(!value.Name)return setError("Name is missing !");
    // if (!value.Email) {
    //   errors.email = 'Email required !'
    // return setError(errors)
    // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.Email)) {
    //   errors.email = 'Invalid email address !'
    // return setError(errors)
    // }

    // if (!value.Contact) {
    //     return setError('Enter phone number !')
    // }
    // if (value.Contact.toString().length != 10) {
    //     return setError('Enter a valid mobile number !')
    // }
    // if(!value.Commission_percentage)return setError("Discount percentage is missing !");

    // if (value.Studentornot == 'NoStudent') {
    //   if (!value.Highest_Educational_Qualification) {
    //     return setError('PLEASE ENTER Highest Educational Qualification');
    //   }
    //   if (!value.Work_Experience) {
    //     return setError('PLEASE ENTER Work_Experience');
    //   }
    //   if (!value.Ready_to_Join_on_anyday_after) {
    //     return setError('PLEASE ENTER Ready to Join on anyday after');
    //   }
    // }
    // if (value.Studentornot == 'Student') {
    //   console.log('I am in');

    //   if (!value.Address) {
    //     return setError('PLEASE ENTER Address');
    //   }
    //   if (!value.Pincode) {
    //     return setError('PLEASE ENTER Pincode');
    //   }
    //   if (!value.ED_Pursuing_Education) {
    //     return setError('PLEASE ENTER Pursuing Education');
    //   }
    //   if (!value.ED_College) {
    //     return setError('PLEASE ENTER College');
    //   }
    //   if (!value.ED_City) {
    //     return setError('PLEASE ENTER City');
    //   }
    //   if (!value.ED_Period_of_Internship_Start) {
    //     return setError('PLEASE ENTER Period of Internship ');
    //   }
    //   if (!value.ED_Period_of_Internship_End) {
    //     return setError('PLEASE ENTER Period of Internship');
    //   }

    //   setError('');
    // } else {
    //   console.log('working elspart q');
    // }
    // if (!value.Address) {
    //   return setError('');
    // }
    // if (!value.Pincode) {
    //   return setError('');
    // }

    if (!Edit.length > 0) {
      console.log(imgi);
      if (!imgi) {
        return setError('PLEASE Upload your PASSPORT size Photo');
      }
      if (!value.Studentornot == 'Student') {
        if (!value.Work_Experience) return setError('3');
        if (!value.Highest_Educational_Qualification) return setError('4');
        if (!value.Ready_to_Join_on_anyday_after) return setError('5');
      }
      if (!value.Password) return setError('');

      //   if (value.Password.toString().length <8) {
      //     return setError('Password must contain 8 characters !')
      // }
      setError('');
      if (value.Password.toString() !== value.ConformPassword.toString()) {
        return setError("'Password' and 'Confirm Password' must be matching !");
      }
    }

    setError('');
    // &&TermsAndCondition.Terms4
    if (Edit.length > 0) {
      console.log('<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>1');
      handleShow11();
    } else {
      if (value.Age <= 17) {
        return setError(
          'Sorry....you are too young ! Age must be 18 years and above.'
        );
      }

      go();
    }
  }

  const [params, setparams] = useSearchParams();
  var refferal = params.get('k');

  useEffect(() => {
    console.log(refferal);
  }, [refferal]);

  function go() {
    console.log(imgi);
    console.log(adar);
    console.log('meeeeeeee');
    var name = value.Name;
    // if (ButtonDisabled1) {
    //   return setError('Enter referral code ');
    // }
    setError('');
    setLoading(true);
    // console.log(value)
    // return
    var Affiliated = true;
    console.log(imgi);
    var formdata = new FormData();
    formdata.append(
      'data',
      JSON.stringify({ refferal, value, valueee, Affiliated })
    );
    var imag = new File([imgi], 'avatar.png', { type: 'image/png' });
    console.log(imag);
    formdata.append('file', imag);

    {
      adar?.map((elem) => {
        console.log(elem);
        formdata.append('file', elem);
      });
    }
    

   

    axios
      .post('/api/fse/fseregistartion', formdata)
      .then((res) => {
        if (res.status === 200) {
          notify('Successfully created', true);
          setValue({
            ...value,
            AdminType: 'AffiliatedAccount',
            Name: '',
            Email: '',
            Contact: '',
            Password: '',
            Pin: '',
            Active_status: true,
            Activev2_status: true,
            Activev3_status: true,
            Passive_status: true,
            Service_provide_category_All: true,
            Product_retailer_category_All: true,
            state: '',
            city: '',
            Commission_percentage: '',
            showConformPassword: false,
            Referral_Code: '',
            ConformPassword: '',
            Account_holder_name: '',
            Account_number: '',
            Ifsc_code: '',
            Bank_name: '',
            Branch_name: '',
            country: '',
            Bank_account_type: '',
            Gender: '',
            Age: '',
            Nationality: '',

            Address: '',
            Pincode: '',
            Work_Experience: '',
            Highest_Educational_Qualification: '',
            Ready_to_Join_on_anyday_after: '',
            ED_Pursuing_Education: '',
            ED_College: '',
            ED_City: '',
            ED_Period_of_Internship_Start: '',
            ED_Period_of_Internship_End: '',

            Studentornot: '',
            FMName: '',
            FMRelationship: '',
            FMContact: '',
            FMAddress: '',
            FMPincode: '',
            BloodGroup: '',
          });
          setService_provide_category([]);
          setProduct_retailer_category([]);
          setLoading(false);
          setError('');
          //navigate(`/congratulations?name=${name}`);
          navigate('/');
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          notify('Wrong security pin', false);
        } else if (err.response.status === 402 || err.response.status === 409) {
          notify('Email already exist', false);
        } else if (err.response.status === 403) {
          notify('Name already exist', false);
        } else if (err.response.status === 406) {
          notify('This Code already exists', false);
        } else if (err.response.status === 407) {
          notify('This Code already exists', false);
        } else if (err.response.status === 408) {
          notify('Enter referral code', false);
        } else if (err.response.status == 400) {
          notify('Email already exist', false);
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        } else {
          notify('Something went wrong', false);
        }

        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const [Service_provide_category, setService_provide_category] =
    React.useState([]);

  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setService_provide_category(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const [Product_retailer_category, setProduct_retailer_category] =
    React.useState([]);
  const handleChange2 = (event) => {
    const {
      target: { value },
    } = event;
    setProduct_retailer_category(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  useEffect(() => {
    if (value.Service_provide_category_All) {
      setService_provide_category([]);
    }
    if (value.Product_retailer_category_All) {
      setProduct_retailer_category([]);
    }
  }, [value.Service_provide_category_All, value.Product_retailer_category_All]);

  const [ButtonDisabled, setButtonDisabled] = useState(false);
  const [ButtonDisabled1, setButtonDisabled1] = useState(true);
  const checkReferral_Code = (e) => {
    if (!Edit?.length > 0) {
      setButtonDisabled(true);
    }
    var Referral_Code = e;
    axios
      .post('/api/auth/checkReferral_Code', { Referral_Code })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length == 0) {
            notify('This referral code is available for you', true);

            setButtonDisabled1(false);

            setError('');
          } else {
            notify('This Code already exists', false);
            setError('This Code already exists');
          }
        }
      })
      .catch((err) => {
        notify('Something went wrong', false);
        setError('');
      });
  };

  const fun = (index) => {
    let temp = [...adar];
    temp.splice(index, 1);
    setadar([...temp]);
  };

  const [Submit2, setSubmit2] = useState(false);
  const [Error2, setError2] = useState('');
  function Update(e) {
    setError2('');
    var formdata = new FormData();
    formdata.append('data', JSON.stringify({ value, OTP }));
    if (imgi) {
      var imag = new File([imgi], 'avatar.png', { type: 'image/png' });

      formdata.append('file', imag);
    }

    axios
      .post('/api/AffiliatedAccount/Update_Account', formdata)
      .then((res) => {
        if (res.status == 200) {
          notify('Updated', true);
          console.log(res.data);
          handleClose11();
          setError2('');
          setOTP('');
          end();
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 410) {
          return setError2('Enter valid OTP ');
        }
        notify('Something went wrong !', false);
      });
  }
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const [TermsAndCondition, setTermsAndCondition] = useState({
    Terms1: false,
    Terms2: false,
    Terms3: false,
    Terms4: false,
    Terms5: false,
    Terms6: false,
    Terms7: false,
    Terms8: false,
    Terms9: false,
    Terms12: false,
    Terms13: false,
    Terms14: false,
    Terms15: false,
    Terms16: false,
    Terms17: false,
    Terms18: false,
    Terms19: false,
    Terms20: false,
    Terms21: false,
    Terms22: false,
    Terms23: false,
    Terms24: false,
  });

  useEffect(() => {
    // console.log(TermsAndCondition)
    // console.log("ButtonDisabled :",ButtonDisabled)
    // console.log("ButtonDisabled1 :",ButtonDisabled1)
    console.log('<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>2');
    console.log(Edit.length);
    if (Edit.length > 0) {
      console.log(Edit.length);
    } else {
      console.log('<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>2');
      //
      if (
        TermsAndCondition.Terms1 &&
        TermsAndCondition.Terms2 &&
        TermsAndCondition.Terms3 &&
        TermsAndCondition.Terms4 &&
        TermsAndCondition.Terms5 &&
        TermsAndCondition.Terms6 &&
        TermsAndCondition.Terms7 &&
        TermsAndCondition.Terms8 &&
        TermsAndCondition.Terms9 &&
        TermsAndCondition.Terms13 &&
        TermsAndCondition.Terms14 &&
        TermsAndCondition.Terms15 &&
        TermsAndCondition.Terms16 &&
        TermsAndCondition.Terms17 &&
        TermsAndCondition.Terms18 &&
        TermsAndCondition.Terms19 &&
        TermsAndCondition.Terms20 &&
        TermsAndCondition.Terms21 &&
        TermsAndCondition.Terms22 &&
        TermsAndCondition.Terms23 &&
        TermsAndCondition.Terms24 &&
        TermsAndCondition.Terms12
      ) {
        if (!ButtonDisabled1) {
          setButtonDisabled(false);
          console.log('hai');
        }
      } else {
        return setError(' ');
      }
    }
    setError('');
  }, [Edit, TermsAndCondition, ButtonDisabled, ButtonDisabled1]);

  const [show11, setShow11] = useState(false);

  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => {
    setShow11(true);
    //
    GenerateOTP();
  };
  const GenerateOTP = () => {
    console.log('hai');
    axios
      .post('/api/AffiliatedAccount/GenerateOTPForAccountUpdate', {
        Email: value.Email,
      })
      .then((res) => {
        if (res.status == 200) {
          setpop(true);
          notify('OTP send successfully', true);
        }
      })
      .catch((err) => {
        setpop(false);
        notify('Something went wrong', false);
      });
  };

  const [OTP, setOTP] = useState('');
  const [pop, setpop] = useState(true);

  const end = () => setpop(false);

  const [NewPassword, setNewPassword] = useState({
    oldPassword: '',
    newPassword: '',
  });
  const [showpassword, setShowpassword] = useState(false);

  const handleClose111 = () => setShowpassword(false);
  const handleShow111 = () => setShowpassword(true);
  const [showPassword12, setShowPassword12] = useState(false);
  const [showPassword123, setShowPassword123] = useState(false);
  const UpdatePassword = () => {
    axios
      .post('/api/AffiliatedAccount/AccountPasswordUpdate', { NewPassword })
      .then((res) => {
        if (res.status == 200) {
          notify('Password updated successfully', true);
          setNewPassword({
            oldPassword: '',
            newPassword: '',
          });
          handleClose111();
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          notify('Old password is incorrect', false);
        } else {
          notify('Something went wrong', false);
        }
      });
  };

  const [showreferral, setShowreferral] = useState(false);

  const handleClosereferral = () => setShowreferral(false);
  const handleShowreferral = () => setShowreferral(true);

  // useEffect(() => {
  //   handleShow();
  // }, []);

  /////////////////////////////////////////////////////////////
  return (
    <>
      {!Edit?.length > 0 ? (
        <>
          {/* <ControlBar />
          <Navbar2 /> */}
        </>
      ) : (
        ''
      )}

      <div className=" rounded bg-white px-3 px-md-5 py-5">
        <Viparheader />
        {!Edit?.length > 0 ? (
          <>
            <h3 className="text-center mt-4 ">
              FIELD SALES EXECUTIVE (Commission) REGISTRATION FORM
            </h3>
            {/* <div className="text-center">
              ( Only for Commission-based Sales )
            </div> */}
            <div className="pt-4 text-center" style={{ fontSize: '20px' }}>
              This is an Agreement between You and{' '}
              <span
                className="vipar"
                style={{
                  color: 'green',
                  fontSize: '30px',
                  paddingRight: '5px',
                }}
              >
                Vipar
              </span>{' '}
              <sup>®</sup> only on Contract basis.
            </div>
          </>
        ) : (
          <h3 className=" mt-4 mb-5">Account Details</h3>
        )}
        {Edit?.length > 0 ? (
          <div className="d-flex justify-content-end">
            <button
              className="btn pro-spaces-button3"
              onClick={(e) => {
                handleShow111(e);
              }}
            >
              Change password
            </button>
          </div>
        ) : (
          ''
        )}
        <form
          onSubmit={(e) => validator1(e)}
          novalidate
          className={Submit2 ? 'was-validated' : ''}
        >
          <div class="form-check pt-3">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              defaultChecked={value.Studentornot === 'NoStudent' ? true : false}
              value={'NoStudent'}
              onChange={(e) =>
                setValue({
                  ...value,
                  Studentornot: e.target.value,
                  ED_Pursuing_Education: '',
                  ED_College: '',
                  ED_University: '',
                  ED_City: '',
                  ED_Period_of_Internship_Start: '',
                  ED_Period_of_Internship_End: '',
                })
              }
            />
            <label class="form-check-label" for="flexRadioDefault1">
              I am NOT a Student.
            </label>
          </div>
          <div class="form-check pt-3">
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              defaultChecked={value.Studentornot == 'Student' ? true : false}
              value={'Student'}
              onChange={(e) =>
                setValue({
                  ...value,
                  Studentornot: e.target.value,
                  Highest_Educational_Qualification: '',
                  Work_Experience: '',
                  Ready_to_Join_on_anyday_after: '',
                })
              }
            />
            <label class="form-check-label" for="flexRadioDefault2">
              I am a Student doing Internship.
            </label>
          </div>
          {!Edit?.length > 0 ? (
            <>
              <h5 className="mt-4 ">TERMS and CONDITIONS *</h5>
              <h6 className="pt-4">CONSENT :</h6>

              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault1">
                  <ul>
                    <li>
                      I am aware and agree that I fully understand that{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup>®</sup> is the Organisation (based out of Bengaluru,
                      Karnataka) with which I am associated as a Field Sales
                      Executive and that{' '}
                      <span className="prochure">PROchure</span>{' '}
                      {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                      is a digital web and mobile application developed by{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <span
                        className="prochure"
                        style={{ fontSize: '16px', color: 'black' }}
                      >
                        CONNECT
                      </span>
                      , which is a unit of{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup>®</sup>.
                    </li>
                  </ul>
                </label>
              </div>

              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault2">
                  <ul>
                    <li>
                      I am ready to enrol myself as a Field Sales Executive at{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup class="">®</sup> to get potential interested
                      customers to subscribe on the digital platform called{' '}
                      <span className="prochure">PROchure</span>{' '}
                      {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                      (an app accessible to users across the globe) which is
                      primarily a digital directory of Services and Products.
                    </li>
                  </ul>
                </label>
              </div>
              {value?.Studentornot == 'Student' ? (
                <div class=" pt-3">
                  <label class="form-check-label" for="flexCheckDefault9">
                    <ul>
                      <li>
                        I admit and agree that I am joining{' '}
                        <span
                          className="vipar"
                          style={{
                            color: 'green',
                            fontSize: '20px',
                            paddingRight: '5px',
                          }}
                        >
                          Vipar
                        </span>{' '}
                        <sup class="">®</sup> as a Sales Intern with the
                        knowledge and consent of my family and the concerned
                        authority of the College / Institution where I am
                        currently pursuing my education, and that IF need
                        arises, I SHALL get the required consent letter from the
                        College / Institution.
                      </li>
                    </ul>
                  </label>
                </div>
              ) : (
                <></>
              )}
              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault2">
                  <ul>
                    <li>
                      I am aware and agree that this Registration Form itself is
                      my Appointment Letter and SHALL be considered as the
                      mutually agreed upon Terms and Conditions between myself
                      and{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup class="">®</sup>.
                    </li>
                  </ul>
                </label>
              </div>
              <h6 className="pt-4">SALES PROTOCOLS :</h6>
              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault2">
                  <ul>
                    <li>
                      I am aware and agree that I SHALL introduce myself to each
                      and every customer with my unique{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup class="">®</sup> Employee ID card (issued to me by
                      the Organisation) and will co-operate with the respective
                      customer to scan the QR code on my ID card to Authenticate
                      my employment with{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup class="">®</sup>.
                    </li>
                  </ul>
                </label>
              </div>

              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault8">
                  <ul>
                    <li>
                      I am aware and agree that I SHALL read, understand and
                      explain the features, benefits, fee structure and
                      membership subscription details of the app to each and
                      every customer with clarity.
                    </li>
                  </ul>
                </label>
              </div>

              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault3">
                  <ul>
                    <li>
                      I am aware and agree that I SHALL strictly follow the
                      instructions regarding how a profile should be created for
                      a customer and shall leave no scope for errors.
                    </li>
                  </ul>
                </label>
              </div>

              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault4">
                  <ul>
                    <li>
                      I am aware and agree that I SHALL enrol subscribers on to
                      the <span className="prochure">PROchure</span> platform
                      ONLY via the web app www.prochure.in or the mobile apps of{' '}
                      <span className="prochure">PROchure</span> available on
                      Google Play Store and App Store in the name of{' '}
                      <span className="prochure">PROchure</span> and SHALL NOT
                      take subscriptions via any other online / offline method.
                    </li>
                  </ul>
                </label>
              </div>

              <h6 className="pt-4">PROFESSIONAL CONDUCT / ETHICS :</h6>

              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault5">
                  <ul>
                    <li>
                      I am aware and agree that I SHALL NOT make any false
                      claims / false promises to the potential subscribers
                      regarding the features and benefits of using the platform
                      or mislead them with incorrect or wrong information
                      regarding the app only for the sake of getting their
                      subscription.
                    </li>
                  </ul>
                </label>
              </div>

              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault6">
                  <ul>
                    <li>
                      I am aware and agree that I SHALL NOT say anything to the
                      potential subscribers at my own discretion beyond what has
                      been instructed to me by{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup class="">®</sup> and that I SHALL maintain absolute
                      professional decorum as a representative of{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup class="">®</sup> during my course of interaction with
                      prospective customers.
                    </li>
                  </ul>
                </label>
              </div>
              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault13">
                  <ul>
                    <li>
                      I am aware and agree that I SHALL strictly abide by the
                      rules and regulations of the organisation, and all the
                      Terms and Conditions mentioned here and that I SHALL NOT
                      indulge in any activity that will directly or indirectly
                      spoil the reputation of the digital platform ({' '}
                      <span className="prochure">PROchure</span>{' '}
                      {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}) and
                      the organisation ({' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup class="">®</sup> ).
                    </li>
                  </ul>
                </label>
              </div>
              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault15">
                  <ul>
                    <li>
                      I am aware and agree that I SHALL neither lose my temper
                      nor get into any kind of physical or verbal fights with a
                      customer even if it is a mistake on the part of my
                      customer and SHALL politely relieve myself from the
                      customer’s place in any such untoward scenario.
                    </li>
                  </ul>
                </label>
              </div>
              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault13">
                  <ul>
                    <li>
                      I am aware and agree that I SHALL NOT post any content on
                      any social media platform or any other digital platform
                      against <span className="prochure">PROchure</span> or
                      {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup class="">®</sup> with the intention to tarnish the
                      reputation and goodwill of both{' '}
                      <span className="prochure">PROchure</span> and
                      {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup class="">®</sup>, purely out of personal grudge and
                      without substantiating evidence.
                    </li>
                  </ul>
                </label>
              </div>
              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault15">
                  <ul>
                    <li>
                      I am aware and agree that IF I flout any or all of the
                      above mentioned clauses in this section, then I SHALL be
                      Terminated with immediate effect.
                    </li>
                  </ul>
                </label>
              </div>

              <h6 className="pt-4">FINANCIAL PROTOCOLS :</h6>
              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault6">
                  <ul>
                    <li>
                      I am aware and agree that I SHALL collect the Subscription
                      Fee, from any individual who wishes to create a business
                      listing / profile of his/her on the platform, ONLY via the
                      UPI payment method on the web and mobile apps of{' '}
                      <span className="prochure">PROchure</span> to the{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup class="">®</sup> account and NOT to any of my
                      personal accounts or to any other account of person(s) /
                      company known to me.
                    </li>
                  </ul>
                </label>
              </div>
              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault6">
                  <ul>
                    <li>
                      I am aware and agree that I SHALL NOT collect this
                      Subscription Fee, from any individual who wishes to create
                      a business listing / profile of his/her on the platform,
                      either in the form of cash / cheque / DD / net transfer
                      such as IMPS, NEFT, RTGS / QR code scanning or UPI payment
                      method to any external account including my own account.
                    </li>
                  </ul>
                </label>
              </div>

              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault15">
                  <ul>
                    <li>
                      I am aware and agree that IF I flout any or both of the
                      above mentioned clauses in this section, then I SHALL be
                      Terminated with immediate effect.
                    </li>
                  </ul>
                </label>
              </div>
              <h6 className="pt-4">REMUNERATION :</h6>

              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault15">
                  <ul>
                    <li>
                      I am aware and agree that the term ‘Remuneration’ in this
                      Agreement SHALL refer ONLY to the Commissions on
                      Percentage basis from each Subscription sale.
                    </li>
                  </ul>
                </label>
              </div>

              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault15">
                  <ul>
                    <li>
                      I am aware and agree that my Remuneration for Subscription
                      sales SHALL be as per the slabs mentioned in the table
                      below :
                    </li>
                  </ul>
                </label>
                <div className="table-responsive pt-3">
                  <table class="table table-sm align-middle table-bordered">
                    <thead>
                      <tr className="text-center">
                        <th
                          className="d-flex justify-content-center align-items-center"
                          scope="col"
                        >
                          NUMBER OF SALES
                        </th>
                        <th scope="col">
                          COMMISSION PERCENTAGE
                          <br />
                          <span style={{ fontWeight: '400' }}>
                            per subscription sale
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-center">
                        <td>1 to 1000</td>
                        <td>35%</td>
                      </tr>
                      <tr className="text-center">
                        <td>1001 to 5000</td>
                        <td>40%</td>
                      </tr>
                      <tr className="text-center">
                        <td>5001 and above</td>
                        <td>50%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault6">
                  <ul>
                    <li>
                      I am aware and agree that my Remuneration SHALL be settled
                      in full on every Sunday.
                    </li>
                  </ul>
                </label>
              </div>
              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault15">
                  <ul>
                    <li>
                      I am aware and agree that for every 1000 (ONE THOUSAND)
                      Subscription sales I achieve upto a total of 5000 (FIVE
                      THOUSAND) subscriptions, WITHOUT using a Discount coupon,
                      I SHALL be paid an INCENTIVE amount (beyond my
                      Remuneration) of Rs 25,000/- (Rupees TWENTY FIVE THOUSAND
                      only).
                    </li>
                  </ul>
                </label>
              </div>

              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault15">
                  <ul>
                    <li>
                      I am aware and agree that for every 1000 (ONE THOUSAND)
                      Subscription sales I achieve above 5000 (FIVE THOUSAND)
                      subscriptions, WITHOUT using a Discount coupon, I SHALL be
                      paid an INCENTIVE amount (beyond my Remuneration) of Rs
                      50,000/- (Rupees FIFTY THOUSAND only).
                    </li>
                  </ul>
                </label>
              </div>

              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault15">
                  <ul>
                    <li>
                      I am aware and agree that the Incentive amount SHALL be
                      paid in full within 24 hours after achieving 1000 (ONE
                      THOUSAND) Subscription sales.
                    </li>
                  </ul>
                </label>
              </div>

              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault6">
                  <ul>
                    <li>
                      I am aware and agree that I SHALL NOT be eligibile to get
                      any reimbursement on the conveyance or phone call expenses
                      that I incur as a part of my efforts to make subscription
                      sales at <span className="prochure">PROchure</span>
                      {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}.
                    </li>
                  </ul>
                </label>
              </div>
              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault14">
                  <ul>
                    <li>
                      I am aware and agree that I SHALL NOT be eligible for any
                      additional fixed daily, weekly or monthly payments beyond
                      what is mutually agreed upon in this Agreement between
                      myself and{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup class="">®</sup> .
                    </li>
                  </ul>
                </label>
              </div>
              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault6">
                  <ul>
                    <li>
                      I am aware and agree that I SHALL be eligible for my
                      Remuneration from subscription sales ONLY IF my customer
                      mandatorily mentions my Unique Referral Code on the
                      payment page while creating his/her/their profile
                      (customers) at <span className="prochure">PROchure</span>.
                    </li>
                  </ul>
                </label>
              </div>
              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault6">
                  <ul>
                    <li>
                      I am aware and agree that my Remuneration SHALL ONLY be
                      for Subscription Sales and SHALL NOT be for any failed
                      efforts / attempts to make subscription sales irrespective
                      of how much loss I have had to incur with respect to time,
                      money and effort.
                    </li>
                  </ul>
                </label>
              </div>

              <h6 className="pt-4">RESIGNATION :</h6>
              <div class=" pt-3">
                <label class="form-check-label">
                  <ul>
                    <li>
                      I am aware and agree that while resigning from the job, I
                      SHALL send an email regarding my resignation to only the
                      official marketing email id of{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup class="">®</sup>, which is
                      vipar.marketing.hr@gmail.com from my registered email id
                      with Subject Title as ‘
                      <span className="prochure">PROchure</span> FSE
                      (Commission) RESIGNATION’ and clearly mentioning my Unique
                      Referral Code. Else my resignation SHALL NOT be
                      acceptable.
                    </li>
                  </ul>
                </label>
              </div>
              <div class=" pt-3">
                <label class="form-check-label">
                  <ul>
                    <li>
                      I am aware and agree that I can resign from the
                      Organisation without any notice period.
                    </li>
                  </ul>
                </label>
              </div>
              <div class=" pt-3">
                <label class="form-check-label">
                  <ul>
                    <li>
                      I am aware and agree that both my Remuneration Certificate
                      and Experience Certificate SHALL be issued to me ONLY IF I
                      complete a minimum of 6 (SIX) Months of work duration with{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup class="">®</sup> as a FSE.
                    </li>
                  </ul>
                </label>
              </div>
              <h6 className="pt-4">RELIEVING FROM JOB :</h6>
              <div class=" pt-3">
                <label class="form-check-label">
                  <ul>
                    <li>
                      I am aware and agree that IF the Organisation finds me
                      inept for this Job or in a scenario where the Organisation
                      decides to put an end to the marketing and sales of the
                      app, then the Organisation SHALL have the authority to
                      relieve me from this Job by giving me a Notice period of 1
                      (ONE) Month in writing via mail to my registered email id.
                    </li>
                  </ul>
                </label>
              </div>
              <h6 className="pt-4">NON-DISCLOSURE :</h6>
              <div class=" pt-3">
                <label class="form-check-label">
                  <ul>
                    <li>
                      I am aware and agree that for as long as I am employed
                      with{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup class="">®</sup>, I SHALL NOT disclose any of the
                      marketing strategies or internal confidential matters of
                      the Organisation to any 3<sup>rd</sup> party, be it an
                      individual or company, and that IF I do so, I SHALL be
                      penalised for the same.
                    </li>
                  </ul>
                </label>
              </div>

              <h6 className="pt-4">LEGAL BINDING :</h6>
              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault15">
                  <ul>
                    <li>
                      I am aware and agree that I SHALL be Terminated without
                      any intimation or notification IF the Organisation detects
                      any suspicious activity or unethical practice on my part
                      during my course of sales of the app.
                    </li>
                  </ul>
                </label>
              </div>
              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault15">
                  <ul>
                    <li>
                      I am aware and agree that it SHALL be me and only me who
                      will be fully and solely responsible for my bad and
                      irresponsible behaviour with a customer and only I SHALL
                      be held liable for the same legally and NOT any other
                      employee / representative of the Organisation or the Head
                      of the Organisation.
                    </li>
                  </ul>
                </label>
              </div>
              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault15">
                  <ul>
                    <li>
                      I am aware and agree that I SHALL be Terminated IF found
                      to be involved in any kind of fraudulent activity which
                      directly or indirectly is an act against the interests of
                      either a subscriber of{' '}
                      <span className="prochure">PROchure</span> or the
                      Organisation or both.
                    </li>
                  </ul>
                </label>
              </div>
              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault6">
                  <ul>
                    <li>
                      I am aware and agree that in case of Termination of my
                      employment contract with{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup class="">®</sup> for any reason, then I SHALL NOT be
                      eligible for my pending Remuneration if any, and that I
                      SHALL be penalised and legally sued for monetary damages
                      at the discretion of{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup class="">®</sup> .
                    </li>
                  </ul>
                </label>
              </div>
              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault6">
                  <ul>
                    <li>
                      I am aware and agree that any lapse(s) occuring from my
                      end regarding ALL or ANY of the above mentioned mutually
                      agreed upon Terms and Conditions SHALL solely be my
                      responsibility and for which{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup class="">®</sup> SHALL NOT be held responsible or
                      liable, both legally and otherwise.
                    </li>
                  </ul>
                </label>
              </div>
              <div class=" pt-3">
                <label class="form-check-label" for="flexCheckDefault6">
                  <ul>
                    <li>
                      I am aware and agree that these Terms and the relationship
                      between myself and{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup class="">®</sup> SHALL be governed by the laws of
                      Karnataka, India without regard to its conflict of law
                      provisions.{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup class="">®</sup> and I agree that all disputes SHALL
                      be subject to arbitration at Bengaluru, Karnataka in
                      accordance with the Arbitration and Conciliation Act,
                      1996. The arbitration proceedings SHALL be conducted in
                      the English language. That{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup class="">®</sup> and I hereby expressly and
                      irrevocably attorn to the jurisdiction of the courts of
                      Bengaluru with respect to any matter or claim, suit,
                      action or proceeding arising under or related to this
                      Agreement. I covenant NOT to sue{' '}
                      <span
                        className="vipar"
                        style={{
                          color: 'green',
                          fontSize: '20px',
                          paddingRight: '5px',
                        }}
                      >
                        Vipar
                      </span>{' '}
                      <sup class="">®</sup> in any other forum.
                    </li>
                  </ul>
                </label>
              </div>
              <div>
                <h6 className="mt-4">TO BE NOTED :</h6>

                <ul className="ps-5 mt-2">
                  <li className="pt-2">
                    You can View the Accepted Terms and Conditions from your FSE
                    account.
                  </li>
                  <li className="pt-2">
                    You can Download, Print a copy of the Accepted Terms and
                    Conditions for future reference.
                  </li>

                  <li className="pt-2">
                    {' '}
                    For any grievances, send us an email regarding the issue to
                    vipar.marketing.hr@gmail.com from your registered email id
                    with Subject Title as ‘
                    <span className="prochure">PROchure</span> FSE (Commission)
                    GRIEVANCE’ and clearly mentioning your Unique Referral Code.
                  </li>
                </ul>

                <h6 className="mt-4">REFERRAL CODE :</h6>
                <ul className="ps-5 mt-2">
                  <li className="pt-2" style={{ lineSpacing: '0' }}>
                    During Registration, Create a Referral Code of your choice
                    which will be your FSE Identification Code at{' '}
                    <span
                      className="vipar"
                      style={{
                        fontSize: '22px',
                        color: '#99d050',
                      }}
                    >
                      {' '}
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup>
                  </li>
                  <li className="pt-2" style={{ lineSpacing: '0' }}>
                    Your Unique Referral Code is the one with which
                    <span
                      className="vipar"
                      style={{
                        fontSize: '22px',
                        color: '#99d050',
                      }}
                    >
                      {' '}
                      Vipar
                    </span>{' '}
                    <sup class="">®</sup> can identify that it is via your FSE
                    account that a Subscription is sold.
                  </li>
                  <li className="pt-2">
                    It is Mandatory for tracking your sales targets and
                    disbursing your Remuneration to you.
                  </li>
                  <li className="pt-2">
                    Once a Referral Code is created, it is permanent and cannot
                    be changed.
                  </li>

                  <li className="pt-2">
                    Can be a combination of Alphabets, Numbers and Symbols.
                  </li>
                  <li className="pt-2">
                    Minimum Character length &#8239;: 5<br />
                    Maximum Character length : 10
                  </li>
                  <li className="pt-2">
                    In case you forget your Referral Code, it will be visible to
                    you on the ‘Account Details’ page of your profile.
                  </li>
                </ul>
              </div>
            </>
          ) : (
            ''
          )}
          <hr />
          {/*  */}

          <div className=" pt-3">
            <div className="row">
              <div className="col-md-12 mt-2">
                <div className="d-flex justify-content-between">
                  <h5 className=" d-flex align-items-center ">
                    UNIQUE REFERRAL CODE
                    *&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <IoInformationCircleOutline
                      size={18}
                      onClick={handleShowreferral}
                    />
                  </h5>
                </div>
                <div className="labels">
                  ( Click on the Information icon to clearly understand what
                  Referral Code is. )
                </div>
                <input
                  placeholder="Create Unique Referral Code of your Choice "
                  disabled={Edit?.length > 0 ? true : false}
                  className="form-control"
                  required
                  type={'text'}
                  value={value.Referral_Code}
                  onChange={(e) => {
                    setValue({ ...value, Referral_Code: e.target.value });
                  }}
                  minLength={5}
                  maxLength={10}
                  onBlur={(e) => {
                    if (e.target.value.length > 3)
                      checkReferral_Code(e.target.value);
                  }}
                ></input>
                <div class="invalid-feedback">
                  PLEASE ENTER Referral Code ( minimum of 5 characters )
                </div>
              </div>
              <h5 className="mt-4 ">PERSONAL DETAILS *</h5>
              <div className="col-12  col-md-3 mnmnmnm mmmmm">
                <Imageupload  onChange={(e) => setimgi(e)} />

                <span className="mt-3 " style={{ paddingLeft: '15px' }}>
                  Upload your PASSPORT size Photo *
                </span>
                <p className="text-danger">{error}</p>
              </div>

              <div className="col-12 col-md-9">
                <div className="row mt-3">
                  {/* <h5 className="mt-4  d-none d-md-block">
                    PERSONAL DETAILS *
                  </h5> */}
                  <div className="col-md-12">
                    <label className="labels">Full Name *</label>
                    <input
                      placeholder="Enter Full Name"
                      className="form-control"
                      disabled={Edit?.length > 0 ? true : false}
                      required
                      type={'text'}
                      value={value.Name}
                      onChange={(e) => {
                        setValue({ ...value, Name: e.target.value });
                      }}
                    ></input>
                    <div class="invalid-feedback">PLEASE ENTER Full Name</div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Gender *</label>
                    <select
                      class="form-control form-select"
                      value={value.Gender}
                      onChange={(e) => {
                        setValue({ ...value, Gender: e.target.value });
                      }}
                      required
                      disabled={Edit?.length > 0 ? true : false}
                    >
                      <option></option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Transgender">Transgender</option>
                    </select>
                    <div class="invalid-feedback">PLEASE SELECT Gender</div>
                  </div>
                  <div className="col-md-12 ">
                    <label className="labels">Age *</label>
                    <div>
                      <div className="age w-100">
                        <input
                          placeholder="Enter Age"
                          disabled={Edit?.length > 0 ? true : false}
                          className="form-control  "
                          type={'number'}
                          min="18"
                          pattern="[0-9]{2}"
                          required
                          // onBlur={(e) => {
                          //   if (e.target.value <= 17) {
                          //     setValue({ ...value, Age: ' ' });

                          //   }
                          //   else{
                          //     setErr
                          //   }
                          // }}
                          value={value.Age}
                          onChange={(e) => {
                            if (
                              e.target.value.length <= 2 &&
                              /^\d*$/.test(e.target.value)
                            )
                              setValue({ ...value, Age: e.target.value });
                          }}
                        ></input>
                      </div>
                    </div>
                    <div class="invalid-feedback">PLEASE ENTER Age</div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Nationality *</label>
                    <select
                      class="form-control form-select"
                      required
                      value={value.Nationality}
                      onChange={(e) => {
                        setValue({ ...value, Nationality: e.target.value });
                      }}
                      disabled={Edit?.length > 0 ? true : false}
                    >
                      <option></option>
                      <option value="Indian">Indian</option>
                      <option value="American">American</option>
                      <option value="British">British</option>
                      <option value="German">German</option>
                      <option value="Italian">Italian</option>
                    </select>
                    <div class="invalid-feedback">
                      PLEASE SELECT Nationality
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Blood Group *</label>
                    <input
                      className="form-control"
                      disabled={Edit?.length > 0 ? true : false}
                      required
                      type={'text'}
                      value={value.BloodGroup}
                      onChange={(e) => {
                        setValue({ ...value, BloodGroup: e.target.value });
                      }}
                    ></input>
                    <div class="invalid-feedback">PLEASE ENTER Blood Group</div>
                  </div>

                  <StateCity setValue={setValue} value={value} Edit={Edit} />
                  {/* [{country:value.country,state:value.state,city:value.city}] */}

                  <div className="col-md-12 mt-2 ">
                    <label className="labels">Address *</label>

                    <textarea
                      placeholder="Enter Full Address"
                      className="form-control  "
                      required
                      value={value.Address}
                      onChange={(e) => {
                        setValue({ ...value, Address: e.target.value });
                      }}
                    ></textarea>

                    <div class="invalid-feedback">PLEASE ENTER Address</div>
                  </div>
                  <div className="col-md-12 mt-2">
                    <label className="labels">Pin Code *</label>

                    <input
                      placeholder="Enter Pin Code"
                      className="form-control  "
                      type={'text'}
                      maxLength={6}
                      required
                      value={value.Pincode}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value))
                          setValue({ ...value, Pincode: e.target.value });
                      }}
                    ></input>

                    <div class="invalid-feedback">PLEASE ENTER Pin Code</div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Email Id *</label>
                    <input
                      placeholder="Enter an existing Email Id  "
                      disabled={Edit?.length > 0 ? true : false}
                      className="form-control "
                      type={'email'}
                      required
                      value={value.Email}
                      onChange={(e) => {
                        setValue({ ...value, Email: e.target.value });
                      }}
                    ></input>
                    <div class="invalid-feedback">PLEASE ENTER Email Id</div>
                  </div>

                  <div className="col-md-12">
                    <label className="labels">Contact Number *</label>
                    <input
                      placeholder="Enter Anytime Available Contact Number  "
                      maxLength={10}
                      required
                      className="form-control "
                      type={'text'}
                      value={value.Contact}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value))
                          setValue({ ...value, Contact: e.target.value });
                      }}
                    ></input>
                    <div class="invalid-feedback">PLEASE ENTER Contact</div>
                  </div>
                </div>
                <h5 className="mt-4">IDENTITY PROOF *</h5>
                <div className="row mt-2">
                  <label className="labels">
                    Upload both front and back sides of your Aadhaar Card
                  </label>
                  <div className="col-md-12">
                    <div></div>
                    <input
                      hidden
                      ref={btnchange}
                      className="form-control"
                      type="file"
                      id="formFile"
                      accept="image/*"
                      required
                      onChange={(e) => setadar([...adar, ...e.target.files])}
                    />
                    <button
                      id="btnchange"
                      className="btn pro-spaces-button mt-3 mx-2"
                      style={{ backgroundColor: 'rgb(1 32 96)' }}
                      accept="image/*"
                      onClick={(e) => btnchange.current.click()}
                    >
                      Upload your Aadhaar Card *
                    </button>

                    <div className="ps-3 pt-3">
                      <div className="row g-3">
                        {adar?.map((ele, index) => {
                          console.log(ele);
                          return (
                            <div className="col-12 col-md-6 col-lg-4">
                              <IoIosCloseCircleOutline
                                className="d-flex float-end"
                                onClick={(e) => fun(index)}
                              />
                              <img
                                src={URL.createObjectURL(ele)}
                                style={{
                                  width: '100%',
                                  height: '200px',
                                  objectFit: 'cover',
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div class="invalid-feedback">
                      PLEASE UPLOAD Aadhaar Card
                    </div>
                  </div>
                </div>
                {value?.Studentornot == 'Student' ? (
                  <>
                    <h5 className="mt-4">EDUCATIONAL DETAILS *</h5>
                    <div className="row mt-2">
                      <div className="col-md-12">
                        <label className="labels">
                          Pursuing Educational Course *
                        </label>

                        <textarea
                          placeholder="Enter Course Details relevant to your Internship program"
                          className="form-control  "
                          required
                          value={value.ED_Pursuing_Education}
                          onChange={(e) => {
                            setValue({
                              ...value,
                              ED_Pursuing_Education: e.target.value,
                            });
                          }}
                        ></textarea>

                        <div class="invalid-feedback">
                          PLEASE ENTER Pursuing Educational Course
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label className="labels">College *</label>

                        <input
                          className="form-control  "
                          required
                          value={value.ED_College}
                          onChange={(e) => {
                            setValue({
                              ...value,
                              ED_College: e.target.value,
                            });
                          }}
                        ></input>

                        <div class="invalid-feedback">PLEASE ENTER COLLEGE</div>
                      </div>

                      <div className="col-md-12">
                        <label className="labels">University *</label>

                        <input
                          className="form-control  "
                          required
                          value={value.ED_University}
                          onChange={(e) => {
                            setValue({
                              ...value,
                              ED_University: e.target.value,
                            });
                          }}
                        ></input>

                        <div class="invalid-feedback">
                          PLEASE ENTER University
                        </div>
                      </div>

                      <div className="col-md-12">
                        <label className="labels">City *</label>

                        <input
                          placeholder=""
                          className="form-control  "
                          required
                          value={value.ED_City}
                          onChange={(e) => {
                            setValue({
                              ...value,
                              ED_City: e.target.value,
                            });
                          }}
                        />

                        <div class="invalid-feedback">PLEASE ENTER City</div>
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Period of Internship *</label>
                        <div className="row">
                          <div className="col-5">
                            <input
                              placeholder=""
                              type="date"
                              className="form-control  "
                              required
                              value={value.ED_Period_of_Internship_Start}
                              onChange={(e) => {
                                setValue({
                                  ...value,
                                  ED_Period_of_Internship_Start: e.target.value,
                                });
                              }}
                            />
                            <div class="invalid-feedback">
                              PLEASE ENTER Period of Internship
                            </div>
                          </div>
                          <div className="col-2 d-flex justify-content-center align-items-end">
                            <span className="">to</span>
                          </div>
                          <div className="col-5">
                            <input
                              placeholder=""
                              type="date"
                              className="form-control  "
                              required
                              value={value.ED_Period_of_Internship_End}
                              onChange={(e) => {
                                setValue({
                                  ...value,
                                  ED_Period_of_Internship_End: e.target.value,
                                });
                              }}
                            />
                            <div class="invalid-feedback">
                              PLEASE ENTER Period of Internship
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {value?.Studentornot == 'NoStudent' ? (
                  <>
                    <h5 className="mt-4">
                      QUALIFICATION and WORK EXPERIENCE *
                    </h5>
                    <div className="row mt-2">
                      <div className="col-md-12">
                        <label className="labels">
                          Highest Educational Qualification *
                        </label>

                        <textarea
                          className="form-control  "
                          required
                          value={value.Highest_Educational_Qualification}
                          onChange={(e) => {
                            setValue({
                              ...value,
                              Highest_Educational_Qualification: e.target.value,
                            });
                          }}
                        ></textarea>

                        <div class="invalid-feedback">
                          PLEASE ENTER Highest Educational Qualification
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Work Experience *</label>

                        <textarea
                          placeholder="Type NIL if Fresher"
                          className="form-control  "
                          required
                          value={value.Work_Experience}
                          onChange={(e) => {
                            setValue({
                              ...value,
                              Work_Experience: e.target.value,
                            });
                          }}
                        ></textarea>

                        <div class="invalid-feedback">
                          PLEASE ENTER Work Experience
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label className="labels">
                          Ready to Join on anyday after *
                        </label>

                        <input
                          type="date"
                          className="form-control"
                          required
                          // disabled={Edit?.length > 0 ? true : false}
                          value={value.Ready_to_Join_on_anyday_after?.slice(
                            0,
                            10
                          )}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setValue({
                              ...value,
                              Ready_to_Join_on_anyday_after: e.target.value,
                            });
                          }}
                        ></input>

                        <div class="invalid-feedback">
                          PLEASE ENTER Ready to Join on anyday after
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <h5 className="mt-4">BANK ACCOUNT DETAILS *</h5>

                <div className="row mt-2">
                  <div className="col-md-12">
                    <label className="labels">Account Holder Name *</label>
                    <input
                      type="text"
                      placeholder="Enter Full Name as per bank account "
                      required
                      className="form-control"
                      maxLength="150"
                      value={value.Account_holder_name}
                      onChange={(e) =>
                        setValue({
                          ...value,
                          Account_holder_name: e.target.value,
                        })
                      }
                    />
                    <div class="invalid-feedback">
                      PLEASE ENTER Bank account holder name
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Bank Name *</label>
                    <input
                      type="text"
                      placeholder="Enter Bank Name "
                      required
                      className="form-control"
                      maxLength="40"
                      value={value.Bank_name}
                      onChange={(e) =>
                        setValue({ ...value, Bank_name: e.target.value })
                      }
                    />
                    <div class="invalid-feedback">PLEASE ENTER Bank name</div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Bank Account Type *</label>

                    <select
                      class="form-control form-select"
                      required
                      value={value.Bank_account_type}
                      onChange={(e) =>
                        setValue({
                          ...value,
                          Bank_account_type: e.target.value,
                        })
                      }
                    >
                      <option></option>
                      <option value="SB">SB</option>
                      <option value="Current">Current</option>
                    </select>
                    <div class="invalid-feedback">
                      PLEASE SELECT Bank account type
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Account Number *</label>
                    <input
                      type="number"
                      placeholder="Enter Account Number "
                      required
                      className="form-control"
                      maxLength="100"
                      value={value.Account_number}
                      onChange={(e) =>
                        setValue({ ...value, Account_number: e.target.value })
                      }
                    />
                    <div class="invalid-feedback">
                      PLEASE ENTER Account number
                    </div>
                  </div>

                  <div className="col-md-12">
                    <label className="labels">IFSC code *</label>
                    <input
                      type="text"
                      required
                      placeholder="Enter IFSC code "
                      className="form-control"
                      maxLength="40"
                      value={value.Ifsc_code}
                      onChange={(e) =>
                        setValue({ ...value, Ifsc_code: e.target.value })
                      }
                    />
                    <div class="invalid-feedback">PLEASE ENTER IFSC code</div>
                  </div>

                  {!Edit?.length > 0 ? (
                    <>
                      <div>
                        <div class=" pt-3">
                          <label
                            class="form-check-label"
                            for="flexCheckDefault7"
                          >
                            <ul>
                              <li>
                                I am aware and agree that the bank account
                                details I have mentioned is correct and wish to
                                have my Remuneration credited to this account.
                              </li>
                            </ul>
                          </label>
                        </div>
                      </div>
                      <div>
                        <div class=" pt-3">
                          <label
                            class="form-check-label"
                            for="flexCheckDefault9"
                          >
                            <ul>
                              <li>
                                I am aware and agree that any mistake / error in
                                the bank account details entered by me SHALL NOT
                                be the responsibility of the Organisation.
                              </li>
                            </ul>
                          </label>
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                  <h5 className="mt-4">
                    CONTACT INFORMATION of a FAMILY MEMBER in case of an
                    Emergency *
                  </h5>
                  <div className="col-md-12 mt-2">
                    <label className="labels">
                      Full Name of Family Member *
                    </label>
                    <input
                      className="form-control"
                      required
                      placeholder="Enter Full Name"
                      type={'text'}
                      value={value.FMName}
                      onChange={(e) =>
                        setValue({
                          ...value,
                          FMName: e.target.value,
                        })
                      }
                    ></input>
                    <div class="invalid-feedback">
                      PLEASE ENTER Full Name of Family Member
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">
                      Relationship with Family Member *
                    </label>

                    <select
                      class="form-control form-select"
                      required
                      id="exampleFormControlSelect1"
                      value={value.FMRelationship}
                      onChange={(e) =>
                        setValue({
                          ...value,
                          FMRelationship: e.target.value,
                        })
                      }
                    >
                      <option></option>
                      <option value="Father">Father</option>
                      <option value="Mother">Mother</option>
                      <option value="Brother">Brother</option>
                      <option value="Sister">Sister</option>
                      <option value="Husband">Husband</option>
                      <option value="Wife">Wife</option>
                      <option value="Son">Son</option>
                      <option value="Daughter">Daughter</option>
                    </select>
                    <div class="invalid-feedback">
                      PLEASE SELECT Relationship with Family Member
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">
                      Contact Number of Family Member *
                    </label>
                    <input
                      placeholder="  "
                      maxLength={10}
                      required
                      className="form-control "
                      type={'text'}
                      value={value.FMContact}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value))
                          setValue({ ...value, FMContact: e.target.value });
                      }}
                    ></input>
                    <div class="invalid-feedback">
                      PLEASE ENTER Contact Number of Family Member
                    </div>
                  </div>
                  <div className="col-md-12 mt-2 ">
                    <label className="labels">Address of Family Member *</label>

                    <textarea
                      placeholder="Enter Full Address"
                      className="form-control  "
                      required
                      value={value.FMAddress}
                      onChange={(e) => {
                        setValue({ ...value, FMAddress: e.target.value });
                      }}
                    ></textarea>

                    <div class="invalid-feedback">PLEASE ENTER Address</div>
                  </div>

                  <div className="col-md-12 mt-2">
                    <StateCity
                      setValue={setvalueee}
                      value={valueee}
                      Edit={Edit}
                    />
                  </div>
                  <div className="col-md-12 mt-2">
                    <label className="labels">Pin Code *</label>

                    <input
                      placeholder="Enter Pin Code"
                      className="form-control  "
                      type={'text'}
                      maxLength={6}
                      required
                      value={value.FMPincode}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value))
                          setValue({ ...value, FMPincode: e.target.value });
                      }}
                    ></input>
                    <div class="invalid-feedback">PLEASE ENTER Pin Code</div>
                  </div>
                  <h5 className="mt-4">LOGIN CREDENTIALS *</h5>
                  <div className="col-md-12 mt-2">
                    <label className="labels">
                      Login Id <br></br>( Your Email Id is your Default
                      Login Id. )
                    </label>
                    <input
                      disabled
                      className="form-control"
                      type={'text'}
                      value={value.Email}
                    ></input>
                  </div>

                  {!Edit?.length > 0 ? (
                    <>
                      <div className="col-md-12">
                        <label className="labels">Password *</label>
                        <div>
                          <input
                            class="form-control passInout12321"
                            placeholder="Enter Password"
                            aria-describedby="basic-addon2"
                            type={showPassword ? 'text' : 'password'}
                            value={value.Password}
                            onChange={(e) =>
                              setValue({ ...value, Password: e.target.value })
                            }
                            required
                          />
                          &nbsp;
                          <span class="showbutton123321">
                            {showPassword ? (
                              <RiEyeFill
                                onClick={(e) => {
                                  setShowPassword(!showPassword);
                                }}
                              />
                            ) : (
                              <RiEyeOffFill
                                onClick={(e) => {
                                  setShowPassword(!showPassword);
                                }}
                              />
                            )}
                          </span>
                          <div class="invalid-feedback ">
                            PLEASE ENTER Password
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <label className="labels">Confirm Password *</label>
                        <div>
                          <div>
                            <input
                              class="form-control passInout12321"
                              placeholder="Enter Password again"
                              type={showPassword1 ? 'text' : 'password'}
                              value={value.ConformPassword}
                              onChange={(e) =>
                                setValue({
                                  ...value,
                                  ConformPassword: e.target.value,
                                })
                              }
                              required
                            />

                            <span class="showbutton123321" type="button">
                              {' '}
                              {showPassword1 ? (
                                <RiEyeFill
                                  onClick={(e) => {
                                    setShowPassword1(!showPassword1);
                                  }}
                                />
                              ) : (
                                <RiEyeOffFill
                                  onClick={(e) => {
                                    setShowPassword1(!showPassword1);
                                  }}
                                />
                              )}
                            </span>
                          </div>
                          <div class="invalid-feedback ">
                            PLEASE ENTER Confirm Password
                          </div>
                        </div>
                        <hr />
                        <div class="form-check pt-3 ps-5">
                          <input
                            class="form-check-input affcheckbox"
                            type="checkbox"
                            id="flexCheckDefault1"
                            defaultChecked={TermsAndCondition.Terms1}
                            onChange={(e) => setbtn(!btn)}
                            onClick={(e) => {
                              setTermsAndCondition({
                                ...TermsAndCondition,
                                Terms1: !TermsAndCondition.Terms1,
                              });
                            }}
                          />
                          <label class="form-check-label">
                            I have read and understood each and every clause
                            mentioned above and agree to comply with all the
                            Terms and Conditions upon registration.
                          </label>
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}

                  {error.length ? (
                    <div className="col-12 ">
                      <p className="py-1 m-0" style={{ color: 'red' }}>
                        {error ? error : ''}
                      </p>
                    </div>
                  ) : (
                    ''
                  )}

                  <div className="col-12 px-1  d-flex justify-content-center pt-3  ">
                    {ButtonDisabled ? (
                      <button
                        className="btn pro-spaces-button3 "
                        disabled={!btn}
                      >
                        {Edit?.length > 0 ? 'UPDATE' : 'REGISTER'}
                      </button>
                    ) : (
                      !loading && (
                        <button
                          disabled={!btn}
                          className="btn pro-spaces-button3 "
                          onClick={(e) => validator1(e)}
                        >
                          {Edit?.length > 0 ? 'UPDATE' : 'REGISTER'}
                        </button>
                      )
                    )}
                    {/* {loading && (
                      <button
                        className="btn pro-spaces-button3"
                        disabled={!btn}
                      >
                        REGISTER &nbsp;
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          /> */}
        </form>
      </div>
      {/* <div>
        <Footer />
      </div> */}

      <Modal show={show11} onHide={handleClose11} centered>
        <Modal.Header closeButton>
          <Modal.Title>Enter OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row mt-2">
              <div className="col-12">
                <label className="labels">
                  OTP (sent to registered email address)
                </label>
                <input
                  type="text"
                  placeholder="Enter OTP "
                  required
                  className="form-control"
                  maxLength="5"
                  value={OTP}
                  onChange={(e) => setOTP(e.target.value)}
                />
              </div>
            </div>

            <div className="pt-4 ">
              {pop ? (
                <div className="mb-3 text-center">
                  <Timer minutes={2} seconds={59} callback={end} />
                </div>
              ) : (
                ''
              )}

              {pop ? (
                <>
                  <button className="btn pro-spaces-outline my-1 " disabled>
                    Resend OTP ?
                  </button>
                  <br />
                </>
              ) : (
                <>
                  <a
                    id="resendOTP"
                    onClick={(e) => GenerateOTP(e)}
                    className="btn pro-spaces-outline my-1 "
                  >
                    Resend OTP ?{' '}
                    {loading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </a>
                  <br />
                </>
              )}
            </div>
            <div>
              <p style={{ color: 'red' }}>{Error2 ? Error2 : ''}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn pro-spaces-button3"
            onClick={(e) => {
              Update();
            }}
          >
            Verify
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showpassword} onHide={handleClose111} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <label className="labels">Old password *</label>
                <div>
                  <input
                    class="form-control passInout12321"
                    placeholder="Enter old Password"
                    aria-describedby="basic-addon2"
                    type={showPassword12 ? 'text' : 'password'}
                    value={NewPassword.oldPassword}
                    onChange={(e) =>
                      setNewPassword({
                        ...NewPassword,
                        oldPassword: e.target.value,
                      })
                    }
                  />
                  &nbsp;
                  <span class="showbutton123321">
                    {showPassword12 ? (
                      <RiEyeFill
                        onClick={(e) => {
                          setShowPassword12(!showPassword12);
                        }}
                      />
                    ) : (
                      <RiEyeOffFill
                        onClick={(e) => {
                          setShowPassword12(!showPassword12);
                        }}
                      />
                    )}
                  </span>
                </div>
              </div>

              <div className="col-md-12">
                <label className="labels">New password *</label>
                <div>
                  <div>
                    <input
                      class="form-control passInout12321"
                      placeholder="Enter new Password"
                      type={showPassword123 ? 'text' : 'password'}
                      value={NewPassword.newPassword}
                      onChange={(e) =>
                        setNewPassword({
                          ...NewPassword,
                          newPassword: e.target.value,
                        })
                      }
                    />

                    <span class="showbutton123321" type="button">
                      {' '}
                      {showPassword123 ? (
                        <RiEyeFill
                          onClick={(e) => {
                            setShowPassword123(!showPassword123);
                          }}
                        />
                      ) : (
                        <RiEyeOffFill
                          onClick={(e) => {
                            setShowPassword123(!showPassword123);
                          }}
                        />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p style={{ color: 'red' }}>{}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn pro-spaces-button3"
            onClick={(e) => {
              UpdatePassword();
            }}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>

      {/* referralcode modal */}

      <Modal show={showreferral} onHide={handleClosereferral}>
        <Modal.Header centered closeButton></Modal.Header>
        <Modal.Body>
          <div className="footlightfont">
            <div className="pt-3" style={{ lineSpacing: '0' }}>
              • During Registration, Create a Referral Code of your choice which
              will be your FSE Identification at{' '}
              <span
                className="vipar"
                style={{
                  fontSize: '22px',
                  color: '#99d050',
                }}
              >
                {' '}
                Vipar
              </span>{' '}
              <sup class="">®</sup>
            </div>
            <div className="pt-3" style={{ lineSpacing: '0' }}>
              • Your Unique Referral Code is the one with which
              <span
                className="vipar"
                style={{
                  fontSize: '22px',
                  color: '#99d050',
                }}
              >
                {' '}
                Vipar
              </span>{' '}
              <sup class="">®</sup> can identify that it is via your FSE account
              that a Subscription is sold.
            </div>
            <div className="pt-3">
              • It is Mandatory for tracking your sales targets and disbursing
              your Remuneration to you.
            </div>
            <div className="pt-3">
              • Once a Referral Code is created, it is permanent and cannot be
              changed.
            </div>

            <div className="pt-3">
              • Can be a combination of Alphabets, Numbers and Symbols.
            </div>
            <div className="pt-3">
              • Minimum Character length &#8239;: 5<br />
              &nbsp;&nbsp;Maximum Character length : 10
            </div>
            <div className="pt-3">
              • In case you forget your Referral Code, it will be visible to you
              on the ‘Account Details’ page of your profile.
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* referralcode modal */}

      {/* Starting Modal */}
      <Modal fullscreen show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="pt-3 footlightfont text-center ">
            <span className="prochure">PROchure</span>
            &nbsp;
            <span className="boldfont">FIELD SALES</span>
          </div>
          <div className="pt-3 footlightfont">
            There are currently two types of Membership Subscription.
            <div className="pt-2 footlightfont">
              1. BASIC listing
              <br />
              ₹ 500/- per year including GST
              <br />
              2. PRIME league
              <br />₹ 5000/- per year including GST
            </div>
          </div>
          <div className="pt-3 footlightfont">
            Please Note that in certain Categories / Sub Categories of Qualified
            Professionals, Profiles cannot be created under BASIC listing.
          </div>
          <div className="pt-3 footlightfont">
            Profiles of these Qualified Professionals (like Architects,
            Structural Engineers, Chartered Accountants, Doctors, Lawyers, etc)
            can be listed Only under PRIME league because of mandatory
            Professional information required from them.
          </div>
          <div className="pt-3 footlightfont">
            <span className="boldfont">Remuneration</span> :
          </div>
          <div className="pt-3 footlightfont">
            You will be paid 35% Commission on the Final (in case of using a
            Discount Coupon) Subscription Amount on each profile that you get
            listed via your Unique Referral Code.
            <br /> For instance, if you create a Profile under PRIME league for
            ₹ 5000/-, you will get ₹ 1500/- as commission.
          </div>
          <div className="pt-3 footlightfont">
            <span className="boldfont">Here's how it works</span> :
          </div>
          <div className="pt-3 footlightfont">
            1. JOIN to Create a FSE account with us.
          </div>
          <div className="pt-2 footlightfont">
            2. Via your FSE account, you can :<br />
            a) See details of the profiles created by yourself.
            <br />
            b) Track your commission remittances.
          </div>
          <div className="pt-3 footlightfont">
            <span className="boldfont">After Registration</span> :
            <div className="pt-3 footlightfont">
              • You can see the CATEGORIES and SUB CATEGORIES under Services and
              Products in which Profiles must be created.
            </div>
            <div className="pt-3 footlightfont">
              • You can Sign In / Log In to www.vipar-connect.in anytime you
              wish to do marketing and sales.
            </div>
          </div>

          <div className="pt-3 footlightfont">
            <span className="boldfont">Referral Code</span> :
          </div>

          <div className="pt-3 footlightfont" style={{ lineSpacing: '0' }}>
            • Create a Referral Code of your choice which will be your FSE
            Identification at{' '}
            <span
              className="vipar"
              style={{
                fontSize: '22px',
                color: '#99d050',
              }}
            >
              {' '}
              Vipar
            </span>{' '}
            <sup class="">®</sup>.
          </div>
          <div className="pt-3 footlightfont" style={{ lineSpacing: '0' }}>
            • Your Unique Referral Code is the one with which
            <span
              className="vipar"
              style={{
                fontSize: '22px',
                color: '#99d050',
              }}
            >
              {' '}
              Vipar
            </span>{' '}
            <sup class="">®</sup> can identify that it is via your FSE account
            that a Subscription is sold.
          </div>
          <div className="pt-3 footlightfont">
            • It is Mandatory for tracking your sales targets and disbursing
            your commission to you.
          </div>
          <div className="pt-3 footlightfont">
            • Once a Referral Code is created, it is permanent and cannot be
            changed.
          </div>

          <div className="pt-3 footlightfont">
            • Can be a combination of Alphabets, Numbers and Symbols.
          </div>
          <div className="pt-3 footlightfont">
            • Minimum Character length &#8239;: 5<br />
            &nbsp;&nbsp;Maximum Character length : 10
          </div>
          <div className="pt-3 footlightfont">
            • In case you forget your Referral Code, it will be visible to you
            on the ‘Account Details’ page of your profile.
          </div>

          <div className="py-3 d-flex justify-content-center">
            {/* <Link
              style={{ textDecoration: 'none' }}
              to="/ame_registration_form"
            > */}
            <button
              className="btn pro-spaces-button3 footlightfont "
              onClick={() => {
                handleClose();
              }}
            >
              JOIN NOW
            </button>
            {/* </Link> */}
          </div>
        </Modal.Body>
      </Modal>
      {/* Starting Modal */}
    </>
  );
};
