import React, { useState, useEffect, Children, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi';

import TagFacesIcon from '@mui/icons-material/TagFaces';
function NewConsultantCategory({ LogOp }) {
  let editcategory = useRef();
  // useEffect(() => {
  //   axios.get("/api/Admin/UpdateCategoryPosition")
  //   .then((res) => {
  //     if (res.status === 200) {
  //       console.log("workd");
  //       console.log(res.data)
  //       // setCat(res.data);
  //     }
  //   })
  //   .catch((err) => {
  //     console.log(err.response.status);
  //   });

  // }, [])

  const [open, setOpen] = useState(false);
  const [showCat, setShowCat] = useState(false);
  const [showSubCat, setShowSubCat] = useState(false);
  const [loading, setloading] = useState(false);
  const handleCloseCat = () => setShowCat(false);
  const [subcategory, setSubcategory] = useState(false);
  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [value, setValue] = useState({
    categoryname: '',
    category: '',
    subcategoryname: '',
    subcategory: '',
    first: false,
    subcategoryAfter: null,
    formType: null,
    positionSelected: '',
    positionSelectedindex: '',
    catIndex: '',
    subcategoryAfterIndex: null,
    type: true,
    book_a_table: false,
    book_an_appointment: false,
    place_an_order: false,
    make_a_purchase: false,
    Static_status: false,
    description: '',
  });

  useEffect(() => {
    setValue({ ...value, type: LogOp });
  }, [LogOp]);

  const [error, seterror] = useState('');
  const handleShowCat = (category, id) => {
    setValue({
      ...value,
      // categoryname:category
      categoryname: category,
      category: id,
    });
    setShowCat(true);
  };

  const handleCloseSubCat = () => {
    setShowSubCat(false);
    setChipData([]);
    setValue({
      categoryname: '',
      category: '',
      subcategoryname: '',
      subcategory: '',
      first: false,
      subcategoryAfter: null,
      formType: null,
      positionSelected: '',
      positionSelectedindex: '',
      catIndex: '',
      subcategoryAfterIndex: null,
      type: true,
      book_a_table: false,
      book_an_appointment: false,
      place_an_order: false,
      make_a_purchase: false,
      Static_status: false,
      description: '',
    });
  };
  const handleShowSubCat = (
    category,
    subcategory,
    subcategoryname,
    formType,
    position,
    index,
    index1,
    userCount,
    keyword,
    book_a_table,
    book_an_appointment,
    place_an_order,
    make_a_purchase,
    Static_status,
    description
  ) => {
    sabari(category);
    setValue({
      ...value,

      category: category, ///category Id
      subcategoryname: subcategoryname, /// Sub category name
      subcategory: subcategory, ///  Sub category Id
      first: false, ///   positions first
      subcategoryAfter: '', ///    positions After subcategory Id
      formType: formType, ///     form type of subcategory
      positionSelected: position, ///      position from backend
      positionSelectedindex: index, ///       index of subcategory
      catIndex: index1, ///        index of category
      userCount: userCount.length, ///         number of users->for collapse table
      subcategoryAfterIndex: null, ///          index of subcategory after selected in the subcategory
      type: true, ///           consultant or vender true->consultant,false->vender
      book_a_table: book_a_table,
      book_an_appointment: book_an_appointment,
      place_an_order: place_an_order,
      make_a_purchase: make_a_purchase,
      Static_status: Static_status,
      description: description,
    });
    if (keyword) {
      if (keyword.length > 0) {
        setChipData(keyword);
      }
    }
    setShowSubCat(true);
  };

  const [cat, setCat] = useState([]);
  function reload() {
    axios
      .get('/api/api/getprocategoryForEdit')
      .then((res) => {
        if (res.status === 200) {
          setCat(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    reload();
  }, []);
  const EditCategorys = async () => {
    if (!editcategory.current.value.length > 0) {
      return seterror('Enter category name');
    }
    value.categoryname = editcategory.current.value;
    seterror('');
    let update = await axios
      .post('/api/Admin/UpdateCtategory', { value })
      .then((res) => {
        if (res.status == 200) {
          notify('Successfully updated', true);

          setValue({
            categoryname: '',
            category: '',
          });
          handleCloseCat();
          reload();
        }
      })
      .catch((err) => {
        console.log(err);
        notify(' Something went wrong', false);
        setloading(false);
      });
  };

  const EditSubCategorys = async (e) => {
    value.subcategoryname = e.target.elements.subcategoryname.value;
    value.first = e.target.elements.first.checked;
    value.subcategoryAfter = e.target.elements.subcategoryAfter.value;
    value.formType = e.target.elements.formType.value;
    value.book_a_table = e.target.elements.book_a_table.checked;
    value.book_an_appointment = e.target.elements.book_an_appointment.checked;
    value.place_an_order = e.target.elements.place_an_order.checked;
    value.make_a_purchase = e.target.elements.make_a_purchase.checked;
    value.chipData = chipData;
    value.Static_status = e.target.elements.Static_status.checked;
    value.description = e.target.elements.description.value;

    SubcategoryPosition();
    e.preventDefault();
    if (!e.target.elements.subcategoryname.value) {
      return seterror('Enter sub category name');
    }
    seterror('');
    if (!e.target.elements.formType.value) {
      return seterror('Select form type');
    }
    seterror('');
    setloading(true);

    let update = await axios
      .post('/api/Admin/UpdateSubCtategory', { value })
      .then((res) => {
        if (res.status == 200) {
          notify('Successfully updated', true);
          setValue({
            categoryname: '',
            category: '',
            subcategoryname: '',
            subcategory: '',
            first: false,
            subcategoryAfter: null,
            formType: null,
            positionSelected: '',
            positionSelectedindex: '',
            catIndex: '',
            subcategoryAfterIndex: null,
            type: true,
            book_a_table: false,
            book_an_appointment: false,
            place_an_order: false,
            make_a_purchase: false,
            Static_status: false,
            description: '',
          });
          setloading(false);
          setChipData([]);
          handleCloseSubCat();
          reload();
        }
      })
      .catch((err) => {
        console.log(err);
        notify(' Something went wrong', false);
        setloading(false);
      });
  };

  const sabari = (e) => {
    var anadhan;
    setValue({ ...value, category: e });

    cat.forEach((element) => {
      if (element._id === e) {
        anadhan = element.sub.map((elements) => {
          return <option value={elements._id}>{elements.sub}</option>;
        });
      } else {
      }
    });
    setSubcategory(anadhan);
  };

  const DeleteSubcategory = async (CatId, SubCatId) => {
    let update = await axios
      .post('/api/Admin/SubCategoryDelete', { CatId, SubCatId })
      .then((res) => {
        if (res.status == 200) {
          notify('Deleted', true);
          reload();
        }
      })
      .catch((err) => {
        console.log(err);
        notify(' Something went wrong', false);
      });
  };

  const DeleteBrand = async (subCatID, BrandID) => {
    let update = await axios
      .post('/api/Admin/BrandDelete', { subCatID, BrandID })
      .then((res) => {
        if (res.status == 200) {
          notify('Deleted', true);
          reload();
        }
      })
      .catch((err) => {
        console.log(err);
        notify(' Something went wrong', false);
      });
  };

  ////////////////Collapse table/////////
  function Row(props) {
    const { row, id, index, index1 } = props;
    const [open, setOpen] = React.useState(false);
    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell align="left">{index + 1}</TableCell>
          <TableCell align="left">{row.sub}</TableCell>
          <TableCell align="left">
            <b>
              {row.formType == 1
                ? 'A'
                : row.formType == 2
                ? 'B'
                : row.formType == 3
                ? 'C'
                : row.formType == 4
                ? 'D'
                : row.formType == 5
                ? 'E'
                : ''}
            </b>
          </TableCell>
          <TableCell align="left">{row.num}</TableCell>
          <TableCell align="left">
            {row.book_a_table ? <Chip label="BAT" variant="outlined" /> : ''}
            &nbsp;
            {row.book_an_appointment ? (
              <Chip label="BAA" variant="outlined" />
            ) : (
              ''
            )}
            &nbsp;
            {row.make_a_purchase ? <Chip label="MAP" variant="outlined" /> : ''}
            &nbsp;
            {row.place_an_order ? <Chip label="PAO" variant="outlined" /> : ''}
            &nbsp;
          </TableCell>
          <TableCell align="left">
            <button
              className="btn pro-spaces-button3"
              onClick={(ed) =>
                handleShowSubCat(
                  id,
                  row._id,
                  row.sub,
                  row.formType,
                  row.num,
                  index,
                  index1,
                  row.userCount,
                  row.keyword,
                  row.book_a_table,
                  row.book_an_appointment,
                  row.place_an_order,
                  row.make_a_purchase,
                  row.Static_status,
                  row.description
                )
              }
            >
              Edit
            </button>
          </TableCell>
          <TableCell>
            {!row.Hide_status ? (
              HideAndUnhideSubCatLoading ? (
                <button className="btn pro-spaces-button3" disabled>
                  Hide
                </button>
              ) : (
                <button
                  className="btn pro-spaces-button3"
                  onClick={(ef) => HideAndUnhideSubCat(id, row._id, true)}
                >
                  Hide
                </button>
              )
            ) : HideAndUnhideSubCatLoading ? (
              <button className="btn pro-spaces-button3" disabled>
                Unhide
              </button>
            ) : (
              <button
                className="btn pro-spaces-button3"
                onClick={(ef) => HideAndUnhideSubCat(id, row._id, false)}
              >
                Unhide
              </button>
            )}
          </TableCell>
          {row.userCount?.length == 0 ? (
            <TableCell align="left">
              <button
                className="btn btn-danger"
                onClick={(ed) => DeleteSubcategory(id, row._id)}
              >
                Delete
              </button>
            </TableCell>
          ) : (
            ''
          )}
        </TableRow>

        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                {row.brand == true ? (
                  row.brand1.length > 0 ? (
                    <>
                      <Typography variant="h6" gutterBottom component="div">
                        Brand
                      </Typography>
                      <>
                        <div className="d-flex justify-content-end mb-3">
                          <button
                            className="btn pro-spaces-button3"
                            onClick={(e) => CreateBran(index1, index, row._id)}
                          >
                            Add brand
                          </button>
                        </div>
                        <Table size="small" aria-label="purchases">
                          <TableHead>
                            <TableRow>
                              <TableCell>No.</TableCell>
                              <TableCell>Brand Name</TableCell>
                              <TableCell>Origin</TableCell>
                              <TableCell>position</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.brand1.map((brand, ind) => (
                              <>
                                <TableRow key={ind}>
                                  <TableCell component="th" scope="row">
                                    {ind + 1}
                                  </TableCell>
                                  <TableCell>{brand.brand}</TableCell>
                                  <TableCell>{brand.origin}</TableCell>
                                  <TableCell>{brand.num}</TableCell>
                                  <TableCell>
                                    <button
                                      className="btn pro-spaces-button3"
                                      onClick={(ed) =>
                                        handleShowBrand(
                                          brand.brand,
                                          brand._id,
                                          brand.num,
                                          row._id,
                                          index1,
                                          index,
                                          ind,
                                          brand.origin
                                        )
                                      }
                                    >
                                      Update
                                    </button>
                                  </TableCell>

                                  {brand.BrandUserCount.length == 0 ? (
                                    <TableCell>
                                      <button
                                        className="btn btn-danger"
                                        onClick={(ed) =>
                                          DeleteBrand(row._id, brand._id)
                                        }
                                      >
                                        Delete
                                      </button>
                                    </TableCell>
                                  ) : (
                                    ''
                                  )}
                                </TableRow>
                              </>
                            ))}
                          </TableBody>
                        </Table>
                      </>
                    </>
                  ) : (
                    <center>
                      <button
                        className="btn pro-spaces-button3"
                        onClick={(e) => CreateBran(index1, index, row._id)}
                      >
                        Add Brand
                      </button>
                    </center>
                  )
                ) : row.userCount?.length != 0 ? (
                  <center>
                    <button className="btn btn-primary" disabled>
                      Activate Brand
                    </button>
                  </center>
                ) : (
                  <center>
                    <button
                      className="btn btn-primary"
                      onClick={(e) => ActivateBrand(row._id)}
                    >
                      Activate Brand
                    </button>
                  </center>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  /////////////////Brand/////////////////
  const [ShowBrand, setShowBrand] = useState(false);
  const [brand, setbrand] = useState({
    brandName: '',
    brandNameId: '',
    brandPosition: '',
    subcategory: '',
    indexOfCategory: '',
    indexOfSubCategory: '',
    indexOfBrand: '',
    brandAfter: '',
    brandFirst: false,
    brandAfterIndex: null,
    origin: 'none',
  });
  const handleShowBrand = (
    brandName,
    brandNameId,
    brandPosition,
    subcategory,
    index1,
    index,
    ind,
    origin
  ) => {
    if (cat[index1].sub[index].brand1.length > 0) {
      var barndList = cat[index1].sub[index].brand1.map((elements) => {
        return <option value={elements._id}>{elements.brand}</option>;
      });
      setBrandList(barndList);
    }

    setbrand({
      ...brand,
      brandName: brandName, //Brand name
      brandNameId: brandNameId, // Brand Id
      brandPosition: brandPosition, //  Brand Position from backend
      subcategory: subcategory, //   Subcategory Id
      indexOfCategory: index1, //    Category index
      indexOfSubCategory: index, //     Subcategory index
      indexOfBrand: ind, //      Brand Index
      brandAfter: '', //       Brand After Id
      brandFirst: false, //        Brand First position
      brandAfterIndex: null, //         Index of selected index
      origin: origin,
    });
    setShowBrand(true);
    // takeBrand()
  };
  const handleCloseBrand = () => {
    setShowBrand(false);
    setbrand({
      ...brand,
      brandName: '',
      brandNameId: '',
      brandPosition: '',
      subcategory: '',
      indexOfCategory: '',
      indexOfSubCategory: '',
      indexOfBrand: '',
      brandAfter: '',
      brandFirst: false,
      brandAfterIndex: null,
      origin: 'none',
    });
  };

  const ActivateBrand = async (id) => {
    let update = await axios
      .post('/api/Admin/ActivateBrand', { id })
      .then((res) => {
        if (res.status == 200) {
          notify('Brand Activated', true);
          reload();
        }
      })
      .catch((err) => {
        console.log(err);
        notify(' Something went wrong', false);
      });
  };

  const UpdateBrand = async (e) => {
    e.preventDefault();

    brand.brandName = e.target.elements.brandName.value;
    brand.brandAfter = e.target.elements.brandAfter.value;
    brand.brandFirst = e.target.elements.brandFirst.checked;
    brand.origin = e.target.elements.origin.value;

    if (!e.target.elements.brandName.value) {
      return seterror('Enter brand name');
    }
    // if (!brand.brandAfter&&brand.brandFirst==false) {
    // return  seterror("Select brand position")

    // }

    BrandPosition();

    let update = await axios
      .post('/api/Admin/UpdateBrand', { brand })
      .then((res) => {
        if (res.status == 200) {
          notify('Brand Updated', true);
          reload();
          handleCloseBrand();
        }
      })
      .catch((err) => {
        console.log(err);
        notify(' Something went wrong', false);
      });
  };
  const [BrandList, setBrandList] = useState([]);
  ////////////////////// Function for position//////////////////////////////////
  const BrandPosition = () => {
    if (brand.brandAfter) {
      // cat[value.catIndex]?.sub[cat[value.catIndex].sub.length-1]

      if (
        brand.brandAfterIndex - 1 ===
        cat[brand.indexOfCategory]?.sub[brand.indexOfSubCategory]?.brand1
          ?.length -
          1
      ) {
        var len =
          cat[brand.indexOfCategory].sub[brand.indexOfSubCategory]?.brand1
            ?.length - 1;
        var lastNum =
          cat[brand.indexOfCategory].sub[brand.indexOfSubCategory].brand1[len]
            ?.num;
        brand.brandPosition = lastNum + 1;
      } else {
        brand.brandAfterIndex = brand.brandAfterIndex - 1;
        var NumOfSelectedBrand =
          cat[brand.indexOfCategory]?.sub[brand.indexOfSubCategory]?.brand1[
            brand.brandAfterIndex
          ]?.num;
        var NumOfNextBrand =
          cat[brand.indexOfCategory]?.sub[brand.indexOfSubCategory]?.brand1[
            brand.brandAfterIndex + 1
          ]?.num;
        brand.brandPosition = (NumOfSelectedBrand + NumOfNextBrand) / 2;
      }
    }
  };

  const SubcategoryPosition = () => {
    if (value.subcategoryAfter) {
      if (
        value.subcategoryAfterIndex - 1 ===
        cat[value.catIndex]?.sub?.length - 1
      ) {
        var lastNum =
          cat[value.catIndex]?.sub[cat[value.catIndex].sub.length - 1]?.num;
        value.positionSelected = lastNum + 1;
      } else {
        value.subcategoryAfterIndex = value.subcategoryAfterIndex - 1;
        var NumOfSelectedSubCategory =
          cat[value.catIndex]?.sub[value.subcategoryAfterIndex]?.num;
        var NumOfNextSubCategory =
          cat[value.catIndex]?.sub[value.subcategoryAfterIndex + 1]?.num;
        value.positionSelected =
          (NumOfSelectedSubCategory + NumOfNextSubCategory) / 2;
      }
    }
  };
  ///////////////////////Validation//////////////////////////////////
  const CatValidation = () => {};
  const SubCatValidation = () => {};
  const BrandValidation = () => {};

  //////////Crating Category, Subcategory,And Brand//////////
  const [showCatCreate, setShowCatCreate] = useState(false);
  const [showSubCatCreate, setShowSubCatCreate] = useState(false);
  const [showBrandCreate, setShowBrandCreate] = useState(false);

  const handleOpenCatCreate = () => setShowCatCreate(true);
  const handleOpenSubCatCreate = () => setShowSubCatCreate(true);
  const handleOpenBrandCreate = () => setShowBrandCreate(true);

  const handleCloseCatCreate = () => setShowCatCreate(false);
  const handleCloseSubCatCreate = () => {
    setShowSubCatCreate(false);
    setValue({
      ...value,
      categoryname: '',
      category: '',
      subcategoryname: '',
      subcategory: '',
      first: false,
      subcategoryAfter: null,
      formType: null,
      positionSelected: '',
      positionSelectedindex: '',
      catIndex: '',
      subcategoryAfterIndex: null,
      type: true,
    });
    setChipData([]);
  };
  const handleCloseBrandCreate = () => {
    setShowBrandCreate(false);
    setbrand({
      ...brand,
      brandName: '', //Brand name
      brandNameId: '',
      brandPosition: '',
      subcategory: '',
      indexOfCategory: '',
      indexOfSubCategory: '',
      indexOfBrand: '',
      brandAfter: null,
      brandFirst: false,
      brandAfterIndex: null,
      origin: '',
    });
  };
  const categoryName = useRef();
  const CreateCategory = async () => {
    if (!categoryName.current.value.length > 0) {
      return seterror('Enter category name');
    }
    setloading(true);
    value.categoryname = categoryName.current.value;
    let check = await axios
      .post('/api/Admin/CheckCategorys', { value })
      .then((res) => {
        if (res.status == 200) {
          if (!res.data) {
            let sab = axios
              .post('/api/Admin/AddCategorys', { value })
              .then((res) => {
                if (res.status == 200) {
                  notify('Successfully added', true);
                  categoryName.current.value = '';
                  reload();
                  handleCloseCatCreate();
                  seterror('');
                  setloading(false);
                }
              })
              .catch((err) => {
                notify(' Something went wrong', false);
              });
            setloading(false);
          } else {
            seterror('Category already exists');
          }
        }
      })
      .catch((err) => {
        notify(' Something went wrong', false);
        setloading(false);
      });
  };

  const CreateSUBCAT = (id, index) => {
    sabari(id);
    handleOpenSubCatCreate();
    setValue({ ...value, category: id, catIndex: index });
  };

  const CreateSubCategory = async (e) => {
    e.preventDefault();
    value.subcategoryname = e.target.elements.subcategoryname.value;
    value.first = e.target.elements.first.checked;
    value.subcategoryAfter = e.target.elements.subcategoryAfter.value;
    value.formType = e.target.elements.formType.value;
    value.book_a_table = e.target.elements.book_a_table.checked;
    value.book_an_appointment = e.target.elements.book_an_appointment.checked;
    value.place_an_order = e.target.elements.place_an_order.checked;
    value.make_a_purchase = e.target.elements.make_a_purchase.checked;
    value.chipData = chipData;
    value.Static_status = e.target.elements.Static_status.checked;
    value.description = e.target.elements.description.value;
    if (!e.target.elements.subcategoryname.value) {
      return seterror('Enter sub category name');
    }
    seterror('');
    if (
      !e.target.elements.first.checked &&
      !e.target.elements.subcategoryAfter.value
    ) {
      return seterror('Select position');
    }
    if (!e.target.elements.formType.value) {
      return seterror('Select form type');
    }
    seterror('');
    SubcategoryPosition();
    setloading(true);
    // return
    let check = axios
      .post('/api/Admin/CheckSubCategorys', value)
      .then((res) => {
        if (res.status == 200) {
          if (!res.data) {
            let sab = axios
              .post('/api/Admin/AddSubCategorys', value)
              .then((res) => {
                if (res.status == 200) {
                  notify('Successfully added', true);
                  handleCloseSubCatCreate();
                  setValue({
                    categoryname: '',
                    category: '',
                    subcategoryname: '',
                    subcategory: '',
                    first: false,
                    subcategoryAfter: null,
                    formType: null,
                    positionSelected: '',
                    positionSelectedindex: '',
                    catIndex: '',
                    subcategoryAfterIndex: null,
                    type: true,
                    book_a_table: false,
                    book_an_appointment: false,
                    place_an_order: false,
                    make_a_purchase: false,
                    Static_status: false,
                    description: '',
                  });
                  seterror('');
                  setChipData([]);
                  setloading(false);
                  reload();
                }
              })
              .catch((err) => {
                notify(' Something went wrong', false);
              });
            setloading(false);
          } else {
            seterror('Sub Category already exists');
          }
        }
      })
      .catch((err) => {
        notify(' Something went wrong', false);
        setloading(false);
      });
  };

  const CreateBran = (index, index1, subId) => {
    setbrand({
      ...brand,
      indexOfCategory: index,
      indexOfSubCategory: index1,
      subcategory: subId,
    });
    if (cat[index]?.sub[index1]?.brand1.length > 0) {
      var barndList = cat[index].sub[index1].brand1.map((elements) => {
        return <option value={elements._id}>{elements.brand}</option>;
      });
      setBrandList(barndList);
    }
    handleOpenBrandCreate();
  };

  const CreateBrand = (e) => {
    brand.brandName = e.target.elements.brandName.value;
    brand.brandAfter = e.target.elements.brandAfter.value;
    brand.brandFirst = e.target.elements.brandFirst.checked;
    brand.origin = e.target.elements.origin.value;

    e.preventDefault();

    if (!e.target.elements.brandName.value) {
      return seterror('Enter brand name');
    }
    if (
      !e.target.elements.brandAfter.value &&
      e.target.elements.brandFirst.checked == false
    ) {
      return seterror('Select brand position');
    }
    BrandPosition();
    setloading(true);
    let check = axios
      .post('/api/Admin/CheckBrand', brand)
      .then((res) => {
        if (res.status == 200) {
          if (!res.data) {
            let sab = axios
              .post('/api/Admin/ConsBrandAdd', brand)
              .then((res) => {
                if (res.status == 200) {
                  notify('Successfully added', true);
                  handleCloseBrandCreate();
                  setbrand({
                    categoryname: '',
                    category: '',
                    subcategoryname: '',
                    subcategory: '',
                    first: false,
                    subcategoryAfter: null,
                    formType: null,
                    positionSelected: '',
                    positionSelectedindex: '',
                    catIndex: '',
                    subcategoryAfterIndex: null,
                    type: true,
                    origin: '',
                  });
                  seterror('');
                  setloading(false);
                  reload();
                }
              })
              .catch((err) => {
                notify(' Something went wrong', false);
              });
            setloading(false);
          } else {
            setloading(false);
            seterror('Sub Category already exists');
          }
        }
      })
      .catch((err) => {
        notify(' Something went wrong', false);
        setloading(false);
      });
  };

  /////////////////////Chip///////////////
  const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  const [chipData, setChipData] = React.useState([]);

  const [chipRef, setChipref] = React.useState({
    label: '',
    key: '',
  });

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };
  const first = useRef();
  const first1 = useRef();
  function AddChip() {
    let temp = { label: first.current.value, key: Math.random() };
    setChipData([...chipData, temp]);
    // setChipref({

    //   label:"",
    //   key:""
    // })
    first.current.value = '';
  }
  function AddChip1() {
    let temp = { label: first1.current.value, key: Math.random() };
    setChipData([...chipData, temp]);
    // setChipref({

    //   label:"",
    //   key:""
    // })
    first1.current.value = '';
  }
  const [pno, setpno] = useState(1);
  // const rowper = 6
  const [rowper, setrowper] = useState(6);
  //////////////////////////////////////////////////////////
  const [HideAndUnhideCatLoading, setHideAndUnhideCatLoading] = useState(false);
  const HideAndUnhide = (category, id, status) => {
    var value = { categoryname: category, category: id, status: status };
    setHideAndUnhideCatLoading(true);

    let sab = axios
      .post('/api/Admin/HideAndUnhideCat', value)
      .then((res) => {
        if (res.status == 200) {
          notify('Successfully updated', true);
          setHideAndUnhideCatLoading(false);
          reload();
        }
      })
      .catch((err) => {
        notify(' Something went wrong', false);
        setHideAndUnhideCatLoading(false);
      });
  };

  const [HideAndUnhideSubCatLoading, setHideAndUnhideSubCatLoading] =
    useState(false);
  const HideAndUnhideSubCat = (cat_id, sub_id, status) => {
    var value = {
      category: cat_id,
      subcategory: sub_id,
      status: status,
    };
    setHideAndUnhideSubCatLoading(true);

    let sab = axios
      .post('/api/Admin/HideAndUnhideSubCat', value)
      .then((res) => {
        if (res.status == 200) {
          notify('Successfully updated', true);
          setHideAndUnhideSubCatLoading(false);
          reload();
        }
      })
      .catch((err) => {
        notify(' Something went wrong', false);
        setHideAndUnhideSubCatLoading(false);
      });
  };

  return (
    <div className="m-3 ms-3 ">
      <div className="d-flex justify-content-end mb-3">
        <button
          className="btn pro-spaces-button3"
          onClick={(e) => handleOpenCatCreate()}
        >
          Add Category
        </button>
      </div>
      <div className="container-fluid p-1">
        <input
          type={'number'}
          style={{ width: '40px' }}
          value={rowper}
          onChange={(e) => {
            setrowper((rev) => parseInt(e.target.value));
          }}
        ></input>
        <span>
          &nbsp;displaying{' '}
          {rowper * (pno - 1) === 0 ? '1' : rowper * (pno - 1) + 1} -{' '}
          {rowper * pno > cat.length ? cat.length : rowper * pno} of{' '}
          {cat.length}{' '}
        </span>
        <button
          className="btn btn-info"
          disabled={pno === 1}
          onClick={(e) => setpno((pre) => pre - 1)}
        >
          <BiLeftArrow />
        </button>
        &nbsp;
        <button
          disabled={cat.length < rowper * pno}
          className="btn btn-info"
          onClick={(e) => setpno((pre) => pre + 1)}
        >
          <BiRightArrow />
        </button>
      </div>
      {cat.map((e, index1) => {
        if (
          index1 < rowper * pno &&
          index1 >= (pno - 1 === 0 ? 0 : rowper * (pno - 1))
        )
          return (
            <>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <b>{e.category + '  ( ' + e.sub.length + ' ) '}</b>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="row px-1 py-3 ">
                      <div className="col-12 mt-2 p-0">
                        <b>{e.category}</b>&nbsp;&nbsp;&nbsp;
                        <button
                          className="btn pro-spaces-button3"
                          onClick={(ef) => handleShowCat(e.category, e._id)}
                        >
                          Edit
                        </button>
                        &nbsp;&nbsp;&nbsp;
                        {!e.Hide_status ? (
                          HideAndUnhideCatLoading ? (
                            <button className="btn pro-spaces-button3" disabled>
                              Hide
                            </button>
                          ) : (
                            <button
                              className="btn pro-spaces-button3"
                              onClick={(ef) =>
                                HideAndUnhide(e.category, e._id, true)
                              }
                            >
                              Hide
                            </button>
                          )
                        ) : HideAndUnhideCatLoading ? (
                          <button className="btn pro-spaces-button3" disabled>
                            Unhide
                          </button>
                        ) : (
                          <button
                            className="btn pro-spaces-button3"
                            onClick={(ef) =>
                              HideAndUnhide(e.category, e._id, false)
                            }
                          >
                            Unhide
                          </button>
                        )}
                      </div>
                      {/* <div className="col-4 mt-2"><button className="btn pro-spaces-button3" onClick={(ef)=>handleShowCat(e.category,e._id)}>Edit</button></div> */}
                    </div>
                    <div className="d-flex justify-content-end mb-3 ">
                      <button
                        className="btn pro-spaces-button3"
                        onClick={(es) => CreateSUBCAT(e._id, index1)}
                      >
                        Add Subcategory
                      </button>{' '}
                    </div>
                    <TableContainer component={Paper}>
                      <Table aria-label="collapsible table">
                        <TableHead>
                          <TableRow>
                            <TableCell />
                            <TableCell>No.</TableCell>
                            <TableCell align="left">Sub category</TableCell>
                            <TableCell align="left">Form type </TableCell>
                            <TableCell align="left">Positions </TableCell>
                            <TableCell align="left">Button </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {e.sub.map((row, index) => {
                            // if(index<1){
                            return (
                              <Row
                                key={index}
                                row={row}
                                id={e._id}
                                index={index}
                                index1={index1}
                              />
                            );

                            // }
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* <hr className="my-4"/> */}
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <br />
            </>
          );
      })}

      {/* //////////Creating//////////// */}
      <Modal
        show={showCatCreate}
        onHide={handleCloseCatCreate}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col">Category</div>
            <div className="col">
              <input
                className="form-control"
                type="text"
                defaultValue={value.categoryname}
                ref={categoryName}
              ></input>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p style={{ color: 'red' }}>{error ? error : ''}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCatCreate}>
            Close
          </Button>

          {!loading && (
            <button
              className="btn btn-success"
              onClick={(e) => CreateCategory(e)}
            >
              Add
            </button>
          )}
          {loading && (
            <button className="btn btn-success" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={showSubCatCreate}
        onHide={handleCloseSubCatCreate}
        centered
        size="lg"
      >
        <form onSubmit={(e) => CreateSubCategory(e)}>
          <Modal.Header closeButton>
            <Modal.Title>Add Subcategory</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <br></br>
            <div className="row">
              <div className="col-4"> Sub-Category</div>
              <div className="col-4">
                <input
                  className="form-control"
                  type="text"
                  defaultValue={value.subcategoryname}
                  // onChange={(e) =>
                  //   setValue({ ...value, subcategoryname: e.target.value })
                  // }
                  name="subcategoryname"
                ></input>
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                {' '}
                <label className="labels">After</label>
              </div>
              <div className="col-4">
                <select
                  className="form-select"
                  defaultValue={value.subcategoryAfter}
                  onChange={(e) =>
                    setValue({
                      ...value,
                      subcategoryAfterIndex: e.target.selectedIndex,
                    })
                  }
                  name="subcategoryAfter"
                >
                  <option value="" disabled selected>
                    Select your sub category
                  </option>
                  {subcategory ? subcategory : ''}
                </select>
              </div>
              <div className="col-4">
                <label className="labels">First subcategory</label>
                &nbsp;&nbsp;&nbsp;
                {value.first ? (
                  <input
                    type="checkbox"
                    defaultValue={false}
                    className="form-check-input "
                    defaultChecked
                    // onChange={(e) =>
                    //     setValue({ ...value, first: (!value.first) })
                    //   }
                    name="first"
                  ></input>
                ) : (
                  <input
                    type="checkbox"
                    defaultValue={false}
                    className="form-check-input "
                    // onChange={(e) =>
                    //   setValue({ ...value, first: (!value.first) })
                    // }
                    name="first"
                  ></input>
                )}
              </div>
            </div>

            <>
              <div className="row">
                <div className="col-4">Form Type</div>
                <div className="col-4">
                  {/* onChange={(e) => setValue ({ ...value, formType: e.target.value })}  */}
                  <select
                    name="formType"
                    defaultValue={value.formType}
                    className="form-select"
                  >
                    <option value={0}>Select form type</option>
                    <option value={1}>A</option>
                    <option value={2}>B</option>
                    <option value={3}>C</option>
                    <option value={4}>D</option>
                    <option value={5}>E</option>
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-4">Description</div>
                <div className="col-4">
                  <textarea
                    name="description"
                    defaultValue={value.description}
                    className="form-control"
                  ></textarea>
                </div>
              </div>

              <hr></hr>

              <div className="row">
                <div className="col-4">Key Words</div>
                <div className="col-4">
                  <input
                    className="form-control"
                    type="text"
                    // value={chipRef.label}
                    // onChange={(e) =>
                    //   setChipref({ ...chipRef, label: e.target.value, key: Math.random() })
                    // }
                    // name="label"
                    ref={first}
                  ></input>
                </div>
                <div className="col-4">
                  <button
                    type="button"
                    className="btnbtn pro-spaces-button3 "
                    onClick={(e) => AddChip()}
                  >
                    Add
                  </button>
                </div>
              </div>
              {chipData ? (
                chipData.length > 0 ? (
                  <Paper
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexWrap: 'wrap',
                      listStyle: 'none',
                      p: 0.5,
                      m: 0,
                    }}
                    component="ul"
                  >
                    {chipData.map((data) => {
                      let icon;

                      if (data.label === 'React') {
                        icon = <TagFacesIcon />;
                      }

                      return (
                        <ListItem key={data?.key}>
                          <Chip
                            icon={icon}
                            label={data?.label}
                            onDelete={
                              data?.label === 'React'
                                ? undefined
                                : handleDelete(data)
                            }
                          />
                        </ListItem>
                      );
                    })}
                  </Paper>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              <br></br>
              <div className="row">
                <div className="col-4">
                  DISABLE Basic&nbsp;&nbsp;&nbsp;{' '}
                  <input
                    type="checkbox"
                    name="Static_status"
                    className="form-check-input "
                    defaultValue={value.Static_status}
                    //  onChange={(e) =>setValue({ ...value, book_an_appointment: (!value.book_an_appointment) })}
                  ></input>
                </div>
              </div>
              <hr></hr>
              <div className="row">
                <div className="col-4">
                  BOOK AN APPOINTMENT&nbsp;&nbsp;&nbsp;{' '}
                  <input
                    type="checkbox"
                    name="book_an_appointment"
                    className="form-check-input "
                    defaultValue={value.book_an_appointment}
                    //  onChange={(e) =>setValue({ ...value, book_an_appointment: (!value.book_an_appointment) })}
                  ></input>
                </div>
                <div className="col-4">
                  BOOK A TABLE &nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    className="form-check-input "
                    name="book_a_table"
                    defaultValue={value.book_a_table}
                    // onChange={(e) =>   setValue({ ...value, book_a_table: (!value.book_a_table) }) }
                  ></input>{' '}
                </div>
                <div className="col-4">
                  PLACE AN ORDER &nbsp;&nbsp;&nbsp;{' '}
                  <input
                    type="checkbox"
                    className="form-check-input "
                    name="place_an_order"
                    defaultValue={value.place_an_order}
                    // onChange={(e) =>setValue({ ...value, place_an_order: (!value.place_an_order) }) }
                  ></input>
                </div>
                <div className="col-4">
                  MAKE A PURCHASE &nbsp;&nbsp;&nbsp;{' '}
                  <input
                    type="checkbox"
                    className="form-check-input "
                    name="make_a_purchase"
                    defaultValue={value.make_a_purchase}
                    // onChange={(e) => setValue({ ...value, make_a_purchase: (!value.make_a_purchase) })}
                  ></input>{' '}
                </div>
              </div>
            </>
            <br />
            <div className="row">
              <div className="col">
                <p style={{ color: 'red' }}>{error ? error : ''}</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseSubCat}>
              Close
            </Button>

            {!loading && (
              <button type="submit" className="btn btn-success">
                Add
              </button>
            )}
            {loading && (
              <button className="btn btn-success" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            )}
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        show={showBrandCreate}
        onHide={handleCloseBrandCreate}
        centered
        size="lg"
      >
        <form onSubmit={(e) => CreateBrand(e)}>
          <Modal.Header closeButton>
            <Modal.Title>Brand</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <br></br>
            <div className="row">
              <div className="col-4">Brand</div>
              <div className="col-4">
                <input
                  className="form-control"
                  type="text"
                  name="brandName"
                  defaultValue={brand.brandName}
                  // onChange={(e) =>
                  //   setbrand({ ...brand, brandName: e.target.value })
                  // }
                ></input>
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-4">
                {' '}
                <label className="labels">After</label>
              </div>
              <div className="col-4">
                <select
                  className="form-select"
                  defaultValue={brand.brandAfter}
                  name="brandAfter"
                  onChange={(e) =>
                    setbrand({
                      ...brand,
                      brandAfterIndex: e.target.selectedIndex,
                    })
                  }
                >
                  <option value="" disabled selected>
                    Select your brand
                  </option>
                  {BrandList ? BrandList : ''}
                </select>
              </div>
              <div className="col-4">
                <label className="labels">First brand</label>
                &nbsp;&nbsp;&nbsp;
                {brand.brandFirst ? (
                  <input
                    type="checkbox"
                    className="form-check-input "
                    defaultChecked
                    name="brandFirst"
                    //  onChange={(e) =>
                    //     setbrand({ ...brand, brandFirst: (!brand.brandFirst) })
                    //   }
                  ></input>
                ) : (
                  <input
                    type="checkbox"
                    className="form-check-input "
                    // onChange={(e) =>
                    //   setbrand({ ...brand, brandFirst: (!brand.brandFirst) })
                    // }
                    name="brandFirst"
                  ></input>
                )}
              </div>
            </div>
            <br />
            <br />
            {/* <div className='row'>
            <div className='col-4'>position</div><div className='col-4'><input className="form-control" type="text"  value={brand.brandPosition}
                    onChange={(e) =>
                      setbrand({ ...brand, brandPosition: e.target.value })
                    }></input></div>
        </div><br />
        <br /> */}
            <div className="row">
              <div className="col-4">Origin</div>
              <div className="col-4">
                <select
                  className="form-select"
                  defaultValue={brand.origin}
                  name="origin"
                >
                  <option>none</option>
                  <option>Indian</option>
                  <option>Global</option>
                </select>
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col">
                <p style={{ color: 'red' }}>{error ? error : ''}</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseBrand}>
              Close
            </Button>

            {!loading && (
              <button className="btn btn-success" type="submit">
                Add
              </button>
            )}
            {loading && (
              <button className="btn btn-success" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            )}
          </Modal.Footer>
        </form>
      </Modal>

      {/* ///////////////Updating //////////////// */}
      <Modal show={showCat} onHide={handleCloseCat} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Update Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Category:{Category.category} */}
          <div className="row">
            <div className="col">Edit category</div>
            <div className="col">
              <input
                className="form-control"
                type="text"
                defaultValue={value.categoryname}
                ref={editcategory}
              ></input>
            </div>
            {/* <div className="col">  <div className="col">
                 
                    </div></div> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCat}>
            Close
          </Button>

          {!loading && (
            <button
              className="btn btn-success"
              onClick={(e) => EditCategorys(e)}
            >
              Update
            </button>
          )}
          {loading && (
            <button className="btn btn-success" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal show={showSubCat} onHide={handleCloseSubCat} centered size="lg">
        <form onSubmit={(e) => EditSubCategorys(e)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Subcategory</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <br></br>
            <div className="row">
              <div className="col-4">Edit Sub-Category</div>
              <div className="col-4">
                <input
                  className="form-control"
                  type="text"
                  defaultValue={value.subcategoryname}
                  // onChange={(e) =>
                  //   setValue({ ...value, subcategoryname: e.target.value })
                  // }
                  name="subcategoryname"
                ></input>
              </div>
            </div>

            <div className="row">
              <div className="col-4">
                {' '}
                <label className="labels">After</label>
              </div>
              <div className="col-4">
                <select
                  className="form-select"
                  defaultValue={value.subcategoryAfter}
                  onChange={(e) =>
                    setValue({
                      ...value,
                      subcategoryAfterIndex: e.target.selectedIndex,
                    })
                  }
                  name="subcategoryAfter"
                >
                  <option value="" disabled selected>
                    Select your sub category
                  </option>
                  {subcategory ? subcategory : ''}
                </select>
              </div>
              <div className="col-4">
                <label className="labels">First subcategory</label>
                &nbsp;&nbsp;&nbsp;
                {value.first ? (
                  <input
                    type="checkbox"
                    defaultValue={false}
                    className="form-check-input "
                    defaultChecked
                    // onChange={(e) =>
                    //     setValue({ ...value, first: (!value.first) })
                    //   }
                    name="first"
                  ></input>
                ) : (
                  <input
                    type="checkbox"
                    defaultValue={false}
                    className="form-check-input "
                    // onChange={(e) =>
                    //   setValue({ ...value, first: (!value.first) })
                    // }
                    name="first"
                  ></input>
                )}
              </div>
            </div>

            <>
              <div className="row">
                <div className="col-4">Form Type</div>
                <div className="col-4">
                  {/* onChange={(e) => setValue ({ ...value, formType: e.target.value })}  */}
                  <select
                    name="formType"
                    defaultValue={value.formType}
                    style={{ width: '240px' }}
                    className="form-select"
                  >
                    <option value={0}>Select form type</option>
                    <option value={1}>A</option>
                    <option value={2}>B</option>
                    <option value={3}>C</option>
                    <option value={4}>D</option>
                    <option value={5}>E</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-4">Description</div>
                <div className="col-4">
                  <textarea
                    name="description"
                    defaultValue={value.description}
                    className="form-control"
                  ></textarea>
                </div>
              </div>
              <hr></hr>

              <div className="row">
                <div className="col-4">Key Words</div>
                <div className="col-4">
                  <input
                    className="form-control"
                    type="text"
                    ref={first1}
                    // name="laberegl"
                  ></input>
                </div>
                <div className="col-4">
                  <button
                    type="button"
                    className="btnbtn pro-spaces-button3 "
                    onClick={(e) => AddChip1()}
                  >
                    Add
                  </button>
                </div>
              </div>
              {chipData ? (
                chipData.length > 0 ? (
                  <Paper
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexWrap: 'wrap',
                      listStyle: 'none',
                      p: 0.5,
                      m: 0,
                    }}
                    component="ul"
                  >
                    {chipData.map((data) => {
                      let icon;

                      if (data.label === 'React') {
                        icon = <TagFacesIcon />;
                      }

                      return (
                        <ListItem key={data?.key}>
                          <Chip
                            icon={icon}
                            label={data?.label}
                            onDelete={
                              data?.label === 'React'
                                ? undefined
                                : handleDelete(data)
                            }
                          />
                        </ListItem>
                      );
                    })}
                  </Paper>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              <br></br>
              <div className="row">
                <div className="col-4">
                  DISABLE Basic&nbsp;&nbsp;&nbsp;{' '}
                  <input
                    type="checkbox"
                    name="Static_status"
                    className="form-check-input "
                    defaultValue={value.Static_status}
                    defaultChecked={value.Static_status}
                    //  onChange={(e) =>setValue({ ...value, book_an_appointment: (!value.book_an_appointment) })}
                  ></input>
                </div>
              </div>
              <hr></hr>
              <div className="row">
                <div className="col-4">
                  BOOK AN APPOINTMENT&nbsp;&nbsp;&nbsp;{' '}
                  <input
                    type="checkbox"
                    name="book_an_appointment"
                    defaultValue={value.book_an_appointment}
                    className="form-check-input "
                    defaultChecked={value.book_an_appointment}
                    //  onChange={(e) =>setValue({ ...value, book_an_appointment: (!value.book_an_appointment) })}
                  ></input>
                </div>
                <div className="col-4">
                  BOOK A TABLE &nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    className="form-check-input "
                    name="book_a_table"
                    defaultValue={value.book_a_table}
                    defaultChecked={value.book_a_table}
                    // onChange={(e) =>   setValue({ ...value, book_a_table: (!value.book_a_table) }) }
                  ></input>{' '}
                </div>
                <div className="col-4">
                  PLACE AN ORDER &nbsp;&nbsp;&nbsp;{' '}
                  <input
                    type="checkbox"
                    className="form-check-input "
                    name="place_an_order"
                    defaultValue={value.place_an_order}
                    defaultChecked={value.place_an_order}
                    // onChange={(e) =>setValue({ ...value, place_an_order: (!value.place_an_order) }) }
                  ></input>
                </div>
                <div className="col-4">
                  MAKE A PURCHASE &nbsp;&nbsp;&nbsp;{' '}
                  <input
                    type="checkbox"
                    className="form-check-input "
                    name="make_a_purchase"
                    defaultValue={value.make_a_purchase}
                    defaultChecked={value.make_a_purchase}
                    // onChange={(e) => setValue({ ...value, make_a_purchase: (!value.make_a_purchase) })}
                  ></input>{' '}
                </div>
              </div>
            </>
            <br />
            <div className="row">
              <div className="col">
                <p style={{ color: 'red' }}>{error ? error : ''}</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseSubCat}>
              Close
            </Button>

            {!loading && (
              <button type="submit" className="btn btn-success">
                Update
              </button>
            )}
            {loading && (
              <button className="btn btn-success" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            )}
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={ShowBrand} onHide={handleCloseBrand} centered size="lg">
        <form onSubmit={(e) => UpdateBrand(e)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Brand</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <br></br>
            <div className="row">
              <div className="col-4">Edit Brand</div>
              <div className="col-4">
                <input
                  className="form-control"
                  name="brandName"
                  type="text"
                  defaultValue={brand.brandName}
                  // onChange={(e) =>
                  //   setbrand({ ...brand, brandName: e.target.value })
                  // }
                ></input>
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-4">
                {' '}
                <label className="labels">After</label>
              </div>
              <div className="col-4">
                <select
                  className="form-select"
                  defaultValue={brand.brandAfter}
                  name="brandAfter"
                  onChange={(e) =>
                    setbrand({
                      ...brand,
                      brandAfterIndex: e.target.selectedIndex,
                    })
                  }
                >
                  <option value="" disabled selected>
                    Select your brand
                  </option>
                  {BrandList ? BrandList : ''}
                </select>
              </div>
              <div className="col-4">
                <label className="labels">First brand</label>
                &nbsp;&nbsp;&nbsp;
                {brand.brandFirst ? (
                  <input
                    type="checkbox"
                    className="form-check-input "
                    name="brandFirst"
                    defaultChecked
                    // onChange={(e) =>
                    //     setbrand({ ...brand, brandFirst: (!brand.brandFirst) })
                    //   }
                  ></input>
                ) : (
                  <input
                    type="checkbox"
                    className="form-check-input "
                    name="brandFirst"
                    //   onChange={(e) =>
                    //   setbrand({ ...brand, brandFirst: (!brand.brandFirst) })
                    // }
                  ></input>
                )}
              </div>
            </div>
            <br />
            <br />
            {/* <div className='row'>
            <div className='col-4'>position</div><div className='col-4'><input className="form-control" type="text"  defaultValue={brand.brandPosition}
                    onChange={(e) =>
                      setbrand({ ...brand, brandPosition: e.target.value })
                    }></input></div>
        </div><br />
        <br />
        <br /> */}
            <div className="row">
              <div className="col-4">Origin</div>
              <div className="col-4">
                <select
                  className="form-select"
                  defaultValue={brand.origin}
                  name="origin"
                >
                  <option>none</option>
                  <option>Indian</option>
                  <option>Global</option>
                </select>
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col">
                <p style={{ color: 'red' }}>{error ? error : ''}</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseBrand}>
              Close
            </Button>

            {!loading && (
              <button type="submit" className="btn btn-success">
                Update
              </button>
            )}
            {loading && (
              <button className="btn btn-success" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            )}
          </Modal.Footer>
        </form>
      </Modal>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default NewConsultantCategory;
