import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiReset,BiRupee,BiDotsVerticalRounded, } from "react-icons/bi";

import { print_state, print_city, state_arr } from '../../../Utility/stateCity'
import Spinner from 'react-bootstrap/Spinner';
import { Link } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { GrMap,  } from "react-icons/gr";
import { AiOutlineLink,  } from "react-icons/ai";
import Table from 'react-bootstrap/Table';
import { DataGrid } from '@mui/x-data-grid';



export function Vegetables(props) {
  useEffect(() => {
    // props.Auth();
  }, []);

  const [show, setShow] = useState(false);
const [Id, setId] = useState();
const handleShow = () => setShow(true);

  const handleClose = () =>{
    setShow(false);
    setId('')

  } 

  const [load, setload] = useState(false);
  const [error, seterror] = useState("");
  const [output, setoutput] = useState([]);
  const [Keyer, setKeyer] = useState(1)
  const [Stateload, setStateload] = useState(true);
  const [rows, setrows] = useState([])

  const [value, setValue] = useState({
    state: "",
    city: "",
    pincode:"",
    // WE:false
  });

  function Cityshow(e) {
    var index = document.getElementById(e.target.id).selectedIndex;
    print_city("City", index);
    setValue({ ...value, state: e.target.value });
  }
  useEffect(() => {
    if (Stateload) print_state("State");
    setStateload(false);
  }, []);

  const vaid = () => {
    if (value.state.length > 0) {
      seterror("");
    } else {
      return seterror("Please select a state");
    }
    if (value.city.length > 0) {
      seterror("");
    } else {
      return seterror("Please select a city");
    }
    return true;
  };
  const Reset = (e) => {
    document.getElementById("searchForm").reset();
    setValue({ ...value, city: "", state: "", WE: !value.WE, pincode: "" });
    // Club();
    GetVegetables()
    
  };
  const GetVegetables = (last) => {
    setload(true)
    console.log('page')
    axios.get("/api/User/GetVegetables").then((response) => {
      setrows([])
        setload(false)
        console.log('heree')
        console.log(response)
        response.data.map((e,index)=>{
          setrows(prevRows => [...prevRows, {...e, id: index+1}])
        })
        setoutput(response.data)
    }).catch(
        (res) => {
            setload(false)
            console.log('Something went wrong')
           
        }
    )
   
}

useEffect(() => {
    GetVegetables()
}, [])

const FindVegetables = (last) => {
    // console.log(page)
    setload(true)

    axios.post("/api/User/FindVegetables",{value}).then((response) => {
      setrows([])
        setload(false)
        console.log('first')
        console.log(response.data)
        setoutput(response.data)
        response.data.map((e,index)=>{
          setrows(prevRows => [...prevRows, {...e, id: index+1}])
        })
    }).catch(
        (res) => {
            setload(false)
            console.log('Something went wrong')
          
        }
    )
   
}
const [index, setindex] = useState()
const [Ids, setIds] = useState()

function stepDelete(Id,index) {
  setIds(Id)
  setindex(index)
  handleShow()
}
const Delete=()=>{
  console.log(Id)
  var id = Ids
  axios.post("/api/Admin/Vegetables_Delete", { id }).then((response) => {
      // console.log('success')
      if (response.status === 200) {
          //   console.log("reported11")
          // setreported(true)
          // window.location.reload();
          setrows([])
          GetVegetables()
          let temp = [...output]
          temp.splice(index, 1)
          setindex(undefined)
          setId('')
          setoutput(temp)
          handleClose()

      }
  }).catch(
      res => {
          console.log('Something went wrong')
      }
  )
}

const columns = [
  { field: 'id', headerName: 'No .', width: 70,  headerAlign: 'center',
  align: 'center' },
  { field: 'State', headerName: 'State', width: 150,  headerAlign: 'center',
  align: 'center' },
  { field: 'City', headerName: 'City', width: 150,  headerAlign: 'center',
  align: 'center' },
  {
    field: 'Vegetable_name',
    headerName: 'Vegetable',
    type: 'number',
    width: 150,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Prices',
    headerName:` (\u20B9)/Kg`,
    // sortable: false,
    width: 150,
    type: 'number',  headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Edit',
    headerName: 'Update',
    sortable: false,
    width: 90,  headerAlign: 'center',
    align: 'center',
    renderCell: (params) => (
      <Link to={"/vegetables_update?pi="+params.row._id}>
        <button className="btn pro-spaces-button3" >
           Update
        </button>
      </Link>
    ),
  },
  {
    field: 'Delete',
    headerName: 'Delete',
    sortable: false,
    width: 90,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => (
      <button className="btn btn-danger" onClick={(e)=>stepDelete(params.row._id,params.row.id-1)}>
        Delete
      </button>
    ),
  },
];

  return (
    <div className="container-fluid bg-trasparent pro-sabari wieugfiqgefnqenc2rnewiuhgiuhq ">

<div> <div className="d-flex justify-content-end">
<Link to="/vegetables_create">
    <button className="btn btn-dosabutton ps-3 pe-3 mb-3 deghievgviehcieh" >Add New</button>
    </Link>
       </div>
       </div>

     
      <div id="AdvancedSearch" className=" row bg-light  p-2 mt-1">
        <div>
          <p>Advanced Search Filters</p>
        </div>
        <form id="searchForm">
          <div className="col-12 ">
            <div className="row g-1">
              <div className="col-6 col-lg-4">
                <div className="form-floating">
                <select
                  className="form-select   "
                  value={value.state}
                  id="State"
                  name="City"
                  onChange={(e) => Cityshow(e)}
                ></select>
                 <label htmlFor="State">State</label>
                 </div>
              </div>
              <div className="col-6 col-lg-4">
              <div className="form-floating">
                <select
                  className="form-select   "
                  name="City"
                  value={value.city}
                  id="City"
                  onChange={(e) => setValue({ ...value, city: e.target.value })}
                ></select>
                <label htmlFor="City">City</label>
                </div>
              </div>
           
              <div className="col-12 col-md-12 mb-1 mt-2 col-lg-4">
                {/* <div className="form-floating">
                <input
                  type="number"
                  className="form-control"
                  name="pin"
                  value={value.pincode}
                  id="a"
                  onChange={(e) =>
                    setValue({ ...value, pincode: e.target.value })
                  }
                ></input>
                <label htmlFor="a">Pin&nbsp;code</label>
                </div> */}
                <div className=" d-flex justify-content-end">
                <div className="   me-2 ">
                <button
                  className="btn btnjui ps-4 pe-4 d-flex justify-content-center"
                  type="button"
                  onClick={(e) => Reset(e)}
                >
                  <BiReset size={24} />
                </button>
              </div>
              <button
                type="button"
                className="btn btnjui "
                onClick={(e) => FindVegetables()}
              >
                Go
              </button>
                </div>

              </div>

            </div>
          </div>
          <div className="col-12 col-md-12 mb-1 mt-2">
            {/* <div className=" d-flex justify-content-end">
              <div className="   me-2 ">
                <button
                  className="btn btnjui ps-4 pe-4 d-flex justify-content-center"
                  type="button"
                  onClick={(e) => Reset(e)}
                >
                  <BiReset size={24} />
                </button>
              </div>
              <button
                type="button"
                className="btn btnjui "
                onClick={(e) => FindAmbuianceAnimal()}
              >
                Go
              </button>
            </div> */}

            <span style={{ color: "red" }}>{error ? error : ""}</span>
          </div>
        </form>
      </div>

      <div className="row car"> 
      {
          load?
             <center>
                  <Spinner animation="border" />
             </center>
           : 
           output.length>0?
           <div className="container bg-light">
     
  <>
       <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}

        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // checkboxSelection
      />
    </div>

        </>
      <></>
           </div>
           :
           <></>
      }

      {
        output.length>0?<></>:<h3><center>No results found !!</center></h3>
      }
      </div>
    
    


      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
                <Modal show={show} onHide={handleClose} backdrop={false} id={index} aria-labelledby="contained-modal-title-vcenter"centered >
        <Modal.Header closeButton>
          <Modal.Title>Deleting post </Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
      
          <button className="btn pro-spaces-button3" onClick={(e)=>Delete()}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
{/* <Modal show={show} onHide={handleClose} backdrop={false} id={index} aria-labelledby="contained-modal-title-vcenter"centered >
<Modal.Header closeButton>
  <Modal.Title>Deleting post </Modal.Title>
</Modal.Header>
<Modal.Body>Are you sure?</Modal.Body>
<Modal.Footer>

  <button className="btn pro-spaces-button3" onClick={(e)=>Delete(elem._id)}>
    Delete
  </button>
</Modal.Footer>
</Modal>
</> */}