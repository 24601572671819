import React, { useRef, useState, useEffect, useContext } from 'react'
import { AiFillDelete } from 'react-icons/ai'
import { BsFillPlayFill, BsPauseFill } from 'react-icons/bs'
import { TbReplace } from 'react-icons/tb'
import { keyGen } from '../../Utility/helper'
import CropperModal from '../CropperModal/CropperModal'
import { Modal } from 'react-bootstrap'
import axios from "axios";
import { SpinnerSmall, SpinnerSmallTransparent } from '../../Utility/spinner'
import { notify } from '../../Utility/notify'
import Archive from './archive'
import { AddPayment } from '../../Pages/Payment/PayForAd'
import { AdContext } from './Advertisementt'
import { useNavigate, useSearchParams } from 'react-router-dom'

let metadata = {
    type: 'image/png'
};
const Rotational = () => {

    let [searchParams, setSearchParams] = useSearchParams();  
    var Account_Id = searchParams.get("a")
    var Login_Id = searchParams.get("l")

    let adContext = useContext(AdContext)
    let pay = adContext.Pay
    const [call, setcall] = useState(null) // delete image id
    const [show, setShow] = useState(false) // delete_warning modal state
    const [actionLoading, setactionLoading] = useState(false)
    const [Error, setError] = useState('')
    const [frequency, setFrequency] = useState(1)

    const handleClose = () => setShow(false); //delete_warning modal handle
    const handleShow = (ev) => {
        setcall(ev)
        setShow(true)
    };  //delete_warning modal handle

    const [archiveShow, setarchiveShow] = useState(false)
    const handleArchiveShow = () => {
        setarchiveShow(true)
    }
    const handleArchiveHide = () => {
        setarchiveShow(false)
    }
    const [PageLoading, setPageLoading] = useState(true) // PageLoading state
    const [Edit, setEdit] = useState(false) // Edit state
    const [Replace, setReplace] = useState(null) // Replace image id
    const load = () => {
        return new Promise((resolve, reject) => {


            setactionLoading(true)
            axios.post(`/api/User/RotationalAdd`,{Account_Id}).then((res) => {
                setPageLoading(false)
                if (res.data) {
                    console.log(res.data.File);
                    setImage([...res.data.File])
                    setEdit(true)
                    setFrequency(res.data?.frequency ? res.data?.frequency : 1)

                }
            }).catch((err) => {
                console.log(err);
                setPageLoading(false)

            }).finally(() => {
                setactionLoading(false)
                resolve()
            })
        })

    }
    useEffect(() => {
        // initial loading of previous rotational add
        load()
    }, [])

    const fileInputRef = useRef() // input File ref
    const [toCrop, settoCrop] = useState(null)  // state for croped image
    const [Image, setImage] = useState([]) // state for image 
    const validation = () => {
        if (Image.length == 0) {
            return setError("Upload an image !")
        }
        setError("")
        return true
    }
    const submit = (e) => {
        e.preventDefault()
        if (validation()!=true) {
            return
        }
        if (!pay) {
            return handlePayOpen()
        }
        // FN for post and put 
        var dataSend = frequency
        let formData = new FormData()
        if (Edit) {
            // New add code
            let FileData = []
            let count = 0
            Image.map((elem) => {
                console.log(elem)
                if (elem.link) {
                    if (!elem.adminStatus) {
                        let fileName = `Rotational_prochure_${keyGen() + Date.now()}.png`

                        FileData = [...FileData, { fileName: fileName, data: elem, status: elem.status }]
                    }
                    count++
                } else {
                    let fileName = `Rotational_prochure_${keyGen() + Date.now()}.png`
                    formData.append('file', new File([elem.File], fileName, metadata))
                    FileData = [...FileData, { fileName, status: elem.status }]
                }
            })
            let imageLength = count
            formData.append('Data', JSON.stringify(FileData))
            formData.append("frequency", dataSend);
            formData.append("Account_Id", Account_Id);
            formData.append('length', JSON.stringify(imageLength))
            axios.put(`/api/User/RotationalAdd`, formData).then(async (res) => {
                if (res.data) {
                    console.log(res.data);
                }
                let s = await load()
                notify("Updated", true)
            }).catch((err) => {
                console.log(err);
                notify("Something went wrong !", false)

            })
        } else {
            //  add update code

            let FileData = []
            Image.map((elem) => {
                if (elem.link) {
                    let fileName = `Rotational_prochure_${keyGen() + Date.now()}.png`

                    FileData = [...FileData, { fileName: fileName, data: elem, status: elem.status }]
                } else {
                    let fileName = `Rotational_prochure_${keyGen() + Date.now()}.png`
                    formData.append('file', new File([elem.File], fileName, metadata))
                    FileData = [...FileData, { fileName, status: elem.status }]
                }
            })
            formData.append('Data', JSON.stringify(FileData))
            formData.append("frequency", dataSend);
            formData.append("Account_Id", Account_Id);
            axios.post(`/api/User/RotationalAddPost`, formData).then(async (res) => {
                if (res.data) {
                    let s = await load()

                    notify("Created", true)

                }
            }).catch((err) => {
                console.log(err);
                notify("Something went wrong !", false)

            })
        }
    }

    const modifyUpdate = (id, status) => {
        // Fn for status update code for older images
        let temp = [...Image]
        status = !status
        if (!isNaN(id)) {
            temp[id].status = status
            setImage([...temp])

        } else {
            axios.put('/api/User/RotationalUpdateStatus', { id, status, Account_Id }).then((res) => {
                console.log(res);
                if (res.data) {
                    console.log(res.data);
                    let s = temp.indexOf(temp.find(ele => ele._id == id))
                    temp[s].status = status
                    setImage([...temp])
                    notify("Updated", true)

                }
            }).catch((err) => {
                console.log(err);
                notify("Something went wrong !", false)

            })
        }
    }
    const Delete = (id) => {
        let temp = [...Image]
        if (!isNaN(id)) {
            // Fn for delete live images
            temp.splice(id, 1)
            setImage([...temp])
            setcall(null)
            handleClose()
        } else {
            // Fn for delete older images
            axios.delete('/api/User/RotationalDelete?id=' + id+'&&Account_Id='+Account_Id).then((res) => {
                console.log(res);
                if (res.data) {
                    console.log(res.data);
                    setcall(null)
                    handleClose()
                    console.log(Image, id);
                    let s = temp.indexOf(temp.find(ele => ele._id == id))
                    temp.splice(s, 1)
                    setImage([...temp])
                    notify("Deleted", true)

                }
            }).catch((err) => {
                console.log(err);
                notify("Something went wrong !", false)

            })
        }
    }
    const ReplaceImage = (image) => {
        console.log(Replace, image);
        if (isNaN(Replace)) {


            // Fn for replace image for older image

            let formData = new FormData()
            setactionLoading(true)
            let fileName = `Rotational_prochure_${keyGen() + Date.now()}.png`
            formData.append('file', new File([image], fileName, metadata))
            formData.append('Data', Replace)
            // formData.append('Data', JSON.stringify(frequency))
            formData.append("Account_Id", Account_Id);


            setReplace(null)
            axios.put('/api/User/RotationalAddImageReplace', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(async (res) => {
                let s = await load()
                notify("Updated", true)
            }).catch((err) => {
                console.log(err);
                notify("Something went wrong !", false)
            }).finally(() => {
                setactionLoading(false)
            })
        } else {
            let temp = [...Image]
            temp[Replace] = { File: image, status: true }
            setImage(temp)
        }
    }

    const [choose, setchoose] = useState(false)

    const replaceFromArchive = (ArchiveImageDetails) => {
        // replace //usestate
        if (ArchiveState === 'NORMAL') {
            //   axios for normal add
            setImage([...Image, ArchiveImageDetails])
            return
            if (Edit) {
                axios.post('/api/User/RotationalAddImagePushNewUsingArchive', { Replace, ArchiveImageDetails }).then(async (res) => {
                    let s = await load()
                    notify("Updated", true)
                }).catch((err) => {
                    console.log(err);
                    notify("Something went wrong !", false)
                }).finally(() => {
                    setactionLoading(false)
                })
            } else {
                //fresh
                setImage([...Image, ArchiveImageDetails])
            }
        } else if (ArchiveState === 'REPLACE') {
            //   axios for normal replace
            console.log(Replace)
            console.log(ArchiveImageDetails)
        
            axios.post('/api/User/RotationalAddImageReplaceUsingArchive', { Replace, ArchiveImageDetails,Account_Id }).then(async (res) => {
                let s = await load()
                notify("Updated", true)
            }).catch((err) => {
                console.log(err);
                notify("Something went wrong !", false)
            }).finally(() => {
                setactionLoading(false)
            })
        }
    }
    const [payShow, setpayShow] = useState(false)
    const handlePayClose = () => {
        console.log('pppp');
        setpayShow(false)
    }
    const handlePayOpen = () => {
        setpayShow(true)
    }
    const [ArchiveState, setArchiveState] = useState('NORMAL')
    if (!PageLoading) {
        return (
            <>
                <form
                    onSubmit={submit}
                    style={{ position: 'relative', overflow: 'hidden', }}
                >
                    {actionLoading ? <SpinnerSmallTransparent /> : ''}
                    <Archive
                        choose={choose}
                        onChange={(e) => {
                            replaceFromArchive(e)
                        }}
                        onClose={() => {
                            setArchiveState('NORMAL')
                            setchoose(false)
                        }}
                    />
                    {/* <b>ROTATIONAL DISPLAY of ADs {call && call}</b> */}
                    <div className='d-flex justify-content-center align-items-center mt-2'>
                        {Image.length ?
                            <div className='row d-flex justify-content-center g-1'>
                                {Image.map((elem, index) => {
                                    console.log(typeof (elem));
                                    return <div
                                        key={'s' + keyGen() + index}
                                        className={
                                            `${Image.length == 1 ?
                                                'col-12' : Image.length == 2 ?
                                                    'col-md-4 col-sm-6 col-12' : Image.length >= 3 ?
                                                        'col-md-3 col-sm-6 col-xl-2 col-12' : ''} `
                                        }
                                    >
                                        <div className='p-1'>
                                            {/* ${elem.status ? ' border border-success' : 'border border-danger'} */}

                                            <div className='d-flex justify-content-center'>
                                                <img
                                                    className='img-fluid'
                                                    style={{
                                                        // maxWidth: '400px',
                                                        //  minHeight: '120px',
                                                        width: '100%'
                                                    }}
                                                    src={elem.link ? elem.link : URL.createObjectURL(elem.File)}
                                                />
                                            </div>
                                            <div className='d-flex justify-content-end'>

                                                <button
                                                    type='button'
                                                    className='advertisementimgbtn'
                                                    onClick={() => {
                                                        if (elem.link) {
                                                            setReplace(elem._id)
                                                            handleArchiveShow()
                                                        } else {
                                                            setReplace(index)
                                                            fileInputRef.current.click()
                                                        }
                                                    }} >
                                                    <TbReplace />
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        if (elem.link) {
                                                            modifyUpdate(elem._id, elem.status)
                                                        }
                                                        else {
                                                            modifyUpdate(index, elem.status)
                                                        }
                                                    }}
                                                    type='button'
                                                    className='advertisementimgbtn'
                                                >
                                                    {elem?.status ? <BsPauseFill /> : <BsFillPlayFill />}
                                                </button>
                                                <button
                                                    type='button'
                                                    onClick={() => {
                                                        if (elem.link) {
                                                            handleShow(elem._id);
                                                        }
                                                        else {
                                                            handleShow(index);
                                                        }
                                                    }}
                                                    className='advertisementimgbtn'>
                                                    <AiFillDelete />
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                })}
                            </div>
                            :
                            <div
                                className='d-flex justify-content-center align-items-center '
                                style={{ height: '225px', width: '400px', border: '1px dotted black' }}><div>
                                    <p className='m-0'>
                                        Select Advertisement Artwork
                                    </p>
                                    <p className='text-muted m-0'>
                                        <center>Artwork resolution - 16 : 9</center>
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='row py-3'>
                        <div className='col-12'>
                            <label for="selectboxad" class="form-label">AD Rotation Frequency setting</label>
                            <select id="selectboxad" className="form-select" aria-label="Default select example"
                                value={frequency}
                                onChange={(e) => {
                                    setFrequency(e.target.value)
                                }}>
                                <option value="1">Daily</option>
                                <option value="2">Alternate Days</option>
                                <option value="7">Weekly</option>
                                <option value="30">Monthly</option>
                            </select>
                        </div>
                    </div>
                    <div className='row py-3'>
                        <div className='col-12'>
                            <p className='text-danger' >{Error}</p>
                        </div>
                        <div className='col-12 py-2'>
                            <center>
                                <button
                                    type='button'
                                    style={{ 'width': '270px' }}
                                    className='btn pro-spaces-button'
                                    onClick={() => {
                                        fileInputRef.current.click()
                                    }} >
                                    SELECT AD ARTWORK
                                </button>
                            </center>
                        </div>
                        <div className='col-12 py-2'>
                            <center>
                                <button
                                    style={{ 'width': '270px' }}
                                    type='submit'
                                    className='btn pro-spaces-button3'
                                >
                                    UPLOAD AD
                                </button>
                            </center>

                        </div>
                    </div>
                    <input
                        ref={fileInputRef}
                        type={'file'}
                        accept="image/*"
                        onChange={(e) => {
                            if (e.target.files.length) {
                                const reader = new FileReader();
                                reader.onload = () => {
                                    settoCrop({ file: reader.result })
                                };
                                reader.readAsDataURL(e.target.files[0]);
                            }
                        }}
                        className='d-none'
                    ></input>

                    <CropperModal toCrop={toCrop} setImage={(e) => {
                        if (Replace != null) {
                            ReplaceImage(e)
                        } else {
                            setImage([...Image, { File: e, status: true }])

                        }
                    }} />
                    <>
                        <Modal
                            show={show}
                            onHide={() => {
                                setcall(null)
                                handleClose();
                            }}
                            backdrop={'static'}
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className='text-danger'>
                                    Are you sure you want to delete this image ? This action cannot be undone.
                                </p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    type="button"
                                    className="btn pro-spaces-button3"
                                    onClick={() => {
                                        Delete(call)
                                    }}
                                >ok</button>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={archiveShow}
                            onHide={() => {
                                handleArchiveHide()
                            }}
                            backdrop={'static'}
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            Where would you like to add the image from?
                                <div className='row g-1'>
                                    <div className='col-12 col-md-6 d-flex justify-content-center'>
                                        <button
                                            onClick={() => {
                                                fileInputRef.current.click();
                                                handleArchiveHide()
                                            }}
                                            type='button'
                                            className='btn pro-spaces-button'
                                        >
                                            upload from device
                                        </button>
                                    </div>
                                    <div className='col-12 col-md-6 d-flex justify-content-center'>
                                        <button
                                            type='button'
                                            onClick={() => {
                                                setchoose(true)
                                                setArchiveState('REPLACE');
                                                handleArchiveHide()
                                            }}
                                            className='btn pro-spaces-button'

                                        >
                                            upload from archive
                                        </button>
                                    </div>
                                </div>


                            </Modal.Body>

                        </Modal>

                        <Modal
                            show={payShow}
                            onHide={() => {
                                handlePayClose()
                            }}
                            backdrop={'static'}
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <AddPayment paddingTop={'0px'} onSuccess={submit} close={handlePayClose} Account_Id={Account_Id} Login_Id={Login_Id}  />
                            </Modal.Body>
                        </Modal>
                    </>
                </form>
            </>
        )
    } else {
        return <>
            <SpinnerSmall />
        </>
    }
}

export default Rotational