import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { useNavigate } from "react-router-dom";
// import "./Edit.css";
import "cropperjs/dist/cropper.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InformationForms } from "./InformationForms";


export const InformationFormsEdit = () => {
  return (
    <div>

        <InformationForms edit={true} sab={true}/>

    </div>
  )
}
