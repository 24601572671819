import { useState } from "react";
import { Carousel } from "react-bootstrap";
// var bucketName = 'https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/Information'
var bucketName = 'https://s3.ap-south-1.amazonaws.com/prochure.in.profile/Information'
export const MyCarousel = ({ titleEnabled, data, onChange, FileDelete }) => {
  console.log(data);
  const [activeIndex, setActiveIndex] = useState(0);

  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       setActiveIndex((prevIndex) => (prevIndex + 1) % 3); // Adjust the number of slides here
  //     }, 2000); // Adjust the interval time as per your needs (in milliseconds)
  //     return () => clearInterval(interval);
  //   }, []);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };
  const preview = (elem) => {
    if (elem.file) {
      return URL.createObjectURL(elem.file);
    } else {
      console.log('object');
      return elem.link
    }

  };

  return (
    <div className="caro-pro-249882 pb-5">
      {data.length ? (
        <Carousel
          indicators={false}
          activeIndex={activeIndex}
          onSelect={handleSelect}
        >
          {data.map((elem, index) => {
            console.log(elem);
            

            if (elem?.file?.type.indexOf('image') != -1 || elem?.type.indexOf('image') != -1) {
              console.log(elem);
              return (
                <Carousel.Item>
                  <div className='d-flex justify-content-end' ><button type="button" className='btn btn-close p-1' onClick={() => { FileDelete(data, index,); handleSelect(0) }}  ></button></div>
                  <img
                    className="d-block w-100 caro-toolbarv2"
                    src={
                      preview(elem)

                    }
                    alt="Image 1"
                  />
                  {titleEnabled && (
                    <div className="py-2">
                      <input
                        placeholder='Type your Image Description'
                        onChange={(e) => onChange(e.target.value, index)}
                        className="form-control"
                      ></input>
                    </div>
                  )}
                </Carousel.Item>
              );
            } else {
              return (
                <Carousel.Item>
                  <div className='d-flex justify-content-end' ><button className='btn btn-close p-1' ></button></div>

                  <video
                    controls
                    className="d-block w-100 caro-toolbarv2"
                    alt="Image 1"
                  >
                    <source src={
                      preview(elem)
                    } />
                  </video>
                  {titleEnabled && (
                    <div className="py-2">
                      <input
                        placeholder='Type your Image Description'
                        onChange={(e) => onChange(e.target.value, index)}
                        className="form-control"
                      ></input>
                    </div>
                  )}
                </Carousel.Item>
              );
            }
          })}
        </Carousel>
      ) : (
        <></>
      )}
    </div>
  );
};