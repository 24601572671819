import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { print_state, print_city, state_arr,s_a } from '../../../../../Utility/stateCity'

export default function FireStationsReg() {

    const [Stateload, setStateload] = useState(true);
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState(false);


    
    const notify = (message, sab) => {
      if (sab) {
        toast.success(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.warning(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };



    const [value, setValue] = useState({
          name: "",
          phone: "",
          phone2: "",
          state: "",
          city: "",
          ServiceCharges: "",  
          bloodgroup:"",
          pincode :"" ,
          Address:"",
          Availability:""
      });


      function Cityshow(e) {
        var index = document.getElementById(e.target.id).selectedIndex;
        print_city("City", index);
        setValue({ ...value, state: e.target.value });
      }
    
  
    

      useEffect(() => {
        if (Stateload) print_state("State");
        setStateload(false);
      }, []);


      const dasapppan= async(e)=>{



        // if(!value.name)return seterror("Name is missing !");
        if (value.state.length === 0) return seterror("State is missing !");
        if (value.city.length === 0) return seterror("City is missing !");
        if(value.pincode)
        {
        if(value.pincode.toString().length!=6)return seterror(" Enter valid Pin code is missing !");
            
        }

        if (!value.phone) {
            return seterror('Enter your phone number')
        }
        // if (value.phone.toString().length != 10) {
        //     return seterror('Enter a valid mobile number')
        // }
        
        // if(value.bloodgroup.length===0) return seterror("Blood group is missing !")
        



        setloading(true)
        let sab = await  axios.post('/api/Admin/FireStationsReg',{
            value
      }).then((res)=>{
        if(res.status==200){
          notify("Successfully added", true);
         setValue({
            name: "",
            phone: "",
            phone2: "",
            state: "",
            city: "",
            pincode :"" ,
            location:{
              address:'',
              lat:'',
              log:''
            }
         })
          
        }
      }).catch((err)=>{
        console.log(err)
        notify(" Something went wrong", false);
        // setValue({
        //   name: "",
        //   phone: "",
        //   state: "",
        //   city: "",
        //   ServiceCharges: "",  
        //   bloodgroup:"",
        //   pincode :""   
     
        //  })
      })
      setloading(false)
          
      }


     
     
  return (
    <div className="container pt-5 mt-3">
        <h1>Fire Stations</h1>
        {/* <div className='row'>
            <div className='col'><label className="labels">Name *</label></div><div className='col'><input className="form-control" type="text"  value={value.name}
                    onChange={(e) =>
                      setValue({ ...value, name: e.target.value })
                    }></input></div>
        </div><br /> */}
           
        <div className='row'>
            <div className='col'> <label className="labels">State *</label></div><div className='col'> <select
        className="form-select"
        value={value.state}
        id="State"
        name="City"
        onChange={(e) => Cityshow(e)}
      >
        {/* <select onChange={(e) =>}  className="form-select" >
                            </select> */}
      </select></div>
        </div><br />
        <div className='row'>
            <div className='col'> <label className="labels">City *</label></div><div className='col'>
                  <select
                    className="form-select"
                    name="City"
                    value={value.city}
                    id="City"
                    onChange={(e) =>
                      setValue({ ...value, city: e.target.value })
                    }
                  >
                    <option>Select City</option>
                  </select></div>
        </div><br />
        <div className='row'>
            <div className='col'><label className="labels">PIN Code</label></div><div className='col'><input type="number" className="form-control"   value={value.pincode}
                    onChange={(e) =>
                      setValue({ ...value, pincode: e.target.value })
                    }></input></div>
        </div><br />
        <div className='row'>
            <div className='col'>  <label className="labels">Contact Number *</label></div><div className='col'>
            <input type="number"  value={value.phone} className="form-control"
                    onChange={(e) =>
                     { setValue({ ...value, phone: e.target.value })}
                    }></input></div>
        </div><br />
        <div className='row'>
            <div className='col'>  <label className="labels">Contact Number 2</label></div><div className='col'>
            <input type="number"  value={value.phone2} className="form-control"
                    onChange={(e) =>
                     { setValue({ ...value, phone2: e.target.value })}
                    }></input></div>
        </div><br />

        <div className="row">
            <div className="col"><p style={{"color":'red'}}>{error?error:''}</p></div>
            <div className="col">
            {!loading &&   <button className="btn pro-spaces-button3" onClick={(e)=>dasapppan(e)}>Add</button> }
            {loading &&   <button className="btn btn-success" disabled><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button> }

            </div>
        </div>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    </div>
  )
}
