import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { styled } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Axios from 'axios';
import { SpinnerBig } from '../../../Utility/spinner';
import Moment from 'moment';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

export default function Payment_details_list() {
  const [List, setList] = useState();
  const [SpinnerStatus, setSpinnerStatus] = useState(true);
  useEffect(() => {
    Axios.get('/api/SuperAdmin/Payment_details_list').then((res) => {
      if (res.data) {
        console.log(res.data);
        setList(res.data);
        setSpinnerStatus(false);
      } else {
        setList(null);
        setSpinnerStatus(false);
      }
    });
  }, []);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const DelPa = (id) => {
    console.log(id);
    Axios.post('/api/Admin/PaymentDelete', { id });
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#999',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <div
      style={{ height: '100%', overflow: 'hidden' }}
      className=" p-2 border bg-light"
    >
      <div className="bg-secondary text-light text-center p-3">
        {' '}
        <h1 className="text-capital "> PAYMENT DETAILS</h1>
      </div>

      {SpinnerStatus ? <SpinnerBig /> : ''}

      {List ? (
        <TableContainer
          sx={{ height: '100%', overflow: 'hidden' }}
          component={Paper}
        >
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
            component="div"
            count={List.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <div className="RollContainer">
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead sx={{ position: 'sticky' }}>
                <StyledTableRow>
                  <StyledTableCell>Account Type</StyledTableCell>
                  <StyledTableCell>Profile Classification</StyledTableCell>
                  <StyledTableCell>Profile Name</StyledTableCell>
                  <StyledTableCell>Paid Amount</StyledTableCell>
                  <StyledTableCell>Coupon</StyledTableCell>
                  <StyledTableCell>Payment Date</StyledTableCell>
                  <StyledTableCell>Expiry Date</StyledTableCell>
                </StyledTableRow>
              </TableHead>

              <TableBody>
                {List.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).map((row, index) => (
                  <StyledTableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <StyledTableCell component="th" scope="row">
                      {row.con[0]?.Type === 'Consultant' ||
                      row.con[0]?.Type === 'Product'
                        ? 'Prime'
                        : row.con[0]?.Type === 'Consultantv2' ||
                          row.con[0]?.Type === 'Productv2'
                        ? 'Elite'
                        : row.con[0]?.Type === 'Consultantv3' ||
                          row.con[0]?.Type === 'Productv3'
                        ? 'Basic'
                        : 'Passive'}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.con[0]?.Type === 'Consultant' ||
                      row.con[0]?.Type === 'Consultantv2' ||
                      row.con[0]?.Type === 'Consultantv3'
                        ? 'Services'
                        : row.con[0]?.Type === 'Product' ||
                          row.con[0]?.Type === 'Productv2' ||
                          row.con[0]?.Type === 'Productv3'
                        ? 'Products'
                        : 'Passive'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.con[0]?.pname ? row.con[0].pname : '-'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.amount ? row.amount : '-'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.Coupon ? row.Coupon : '-'}
                    </StyledTableCell>
                    <StyledTableCell>
                      {Moment.unix(row.created_at).format('DD-MM-YYYY')}
                    </StyledTableCell>
                    {/* <StyledTableCell >{row.Created_on}</StyledTableCell> */}
                    <StyledTableCell>
                      {row.con[0]?.Validity
                        ? Moment(row.con[0].Validity).format('DD-MM-YYYY')
                        : '-'}
                    </StyledTableCell>
                    {/* <StyledTableCell ><button onClick={(e)=>DelPa(row._id)}>Del</button></StyledTableCell> */}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
      ) : (
        <></>
      )}
    </div>
  );
}
