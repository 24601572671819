import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ActiveMemberList.css';
import Axios from 'axios';
import ProductList from './product';
import {
  BiLeftArrow,
  BiRightArrow,
  BiUpArrowAlt,
  BiDownArrowAlt,
} from 'react-icons/bi';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { SpinnerBig, SpinnerSmall } from '../../../Utility/spinner';
import Moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function ProductStore() {
  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const [Product, setProduct] = useState([]);
  const [load, setload] = useState(true);
  const [error, seterror] = useState('');
  const [output, setoutput] = useState('');
  const [data, setdata] = useState(false);
  const [productid, setproductid] = useState([]);
  var result = [];

  const fetchData = () => {
    setload(true);
    console.log(productid);
    Axios.get('/api/Admin/Product')
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setProduct(res.data);
          setproductid(res.data);
          setload(false);

          const datas1 = res.data.map((product, index) => ({
            product: product,
            index: index,
          }));
          setdata(datas1);
          // ,status:product.login[0].Status
          // ,phone:product.login[0].Contact

          // Counts=res.data
          // res.data
          // console.log(Id)

          // setLoading(false)
          //    settotal(res.data.JobRecruit_screenQustionNumber)
        }
      })
      .catch((err) => {
        console.log(err);
        setload(false);
        seterror('');
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    console.log(data);
  }, [data]);

  function makeid(length) {
    length = 5;
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const tableRow = (
    <tr className={`tableBody ${load ? 'skeleton' : ''} `} scope="row">
      <th scope="row"></th>
      <th>&nbsp;</th>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  );

  let dem = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  let skelton = dem.map((mrx) => {
    return tableRow;
  });
  useEffect(() => {
    if (load) {
      setoutput(<>{skelton}</>);
    } else {
      if (data) {
        setto(rowsPerPage);
        setfrom(1);
        setoutput(
          data.slice(0, 12).map((number) => {
            // if(number.con>0){
            return (
              <ProductList
                key={number.id}
                ids={makeid()}
                data={number}
                UpdatePaymentAndValidityGet={UpdatePaymentAndValidityGet}
              ></ProductList>
            );
            // }
          })
        );
      } else {
        setoutput(error);
      }
    }
  }, [data]);

  const [Query, setQuery] = useState('');

  useEffect(() => {
    // Filter function
    if (Query.length) {
      console.log(Query);
      console.log(data);
      var condition = new RegExp(Query, 'i');
      result = data.filter(function (el) {
        console.log(el);
        return condition.test(
          el.product.login[0]?.Contact ||
            el.product.firstname ||
            el.product.login[0]?.Email
        );
      });
      setoutput(
        result.map((number) => {
          // if(number.con>0){
          return (
            <ProductList
              key={number.id}
              ids={makeid()}
              data={number}
              UpdatePaymentAndValidityGet={UpdatePaymentAndValidityGet}
            ></ProductList>
          );
          // }
        })
      );
      console.log(result);
    } else {
      if (data) {
        setoutput(
          data.map((number) => {
            // if(number.con>0){
            return (
              <ProductList
                key={number.id}
                ids={makeid()}
                data={number}
                UpdatePaymentAndValidityGet={UpdatePaymentAndValidityGet}
              ></ProductList>
            );
            // }
          })
        );
      }
    }
    return () => {};
  }, [Query]);

  const [rowsPerPage, setrowsPerPage] = useState(12);
  const [page, setpage] = useState(1);

  const prev = () => {
    if (page === 1) {
      console.log('no prev');
      return;
    }
    setpage((prev) => prev - 1);
    console.log('go prev');
  };
  const next = () => {
    let length = data.length;
    if (page * rowsPerPage < length) {
      console.log('go next');
      setpage((prev) => prev + 1);
    } else {
      console.log('no next');
    }
  };

  const up = (fr = 0, t = rowsPerPage) => {
    console.clear();
    let filter = data.slice(fr - 1, t);
    console.log(fr, t, filter);
    setoutput(
      filter.map((number) => {
        // if(number.con>0){
        return (
          <ProductList
            key={number.id}
            ids={makeid()}
            data={number}
            UpdatePaymentAndValidityGet={UpdatePaymentAndValidityGet}
          ></ProductList>
        );
        // }
      })
    );
  };

  const [to, setto] = useState();
  const [from, setfrom] = useState(0);

  useEffect(() => {
    if (from != 1) {
      setpage(1);
    }
    return () => {};
  }, [rowsPerPage]);

  useEffect(() => {
    // to;
    let realto = 0;
    let realfrom = 0;
    if (data.length) {
      let from = rowsPerPage * page + 1 - rowsPerPage;
      let to = rowsPerPage * page;
      if (to <= data.length) {
        realto = to;
      } else {
        realto = data.length;
      }
      up(from, realto);
      setto(realto);
      setfrom(from);
    } else {
      setfrom(0);
      setto(0);
    }
    return () => {};
  }, [page, rowsPerPage]);

  //////////////////////////////////////////////////sorting Starts///////////////////////////////////
  const [NameSort, setNameSort] = useState(true);
  const nameSortFunction = () => {
    console.log(data.length);
    if (NameSort) {
      setNameSort(!NameSort);
      setoutput(
        data
          .sort((a, b) =>
            a.product.pname?.toLowerCase() > b.product?.pname?.toLowerCase()
              ? 1
              : -1
          )
          .map((number) => {
            // if(number.con>0){
            return (
              <ProductList
                key={number.id}
                ids={makeid()}
                data={number}
                fetchData={fetchData}
                UpdatePaymentAndValidityGet={UpdatePaymentAndValidityGet}
              ></ProductList>
            );
            // }
          })
      );
    } else {
      setNameSort(!NameSort);
      setoutput(
        data
          .sort((a, b) =>
            a.product.pname?.toLowerCase() < b.product?.pname?.toLowerCase()
              ? 1
              : -1
          )
          .map((number) => {
            // if(number.con>0){
            return (
              <ProductList
                key={number.id}
                ids={makeid()}
                data={number}
                fetchData={fetchData}
                UpdatePaymentAndValidityGet={UpdatePaymentAndValidityGet}
              ></ProductList>
            );
            // }
          })
      );
    }
  };
  const membershipSort = (Type) => {
    console.log(Type);
    if (Type) {
      setoutput(
        data.map((number) => {
          console.log(number.product.Type, Type);
          if (number.product.Type == Type) {
            // console.log(Type)
            return (
              <ProductList
                key={number.id}
                ids={makeid()}
                data={number}
                fetchData={fetchData}
                UpdatePaymentAndValidityGet={UpdatePaymentAndValidityGet}
              ></ProductList>
            );
          }
        })
      );
    } else {
      setoutput(
        data.map((number) => {
          return (
            <ProductList
              key={number.id}
              ids={makeid()}
              data={number}
              fetchData={fetchData}
              UpdatePaymentAndValidityGet={UpdatePaymentAndValidityGet}
            ></ProductList>
          );
        })
      );
    }
  };

  const [ValiditySort, setValiditySort] = useState(true);
  const validitySortFunction = () => {
    if (ValiditySort) {
      setValiditySort(!ValiditySort);
      setoutput(
        data
          .sort((a, b) => (a.product?.Validity > b.product?.Validity ? 1 : -1))
          .map((number) => {
            // if(number.con>0){
            return (
              <ProductList
                key={number.id}
                ids={makeid()}
                data={number}
                fetchData={fetchData}
                UpdatePaymentAndValidityGet={UpdatePaymentAndValidityGet}
              ></ProductList>
            );
            // }
          })
      );
    } else {
      setValiditySort(!ValiditySort);
      setoutput(
        data
          .sort((a, b) => (a.product?.Validity > b.product?.Validity ? -1 : 1))
          .map((number) => {
            // if(number.con>0){
            return (
              <ProductList
                key={number.id}
                ids={makeid()}
                data={number}
                fetchData={fetchData}
                UpdatePaymentAndValidityGet={UpdatePaymentAndValidityGet}
              ></ProductList>
            );
            // }
          })
      );
    }
  };
  const CreatedBySort = (Type) => {
    console.log(Type);
    if (Type) {
      setoutput(
        data.map((number) => {
          if (Type == 'Self') {
            if (
              !number.product.login[0]?.DirectLink &&
              !number.product.login[0]?.NOTES?.createdByAdmin
            ) {
              // console.log(Type)
              return (
                <ProductList
                  key={number.id}
                  ids={makeid()}
                  data={number}
                  fetchData={fetchData}
                  UpdatePaymentAndValidityGet={UpdatePaymentAndValidityGet}
                ></ProductList>
              );
            }
          }
          if (Type == 'CreatedbyAdmin') {
            if (number.product.login[0]?.NOTES?.createdByAdmin) {
              // console.log(Type)
              return (
                <ProductList
                  key={number.id}
                  ids={makeid()}
                  data={number}
                  fetchData={fetchData}
                  UpdatePaymentAndValidityGet={UpdatePaymentAndValidityGet}
                ></ProductList>
              );
            }
          }
          if (Type == 'Directlink') {
            if (number.product.login[0]?.DirectLink) {
              // console.log(Type)
              return (
                <ProductList
                  key={number.id}
                  ids={makeid()}
                  data={number}
                  fetchData={fetchData}
                  UpdatePaymentAndValidityGet={UpdatePaymentAndValidityGet}
                ></ProductList>
              );
            }
          }
        })
      );
    } else {
      setoutput(
        data.map((number) => {
          return (
            <ProductList
              key={number.id}
              ids={makeid()}
              data={number}
              fetchData={fetchData}
              UpdatePaymentAndValidityGet={UpdatePaymentAndValidityGet}
            ></ProductList>
          );
        })
      );
    }
  };

  const [MailCountSort, setMailCountSort] = useState(true);
  const mailCountSortFunction = () => {
    if (MailCountSort) {
      console.log('first');
      setMailCountSort(!MailCountSort);
      setoutput(
        data
          .sort((a, b) =>
            a.product?.invoice_mail_count.length >
            b.product?.invoice_mail_count.length
              ? 1
              : -1
          )
          .map((number) => {
            // if(number.con>0){
            return (
              <ProductList
                key={number.id}
                ids={makeid()}
                data={number}
                fetchData={fetchData}
                UpdatePaymentAndValidityGet={UpdatePaymentAndValidityGet}
              ></ProductList>
            );
            // }
          })
      );
    }
    if (!MailCountSort) {
      console.log('2');
      setMailCountSort(!MailCountSort);
      setoutput(
        data
          .sort((a, b) =>
            a.product?.invoice_mail_count.length <
            b.product?.invoice_mail_count.length
              ? 1
              : -1
          )
          .map((number) => {
            if (number.product?.invoice_mail_count.length > 0) {
              console.log('garter');
              return (
                <ProductList
                  key={number.id}
                  ids={makeid()}
                  data={number}
                  fetchData={fetchData}
                  UpdatePaymentAndValidityGet={UpdatePaymentAndValidityGet}
                ></ProductList>
              );
            }
          })
      );
    }
  };
  const PrioritySort = (Priority) => {
    console.log(Priority);
    if (Priority) {
      setoutput(
        data.map((number) => {
          if (Priority == 'Primary') {
            if (number.product.Priority == 'Primary') {
              // console.log(Type)
              return (
                <ProductList
                  key={number.id}
                  ids={makeid()}
                  data={number}
                  fetchData={fetchData}
                  UpdatePaymentAndValidityGet={UpdatePaymentAndValidityGet}
                ></ProductList>
              );
            }
          }
          if (Priority == 'Sub') {
            if (number.product.Priority == 'Sub') {
              // console.log(Type)
              return (
                <ProductList
                  key={number.id}
                  ids={makeid()}
                  data={number}
                  fetchData={fetchData}
                  UpdatePaymentAndValidityGet={UpdatePaymentAndValidityGet}
                ></ProductList>
              );
            }
          }
        })
      );
    } else {
      setoutput(
        data.map((number) => {
          return (
            <ProductList
              key={number.id}
              ids={makeid()}
              data={number}
              fetchData={fetchData}
              UpdatePaymentAndValidityGet={UpdatePaymentAndValidityGet}
            ></ProductList>
          );
        })
      );
    }
  };

  const [CreatedOnSort, setCreatedOnSort] = useState(true);

  const CreatedOnSortFunction = () => {
    console.log(data.length);
    if (CreatedOnSort) {
      setCreatedOnSort(!CreatedOnSort);
      setoutput(
        data
          .sort((a, b) =>
            a.product.Created_on?.toLowerCase() >
            b.product?.Created_on?.toLowerCase()
              ? 1
              : -1
          )
          .map((number) => {
            // if(number.con>0){
            return (
              <ProductList
                key={number.id}
                ids={makeid()}
                data={number}
                fetchData={fetchData}
                UpdatePaymentAndValidityGet={UpdatePaymentAndValidityGet}
              ></ProductList>
            );
            // }
          })
      );
    } else {
      setCreatedOnSort(!CreatedOnSort);
      setoutput(
        data
          .sort((a, b) =>
            a.product.Created_on?.toLowerCase() <
            b.product?.Created_on.toLowerCase()
              ? 1
              : -1
          )
          .map((number) => {
            // if(number.con>0){
            return (
              <ProductList
                key={number.id}
                ids={makeid()}
                data={number}
                fetchData={fetchData}
                UpdatePaymentAndValidityGet={UpdatePaymentAndValidityGet}
              ></ProductList>
            );
            // }
          })
      );
    }
  };

  const [CitySort, setCitySort] = useState(true);

  const CitySortFunction = () => {
    console.log(data.length);
    if (CitySort) {
      setCitySort(!CitySort);
      setoutput(
        data
          .sort((a, b) =>
            a.product.city?.toLowerCase() > b.product?.city?.toLowerCase()
              ? 1
              : -1
          )
          .map((number) => {
            // if(number.con>0){
            return (
              <ProductList
                key={number.id}
                ids={makeid()}
                data={number}
                fetchData={fetchData}
                UpdatePaymentAndValidityGet={UpdatePaymentAndValidityGet}
              ></ProductList>
            );
            // }
          })
      );
    } else {
      setCitySort(!CitySort);
      setoutput(
        data
          .sort((a, b) =>
            a.product.city?.toLowerCase() < b.product?.city?.toLowerCase()
              ? 1
              : -1
          )
          .map((number) => {
            // if(number.con>0){
            return (
              <ProductList
                key={number.id}
                ids={makeid()}
                data={number}
                fetchData={fetchData}
                UpdatePaymentAndValidityGet={UpdatePaymentAndValidityGet}
              ></ProductList>
            );
            // }
          })
      );
    }
  };
  //////////////////////////////////////////////////////Sorting end//////////////////////////////////////////////
  const [GetPaymentDetails, setGetPaymentDetails] = useState({
    Created_on: '',
    Validity: '',
    amount: '',
    Acc_id: '',
    Login_id: '',
    payment_id: '',
    Notes: '',
  });
  const [GetPaymentDetailsLoading, setGetPaymentDetailsLoading] =
    useState(false);
  const [GetPaymentDetailsError, setGetPaymentDetailsError] = useState();
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const UpdatePaymentAndValidityGet = (acc_id, login_id) => {
    setGetPaymentDetailsLoading(true);
    handleShow1();
    Axios.post('/api/Admin/UpdatePaymentAndValidityGet', { acc_id, login_id })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          var data1 = Moment(res.data[0]?.Validity).format('YYYY-MM-DD');
          var data2 = Moment(res.data[0]?.login[0]?.Created_on).format(
            'YYYY-MM-DD'
          );

          setGetPaymentDetails({
            Created_on: data2,
            Validity: data1,
            amount: res.data[0]?.amount,
            Acc_id: acc_id,
            Login_id: login_id,
            payment_id: res.data[0]?._id,
            Notes: res.data[0]?.Notes,
          });
          setGetPaymentDetailsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        notify('Some thing went wrong', false);
      });
  };
  const [GetPaymentDetailsLoadingButton, setGetPaymentDetailsLoadingButton] =
    useState(false);
  const Validates = () => {
    console.log();
    if (GetPaymentDetails.Created_on.length <= 0) {
      return setGetPaymentDetailsError('Select created date');
    }
    if (GetPaymentDetails.Validity.length <= 0) {
      return setGetPaymentDetailsError('Select Validity date');
    }
    if (GetPaymentDetails.amount < 0) {
      return setGetPaymentDetailsError('Enter amount 0 or greater than 0');
    }
    setGetPaymentDetailsError('');
    return true;
  };
  const UpdatePaymentAndValidity = () => {
    if (Validates()) {
      setGetPaymentDetailsLoadingButton(true);
      Axios.post('/api/Admin/UpdatePaymentAndValidity', { GetPaymentDetails })
        .then((res) => {
          if (res.status === 200) {
            notify('Updated successful', true);
            fetchData();
            setGetPaymentDetailsLoadingButton(false);
            handleClose1();
          }
        })
        .catch((err) => {
          console.log(err);
          notify('Some thing went wrong', false);
          setGetPaymentDetailsLoadingButton(false);
        });
    }
  };
  //////////////////////////////////////////Updating Validity and payment  amount end here//////////////////////////////////////////////////

  return (
    <div
      style={{ height: '100%', overflow: 'hidden' }}
      className=" p-2 border bg-light"
    >
      <div className="bg-secondary text-light text-center p-3">
        {' '}
        <h1 className="text-capital ">PRODUCTS</h1>
      </div>
      <div className="row m-0 p-0 mt-3">
        <div className="col-6 col-md-6  m-0 p-0">
          <input
            type="text"
            placeholder="Filter"
            onChange={(e) => setQuery(e.target.value)}
            class="form-control"
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
          />
        </div>
        <div className="col-6 col-md-6  m-0 p-0 ps-2  ">
          <select
            className="form-select"
            onChange={(e) => PrioritySort(e.target.value)}
          >
            <option value={''} selected>
              Select account type
            </option>
            <option value={'Primary'}>Primary account</option>
            <option value={'Sub'}>Sub account</option>
          </select>
        </div>
        <div
          className="col-12 col-md-12  m-0 p-2 d-flex justify-content-end align-items-center "
          style={{ whiteSpace: 'nowrap' }}
        >
          <div className="row gy-3">
            {Query.length === 0 ? (
              <>
                <div className="col-12 col-md-6 d-flex justify-content-end align-items-center">
                  rows per page&nbsp;{' '}
                  <select
                    value={rowsPerPage}
                    onChange={(e) => {
                      setrowsPerPage(parseInt(e.target.value));
                    }}
                    style={{ width: '60px' }}
                  >
                    <option>12</option>
                    <option>24</option>
                    <option>100</option>
                    <option>250</option>
                    <option>500</option>
                    <option>1000</option>
                  </select>
                </div>
                <div className="col-12 col-md-6 d-flex justify-content-end align-items-center">
                  {from}-{to} of {data.length}&nbsp;&nbsp;{' '}
                  <button
                    className="btn btn-secondary"
                    onClick={(e) => prev()}
                    style={{ maxWidth: '25%' }}
                  >
                    <BiLeftArrow />
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="btn btn-secondary "
                    onClick={(e) => next()}
                    style={{ maxWidth: '25%' }}
                  >
                    <BiRightArrow />
                  </button>
                  {/* <button onClick={(e) => fetchData()}>Reload</button> */}
                </div>
              </>
            ) : (
              <>Showing all data !</>
            )}
          </div>
        </div>
      </div>

      <div className="RollContainer pb-5 ">
        <table className="table table-striped table-hover table-bordered ">
          <thead className="pro-sticky-head">
            <tr className="tableHead">
              <td scope="col">No</td>
              <td scope="col">
                <div
                  className="btn w-100"
                  onClick={() => nameSortFunction()}
                  style={{ textTransform: 'unset', whiteSpace: 'nowrap' }}
                >
                  Name&nbsp;&nbsp;
                  {NameSort ? <BiUpArrowAlt /> : <BiDownArrowAlt />}
                </div>
              </td>
              <td scope="col">
                Membership<br></br>
                <select
                  className="form-select"
                  onChange={(e) => membershipSort(e.target.value)}
                >
                  <option></option>
                  <option value={'Productv3'}>Basic</option>
                  <option value={'Product'}>Prime</option>
                  <option value={'Productv2'}>Elite</option>
                </select>
              </td>
              <td scope="col">
                {' '}
                <div className="d-flex justify-content-center align-items-center">
                  Phone
                </div>
              </td>
              <td scope="col">
                <div className="d-flex justify-content-center align-items-center">
                  {' '}
                  Email
                </div>
              </td>
              <td scope="col">
                <div
                  className="btn w-100"
                  onClick={() => CitySortFunction()}
                  style={{ textTransform: 'unset', whiteSpace: 'nowrap' }}
                >
                  {' '}
                  City&nbsp;{CitySort ? <BiUpArrowAlt /> : <BiDownArrowAlt />}
                </div>
              </td>
              <td scope="col">
                {' '}
                <div
                  className="btn w-100"
                  style={{ whiteSpace: 'nowrap' }}
                  onClick={() => validitySortFunction()}
                >
                  Validity&nbsp;&nbsp;
                  {ValiditySort ? <BiUpArrowAlt /> : <BiDownArrowAlt />}
                </div>{' '}
              </td>
              <td scope="col  " style={{ whiteSpace: 'nowrap' }}>
                <div className=" d-flex justify-content-center ">
                  Created By
                </div>
                <select
                  className="form-select"
                  onChange={(e) => CreatedBySort(e.target.value)}
                >
                  <option></option>
                  <option value={'Self'}>Self</option>
                  <option value={'CreatedbyAdmin'}>Created by Admin</option>
                  <option value={'Directlink'}>Direct link</option>
                </select>
              </td>
              <td scope="col">
                {' '}
                <div
                  className="btn w-100"
                  onClick={() => CreatedOnSortFunction()}
                  style={{ textTransform: 'unset', whiteSpace: 'nowrap' }}
                >
                  Created&nbsp;On&nbsp;
                  {CreatedOnSort ? <BiUpArrowAlt /> : <BiDownArrowAlt />}
                </div>
              </td>
              <td scope="col">
                {' '}
                <div
                  className="btn w-100"
                  onClick={() => mailCountSortFunction()}
                  style={{ textTransform: 'unset', whiteSpace: 'nowrap' }}
                >
                  Mail Count&nbsp;&nbsp;
                  {MailCountSort ? <BiUpArrowAlt /> : <BiDownArrowAlt />}
                </div>
              </td>
              <td scope="col" style={{ whiteSpace: 'nowrap' }}>
                Verify Account
              </td>
              <td scope="col" style={{ whiteSpace: 'nowrap' }}>
                Edit Profile
              </td>
              {/* <td scope="col" style={{ "whiteSpace": "nowrap" }} >Edit location</td> */}
              <td scope="col" style={{ whiteSpace: 'nowrap' }}>
                Send Invoice Mail
              </td>
              <td scope="col" style={{ whiteSpace: 'nowrap' }}>
                Send Notification
              </td>
            </tr>
          </thead>
          <tbody>
            {/* {
           Product.length > 0 ?
           Product.map((map, index) => {
                var expdate = Moment(map.Validity).format('DD-MM-YYYY')
                return 
              })
              : ""
          } */}
            {output}
          </tbody>
        </table>
      </div>

      <Modal
        show={show1}
        onHide={handleClose1}
        style={{ zIndex: 6000 }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Payment and Validity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {GetPaymentDetailsLoading ? (
            <SpinnerSmall />
          ) : (
            <>
              <div className="row">
                <div className="col-12">
                  <label className="labels">Created on</label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="date"
                    value={GetPaymentDetails.Created_on}
                    className="form-control "
                    id="firstPoss"
                    onChange={(e) =>
                      setGetPaymentDetails({
                        ...GetPaymentDetails,
                        Created_on: e.target.value,
                      })
                    }
                  ></input>
                </div>
                <div className="col-12">
                  <label className="labels">Validity</label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="date"
                    value={GetPaymentDetails.Validity}
                    className="form-control "
                    id="firstPoss1"
                    onChange={(e) =>
                      setGetPaymentDetails({
                        ...GetPaymentDetails,
                        Validity: e.target.value,
                      })
                    }
                  ></input>
                </div>
                <div className="col-12">
                  <label className="labels">Amount</label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="text"
                    value={GetPaymentDetails.amount}
                    className="form-control "
                    id="firstPoss3"
                    onChange={(e) =>
                      setGetPaymentDetails({
                        ...GetPaymentDetails,
                        amount: e.target.value,
                      })
                    }
                  ></input>
                </div>
              </div>

              <div>
                {GetPaymentDetailsError ? (
                  <p style={{ color: 'red' }}>{GetPaymentDetailsError}</p>
                ) : (
                  ''
                )}
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {GetPaymentDetailsLoadingButton ? (
            <Button variant="primary" disabled>
              Update
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={(e) => UpdatePaymentAndValidity()}
            >
              Update
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
