import React,{useState,useEffect} from "react";
import {AdminType} from "../../Utility/helper"
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {validator} from 'validator'
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Form } from 'react-bootstrap';


import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { print_state, print_city, state_arr } from '../../Utility/stateCity'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '300',
    },
  },
};



export const CreateAdmin = () => {
  const [Stateload, setStateload] = useState(true)
  useEffect(() => {
      if (Stateload)
          print_state("State")
      setStateload(false)
  }, [])
  // var validator=validator
  const [value, setValue] = useState({
    AdminType:'AdminType1',
    Name:'',
    Email:"",
    Contact:"",
    Password:"",
    Pin:'',
    showPassword: false,
    showPin: false,
    Active_status:true,
    Activev2_status:true,
    Activev3_status:true,
    Passive_status:true,
    Service_provide_category_All:true,
    Product_retailer_category_All:true,
    state:"",
    city:"",
    Commission_percentage:""
})
    const notify = (message, sab) => {
        if (sab) {
          toast.success(message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.warning(message, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      };

      function Cityshow(e) {
        var index = document.getElementById(e.target.id).selectedIndex
        print_city('City', index)
        setValue({ ...value, state: e.target.value })
    }





const [Cat, setCat] = useState([])
      function reload()
      {
        axios
        .get("/api/api//getcategory")
        .then((res) => {
          if (res.status === 200) {
            console.log("workd");
            console.log(res.data)
            setCat(res.data);
          }
        })
        .catch((err) => {
          // console.log(err.response.status);
        });
      }
const [Cat1, setCat1] = useState([])

      function reload1()
      {
        axios
        .get("/api/api/getprocategory")
        .then((res) => {
          if (res.status === 200) {
            console.log("workd");
            console.log(res.data)
            setCat1(res.data);
          }
        })
        .catch((err) => {
          // console.log(err.response.status);
        });
      }

      useEffect(() => {
        reload()
        reload1()

      }, [])
      
  

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    function validator1(){
      const errors = {}
        if(!value.Name)return setError("Name is missing !");
        if (!value.Email) {
          errors.email = 'Email required !'
        return setError(errors)
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.Email)) {
          errors.email = 'Invalid email address !'
        return setError(errors)
        }
      

        if (!value.Contact) {
            return setError('Enter phone number !')
        }
        if (value.Contact.toString().length != 10) {
            return setError('Enter a valid mobile number !')
        }
        if(!value.Commission_percentage)return setError("Discount percentage is missing !");
        if(!value.Password)return setError("Password is missing !");

        if (value.Password.toString().length <8) {
          return setError('Password must contain 8 characters !')
      }
      
        go()
      
    }
    function go(){
        setLoading(true)
        // console.log(value)
        // return
        var Affiliated=true
        axios.post('/api/SuperAdmin/CreateSubAdmin',{value,Service_provide_category,Product_retailer_category,Affiliated}).then((res)=>{
            if (res.status===200) {
               notify("Successfully created", true);
                setValue({
                    ...value,
                    AdminType:"",
                    Name:'',
                    Email:"",
                    Contact:"",
                    Password:"",
                    Pin:'',
                    Active_status:true,
                    Activev2_status:true,
                    Activev3_status:true,
                    Passive_status:true,
                    Service_provide_category_All:true,
                    Product_retailer_category_All:true,
                    state:"",
                    city:"",
                    Commission_percentage:""
                })
                setService_provide_category([])
                setProduct_retailer_category([])
                setLoading(false)
                setError('')
                
            }
        }).catch((err)=>{
            if(err.response.status===401)
            {
             notify("Wrong security pin", false);
            }
            else if (err.response.status===402) {
             notify("Email already exist", false);
            }
            else if (err.response.status===403) {
              notify("Name already exist", false);
             }
            else
            {
             notify("Something went wrong", false);
            }
            setLoading(false)


        })

    }

    const handleChange = (prop) => (event) => {
        setValue({ ...value, [prop]: event.target.value });
      };
    
      const handleClickShowPassword = () => {
        setValue({
          ...value,
          showPassword: !value.showPassword,
        });
      };
    
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
      const handleClickShowPassword1 = () => {
        setValue({
          ...value,
          showPin: !value.showPin,
        });
      };
    
      const handleMouseDownPassword1 = (event) => {
        event.preventDefault();
      };


     
      const [Service_provide_category, setService_provide_category] = React.useState([]);

      const handleChange1 = (event) => {
        const {
          target: { value },
        } = event;
        setService_provide_category(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };
      const [Product_retailer_category, setProduct_retailer_category] = React.useState([]);
      const handleChange2 = (event) => {
        const {
          target: { value },
        } = event;
        setProduct_retailer_category(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };

      useEffect(() => {
        if (value.Service_provide_category_All) {
          setService_provide_category([])
        }  
        if (value.Product_retailer_category_All) {
          setProduct_retailer_category([])
        }  
      

      }, [value.Service_provide_category_All,value.Product_retailer_category_All])

      
    return <div className="bg-light">Create Sub-Admin
      <div className="container border py-3">
        <input placeholder="FullName" className="form-control" type={"text"} value={value.Name} onChange={(e) => { setValue({ ...value, Name: e.target.value }) }} ></input>
        <input placeholder="Email " className="form-control mt-2" type={"email"} value={value.Email} onChange={(e) => { setValue({ ...value, Email: e.target.value }) }} ></input>
        <input placeholder="Contact " className="form-control mt-2" type={"number"} value={value.Contact} onChange={(e) => { setValue({ ...value, Contact: e.target.value }) }}></input>
        <input placeholder="Discount percentage % " className="form-control mt-2" type={"number"} value={value.Commission_percentage} onChange={(e) => { setValue({ ...value, Commission_percentage: e.target.value }) }}></input>
        <div className="mt-1 mb-1"><label>State</label></div>
        <select
          className="form-select"
          placeholder="State"
          value={value.state}
          id="State"
          name="City"
          onChange={(e) => Cityshow(e)}
        >
          {/* <select onChange={(e) =>}  className="form-select" >
                                          </select> */}
        </select>
        <div className="mt-1 mb-1"><label>City</label></div>
        <select
          className="form-select"
          name="City"
          value={value.city}
          id="City"
          onChange={(e) =>
            setValue({ ...value, city: e.target.value })
          }
        >
          <option></option>
        </select>

        <Form>
          <div className="row mt-1">
            <div className="col">
              <Form.Check
                type="checkbox"
                label="All service provide category's"
                id="prime-checkbox"
                checked={value.Service_provide_category_All ? true : false}
                onChange={(e) => (setValue({ ...value, Service_provide_category_All: !value.Service_provide_category_All }))}
              />
            </div>
          </div>
        </Form>
        <div className="mt-1">
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="demo-multiple-checkbox-label">Service provide category's</InputLabel>
            <Select
              disabled={value.Service_provide_category_All ? true : false}
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={Service_provide_category}
              onChange={handleChange1}
              input={<OutlinedInput label="Service provide category's" />}
              renderValue={(selected) =>
                selected.map(
                  (categoryId) => Cat.find((cat) => cat._id === categoryId)?.category || ''
                ).join(', ')
              }
              MenuProps={MenuProps}
            >
              {Cat.map((name) => (
                <MenuItem key={name._id} value={name._id}>
                  <Checkbox checked={Service_provide_category.indexOf(name._id) > -1} />
                  <ListItemText primary={name.category} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <Form>
          <div className="row mt-1">
            <div className="col">
              <Form.Check
                type="checkbox"
                label="All Product retailer category's"
                id="prime-checkbox"
                checked={value.Product_retailer_category_All ? true : false}
                onChange={(e) => setValue({ ...value, Product_retailer_category_All: !value.Product_retailer_category_All })}
              />
            </div>
          </div>
        </Form>
        <div className="mt-1">
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="demo-multiple-checkbox-label">Product retailer category's</InputLabel>
            <Select
              disabled={value.Product_retailer_category_All ? true : false}
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={Product_retailer_category}
              onChange={handleChange2}
              input={<OutlinedInput label="Product retailer category's" />}
              renderValue={(selected) =>
                selected.map(
                  (categoryId) => Cat1.find((cat1) => cat1._id === categoryId)?.category || ''
                ).join(', ')
              }
              MenuProps={MenuProps}
            >
              {Cat1.map((name) => (
                <MenuItem key={name._id} value={name._id}>
                  <Checkbox checked={Product_retailer_category.indexOf(name._id) > -1} />
                  <ListItemText primary={name.category} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <FormControl  variant="outlined" className="form-control mt-2">
        {/* sx={{ m: 1, width: '25ch' }} */}
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={value.showPassword ? 'text' : 'password'}
            value={value.Password}
            // inputProps={{ maxLength: 10 }}
            onChange={handleChange('Password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {value.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>

            {/* <div className='row mt-2'>
            <div className='col'>  <label className="labels">Admin Type</label></div><div className='col'>

                       <select onChange={(e) =>setValue({ ...value, AdminType: e.target.value })} value={value.AdminType} className="form-select">
                        <option></option>
                        {
                          AdminType.map((e)=>{

                       return   <option value={e}>{e}</option>


                          })
                        }
                        </select>
           </div>
        </div> */}
        <FormControl  variant="outlined" className="form-control mt-2">
        {/* sx={{ m: 1, width: '25ch' }} */}
          <InputLabel htmlFor="outlined-adornment-password">Pin</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={value.showPin ? 'text' : 'password'}
            value={value.Pin}
            onChange={(e)=>{if(e.target.value.length==5) return false; setValue({ ...value, Pin: e.target.value })}}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword1}
                  onMouseDown={handleMouseDownPassword1}
                  edge="end"
                >
                  {value.showPin ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="pin"
          />
        </FormControl>
        <br></br>
         <br></br>

         <Form>
      <div className="row">
        <div className="col">
          <Form.Check
            type="checkbox"
            label="Prime"
            id="prime-checkbox"
            checked={value.Active_status}
            onChange={(e)=>setValue({ ...value, Active_status: !value.Active_status })}
          />
        </div>
        <div className="col">
          <Form.Check
            type="checkbox"
            label="Basic"
            id="basic-checkbox"
            checked={value.Activev2_status}
            onChange={(e)=>setValue({ ...value, Activev2_status: !value.Activev2_status })}

          />
        </div>
        <div className="col">
          <Form.Check
            type="checkbox"
            label="Static"
            id="static-checkbox"
            checked={value.Activev3_status}
            onChange={(e)=>setValue({ ...value, Activev3_status: !value.Activev3_status })}

          />
        </div>
        <div className="col">
          <Form.Check
            type="checkbox"
            label="Passive"
            id="passive-checkbox"
            checked={value.Passive_status}
           
            onChange={(e)=>setValue({ ...value, Passive_status: !value.Passive_status })}

          />
        </div>
      </div>
    </Form> 
        <br></br>
        <div className="row">
            <div className="col"><p style={{"color":'red'}}>{error?error:''}</p></div>
            <div className="col  d-flex justify-content-end">
            {!loading &&   <button className="btn pro-spaces-button3" onClick={(e)=>validator1(e)}>Add</button> }
            {loading &&   <button className="btn btn-success" disabled><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button> }

            </div>
        </div>
            

        </div>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    </div>
}