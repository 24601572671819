import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import React, { createContext, useEffect, useState } from "react";
import Axios from "axios";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { keyGen } from "../../../../Utility/helper";
import validator from 'validator'

import { print_state, print_city, state_arr, s_a } from '../../../../../Utility/stateCity'
import MapWithSwitch from "../../../../AdminMap/MapWithSwitch";
export let EditContext = createContext()

export default function Fuel_Stations_Edit() {
    let navigate = useNavigate()



    useEffect(() => {
        // props.Auth()
    }, [])
    const notify = (message, sab) => {
        if (sab) {
            toast.success(message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            toast.warning(message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };




    let [searchParams, setSearchParams] = useSearchParams();  /////getting id from the url the id of the jobs id
    var id = searchParams.get("pi")
    // var id = "62b5b8c5b44ac0fe19a83e77"

    // setValue({...value,id:id})
    useEffect(() => {
        console.log(id)

    }, [])


    const [value, setValue] = useState({
        id: "",
        name: "",
        phone: "",
        phone2: "",
        state: "",
        city: "",
        email: "",
        link: "",
        pincode: "",
        address: '',
        locations: [],
    });
    const [Location, setLocation] = useState({ lat: '', lng: '' })
    const [LocationOn, setLocationOn] = useState(false)




    const [Stateload, setStateload] = useState(true)

    function Cityshow(e) {
        var index = document.getElementById(e.target.id).selectedIndex;
        print_city("City", index);
        setValue({ ...value, state: e.target.value });
    }


    useEffect(() => {
        if (Stateload)
            print_state("State")

        setStateload(false)
    }, [])

    const Statefix = () => {
        const City = document.getElementById('City').value
        const Statee = document.getElementById('State').value
        setValue({ ...value, state: Statee, city: City })
    }



    const [Error, setError] = useState(false)

    const [error, seterror] = useState(false);




    const [loading, setloading] = useState(false)

    function up1(e) {

        setloading(true)

        console.log(value)
        if (!value.name) return seterror("Name is missing !");
        if (value.state.length === 0) return seterror("State is missing !");
        if (value.city.length === 0) return seterror("City is missing !");
        if (value.pincode) {
            if (value.pincode.toString().length != 6) return seterror(" Enter valid Pin code is missing !");

        }

        if (!value.phone) {
            return seterror('Enter your phone number')
        }
        // if (value.phone.toString().length != 10) {
        //     return seterror('Enter a valid mobile number')
        // }

        // if (validator.isEmail(value.email)) {
        //     seterror('')
        //   } else {
        //     return seterror('Enter valid Email!')
        //   }

        //   if (validator.isURL(value.link)) {
        //     // setErrorMessage('Is Valid URL')
        //      seterror('')
        //   } else {
        //     seterror('Is Not Valid URL')
        //   }
        var me=false
        if(Location.lat&&Location.lng)
        {
            me=true
        }

            var data={
                value,
                Location,
                LocationOn:me
            }
console.log(data)
        Axios.post('/api/Admin/Fuel_Stations_Edit', { data })
            .then(res => {
                if (res.status == 200) {
                    // console.log('internupdate ')
                    console.log(res.data)
                    // return navigate(`/home/jobseeker_view?k=${id}`)
                    notify("Post updated successfully", true);
                    setloading(false)

                    setTimeout(() => {

                        setloading(false)

                        return navigate(`/fuel_stations`)
                    }, 1000);
                }

            }).catch((err) => {
                console.log(err.response.data)
                notify("Something went wrong", false);


            })
        console.log('first')



    }







    const [Details, setDetails] = useState(false)



    const [loader, setLoader] = useState(<div id='iooioooo1231312' className='container  d-flex mt-5 pt-5  justify-content-center p-3 p-md-5'><div class="spinner-border text-dark" role="status">
        <span class="visually-hidden">Loading...</span>
    </div></div>)
    const [Loading, setLoading] = useState(true)
    // var id = "625541f375d16debd14ced72"
    var Locationi
    var lstatui
    useEffect(() => {
        console.log("haiiiii")
        Axios.post('/api/Admin/Fuel_Stations_EditView', { id })
            .then(res => {
                if (res.status === 200) {
                    console.log('jobView Edit')
                    console.log(res.data)
                    // console.log(Id)
                    // setDetails(res.data);
                    setValue({
                        id: res.data._id,
                        name: res.data.Name,
                        phone: res.data.Phone,
                        phone2: res.data.Phone2,
                        state: res.data.State,
                        city: res.data.City,
                        email: res.data.Email,
                        link: res.data.Link,
                        pincode: res.data.Pincode,
                        address: res.data.Address,
                        locations: res.data.locations,
                    })
                    setLocation({
                        lat: res.data.Location?.lat, lng: res.data.Location?.lng,status:res.data.Location_status
                    })
                    setLocationOn(res.data.Location_status)
                    Locationi = { lat: res.data.Location?.lat, lng: res.data.Location?.lng }
                    lstatui = res.data.Location_status
                    console.log(Locationi, lstatui)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log(err)
                alert("something went wrong");
                setLoading(false)

            })
    }, [])

    const Eyod = (e) => {
        console.log('f')
        console.log(e.target.checked)
        if (e.target.checked) {
            console.log('f1')
            document.getElementById('EndY').classList.add('d-none')
        } else {
            console.log('f2')
            document.getElementById('EndY').classList.remove('d-none')
        }
    }



    function print_state(state_id) {
        // given the id of the <select> tag as function argument, it inserts <option> tags
        var option_str = document.getElementById(state_id);
        option_str.length = 0;
        option_str.options[0] = new Option('Select State', '');
        option_str.selectedIndex = 0;
        for (var i = 0; i < state_arr.length; i++) {
            option_str.options[option_str.length] = new Option(state_arr[i], state_arr[i]);
        }
    }
    function print_city(city_id, city_index) {
        var option_str = document.getElementById(city_id);
        option_str.length = 0;
        option_str.options[0] = new Option('Select City', '');
        option_str.selectedIndex = 0;
        var city_arr = s_a[city_index].split("|");
        for (var i = 0; i < city_arr.length; i++) {
            option_str.options[option_str.length] = new Option(city_arr[i], city_arr[i]);
        }

    }

    const [tri, settri] = useState(false)
    useEffect(() => {

        if (value.state.length >= 1) {
            if (!tri) {
                settri(true)
                var index = state_arr.indexOf(value.state)
                // var index = 2
                print_city('City', index + 1)
                console.log(index)
            }

        }

    }, [value.state])





    useEffect(() => {
        print_city('City', document.getElementById('State').selectedIndex)
    }, [value.state])


useEffect(() => {
console.log(Location)

  return () => {
    
  }
}, [Location])


    return (
        <EditContext.Provider value={{ Edit: true, Location, LocationOn }}  >

            <div className="container pt-5 mt-3">
                <h1> Fuel Stations Update</h1>
                <div className='row'>
                    <div className='col'><label className="labels">Name *</label></div><div className='col'><input className="form-control" type="text" value={value.name}
                        onChange={(e) =>
                            setValue({ ...value, name: e.target.value })
                        }></input></div>
                </div><br />

                <div className='row'>
                    <div className='col'> <label className="labels">State *</label></div><div className='col'> <select
                        className="form-select"
                        value={value.state}
                        id="State"
                        name="City"
                        onChange={(e) => Cityshow(e)}
                    >
                        {/* <select onChange={(e) =>}  className="form-select" >
                        </select> */}
                    </select></div>
                </div><br />
                <div className='row'>
                    <div className='col'> <label className="labels">City *</label></div><div className='col'>
                        <select
                            className="form-select"
                            name="City"
                            value={value.city}
                            id="City"
                            onChange={(e) =>
                                setValue({ ...value, city: e.target.value })
                            }
                        >
                            <option>Select City</option>
                        </select></div>
                </div><br />
                <div className='row'>
                    <div className='col'><label className="labels">PIN Code</label></div><div className='col'><input type="number" className="form-control" value={value.pincode}
                        onChange={(e) =>
                            setValue({ ...value, pincode: e.target.value })
                        }></input></div>
                </div><br />
                <div className='row'>
                    <div className='col'>  <label className="labels">Contact Number *</label></div><div className='col'>
                        <input type="number" value={value.phone} className="form-control"
                            onChange={(e) => {  setValue({ ...value, phone: e.target.value }) }
                            }></input></div>
                </div><br />
                {/* <div className='row'>
                    <div className='col'>  <label className="labels">Contact Number 2</label></div><div className='col'>
                        <input type="number" value={value.phone2} className="form-control"
                            onChange={(e) => { if (e.target.value.length == 11) return false; setValue({ ...value, phone2: e.target.value }) }
                            }></input></div>
                </div><br /> */}
                {/* <MapWithSwitch Location={Location} EditContext={EditContext}  setLocation={setLocation} LocationOn={LocationOn} setLocationOn={setLocationOn} key={"aouhduahdiuqbduiqderhetjyvq65564"} /> */}
                {/* <br></br> */}
                <div className='row'>
        <div className='col'>  <label className="labels">Address</label></div><div className='col'>
          <textarea value={value.address} className="form-control"
                onChange={(e) =>
                  setValue({ ...value, address: e.target.value })
                }></textarea>
       </div>
    </div><br />
    {/* <div className='row'>
        <div className='col'><label className="labels">Email id</label></div><div className='col'><input type="text" className="form-control"   value={value.email}
                onChange={(e) =>
                  setValue({ ...value, email: e.target.value })
                }></input></div>
    </div><br />


    <div className='row'>
        <div className='col'><label className="labels">Website&nbsp;/&nbsp;App </label></div><div className='col'><input type="text" className="form-control"   value={value.link} placeholder="Link"
                onChange={(e) =>
                  setValue({ ...value, link: e.target.value })
                }></input></div>
    </div><br /> */}




                <div className="row">
                    <div className="col"><p style={{ "color": 'red' }}>{error ? error : ''}</p></div>
                    <div className="col">
                        {!loading && <button className="btn pro-spaces-button3" onClick={(e) => up1(e)}>Update</button>}
                        {loading && <button className="btn btn-success" disabled><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>}

                    </div>
                </div>
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </EditContext.Provider>
    )
}
