import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiReset,BiRupee,BiDotsVerticalRounded, } from "react-icons/bi";

import { print_state, print_city, state_arr } from '../../../Utility/stateCity'
import {OTTPlatformList,DTHOperator ,DTHPlans } from '../../../Utility/helper'

import Spinner from 'react-bootstrap/Spinner';
import { Link } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { GrMap,  } from "react-icons/gr";
import { AiOutlineLink,  } from "react-icons/ai";
import Table from 'react-bootstrap/Table';
import { DataGrid } from '@mui/x-data-grid';



export function DTH(props) {
  useEffect(() => {
    // props.Auth();
  }, []);

  const [show, setShow] = useState(false);
const [Id, setId] = useState();
const handleShow = () => setShow(true);

  const handleClose = () =>{
    setShow(false);
    setId('')

  } 

  const [load, setload] = useState(false);
  const [error, seterror] = useState("");
  const [output, setoutput] = useState([]);
  const [Keyer, setKeyer] = useState(1)
  const [Stateload, setStateload] = useState(true);
  const [rows, setrows] = useState([])

  const [value, setValue] = useState({
    state: "",
    city: "",
    pincode:"",

  });


  const Reset = (e) => {
    document.getElementById("searchForm").reset();
    setValue({ ...value, city: "", state: "", WE: !value.WE, pincode: "" });
    // Club();
    GetDTH()
    
  };
  const GetDTH = (last) => {
    setload(true)
    console.log('page')
    axios.get("/api/User/GetDTH").then((response) => {
      setrows([])
        setload(false)
        console.log('heree')
        console.log(response)
        response.data.map((e,index)=>{
          setrows(prevRows => [...prevRows, {...e, id: index+1}])
        })
        setoutput(response.data)
    }).catch(
        (res) => {
            setload(false)
            console.log('Something went wrong')
           
        }
    )
   
}

useEffect(() => {
  GetDTH()
}, [])

const FindDTH = (last) => {
    // console.log(page)
    setload(true)

    axios.post("/api/User/FindDTH",{value}).then((response) => {
      setrows([])
        
        setload(false)
        console.log('first')
        console.log(response.data)
        response.data.map((e,index)=>{
          setrows(prevRows => [...prevRows, {...e, id: index+1}])
        })
        setoutput(response.data)
    }).catch(
        (res) => {
            setload(false)
            console.log('Something went wrong')
          
        }
    )
   
}
const [index, setindex] = useState()
const [Ids, setIds] = useState()

function stepDelete(Id,index) {
  setIds(Id)
  setindex(index)
  handleShow()
}
const Delete=()=>{
  console.log(Ids)
  // return
  var id = Ids
  axios.post("/api/Admin/DTH_Delete", { id }).then((response) => {
      // console.log('success')
      if (response.status === 200) {
      setrows([])
      GetDTH()
          let temp = [...output]
          temp.splice(index, 1)
          setindex(undefined)
          setId('')
          setoutput(temp)
          handleClose()

      }
  }).catch(
      res => {
          console.log('Something went wrong')
      }
  )
}
const columns = [
  { field: 'id', headerName: 'No .', width: 70,  headerAlign: 'center',
  align: 'center' },
  { field: 'Dth_Platform', headerName: 'DTH provider', width: 200,  headerAlign: 'center',
  align: 'center' },
  { field: 'Dth_Plans', headerName: 'Region / Language', width: 175,  headerAlign: 'center',
  align: 'center' },
  { field: 'Name', headerName: 'Plan', width: 175,  headerAlign: 'center',
  align: 'center' },
  {
    field: 'One_month',
    headerName: 'One month'+` (\u20B9)`,
    type: 'number',
    width: 150,
    headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Three_months',
    headerName: 'Three months'+` (\u20B9)`,
    // sortable: false,
    width: 150,
    type: 'number',  headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Six_months',
    headerName: 'Six months'+` (\u20B9)`,
    // sortable: false,
    width: 150,
    type: 'number',  headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'One_year',
    headerName: 'One year'+` (\u20B9)`,
    // sortable: false,
    width: 150,
    type: 'number',  headerAlign: 'center',
    align: 'center'
  },
  {
    field: 'Edit',
    headerName: 'Update',
    sortable: false,
    width: 90,  headerAlign: 'center',
    align: 'center',
    renderCell: (params) => (
      <Link to={"/dth_update?pi=" +params.row._id}>
        <button className="btn pro-spaces-button3" >
           Update
        </button>
      </Link>
    ),
  },
  {
    field: 'Delete',
    headerName: 'Delete',
    sortable: false,
    width: 90,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => (
      <button className="btn btn-danger" onClick={(e)=>stepDelete(params.row._id,params.row.id-1)}>
        Delete
      </button>
    ),
  },
];





  return (
    <div className="container-fluid bg-trasparent pro-sabari wieugfiqgefnqenc2rnewiuhgiuhq ">

<div> <div className="d-flex justify-content-end">
<Link to="/dth_create">
    <button className="btn btn-dosabutton ps-3 pe-3 mb-3 deghievgviehcieh" >Add New</button>
    </Link>
       </div>
       </div>

     
      <div id="AdvancedSearch" className=" row bg-light  p-2">
        <div>
          <p>Advanced Search Filters</p>
        </div>
        <form id="searchForm">
          <div className="col-12 ">
            <div className="row g-1">
              <div className="col-6 col-lg-4">
                <div className="form-floating">
                {/* <select
                  className="form-select   "
                  value={value.state}
                  id="State"
                  name="City"
                  onChange={(e) => Cityshow(e)}
                ></select>
                 <label htmlFor="State">State</label> */}
                 
                 <select value={value.state}onChange={(e) => { setValue({ ...value, state:e.target.value}) }} className="form-select" >
                  <option></option>
                  {
                        DTHOperator.map((m)=>{
                            return <option value={m}>{m}</option>
                        })

                       }
                 </select>
                 <label htmlFor="State">DTH provider</label>

                 </div>
              </div>
              <div className="col-6 col-lg-4">
              <div className="form-floating">
              <select value={value.city}onChange={(e) => { setValue({ ...value, city:e.target.value}) }} className="form-select" >
                  <option></option>
                  {
                        DTHPlans.map((m)=>{
                            return <option value={m}>{m}</option>
                        })

                       }
                 </select>
                 <label htmlFor="State">Region / Language</label>
                </div>
              </div>
           
              <div className="col-12 col-md-12 mb-1 mt-2 col-lg-4">
                {/* <div className="form-floating">
                <input
                  type="number"
                  className="form-control"
                  name="pin"
                  value={value.pincode}
                  id="a"
                  onChange={(e) =>
                    setValue({ ...value, pincode: e.target.value })
                  }
                ></input>
                <label htmlFor="a">Pin&nbsp;code</label>
                </div> */}
                <div className=" d-flex justify-content-end">
                <div className="   me-2 ">
                <button
                  className="btn btnjui ps-4 pe-4 d-flex justify-content-center"
                  type="button"
                  onClick={(e) => Reset(e)}
                >
                  <BiReset size={24} />
                </button>
              </div>
              <button
                type="button"
                className="btn btnjui "
                onClick={(e) => FindDTH()}
              >
                Go
              </button>
                </div>

              </div>

            </div>
          </div>
          <div className="col-12 col-md-12 mb-1 mt-2">
            {/* <div className=" d-flex justify-content-end">
              <div className="   me-2 ">
                <button
                  className="btn btnjui ps-4 pe-4 d-flex justify-content-center"
                  type="button"
                  onClick={(e) => Reset(e)}
                >
                  <BiReset size={24} />
                </button>
              </div>
              <button
                type="button"
                className="btn btnjui "
                onClick={(e) => FindAmbuianceAnimal()}
              >
                Go
              </button>
            </div> */}

            <span style={{ color: "red" }}>{error ? error : ""}</span>
          </div>
        </form>
      </div>

      <div className="row car mt-1"> 
      {
          load?
             <center>
                  <Spinner animation="border" />
             </center>
           : 
           output.length>0?
           <div className="container bg-light">
     
  <>
       <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}

        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        // checkboxSelection
      />
    </div>

        </>

      :
      <></>
           </div>
           :
           <></>
      }
      

      {
        output.length>0?<></>:<h3><center>No results found !!</center></h3>
      }
      </div>
    
      <Modal show={show} onHide={handleClose} backdrop={false} id={index} centered >
        <Modal.Header closeButton>
          <Modal.Title>Deleting post </Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure?</Modal.Body>
        <Modal.Footer>
      
          <button className="btn pro-spaces-button3" onClick={(e)=>Delete()}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>


      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
{/* <Modal show={show} onHide={handleClose} backdrop={false} id={index} aria-labelledby="contained-modal-title-vcenter"centered >
<Modal.Header closeButton>
  <Modal.Title>Deleting post </Modal.Title>
</Modal.Header>
<Modal.Body>Are you sure?</Modal.Body>
<Modal.Footer>

  <button className="btn pro-spaces-button3" onClick={(e)=>Delete(elem._id)}>
    Delete
  </button>
</Modal.Footer>
</Modal>
</> */}