import React, { useState, useEffect,createContext
 } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { print_state, print_city, } from '../../../../../Utility/stateCity'
import {DTHOperator ,DTHPlans} from '../../../../../Utility/helper'
export let EditContext = createContext()


export default function DTH_create() {
    const [Stateload, setStateload] = useState(true);
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState(false);
    const notify = (message, sab) => {
      if (sab) {
        toast.success(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.warning(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };



    const [value, setValue] = useState({
      Dth_Platform:'',
      Dth_Plans:'',
      Name:"",
      One_month:'',
      Three_months:'',
      Six_months:'',
      One_year:'',
      Name:'',

          
      });
      const [Location, setLocation] = useState({lat:'',lng:''})
      const [LocationOn, setLocationOn] = useState(false)

    
      // function Cityshow(e) {
      //   var index = document.getElementById(e.target.id).selectedIndex;
      //   print_city("City", index);
      //   setValue({ ...value, state: e.target.value });
      // }
    
  
      // useEffect(() => {
      //   if (Stateload) print_state("State");
      //   setStateload(false);
      // }, []);


      const dasapppan= async(e)=>{

        if (value.Dth_Platform.length === 0) return seterror(" Dth provider is missing !");
        if (value.Dth_Plans.length === 0) return seterror("Dth plan is missing !");
        if (value.Name.length === 0) return seterror("Dth plan name is missing !");

  

    

     
   
        setloading(true)
        var data={
          value,
          Location,
          // LocationOn:Location_status
        }
        let sab = await  axios.post('/api/Admin/DTH_Reg',{
          data
      }).then((res)=>{
        if(res.status==200){
          notify("Successfully added", true);
         setValue({
          Dth_Platform:'',
          Dth_Plans:'',
          Name:"",
          One_month:'',
          Three_months:'',
          Six_months:'',
          One_year:'',
          Name:"",
         })
         setLocation({lat:'',lng:''})
         setLocationOn()
         seterror('')

          
        }
      }).catch((err)=>{
        console.log(err)
        notify(" Something went wrong", false);
         seterror('')
         setLocationOn()

      })
      setloading(false)
          
      }

  return (
  

    <div className="container pt-5 mt-3">
    <h1>DTH</h1>     
    <div className='row'>
        <div className='col'> <label className="labels">DTH Operator *</label></div>
        <div className='col'> 
        <select value={value.Dth_Platform} onChange={(e)=>{setValue({...value,Dth_Platform:e.target.value})}} className="form-select" >
                  <option></option>
                  {
                        DTHOperator.map((m)=>{
                            return <option value={m}>{m}</option>
                        })

                       }
                 </select>
  
       </div>
    </div><br />

    <div className='row'>
        <div className='col'> <label className="labels">DTH Plans *</label></div>
        <div className='col'> 
        <select value={value.Dth_Plans} onChange={(e)=>{setValue({...value,Dth_Plans:e.target.value})}} className="form-select" >
                  <option></option>
                  {
                        DTHPlans.map((m)=>{
                            return <option value={m}>{m}</option>
                        })

                       }
                 </select>
       </div>
    </div><br />

    <div className='row'>
        <div className='col'><label className="labels">Plan name * </label></div><div className='col'><input type="text" className="form-control"  
         value={value.Name}
                onChange={(e) =>
                 { setValue({ ...value, Name: e.target.value })}
                }></input></div>
    </div><br />

    <div className='row'>
        <div className='col'><label className="labels">One month </label></div><div className='col'><input type="number" className="form-control"  
         value={value.One_month}
                onChange={(e) =>
                 {if(e.target.value.length==10) return false; setValue({ ...value, One_month: e.target.value })}
                }></input></div>
    </div><br />
    <div className='row'>
        <div className='col'>  <label className="labels">Three months </label></div><div className='col'>
        <input type="number"  value={value.Three_months} className="form-control"
                onChange={(e) =>
                 {if(e.target.value.length==10) return false; setValue({ ...value, Three_months: e.target.value })}
                }></input></div>
    </div><br />
    <div className='row'>
        <div className='col'>  <label className="labels">Six months </label></div><div className='col'>
        <input type="number"  value={value.Six_months} className="form-control"
                onChange={(e) =>
                 {if(e.target.value.length==10) return false; setValue({ ...value, Six_months: e.target.value })}
                }></input></div>
    </div><br />
    <div className='row'>
        <div className='col'>  <label className="labels">One year </label></div><div className='col'>
        <input type="number"  value={value.One_year} className="form-control"
                onChange={(e) =>
                 {if(e.target.value.length==10) return false; setValue({ ...value, One_year: e.target.value })}
                }></input></div>
    </div><br />
    <div className="row">
        <div className="col"><p style={{"color":'red'}}>{error?error:''}</p></div>
        <div className="col">
        {!loading &&   <button className="btn pro-spaces-button3" onClick={(e)=>dasapppan(e)}>Add</button> }
        {loading &&   <button className="btn btn-success" disabled><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button> }

        </div>
    </div>
    <ToastContainer
      position="bottom-left"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
</div>


  )
}
