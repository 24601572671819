
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { FaShareAlt } from "react-icons/fa";
// import "./Pview.css";
import axios from "axios";

import { Link } from "react-router-dom";
// import "./Edit";
import { MdMyLocation } from "react-icons/md";

// import Portfolio from "./portfolio";
// import Offerportfolio from "./Offerportfolio";
import { useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { Timelines } from "../../../Components/Timeline/Timeline";

import { StaticGoogleMap, Marker, Path } from "react-static-google-map";
import { Mask } from "../../../Utility/mask";
// import AddOn from "./addAnother";
import { PersonalInformationStaticMedical_Store } from "./Profile_components/Medical_Store/PersonalInformationStaticMedical_Store";
import { BussinessInformationVendorMedical_Store } from "./Profile_components/Medical_Store/BussinessInformationVendorMedical_Store";
import { ContactInformationVendorMedical_Store } from "./Profile_components/Medical_Store/ContactInformationVendorMedical_Store";
function Information_Medical_Store_profile(props) {
  useEffect(() => {
    // props.Auth();
  }, []);

  let [searchParams, setSearchParams] = useSearchParams();
  var id = searchParams.get("k")

  let success = searchParams.get("success");

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    // props.Auth();

    if (success) {
      notify("Profile Updated", true);
      console.log("updated");
    }
  }, []);

  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const [shareshow, setshareshow] = useState(false);


  useEffect(() => {
    axios
      .post("/api/User/Information_Medical_Store_single", {id})
      .then((res) => {
        setGetval(res.data);
        console.log(res.data);

        // console.log(res.data[0])
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [getval, setGetval] = useState(false);
  const [dopper, setDooper] = useState(false);
  const [filedel, setFiledel] = useState([]);
  const [forume, setforume] = useState();

  const [type, settype] = useState();
  useEffect(() => {
    if (getval.formType === 1) {
      settype(true);
    } else if (getval.formType === 2) {
      settype(false);
    }
  }, [getval]);

  function keyGen() {
    var length = 5;
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  useEffect(() => {
    console.log(forume);
  }, [forume]);

  const [first8, setfirst8] = useState(false);

  const [url, setUrl] = useState("");


  return (
    <>
      {/* <NavBar /> */}
      <div>
        <div className="container emp-profile border ">
          <div className="row">
            <div className="col-md-4">
              <div className="profilee-img">
                {
                 getval.link?
                 <img
                 id="DP"
                 className="img-fluid"
                 width="100px"
                 height="100px"
                 src={getval.link}
                 alt=""
                 onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src =
                      "/blank.png";
              }}
               />
                 :""
                }

              </div>
            </div>
            <div className="col-md-8">
              <div className="profile-head mt-3">
                <div className="d-none d-md-block">
                  {" "}
                  <h5 className="mb-1">
                    {getval.pname ? getval.pname.toUpperCase() : ""}
                  </h5>
                  {/* <p className="mt-0 pt-0 mb-2">
                    {getval?.taglineCheck
                      ? getval?.brandtagline
                        ? getval.brandtagline
                        : ""
                      : ""}
                  </p> */}
                </div>
                <div className="d-block d-md-none">
                  {" "}
                  <center>
                    <h5 className="mb-1">
                      {getval.pname ? getval.pname.toUpperCase() : ""}
                    </h5>
                    {/* <p className="mt-0 pt-0 mb-2">
                      {getval?.taglineCheck
                        ? getval?.brandtagline
                          ? getval.brandtagline
                          : ""
                        : ""}
                    </p> */}
                  </center>
                </div>
 
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-none d-md-block">
                      <h6>
                        {getval.subcategory?.sub ? getval.subcategory?.sub : ""} |{" "}
                        {getval?.category?.category ? getval?.category?.category : ""}
                        <br />
                      </h6>
                    </div>
                    <div className="d-block d-md-none">
                      <center>
                        {" "}
                        <h6>
                          {getval.subcategory?.sub ? getval.subcategory?.sub : ""} |{" "}
                          {getval?.category?.category? getval?.category?.category : ""}
                          <br />
                        </h6>
                      </center>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col"></div>
                </div>
              </div>
            </div>
            <center className="pt-4">
              <div
                className=""
                style={{ maxWidth: "800px", textAlign: "left" }}
              >
                {/* <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography sx={{ width: "100%", flexShrink: 0 }}>
                      <b>
                        {getval
                          ? type
                            ? "Catalogue of Products"
                            : "Catalogue of Projects"
                          : "Catalogue"}
                      </b>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Portfolio type={type} />
                    </Typography>
                  </AccordionDetails>
                </Accordion> */}
                {/* <Accordion
                  expanded={expanded === "panel1092"}
                  onChange={handleChange("panel1092")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography sx={{ width: "100%", flexShrink: 0 }}>
                      <b>
                        NEWLY LAUNCHED{" "}
                        {getval ? (type ? "Product" : "Project") : ""}
                      </b>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <NewLaunchportfolio type={type} />
                    </Typography>
                  </AccordionDetails>
                </Accordion> */}
                {/* <Accordion
                  expanded={expanded === "panel109"}
                  onChange={handleChange("panel109")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography sx={{ width: "100%", flexShrink: 0 }}>
                      <b>
                        Exclusive <span className="">OFFERS / DISCOUNTS</span>
                      </b>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Offerportfolio type={type} />
                    </Typography>
                  </AccordionDetails>
                </Accordion> */}
                {
                  getval.Description?
                  <Accordion
                  expanded={expanded === "panelewrg4"}
                  onChange={handleChange("panelewrg4")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography sx={{ width: "100%", flexShrink: 0 }}>
                      <b>Product Description</b>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <p
                        style={{ whiteSpace: "pre-line" }}
                        className="py-2"
                      >
                        {getval.Description}
                      </p>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                  :""
                }
                <PersonalInformationStaticMedical_Store getval={getval} />
                <BussinessInformationVendorMedical_Store getval={getval} />
                <ContactInformationVendorMedical_Store getval={getval} />

              </div>
            </center>
          </div>
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-8"></div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default Information_Medical_Store_profile;



