import { useEffect, useState } from 'react';
import Axios from 'axios';
import { SpinnerBig } from '../../../Utility/spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

export const Prochure_Ask_Us_List = (props) => {
  const [Loading, setLoading] = useState(true);
  const [Feedback, setFeedback] = useState([]);
  const [toggle, settoggle] = useState('');
  const get = () => {
    Axios.get('/api/Admin/FeedbackView')
      .then((res) => {
        if (res.status === 200) {
          console.log('Feedback View');
          console.log(res.data);
          setFeedback(res.data);
          setLoading(false);
          props.callAskCount();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getgrievance = () => {
    Axios.get('/api/Admin/getgrievance')
      .then((res) => {
        if (res.status === 200) {
          console.log('Feedback View');
          console.log(res.data);
          setFeedback(res.data);
          setLoading(false);
          props.callAskCount();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getinvoice = () => {
    Axios.get('/api/Admin/getinvoice')
      .then((res) => {
        if (res.status === 200) {
          console.log('Feedback View');
          console.log(res.data);
          setFeedback(res.data);
          setLoading(false);
          props.callAskCount();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getquery = () => {
    Axios.get('/api/Admin/getquery')
      .then((res) => {
        if (res.status === 200) {
          console.log('Feedback View');
          console.log(res.data);
          setFeedback(res.data);
          setLoading(false);
          props.callAskCount();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getsuggestion = () => {
    Axios.get('/api/Admin/getsuggestion')
      .then((res) => {
        if (res.status === 200) {
          console.log('Feedback View');
          console.log(res.data);
          setFeedback(res.data);
          setLoading(false);
          props.callAskCount();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    if (toggle == 'GRIEVANCE') {
      getgrievance();
    } else if (toggle == 'INVOICE RELATED') {
      getinvoice();
    } else if (toggle == 'QUERY') {
      getquery();
    } else if (toggle == 'SUGGESTION') {
      getsuggestion();
    }
  }, [toggle]);

  var columns = [
    {
      field: 'id',
      headerName: 'No. ',
      width: 60,
    },
    {
      field: 'Name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'Option',
      headerName: 'Subject',
      width: 200,
    },
    {
      field: 'Status',
      headerName: 'Status',
      width: 200,
      renderCell: (params) => {
        return (
          <>
            {params.row.Status == 'Pending' ? (
              <span style={{ color: 'red' }}>Pending</span>
            ) : (
              <span style={{ color: 'green' }}>Success</span>
            )}
          </>
        );
      },
    },
    {
      field: 'Date_on',
      headerName: 'Created On',
      width: 200,
    },
    {
      field: 'reply_on',
      headerName: 'Reply On',
      width: 200,
    },

    {
      field: 'View',
      headerName: 'View',
      width: 150,
      renderCell: (params) => {
        return (
          <Link to={`/ask_us_view?k=${params.row._id}`}>
            <button className="btn pro-spaces-button3">View</button>
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <div className="bg-light mt-4">
        <div className="py-1">
          <button
            className="btn pro-spaces-button3"
            onClick={(e) => settoggle('INVOICE RELATED')}
          >
            INVOICE&nbsp;RELATED
          </button>
        </div>
        <div className="py-1">
          <button
            className="btn pro-spaces-button3"
            onClick={(e) => settoggle('QUERY')}
          >
            QUERY
          </button>
        </div>
        <div className="py-1">
          <button
            className="btn pro-spaces-button3"
            onClick={(e) => settoggle('SUGGESTION')}
          >
            SUGGESTION
          </button>
        </div>
        <div className="py-1">
          <button
            className="btn pro-spaces-button3"
            onClick={(e) => settoggle('GRIEVANCE')}
          >
            GRIEVANCE
          </button>
        </div>
        <div className="d-flex justify-content-end p-2">
          {/* <button className="btn pro-spaces-button3" onClick={(e) => get()}>
            Refresh{' '}
          </button>{' '} */}
        </div>
        {Loading ? <SpinnerBig></SpinnerBig> : ''}
        {Feedback && (
          <Box sx={{ height: 700, width: '100%' }}>
            <DataGrid
              rows={Feedback}
              columns={columns}
              // Other DataGrid props...
            />
          </Box>
        )}
      </div>
    </>
  );
};
