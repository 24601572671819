import React, { useState } from 'react';
import ExportableTable from '../../Utility/ExportableTable';

export const Salessme = () => {
  return (
    <>
      <ExportableTable />
    </>
  );
};
