import React, { useState, useEffect, useRef } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import { AiFillCamera,AiFillExclamationCircle } from 'react-icons/ai'
// import './Edit.css'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import axios from 'axios'
import { useNavigate,useSearchParams } from 'react-router-dom'
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormConsultent } from './FormConsultent';



const defaultSrc =
  "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";


function EditConsultantV1(props) {

  let navigate = useNavigate()
  const formData = new FormData()
  useEffect(() => {
    // props.Auth()
  }, [])
  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }




  // useEffect(() => {
  //   axios.post('/api/ActiveUser/ConProfile', {

  //   }).then((res) => {
  //     // setGetval(res.data[0])
     
     

  //     // setValue({
  //     //   ...value,
  //     //   pname:res.data[0].pname?res.data[0].pname:'',
  //     //   firstname: res.data[0].firstname,
  //     //   lastname: res.data[0].lastname,
  //     //   address1: res.data[0].address1,
  //     //   state: res.data[0].state,
  //     //   city: res.data[0].city,
  //     //   pincode: res.data[0].pincode,
  //     //   email: res.data[0].email,
  //     //   website: res.data[0].website,
  //     //   gender: res.data[0].gender,
  //     //   consultantType: res.data[0].consultantType?res.data[0].consultantType:'',
  //     //   WomanOwned: res.data[0].WomanOwned?res.data[0].WomanOwned:false,
  //     //   dob: res.data[0].dob,
  //     //   category: res.data[0].category,
  //     //   subcategory: res.data[0].subcategory,
  //     //   TypeofConsultation: res.data[0].TypeofConsultation?res.data[0].TypeofConsultation:'',
  //     //   yearofb: res.data[0].yearofb?res.data[0].yearofb:'',
  //     //   qualification: res.data[0].qualification,
  //     //   univesity: res.data[0].univesity,
  //     //   yearofpassing: res.data[0].yearofpassing,
  //     //   licence: res.data[0].licence,
  //     //   currentorganization: res.data[0].currentorganization,
  //     //   previousorganization: res.data[0].previousorganization,
  //     //   specialization: res.data[0].specialization,
  //     //   serviceprovided: res.data[0].serviceprovided,
  //     //   experience: res.data[0].experience?res.data[0].experience:'',
  //     //   skills: res.data[0].skills,
  //     //   language: res.data[0].language,
  //     //   link: res.data[0].link?res.data[0].link:'',
  //     //   gst: res.data[0].gst?res.data[0].gst:'',
  //     //   chif: res.data[0].chif?res.data[0].chif:'',
  //     //   contact: res.data[0].contact?res.data[0].contact:'',
  //     //   contact2: res.data[0].contact2?res.data[0].contact2:'',
  //     //   image: ''
  //     // })
  //   }).catch((err) => {
  //     console.log(err)
  //   })
  // }, [])
  const submit = async (formData) => {
  
    return new Promise((resolve,reject)=>{
      axios.put('/api/ActiveUser/ConProfile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }).then((res) => {
        notify("Profile Updated", true);
        navigate('/Consultants')
        resolve(true)
      }).catch((err) => {
        console.log(err)
        notify("Something went wrong", false);
        resolve(false)
      })
    })
      // console.log('get')
   
}


  return (
    <div className="container rounded bg-white pt-5 pb-5">


     <FormConsultent postTo={submit} edit={true} sab={true} get='/api/ActiveUser/ConProfile' />




      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default EditConsultantV1
