import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { saveAs } from 'file-saver';
import TableComponent from './TableComponent';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

const ExportableTable = () => {
  const [datas, setdatas] = useState([]);
  const [search, setSearch] = useState('');
  const [searchmonthyear, setSearchmonthyear] = useState('');

  const [displaydate, setDisplaydate] = useState('');

  const handleSearch = () => {
    // Call your search logic here or update the display value directly

    var temp = [...displaydate];
    console.log(temp);

    var result = temp.filter((e) => {
      if (e.Created_Date.indexOf(search) != -1) {
        return e;
      }
    });
    console.log(temp);
    console.log(result);
    setdatas(result);
  };
  const handleSearchmonthyear = () => {
    // Call your search logic here or update the display value directly

    var temp = [...displaydate];
    console.log(temp);

    var result = temp.filter((e) => {
      if (e.Created_Date.indexOf(searchmonthyear) != -1) {
        return e;
      }
    });
    console.log(temp);
    console.log(result);
    setdatas(result);
  };
  const handleClear = () => {
    setSearch('');
    setSearchmonthyear('');
    setdatas(displaydate);
  };

  const columns = [
    {
      Header: ' ID ',
      accessor: 'id',
    },
    {
      Header: ' Name ',
      accessor: 'Name',
    },
    {
      Header: ' State ',
      accessor: 'State',
    },
    {
      Header: ' City ',
      accessor: 'City',
    },
    {
      Header: ' Country ',
      accessor: 'Country',
    },
    {
      Header: ' Date ',
      accessor: 'Created_Date',
    },
    {
      Header: ' DiscountAmount ',
      accessor: 'DiscountAmount',
    },
    {
      Header: ' PaidAmount ',
      accessor: 'PaidAmount',
    },
    {
      Header: ' PaymentDate ',
      accessor: 'PaymentDate',
    },
  ];

  const handleExportToCSV = () => {
    const csvData = datas.map((item) => {
      console.log(item);
      return {
        ID: item.id,
        Name: item.Name,
        State: item.State,
        City: item.City,
        Country: item.Country,
        Date: item.Created_Date,
        DiscountAmount: item.DiscountAmount,
        PaidAmount: item.PaidAmount,
        PaymentDate: item.PaymentDate,
      };
    });

    // Filter out any undefined elements from csvData
    const validCSVData = csvData.filter((item) => item !== undefined);

    const csvHeaders = columns.map((column) => column.Header);
    const csvFormattedData = [
      csvHeaders,
      ...validCSVData.map((row) => csvHeaders.map((header) => row[header])),
    ];

    const csvString = csvFormattedData
      .map((row) => {
        return row.map((value) => `"${value}"`).join(',');
      })
      .join('\n');

    console.log({ csvString, csvFormattedData });

    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'table_data.csv');
  };

  const button = (
    <button className="btn pro-spaces-button3" onClick={handleExportToCSV}>
      Download
    </button>
  );

  const [params, setparams] = useSearchParams();
  var Acc_id = params.get('k');

  console.log(Acc_id);

  const submit = () => {
    axios
      .post('/api/AffiliatedAccount/smemonthlytarget', { Acc_id })
      .then((res) => {
        console.log(res.data);
        setdatas(res.data);
        setDisplaydate(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    submit();
    setLoading(false);
  }, []);

  const [loading, setLoading] = useState(true);

  return (
    <>
      {datas?.length ? (
        <>
          <div className="px-2 py-2  justify-content-end">
            <div className="mb-3">{button}</div>

            <div class=" input-group mb-3 ">
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                class="form-control "
                placeholder="format DD-MM-YYYY"
                aria-label="Date"
                aria-describedby="Date"
              />
              <button
                class="btn pro-spaces-button3"
                type="button"
                id="Date"
                onClick={handleSearch}
              >
                Search
              </button>
              <button
                class="btn btn-outline-secondary"
                type="button"
                id="Date"
                onClick={handleClear}
              >
                Clear
              </button>
            </div>
            <div class="input-group mb-3">
              <input
                value={searchmonthyear}
                onChange={(e) => setSearchmonthyear(e.target.value)}
                type="text"
                class="form-control"
                placeholder="format MM-YYYY"
                aria-label="Month/Year"
                aria-describedby="Month/Year"
              />
              <button
                class="btn pro-spaces-button3"
                type="button"
                id="Month/Year"
                onClick={handleSearchmonthyear}
              >
                Search
              </button>
              <button
                class="btn btn-outline-secondary"
                type="button"
                id="Date"
                onClick={handleClear}
              >
                Clear
              </button>
            </div>
          </div>
          <div className="px-4" style={{ overflow: 'scroll' }}>
            <TableComponent columns={columns} data={datas} />
          </div>
        </>
      ) : (
        <>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: '100vh' }}
          >
            Nothing to show
          </div>
        </>
      )}
    </>
  );
};

export default ExportableTable;
