import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RiCoupon2Line } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import {
  MdPersonOutline,
  MdHome,
  MdPerson,
  MdPersonAddAlt,
  MdFormatListBulletedAdd,
  MdReportGmailerrorred,
} from 'react-icons/md';

import { HiViewGridAdd, HiOutlineViewGridAdd } from 'react-icons/hi';
import { AiOutlineApi, AiOutlineExclamationCircle } from 'react-icons/ai';
import { HiOutlineViewList } from 'react-icons/hi';
import { BiMessageAltCheck, BiUser, BiRupee } from 'react-icons/bi';
import { RiAdvertisementFill } from 'react-icons/ri';
import { CiViewList } from 'react-icons/ci';

import { VscThreeBars } from 'react-icons/vsc';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Offcanvas from 'react-bootstrap/Offcanvas';

import './AdminDashbord.css';
import { AdminDashBord } from '../../../Components/AdminDashbordComponents/AdminDashBord/AdminDashBord';
import { ProductStore } from '../../../Components/AdminDashbordComponents/prodect/ProductStore';
import { Consultants } from '../../../Components/AdminDashbordComponents/consultants/Consultants';
import { Feedback } from '../../../Components/AdminDashbordComponents/Feedback/Feedback';
import { AdminPostApprovedHome } from '../../../Components/AdminDashbordComponents/AdminPostApproved/AdminHome/Home';
import { Coupon } from '../../../Components/AdminDashbordComponents/Coupon/Coupon';
import { CouponView } from '../../../Components/AdminDashbordComponents/CouponView/CouponView';
import { Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { PassiveMemberList1 } from '../../../Components/AdminDashbordComponents/Passive/PassiveMemberList';
import Service from '../../../Components/AdminDashbordComponents/Service/Service';

import EmergencyServices from '../../../Components/AdminDashbordComponents/Service/EMERGENCYSERVICES/EmergencyServices';
import { AmbulanceHumansReg } from '../../../Components/AdminDashbordComponents/Service/EMERGENCYSERVICES/AmbulanceServicesHumans/AmbulanceHumansReg';
import DeceasedAnimalBurialGroundsReg from '../../../Components/AdminDashbordComponents/Service/EMERGENCYSERVICES/DeceasedAnimalBurialGrounds/DeceasedAnimalBurialGroundsReg';
import AmbulanceAnimalsReg from '../../../Components/AdminDashbordComponents/Service/EMERGENCYSERVICES/AmbulanceServicesAnimals/AmbulanceServicesAnimalsReg';
import BloodDonorsReg from '../../../Components/AdminDashbordComponents/Service/EMERGENCYSERVICES/BloodDonors/BloodDonorsReg';
import Activists from '../../../Components/AdminDashbordComponents/Service/ACTIVISTS/Activists';
import ActivistAnimalReg from '../../../Components/AdminDashbordComponents/Service/ACTIVISTS/Animal/ActivistsAnimalReg';
import ActivistEnvironmentalReg from '../../../Components/AdminDashbordComponents/Service/ACTIVISTS/Environmental/ActivistsEnvironmentalReg';
import ActivistSocialReg from '../../../Components/AdminDashbordComponents/Service/ACTIVISTS/Social/ActivistsSocialReg';
import AmbulanceHumansEdit from '../../../Components/AdminDashbordComponents/Service/EMERGENCYSERVICES/AmbulanceServicesHumans/AmbulanceHumansEdit';
import AmbulanceServicesAnimalsEdit from '../../../Components/AdminDashbordComponents/Service/EMERGENCYSERVICES/AmbulanceServicesAnimals/AmbulanceServicesAnimalsEdit';
import BloodDonorsEdit from '../../../Components/AdminDashbordComponents/Service/EMERGENCYSERVICES/BloodDonors/BloodDonorsEdit';
import ActivistsAnimalEdit from '../../../Components/AdminDashbordComponents/Service/ACTIVISTS/Animal/ActivistsAnimalEdit';
import ActivistsEnvironmentalEdit from '../../../Components/AdminDashbordComponents/Service/ACTIVISTS/Environmental/ActivistsEnvironmentalEdit';
import ActivistsSocialEdit from '../../../Components/AdminDashbordComponents/Service/ACTIVISTS/Social/ActivistsSocialEdit';

import Categorys from '../../../Components/AdminDashbordComponents/categorys/Categorys';
import Consultantscategory from '../../../Components/AdminDashbordComponents/categorys/Consultantscategory';
import Vendorcategory from '../../../Components/AdminDashbordComponents/categorys/Vendorcategory';
import CategorysAddEdit from '../../../Components/AdminDashbordComponents/categorys/category/CategorysAddEdit';
import VendorCategorysAddEdit from '../../../Components/AdminDashbordComponents/categorys/category/VendorCategorysAddEdit';
import SubCategorysAddEdit from '../../../Components/AdminDashbordComponents/categorys/subcategory/ConsultentSubCat/SubCategorysAddEdit';
import VendorSubCategorysAddEdit from '../../../Components/AdminDashbordComponents/categorys/subcategory/VendorSubCat/VendorCategorysAddEdit';
import { AmbulanceServicesHumansList } from '../../../Components/AdminDashbordComponents/EmergencyServices/AmbulanceServicesHumans';
import { DeceasedAnimalBurialGroundsList } from '../../../Components/AdminDashbordComponents/EmergencyServices/DeceasedAnimalBurialGrounds';
import DeceasedAnimalBurialGroundsEdit from '../../../Components/AdminDashbordComponents/Service/EMERGENCYSERVICES/DeceasedAnimalBurialGrounds/DeceasedAnimalBurialGroundsEdit';
import { AmbulanceServicesAnimalsList } from '../../../Components/AdminDashbordComponents/EmergencyServices/AmbulanceServicesAnimals/AmbulanceServicesAnimalsList';
import { BloodDonorsList } from '../../../Components/AdminDashbordComponents/EmergencyServices/BloodDonors';
import { InnovationCreate } from '../../../Components/AdminDashbordComponents/Innovation_Movies_Web_Seriese_Other/Innovation/Create';
import { InnovationEdit } from '../../../Components/AdminDashbordComponents/Innovation_Movies_Web_Seriese_Other/Innovation/innovationEdit';
import { InnovationGridView } from '../../../Components/AdminDashbordComponents/Innovation_Movies_Web_Seriese_Other/Innovation/innovationTiles';
import { InnovationTlieList } from '../../../Components/AdminDashbordComponents/Innovation_Movies_Web_Seriese_Other/Innovation/innovationTlieList';
import { MovieCreate } from '../../../Components/AdminDashbordComponents/Innovation_Movies_Web_Seriese_Other/Movie/Create';
import { MovieEdit } from '../../../Components/AdminDashbordComponents/Innovation_Movies_Web_Seriese_Other/Movie/Edit';
import { MovieGridView } from '../../../Components/AdminDashbordComponents/Innovation_Movies_Web_Seriese_Other/Movie/movieTiles';
import { TileMovies } from '../../../Components/AdminDashbordComponents/Innovation_Movies_Web_Seriese_Other/Movie/tlie';
import { OtherCreate } from '../../../Components/AdminDashbordComponents/Innovation_Movies_Web_Seriese_Other/Other/Create';
import { OtherEdit } from '../../../Components/AdminDashbordComponents/Innovation_Movies_Web_Seriese_Other/Other/Edit';
import { OtherGridView } from '../../../Components/AdminDashbordComponents/Innovation_Movies_Web_Seriese_Other/Other/OtherTiles';
import { WebSeriesMoviesTile } from '../../../Components/AdminDashbordComponents/Innovation_Movies_Web_Seriese_Other/Web_Series/tlie';
import { WebSeriesGridView } from '../../../Components/AdminDashbordComponents/Innovation_Movies_Web_Seriese_Other/Web_Series/movieTiles';
import { WebSeriesCreate } from '../../../Components/AdminDashbordComponents/Innovation_Movies_Web_Seriese_Other/Web_Series/Create';
import { WebSeriesEdit } from '../../../Components/AdminDashbordComponents/Innovation_Movies_Web_Seriese_Other/Web_Series/Edit';
import { BloodBanksList } from '../../../Components/AdminDashbordComponents/EmergencyServices/BloodBanks';
import BloodBankEdit from '../../../Components/AdminDashbordComponents/Service/EMERGENCYSERVICES/BloodBanks/BloodBanksEdit';
import BloodBankReg from '../../../Components/AdminDashbordComponents/Service/EMERGENCYSERVICES/BloodBanks/BloodBanksReg';
import { PoliceStationsList } from '../../../Components/AdminDashbordComponents/EmergencyServices/PoliceStations';
import PoliceStationsReg from '../../../Components/AdminDashbordComponents/Service/EMERGENCYSERVICES/PoliceStations/PoliceStationsReg';
import PoliceStationsEdit from '../../../Components/AdminDashbordComponents/Service/EMERGENCYSERVICES/PoliceStations/PoliceStationsEdit';
import { PoliceControlRoomsList } from '../../../Components/AdminDashbordComponents/EmergencyServices/PoliceControlRooms';
import PoliceControlRoomsEdit from '../../../Components/AdminDashbordComponents/Service/EMERGENCYSERVICES/PoliceControlRooms/PoliceControlRoomsEdit';
import PoliceControlRoomsReg from '../../../Components/AdminDashbordComponents/Service/EMERGENCYSERVICES/PoliceControlRooms/PoliceControlRoomsReg';
import { FireStationsList } from '../../../Components/AdminDashbordComponents/EmergencyServices/FireStations';
import FireStationsReg from '../../../Components/AdminDashbordComponents/Service/EMERGENCYSERVICES/FireStations/FireStationsReg';
import FireStationsEdit from '../../../Components/AdminDashbordComponents/Service/EMERGENCYSERVICES/FireStations/FireStationsEdit';
import { PharmaciesList } from '../../../Components/AdminDashbordComponents/EmergencyServices/Pharmacies';
import { PharmaciesReg } from '../../../Components/AdminDashbordComponents/Service/EMERGENCYSERVICES/Pharmacies/PharmaciesReg';
import PharmaciesEdit from '../../../Components/AdminDashbordComponents/Service/EMERGENCYSERVICES/Pharmacies/PharmaciesEdit';
import Entertainment from '../../../Components/AdminDashbordComponents/Innovation_Movies_Web_Seriese_Other/Entertainment';
import { SportsGridView } from '../../../Components/AdminDashbordComponents/Innovation_Movies_Web_Seriese_Other/Sports/SportsTiles';
import { SportsCreate } from '../../../Components/AdminDashbordComponents/Innovation_Movies_Web_Seriese_Other/Sports/Create';
import { SportsEdit } from '../../../Components/AdminDashbordComponents/Innovation_Movies_Web_Seriese_Other/Sports/Edit';
import ActivistAnimalView from '../../../Components/AdminDashbordComponents/Activists/AnimalActivistsView';
import { EnvironmentalActivistsView } from '../../../Components/AdminDashbordComponents/Activists/EnvironmentalActivistsView';
import { SocialActivistsView } from '../../../Components/AdminDashbordComponents/Activists/SocialActivistsView';
import Shopping_Malls_create from '../../../Components/AdminDashbordComponents/Service/Leisure/Shopping_Malls/Shopping_Malls_create';
import { Shopping_Malls } from '../../../Components/AdminDashbordComponents/Leisure/Shopping_Malls';
import Leisure from '../../../Components/AdminDashbordComponents/Service/Leisure/Leisure';
import Shopping_Malls_Edit from '../../../Components/AdminDashbordComponents/Service/Leisure/Shopping_Malls/Shopping_Malls_Edit';
import { Theatres } from '../../../Components/AdminDashbordComponents/Leisure/Theatres';
import Theatres_create from '../../../Components/AdminDashbordComponents/Service/Leisure/Theatres/Theatres_create';
import Theatres_Edit from '../../../Components/AdminDashbordComponents/Service/Leisure/Theatres/Theatres_Edit';
import { Childrens_Parks } from '../../../Components/AdminDashbordComponents/Leisure/Childrens_Parks';
import Childrens_Parks_create from '../../../Components/AdminDashbordComponents/Service/Leisure/Childrens_Parks/Childrens_Parks_create';
import Childrens_Parks_Edit from '../../../Components/AdminDashbordComponents/Service/Leisure/Childrens_Parks/Childrens_Parks_Edit';
import { Jogging_Parks } from '../../../Components/AdminDashbordComponents/Leisure/Jogging_Parks';
import Jogging_Parks_create from '../../../Components/AdminDashbordComponents/Service/Leisure/Jogging_Parks/Jogging_Parks_create';
import Jogging_Parks_Edit from '../../../Components/AdminDashbordComponents/Service/Leisure/Jogging_Parks/Jogging_Parks_Edit';
import { Beaches } from '../../../Components/AdminDashbordComponents/Leisure/Beaches';
import Beaches_create from '../../../Components/AdminDashbordComponents/Service/Leisure/Beaches/Beaches_create';
import Beaches_Edit from '../../../Components/AdminDashbordComponents/Service/Leisure/Beaches/Beaches_Edit';
import { Amusement_Water_theme_Parks } from '../../../Components/AdminDashbordComponents/Leisure/Amusement_Water_theme_Parks';
import Amusement_Water_theme_Parks_create from '../../../Components/AdminDashbordComponents/Service/Leisure/Amusement_Water_theme_Parks/Amusement_Water_theme_Parks_create';
import Amusement_Water_theme_Parks_Edit from '../../../Components/AdminDashbordComponents/Service/Leisure/Amusement_Water_theme_Parks/Amusement_Water_theme_Parks_Edit';
import { Museums } from '../../../Components/AdminDashbordComponents/Leisure/Museums';
import Museums_create from '../../../Components/AdminDashbordComponents/Service/Leisure/Museums/Museums_create';
import Museums_Edit from '../../../Components/AdminDashbordComponents/Service/Leisure/Museums/Museums_Edit';
import { Libraries } from '../../../Components/AdminDashbordComponents/Leisure/Libraries';
import Libraries_create from '../../../Components/AdminDashbordComponents/Service/Leisure/Libraries/Libraries_create';
import Libraries_Edit from '../../../Components/AdminDashbordComponents/Service/Leisure/Libraries/Libraries_Edit';
import { Social_Clubs } from '../../../Components/AdminDashbordComponents/Leisure/Social_Clubs';
import Social_Clubs_create from '../../../Components/AdminDashbordComponents/Service/Leisure/Social_Clubs/Social_Clubs_create';
import Social_Clubs_Edit from '../../../Components/AdminDashbordComponents/Service/Leisure/Social_Clubs/Social_Clubs_Edit';
import Utilities from '../../../Components/AdminDashbordComponents/Service/Utilities/Utilities';
import { Gas_Agencies_Govt } from '../../../Components/AdminDashbordComponents/Utilities/Gas_Agencies_Govt';
import Gas_Agencies_Govt_create from '../../../Components/AdminDashbordComponents/Service/Utilities/GasAgencies_Govt/Gas_Agencies_Govt_create';
import Gas_Agencies_Govt_Edit from '../../../Components/AdminDashbordComponents/Service/Utilities/GasAgencies_Govt/Gas_Agencies_Govt_Edit';
import { Gas_Agencies_Private } from '../../../Components/AdminDashbordComponents/Utilities/Gas_Agencies_Private';
import Gas_Agencies_Private_create from '../../../Components/AdminDashbordComponents/Service/Utilities/Gas_Agencies_Private/Gas_Agencies_Private_create';
import Gas_Agencies_Private_Edit from '../../../Components/AdminDashbordComponents/Service/Utilities/Gas_Agencies_Private/Gas_Agencies_Private_Edit';
import { Newspaper_Agencies } from '../../../Components/AdminDashbordComponents/Utilities/Newspaper_Agencies';
import Newspaper_Agencies_create from '../../../Components/AdminDashbordComponents/Service/Utilities/Newspaper_Agencies/Newspaper_Agencies_create';
import Newspaper_Agencies_Edit from '../../../Components/AdminDashbordComponents/Service/Utilities/Newspaper_Agencies/Newspaper_Agencies_Edit';
import { Milk_Vendors } from '../../../Components/AdminDashbordComponents/Utilities/Milk_Vendors';
import Milk_Vendors_create from '../../../Components/AdminDashbordComponents/Service/Utilities/Milk_Vendors/Milk_Vendors_create';
import Milk_Vendors_Edit from '../../../Components/AdminDashbordComponents/Service/Utilities/Milk_Vendors/Milk_Vendors_Edit';

import { ProPostView } from '../../../Components/AdminDashbordComponents/postToolBarPro/view';
import { ProPostView1 } from '../../../Components/AdminDashbordComponents/postToolBarProVendor/view';
import Propostlist from '../../../Components/AdminDashbordComponents/propostPost/propostlist';
import Propostlist1 from '../../../Components/AdminDashbordComponents/propostPostVender/propostlist';

import { Signin } from '../../../Components/SignIn/signin';
import ManageAdmin from '../../../Components/ManageAdmin/manageAdminDash';
import { CreateAdmin } from '../../../Components/ManageAdmin/createAdmin';
import Non_registered_members from '../../../Components/AdminDashbordComponents/Non_registered_members/Non_registered_members';
import Registered_members from '../../../Components/AdminDashbordComponents/Non_registered_members/Registered_members';
import Profile_created_members from '../../../Components/AdminDashbordComponents/Non_registered_members/Profile_created_members';
import Payment_details_list from '../../../Components/AdminDashbordComponents/Payment_details_list/Payment_details_list';

import SubAdminList from '../../../Components/ManageAdmin/SubAdminList';
import { SubAdminProfile } from '../../../Components/ManageAdmin/SubAdminProfile';
import { UpdateAdmin } from '../../../Components/ManageAdmin/UpdateAdmin';

import { Priceing } from '../../../Components/Priceing/Priceing';

import { ActiveRegisterv2 } from '../../../Components/Signup/ActiverRegisterv2';
import { ActiveRegister } from '../../../Components/Signup/ActiveRegister';
import { ProfileCreation2 } from '../../../Components/ProfileCreation/ProfileCreationV2';
import { ProfileCreation3 } from '../../../Components/ProfileCreation/ProfileCreationV3';
import { ProfileCreation } from '../../../Components/ProfileCreation/ProfileCreation';
import { Payment } from '../../../Pages/Payment/payment';
import { Report } from '../../../Components/AdminDashbordComponents/Report/Report';
import { Users } from '../../../Components/AdminDashbordComponents/Users/users';
import Pextenalview from '../../../Pages/Active/ProductProfile/Pextenalview';
import ExternalPview from '../../../Pages/Active/ActiveProfile/externalPview';
import { Vegetable_Markets } from '../../../Components/AdminDashbordComponents/Utilities/Vegetable_Markets';
import Vegetable_Markets_create from '../../../Components/AdminDashbordComponents/Service/Utilities/Vegetable_Markets/Vegetable_Markets_create';
import Vegetable_Markets_Edit from '../../../Components/AdminDashbordComponents/Service/Utilities/Vegetable_Markets/Vegetable_Markets_Edit';
import { Meat_Markets } from '../../../Components/AdminDashbordComponents/Utilities/Meat_Markets';
import Meat_Markets_create from '../../../Components/AdminDashbordComponents/Service/Utilities/Meat_Markets/Meat_Markets_create';
import Meat_Markets_Edit from '../../../Components/AdminDashbordComponents/Service/Utilities/Meat_Markets/Meat_Markets_Edit';
import { Waste_Disposal } from '../../../Components/AdminDashbordComponents/Utilities/Waste_Disposal';
import Waste_Disposal_create from '../../../Components/AdminDashbordComponents/Service/Utilities/Waste_Disposal/Waste_Disposal_create';
import Waste_Disposal_Edit from '../../../Components/AdminDashbordComponents/Service/Utilities/Waste_Disposal/Waste_Disposal_Edit';
import { Fuel_Stations } from '../../../Components/AdminDashbordComponents/Utilities/Fuel_Stations';
import Fuel_Stations_create from '../../../Components/AdminDashbordComponents/Service/Utilities/Fuel_Stations/Fuel_Stations_create';
import Fuel_Stations_Edit from '../../../Components/AdminDashbordComponents/Service/Utilities/Fuel_Stations/Fuel_Stations_Edit';
import { Bus_Stand } from '../../../Components/AdminDashbordComponents/Utilities/Bus_Stand';
import Bus_Stand_create from '../../../Components/AdminDashbordComponents/Service/Utilities/Bus_Stand/Bus_Stand_create';
import Bus_Stand_Edit from '../../../Components/AdminDashbordComponents/Service/Utilities/Bus_Stand/Bus_Stand_Edit';
import { Metro_Stations } from '../../../Components/AdminDashbordComponents/Utilities/Metro_Stations';
import Metro_Stations_create from '../../../Components/AdminDashbordComponents/Service/Utilities/Metro_Stations/Metro_Stations_create';
import Metro_Stations_Edit from '../../../Components/AdminDashbordComponents/Service/Utilities/Metro_Stations/Metro_Stations_Edit';
import { Railway_Stations } from '../../../Components/AdminDashbordComponents/Utilities/Railway_Stations';
import Railway_Stations_create from '../../../Components/AdminDashbordComponents/Service/Utilities/Railway_Stations/Railway_Stations_create';
import Railway_Stations_Edit from '../../../Components/AdminDashbordComponents/Service/Utilities/Railway_Stations/Railway_Stations_Edit';
import { Airports } from '../../../Components/AdminDashbordComponents/Utilities/Airports';
import Airports_create from '../../../Components/AdminDashbordComponents/Service/Utilities/Airports/Airports_create';
import Airports_Edit from '../../../Components/AdminDashbordComponents/Service/Utilities/Airports/Airports_Edit';
import { Post_Offices } from '../../../Components/AdminDashbordComponents/Utilities/Post_Offices';
import Post_Offices_create from '../../../Components/AdminDashbordComponents/Service/Utilities/Post_Offices/Post_Offices_create';
import Post_Offices_Edit from '../../../Components/AdminDashbordComponents/Service/Utilities/Post_Offices/Post_Offices_Edit';
import { Regional_Transport_Offices } from '../../../Components/AdminDashbordComponents/Utilities/Regional_Transport_Offices';
import Regional_Transport_Offices_create from '../../../Components/AdminDashbordComponents/Service/Utilities/Regional_Transport_Offices/Regional_Transport_Offices_create';
import Regional_Transport_Offices_Edit from '../../../Components/AdminDashbordComponents/Service/Utilities/Regional_Transport_Offices/Regional_Transport_Offices_Edit';
import { Banks } from '../../../Components/AdminDashbordComponents/Utilities/Banks';
import Banks_create from '../../../Components/AdminDashbordComponents/Service/Utilities/Banks/Banks_create';
import Banks_Edit from '../../../Components/AdminDashbordComponents/Service/Utilities/Banks/Banks_Edit';
import { Telecom } from '../../../Components/AdminDashbordComponents/Utilities/Telecom';
import Telecom_create from '../../../Components/AdminDashbordComponents/Service/Utilities/Telecom/Telecom_create';
import Telecom_Edit from '../../../Components/AdminDashbordComponents/Service/Utilities/Telecom/Telecom_Edit';
import { Media } from '../../../Components/AdminDashbordComponents/Utilities/Media';
import Media_create from '../../../Components/AdminDashbordComponents/Service/Utilities/Media/Media_create';
import Media_Edit from '../../../Components/AdminDashbordComponents/Service/Utilities/Media/Media_Edit';
import Online_prime from '../../../Components/AdminDashbordComponents/Online_memebers/Prime';
import Online_basic from '../../../Components/AdminDashbordComponents/Online_memebers/Basic';
import Online_Passive from '../../../Components/AdminDashbordComponents/Online_memebers/Passive';
import Prices from '../../../Components/AdminDashbordComponents/Service/Prices/Prices';
import { Fuel } from '../../../Components/AdminDashbordComponents/Prices/Fuel';
import Fuel_create from '../../../Components/AdminDashbordComponents/Service/Prices/Fuel/Fuel_create';
import Fuel_Edit from '../../../Components/AdminDashbordComponents/Service/Prices/Fuel/Fuel_Edit';
import { Jewellery } from '../../../Components/AdminDashbordComponents/Prices/Jewellery';
import Jewellery_create from '../../../Components/AdminDashbordComponents/Service/Prices/Jewellery/Jewellery_create';
import Jewellery_Edit from '../../../Components/AdminDashbordComponents/Service/Prices/Jewellery/Jewellery_Edit';
import { Ott_Platform } from '../../../Components/AdminDashbordComponents/Prices/Ott_Platform';
import Ott_Platform_create from '../../../Components/AdminDashbordComponents/Service/Prices/Ott_Platform/Ott_Platform_create';
import Ott_Platform_Edit from '../../../Components/AdminDashbordComponents/Service/Prices/Ott_Platform/Ott_Platform_Edit';
import { DTH } from '../../../Components/AdminDashbordComponents/Prices/DTH';
import DTH_create from '../../../Components/AdminDashbordComponents/Service/Prices/DTH/DTH_create';
import DTH_Edit from '../../../Components/AdminDashbordComponents/Service/Prices/DTH/DTH_Edit';
import { Vegetables } from '../../../Components/AdminDashbordComponents/Prices/Vegetables';
import Vegetables_create from '../../../Components/AdminDashbordComponents/Service/Prices/Vegetables/Vegetables_create';
import Vegetables_Edit from '../../../Components/AdminDashbordComponents/Service/Prices/Vegetables/Vegetables_Edit';
import { Sea_Food } from '../../../Components/AdminDashbordComponents/Prices/Sea_Food';
import Sea_Food_create from '../../../Components/AdminDashbordComponents/Service/Prices/Sea_Food/Sea_Food_create';
import Sea_Food_Edit from '../../../Components/AdminDashbordComponents/Service/Prices/Sea_Food/Sea_Food_Edit';
import { Chicken } from '../../../Components/AdminDashbordComponents/Prices/Chicken';
import Chicken_create from '../../../Components/AdminDashbordComponents/Service/Prices/Chicken/Chicken_create';
import Chicken_Edit from '../../../Components/AdminDashbordComponents/Service/Prices/Chicken/Chicken_Edit';
import { Mutton } from '../../../Components/AdminDashbordComponents/Prices/Mutton';
import Mutton_create from '../../../Components/AdminDashbordComponents/Service/Prices/Mutton/Mutton_create';
import Mutton_Edit from '../../../Components/AdminDashbordComponents/Service/Prices/Mutton/Mutton_Edit';
import { Expired_account } from '../../../Components/AdminDashbordComponents/Expired_account/Expired_account';
import BrandCons from '../../../Components/AdminDashbordComponents/categorys/Brand/ConsltBrand/BrandCons';
import BrandVendor from '../../../Components/AdminDashbordComponents/categorys/Brand/VendorBrand/BrandVendor';
import { Marketing_feedback } from '../../../Components/AdminDashbordComponents/Employe_Marketing_Feedback/Marketing_feedback';
import { Employe_Marketing_Table } from '../../../Components/AdminDashbordComponents/Employe_Marketing_Feedback/Employe_Marketing_table';
import { Expired_account_by_admin } from '../../../Components/AdminDashbordComponents/Expired_account/Expired_account_by_admin';
import { ActiveRegisterv3 } from '../../../Components/Signup/ActiverRegisterv3';
import { Offline_profile_Creation_list } from '../../../Components/AdminDashbordComponents/Offline_profile_Creation/Offline_profile_Creation';
import Offline_Account_Print_bill from '../../../Components/AdminDashbordComponents/Offline_profile_Creation/Ofline_Account_Print_bill';
import NewConsultantCategory from '../../../Components/AdminDashbordComponents/categorys/NewConsulitantCategory/NewConsultantCategory';
import NewVendorCategory from '../../../Components/AdminDashbordComponents/categorys/NewVendorCategory/NewVendorCategory';
import TempProfiles from '../../../Components/TempProfile/TempProfiles';
import TempProfilesProduct from '../../../Components/TempProfile/TempProfileProduct';
import TempProfilesV3 from '../../../Components/TempProfile/TempProfileV3';
import EditConsultantV1 from '../../../Components/CreateConseltent/Edit';
import EditConsultantV2 from '../../../Components/CreateConseltentV2/Edit';
import EditConsultantV3 from '../../../Components/CreateConseltentV3/Edit';
import EditProductV1 from '../../../Components/CreateProduct/Edit';
import EditProductV2 from '../../../Components/CreateProductV2/Edit';
import EditProductV3 from '../../../Components/CreateProductV3/Edit';
import { PassiveRegister } from '../../../Components/Signup/PassiveRegister';
import { PassiveProfileCreation } from '../../../Components/PassiveProfileCreation/PassiveProfileCreation';
import { Advertisementt } from '../../../Components/advertisement/Advertisementt';
import { AddPayment } from '../../Payment/PayForAd';
import { UsersAd } from '../../../Components/AdminDashbordComponents/UsersAd/usersAd';
import { ConsultantsAdd } from '../../../Components/AdminDashbordComponents/consultantsAdd/Consultants';
import { ProductStoreAdd } from '../../../Components/AdminDashbordComponents/prodectAdd/ProductStoreAdd';
import { AddPaymentOut } from '../../Payment/PayForAdOut';
import Account_details_executive from '../../../Components/AdminDashbordComponents/Executive/Account_details';
import Sub_admin_executive_details_page from '../../../Components/AdminDashbordComponents/Executive/Sub_admin_executive_details_page';
import Admin_executive_earnings from '../../../Components/AdminDashbordComponents/Executive/Admin_executive_earnings';
import Sub_admin_my_earnings from '../../../Components/AdminDashbordComponents/Executive/Sub_admin_my_earnings';
import { CreateAffiliatedAccount } from '../../../Components/ManageAdmin/createAffiliatedAccount';
import { LinkForm } from '../../../Components/LinkForm/LinkForm';
import { LinkForm2 } from '../../../Components/LinkForm/LinkForm2';
import NotFoundPage from '../../404/NotFoundPage';
import { Payment2 } from '../../Payment/Payment2';
import { Navbar2 } from '../../HomePage/Navbar2';
import { Footer } from '../../HomePage/Footer';
import { Homepage } from '../../HomePage/Homepage';
import { About } from '../../HomePage/About';
import { Apps } from '../../HomePage/Apps';
import { Jobs } from '../../HomePage/Jobs';
import { Congratulations } from '../../HomePage/Congratulations';
import { Jobopportunities } from '../../JobConnect/Jobopportunities';
import { Applicantsview } from '../../JobConnect/Applicantsview';
import { Fixedsalaryame } from '../../../Components/ManageAdmin/Fixedsalaryame';
import SMEList from '../../../Components/ManageAdmin/SMEList';
import { SMEProfile } from '../../../Components/ManageAdmin/SMEProfile';
import { SME_salary_remittances } from '../../../Components/ManageAdmin/SME_salary_remittances';
import { Salary_remittances_for_SME } from '../../../Components/AdminDashbordComponents/Executive/Salary_remittances_for_SME';
import SME_created_accounts from '../../../Components/ManageAdmin/SME_created_accounts';
import { View_categories } from '../../../Components/AdminDashbordComponents/Executive/View_categories';
import { Imageupload } from '../../../Components/ManageAdmin/Imageupload';
import { Online_members } from '../../../Components/AdminDashbordComponents/AdminDashBord/Online_members';
import { Ametermsandcondition } from '../../../Components/ManageAdmin/Ametermsandcondition';
import { Smetermsandcondition } from '../../../Components/ManageAdmin/Smetermsandcondition';
import { Updatetermsandcondition } from '../../../Components/ManageAdmin/Updatetermsandcondition';
import { Ameupdatetermsandcondition } from '../../../Components/ManageAdmin/Ameupdatetermsandcondition';
import { Smeupdatetermsandcondition } from '../../../Components/ManageAdmin/Smeupdatetermsandcondition';
import { MarketingAccount } from '../../../Components/ManageAdmin/MarketingAccount';
import { Add_information_Cat_and_Subcat } from '../../../Components/Information/Add_information_Cat_and_SubCat/Add_information_Cat_and_Subcat';
import { Viewnotificationsmeame } from '../../../Components/ManageAdmin/Viewnotificationsmeame';
import { Viewvaccanies } from '../../HomePage/Viewvacancies';
import { InformationForms } from '../../../Components/Information/Information_Forms/InformationForms';
import { InformationFormsEdit } from '../../../Components/Information/Information_Forms/InformationFormsEdit';
import { ProForum } from '../../../Components/AdminDashbordComponents/ProForum/ProForum';
import { Appointmentheader } from '../../HomePage/Appointmentheader';
import { Update_ame_tc } from '../../../Components/ManageAdmin/Components/Update_ame_tc';
import { Update_sme_tc } from '../../../Components/ManageAdmin/Components/Update_sme_tc';
import { Information_Category_list } from '../../../Components/Information_View/Information_Category_list';
import { Information_Subcategory_list } from '../../../Components/Information_View/Information_Subcategory_list';
import { Information_Subsubcategory_list } from '../../../Components/Information_View/Information_Subsubcategory_list';
import { Information_listing } from '../../../Components/Information_View/Information_listing';
import Information_Type1 from '../../../Components/Information_View/SIngle_page/Information_Type1';
import { Appointmentsmeuserview } from '../../../Components/ManageAdmin/Appointmentsmeuserview';
import { Updatesmeprofile } from '../../../Components/ManageAdmin/Updatesmeprofile';
import { Updateameprofile } from '../../../Components/ManageAdmin/Updateameprofile';
import { Salessme } from '../../../Components/ManageAdmin/Salessme';
import { Information_listing_edit } from '../../../Components/Information_View/Information_listing_edit';
import { Information_Medical_Store } from '../../../Components/Information_View/Information_Medical_Store';
import Information_Medical_Store_profile from '../../../Components/Information_View/SIngle_page/Information_Medical_Store_profile';
import { Admin_notification } from '../../../Components/ManageAdmin/Admin_notification';
import { Couponviewame } from '../../../Components/ManageAdmin/Couponviewame';
import { Couponviewsme } from '../../../Components/ManageAdmin/Couponviewsme';
import {
  Couponadmin,
  Couponadminsme,
} from '../../../Components/ManageAdmin/Couponadminsme';
import { Couponadminame } from '../../../Components/ManageAdmin/Couponadminame';
import { Feedbackview } from '../../../Components/AdminDashbordComponents/Feedback/Feedbackview';
import { BsDot } from 'react-icons/bs';
import {
  Registartion,
  Registartionregional,
} from '../../../Components/AdminDashbordComponents/Regionalmanager/Registartionregional';
import { Registrationcountryhead } from '../../../Components/AdminDashbordComponents/Countryhead/Registrationcountryhead';
import { Registrationzonalmanager } from '../../../Components/AdminDashbordComponents/Zonalmanagers/Registrationzonalmanager';
import { RmHomepage } from '../../../Components/AdminDashbordComponents/Regionalmanager/RmHomepage';
import { ZmHomepage } from '../../../Components/AdminDashbordComponents/Zonalmanagers/ZmHomepage';
import { ChHomepage } from '../../../Components/AdminDashbordComponents/Countryhead/ChHomepage';
import { Areamanagerview } from '../../../Components/AdminDashbordComponents/ClusterSalesmanager/Areamanagerview';
import { AdminselectionRM } from '../../../Components/AdminDashbordComponents/Regionalmanager/AdminselectionRM';
import { AdminselectionZM } from '../../../Components/AdminDashbordComponents/Zonalmanagers/AdminselectionZM';
import { AdminselectionCH } from '../../../Components/AdminDashbordComponents/Countryhead/AdminselectionCH';
import { MemberviewCH } from '../../../Components/AdminDashbordComponents/Countryhead/MemberviewCH';
import { MemberviewZM } from '../../../Components/AdminDashbordComponents/Zonalmanagers/MemberviewZM';
import { MemberviewRM } from '../../../Components/AdminDashbordComponents/Regionalmanager/MemberviewRM';
import { AccountdetailsRM } from '../../../Components/AdminDashbordComponents/Regionalmanager/AccountdetailsRM';
import { SpinnerBig } from '../../../Utility/spinner';

import { RMprofile } from '../../../Components/AdminDashbordComponents/Regionalmanager/RMprofile';
import { EditadminprofileRM } from '../../../Components/AdminDashbordComponents/Regionalmanager/EditadminprofileRM';
import { EditadminprofileAM } from '../../../Components/AdminDashbordComponents/ClusterSalesmanager/EditadminprofileAM';
import { AdminselectionFSE } from '../../../Components/AdminDashbordComponents/Regionalmanager/AdminselectionFSE';
import { RegisterFSE } from '../../../Components/AdminDashbordComponents/FSEmanage/RegisterFSE';
import { RegistrationCSmanager } from '../../../Components/AdminDashbordComponents/ClusterSalesmanager/RegistrationCSmanager';
import { MemberviewCSM } from '../../../Components/AdminDashbordComponents/ClusterSalesmanager/MemberviewCSM';
import { AdminselectionCSM } from '../../../Components/AdminDashbordComponents/ClusterSalesmanager/AdminselectionCSM';
import { CSMprofile } from '../../../Components/AdminDashbordComponents/ClusterSalesmanager/CSMprofile';
import { AccountdetailsCSM } from '../../../Components/AdminDashbordComponents/ClusterSalesmanager/AccountdetailsCSM';
import {
  CMSacceptedtandc,
  CSMacceptedtandc,
} from '../../../Components/AdminDashbordComponents/ClusterSalesmanager/CSMacceptedtandc';
import {
  Cmstermsandcondition,
  Csmtermsandcondition,
} from '../../../Components/ManageAdmin/Components/Csmtermsandcondition';
import { RegisterFME } from '../../../Components/AdminDashbordComponents/FMEmanage/RegisterFME';
import { AdminselectionFME } from '../../../Components/AdminDashbordComponents/Regionalmanager/AdminselectionFME';
import { FSEMemberviewRM } from '../../../Components/AdminDashbordComponents/Regionalmanager/FSEmemberviewRM';
import { FMEMemberviewRM } from '../../../Components/AdminDashbordComponents/Regionalmanager/FMEmemberviewRM';
import { FMEmemberviewCSM } from '../../../Components/AdminDashbordComponents/ClusterSalesmanager/FMEmemberviewCSM';
import { Form } from '../../../Components/AdminDashbordComponents/DCE/Form';
import { Notification } from '../../../Components/AdminDashbordComponents/DCE/Notification';
import { Abstractdailymonthlytargets } from '../../../Components/AdminDashbordComponents/DCE/Abstractdailymonthlytargets';
import { Pausepage } from '../../../Components/AdminDashbordComponents/DCE/Pausepage';
import { AdminselectionCA } from '../../../Components/AdminDashbordComponents/ClericalAdmin/AdminselectionCA';

import { Clericalregistration } from '../../../Components/AdminDashbordComponents/ClericalAdmin/Clericalregistration';
import { Clerialmemberview } from '../../../Components/AdminDashbordComponents/ClericalAdmin/Clerialmemberview';
import { ViparFriendinsert } from '../../../Components/AdminDashbordComponents/ViparFriend/ViparFriendinsert';
import { ViparFriendprofileview } from '../../../Components/AdminDashbordComponents/ViparFriend/ViparFriendprofileview';
import { VFprofile } from '../../../Components/AdminDashbordComponents/ViparFriend/VFprofile';
import { Accountdetails } from '../../../Components/ManageAdmin/Components/Accountdetails';
import { HomeAdmin } from '../../../Components/ManageAdmin/Components/HomeAdmin';
import { Clerical2registration } from '../../../Components/AdminDashbordComponents/ClericalAdmin2/Clerical2registration';
import { Clerical2memberview } from '../../../Components/AdminDashbordComponents/ClericalAdmin2/Clerical2memberview';
import { Data_entry_formTable } from 'Components/AdminDashbordComponents/DCETABLE/DceTable';
import { Televerificationregister } from 'Components/AdminDashbordComponents/Televerification/Televerificationregister';
import { Televerificationview } from 'Components/AdminDashbordComponents/Televerification/Televerificationview';

function AdminDashbord() {
  let navigate = useNavigate();
  const [show, setshow] = useState(<AdminDashBord />);
  const [Authenticated, setAuthenticated] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [collapse, setcollapse] = useState(false);
  const [type, setType] = useState();

  //Authentication this is used first time the site load
  const Auth = () => {
    axios
      .get('/api/verify/authenticateduser')
      .then((res) => {
        if (res.status === 200) {
          setAuthenticated(true);
          checktermsandcondition();
        } else {
          localStorage.clear();
          // navigate('/')
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response.status);
        if (err) {
          if (localStorage.getItem('Name')) {
            localStorage.clear();
            navigate('/');
          }
          setAuthenticated(false);

          //
          console.log(err);
        }
        setLoading(false);
        //

        // console.log(err.response.status);
      });
  };
  //Authentication after login----------> it is used to Authentication in each page
  const Auth1 = () => {
    axios
      .get('/api/verify/authenticateduserwithststue')
      .then((res) => {
        if (res.status === 200) {
          setAuthenticated(true);
          checktermsandcondition();
        } else {
          logout();
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.response.status);
        if (err) {
          if (localStorage.getItem('Name')) {
            localStorage.clear();
            navigate('/');
            // window.location.reload();
          }

          setAuthenticated(false);

          //
          console.log(err);
        }
        setLoading(false);
        //

        // console.log(err.response.status);
      });
  };
  ///used to check temrms and condition
  const [checkterms, setcheckterms] = useState();
  const checktermsandcondition = () => {
    axios
      .get('/api/verify/checktermsandcondition')
      .then((res) => {
        setcheckterms(res.data.Accepted_Updated_Termsandconditions);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    checktermsandcondition();
  }, []);

  // geting user type
  const [Role, setRole] = useState();
  useEffect(() => {
    axios
      .post('/api/User/checkforsuper', {})
      .then((res) => {
        setRole(res.data.Role);
        setType(res.data.Type);
        localStorage.setItem('Account_type', res.data.Type);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //used to know the Affilate has fixed salary
  const [Salary, setSalary] = useState(false);
  useEffect(() => {
    axios
      .get('/api/Admin/checkforSalaryFixed', {})
      .then((res) => {
        setSalary(res.data.FixedSalary);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const logout = async () => {
    try {
      let s = await axios.post('/api/Logout');
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  ////////////no youse in here
  const [Iam, setIam] = useState(false);
  const whois = () => {
    axios
      .get('/api/verify/whois')
      .then((res) => {
        if (res.status === 200) {
          setIam({
            iam: res.data.iam,
            status: res.data.status,
          });
          if (res.status === 203) {
            navigate('/');
            setAuthenticated(false);
          }

          //
        } else {
          //
          // navigate("/lobby/");
        }
      })
      .catch((err) => {
        // navigate("/prochure_affiliate");
      });
  };

  useEffect(() => {
    Auth();
    whois();
    return () => {};
  }, []);
  const [referral, setreferral] = useState('');

  useEffect(() => {
    if (Authenticated) {
      axios.get('/api/api/localData').then((res) => {
        setreferral(res.data);
        localStorage.setItem('Name', res.data.Name);
        localStorage.setItem('ReferalCode', res.data.Referral_Code);
        localStorage.setItem('LoginID', res.data.LoginID);
        localStorage.setItem('AccountID', res.data.AccountID);
      });
    }

    return () => {};
  }, [Authenticated]);

  const [shows, setShows] = useState(false);
  const handleClose = () => setShows(false);
  const handleShow = () => setShows(true);
  const [shows1, setShows1] = useState(false);
  const handleClose1 = () => setShows1(false);
  const handleShow1 = () => setShows1(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const checkforrupdateamesme = () => {
    // axios
    //   .get('/api/User/checkforrupdateame')
    //   .then((res) => {
    //     console.log(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  useEffect(() => {
    checkforrupdateamesme();
  }, []);

  //////////////////used to get the count of ask us
  const [dataCount, setDataCount] = useState(null);

  useEffect(() => {
    callAskCount();
    const interval = setInterval(() => {
      callAskCount();
    }, 5 * 60 * 1000);
  }, []);

  const callAskCount = () => {
    axios.get('/api/Admin/ask_us_count').then((response) => {
      setDataCount(response.data);
    });
  };

  //daily & monthly target count
  const [Count, setCount] = useState();
  useEffect(() => {
    axios
      .post('/api/User/dcecount')
      .then((res) => {
        setCount(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // finduserdata pass fail pause
  //  var id = searchParams.get('k');

  const [findUD, setFindUD] = useState();
  useEffect(() => {
    axios
      .post('/api/Admin/userdatadataadminroute')
      .then((req) => {
        setFindUD(req.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // finduserdata pass fail pause

  return (
    <div className="">
      {Loading ? (
        <></>
      ) : !Authenticated ? (
        <Routes>
          <Route element={<Homepage />} path="/" />

          {/* <Route
            element={<Salessme />}
            path="/salessme"
          ></Route> */}

          <Route element={<NotFoundPage />} path="/*"></Route>
          <Route
            element={<Signin checkforrupdateamesme={checkforrupdateamesme} />}
            path="/sign_in"
          />
          <Route
            element={<CreateAffiliatedAccount type={type} />}
            path="/ame_registration_form"
          ></Route>
          <Route element={<About />} path="/the_brand" />
          <Route element={<Apps />} path="/apps" />

          <Route element={<Jobs />} path="/jobs" />

          <Route element={<Congratulations />} path="/congratulations" />
          <Route element={<Fixedsalaryame />} path="/sme_registration_form" />
          <Route element={<Viewvaccanies />} path="/viewvacanies" />
          <Route element={<MarketingAccount />} path="/marketingaccount" />
          <Route
            element={<RegisterFSE type={type} />}
            path="/fse_registration"
          />
          <Route
            element={<RegisterFME type={type} />}
            path="/DCE_registration"
          />
          <Route
            element={<ViparFriendinsert />}
            path="/prochure_free_listing"
          />
        </Routes>
      ) : (
        <>
          {/*Main Navigation*/}

          {/* Sidebar */}
          <Navbar2 />
          {/* Sidebar */}
          {/* Navbar */}
          <nav className="navbar navbar-expand-lg navbar-light bg-white zindexClass ">
            {/* Container wrapper */}
            <div className="container-fluid">
              {/* Toggle button */}

              {/* Brand */}
              <a className="navbar-brand" href="#">
                <div
                  id="PRO-SPACES"
                  className="prochure"
                  transform="translate(37 155.05) scale(1.172 1.069)"
                  style={{ fontSize: '25px' }}
                >
                  PROchure{' '}
                </div>
              </a>
              {/* Search form */}

              {/* Right links */}
              {/* <ul className="navbar-nav ms-auto d-flex flex-row">
                  {/* Notification dropdown */}
              {/* Avatar */}

              {/* </ul> */}
              <div className="d-flex justfiy-content-end">
                <button
                  className=" "
                  style={{ border: 'none' }}
                  type="button"
                  data-mdb-toggle="collapse"
                  onClick={(e) => setcollapse(!collapse)}
                >
                  <VscThreeBars size={22} />
                </button>
              </div>
            </div>
            {/* Container wrapper */}
          </nav>
          {/* Navbar */}
          <>
            <Offcanvas
              show={collapse}
              onHide={(e) => setcollapse(!collapse)}
              className="navezindex"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <>
                  <div className="">
                    <div className="list-group list-group-flush mx-3 mt-4">
                      {/* Regionalmanager */}
                      {type === 'SUBADMINSRM' ? (
                        <Link
                          onClick={(e) => setcollapse(!collapse)}
                          to="/"
                          className="list-group-item list-group-item-action py-2 ripple"
                          aria-current="true"
                        >
                          <i className="fas fa-tachometer-alt fa-fw " />
                          <span className="icon">
                            <MdHome className="react-icon" />
                          </span>
                          &nbsp;
                          <span className="title">HOME</span>
                        </Link>
                      ) : (
                        ''
                      )}
                      {/* Regionalmanager */}

                      {/* Zonalmanager */}
                      {type === 'ADMINSZM' ? (
                        <Link
                          onClick={(e) => setcollapse(!collapse)}
                          to="/"
                          className="list-group-item list-group-item-action py-2 ripple"
                          aria-current="true"
                        >
                          <i className="fas fa-tachometer-alt fa-fw " />
                          <span className="icon">
                            <MdHome className="react-icon" />
                          </span>
                          &nbsp;
                          <span className="title">HOME</span>
                        </Link>
                      ) : (
                        ''
                      )}
                      {/* Zonalmanager */}

                      {/* CountryHead */}
                      {type === 'SUBADMINSRM' ? (
                        <Link
                          onClick={(e) => setcollapse(!collapse)}
                          to="/"
                          className="list-group-item list-group-item-action py-2 ripple"
                          aria-current="true"
                        >
                          <i className="fas fa-tachometer-alt fa-fw " />
                          <span className="icon">
                            <MdHome className="react-icon" />
                          </span>
                          &nbsp;
                          <span className="title">HOME</span>
                        </Link>
                      ) : (
                        ''
                      )}
                      {/* CountryHead */}

                      {type === 'AffiliatedAccount' ? (
                        <>
                          {checkterms ? (
                            <>
                              <Link
                                onClick={(e) => setcollapse(!collapse)}
                                to="/"
                                className="list-group-item list-group-item-action py-2 ripple"
                                aria-current="true"
                              >
                                <span className="title">HOME</span>
                              </Link>

                              <Link
                                onClick={(e) => setcollapse(!collapse)}
                                to="view_categories"
                                className="list-group-item list-group-item-action py-2 ripple"
                                aria-current="true"
                              >
                                <span className="title">VIEW CATEGORIES</span>
                              </Link>

                              {/* <Link
                                  onClick={(e) => setcollapse(!collapse)}
                                  to="c_v_registration"
                                  className="list-group-item list-group-item-action py-2 ripple"
                                  aria-current="true"
                                >
                                  <i className="fas fa-tachometer-alt fa-fw " />{' '}
                                  <span className="icon">
                                    <MdPerson className="react-icon" />
                                  </span>
                                  <span className="title">
                                    {' '}
                                    CREATE PROFILE MYSELF
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <AiOutlineExclamationCircle
                                      onClick={(e) => {
                                        handleShow();
                                      }}
                                    />{' '}
                                  </span>
                                </Link> */}
                              {/* {Salary ? (
                                <Link
                                  onClick={(e) => {
                                    setcollapse(!collapse);
                                  }}
                                  to="DCE_notification"
                                  className="list-group-item list-group-item-action py-2 ripple"
                                  aria-current="true"
                                >
                                  <i className="fas fa-tachometer-alt fa-fw " />{' '}
                                  <span className="icon">
                                    <MdPerson className="react-icon" />
                                  </span>
                                  <span className="title">NOTIFICATIONS </span>
                                </Link>
                              ) : (
                                <></>
                              )} */}
                              {Salary ? (
                                <Link
                                  onClick={(e) => setcollapse(!collapse)}
                                  to={
                                    findUD?.Pause === false
                                      ? 'DCE_data_entry'
                                      : 'dce_entry_'
                                  }
                                  className="list-group-item list-group-item-action py-2 ripple"
                                  aria-current="true"
                                >
                                  <span className="title">
                                    {' '}
                                    {findUD?.Pass === true ? (
                                      <span>NEW PROFILE DATA ENTRY</span>
                                    ) : (
                                      <span>1-DAY SKILL TEST FORM</span>
                                    )}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {/* <AiOutlineExclamationCircle
                                      onClick={(e) => {
                                        handleShow3();
                                      }}
                                    />{' '} */}
                                  </span>
                                </Link>
                              ) : (
                                <></>
                              )}
                              {Salary ? (
                                <Link
                                  to=""
                                  className="list-group-item list-group-item-action py-2 ripple"
                                  aria-current="true"
                                >
                                  {findUD?.Pass === true ? (
                                    <span className="title">
                                      {' '}
                                      Today’s Profile Data Targets (
                                      {Count?.todayCount ? (
                                        <span>{Count?.todayCount}</span>
                                      ) : (
                                        <span>0</span>
                                      )}
                                      )
                                    </span>
                                  ) : (
                                    <span className="title">
                                      Today’s Test Data Targets (
                                      {Count?.todayCount ? (
                                        <span>{Count?.todayCount}</span>
                                      ) : (
                                        <span>0</span>
                                      )}
                                      )
                                    </span>
                                  )}
                                </Link>
                              ) : (
                                <></>
                              )}
                              {Salary ? (
                                <Link
                                  onClick={(e) => setcollapse(!collapse)}
                                  to="/data_entry_formtable"
                                  className="list-group-item list-group-item-action py-2 ripple"
                                  aria-current="true"
                                >
                                  {findUD?.Pass === true ? (
                                    <span className="title">
                                      {' '}
                                      TOTAL PROFILE DATA (
                                      {Count?.totalCount ? (
                                        <span>{Count?.totalCount}</span>
                                      ) : (
                                        <span>0</span>
                                      )}
                                      ) collected by me
                                    </span>
                                  ) : (
                                    <span className="title">
                                      PREVIOUS SKILL TEST DETAILS
                                    </span>
                                  )}
                                </Link>
                              ) : (
                                <></>
                              )}
                              {Salary && findUD?.Pass === true ? (
                                <Link
                                  onClick={(e) => setcollapse(!collapse)}
                                  to="/DCE_targets"
                                  className="list-group-item list-group-item-action py-2 ripple"
                                  aria-current="true"
                                >
                                  <span className="title">
                                    {' '}
                                    Abstract of DAILY / MONTHLY TARGETS
                                  </span>
                                </Link>
                              ) : (
                                <></>
                              )}
                              {Salary ? (
                                <></>
                              ) : (
                                <Link
                                  onClick={(e) => setcollapse(!collapse)}
                                  to="send_profile_creation_link"
                                  className="list-group-item list-group-item-action py-2 ripple"
                                  aria-current="true"
                                >
                                  <span className="title">
                                    {' '}
                                    SEND PROFILE CREATION LINK
                                    &nbsp;&nbsp;&nbsp;
                                    <AiOutlineExclamationCircle
                                      onClick={(e) => {
                                        handleShow1();
                                      }}
                                    />
                                  </span>
                                </Link>
                              )}
                              {Salary ? (
                                <></>
                              ) : (
                                <Link
                                  onClick={(e) => setcollapse(!collapse)}
                                  to="profile_created_executive"
                                  className="list-group-item list-group-item-action py-2 ripple"
                                  aria-current="true"
                                >
                                  <span className="title">
                                    {' '}
                                    PROFILES CREATED
                                  </span>
                                </Link>
                              )}
                              {/* 
                              {Salary ? (
                                <Link
                                  onClick={(e) => setcollapse(!collapse)}
                                  to="salary_remittances"
                                  className="list-group-item list-group-item-action py-2 ripple"
                                  aria-current="true"
                                >
                                  <i className="fas fa-tachometer-alt fa-fw " />{' '}
                                  <span className="icon">
                                    <HiViewGridAdd className="react-icon" />
                                  </span>
                                  <span className="title">
                                    SALARY REMITTANCES{' '}
                                  </span>
                                </Link>
                              ) : (
                                <Link
                                  onClick={(e) => setcollapse(!collapse)}
                                  to="my_earnings"
                                  className="list-group-item list-group-item-action py-2 ripple"
                                  aria-current="true"
                                >
                                  <i className="fas fa-tachometer-alt fa-fw " />{' '}
                                  <span className="icon">
                                    <HiViewGridAdd className="react-icon" />
                                  </span>
                                  <span className="title"> MY EARNINGS</span>
                                </Link>
                              )} */}
                              {/* <Link
                                onClick={(e) => setcollapse(!collapse)}
                                to={Salary ? `coupon_sme` : 'coupon_ame'}
                                className="list-group-item list-group-item-action py-2 ripple"
                                aria-current="true"
                              >
                                <i className="fas fa-tachometer-alt fa-fw " />{' '}
                                <span className="icon">
                                  <HiViewGridAdd className="react-icon" />
                                </span>
                                <span className="title">COUPONS</span>
                              </Link> */}
                              <Link
                                to={
                                  Salary
                                    ? 'sme_terms&conditions'
                                    : 'ame_terms&conditions'
                                }
                                onClick={(e) => {
                                  setcollapse(!collapse);
                                }}
                                // data-bs-toggle="modal"
                                // data-bs-target={
                                //   Salary
                                //     ? '#ACCEPTED_TERMS_CONDITIONS_SME'
                                //     : '#ACCEPTED_TERMS_CONDITIONS'
                                // }
                                className="list-group-item list-group-item-action py-2 ripple"
                                aria-current="true"
                              >
                                <span className="">
                                  {' '}
                                  ACCEPTED TERMS & CONDITIONS
                                </span>
                              </Link>
                              {/* {Salary ? (
                                <Link
                                  onClick={(e) => setcollapse(!collapse)}
                                  to="sme_appointmentletter"
                                  className="list-group-item list-group-item-action py-2 ripple"
                                  aria-current="true"
                                >
                                  <i className="fas fa-tachometer-alt fa-fw " />
                                  <span className="icon">
                                    <HiViewGridAdd className="react-icon" />
                                  </span>
                                  <span className="title">
                                    APPOINTMENT LETTER
                                  </span>
                                </Link>
                              ) : (
                                ''
                              )} */}
                              {/* <Link
                                onClick={(e) => setcollapse(!collapse)}
                                to={Salary ? 'coupon_sme' : 'coupon_ame'}
                                className="list-group-item list-group-item-action py-2 ripple"
                                aria-current="true"
                              >
                                <i className="fas fa-tachometer-alt fa-fw " />
                                <span className="icon">
                                  <HiViewGridAdd className="react-icon" />
                                </span>
                                <span className="title">COUPONS</span>
                              </Link> */}
                              <Link
                                onClick={(e) => setcollapse(!collapse)}
                                to={
                                  Salary
                                    ? 'prochure_SME_account'
                                    : 'prochure_AME_account'
                                }
                                className="list-group-item list-group-item-action py-2 ripple"
                                aria-current="true"
                              >
                                <span className="title"> ACCOUNT DETAILS</span>
                              </Link>
                            </>
                          ) : (
                            <></>
                          )}

                          <div
                            onClick={(e) => {
                              setcollapse(!collapse);
                              logout();
                            }}
                            style={{ cursor: 'pointer' }}
                            to="ReportedPost"
                            className="list-group-item list-group-item-action py-2 ripple"
                            aria-current="true"
                          >
                            <span className="title"> LOG OUT</span>
                          </div>
                        </>
                      ) : (
                        ''
                      )}

                      {/* {type != 'SUPERADMIN' ||
                        type != 'AffiliatedAccount' ? (
                          <>
                            <Link
                              onClick={(e) => setcollapse(!collapse)}
                              to="profile_created_executive"
                              className="list-group-item list-group-item-action py-2 ripple"
                              aria-current="true"
                            >
                              <i className="fas fa-tachometer-alt fa-fw " />{' '}
                              <span className="icon">
                                <HiViewGridAdd className="react-icon" />
                              </span>
                              <span className="title"> PPROFILE CREATED</span>
                            </Link>

                            <Link
                              onClick={(e) => setcollapse(!collapse)}
                              to="my_earnings"
                              className="list-group-item list-group-item-action py-2 ripple"
                              aria-current="true"
                            >
                              <i className="fas fa-tachometer-alt fa-fw " />{' '}
                              <span className="icon">
                                <HiViewGridAdd className="react-icon" />
                              </span>
                              <span className="title"> MY EARNINGS</span>
                            </Link>
                          </>
                        ):""} */}

                      {/* {type === 'AdminType1' && (
                        <Link
                          onClick={(e) => setcollapse(!collapse)}
                          to="bank_accounts_details"
                          className="list-group-item list-group-item-action py-2 ripple"
                          aria-current="true"
                        >
                          <i className="fas fa-tachometer-alt fa-fw " />{' '}
                          <span className="icon">
                            <HiViewGridAdd className="react-icon" />
                          </span>
                          <span className="title"> BANK ACCOUNTS DETAILS</span>
                        </Link>
                      )} */}

                      {/* SUPERADMIN NAVBAR */}
                      {type === 'SUPERADMIN' || type === 'AdminType1' ? (
                        <Link
                          onClick={(e) => setcollapse(!collapse)}
                          to="/admin_home"
                          className=" list-group-item-action py-2 "
                          style={{ textDecoration: 'none', color: 'black' }}
                          aria-current="true"
                        >
                          <span className="title">HOME</span>
                        </Link>
                      ) : (
                        ''
                      )}

                      {type === 'SUPERADMIN' && (
                        <>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="adminnotification"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">NOTIFICATIONS</span>
                          </Link>
                          <Link
                            onClick={(e) => {
                              setcollapse(!collapse);
                            }}
                            to="updatetermsandcondition"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              Update NEW TERMS & CONDITIONS
                            </span>
                          </Link>
                          <hr />
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="categories"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              Manage CATEGORIES & SUB-CATEGORIES
                            </span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="CouponView"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">Manage COUPONS</span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="information_category"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              Manage INFORMATION CATEGORIES
                            </span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="information"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">Add NEW INFORMATION</span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="proform_topic"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">Manage PRO-FORUM</span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="job_opportunities"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">Manage JOB VACANCIES</span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="applicants_view"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">View APPLICANTS</span>
                          </Link>
                          <hr />
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="prochure_ask_us"
                            className=" list-group-item-action  ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              Manage PROchure ASK US{' '}
                              {dataCount > 0 ? (
                                <>
                                  <BsDot size={35} color="red"></BsDot>
                                </>
                              ) : (
                                <></>
                              )}
                            </span>
                                     
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="report"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">View REPORTS</span>
                          </Link>
                          <hr />
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="c_v_registration"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              OFFLINE PROFILE CREATION
                            </span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="invite_direct_link_forms"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              Send INVITE DIRECT LINK FORM
                            </span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="usersad"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              Create NEW ADVERTISEMENT
                            </span>
                          </Link>
                          <hr />
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="users"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">MEMBERS</span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="payment_details_list"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">PAYMENT DETAILS</span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="non_registered_members"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              {' '}
                              INCOMPLETE REGISTRATIONS
                            </span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="online_members"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">ONLINE MEMBERS </span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="expired_account"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">EXPIRED ACCOUNTS</span>
                          </Link>
                          <hr />
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="prochure_free_listing_view"
                            className="list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title"> View GENERAL FREE LISTINGS</span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="super_admin_data_entry"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                            Create ADMIN FREE LISTINGS
                            </span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            // to="sme_created_accounts "
                            to="data_entry_formtable"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              {' '}
                              View DCE & ADMIN FREE LISTINGS
                            </span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="ame_created_accounts "
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title"> View FSE PROFILES</span>
                          </Link>

                          <hr />

                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/Televerification_registration"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              {' '}
                              Register NEW TELE-VERIFICATION ADMINS
                            </span>
                                     
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/clerical_admin_registration"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              {' '}
                              Register NEW CLERICAL ADMINS (Free Listings and
                              Sales)
                            </span>
                                     
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/clerical_admin_registration_2"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              {' '}
                              Register NEW CLERICAL ADMINS (Information and
                              General)
                            </span>
                                     
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/regionalmanager_registration"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              {' '}
                              Register NEW REGIONAL MANAGERS
                            </span>
                                     
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="clustersalesmanager_registration"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              Register NEW CLUSTER SALES MANAGERS
                            </span>
                                     
                          </Link>
                          <hr />
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="manage_sme"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              {' '}
                              Manage DATA COLLECTION EXECUTIVES
                            </span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="sub_admin_list"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              {' '}
                              Manage FIELD SALES EXECUTIVES
                            </span>
                          </Link>

                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/regionalmanager_memberview"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              Manage REGIONAL MANAGERS
                            </span>
                                     
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/televerification_memberview"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              Manage TELE-VERIFICATION ADMINS
                            </span>
                                     
                          </Link>

                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/clerical_admin_memberview"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              Manage CLERICAL ADMINS (Free Listings and Sales)
                            </span>
                                     
                          </Link>

                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/clerical_admin_memberview_2"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              Manage CLERICAL ADMINS (Information and General)
                            </span>
                                     
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="clustersalesmanager_memberview"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              Manage CLUSTER SALES MANAGERS
                            </span>
                                     
                          </Link>

                          <hr />

                          {/* <Link
                              onClick={(e) => setcollapse(!collapse)}
                              to="countryhead"
                              className="list-group-item list-group-item-action  ripple"
                              aria-current="true"
                            >
                              <i className="fas fa-tachometer-alt fa-fw " />{' '}
                              <span className="icon">
                                <MdPerson className="react-icon" />
                              </span>
                              <span className="title"> COUNTRY HEAD </span>
                                       
                            </Link> */}

                          {/* <Link
                              onClick={(e) => setcollapse(!collapse)}
                              to="zonalmanager"
                              className="list-group-item list-group-item-action  ripple"
                              aria-current="true"
                            >
                              <i className="fas fa-tachometer-alt fa-fw " />{' '}
                              <span className="icon">
                                <MdPerson className="react-icon" />
                              </span>
                              <span className="title"> ZONAL MANAGER </span>
                                       
                            </Link> */}
                        </>
                      )}

                      {type === 'SUPERADMIN' || type === 'AdminType1' ? (
                        <div
                          onClick={(e) => {
                            setcollapse(!collapse);
                            logout();
                          }}
                          style={{
                            cursor: 'pointer',
                            textDecoration: 'none',
                            color: 'black',
                          }}
                          to="ReportedPost"
                          className=" list-group-item-action py-2 ripple"
                          aria-current="true"
                        >
                          <span className="title">LOG OUT</span>
                        </div>
                      ) : (
                        ''
                      )}
                      {/* SUPERADMIN NAVBAR */}
                      {/* REGIONALMANAGER NAVBAR */}
                      {type === 'REGIONAL_MANAGER' ? (
                        <>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">HOME</span>
                          </Link>

                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/regionalmanager_accountdetails"
                            className=" list-group-item-action  ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title"> ACCOUNT DETAILS </span>
                                     
                          </Link>

                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/clustersalesmanager"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              {' '}
                              CLUSTER SALES MANAGER{' '}
                            </span>
                                     
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/fse"
                            className="py-2 list-group-item-action  ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title"> FSE </span>
                                     
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/fme"
                            className=" list-group-item-action  ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title"> DCE </span>
                                     
                          </Link>
                          <div
                            onClick={(e) => {
                              setcollapse(!collapse);
                              logout();
                            }}
                            style={{
                              cursor: 'pointer',
                              textDecoration: 'none',
                              color: 'black',
                            }}
                            to="ReportedPost"
                            className="list-group-item-action py-2 ripple"
                            aria-current="true"
                          >
                            <span className="title">LOG OUT</span>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {/* REGIONALMANAGER NAVBAR */}
                      {/* AREAMANAGER NAVBAR */}
                      {type === 'AREA_MANAGER' ? (
                        <>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/homeCH"
                            className="list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">HOME</span>
                          </Link>

                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/clustersalesmanager_accountdetails"
                            className="list-group-item list-group-item-action  ripple"
                            aria-current="true"
                          >
                            <span className="title"> ACCOUNT DETAILS </span>
                                     
                          </Link>

                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/fme"
                            className=" list-group-item-action  ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title"> FME </span>
                                     
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/csm_acceptedt&c"
                            className="list-group-item list-group-item-action  ripple"
                            aria-current="true"
                          >
                            <i className="fas fa-tachometer-alt fa-fw " />{' '}
                            <span className="icon">
                              <BiMessageAltCheck className="react-icon" />
                            </span>
                            <span className="title">
                              {' '}
                              ACCEPTED TERMS & CONDITIONS{' '}
                            </span>
                                     
                          </Link>
                          <div
                            onClick={(e) => {
                              setcollapse(!collapse);
                              logout();
                            }}
                            style={{ cursor: 'pointer' }}
                            to="ReportedPost"
                            className="list-group-item list-group-item-action py-2 ripple"
                            aria-current="true"
                          >
                            <i className="fas fa-tachometer-alt fa-fw " />{' '}
                            <span className="icon">
                              <AiOutlineApi className="react-icon" />
                            </span>
                            <span className="title">LOG OUT</span>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {/* AREAMANAGER NAVBAR */}
                      {/* COUNTRYHEAD NAVBAR */}
                      {type === 'COUNTRY_HEAD' ? (
                        <>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/homeCH"
                            className="list-group-item list-group-item-action py-2 ripple"
                            aria-current="true"
                          >
                            <i className="fas fa-tachometer-alt fa-fw " />
                            <span className="icon">
                              <MdHome className="react-icon" />
                            </span>
                            &nbsp;
                            <span className="title">HOME</span>
                          </Link>

                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to=""
                            className="list-group-item list-group-item-action  ripple"
                            aria-current="true"
                          >
                            <i className="fas fa-tachometer-alt fa-fw " />{' '}
                            <span className="icon">
                              <HiViewGridAdd className="react-icon" />
                            </span>
                            <span className="title"> ACCOUNT DETAILS </span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to=""
                            className="list-group-item list-group-item-action  ripple"
                            aria-current="true"
                          >
                            <i className="fas fa-tachometer-alt fa-fw " />{' '}
                            <span className="icon">
                              <BiMessageAltCheck className="react-icon" />
                            </span>
                            <span className="title"> ZONAL MANAGER </span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to=""
                            className="list-group-item list-group-item-action  ripple"
                            aria-current="true"
                          >
                            <i className="fas fa-tachometer-alt fa-fw " />{' '}
                            <span className="icon">
                              <BiMessageAltCheck className="react-icon" />
                            </span>
                            <span className="title"> REGIONAL MANAGER </span>
                                     
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to=""
                            className="list-group-item list-group-item-action  ripple"
                            aria-current="true"
                          >
                            <i className="fas fa-tachometer-alt fa-fw " />{' '}
                            <span className="icon">
                              <BiMessageAltCheck className="react-icon" />
                            </span>
                            <span className="title">
                              {' '}
                              CLUSTER SALES MANAGER{' '}
                            </span>
                                     
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to=""
                            className="list-group-item list-group-item-action  ripple"
                            aria-current="true"
                          >
                            <i className="fas fa-tachometer-alt fa-fw " />{' '}
                            <span className="icon">
                              <BiMessageAltCheck className="react-icon" />
                            </span>
                            <span className="title"> SME </span>
                                     
                          </Link>
                          <div
                            onClick={(e) => {
                              setcollapse(!collapse);
                              logout();
                            }}
                            style={{ cursor: 'pointer' }}
                            to="ReportedPost"
                            className="list-group-item list-group-item-action py-2 ripple"
                            aria-current="true"
                          >
                            <i className="fas fa-tachometer-alt fa-fw " />{' '}
                            <span className="icon">
                              <AiOutlineApi className="react-icon" />
                            </span>
                            <span className="title">LOG OUT</span>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {/* COUNTRYHEAD NAVBAR */}
                      {/* ZONALMANAGER NAVBAR */}
                      {type === 'ZONAL_MANAGER' ? (
                        <>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/homeZM"
                            className="list-group-item list-group-item-action py-2 ripple"
                            aria-current="true"
                          >
                            <i className="fas fa-tachometer-alt fa-fw " />
                            <span className="icon">
                              <MdHome className="react-icon" />
                            </span>
                            &nbsp;
                            <span className="title">HOME</span>
                          </Link>

                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to=""
                            className="list-group-item list-group-item-action  ripple"
                            aria-current="true"
                          >
                            <i className="fas fa-tachometer-alt fa-fw " />{' '}
                            <span className="icon">
                              <HiViewGridAdd className="react-icon" />
                            </span>
                            <span className="title"> ACCOUNT DETAILS </span>
                                     
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to=""
                            className="list-group-item list-group-item-action  ripple"
                            aria-current="true"
                          >
                            <i className="fas fa-tachometer-alt fa-fw " />{' '}
                            <span className="icon">
                              <BiMessageAltCheck className="react-icon" />
                            </span>
                            <span className="title"> REGIONAL MANAGER </span>
                                     
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to=""
                            className="list-group-item list-group-item-action  ripple"
                            aria-current="true"
                          >
                            <i className="fas fa-tachometer-alt fa-fw " />{' '}
                            <span className="icon">
                              <BiMessageAltCheck className="react-icon" />
                            </span>
                            <span className="title">
                              {' '}
                              CLUSTER SALES MANAGER{' '}
                            </span>
                                     
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to=""
                            className="list-group-item list-group-item-action  ripple"
                            aria-current="true"
                          >
                            <i className="fas fa-tachometer-alt fa-fw " />{' '}
                            <span className="icon">
                              <BiMessageAltCheck className="react-icon" />
                            </span>
                            <span className="title"> SME </span>
                                     
                          </Link>
                          <div
                            onClick={(e) => {
                              setcollapse(!collapse);
                              logout();
                            }}
                            style={{ cursor: 'pointer' }}
                            to="ReportedPost"
                            className="list-group-item list-group-item-action py-2 ripple"
                            aria-current="true"
                          >
                            <i className="fas fa-tachometer-alt fa-fw " />{' '}
                            <span className="icon">
                              <AiOutlineApi className="react-icon" />
                            </span>
                            <span className="title">LOG OUT</span>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {/* ZONALMANAGER NAVBAR */}

                      {/* CLERICALADMIN NAVBAR */}
                      {type === 'CLERICALADMIN' ? (
                        <>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">HOME</span>
                          </Link>
                          <hr />
                          
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="prochure_free_listing_view"
                            className="list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title"> View GENERAL FREE LISTINGS</span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="clerical_admin_data_entry"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                            Create ADMIN FREE LISTINGS
                            </span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            // to="sme_created_accounts "
                            to="data_entry_formtable"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              {' '}
                              View DCE & ADMIN FREE LISTINGS
                            </span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="ame_created_accounts "
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title"> View FSE PROFILES</span>
                          </Link>
                          <hr />

                          <div
                            onClick={(e) => {
                              setcollapse(!collapse);
                              logout();
                            }}
                            style={{
                              cursor: 'pointer',
                              textDecoration: 'none',
                              color: 'black',
                            }}
                            to="ReportedPost"
                            className=" list-group-item-action py-2 ripple"
                            aria-current="true"
                          >
                            <span className="title">LOG OUT</span>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {/* CLERICALADMIN NAVBAR */}

                      {/* CLERICALADMIN2 NAVBAR */}

                      {type === 'CLERICALADMIN2' ? (
                        <>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">HOME</span>
                          </Link>
                          <hr />
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/job_opportunities"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">Manage JOB VACANCIES</span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/applicants_view"
                            className="list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title"> View APPLICANTS</span>
                          </Link>
                          <hr />

                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/prochure_ask_us"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              {' '}
                              Manage PROchure ASK US
                            </span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/report "
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">View REPORTS</span>
                          </Link>
                          <hr />

                          <div
                            onClick={(e) => {
                              setcollapse(!collapse);
                              logout();
                            }}
                            style={{
                              cursor: 'pointer',
                              textDecoration: 'none',
                              color: 'black',
                            }}
                            to="ReportedPost"
                            className=" list-group-item-action py-2 ripple"
                            aria-current="true"
                          >
                            <span className="title">LOG OUT</span>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {/* CLERICALADMIN2 NAVBAR */}

                       {/* televerification */}
                       {type === 'TELEVERIFICATION' ? (
                        <>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="/"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">HOME</span>
                          </Link>
                          <hr />
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="notifications"
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              NOTIFICATIONS
                            </span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="prochure_free_listing_view"
                            className="list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title"> View GENERAL FREE LISTINGS</span>
                          </Link>
                          <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="Tele_admin_data_entry "
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title">
                              {' '}
                              Create ADMIN FREE LISTINGS
                            </span>
                          </Link>
                          {/* <Link
                            onClick={(e) => setcollapse(!collapse)}
                            to="ame_created_accounts "
                            className=" list-group-item-action py-2 ripple"
                            style={{ textDecoration: 'none', color: 'black' }}
                            aria-current="true"
                          >
                            <span className="title"> View FSE PROFILES</span>
                          </Link> */}
                          <hr />

                          <div
                            onClick={(e) => {
                              setcollapse(!collapse);
                              logout();
                            }}
                            style={{
                              cursor: 'pointer',
                              textDecoration: 'none',
                              color: 'black',
                            }}
                            to="ReportedPost"
                            className=" list-group-item-action py-2 ripple"
                            aria-current="true"
                          >
                            <span className="title">LOG OUT</span>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                       {/* televerification */}



                    </div>
                  </div>
                </>
              </Offcanvas.Body>
            </Offcanvas>
          </>
          {/*Main Navigation*/}
          {/*Main layout*/}
          <main className=" ">
            <div style={{ height: '95%' }} className="  ">
              {type ? (
                <>
                  {type === 'SUPERADMIN' || type === 'AdminType1' ? (
                    <Routes>
                      <Route element={<NotFoundPage />} path="/*"></Route>
                      <Route
                        element={<AdminDashBord type={type} Auth1={Auth1} />}
                        path="/"
                      ></Route>
                      <Route
                        element={<Online_members type={type} Auth1={Auth1} />}
                        path="/online_members"
                      ></Route>
                      <Route
                        element={<HomeAdmin type={type} Auth1={Auth1} />}
                        path="/admin_home"
                      ></Route>
                      <Route
                        element={<Accountdetails type={type} Auth1={Auth1} />}
                        path="/account_settings"
                      ></Route>
                      <Route
                        element={
                          <Admin_notification type={type} Auth1={Auth1} />
                        }
                        path="/adminnotification"
                      ></Route>
                      <Route
                        element={<ProductStore />}
                        path="/ProductStore"
                      ></Route>
                      <Route
                        element={<Consultants />}
                        path="/Consultants"
                      ></Route>
                      <Route
                        element={<ManageAdmin />}
                        path="/ManageAdmin"
                      ></Route>
                      <Route element={<Coupon />} path="/Coupon"></Route>
                      <Route
                        element={<CouponView />}
                        path="/CouponView"
                      ></Route>
                      <Route
                        element={
                          <Feedback
                            callAskCount={callAskCount}
                            dataCount={dataCount}
                          />
                        }
                        path="/prochure_ask_us"
                      ></Route>
                      {/* Manager Routes  */}
                      {/* CH */}
                      <Route
                        element={<Registrationcountryhead />}
                        path="/countryhead_registration"
                      ></Route>
                      <Route
                        element={<AdminselectionCH />}
                        path="/countryhead"
                      ></Route>
                      <Route
                        element={<MemberviewCH />}
                        path="/countryhead_memberview"
                      ></Route>
                      {/* CH */}
                      {/* RM */}
                      <Route
                        element={<Registartionregional />}
                        path="/regionalmanager_registration"
                      ></Route>
                      <Route
                        element={<AdminselectionRM />}
                        path="/regionalmanager"
                      ></Route>
                      <Route
                        element={<MemberviewRM type={type} Auth1={Auth1} />}
                        path="/regionalmanager_memberview"
                      ></Route>
                      <Route
                        element={<RMprofile type={type} Auth1={Auth1} />}
                        path="/regionalmanager_profile"
                      />
                      <Route
                        element={<CSMprofile type={type} Auth1={Auth1} />}
                        path="/clustersalesmanager_profile"
                      ></Route>
                      {/* <Route
                        element={<EditadminprofileRM type={type} />}
                        path="/regionalmanager_editprofile"
                      ></Route> */}
                      {/* RM */}
                      {/* ZM */}
                      <Route
                        element={<Registrationzonalmanager />}
                        path="/zonalmanager_registration"
                      ></Route>
                      <Route
                        element={<AdminselectionZM />}
                        path="/zonalmanager"
                      ></Route>
                      <Route
                        element={<MemberviewZM />}
                        path="/zonalmanager_memberview"
                      ></Route>
                      {/* ZM */}
                      {/* AM */}
                      <Route
                        element={<AdminselectionCSM />}
                        path="/clustersalesmanager"
                      ></Route>
                      <Route
                        element={<MemberviewCSM />}
                        path="/clustersalesmanager_memberview"
                      ></Route>
                      <Route
                        element={<RegistrationCSmanager />}
                        path="/clustersalesmanager_registration"
                      ></Route>
                      <Route
                        element={<CSMprofile />}
                        path="/clustersalesmanager_profile"
                      ></Route>
                      {/* <Route
                        element={<EditadminprofileAM type={type} />}
                        path="/areamanager_editprofile"
                      ></Route> */}
                      {/* AM */}
                      <Route element={<RmHomepage />} path="/homeRM"></Route>
                      <Route element={<ZmHomepage />} path="/homeZM"></Route>
                      <Route element={<ChHomepage />} path="/homeCH"></Route>
                      {/* clericaladmin route */}
                      <Route
                        element={<AdminselectionCA />}
                        path="/clericaladmin"
                      ></Route>
                      <Route
                        element={<Clericalregistration />}
                        path="/clerical_admin_registration"
                      ></Route>
                      
                      <Route
                        element={<Clerialmemberview />}
                        path="/clerical_admin_memberview"
                      ></Route>
                      {/* clericaladmin route*/}
                      {/* Televerification route */}
                      <Route
                        element={<Televerificationregister />}
                        path="/Televerification_registration"
                      ></Route>
                         <Route
                        element={<Televerificationview />}
                        path="/televerification_memberview"
                      ></Route>
                      {/* Televerification route */}

                      <Route element={<Feedbackview />} path="/ask_us_view" />
                      <Route element={<Report />} path="/report"></Route>
                      <Route element={<Users />} path="/users"></Route>
                      <Route
                        element={<AdminPostApprovedHome />}
                        path="/ReportedPost"
                      ></Route>
                      <Route
                        element={<PassiveMemberList1 />}
                        path="/Passive"
                      ></Route>
                      <Route
                        element={<Online_prime />}
                        path="/online_Prime_member"
                      ></Route>
                      <Route
                        element={<Online_basic />}
                        path="/online_basic_member"
                      ></Route>
                      <Route
                        element={<Online_Passive />}
                        path="/online_passive_member"
                      ></Route>
                      <Route
                        element={<Expired_account />}
                        path="/expired_account"
                      ></Route>
                      <Route
                        element={<Expired_account_by_admin />}
                        path="/expired_account_by_admin"
                      ></Route>
                      <Route
                        element={<BrandCons />}
                        path="/brand_add_edit"
                      ></Route>
                      <Route
                        element={<BrandVendor />}
                        path="/brand_vendor_add_edit"
                      ></Route>
                      <Route element={<Service />} path="/information1"></Route>
                      <Route
                        element={<EmergencyServices />}
                        path="/emergency_services"
                      ></Route>
                      <Route element={<Activists />} path="/activists"></Route>
                      <Route
                        element={<Entertainment />}
                        path="/entertainment"
                      ></Route>
                      <Route element={<Leisure />} path="/leisure"></Route>
                      <Route element={<Utilities />} path="/utilities"></Route>
                      <Route element={<Prices />} path="/prices"></Route>
                      <Route
                        element={<AmbulanceServicesHumansList />}
                        path="/ambulance_services_humans"
                      ></Route>
                      <Route
                        element={<AmbulanceHumansReg />}
                        path="/AmbulanceServicesHumansReg"
                      ></Route>
                      <Route
                        element={<AmbulanceHumansEdit />}
                        path="/AmbulanceHumansEdit"
                      ></Route>
                      <Route
                        element={<DeceasedAnimalBurialGroundsReg />}
                        path="/DeceasedAnimalBurialGroundsReg"
                      ></Route>
                      <Route
                        element={<DeceasedAnimalBurialGroundsList />}
                        path="/deceased_animal_burial_grounds"
                      ></Route>
                      <Route
                        element={<DeceasedAnimalBurialGroundsEdit />}
                        path="/DeceasedAnimalBurialGroundsEdit"
                      ></Route>
                      <Route
                        element={<AmbulanceServicesAnimalsList />}
                        path="/ambulance_services_animals"
                      ></Route>
                      <Route
                        element={<AmbulanceAnimalsReg />}
                        path="/AmbulanceAnimalsReg"
                      ></Route>
                      <Route
                        element={<AmbulanceServicesAnimalsEdit />}
                        path="/AmbulanceServicesAnimalsEdit"
                      ></Route>
                      <Route
                        element={<BloodDonorsList />}
                        path="/blood_donors"
                      ></Route>
                      <Route
                        element={<BloodDonorsReg />}
                        path="/BloodDonorsReg"
                      ></Route>
                      <Route
                        element={<BloodDonorsEdit />}
                        path="/BloodDonorsEdit"
                      ></Route>
                      <Route
                        element={<ActivistAnimalView />}
                        path="/activists_animal"
                      ></Route>
                      <Route
                        element={<ActivistAnimalReg />}
                        path="/activist_animal_reg"
                      ></Route>
                      <Route
                        element={<ActivistsAnimalEdit />}
                        path="/activist_animal_edit"
                      ></Route>
                      <Route
                        element={<EnvironmentalActivistsView />}
                        path="/activist_environmental"
                      ></Route>
                      <Route
                        element={<ActivistEnvironmentalReg />}
                        path="/activist_environmental_reg"
                      ></Route>
                      <Route
                        element={<ActivistsEnvironmentalEdit />}
                        path="/activist_environmental_edit"
                      ></Route>
                      <Route
                        element={<SocialActivistsView />}
                        path="/activist_social"
                      ></Route>
                      <Route
                        element={<ActivistSocialReg />}
                        path="/activist_social_reg"
                      ></Route>
                      <Route
                        element={<ActivistsSocialEdit />}
                        path="/activist_social_edit"
                      ></Route>
                      <Route element={<Categorys />} path="/categories"></Route>
                      <Route
                        element={<Consultantscategory />}
                        path="/consultantscategory"
                      ></Route>
                      <Route
                        element={<Vendorcategory />}
                        path="/vendorcategory"
                      ></Route>
                      <Route
                        element={<CategorysAddEdit />}
                        path="/categorysaddedit"
                      ></Route>
                      <Route
                        element={<VendorCategorysAddEdit />}
                        path="/vendorcategorysaddedit"
                      ></Route>
                      <Route
                        element={<SubCategorysAddEdit />}
                        path="/subcategorysaddedit"
                      ></Route>
                      <Route
                        element={<VendorSubCategorysAddEdit />}
                        path="/vendorsubcategorysaddedit"
                      ></Route>
                      <Route
                        element={<InnovationTlieList />}
                        path="/innovation"
                      ></Route>
                      <Route
                        element={<InnovationGridView />}
                        path="/innovation_view"
                      ></Route>
                      <Route
                        element={<InnovationCreate />}
                        path="/create_innovation"
                      ></Route>
                      <Route
                        element={<InnovationEdit />}
                        path="/update_innovation"
                      ></Route>
                      <Route element={<TileMovies />} path="/movies"></Route>
                      <Route
                        element={<MovieGridView />}
                        path="/movies_view"
                      ></Route>
                      <Route
                        element={<MovieCreate />}
                        path="/create_movie"
                      ></Route>
                      <Route
                        element={<MovieEdit />}
                        path="/update_movie"
                      ></Route>
                      <Route element={<OtherGridView />} path="/other"></Route>
                      <Route
                        element={<OtherCreate />}
                        path="/other_create"
                      ></Route>
                      <Route
                        element={<OtherEdit />}
                        path="/other_update"
                      ></Route>
                      <Route
                        element={<WebSeriesMoviesTile />}
                        path="/web_series"
                      ></Route>
                      <Route
                        element={<WebSeriesGridView />}
                        path="/web_series_view"
                      ></Route>
                      <Route
                        element={<WebSeriesCreate />}
                        path="/web_series_create"
                      ></Route>
                      <Route
                        element={<WebSeriesEdit />}
                        path="/web_series_update"
                      ></Route>
                      <Route
                        element={<SportsGridView />}
                        path="/sports"
                      ></Route>
                      <Route
                        element={<SportsCreate />}
                        path="/sports_create"
                      ></Route>
                      <Route
                        element={<SportsEdit />}
                        path="/sports_update"
                      ></Route>
                      <Route
                        element={<BloodBanksList />}
                        path="/blood_banks"
                      ></Route>
                      <Route
                        element={<BloodBankReg />}
                        path="/blood_banks_reg"
                      ></Route>
                      <Route
                        element={<BloodBankEdit />}
                        path="/blood_banks_edit"
                      ></Route>
                      <Route
                        element={<PoliceStationsList />}
                        path="/police_stations"
                      ></Route>
                      <Route
                        element={<PoliceStationsReg />}
                        path="/police_stations_reg"
                      ></Route>
                      <Route
                        element={<PoliceStationsEdit />}
                        path="/police_stations_edit"
                      ></Route>
                      <Route
                        element={<PoliceControlRoomsList />}
                        path="/police_control_rooms"
                      ></Route>
                      <Route
                        element={<PoliceControlRoomsReg />}
                        path="/police_control_rooms_reg"
                      ></Route>
                      <Route
                        element={<PoliceControlRoomsEdit />}
                        path="/police_control_rooms_edit"
                      ></Route>
                      <Route
                        element={<FireStationsList />}
                        path="/fire_stations"
                      ></Route>
                      <Route
                        element={<FireStationsReg />}
                        path="/fire_stations_reg"
                      ></Route>
                      <Route
                        element={<FireStationsEdit />}
                        path="/fire_stations_edit"
                      ></Route>
                      <Route
                        element={<PharmaciesList />}
                        path="/pharmacies"
                      ></Route>
                      <Route
                        element={<PharmaciesReg />}
                        path="/pharmacies_reg"
                      ></Route>
                      <Route
                        element={<PharmaciesEdit />}
                        path="/pharmacies_edit"
                      ></Route>
                      <Route
                        element={<Shopping_Malls />}
                        path="/shopping_malls"
                      ></Route>
                      <Route
                        element={<Shopping_Malls_create />}
                        path="/shopping_malls_create"
                      ></Route>
                      <Route
                        element={<Shopping_Malls_Edit />}
                        path="/shopping_malls_update"
                      ></Route>
                      <Route element={<Theatres />} path="/theatres"></Route>
                      <Route
                        element={<Theatres_create />}
                        path="/theatres_create"
                      ></Route>
                      <Route
                        element={<Theatres_Edit />}
                        path="/theatres_update"
                      ></Route>
                      <Route
                        element={<Childrens_Parks />}
                        path="/childrens_parks"
                      ></Route>
                      <Route
                        element={<Childrens_Parks_create />}
                        path="/childrens_parks_create"
                      ></Route>
                      <Route
                        element={<Childrens_Parks_Edit />}
                        path="/childrens_parks_update"
                      ></Route>
                      <Route
                        element={<Jogging_Parks />}
                        path="/jogging_parks"
                      ></Route>
                      <Route
                        element={<Jogging_Parks_create />}
                        path="/jogging_parks_create"
                      ></Route>
                      <Route
                        element={<Jogging_Parks_Edit />}
                        path="/jogging_parks_update"
                      ></Route>
                      <Route element={<Beaches />} path="/beaches"></Route>
                      <Route
                        element={<Beaches_create />}
                        path="/beaches_create"
                      ></Route>
                      <Route
                        element={<Beaches_Edit />}
                        path="/beaches_update"
                      ></Route>
                      <Route
                        element={<Amusement_Water_theme_Parks />}
                        path="/amusement_water_theme_parks"
                      ></Route>
                      <Route
                        element={<Amusement_Water_theme_Parks_create />}
                        path="/amusement_water_theme_parks_create"
                      ></Route>
                      <Route
                        element={<Amusement_Water_theme_Parks_Edit />}
                        path="/amusement_water_theme_parks_update"
                      ></Route>
                      <Route element={<Museums />} path="/museums"></Route>
                      <Route
                        element={<Museums_create />}
                        path="/museums_create"
                      ></Route>
                      <Route
                        element={<Museums_Edit />}
                        path="/museums_update"
                      ></Route>
                      <Route element={<Libraries />} path="/libraries"></Route>
                      <Route
                        element={<Libraries_create />}
                        path="/libraries_create"
                      ></Route>
                      <Route
                        element={<Libraries_Edit />}
                        path="/libraries_update"
                      ></Route>
                      <Route
                        element={<Social_Clubs />}
                        path="/social_clubs"
                      ></Route>
                      <Route
                        element={<Social_Clubs_create />}
                        path="/social_clubs_create"
                      ></Route>
                      <Route
                        element={<Social_Clubs_Edit />}
                        path="/social_clubs_update"
                      ></Route>
                      <Route
                        element={<Gas_Agencies_Govt />}
                        path="/gas_agencies_govt"
                      ></Route>
                      <Route
                        element={<Gas_Agencies_Govt_create />}
                        path="/gas_agencies_govt_create"
                      ></Route>
                      <Route
                        element={<Gas_Agencies_Govt_Edit />}
                        path="/gas_agencies_govt_update"
                      ></Route>
                      <Route
                        element={<Gas_Agencies_Private />}
                        path="/gas_agencies_private"
                      ></Route>
                      <Route
                        element={<Gas_Agencies_Private_create />}
                        path="/gas_agencies_private_create"
                      ></Route>
                      <Route
                        element={<Gas_Agencies_Private_Edit />}
                        path="/gas_agencies_private_update"
                      ></Route>
                      <Route
                        element={<Newspaper_Agencies />}
                        path="/newspaper_agencies"
                      ></Route>
                      <Route
                        element={<Newspaper_Agencies_create />}
                        path="/newspaper_agencies_create"
                      ></Route>
                      <Route
                        element={<Newspaper_Agencies_Edit />}
                        path="/newspaper_agencies_update"
                      ></Route>
                      <Route
                        element={<Milk_Vendors />}
                        path="/milk_vendors"
                      ></Route>
                      <Route
                        element={<Milk_Vendors_create />}
                        path="/milk_vendors_create"
                      ></Route>
                      <Route
                        element={<Milk_Vendors_Edit />}
                        path="/milk_vendors_update"
                      ></Route>
                      <Route
                        element={<Vegetable_Markets />}
                        path="/vegetable_markets"
                      ></Route>
                      <Route
                        element={<Vegetable_Markets_create />}
                        path="/vegetable_markets_create"
                      ></Route>
                      <Route
                        element={<Vegetable_Markets_Edit />}
                        path="/vegetable_markets_update"
                      ></Route>
                      <Route
                        element={<Meat_Markets />}
                        path="/meat_markets"
                      ></Route>
                      <Route
                        element={<Meat_Markets_create />}
                        path="/meat_markets_create"
                      ></Route>
                      <Route
                        element={<Meat_Markets_Edit />}
                        path="/meat_markets_update"
                      ></Route>
                      <Route
                        element={<Waste_Disposal />}
                        path="/waste_disposal"
                      ></Route>
                      <Route
                        element={<Waste_Disposal_create />}
                        path="/waste_disposal_create"
                      ></Route>
                      <Route
                        element={<Waste_Disposal_Edit />}
                        path="/waste_disposal_update"
                      ></Route>
                      <Route
                        element={<Fuel_Stations />}
                        path="/fuel_stations"
                      ></Route>
                      <Route
                        element={<Fuel_Stations_create />}
                        path="/fuel_stations_create"
                      ></Route>
                      <Route
                        element={<Fuel_Stations_Edit />}
                        path="/fuel_stations_update"
                      ></Route>
                      \<Route element={<Bus_Stand />} path="/bus_stand"></Route>
                      <Route
                        element={<Bus_Stand_create />}
                        path="/bus_stand_create"
                      ></Route>
                      <Route
                        element={<Bus_Stand_Edit />}
                        path="/bus_stand_update"
                      ></Route>
                      <Route
                        element={<Metro_Stations />}
                        path="/metro_stations"
                      ></Route>
                      <Route
                        element={<Metro_Stations_create />}
                        path="/metro_stations_create"
                      ></Route>
                      <Route
                        element={<Metro_Stations_Edit />}
                        path="/metro_stations_update"
                      ></Route>
                      <Route
                        element={<Railway_Stations />}
                        path="/railway_stations"
                      ></Route>
                      <Route
                        element={<Railway_Stations_create />}
                        path="/railway_stations_create"
                      ></Route>
                      <Route
                        element={<Railway_Stations_Edit />}
                        path="/railway_stations_update"
                      ></Route>
                      <Route element={<Airports />} path="/airports"></Route>
                      <Route
                        element={<Airports_create />}
                        path="/airports_create"
                      ></Route>
                      <Route
                        element={<Airports_Edit />}
                        path="/airports_update"
                      ></Route>
                      <Route
                        element={<Post_Offices />}
                        path="/post_offices"
                      ></Route>
                      <Route
                        element={<Post_Offices_create />}
                        path="/post_offices_create"
                      ></Route>
                      <Route
                        element={<Post_Offices_Edit />}
                        path="/post_offices_update"
                      ></Route>
                      <Route
                        element={<Regional_Transport_Offices />}
                        path="/regional_transport_offices"
                      ></Route>
                      <Route
                        element={<Regional_Transport_Offices_create />}
                        path="/regional_transport_offices_create"
                      ></Route>
                      <Route
                        element={<Regional_Transport_Offices_Edit />}
                        path="/regional_transport_offices_update"
                      ></Route>
                      <Route element={<Banks />} path="/banks"></Route>
                      <Route
                        element={<Banks_create />}
                        path="/banks_create"
                      ></Route>
                      <Route
                        element={<Banks_Edit />}
                        path="/banks_update"
                      ></Route>
                      <Route element={<Telecom />} path="/telecom"></Route>
                      <Route
                        element={<Telecom_create />}
                        path="/telecom_create"
                      ></Route>
                      <Route
                        element={<Telecom_Edit />}
                        path="/telecom_update"
                      ></Route>
                      <Route element={<Media />} path="/media"></Route>
                      <Route
                        element={<Media_create />}
                        path="/media_create"
                      ></Route>
                      <Route
                        element={<Media_Edit />}
                        path="/media_update"
                      ></Route>
                      <Route element={<Fuel />} path="/fuel"></Route>
                      <Route
                        element={<Fuel_create />}
                        path="/fuel_create"
                      ></Route>
                      <Route
                        element={<Fuel_Edit />}
                        path="/fuel_update"
                      ></Route>
                      <Route element={<Jewellery />} path="/jewellery"></Route>
                      <Route
                        element={<Jewellery_create />}
                        path="/Jewellers_create"
                      ></Route>
                      <Route
                        element={<Jewellery_Edit />}
                        path="/Jewellers_update"
                      ></Route>
                      <Route
                        element={<Ott_Platform />}
                        path="/ott_platforms"
                      ></Route>
                      <Route
                        element={<Ott_Platform_create />}
                        path="/ott_platform_create"
                      ></Route>
                      <Route
                        element={<Ott_Platform_Edit />}
                        path="/ott_platform_update"
                      ></Route>
                      <Route element={<DTH />} path="/dth_services"></Route>
                      <Route
                        element={<DTH_create />}
                        path="/dth_create"
                      ></Route>
                      <Route element={<DTH_Edit />} path="/dth_update"></Route>
                      <Route
                        element={<Vegetables />}
                        path="/vegetables"
                      ></Route>
                      <Route
                        element={<Vegetables_create />}
                        path="/vegetables_create"
                      ></Route>
                      <Route
                        element={<Vegetables_Edit />}
                        path="/vegetables_update"
                      ></Route>
                      <Route element={<Sea_Food />} path="/sea_food"></Route>
                      <Route
                        element={<Sea_Food_create />}
                        path="/sea_food_create"
                      ></Route>
                      <Route
                        element={<Sea_Food_Edit />}
                        path="/sea_food_update"
                      ></Route>
                      <Route element={<Chicken />} path="/chicken"></Route>
                      <Route
                        element={<Chicken_create />}
                        path="/chicken_create"
                      ></Route>
                      <Route
                        element={<Chicken_Edit />}
                        path="/chicken_update"
                      ></Route>
                      <Route element={<Mutton />} path="/mutton"></Route>
                      <Route
                        element={<Mutton_create />}
                        path="/mutton_create"
                      ></Route>
                      <Route
                        element={<Mutton_Edit />}
                        path="/mutton_update"
                      ></Route>
                      {/* <Route element={<ProPostView />} path="posttoolbarview" ></Route> */}
                      {/* <Route element={<Propostlist />} path="pro_featured_consultants"></Route> */}
                      {/* <Route element={<Propostlist1 />} path="pro_featured_vendors"></Route> */}
                      {/* <Route element={<ProPostView1 />} path="posttoolbarview_vendor" ></Route> */}
                      <Route
                        element={<Non_registered_members />}
                        path="/non_registered_members"
                      ></Route>
                      <Route
                        element={<Registered_members />}
                        path="/registered_members"
                      ></Route>
                      <Route
                        element={<Profile_created_members />}
                        path="/profile_created_members"
                      ></Route>
                      <Route
                        element={<Payment_details_list />}
                        path="/payment_details_list"
                      ></Route>
                      <Route
                        element={<CreateAdmin />}
                        path="/create_admin"
                      ></Route>
                      <Route
                        element={<ManageAdmin />}
                        path="/manage_admin"
                      ></Route>
                      <Route
                        element={<SubAdminList type={type} />}
                        path="/sub_admin_list"
                      ></Route>
                      <Route
                        element={<SubAdminProfile />}
                        path="/sub_admin_profile"
                      ></Route>
                      <Route
                        element={<UpdateAdmin />}
                        path="/sub_admin_update"
                      ></Route>
                      <Route
                        element={<Priceing type={type} Auth1={Auth1} />}
                        path="/c_v_registration"
                      ></Route>
                      <Route
                        element={<ActiveRegisterv2 />}
                        path="/active_register"
                      ></Route>
                      <Route
                        element={<ActiveRegisterv3 />}
                        path="/static_listing_register"
                      ></Route>
                      <Route
                        element={<ActiveRegister />}
                        path="/pro-active_register"
                      ></Route>
                      <Route
                        element={<ProfileCreation />}
                        path="next/*"
                      ></Route>
                      <Route
                        element={<ProfileCreation2 />}
                        path="next_/*"
                      ></Route>
                      <Route
                        element={<ProfileCreation3 />}
                        path="next__/*"
                      ></Route>
                      <Route element={<Payment />} path="/payment"></Route>
                      <Route
                        element={<Pextenalview />}
                        path="/vendor_profile"
                      ></Route>
                      <Route
                        element={<ExternalPview />}
                        path="/consultant_profile"
                      ></Route>
                      <Route
                        element={<TempProfiles />}
                        path="/TempProfiles"
                      ></Route>
                      <Route
                        element={<TempProfilesProduct />}
                        path="/TempProfilesProduct"
                      ></Route>
                      <Route
                        element={<TempProfilesV3 />}
                        path="/TempProfilesStatic"
                      ></Route>
                      <Route
                        element={<PassiveRegister />}
                        path="/passive_register"
                      ></Route>
                      <Route
                        element={<PassiveProfileCreation />}
                        path="/passive_profile_creation"
                      ></Route>
                      <Route
                        element={<Marketing_feedback />}
                        path="/marketing_feedback"
                      ></Route>
                      <Route
                        element={<Employe_Marketing_Table />}
                        path="/marketing_feedback_view"
                      ></Route>
                      <Route
                        element={<Offline_profile_Creation_list />}
                        path="/offline_profile_created_list"
                      ></Route>
                      <Route
                        element={<Offline_Account_Print_bill />}
                        path="/offline_account_print_invoice"
                      ></Route>
                      <Route
                        element={<EditConsultantV1 />}
                        path="/update_service_provider_prime"
                      ></Route>
                      <Route
                        element={<EditConsultantV2 />}
                        path="/update_service_provider_basic"
                      ></Route>
                      <Route
                        element={<EditConsultantV3 />}
                        path="/update_service_provider_static"
                      ></Route>
                      <Route
                        element={<EditProductV1 />}
                        path="/update_product_retailer_prime"
                      ></Route>
                      <Route
                        element={<EditProductV2 />}
                        path="/update_product_retailer_basic"
                      ></Route>
                      <Route
                        element={<EditProductV3 />}
                        path="/update_product_retailer_static"
                      ></Route>
                      <Route
                        element={<Advertisementt />}
                        path="/Advertisement"
                      />
                      <Route element={<AddPayment />} path="/add_payment" />
                      <Route
                        element={<AddPaymentOut />}
                        path="/add_payment_out"
                      />
                      <Route element={<UsersAd />} path="/usersad"></Route>
                      <Route
                        element={<Updatetermsandcondition />}
                        path="/updatetermsandcondition"
                      ></Route>
                      <Route
                        element={<Ametermsandcondition />}
                        path="/fsetermsandcondition"
                      ></Route>
                      <Route
                        element={<Csmtermsandcondition />}
                        path="/csmtermsandcondition"
                      ></Route>
                      <Route
                        element={<Smetermsandcondition />}
                        path="/smetermsandcondition"
                      ></Route>
                      <Route
                        element={<Updatesmeprofile Auth1={Auth1} />}
                        path="/prochure_SME_account"
                      ></Route>
                      <Route
                        element={<ConsultantsAdd />}
                        path="/Consultants_add"
                      ></Route>
                      <Route
                        element={<ProductStoreAdd />}
                        path="/Product_store_add"
                      ></Route>
                      <Route
                        element={<LinkForm />}
                        path="/invite_direct_link_forms"
                      />
                      <Route
                        element={<Account_details_executive />}
                        path="/bank_accounts_details"
                      ></Route>
                      <Route
                        element={<Sub_admin_executive_details_page />}
                        path="/profile_created_executive"
                      ></Route>
                      <Route
                        element={<Admin_executive_earnings />}
                        path="/ame_created_accounts"
                      ></Route>
                      <Route
                        element={<SME_created_accounts />}
                        path="/sme_created_accounts"
                      ></Route>
                      <Route
                        element={<Sub_admin_my_earnings />}
                        path="/my_earnings"
                      ></Route>
                      <Route
                        element={<Updateameprofile />}
                        path="/prochure_AME_account"
                      ></Route>
                      <Route
                        element={<Updatesmeprofile />}
                        path="/prochure_SME_account"
                      ></Route>
                      {/* <Route
                    element={<Payment2 type={type} Auth1={Auth1} />}
                    path="/payment_by_affliate"
                  ></Route> */}
                      <Route
                        element={<LinkForm2 Auth1={Auth1} />}
                        path="/send_profile_creation_link"
                      />
                      {/* jobconnect */}
                      <Route
                        element={<Jobopportunities />}
                        path="/job_opportunities"
                      />
                      <Route
                        element={<Applicantsview />}
                        path="/applicants_view"
                      />
                      <Route
                        element={<SMEList type={type} />}
                        path="/manage_sme"
                      />
                      <Route
                        element={<SMEProfile type={type} />}
                        path="/sme_profile"
                      />
                      <Route
                        element={<SME_salary_remittances type={type} />}
                        path="/sme_salary_remittances"
                      />
                      <Route
                        element={<Viewnotificationsmeame />}
                        path="/viewnotification"
                      />
                      <Route
                        element={<Add_information_Cat_and_Subcat />}
                        path="/information_category"
                      />
                      <Route
                        element={<InformationFormsEdit Auth1={Auth1} />}
                        path="/information_forms_edit"
                      ></Route>
                      <Route
                        element={<InformationForms Auth1={Auth1} />}
                        path="/information_forms"
                      ></Route>
                      <Route element={<ProForum />} path="/proform_topic" />
                      <Route
                        element={<Appointmentheader />}
                        path="/create_appointment_letter"
                      />
                      <Route element={<Salessme />} path="/salessme" />
                      <Route
                        element={<Couponadminsme />}
                        path="/couponcreate_sme"
                      />
                      <Route
                        element={<Couponadminame />}
                        path="/couponcreate_ame"
                      />
                      {/* jobconnect */}
                      {/* <Route element={<LinkForm />} path="/LinkFormN" /> */}
                      {/* executive_earnings */}
                      {/* <Route element={<NewConsultantCategory/>} path="/new_consultant_category"></Route>
                <Route element={<NewVendorCategory/>} path="/new_vendor_category"></Route> */}
                      {/* <Route element={<ViewModarator />} path="/ViewModarator"></Route> */}
                      {/* <Route element={<Post12/>} path="/Allpostas"></Route> */}
                      <Route
                        element={<Information_Category_list />}
                        path="/information"
                      />
                      <Route
                        element={<Information_Subcategory_list />}
                        path="/information_subcategory"
                      />
                      <Route
                        element={<Information_Subsubcategory_list />}
                        path="/information_sub_sub_category"
                      />
                      <Route
                        element={<Information_listing />}
                        path="/Information_listing"
                      />
                      <Route
                        element={<Information_Type1 />}
                        path="/Information_profile"
                      />
                      <Route
                        element={<Information_listing_edit />}
                        path="/Information_view_for_edit"
                      />
                      <Route
                        element={<Information_Medical_Store />}
                        path="/Information_medical_store"
                      />
                      <Route
                        element={<Information_Medical_Store_profile />}
                        path="/Information_medical_store_profile"
                      />
                      {/* <Route
                    element={<Information_Type3 />}
                    path="/Information_details"
                  />
                  <Route
                    element={<Information_Type4 />}
                    path="/Information_entertiment"
                  /> */}
                      <Route
                        element={<Data_entry_formTable admin={true} />}
                        path="/data_entry_formTable"
                      />
                      <Route
                        element={<Form key={'skjdf'} />}
                        path="/DCE_data_entry"
                      />
                      <Route
                        element={<Form key={'sdjjfhsui'} />}
                        path="/super_admin_data_entry"
                      />
                      <Route
                        element={<VFprofile />}
                        path="/prochure_free_listing_profile"
                      />
                      <Route
                        element={<ViparFriendprofileview />}
                        path="/prochure_free_listing_view"
                      />
                      <Route
                        element={<Clerical2registration />}
                        path="/clerical_admin_registration_2"
                      />
                      <Route
                        element={<Clerical2memberview />}
                        path="/clerical_admin_memberview_2"
                      />
                    </Routes>
                  ) : // RM ROUTES
                  type === 'REGIONAL_MANAGER' ? (
                    <>
                      <Routes>
                        <Route element={<NotFoundPage />} path="/*"></Route>
                        <Route
                          element={<AdminDashBord type={type} Auth1={Auth1} />}
                          path="/"
                        ></Route>
                        <Route
                          element={
                            <AccountdetailsRM type={type} Auth1={Auth1} />
                          }
                          path="/regionalmanager_accountdetails"
                        ></Route>
                        <Route
                          element={<AdminselectionCSM />}
                          path="/clustersalesmanager"
                        ></Route>
                        <Route
                          element={<MemberviewCSM />}
                          path="/clustersalesmanager_memberview"
                        ></Route>
                        <Route
                          element={<RegistrationCSmanager />}
                          path="/clustersalesmanager_registration"
                        ></Route>
                        <Route
                          element={<AdminselectionFME />}
                          path="/fme"
                        ></Route>
                        <Route
                          element={<RegisterFME />}
                          path="/DCE_registration"
                        />

                        <Route
                          element={<CSMprofile type={type} Auth1={Auth1} />}
                          path="/clustersalesmanager_profile"
                        ></Route>
                        <Route
                          element={<AdminselectionFSE />}
                          path="/fse"
                        ></Route>
                        <Route
                          element={<RegisterFSE type={type} />}
                          path="/fse_registration"
                        />
                        <Route
                          element={<FSEMemberviewRM />}
                          path="/fse_memberview"
                        />
                        <Route
                          element={<FMEMemberviewRM />}
                          path="/fme_memberview"
                        />
                        <Route
                          element={<SMEProfile type={type} />}
                          path="/sme_profile"
                        />
                      </Routes>
                    </>
                  ) : // RM ROUTES
                  //AM ROUTES
                  type === 'AREA_MANAGER' ? (
                    <>
                      <Routes>
                        <Route element={<NotFoundPage />} path="/*"></Route>
                        <Route
                          element={<AdminDashBord type={type} Auth1={Auth1} />}
                          path="/"
                        ></Route>
                        <Route
                          element={<AdminselectionFME />}
                          path="/fme"
                        ></Route>
                        <Route
                          element={
                            <AccountdetailsCSM type={type} Auth1={Auth1} />
                          }
                          path="/clustersalesmanager_accountdetails"
                        ></Route>

                        <Route
                          element={<RegisterFME />}
                          path="/DCE_registration"
                        />

                        <Route
                          element={<FMEmemberviewCSM />}
                          path="/fme_memberview"
                        />

                        <Route
                          element={<CSMacceptedtandc />}
                          path="/csm_acceptedt&c"
                        />
                      </Routes>
                    </>
                  ) : //AM ROUTES
                  // Clerical1Routes
                  type === 'CLERICALADMIN' ? (
                    <>
                      <Routes>
                        <Route element={<NotFoundPage />} path="/*"></Route>
                        <Route
                          element={<AdminDashBord type={type} Auth1={Auth1} />}
                          path="/"
                        ></Route>
                        <Route
                          element={
                            <Data_entry_formTable admin={true} type={type} />
                          }
                          path="/data_entry_formTable"
                        />
                        <Route
                          element={<Form key={'dhf'} />}
                          path="/clerical_admin_data_entry"
                        />
                        <Route
                          element={<Form key={'skjdffdg'} />}
                          path="/DCE_data_entry"
                        />

                        <Route
                          element={<Admin_executive_earnings />}
                          path="/ame_created_accounts"
                        ></Route>
                        <Route
                          element={<VFprofile />}
                          path="/prochure_free_listing_profile"
                        />
                        <Route
                          element={<ViparFriendprofileview />}
                          path="/prochure_free_listing_view"
                        />
                      </Routes>
                    </>
                  ) : //Clerical1Routes
                  //clerical2Routes
                  type === 'CLERICALADMIN2' ? (
                    <>
                      <Routes>
                        <Route element={<NotFoundPage />} path="/*"></Route>
                        <Route
                          element={<AdminDashBord type={type} Auth1={Auth1} />}
                          path="/"
                        ></Route>
                        <Route
                          element={<Jobopportunities />}
                          path="/job_opportunities"
                        />
                        <Route
                          element={<Applicantsview />}
                          path="/applicants_view"
                        />
                        <Route
                          element={
                            <Feedback
                              callAskCount={callAskCount}
                              dataCount={dataCount}
                            />
                          }
                          path="/prochure_ask_us"
                        />
                        <Route element={<Report />} path="/report"></Route>
                      </Routes>
                    </>
                  ) :     type === 'TELEVERIFICATION' ? (
                    <>
                      <Routes>
                        <Route element={<NotFoundPage />} path="/*"></Route>
                        <Route
                          element={<AdminDashBord type={type} Auth1={Auth1} />}
                          path="/"
                        ></Route>
                        <Route
                          element={
                            <Data_entry_formTable admin={true} type={type} />
                          }
                          path="/data_entry_formTable"
                        />
                        <Route
                          element={<Form key={'dhf'} />}
                          path="/Tele_admin_data_entry"
                        />
                        <Route
                          element={<Form key={'skjdffdg'} />}
                          path="/DCE_data_entry"
                        />

                        <Route
                          element={<Admin_executive_earnings />}
                          path="/ame_created_accounts"
                        ></Route>
                        <Route
                          element={<VFprofile />}
                          path="/prochure_free_listing_profile"
                        />
                        <Route
                          element={<ViparFriendprofileview />}
                          path="/prochure_free_listing_view"
                        />
                      </Routes>
                    </>
                  ): (
                    <>
                      {checkterms ? (
                        <>
                          <Routes>
                            <Route element={<NotFoundPage />} path="/*"></Route>
                            <Route
                              element={
                                <AdminDashBord type={type} Auth1={Auth1} />
                              }
                              path="/"
                            ></Route>
                            <Route
                              element={
                                <Sub_admin_executive_details_page
                                  Auth1={Auth1}
                                />
                              }
                              path="/profile_created_executive"
                            ></Route>
                            <Route
                              element={
                                <Admin_executive_earnings Auth1={Auth1} />
                              }
                              path="/executive_earnings"
                            ></Route>
                            <Route
                              element={<Sub_admin_my_earnings Auth1={Auth1} />}
                              path="/my_earnings"
                            ></Route>

                            <Route
                              element={<Updateameprofile Auth1={Auth1} />}
                              path="/prochure_AME_account"
                            ></Route>
                            <Route
                              element={<Updatesmeprofile Auth1={Auth1} />}
                              path="/prochure_SME_account"
                            ></Route>
                            <Route
                              element={<Payment2 type={type} Auth1={Auth1} />}
                              path="/payment_by_affliate"
                            ></Route>
                            <Route
                              element={<LinkForm2 Auth1={Auth1} />}
                              path="/send_profile_creation_link"
                            />

                            <Route
                              element={<Form DCE={true} key="jhbd" />}
                              path="/DCE_data_entry"
                            />

                            {/* <Route element={<Form key={'asdas'} />} path="/data_entry_form_edit" /> */}

                            <Route
                              element={<Data_entry_formTable />}
                              path="/data_entry_formTable"
                            />
                            <Route
                              element={<Notification />}
                              path="/DCE_notification"
                            />
                            <Route element={<Pausepage />} path="/DCE_entry_" />
                            <Route
                              element={<Abstractdailymonthlytargets />}
                              path="/DCE_targets"
                            />

                            <Route
                              element={<Priceing type={type} Auth1={Auth1} />}
                              path="/c_v_registration"
                            ></Route>

                            <Route
                              element={<ActiveRegisterv2 />}
                              path="/active_register"
                            ></Route>
                            <Route
                              element={<ActiveRegisterv3 />}
                              path="/static_listing_register"
                            ></Route>
                            <Route
                              element={<ActiveRegister />}
                              path="/pro-active_register"
                            ></Route>
                            <Route
                              element={<ProfileCreation />}
                              path="next/*"
                            ></Route>
                            <Route
                              element={<ProfileCreation2 />}
                              path="next_/*"
                            ></Route>
                            <Route
                              element={<ProfileCreation3 />}
                              path="next__/*"
                            ></Route>
                            <Route
                              element={<Pextenalview />}
                              path="/vendor_profile"
                            ></Route>
                            <Route
                              element={<ExternalPview />}
                              path="/consultant_profile"
                            ></Route>
                            <Route
                              element={<TempProfiles />}
                              path="/TempProfiles"
                            ></Route>
                            <Route
                              element={<TempProfilesProduct />}
                              path="/TempProfilesProduct"
                            ></Route>
                            <Route
                              element={<TempProfilesV3 />}
                              path="/TempProfilesStatic"
                            ></Route>
                            <Route
                              element={<PassiveRegister />}
                              path="/passive_register"
                            ></Route>
                            <Route
                              element={<PassiveProfileCreation />}
                              path="/passive_profile_creation"
                            ></Route>
                            <Route
                              element={
                                <Salary_remittances_for_SME Auth1={Auth1} />
                              }
                              path="/salary_remittances"
                            ></Route>
                            <Route
                              element={<View_categories Auth1={Auth1} />}
                              path="/view_categories"
                            ></Route>
                            <Route
                              element={<Update_ame_tc Auth1={Auth1} />}
                              path="/ame_terms&conditions"
                            ></Route>
                            <Route
                              element={<Update_sme_tc Auth1={Auth1} />}
                              path="/sme_terms&conditions"
                            ></Route>
                            <Route
                              element={<Appointmentsmeuserview />}
                              path="/sme_appointmentletter"
                              Auth1={Auth1}
                              id={''}
                            />

                            <Route
                              element={<Couponviewame />}
                              path="/coupon_ame"
                              Auth1={Auth1}
                              id={''}
                            />
                            <Route
                              element={<Couponviewsme />}
                              path="/coupon_sme"
                              Auth1={Auth1}
                              id={''}
                            />

                            <Route
                              element={<Updatesmeprofile Auth1={Auth1} />}
                              path="/prochure_SME_account"
                            ></Route>
                            <Route
                              element={<Updateameprofile Auth1={Auth1} />}
                              path="/prochure_AME_account"
                            ></Route>
                          </Routes>
                        </>
                      ) : (
                        <>
                          {!checkterms ? (
                            Salary ? (
                              <>
                                <Routes>
                                  <Route
                                    element={<NotFoundPage />}
                                    path="/*"
                                  ></Route>
                                  <Route
                                    element={<Smeupdatetermsandcondition />}
                                    path="/"
                                  ></Route>
                                </Routes>
                              </>
                            ) : (
                              <>
                                <Routes>
                                  <Route
                                    element={<NotFoundPage />}
                                    path="/*"
                                  ></Route>
                                  <Route
                                    element={<Ameupdatetermsandcondition />}
                                    path="/"
                                  ></Route>
                                </Routes>
                              </>
                            )
                          ) : (
                            <>
                              <SpinnerBig />
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <SpinnerBig />
              )}
            </div>
          </main>
          {/* <div className="">
            <Footer />
          </div> */}
          {/*Main layout*/}
        </>
      )}

      <Modal show={shows} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="continer">
            <h6>STEPS TO FOLLOW :</h6>
            <br></br>
            1. Select the MEMBERSHIP TYPE.<br></br>
            2. Fill in the relevant details on the REGISTRATION page.<br></br>
            3. Select the appropriate CLASSIFICATION.<br></br>
            4. Select the appropriate CATEGORY and SUB CATEGORY on the Profile
            Creation Form.<br></br>
            5. FILL in the FORM completely.<br></br>
            6. Fields with ( * ) are MANDATORY.<br></br>
            7. Apply DISCOUNT COUPON (if any) on the Payment page.<br></br>
            8. Make PAYMENT via Razorpay.
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={shows1} onHide={handleClose1} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="continer">
            <h6>STEPS TO FOLLOW :</h6>
            <br></br>
            1. Select the MEMBERSHIP TYPE and CLASSIFICATION.
            <br></br>
            2. Select CATEGORY and Click on 'GENERATE LINK' IF you want to share
            only the Category Link.
            <br></br>
            3. Select both CATEGORY and SUB CATEGORY and Click on 'GENERATE
            LINK' IF you want to share only the Sub Category Link.
            <br></br>
            4. SHARE the generated LINK with your prospective Customer on
            whatsapp or via sms.
            <br></br>
            <br></br>
            Please Note :<br></br>
            1. Ask your Customer to FILL in the FORM completely.
            <br></br>
            2. Fields with ( * ) are MANDATORY.
            <br></br>
            3. Share the DISCOUNT COUPON (if any) to be applied on the Payment
            page.
            <br></br>
            4. Ask them to Mention your Unique REFERRAL CODE / NAME Mandatorily
            IF you want your commission. Else your commission cannot be claimed
            by you.
            <br></br>
            5. Ask them to Make PAYMENT via Razorpay.
          </div>
        </Modal.Body>
      </Modal>

      {/* new profile form */}
      <Modal show={show3} onHide={handleClose3} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="continer">
            <h6>PHOTO PROTOCOLS :</h6>
            <br></br>
            a) Photograpgh MUST be a very clear one and NOT blurred.
            <br />
            b) Photograph MUST be a Still picture and NOT taken while in motion.
            <br />
            c) Photograph MUST be suitable for 16:9 resolution.
            <br />
            d) Photograph MUST NOT be taken from an angle.
            <br />
            e) Photograpgh MUST be taken from exactly the front of the Office /
            Shop / Showroom.
            <br />
            f) Photograph MUST contain the Name Board of the Office / Shop /
            Showroom.
            <br />
            g) Photograph MUST have the Name Board captured such that the board
            is very clearly visible and readable.
            <br />
            h) Photograpgh MUST NOT contain any other object in the background
            or in and around the Office / Shop / Showroom.
            <br />
            i) Photograph MUST be taken without any obstruction of a Human /
            Object covering the front façade of the Office / Shop / Showroom.
            <br />
            j) Photograpgh MUST be taken from a relatively appropriate close
            distance of the Office / Shop / Showroom and NOT from far distance.
          </div>
        </Modal.Body>
      </Modal>
      {/* new profile form */}
    </div>
  );
}

export default AdminDashbord;
