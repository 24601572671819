import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Axios from "axios";
import Moment from 'moment';
import { width } from '@mui/system';
import { Link } from 'react-router-dom'

// const columns = [
//     { field: 'id', headerName: 'ID', width: 70 },
//     { field: 'firstName', headerName: 'First name', width: 130 },
//     { field: 'lastName', headerName: 'Last name', width: 130 },
//     {
//       field: 'age',
//       headerName: 'Age',
//       type: 'number',
//       width: 90,
//     },
//     {
//       field: 'fullName',
//       headerName: 'Full name',
//       description: 'This column has a value getter and is not sortable.',
//       sortable: false,
//       width: 160,
//       valueGetter: (params) =>
//         `${params.row.firstName || ''} ${params.row.lastName || ''}`,
//     },
//   ];

//   const rows = [
//     { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
//     { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//     { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//     { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//     { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//     { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//     { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//     { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//     { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
//   ];
export const Employe_Marketing_Table = () => {
    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'Emp_id', headerName: 'Employee ID' },
        { field: 'Date', headerName: 'Date', width:150 },
        { field: 'City', headerName: 'City' , width:150},
        { field: 'Street_road', headerName: 'Street/ Road', width:150 },
        { field: 'Side', headerName: 'Side', width:150 },
        { field: 'Name', headerName: 'Profile Name', width:300 },
        { field: 'Classification', headerName: 'Classification', width:150 },
        // { field: 'mainCat', headerName: 'Category' },
        { field: 'subCat', headerName: ' Sub Category', width:150 },
        { field: 'Response', headerName: ' Response', width:150 },
        { field: 'Remarks', headerName: 'Remarks' ,width:200},
        { field: 'Type', headerName: 'Membership Type', width:150 },
        { field: 'Fees', headerName: 'Fees', width:150 },
        { field: 'Coupon', headerName: 'Coupon Code', width:150 },
        { field: 'CreatedBy', headerName: 'Created By' },
    //     { field: 'Update',
    //      renderCell:(values)=>{
    //         return(
    //             // <Link to={"/marketing_feedback?pi="+values.row._id&&"cat="+values.row.Classification}>
    //             //                 <button className="btn pro-spaces-button3"  >
    //             //  Update
    //             // </button>
    //             // </Link>
    //             <Link to={`/marketing_feedback?pi=${values.row._id}&&cat=${values.row.Classification}`}>
    //              <button className="btn pro-spaces-button3"  >
    //              Update
    //              </button>
    //             </Link>
    //         )
    //         // onClick={(e)=>{
    //         //     handleClick(e,values)
    //         // }}
    //      }
    // },

    ]

    const handleClick=(e,value)=>{
         console.log(value.row._id)
    }

    const [rows, setDatas] = useState([])
    useEffect(() => {
        Axios.get('/api/Admin/GetMarketingFeedBack').then(res => {
            if (res.data) {
                console.log(res.data)
                setDatas(res.data.map((elem, index) => { return { id: index + 1,...elem,subCat:elem.subCat.sub,Date:Moment(elem.Date).format('DD-MM-YYYY'), } }))
            }
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    // const rows = [
    //     // {"Emp_id":76}
    //     // Datas.map((e)=>{
    //     //     id ,Emp_id:Datas.,Date:Datas.,City:Datas.,Street_road:Datas.,Side:Datas.,Name:Datas.,Classification:Datas.,mainCat:Datas.,subCat:Datas.,Response:Datas.,Remarks:Datas.,Fees:Datas.,Coupon:Datas.,CreatedBy  

    //     // })
    // ]


    return (
        <div>
            {/* <h1>hai</h1> */}
            <div className='bg-light container' style={{ height: 900, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={15}
                    // rowsPerPageOptions={[5, 10, 25,50,100,200,500,1000]}
                    // rowsPerPage={rowsPerPage}
                    // onRowsPerPageChange={handleChangeRowsPerPage}

                // checkboxSelection

                />
            </div>
        </div>
    )
}
