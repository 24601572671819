import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { TestEditor } from '../TestEditor';
import { Modal } from 'react-bootstrap';

export const Appointmentheader = () => {
  const [first, setFirst] = useState({
    text: '',
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div style={{ overflow: 'scroll' }}>
        <div>
          <div className="py-3 px-2 h-100">
            <TestEditor
              style={{ whiteSpace: 'pre-line', minHeight: '400px' }}
              onChange={(e) => {
                setFirst({ ...first, text: e });
              }}
            />
          </div>
        </div>
      </div>
      <div className="py-2 px-2">
        <button onClick={handleShow} className="btn pro-spaces-button3">
          Preview
        </button>
      </div>

      <Modal show={show} onHide={handleClose} fullscreen>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-0">
          <div id="appoint" style={{ width: '1245px', height: '1754px' }}>
            <div
              id="viparcare"
              style={{ fontFamily: 'FootlightMTLight', display: 'block' }}
            >
              <div
                className="  px-3  w-100"
                style={{
                  display: 'block',
                  height: '147px',
                  backgroundColor: '',
                }}
              >
                <div className="">
                  <div className="d-flex justify-content-between">
                    <div
                      className=""
                      style={{ marginTop: '22px', marginLeft: '25px' }}
                    >
                      <Link
                        class="navbar-brand text-success logo align-self-center"
                        to=""
                      >
                        <img
                          className=""
                          src="/roundround.png"
                          alt=""
                          height="100px"
                          width="100px"
                        />
                      </Link>
                    </div>

                    {/* <div className="" style={{ marginTop: '19px' }}>
                      <div className="d-flex justify-content-center align-items-start pt-3">
                        <span
                          className="vipar transform "
                          style={{
                            fontSize: '27px',
                            color: '#99d050',
                          }}
                        >
                          Vipar&nbsp;
                          <sup class="text-white" style={{ fontSize: '13px' }}>
                            &reg;
                          </sup>
                        </span>
                      </div>

                      <div className="text-end ">
                        <span style={{ fontSize: '22px' }} className="pe-2 ml">
                          <span className="vipar " style={{ fontSize: '22px' }}>
                            Vi
                          </span>
                          <span
                            className="mono  "
                            style={{
                              fontSize: '22px',

                              color: 'CFD6E1',
                            }}
                          >
                            sionary
                          </span>{' '}
                          &nbsp;
                          <span className="vipar " style={{ fontSize: '22px' }}>
                            &nbsp;par&nbsp;
                          </span>
                          <span
                            className="mono  text-light ml"
                           
                          >
                            extremity
                          </span>
                        </span>
                      </div>
                    </div> */}
                    <div className="px-5" style={{ color: '#012060' }}>
                      <div
                        className="pt-2"
                        style={{
                          fontSize: '14px',
                          textAlign: 'right',
                          fontWeight: '900',
                        }}
                      >
                        103, 2nd Floor
                        <br />
                        2023/A, Jeevanbheema Nagar
                        <br />
                        14th Main, HAL 2nd Stage
                        <br />
                        Indiranagar, Bangalore - 560038
                      </div>
                      <div
                        className="py-2 "
                        style={{
                          fontSize: '14px',
                          textAlign: 'right',
                          fontWeight: '900',
                        }}
                      >
                        {/* GSTIN : 29AERPV7005H2ZV
                        <br /> */}
                        Email : vipar.official.hr@gmail.com
                        <br />
                        {/* Web : www.vipar-connect.in */}
                        Web : www.vipar.in
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="py-2 px-2">
              <div
                className="py-1 px-3"
                dangerouslySetInnerHTML={{
                  __html: first?.text,
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn pro-spaces-button3"
            onClick={() => {
              exportAsImage(document.getElementById('appoint'), 'Appoint.png');
            }}
          >
            Download
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const exportAsImage = async (el, imageFileName) => {
  const canvas = await html2canvas(el);
  const image = canvas.toDataURL('image/png', 1.0);
  console.log(image);
  downloadImage(image, imageFileName);
};

const downloadImage = (blob, fileName) => {
  const fakeLink = window.document.createElement('a');
  // console.log(fileName)
  fakeLink.download = fileName;

  fakeLink.href = blob;

  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);

  fakeLink.remove();
};

export default exportAsImage;
