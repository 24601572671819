import React, { useEffect, useState, useRef } from 'react';
import Axios from 'axios';
import Moment from 'moment';
import './Offline_profile.css';
import Handlebars from 'handlebars';
import exportAsImage from '../../../Utility/exportAsImage';
import { useNavigate, useSearchParams } from 'react-router-dom';
function Offline_Account_Print_bill() {
  let [searchParams, setSearchParams] = useSearchParams();
  var id = searchParams.get('pi');
  const exportRef = useRef();
  const exportRef2 = useRef();
  const exportRef3 = useRef();

  const [data, setData] = useState({
    DiscountAmount: '',
    GST: '',
    AmountToPay: '',
    PayAmount: '',
    orderId: '',
    GSTIN: '',
    pname: '',
    orderId: '',
    date: '',
  });
  const [psdata, setPSdata] = useState({
    pname: '',
    state: '',
    city: '',
    classification: '',
    mainCat: '',
    SubCat: '',
    MemberShipType: '',
    FeePerYear: '',
    joinDate: '',
    userID: '',
  });
  useEffect(() => {
    Axios.post('/api/SuperAdmin/Offline_Account_Print_bill', { id })
      .then((res) => {
        if (res.status === 200) {
          setData({
            ...data,
            id: res.data[0]?._id.slice(-5),
            DiscountAmount:
              res.data[0]?.Payments1[0]?.Notes.DiscountAmount.toFixed(2),
            GST: res.data[0]?.Payments1[0]?.Notes.GST,
            AmountToPay:
              res.data[0]?.Payments1[0]?.Notes.AmountToPay.toFixed(2),
            PayAmount: res.data[0]?.Payments1[0]?.Notes.PayAmount.toFixed(2),
            orderId: res.data[0]?.Payments1[0]?.order_id,
            // GSTIN:"",
            pname: res.data[0]?.pname
              ? res.data[0]?.pname
              : res.data[0]?.firstname
              ? res.data[0]?.firstname
              : '-',
            date: Moment(data[0]?.Payments1[0]?.Notes.indianFormateDate).format(
              'DD-MM-YYYY'
            ),
          });
          setPSdata({
            ...psdata,
            pname: res.data[0]?.pname
              ? res.data[0]?.pname
              : res.data[0]?.firstname
              ? res.data[0]?.firstname
              : '-',
            state: res.data[0]?.state ? res.data[0]?.state : '',
            city: res.data[0]?.city ? res.data[0]?.city : '',
            classification:
              res.data[0]?.Type == 'Product' ||
              res.data[0]?.Type == 'Productv2' ||
              res.data[0]?.Type == 'Productv3'
                ? 'Product Store'
                : 'Service Provider',
            mainCat: res.data[0]?.category1[0]?.category
              ? res.data[0]?.category1[0]?.category
              : '-',
            SubCat: res.data[0]?.subcategory1[0]?.sub
              ? res.data[0]?.subcategory1[0]?.sub
              : '-',
            MemberShipType:
              res.data[0]?.Type == 'Product' ||
              res.data[0]?.Type == 'Consultant'
                ? 'PRIME'
                : res.data[0]?.Type == 'Productv2' ||
                  res.data[0]?.Type == 'Consultantv2'
                ? 'ELITE'
                : 'BASIC',
            FeePerYear: res.data[0]?.Payments1[0]?.Notes.AmountToPay.toFixed(2),
            joinDate: Moment(res.data[0]?.Created_on).format('DD-MM-YYYY'),
            userID:
              res.data[0]?.login[0]?.EmailLogin === true
                ? res.data[0]?.login[0]?.Email
                : res.data[0]?.login[0]?.Contact,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const hbr = `
    <style type="text/css">
    body,
    table,
    td,
    a {
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
    }

    table,
    td {
        mso-table-lspace: 0pt;
        mso-table-rspace: 0pt;
    }

    img {
        -ms-interpolation-mode: bicubic;
    }

    img {
        border: 0;
        height: auto;
        line-height: 100%;
        outline: none;
        text-decoration: none;
    }

    table {
        border-collapse: collapse !important;
    }

    body {
        height: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        width: 100% !important;
    }


    a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: none !important;
        font-size: inherit !important;
        font-family: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
    }

    @media screen and (max-width: 480px) {
        .mobile-hide {
            display: none !important;
        }

        .mobile-center {
            text-align: center !important;
        }
    }

    div[style*="margin: 16px 0;"] {
        margin: 0 !important;
    }
</style>
    <table border="0" cellpadding="0" cellspacing="0" width="100%">
    <tr>
        <td align="center" style="background-color: #eeeeee;" bgcolor="#eeeeee">

            <table  id="xefwewe"  align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:600px;">
                <tr>
                    <td align="center" style="  background-color: #55374a;" bgcolor="#1b9ba3">
                        <table align="" border="0" cellpadding="0" cellspacing="0" width="100%"
                            style="max-width:600px;">
                            <tr>
                                <td align="left"
                                    style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 20px 0px 15px 20px;">
                                    <span style="color: #ffffff; font-size: 30px; ">PROchure</span>

                                </td>
                            </tr>

                        </table>
                    </td>
                </tr>
                <tr>
                    <td align="center" style="padding: 5px 35px 0px 35px; background-color: #ffffff;"
                        bgcolor="#ffffff">
                        <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
                            style="max-width:600px;">

                            <tr>
                                <td align="left"
                                    style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;  ">
                                    <p style="font-size: 16px; font-weight: 400; line- color: #777777;">
                                        PROchure ™ is an application developed by VIPAR-connect, a unit of VIPAR
                                        <sup>®</sup>
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td align="center" valign="top"
                                    style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px;">
                                    <p style="font-weight: 900;">SUBSCRIPTION INVOICE</p>
                                    <p></p>
                                </td>
                            </tr>
                            <tr>
                                <td align="left"
                                    style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding-top: 10px;">

                                    <p style="font-size: 16px; font-weight: 400; line-height: 24px; "><span
                                            style="font-weight: 900;">Invoice Id</span><br />VcPro/22-23/{{orderId}}
                                    </p>
                                    <p style="font-size: 16px; font-weight: 400; line-height: 24px; "><span
                                            style="font-weight: 900;">Invoice Date</span><br />{{date}}</p>
                                    <p style="font-size: 16px; font-weight: 400; line-height: 24px; "><span
                                            style="font-weight: 900;">VIPAR </span><br /># 101, Aadheesh
                                        Avenue<br />Kalakunj road, Kodialbail<br />Mangalore – 575003<br />GSTIN :
                                        29AERPV7005H2ZV</p>
                                    <hr />



                                    <p style="font-size: 16px; font-weight: 400; line-height: 24px; ">
                                        Recipient<br />{{pname}}<br />
                                        {{#if GSTIN}}
                                        GSTIN : {{GSTIN}}
                                        {{/if}}
                                    </p>
                                </td>
                            </tr>



                            <tr>
                                <td align="left" style="padding-top: 20px;">
                                    <table cellspacing="0" cellpadding="0" border="0" width="100%">
                                        <tr>
                                            <td width="75%" align="left" bgcolor="#eeeeee"
                                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 800; line-height: 24px; padding: 10px;">
                                                Order Id
                                            </td>
                                            <td width="25%" align="left" bgcolor="#eeeeee"
                                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 800; line-height: 24px; padding: 10px;">
                                                {{orderId}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="75%" align="left"
                                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 15px 10px 5px 10px;">
                                                Subscription Fee
                                            </td>
                                            <td width="25%" align="left"
                                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 15px 10px 5px 10px;">
                                                ₹&nbsp;{{PayAmount}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="75%" align="left"
                                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 5px 10px;">
                                                Discount
                                            </td>
                                            <td width="25%" align="left"
                                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 5px 10px;">
                                                ₹&nbsp;{{DiscountAmount}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="75%" align="left"
                                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 5px 10px;">
                                                GST
                                            </td>
                                            <td width="25%" align="left"
                                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 5px 10px;">
                                                ₹&nbsp;{{GST}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="75%" align="left"
                                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 800; line-height: 24px; padding: 10px; border-top: 3px solid #eeeeee; border-bottom: 3px solid #eeeeee;">
                                                TOTAL
                                            </td>
                                            <td width="25%" align="left"
                                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 800; line-height: 24px; padding: 10px; border-top: 3px solid #eeeeee; border-bottom: 3px solid #eeeeee;">
                                                ₹&nbsp;{{AmountToPay}}
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>

                        </table>

                    </td>
                </tr>
                <tr>
                    <td align="center" height="100%" valign="top" width="100%"
                        style="padding: 0 35px 35px 35px; background-color: #ffffff;" bgcolor="#ffffff">
                        <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
                            style="max-width:660px;">
                            <tr>
                                <td align="center" valign="top" style="font-size:0;">


                                    <div style="display:inline-block;   vertical-align:top; width:100%;">
                                        <table align="center" border="0" cellpadding="0" cellspacing="0"
                                            width="100%">
                                            <tr>
                                                <td align="left" valign="top"
                                                    style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px;">
                                                    <p style="font-weight: 400;">For invoice related and other
                                                        queries : info@prochure.in<br />For complaints and
                                                        grievances : grievances@prochure.in</p>
                                                    <p></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="center" valign="top"
                                                    style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px;">
                                                    <p style="font-weight: 400;">**This is a computer generated
                                                        invoice. No signature required.**</p>
                                                    <p></p>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>


            </table>
        </td>
    </tr>
</table>`;

  const hbr1 = `
<style type="text/css">
body,
table,
td,
a {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

table,
td {
    mso-table-lspace: 0pt;
    mso-table-rspace: 0pt;
}

img {
    -ms-interpolation-mode: bicubic;
}

img {
    border: 0;
    height: auto;
    line-height: 100%;
    outline: none;
    text-decoration: none;
}

table {
    border-collapse: collapse !important;
}

body {
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
}


a[x-apple-data-detectors] {
    color: inherit !important;
    text-decoration: none !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
}

@media screen and (max-width: 480px) {
    .mobile-hide {
        display: none !important;
    }

    .mobile-center {
        text-align: center !important;
    }
}

div[style*="margin: 16px 0;"] {
    margin: 0 !important;
}
</style>

<body style="margin: 0 !important; padding: 0 !important; background-color: #eeeeee;" bgcolor="#eeeeee">




<table border="0" cellpadding="0" cellspacing="0" width="100%">
<tr>
    <td align="center" style="background-color: #eeeeee;" bgcolor="#eeeeee">

        <table id="12345wer" align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:600px;">
            <tr>
                <td align="center" style="  background-color: #55374a;" bgcolor="#1b9ba3">
                    <table align="" border="0" cellpadding="0" cellspacing="0" width="100%"
                        style="max-width:600px;">
                        <tr>
                            <td align="left"
                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding: 20px 0px 15px 20px;">
                                <span style="color: #ffffff; font-size: 30px; ">PROchure&nbsp;&nbsp;<span style="font-size: 16px;">@&nbsp;&nbsp;www.prochure.in</span>

                            </td>
                            
                        </tr>

                    </table>
                </td>
            </tr>
            <tr>
                <td align="center" style="padding: 5px 35px 0px 35px; background-color: #ffffff;"
                    bgcolor="#ffffff">
                    <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%"
                        style="max-width:600px;">

                        <tr>
                            <td align="left"
                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400;  ">
                                <p style="font-size: 16px; font-weight: 400; line- color: #777777;">
                                   
                                  <center> an online brochure of Professional Services and Products</center>
                                </p>
                            </td>
                        </tr>
                         <tr>
                            <td align="center" valign="top"
                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px;">
                                <p style="font-weight: 900;">SUBSCRIPTION DETAILS</p>
                                <p></p>
                            </td>
                        </tr>
                        <tr>
                            <td align="left"
                                style="font-family: Open Sans, Helvetica, Arial, sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; padding-top: 10px;">
                                <p style="font-size: 16px; font-weight: 400; line-height: 24px; "><span style="font-weight: 900;">Profile Name :&nbsp;{{pname}}</span>
                                </p>
                                <p style="font-size: 16px; font-weight: 400; line-height: 24px; "><span style="font-weight: 900;">Country : INDIA</span>
                                </p>
                                <p style="font-size: 16px; font-weight: 400; line-height: 24px; "><span style="font-weight: 900;">State :&nbsp;{{state}}</span>
                                </p>
                                <p style="font-size: 16px; font-weight: 400; line-height: 24px; "><span style="font-weight: 900;">City :&nbsp;{{city}}</span>
                                </p>
                                 <p style="font-size: 16px; font-weight: 400; line-height: 24px; "><span style="font-weight: 900;">Profile Classification :&nbsp;{{classification}}</span>
                                </p>
                                 <p style="font-size: 16px; font-weight: 400; line-height: 24px; "><span style="font-weight: 900;">Profile Category :&nbsp;{{mainCat}}</span>
                                </p>
                                 <p style="font-size: 16px; font-weight: 400; line-height: 24px; "><span style="font-weight: 900;">Profile Sub Category :&nbsp;{{SubCat}}</span>
                                </p>
                                 <p style="font-size: 16px; font-weight: 400; line-height: 24px; "><span style="font-weight: 900;">Membership Type :&nbsp;{{MemberShipType}}</span>
                                </p>
                                 <p style="font-size: 16px; font-weight: 400; line-height: 24px; "><span style="font-weight: 900;">Fee per year :&nbsp;{{FeePerYear}}</span>
                                </p>
                                 <p style="font-size: 16px; font-weight: 400; line-height: 24px; "><span style="font-weight: 900;">Joining Date :&nbsp;{{joinDate}}</span>
                                </p>
                                 <p style="font-size: 16px; font-weight: 400; line-height: 24px; "><span style="font-weight: 900;">User Id / Login Id :&nbsp;{{userID}}</span>
                                </p>
                                
                               
                                                                 
                            </td>
                        </tr>



                        

                    </table>

                </td>
            </tr>
           


        </table>
    </td>
</tr>
</table>

</body>

`;
  const template = Handlebars.compile(hbr);
  const template1 = Handlebars.compile(hbr1);

  const [Print, setPrint] = useState();

  return (
    <div>
      <div>
        <div
          className="mt-2"
          ref={exportRef}
          dangerouslySetInnerHTML={{ __html: template(data) }}
        />
        <div className="mt-2">
          <center>
            <button
              onClick={() =>
                exportAsImage(
                  document.getElementById('xefwewe'),
                  `Prochure_GST_Invoice_${data.id}_${data.pname}`,
                  setPrint,
                  exportRef3
                )
              }
              className="btn pro-spaces-button3"
            >
              Download GST
            </button>
          </center>
        </div>
        <div
          className="mt-2"
          ref={exportRef2}
          dangerouslySetInnerHTML={{ __html: template1(psdata) }}
        />
      </div>
      <div className="mt-2">
        <center>
          <button
            onClick={() =>
              exportAsImage(
                document.getElementById('12345wer'),
                `Prochure_User_Details_${data.id}_${data.pname}`
              )
            }
            className="btn pro-spaces-button3"
          >
            Download User Details
          </button>
        </center>
      </div>
    </div>
  );
}
export default Offline_Account_Print_bill;
