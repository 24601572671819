import React, { useEffect, useReducer, useState } from 'react';
import { StateCity } from '../../ManageAdmin/StateCity';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { AiFillCamera } from 'react-icons/ai';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { closeBackdrop, openBackdrop } from '../../backdrop/backdrop';
import MapWithSwitch from '../../Maps/MapWithSwitch';
import { keyGen } from '../../../Utility/helper';
import Select from 'react-select';
import { notify } from '../../../Utility/notify';
import testImage from './test1.jpg';
export const Form = ({ DCE }) => {
  let navigate = useNavigate();
  let location = useLocation();
  let params = location.state;
  const [errorr, setErrorr] = useState('');
  const [value, setValue] = useState({
    Type: '',
    pname: '',
    country: '',
    state: '',
    city: '',
    pincode: '',
    category: '',
    subcategory: '',
    address1: '',
    contact: '',
    profileTypeForDataEntry: '',
    secondaryLocality: '',
    Brand: null,
  });
console.log(value)
  let isService = value.profileTypeForDataEntry === 'consultant';
  const [Submit2, setSubmit2] = useState(false);
  const [Password, setPassword] = useState('');
  const [Contact, setContact] = useState('');
  const [Email, setEmail] = useState('');
  const [LogOp, setLogOp] = useState(true);
  const [Edit, setEdit] = useState(null);

  //Things for image upload with croper
  const [image, setImage] = useState();
  const [cropData, setCropData] = useState(false);
  const [cropper, setCropper] = useState();
  const onChange = async (e) => {
    var myModal = new bootstrap.Modal(
      document.getElementById('profileUpdation')
    );
    myModal.show();
    var myModalEl = document.getElementById('profileUpdation');
    myModalEl.addEventListener('shown.bs.modal', function (event) {
      cropData ? setCropData(false) : <></>;
      e.preventDefault();
      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      if (files[0]) {
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
        };
        reader.readAsDataURL(files[0]);
      }
      e.target.value = null;
    });
    var x = document.getElementById('profileUpdation').offsetWidth;
  };
  const [imageinserted, setimageinserted] = useState(false);
  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setimageinserted(true);
      setCropData();
      cropper.getCroppedCanvas().toBlob(
        (blob) => {
          setCropData(URL.createObjectURL(blob));
          setnewb(blob);
        },
        'image/jpeg',
        0.5
      );
    }
  };
  const uploadme = () => {
    var DP = document.getElementById('DP');
    DP.src = cropData;
  };
  const photo = () => document.getElementById('image').click();
  useEffect(() => {
    setValue({ ...value, image: cropData });
  }, [cropData]);
  //----------end---------------
  const getImageFile = (path) => {
    // Fetch the image file based on the path
    // In this example, I assume that the image file is in the public folder
    const imageFile = new File([new Blob()], 'test1.jpg', {
      type: 'image/jpeg',
    });
    return imageFile;
  };
  const base64ToBlob = (base64String, type) => {
    const cleanedBase64String = base64String.replace(
      /^data:image\/(png|jpeg|jpg);base64,/,
      ''
    );

    try {
      const byteCharacters = atob(cleanedBase64String);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type });
    } catch (error) {
      console.error('Error decoding base64 string:', error);
      return null; // Handle the error as needed
    }
  };

  const [newb, setnewb] = useState();
  const submit = async (e) => {
    e.preventDefault();

    if (params) {
      return submitput(e);
    }
    const formData = new FormData();
    let EmailLogin = true;
    if (LogOp) {
      EmailLogin = true;
    } else {
      EmailLogin = false;
    }
    if (Email?.length) {
      let overAgain = await axios.post('/api/auth/emailmatch', { Email });
      if (overAgain.data === 'Exist') {
        return setErrorr(
          <p>An account is already created at PROchure using this Email Id.</p>
        );
      } else {
        setErrorr('');
      }
    }
    if (Contact?.length) {
      var over = await axios.post('/api/auth/numbermatch', { Contact });
      if (over.data === 'Exist') {
        return setErrorr(
          <p>
            An account is already created at PROchure using this Mobile Number.
          </p>
        );
      } else {
        setErrorr('');
      }
    }
    let AccountType = 'Active';
    if (value.Type === 'Consultantv3' || value.Type === 'Productv3') {
      AccountType = 'Activev3';
    } else if (value.Type === 'Consultantv2' || value.Type === 'Productv2') {
      AccountType = 'Activev2';
    } else {
      AccountType = 'Active';
    }
    let registerValue = {
      Email: Email,
      Contact: Contact,
      Password: Password,
      Type: AccountType,
      EmailLogin: EmailLogin,
      DataEntry: true,
    };
    var dataSend = JSON.stringify({ value, registerValue });
    formData.delete('file');
    formData.delete('data');
    let metadata = {
      type: 'image/jpeg',
    };
    if (newb) {
      let file = new File([newb], 'test1.jpg', metadata);
      formData.append('file', file);
    } else {
      let imgd = await base64ToBlob(testImage, 'image/jpeg');
      let file = new File([imgd], 'test1.jpg', metadata);

      formData.append('file', file);
    }
    formData.append('data', dataSend);
    axios
      .post('/api/User/dataentry', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        notify('Profile Created Successfully', true);

        window.location.reload();
      })
      .catch((err) => {
        notify('Something went wrong', false);

        console.log(err);
      });
  };
  const submitput = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    let EmailLogin = true;
    if (LogOp) {
      EmailLogin = true;
    } else {
      EmailLogin = false;
    }
    let AccountType = 'Active';
    if (value.Type === 'Consultantv3' || value.Type === 'Productv3') {
      AccountType = 'Activev3';
    } else if (value.Type === 'Consultantv2' || value.Type === 'Productv2') {
      AccountType = 'Activev2';
    } else {
      AccountType = 'Active';
    }
    let registerValue = {
      Email: Email,
      Contact: Contact,
      Password: Password,
      Type: AccountType,
      EmailLogin: EmailLogin,
    };

    var dataSend = JSON.stringify({ value, registerValue });
    formData.delete('file');
    formData.delete('data');
    let metadata = {
      type: 'image/jpeg',
    };
    if (newb) {
      let file = new File([newb], 'test1.jpg', metadata);
      formData.append('file', file);
    }
    formData.append('data', dataSend);
    axios
      .put('/api/User/dataentry', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        notify('Profile Updated Successfully', true);
        navigate(-1);
      })
      .catch((err) => {
        notify('Something went wrong', false);

        console.log(err);
      });
  };
  const PhoneReg = (
    <>
      <div className="mt-2 mb-3">
        <input
          type="number"
          required
          value={Contact}
          onChange={(e) => {
            if (e.target.value.length == 11) return false;
            setContact(e.target.value);
            setPassword(e.target.value);
          }}
          className="form-control "
          placeholder="Mobile Number * ( your Login Id )"
          autoComplete="off"
        />
        <div className="invalid-feedback" style={{ textAlign: 'left' }}>
          PLEASE ENTER Mobile Number
        </div>
      </div>
      <div className="mt-2 mb-4">
        <input
          disabled
          type="text"
          value={Password}
          onChange={(e) => setPassword(e.target.value)}
          className="form-control"
          placeholder="Create a Password * (min 8 characters)"
          autoComplete="off"
        />
      </div>
      <div className="mt-2 mb-3">
        <input
          type="email"
          value={Email}
          onChange={(e) => setEmail(e.target.value)}
          className="form-control"
          name="Email"
          placeholder="Email Id"
          autoComplete="off"
        />
      </div>
    </>
  );

  const EmailReg = (
    <>
      <div className="mt-2 mb-3">
        <input
          required
          type="email"
          value={Email}
          onChange={(e) => {
            setEmail(e.target.value);
            setPassword(e.target.value);
          }}
          name="Email"
          className="form-control"
          placeholder="Email Id * ( your Login Id )"
          autoComplete="off"
        />
        <div className="invalid-feedback" style={{ textAlign: 'left' }}>
          PLEASE ENTER Email Id
        </div>
      </div>
      <div className="mt-2 mb-4">
        <input
          disabled
          type="text"
          value={Password}
          onChange={(e) => setPassword(e.target.value)}
          className="form-control"
          placeholder="Create a Password * (min 8 characters)"
          autoComplete="off"
        />
      </div>
      <div className="mt-2 mb-3">
        <input
          type="number"
          value={Contact}
          onChange={(e) => {
            if (e.target.value.length == 11) return false;
            setContact(e.target.value);
          }}
          className="form-control "
          placeholder="Mobile Number "
          autoComplete="off"
        />
      </div>
    </>
  );

  const [cat, setCat] = useState([]);
  const [category, setCategory] = useState(false);
  const [subcategory, setSubcategory] = useState(false);
  const [brand, setBrand] = useState([]);
  const [subcat, setsubcat] = useState([]);

  useEffect(() => {
    setBrand([]);
    var anadhan;
    cat.forEach((element) => {
      if (element._id === value.category) {
        setsubcat(element.sub);
        anadhan = element.sub.map((elements) => {
          return (
            <option formtype={elements.formType} value={elements._id}>
              {elements.sub}
            </option>
          );
        });
      }
    });
    setSubcategory(anadhan);

    return () => { };
  }, [value.category, cat]);

  const [categoryAll, setcategoryAll] = useState({ product: [], service: [] });
  const fetchData = () => {
    return axios.get(`/api/User/getDce?acc=${params.accountID}`);
  };

  const fetch = async () => {
    openBackdrop();
    let product = '/api/api/getprocategory';
    let service = '/api/api/getcategory';
    let pres = axios.get(product);
    let sres = axios.get(service);
    let dres = params ? fetchData() : '';
    Promise.all([pres, sres, dres]).then((res) => {
      closeBackdrop();
      let pres = res[0].data;
      let sres = res[1].data;
      setcategoryAll((prev) => ({ ...prev, product: pres, service: sres }));

      if (params) {
        let data = res[2].data[0];
        let userData = data.UserData[0];
        setLogOp(data?.EmailLogin)
        setEmail(data?.Email)
        setContact(data?.Contact)
        setPassword(data?.EmailLogin ? data?.Email : data?.Contact)
        setValue({
          id: userData._id,
          Type: userData.Type,
          pname: userData.pname,
          country: userData.country,
          state: userData.state,
          city: userData.city,
          pincode: userData.pincode,
          category: userData.category,
          subcategory: userData.subcategory,
          address1: userData.address1,
          contact: userData.contact,
          secondaryLocality: userData.secondaryLocality,
          profileTypeForDataEntry: userData.Type.startsWith('Product')
            ? 'product'
            : 'consultant',
          Brand: null,
        });
        setEdit([
          {
            country: userData.country,
            state: userData.state,
            city: userData.city,
          },
        ]);
        isService = userData.Type.startsWith('Product') ? false : true;
      }
      if (isService) {
        setCat(sres);
      } else {
        setCat(pres);
      }
    });
  };
  useEffect(() => {
    if (categoryAll.product.length === 0 || categoryAll.product.length === 0) {
      fetch();
    } else {
      if (isService) setCat(categoryAll.service);
      else {
        setCat(categoryAll.product);
      }
    }
  }, [isService]);
  var sab = [];
  useEffect(() => {
    if (cat.length >= 1) {
      cat.forEach((element) => {
        sab.push(<option value={element._id}>{element.category}</option>);
      });
      setCategory(sab);
    } else {
    }
  }, [cat]);
  const formLoader = () => {
    let e = document.getElementById('subcatananda83389');
    let ft = e.options[e.selectedIndex].getAttribute('formtype');
    setValue({ ...value, subcategory: e.value, formType: ft });
    cat.find((elem) => {
      if (elem._id === value.category) {
        elem.sub.find((element) => {
          if (element._id == e.value) {
            setBrand(element.brand);
          }
        });
      }
    });
  };

  const [Options, setOptions] = useState([]);
  useEffect(() => {
    openBackdrop();
    let product = '/api/api/getprocategory';
    let service = '/api/api/getcategory';
    axios
      .get(isService ? service : product)
      .then((res) => {
        let op = res.data?.map((element, index) => {
          return element.sub.map((elm, ind) => {
            let value = elm.sub.toString();
            if (elm.keyword?.length) {
              var sabari = elm.keyword.map((keywordsmap) => keywordsmap.label);
              value = value + '' + sabari.toString();
            }
            let brand;
            if (elm.brand?.length) {
              brand = true;
            } else {
              brand = false;
            }
            return {
              value,
              label: `${elm.sub} [ ${element.category} ]`,
              id: elm._id,
              categoryName: element.category,
              brand,
              type: element.type,
              catid: element._id,
              ft: elm.formType,
            };
          });
        });
        op = op.flat(1);
        setOptions(op);
        closeBackdrop();
      })
      .catch((err) => {
        closeBackdrop();
        console.log(err);
      });
  }, [isService]);

  const redmik20pro = (id) => {
    let catid = id.catid;
    let subcatid = id.id;
    let anadhan;
    let ft = id.ft;
    cat.forEach((element) => {
      if (element._id === catid) {
        setsubcat(element.sub);
        anadhan = element.sub.map((elements) => {
          if (elements._id === subcatid) {
            ft = elements.formType;
          } else {
            // console.log(elements._id,res.data[0].subcategory)
          }
          return (
            <option formtype={elements.formType} value={elements._id}>
              {elements.sub}
            </option>
          );
        });
      }
    });
    setSubcategory(anadhan);
    setValue((rev) => ({
      ...rev,
      category: catid,
      subcategory: subcatid,
      formType: ft,
    }));
    console.log(ft);
  };

  const [selectedOption, setSelectedOption] = useState(null);
  const handleChange = (selectedOption) => {
    redmik20pro(selectedOption);
  };

  const handle = (e) => {
    if (e.target.value.length > 2) {
      let elem = document.querySelector(':root');
      elem.style.setProperty('--display', 'block  ');
    } else {
      let elem = document.querySelector(':root');
      elem.style.setProperty('--display', 'none  ');
    }
  };

  useEffect(() => {
    try {
      rt();

      let element = document.getElementsByClassName('inputselectcat');
      element[0].children[2].children[0].children[1].children[0].addEventListener(
        'input',
        handle
      );
      element[0].children[2].children[1].classList.add('d-none');
    } catch (error) {
      console.log(error);
    }
    return () => {
      try {
        let element = document.getElementsByClassName('inputselectcat');
        element[0].children[2].children[0].children[1].children[0].removeEventListener(
          'input',
          handle
        );
      } catch (err) { }
    };
  }, []);

  const rt = () => {
    let elem = document.querySelector(':root');
    elem.style.setProperty('--display', 'none ');
  };

  const superscriptStyle = {
    verticalAlign: 'super',
    fontSize: 'smaller',
  };

  const bucketName =
    'https://pro-spaces-profile.s3.ap-south-1.amazonaws.com/profile';
  //   export const bucketName = 'https://s3.ap-south-1.amazonaws.com/prochure.in.profile/profile'
  // var bucketName = 'https://s3.ap-south-1.amazonaws.com/prochure.in.profile/profile';
  // console.log(params, DCE)
  let blank = '/test1.jpg';
  return (
    <>
      <h3 className="text-center">Profile Form</h3>
      <div className="container rounded bg-white pt-5 pb-5">
        <form
          novalidate
          className={Submit2 ? 'was-validated' : ''}
          onSubmit={(e) => submit(e)}
        >
          <section className="mb-4">
            <center>
              <p className="mb-xl-2">Create a Login Id via :</p>
              <div className="w-xl-50">
                <div
                  style={{
                    width: '350px',
                    border: '1px solid #55374a',
                    padding: '10px',
                  }}
                  className="row p-0 m-0 mb-3"
                >
                  {/* {!!!Edit && ( */}
                  <>
                    <div className="col-6 p-0">
                      <input
                        type="button"
                        style={{ borderRadius: '0px' }}
                        className={`btn pro-spaces-outline w-100 ${LogOp ? 'RActive' : ''
                          }`}
                        onClick={(e) => setLogOp(true)}
                        // disabled={Edit}
                        value="Email Id"
                        name="btnradio"
                        id="Emd"
                        autocomplete="off"
                        checked={LogOp ? true : false}
                      />
                    </div>
                    <div className="col-6 p-0">
                      <input
                        type="button"
                        style={{ borderRadius: '0px' }}
                        className={`btn pro-spaces-outline w-100 px-0 ${!LogOp ? 'RActive' : ''
                          }`}
                        onClick={(e) => setLogOp(false)}
                        // disabled={Edit}
                        value="Mobile Number"
                        name="btnradio"
                        id="Mbn"
                        autocomplete="off"
                        checked={LogOp ? true : false}
                      />
                    </div>
                    {LogOp ? EmailReg : PhoneReg}
                  </>
                  {/* )} */}
                  <div className="mt-2 mb-3">
                    <select
                      required
                      value={value.profileTypeForDataEntry}
                      className="form-select"
                      onChange={(e) =>
                        setValue({
                          ...value,
                          profileTypeForDataEntry: e.target.value,
                          Type:e.target.value ==='consultant' ? 'Consultant' : 'Product',
                          subcategory: '',
                          category: '',
                          Brand: '',
                        })
                      }
                    >
                      <option value="" disabled>
                        Select a Classification Type *
                      </option>
                      <option value={'consultant'}>SERVICES</option>
                      <option value={'product'}>PRODUCTS</option>
                    </select>
                    <div
                      className="invalid-feedback"
                      style={{ textAlign: 'left' }}
                    >
                      PLEASE SELECT Membership Type
                    </div>
                  </div>
                  {value.profileTypeForDataEntry && (
                    <div className="mt-2 mb-3">
                      <select
                        required
                        disabled
                        value={value.Type}
                        className="form-select"
                        onChange={(e) =>
                          setValue({ ...value, Type: e.target.value })
                        }
                      >
                        <option value="" disabled>
                          Select a Membership Type *
                        </option>
                        <option
                          value={isService ? 'Consultantv3' : 'Productv3'}
                        >
                          BASIC
                        </option>
                        <option value={isService ? 'Consultant' : 'Product'}>
                          PRIME
                        </option>
                        <option
                          value={isService ? 'Consultantv2' : 'Productv2'}
                        >
                          ELITE
                        </option>
                      </select>
                      <div
                        className="invalid-feedback"
                        style={{ textAlign: 'left' }}
                      >
                        PLEASE SELECT Membership Type
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </center>
          </section>
          <section>
            {value.profileTypeForDataEntry && (
              <>
                {!Edit && (
                  <>
                    <div className="col-12">
                      <div className="mb-2 ">
                        <Select
                          value={selectedOption}
                          className="inputselectcat"
                          placeholder="Type and Search for your Category"
                          onChange={(e) => handleChange(e)}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: state.isFocused
                                ? '#ced4da'
                                : '#ced4da',
                            }),
                          }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: '#ced4da',

                              primary: '#ced4da',
                            },
                          })}
                          options={Options}
                        />
                      </div>
                    </div>
                    <center>
                      <h6>OR</h6>
                    </center>
                  </>
                )}
                <h6 className="mt-1">
                  Select an appropriate Category and Sub Category
                </h6>
                <div className="col-md-12">
                  <label className="labels">Category *</label>
                  <select
                    required
                    className="form-select"
                    value={value.category}
                    onChange={(e) =>
                      setValue((prev) => ({
                        ...prev,
                        category: e.target.value,
                      }))
                    }
                  >
                    <option value="" disabled selected></option>
                    {category ? category : ''}
                  </select>
                  <div
                    className="invalid-feedback"
                    style={{ textAlign: 'left' }}
                  >
                    PLEASE SELECT Category
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="labels">Sub Category *</label>
                  <select
                    required
                    id={'subcatananda83389'}
                    className="form-select"
                    value={value.subcategory}
                    onChange={(e) => formLoader(e)}
                  >
                    <option></option>
                    {subcategory ? subcategory : ''}
                  </select>
                  <div
                    className="invalid-feedback"
                    style={{ textAlign: 'left' }}
                  >
                    PLEASE SELECT Sub Category
                  </div>
                </div>
                {brand.length ? (
                  <>
                    <div className="col-md-12">
                      <label className="labels">Brand *</label>
                      <select
                        required
                        value={value.Brand}
                        className="form-select"
                        onChange={(e) =>
                          setValue({ ...value, Brand: e.target.value })
                        }
                      >
                        <option></option>
                        {brand.map((elem) => {
                          return <option value={elem._id}>{elem.brand}</option>;
                        })}
                      </select>
                      <div
                        className="invalid-feedback"
                        style={{ textAlign: 'left' }}
                      >
                        PLEASE SELECT Brand
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </section>
          <section>
            <div
              className="modal fade"
              id="profileUpdation"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h6 className="modal-title" id="exampleModalLabel">
                      Profile Picture
                    </h6>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div id="bod" className="modal-body ">
                    <div>
                      <div className="d-flex justify-content-center">
                        {cropData ? (
                          <div
                            className="box"
                            style={{ width: '50%', float: 'right' }}
                          >
                            <img
                              style={{ width: '100%' }}
                              src={cropData}
                              alt="cropped"
                            />
                          </div>
                        ) : (
                          <div style={{ width: '100%' }}>
                            {' '}
                            <Cropper
                              style={{ height: 400, width: '100%' }}
                              zoomTo={false}
                              aspectRatio={45 / 45}
                              preview=".img-preview"
                              src={image}
                              viewMode={1}
                              minCropBoxHeight={10}
                              minCropBoxWidth={10}
                              background={false}
                              responsive={true}
                              autoCropArea={0}
                              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                              onInitialized={(instance) => {
                                setCropper(instance);
                              }}
                              guides={true}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    {!cropData ? (
                      <button
                        type="button"
                        className="btn pro-spaces-button3"
                        onClick={getCropData}
                      >
                        Ok
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn pro-spaces-button3"
                        onClick={() => uploadme()}
                        data-bs-dismiss="modal"
                      >
                        Save changes
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 border-right ">
                <div className="d-flex flex-column align-items-center text-center pb-3 ps-0 pe-0 pt-5 ">
                  <img
                    id="DP"
                    className=" mt-5"
                    width="150px"
                    src={
                      !DCE
                        ? !params?.accountID
                          ? blank
                          : `${bucketName}/${value.id}/avatar.png`
                        : `${bucketName}/${value.id}/avatar.png`
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = '/blank.png';
                    }}
                  />
                  <div id="idtestingprofilepicvalidation" className="upload">
                    <input
                      required={DCE && imageinserted === false && !Edit}
                      type="file"
                      accept="image/*"
                      onChange={onChange}
                      id="image"
                      className="form-control"
                    />
                    <label htmlFor="image">
                      <AiFillCamera size={'40px'} />
                    </label>
                    <div
                      className="invalid-feedback"
                      style={{ backgroundColor: 'white' }}
                    >
                      PLEASE upload PROFILE DISPLAY PICTURE
                    </div>
                  </div>

                  <span className="text-50 mt-3 up" onClick={() => photo()}>
                    PROFILE DISPLAY PICTURE *
                  </span>

                  <span> </span>
                </div>
              </div>
              <div className="col-md-9 border-right">
                <div className="py-2 ps-0 pe-0 pt-2">
                  <div className="col-md-12">
                    <label className="labels">PROFILE DISPLAY NAME *</label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      value={value.pname?.toUpperCase()}
                      onChange={(e) =>
                        setValue({
                          ...value,
                          pname: e.target.value?.toUpperCase(),
                        })
                      }
                    />
                    <div className="invalid-feedback">
                      PLEASE ENTER PROFILE DISPLAY NAME
                    </div>
                  </div>
                  {/* <div className="col-md-12">
                    <label className="labels">
                      Secondary Locality / Area Name 
                    </label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      value={value.secondaryLocality}
                      onChange={(e) =>
                        setValue({
                          ...value,
                          secondaryLocality: e.target.value,
                        })
                      }
                    />
                    <div className="invalid-feedback">
                      PLEASE ENTER Secondary Locality / Area Name
                    </div>
                  </div> */}
                  <div className="col-md-12">
                    <label className="labels">
                      Secondary, Primary Locality *
                    </label>
                    <input
                      required
                      type="text"
                      placeholder="e.g. HAL 2nd Stage, Indiranagar"
                      className="form-control"
                      value={value.address1}
                      onChange={(e) =>
                        setValue({ ...value, address1: e.target.value })
                      }
                    />
                    <div className="invalid-feedback">
                      PLEASE ENTER Secondary, Primary Locality
                    </div>
                  </div>
                  {/* <MapWithSwitch Location={Location} setLocation={setLocation} LocationOn={LocationOn} setLocationOn={setLocationOn} key={keyGen()} /> */}
                  <StateCity setValue={setValue} value={value} Edit={Edit} />
                  <div className="col-md-12">
                    <label className="labels">Pin code / Zip code</label>
                    <input
                      type="number"
                      className="form-control"
                      value={value.pincode}
                      onChange={(e) => {
                        if (e.target.value.length > 6) return;
                        setValue({ ...value, pincode: e.target.value });
                      }}
                    />
                    {/* <div className="invalid-feedback">
                      PLEASE ENTER Pin code / Zip code
                    </div> */}
                  </div>

                  <div className="col-md-12">
                    <label className="labels">
                      Mobile Number / Landline Number / Customer Care Number /
                      Email Id / Toll Free Number *
                    </label>
                    <input
                      type="text"
                      required
                      placeholder="Enter State Code before Landline Number"
                      value={value.contact}
                      className="form-control"
                      // pattern="[0-9]{6,13}"
                      // minLength="6"
                      // maxLength="13"
                      onChange={(e) => {
                        // if (e.target.value.length == 13) return false;
                        setValue({ ...value, contact: e.target.value });
                      }}
                    />
                    <div
                      className="invalid-feedback"
                      style={{ textAlign: 'left' }}
                    >
                      PLEASE ENTER Mobile Number / Landline Number / Customer
                      Care Number / Email Id / Toll Free Number
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2 text-center">
              <p className="text-danger">{errorr ? errorr : ''}</p>

              {Edit ? (
                params?.admin && (
                  <button
                    className="btn mb-2 mt-4"
                    style={{ backgroundColor: '#55374a', color: 'white' }}
                    onClick={() => setSubmit2(true)}
                  >
                    Submit
                  </button>
                )
              ) : (
                <button
                  className="btn mb-2 mt-4"
                  style={{ backgroundColor: '#55374a', color: 'white' }}
                  onClick={() => setSubmit2(true)}
                >
                  Submit
                </button>
              )}
            </div>
          </section>
        </form>
      </div>
    </>
  );
};
