import React from 'react';
import { Link } from 'react-router-dom';

export const HomeAdmin = () => {
  return (
    <div>
      <div style={{ height: '100%' }} className="bg-light container">
        <div className="row slhishieo3jfiehfjefbewf">
          <div className="col-lg-3 col-md-4 col-sm-6">
            <Link to="/account_settings" className="wgfiuwrgiu">
              <div className="card carrrrbus">
                <div className="padunnor">
                  <center>Account Settings</center>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
