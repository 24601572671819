import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import Moment from 'moment';
import './Feedback.css'
import { useEffect, useState } from "react";
import Axios from 'axios'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { SpinnerBig } from "../../../Utility/spinner";
import { Link } from "react-router-dom";




export function Report() {



  const [loader, setloader] = useState(<div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>)
  const [Loading, setLoading] = useState(true)
  const [reportedAbuse, setreportedAbuse] = useState(false)

  // var Counts
  useEffect(() => {
    // var Id='6209f5e094805605d016655d';
    console.log('reportedAbuse view test')


    Axios.get('/api/SuperAdmin/reportedAbuseList')
      .then(res => {
        if (res.status === 200) {
          console.log('reported Abuse')
          console.log(res.data)
          setreportedAbuse(res.data)


          setLoading(false)
      
        }
      }).catch((err) => {
        console.log(err)

      })
  }, [])

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };




  return (
    <div className="bg-light p-3">
      <div className="">
        <h2 className='p-1'>Report</h2>
        {/* <table className="table table-striped table-hover table-bordered">
          <thead>
            <tr>
              <th scope="col">No</th>

              <th scope="col">Name</th>
              <th scope="col">Feedback</th>
              <th scope="col">Date</th>

            </tr>
          </thead>
          <tbody>


            {Feedback && Feedback.length > 0 ?
              Feedback.map((feedback, index) => {
                var date = Moment(feedback.Date).format('DD-MM-YYYY')

                return <tr>
                  <th scope="row">{index + 1}</th>
   
                  <td>{feedback.string[0]?.pname}</td>
                  <td>{feedback.message}</td>
                  <td>{date}</td>
                </tr>
              }) : "No feedback till now!!!"}
          </tbody>
        </table> */}
              
              {
            Loading?
            <SpinnerBig/>
            :''
        }

{
       reportedAbuse?
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Reported Account</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
           reportedAbuse.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,index) => (
              
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                 {index+1}
                </TableCell>
                <TableCell component="th" scope="row" >
                 {row.account_id.pname?row.account_id.Type==="Consultant"||row.account_id.Type==="Consultantv2"?<Link style={{"textDecoration":"none","color":"black"}} to={`/consultant_profile?Ac_id=${row.account_id._id}`}>{row.account_id.pname}</Link>:<Link style={{"textDecoration":"none","color":"black"}} to={`/vendor_profile?Ac_id=${row.account_id._id}`}>{row.account_id.pname}</Link> :"-"}
                </TableCell>
                <TableCell > {row.reported_account.pname?row.reported_account.Type==="Consultant"||row.reported_account.Type==="Consultantv2"?<Link style={{"textDecoration":"none","color":"black"}} to={`/consultant_profile?Ac_id=${row.reported_account._id}`}>{row.reported_account.pname}</Link>:<Link style={{"textDecoration":"none","color":"black"}} to={`/vendor_profile?Ac_id=${row.reported_account._id}`}>{row.reported_account.pname}</Link> :"-"}</TableCell>
                <TableCell >{row.msg?row.msg:"-"}</TableCell>
                <TableCell >{row.Date? Moment(row.Date).format('DD-MM-YYYY') :"-"}</TableCell>
                <TableCell ></TableCell>
              </TableRow>
            ))
            }
          </TableBody>
        </Table>
        <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={reportedAbuse.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </TableContainer>

        :
        <center>
        <h1>No Members to show</h1>
        </center>

    }



      </div>
    </div>
  )
}

