import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PassiveMemberList.css';
import Axios from 'axios';
import Moment from 'moment';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';

function ConsultantsList(props) {
  // console.log(props.data.product._id)
  const [expdate1, setexpdate1] = useState();
  useEffect(() => {
    setexpdate1(Moment(props.data.product.Validity).format('DD-MM-YYYY'));
  });

  var todayDate = Date.now();
  var expval = Moment(expdate1);
  // expval=expval.valueOf()
  const myDate = new Date(props.data.product.Validity); // create a date object
  const timestamp = myDate.getTime(); // get the timestamp in milliseconds
  return (
    <>
      <tr className="tableBody" scope="row">
        <th scope="row">{props.data.index + 1}</th>
        <th>
          {props.data.product.pname ? props.data.product.pname : '-'}&nbsp;
        </th>
        <th>
          {props.data.product.Type == 'Consultant'
            ? 'Prime'
            : props.data.product.Type == 'Consultantv2'
            ? 'Elite'
            : 'Basic'}
          &nbsp;
        </th>

        <td>
          {props.data.product.login[0]
            ? props.data.product.login[0].Contact
            : '-'}
        </td>
        <td>
          {props.data.product.login[0]?.Email
            ? props.data.product.login[0].Email
            : '-'}
        </td>
        <td>{expdate1}</td>
        <td style={{ whiteSpace: 'nowrap' }}>
          {props.data.product.login[0]?.DirectLink
            ? 'Direct link'
            : props.data.product.login[0]?.NOTES?.createdByAdmin
            ? 'Created by Admin'
            : 'Self'}
        </td>
        <td>
          <Link
            to={`/Advertisement?a=${props.data.product._id}&&l=${props.data.product.Login_id}`}
          >
            <button
              className="btn btn-success w-100"
              type="button"
              style={{ textTransform: 'unset' }}
            >
              Add
            </button>
          </Link>
        </td>
        {/*  */}
        <td>
          {timestamp >= todayDate &&
          props.data.product.login[0]?.Advertisement_Payment_Id == undefined ? (
            props.data.product.Type != 'Productv2' ? (
              <Link
                to={`/add_payment_out?a=${props.data.product._id}&&l=${props.data.product.Login_id}&&type=v`}
              >
                <button
                  className="btn btn-success w-100"
                  type="button"
                  style={{ textTransform: 'unset' }}
                >
                  Pay
                </button>
              </Link>
            ) : (
              ''
            )
          ) : (
            ''
          )}
        </td>
      </tr>
    </>
  );
}
export default ConsultantsList;
