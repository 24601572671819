import axios from "axios"
import { useEffect, useRef, useState,useContext,createContext } from "react"
import { Carousel, CarouselItem } from "react-bootstrap"
import '../chat.css'
import { useNavigate, useSearchParams } from 'react-router-dom';
import  ProgressBar  from "react-bootstrap/ProgressBar";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { keyGen,MenuProps,OTTPlatformList } from "../../../../Utility/helper";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';




const formData = new FormData();
export const CreatorContext = createContext()
export const SportsEdit = () => {
    let navigate = useNavigate()


    let [searchParams, setSearchParams] = useSearchParams();  /////getting id from the url the id of the jobs id
    var id = searchParams.get("pi")

    const notify = (message, sab) => {
        if (sab) {
            toast.success(message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            toast.warning(message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };


    const [language, setLanguage] = useState([])
    const [innovationType, setInnovationType] = useState([])
    const [Description, setDescription] = useState('')
    const [HeadLine, setHeadLine] = useState('')
    const [Files, setFiles] = useState([])
    const [PostCat, setPostCat] = useState('')
const [Category, setcategory] = useState('')
const [newFiled, setNewFiled] = useState({
    TvChannel:'',
    TvChannelOnAt:'',
    OTTOnAt:'',
 })
 const [OTTPlatform, setOTTPlatform] = useState([]);

 const handleChange = (event) => {
   const {
     target: { value },
   } = event;
   setOTTPlatform(
     // On autofill we get a stringified value.
     typeof value === 'string' ? value.split(',') : value,
   );
 };



      
const [ UploadPrecentage, setUploadPrecentage]= useState(0); 
const [Tags, setTags] = useState([])
const [buttons, setButtons] = useState(false)

  useEffect(() => {
    // var id='62eb92ee577e978084dbebeb'

    axios.post('/api/User/getSportsById',{id}).then((res)=>{
        console.log(res.data)
        setDescription(res.data[0]?.Description)
        setHeadLine(res.data[0]?.HeadLine)
        setFiles(res.data[0]?.Files)
        setNewFiled({
            ...newFiled,
            TvChannel:res.data[0]?.TvChannel,
            TvChannelOnAt:res.data[0]?.TvChannelOnAt,
            OTTOnAt:res.data[0]?.OTTOnAt,

        })
        setOTTPlatform(res.data[0].OTTPlatform)
    }).catch((error)=>{
        console.log(error)
    })
 
  }, [])

  const Update = () => {
    setButtons(true)
    formData.delete("file");
    formData.delete("data");
    console.log(HeadLine)
    console.log(Description)
    console.log(Files)
  
    const options = {
        onUploadProgress: (progressEvent) => {
            console.log(progressEvent)
            const { loaded, total } = progressEvent;
            let percent = Math.floor(((loaded / 1000) * 100) / (total / 1000));
            console.log(percent)
            // move(percent)/
            if (percent < 100) {
                setUploadPrecentage(percent);
                console.log(UploadPrecentage)
            }

        },
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }

    let filess = []
    // formData.append("data", data)
    Files.forEach(element => {
        if (element.link) {
            filess = [...filess,element]
        } else {
            
            formData.append("file", element);
        }
    })
        let data = {
            HeadLine, Description,
            PostCat,
            Category,
            Tags,filess,id,
            newFiled,
            OTTPlatform,
        }
        data = JSON.stringify(data)
    
        formData.append("data", data)
        axios.post('/api/User/Sports_update', formData, options).then((res) => {
        console.log(res)
        // setUploadPrecentage(100);
        setButtons(false)
        notify("Post updated successfully",true);


        setTimeout(() => {

            // setloading(false)
            setUploadPrecentage(100);
            
            return navigate(`/sports`)

        }, 1000);

    }).catch((error) => {
        setUploadPrecentage(0)
        setButtons(false)
        notify("Post updated unsuccessfully",false);

        console.log(error)
    })

}

  
    

    return <>
      <CreatorContext.Provider value={{ Files, setFiles }}>
        <div className="container ">
            <br />
            <br />
            <br />
            <br />
            <br />
            <form >

               <SliderMovie setFiles={setFiles} Edit={true} />
               <br></br>
                <input type={'text'} value={HeadLine} onChange={(e) => { setHeadLine(e.target.value) }} placeholder='Name' className='form-control'></input>
                <br></br>
                <textarea placeholder="Synopsis" rows={10} value={Description} onChange={(e) => {
                    setDescription(e.target.value)
                }} className="form-control"></textarea>
                <br></br>
                <input type={'text'} value={newFiled.TvChannel} onChange={(e) => { setNewFiled({...newFiled,TvChannel:e.target.value}) }} placeholder='Tv Channel' className='form-control'></input>
                <br></br>
                <input type={'text'} value={newFiled.TvChannelOnAt} onChange={(e) => { setNewFiled({...newFiled,TvChannelOnAt:e.target.value}) }} placeholder='ON / AT' className='form-control'></input>
                <br></br>
                <FormControl sx={{ m: 1, width: 500 }}>
                    <InputLabel id="demo-multiple-checkbox-label">OTT Platform</InputLabel>
                    <Select
                        labelId="demo-multiple-checkbox-label1"
                        id="demo-multiple-checkbox"
                        multiple
                        value={OTTPlatform}
                        onChange={handleChange}
                        input={<OutlinedInput label="OTT Platform" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                    >
                        {OTTPlatformList.map((name) => (
                            <MenuItem key={name} value={name}>
                                <Checkbox checked={OTTPlatform.indexOf(name) > -1} />
                                <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <br></br>
                <input type={'text'} value={newFiled.OTTOnAt} onChange={(e) => { setNewFiled({...newFiled,OTTOnAt:e.target.value}) }} placeholder='ON / AT' className='form-control'></input>
                <br></br>
                {UploadPrecentage>0?
            <>
            <ProgressBar variant="SOME_NAME" now={UploadPrecentage} label={`${UploadPrecentage}%`} />
            <br></br>

            </>
            :""}
                <div className="row">
                    <div className="col-12">
                        <button type="button" onClick={(e) => Update()} className="btn pro-spaces-button3 float-end">Update</button>
                    </div>
                </div>
                <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            </form>
        </div>
        </CreatorContext.Provider>
    </>
}

export const SliderMovie = (props) => {
    let CreateContext = useContext(CreatorContext)



    const FileInput = useRef()
    function dragOverHandler(ev) {
        console.log('File(s) in drop zone');
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
    }
    const DropUpload = (ev) => {
        console.log('File(s) dropped');
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
        let temp = []
        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (let i = 0; i < ev.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (ev.dataTransfer.items[i].kind === 'file') {
                    const file = ev.dataTransfer.items[i].getAsFile();
                    console.log(`… file[${i}].name = ${file.name}`);
                    temp = [...temp, file]
                }
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            for (let i = 0; i < ev.dataTransfer.files.length; i++) {
                console.log(`… file[${i}].name = ${ev.dataTransfer.files[i].name}`);
                const file = ev.dataTransfer.items[i].getAsFile();
                temp = [...temp, file]
            }
        }
        CreateContext.setFiles([...CreateContext.Files, ...temp])
    }
    let stylecontainer = {
        position: 'relative',
        float: 'left',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',

    }
    let imgs = {
        position: 'relative',
        float: 'left',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: 'inherit'

    }
    const ClickUpload = (e) => {
        let target = e.target.files
        console.log(e.target.files)
        // return
        if (e) {
            let file = [...CreateContext.Files, ...target]
            CreateContext.setFiles(file)
        }
    }
    const removeThisFile = (e, index) => {
        // if (!props.Edit) {
        let temp = [...CreateContext.Files]
        temp.splice(index, 1)
        CreateContext.setFiles(temp)
        // } else {
        //     console.log(index)
        // }
    }
    // useEffect(() => {
    //     CreateContext.setFiles(Files)
    //     console.log(Files)
    //     return () => {
    //     }
    // }, [Files])

    return <> <input onChange={(e) => ClickUpload(e)} className="d-none" type={'file'} ref={FileInput}  multiple accept="image/*"></input>
        <Carousel interval={null} autop slid className="specscarosel23876487236">
            {CreateContext.Files.length > 0 && CreateContext.Files.map((elem, index) => {
                console.log(elem)
                return <CarouselItem >
                    <button type="button" style={{ position: 'absolute', right: '0' }} className="btn btn-close btn-close-white" onClick={(e) => removeThisFile(e, index)}></button>
                    <div className="bg-dark text-light" style={{ height: '450px' }}>
                        <img style={imgs} src={props.Edit ? elem.link ? elem.link : URL.createObjectURL(elem): URL.createObjectURL(elem)}></img>
                    </div>
                </CarouselItem>
            })
            }
            <CarouselItem >
                <div onClick={(e) => { FileInput.current.click() }} onDragOver={(e) => dragOverHandler(e)} onDrop={(e) => DropUpload(e)} className="bg-dark text-light" style={{ height: '450px' }}>
                    <span style={stylecontainer} className="text-light">Drag in File or click</span>
                </div>
            </CarouselItem>
        </Carousel></>
}