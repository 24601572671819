import React from 'react'
import { Link } from "react-router-dom";


export default function Vendorcategory() {
  return (
    <div>
    <div className="container ">
      <div className="row slhishieo3jfiehfjefbewf">
      {/* <div className="d-flex justify-content-end">
    <button className="btn btn-dosabutton ps-3 pe-3 mb-3 deghievgviehcieh" data-bs-toggle="modal" data-bs-target="#jotundeejvheirstand">UNDERSTAND THIS FEATURE</button>
    </div>
    <hr /> */}
        <div className="col-lg-6 col-md-6 col-sm-12">

        <Link to="/vendorcategorysaddedit"  className="wgfiuwrgiu">

          <div className="card carrrrbus">
            
            
              <div className="padunnor"><center>Category<br>

              </br> </center></div>
            
            
          </div>
          </Link>
        </div>
        {/* {localStorage.getItem("Type")==="Consultant" || "Product"? */}
        <div className="col-lg-6 col-md-6 col-sm-12">

        <Link to="/vendorsubcategorysaddedit"  className="wgfiuwrgiu">

          <div className="card carrrrbus">
          
           
              <div className="padunnor"><center>Sub Category<br>
              
              </br></center></div>
            

          </div>
          </Link>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12">

<Link to="/brand_vendor_add_edit"  className="wgfiuwrgiu">

  <div className="card carrrrbus">
  
   
      <div className="padunnor"><center>Brand<br>
      
      </br></center></div>
    

  </div>
  </Link>
</div>
        
        
        {/* :""}
         */}
      </div>
   
  </div>
  </div>
  )
}
