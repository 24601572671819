import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './Priceing.css';
import Axios from 'axios';

import { Link } from 'react-router-dom';
import { Consultants } from './Consultants';
import { Footer } from '../../Pages/HomePage/Footer';

export function Priceing(props) {
  const [Status, setStatus] = useState('');
  const [Active, setActive] = useState('');
  const [Activev2, setActivev2] = useState('');
  const [Activev3, setActivev3] = useState('');
  const [PAssive, setPAssive] = useState('');
  useEffect(() => {
    props.Auth1();
    Axios.get('/api/Admin/AdminsAccountDetails')
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          setStatus(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    Axios.get('/api/free/getsubscriptionfees')
      .then((res) => {
        console.log(res.data);
        setActive(res.data.Active);
        setActivev2(res.data.Activev2);
        setActivev3(res.data.Activev3);
        setPAssive(res.data.Passive);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="bg-light">
      <div className="container py-3 notranslate ">
        <center className="d-none">
          <p>
            <span
              className="blink_me "
              style={{ color: '#55374a', fontWeight: '700' }}
            >
              JOIN NOW
            </span>{' '}
            to get a{' '}
            <span style={{ color: '#55374a', fontWeight: '700' }}>
              PRO-ACTIVE
            </span>{' '}
            membership at the cost of{' '}
            <span style={{ color: '#55374a', fontWeight: '700' }}>ACTIVE</span>{' '}
            membership using code{' '}
            <span style={{ color: '#55374a', fontWeight: '700' }}>
              " IAMAPRO "
            </span>
          </p>
        </center>
        <center>
          <h4 className="mb-3">MEMBERSHIP SUBSCRIPTION TYPE</h4>
        </center>

        <div className="row row-cols-1 row-cols-md-2 row-cols-xxl-3 mb-3 text-center d-flex justify-content-center ">
          {Status.Login_id?.Type === 'AffiliatedAccount' ? (
            <>
              <div className="col">
                <div className="card mb-4 rounded-3 shadow-sm ">
                  <div className="card-header py-3">
                    <h4 className="my-0 fw-normal">
                      <b style={{ color: '#55374a' }}></b>For Service Providers
                      / Product Retailers
                    </h4>
                  </div>
                  <div className="card-body">
                    <h4 className="card-title pricing-card-title pt-3">
                      BASIC listing
                    </h4>
                    {/* <p className="mt-1">
                      One time Registration Fee Only for the 1st year : ₹{' '}
                      {Activev3}/- (including GST)
                    </p>
                    <p className="mt-1">
                      From 2nd year onwards : ₹ 500/- per year (including GST)
                    </p> */}
                    <p className="my-2">
                      ₹ {Activev3}/- per year including GST
                    </p>
                    <Link to="/static_listing_register">
                      <button
                        type="button"
                        className="w-100 btn btn-lg pro-spaces-button3"
                      >
                        Get started
                      </button>
                    </Link>

                    <p className="mt-4">
                      Use the below mentioned COUPON CODE for a 50% Discount
                      <br></br>
                      <br></br>
                      BASIC250 for only ₹ 250/-<br></br>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card mb-4 rounded-3 shadow-sm">
                  <div className="card-header py-3">
                    <h4 className="my-0 fw-normal">
                      <b style={{ color: '#55374a' }}></b>For Service Providers
                      / Product Retailers
                    </h4>
                  </div>
                  <div className="card-body">
                    <h4 className="card-title pricing-card-title pt-3">
                      PRIME league
                    </h4>
                    {/* <p className="mt-1">
                      One time Registration Fee Only for the 1st year : ₹{' '}
                      {Active}/- (including GST)
                    </p>
                    <p className="mt-1">
                      From 2nd year onwards : ₹ 1000/- per year (including GST)
                    </p> */}
                    <p className="my-2">₹ 5000/- per year including GST</p>
                    <Link to="/pro-active_register">
                      <button
                        type="button"
                        className="w-100 btn btn-lg pro-spaces-button3"
                      >
                        Get started
                      </button>
                    </Link>
                    <p className="mt-4">
                      Use the below mentioned COUPON CODE for a 50% Discount
                      <br></br>
                      <br></br>
                      PRIME2500 for only ₹ 2500/-
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {Status.Activev3_status === true ||
              Status.Login_id?.Type === 'SUPERADMIN' ? (
                <div className="col">
                  <div className="card mb-4 rounded-3 shadow-sm ">
                    <div className="card-header py-3">
                      <h4 className="my-0 fw-normal">
                        <b style={{ color: '#55374a' }}></b>For Service
                        Providers / Product Retailers
                      </h4>
                    </div>
                    <div className="card-body">
                      <h3 className="card-title pricing-card-title pt-3">
                        BASIC
                      </h3>
                      <p className="mt-1">
                        ( ₹ {Activev3} /- Inclusive of 18% GST )
                      </p>
                      <Link to="/static_listing_register">
                        <button
                          type="button"
                          className="w-100 btn btn-lg pro-spaces-button3"
                        >
                          Get started
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              {Status.Active_status ||
              Status.Login_id?.Type === 'SUPERADMIN' ? (
                <div className="col">
                  <div className="card mb-4 rounded-3 shadow-sm">
                    <div className="card-header py-3">
                      <h4 className="my-0 fw-normal">
                        <b style={{ color: '#55374a' }}></b>For Service
                        Providers / Product Retailers
                      </h4>
                    </div>
                    <div className="card-body">
                      <h3 className="card-title pricing-card-title pt-3">
                        PRIME
                      </h3>
                      <p className="mt-1">
                        ( ₹ {Active} /- Inclusive of 18% GST )
                      </p>
                      <Link to="/pro-active_register">
                        <button
                          type="button"
                          className="w-100 btn btn-lg pro-spaces-button3"
                        >
                          Get started
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              {Status.Activev2_status ||
              Status.Login_id?.Type === 'SUPERADMIN' ? (
                <div className="col">
                  <div className="card mb-4 rounded-3 shadow-sm ">
                    <div className="card-header py-3">
                      <h4 className="my-0 fw-normal">
                        <b style={{ color: '#55374a' }}></b>For Service
                        Providers / Product Retailers
                      </h4>
                    </div>
                    <div className="card-body">
                      <h3 className="card-title pricing-card-title pt-3">
                        ELITE
                      </h3>
                      <p className="mt-1">
                        ( ₹ {Activev2} /- Inclusive of 18% GST )
                      </p>
                      <Link to="/active_register">
                        <button
                          type="button"
                          className="w-100 btn btn-lg pro-spaces-button3"
                        >
                          Get started
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              {Status.Passive_status ||
              Status.Login_id?.Type === 'SUPERADMIN' ? (
                <div className="col">
                  <div className="card mb-4 rounded-3 shadow-sm">
                    <div className="card-header py-3">
                      <h4 className="my-0 fw-normal">
                        <b style={{ color: '#55374a' }}></b>For Clients /
                        Customers
                      </h4>
                    </div>
                    <div className="card-body">
                      <h3 className="card-title pricing-card-title pt-3">
                        PASSIVE
                      </h3>
                      <p className="mt-1">
                        ( ₹ {PAssive} /- Inclusive of 18% GST )
                      </p>

                      <Link to="/passive_register">
                        <button
                          type="button"
                          className="w-100 btn btn-lg pro-spaces-button3"
                        >
                          Get started
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </>
          )}
        </div>
        {Status.Login_id?.Type === 'AffiliatedAccount' && (
          <>
            <div className=" mt-3 card mb-4 rounded-3 shadow-sm">
              <div className="my-3 px-3">
                {/* <li>
              You may use the above mentioned Relevant Coupon Codes for a Discount on the subscription fees.<br></br><br></br>

              (Static Coupons for Static listing and Prime Coupons for Prime membership)
            </li>
            <br></br> */}
                <span className="boldfont">POINTS TO REMEMBER </span>
                <br />
                <br />
                • All Subscriptions are valid till 1st January, 2025. That
                means, Subscription RENEWAL needs to be done ONLY on 01-01-2025.
                <br />
                <br />• Please Note that in certain Categories / Sub Categories
                of Qualified Professionals, Profiles cannot be created under
                BASIC listing.<br></br>
                <br></br>• Profiles of these Qualified Professionals (like
                Architects, Structural Engineers, Chartered Accountants,
                Doctors, Lawyers, etc) can be listed Only under PRIME league
                because of&nbsp;mandatory Professional information
                required from them.
              </div>
            </div>

            <div className=" mt-3 card mb-4 rounded-3 shadow-sm">
              <div className="row py-3  px-2">
                <div className="pt-2  text-center">
                  A set of 3 Multi-purpose Holders{' '}
                  <span
                    id="freeblink"
                    className="boldfont px-2 pb-1"
                    style={{
                      border: '2px solid',
                      borderColor: 'yellow',
                      borderRadius: '20px',
                      backgroundColor: 'yellow',
                    }}
                  >
                    FREE
                  </span>
                </div>
                <div className="pt-1 text-center pb-3">
                  (with PRIME Subscription Only)
                </div>
                <div className="col-6 ">
                  <img className="img-fluid" src="/c9.jpg" />
                </div>
                <div className="col-6 d-flex align-items-center ">
                  <div className="pt-2 ">
                    <span
                      className="vipar"
                      style={{
                        fontSize: '28px',
                        color: '#99d050',
                      }}
                    >
                      {' '}
                      Vipar&nbsp;
                    </span>{' '}
                    <span className="footlightfont pxfont25">Cap-pod</span>
                  </div>
                </div>
                <div className="">
                  <hr className="" />
                  <div className=" text-center footlightfont">
                    a capsule-shaped compact receptacle
                  </div>
                  <div className="pt-2 ps-2">
                    <div className="row d-flex justify-content-start">
                      <div className="col-3 d-flex justify-content-end">
                        CHROME
                      </div>
                      <div className="col-7">(Black, White, Grey)</div>
                      <div className="col-3 d-flex justify-content-end">
                        NATURE
                      </div>
                      <div className="col-7">(Blue, Green, Yellow)</div>
                      <div className="col-3 d-flex justify-content-end">
                        FLORAL
                      </div>
                      <div className="col-7">(Red, Pink, Lavender)</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <Consultants type={props.type} />
      </div>

      {Status.Login_id?.Type === 'AffiliatedAccount' && (
        <div
          className="bg-light"
          style={{ position: 'absolute', paddingTop: '100px', width: '100%' }}
        >
          <Footer />
        </div>
      )}
    </div>
  );
}
