import React, { useState, useEffect, useRef } from 'react';
import { AdminType } from '../../Utility/helper';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validator } from 'validator';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Form } from 'react-bootstrap';

import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { print_state, print_city, state_arr } from '../../Utility/stateCity';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { StateCity } from './StateCity';
import { IoChevronUpSharp, IoInformationCircleOutline } from 'react-icons/io5';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Timer } from '../../Utility/timer';
import { Footer } from '../../Pages/HomePage/Footer';
import { ControlBar } from '../../Pages/HomePage/ControlBar';
import { Navbar2 } from '../../Pages/HomePage/Navbar2';
import { Imageupload } from './Imageupload';
import { Statecity2 } from './Statecity2';
import { IoIosCloseCircleOutline } from 'react-icons/io';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '300',
    },
  },
};

export const Updateameprofile = (props) => {
  let navigate = useNavigate();
  const [Stateload, setStateload] = useState(true);
  const [Stateloadd, setStateloadd] = useState(true);

  useEffect(() => {
    if (Stateload) print_state('State');
    setStateload(false);
  }, []);

  const [adar, setadar] = useState([]);

  const fun = (index) => {
    let temp = [...adar];
    temp.splice(index, 1);
    setadar([...temp]);
  };

  var btnchange = useRef();

  const [value, setValue] = useState({
    AdminType: 'AffiliatedAccount',
    Name: '',
    Email: '',
    Gender: '',
    Age: '',
    Nationality: '',
    Contact: '',
    Password: '',
    ConformPassword: '',
    showPassword: false,
    showConformPassword: false,
    Referral_Code: '',
    Active_status: true,
    Activev2_status: true,
    Activev3_status: true,
    Passive_status: true,
    Service_provide_category_All: true,
    Product_retailer_category_All: true,
    country: '',
    state: '',
    city: '',
    Commission_percentage: '',
    Bank_account_type: '',
    loginID: '',
    Gender: '',
    Age: '',
    Nationality: '',
    Address: '',
    Pincode: '',
    id: '',
    Work_Experience: '',
    Highest_Educational_Qualification: '',
    Ready_to_Join_on_anyday_after: '',
    ED_Pursuing_Education: '',
    ED_College: '',
    ED_University: '',
    ED_City: '',
    ED_Period_of_Internship_Start: '',
    ED_Period_of_Internship_End: '',

    Studentornot: '',
    FMName: '',
    FMRelationship: '',
    FMContact: '',
    FMAddress: '',
    FMPincode: '',
    BloodGroup: '',
  });

  const [img, setimg] = useState('');

  const [Edit, setEdit] = useState('');
  useEffect(() => {
    axios
      .get('/api/AffiliatedAccount/getAffiliatedAccountDetails')
      .then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          var data = res.data[0];
          if (res.data.length > 0) {
            props.Auth1();
            setEdit(res.data);
            setValue({
              ...value,
              Name: data.Name,
              Email: data.Account[0]?.Email,
              Gender: data.Gender,
              Age: data.Age,
              Nationality: data.Nationality,
              Contact: data.Account[0]?.Contact,
              Referral_Code: data.Referral_Code,
              Adhar: data.Adhar,
              state: data.state,
              city: data.city,
              country: data.country,
              Account_holder_name: data.Account_holder_name,
              Account_number: data.Account_number,
              Ifsc_code: data.Ifsc_code,
              Bank_name: data.Bank_name,
              Branch_name: data.Branch_name,
              country: data.country,
              Bank_account_type: data?.Bank_account_type,
              loginID: data.Account[0]?._id,
              Gender: data.Gender,
              Age: data.Age,
              Nationality: data.Nationality,
              Address: data.Address,
              Pincode: data.Pincode,
              id: data._id,
              Work_Experience: data.Work_Experience,
              Highest_Educational_Qualification:
                data.Highest_Educational_Qualification,
              Studentornot: data.Studentornot,
              Ready_to_Join_on_anyday_after: data.Ready_to_Join_on_anyday_after,
              ED_Pursuing_Education: data.ED_Pursuing_Education,
              ED_College: data.ED_College,
              ED_City: data.ED_City,
              ED_Period_of_Internship_Start: data.ED_Period_of_Internship_Start,
              ED_Period_of_Internship_End: data.ED_Period_of_Internship_End,
              ED_University: data.ED_University,
              FMName: data.Family_name,
              FMRelationship: data.Family_relationship,
              FMContact: data.Family_contact,
              FMAddress: data.Family_address,
              FMPincode: data.Family_pincode,
              Fmstate: data.Family_state,
              Fmcity: data.Family_city,
              Fmcountry: data.Family_country,
              BloodGroup: data.BloodGroup,
            });

            setvalueee({
              state: data.Family_state,
              city: data.Family_city,
              country: data.Family_country,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // var validator=validator

  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (sab == 'red') {
      toast.danger(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [valueee, setvalueee] = useState({
    Fmstate: '',
    Fmcity: '',
    Fmcountry: '',
  });

  // const [Cat, setCat] = useState([])
  //       function reload()
  //       {
  //         axios
  //         .get("/api/api//getcategory")
  //         .then((res) => {
  //           if (res.status === 200) {
  //             console.log("workd");
  //             console.log(res.data)
  //             setCat(res.data);
  //           }
  //         })
  //         .catch((err) => {
  //           // console.log(err.response.status);
  //         });
  //       }
  // const [Cat1, setCat1] = useState([])

  //       function reload1()
  //       {
  //         axios
  //         .get("/api/api/getprocategory")
  //         .then((res) => {
  //           if (res.status === 200) {
  //             console.log("workd");
  //             console.log(res.data)
  //             setCat1(res.data);
  //           }
  //         })
  //         .catch((err) => {
  //           // console.log(err.response.status);
  //         });
  //       }

  //       useEffect(() => {
  //         reload()
  //         reload1()

  //       }, [])

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  function validator1(e) {
    e.preventDefault();

    setSubmit2(true);
    const errors = {};
    // if(!value.Name)return setError("Name is missing !");
    // if (!value.Email) {
    //   errors.email = 'Email required !'
    // return setError(errors)
    // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.Email)) {
    //   errors.email = 'Invalid email address !'
    // return setError(errors)
    // }

    // if (!value.Contact) {
    //     return setError('Enter phone number !')
    // }
    // if (value.Contact.toString().length != 10) {
    //     return setError('Enter a valid mobile number !')
    // }
    // if(!value.Commission_percentage)return setError("Discount percentage is missing !");
    if (!value.Address) {
      return setError('');
    }
    if (!value.Pincode) {
      return setError('');
    }

    if (!Edit.length > 0) {
      console.log(img);
      if (!img) {
        return setError('PLEASE UPLOAD Image');
      }
      if (!value.Work_Experience) return setError('');
      if (!value.Highest_Educational_Qualification) return setError('');
      if (!value.Ready_to_Join_on_anyday_after) return setError('');
      if (!value.Password) return setError('');

      //   if (value.Password.toString().length <8) {
      //     return setError('Password must contain 8 characters !')
      // }
      setError('');
      if (value.Password.toString() !== value.ConformPassword.toString()) {
        return setError("'Password' and 'Confirm Password' must be matching !");
      }
    }

    setError('');
    // &&TermsAndCondition.Terms4
    if (Edit.length > 0) {
      console.log('<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>1');
      handleShow11();
    } else {
      if (value.Age <= 17) {
        return setError(
          'Sorry....you are too young ! Age must be 18 years and above.'
        );
      }
      if (
        TermsAndCondition.Terms1 &&
        TermsAndCondition.Terms2 &&
        TermsAndCondition.Terms3 &&
        TermsAndCondition.Terms4 &&
        TermsAndCondition.Terms5 &&
        TermsAndCondition.Terms6 &&
        TermsAndCondition.Terms7 &&
        TermsAndCondition.Terms8 &&
        TermsAndCondition.Terms9 &&
        TermsAndCondition.Terms13 &&
        TermsAndCondition.Terms14 &&
        TermsAndCondition.Terms15 &&
        TermsAndCondition.Terms16 &&
        TermsAndCondition.Terms17 &&
        TermsAndCondition.Terms18 &&
        TermsAndCondition.Terms19 &&
        TermsAndCondition.Terms20 &&
        TermsAndCondition.Terms21 &&
        TermsAndCondition.Terms22 &&
        TermsAndCondition.Terms12
      ) {
        if (!ButtonDisabled1) {
          setButtonDisabled(false);
          console.log('hai');
        }
      } else {
        return setError('Accept all terms and condition ');
      }
      go();
    }
  }

  function go() {
    console.log(img);
    console.log('meeeeeeee');
    var name = value.Name;
    if (ButtonDisabled1) {
      return setError('Enter referral code ');
    }
    setError('');
    setLoading(true);
    // console.log(value)
    // return

    var Affiliated = true;
    console.log(img);
    var formdata = new FormData();
    {
      adar?.map((elem) => {
        formdata.append('file', elem);
      });
    }
    formdata.append('data', JSON.stringify({ value, Affiliated }));
    var imag = new File([img], 'avatar.png', { type: 'image/png' });

    formdata.append('file', imag);
    axios
      .post('/api/auth/CreateAffiliatedAccount', formdata)
      .then((res) => {
        if (res.status === 200) {
          notify('Successfully created', true);
          setValue({
            ...value,
            AdminType: '',
            Name: '',
            Email: '',
            Contact: '',
            Password: '',
            Pin: '',
            Active_status: true,
            Activev2_status: true,
            Activev3_status: true,
            Passive_status: true,
            Service_provide_category_All: true,
            Product_retailer_category_All: true,
            state: '',
            city: '',
            Commission_percentage: '',
            showConformPassword: false,
            Referral_Code: '',
            ConformPassword: '',
            Account_holder_name: '',
            Account_number: '',
            Ifsc_code: '',
            Bank_name: '',
            Branch_name: '',
            country: '',
            Bank_account_type: '',
            Gender: '',
            Age: '',
            Nationality: '',

            Address: '',
            Pincode: '',
            Work_Experience: '',
            Highest_Educational_Qualification: '',
            Ready_to_Join_on_anyday_after: '',
            ED_Pursuing_Education: '',
            ED_College: '',
            ED_City: '',
            ED_Period_of_Internship_Start: '',
            ED_Period_of_Internship_End: '',
            ED_University: '',
            FMName: '',
            FMRelationship: '',
            FMContact: '',
            FMAddress: '',
            FMPincode: '',
            BloodGroup: '',
          });
          setService_provide_category([]);
          setProduct_retailer_category([]);
          setLoading(false);
          setError('');
          navigate(`/congratulations?name=${name}`);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          notify('Wrong security pin', false);
        } else if (err.response.status === 402 || err.response.status === 409) {
          notify('Email already exist', false);
        } else if (err.response.status === 403) {
          notify('Name already exist', false);
        } else if (err.response.status === 406) {
          notify('This Code already exists', false);
        } else if (err.response.status === 407) {
          notify('This Code already exists', false);
        } else if (err.response.status === 408) {
          notify('Enter referral code', false);
        } else {
          notify('Something went wrong', false);
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const [Service_provide_category, setService_provide_category] =
    React.useState([]);

  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setService_provide_category(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const [Product_retailer_category, setProduct_retailer_category] =
    React.useState([]);
  const handleChange2 = (event) => {
    const {
      target: { value },
    } = event;
    setProduct_retailer_category(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  useEffect(() => {
    if (value.Service_provide_category_All) {
      setService_provide_category([]);
    }
    if (value.Product_retailer_category_All) {
      setProduct_retailer_category([]);
    }
  }, [value.Service_provide_category_All, value.Product_retailer_category_All]);

  const [ButtonDisabled, setButtonDisabled] = useState(false);
  const [ButtonDisabled1, setButtonDisabled1] = useState(true);
  const checkReferral_Code = (e) => {
    if (!Edit?.length > 0) {
      setButtonDisabled(true);
    }
    var Referral_Code = e;
    axios
      .post('/api/auth/checkReferral_Code', { Referral_Code })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length == 0) {
            notify('This referral code is available for you', true);

            setButtonDisabled1(false);

            setError('');
          } else {
            notify('This Code already exists', false);
            setError('This Code already exists');
          }
        }
      })
      .catch((err) => {
        notify('Something went wrong', false);
        setError('');
      });
  };
  const [Submit2, setSubmit2] = useState(false);
  const [Error2, setError2] = useState('');
  function Update(e) {
    setError2('');
    var formdata = new FormData();
    formdata.append('data', JSON.stringify({ value, OTP }));
    if (img) {
      var imag = new File([img], 'avatar.png', { type: 'image/png' });

      formdata.append('file', imag);
    }
    {
      adar?.map((elem) => {
        formdata.append('file', elem);
      });
    }

    axios
      .post('/api/AffiliatedAccount/Update_Account', formdata)
      .then((res) => {
        if (res.status == 200) {
          notify('Updated', true);
          console.log(res.data);
          handleClose11();
          setError2('');
          setOTP('');
          end();
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 410) {
          return setError2('Enter valid OTP ');
        }
        notify('Something went wrong !', false);
      });
  }
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const [TermsAndCondition, setTermsAndCondition] = useState({
    Terms1: false,
    Terms2: false,
    Terms3: false,
    Terms4: false,
    Terms5: false,
    Terms6: false,
    Terms7: false,
    Terms8: false,
    Terms9: false,
    Terms12: false,
    Terms13: false,
    Terms14: false,
    Terms15: false,
    Terms16: false,
    Terms17: false,
    Terms18: false,
    Terms19: false,
    Terms20: false,
    Terms21: false,
    Terms22: false,
  });

  useEffect(() => {
    // console.log(TermsAndCondition)
    // console.log("ButtonDisabled :",ButtonDisabled)
    // console.log("ButtonDisabled1 :",ButtonDisabled1)
    console.log('<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>2');
    console.log(Edit.length);
    if (Edit.length > 0) {
      console.log(Edit.length);
    } else {
      console.log('<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>2');
      //
      if (
        TermsAndCondition.Terms1 &&
        TermsAndCondition.Terms2 &&
        TermsAndCondition.Terms3 &&
        TermsAndCondition.Terms4 &&
        TermsAndCondition.Terms5 &&
        TermsAndCondition.Terms6 &&
        TermsAndCondition.Terms7 &&
        TermsAndCondition.Terms8 &&
        TermsAndCondition.Terms9 &&
        TermsAndCondition.Terms13 &&
        TermsAndCondition.Terms14 &&
        TermsAndCondition.Terms15 &&
        TermsAndCondition.Terms16 &&
        TermsAndCondition.Terms17 &&
        TermsAndCondition.Terms18 &&
        TermsAndCondition.Terms19 &&
        TermsAndCondition.Terms20 &&
        TermsAndCondition.Terms21 &&
        TermsAndCondition.Terms22 &&
        TermsAndCondition.Terms12
      ) {
        if (!ButtonDisabled1) {
          setButtonDisabled(false);
          console.log('hai');
        }
      } else {
        return setError(' ');
      }
    }
    setError('');
  }, [Edit, TermsAndCondition, ButtonDisabled, ButtonDisabled1]);

  const [show11, setShow11] = useState(false);

  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => {
    setShow11(true);
    //
    GenerateOTP();
  };
  const GenerateOTP = () => {
    console.log('hai');
    axios
      .post('/api/AffiliatedAccount/GenerateOTPForAccountUpdate', {
        Email: value.Email,
      })
      .then((res) => {
        if (res.status == 200) {
          setpop(true);
          notify('OTP send successfully', true);
        }
      })
      .catch((err) => {
        setpop(false);
        notify('Something went wrong', false);
      });
  };

  const [OTP, setOTP] = useState('');
  const [pop, setpop] = useState(true);

  const end = () => setpop(false);

  const [NewPassword, setNewPassword] = useState({
    oldPassword: '',
    newPassword: '',
  });
  const [showpassword, setShowpassword] = useState(false);

  const handleClose111 = () => setShowpassword(false);
  const handleShow111 = () => setShowpassword(true);
  const [showPassword12, setShowPassword12] = useState(false);
  const [showPassword123, setShowPassword123] = useState(false);
  const UpdatePassword = () => {
    axios
      .post('/api/AffiliatedAccount/AccountPasswordUpdate', { NewPassword })
      .then((res) => {
        if (res.status == 200) {
          notify('Password updated successfully', true);
          setNewPassword({
            oldPassword: '',
            newPassword: '',
          });
          handleClose111();
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          notify('Old password is incorrect', false);
        } else {
          notify('Something went wrong', false);
        }
      });
  };

  const [showreferral, setShowreferral] = useState(false);

  const handleClosereferral = () => setShowreferral(false);
  const handleShowreferral = () => setShowreferral(true);

  /////////////////////////////////////////////////////////////
  return (
    <>
      {!Edit?.length > 0 ? (
        <>
          <ControlBar />
          <Navbar2 />
        </>
      ) : (
        ''
      )}

      <div className=" rounded bg-white px-3 px-md-5 py-5">
        {!Edit?.length > 0 ? (
          <h3 className="text-center mt-4 mb-5">
            AFFILIATE MARKETING REGISTRATION FORM
          </h3>
        ) : (
          <h3 className=" mt-4 mb-5">Account Details</h3>
        )}
        {Edit?.length > 0 ? (
          <div className="d-flex justify-content-end">
            <button
              className="btn pro-spaces-button3"
              onClick={(e) => {
                handleShow111(e);
              }}
            >
              Change password
            </button>
          </div>
        ) : (
          ''
        )}
        <form
          onSubmit={(e) => validator1(e)}
          novalidat
          className={Submit2 ? 'was-validated' : ''}
        >
          <hr />
          {/*  */}

          <div className=" pt-3">
            <div className="row">
              <h5 className="mt-4 d-block d-md-none">PERSONAL DETAILS *</h5>
              <div className="col-12  col-md-3 mnmnmnm mmmmm">
                <Imageupload onChange={(e) => setimg(e)} id={value.id} />
                <span className="mt-3 " style={{ paddingLeft: '15px' }}>
                  Upload your Photo *
                </span>
                <p className="danger">{Error}</p>
              </div>

              <div className="col-12 col-md-9">
                <div class="form-check pt-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    checked={value.Studentornot === 'NoStudent' ? true : false}
                    value={'NoStudent'}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        Studentornot: e.target.value,
                        ED_Pursuing_Education: '',
                        ED_College: '',
                        ED_University: '',
                        ED_City: '',
                        ED_Period_of_Internship_Start: '',
                        ED_Period_of_Internship_End: '',
                      })
                    }
                  />
                  <label class="" for="flexRadioDefault1">
                    I am NOT a Student.
                  </label>
                </div>
                <div class="form-check pt-3">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked={value.Studentornot == 'Student' ? true : false}
                    value={'Student'}
                    onChange={(e) =>
                      setValue({
                        ...value,
                        Studentornot: e.target.value,

                        Highest_Educational_Qualification: '',
                        Work_Experience: '',
                        Ready_to_Join_on_anyday_after: '',
                      })
                    }
                  />
                  <label class="" for="flexRadioDefault2">
                    I am a Student doing Internship.
                  </label>
                </div>

                <div className="row mt-3">
                  <div className="col-md-12 mt-2">
                    <div className="d-flex justify-content-between">
                      <h5 className=" d-flex align-items-center ">
                        UNIQUE REFERRAL CODE
                        *&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <IoInformationCircleOutline
                          size={18}
                          onClick={handleShowreferral}
                        />
                      </h5>
                    </div>
                    <div className="labels">
                      ( Click on the Information icon to clearly understand what
                      Referral Code is. )
                    </div>
                    <input
                      placeholder="Create Unique Referral Code of your Choice "
                      disabled={Edit?.length > 0 ? true : false}
                      className="form-control"
                      required
                      type={'text'}
                      value={value.Referral_Code}
                      onChange={(e) => {
                        setValue({ ...value, Referral_Code: e.target.value });
                      }}
                      minLength={5}
                      maxLength={10}
                      onBlur={(e) => {
                        if (e.target.value.length > 3)
                          checkReferral_Code(e.target.value);
                      }}
                    ></input>
                    <div class="invalid-feedback">
                      PLEASE ENTER Referral Code ( minimum of 5 characters )
                    </div>
                  </div>
                  <h5 className="mt-4  d-none d-md-block">
                    PERSONAL DETAILS *
                  </h5>
                  <div className="col-md-12">
                    <label className="labels">Full Name *</label>
                    <input
                      placeholder="Enter Full Name"
                      className="form-control"
                      disabled={Edit?.length > 0 ? true : false}
                      required
                      type={'text'}
                      value={value.Name}
                      onChange={(e) => {
                        setValue({ ...value, Name: e.target.value });
                      }}
                    ></input>
                    <div class="invalid-feedback">PLEASE ENTER Full Name</div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Gender *</label>
                    <select
                      class="form-control form-select"
                      id="exampleFormControlSelect1"
                      value={value.Gender}
                      onChange={(e) => {
                        setValue({ ...value, Gender: e.target.value });
                      }}
                      required
                      disabled={Edit?.length > 0 ? true : false}
                    >
                      <option></option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Transgender">Transgender</option>
                    </select>
                    <div class="invalid-feedback">PLEASE SELECT Gender</div>
                  </div>
                  <div className="col-md-12 ">
                    <label className="labels">Age *</label>
                    <div>
                      <div className="age w-100">
                        <input
                          placeholder="Enter Age"
                          disabled={Edit?.length > 0 ? true : false}
                          className="form-control  "
                          type={'number'}
                          min="18"
                          pattern="[0-9]{2}"
                          required
                          // onBlur={(e) => {
                          //   if (e.target.value <= 17) {
                          //     setValue({ ...value, Age: ' ' });

                          //   }
                          //   else{
                          //     setErr
                          //   }
                          // }}
                          value={value.Age}
                          onChange={(e) => {
                            if (
                              e.target.value.length <= 2 &&
                              /^\d*$/.test(e.target.value)
                            )
                              setValue({ ...value, Age: e.target.value });
                          }}
                        ></input>
                      </div>
                    </div>
                    <div class="invalid-feedback">PLEASE ENTER Age</div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Nationality *</label>
                    <select
                      class="form-control form-select"
                      required
                      id="exampleFormControlSelect1"
                      value={value.Nationality}
                      onChange={(e) => {
                        setValue({ ...value, Nationality: e.target.value });
                      }}
                      disabled={Edit?.length > 0 ? true : false}
                    >
                      <option></option>
                      <option value="Indian">Indian</option>
                      <option value="American">American</option>
                      <option value="British">British</option>
                      <option value="German">German</option>
                      <option value="Italian">Italian</option>
                    </select>
                    <div class="invalid-feedback">
                      PLEASE SELECT Nationality
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">BloodGroup *</label>
                    <input
                      placeholder="Enter BloodGroup"
                      className="form-control"
                      disabled={Edit?.length > 0 ? true : false}
                      required
                      type={'text'}
                      value={value.BloodGroup}
                      onChange={(e) => {
                        setValue({ ...value, BloodGroup: e.target.value });
                      }}
                    ></input>
                    <div class="invalid-feedback">PLEASE ENTER Blood Group</div>
                  </div>
                  <StateCity setValue={setValue} value={value} Edit={Edit} />
                  {/* [{country:value.country,state:value.state,city:value.city}] */}

                  <div className="col-md-12 mt-2 ">
                    <label className="labels">Address *</label>

                    <textarea
                      placeholder="Enter Full Address"
                      className="form-control  "
                      required
                      value={value.Address}
                      onChange={(e) => {
                        setValue({ ...value, Address: e.target.value });
                      }}
                    ></textarea>

                    <div class="invalid-feedback">PLEASE ENTER Address</div>
                  </div>
                  <div className="col-md-12 mt-2">
                    <label className="labels">Pin Code *</label>

                    <input
                      placeholder="Enter Pin Code"
                      className="form-control  "
                      type={'text'}
                      maxLength={6}
                      required
                      value={value.Pincode}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value))
                          setValue({ ...value, Pincode: e.target.value });
                      }}
                    ></input>

                    <div class="invalid-feedback">PLEASE ENTER Pin Code</div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Email Id *</label>
                    <input
                      placeholder="Enter an existing Email Id  "
                      disabled={Edit?.length > 0 ? true : false}
                      className="form-control "
                      type={'email'}
                      required
                      value={value.Email}
                      onChange={(e) => {
                        setValue({ ...value, Email: e.target.value });
                      }}
                    ></input>
                    <div class="invalid-feedback">PLEASE ENTER Email Id</div>
                  </div>

                  <div className="col-md-12">
                    <label className="labels">Contact Number *</label>
                    <input
                      placeholder="Enter Anytime Available Contact Number  "
                      maxLength={10}
                      required
                      className="form-control "
                      type={'text'}
                      value={value.Contact}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value))
                          setValue({ ...value, Contact: e.target.value });
                      }}
                    ></input>
                    <div class="invalid-feedback">PLEASE ENTER Contact</div>
                  </div>
                </div>

                <h5 className="mt-4">IDENTITY PROOF </h5>

                <input
                  hidden
                  ref={btnchange}
                  className="form-control"
                  type="file"
                  id="formFile"
                  accept="image/*"
                  required
                  onChange={(e) => setadar([...adar, ...e.target.files])}
                />
                <button
                  id="btnchange"
                  className="btn pro-spaces-button mt-3 mx-2"
                  style={{ backgroundColor: 'rgb(1 32 96)' }}
                  accept="image/*"
                  onClick={(e) => btnchange.current.click()}
                  type='button'
                >
                  Upload your Aadhaar Card *
                </button>
                {adar.length === 0 ? (
                  <>
                    <div className="row mt-2">
                      {value?.Adhar?.map((elem) => {
                        console.log(elem.file);
                        if (elem.file != 'avatar.png') {
                          return (
                            <div className="d-flex justify-content-center g-2 col-md-6 col-sm-12">
                              <img
                                src={elem.link}
                                style={{
                                  width: '300px',
                                  height: '300px',
                                  objectFit: 'cover',
                                }}
                              ></img>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row mt-2">
                      <label className="labels">
                        Upload both front and back sides of your Aadhaar Card
                      </label>
                      <div className="col-md-12">
                        <div></div>

                        <div className="ps-3 pt-3">
                          <div className="row g-3">
                            {adar.map((ele, index) => {
                              console.log(ele);
                              return (
                                <div className="col-12 col-md-6 col-lg-4">
                                  <IoIosCloseCircleOutline
                                    className="d-flex float-end"
                                    onClick={(e) => fun(index)}
                                  />
                                  <img
                                    src={URL.createObjectURL(ele)}
                                    style={{
                                      width: '100%',
                                      height: '200px',
                                      objectFit: 'cover',
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div class="invalid-feedback">
                          PLEASE UPLOAD Aadhaar Card
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {value?.Studentornot == 'Student' ? (
                  <>
                    <h5 className="mt-4">EDUCATIONAL DETAILS *</h5>
                    <div className="row mt-2">
                      <div className="col-md-12">
                        <label className="labels">
                          Pursuing Educational Course *
                        </label>

                        <textarea
                          placeholder="Enter Course Details relevant to your Internship program"
                          className="form-control  "
                          required
                          value={value.ED_Pursuing_Education}
                          onChange={(e) => {
                            setValue({
                              ...value,
                              ED_Pursuing_Education: e.target.value,
                            });
                          }}
                        ></textarea>

                        <div class="invalid-feedback">
                          PLEASE ENTER Pursuing Educational Course
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label className="labels">College *</label>

                        <input
                          className="form-control  "
                          required
                          value={value.ED_College}
                          onChange={(e) => {
                            setValue({
                              ...value,
                              ED_College: e.target.value,
                            });
                          }}
                        ></input>

                        <div class="invalid-feedback">PLEASE ENTER COLLEGE</div>
                      </div>

                      <div className="col-md-12">
                        <label className="labels">University *</label>

                        <input
                          className="form-control  "
                          required
                          value={value.ED_University}
                          onChange={(e) => {
                            setValue({
                              ...value,
                              ED_University: e.target.value,
                            });
                          }}
                        ></input>

                        <div class="invalid-feedback">
                          PLEASE ENTER University
                        </div>
                      </div>

                      <div className="col-md-12">
                        <label className="labels">City *</label>

                        <input
                          placeholder=""
                          className="form-control  "
                          required
                          value={value.ED_City}
                          onChange={(e) => {
                            setValue({
                              ...value,
                              ED_City: e.target.value,
                            });
                          }}
                        />

                        <div class="invalid-feedback">PLEASE ENTER City</div>
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Period of Internship *</label>
                        <div className="row">
                          <div className="col-5">
                            <input
                              placeholder=""
                              type="date"
                              className="form-control  "
                              required
                              value={value.ED_Period_of_Internship_Start}
                              onChange={(e) => {
                                setValue({
                                  ...value,
                                  ED_Period_of_Internship_Start: e.target.value,
                                });
                              }}
                            />
                            <div class="invalid-feedback">
                              PLEASE ENTER Period of Internship
                            </div>
                          </div>
                          <div className="col-2 d-flex justify-content-center align-items-end">
                            <span className="">to</span>
                          </div>
                          <div className="col-5">
                            <input
                              placeholder=""
                              type="date"
                              className="form-control  "
                              required
                              value={value.ED_Period_of_Internship_End}
                              onChange={(e) => {
                                setValue({
                                  ...value,
                                  ED_Period_of_Internship_End: e.target.value,
                                });
                              }}
                            />
                            <div class="invalid-feedback">
                              PLEASE ENTER Period of Internship
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {value?.Studentornot == 'NoStudent' ? (
                  <>
                    <h5 className="mt-4">
                      QUALIFICATION and WORK EXPERIENCE *
                    </h5>
                    <div className="row mt-2">
                      <div className="col-md-12">
                        <label className="labels">
                          Highest Educational Qualification *
                        </label>

                        <textarea
                          className="form-control  "
                          required
                          value={value.Highest_Educational_Qualification}
                          onChange={(e) => {
                            setValue({
                              ...value,
                              Highest_Educational_Qualification: e.target.value,
                            });
                          }}
                        ></textarea>

                        <div class="invalid-feedback">
                          PLEASE ENTER Highest Educational Qualification
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label className="labels">Work Experience *</label>

                        <textarea
                          placeholder="Type NIL if Fresher"
                          className="form-control  "
                          required
                          value={value.Work_Experience}
                          onChange={(e) => {
                            setValue({
                              ...value,
                              Work_Experience: e.target.value,
                            });
                          }}
                        ></textarea>

                        <div class="invalid-feedback">
                          PLEASE ENTER Work Experience
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label className="labels">
                          Ready to Join on anyday after *
                        </label>

                        <input
                          type="date"
                          className="form-control"
                          required
                          // disabled={Edit?.length > 0 ? true : false}
                          value={value.Ready_to_Join_on_anyday_after?.slice(
                            0,
                            10
                          )}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setValue({
                              ...value,
                              Ready_to_Join_on_anyday_after: e.target.value,
                            });
                          }}
                        ></input>

                        <div class="invalid-feedback">
                          PLEASE ENTER Ready to Join on anyday after
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <h5 className="mt-4">BANK ACCOUNT DETAILS *</h5>

                <div className="row mt-2">
                  <div className="col-md-12">
                    <label className="labels">Account Holder Name *</label>
                    <input
                      type="text"
                      placeholder="Enter Full Name as per bank account "
                      required
                      className="form-control"
                      maxLength="150"
                      value={value.Account_holder_name}
                      onChange={(e) =>
                        setValue({
                          ...value,
                          Account_holder_name: e.target.value,
                        })
                      }
                    />
                    <div class="invalid-feedback">
                      PLEASE ENTER Bank account holder name
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Bank Name *</label>
                    <input
                      type="text"
                      placeholder="Enter Bank Name "
                      required
                      className="form-control"
                      maxLength="40"
                      value={value.Bank_name}
                      onChange={(e) =>
                        setValue({ ...value, Bank_name: e.target.value })
                      }
                    />
                    <div class="invalid-feedback">PLEASE ENTER Bank name</div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Bank Account Type *</label>

                    <select
                      class="form-control form-select"
                      required
                      id="exampleFormControlSelect1"
                      value={value.Bank_account_type}
                      onChange={(e) =>
                        setValue({
                          ...value,
                          Bank_account_type: e.target.value,
                        })
                      }
                    >
                      <option></option>
                      <option value="SB">SB</option>
                      <option value="Current">Current</option>
                    </select>
                    <div class="invalid-feedback">
                      PLEASE SELECT Bank account type
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Account Number *</label>
                    <input
                      type="number"
                      placeholder="Enter Account Number "
                      required
                      className="form-control"
                      maxLength="100"
                      value={value.Account_number}
                      onChange={(e) =>
                        setValue({ ...value, Account_number: e.target.value })
                      }
                    />
                    <div class="invalid-feedback">
                      PLEASE ENTER Account number
                    </div>
                  </div>

                  <div className="col-md-12">
                    <label className="labels">IFSC code *</label>
                    <input
                      type="text"
                      required
                      placeholder="Enter IFSC code "
                      className="form-control"
                      maxLength="40"
                      value={value.Ifsc_code}
                      onChange={(e) =>
                        setValue({ ...value, Ifsc_code: e.target.value })
                      }
                    />
                    <div class="invalid-feedback">PLEASE ENTER IFSC code</div>
                  </div>
                  <h5 className="mt-4">
                    CONTACT INFORMATION of a FAMILY MEMBER in case of an
                    Emergency *
                  </h5>
                  <div className="col-md-12 mt-2">
                    <label className="labels">
                      Full Name of Family Member *
                    </label>
                    <input
                      className="form-control"
                      type={'text'}
                      value={value.FMName}
                      onChange={(e) =>
                        setValue({
                          ...value,
                          FMName: e.target.value,
                        })
                      }
                    ></input>
                    <div class="invalid-feedback">
                      PLEASE ENTER Full Name of Family Member
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">
                      Relationship with Family Member *
                    </label>

                    <select
                      class="form-control form-select"
                      required
                      id="exampleFormControlSelect1"
                      value={value.FMRelationship}
                      onChange={(e) =>
                        setValue({
                          ...value,
                          FMRelationship: e.target.value,
                        })
                      }
                    >
                      <option></option>
                      <option value="Father">Father</option>
                      <option value="Mother">Mother</option>
                      <option value="Brother">Brother</option>
                      <option value="Sister">Sister</option>
                      <option value="Husband">Husband</option>
                      <option value="Wife">Wife</option>
                      <option value="Son">Son</option>
                      <option value="Daughter">Daughter</option>
                    </select>
                    <div class="invalid-feedback">
                      PLEASE SELECT Relationship with Family Member
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">
                      Contact Number of Family Member *
                    </label>
                    <input
                      placeholder="  "
                      maxLength={10}
                      required
                      className="form-control "
                      type={'text'}
                      value={value.FMContact}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value))
                          setValue({ ...value, FMContact: e.target.value });
                      }}
                    ></input>
                    <div class="invalid-feedback">
                      PLEASE ENTER Contact Number of Family Member
                    </div>
                  </div>
                  <div className="col-md-12 mt-2 ">
                    <label className="labels">Address of Family Member *</label>

                    <textarea
                      placeholder="Enter Full Address"
                      className="form-control  "
                      required
                      value={value.FMAddress}
                      onChange={(e) => {
                        setValue({ ...value, FMAddress: e.target.value });
                      }}
                    ></textarea>

                    <div class="invalid-feedback">PLEASE ENTER Address</div>
                  </div>
                  {/* country
                  state
                  city */}
                  <div className="col-md-12 mt-2">
                    <label className="labels">Pin Code *</label>

                    <input
                      placeholder="Enter Pin Code"
                      className="form-control  "
                      type={'text'}
                      maxLength={6}
                      required
                      value={value.FMPincode}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value))
                          setValue({ ...value, FMPincode: e.target.value });
                      }}
                    ></input>

                    <Statecity2
                      setValue={setvalueee}
                      value={valueee}
                      Edit={Edit}
                    />

                    <div class="invalid-feedback">PLEASE ENTER Pin Code</div>
                  </div>
                  <h5 className="mt-4">LOGIN CREDENTIALS *</h5>
                  <div className="col-md-12 mt-2">
                    <label className="labels">
                      Login Id <br></br>( Your Email Id is your Default
                      Login Id. )
                    </label>
                    <input
                      disabled
                      className="form-control"
                      type={'text'}
                      value={value.Email}
                    ></input>
                  </div>

                  {error.length ? (
                    <div className="col-12 ">
                      <p className="py-1 m-0" style={{ color: 'red' }}>
                        {error ? error : ''}
                      </p>
                    </div>
                  ) : (
                    ''
                  )}

                  <div className="col-12 px-1  d-flex justify-content-center pt-3  ">
                    {ButtonDisabled ? (
                      <button className="btn pro-spaces-button3 " disabled>
                        {Edit?.length > 0 ? 'UPDATE' : 'REGISTER'}
                      </button>
                    ) : (
                      !loading && (
                        <button
                          className="btn pro-spaces-button3 "
                          onClick={(e) => validator1(e)}
                        >
                          {Edit?.length > 0 ? 'UPDATE' : 'REGISTER'}
                        </button>
                      )
                    )}
                    {loading && (
                      <button className="btn pro-spaces-button3" disabled>
                        REGISTER &nbsp;
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          /> */}
        </form>
      </div>
      <div>
        <Footer />
      </div>

      <Modal show={show11} onHide={handleClose11} centered>
        <Modal.Header closeButton>
          <Modal.Title>Enter OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row mt-2">
              <div className="col-12">
                <label className="labels">
                  OTP (sent to registered email address)
                </label>
                <input
                  type="text"
                  placeholder="Enter OTP "
                  required
                  className="form-control"
                  maxLength="5"
                  value={OTP}
                  onChange={(e) => setOTP(e.target.value)}
                />
              </div>
            </div>

            <div className="pt-4 ">
              {pop ? (
                <div className="mb-3 text-center">
                  <Timer minutes={2} seconds={59} callback={end} />
                </div>
              ) : (
                ''
              )}

              {pop ? (
                <>
                  <button className="btn pro-spaces-outline my-1 " disabled>
                    Resend OTP ?
                  </button>
                  <br />
                </>
              ) : (
                <>
                  <a
                    id="resendOTP"
                    onClick={(e) => GenerateOTP(e)}
                    className="btn pro-spaces-outline my-1 "
                  >
                    Resend OTP ?{' '}
                    {loading && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </a>
                  <br />
                </>
              )}
            </div>
            <div>
              <p style={{ color: 'red' }}>{Error2 ? Error2 : ''}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn pro-spaces-button3"
            onClick={(e) => {
              Update();
            }}
          >
            Verify
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={showpassword} onHide={handleClose111} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <label className="labels">Old password *</label>
                <div>
                  <input
                    class="form-control passInout12321"
                    placeholder="Enter old Password"
                    aria-describedby="basic-addon2"
                    type={showPassword12 ? 'text' : 'password'}
                    value={NewPassword.oldPassword}
                    onChange={(e) =>
                      setNewPassword({
                        ...NewPassword,
                        oldPassword: e.target.value,
                      })
                    }
                  />
                  &nbsp;
                  <span class="showbutton123321">
                    {showPassword12 ? (
                      <RiEyeFill
                        onClick={(e) => {
                          setShowPassword12(!showPassword12);
                        }}
                      />
                    ) : (
                      <RiEyeOffFill
                        onClick={(e) => {
                          setShowPassword12(!showPassword12);
                        }}
                      />
                    )}
                  </span>
                </div>
              </div>

              <div className="col-md-12">
                <label className="labels">New password *</label>
                <div>
                  <div>
                    <input
                      class="form-control passInout12321"
                      placeholder="Enter new Password"
                      type={showPassword123 ? 'text' : 'password'}
                      value={NewPassword.newPassword}
                      onChange={(e) =>
                        setNewPassword({
                          ...NewPassword,
                          newPassword: e.target.value,
                        })
                      }
                    />

                    <span class="showbutton123321" type="button">
                      {' '}
                      {showPassword123 ? (
                        <RiEyeFill
                          onClick={(e) => {
                            setShowPassword123(!showPassword123);
                          }}
                        />
                      ) : (
                        <RiEyeOffFill
                          onClick={(e) => {
                            setShowPassword123(!showPassword123);
                          }}
                        />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <p style={{ color: 'red' }}>{}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn pro-spaces-button3"
            onClick={(e) => {
              UpdatePassword();
            }}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>

      {/* referralcode modal */}

      <Modal show={showreferral} onHide={handleClosereferral}>
        <Modal.Header centered closeButton></Modal.Header>
        <Modal.Body>
          <div className="footlightfont">
            <div className="pt-3" style={{ lineSpacing: '0' }}>
              • During Registration, Create a Referral Code of your choice which
              will be your FSE Identification at{' '}
              <span
                className="vipar"
                style={{
                  fontSize: '22px',
                  color: '#99d050',
                }}
              >
                {' '}
                Vipar
              </span>{' '}
              <sup class="">®</sup>
            </div>
            <div className="pt-3" style={{ lineSpacing: '0' }}>
              • Your Unique Referral Code is the one with which
              <span
                className="vipar"
                style={{
                  fontSize: '22px',
                  color: '#99d050',
                }}
              >
                {' '}
                Vipar
              </span>{' '}
              <sup class="">®</sup> can identify that it is via your FSE account
              that a Subscription is sold.
            </div>
            <div className="pt-3">
              • It is Mandatory for tracking your sales targets and disbursing
              your Remuneration to you.
            </div>
            <div className="pt-3">
              • Once a Referral Code is created, it is permanent and cannot be
              changed.
            </div>

            <div className="pt-3">
              • Can be a combination of Alphabets, Numbers and Symbols.
            </div>
            <div className="pt-3">
              • Minimum Character length &#8239;: 5<br />
              &nbsp;&nbsp;Maximum Character length : 10
            </div>
            <div className="pt-3">
              • In case you forget your Referral Code, it will be visible to you
              on the ‘Account Details’ page of your profile.
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* referralcode modal */}
    </>
  );
};
