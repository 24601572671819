import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { notify } from '../../../Utility/notify';
import moment from 'moment';
import { Button } from 'react-bootstrap';

export const Televerificationview = () => {
    const [first, setfirst] = useState([]);

    const [falsee, setfalsee] = useState();
    const [vall, setvall] = useState([]);
    const [action, setaction] = useState()
    const [btnswitch, setbtnswitch] = useState()
    useEffect(() => {
      console.log(vall); // Check if vall is correctly updated
      console.log(vall[0]?.Pause);
    }, [vall]);
  
    const [pause, setpause] = useState(true);
    const submit = (e) => {
      const id = e;
      setfalsee((prevFalsee) => {
        if (prevFalsee && prevFalsee.Pause === false) {
          axios
            .post('/api/tele/pausetrue', { pause, id })
            .then((req) => {
              notify('Approval Paused Successfully', true);
            
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            })
            .catch((err) => {
              notify('Something Went Wrong', false);
  
              console.log(err);
            });
          return prevFalsee; // Return previous state if not modifying it
        } else {
          axios
            .post('/api/tele/pausefalse', { pause, id })
            .then((req) => {
              notify('Approval Unpaused Successfully', true);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
              setbtnswitch(req.data)
              
  
            })
            .catch((err) => {
              notify('Something Went Wrong', false);
  
              console.log(err);
            });
          return prevFalsee; // Return previous state if not modifying it
        }
      });
    };
  
  
  
    useEffect(() => {
      console.log(btnswitch)
  
    }, [btnswitch])
    
    useEffect(() => {
      axios
        .get('/api/tele/valueget')
        .then((req) => {
          console.log(req.data);
  
          setvall(req.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }, []);
  
    const getval = (e) => {
      console.log(e);
      var id = e;
      axios
        .post('api/tele/pauseget', { id })
        .then((req) => {
          console.log('zxx');
          console.log(req.data);
          setfalsee(req.data)
          if (req.data.Pause === false) {
            submit(req.data.Login_id);
          } else {
            submit(req.data.Login_id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
  
  
  
    const getactions = (id) =>{
       var id = id
       axios.post("/api/tele/pauseactionget",{id}).then((req)=>{
        console.log("AScxx")
        console.log(req.data)
        setaction(req.data)
        if(req.data.Pause_Action === false){
          submitaction(req.data.Login_id)
        }
        else{
          submitaction(req.data.Login_id)
        }
       }).catch((err)=>{
        console.log(err)
       })
    }
  
    const submitaction = (id) =>{
  var id = id
     setaction((prev)=>{
      if(prev && prev.Pause_Action === false) {
       axios.post("/api/tele/pauseactiontrue",{pause,id}).then((req)=>{
        console.log(req.data)
        notify('Actions Paused Successfully', true);
        console.log(req.data)
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        setbtnswitch(req.data)
  
       }).catch((err)=>{
        console.log(err)
        notify('Something Went Wrong', false);
  
       })
      }
      else{
        axios.post("/api/tele/pauseactionfalse",{pause,id}).then((req)=>{
          console.log(req.data)
          notify('Actions Unpaused Successfully', true);
          console.log(req.data)
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          setbtnswitch(req.data)
        }).catch((err)=>{
          console.log(err)
          notify('Something Went Wrong', false);
  
        })
      }
     })
    }
  
  
    // const renderPauseButton = (params) => {
    //   const rowData = vall.find((item) => item._id === params.row._id);
    //   const pauseStatus = rowData ? rowData.Pause : false;
  
    //   const handleButtonClick = () => {
    //     getval(params.row._id);
    //   };
  
    //   return (
    //     <Button
    //       className="btn btn-warning"
    //       style={{ border: 'none', color: 'white' }}
    //       onClick={handleButtonClick}
    //     >
    //       {pauseStatus ? 'Unpause' : 'Pause'}
    //     </Button>
    //   );
    // };
  
    const columns = [
      { field: 'id', headerName: 'Sl No', width: 50 },
      {
        field: 'Name',
        headerName: 'Name',
        width: 200,
      },
  
      {
        field: 'Email',
        headerName: 'User Name',
        type: 'number',
        width: 200,
      },
      {
        field: 'Change Password',
        headerName: 'Change Password',
        sortable: false,
        width: 150,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => (
          <button
            className="btn pro-spaces-button3"
            data-bs-toggle="modal"
            data-bs-target={'#changepasswordmodal' + (params.row.id - 1)}
            onClick={(e) => {
              console.log(params.row.id);
            }}
          >
            Change Password
          </button>
        ),
      },
  
      {
        field: 'date',
        headerName: 'Created on',
  
        width: 110,
        editable: true,
      },
      {
        field: 'Actions',
        headerName: 'Actions',
        sortable: false,
        width: 150,
        headerAlign: 'center',
        align: 'center',
  
        renderCell: (params) => (
      
          <>
          
          {/* <Button
              className="btn btn-warning"
              style={{ border: 'none', color: 'white' }}
              onClick={(e) => {
                getval(params.row._id);
              }}
            >
              {vall?.some(
                (item) => item?.Login_id == params.row._id && item?.Pause == true
              )
                ? 'Unpause Approval'
                : 'Pause Approval'}
            </Button>
     
            
           
            &nbsp;
           
            <Button
              className="btn btn-warning"
              style={{ border: 'none', color: 'white' }}
              onClick={(e) => {
                getactions(params.row._id);
                console.log(params)
              }}
            >
               {vall?.some(
                (item) => item?.Login_id == params.row._id && item?.Pause_Action == true
              )
                ? 'Unpause Actions'
                : 'Pause Actions'}
            </Button>  */}
            &nbsp;
            &nbsp;
            &nbsp;
              <button
              className="btn btn-danger"
              data-bs-toggle="modal"
              data-bs-target={'#deletemodal' + (params.row.id - 1)}
              onClick={(e) => {
                console.log(params.row.id);
              }}
            >
              Delete
            </button>
          </>
        ),
      },
    ];
  
    useEffect(() => {
      axios
        .get('/api/tele/telegetvalue')
        .then((res) => {
          const newstate = res.data?.map((elem, index) => {
            return {
              ...elem,
              id: index + 1,
              date: moment(elem.Created_on).format('DD-MM-YYYY'),
            };
          });
          console.log(newstate);
          console.log(newstate.date);
          setfirst(newstate);
        })
  
        .catch((err) => {
          console.log(err);
        });
    }, []);
  
    const Deleteee = (id, ind) => {
      console.log(id);
      console.log(ind);
      axios
        .post('/api/tele/accountdelete', { id, ind })
        .then((req) => {
          console.log('req');
          notify('deleted', true);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        })
        .catch((err) => {
          console.log(err);
          notify('Something Went Wrong', false);
        });
    };
  
    const [value, setValue] = useState({
      currentpassword: '',
      changepassword: '',
      confirmpassword: '',
    });
  
    const passwordchange = (id, ind) => {
      console.log(id);
      console.log(ind);
      axios
        .post('/api/tele/passwordchange', { value, id })
        .then((req) => {
          console.log('Asdsa');
          if (req.status == 200) {
            notify('Password Changed', true);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            return notify('Current Password Wrong ', false);
          }
        });
    };
  
    return (
      <>
        <Box sx={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={first}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
          />
        </Box>
        {/* delete */}
  
        {first?.map((elem, index) => {
          console.log(elem);
          return (
            <>
              <div
                class="modal fade"
                id={'deletemodal' + index}
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <button
                        type="button"
                        class="btn-close"
                        id={'btnclose'}
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body text-danger">
                      Are you sure you want to delete ?
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={(e) => {
                          Deleteee(elem._id, elem.id);
                          // setopperins(elem.ind);
                          // setIndex(elem.ind);
                        }}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
  
        {/* delete */}
        {/* change password */}
        {first?.map((elem, index) => {
          console.log(elem);
          return (
            <>
              <div
                class="modal fade"
                id={'changepasswordmodal' + index}
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <button
                        type="button"
                        class="btn-close"
                        id={'btnclose'}
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body ">
                      <div className="">
                        <label className="">Current Password</label>
                        <input
                          className="form-control"
                          type="password"
                          onChange={(e) =>
                            setValue({
                              ...value,
                              currentpassword: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div>
                        <label className="">New Password</label>
                        <input
                          className="form-control"
                          type="password"
                          onChange={(e) => {
                            setValue({
                              ...value,
                              changepassword: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div>
                        <label className="">Confirm New Password</label>
                        <input
                          className="form-control"
                          type="password"
                          onChange={(e) => {
                            setValue({
                              ...value,
                              confirmpassword: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={(e) => {
                          passwordchange(elem._id, elem.id);
                          // setopperins(elem.ind);
                          // setIndex(elem.ind);
                        }}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
  
        {/* change password */}
      </>
    );
}
