import { useImmer } from "use-immer";

export const useDelete = () => {
  const [deleteInfo, setDeleteInfo] = useImmer({
    type: "",
    show: false,
    id: null,
    name: "",
    actionFrom: "",
  });

  const onDelete = (itemInfo) => {
    const { name, type, id, actionFrom } = itemInfo;
    setDeleteInfo((draft) => {
      draft = { show: !draft.show, id, name, type, actionFrom };
      return draft;
    });
  };

  const resetDeleteInfo = () => {
    setDeleteInfo((draft) => {
      draft = { show: false, id: null, name: "", type: "", actionFrom: "" };
      return draft;
    });
  };

  return {
    deleteInfo,
    onDelete,
    resetDeleteInfo,
  };
};
