import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Axios from 'axios';
import { SpinnerBig } from '../../../Utility/spinner';
import Moment from 'moment';
import { TableFooter } from '@mui/material';

export default function Registered_members() {
  const [List, setList] = useState();
  const [SpinnerStatus, setSpinnerStatus] = useState(true);
  useEffect(() => {
    Axios.get('/api/Admin/Listing_Registered_members').then((res) => {
      if (res.data) {
        setList(res.data);
        setSpinnerStatus(false);
      } else {
        setList(null);
        setSpinnerStatus(false);
      }
    });
  }, []);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className=" p-2 border">
      <center>
        <h3>Registered&nbsp;members</h3>
      </center>
      {SpinnerStatus ? <SpinnerBig /> : ''}

      {List ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Account Type</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Created On</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {List.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.Type === 'Active'
                      ? 'Prime'
                      : row.Type === 'Activev2'
                      ? 'Basic'
                      : 'Passive'}
                  </TableCell>
                  <TableCell>{row.Email ? row.Email : '-'}</TableCell>
                  <TableCell>{row.Contact ? row.Contact : '-'}</TableCell>
                  <TableCell>
                    {Moment(row.Created_on).format('DD-MM-YYYY')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={List.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      ) : (
        <center>
          <h1>No Members to show</h1>
        </center>
      )}
    </div>
  );
}
