import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import React, { useEffect, useState,createContext } from "react";
import Axios from "axios";
import { useNavigate, useSearchParams, } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { print_state, print_city, state_arr,s_a } from '../../../../../Utility/stateCity'

import Map from "../../../../Maps/Maps";

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { keyGen } from "../../../../../Utility/helper";
import MapWithSwitch from "../../../../AdminMap/MapWithSwitch";
export let EditContext = createContext()





export default function PoliceStationsEdit() {
    let navigate = useNavigate()

    useEffect(() => {
        // props.Auth()
    }, [])

    useEffect(() => {
        console.log("haiiiii")
        Axios.post('/api/Admin/PoliceStationsEditView', { id })
            .then(res => {
                if (res.status === 200) {
                    console.log('jobView Edit')
                    console.log(res.data)
                    // console.log(Id)
                    // setDetails(res.data);
                    setValue({
                        id: res.data._id,
                        name:res.data.name,
                        phone:res.data.phone,
                        phone2:res.data.phone2,
                        state:res.data.state ,
                        city:res.data.city ,           
                        pincode: res.data.pincode,
                        
                    })
                    if (res.data.AddingLocation) {

                        setlocation({
                            lat:res.data.mapPosition.lat , lng: res.data.mapPosition.lng
                        })
                        
                    }
                    else
                    {
                    getLocation()
                           
                    }

                    setAddingLocation(res.data.AddingLocation)

                  
                    setLoading(false)
                }
            }).catch((err) => {
                console.log(err)
                alert("sonthing went wrong");
                setLoading(false)
                // setValue({
                //     id: "",
                //     name:"",
                //     phone:"",
                //     state:"" ,
                //     city:"" ,
                //     ServiceCharges: "",
                //     bloodgroup:"",
                //     pincode:"",
                //     // locations: res.data.locations,
                // })

            })
    }, [])
    const notify = (message, sab) => {
        if (sab) {
            toast.success(message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            toast.warning(message, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };




    let [searchParams, setSearchParams] = useSearchParams();  /////getting id from the url the id of the jobs id
    var id = searchParams.get("pi")
    // var id = "62b5b8c5b44ac0fe19a83e77"

    // setValue({...value,id:id})
    useEffect(() => {
        console.log(id)
  
    }, [])
    

    const [value, setValue] = useState({
        id: "",
        name: "",
        phone: "",
        phone2: "",
        state: "",
        city: "",  
        pincode: "",  
        location:{
            address:'',
            lat:'',
            log:''
          }
    });





    const [Stateload, setStateload] = useState(true)


    function Cityshow(e) {
        var index = document.getElementById(e.target.id).selectedIndex;
        print_city("City", index);
        setValue({ ...value, state: e.target.value });
      }


    useEffect(() => {
        if (Stateload)
            print_state("State")

        setStateload(false)
    }, [])


    const Statefix = () => {
        const City = document.getElementById('City').value
        const Statee = document.getElementById('State').value
        setValue({ ...value, state: Statee, city: City })
    }



    const [error, seterror] = useState(false)




    const [loading, setloading] = useState(false)

    function up1(e) {

        setloading(true)

        console.log(value)

        if(!value.name)return seterror("Name is missing !");
        if (value.state.length === 0) return seterror("State is missing !");
        if (value.city.length === 0) return seterror("City is missing !");
        if(value.pincode)
        {
        if(value.pincode.toString().length!=6)return seterror(" Enter valid Pin code is missing !");
            
        }

        if (!value.phone) {
            return seterror('Enter your phone number')
        }
        // if (value.phone.toString().length != 10) {
        //     return seterror('Enter a valid mobile number')
        // }
        
        // if(value.bloodgroup.length===0) return seterror("Blood group is missing !")
        var me=false
        if(Location.lat&&Location.lng)
        {
            me=true
        }

        let value1 = {
            mapPosition:Location,
            AddingLocation:me
          }
        Axios.post('/api/Admin/PoliceStationsEdit',{value,value1})
            .then(res => {
                if (res.status == 200) {
                    // console.log('internupdate ')
                    console.log(res.data)
                    // return navigate(`/home/jobseeker_view?k=${id}`)
                    notify("Post updateed successfully",true);
                    // setloading(false)

                    setTimeout(() => {

                        setloading(false)

                        return navigate(`/police_stations`)
                    }, 1000);
                }

            }).catch((err) => {
                console.log(err.response.data)
                notify("Something want wrong",false);


            })
        console.log('first')



    }

    // const [location, setlocation] = useState({ lat: 28.633008623330056, lng: 77.22197631835937 })
    const [location, setlocation] = useState({ lat:null, lng: null })

    const [status, setStatus] = useState(null);
    const [lat, setLat] = useState(28.633008623330056);
    const [lng, setLng] = useState(77.22197631835937);

    const [Location, setLocation] = useState({lat:'',lng:''})
      const [LocationOn, setLocationOn] = useState(false)
    const [mapPosition, setmapPosition] = useState({
        lng: '',
        lat: ''
      })
      const [AddingLocation, setAddingLocation] = useState(false)
      // if (AddingLocation) {
      //   getLocation()
      // }
      const [key, setkey] = useState(keyGen())
      const getLocation = () => {
        setAddingLocation(!AddingLocation)
        if (AddingLocation) {
          return
        }
        if (!navigator.geolocation) {
          setStatus('Geolocation is not supported by your browser');
          alert('Geolocation is not supported by your browser')
    
        } else {
          setStatus('Locating...');
          navigator.geolocation.getCurrentPosition((position) => {
            setStatus(null);
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
            setlocation({ lat: position.coords.latitude, lng: position.coords.longitude })
            console.log(position, position.coords.latitude)
          }, () => {
            setStatus('Unable to retrieve your location');
            alert('Unable to retrieve your location')
    
          });
        }
    
      }







    const [Details, setDetails] = useState(false)



    const [loader, setLoader] = useState(<div id='iooioooo1231312' className='container  d-flex mt-5 pt-5  justify-content-center p-3 p-md-5'><div class="spinner-border text-dark" role="status">
        <span class="visually-hidden">Loading...</span>
    </div></div>)
    const [Loading, setLoading] = useState(true)
    // var id = "625541f375d16debd14ced72"
    useEffect(() => {
        console.log("haiiiii")
        Axios.post('/api/Admin/PoliceStationsEditView', { id })
            .then(res => {
                if (res.status === 200) {
                    console.log('jobView Edit')
                    console.log(res.data)
                    // console.log(Id)
                    // setDetails(res.data);
                    setValue({
                        id: res.data._id,
                        name:res.data.name,
                        phone:res.data.phone,
                        phone2:res.data.phone2,
                        state:res.data.state ,
                        city:res.data.city ,           
                        pincode: res.data.pincode,
                        
                    })
                    setLocation({lat:res.data.mapPosition.lat,lng:res.data.mapPosition.lng})
                    setLocationOn(res.data.AddingLocation)

                    setLoading(false)
                }
            }).catch((err) => {
                console.log(err)
                alert("something went wrong");
                setLoading(false)
                // setValue({
                //     id: "",
                //     name:"",
                //     phone:"",
                //     state:"" ,
                //     city:"" ,
                //     ServiceCharges: "",
                //     bloodgroup:"",
                //     pincode:"",
                //     // locations: res.data.locations,
                // })
                setLocation({lat:'',lng:''})
                setLocationOn()

            })
    }, [])

    const Eyod = (e) => {
        console.log('f')
        console.log(e.target.checked)
        if (e.target.checked) {
            console.log('f1')
            document.getElementById('EndY').classList.add('d-none')
        } else {
            console.log('f2')
            document.getElementById('EndY').classList.remove('d-none')
        }
    }



    function print_state(state_id) {
        // given the id of the <select> tag as function argument, it inserts <option> tags
        var option_str = document.getElementById(state_id);
        option_str.length = 0;
        option_str.options[0] = new Option('Select State', '');
        option_str.selectedIndex = 0;
        for (var i = 0; i < state_arr.length; i++) {
            option_str.options[option_str.length] = new Option(state_arr[i], state_arr[i]);
        }
    }
    function print_city(city_id, city_index) {
        var option_str = document.getElementById(city_id);
        option_str.length = 0;
        option_str.options[0] = new Option('Select City', '');
        option_str.selectedIndex = 0;
        var city_arr = s_a[city_index].split("|");
        for (var i = 0; i < city_arr.length; i++) {
            option_str.options[option_str.length] = new Option(city_arr[i], city_arr[i]);
        }

    }

    const [tri, settri] = useState(false)
    useEffect(() => {

        if (value.state.length >= 1) {
            if (!tri) {
                settri(true)
                var index = state_arr.indexOf(value.state)
                // var index = 2
                print_city('City', index + 1)
                console.log(index)
            }

        }

    }, [value.state])

   

    useEffect(() => {
 print_city('City', document.getElementById('State').selectedIndex)
    }, [value.state])




    return (
        <EditContext.Provider value={{ Edit: true, Location, LocationOn }}  >
        <div className="container pt-5 mt-3">
            <h1> Police Stations Update</h1>
            <div className='row'>
                <div className='col'><label className="labels">Name *</label></div><div className='col'><input className="form-control" type="text" value={value.name}
                    onChange={(e) =>
                        setValue({ ...value, name: e.target.value })
                    }></input></div>
            </div><br />

            <div className='row'>
                <div className='col'> <label className="labels">State *</label></div><div className='col'>
                    <select className="form-select" onChange={(e) => Cityshow(e)} id="State"  value={value.state} />
                </div>
            </div><br />
            <div className='row'>
                <div className='col'> <label className="labels">City *</label></div><div className='col'>
                    <select
                        className="form-select"
                        name="City"
                        value={value.city}
                        id="City"
                        onChange={(e) => Statefix(e)}
                    >
                        <option>Select City</option>
                    </select></div>
            </div><br />
            <div className='row'>
                <div className='col'><label className="labels">PIN Code</label></div><div className='col'><input type="number" className="form-control" value={value.pincode}
                    onChange={(e) =>
                       {if (e.target.value.length == 7) return false; setValue({ ...value, pincode: e.target.value })}
                    }></input></div>
            </div><br />
            <div className='row'>
                <div className='col'>  <label className="labels">Contact Number *</label></div><div className='col'>
                    <input type="number" value={value.phone} className="form-control"
                        onChange={(e) => {   setValue({ ...value, phone: e.target.value }) }
                        }></input></div>
            </div><br />
            <div className='row'>
                <div className='col'>  <label className="labels">Contact Number 2</label></div><div className='col'>
                    <input type="number" value={value.phone2} className="form-control"
                        onChange={(e) => {   setValue({ ...value, phone2: e.target.value }) }
                        }></input></div>
            </div><br />

            <MapWithSwitch Location={Location}  EditContext={EditContext} setLocation={setLocation} LocationOn={LocationOn} setLocationOn={setLocationOn} key={"aouhduahdiuqbduiqderhetjyvq65564"} />


            {/* <div className="list-group-item shadow ">
        <div className="row align-items-center">
          <div className="col">
            <strong className="mb-2">Add location</strong>
          </div>
          <div className="col-auto">

            <FormGroup>
              <FormControlLabel checked={AddingLocation} control={<Switch onChange={(e) => {getLocation()}} />} />
            </FormGroup>
          </div>
        </div>
      </div> */}
      <br></br>
      {/* {
        AddingLocation ?
          <div style={{ height: '400px' }}>
            <Map
              key={key}
              center={location}
              height='300px'
              zoom={10}
              setmapPosition={setmapPosition}
            /></div>
          :
          ""
      } */}

      <br></br>

         



        
            <div className="row">
                <div className="col"><p style={{ "color": 'red' }}>{error ? error : ''}</p></div>
                <div className="col">
                    {!loading && <button className="btn pro-spaces-button3" onClick={(e) => up1(e)}>Update</button>}
                    {loading && <button className="btn btn-success" disabled><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>}

                </div>
            </div>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
        </EditContext.Provider>
    )
}
