import React,{useState,useEffect} from 'react'
import { Link,useSearchParams } from "react-router-dom";
import Axios from 'axios'
import { SpinnerBig } from '../../Utility/spinner';

export const Information_Subcategory_list = () => {
  let [searchParams, setSearchParams] = useSearchParams(); // id of category
  var id = searchParams.get("k")
  const [Ids, setIds] = useState(id.toString())
  console.log(id)
  const [Category, setCategory] = useState([])
  const [Loading, setLoading] = useState(true)
  useEffect(() => {
    
    Axios.post('/api/api/Information_sub_category_only',{id}).then((res)=>{
      if (res.status==200) {
        console.log(res.data)
        setCategory(res.data)
        setLoading(false)
      }
    }).catch((err)=>{
      setLoading(false)

    })
  }, [])
  
  return (
    <div>

      <div className='container'>
        {
          Loading?
          <SpinnerBig/>
          :
          <div className="row ">
            {
             Ids== "64b4ec190b1209dabd8dee80"?  // this is the id of the information category emergency services
              <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">
              <Link
               to={`/Information_medical_store`} 
              className="wgfiuwrgiu">
                <div className="card carrrrbus">
                  <div className="padunnor"><center>MEDICAL STORE<br>
                  </br> </center></div>
                </div>
              </Link>
            </div>
              :""
            }

          {
            Category?
            Category.map((e)=>{
              return <>
          <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-12">
            <Link to={e.sub_sub_cat==false?`/Information_listing?k=${e._id}`:`/information_sub_sub_category?k=${e._id}`} className="wgfiuwrgiu">
              <div className="card carrrrbus">
                <div className="padunnor"><center>{e.sub}<br>
                </br> </center></div>
              </div>
            </Link>
          </div>
              </>
            })

            :<center><h3>Nothing to show !</h3></center>
          }

          
        </div>
        }


      </div>
    </div>
    
  )
}
