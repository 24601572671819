import React, { useState } from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { AiOutlineMenu } from 'react-icons/ai';
import { FiMenu } from 'react-icons/fi';
import { RiHome2Line } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

export const ControlBar = () => {
  return (
    <>
      <div
        className="py-2"
        style={{
          display: 'block',

          backgroundColor: 'white',
        }}
        id=""
      >
        <div className="d-flex justify-content-between px-2">
          <div className="footlightfont ms-2">
            <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
              <span>
                &#8293;
                <RiHome2Line style={{ marginTop: '-4px' }} />
              </span>

              <span className="ps-1">Home</span>
            </Link>
          </div>
          <div className="footlightfont me-4">
            <Link
              to="/sign_in"
              style={{ textDecoration: 'none', color: 'black' }}
            >
              Sign In / Log In&#8239;
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
