import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Modal from 'react-bootstrap/Modal';
import IconButton from '@mui/material/IconButton';
import Axios from 'axios';
import { SpinnerBig } from '../../../Utility/spinner';
import React, { useState, useEffect } from 'react';

const notify = (message, sab) => {
  if (sab) {
    toast.success(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.warning(message, {
      position: 'bottom-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

const UpdateCommissionPercentageSingleAME = ({ id }) => {
  const [value, setValue] = useState({
    Pin: '',
    showPin: false,
    update_commission_percentage: '',
  });
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (prop) => (event) => {
    setValue({ ...value, [prop]: event.target.value });
  };

  const handleClickShowPassword1 = () => {
    setValue({
      ...value,
      showPin: !value.showPin,
    });
  };

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };
  const [SpinLoading, setSpinLoading] = useState(true);
  const get = () => {
    Axios.post('/api/SuperAdmin/get_commission_percentage_single_AME', { id })
      .then((res) => {
        console.log(res.data);
        if (res.status == 200) {
          setSpinLoading(false);
          console.log(res.data.Commission_percentage);
          setValue({
            ...value,
            update_commission_percentage: res.data.Commission_percentage,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    get();
  }, [id]);

  function Update() {
    if (!value.update_commission_percentage)
      return setError('Enter commission percentage!');
    if (!value.Pin) return setError('security pin is missing !');
    setLoading(true);

    Axios.post('/api/SuperAdmin/Update_commission_percentage_single_AME', {
      id,
      value,
    })
      .then((res) => {
        if (res.status === 200) {
          handleClose();
          notify('Updated successfully', true);
          setLoading(false);
          setValue({
            ...value,
            Pin: '',
          });
          handleClose();
          //  props.fetch()
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          notify('Wrong security pin', false);
          setLoading(false);
        } else {
          notify('Something went wrong', false);
          setLoading(false);
        }
      });
  }

  return (
    <div>
      <button className=" btn pro-spaces-button3" onClick={handleShow}>
        Commission percentage
      </button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Commission percentage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControl variant="outlined" className="form-control mt-2">
            <InputLabel htmlFor="outlined-adornment-password">
              Commission percentage
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={'number'}
              value={value.update_commission_percentage}
              onChange={(e) => {
                setValue({
                  ...value,
                  update_commission_percentage: e.target.value,
                });
              }}
              label="Commission percentage"
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control mt-2">
            {/* sx={{ m: 1, width: '25ch' }} */}
            <InputLabel htmlFor="outlined-adornment-password">Pin</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={value.showPin ? 'text' : 'password'}
              value={value.Pin}
              onChange={(e) => {
                if (e.target.value.length === 5) return false;
                setValue({ ...value, Pin: e.target.value });
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword1}
                    onMouseDown={handleMouseDownPassword1}
                    edge="end"
                  >
                    {value.showPin ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="pin"
            />
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <div className="row mt-4">
            <div className="col">
              <p style={{ color: 'red' }}>{error ? error : ''}</p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col d-flex justify-content-end">
              {!loading && (
                <button className="btn pro-spaces-button3" onClick={Update}>
                  Update
                </button>
              )}
              {loading && (
                <button className="btn btn-success" disabled>
                  Updating
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default UpdateCommissionPercentageSingleAME;
