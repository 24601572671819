import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';

export const View_categories = (props) => {
  const [first, setfirst] = useState('');
  useEffect(() => {
    props.Auth1();
    axios
      .get('/api/Free/category', {})
      .then((res) => {
        console.log(res.data);
        setfirst(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [first2, setfirst2] = useState('');

  useEffect(() => {
    axios
      .get('/api/Free/procategory', {})
      .then((res) => {
        console.log(res.data);
        setfirst2(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="card m-2">
        <div className=" pt-3 footlightfont ps-2">
          Mentioned below are the CATEGORIES and SUB CATEGORIES of the two
          classifications.
        </div>
        <div className="pt-3 pb-2 footlightfont boldfont text-center">
          SERVICES
        </div>
        <div className=" footlightfont">
          {first
            ? first.map((element) => {
                return (
                  <>
                    <div>
                      <div className="Categories py-2 mx-2">
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              {element.category}
                            </Accordion.Header>
                            <Accordion.Body>
                              <ul>
                                {element.sub
                                  ? element.sub.map((elm) => {
                                      return (
                                        <li>
                                          <div className="row">
                                            <div className="col-auto">
                                              {elm.sub}
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })
                                  : ''}
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
                  </>
                );
              })
            : ''}
        </div>
        <div className="pt-3 pb-2   footlightfont boldfont text-center">
          PRODUCTS
        </div>
        <div className="footlightfont pb-5">
          {first2
            ? first2.map((element) => {
                // console.log(element.sub)
                return (
                  <>
                    <div>
                      <div className="Categories py-2  mx-2">
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              {element.category}
                            </Accordion.Header>
                            <Accordion.Body>
                              <ul>
                                {element.sub
                                  ? element.sub.map((elm) => {
                                      return (
                                        <li>
                                          <div className="row">
                                            <div className="col-auto">
                                              {elm.sub}
                                            </div>
                                          </div>
                                        </li>
                                      );
                                    })
                                  : ''}
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
                  </>
                );
              })
            : ''}
        </div>
      </div>
    </>
  );
};
