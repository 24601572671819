import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

export const Notification = () => {
  const [first, setFirst] = useState();

  const formatDate = (dateString) => {
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    const formattedDate = moment(dateString); // No need to specify the format for ISO 8601

    if (formattedDate.isSame(today, 'day')) {
      return 'Today';
    } else if (formattedDate.isSame(yesterday, 'day')) {
      return 'Yesterday';
    } else {
      return formattedDate.format('DD-MM-YYYY');
    }
  };
  useEffect(() => {
    axios
      .get('/api/admin/viewnotification')
      .then((req) => {
        console.log('Assas');
        console.log(req.data);
        setFirst(req.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="d-flex justify-content-center pb-2">
        <div className="card ">
          <div className="card-body">
            Notifications :{' '}
            {first?.length ? <span>{first?.length}</span> : <span>0</span>}
          </div>
        </div>
      </div>
      {first?.length ? (
        <>
          {first
            ?.slice()
            .reverse()
            .map((elem, index) => {
              const formattedDate = formatDate(elem.Created_on);

              return (
                <>
                  <div className="card" style={{ width: '100%' }} key={elem.id}>
                    <div className="card-body">
                      <div className="fw-bold">{elem.Subject}</div>
                      <div className="pt-1">{elem.Remark}</div>
                      <div className="d-flex justify-content-end text-secondary">
                        {formattedDate}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </>
      ) : (
        <>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: '100vh', fontWeight: 'bold' }}
          >
            Nothing to Show
          </div>
        </>
      )}
    </>
  );
};
