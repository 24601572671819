import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NewConsultantCategory from './NewConsulitantCategory/NewConsultantCategory';
import NewVendorCategory from './NewVendorCategory/NewVendorCategory';

export default function Categorys() {
  const [LogOp, setLogOp] = useState(true);

  return (
    <div>
      <div className="container ">
        {/* <div className="row slhishieo3jfiehfjefbewf">
        <div className="col-lg-6 col-md-6 col-sm-12">

        <Link to="/consultantscategory"  className="wgfiuwrgiu">

          <div className="card carrrrbus">
            
            
              <div className="padunnor"><center>Consultants Category<br>

              </br> </center></div>

          </div>
          </Link>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">

        <Link to="/vendorcategory"  className="wgfiuwrgiu">

          <div className="card carrrrbus">
          
           
              <div className="padunnor"><center>Vendor Category<br>
              
              </br></center></div>
            

          </div>
          </Link>
        </div>

      </div> */}
        <center>
          <div className="row p-0 m-0 mb-3 ">
            <div className="col-6 p-0">
              <input
                type="button"
                style={{ borderRadius: '0px' }}
                className={`btn pro-spaces-outline w-100 ${
                  LogOp ? 'RActive' : ''
                }`}
                onClick={(e) => setLogOp(true)}
                value="Service Provider"
                name="btnradio"
                id="Emd2"
                autocomplete="off"
              />
            </div>
            <div className="col-6 p-0">
              <input
                type="button"
                style={{ borderRadius: '0px' }}
                className={`btn pro-spaces-outline w-100 px-0 ${
                  !LogOp ? 'RActive' : ''
                }`}
                onClick={(e) => setLogOp(false)}
                value="Product Retailer"
                name="btnradio"
                id="Mbn2"
                autocomplete="off"
              />
            </div>
          </div>
        </center>
        {LogOp ? (
          <NewConsultantCategory LogOp={LogOp} />
        ) : (
          <NewVendorCategory LogOp={LogOp} />
        )}
      </div>
    </div>
  );
}
