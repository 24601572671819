import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PassiveMemberList.css';
import Axios from 'axios';
import Moment from 'moment';
import { Button, Spinner } from 'react-bootstrap';
import { RiWhatsappFill } from 'react-icons/ri';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SpinnerBig } from '../../../Utility/spinner';

function ConsultantsList(props) {
  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  // console.log(props.data.product._id)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (id) => {
    console.log(id);
    setNotification({ ...Notification, Account_id: id });
    setShow1(true);
  };

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [verifyornot, setverifyornot] = useState();
  useEffect(() => {
    check(props.data.product._id);
  }, []);
  const check = (id) => {
    try {
      Axios.post('/api/Admin/VerifyOrNotConsultants', { id })
        .then((response) => {
          // console.log("success");
          if (response.status === 200) {
            // console.log("first");
            setverifyornot(response.data);
          }
        })
        .catch((res) => {
          console.log('Something went wrong');
        });
    } catch (er) {}
  };

  const [expdate1, setexpdate1] = useState();

  const verify = (id) => {
    console.log(id);

    //   return
    Axios.post('/api/Admin/verifyConsultants', { id })
      .then((res) => {
        if (res.status == 200) {
          // console.log('product verified')
          // check(id);
          check(id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const notverify = (id) => {
    console.log(id);

    //   return
    Axios.post('/api/Admin/notverifyConsultants', { id })
      .then((res) => {
        if (res.status == 200) {
          console.log('product verified');
          check(id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [Location_Coordinates, setLocation_Coordinates] = useState({
    lat: '',
    lng: '',
  });
  const [Created_on, setCreated_on] = useState('');
  useEffect(() => {
    setCreated_on(Moment(props.data.product.Created_on).format('DD-MM-YYYY'));

    setexpdate1(Moment(props.data.product.Validity).format('DD-MM-YYYY'));
    // console.log(props.data.product.Location_Coordinates?.lat)
    setLocation_Coordinates({
      ...Location_Coordinates,
      lat: props.data.product.Location
        ? props.data.product.Location_Coordinates?.lat
        : '',
      lng: props.data.product.Location
        ? props.data.product.Location_Coordinates?.lng
        : '',
    });
  }, [props]);
  const [Error, setError] = useState('');
  const update = () => {
    if (!Location_Coordinates.lat.length > 0) {
      return setError('Enter latitude');
    }
    if (!Location_Coordinates.lat.length > 0) {
      return setError('Enter longitude');
    }
    setError('');
    var data = {
      id: props.data.product._id,
      Location_Coordinates,
      Location: true,
    };

    Axios.post('/api/Admin/Update_Location_By_Admin', { data })
      .then((res) => {
        if (res.status == 200) {
          console.log('hai');
          handleClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [SendMailLoading, setSendMailLoading] = useState(false);
  const SendMail = () => {
    var loginId = props.data.product.Login_id;
    var accountId = props.data.product._id;
    var data = {
      loginId,
      accountId,
    };
    setSendMailLoading(true);
    Axios.post('/api/Admin/ResendMailInvoice', { data })
      .then((res) => {
        if (res.status == 200) {
          console.log('mail send');
          setSendMailLoading(false);
          mailCount();
          // props?.fetchData()
        }
      })
      .catch((err) => {
        setSendMailLoading(false);
        console.log(err);
      });
  };
  const [CountMail, setCountMail] = useState('');
  // useEffect(() => {
  //   mailCount()
  // }, [])
  const mailCount = () => {
    var loginId = props.data.product.Login_id;
    var accountId = props.data.product._id;
    var data = {
      loginId,
      accountId,
    };
    Axios.post('/api/Admin/MailInvoiceCount', { data })
      .then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          setCountMail(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [Notification, setNotification] = useState({
    Account_id: '',
    message: '',
  });
  const [Status_1, setStatus_1] = useState(false);
  const SendNotification = () => {
    // console.log(Notification)
    // return
    if (!Notification.message.length > 0) {
      return setError('Enter Message');
    }
    setError('');
    setStatus_1(true);
    Axios.post('/api/SuperAdmin/SendNotificationIndividual', { Notification })
      .then((res) => {
        if (res.status == 200) {
          console.log('hai');
          handleClose1();
          notify('Notification send successfully', true);
          setNotification({
            Account_id: '',
            message: '',
          });
          setStatus_1(false);
        }
      })
      .catch((err) => {
        console.log(err);
        notify('Something went wrong', false);
        setStatus_1(false);
      });
  };
  const [AdminDetails, setAdminDetails] = useState({});
  const [AdminDetailsLoading, setAdminDetailsLoading] = useState(false);

  const OpenAdminDetails = (NOTES) => {
    handleShow2();
    var id = NOTES.adminId;
    setAdminDetailsLoading(true);
    // return
    Axios.post('/api/SuperAdmin/GetAdminDetailsFromList', { id })
      .then((res) => {
        console.log(res.data);
        setAdminDetails(res.data);
        setAdminDetailsLoading(false);
      })
      .catch((err) => {
        notify('Something went wrong', false);
        setAdminDetailsLoading(false);
        handleClose2();
      });
  };

  return (
    <>
      <tr className="tableBody" scope="row">
        <th scope="row">{props.data.index + 1}</th>
        <th>
          {props.data.product.pname ? props.data.product.pname : '-'}&nbsp;
        </th>
        <th>
          {props.data.product.Type == 'Consultant'
            ? 'Prime'
            : props.data.product.Type == 'Consultantv2'
            ? 'Elite'
            : 'Basic'}
          &nbsp;
        </th>

        <td>
          {props.data.product.login[0]
            ? props.data.product.login[0].Contact
            : '-'}
        </td>
        <td>
          {props.data.product.login[0]?.Email
            ? props.data.product.login[0].Email
            : '-'}
        </td>
        <th>{props.data.product.city ? props.data.product.city : '-'}&nbsp;</th>
        <td>{expdate1}</td>
        <td style={{ whiteSpace: 'nowrap' }}>
          {props.data.product.login[0]?.DirectLink ? (
            'Direct link'
          ) : props.data.product.login[0]?.NOTES?.createdByAdmin ? (
            <Link
              to={''}
              onClick={(e) =>
                OpenAdminDetails(props.data.product.login[0]?.NOTES)
              }
              style={{ textDecoration: 'none', color: 'black' }}
            >
              Created by Admin
            </Link>
          ) : (
            'Self'
          )}
        </td>
        <td>{Created_on}</td>
        <td>
          {props.data.product.invoice_mail_count || CountMail > 0
            ? CountMail > 0
              ? CountMail
              : props.data.product?.invoice_mail_count
            : '0'}
        </td>
        <td>
          {props.data.product.Type != 'Consultantv3' ? (
            verifyornot ? (
              verifyornot.verified ? (
                <button
                  className="btn btn-success w-100"
                  type="button"
                  onClick={() => notverify(props.data.product._id)}
                  style={{ textTransform: 'unset' }}
                >
                  Verified
                </button>
              ) : (
                <button
                  className="btn btn-primary w-100"
                  type="button"
                  onClick={() => verify(props.data.product._id)}
                  style={{ textTransform: 'unset' }}
                >
                  Verify
                </button>
              )
            ) : (
              <button
                className="btn btn-primary w-100"
                type="button"
                onClick={() => verify(props.data.product._id)}
              >
                Verify
              </button>
            )
          ) : (
            ''
          )}
        </td>
        {/* Profile Editing */}
        <td>
          {/* {
      props.data.product.Type=="Consultant"? 
      <Link to={`/update_service_provider_prime?k=${props.data.product._id}`} >
      <button className="btn btn-primary  w-100" type="button" style={{"textTransform":"unset","whiteSpace":"nowrap"}}>
      Edit Profile
      </button>
      </Link>
      :props.data.product.Type=="Consultantv2"? 
      <Link to={`/update_service_provider_basic?k=${props.data.product._id}`} >
      <button className="btn btn-primary  w-100" type="button" style={{"textTransform":"unset","whiteSpace":"nowrap"}}>
      Edit Profile
      </button>
      </Link>
      :
      <Link to={`/update_service_provider_static?k=${props.data.product._id}`} >
      <button className="btn btn-primary  w-100" type="button" style={{"textTransform":"unset","whiteSpace":"nowrap"}}>
      Edit Profile
      </button>
      </Link>
       } */}

          <div class="dropdown">
            <button
              class="btn btn-primary dropdown-toggle"
              type="button"
              id={`dropdownMenuButton11${props.data.product._id}`}
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Edit
            </button>
            <ul
              class="dropdown-menu"
              aria-labelledby={`dropdownMenuButton11${props.data.product._id}`}
            >
              <li>
                <span class="dropdown-item" style={{ textDecoration: 'none' }}>
                  {props.data.product.Type == 'Consultant' ? (
                    <Link
                      to={`/update_service_provider_prime?k=${props.data.product._id}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <button className="btn pro-spaces-button3 w-100">
                        Edit Profile
                      </button>
                    </Link>
                  ) : props.data.product.Type == 'Consultantv2' ? (
                    <Link
                      to={`/update_service_provider_basic?k=${props.data.product._id}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <button className="btn pro-spaces-button3 w-100">
                        Edit Profile
                      </button>
                    </Link>
                  ) : (
                    <Link
                      to={`/update_service_provider_static?k=${props.data.product._id}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <button className="btn pro-spaces-button3 w-100">
                        Edit Profile
                      </button>
                    </Link>
                  )}
                </span>
              </li>
              <li>
                <span class="dropdown-item">
                  <button
                    className="btn pro-spaces-button3"
                    onClick={() =>
                      props.UpdatePaymentAndValidityGet(
                        props.data.product._id,
                        props.data.product.Login_id
                      )
                    }
                  >
                    Edit Payment and Validity
                  </button>
                </span>
              </li>
            </ul>
          </div>
        </td>
        {/* Profile Editing End */}
        {/* <td>
      <button className="btn btn-primary  w-100" type="button" style={{"textTransform":"unset","whiteSpace":"nowrap"}}>
       Add / Edit Location
      </button>
      </td> */}
        <td>
          {props.data.product.Priority == 'Primary' ? (
            <>
              {SendMailLoading ? (
                <button
                  className="btn btn-info  w-100"
                  type="button"
                  disabled
                  variant="info"
                  style={{ textTransform: 'unset', whiteSpace: 'nowrap' }}
                >
                  Send Invoice mail
                </button>
              ) : (
                <button
                  className="btn btn-info  w-100"
                  type="button"
                  onClick={SendMail}
                  style={{ textTransform: 'unset', whiteSpace: 'nowrap' }}
                >
                  Send Invoice mail
                </button>
              )}
            </>
          ) : (
            ''
          )}
        </td>

        <td>
          <button
            className="btn pro-spaces-button3"
            onClick={(e) => handleShow1(props.data.product._id)}
          >
            Send Notification
          </button>
        </td>
        <td>
          {props.data.product?.login[0]?.Contact ? (
            <>
              <a
                target="_blank"
                href={`https://wa.me/${
                  props.data.product?.login[0]?.Contact.length == 10
                    ? '+91' + props.data.product?.login[0]?.Contact
                    : props.data.product?.login[0]?.Contact
                } `}
                className="btn "
                style={{ border: 'none' }}
              >
                <RiWhatsappFill size={30} color="green" />
              </a>
            </>
          ) : (
            ''
          )}
        </td>
      </tr>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add / Edit Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {Location_Coordinates.toString()} */}
          <div className="row">
            <div className="col-12">
              <label className="labels">Latitude</label>
              &nbsp;&nbsp;&nbsp;
              <input
                type="text"
                value={Location_Coordinates.lat}
                className="form-control "
                id="firstPoss"
                onChange={(e) =>
                  setLocation_Coordinates({
                    ...Location_Coordinates,
                    lat: e.target.value,
                  })
                }
              ></input>
            </div>
            <div className="col-12">
              <label className="labels">Longitude</label>
              &nbsp;&nbsp;&nbsp;
              <input
                type="text"
                value={Location_Coordinates.lng}
                className="form-control "
                id="firstPoss3"
                onChange={(e) =>
                  setLocation_Coordinates({
                    ...Location_Coordinates,
                    lng: e.target.value,
                  })
                }
              ></input>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={update}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show1}
        onHide={handleClose1}
        centered
        style={{ zIndex: 6000 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {Location_Coordinates.toString()} */}
          <div className="row">
            <div className="col-12">
              <label className="labels">Enter message</label>
              &nbsp;&nbsp;&nbsp;
              <textarea
                type="text"
                value={Notification.message}
                className="form-control "
                id="firstPoss"
                onChange={(e) =>
                  setNotification({ ...Notification, message: e.target.value })
                }
              ></textarea>
            </div>
          </div>
          <br></br>
          {Error ? <p style={{ color: 'red' }}>{Error}</p> : ''}
        </Modal.Body>
        <Modal.Footer>
          {Status_1 ? (
            <Button variant="primary" disabled>
              <Spinner animation="border" size="sm" />
              &nbsp;Send
            </Button>
          ) : (
            <Button variant="primary" onClick={SendNotification}>
              Send
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={show2}
        onHide={handleClose2}
        centered
        style={{ zIndex: 6000 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Admin Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {AdminDetailsLoading ? (
            <SpinnerBig />
          ) : (
            <>
              {AdminDetails[0]?.Type === 'SUPERADMIN' ? (
                <center>
                  <h5>Created by super admin</h5>
                </center>
              ) : (
                <>
                  <div className="row">
                    <div className="col-4">Name</div>
                    <div className="col-1">:</div>
                    <div className="col">
                      {AdminDetails[0]?.AdminData[0]?.Name}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">State</div>
                    <div className="col-1">:</div>
                    <div className="col">
                      {AdminDetails[0]?.AdminData[0]?.state}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">City</div>
                    <div className="col-1">:</div>
                    <div className="col">
                      {AdminDetails[0]?.AdminData[0]?.city}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">Email</div>
                    <div className="col-1">:</div>
                    <div className="col">{AdminDetails[0]?.Email}</div>
                  </div>
                  <div className="row">
                    <div className="col-4">Contact</div>
                    <div className="col-1">:</div>
                    <div className="col">{AdminDetails[0]?.Contact}</div>
                  </div>
                </>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default ConsultantsList;
