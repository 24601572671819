import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useDceTable } from './hooks';
import { Loading, ModalLayout } from 'shared';
import { RiWhatsappFill } from 'react-icons/ri';
import { Modal } from 'react-bootstrap';
import { notify } from 'Utility/notify';
import { TiTick } from 'react-icons/ti';
import { Row } from 'antd';
export const Data_entry_formTable = ({ admin, type }) => {
  let navigate = useNavigate();


  const {
    serviceCatagory,
    productCatagory,
    subCatagory,
    filtervalue,
    adminList,
    findUD,
    deleteUser,
    data,
    count,
    page,
    rowsPerPage,
    isLoading,
    deleteInfo,
    onDelete,
    resetDeleteInfo,
    onFilterChange,
    clearCatagory,
    clearSubcategory,
    resetFilter,
    handleChangePage,
    handleChangeRowsPerPage,
    setFilter,
    setPage,
    verifyUserAuth,
    findUDQuey
  } = useDceTable({ load: true });

  const onConfirm = () => {
    if (deleteInfo.actionFrom === 'deleteUser') {
      deleteUser.mutate(deleteInfo.id);
    }
  };

  const onVerify = (value, name, id) => {
    verifyUserAuth.mutate({ value, name, id });
  };

  if (isLoading) {
    return <Loading size="lg" />;
  }

  const checkTest = (a, b) => {
    const aa = a.endsWith('v3')
      ? 'BASIC'
      : a.endsWith('v2')
        ? 'ELITE'
        : a === 'Active'
          ? 'PRIME'
          : ''

    const bb = b.endsWith('v3')
      ? 'BASIC'
      : b.endsWith('v2')
        ? 'ELITE'
        : 'PRIME'
    if (aa == bb) {
      return <span>✅</span>;
    } else {
      console.log(aa, bb);
      return <span>❌</span>;
    }
  }

  return (
    <>
      <Paper>
        <center>
          <div className="container border py-2 px-1 row">
            <div className="col-md-4 col-sm-12 lable-left">
              {' '}
              <label className="labels">From</label>
              <input
                type="date"
                className="form-control"
                value={filtervalue.from}
                onChange={(e) => onFilterChange(e.target.value, 'from')}
              />
            </div>
            <div className="col-md-4 col-sm-12 lable-left">
              {' '}
              <label className="labels">To</label>
              <input
                type="date"
                min={filtervalue.from}
                className="form-control"
                value={filtervalue.to}
                onChange={(e) => onFilterChange(e.target.value, 'to')}
              />
            </div>
            <div className="col-md-4 col-sm-12 lable-left">
              {' '}
              <label className="labels">Profile Creation Status</label>
              <select
                value={filtervalue.status}
                onChange={(e) => onFilterChange(e.target.value, 'status')}
                className="form-control"
              >
                <option value={''}>Select</option>
                <option value={'Approved'}>Approved</option>
                <option value={'Approve'}>Pending</option>
              </select>
            </div>
            <div className="col-md-4 col-sm-12 lable-left">
              {' '}
              <label className="labels">
                Verification Status (Contact Number)
              </label>
              <select
                value={filtervalue.verifyCN}
                onChange={(e) => onFilterChange(e.target.value, 'verifyCN')}
                className="form-control"
              >
                <option value={''}>Select</option>
                <option value={true}>Verified</option>
                <option value={false}>Pending</option>
              </select>
            </div>
            <div className="col-md-4 col-sm-12 lable-left">
              {' '}
              <label className="labels">
                Verification Status (Sub-category)
              </label>
              <select
                value={filtervalue.verifySC}
                onChange={(e) => onFilterChange(e.target.value, 'verifySC')}
                className="form-control"
              >
                <option value={''}>Select</option>
                <option value={true}>Verified</option>
                <option value={false}>Pending</option>
              </select>
            </div>

            <div className="col-md-4 col-sm-12 lable-left">
              <label className="labels">Profile Created By</label>
              <select
                required
                id={'subcatananda83389'}
                className="form-select"
                value={filtervalue.executive}
                onChange={(e) => onFilterChange(e.target.value, 'executive')}
              >
                <option selected></option>
                {adminList?.map((elem) => (
                  <option value={elem.value}>{elem.label}</option>
                ))}
              </select>
            </div>
            <div className="col-md-4 col-sm-12 lable-left">
              {' '}
              <label className="labels">Classification</label>
              <select
                value={filtervalue.classification}
                onChange={(e) => {
                  onFilterChange(e.target.value, 'classification');
                  clearCatagory();
                  clearSubcategory();
                }}
                className="form-control"
              >
                <option value={''}>Select</option>
                <option value={'Consultant'}>SERVICES</option>
                <option value={'Product'}>PRODUCTS</option>
              </select>
            </div>
            <div className="col-md-4 col-sm-12 lable-left">
              <label className="labels">Category </label>
              <select
                required
                className="form-select"
                value={filtervalue?.category}
                disabled={!filtervalue.classification}
                onChange={(e) => {
                  onFilterChange(e.target.value, 'category');
                  clearSubcategory();
                }}
              >
                <option value="" disabled selected></option>
                {filtervalue.classification === 'Consultant'
                  ? serviceCatagory?.map((e) => {
                    return <option value={e?.value}>{e?.label}</option>;
                  })
                  : productCatagory?.map((e) => {
                    return <option value={e?.value}>{e?.label}</option>;
                  })}
              </select>
            </div>
            <div className="col-md-4 col-sm-12 lable-left">
              <label className="labels">Sub Category </label>
              <select
                required
                id={'subcatananda83389'}
                className="form-select"
                value={filtervalue?.subcategory}
                disabled={!filtervalue.category}
                onChange={(e) => onFilterChange(e.target.value, 'subcategory')}
              >
                <option></option>
                {subCatagory?.map((e) => {
                  return <option value={e?.value}>{e?.label}</option>;
                })}
              </select>
            </div>
            <div className="col-md-4 col-sm-12 lable-left">
              {' '}
              <label className="labels">Country</label>
              <input
                value={filtervalue.country}
                onChange={(e) => onFilterChange(e.target.value, 'country')}
                className="form-control"
              ></input>
            </div>
            <div className="col-md-4 col-sm-12 lable-left">
              {' '}
              <label className="labels">State</label>
              <input
                value={filtervalue.state}
                onChange={(e) => onFilterChange(e.target.value, 'state')}
                className="form-control"
              ></input>
            </div>
            <div className="col-md-4 col-sm-12 lable-left">
              {' '}
              <label className="labels">City</label>
              <input
                value={filtervalue.city}
                onChange={(e) => onFilterChange(e.target.value, 'city')}
                className="form-control"
              ></input>
            </div>
            <div className="col-md-4 col-sm-12 lable-left">
              {' '}
              <label className="labels">Locality</label>
              <input
                value={filtervalue.address1}
                onChange={(e) => onFilterChange(e.target.value, 'address1')}
                className="form-control"
              ></input>
            </div>
            <div className="col-md-4 col-sm-12 lable-left">
              {' '}
              <label className="labels">Membership Type</label>
              <select
                value={filtervalue.membership}
                onChange={(e) => onFilterChange(e.target.value, 'membership')}
                className="form-control"
              >
                <option value={''}>Select</option>
                <option value={'Activev3'}>BASIC</option>
                <option value={'Active'}>PRIME</option>
                <option value={'Activev2'}>ELITE</option>
              </select>
            </div>

            <div className="col-md-4 col-sm-12 lable-left">
              {' '}
              <label className="labels">Profile Display Name</label>
              <input
                value={filtervalue.pname}
                onChange={(e) => onFilterChange(e.target.value, 'pname')}
                className="form-control"
              ></input>
            </div>

            <div className="d-flex justify-content-end p-2">
              <div>
                <button
                  className="btn btn-outline-success"
                  onClick={() => {
                    resetFilter();
                    setFilter((draft) => {
                      draft.filters = null;
                      draft.skip = 0;
                      draft.limit = rowsPerPage;
                      return draft;
                    });
                  }}
                >
                  Clear
                </button>
                <button
                  className="btn btn-success mx-1"
                  onClick={() => {
                    setPage(0);
                    setFilter((draft) => {
                      draft.filters = filtervalue;
                      draft.skip = 0;
                      draft.limit = rowsPerPage;
                      return draft;
                    });
                  }}
                >
                  Go
                </button>
              </div>
            </div>
          </div>
        </center>
        <TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 750, 1000]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>SL&nbsp;NO.</TableCell>
                <TableCell>REMARKS</TableCell>

                <TableCell>STATUS</TableCell>

                <TableCell>PROFILE&nbsp;DISPLAY&nbsp;NAME</TableCell>
                <TableCell>CONTACT&nbsp;NUMBER</TableCell>
                <TableCell>
                  <div className="d-flex">
                    <TiTick color="green" size={25} />
                    (CN)
                  </div>
                </TableCell>
                <TableCell>LOGIN ID</TableCell>
                <TableCell>CLASSIFICATION</TableCell>
                <TableCell>CATEGORY</TableCell>
                <TableCell>SUB-CATEGORY</TableCell>
                <TableCell>
                  <div className="d-flex">
                    <TiTick color="green" size={25} />
                    (SC)
                  </div>
                </TableCell>

                <TableCell>MEMBERSHIP TYPE</TableCell>
                <TableCell>CITY</TableCell>
                <TableCell>LOCALITY</TableCell>

                <TableCell>ACTIONS</TableCell>
                <TableCell>CREATED&nbsp;ON</TableCell>
                <TableCell>CREATED&nbsp;BY</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row, index) => {
                let {
                  UserData,
                  EmailLogin,
                  Email,
                  Contact,
                  CategoryData,
                  SubCategoryData,
                  ExecUser,
                  Created_on,
                  _id,
                } = row;
                return (
                  <TableRow key={row._id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>
                      <div className="d-flex">
                        <SendRemark row={row} log_id={_id} admin={admin} />
                      </div>
                    </TableCell>
                    <TableCell>
                      {admin && (
                        <ActionButton
                          value={row}
                          findUD={findUDQuey?.data}
                        ></ActionButton>
                      )}
                    </TableCell>
                    <TableCell>{UserData?.[0]?.pname ?? ''}</TableCell>
                    <TableCell>{UserData?.[0]?.contact ?? ''}</TableCell>
                    <TableCell>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          style={{
                            backgroundColor: UserData?.[0]?.verifyCN
                              ? 'green'
                              : '',
                            borderColor: UserData?.[0]?.verifyCN ? 'green' : '',
                          }}
                          checked={UserData?.[0]?.verifyCN ?? false}
                          onChange={(e) =>
                            onVerify(
                              e.target.checked,
                              'verifyCN',
                              UserData?.[0]?._id
                            )
                          }
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      {EmailLogin ? Email ?? '' : Contact ?? ''}
                    </TableCell>
                    <TableCell>
                      {UserData?.[0]?.Type?.startsWith('C')
                        ? 'SERVICES'
                        : UserData?.[0]?.Type?.startsWith('P')
                          ? 'PRODUCTS'
                          : '-'}
                    </TableCell>
                    <TableCell>{CategoryData?.[0]?.category}</TableCell>
                    <TableCell>{SubCategoryData?.[0]?.sub}</TableCell>
                    <TableCell>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          checked={UserData?.[0]?.verifySC ?? false}
                          style={{
                            backgroundColor: UserData?.[0]?.verifySC
                              ? 'green'
                              : '',
                            borderColor: UserData?.[0]?.verifySC ? 'green' : '',
                          }}
                          onChange={(e) =>
                            onVerify(
                              e.target.checked,
                              'verifySC',
                              UserData?.[0]?._id
                            )
                          }
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      {/* {checkTest(row?.Type, row?.UserData[0]?.Type)} */}
                      {row?.Type?.endsWith('v3')
                        ? 'BASIC '
                        : row?.Type?.endsWith('v2')
                          ? 'ELITE '
                          : row?.Type === 'Active'
                            ? 'PRIME '
                            : ''}
                      {/* ✅:-&nbsp;{row?.UserData[0]?.Type?.endsWith('v3')
                        ? 'BASIC'
                        : row?.UserData[0]?.Type?.endsWith('v2')
                          ? 'ELITE'
                          : 'PRIME'
                      } */}
                    </TableCell>
                    <TableCell>{UserData?.[0]?.city ?? ''}</TableCell>
                    <TableCell>{UserData?.[0]?.address1 ?? ''}</TableCell>

                    <TableCell>
                      <div className="d-flex">
                        <div class="dropdown">
                          <button
                          disabled={(findUDQuey?.data?.Role === 'CLERICALADMIN' && findUDQuey?.data?.Pause_Action === true)}
                            class="btn btn-primary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Actions
                          </button>
                          <ul
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li className="pt-2 text-center">
                              <button
                                className="btn pro-spaces-button-semi mx-1"
                                onClick={() => {
                                  navigate('/DCE_data_entry', {
                                    state: { accountID: row._id, admin },
                                  });
                                }}
                              >
                                {admin ? 'EDIT' : 'VIEW'}
                              </button>
                            </li>
                            <li className="pt-2 text-center">
                              {admin && (
                                <>
                                  {EmailLogin ? (
                                    Email ? (
                                      <Sendanemail log_id={_id} />
                                    ) : (
                                      ''
                                    )
                                  ) : Contact ? (
                                    <div className="text-center">
                                      <a
                                        target="_blank"
                                        href={`https://wa.me/${Contact.length == 10
                                          ? '+91' + Contact
                                          : Contact
                                          } `}
                                        className="btn "
                                        style={{ border: 'none' }}
                                      >
                                        <button className="btn pro-spaces-button3">
                                          NOTIFY
                                        </button>
                                      </a>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </>
                              )}
                            </li>
                            <li className="pt-2 text-center">
                              {admin && (
                                <button
                                  className="btn btn-danger mx-1"
                                  onClick={() => {
                                    onDelete({
                                      name: UserData?.[0]?.pname ?? '',
                                      id: row._id,
                                      actionFrom: 'deleteUser',
                                    });
                                  }}
                                >
                                  DELETE
                                </button>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      {moment(Created_on).format('DD-MM-YYYY')}
                    </TableCell>
                    <TableCell>{ExecUser?.[0]?.Name}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 250, 500, 750, 1000]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {deleteInfo.show && (
        <ModalLayout
          isOpen={deleteInfo.show}
          title={'Confirm'}
          message={`Are you sure? Do you want to delete ${deleteInfo.name}?`}
          onConfirm={() => onConfirm()}
          onCancel={resetDeleteInfo}
        />
      )}
    </>
  );
};

const ActionButton = ({ value, status, findUD }) => {
  console.log(findUD)
  const [Loadingg, setLoadingg] = useState(false);
  const [state, setstate] = useState(value.UserData[0]?.adminAproved);

  const action = async () => {
    try {
      let res = await axios.post('/api/User/decAction', {
        account: value.UserData[0]._id,
        status: !state,
      });
      setstate((prev) => !prev);
      setLoadingg(false);
    } catch (error) {
      setLoadingg(false);
    }
  };

  if (Loadingg) {
    return (
      <Typography>
        <Loading size="sm" />
      </Typography>
    );
  } else {
    return (
      <>

        <button
          className={state ? 'btn btn-success mx-1' : 'btn btn-primary mx-1'}
          onClick={action}
          disabled={(findUD?.Role === 'CLERICALADMIN' && findUD?.Pause === true)}
        >
          {state ? 'Approved' : 'Pending'}
        </button>

      </>
    );
  }
};

//sendemail
const Sendanemail = ({ log_id, Acc_id }) => {
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [state, setState] = useState({
    Subject: '',
    Message: '',
    Acc_id: Acc_id,
    log_id: log_id,
  });

  const send = () => {
    if (validate(state) === true) {
      axios
        .post('/api/Admin/Sendmail_ame_sme', { state })
        .then((res) => {
          handleClose1();
          notify('Notification Sent Successfully', true);
        })
        .catch((err) => {
          console.log(err);
          notify('Something Went Wrong', false);
        });
    }
  };

  const [err, setErr] = useState(false);

  const validate = (state) => {
    if (state?.Subject?.length === 0) {
      return setErr('PLEASE ENTER Mail Subject');
    }
    if (state?.Message?.length === 0) {
      return setErr('PLEASE ENTER Message');
    }
    if (state?.Subject?.length < 4) {
      return setErr('Mail Subject is too short');
    }
    if (state?.Message?.length < 4) {
      return setErr('Message is too short');
    }
    return true;
  };
  return (
    <>
      <button className="btn pro-spaces-button3" onClick={handleShow1}>
        NOTIFY
      </button>

      {/* send an email */}
      <Modal show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            <label className="">Mail Subject *</label>
            <input
              className="form-control"
              type="text"
              onChange={(e) => {
                setState({ ...state, Subject: e.target.value });
              }}
              required
            />
          </div>
          <div className="pt-2">
            <label className="">Message *</label>
            <textarea
              className="form-control"
              rows={3}
              onChange={(e) => {
                setState({ ...state, Message: e.target.value });
              }}
              required
            />
          </div>
          <div className="py-1 text-danger">{err}</div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn pro-spaces-button3" onClick={(e) => send()}>
            Send
          </button>
        </Modal.Footer>
      </Modal>
      {/* send an email */}
    </>
  );
};
//sendemail

//send REMARK
const SendRemark = ({ row, log_id, admin }) => {
  let remarkValue = row?.Remark[0];

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [state, setState] = useState({
    pname: remarkValue?.pname || '',
    classification: remarkValue?.classification || '',
    category: remarkValue?.category || '',
    subcategory: remarkValue?.subcategory || '',
    mType: remarkValue?.mType || '',
    verifyAdmin: remarkValue?.verifyAdmin || false,
    Login_id: log_id,
    id: remarkValue?._id,
  });

  const send = () => {
    axios
      .post('/api/Admin/remarkinsert', state)
      .then((res) => {
        handleClose1();
        notify('Remark Sent Successfully', true);
      })
      .catch((err) => {
        console.log(err);
        notify('Something Went Wrong', false);
      });
  };

  const update = () => {
    axios
      .put(`/api/Admin/remarkupdate`, state)
      .then((res) => {
        handleClose1();
        notify('Remark Sent Successfully', true);
      })
      .catch((err) => {
        console.log(err);
        notify('Something Went Wrong', false);
      });
  };

  const clearAll = () => {
    axios
      .put(`/api/Admin/remarkclear`, state)
      .then((res) => {
        handleClose1();
        notify('Remark Cleared Successfully', true);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        notify('Something Went Wrong', false);
      });
  };

  const formValue = (e, field) => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  return (
    <>
      {remarkValue?.verifyAdmin === true ? (
        <button className="btn btn-success" onClick={handleShow1}>
          DONE
        </button>
      ) : (
        <button
          className={
            remarkValue?.pname?.length > 0 ||
              remarkValue?.classification?.length > 0 ||
              remarkValue?.category?.length > 0 ||
              remarkValue?.subcategory?.length > 0 ||
              remarkValue?.mType?.length > 0
              ? 'btn btn-danger'
              : 'btn btn-outline-secondary'
          }
          onClick={handleShow1}
        >
          {remarkValue?.pname?.length > 0 ||
            remarkValue?.classification?.length > 0 ||
            remarkValue?.category?.length > 0 ||
            remarkValue?.subcategory?.length > 0 ||
            remarkValue?.mType?.length > 0 ? (
            'DOUBT'
          ) : (
            <span className="invisible">DOUBT</span>
          )}
        </button>
      )}

      <Modal show={show1} onHide={handleClose1} centered>
        <Modal.Header closeButton>Remarks</Modal.Header>
        <Modal.Body>
          <div className="pt-2">
            <label className="labels">Profile Display Name</label>
            <input
              value={state.pname}
              className="form-control"
              onChange={(e) => formValue(e, 'pname')}
            />
          </div>
          <div className="pt-2">
            <label className="labels">Classification</label>
            <input
              value={state.classification}
              className="form-control"
              onChange={(e) => formValue(e, 'classification')}
            />
          </div>
          <div className="pt-2">
            <label className="labels">Category</label>
            <input
              value={state.category}
              className="form-control"
              onChange={(e) => formValue(e, 'category')}
            />
          </div>
          <div className="pt-2">
            <label className="labels">Sub category</label>
            <input
              value={state.subcategory}
              className="form-control"
              onChange={(e) => formValue(e, 'subcategory')}
            />
          </div>
          <div className="pt-2">
            <label className="labels">Membership Type</label>
            <input
              value={state.mType}
              className="form-control"
              onChange={(e) => formValue(e, 'mType')}
            />
          </div>
          <div class="pt-2 form-check">
            {admin ? (
              <input
                class="form-check-input"
                type="checkbox"
                checked={state.verifyAdmin}
                style={{
                  backgroundColor: state?.verifyAdmin ? 'green' : '',
                  borderColor: state?.verifyAdmin ? 'green' : '',
                }}
                onChange={(e) => formValue(e, 'verifyAdmin')}
              />
            ) : (
              <input
                class="form-check-input"
                disabled
                type="checkbox"
                checked={state.verifyAdmin}
                style={{
                  backgroundColor: state?.verifyAdmin ? 'green' : '',
                  borderColor: state?.verifyAdmin ? 'green' : '',
                }}
                onChange={(e) => formValue(e, 'verifyAdmin')}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn pro-spaces-button-semi" onClick={clearAll}>
            CLEAR ALL
          </button>
          {!remarkValue ? (
            <button className="btn pro-spaces-button3" onClick={send}>
              SUBMIT
            </button>
          ) : (
            <button className="btn pro-spaces-button3" onClick={update}>
              SUBMIT
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
//send REMARK
