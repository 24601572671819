import React, { useRef, useState, useEffect } from 'react';
import { Imageupload } from '../../ManageAdmin/Imageupload';
import { StateCity } from '../../CreateConseltent/StateCity';

import { IoInformationCircleOutline } from 'react-icons/io5';
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri';
import { Modal } from 'react-bootstrap';
import { print_city, print_state } from '../../../Utility/stateCity';
import axios from 'axios';
import { notify } from '../../../Utility/notify';
import { useNavigate } from 'react-router';
import { IoIosCloseCircleOutline } from 'react-icons/io';

export const RegistrationCSmanager = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let navigate = useNavigate();

  const [img, setimg] = useState('');
  const [showpassword, setshowpassword] = useState(false);
  const [showreferral, setShowreferral] = useState(false);
  const [error, setError] = useState('');
  const [Submit2, setSubmit2] = useState(false);

  const handleClosereferral = () => setShowreferral(false);
  const handleShowreferral = () => setShowreferral(true);

  const buttonshow = useRef();

  function Cityshow(e) {
    var index = document.getElementById(e.target.id).selectedIndex;
    print_city('City', index);
    setvaluefield({ ...valuefield, state: e.target.value });
  }

  const [btn, setbtn] = useState(false);
  const [valuefield, setvaluefield] = useState({
    Name: '',
    Email: '',
    Gender: '',
    Age: '',
    Nationality: '',
    Contact: '',
    Password: '',
    ConfirmPassword: '',
    Account_type: 'REGIONAL MANAGER',
    showPassword: false,
    showConformPassword: false,
    Referral_Code: '',
    Active_status: true,
    Activev2_status: true,
    Activev3_status: true,
    Passive_status: true,
    Service_provide_category_All: true,
    Product_retailer_category_All: true,
    country: '',
    state: '',
    city: '',
    Commission_percentage: '',
    Bank_account_type: '',
    Account_holder_name: '',
    Bank_name: '',
    Account_number: '',
    Ifc_code: '',
    loginID: '',
    Address: '',
    Pincode: '',
    id: '',
  });
  const [Stateload, setStateload] = useState(true);
  function validator1(e) {
    e.preventDefault();

    setSubmit2(true);
    const errors = {};
    // if(!value.Name)return setError("Name is missing !");
    // if (!value.Email) {
    //   errors.email = 'Email required !'
    // return setError(errors)
    // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.Email)) {
    //   errors.email = 'Invalid email address !'
    // return setError(errors)
    // }

    // if (!value.Contact) {
    //     return setError('Enter phone number !')
    // }
    // if (valuefield.Contact.toString().length != 10) {
    //     return setError('Enter a valid mobile number !')
    // }
    // if(!value.Commission_percentage)return setError("Discount percentage is missing !");
    if (!valuefield.Address) {
      return setError('');
    }
    if (!valuefield.Pincode) {
      return setError('');
    }

    if (!img) {
      return setError('PLEASE UPLOAD Image');
    }

    if (!valuefield.Password) return setError('');

    //   if (valuefield.Password.toString().length <8) {
    //     return setError('Password must contain 8 characters !')
    // }
    setError('');
    if (
      valuefield.Password.toString() !== valuefield.ConfirmPassword.toString()
    ) {
      return setError("'Password' and 'Confirm Password' must be matching !");
    }

    setError('');
    // &&TermsAndCondition.Terms4

    if (valuefield.Age <= 17) {
      return setError(
        'Sorry....you are too young ! Age must be 18 years and above.'
      );
    }
    go();
  }

  useEffect(() => {
    if (Stateload) print_state('State');
    setStateload(false);
  }, []);

  function go() {
    var formdata = new FormData();
    formdata.append('data', JSON.stringify({ valuefield }));
    var imag = new File([img], 'avatar.png', { type: 'image/png' });

    formdata.append('file', imag);

    axios
      .post('/api/areamanager/areareagistartion', formdata)
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          notify('Successfully created', true);
          navigate(`/areamanager`);
        }
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }

  const checkReferral_Code = (e) => {
    console.log(e);
    var Referral_Code = e;
    axios
      .post('/api/areamanager/checkReferral_Code', { Referral_Code })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length == 0) {
            notify('This referral code is available for you', true);
          } else {
            notify('This Code already exists', false);
          }
        }
      })
      .catch((err) => {
        notify('Something went wrong', false);
      });
  };

  // useEffect(() => {
  //   handleShow();
  // }, []);

  return (
    <>
      <div className=" rounded bg-white px-3 px-md-5 py-5">
        <h3 className="text-center mt-4 ">
          CLUSTER SALES MANAGER REGISTRATION FORM
        </h3>
        <div className="text-center">( Only for Commission-based Sales )</div>
        <div className="pt-4">
          This is a Legal Contractual Agreement between You and{' '}
          <span
            className="vipar"
            style={{
              color: 'green',
              fontSize: '20px',
              paddingRight: '5px',
            }}
          >
            Vipar
          </span>{' '}
          <sup>®</sup>
        </div>

        <form
          onSubmit={(e) => validator1(e)}
          novalidate
          className={Submit2 ? 'was-validated' : ''}
        >
          <>
            <h5 className="mt-4 ">TERMS and CONDITIONS *</h5>
            <div className="pt-4">CONSENT :</div>

            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault1">
                I am aware and agree that I fully understand that{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup>®</sup> is the Organisation (based out of Bengaluru,
                Karnataka)with which I am associated as a Cluster Sales Manager
                and that <span className="prochure">PROchure</span>{' '}
                {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                is only an application developed by{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <span
                  className="prochure"
                  style={{ fontSize: '16px', color: 'black' }}
                >
                  CONNECT
                </span>
                , which is a unit of{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup>®</sup>.
              </label>
            </div>

            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault2">
                I am ready to enrol myself as a Cluster Sales Manager at{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> to recruit and supervise Field Sales
                Executives inorder to get potential interested customers to
                subscribe on the platform called{' '}
                <span className="prochure">PROchure</span>{' '}
                {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}
                (an app accessible to users across the globe) which is primarily
                a digital directory of Services and Products.
              </label>
            </div>

            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault2">
                I am aware and agree that at any given time, I SHALL recruit and
                maintain a minimum of 5 (FIVE) Field Sales Executives and SHALL
                recruit Only a maximum of 50 (FIFTY) Field Sales Executives.
              </label>
            </div>

            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault2">
                I am aware and agree that in a sceanrio where I have lesser than
                5 FSEs in my FSE Cluster team, then my CSM account and the
                accounts of all the existing FSEs in my team will be Locked, and
                also that the Unique Referral Code of the existing FSEs in my
                team will be invalid.
              </label>
            </div>
            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault2">
                I am aware and agree that I SHALL mandatorily get each and every
                FSE working under me in my Cluster team to register as a FSE via
                the Organisation’s web app www.vipar-connect.in before the FSE
                starts his/her sales pitch.
              </label>
            </div>
            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault2">
                I am aware and agree that any sales achieved by any of my FSEs
                SHALL NOT be considered valid unless and until his/her
                registration as a FSE is not done with{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup>.
              </label>
            </div>
            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault2">
                I am aware and agree that I SHALL NOT recruit anyone to join as
                a FSE by misguiding / misleading / misinforming them regarding
                their remuneration and professional protocols with the
                Organisation.
              </label>
            </div>
            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault2">
                I am aware and agree that this Contractual Agreement is valid
                only for a period of 1 (ONE) Year and SHALL be renewed every
                year based on the mutual understanding between myself and{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup>.
              </label>
            </div>
            <div className="pt-4">SALES :</div>

            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault2">
                I am aware and agree that I SHALL give appropriate training and
                guidance to each and every Field Sales Executive who is working
                under me in my team about the features, benefits, fee structure
                and membership subscription details of the app so that each one
                of my FSEs can explain the same to each and every customer with
                clarity.
              </label>
            </div>

            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault8">
                I am aware and agree that I SHALL clearly explain to each one of
                my FSEs regarding how a profile should be created for a customer
                and shall leave no scope for errors.
              </label>
            </div>

            <div className="pt-4">PROFESSIONAL CONDUCT / ETHICS :</div>

            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault4">
                I am aware and agree that I SHALL NOT encourage my FSEs to
                engage or indulge in any fraudulent activities that will spoil
                the reputation of the either the App or the Organisation, only
                for the sake of getting subscriptions at{' '}
                <span className="prochure">PROchure</span> via my FSEs.
              </label>
            </div>

            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault5">
                I am aware and agree that I SHALL NOT encourage or allow my FSEs
                to make any false claims / false promises to the potential
                subscribers regarding the features and benefits of using the
                platform or mislead them with incorrect or wrong information
                regarding the app only for the sake of getting their
                subscription.
              </label>
            </div>

            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                I am aware and agree that I SHALL NOT encourage or allow my FSEs
                to say anything to the potential subscribers at my / their own
                discretion beyond what has been instructed to me / them by{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> and that I SHALL ensure that my FSEs
                maintain absolute professional decorum as a representative of{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> during my course of interaction with
                prospective customers.
              </label>
            </div>
            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault13">
                I am aware and agree that I SHALL strictly abide by the rules
                and regulations of the Organisation, and all the Terms and
                Conditions mentioned here and that I SHALL NOT indulge in any
                activity that will directly or indirectly spoil the reputation
                of the platform ( <span className="prochure">PROchure</span>{' '}
                {/* <sup style={{ fontSize: '10px' }}> TM</sup> */}) and the
                Organisation ({' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> ).
              </label>
            </div>
            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault15">
                I am aware and agree that I SHALL ensure that my FSEs neither
                lose their temper nor get into any kind of physical or verbal
                fights with a customer even if it is a mistake on the part of
                the customer and SHALL advice them to politely relieve
                themselves from the customer’s place in any such untoward
                scenario.
              </label>
            </div>
            <div className="pt-4">REMUNERATION :</div>
            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault14">
                I am aware and agree that I SHALL be paid the sales commission
                only on percentage basis on each Subscription (amount) achieved
                via sales by each of my FSEs via their Unique Referral Code and
                that this Subscription amount is subject to change at the
                discretion of{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> .
              </label>
            </div>
            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault15">
                I am aware and agree that I SHALL be paid commission Only on the
                Final (in case of using a Discount Coupon) Subscription (amount)
                of each profile that each of my FSEs get listed at{' '}
                <span className="prochure">PROchure</span> via their Unique
                Referral Code.
              </label>
            </div>
            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault15">
                I am aware and agree that I SHALL be paid commission (which is
                mentioned in my Appointment Letter) on each Subscription
                (amount) of each profile that each one of my FSEs get listed at{' '}
                <span className="prochure">PROchure</span> via their Unique
                Referral Code and each of my FSEs SHALL be paid 35% commission
                on each of the Subscriptions that they achieve via sales.
              </label>
            </div>
            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault15">
                I am aware and agree that the percentage commissions on sales
                SHALL be settled in full only on the last working day of that
                respective month.
              </label>
            </div>
            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                I am aware and agree that I SHALL NOT be eligible for any
                additional fixed daily, weekly or monthly remuneration beyond
                the sales percentage commission.
              </label>
            </div>
            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                I am aware and agree that I SHALL NOT be eligibile to get any
                reimbursement on the conveyance or phone call expenses that I
                incur as a part of my efforts to either recruit or to get
                subscriptions at <span className="prochure">PROchure</span> via
                my FSEs.
              </label>
            </div>

            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                I am aware and agree that I SHALL be eligible for my sales
                commissions from subscriptions of my FSEs Only if the customer
                mandatorily mentions the Unique Referral Code of the respective
                FSE on the payment page while creating his/her/their profile at{' '}
                <span className="prochure">PROchure</span>.
              </label>
            </div>

            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                I am aware and agree that I SHALL be notified (in writing) 1
                (ONE) Month prior to any increase or decrease in my commission
                percentage and/or the sales commission percentage of my FSEs.
              </label>
            </div>

            <div className="pt-4">FINANCIAL PROTOCOLS :</div>
            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                I am aware and agree that I SHALL NOT encourage or allow to
                collect money for subscriptions from any individual, who wishes
                to create a business listing / profile of theirs on the
                platform, either in the form of cash / cheque / DD / net
                transfer such as IMPS, NEFT, RTGS / QR code scanning or UPI
                payment method to any external account including my own or my
                FSEs.
              </label>
            </div>
            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                I am aware and agree that the subscription fees SHALL be
                collected ONLY via the UPI payment method on the web and mobile
                apps of the <span className="prochure">PROchure</span> platform
                to the{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> account and NOT to any of my personal
                accounts or my FSEs’ personal accounts or to any other account
                of person(s) / company known to me.
              </label>
            </div>

            <div className="pt-4">RESIGNATION :</div>
            <div class="form-check pt-3">
              <label class="form-check-label">
                I am aware and agree that while resigning from the job, I SHALL
                send an email regarding my resignation to only the official
                marketing email id of{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup>, which is vipar.marketing.hr@gmail.com
                from my registered email id with Subject Title as ‘{' '}
                <span className="prochure">PROchure</span> CSM RESIGNATION’ and
                clearly mentioning my Unique Referral Code. Else my resignation
                SHALL NOT be acceptable.
              </label>
            </div>
            <div class="form-check pt-3">
              <label class="form-check-label">
                I am aware and agree that I can resign from the Organisation
                without any notice period.
              </label>
            </div>
            <div class="form-check pt-3">
              <label class="form-check-label">
                I am aware and agree that both my Salary Certificate and
                Experience Certificate SHALL be issued to me ONLY IF I complete
                a minimum of 6 (SIX) Months of work duration with{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> as a CSM.
              </label>
            </div>
            <div class="form-check pt-3">
              <label class="form-check-label">
                I am aware and agree that upon my resignation, my entire FSE
                Cluster team would be immediately and automatically dissolved
                However I SHALL NOT stop the Organisation from hiring or
                assigning my FSEs to other Cluster Sales Managers or Regional
                Marketing Managers IF the FSEs in my Cluster team wish to
                continue to work for and be a part of the Organisation.
              </label>
            </div>
            <div className="pt-4">RELIEVING FROM JOB :</div>
            <div class="form-check pt-3">
              <label class="form-check-label">
                I am aware and agree that IF the Organisation finds me inept for
                this Job or in a scenario where the Organisation decides to put
                an end to the marketing and sales of the app, then the
                Organisation SHALL have the authority to relieve me from this
                Job by giving me a Notice period of 1 (ONE) Month in writing via
                mail to my registered email id.
              </label>
            </div>
            <div className="pt-4">DE-REGISTRATION / TERMINATION :</div>
            <div class="form-check pt-3">
              <label class="form-check-label">
                I am aware and agree that my CSM account SHALL be de-registered
                / terminated without any intimation or notification IF the
                Organisation detects any suspicious activity or unethical
                practice during my course of sales of the app.
              </label>
            </div>
            <div class="form-check pt-3">
              <label class="form-check-label">
                I am aware and agree that I SHALL NOT be eligible for any
                pending remuneration / commission for sales in case of
                de-registration / termination.
              </label>
            </div>
            <div className="pt-4">LEGAL BINDING :</div>
            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault15">
                I am aware and agree that it SHALL be only my FSE who will be
                fully and solely responsible for his/her bad and irresponsible
                behaviour with a customer and only he/she SHALL be held liable
                for the same legally and NOT any other employee / representative
                of the Organisation or the Head of the Organisation since my
                FSEs are directly recruited by me and not by the Organisation.
              </label>
            </div>
            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                I am aware and agree that I SHALL be legally sued for monetary
                damages at the discretion of{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> IF found to be involved in any kind of
                fraudulent activity which directly or indirectly is an act
                against the interests of either a customer or the Organisation
                or both. And that I SHALL NOT be eligible for any pending
                remuneration / commission for sales in such a scenario.
              </label>
            </div>
            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                I am aware and agree that any lapse(s) occuring from my end
                regarding ALL or ANY of the above mentioned mutually agreed upon
                Terms and Conditions SHALL solely be my responsibility and for
                which{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> SHALL NOT be held responsible or liable,
                both legally and otherwise.
              </label>
            </div>
            <div class="form-check pt-3">
              <label class="form-check-label" for="flexCheckDefault6">
                I am aware and agree that these Terms and the relationship
                between myself and{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> shall be governed by the laws of
                Karnataka, India without regard to its conflict of law
                provisions.{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> and I agree that all disputes shall be
                subject to arbitration at Bengaluru, Karnataka in accordance
                with the Arbitration and Conciliation Act, 1996. The arbitration
                proceedings shall be conducted in the English language. That{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> and I hereby expressly and irrevocably
                attorn to the jurisdiction of the courts of Bengaluru with
                respect to any matter or claim, suit, action or proceeding
                arising under or related to this Agreement. I covenant not to
                sue{' '}
                <span
                  className="vipar"
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    paddingRight: '5px',
                  }}
                >
                  Vipar
                </span>{' '}
                <sup class="">®</sup> in any other forum.
              </label>
            </div>
          </>

          <div className=" pt-3">
            <div className="row">
              <h5 className="mt-4 d-block d-md-none">PERSONAL DETAILS *</h5>
              <div className="col-12  col-md-3 mnmnmnm mmmmm">
                <Imageupload required onChange={(e) => setimg(e)} />
                <span className="mt-3 " style={{ paddingLeft: '15px' }}>
                  Upload your Photo *
                </span>
                <p className="text-danger">{error} </p>
                <div class="invalid-feedback">PLEASE UPLOAD IMAGE</div>
              </div>

              <div className="col-12 col-md-9">
                <div className="row mt-3">
                  <h5 className="mt-4  d-none d-md-block">
                    PERSONAL DETAILS *
                  </h5>
                  <div className="col-md-12">
                    <label className="labels">Full Name *</label>
                    <input
                      placeholder="Enter Full Name"
                      className="form-control"
                      type={'text'}
                      onChange={(e) =>
                        setvaluefield({ ...valuefield, Name: e.target.value })
                      }
                      required
                    ></input>
                    <div class="invalid-feedback">PLEASE ENTER Full Name</div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Gender *</label>
                    <select
                      class="form-control form-select"
                      onChange={(e) =>
                        setvaluefield({ ...valuefield, Gender: e.target.value })
                      }
                      required
                    >
                      <option></option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Transgender">Transgender</option>
                    </select>
                    <div class="invalid-feedback">PLEASE SELECT Gender</div>
                  </div>
                  <div className="col-md-12 ">
                    <label className="labels">Age *</label>
                    <div>
                      <div className="age w-100">
                        <input
                          placeholder="Enter Age"
                          className="form-control  "
                          type={'number'}
                          min="18"
                          pattern="[0-9]{2}"
                          required
                          onChange={(e) => {
                            setvaluefield({
                              ...valuefield,
                              Age: e.target.value,
                            });
                          }}
                          // onBlur={(e) => {
                          //   if (e.target.value <= 17) {
                          //     setvaluefield({ ...valuefield, Age: ' ' });

                          //   }
                          //   else{
                          //     setErr
                          //   }
                          // }}
                        ></input>
                      </div>
                    </div>
                    <div class="invalid-feedback">PLEASE ENTER Age</div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Nationality *</label>
                    <select
                      class="form-control form-select"
                      onChange={(e) => {
                        setvaluefield({
                          ...valuefield,
                          Nationality: e.target.value,
                        });
                      }}
                      required
                    >
                      <option></option>
                      <option value="Indian">Indian</option>
                      <option value="American">American</option>
                      <option value="British">British</option>
                      <option value="German">German</option>
                      <option value="Italian">Italian</option>
                    </select>
                    <div class="invalid-feedback">
                      PLEASE SELECT Nationality
                    </div>
                  </div>

                  <StateCity setValue={setvaluefield} value={valuefield} />
                  {/* [{country:value.country,state:value.state,city:value.city}] */}

                  <div className="col-md-12 mt-2 ">
                    <label className="labels">Address *</label>

                    <textarea
                      placeholder="Enter Full Address"
                      className="form-control  "
                      required
                      onChange={(e) => {
                        setvaluefield({
                          ...valuefield,
                          Address: e.target.value,
                        });
                      }}
                    ></textarea>

                    <div class="invalid-feedback">PLEASE ENTER Address</div>
                  </div>
                  <div className="col-md-12 mt-2">
                    <label className="labels">Pin Code *</label>

                    <input
                      placeholder="Enter Pin Code"
                      className="form-control  "
                      type={'text'}
                      maxLength={6}
                      required
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value))
                          setvaluefield({
                            ...valuefield,
                            Pincode: e.target.value,
                          });
                      }}
                    ></input>

                    <div class="invalid-feedback">PLEASE ENTER Pin Code</div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Email Id *</label>
                    <input
                      placeholder="Enter an existing Email Id  "
                      className="form-control "
                      type={'email'}
                      onChange={(e) => {
                        setvaluefield({ ...valuefield, Email: e.target.value });
                      }}
                      required
                    ></input>
                    <div class="invalid-feedback">PLEASE ENTER Email Id</div>
                  </div>

                  <div className="col-md-12">
                    <label className="labels">Contact Number *</label>
                    <input
                      placeholder="Enter Anytime Available Contact Number  "
                      maxLength={10}
                      required
                      className="form-control "
                      type={'text'}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value))
                          setvaluefield({
                            ...valuefield,
                            Contact: e.target.value,
                          });
                      }}
                    ></input>
                    <div class="invalid-feedback">PLEASE ENTER Contact</div>
                  </div>

                  <div className="col-md-12 mt-2">
                    <div className="d-flex justify-content-between">
                      <div className="labels d-flex align-items-center ">
                        Unique Referral Code
                        *&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <IoInformationCircleOutline
                          size={18}
                          onClick={handleShowreferral}
                        />
                      </div>
                    </div>
                    <div className="labels">
                      ( Click on the Information icon to clearly understand what
                      Referral Code is. ) ( Click on the Information icon to
                      clearly understand what Referral Code is. )
                    </div>
                    <input
                      placeholder="Create Unique Referral Code of your wish "
                      className="form-control"
                      type={'text'}
                      onChange={(e) =>
                        setvaluefield({
                          ...valuefield,
                          Referral_Code: e.target.value,
                        })
                      }
                      minLength={5}
                      maxLength={10}
                      onBlur={(e) => {
                        if (e.target.value.length > 3)
                          checkReferral_Code(e.target.value);
                      }}
                      required
                    ></input>
                    <div class="invalid-feedback">
                      PLEASE ENTER Referral Code ( minimum of 5 characters )
                    </div>
                  </div>
                </div>
                <h5 className="mt-4">IDENTITY PROOF *</h5>
                <div className="row mt-2">
                  <label className="labels">
                    Upload both front and back sides of your Aadhaar Card
                  </label>
                  {/* <div className="col-md-12">
                    <div></div>
                    <input
                      hidden
                      ref={btnchange}
                      className="form-control"
                      type="file"
                      id="formFile"
                      accept="image/*"
                      required
                      onChange={(e) => setadar([...adar, ...e.target.files])}
                    />
                    <button
                      id="btnchange"
                      className="btn pro-spaces-button mt-3 mx-2"
                      style={{ backgroundColor: 'rgb(1 32 96)' }}
                      accept="image/*"
                      onClick={(e) => btnchange.current.click()}
                    >
                      Upload your Aadhaar Card *
                    </button>

                    <div className="ps-3 pt-3">
                      <div className="row g-3">
                        {adar.map((ele, index) => {
                          console.log(ele);
                          return (
                            <div className="col-12 col-md-6 col-lg-4">
                              <IoIosCloseCircleOutline
                                className="d-flex float-end"
                                onClick={(e) => fun(index)}
                              />
                              <img
                                src={URL.createObjectURL(ele)}
                                style={{
                                  width: '100%',
                                  height: '200px',
                                  objectFit: 'cover',
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div class="invalid-feedback">
                      PLEASE UPLOAD Aadhaar Card
                    </div>
                  </div> */}
                </div>
                <h5 className="mt-4">BANK ACCOUNT DETAILS *</h5>

                <div className="row mt-2">
                  <div className="col-md-12">
                    <label className="labels">Account Holder Name *</label>
                    <input
                      type="text"
                      placeholder="Enter Full Name as per bank account "
                      className="form-control"
                      maxLength="150"
                      onChange={(e) => {
                        setvaluefield({
                          ...valuefield,
                          Account_holder_name: e.target.value,
                        });
                      }}
                      required
                    />
                    <div class="invalid-feedback">
                      PLEASE ENTER Bank account holder name
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Bank Name *</label>
                    <input
                      type="text"
                      placeholder="Enter Bank Name "
                      className="form-control"
                      maxLength="40"
                      onChange={(e) => {
                        setvaluefield({
                          ...valuefield,
                          Bank_name: e.target.value,
                        });
                      }}
                      required
                    />
                    <div class="invalid-feedback">PLEASE ENTER Bank name</div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Bank Account Type *</label>

                    <select
                      class="form-control form-select"
                      onChange={(e) => {
                        setvaluefield({
                          ...valuefield,
                          Bank_account_type: e.target.value,
                        });
                      }}
                      required
                    >
                      <option></option>
                      <option value="SB">SB</option>
                      <option value="Current">Current</option>
                    </select>
                    <div class="invalid-feedback">
                      PLEASE SELECT Bank account type
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">Account Number *</label>
                    <input
                      type="number"
                      placeholder="Enter Account Number "
                      className="form-control"
                      maxLength="100"
                      onChange={(e) => {
                        setvaluefield({
                          ...valuefield,
                          Account_number: e.target.value,
                        });
                      }}
                      required
                    />
                    <div class="invalid-feedback">
                      PLEASE ENTER Account number
                    </div>
                  </div>

                  <div className="col-md-12">
                    <label className="labels">IFSC code *</label>
                    <input
                      type="text"
                      placeholder="Enter IFSC code "
                      className="form-control"
                      maxLength="40"
                      onChange={(e) => {
                        setvaluefield({
                          ...valuefield,
                          Ifc_code: e.target.value,
                        });
                      }}
                      required
                    />
                    <div class="invalid-feedback">PLEASE ENTER IFSC code</div>
                  </div>

                  <>
                    <div>
                      <div class="form-check pt-3">
                        <label class="form-check-label" for="flexCheckDefault7">
                          I am aware and agree that the bank account details I
                          have mentioned is correct and wish to have my
                          commissions credited to this account.
                        </label>
                      </div>
                    </div>
                    <div>
                      <div class="form-check pt-3">
                        <label class="form-check-label" for="flexCheckDefault9">
                          I am aware and agree that any mistake / error in the
                          bank account details entered is not the responsibility
                          of the platform.
                        </label>
                      </div>
                    </div>
                  </>
                  <h5 className="mt-4">
                    CONTACT INFORMATION of a FAMILY MEMBER in case of an
                    Emergency *
                  </h5>
                  <div className="col-md-12 mt-2">
                    <label className="labels">
                      Full Name of Family Member *
                    </label>
                    <input
                      className="form-control"
                      required
                      placeholder="Enter Full Name"
                      type={'text'}
                      onChange={(e) =>
                        setvaluefield({
                          ...valuefield,
                          FMName: e.target.value,
                        })
                      }
                    ></input>
                    <div class="invalid-feedback">
                      PLEASE ENTER Full Name of Family Member
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">
                      Relationship with Family Member *
                    </label>

                    <select
                      class="form-control form-select"
                      required
                      id="exampleFormControlSelect1"
                      onChange={(e) =>
                        setvaluefield({
                          ...valuefield,
                          FMRelationship: e.target.value,
                        })
                      }
                    >
                      <option></option>
                      <option value="Father">Father</option>
                      <option value="Mother">Mother</option>
                      <option value="Brother">Brother</option>
                      <option value="Sister">Sister</option>
                      <option value="Husband">Husband</option>
                      <option value="Wife">Wife</option>
                      <option value="Son">Son</option>
                      <option value="Daughter">Daughter</option>
                    </select>
                    <div class="invalid-feedback">
                      PLEASE SELECT Relationship with Family Member
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label className="labels">
                      Contact Number of Family Member *
                    </label>
                    <input
                      placeholder="  "
                      maxLength={10}
                      required
                      className="form-control "
                      type={'text'}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value))
                          setvaluefield({
                            ...valuefield,
                            FMContact: e.target.value,
                          });
                      }}
                    ></input>
                    <div class="invalid-feedback">
                      PLEASE ENTER Contact Number of Family Member
                    </div>
                  </div>
                  <div className="col-md-12 mt-2 ">
                    <label className="labels">Address of Family Member *</label>

                    <textarea
                      placeholder="Enter Full Address"
                      className="form-control  "
                      required
                      onChange={(e) => {
                        setvaluefield({
                          ...valuefield,
                          FMAddress: e.target.value,
                        });
                      }}
                    ></textarea>

                    <div class="invalid-feedback">PLEASE ENTER Address</div>
                  </div>
                  {/* country
                  state
                  city */}
                  <div className="col-md-12 mt-2">
                    {/* <StateCity setValue={setvalueee} value={valueee} /> */}
                  </div>
                  <div className="col-md-12 mt-2">
                    <label className="labels">Pin Code *</label>

                    <input
                      placeholder="Enter Pin Code"
                      className="form-control  "
                      type={'text'}
                      maxLength={6}
                      required
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value))
                          setvaluefield({
                            ...valuefield,
                            FMPincode: e.target.value,
                          });
                      }}
                    ></input>
                    <div class="invalid-feedback">PLEASE ENTER Pin Code</div>
                  </div>

                  <h5 className="mt-4">LOGIN CREDENTIALS *</h5>
                  <div className="col-md-12 mt-2">
                    <label className="labels">
                      Login Id <br></br>( Your Email Id is your Default
                      Login Id. )
                    </label>
                    <input
                      className="form-control"
                      type={'text'}
                      value={valuefield.Email}
                    ></input>
                  </div>

                  <>
                    <div className="col-md-12">
                      <label className="labels">Create Password *</label>
                      <div>
                        <input
                          class="form-control passInout12321"
                          placeholder="Enter Password"
                          aria-describedby="basic-addon2"
                          type={showpassword ? 'text' : 'password'}
                          onChange={(e) => {
                            setvaluefield({
                              ...valuefield,
                              Password: e.target.value,
                            });
                          }}
                          required
                        />
                        &nbsp;
                        <span class="showbutton123321">
                          {showpassword ? (
                            <RiEyeFill
                              onClick={(e) => {
                                setshowpassword(!showpassword);
                              }}
                            />
                          ) : (
                            <RiEyeOffFill
                              onClick={(e) => {
                                setshowpassword(!showpassword);
                              }}
                            />
                          )}
                        </span>
                        <div class="invalid-feedback ">
                          PLEASE ENTER Password
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <label className="labels">Confirm Password *</label>
                      <div>
                        <div>
                          <input
                            class="form-control passInout12321"
                            placeholder="Enter Password again"
                            type={showpassword ? 'text' : 'password'}
                            required
                            onChange={(e) =>
                              setvaluefield({
                                ...valuefield,
                                ConfirmPassword: e.target.value,
                              })
                            }
                          />

                          <span class="showbutton123321" type="button">
                            {' '}
                            {showpassword ? (
                              <RiEyeFill
                                onClick={(e) => {
                                  setshowpassword(!showpassword);
                                }}
                              />
                            ) : (
                              <RiEyeOffFill
                                onClick={(e) => {
                                  setshowpassword(!showpassword);
                                }}
                              />
                            )}
                          </span>
                        </div>
                        <div class="invalid-feedback ">
                          PLEASE ENTER Confirm Password
                        </div>
                      </div>
                    </div>
                  </>

                  <>
                    <h5 className="mt-4">TO BE NOTED :</h5>

                    <ul className="ps-5 mt-2">
                      <li className="pt-2">
                        You can View the Accepted Terms and Conditions from your
                        CSM account.
                      </li>

                      <li className="pt-2">
                        {' '}
                        For any grievances, send us an email regarding the issue
                        to vipar.marketing.hr@gmail.com from your registered
                        email id with Subject Title as ‘
                        <span className="prochure">PROchure</span> CSM
                        GRIEVANCE’ and clearly mentioning your Unique Referral
                        Code.
                      </li>
                    </ul>
                    <h5 className="mt-4">REFERRAL CODE :</h5>
                    <ul className="ps-5 mt-2">
                      <li className="pt-2" style={{ lineSpacing: '0' }}>
                        During Registration, Create a Referral Code of your
                        choice which will be your CSM Identification at{' '}
                        <span
                          className="vipar"
                          style={{
                            fontSize: '22px',
                            color: '#99d050',
                          }}
                        >
                          {' '}
                          Vipar
                        </span>{' '}
                        <sup class="">®</sup>
                      </li>

                      <li className="pt-2">
                        It is Mandatory for disbursing on your commission to
                        you.
                      </li>
                      <li className="pt-2">
                        Once a Referral Code is created, it is permanent and
                        cannot be changed.
                      </li>

                      <li className="pt-2">
                        Can be a combination of Alphabets, Numbers and Symbols.
                      </li>
                      <li className="pt-2">
                        Minimum Character length &#8239;: 5<br />
                        &nbsp;&nbsp;Maximum Character length : 10
                      </li>
                      <li className="pt-2">
                        In case you forget your Referral Code, it will be
                        visible to you on the ‘Account Details’ page of your
                        profile.
                      </li>
                    </ul>
                    <div class="form-check pt-3 ps-5">
                      <input
                        class="form-check-input affcheckbox"
                        type="checkbox"
                        id="flexCheckDefault1"
                        onChange={(e) => setbtn(!btn)}
                      />
                      <label class="form-check-label">
                        I have read and understood each and every clause
                        mentioned above and agree to comply with all the Terms
                        and Conditions upon registration.
                      </label>
                    </div>
                  </>

                  <div className="col-12 px-1  d-flex justify-content-center pt-3  ">
                    <button
                      className="btn pro-spaces-button3 "
                      ref={buttonshow}
                      disabled={!btn}
                      onClick={(e) => validator1(e)}
                    >
                      REGISTER
                    </button>

                    {/* {loading && (
                      <button
                        className="btn pro-spaces-button3"
                        disabled={!btn}
                      >
                        REGISTER &nbsp;
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          /> */}
        </form>

        {/* referralcode modal */}

        <Modal show={showreferral} onHide={handleClosereferral}>
          <Modal.Header centered closeButton></Modal.Header>
          <Modal.Body>
            <div className="footlightfont">
              <div className="pt-3" style={{ lineSpacing: '0' }}>
                •During Registration, Create a Referral Code of your choice
                which will be your CSM Identification at{' '}
                <span
                  className="vipar"
                  style={{
                    fontSize: '22px',
                    color: '#99d050',
                  }}
                >
                  {' '}
                  Vipar
                </span>{' '}
                <sup class="">®</sup>
              </div>

              <div className="pt-3">
                • It is Mandatory for disbursing on your commission to you.
              </div>
              <div className="pt-3">
                • Once a Referral Code is created, it is permanent and cannot be
                changed.
              </div>

              <div className="pt-3">
                • Can be a combination of Alphabets, Numbers and Symbols.
              </div>
              <div className="pt-3">
                • Minimum Character length &#8239;: 5<br />
                &nbsp;&nbsp;Maximum Character length : 10
              </div>
              <div className="pt-3">
                • In case you forget your Referral Code, it will be visible to
                you on the ‘Account Details’ page of your profile.
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* referralcode modal */}

        {/* Starting Modal */}
        <Modal fullscreen show={show} onHide={handleClose}>
          <Modal.Body>
            <div className="pt-3 footlightfont text-center ">
              <span className="prochure">PROchure</span>
              &nbsp;
              <span className="boldfont">CLUSTER SALES</span>
            </div>
            <div className="pt-3 footlightfont">
              You must recruit a minimum of 5 Field Sales Executives and can
              recruit a maximum of 50 Field Sales Executives to work under you.
            </div>
            <div className="pt-3 footlightfont">
              There are currently two types of Membership Subscription.
              <div className="pt-2 footlightfont">
                1. BASIC listing
                <br />
                ₹ 500/- per year including GST
                <br />
                2. PRIME league
                <br />₹ 5000/- per year including GST
              </div>
            </div>
            <div className="pt-3 footlightfont">
              Please Note that in certain Categories / Sub Categories of
              Qualified Professionals, Profiles cannot be created under BASIC
              listing.
            </div>
            <div className="pt-3 footlightfont">
              Profiles of these Qualified Professionals (like Architects,
              Structural Engineers, Chartered Accountants, Doctors, Lawyers,
              etc) can be listed Only under PRIME league because of mandatory
              Professional information required from them.
            </div>
            <div className="pt-3 footlightfont">
              <span className="boldfont">Remuneration</span> :
            </div>
            <div className="pt-3 footlightfont">
              You will be paid 10% Commission on the Final (in case of using a
              Discount Coupon) Subscription Amount on each of your assigned FSEs
              get listed via his/her Unique Referral Code.
              <br /> For instance, if you create a Profile under PRIME league
              for ₹ 5000/-, you will get ₹ 500/- as commission.
            </div>
            <div className="pt-3 footlightfont">
              <span className="boldfont">Here's how it works</span> :
            </div>
            <div className="pt-3 footlightfont">
              1. JOIN to Create a CSM account with us.
            </div>
            <div className="pt-2 footlightfont">
              2. Via your CSM account, you can :<br />
              a) See details of the profiles created by each one of your FSEs.
              <br />
              b) Track your commission remittances.
            </div>
            <div className="pt-3 footlightfont">
              <span className="boldfont">After Registration</span> :
              <div className="pt-3 footlightfont">
                • You can see the CATEGORIES and SUB CATEGORIES under Services
                and Products in which Profiles must be created by your FSEs.
              </div>
              {/* <div className="pt-3 footlightfont">
                • You can Sign In / Log In to www.vipar-connect.in anytime you
                wish to do marketing and sales.
              </div> */}
            </div>

            <div className="pt-3 footlightfont">
              <span className="boldfont">Referral Code</span> :
            </div>

            <div className="pt-3 footlightfont" style={{ lineSpacing: '0' }}>
              • Create a Referral Code of your choice which will be your CSM
              Identification at{' '}
              <span
                className="vipar"
                style={{
                  fontSize: '22px',
                  color: '#99d050',
                }}
              >
                {' '}
                Vipar
              </span>{' '}
              <sup class="">®</sup>.
            </div>
            {/* <div className="pt-3 footlightfont" style={{ lineSpacing: '0' }}>
              • Your Unique Referral Code is the one with which
              <span
                className="vipar"
                style={{
                  fontSize: '22px',
                  color: '#99d050',
                }}
              >
                {' '}
                Vipar
              </span>{' '}
              <sup class="">®</sup> can identify that it is via your FSE account
              that a Subscription is sold.
            </div> */}
            <div className="pt-3 footlightfont">
              • It is Mandatory for disbursing on your commission to you.
            </div>
            <div className="pt-3 footlightfont">
              • Once a Referral Code is created, it is permanent and cannot be
              changed.
            </div>

            <div className="pt-3 footlightfont">
              • Can be a combination of Alphabets, Numbers and Symbols.
            </div>
            <div className="pt-3 footlightfont">
              • Minimum Character length &#8239;: 5<br />
              &nbsp;&nbsp;Maximum Character length : 10
            </div>
            <div className="pt-3 footlightfont">
              • In case you forget your Referral Code, it will be visible to you
              on the ‘Account Details’ page of your profile.
            </div>

            <div className="py-3 d-flex justify-content-center">
              {/* <Link
              style={{ textDecoration: 'none' }}
              to="/ame_registration_form"
            > */}
              <button
                className="btn pro-spaces-button3 footlightfont "
                onClick={() => {
                  handleClose();
                }}
              >
                JOIN NOW
              </button>
              {/* </Link> */}
            </div>
          </Modal.Body>
        </Modal>
        {/* Starting Modal */}
      </div>
    </>
  );
};
