import axios from 'axios';
import React, { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';

export const Appointmentsmeuserview = ({ id }) => {
  const [imgg, setimgg] = useState([]);
  var id = localStorage.getItem('AccountID');
  useEffect(() => {
    axios
      .get(`/api/AffiliatedAccount/smeappointmentletterview?id=${id}`)
      .then((res) => {
        console.log(res.data);
        setimgg(res.data[0].Image);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <div className="container ">
        <div className="pt-2" id="appointmentdownload2">
          {imgg.length > 0 ? (
            <>
              <div className="py-2 d-flex justify-content-end">
                <a
                  className="btn pro-spaces-button3"
                  href={imgg[0].link}
                  download={true}
                >
                  DOWNLOAD
                </a>
              </div>
              <img
                src={imgg[0].link}
                style={{ width: '100%', height: '100%' }}
              />
            </>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '100vh' }}
            >
              Your Appointment Letter will available within 48 hours.
            </div>
          )}

          {/* {imgg?.map((elem) => {
            console.log(elem);
            return (
              <>
                {elem.Image?.map((ele) => {
                  console.log(ele);
                  return (
                    <>
                      {ele.link?.length ? (
                        <>
                          <div className="py-2 d-flex justify-content-end">
                            <a
                              className="btn pro-spaces-button3"
                              href={ele.link}
                              download={true}
                            >
                              DOWNLOAD
                            </a>
                          </div>

                          <img
                            src={ele.link}
                            style={{ width: '100%', height: '100%' }}
                          />
                        </>
                      ) : (
                        <>
                          <div>
                            Your Appointment Letter will available within 48
                            hours.{' '}
                          </div>
                        </>
                      )}
                    </>
                  );
                })}
              </>
            );
          })} */}
        </div>{' '}
      </div>
    </>
  );
};
