import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { keyGen } from "../../../../Utility/helper";
import { print_state, print_city, state_arr,s_a } from '../../../../../Utility/stateCity'
import { bloodgrouplist } from '../../../../../Utility/helper'


// const bloodgrouplist=['A+','A-','B+','B-','O+','O-','AB+','AB-']


export default function BloodDonorsReg() {
    const [cat, setCat] = useState([]);
    const [category, setCategory] = useState(false);
    const [subcategory, setSubcategory] = useState(false);
    const [Stateload, setStateload] = useState(true);
    const [loading, setloading] = useState(false)
    const [error, seterror] = useState(false);


    
    const notify = (message, sab) => {
      if (sab) {
        toast.success(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.warning(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    };



    const [value, setValue] = useState({
          name: "",
          phone: "",
          phone2: "",
          state: "",
          city: "",
          ServiceCharges: "",  
          bloodgroup:"",
          pincode :"" ,
          Address:"",
          Availability:""
      });


      function Cityshow(e) {
        var index = document.getElementById(e.target.id).selectedIndex;
        print_city("City", index);
        setValue({ ...value, state: e.target.value });
      }
    
      const sabari = (e) => {
        console.log(e.target.value);
        var anadhan;
        setValue({ ...value, category: e.target.value });
        cat.forEach((element) => {
          console.log(element._id);
          if (element._id === e.target.value) {
            anadhan = element.sub.map((elements) => {
              return <option value={elements._id}>{elements.sub}</option>;
            });
          } else {
          }
        });
        setSubcategory(anadhan);
      };
    
      useEffect(() => {
        axios
          .get("/api/api/getcategory")
          .then((res) => {
            if (res.status === 200) {
              console.log("workd");
              setCat(res.data);
            }
          })
          .catch((err) => {
            console.log(err.response.status);
          });
      }, []);
    
      var sab = [];
      useEffect(() => {
        if (cat.length >= 1) {
          cat.forEach((element) => {
            sab.push(<option value={element._id}>{element.category}</option>);
          });
          setCategory(sab);
        } else {
          console.log("nothing");
        }
      }, [cat]);
      useEffect(() => {
        if (Stateload) print_state("State");
        setStateload(false);
      }, []);


      const dasapppan= async(e)=>{



        if(!value.name)return seterror("Name is missing !");
        if (value.state.length === 0) return seterror("State is missing !");
        if (value.city.length === 0) return seterror("City is missing !");
        if(value.pincode)
        {
        if(value.pincode.toString().length!=6)return seterror(" Enter valid Pin code is missing !");
            
        }

        if (!value.phone) {
            return seterror('Enter your phone number')
        }
        if(value.bloodgroup.length===0) return seterror("Blood group is missing !")
        



        setloading(true)
        let sab = await  axios.post('/api/Admin/BloodDonorsReg',{
            value
      }).then((res)=>{
        if(res.status==200){
          notify("Successfully added", true);
         setValue({
            name: "",
            phone: "",
            phone2: "",
            state: "",
            city: "",
            ServiceCharges: "",  
            bloodgroup:"",
            pincode :"" ,
            Address:"",
            Availability:""
         })
          
        }
      }).catch((err)=>{
        console.log(err)
        notify(" Something went wrong", false);
        // setValue({
        //   name: "",
        //   phone: "",
        //   state: "",
        //   city: "",
        //   ServiceCharges: "",  
        //   bloodgroup:"",
        //   pincode :""   
     
        //  })
      })
      setloading(false)
          
      }


     
     
  return (
    <div className="container pt-5 mt-3">
        <h1>Blood Donors</h1>
        <div className='row'>
            <div className='col'><label className="labels">Name *</label></div><div className='col'><input className="form-control" type="text"  value={value.name}
                    onChange={(e) =>
                      setValue({ ...value, name: e.target.value })
                    }></input></div>
        </div><br />
           
        <div className='row'>
            <div className='col'> <label className="labels">State *</label></div><div className='col'> <select
        className="form-select"
        value={value.state}
        id="State"
        name="City"
        onChange={(e) => Cityshow(e)}
      >
        {/* <select onChange={(e) =>}  className="form-select" >
                            </select> */}
      </select></div>
        </div><br />
        <div className='row'>
            <div className='col'> <label className="labels">City *</label></div><div className='col'>
                  <select
                    className="form-select"
                    name="City"
                    value={value.city}
                    id="City"
                    onChange={(e) =>
                      setValue({ ...value, city: e.target.value })
                    }
                  >
                    <option>Select City</option>
                  </select></div>
        </div><br />
        <div className='row'>
            <div className='col'><label className="labels">PIN Code</label></div><div className='col'><input type="number" className="form-control"   value={value.pincode}
                    onChange={(e) =>
                      setValue({ ...value, pincode: e.target.value })
                    }></input></div>
        </div><br />
        <div className='row'>
            <div className='col'>  <label className="labels">Contact Number *</label></div><div className='col'>
            <input type="number"  value={value.phone} className="form-control"
                    onChange={(e) =>
                     {setValue({ ...value, phone: e.target.value })}
                    }></input></div>
        </div><br />
        <div className='row'>
            <div className='col'>  <label className="labels">Contact Number 2</label></div><div className='col'>
            <input type="number"  value={value.phone2} className="form-control"
                    onChange={(e) =>
                     { setValue({ ...value, phone: e.target.value })}
                    }></input></div>
        </div><br />
        <div className='row'>
            <div className='col'>  <label className="labels">Address</label></div><div className='col'>
              <textarea value={value.Address} className="form-control"
                    onChange={(e) =>
                      setValue({ ...value, Address: e.target.value })
                    }></textarea>
           </div>
        </div><br />
        <div className='row'>
            <div className='col'>  <label className="labels">Blood Group *</label></div><div className='col'>
    
                      <select onChange={(e) =>
                      setValue({ ...value, bloodgroup: e.target.value })} value={value.bloodgroup} className="form-select">
                        <option></option>
                        {
                          bloodgrouplist.map((e)=>{

                       return   <option value={e}>{e}</option>


                          })
                        }
                      </select>
           </div>
        </div><br />
        <div className='row'>
            <div className='col'><label className="labels">Service Charges in Indian Rupee</label></div><div className='col'><input type="number" className="form-control"   value={value.ServiceCharges}
                    onChange={(e) =>
                      setValue({ ...value, ServiceCharges: e.target.value })
                    }></input></div>
        </div><br />
        <div className='row'>
            <div className='col'><label className="labels">Availability</label></div><div className='col'><input type="text" className="form-control"   value={value.Availability}
                    onChange={(e) =>
                      setValue({ ...value, Availability: e.target.value })
                    }></input></div>
        </div><br />
        <div className="row">
            <div className="col"><p style={{"color":'red'}}>{error?error:''}</p></div>
            <div className="col">
            {!loading &&   <button className="btn pro-spaces-button3" onClick={(e)=>dasapppan(e)}>Add</button> }
            {loading &&   <button className="btn btn-success" disabled><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button> }

            </div>
        </div>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    </div>
  )
}
