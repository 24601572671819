import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { notify } from '../../Utility/notify';

export const Vaccanies = () => {
  const [uservalue, setUservalue] = useState({
    CurrentCTS: '',
    SalaryExpectation: '',
  });
  const [Submit2, setSubmit2] = useState(false);

  const [check, setcheck] = useState(true);
  const [check2, setcheck2] = useState(true);

  const anees = useRef();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [shows, setShows] = useState(false);

  const [index, setindex] = useState();

  const handleCloses = () => setShows(false);
  const handleShows = () => setShows(true);
  const [drop, setdrop] = useState(true);
  const [opper, setopper] = useState();

  useEffect(() => {
    axios
      .post('/vipar/reg')
      .then((res) => {
        console.log(res.data);
        setopper(res.data);
      })
      .catch((err) => {});
  }, []);
  useEffect(() => {
    console.log(opper);
  }, [opper]);

  const [verticals, setVerticals] = useState([]);
  const [novacancy, setNovacancy] = useState(false);

  useEffect(() => {
    axios
      .post('/jobroute/jobconnectview')
      .then((res) => {
        console.log(res.data);
        res.data.forEach((elem) => {
          if (elem?.Status === true) {
            setNovacancy(true);
          }
        });
        setVerticals(
          res.data.filter((element) => {
            return element.Status;
          })
        );
      })
      .catch((err) => {});
  }, []);

  const [img, setimg] = useState([]);

  // useEffect(() => {
  //   axios
  //     .post("/vipar/regupdate")
  //     .then((res) => {})
  //     .catch((err) => {});
  // }, []);

  const [first, setfirst] = useState({});

  // useEffect(() => {
  //   axios
  //     .post("/vipar/regupdate", { first })
  //     .then((res) => {
  //       console.log("SADds");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [first]);

  const submit = (e) => {
    console.log('ASdsasd');
    console.log(e);
    e.preventDefault();
    handleClose();

    var formData = new FormData();
    console.log(img);
    {
      img?.map((elem) => {
        formData.append('file', elem);
      });
    }

    var dataSend = JSON.stringify({ uservalue, first });
    formData.append('data', dataSend);

    axios
      .post('/jobroute/jobconnectuser', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        notify('Vacancy Applied Succesffuly', true);
        handleClose2();
        setimg([]);
        setSubmit2(false);
      })
      .catch((err) => {
        notify('Something went wrong', false);
        setSubmit2(false);
        console.log(err);
      });
  };
  const [jobview, setJobview] = useState({});
  useEffect(
    (vert) => {
      console.log(jobview);
    },
    [jobview]
  );

  const [Noval, setNoval] = useState('');
  // const sortedOpper = opper.sort((a, b) => a.opper.localeCompare(b.opper));
  return (
    <>
      {/* <div className="card footlightfont text-center py-3">
        <span className="boldfont">WORK-from-OFFICE</span> Jobs
      </div> */}
      {verticals?.length && novacancy ? (
        <>
          <div className="">
            <div className="container p-4 ">
              {verticals?.map((elem, ind) => {
                console.log(elem);
                return (
                  <>
                    {elem.Status === true ? (
                      <>
                        <div className="">
                          {verticals[ind - 1]?.JobSection ==
                          elem?.JobSection ? (
                            <></>
                          ) : (
                            <>
                              {ind != 0 && <hr />}

                              <div
                                className="boldfont pb-3"
                                style={{ textTransform: 'uppercase' }}
                              >
                                {elem?.JobSection}
                              </div>
                            </>
                          )}
                          <div className="pt-3">
                            <Link
                              style={{
                                color: 'black',
                              }}
                              to={`/viewvacanies?id=${elem?._id}`}
                            >
                              <div
                                style={{
                                  textTransform: 'uppercase',
                                }}
                              >
                                {elem?.JobSubSection}
                              </div>
                            </Link>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {!novacancy && (
        <div className="pt-2">
          {' '}
          <h5 className="footlightfont ">
            Currently there is no vacancy in{' '}
            <span
              className="vipar"
              style={{
                fontSize: '25px',
                color: '#99d050',
              }}
            >
              {' '}
              Vipar&nbsp;&nbsp;
            </span>
            <span className="verticals">CONNECT</span>.
          </h5>
        </div>
      )}
      <Modal show={show2} onHide={handleClose2} centered>
        <Modal.Header closeButton></Modal.Header>
        <form
          id="myForm"
          onSubmit={(e) => submit(e)}
          novalidate
          className={Submit2 ? 'was-validated' : ''}
        >
          <Modal.Body>
            {verticals?.map((elem, ind) => {
              if (index === ind) {
                return (
                  <>
                    {elem?.Experience != 'Fresher' ||
                    elem?.Experience != 'Intern' ? (
                      <>
                        <div className="footlightfont">
                          <label className="">What is your Current CTC ?</label>
                          <input
                            className="form-control pt-2"
                            type="number"
                            onChange={(e) => {
                              setUservalue({
                                ...uservalue,
                                CurrentCTS: e.target.value,
                              });
                            }}
                          />
                          <div class="invalid-feedback ">
                            Please enter your Current CTC
                          </div>
                        </div>
                        <div className="py-4 footlightfont">
                          <label>What is your Salary Expectation ?</label>
                          <input
                            className="form-control pt-2"
                            type="number"
                            onChange={(e) => {
                              setUservalue({
                                ...uservalue,
                                SalaryExpectation: e.target.value,
                              });
                            }}
                          />
                          <div class="invalid-feedback">
                            Please enter your Salary Expectation
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div className="footlightfont">
                      <label>Are you ready to Relocate ?</label>

                      <div className="pt-2">
                        <input
                          type="checkbox"
                          defaultChecked
                          className="form-check-input "
                          id="exampleCheck1"
                          value={check2}
                          onChange={(e) => setcheck2(false)}
                        />
                        <label
                          class="form-check-label ps-2"
                          for="exampleCheck1"
                        >
                          Yes I am ready to Relocate.
                        </label>
                      </div>
                    </div>
                    <div className="pt-4 footlightfont">
                      <div>
                        <div class="form-check form-check-inline">
                          <input
                            required
                            class="form-check-input"
                            type="checkbox"
                            id="inlineCheckbox1"
                            value={check}
                            onChange={(e) => setcheck(false)}
                          />
                          <label class="form-check-label" for="inlineCheckbox1">
                            I have read, understood and agree with the Concept,
                            Remuneration and Employment Policies of
                            <span
                              className="vipar"
                              style={{
                                fontSize: '23px',
                                color: '#99d050',
                              }}
                            >
                              {' '}
                              Vipar
                            </span>
                            .
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="pt-3 footlightfont">
                      <div>
                        <input
                          required
                          hidden
                          ref={anees}
                          type="file"
                          className="btn btn-primary mt-3 mx-2"
                          style={{ backgroundColor: 'rgb(1 32 96)' }}
                          onChange={(e) => setimg([...img, ...e.target.files])}
                        />
                        <button
                          type="button"
                          id="anees"
                          className="btn btn-outline-primary mt-3 mx-2"
                          style={{
                            backgroundColor: 'white',
                            color: 'rgb(1 32 96)',
                            borderColor: 'rgb(1 32 96)',
                          }}
                          onClick={(e) => anees.current.click()}
                        >
                          UPLOAD CV
                        </button>
                        <div class="invalid-feedback">PLEASE UPLOAD CV</div>
                      </div>
                    </div>
                    {/* <div className="py-4  footlightfont">
                      Interested Candidates may kindly reply with the following
                      information to vipar.official@gmail.com :
                    </div>
                    <div className="pb-2 footlightfont">
                      1. Your Resumé / CV with detailed Work Experience and
                      Skills.
                      <br />
                      2. Current CTC
                      <br />
                      3. Expected Salary
                    </div> */}
                    <div className="py-4">
                      <div>
                        <button
                          className="btn btn-primary mt-3 mx-2 footlightfont"
                          style={{ backgroundColor: 'rgb(1 32 96)' }}
                          variant="primary"
                          onClick={(e) => {
                            setSubmit2(true);
                            setfirst(elem);
                          }}
                        >
                          SEND
                        </button>
                      </div>
                    </div>
                  </>
                );
              }
              console.log(elem);
            })}
          </Modal.Body>
        </form>
      </Modal>
    </>
  );
};
