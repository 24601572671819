import React from "react";
import { useQuery } from "react-query";
import { getAllSubCatagory, GET_SUBCATAGORY_FOR_CATAGORY } from "..";

export const useGetAllSubCatagoryQuery = ({
  enabled = false,
  catId = null,
}) => {
  return {
    query: useQuery(
      [GET_SUBCATAGORY_FOR_CATAGORY, catId],
      () => getAllSubCatagory(catId),
      {
        enabled,
      }
    ),
  };
};
