import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PassiveMemberList.css';
import Axios from 'axios';
import Moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { SpinnerBig } from '../../../Utility/spinner';
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';
import { maxWidth } from '@mui/system';
import { makeStyles } from '@material-ui/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-bootstrap/Modal';
import { Button, Spinner } from 'react-bootstrap';
import { SpinnerSmall } from '../../../Utility/spinner';
import { Link } from 'react-router-dom';
import { RiWhatsappFill } from 'react-icons/ri';

export function PassiveMemberList1() {
  const notify = (message, sab) => {
    if (sab) {
      toast.success(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warning(message, {
        position: 'bottom-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [rows, setrows] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const fetchData = () => {
    Axios.get('/api/Admin/passive')
      .then((res) => {
        if (res.status === 200) {
          console.log('passive View');
          console.log(res.data);
          setrows(res.data);
          setData(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // var Id='6209f5e094805605d016655d';
    fetchData();
  }, []);
  const [find, setFind] = useState('');
  let result;
  useEffect(() => {
    if (find.length) {
      console.log(find);
      console.log(data);
      var condition = new RegExp(find, 'i');
      result = data.filter(function (el) {
        console.log(el);
        return condition.test(
          el.pname || el.login[0]?.Email || el.login[0]?.Contact.toString()
        );
      });
      console.log(result);
      setrows(result);
    } else {
      if (data) {
        setrows(data);
      }
    }
    return () => {};
  }, [find]);

  ///////////////////////////////////////Mui table/////////////////////////////////////////////
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  ////////////////////////////////////////////////////////////////////
  const [SortValidity, setSortValidity] = useState(true);
  const SortValidityFunction = () => {
    console.log(rows);
    if (SortValidity) {
      setSortValidity(!SortValidity);
      setrows(
        rows
          .sort((a, b) =>
            a.Validity.toLowerCase() > b.Validity?.toLowerCase() ? 1 : -1
          )
          .map((number) => {
            return number;
          })
      );
    } else {
      setSortValidity(!SortValidity);
      setSortValidity(!SortValidity);
      setrows(
        rows
          .sort((a, b) =>
            a.Validity.toLowerCase() < b.Validity?.toLowerCase() ? 1 : -1
          )
          .map((number) => {
            return number;
          })
      );
    }
  };
  const CreatedBySort = (Type) => {
    // console.log(Type)

    if (Type) {
      if (Type == 'Self') {
        setrows(
          data.map((number, index) => {
            if (
              !number?.login[0]?.DirectLink &&
              !number?.login[0]?.NOTES?.createdByAdmin
            ) {
              console.log(Type);
              console.log(number);
              return number;
            }
          })
        );
      }
      if (Type == 'CreatedbyAdmin') {
        setrows(
          data
            .map((number, index) => {
              if (number?.login[0]?.NOTES?.createdByAdmin) {
                console.log(Type);
                console.log(number);
                console.count();
                return number;
              }
            })
            .filter((b) => b != undefined)
        );
      }
      if (Type == 'Directlink') {
        setrows(
          data
            .map((number, index) => {
              if (number?.login[0]?.DirectLink) {
                console.log(Type);
                console.count();

                console.log(number);
                return number;
              }
            })
            .filter((b) => b != undefined)
        );
      }
    } else {
      setrows(data);
    }
  };

  const [SortMAilCount, setSortMAilCount] = useState(true);
  const SortMAilCountFunction = () => {
    console.log(rows);
    if (SortMAilCount) {
      setSortMAilCount(!SortMAilCount);
      setrows(
        rows
          .sort((a, b) =>
            a.invoice_mail_count > b.invoice_mail_count ? 1 : -1
          )
          .map((number) => {
            return number;
          })
      );
    } else {
      setSortMAilCount(!SortMAilCount);
      setSortMAilCount(!SortMAilCount);
      setrows(
        rows
          .sort((a, b) =>
            a.invoice_mail_count < b.invoice_mail_count ? 1 : -1
          )
          .map((number) => {
            return number;
          })
      );
    }
  };

  const [SendMailLoading, setSendMailLoading] = useState(false);
  const SendMail = (Login_id, _id) => {
    var loginId = Login_id;
    var accountId = _id;
    var data = {
      loginId,
      accountId,
    };
    setSendMailLoading(true);
    Axios.post('/api/Admin/ResendMailInvoice', { data })
      .then((res) => {
        if (res.status == 200) {
          console.log('mail send');
          setSendMailLoading(false);
          fetchData();
          //  mailCount(Login_id,_id)
          // props?.fetchData()
        }
      })
      .catch((err) => {
        setSendMailLoading(false);
        console.log(err);
      });
  };
  const [CountMail, setCountMail] = useState('');
  // useEffect(() => {
  //   mailCount()
  // }, [])
  // const mailCount=(Login_id,_id)=>{
  //   var loginId=Login_id
  //   var accountId=_id
  //   var data={
  //     loginId,
  //     accountId
  //   }
  //   Axios.post('/api/Admin/MailInvoiceCount',{data}).then((res)=>{
  //     if (res.status==200) {
  //      console.log(res.data)
  //      setCountMail(res.data.data)
  //     }
  //   }).catch((err)=>{
  //     console.log(err)
  //   })
  // }

  //////////////////////////////////////////////////////Sorting end//////////////////////////////////////////////
  const [GetPaymentDetails, setGetPaymentDetails] = useState({
    Created_on: '',
    Validity: '',
    amount: '',
    Acc_id: '',
    Login_id: '',
    payment_id: '',
    Notes: '',
  });
  const [GetPaymentDetailsLoading, setGetPaymentDetailsLoading] =
    useState(false);
  const [GetPaymentDetailsError, setGetPaymentDetailsError] = useState();
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const UpdatePaymentAndValidityGet = (acc_id, login_id) => {
    setGetPaymentDetailsLoading(true);
    handleShow1();
    Axios.post('/api/Admin/UpdatePaymentAndValidityGet', { acc_id, login_id })
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          var data1 = Moment(res.data[0]?.Validity).format('YYYY-MM-DD');
          var data2 = Moment(res.data[0]?.login[0]?.Created_on).format(
            'YYYY-MM-DD'
          );

          setGetPaymentDetails({
            Created_on: data2,
            Validity: data1,
            amount: res.data[0]?.amount,
            Acc_id: acc_id,
            Login_id: login_id,
            payment_id: res.data[0]?._id,
            Notes: res.data[0]?.Notes,
          });
          setGetPaymentDetailsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        notify('Some thing went wrong', false);
      });
  };
  const [GetPaymentDetailsLoadingButton, setGetPaymentDetailsLoadingButton] =
    useState(false);
  const Validates = () => {
    console.log();
    if (GetPaymentDetails.Created_on.length <= 0) {
      return setGetPaymentDetailsError('Select created date');
    }
    if (GetPaymentDetails.Validity.length <= 0) {
      return setGetPaymentDetailsError('Select Validity date');
    }
    if (GetPaymentDetails.amount < 0) {
      return setGetPaymentDetailsError('Enter amount 0 or greater than 0');
    }
    setGetPaymentDetailsError('');
    return true;
  };
  const UpdatePaymentAndValidity = () => {
    if (Validates()) {
      setGetPaymentDetailsLoadingButton(true);
      Axios.post('/api/Admin/UpdatePaymentAndValidity', { GetPaymentDetails })
        .then((res) => {
          if (res.status === 200) {
            notify('Updated successful', true);
            fetchData();
            setGetPaymentDetailsLoadingButton(false);
            handleClose1();
          }
        })
        .catch((err) => {
          console.log(err);
          notify('Some thing went wrong', false);
          setGetPaymentDetailsLoadingButton(false);
        });
    }
  };
  //////////////////////////////////////////Updating Validity and payment  amount end here//////////////////////////////////////////////////
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const [Error, setError] = useState('');

  const handleShow2 = (id) => {
    console.log(id);
    setNotification({ ...Notification, Account_id: id });
    setShow2(true);
  };
  const [Notification, setNotification] = useState({
    Account_id: '',
    message: '',
  });
  const [Status_1, setStatus_1] = useState(false);
  const SendNotification = () => {
    // console.log(Notification)
    // return
    if (!Notification.message.length > 0) {
      return setError('Enter Message');
    }
    setError('');
    setStatus_1(true);
    Axios.post('/api/SuperAdmin/SendNotificationIndividual', { Notification })
      .then((res) => {
        if (res.status == 200) {
          console.log('hai');
          handleClose2();
          notify('Notification send successfully', true);
          setNotification({
            Account_id: '',
            message: '',
          });
          setStatus_1(false);
        }
      })
      .catch((err) => {
        console.log(err);
        notify('Something went wrong', false);
        setStatus_1(false);
      });
  };

  const classes = useStyles();

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [AdminDetails, setAdminDetails] = useState({});
  const [AdminDetailsLoading, setAdminDetailsLoading] = useState(false);

  const OpenAdminDetails = (NOTES) => {
    handleShow3();
    var id = NOTES.adminId;
    setAdminDetailsLoading(true);
    // return
    Axios.post('/api/SuperAdmin/GetAdminDetailsFromList', { id })
      .then((res) => {
        console.log(res.data);
        setAdminDetails(res.data);
        setAdminDetailsLoading(false);
      })
      .catch((err) => {
        notify('Something went wrong', false);
        setAdminDetailsLoading(false);
        handleClose2();
      });
  };
  //////////////////////////sorting new/////////////////////////////
  const [CreatedOnSort, setCreatedOnSort] = useState(true);

  const CreatedOnSortFunction = () => {
    console.log(rows);
    console.log(rows);
    if (CreatedOnSort) {
      setCreatedOnSort(!CreatedOnSort);
      setrows(
        rows
          .sort((a, b) => (a.Created_on > b.Created_on ? 1 : -1))
          .map((number) => {
            return number;
          })
      );
    } else {
      setCreatedOnSort(!CreatedOnSort);
      setCreatedOnSort(!CreatedOnSort);
      setrows(
        rows
          .sort((a, b) => (a.Created_on < b.Created_on ? 1 : -1))
          .map((number) => {
            return number;
          })
      );
    }
  };

  const [CitySort, setCitySort] = useState(true);

  const CitySortFunction = () => {
    console.log(rows);
    console.log(rows);
    if (CitySort) {
      setCitySort(!CitySort);
      setrows(
        rows
          .sort((a, b) => (a.city > b.city ? 1 : -1))
          .map((number) => {
            return number;
          })
      );
    } else {
      setCitySort(!CitySort);
      setCitySort(!CitySort);
      setrows(
        rows
          .sort((a, b) => (a.city < b.city ? 1 : -1))
          .map((number) => {
            return number;
          })
      );
    }
  };

  return (
    <div style={{ height: '100%' }} className=" p-2 border bg-light">
      <div style={{}}>
        <div className="bg-secondary text-light text-center p-3">
          {' '}
          <h1 className="text-capital ">PASSIVE</h1>
        </div>
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            {/* <span class="input-group-text" id="inputGroup-sizing-sm">Small</span> */}
          </div>

          <input
            type="text"
            class="form-control mt-5"
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            onChange={(e) => {
              setFind(e.target.value);
            }}
          />
        </div>
        <br></br>
      </div>
      {Loading ? <SpinnerBig /> : ''}

      <div style={{}}>
        {rows ? (
          <TableContainer component={Paper}>
            <div className=" d-flex justify-content-end ">
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
            <Table sx={{}} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Phone </TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell className={classes.cell1}>
                    City{' '}
                    {CitySort ? (
                      <AiOutlineArrowUp onClick={() => CitySortFunction()} />
                    ) : (
                      <AiOutlineArrowDown onClick={() => CitySortFunction()} />
                    )}{' '}
                  </TableCell>
                  <TableCell className={classes.cell1}>
                    Validity{' '}
                    {SortValidity ? (
                      <AiOutlineArrowUp
                        onClick={() => SortValidityFunction()}
                      />
                    ) : (
                      <AiOutlineArrowDown
                        onClick={() => SortValidityFunction()}
                      />
                    )}{' '}
                  </TableCell>
                  <TableCell className={classes.cell1}>
                    Created On{' '}
                    {CreatedOnSort ? (
                      <AiOutlineArrowUp
                        onClick={() => CreatedOnSortFunction()}
                      />
                    ) : (
                      <AiOutlineArrowDown
                        onClick={() => CreatedOnSortFunction()}
                      />
                    )}{' '}
                  </TableCell>

                  <TableCell className={classes.cell}>
                    <div
                      className=" d-flex justify-content-center "
                      style={{ textTransform: 'unset', whiteSpace: 'nowrap' }}
                    >
                      Created By
                    </div>
                    <select
                      className="form-select"
                      onChange={(e) => CreatedBySort(e.target.value)}
                    >
                      <option></option>
                      <option value={'Self'}>Self</option>
                      <option value={'CreatedbyAdmin'}>Created by Admin</option>
                      {/* <option value={"Directlink"}>Direct link</option> */}
                    </select>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <div
                      style={{ textTransform: 'unset', whiteSpace: 'nowrap' }}
                    >
                      Mail Count{' '}
                      {SortMAilCount ? (
                        <AiOutlineArrowUp
                          onClick={() => SortMAilCountFunction()}
                        />
                      ) : (
                        <AiOutlineArrowDown
                          onClick={() => SortMAilCountFunction()}
                        />
                      )}
                    </div>{' '}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{row?.pname ? row?.pname : '-'}</TableCell>
                      <TableCell>
                        {row?.login[0]?.Contact ? row?.login[0]?.Contact : '-'}
                      </TableCell>
                      <TableCell>
                        {row?.login[0]?.Email ? row?.login[0]?.Email : '-'}
                      </TableCell>
                      <TableCell>{row?.city}</TableCell>
                      <TableCell>
                        {Moment(row?.Validity).format('DD-MM-YYYY')}
                      </TableCell>
                      <TableCell>
                        {Moment(row?.Created_on).format('DD-MM-YYYY')}
                      </TableCell>
                      <TableCell>
                        {row?.login[0]?.NOTES?.createdByAdmin ? (
                          <Link
                            to={''}
                            onClick={(e) =>
                              OpenAdminDetails(row?.login[0]?.NOTES)
                            }
                            style={{ textDecoration: 'none', color: 'black' }}
                          >
                            Created by Admin
                          </Link>
                        ) : row?.login[0]?.DirectLink ? (
                          'Direct link'
                        ) : (
                          'Self'
                        )}
                      </TableCell>
                      <TableCell>
                        {row?.invoice_mail_count > 0 || CountMail > 0
                          ? row?.invoice_mail_count
                          : '0'}
                      </TableCell>
                      <TableCell>
                        {' '}
                        {row?.Priority == 'Primary' ? (
                          <>
                            {SendMailLoading ? (
                              <button
                                className="btn btn-info  w-100"
                                type="button"
                                disabled
                                variant="info"
                                style={{
                                  textTransform: 'unset',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                Send Invoice mail
                              </button>
                            ) : (
                              <button
                                className="btn btn-info  w-100"
                                type="button"
                                onClick={(e) =>
                                  SendMail(row?.login[0]?._id, row?._id)
                                }
                                style={{
                                  textTransform: 'unset',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                Send Invoice mail
                              </button>
                            )}
                          </>
                        ) : (
                          ''
                        )}
                      </TableCell>

                      <TableCell>
                        {' '}
                        {row?.Priority == 'Primary' ? (
                          <>
                            {SendMailLoading ? (
                              <button
                                className="btn btn-info  w-100"
                                type="button"
                                disabled
                                variant="info"
                                style={{
                                  textTransform: 'unset',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                Update Payment and Validity
                              </button>
                            ) : (
                              <button
                                className="btn btn-info  w-100"
                                type="button"
                                onClick={(e) =>
                                  UpdatePaymentAndValidityGet(
                                    row._id,
                                    row.login[0]?._id
                                  )
                                }
                                style={{
                                  textTransform: 'unset',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                Update Payment and Validity
                              </button>
                            )}
                          </>
                        ) : (
                          ''
                        )}
                      </TableCell>

                      <TableCell>
                        <button
                          className="btn pro-spaces-button3"
                          onClick={(e) => handleShow2(row._id)}
                        >
                          Send Notification
                        </button>
                      </TableCell>
                      <TableCell>
                        <td>
                          {row?.login[0]?.Contact ? (
                            <>
                              <a
                                target="_blank"
                                href={`https://wa.me/${
                                  row?.login[0]?.Contact.length == 10
                                    ? '+91' + row?.login[0]?.Contact
                                    : row?.login[0]?.Contact
                                } `}
                                className="btn "
                                style={{ border: 'none' }}
                              >
                                <RiWhatsappFill size={30} color="green" />
                              </a>
                            </>
                          ) : (
                            ''
                          )}
                        </td>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <center>
            <h1>No Members to show</h1>
          </center>
        )}
      </div>

      <Modal
        show={show1}
        onHide={handleClose1}
        style={{ zIndex: 6000 }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Payment and Validity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {GetPaymentDetailsLoading ? (
            <SpinnerSmall />
          ) : (
            <>
              <div className="row">
                <div className="col-12">
                  <label className="labels">Created on</label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="date"
                    value={GetPaymentDetails.Created_on}
                    className="form-control "
                    id="firstPoss"
                    onChange={(e) =>
                      setGetPaymentDetails({
                        ...GetPaymentDetails,
                        Created_on: e.target.value,
                      })
                    }
                  ></input>
                </div>
                <div className="col-12">
                  <label className="labels">Validity</label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="date"
                    value={GetPaymentDetails.Validity}
                    className="form-control "
                    id="firstPoss1"
                    onChange={(e) =>
                      setGetPaymentDetails({
                        ...GetPaymentDetails,
                        Validity: e.target.value,
                      })
                    }
                  ></input>
                </div>
                <div className="col-12">
                  <label className="labels">Amount</label>
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="text"
                    value={GetPaymentDetails.amount}
                    className="form-control "
                    id="firstPoss3"
                    onChange={(e) =>
                      setGetPaymentDetails({
                        ...GetPaymentDetails,
                        amount: e.target.value,
                      })
                    }
                  ></input>
                </div>
              </div>

              <div>
                {GetPaymentDetailsError ? (
                  <p style={{ color: 'red' }}>{GetPaymentDetailsError}</p>
                ) : (
                  ''
                )}
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {GetPaymentDetailsLoadingButton ? (
            <Button variant="primary" disabled>
              Update
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={(e) => UpdatePaymentAndValidity()}
            >
              Update
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={show2}
        onHide={handleClose2}
        centered
        style={{ zIndex: 6000 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {Location_Coordinates.toString()} */}
          <div className="row">
            <div className="col-12">
              <label className="labels">Enter message</label>
              &nbsp;&nbsp;&nbsp;
              <textarea
                type="text"
                value={Notification.message}
                className="form-control "
                id="firstPoss"
                onChange={(e) =>
                  setNotification({ ...Notification, message: e.target.value })
                }
              ></textarea>
            </div>
          </div>
          <br></br>
          {Error ? <p style={{ color: 'red' }}>{Error}</p> : ''}
        </Modal.Body>
        <Modal.Footer>
          {Status_1 ? (
            <Button variant="primary" disabled>
              <Spinner animation="border" size="sm" />
              &nbsp;Send
            </Button>
          ) : (
            <Button variant="primary" onClick={SendNotification}>
              Send
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={show3}
        onHide={handleClose3}
        centered
        style={{ zIndex: 6000 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Admin Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {AdminDetailsLoading ? (
            <SpinnerBig />
          ) : (
            <>
              {AdminDetails[0]?.Type === 'SUPERADMIN' ? (
                <center>
                  <h5>Created by super admin</h5>
                </center>
              ) : (
                <>
                  <div className="row">
                    <div className="col-4">Name</div>
                    <div className="col-1">:</div>
                    <div className="col">
                      {AdminDetails[0]?.AdminData[0]?.Name}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">State</div>
                    <div className="col-1">:</div>
                    <div className="col">
                      {AdminDetails[0]?.AdminData[0]?.state}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">City</div>
                    <div className="col-1">:</div>
                    <div className="col">
                      {AdminDetails[0]?.AdminData[0]?.city}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">Email</div>
                    <div className="col-1">:</div>
                    <div className="col">{AdminDetails[0]?.Email}</div>
                  </div>
                  <div className="row">
                    <div className="col-4">Contact</div>
                    <div className="col-1">:</div>
                    <div className="col">{AdminDetails[0]?.Contact}</div>
                  </div>
                </>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
const useStyles = makeStyles({
  cell: {
    maxWidth: '200px',
    minWidth: '200px',
  },
  cell1: {
    maxWidth: '150px',
    minWidth: '150px',
  },
});
